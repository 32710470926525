import styled from 'styled-components'
import {
  Grid,
  AccordionDetails as MuiAccordionDetails,
  CircularProgress as MuiCircularProgress
} from '@mui/material'
import { FactCheck as MuiFactCheck } from '@mui/icons-material'
import theme from 'theme/designTokens'

const Text = styled.p`
  color: ${() => theme.palette.textColors.black.darkBlack};
  ${({ $hasFontWeight }) => $hasFontWeight && ' font-weight: bold'};
  ${({ $hasFontWeight }) =>
    $hasFontWeight ? 'margin: 0 6px 0 0' : 'margin: 0'};

  padding: 8px 0;
`

const TextGroup = styled.p`
  font-size: 15px;
  color: ${() => theme.palette.textColors.primary.mediumGray};
  margin: 0 1rem 0 0;
`

const AccordionDetails = styled(MuiAccordionDetails)`
  .MuiGrid-container {
    align-items: baseline;
  }
`

const SectionAccordion = styled.div`
  margin: 0 2rem;
`

const SectionAccordionTop = styled.div`
  margin-top: 1.5rem;
  margin-right: 2rem;
`

const MenuButton = styled.div`
  margin-top: 2rem;
  margin-right: 2rem;
`

const Ead = styled.div`
  border-radius: 50px;
  background-color: ${() => theme.palette.gray.light};
  padding: 0 10px;
  margin-top: 2.5rem;
`

const TextIsEad = styled.p`
  color: ${() => theme.palette.textColors.black.textLightPrimary};
`

const Status = styled.div`
  border-radius: 50px;
  background-color: ${({ $bgColor }) => $bgColor};
  margin: ${({ $isMg }) => $isMg};
`

const StatusOccurece = styled.div`
  border-radius: 50px;
  background-color: ${({ $bgColor }) => $bgColor};
  margin: 0 auto;
  font-size: 13px;
  width: 53%;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`

const TextStatus = styled.p`
  margin: 5px;
  padding: 0.5rem;
  color: ${() => theme.palette.primary.white};
  text-align: center;
`

const TextProgress = styled.p`
  font-size: 12px;
  color: ${() => theme.palette.textColors.black.textLightSecondary};
`

const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    color: #4caf50;
    width: 50px;
    height: 50px;
  }
`
const TextAccordion = styled.p`
  font-size: 16px;
`

const SecondGrid = styled(Grid)`
  padding: 0 0 0 16px !important;
  > div {
    margin: 0;
  }
`

const AccordionSummaryContainer = styled.div`
  display: flex;
  align-items: center;
`

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Link = styled.a`
  text-decoration: underline;
  cursor: pointer;
  color: ${() => theme.palette.primary.main};
`

const FactCheck = styled(MuiFactCheck)`
  &.MuiSvgIcon-root {
    fill: ${(props) => props.theme.palette.yellow.warning};
  }
`

export {
  LeftContainer,
  RightContainer,
  AccordionSummaryContainer,
  Text,
  Status,
  AccordionDetails,
  SectionAccordion,
  SectionAccordionTop,
  TextStatus,
  TextProgress,
  Ead,
  TextIsEad,
  MenuButton,
  CircularProgress,
  ButtonGroup,
  StatusOccurece,
  TextAccordion,
  SecondGrid,
  Link,
  TextGroup,
  FactCheck
}
