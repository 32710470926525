/* eslint-disable camelcase */
const mountRowsBody = ({
  program,
  competence,
  complementary_bibliography,
  evaluation_system,
  guidelines,
  main_bibliography,
  methodology,
  skills
}) => [
  {
    label: 'Ementa',
    value: program
  },
  {
    label: 'Competência e Habilidades',
    value: skills
  },
  {
    label: 'Sistema de Avaliação',
    value: evaluation_system
  },
  {
    label: 'Metodologias/Recursos',
    value: methodology
  },
  {
    label: 'Conteúdo',
    value: competence
  },
  {
    label: 'Bibliografia Principal',
    value: main_bibliography
  },
  {
    label: 'Bibliografia Complementar',
    value: complementary_bibliography
  },
  {
    label: 'Orientações',
    value: guidelines
  }
]

const mountRowsHeader = ({ urlParams }) => [
  {
    label: 'Disciplina',
    value: urlParams.get('discipline')
  },
  {
    label: 'Código',
    value: urlParams.get('disciplineCode')
  },
  {
    label: 'Revisão',
    value: urlParams.get('revisionDate')
  }
]

export { mountRowsBody, mountRowsHeader }
