import React, { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, ListItemButton } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import { dateFormat } from 'services/helpers'
import { updateNotifications } from 'services/api/shared'
import { ROUTE } from 'services/constants'
import { Popover } from 'components/atoms'
import { ModalSkeleton, TextGroup } from 'components/molecules'
import { ModalNotificationDescription } from 'components/organisms'
import { minimalNotification } from './constants'

const PopoverNotification = ({
  idPopover,
  open,
  anchorEl,
  handleClose,
  notification
}) => {
  const [openNotification, setOpenNotification] = useState(false)
  const [descriptionNotification, setDescriptionNotification] = useState({})
  const history = useNavigate()

  const onHandleReadMessage = useCallback(
    async (item) => {
      const { id } = item
      await updateNotifications({ id })
      setOpenNotification(!openNotification)
      setDescriptionNotification(item)
    },
    [openNotification, descriptionNotification]
  )

  const handleCloseModalDescription = useCallback(() => {
    setOpenNotification(!openNotification)
  }, [openNotification])

  const handleReadMore = () => {
    history(ROUTE.NOTIFICATION)
    handleClose()
  }

  return (
    <>
      <Popover
        idPopover={idPopover}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        {notification && notification.length >= minimalNotification ? (
          <ModalSkeleton
            onClickClose={() => handleClose()}
            onClickReadMessage={() => handleReadMore()}
            valueButton="Ver todas"
          >
            <Box sx={{ padding: 0 }}>
              {notification.map((item) => (
                <ListItemButton
                  sx={{ padding: '0px', marginBottom: '16px' }}
                  onClick={() => onHandleReadMessage(item)}
                  key={item.id}
                >
                  <TextGroup
                    title={item.title}
                    subtitle={dateFormat(item?.created_at, 'DD/MM/YYYY - h:mm')}
                    icon={<EmailIcon color="primary" />}
                  />
                </ListItemButton>
              ))}
            </Box>
          </ModalSkeleton>
        ) : (
          <ModalSkeleton
            onClickClose={() => handleClose()}
            isVisibleFooter={false}
          >
            <Box sx={{ padding: 0, marginBottom: '16px' }}>
              <TextGroup
                title="Não há nenhuma nova notificação"
                icon={<EmailIcon color="primary" />}
              />
            </Box>
          </ModalSkeleton>
        )}
      </Popover>

      <ModalNotificationDescription
        open={openNotification}
        handleClose={handleCloseModalDescription}
        descriptionNotification={descriptionNotification}
      />
    </>
  )
}

export default PopoverNotification
