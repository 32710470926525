import styled from 'styled-components'
import { Toolbar } from '@mui/material'

const ToolbarArea = styled(Toolbar)`
  &.MuiToolbar-root {
    padding: 0;
    display: flex;
    align-items: start;
    margin: 35px 0 -10px;
    min-height: 30px !important;
  }
`

export { ToolbarArea }
