const initialSubject = {}
const defaultItemsPerPage = 10
const defaultTotalPage = 1
const defaultPage = 1

const initialValues = {
  content_description: '',
  content_section: '',
  content_workload: ''
}

const messageInformative =
  '<b>Ainda não há nenhum conteúdo EAD cadastrado.</b> <br /> Adicione um novo clicando no botão abaixo.'
const messageSuccess = (isEdit) =>
  `Conteúdo EAD ${isEdit ? 'editado' : 'cadastrado'} com sucesso!`

const messageSuccessDelete = 'Conteúdo EAD deletado com sucesso!'

const modalMessageConfirm = (isDelete) =>
  isDelete
    ? 'Deseja remover este conteúdo EAD da disciplina? Você perderá as informações sobre ele.'
    : 'Deseja excluir a operação? Você perderá as alterações que ainda não foram salvas.'

const breadCrumbsItems = (subject) => [
  {
    path: '',
    label: 'Gestão'
  },
  {
    path: '',
    label: 'Disciplinas'
  },
  {
    path: '',
    label: `<b>${subject.discipline || '---'}</b>`
  }
]

export {
  breadCrumbsItems,
  initialSubject,
  messageInformative,
  messageSuccess,
  initialValues,
  modalMessageConfirm,
  messageSuccessDelete,
  defaultItemsPerPage,
  defaultTotalPage,
  defaultPage
}
