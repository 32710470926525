import { lazy, useEffect, useState } from 'react'
import { Form as FormikForm, Formik } from 'formik'
import { ListAlt, Search } from '@mui/icons-material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { usePaginationBasic, useSnack } from 'services/hooks'
import {
  IsEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import { getStudentsList } from 'services/api/admin'
import {
  defaultPage,
  nameAccordion,
  MESSAGE,
  defaultItemsPerPage,
  countDefault
} from './constants'
import schema from './schema'
import { initialValue } from './config'
import { useStoreStudents } from '../Store/useStoreStudents'

const Form = lazy(() => import('./components/Form'))
const Table = lazy(() => import('./components/Table'))

const ListStudents = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const snackProps = useSnack()
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [notSearch, setNotSearch] = useState(true)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [studentsList, setStudentsList] = useState([])

  const isSearchParam = location?.state?.savedSearch || false

  const { formValues, setFormValues, resetFormValues } = useStoreStudents()

  const { page, totalPage, items, setPage, handleSetPage } = usePaginationBasic(
    studentsList,
    filter,
    count
  )
  const { setSnack } = snackProps

  const onSubmit = async (values) => {
    setSnack('', '')
    setLoading(true)
    const paginationData = {
      limit: filter,
      page: page || defaultPage
    }

    const params = { ...paginationData, [values.searchType]: values.searchBy }
    const { data: response, error, status } = await getStudentsList(params)

    setLoading(false)
    if (error) {
      setNotSearch(true)
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
      })
      return
    }

    setCount(response?.count)
    setStudentsList(response?.data)
    setNotSearch(false)
    setFormValues(values)
    setExpanded(false)
  }

  const cancelAutomaticSearch = () => {
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  const onClear = () => {
    setFormValues(null)
    resetFormValues()
    setStudentsList([])
    setNotSearch(true)
    cancelAutomaticSearch()
  }

  useEffect(
    () =>
      formValues?.searchBy && formValues?.searchType && onSubmit(formValues),
    [page, filter]
  )

  useEffect(() => {
    if (isSearchParam && formValues?.searchBy && formValues?.searchType) {
      return onSubmit(formValues)
    }

    resetFormValues()
    cancelAutomaticSearch()
    return setStudentsList([])
  }, [isSearchParam])

  const initialState = mergeInitialValues(
    initialValue,
    isSearchParam && formValues
  )

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH_PERFOMED
    : MESSAGE.NOT_FOUND_SEARCH

  return (
    <TemplateDefaultFormAndResult
      titleHeader="Secretaria - Alunos"
      snackProps={snackProps}
      nameAccordion={nameAccordion}
      loadingOpen={loading}
      listItems={studentsList}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      expanded={expanded}
      setExpanded={setExpanded}
      accordion={
        <Formik
          initialValues={initialState}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={schema}
        >
          {(props) => (
            <FormikForm
              onSubmit={props.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Form
                setLoading={setLoading}
                setCount={setCount}
                setFilter={setFilter}
                setPage={setPage}
                onClear={onClear}
                {...props}
              />
            </FormikForm>
          )}
        </Formik>
      }
    >
      {studentsList && !IsEmpty(studentsList) && (
        <Table
          rows={items}
          filter={filter}
          setFilter={setFilter}
          setPage={setPage}
          page={page}
          totalPage={totalPage}
          count={count}
          handleSetPage={handleSetPage}
          setLoading={setLoading}
          setSnack={setSnack}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ListStudents
