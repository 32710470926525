import * as Styled from './style'

const StepFour = ({ finishTutorial }) => (
  <Styled.Container>
    <Styled.BackgroundImage src="/images/onboarding_slide_4.svg" alt="#" />
    <Styled.Close onClick={finishTutorial} />

    <Styled.Message>
      <Styled.MessageImage
        src="/images/onboarding_slide_4_ilustracoes.svg"
        alt="#"
      />
      <Styled.MessageText>
        Caso tenha ainda alguma dúvida ou precise abrir um chamado sobre o
        sistema, clique no botão ”ajuda” no canto inferior direito da tela.
      </Styled.MessageText>
    </Styled.Message>
  </Styled.Container>
)

export default StepFour
