import { Close } from '@mui/icons-material'
import { Box } from '@mui/material'
import { ModalDownload } from 'components/molecules'
import { worksheets } from './constants'
import * as Styled from './style'

const ModalWorksheets = ({
  open,
  handleClose,
  setSnack,
  setLoading,
  values,
  id,
  isValidation,
  setModalDonwloadsFile,
  setDisciplineAndClass
}) => (
  <ModalDownload
    open={open}
    handleClose={handleClose}
    title="Baixar planilha"
    icon={<Close />}
  >
    {worksheets?.map((item) => (
      <Styled.GridWorksheets item xs={12} sm={12} md={6} key={item.label}>
        <Styled.LinkWorksheets
          className={item.name_seo}
          onClick={() =>
            item.download({
              setSnack,
              setLoading,
              values,
              id,
              isValidation,
              setModalDonwloadsFile,
              setDisciplineAndClass
            })
          }
        >
          {!item.icon ? (
            <Styled.PictureAsPdf />
          ) : (
            <Box dangerouslySetInnerHTML={{ __html: item.icon }} />
          )}

          <Styled.LabelWorksheets variant="subtitle-2">
            {item.label}
          </Styled.LabelWorksheets>
        </Styled.LinkWorksheets>
      </Styled.GridWorksheets>
    ))}
  </ModalDownload>
)

export { ModalWorksheets }
