import styled from 'styled-components'
import { Chip as MuiChip, Grid, TextField } from '@mui/material'
import { Button } from 'components/atoms'

const Chip = styled(MuiChip)`
  &.MuiChip-root {
    font-weight: bold;
  }
`

const SwitchAdvSearch = styled.div`
  padding: 20px 0 10px;
`

const ButtonSearch = styled(Button)`
  &.MuiButton-root {
    margin: 0 0 0 16px;
  }
`

const ButtonClear = styled(Button)`
  &.MuiButton-root {
    margin: 10px 0;
  }
`

const Checkbox = styled.div`
  margin-top: 8px;
`

const Switch = styled.div`
  margin-top: 4px;
`

const GroupSwitch = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;

    @media (max-width: 769px) {
      flex-wrap: wrap;
    }
  }
`

const CardFooter = styled.div`
  text-align: end;
  margin-top: 26px;
  margin-left: 16px;
`

const Input = styled(TextField)`
  .MuiInputAdornment-root {
    display: none;
  }
  &:hover .MuiInputAdornment-root {
    display: flex;
  }
`

export {
  ButtonSearch,
  ButtonClear,
  SwitchAdvSearch,
  Chip,
  Checkbox,
  Switch,
  GroupSwitch,
  CardFooter,
  Input
}
