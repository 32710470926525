import { Box } from '@mui/material'
import { AddCircle } from '@mui/icons-material'

import { Button } from 'components/atoms'

const Actions = ({ handleAddDisciplinesInGrouping, loading }) => (
  <Box>
    <Button
      startIcon={<AddCircle />}
      color="warning"
      variant="outlined"
      loading={loading}
      loadingPosition="end"
      onClick={() => handleAddDisciplinesInGrouping()}
    >
      Adicionar
    </Button>
  </Box>
)

export default Actions
