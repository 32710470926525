const ERROR = 'error'
const INFO = 'info'
const SUCCESS = 'success'
const WARNING = 'warning'
const HIDE_IN_MILLISECONDS = 6000
const AUTO_HIDE_TIME = HIDE_IN_MILLISECONDS

const defaultMessageError =
  'Não foi possível conectar ao servidor. Tente novamente!'
const defaultMessageSuccess = 'Dados salvos com sucesso!'
const defaultMessageForUser =
  'Não conseguimos processar sua requisição, tente novamente!'

export {
  AUTO_HIDE_TIME,
  ERROR,
  INFO,
  SUCCESS,
  WARNING,
  defaultMessageError,
  defaultMessageSuccess,
  defaultMessageForUser
}
