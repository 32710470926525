import humps from 'humps'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  getDisciplinesLinked,
  modifyDisciplinesLinked,
  removeDisciplinesLinked
} from 'services/api/admin'
import {
  removeCurrencyMask,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'

const loadDisciplinesLinked = async ({
  onError,
  professorId,
  schoolClassId,
  inactive
}) => {
  const { data, error, status } = await getDisciplinesLinked(
    humps.decamelizeKeys({
      professorId,
      schoolClassId,
      inactive
    })
  )

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDisciplines
    })
  }

  return humps.camelizeKeys(data)
}

const updateDisciplinesLinked = async ({ classId, values, onError }) => {
  const { data, error, status } = await modifyDisciplinesLinked(
    classId,
    humps.decamelizeKeys({
      linked_disciplines: values.map((value) => ({
        ...value,
        hourlyLessonCost: removeCurrencyMask(value.hourlyLessonCost)
      }))
    })
  )

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditClassLinked
    })
  }

  toastNotificationSuccess('Alteraçoes realizadas com sucesso')

  return humps.camelizeKeys(data)
}

const deleteDisciplinesLinked = async ({
  classId,
  professorId,
  disciplinesId,
  onError
}) => {
  const { data, error, status } = await removeDisciplinesLinked(
    humps.decamelizeKeys({
      professor_id: +professorId,
      school_class_id: classId,
      disciplinesId
    })
  )

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToInactivateClassLinked
    })
  }

  return toastNotificationSuccess('Vínculo(s) inativado(s) com sucesso.')
}

export {
  loadDisciplinesLinked,
  updateDisciplinesLinked,
  deleteDisciplinesLinked
}
