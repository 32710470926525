import styled from 'styled-components'
import { Link as RouteLink } from 'react-router-dom'
import { IconButton as MuiIconButton } from '@mui/material'

const BoxLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 8px;

  & > a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const IconButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    margin-bottom: 4px;
    padding: 0;
    margin-left: 8px;
  }
`

const Link = styled(RouteLink)`
  text-transform: uppercase;
`

export { BoxLink, IconButton, Link }
