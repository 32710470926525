import styled from 'styled-components'
import {
  Paper as MuiPaper,
  Toolbar as MuiTableToolbar,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell
} from '@mui/material'

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
  }
`

const TableToolbar = styled(MuiTableToolbar)`
  display: flex;
  gap: 20px;
`

const Table = styled(MuiTable)`
  min-width: 750px;

  .MuiToolbar-root {
    padding: 0;
  }
  &.MuiTableContainer-root {
    overflow-y: ${({ $overflow }) => $overflow && 'scroll'};
    height: ${({ $overflow }) => $overflow && '320px'};
  }
`

const TableBody = styled(MuiTableBody)``

const TableRow = styled(MuiTableRow)`
  & > * {
    border-bottom: 'unset';
  }
`

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    /* width: ${({ $width }) => $width || '100%'}; */
    border-left: 1px solid;
    border-color: ${({ theme }) => theme.palette.gray.primaryLight};
  }
`

const RenderText = styled.p``

export {
  Paper,
  TableRow,
  TableBody,
  TableCell,
  RenderText,
  TableToolbar,
  Table
}
