import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Clear, ListAlt, Search } from '@mui/icons-material'
import { useFormik } from 'formik'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { useMakeService, usePaginationBasic, useSnack } from 'services/hooks'
import {
  IsEmpty,
  convertToParamsArrayString,
  isEmpty,
  mergeInitialValues,
  parseUnits,
  updateErrorMessage
} from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import { ROUTE } from 'services/constants'
import { getListSchoolClass } from 'services/api/admin'

import { ButtonFooterV2 } from 'components/molecules'
import { Grid } from '@mui/material'
import Table from './components/Table'
import FormClass from './components/FormClass'
import {
  MESSAGE,
  countDefault,
  defaultItensPerPage,
  defaultPage,
  initUnit,
  nameAccordion
} from './constants'
import { initialState } from './config'
import { isDisabledButton, parseParamsSearchAdvanced } from './helpers'
import { useListSchoolClass } from './context/FormContext'
import {
  deleteSubmitClass,
  handleLevels,
  handleModules,
  handleShifts,
  onUnitsRegions
} from './services'
import * as Styled from './style'

const ListSchoolClass = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const [isActiveButton, setIsActiveButton] = useState(false)
  const [searchResult, setSearchResult] = useState(true)
  const [classList, setClassList] = useState([])
  const [unitSelected, setUnitSelected] = useState()
  const [unitIdSelected, setUnitIdSelected] = useState(initUnit)
  const [listPole, setListPole] = useState([])
  const [courses, setCourses] = useState([])
  const [shifts, setShifts] = useState([])
  const [modules, setModules] = useState([])
  const [levels, setLevels] = useState([])
  const [loading, setLoading] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [isSearchAdvanced, setIsSearchAdvanced] = useState(false)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [filter, setFilter] = useState(defaultItensPerPage)
  const [count, setCount] = useState(countDefault)
  const [isOpen, setIsOpen] = useState(false)

  const location = useLocation()
  const { formValues, setFormValues } = useListSchoolClass()
  const isSearchParam = location?.state?.savedSearch

  const paramsMakeService = {
    unitsValue: true,
    isUnits: true,
    isGroup: true,
    setSnack
  }

  const { unitys, group } = useMakeService(paramsMakeService)

  const { page, totalPage, items, setPage } = usePaginationBasic(
    classList,
    filter,
    count
  )

  const getSchoolClass = async (values) => {
    setSnack('')
    setLoading(true)
    setFormValues(values)

    const { data, error, status } = await getListSchoolClass(
      values.group,
      values.pole,
      parseParamsSearchAdvanced({
        ...values,
        filter,
        page,
        unitys: parseUnits(unitys),
        isSearchAdvanced
      }),
      convertToParamsArrayString(values.group, 'group_name'),
      convertToParamsArrayString(values.pole, 'pole_name')
    )
    setLoading(false)
    setSearchResult(false)
    setExpanded(false)
    setNotSearch(false)
    setCount(data?.count)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status
      })
    }

    if (IsEmpty(data?.data)) setSearchResult(true)

    return setClassList(data?.data)
  }

  const onClear = (handleClear) => {
    setClassList([])
    setFormValues(initialState)
    setNotSearch(true)
    handleClear()
  }

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      getSchoolClass(values)
    }
  })

  const { values } = formik

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.MSG_NOT_FOUND_SEARCH

  useEffect(() => {
    if (isSearchParam && formValues?.unit && !isEmpty(parseUnits(unitys))) {
      setIsActiveButton(true)
      return getSchoolClass(formValues)
    }

    return !isSearchParam && setFormValues(initialState) && setClassList([])
  }, [isSearchParam, !isEmpty(parseUnits(unitys))])

  useEffect(() => {
    if (isActiveButton) getSchoolClass(formValues)
  }, [page, filter])

  useEffect(() => {
    if (unitIdSelected)
      onUnitsRegions({ setSnack, setListPole, unitIdSelected })
  }, [unitIdSelected])

  useEffect(async () => {
    await Promise.all([
      handleShifts({ setSnack, setShifts }),
      handleLevels({ setSnack, setLevels }),
      handleModules({ setSnack, setModules })
    ])
  }, [])

  useEffect(async () => {
    const findUnit = unitys?.find(({ id }) => id === values?.unit)

    setUnitSelected(findUnit)
    setClassList([])
    setNotSearch(true)
  }, [values?.unit])

  return (
    <TemplateDefaultFormAndResult
      expanded={expanded}
      loadingOpen={loading}
      listItems={classList}
      snackProps={snackProps}
      setExpanded={setExpanded}
      nameToggle="Busca Avançada"
      isToggle={isSearchAdvanced}
      nameAccordion={nameAccordion}
      labelButtonHeader="Nova turma"
      titleHeader="Acadêmico - Turmas"
      setIsToggle={setIsSearchAdvanced}
      messageInformative={messageInformative}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      linkHeader={ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_CREATE}
      classNameHeader={TAG_MANAGER.secretary_bnt_cria_newScoolCLass}
      accordion={
        <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
          <Grid container spacing={{ xs: 2, sm: 2 }}>
            <FormClass
              {...formik}
              groups={group}
              levels={levels}
              period={shifts}
              poles={listPole}
              courses={courses}
              modules={modules}
              setSnack={setSnack}
              setCourses={setCourses}
              unitys={parseUnits(unitys)}
              onClickClose={() => onClear()}
              isSearchAdvanced={isSearchAdvanced}
              setUnitIdSelected={setUnitIdSelected}
            />

            <Styled.CardFooter>
              <ButtonFooterV2
                size="medium"
                typeReset="reset"
                labelClose="Limpar"
                labelConfirm="Buscar"
                startIcon={<Clear />}
                onClickConfirm={() => {
                  setIsActiveButton(true)
                  setPage(defaultPage)
                }}
                onClickClose={() => onClear(formik.handleClear)}
                disabled={isDisabledButton({ ...formik.values })}
                classNameConfirm={TAG_MANAGER.secretary_bnt_search_scoolCLass}
              />
            </Styled.CardFooter>
          </Grid>
        </form>
      }
    >
      <>
        {classList && !isEmpty(classList) && (
          <Table
            page={page}
            rows={items}
            count={count}
            filter={filter}
            setPage={setPage}
            unit={values?.unit}
            setSnack={setSnack}
            navigate={navigate}
            setIsOpen={setIsOpen}
            setFilter={setFilter}
            totalPage={totalPage}
            formValues={formValues}
            setLoading={setLoading}
            onSubmit={getSchoolClass}
            situation={values?.closed}
            unitSelected={unitSelected}
            unitys={parseUnits(unitys)}
            deleteSubmitClass={deleteSubmitClass}
          />
        )}
      </>
    </TemplateDefaultFormAndResult>
  )
}
export default ListSchoolClass
