import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)
const ChartGeneric = ({
  labels,
  values,
  pointStyle,
  LegendPosition,
  backgroundColor
}) => {
  const options = {
    plugins: {
      legend: {
        display: true,
        position: LegendPosition || 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: pointStyle || 'circle'
        }
      }
    }
  }

  const data = {
    labels,
    padding: 10,
    datasets: [
      {
        label: '',
        data: values || [],
        backgroundColor: backgroundColor || [
          '#A186CB',
          '#7A5CA8',
          '#593493',
          '#3E2466'
        ],
        borderWidth: 1
      }
    ]
  }

  return <Pie data={data} options={options} />
}
export default ChartGeneric
