import { TableBody, TableRow } from '@mui/material'
import { getDataFormKeys } from 'services/helpers'
import { useCurrentUser } from 'services/hooks'
import { useState } from 'react'
import StudentGrade from '../StudentGrade'
import { getFirstkeysActivities, initialValue } from '../constants'
import * as Styled from './style'
import { shouldLockActivities } from '../../helpers'

const TableHeader = ({
  row,
  labelId,
  index,
  activity,
  disableActions,
  handleScore,
  dropouts
}) => {
  const { disciplineId } = useCurrentUser()
  const [alertInfo, setAlertInfo] = useState(null)

  const shouldAlert = (activityDataId, rowRegister) =>
    alertInfo?.activityId === activityDataId &&
    alertInfo?.studentId === rowRegister

  const gradeFound = (rowScores, activityDataId) =>
    getDataFormKeys(
      getFirstkeysActivities,
      rowScores.filter(({ activity_id: activityId }) =>
        activityDataId.includes(activityId)
      )
    )

  return (
    <TableBody key={row.id}>
      <TableRow
        hover
        id={labelId}
        role="checkbox"
        tabIndex={-1}
        key={row.register}
      >
        <Styled.TableCell $fieldSize="5%">
          {index + initialValue}
        </Styled.TableCell>
        <Styled.TableCell $fieldSize="30%" $fiedlName>
          <Styled.TextStudent>
            {row.name}
            {row?.hasDropouts && (
              <Styled.TextDropouts>
                {row?.status ? `${row?.status}` : 'Ocorrência'}
              </Styled.TextDropouts>
            )}
          </Styled.TextStudent>
        </Styled.TableCell>
        <Styled.TableCell $fieldSize="10%">{row.register}</Styled.TableCell>

        {dropouts &&
          activity.map((activityData) => (
            <Styled.Scores
              data-alert={shouldAlert(activityData.id, row.register)}
              key={activityData.id}
              align="center"
            >
              <StudentGrade
                student={row}
                activityData={activityData}
                disciplineId={disciplineId}
                setAlertInfo={setAlertInfo}
                handleScore={handleScore}
                grade={
                  row.scores ? gradeFound(row.scores, activityData.id) : null
                }
                disableActions={
                  // shouldLockActivities(activityData) ||
                  disableActions
                }
              />
            </Styled.Scores>
          ))}
        {!dropouts && <Styled.TableCell $fieldSize="65%" />}
      </TableRow>
    </TableBody>
  )
}

export default TableHeader
