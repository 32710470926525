import { parserMinutesForHours } from 'services/helpers'

const parserObjforXLS = (data) =>
  data.map((obj) => ({
    coluna1: obj.professor_name,
    coluna2: obj.school_class_code,
    coluna3: obj.cpf,
    coluna4: parserMinutesForHours(obj.total_minutes),
    coluna5: obj.total_value
  }))

export { parserObjforXLS }
