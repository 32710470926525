import { useState, lazy, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useSnack } from 'services/hooks'
import { getListOccurrenceById } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import { useStore } from '../Store/useStore'
import { breadcrumbsItems } from './helpers'
import Toolbar from './Toolbar'

const Table = lazy(() => import('./Table'))

const UpdateOccurrence = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id, type } = params
  const occurrence = `[${id}] ${type} `
  const [occurrenceByID, setOccurrenceByID] = useState({})
  const { formValues } = useStore()
  const snackProps = useSnack()
  const { setSnack } = snackProps

  const onHandleConfirm = () => {
    navigate(ROUTE.ADMIN_SECRETARY_OCCURRENCES, {
      state: { savedSearch: true }
    })
  }

  const handleOccurrenceId = async () => {
    setSnack('', '')
    setLoading(true)
    const inactive = formValues?.inactive
    const { error, status, data } = await getListOccurrenceById(id, inactive)
    setLoading(false)
    if (!error) return setOccurrenceByID(data)

    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToViewOccurrence
    })
  }

  useEffect(() => {
    if (id) handleOccurrenceId()
  }, [id])

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems(occurrence)}
      onClickButton={() => onHandleConfirm()}
      content={
        <>
          <Toolbar params={params} occurrence={occurrenceByID} />

          <Table
            creationDate="Data da criação"
            date={occurrenceByID.occurrence_date}
            user={occurrenceByID.user}
            description={occurrenceByID.description}
            dateComplement={occurrenceByID.date_complement}
            userComplement={occurrenceByID.user_complement}
            complement={occurrenceByID.complement}
            hasComplement
          />

          {formValues?.inactive && (
            <Table
              creationDate="Data da inativação"
              date={occurrenceByID.delete_date}
              user={occurrenceByID.delete_user}
              description={occurrenceByID.reason}
            />
          )}
        </>
      }
    />
  )
}

export default UpdateOccurrence
