import buildObservations from 'components/templates/PDF/shared/Observation'
import buildResponsibleTeacher from 'components/templates/PDF/shared/ResponsibleTeacher'
import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { COMPANY } from 'services/constants/company'

const { borderLayoutDefault } = utilsPDF

const contentObservationByCompanyId = {
  [COMPANY.SP.id]: {
    stack: [
      {
        text: 'É considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, nota final igual ou superior a 6 (seis) pontos e frequência igual ou superior a 75% (setenta e cinco por cento). Até janeiro de 2023, é considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, a nota final igual ou superior a 5 (cinco) pontos em cada unidade curricular e frequência igual ou superior a 75% (setenta e cinco por cento). As notas precedidas de asterisco (*) estão abaixo do valor mínimo exigido para aprovação e de dois asteriscos (**) são proveniente de aproveitamento de estudos.'
      },
      { text: ' ' },
      {
        text: 'Os alunos marcados com asterisco (*) são provenientes de vínculo de turma.'
      }
    ]
  },
  [COMPANY.MG.id]: {
    stack: [
      {
        text: 'É considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, nota final igual ou superior a 6 (seis) pontos e frequência igual ou superior a 75% (setenta e  cinco por cento). As notas precedidas de asterisco (*) estão abaixo do valor mínimo exigido para aprovação e de dois asteriscos (**) são proveniente de aproveitamento de estudos.'
      },
      { text: ' ' },
      {
        text: 'Os alunos marcados com asterisco (*) são provenientes de vínculo de turma.'
      }
    ]
  }
}

export function buildTableFooter({
  totalWorkloadCompleted,
  unit,
  numberOfCompletedLessons,
  responsibleTeachers,
  companyId
}) {
  return {
    table: {
      body: [
        ...buildObservations(contentObservationByCompanyId[companyId]),
        ...buildResponsibleTeacher({
          responsibleTeachers,
          totalWorkloadCompleted,
          unit,
          numberOfCompletedLessons
        })
      ],
      widths: [240, 60, '*']
    },
    layout: {
      ...borderLayoutDefault,
      paddingBottom: () => 10,
      paddingLeft: () => 10,
      paddingRight: () => 10,
      paddingTop: () => 10
    }
  }
}
