import styled from 'styled-components'
import { Grid as MuiGrid, Typography } from '@mui/material'
import { Modal as MuiModal } from 'components/atoms'

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 770px;
  }
`

const Grid = styled(MuiGrid)`
  padding: 21px;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
`

export { Modal, Grid, Title, Box }
