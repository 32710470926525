import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { Form as FormikForm, Formik } from 'formik'
import { InputSwitch } from 'components/atoms'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import { typeMask } from 'services/helpers'
import { defaultValues } from './config'
import { typeSearchOptions, formModel, SEARCH_TYPE } from './constants'
import * as Styled from './style'

export default function SearchForm({
  handleSearch,
  setStatus,
  setEnrollmentList
}) {
  const isDisabled = (values) => !values?.searchType && !values?.fieldValue

  const { searchType, fieldValue, duplicated } = formModel

  const fieldValueMask = (values) => {
    const isCPF = values[searchType.key] === SEARCH_TYPE.CPF ? 'CPF' : ''
    const isEnrollment =
      values[searchType.key] === SEARCH_TYPE.ENROLLMENT ? 'ENROLLMENT' : ''

    return isCPF || isEnrollment
  }

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      onSubmit={(values, formikHelpers) =>
        handleSearch({ values, formikHelpers, hasSubmit: true })
      }
    >
      {({
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue,
        values,
        errors
      }) => (
        <FormikForm autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12} sm={12} md={3}>
              <Select
                label={searchType.label}
                onChange={(e) => setFieldValue(searchType.key, e.target.value)}
                optionsList={typeSearchOptions}
                value={values[searchType.key]}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label={fieldValue.getLabel(
                  values[searchType.key] || SEARCH_TYPE.NAME
                )}
                id={fieldValue.key}
                onChange={handleChange}
                value={typeMask(fieldValueMask(values), values[fieldValue.key])}
                error={errors?.[fieldValue.key]}
                helperText={errors?.[fieldValue.key]}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <Styled.WrapperButtons>
                <InputSwitch
                  checked={values[duplicated.key]}
                  label={duplicated.label}
                  onClick={() =>
                    setFieldValue(duplicated.key, !values[duplicated.key])
                  }
                />
                <ButtonFooterV2
                  disabledClose={isDisabled(values)}
                  disabledConfirm={isDisabled(values)}
                  labelClose="Limpar"
                  labelConfirm="Buscar"
                  onClickClose={() => {
                    setStatus({
                      empty: true,
                      notFound: false
                    })
                    setEnrollmentList([])
                    resetForm()
                  }}
                  size="large"
                  startIcon={<Clear />}
                />
              </Styled.WrapperButtons>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  )
}

SearchForm.propTypes = {
  handleSearch: PropTypes.func
}

SearchForm.defaultProps = {
  handleSearch: () => {}
}
