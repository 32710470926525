import * as yup from 'yup'

const requiredFieldText = (field) => `O campo ${field} é obrigatório`

const paymentPlanFormSchema = yup.object().shape({
  account: yup.string().required(requiredFieldText('Conta')),
  addition: yup.string().when('every', {
    is: (every) => !!every,
    then: yup.string().required(requiredFieldText('acréscimo')),
    otherwise: (schema) => schema.optional()
  }),
  cashDiscount: yup.string().optional(),
  checkedEAD: yup.bool().required(requiredFieldText('EAD')),
  checkedPercentage: yup.bool(),
  checkedReal: yup.bool().required(requiredFieldText('Real')),
  contract: yup.string().required(requiredFieldText('contrato')),
  course: yup.number().nullable(true).required(requiredFieldText('curso')),
  duration: yup.number().nullable(true).required(requiredFieldText('duração')),
  every: yup.string().optional().nullable(true),
  group: yup.string().required(requiredFieldText('grupo')),
  installments: yup.string().required(requiredFieldText('parcelas')),
  level: yup.string().required(requiredFieldText('nível')),
  module: yup.number().required(requiredFieldText('módulo')),
  registration: yup.string().optional(),
  shift: yup.string().required(requiredFieldText('turno')),
  typeOfPayment: yup.string().required(requiredFieldText('tipo de pagmento')),
  unity: yup.number().nullable(true).required(requiredFieldText('unidade')),
  valueInstallments: yup
    .string()
    .required(requiredFieldText('valor da parcela')),
  websiteRegistration: yup.bool()
  // workload: ''
})

export { paymentPlanFormSchema }
