const initialState = {
  course: '',
  ownership: '',
  mec: '',
  resolutionAuthorization: '',
  level: '',
  pole: '',
  technologicalAxis: '',
  practicalTime: '',
  theoreticalTime: '',
  minimumAge: '',
  complementaryActivity: '',
  internship: '',
  active: true,
  dontSumPracticalWorkload: false
}

const emptyValue = 0

const MESSAGE = {
  createSuccess: 'Curso foi criado com sucesso!',
  updateSuccess: 'Curso foi atualizado com sucesso!'
}

const INITIAL_TRANSFERLIST_RIGH = []

const typeEndpointPole = (unitId) => `type=pole&unit_id=${unitId}`

export {
  emptyValue,
  typeEndpointPole,
  initialState,
  INITIAL_TRANSFERLIST_RIGH,
  MESSAGE
}
