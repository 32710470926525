import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import * as Styled from './style'

const emptyValue = ''

const LinkTo = ({
  tooltipTitle,
  tooltipPlacement = 'top',
  linkTo,
  nameLink,
  className,
  target
}) => {
  const isExternal = linkTo.match(/^https?:\/\//)
  return (
    <Styled.LinkTo>
      <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
        {isExternal ? (
          <Styled.ExternalLink
            href={linkTo}
            className={className}
            target="_blank"
            rel="noreferrer"
          >
            {nameLink || emptyValue}
          </Styled.ExternalLink>
        ) : (
          <Link to={linkTo} className={className} target={target}>
            {nameLink || emptyValue}
          </Link>
        )}
      </Tooltip>
    </Styled.LinkTo>
  )
}

LinkTo.propTypes = {
  tooltipTitle: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  nameLink: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top'
  ])
}

export default LinkTo
