import React from 'react'
import { Box, TableContainer, Paper } from '@mui/material'
import { IsEmpty } from 'services/helpers'
import TableHeader from '../TableHeader'
import TableFooter from '../TableFooter'
import { numberSelected } from '../constants'
import * as Styled from './style'

const Table = ({
  dataSource,
  setContentCallBack,
  createLessonButton,
  selected,
  setSelected
}) => {
  const handleClick = (event, aula) => {
    const selectedIndex = selected.indexOf(aula)
    const isToRemoveItem = selectedIndex !== -1
    const getSelectedItems = () => {
      if (isToRemoveItem) {
        // eslint-disable-next-line no-unused-vars
        const { [selectedIndex]: itemRemoved, ...all } = selected
        return Object.values(all)
      }
      return [...selected, aula]
    }

    setSelected(getSelectedItems)
  }

  const isSelected = (aula) => selected.indexOf(aula) !== -1

  return (
    <>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Styled.Table aria-labelledby="tableTitle" size="medium">
            <TableHeader />
            {dataSource.map((row, index) => {
              const isItemSelected = isSelected(row.id)
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <TableFooter
                  row={row}
                  key={row.id}
                  labelId={labelId}
                  handleClick={handleClick}
                  isItemSelected={isItemSelected}
                  setContentCallBack={setContentCallBack}
                />
              )
            })}
          </Styled.Table>
        </TableContainer>
      </Paper>
      {!IsEmpty(dataSource) <= numberSelected && (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <p>Ainda não há aula registradas {createLessonButton}</p>
        </Box>
      )}
    </>
  )
}

export default Table
