import { getMoneyFormattedToBRL } from './mask'

export const getTotalSumOfListByKey = (list, keyName) => {
  const sumValue = list?.reduce(
    (accumulator, item) => accumulator + item[keyName],
    0
  )

  return getMoneyFormattedToBRL(sumValue)
}
