import EmailIcon from '@mui/icons-material/Email'
import { dateFormat } from 'services/helpers'
import { ModalSkeleton, TextGroup } from 'components/molecules'
import { Modal } from 'components/atoms'
import * as Styled from './style'

const ModalNotificationDescription = ({
  open,
  handleClose,
  descriptionNotification
}) => (
  <Modal open={open} onClose={handleClose}>
    <ModalSkeleton
      onClickClose={handleClose}
      onClickReadMessage={handleClose}
      valueButton="Fechar"
    >
      <div>
        <TextGroup
          title={descriptionNotification?.title}
          subtitle={dateFormat(
            descriptionNotification?.created_at,
            'DD/MM/YYYY - h:mm'
          )}
          icon={<EmailIcon color="primary" />}
        />
        <Styled.InformativeText
          variant="subtitle2"
          dangerouslySetInnerHTML={{
            __html: descriptionNotification?.content
          }}
        />
      </div>
    </ModalSkeleton>
  </Modal>
)
export default ModalNotificationDescription
