import styled from 'styled-components'
import { Delete, AddBox } from '@mui/icons-material'

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: 1px dashed ${({ theme }) => theme.palette.gray.bgLight};
  padding: 16px;
  width: 100%;

  & > div {
    margin-bottom: 16px;
  }
`

const ContainerField = styled.div`
  display: flex;
  width: 100%;
`

const BoxTooltip = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.textColors.error};
  }
`

const AddIcon = styled(AddBox)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
  }
`

export { DeleteIcon, AddIcon, ContainerField, Container, BoxTooltip }
