import getEnvAccess from './getEnvAccess'

function getManagementPermissions(permissions) {
  const hasPermission = getEnvAccess()
  const system = permissions?.system || {}
  const managementSubject = permissions?.discipline || {}
  const management = permissions?.management || {}
  const occurrence = permissions?.occurrence || {}
  const requirements = permissions?.requirement || {}
  const permissionsSystem = system?.unit
  const permissionsSystemPole = permissionsSystem?.pole
  const permissionsSubjects = managementSubject?.GET
  const permissionsSubjectsRegisterEadContent = managementSubject?.GET
  const permissionsSubjectsCreate = managementSubject?.POST
  const permissionsSubjectsEdit = managementSubject?.PUT
  const permissionsSubjectsPlanCreate = managementSubject?.teaching_plan?.GET
  const permissionsOccurrence = occurrence?.type?.GET
  const permissionsOccurrenceCreate = occurrence?.type?.POST
  const permissionsCourse = management?.course
  const permissionsCurriculum = management?.curriculum?.GET
  const permissionsCurriculumCreate = management?.curriculum?.POST
  const permissionsCurriculumEdit = management?.curriculum?.PUT
  const permissionsRequirements = requirements?.type?.GET
  const permissionsComplementaryActivityType =
    system?.complementary_activity_type?.GET
  const permissionsRequirementsCreate = requirements?.type?.POST

  const MANAGEMENT_PERMISSIONS = {
    MANAGEMENT: Boolean(permissionsSystem),
    MANAGEMENT_UNITS: Boolean(permissionsSystem),
    MANAGEMENT_UNIT_CREATE: Boolean(permissionsSystem),
    MANAGEMENT_UNIT_EDIT: Boolean(permissionsSystem),
    MANAGEMENT_POLE: Boolean(permissionsSystemPole),
    MANAGEMENT_POLE_CREATE: Boolean(permissionsSystemPole),
    MANAGEMENT_POLE_EDIT: Boolean(permissionsSystemPole),
    MANAGEMENT_SUBJECTS: Boolean(permissionsSubjects),
    MANAGEMENT_SUBJECTS_REGISTER_EAD_CONTENT: Boolean(
      permissionsSubjectsRegisterEadContent
    ),
    MANAGEMENT_SUBJECTS_CREATE: Boolean(permissionsSubjectsCreate),
    MANAGEMENT_SUBJECTS_EDIT: Boolean(permissionsSubjectsEdit),
    MANAGEMENT_SUBJECTS_PLAN_CREATE: Boolean(permissionsSubjectsPlanCreate),
    MANAGEMENT_OCCURRENCE: Boolean(permissionsOccurrence),
    MANAGEMENT_OCCURRENCE_CREATE: Boolean(permissionsOccurrenceCreate),
    MANAGEMENT_TYPE_REQUIREMENTS: Boolean(permissionsRequirements),
    MANAGEMENT_TYPE_ACTIVITY: Boolean(permissionsComplementaryActivityType),
    MANAGEMENT_TYPE_REQUIREMENTS_CREATE: Boolean(permissionsRequirementsCreate),
    MANAGEMENT_COURSE: Boolean(permissionsCourse),
    MANAGEMENT_COURSE_CREATE: Boolean(permissionsCourse),
    MANAGEMENT_COURSE_EDIT: Boolean(permissionsCourse),
    MANAGEMENT_LIST_CURRICULUM: Boolean(permissionsCurriculum),
    MANAGEMENT_CURRICULUM_CREATE: Boolean(permissionsCurriculumCreate),
    MANAGEMENT_CURRICULUM_EDIT: Boolean(permissionsCurriculumEdit)
  }

  return MANAGEMENT_PERMISSIONS
}

export default getManagementPermissions
