import { useState, useCallback, useEffect } from 'react'
import { getNotifications } from 'services/api/shared'
import { useUserStore } from 'store/GlobalContext'
import { Badge } from 'components/atoms'
import PopoverNotification from '../PopoverNotification'
import {
  defaultElementId,
  amountRead,
  limitDefault,
  notRead
} from './constants'
import * as Styled from './style'

const NotificationMenu = () => {
  const [notification, setNotification] = useState([])
  const [openNotification, setOpenNotification] = useState(null)

  const { id: idUser } = useUserStore()

  const open = Boolean(openNotification)
  const id = open ? defaultElementId : undefined

  const handleOpenModal = useCallback((event) => {
    setOpenNotification(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setOpenNotification(null)
  }, [])

  const handleListNotifications = useCallback(async () => {
    if (idUser) {
      const params = {
        status_notification: notRead,
        limit: limitDefault
      }

      const { data: response } = await getNotifications(params)
      setNotification(response?.data)
    }
  }, [idUser])

  useEffect(() => {
    handleListNotifications()
  }, [openNotification, idUser])

  const amountReadNotifications =
    notification && notification.filter((item) => item.readed === amountRead)

  return (
    <Styled.Toolbar>
      <Badge
        id={id}
        handleOpenModal={handleOpenModal}
        badgeContent={amountReadNotifications?.length}
      />

      <PopoverNotification
        id={id}
        open={open}
        anchorEl={openNotification}
        handleClose={handleClose}
        notification={notification}
      />
    </Styled.Toolbar>
  )
}

export default NotificationMenu
