import * as yup from 'yup'

const phoneRegExp = /^\(?([1-9]{2})\)?\s?9\d{4}-?\d{4}$/

const formSchema = (isBrazilian) =>
  yup.object().shape({
    nome: yup
      .string()
      .min(8, 'Nome inválido, deve ter 8 ou mais caracteres')
      .required('Campo obrigatório'),
    ...(isBrazilian && {
      rg: yup
        .string()
        .nullable()
        .max(11, 'RG não pode ter mais de 11 caracteres')
        .required('Campo obrigatório')
    }),
    nascimento: yup
      .date()
      .min(new Date(1900, 0, 1), 'A data deve ser posterior a 01/01/1900')
      .required('Campo obrigatório')
      .typeError('Data inválida'),
    email: yup
      .string()
      .nullable()
      .email('E-mail inválido.')
      .required('Campo obrigatório'),
    cep: yup.string().nullable().required('Campo obrigatório'),
    uf: yup.string().nullable().required('Campo obrigatório'),
    country: yup.string().nullable().required('Campo obrigatório'),
    cidade: yup.string().nullable().required('Campo obrigatório'),
    endereco: yup.string().nullable().required('Campo obrigatório'),
    numero: yup
      .number()
      .nullable()
      .typeError('Deve ser um número')
      .required('Campo obrigatório'),
    bairro: yup.string().nullable().required('Campo obrigatório'),
    civil: yup.string().nullable().required('Campo obrigatório'),
    mae: yup.string().nullable().required('Campo obrigatório'),
    sexo: yup.string().nullable().required('Campo obrigatório'),
    celular: yup
      .string()
      .matches(phoneRegExp, 'Número de celular inválido')
      .required('Campo obrigatório'),
    naturalidade: yup.string().nullable().required('Campo obrigatório'),
    nacionalidade: yup.string().nullable().required('Campo obrigatório'),
    contato: yup.string().nullable().required('Campo obrigatório'),
    naturalidade_cidade: yup.string().nullable().required('Campo obrigatório'),
    enrollmentOrigin: yup.string().required('Campo Obrigatório')
  })

export { formSchema }
