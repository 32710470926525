import { ROUTE } from 'services/constants'

const breadcrumbsItems = [
  {
    path: ROUTE.LIST_DECLARATIONS,
    label: 'Sistema'
  },
  {
    path: ROUTE.LIST_DECLARATIONS,
    label: 'Tipos de Declarações'
  },
  {
    path: ROUTE.CREATE_DECLARATIONS,
    label: '<b> Nova Declaração </b>'
  }
]

export { breadcrumbsItems }
