import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ListAlt, Search, WarningAmber } from '@mui/icons-material'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { ModalConfirm } from 'components/molecules'
import { List, ListSelectEnrollment, SearchForm } from './components'
import { DEFAULT_ITEMS_PER_PAGE, hasSelected, messages } from '../constants'
import { messagesModalBack } from '../FinallyUnify/constants'
import { searchEnrollment } from '../services'
import { useListUsersContext } from '../Context/listUsersContext'
import { formModel } from './components/SearchForm/constants'
import FinallyUnify from '../FinallyUnify'
import { handleChangeInitPage, isExistingEnrollment } from './helpers'
import * as Styled from './style'

const SearchUnifyEnrollment = () => {
  const navigate = useNavigate()
  const [listSelected, setListSelected] = useState([])
  const [filter, setFilter] = useState(DEFAULT_ITEMS_PER_PAGE)
  const [isOpen, setIsOpen] = useState({
    listSelectUsers: false,
    listSearch: true,
    DisplayAll: false
  })
  const [search, setSearch] = useState(null)
  const [pageFinally, setPageFinally] = useState({ state: false })
  const [status, setStatus] = useState({ empty: true, notFound: false })
  const [enrollmentList, setEnrollmentList] = useState([])
  const [count, setCount] = useState(null)
  const [enrollments, setEnrollments] = useState([])
  const [selectRegistrationMerge, setSelectRegistrationMerge] = useState()
  const [loading, setLoading] = useState()
  const [openModalBack, setOpenModalBack] = useState()
  const snackProps = useSnack()

  const {
    listUnifyUsers,
    setListUnifyUsers,
    removeUsersListUnifyById,
    resetListUnify
  } = useListUsersContext()

  const {
    page: pageActual,
    totalPage,
    setPage,
    handleSetPage
  } = usePaginationBasic(enrollmentList, filter, count)

  const noResultMessage = () => {
    if (status.empty) return messages.supplierEmpty.text
    if (status.notFound) return messages.supplierNotFound.text
    return null
  }

  const noResultIcon = () => {
    if (status.empty) return <ListAlt />
    if (status.notFound) return <Search />
    return null
  }

  const handleSearch = ({ values, formikHelpers, hasSubmit = false }) => {
    const selectedValue = hasSelected(values)
    const { key } = formModel.fieldValue

    let errorMessage = ''

    if (hasSubmit) {
      if (selectedValue.hasSelectedName && !selectedValue.hasMinCharacter) {
        errorMessage = 'Digite pelo menos 3 caracteres para realizar a busca'
      } else if (
        selectedValue.hasSelectedEnrollment &&
        !selectedValue.hasMinCharacterEnrollment
      ) {
        errorMessage = 'Mínimo 5 dígitos, apenas números'
      }
    }

    if (selectedValue.hasSelectedCPF && !selectedValue.hasMinCharacterCPF) {
      errorMessage = 'CPF incorreto ou incompleto'
    }

    formikHelpers?.setFieldError(key, errorMessage)
    if (errorMessage) {
      return
    }

    setSearch(values)
  }

  useEffect(() => {
    if (search !== null) {
      searchEnrollment({
        setSnack: snackProps.setSnack,
        setStatus,
        setEnrollmentList,
        filter,
        page: pageActual,
        values: search,
        setCount,
        setLoading,
        setSearch
      })
    }
  }, [search, pageActual, filter])

  useEffect(() => {
    isExistingEnrollment({ isOpen, enrollments, setListUnifyUsers })
  }, [isOpen, setListUnifyUsers])

  useEffect(() => {
    if (selectRegistrationMerge > 1) {
      setPageFinally({ state: true, selectUser: selectRegistrationMerge })
    }
  }, [selectRegistrationMerge])

  const hasResult = !!enrollmentList.length

  const renderListSearch = (
    <>
      <TemplateDefaultFormAndResult
        formHeader={
          <ListSelectEnrollment
            items={listUnifyUsers}
            setSelectRegistrationMerge={setSelectRegistrationMerge}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            removeUsersListUnifyById={removeUsersListUnifyById}
          />
        }
        snackProps={snackProps}
        labelButtonHeader="Voltar"
        onClickButton={() => setOpenModalBack(true)}
        titleHeader="<strong>Sistema -</strong> Unificar matrículas"
      />

      <ModalConfirm
        icon={<WarningAmber color="warning" fontSize="large" />}
        open={openModalBack}
        onClickNotConfirm={() => setOpenModalBack(false)}
        title={messagesModalBack.title}
        message={messagesModalBack.text}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickConfirm={() => {
          setOpenModalBack(false)
          resetListUnify()
          handleChangeInitPage({
            setIsOpen,
            setSelectRegistrationMerge,
            setPageFinally,
            setEnrollmentList,
            setStatus
          })
        }}
      />
    </>
  )

  const renderSearch = (
    <>
      <TemplateDefaultFormAndResult
        formHeader={
          <SearchForm
            handleSearch={handleSearch}
            setStatus={setStatus}
            setEnrollmentList={setEnrollmentList}
          />
        }
        iconInformative={noResultIcon()}
        listItems={enrollmentList}
        messageInformative={noResultMessage()}
        snackProps={snackProps}
        titleHeader="<strong>Sistema -</strong> Unificar matrículas"
        loadingOpen={loading}
      />

      {hasResult && (
        <List
          items={enrollmentList}
          setPage={setPage}
          totalPage={totalPage}
          page={pageActual}
          filter={filter}
          count={count}
          setIsOpen={setIsOpen}
          setListSelected={setListSelected}
          setFilter={setFilter}
          setEnrollments={setEnrollments}
          setListUnifyUsers={setListUnifyUsers}
          handleSetPage={handleSetPage}
        />
      )}
    </>
  )

  return (
    <Styled.Container>
      {pageFinally.state ? (
        <FinallyUnify
          navigate={navigate}
          selectUser={pageFinally.selectUser}
          handleChangeInitPage={() =>
            handleChangeInitPage({
              setIsOpen,
              setSelectRegistrationMerge,
              setPageFinally,
              setEnrollmentList,
              setStatus
            })
          }
        />
      ) : (
        <>
          {isOpen.listSearch && !isOpen.DisplayAll && renderSearch}

          {isOpen.listSelectUsers && !isOpen.DisplayAll && renderListSearch}

          {!isOpen.listSearch && !isOpen.listSelectUsers && isOpen.DisplayAll && (
            <>
              {renderListSearch}
              {renderSearch}
            </>
          )}
        </>
      )}
    </Styled.Container>
  )
}

export default SearchUnifyEnrollment
