import React, { lazy } from 'react'
import { Link } from 'react-router-dom'
import { Table, TableBody, TableContainer, Paper } from '@mui/material'
import { IsEmpty } from 'services/helpers'
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'

const TableHeader = lazy(() => import('./TableHeader'))
const TableFooter = lazy(() => import('./TableFooter'))

const DetailsFinancial = ({ listDetailFinancial }) => (
  <>
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHeader />
        <TableBody>
          {listDetailFinancial &&
            listDetailFinancial.map((row) => (
              <TableFooter key={row.id_titulo} row={row} />
            ))}
        </TableBody>
      </Table>

      {IsEmpty(listDetailFinancial) ? (
        <Styled.Link>
          <Styled.Typography>Não há pendências Financeira</Styled.Typography>
          <Link
            className={TAG_MANAGER.student_link_accesses_Financial_shortcut}
            to={`${ROUTE.STUDENT_FINANCIAL}`}
          >
            Ver mais
          </Link>
        </Styled.Link>
      ) : (
        <Styled.Link>
          <Link to={`${ROUTE.STUDENT_FINANCIAL}`}>Ver mais</Link>
        </Styled.Link>
      )}
    </TableContainer>
  </>
)

export default DetailsFinancial
