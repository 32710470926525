import { useState } from 'react'

const useCheckedSingleTable = (listSortTable, nameHeadCells) => {
  const [selected, setSelected] = useState(null)
  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked
      ? listSortTable?.map((n) => n[nameHeadCells])
      : null
    setSelected(newSelected)
  }
  const handleClick = (id) => {
    setSelected(id)
  }
  const isSelected = (name) => selected === name
  return {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected,
    setSelected
  }
}
export default useCheckedSingleTable
