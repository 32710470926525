const DASH_ID = 'dash'

const initialValues = {
  startDate: '',
  endDate: '',
  typeFile: ''
}

const optionTypeFile = [
  {
    value: 'pdf',
    label: 'PDF'
  }
]

export { optionTypeFile, DASH_ID, initialValues }
