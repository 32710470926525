export const defaultMessageTitle = 'Ocorreu um problema aqui.'
export const defaultMessageSubTitle =
  'Desculpe, mas houve um problema e não encontramos a página ou o conteúdo que está procurando.'
const noAccessPermission = 'Sem permissão de acesso.'
export const messageSubTitleCode =
  'Entre em contato com sua Secretaria caso isso seja um problema pra você.'
export const codeStatus = 401
const errorPath = '/error'

export { errorPath, noAccessPermission }
