import React, { useState } from 'react'
import { ModalUploadPhoto } from 'components/molecules'
import {
  maxSizePhoto,
  colorInfoRed,
  colorInfoBlack,
  sizeFiles
} from './constants'

const UploadPhoto = ({
  open,
  setOpen,
  setPhoto,
  classNameModal,
  classNameTrakingBtn,
  handleUpload
}) => {
  const [imgSrc, setImgSrc] = useState(null)
  const [sizePhoto, setSizePhoto] = useState(false)

  const handleClose = () => {
    setOpen(false)
    setImgSrc(null)
  }

  const colorInformation = sizePhoto ? colorInfoRed : colorInfoBlack

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > sizeFiles) {
      setImgSrc(null)
      const reader = new FileReader()
      const { size } = e.target.files[0]

      if (size < maxSizePhoto) {
        setSizePhoto(false)
      } else {
        setSizePhoto(true)
      }

      reader.addEventListener('load', () =>
        setImgSrc(reader.result.toString() || '')
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleConfirmUpload = async () => {
    if (!handleUpload) return

    const success = await handleUpload(imgSrc)
    if (!success) return

    setPhoto(imgSrc)
    handleClose()
  }

  return (
    <ModalUploadPhoto
      classNameModal={classNameModal}
      classNameTrakingBtn={classNameTrakingBtn}
      open={open}
      labelHeader="Enviar Imagem"
      handleClose={handleClose}
      labelContent="Clique para enviar uma imagem"
      imgSrc={imgSrc}
      onChange={onSelectFile}
      colorInformation={colorInformation}
      onClickConfirm={handleConfirmUpload}
      type="img"
    />
  )
}

export default UploadPhoto
