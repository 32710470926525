const INITIAL_DOCUMENTS = {
  personalDocuments: [],
  courseDocuments: [],
  declarationsRequirements: [],
  personalDocumentsHasPendencies: false
}

const INITIAL_DOCS_UPLOAD_PERSONAL = {
  id: 0,
  name: '',
  isRequiredDocument: false,
  typeId: 0
}

const STATUS_NOT_COURSE = 422

const MESSAGE_INFO =
  'Para acessar documentos você precisa estar vinculado a uma turma.<br/> Para mais informações, contate a secretaria da sua unidade.'

export {
  INITIAL_DOCUMENTS,
  INITIAL_DOCS_UPLOAD_PERSONAL,
  MESSAGE_INFO,
  STATUS_NOT_COURSE
}

export const ERROR_MESSAGE =
  'Erro ao carregar Documentos e solicitações, tente novamente...'
