import { lazy, useState } from 'react'
import { Form, Formik } from 'formik'
import { Grid, Tooltip, Typography } from '@mui/material'
import { Modal } from 'components/atoms'
import { ButtonFooterV2 } from 'components/molecules'
import { isEmpty } from 'services/helpers'
import * as Styled from './style'
import { disabledButtonSchedule, initialValues } from './contants'
import { createSchedules } from '../../../services'

const Table = lazy(() => import('./Table'))

const ModalClassSchedule = ({
  open,
  handleClose,
  listSelectListDisciplines,
  listSchedules,
  setSnack,
  setLoadingOpen,
  classId,
  setListSchedules
}) => {
  const [listDelete, setListDelete] = useState([])
  const margedInitalValues = !isEmpty(listSchedules)
    ? { classSchedule: listSchedules }
    : initialValues

  return (
    <Modal open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <Formik
        initialValues={margedInitalValues}
        onSubmit={(values) =>
          createSchedules({
            values,
            handleClose,
            setSnack,
            setLoadingOpen,
            classId,
            setListSchedules,
            listDelete,
            setListDelete
          })
        }
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Card>
              <Grid item xs={12}>
                <Styled.Title>Horários da Turma</Styled.Title>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">
                  Preencha os campos abaixo para definir os horários da turma:
                </Typography>
              </Grid>

              <Table
                listSelectListDisciplines={listSelectListDisciplines}
                rows={values}
                listSchedules={listSchedules}
                setFieldValue={setFieldValue}
                listDelete={listDelete}
                setListDelete={setListDelete}
                handleClose={handleClose}
                classId={classId}
                setSnack={setSnack}
                setLoadingOpen={setLoadingOpen}
                setListSchedules={setListSchedules}
              />

              <Styled.BoxIcon>
                <Tooltip title="Adicionar disciplina" placement="top" arrow>
                  <Styled.AddIcon
                    className="academico_btn_adiciona_horarios"
                    onClick={() =>
                      setFieldValue('classSchedule', [
                        ...values.classSchedule,
                        {
                          id: '',
                          startTime: '',
                          endTime: '',
                          monday: '',
                          tuesday: '',
                          wednesday: '',
                          thursday: '',
                          friday: '',
                          saturday: ''
                        }
                      ])
                    }
                  />
                </Tooltip>
              </Styled.BoxIcon>

              <Styled.CardFooter>
                <Styled.Box>
                  <ButtonFooterV2
                    labelClose="Cancelar"
                    labelConfirm="Confirmar"
                    size="medium"
                    classNameConfirm="academico_btn_confirma_horarios"
                    disabledConfirm={disabledButtonSchedule(values)}
                    onClickClose={() => {
                      setFieldValue(
                        'classSchedule',
                        margedInitalValues.classSchedule
                      )
                      handleClose()
                    }}
                  />
                </Styled.Box>
              </Styled.CardFooter>
            </Styled.Card>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalClassSchedule
