import styled from 'styled-components'

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-content: ${({ messageExists, messageCenter }) =>
    messageExists && !messageCenter ? 'flex-end' : 'center'};
`

export { ButtonGroup }
