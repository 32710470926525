import styled from 'styled-components'
import { TextField as MuiTextField } from '@mui/material'

const TextField = styled(MuiTextField)`
  &.MuiTextField-root {
    margin: ${(props) => props.$isMargin ?? '0 0 16px'};
  }
`

export { TextField }
