import theme from 'theme/designTokens'

const DOCUMENT_TITLE = 'Diário de Frequência'
const MAX_LESSONS_PER_PAGE = 20
const DOT_SVG = `<svg
width="6"
height="6"
viewBox="0 0 10 10"
xmlns="http://www.w3.org/2000/svg"
>
<circle cx="5" cy="5" r="2" fill="${theme.palette.primary.main}" />
</svg>`

const OBSERVATION_MINAS_OLD_GRADE =
  'É considerado APROVADO(A) o(a) aluno(a) que obtiver a média final igual ou superior a 60 (sessenta) pontos em cada disciplina e frequência igual ou superior a 75% (setenta e cinco por cento) do total da carga horária. As notas precedidas de asterisco (*) estão abaixo da média e de dois asteriscos (**) são proveniente de aproveitamento de estudos.'
const OBSERVATION_MINAS_NEW_GRADE =
  'É considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, notafinal igual ou superior a 6 (seis) pontos e frequência igual ou superior a 75% (setenta e  cinco por cento). As notas precedidas de asterisco (*) estão abaixo do valor mínimo exigido para aprovação e de dois asteriscos (**) são proveniente de aproveitamento de estudos.'
const OBSERVATION_OUTHER_COMPANIES =
  'É considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, nota final igual ou superior a 6 (seis) pontos e frequência igual ou superior a 75% (setenta e cinco por cento). Até janeiro de 2023, é considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, a nota final igual ou superior a 5 (cinco) pontos em cada unidade curricular e frequência igual ou superior a 75% (setenta e cinco por cento). As notas precedidas de asterisco (*) estão abaixo do valor mínimo exigido para aprovação e de dois asteriscos (**) são proveniente de aproveitamento de estudos.'
const MAX_SCORE_IN_NEW_GRADE = 10

export {
  DOCUMENT_TITLE,
  MAX_LESSONS_PER_PAGE,
  DOT_SVG,
  OBSERVATION_MINAS_OLD_GRADE,
  OBSERVATION_MINAS_NEW_GRADE,
  OBSERVATION_OUTHER_COMPANIES,
  MAX_SCORE_IN_NEW_GRADE
}
