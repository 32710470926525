import { toast } from 'react-toastify'
import { ERROR, INFO, SUCCESS, WARNING } from 'services/constants'

const toastNotification = ({ msg, type, timer }) =>
  toast(msg, {
    type,
    position: 'top-right',
    autoClose: timer || 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: type !== SUCCESS,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  })

export const toastNotificationSuccess = (msg, timer) =>
  toastNotification({ msg, type: SUCCESS, timer })

export const toastNotificationError = (msg, timer) =>
  toastNotification({ msg, type: ERROR, timer })

export const toastNotificationErrorServer = (msg, timer) =>
  toastNotification({ msg, type: WARNING, timer })

export const toastNotificationWarning = (msg, timer) =>
  toastNotification({ msg, type: WARNING, timer })

export const toastNotificationInfo = (msg, timer) =>
  toastNotification({ msg, type: INFO, timer })
