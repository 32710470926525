const parserOptions = (data) =>
  data?.reduce(
    (acc, item) => [
      ...acc,
      {
        label: item.name,
        value: item.name
      }
    ],
    []
  )

const parserOptionsCoordinator = (data) =>
  data?.reduce(
    (acc, item) => [
      ...acc,
      {
        label: item.coordinator_name,
        value: item.coordinator_id
      }
    ],
    []
  )

const parserOptionsSchoolClass = (data) =>
  data?.reduce(
    (acc, item) => [
      ...acc,
      {
        label: item.school_class_code,
        value: item.school_class_id
      }
    ],
    []
  )

export { parserOptions, parserOptionsCoordinator, parserOptionsSchoolClass }
