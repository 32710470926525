import { lazy, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { ListAlt, Search } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { usePaginationBasic } from 'services/hooks'
import { ROUTE } from 'services/constants'
import {
  IsEmpty,
  mergeInitialValues,
  toastNotificationSuccess
} from 'services/helpers'
import { MESSAGE, defaultItemsPerPage, nameAccordion } from './constants'
import { onSubmit } from './services'
import { usePaymentPlans } from './Context/FormContext'

const initialState = {
  unity: '',
  level: '',
  course: '',
  shift: '',
  inactiveCourses: false,
  ead: false
}

const Table = lazy(() => import('./components/Table'))
const FormPaymentPlans = lazy(() => import('./components/FormPaymentPlans'))

const ListPaymentPlans = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [valuesForm, setvaluesForm] = useState(null)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [apiResult, setApiResult] = useState({
    list: [],
    count: 0
  })

  const isSearchParam = location?.state?.savedSearch || false
  const isParamSavedPayment = location?.state?.savedPayment || false
  const isParamUpdatePayment = location?.state?.updatePayment || false
  const paramSnackOpen = isParamSavedPayment || isParamUpdatePayment
  const { formValues, setFormValues, resetFormValues } = usePaymentPlans()

  const {
    page: currentPage,
    totalPage,
    items,
    setPage,
    handleSetPage
  } = usePaginationBasic(apiResult.list, filter, apiResult.count)

  const onClear = () => {
    setApiResult({
      list: []
    })
    resetFormValues()
    setLoadingOpen(false)
    setNotSearch(true)
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  useEffect(() => {
    if (valuesForm?.unity) {
      onSubmit({
        values: valuesForm,
        setApiResult,
        setLoadingOpen,
        currentPage,
        filter,
        setvaluesForm,
        setFormValues,
        setExpanded,
        setNotSearch
      })
    }
  }, [currentPage, filter])

  useEffect(() => {
    if (isSearchParam && formValues?.unity) {
      return onSubmit({
        values: formValues,
        setApiResult,
        setLoadingOpen,
        currentPage,
        filter,
        setvaluesForm,
        setFormValues,
        setExpanded,
        setNotSearch
      })
    }

    return (
      !isSearchParam &&
      setApiResult({
        list: []
      }) &&
      setFormValues(initialState)
    )
  }, [isSearchParam])

  useEffect(() => {
    if (paramSnackOpen) toastNotificationSuccess(MESSAGE.SUCCESS, 1500)
  }, [paramSnackOpen])

  useEffect(() => {
    if (paramSnackOpen) {
      navigate(location?.pathname, {
        state: {
          updatePayment: undefined,
          savedPayment: undefined,
          savedSearch: true
        },
        replace: true
      })
    }
  }, [paramSnackOpen])

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  const messageInformative = notSearch
    ? MESSAGE.MAIN_MESSAGE
    : MESSAGE.NOT_FOUND_SEARCH

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (valuesForms) => {
      onSubmit({
        values: valuesForms,
        setApiResult,
        setLoadingOpen,
        currentPage,
        filter,
        setvaluesForm,
        setFormValues,
        setExpanded,
        setNotSearch
      })
    }
  })

  return (
    <TemplateDefaultFormAndResult
      titleHeader="Financeiro - Planos de pagamento"
      labelButtonHeader="novo plano"
      nameAccordion={nameAccordion}
      loadingOpen={loadingOpen}
      listItems={apiResult.list}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      linkHeader={ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN_CREATE}
      classNameHeader={TAG_MANAGER.financeiro_btn_inicia_novoPlano}
      expanded={expanded}
      setExpanded={setExpanded}
      accordion={
        <FormPaymentPlans
          onClear={onClear}
          values={values}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          isSearchParam={isSearchParam}
          setPage={setPage}
          setFilter={setFilter}
        />
      }
    >
      {apiResult.list && !IsEmpty(apiResult.list) && (
        <Table
          rowsItems={items}
          filter={filter}
          values={values}
          setFilter={setFilter}
          currentPage={currentPage}
          totalPage={totalPage}
          setPage={setPage}
          count={apiResult.count}
          setApiResult={setApiResult}
          setvaluesForm={setvaluesForm}
          setFormValues={setFormValues}
          setExpanded={setExpanded}
          setNotSearch={setNotSearch}
          setLoadingOpen={setLoadingOpen}
          handleSetPage={handleSetPage}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}
export default ListPaymentPlans
