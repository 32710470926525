import React from 'react'
import { Box } from '@mui/material'
import { IsEmpty } from 'services/helpers'

import TableToolbar from './TableToolbar'
import Table from './Table'

import { styles } from './style'

const ContentTable = ({
  dataSource,
  handleDeleteClass,
  setContentCallBack,
  selectedState,
  createLessonButton
}) => {
  const [selected, setSelected] = selectedState

  const handleSelectAllClick = (event) => {
    if (!event.target.checked) return setSelected([])

    const isToCleanSelected =
      selected.length && selected.length < dataSource.length

    if (isToCleanSelected) {
      return setSelected([])
    }
    const newSelecteds = dataSource.map(({ id }) => id)
    return setSelected(newSelecteds)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={styles.box}>
        {!IsEmpty(dataSource) && (
          <>
            <TableToolbar
              numSelected={selected.length}
              selected={selected}
              handleDeleteClass={handleDeleteClass}
              rowCount={dataSource.length}
              handleSelectAllClick={handleSelectAllClick}
            />
            <div>{createLessonButton}</div>
          </>
        )}
      </Box>

      <Table
        dataSource={dataSource}
        setContentCallBack={setContentCallBack}
        createLessonButton={createLessonButton}
        selected={selected}
        setSelected={setSelected}
      />
    </Box>
  )
}

export default ContentTable
