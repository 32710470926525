import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { Add, ListAlt } from '@mui/icons-material'
import { Select, TextField } from 'components/molecules'
import { TextNotFound } from 'components/atoms'
import { isEmpty } from 'services/helpers'
import * as Styled from './style'
import { MESSAGE } from '../../constants'
import { Table } from '../Table'
import { schema } from './schema'
import { createProfessorsSkills } from '../../services'
import { levelOptions } from './constants'

const FormAcademicEducation = ({
  formAcademicEducation,
  setSnack,
  setLoadingOpen,
  idProfessor,
  listAcademicEducation,
  setListAcademicEducation,
  setFormAcademicEducation,
  setFormPesonalData
}) => (
  <>
    <Formik
      initialValues={formAcademicEducation}
      onSubmit={(values) =>
        createProfessorsSkills({
          values,
          setSnack,
          setLoadingOpen,
          setFormAcademicEducation,
          idProfessor,
          setListAcademicEducation,
          setFormPesonalData
        })
      }
      validationSchema={schema}
      enableReinitialize
    >
      {({ values, setFieldValue, handleSubmit, touched, errors, dirty }) => (
        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12} sm={12} md={2}>
              <Select
                id="level"
                value={values?.level}
                label="Nível"
                optionsList={levelOptions}
                onChange={(e) => setFieldValue('level', e.target.value)}
                helperText={errors && touched.level && errors.level}
                error={Boolean(touched.level && errors.level)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                id="course_name"
                name="course_name"
                label="Curso"
                value={values?.course_name}
                onChange={(e) => setFieldValue('course_name', e.target.value)}
                helperText={errors && touched.course_name && errors.course_name}
                error={Boolean(touched.course_name && errors.course_name)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <TextField
                fullWidth
                id="institution_name"
                name="institution_name"
                label="Nome da Instituição"
                value={values?.institution_name}
                onChange={(e) =>
                  setFieldValue('institution_name', e.target.value)
                }
                helperText={
                  errors && touched.institution_name && errors.institution_name
                }
                error={Boolean(
                  touched.institution_name && errors.institution_name
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextField
                fullWidth
                id="conclusion_year"
                name="conclusion_year"
                label="Ano de Conclusão"
                value={values?.conclusion_year}
                onChange={(e) =>
                  setFieldValue('conclusion_year', e.target.value)
                }
                helperText={
                  errors && touched.conclusion_year && errors.conclusion_year
                }
                error={Boolean(
                  touched.conclusion_year && errors.conclusion_year
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={1}>
              <Styled.Button
                labelConfirm={<Add />}
                size="small"
                disabledConfirm={!dirty}
              />
            </Grid>
          </Styled.Grid>
        </Form>
      )}
    </Formik>

    {!isEmpty(listAcademicEducation) ? (
      <Table
        rows={listAcademicEducation}
        setSnack={setSnack}
        setLoadingOpen={setLoadingOpen}
        idProfessor={idProfessor}
        setListAcademicEducation={setListAcademicEducation}
        setFormPesonalData={setFormPesonalData}
      />
    ) : (
      <TextNotFound icon={<ListAlt />} text={MESSAGE.NOT_ACADEMIC_EDUCATION} />
    )}
  </>
)

export { FormAcademicEducation }
