import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Modal } from 'components/atoms'
import * as Styled from './styles'

const ModalDownload = ({ children, open, handleClose, icon, title }) => (
  <Modal open={open} onClose={handleClose}>
    <Grid container spacing={{ xs: 0, sm: 2 }}>
      <Styled.GridHeaderModal item xs={12} sm={12} md={12}>
        <Styled.TypographyHeaderModal variant="h5">
          {title}
        </Styled.TypographyHeaderModal>
        <Styled.IconClose onClick={() => handleClose()}>
          {icon}
        </Styled.IconClose>
      </Styled.GridHeaderModal>
      {children}
    </Grid>
  </Modal>
)

export default ModalDownload

ModalDownload.propTypes = {
  open: PropTypes.bool,
  icon: PropTypes.element,
  title: PropTypes.string,
  handleClose: PropTypes.func
}

ModalDownload.defaultProps = {
  open: false,
  title: ''
}
