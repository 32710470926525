import getAdminPermissions from './permissionsAdmin'
import getCommercialPermissions from './permissionsCommercial'
import getFinancialPermissions from './permissionsFinancial'
import getHumanResourcesPermissions from './permissionsHumanResources'
import getManagementPermissions from './permissionsManagement'
import getSecretaryPermissions from './permissionsSecretary'
import getSystemPermissions from './permissionsSystem'
import getPDFPermissions from './permissionsPDF'

const getAllPermissions = (permissions) => {
  const permissonsMap = {
    ...getAdminPermissions(),
    ...getFinancialPermissions(permissions),
    ...getSecretaryPermissions(permissions),
    ...getCommercialPermissions(permissions),
    ...getManagementPermissions(permissions),
    ...getSystemPermissions(permissions),
    ...getHumanResourcesPermissions(permissions),
    ...getPDFPermissions(permissions)
  }

  return permissonsMap
}

export default getAllPermissions
