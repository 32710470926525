const disabledSubmit = (valuesForm, pageEdit) =>
  pageEdit
    ? !(
        valuesForm.enrollmentStartDate &&
        valuesForm.enrollmentEndDate &&
        valuesForm.dueDate &&
        valuesForm.startPrevisionDate
      )
    : !(
        valuesForm.name &&
        valuesForm.enrollmentStartDate &&
        valuesForm.enrollmentEndDate &&
        valuesForm.dueDate &&
        valuesForm.startPrevisionDate
      )

export { disabledSubmit }
