/* eslint-disable react/no-array-index-key */
import {
  Checkbox,
  InputAdornment,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import { currencyFormat, currencyMaskWithoutPrefix } from 'services/helpers'
import * as Styled from './style'

const TableFooter = ({ row, labelId, handleClick, isItemSelected }) => (
  <TableRow hover key={row?.id} tabIndex={-1} role="checkbox">
    <Styled.StyledTableCell
      padding="checkbox"
      onClick={() => handleClick(row?.id)}
    >
      <Checkbox
        color="primary"
        checked={isItemSelected}
        inputProps={{
          'aria-labelledby': labelId
        }}
      />
    </Styled.StyledTableCell>
    <Styled.StyledTableCell $width={22}>
      <Styled.Box>
        {row?.discipline}
        <Styled.ContainerIcon className="boxIcons">
          {Boolean(row?.has_grouping) && (
            <Tooltip title="Em Agrupamento" placement="top" arrow>
              <Styled.BoxGrouping>
                <Typography variant="body1">A</Typography>
              </Styled.BoxGrouping>
            </Tooltip>
          )}
        </Styled.ContainerIcon>
      </Styled.Box>
    </Styled.StyledTableCell>
    <Styled.StyledTableCell $width={8}>{row?.module}º</Styled.StyledTableCell>
    <Styled.StyledTableCell $width={13}>
      {row?.workload_hours}h{row?.workload_minutes}min
    </Styled.StyledTableCell>
    <Styled.StyledTableCell>
      {row?.professors ? (
        <>
          {row?.professors
            ?.sort((a, b) => a.position - b.position)
            .map((professor, index) => (
              <div key={index}>
                <Styled.Text>
                  {professor.position}-{professor.label || professor.name}
                </Styled.Text>
              </div>
            ))}
        </>
      ) : (
        '-'
      )}
    </Styled.StyledTableCell>

    <Styled.StyledTableCell $width={12}>
      {row?.professors ? (
        <>
          {row?.professors
            ?.sort((a, b) => a.position - b.position)
            .map((professor, index) => (
              <div key={index}>
                <Styled.Text>
                  R$
                  <Styled.Text>
                    {currencyMaskWithoutPrefix(
                      currencyFormat(professor?.hourly_lesson_cost || '0')
                    )}
                  </Styled.Text>
                </Styled.Text>
              </div>
            ))}
        </>
      ) : (
        '-'
      )}
    </Styled.StyledTableCell>
  </TableRow>
)

export default TableFooter
