import { ERROR, SUCCESS } from 'services/constants'
import { importIntegration } from 'services/api/admin'
import { MESSAGE } from './constants'

const importFile = async ({ file, route, setSnack, setLoadingOpen }) => {
  setSnack('')
  setLoadingOpen(true)

  const { error } = await importIntegration({ route, file })

  setLoadingOpen(false)

  if (error) {
    const {
      message: {
        error: { error: serverError }
      }
    } = error
    return setSnack(serverError || MESSAGE.IMPORT_ERROR, ERROR)
  }

  return setSnack(MESSAGE.IMPORT_SUCCESS, SUCCESS)
}

export { importFile }
