import { ListAlt, Search } from '@mui/icons-material'

const defaultPage = 1
const defaultItemsPerPage = 10

const initialStateForm = {
  name: '',
  unity: '',
  endDate: '',
  startDate: '',
  inactive: false
}

const MESSAGE = {
  success_inactive: 'Campanha inativada com sucesso.',
  modal_message:
    'Deseja realmente inativar esta campanha? \n Após inativar não será possível reativá-la.',
  NO_SEARCH: {
    icon: <ListAlt />,
    text: '<strong>Encontre campanhas aqui</strong><br />Você pode filtrar por diferentes critérios ou clicar em buscar para visualizar todos os resultados.'
  },
  NOT_FOUND_SEARCH: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
  }
}

const nameAccordion = 'campaigns'

export {
  MESSAGE,
  defaultPage,
  nameAccordion,
  initialStateForm,
  defaultItemsPerPage
}
