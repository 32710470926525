import * as R from 'ramda'

const pathCheck = (data, object) => data && R.path(data, object)
const IsEmpty = (data) => data && R.isEmpty(data)
const getLenght = (data) => data && R.length(data)

const isIfElse = R.ifElse()
const isValidArray = (data) => R.is(Array, data)
const getDataFormKeys = (keys = [], data = {}) =>
  isValidArray(keys) && R.path([...keys], data)
const getDataFormKeysOR = (keys, defaultValue, data) =>
  isValidArray(keys) && R.pathOr(defaultValue, [...keys], data)

const isNonEmptyString = R.both(R.is(String), R.compose(R.not, R.isEmpty))

const areArraysOfObjectsEqual = (arr1, arr2) => {
  if (
    typeof arr1 !== 'object' ||
    arr1 === null ||
    typeof obj2 !== 'object' ||
    arr2 === null
  ) {
    return arr1 === arr2
  }

  const keys1 = Object.keys(arr1)
  const keys2 = Object.keys(arr2)

  if (keys1.length !== keys2.length) {
    return false
  }

  return keys1
    .map(
      (key) =>
        keys2.includes(key) && areArraysOfObjectsEqual(arr1[key], arr2[key])
    )
    .every(Boolean)
}

const clone = (data) => R.clone(data)

const isNil = (data) => R.isNil(data)

export {
  pathCheck,
  IsEmpty,
  isIfElse,
  isValidArray,
  getDataFormKeys,
  getDataFormKeysOR,
  getLenght,
  areArraysOfObjectsEqual,
  isNonEmptyString,
  clone,
  isNil
}
