import styled from 'styled-components'
import { Box as MuiBox } from '@mui/material'

const Box = styled(MuiBox)`
  &.MuiBox-root {
    margin-bottom: 20px;
  }
`

export { Box }
