import styled from 'styled-components'

const ToolbarArea = styled.div`
  .MuiToolbar-root {
    padding-left: 4px !important;
  }
  .delete-msg {
    color: white;
    background-color: ${({ theme }) => theme.palette.textColors.error};
    min-width: 178px;
    border-radius: 15px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
`

const Message = styled.p`
  background-color: ${({ theme }) => theme.palette.textColors.error};
  border-radius: 64px;
  color: white;
  font-size: 12px;
  padding: 4px 12px;
  margin-left: 4px;
`
export { ToolbarArea, Message }
