import { lazy } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowBack, NavigateNext } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import { TemplateBankBilling as Template } from 'components/templates/Admin'
import { HeaderPage } from 'components/organisms'
import { Snackbar } from 'components/molecules'
import { ROUTE } from 'services/constants'
import { useSnack } from 'services/hooks'
import { savedSearch } from './constants'
import { listBreadcrumbs, pathRoute } from './services'
import * as Styled from './style'

const Content = lazy(() => import('./components/Content'))

const BankBilling = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const {
    name,
    register,
    situation,
    codeBankBilling,
    isPage,
    disableDownloadData,
    inactive
  } = params
  const navigate = useNavigate()

  const { snack, setSnack, snackOpen } = useSnack()

  const isDisabledFields = disableDownloadData === 'true'
  const isDisabledInactive = inactive === 'true'
  const isDisabledSituation = situation === inactive ? inactive : false

  const PATH = pathRoute(register, name, situation, codeBankBilling)

  const breadcrumbs = listBreadcrumbs(codeBankBilling, isPage)

  const roles = {
    invoices: ROUTE.ADMIN_FINANCIAL_INVOICES,
    financialBreakdown: `${ROUTE.ADMIN_FINANCIAL_BREAKDOWN}?register=${register}&name=${name}`,
    billsReceive: ROUTE.ADMIN_FINANCIAL_BILLS_RECEIVE
  }

  return (
    <Template
      header={
        <HeaderPage
          separator={<NavigateNext size="small" />}
          breadcrumbsNotLink={breadcrumbs}
          classNameCallToAction={`goBack ${
            register
              ? TAG_MANAGER.financial_link_opens_student_sheet
              : TAG_MANAGER.finanncial_link_retorna_detailamento_financial
          }`}
          callToAction={() => {
            navigate(roles[isPage], {
              state: { savedSearch }
            })
          }}
          startIcon={<ArrowBack />}
          labelCallToAction="voltar"
          divider
        >
          <Styled.BoxLink>
            <Styled.Link to={PATH}>{`${
              codeBankBilling || register
            } - ${name}`}</Styled.Link>
          </Styled.BoxLink>
        </HeaderPage>
      }
      content={
        <Content
          numberBankBilling={codeBankBilling}
          isDisabledFields={isDisabledSituation || isDisabledFields}
          setSnack={setSnack}
          registerId={register}
          nameStudent={name}
          typePath={roles[isPage]}
          isDisabledInactive={isDisabledInactive}
          disableDownloadData={isDisabledFields}
        />
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default BankBilling
