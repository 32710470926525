import { useEffect, useState } from 'react'

import { getAddressByCep } from 'services/api/external'
import { getCityByState, getStates } from 'services/api/shared'

const useAddress = ({ address, setAddress }) => {
  const [listStates, setListStates] = useState([])
  const [listCitys, setListCitys] = useState([])
  const [isUpdated, setIsUpdated] = useState(false)

  useEffect(async () => {
    const listState = await getStates()
    setListStates(listState)
  }, [])

  useEffect(async () => {
    if (address?.state) {
      const cities = await getCityByState(address?.state)
      setListCitys(cities)
    }
  }, [address?.state])

  const toggleUpdate = () => {
    setIsUpdated((prev) => !prev)
  }

  const fetchAddress = async (cep) => {
    if (cep) {
      try {
        const response = await getAddressByCep(cep)

        const { bairro, logradouro, cidade, uf } = response

        setAddress({
          district: bairro,
          address: logradouro,
          city: cidade,
          state: uf
        })
        setIsUpdated(true)
      } catch (error) {
        console.error('Ocorreu um erro ao buscar o endereço:', error)
      }
    }
  }

  const handleChangeCep = ({
    event,
    setSearchByCep,
    setFieldValue,
    fieldNameZipCode
  }) => {
    const valueInput = event.target.value
    const valueInputOnlyNumber = valueInput.replace('-', '')
    const LENGTH_CEP_ONLY_NUMBER = 8

    if (valueInputOnlyNumber?.length === LENGTH_CEP_ONLY_NUMBER) {
      setSearchByCep(true)
    }

    setFieldValue(fieldNameZipCode ?? 'zipCode', valueInput)
  }

  const handleChangeState = ({
    event,
    setFieldValue,
    fieldNameState,
    fieldNameCity
  }) => {
    const option = event.target.value

    setAddress({ state: option })
    setFieldValue(fieldNameState ?? 'state', option)
    setFieldValue(fieldNameCity ?? 'city', '')
  }

  return {
    fetchAddress,
    listStates,
    listCitys,
    isUpdated,
    toggleUpdate,
    handleChangeCep,
    handleChangeState
  }
}

export default useAddress
