import { formatDateEnv } from 'services/helpers'

const getParams = ({ values, filter, pageActual }) => ({
  ...(values?.start_date && { start_date: formatDateEnv(values.start_date) }),
  ...(values?.end_date && { end_date: formatDateEnv(values.end_date) }),
  ...(values?.search_1 && { search_1: values.search_1 }),
  ...(values?.search_2 && { search_2: values.search_2 }),
  ...(values?.search_type && { search_type: values.search_type }),
  ...(values?.user && { user: values.user }),
  limit: filter,
  page: pageActual
})

export { getParams }
