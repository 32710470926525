const MESSAGE = {
  NO_SEARCH_PERFOMED:
    '<b>Encontre alunos registrados no sistema</b><br/>Selecione um critério e realize uma busca para encontrar alunos ou um aluno específico ',
  NOT_FOUND_SEARCH:
    'Não encontramos nenhum documento nos registros desta ficha',
  SUCCESS: 'Documento enviado com sucesso.'
}

const ERROR_MESSAGE = 'Erro ao carregar Documentos, tente novamente...'

const STATUS_NOT_PDF = 422

const SUCCESS = 'success'

const emptyValue = ''

export { MESSAGE, SUCCESS, emptyValue, ERROR_MESSAGE, STATUS_NOT_PDF }
