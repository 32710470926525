export const isAvailabeToSubmit = (values) => {
  const {
    course,
    isCnpj,
    account,
    documentDate,
    dueDate,
    installmentNumber,
    installmentsAmount,
    installmentValue,
    hasCostCenter,
    unit,
    costCenter,
    type,
    description,
    nature,
    amount
  } = values

  const enabled =
    (course || isCnpj) &&
    account &&
    documentDate &&
    dueDate &&
    installmentNumber &&
    installmentsAmount &&
    installmentValue &&
    hasCostCenter &&
    unit &&
    costCenter &&
    type &&
    description &&
    nature &&
    amount

  return enabled
}
