import { createContext, useState, useContext } from 'react'
import { isEmpty } from 'services/helpers'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { countDefault, defaultItemsPerPage } from '../constants'
import { getPayrolls } from '../services'

const initialState = {
  unity: '',
  situation: '',
  competence: '',
  sends: false,
  filter: defaultItemsPerPage,
  setFilter: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
  count: countDefault,
  setCount: () => {},
  listPayroll: [],
  setListPayroll: () => {},
  loadPayrolls: () => new Promise()
}

export const FormContext = createContext(initialState)
export const TimeTrakingProvider = ({ children }) => {
  const [formValues, setFormValues] = useState(initialState)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(countDefault)
  const [listPayroll, setListPayroll] = useState([])
  const { setLoading } = useLoading()

  const resetFormValues = () => {
    setFormValues({ ...initialState })
  }

  const loadPayrolls = async () => {
    setLoading(true)
    const { data } = await getPayrolls({
      filter,
      currentPage,
      values: formValues
    })

    if (isEmpty(data?.data)) {
      return setListPayroll([])
    }
    setCount(data?.count)
    setListPayroll(data?.data)
    return setLoading(false)
  }

  return (
    <FormContext.Provider
      value={{
        formValues,
        setFormValues,
        resetFormValues,
        setFilter,
        filter,
        setCurrentPage,
        currentPage,
        count,
        setCount,
        listPayroll,
        setListPayroll,
        loadPayrolls
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useTimeTrakingProvider = () => useContext(FormContext)
