import styled from 'styled-components'
import { Typography, Paper, TableContainer } from '@mui/material'

const StyledCaption = styled.div`
  display: flex;
  align-items: center;
`
const StyledCaptionTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 10px;
    font-size: 14px;
  }
`

const StyledFooter = styled.div`
  display: grid;
  grid-template-columns: 90px 90px;
  grid-gap: 24px;
  margin: 24px 0;
`

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    width: 100%;
  }
`

const StyledTableContainer = styled(TableContainer)`
  &.MuiTableContainer-root {
    zoom: 95%;
    margin-top: 24px;
    th {
      text-align: center;
    }
  }
`
export {
  StyledCaption,
  StyledCaptionTitle,
  StyledFooter,
  StyledPaper,
  StyledTableContainer
}
