import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { isEmpty } from 'services/helpers'
import { usePageType, useSnack } from 'services/hooks'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'

import { Form } from '../components'
import {
  breadcrumbItems,
  messageModalConfirm,
  titleModalConfirm
} from './constants'
import { createSupplier, searchSupplierById, updateSupplier } from '../services'

const CreateOrUpdateSupplier = () => {
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [payload, setPayload] = useState([])
  const [supplier, setSupplier] = useState({ phone: [] })

  const { isEdit, isNew } = usePageType()
  const snackProps = useSnack()
  const navigate = useNavigate()
  const location = useLocation()

  const handlePayload = (value) => {
    setPayload(value)
  }

  const idSupplier = location?.state?.supplier?.id

  const handleCreate = async (values) => {
    await createSupplier({
      setSnack: snackProps.setSnack,
      payload: values,
      navigate,
      setLoadingVisibility
    })
  }

  const handleUpdate = async (values) => {
    await updateSupplier({
      setSnack: snackProps.setSnack,
      payload: values,
      navigate,
      setLoadingVisibility
    })
  }

  const openModalConfirm = () => setOpenModal(true)

  useEffect(() => {
    if (!isEmpty(payload)) {
      if (isNew) handleCreate(payload)
      if (isEdit) handleUpdate(payload)
    }
  }, [payload])

  useEffect(() => {
    if (isEdit && idSupplier) {
      searchSupplierById({
        setSnack: snackProps.setSnack,
        idSupplier,
        setSupplier,
        setLoadingVisibility
      })
    }
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      breadcrumbsItems={[...breadcrumbItems(isEdit, supplier?.tradeName)]}
      classNameHeader="goBack"
      content={
        (!isEdit || supplier?.id) && (
          <Form
            loadingVisibility={loadingVisibility}
            setLoadingVisibility={setLoadingVisibility}
            handlePayload={handlePayload}
            isEdit={isEdit}
            formValues={supplier}
            openModalConfirm={openModalConfirm}
          />
        )
      }
      labelCallToAction="voltar"
      onClickButton={openModalConfirm}
      snackProps={snackProps}
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => navigate(-1)}
      titleModalConfirm={titleModalConfirm}
      messageModalConfirm={messageModalConfirm}
    />
  )
}

export default CreateOrUpdateSupplier
