import styled from 'styled-components'


const Switch = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

export { Switch }
