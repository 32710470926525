import FEEDBACK_SNACK from 'feedBackSnack'
import { SUCCESS } from 'services/constants'
import { isEmpty, updateErrorMessage } from 'services/helpers'
import {
  getCourseReportExportXLSX,
  getListProfessors,
  putActiveProfessor,
  sendProfessorEmail
} from 'services/api/admin'
import { MESSAGE } from './constants'
import { paramsOnSubmit, paramsProfessorCourseReport } from './helpers'

const onSubmit = async ({
  values,
  setSnack,
  setLoadingOpen,
  filter,
  pageActual,
  setFormValues,
  setCount,
  setNotSearch,
  setListProfessorTypes
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { data, error, status } = await getListProfessors(
    paramsOnSubmit({
      filter,
      pageActual,
      values
    })
  )

  setLoadingOpen(false)
  setFormValues(values)
  setCount(data?.count)

  if (!isEmpty(data?.data)) {
    setNotSearch(false)
  }

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListOccurrenciesTypes
    })
  }

  return setListProfessorTypes(data?.data)
}

const onSubmitEmail = async ({
  setSnack,
  setLoadingOpen,
  setOpenModalEmail,
  values,
  user
}) => {
  const payload = {
    subject: values?.subject,
    content: values?.content
  }
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = await sendProfessorEmail(user.id, payload)
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.updateErrorMessage
    })
  }

  setSnack(MESSAGE.SUCCESS_EMAIl, SUCCESS)

  return setOpenModalEmail(false)
}

const onSubmitActiveProfessor = async ({
  values,
  setLoadingOpen,
  filter,
  pageActual,
  setFormValues,
  setCount,
  setSnack,
  setNotSearch,
  setListProfessorTypes,
  user
}) => {
  setSnack('')
  setLoadingOpen(true)

  const params = user.blocked ? 'activate' : 'inactivate'

  const { error, status } = await putActiveProfessor(user.id, params)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.updateErrorMessage
    })
  }

  setSnack(
    user.blocked ? MESSAGE.SUCCESS_ATCTIVE : MESSAGE.SUCCESS_INATCTIVE,
    SUCCESS
  )

  return setTimeout(() => {
    onSubmit({
      values,
      setSnack,
      setLoadingOpen,
      filter,
      pageActual,
      setFormValues,
      setCount,
      setNotSearch,
      setListProfessorTypes
    })
  }, 1500)
}

const onSubmitReportExportXLSX = async ({
  setSnack,
  setLoading,
  values,
  id,
  isValidation,
  setModalDonwloadsFile,
  setDisciplineAndClass
}) => {
  setSnack('')
  setLoading(true)

  const { data, error, status } = await getCourseReportExportXLSX(
    paramsProfessorCourseReport({
      values,
      validation: isValidation
    }),
    id
  )
  setModalDonwloadsFile(true)
  setDisciplineAndClass(false)

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListOccurrenciesTypes
    })
  }

  setModalDonwloadsFile(true)
  setDisciplineAndClass(false)

  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
  })

  const url = window.URL.createObjectURL(blob)

  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = 'planilha'
  downloadLink.click()

  URL.revokeObjectURL(url)
  return downloadLink.remove()
}

export {
  onSubmit,
  onSubmitEmail,
  onSubmitActiveProfessor,
  onSubmitReportExportXLSX
}
