import { useState, Suspense, useEffect } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { getComparator, stableSort } from 'services/helpers/formatTable'
import { PaginationAdvanced } from 'components/molecules'
import { paginationFilter } from 'services/helpers'
import { useCheckedTable } from 'services/hooks'
import { TableHeaderDefault } from 'components/organisms'
import { headCellsPending, sortAsc, sortDesc } from './constants'
import TableFooter from './TableFooter'
import TableToolbar from './TableToolbar'
import { StyledPaper, StyledTableContainer } from './style'
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants'

const List = ({
  items,
  setPage,
  totalPage,
  page,
  filter,
  count,
  setIsOpen,
  setListSelected,
  setFilter,
  setEnrollments,
  handleSetPage
}) => {
  const [order, setOrder] = useState(sortAsc)
  const [orderBy, setOrderBy] = useState()

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected: selectedItem
  } = useCheckedTable(items, 'register')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }

  useEffect(() => {
    setListSelected(selectedItem)
    const filteredObjects = items.filter((obj) =>
      selectedItem.includes(obj.register)
    )
    setEnrollments(filteredObjects)
  }, [selectedItem])

  return (
    <Suspense>
      {selectedItem?.length >= 1 && (
        <TableToolbar
          setIsOpen={() =>
            setIsOpen({ listSelectUsers: true, DisplayAll: false })
          }
          amount={selectedItem?.length}
        />
      )}
      <StyledPaper>
        <StyledTableContainer>
          <MuiTable>
            <TableHeaderDefault
              checkbox
              order={order}
              orderBy={orderBy}
              headCells={headCellsPending}
              rowCount={items?.length}
              onRequestSort={handleRequestSort}
              numSelected={selectedItem?.length}
              handleSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.register)
                  const labelId = `enhanced-table-${row.register}`

                  return (
                    <TableFooter
                      row={row}
                      key={row.register}
                      labelId={labelId}
                      setIsOpen={() =>
                        setIsOpen({ listSelectUsers: true, DisplayAll: false })
                      }
                      selected={selectedItem}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                    />
                  )
                }
              )}
            </TableBody>
          </MuiTable>
        </StyledTableContainer>

        {count > DEFAULT_ITEMS_PER_PAGE && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={page}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setPage(1)
              setFilter(e.target.value)
            }}
          />
        )}
      </StyledPaper>
    </Suspense>
  )
}

export default List
