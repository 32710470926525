import { useContext, useEffect } from 'react'

import {
  getUserFromLocalStorage,
  setUserInLocalStorage
} from 'services/helpers'

import { nameSteps } from '../../constants'
import { userFinishTutorial } from './services'
import { TutorialContext, initialState } from '../TutorialContext'

export const useTutorialContext = () => {
  const user = getUserFromLocalStorage()
  const { values, setValues } = useContext(TutorialContext)

  useEffect(() => {
    if (user?.has_tutorial_pending) {
      setValues((prev) => ({
        ...prev,
        isPending: true,
        completedTutorial: true
      }))
    }
  }, [])

  const resetFormValues = () => {
    setValues({ ...initialState })
  }

  const handleStep = (newIndexStep) =>
    setValues((prev) => ({
      ...prev,
      currentStep: newIndexStep
    }))

  const finishTutorial = ({ userId }) =>
    userFinishTutorial({
      id: userId,
      callback: () => {
        setValues((prev) => ({
          ...prev,
          currentStep: 0,
          isPending: false
        }))
        setUserInLocalStorage({
          ...user,
          has_tutorial_pending: 0
        })
      }
    })

  const setViewedSecretary = () =>
    setValues((prev) => ({
      ...prev,
      hasViewedSecretary: true
    }))

  const inTutorial =
    values.isPending && Object.values(nameSteps).includes(values.currentStep)

  return {
    ...values,
    handleStep,
    inTutorial,
    finishTutorial,
    resetFormValues,
    setViewedSecretary
  }
}
