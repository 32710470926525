const defaultItemsPerPage = 10
const defaultPage = 1
const noDefinedValue = 0
const companyIdEnferminas = 85
const codeAccountEnferminas = 'B00EN'
const codeAccountEssa = 'B00ES'
const zeroMoney = '0,00'
const amount = 'VALOR'

export {
  defaultPage,
  defaultItemsPerPage,
  noDefinedValue,
  companyIdEnferminas,
  codeAccountEnferminas,
  codeAccountEssa,
  zeroMoney,
  amount
}
