const { ROUTE } = require('services/constants')

const breadcrumbsItems = ({ name }) => [
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: 'Alunos'
  },

  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_DECLARATION,
    label: `${name}`
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_DECLARATION,
    label: 'Declarações'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_CREATE_DECLARATION,
    label: '<b>Nova Declaração</b>'
  }
]

const MESSAGE = {
  NO_SEARCH: 'Não encontramos nenhum documento nos registros desta ficha.',
  SUCCESS: 'Declaração criada com sucesso.'
}
const SUCCESS = 'success'
const defaultItemsPerPage = 10
const defaultPage = 1
const countDefault = 0
const requirementEnferminas = 85
const requirementEssa = 362

const initialState = {
  matricula: '',
  reqturma: '',
  modelo: '',
  curso: '',
  turma: '',
  ano: '',
  titulo: '',
  horario_inicio: '',
  horario_fim: '',
  observacoes: '',
  timbrado: false
}

export {
  breadcrumbsItems,
  MESSAGE,
  SUCCESS,
  initialState,
  defaultItemsPerPage,
  defaultPage,
  countDefault,
  requirementEnferminas,
  requirementEssa
}
