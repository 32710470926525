const numberSelected = 0
const sortAsc = 'asc'
const defaultTotalPage = 1
const PENDING = 'pending'
const EFFECTIVE = 'effective'
const INACTIVE = 'inactive'
const emptyValue = '--'
const financialBreakdown = 'financialBreakdown'
const ALL = 'all'
const NO_SHOW = 'no-show'
const STUDENT_DROPOUT = 'student-dropout'
const NO_CLASS_TRAINING = 'no-class-training'
const LOCKING = 'locking'
const CURRICULUM_ANALYSIS = 'curriculum-analysis'
const TRANSFER_BETWEEN_COURSE_UNITS = 'transfer-between-course-units'
const TITLE_BALANCE = 'title-balance'
const NEGOTIATION_CANCELED = 'negotiation_canceled'
const NEGOTIATION_EXPIRED = 'negotiation_expired'
const NEGOTIATION_ACCEPTED = 'negotiation_accepted'
const defaultItemsPerPage = 10
const bankBillingUpdatedSucess = 'Título salvo'
const itemSelected = 1
const defaultAmount = 0
const defaultPercents = 100
const originalValue = 'originalValue'
const updatedValue = 'updatedValue'
const selectAllTypes = 'Todos'
const typeNegotiation = 'Negociação/Acordo'

const type = {
  situation: {
    pending: 'pending',
    effective: 'effective',
    inactive: 'inactive'
  },
  applyScholarship: 'apply-scholarship',
  installments: 'advance-installments',
  discount: 'add-discount',
  activate: 'activateTitle',
  inactivate: 'inactivateTitle',
  sendTicket: 'sendTicket',
  create: 'create',
  remove: 'remove',
  negotiation: 'negotiation',
  replaceBankSlips: 'replaceBankSlips',
  update: {
    sendTicket: 'Boleto(s) enviado(s) para o e-mail do responsável financeiro',
    errorSendTicket:
      'Erro ao enviar o(s) boleto(s), tente novamente mais tarde',
    titlesActivatedSuccessfully: 'Título(s) ativado(s) com sucesso',
    titlesSuccessfullyInactivated: 'Título(s) inativado(s) com sucesso',
    contractSuccessfullyLinked: 'Vínculo de Contrato realizado com sucesso.',
    contractErrorLink: 'Houve um erro ao salvar. Tente novamente.'
  },
  linkContract: 'linkContract'
}

export {
  numberSelected,
  sortAsc,
  defaultTotalPage,
  type,
  defaultItemsPerPage,
  PENDING,
  EFFECTIVE,
  INACTIVE,
  emptyValue,
  financialBreakdown,
  ALL,
  NO_SHOW,
  STUDENT_DROPOUT,
  NO_CLASS_TRAINING,
  LOCKING,
  CURRICULUM_ANALYSIS,
  TRANSFER_BETWEEN_COURSE_UNITS,
  TITLE_BALANCE,
  bankBillingUpdatedSucess,
  itemSelected,
  defaultAmount,
  defaultPercents,
  originalValue,
  updatedValue,
  selectAllTypes,
  NEGOTIATION_CANCELED,
  NEGOTIATION_EXPIRED,
  NEGOTIATION_ACCEPTED,
  typeNegotiation
}
