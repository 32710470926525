import { ROUTE } from 'services/constants'

const breadCrumbsItems = ({ name, code }) => [
  {
    path: '',
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: 'Professores'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: `${name}`
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR_CLASS_LINKED,
    label: '<b>Editar vínculo</b>'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR_CLASS_LINKED_DETAILS,
    label: `<b>Turma ${code}</b>`
  }
]

export { breadCrumbsItems }
