import { useState } from 'react'
import { Paper, Table, TableBody, TableContainer } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { TextNotFound } from 'components/atoms'
import { TableHeaderDefault } from 'components/organisms'
import { getComparator, stableSort } from 'services/helpers'
import { useLinkedClassDetailsEditContext } from '../../context'
import { headerData } from './contants'
import TableBodyRow from './TableBodyRow'

export default function LinkedClassDetailsEditTable() {
  const {
    disciplines,
    disciplinesThatCanBeCheked,
    handleSelectAllClick,
    selectedIds,
    showOnlyInactives
  } = useLinkedClassDetailsEditContext()

  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()

  const handleRequestSort = (_, property) => {
    const sortAsc = 'asc'
    const sortDesc = 'desc'

    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }

  return (
    <>
      {disciplines.length ? (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHeaderDefault
              headCells={headerData({ inactives: showOnlyInactives })}
              order={order}
              orderBy={orderBy}
              rowCount={disciplinesThatCanBeCheked?.length}
              onRequestSort={handleRequestSort}
              checkbox={!showOnlyInactives}
              handleSelectAllClick={handleSelectAllClick}
              numSelected={selectedIds.length}
            />
            <TableBody>
              {stableSort(disciplines, getComparator(order, orderBy)).map(
                (discipline) => (
                  <TableBodyRow discipline={discipline} key={discipline.id} />
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TextNotFound
          icon={<SearchIcon />}
          text="Não foram encontradas disciplinas vínculadas a este(a) professor(a)."
        />
      )}
    </>
  )
}
