import * as XLSX from 'xlsx'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  getDetailsHRManageReceipts,
  selectPayroll,
  sendManageReceipts
} from 'services/api/admin'
import {
  stringToArrayBuffer,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import { ROUTE } from 'services/constants'
import { parserObjforXLS } from './helpers'

const getDetailsManageReceipts = async ({
  params,
  setDetailsManageReceipts
}) => {
  const { data, error, status } = await getDetailsHRManageReceipts(
    params.payroll_id
  )

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListReceipts
    })
  }

  return setDetailsManageReceipts(data)
}

const sendReceipts = async ({ params, setLoadingOpen, navigate }) => {
  setLoadingOpen(true)

  const { error, status } = await sendManageReceipts(params.payroll_id)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListSendReceipts
    })
  }

  toastNotificationSuccess('Recibo enviado com sucesso!', 1500)

  return setTimeout(
    () =>
      navigate(ROUTE.ADMIN_HR_TIMEKEEPING, {
        state: { savedSearch: true }
      }),
    1500
  )
}

const getInfoHeaders = async ({ params, setInfoHeaders }) => {
  const { data, error, status } = await selectPayroll(params.payroll_id)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListHeadersReceipts
    })
  }

  return setInfoHeaders(data)
}

const handleExportXLS = ({ detailsManageReceipts, setLoadingOpen }) => {
  setLoadingOpen(true)

  const data = [
    {
      coluna1: 'Nome',
      coluna2: 'Turma',
      coluna3: 'CPF',
      coluna4: 'Total/Horas',
      coluna5: 'Valor Total'
    },
    ...parserObjforXLS(detailsManageReceipts)
  ]

  const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true })
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Planilha1')

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  const blob = new Blob([stringToArrayBuffer(wbout)], {
    type: 'application/octet-stream'
  })

  setLoadingOpen(false)

  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = 'tabela.xlsx'
  document.body.appendChild(link)
  link.click()

  URL.revokeObjectURL(url)
  link.remove()
}

export {
  getDetailsManageReceipts,
  getInfoHeaders,
  handleExportXLS,
  sendReceipts
}
