import styled, { css } from 'styled-components'
import { FormHelperText, FormControl } from '@mui/material'
import theme from 'theme/designTokens'

const FormText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    color: ${({ $isColor }) =>
      !$isColor ? theme.palette.textColors.error : theme.palette.gray.main};

    a {
      text-decoration: none;
      color: ${theme.palette.primary.main};
    }
  }
`
const Container = styled(FormControl)`
  &.MuiFormControl-root {
    margin: ${(props) => props.$isMargin};
    width: ${(props) => props.$width};

    @media (max-width: 769px) {
      width: ${(props) => props.$width && '100%'};
    }
    ${({ $error }) =>
      $error &&
      css`
        .MuiOutlinedInput-notchedOutline,
        .MuiFormLabel-root {
          color: #d32f2f !important;
          border-color: #d32f2f !important;
        }
      `}
  }
`

export { FormText, Container }
