import styled, { css } from 'styled-components'
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  RadioGroup as MuiRadioGroup
} from '@mui/material'

const Grid = styled(MuiGrid)`
  ${({ flex }) =>
    flex &&
    css`
      gap: 10px;
      display: flex;
      align-items: center;
    `}
  ${({ $small }) =>
    $small &&
    css`
      max-width: 125px !important;
    `}

  .MuiFormHelperText-root {
    margin-top: 2px !important;
    margin-bottom: -20px !important;
  }
`

const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    -webkit-flex-wrap: nowrap;
  }
`

const Switch = styled.div`
  margin-top: 4px;
  max-width: 125px;
`

const Typography = styled(MuiTypography)``

export { Grid, RadioGroup, Switch, Typography }
