import styled, { css } from 'styled-components'
import { Box } from '@mui/material'

const MainPdf = styled.div`
  width: 100%;
  padding: 20px;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
`

const Logo = styled.img`
  width: 202px;
  height: 50px;
  margin: 15px auto;
`

const Border = styled.div`
  border: 2px solid #000;
  display: flex;
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;

  ${({ hasDontBorderRight }) =>
    hasDontBorderRight &&
    css`
      border-right: none;
    `}

  ${({ hasDontBorderTop }) =>
    hasDontBorderTop &&
    css`
      border-top: none;
    `}
`

const Items = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Text = styled.h2`
  margin: 0;
`

const Title = styled(Text)`
  font-size: 25px;
`

const TextLogo = styled(Text)`
  text-align: center;
  width: 100%;
  height: 100%;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextBoxHtml = styled(Box)`
  p {
    margin: 0;
    text-transform: capitalize;
    color: ${({ theme }) => theme.palette.dark.medium};
  }
`

export {
  Container,
  Text,
  TextLogo,
  Title,
  Logo,
  MainPdf,
  Border,
  Items,
  TextBoxHtml
}
