const shiftList = [
  {
    key: 'morning',
    label: 'Manhã'
  },
  {
    key: 'afternoon',
    label: 'Tarde'
  },
  {
    key: 'night',
    label: 'Noite'
  }
]

export { shiftList }
