export const numberSelected = 0
export const indexDefault = -1
export const isSelected = 1
export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const defaultName = 'name'
export const typeAll = 'Tudo'
export const defaultTotalPage = 1

const headCellsPending = [
  {
    id: 'enrollment',
    label: 'Matrícula',
    disabled: true
  },
  {
    id: 'CPF',
    label: 'CPF',
    disabled: true
  },
  {
    id: 'name',
    label: 'Nome',
    disabled: false
  }
]

export { headCellsPending }
