import {
  httpGet,
  httpPut,
  httpPost,
  httpDelete,
  httpGetV2
} from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const saveGrade = async ({ payload }) => {
  const response = await httpPost(ENDPOINT.PROFESSOR_DIARY_SCORES, {
    payload
  })

  return response
}

export const updateGrade = async ({ id, payload }) => {
  const response = await httpPut(`${ENDPOINT.PROFESSOR_DIARY_SCORES}/${id}`, {
    payload
  })

  return response
}

export const getStudentGradesResume = async ({
  professorId,
  classId,
  disciplineId
}) => {
  const params = {
    school_class_id: classId,
    professor_id: professorId
  }

  const response = await httpGetV2(
    `${
      ENDPOINT.PROFESSOR_DIARY_SCORES
    }/resume?subject_ids[]=${disciplineId.join('&subject_ids[]=')}`,
    {
      params
    }
  )

  return response
}

export const getStudentsGradeActivity = async ({
  professorId,
  subgroup,
  stageId,
  classId,
  disciplineId
}) => {
  const params = {
    subgroup,
    stage_id: stageId,
    school_class_id: classId,
    professor_id: professorId
  }

  const response = await httpGet(
    `${ENDPOINT.PROFESSOR_DIARY_ACTIVITIES}/${
      ENDPOINT.PROFESSOR_DIARY_SCORES
    }?subject_ids[]=${disciplineId.join('&subject_ids[]=')}`,
    {
      params
    },
    process.env.REACT_APP_API_V2
  )
  return response
}

export const deleteActivity = async (activity) => {
  const response = await httpDelete(
    `${ENDPOINT.PROFESSOR_DIARY_ACTIVITIES}/?ids[]=${activity.join('&ids[]=')}`
  )
  return response
}

export const addActivity = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.PROFESSOR_DIARY_ACTIVITIES}`,
    {
      ...payload
    },
    {},
    process.env.REACT_APP_API_V2
  )
  return response
}

export const getAmountClasses = async (id) => {
  const response = await httpGet(`${ENDPOINT.PROFESSOR_SUBJECTS}/${id}`)
  return response
}

export const getActivityTypeGroupList = async (group) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSOR_DIARY_ACTIVITIES}/types?group=${group}`
  )
  return response
}

export const editActivity = async (payload, id) => {
  const response = await httpPut(
    `${ENDPOINT.PROFESSOR_DIARY_ACTIVITIES}?ids[]=${id.join('&ids[]=')}`,
    {
      ...payload
    },
    {},
    process.env.REACT_APP_API_V2
  )
  return response
}

export const addGrade = async (payload) => {
  const response = await httpPost(ENDPOINT.PROFESSOR_DIARY_SCORES, {
    ...payload
  })
  return response
}

export const editGrade = async (payload, id) => {
  const response = await httpPut(`${ENDPOINT.PROFESSOR_DIARY_SCORES}/${id}`, {
    ...payload
  })
  return response
}

export const getActivityStageList = async () => {
  const response = await httpGet('stages/?type=1')
  return response
}
