import styled from 'styled-components'
import {
  Chip,
  Typography,
  Toolbar as MuiToolbar,
  Paper as MuiPaper,
  TableContainer as MuiTableContainer
} from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import { Button as AtomButton } from 'components/atoms'

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
  }
`

const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    margin-top: 24px;
  }
`

const ColorInformation = styled(Chip)`
  &.MuiChip-root {
    max-width: 34px;
    max-height: 15px;
    border-radius: 0;
  }
`

const Caption = styled.div`
  display: flex;
  align-items: center;
`
const CaptionTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 10px;
    font-size: 14px;
  }
`

const Footer = styled.div`
  display: grid;
  grid-template-columns: 90px 90px;
  grid-gap: 24px;
  margin: 24px 0;
`

const Toolbar = styled(MuiToolbar)`
  &.MuiToolbar-root {
    padding: 0;
    margin-top: 24px;
    align-items: center;
    display: ${({ $displayNone }) => ($displayNone ? 'none' : 'flex')};
  }
`

const Button = styled(AtomButton)`
  &.MuiButtonBase-root {
    border: 1px solid;
    color: ${({ $isColor, theme }) =>
      $isColor ? theme.palette.red.main : theme.palette.yellow.warning};
    border-color: ${({ $isColor, theme }) =>
      $isColor ? theme.palette.red.main : theme.palette.yellow.warning};
    padding: 4px 8px;

    &:last-child {
      margin-left: 16px;
    }

    &:hover {
      color: ${({ $isColor, theme }) =>
        $isColor ? theme.palette.red.main : theme.palette.yellow.warning};
      border-color: ${({ $isColor, theme }) =>
        $isColor ? theme.palette.red.main : theme.palette.yellow.warning};
      background-color: transparent;
    }
  }
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export {
  ColorInformation,
  Caption,
  CaptionTitle,
  Footer,
  Toolbar,
  Button,
  Paper,
  TableContainer,
  IconWarningAmber
}
