import { CPF } from '../ListRequisition/constants'

const initialValues = {
  searchType: CPF,
  searchBy: '',
  student: '',
  type: '',
  initialSector: '',
  responsible: '',
  schoolClass: '',
  course: '',
  observation: '',
  attachment: ''
}

const MESSAGE = {
  SUCCESS: 'Requerimento criado com sucesso',
  EXISTING_REQUIREMENT:
    'Tem certeza que deseja criar um novo requerimento? Constatou-se que já foi criado um requerimento para o aluno informado:'
}

export { initialValues, MESSAGE }
