import { getIconBySituation } from './helpers'
import * as Styled from './style'

const DisplaySituation = ({ typeSituation, situation, feedbackMessage }) => {
  const IconBySituation = getIconBySituation[situation]

  return (
    <Styled.Container>
      <Styled.Card situation={situation}>
        <IconBySituation className="icon" />

        <Styled.Texts>
          <Styled.TypeSituation>{typeSituation}</Styled.TypeSituation>
          <Styled.Situation>{situation}</Styled.Situation>
        </Styled.Texts>
      </Styled.Card>

      <Styled.FeedbackMessage>{feedbackMessage}</Styled.FeedbackMessage>
    </Styled.Container>
  )
}

export default DisplaySituation
