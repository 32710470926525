import { lazy, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListAlt } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { TextNotFound } from 'components/atoms'
import { ButtonCallToAction } from 'components/molecules'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { isEmpty } from 'services/helpers'
import { useSnack } from 'services/hooks'
import { useStudentOccurrence } from '../StudentRegister/components/TabOccurrences/Store/useStudentOccurrence'
import {
  defaultItemsPerPage,
  initStudentHistory,
  MESSAGE,
  titleButtonNewHistory
} from './constants'
import { breadcrumbsItems } from './helpers'
import {
  deleteDocumentHistory,
  getStudentHistory,
  loadStudentInfo
} from './services'

const Table = lazy(() => import('./components/Table'))

const History = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])
  const { student, name } = params
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const { resetFormValues } = useStudentOccurrence()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [studentInfo, setStudentInfo] = useState({})
  const [isDeleteDocumentHistory, setIsDeleteDocumentHistory] = useState()
  const [studentHistory, setStudentHistory] = useState(initStudentHistory)
  const [notFoundSearch, setNotFoundSearch] = useState(true)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const PATH_STUDENT = `${ROUTE.ADMIN_SECRETARY_STUDENTS}/register/?register=${student}&name=${name}`

  const PATH_NEW_HISTORY = `${ROUTE.ADMIN_SECRETARY_STUDENTS_NEW_DOCUMENT_HISTORY}/?student=${student}&name=${name}`

  const paramnsStudentHistory = {
    setSnack,
    setLoading,
    setStudentHistory,
    setNotFoundSearch,
    register: student
  }

  const paramnsLoadStudent = {
    setSnack,
    setLoading,
    setStudentInfo,
    register: student
  }

  useEffect(
    () => loadStudentInfo(paramnsLoadStudent),

    [student]
  )

  useEffect(
    () => studentInfo && getStudentHistory(paramnsStudentHistory),
    [params.student, isDeleteDocumentHistory]
  )

  const handleDeleteDocumentHistory = (userId) => {
    deleteDocumentHistory({
      setSnack,
      setLoading,
      id: userId,
      setIsDeleteDocumentHistory
    })
    getStudentHistory(paramnsStudentHistory)
  }

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems(name)}
      onClickButton={() => navigate(PATH_STUDENT)}
      setLoading={setLoading}
      content={
        <>
          {studentHistory && !isEmpty(studentHistory) && !notFoundSearch ? (
            <Table
              rows={studentHistory}
              handleDeleteDocumentHistory={(userId) =>
                handleDeleteDocumentHistory(userId)
              }
              linkCallToAction={PATH_NEW_HISTORY}
            />
          ) : (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{ minHeight: '100vh' }}
            >
              <TextNotFound
                widthText="700px"
                icon={<ListAlt />}
                text={MESSAGE.NO_SEARCH}
              />
              <ButtonCallToAction
                width="200px"
                labelCallToAction={titleButtonNewHistory}
                linkCallToAction={PATH_NEW_HISTORY}
              />
            </Grid>
          )}

          {notFoundSearch && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
              style={{ minHeight: '100vh' }}
            >
              <TextNotFound
                widthText="700px"
                icon={<ListAlt />}
                text={MESSAGE.NO_SEARCH}
              />
              <ButtonCallToAction
                width="200px"
                labelCallToAction={titleButtonNewHistory}
                inkCallToAction={PATH_NEW_HISTORY}
              />
            </Grid>
          )}
        </>
      }
    />
  )
}

export default History
