const headCells = [
  {
    id: 'class',
    numeric: false,
    disabled: false,
    label: 'Turma'
  }
]

export { headCells }
