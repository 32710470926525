import { useState } from 'react'
import {
  TableCell,
  TableRow,
  Unstable_Grid2 as Grid,
  Typography,
  TextField,
  Checkbox,
  IconButton,
  Tooltip
} from '@mui/material'
import { RemoveCircleOutline } from '@mui/icons-material'
import GroupingIcon from 'components/atoms/Icons/GroupingIcon'
import { brazilianCurrencyFormat, convertDateToUTC } from 'services/helpers'
import ReplacementIcon from 'components/atoms/Icons/ReplacementIcon'
import { useLinkedClassDetailsEditContext } from '../../../context'

export default function TableBodyRow({ discipline }) {
  const {
    isSelected,
    handleClick,
    setFieldValue,
    linkedClassDetailsEditForm,
    inactivateDisciplinesLinked,
    handleOpenModalInactivate,
    showOnlyInactives
  } = useLinkedClassDetailsEditContext()
  const [mouseOver, setMouseOver] = useState(false)
  const hasGrouping = Boolean(discipline?.hasGrouping)
  const fontSizeCell = '14px'
  const emptyValye = '--'
  const isUnique = discipline.module === 'Único'
  const { isSubstitution } = discipline
  const disciplineForm = linkedClassDetailsEditForm.values.links.find(
    ({ id }) => id === discipline.id
  )
  const linkedClassDetailsEdit =
    linkedClassDetailsEditForm?.errors?.links?.[discipline.id]

  const sizeDisciplines = {
    1: { first: 12, second: 0 },
    2: { first: 9, second: 3 },
    3: { first: 7, second: 5 },
    4: { first: 7, second: 5 }
  }

  const getSizeDisciplines = () => {
    let size = 1
    if (isSubstitution) size += 1
    if (hasGrouping) size += 1
    if (mouseOver) size += 1
    return size
  }

  const shouldDisableRow = showOnlyInactives

  return (
    <TableRow
      hover
      onMouseEnter={() => !showOnlyInactives && setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {!shouldDisableRow && (
        <TableCell padding="checkbox">
          {!hasGrouping && (
            <Checkbox
              color="primary"
              checked={isSelected(discipline.id)}
              inputProps={{
                'aria-labelledby': discipline.id
              }}
              onChange={() => handleClick(discipline.id)}
              disabled={shouldDisableRow}
            />
          )}
        </TableCell>
      )}
      <TableCell>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid xs={sizeDisciplines[getSizeDisciplines()]?.first}>
            <Typography
              variant="body2"
              component="span"
              fontSize={fontSizeCell}
              color={shouldDisableRow ? (t) => t.palette.text.disabled : ''}
            >
              {discipline.disciplineName}
            </Typography>
          </Grid>

          <Grid xs={sizeDisciplines[getSizeDisciplines()]?.second}>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={0.5}
            >
              {hasGrouping && (
                <Grid xs={4}>
                  <GroupingIcon
                    tooltipTitle="Em Agrupamento"
                    size="16px"
                    fontSize="12px"
                  />
                </Grid>
              )}
              {isSubstitution && (
                <Grid xs={4}>
                  <ReplacementIcon
                    tooltipTitle="Em Substituição"
                    size="16px"
                    fontSize="12px"
                  />
                </Grid>
              )}
              <Grid
                xs={4}
                sx={{
                  visibility:
                    mouseOver && !shouldDisableRow ? 'visible' : 'hidden'
                }}
              >
                <Tooltip title="Inativar vínculo">
                  <IconButton
                    disabled={shouldDisableRow}
                    onClick={() => {
                      handleOpenModalInactivate(() =>
                        inactivateDisciplinesLinked({
                          disciplinesId: [discipline.disciplineId]
                        })
                      )
                    }}
                  >
                    <RemoveCircleOutline color="error" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          textAlign="center"
          color={shouldDisableRow ? (t) => t.palette.text.disabled : ''}
        >
          {discipline.module
            ? `${discipline.module}${isUnique ? '' : 'º'}`
            : emptyValye}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          textAlign="center"
          color={shouldDisableRow ? (t) => t.palette.text.disabled : ''}
        >
          {`${discipline.disciplineWorkload}h` ?? emptyValye}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '120px' }}>
        <TextField
          sx={{ '& .MuiInputBase-input': { fontSize: fontSizeCell } }}
          error={
            linkedClassDetailsEdit?.hourly_lesson_cost &&
            linkedClassDetailsEdit.hourly_lesson_cost
          }
          size="small"
          value={
            disciplineForm?.hourlyLessonCost ??
            brazilianCurrencyFormat(discipline.hourlyLessonCost?.toFixed(2))
          }
          onChange={(e) => {
            const valueFormatted = brazilianCurrencyFormat(e.target.value)
            setFieldValue(discipline.id, 'hourlyLessonCost', valueFormatted)
          }}
          disabled={shouldDisableRow}
        />
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          textAlign="center"
          color={shouldDisableRow ? (t) => t.palette.text.disabled : ''}
        >
          {convertDateToUTC(discipline.createdAt).toLocaleDateString('pt-BR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })}
        </Typography>
      </TableCell>
      {showOnlyInactives && (
        <>
          <TableCell>
            <Typography
              variant="body2"
              textAlign="center"
              color={shouldDisableRow ? (t) => t.palette.text.disabled : ''}
            >
              {convertDateToUTC(discipline.deletedAt).toLocaleDateString(
                'pt-BR',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                }
              )}
            </Typography>
          </TableCell>
          <TableCell>
            {discipline?.professors?.map((professor) => (
              <Typography
                variant="body2"
                textAlign="center"
                color={shouldDisableRow ? (t) => t.palette.text.disabled : ''}
              >
                {professor.name}
              </Typography>
            ))}
          </TableCell>
        </>
      )}
    </TableRow>
  )
}
