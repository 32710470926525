import { dateFormat } from 'services/helpers'

const formatInitialValuesResponsible = ({
  telephone,
  birthDate,
  ...restValues
}) => ({
  ...restValues,
  birth: dateFormat(birthDate),
  phone: (telephone ?? [])?.map((cell) => ({ id: Math.random(), cell }))
})

export { formatInitialValuesResponsible }
