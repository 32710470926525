import { useState } from 'react'

const useSnack = () => {
  const snackDefault = { message: '', severity: '' }
  const [snack, updateSnack] = useState(snackDefault)

  const setSnack = (message, severity = 'success') => {
    updateSnack({ message, severity })
  }

  return {
    snack,
    setSnack,
    updateSnack,
    snackOpen: !!snack.message,
  }
}

export default useSnack
