import { CIVIL_STATUS_TYPES, RACE_TYPES } from 'services/constants'

const TYPE_RESPONSIBLES = {
  STUDENT: 'student',
  PHYSICAL_PERSON: 'natural_person',
  LEGAL_PERSON: 'legal_person'
}

const listCivilStatus = CIVIL_STATUS_TYPES.map((race) => ({
  label: race,
  value: race
}))

const listRaces = RACE_TYPES.map((race) => ({ label: race, value: race }))

const MAX_ADD_CONTACT = 1

export { TYPE_RESPONSIBLES, listCivilStatus, listRaces, MAX_ADD_CONTACT }
