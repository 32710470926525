const MESSAGE = {
  NO_SEARCH: 'Não encontramos nenhum documento nos registros desta ficha.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.'
}
const SUCCESS = 'success'
const titleButtonNewHistory = 'Novo Histórico'

const defaultItemsPerPage = 10
const countDefault = 0
const defaultPage = 1
const emptyValue = ''
const documents = 'documents'
const registrationData = 'registrationData'
const requeriment = 'requeriment'
const PAGE_STUDENTS = 'Alunos'

const initStudentHistory = {
}

export {
  emptyValue,
  defaultPage,
  countDefault,
  defaultItemsPerPage,
  requeriment,
  documents,
  registrationData,
  MESSAGE,
  SUCCESS,
  PAGE_STUDENTS,
  initStudentHistory,
  titleButtonNewHistory
}
