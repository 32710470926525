/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Tooltip
} from '@mui/material'
import { TextField, Select, ButtonFooterV2 } from 'components/molecules'
import { DatePicker } from 'components/atoms'

import { typeMask } from 'services/helpers/mask'
import { useAddress } from 'services/hooks'
import { useFormik } from 'formik'
import { MAX_LENGTH_CPF } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import { mergeInitialValues, validateCPF } from 'services/helpers'
import { getCityByState, getStates } from 'services/api/shared'
import { searchCep } from '../../services'
import {
  cepValid,
  civilStatusList,
  listMetUs,
  MAX_LENGTH_CEP,
  MAX_LENGTH_PHONE,
  MAX_LENGTH_PHONE_MOBILE,
  MAX_LENGTH_RG
} from '../../constants'

import { allowedValues, initialValues as initialState } from './constants'
import { formSchema } from './schema'
import * as Styled from './style'

const FormPhysicalPersonData = ({
  isLoad,
  onClickBack,
  valuesAllForms,
  setValuesAllForms,
  handleConfirmRegister
}) => {
  const [citys, setCitys] = useState([])
  const [states, setStates] = useState([])
  const [selectedState, setSelectedState] = useState('')
  const [messageError, setMessageError] = useState('')
  const [isBrazilian, setIsBrazilian] = useState(true)
  const [isValidCpf, setIsValidCpf] = useState(false)
  const [hasBirthplace, setHasBirthplace] = useState(false)

  const [address, setAddress] = useState({
    district: '',
    address: '',
    city: '',
    state: ''
  })

  const initialValues = mergeInitialValues(initialState, valuesAllForms)

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = mergeInitialValues(valuesAllForms, values)

      setValuesAllForms({ ...valuesAllForms, ...values })

      handleConfirmRegister(payload)
    },
    validationSchema: formSchema(hasBirthplace, isBrazilian)
  })

  const handleValidateCpf = (cpf) => {
    const cpfIsValid = validateCPF(cpf)
    if (!cpfIsValid) {
      setMessageError('Campo obrigatório')
    }
    setIsValidCpf(cpfIsValid)
  }

  const allowedBirthplace = !allowedValues.includes(
    formik.values.nacio_fin.toLowerCase()
  )

  const { fetchAddress } = useAddress({ setAddress })

  useEffect(() => {
    setHasBirthplace(allowedBirthplace)
  }, [formik.values.nacio_fin])

  useEffect(async () => {
    if (address?.state) {
      const cities = await getCityByState(address?.state)
      setCitys(cities)
    }
  }, [address?.state])

  useEffect(() => {
    const cepFin = formik.values.cep_fin
    if (cepFin.length === cepValid) searchCep({ fetchAddress, cepFin })
  }, [formik.values.cep_fin])

  useEffect(async () => {
    if (!formik.values.cpf_fin) setIsValidCpf(false)

    const listState = await getStates(address?.state)
    setStates(listState)
  }, [])

  useEffect(() => {
    formik.setFieldValue('uf_fin', address?.state)
    formik.setFieldValue('cidade_fin', address?.city)
    formik.setFieldValue('end_fin', address?.address)
    formik.setFieldValue('bairro_fin', address?.district)
  }, [address])

  useEffect(() => {
    setIsBrazilian(allowedValues.includes(formik.values.nacio_fin))
  }, [formik.values.nacio_fin])

  return (
    <>
      <Styled.Form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
        <Styled.BoxHeader>
          <Styled.TextField
            label="CPF"
            type="text"
            name="cpf_fin"
            isMargin="8px 16px 8px 0"
            onBlur={formik.handleBlur}
            id="financialResponsible.cpf"
            inputProps={{ maxLength: MAX_LENGTH_CPF }}
            value={typeMask('CPF', formik.values.cpf_fin)}
            error={
              (formik.errors.cpf_fin && formik.touched.cpf_fin) ||
              (!isValidCpf && Boolean(messageError))
            }
            helperText={
              (formik.touched.cpf_fin && formik.errors.cpf_fin) || messageError
            }
            onChange={(e) => {
              setIsValidCpf(false)
              setMessageError('')
              const cpfMasked = typeMask('CPF', e.target.value)
              formik.setFieldValue('cpf_fin', cpfMasked)
            }}
          />
          <Styled.Button
            size="large"
            loading={isLoad}
            value="Continuar"
            onClick={() => handleValidateCpf(formik.values.cpf_fin)}
            $isError={formik.errors.cpf_fin && formik.touched.cpf_fin}
            className={TAG_MANAGER.commercial_btn_starts_inscricaoDadosAl0uno}
          />
        </Styled.BoxHeader>
        {isValidCpf && (
          <Grid
            container
            spacing={{ xs: 0, sm: 2 }}
            style={{ marginTop: '2%' }}
          >
            <Styled.Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="nome_fin"
                label="Nome Completo*"
                onBlur={formik.handleBlur}
                value={formik.values.nome_fin || null}
                onChange={formik.handleChange}
                error={formik.errors.nome_fin && formik.touched.nome_fin}
                helperText={formik.touched.nome_fin && formik.errors.nome_fin}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} sm={12} md={3} $notMargin>
              <Styled.FormControl
                error={formik.errors.sexo_fin && formik.touched.sexo_fin}
              >
                <Tooltip
                  title="O que consta no documento oficial"
                  placement="top-start"
                >
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Gênero*
                  </FormLabel>
                </Tooltip>

                <Styled.RadioGroup
                  row
                  name="sexo_fin"
                  onBlur={formik.handleBlur}
                  value={formik.values.sexo_fin}
                  onChange={formik.handleChange}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                >
                  <FormControlLabel
                    value="F"
                    label="Feminino"
                    control={<Radio size="small" />}
                    error={formik.errors.sexo_fin && formik.touched.sexo_fin}
                  />
                  <FormControlLabel
                    value="M"
                    label="Masculino"
                    control={<Radio size="small" />}
                    error={formik.errors.sexo_fin && formik.touched.sexo_fin}
                  />
                </Styled.RadioGroup>
                {formik.touched.sexo_fin && formik.errors.sexo_fin && (
                  <Styled.TextError>{formik.errors.sexo_fin}</Styled.TextError>
                )}
              </Styled.FormControl>
            </Styled.Grid>

            <Styled.Grid item xs={12} sm={12} md={3}>
              <Select
                id="civilStatus"
                name="civil_fin"
                label="Estado Civil*"
                isMargin="0 0 16px"
                onBlur={formik.handleBlur}
                labelId="select-civilStatus"
                optionsList={civilStatusList}
                value={formik.values.civil_fin}
                onChange={(e) => {
                  formik.setFieldValue('civil_fin', e.target.value)
                }}
                error={formik.errors.civil_fin && formik.touched.civil_fin}
                helperText={formik.touched.civil_fin && formik.errors.civil_fin}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                name="nome_social_fin"
                onChange={formik.handleChange}
                label="Nome Social (opcional)"
                value={formik.values.nome_social_fin}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} sm={12} md={!allowedBirthplace ? 3 : 6}>
              <TextField
                fullWidth
                name="nacio_fin"
                label="Nacionalidade*"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.nacio_fin}
                error={formik.errors.nacio_fin && formik.touched.nacio_fin}
                helperText={formik.touched.nacio_fin && formik.errors.nacio_fin}
              />
            </Styled.Grid>

            {!allowedBirthplace && (
              <Styled.Grid item xs={12} sm={12} md={3}>
                <Select
                  id="naturalnessUF"
                  isMargin="0 0 16px"
                  label="Naturalidade (UF)*"
                  name="naturalidade_uf_fin"
                  onBlur={formik.handleBlur}
                  optionsList={states}
                  labelId="select-naturalnessUF"
                  onChange={formik.handleChange}
                  value={formik.values.naturalidade_uf_fin}
                  error={
                    formik.errors.naturalidade_uf_fin &&
                    formik.touched.naturalidade_uf_fin
                  }
                  helperText={
                    formik.touched.naturalidade_uf_fin &&
                    formik.errors.naturalidade_uf_fin
                  }
                />
              </Styled.Grid>
            )}

            <Styled.Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Styled.Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label={`RG${isBrazilian ? '*' : ''}`}
                    name="rg_fin"
                    onBlur={formik.handleBlur}
                    value={formik.values.rg_fin}
                    onChange={formik.handleChange}
                    inputProps={{
                      maxLength: MAX_LENGTH_RG
                    }}
                    error={formik.errors.rg_fin && formik.touched.rg_fin}
                    helperText={formik.touched.rg_fin && formik.errors.rg_fin}
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} sm={12} md={6}>
                  <DatePicker
                    fullWidth
                    id="nasc_fin"
                    name="nasc_fin"
                    label="Nascimento*"
                    onBlur={formik.handleBlur}
                    value={formik.values.nasc_fin || null}
                    onChange={(newValue) => {
                      formik.setFieldValue('nasc_fin', newValue)
                    }}
                    error={formik.errors.nasc_fin && formik.touched.nasc_fin}
                    helperText={
                      formik.touched.nasc_fin && formik.errors.nasc_fin
                    }
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    type="email"
                    label="E-mail*"
                    name="email_fin"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email_fin}
                    error={formik.errors.email_fin && formik.touched.email_fin}
                    helperText={
                      formik.touched.email_fin && formik.errors.email_fin
                    }
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="CEP*"
                    name="cep_fin"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={typeMask('CEP', formik.values.cep_fin)}
                    error={formik.errors.cep_fin && formik.touched.cep_fin}
                    helperText={formik.touched.cep_fin && formik.errors.cep_fin}
                    inputProps={{
                      maxLength: MAX_LENGTH_CEP
                    }}
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} sm={12} md={6}>
                  <Select
                    id="state"
                    name="uf_fin"
                    label="Estado*"
                    labelId="select-state"
                    optionsList={states}
                    onBlur={formik.handleBlur}
                    value={formik.values.uf_fin}
                    error={formik.errors.uf_fin && formik.touched.uf_fin}
                    helperText={formik.touched.uf_fin && formik.errors.uf_fin}
                    onChange={(e) => {
                      setSelectedState(e.target.value)
                      formik.setFieldValue('uf_fin', e.target.value)
                    }}
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} sm={12} md={12}>
                  <Select
                    label="Cidade*"
                    id="cidade_fin"
                    name="cidade_fin"
                    labelId="select-city"
                    optionsList={citys}
                    isMargin="0 0 16px"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.cidade_fin}
                    disabled={formik.values.uf_fin === ''}
                    error={
                      formik.errors.cidade_fin && formik.touched.cidade_fin
                    }
                    helperText={
                      formik.touched.cidade_fin && formik.errors.cidade_fin
                    }
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    name="end_fin"
                    label="Endereço*"
                    onBlur={formik.handleBlur}
                    value={formik.values.end_fin}
                    onChange={formik.handleChange}
                    error={formik.errors.end_fin && formik.touched.end_fin}
                    helperText={formik.touched.end_fin && formik.errors.end_fin}
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    label="Número*"
                    name="numero_fin"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.numero_fin}
                    error={
                      formik.errors.numero_fin && formik.touched.numero_fin
                    }
                    helperText={
                      formik.touched.numero_fin && formik.errors.numero_fin
                    }
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    name="comp_fin"
                    label="Complemento (opcional)"
                    value={formik.values.comp_fin}
                    onChange={formik.handleChange}
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    label="Bairro*"
                    name="bairro_fin"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.bairro_fin}
                    error={
                      formik.errors.bairro_fin && formik.touched.bairro_fin
                    }
                    helperText={
                      formik.touched.bairro_fin && formik.errors.bairro_fin
                    }
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} sm={12} md={12}>
                  <Select
                    id="metUs"
                    isMargin="0 0 16px"
                    labelId="select-metUs"
                    optionsList={listMetUs}
                    value={formik.values.metUs_fin}
                    label="Como conheceu a Proz (opcional)"
                    onChange={(e) =>
                      formik.setFieldValue('metUs_fin', e.target.value)
                    }
                  />
                </Styled.Grid>
              </Grid>
            </Styled.Grid>

            <Styled.Grid item xs={12} sm={12} md={6} $notMargin>
              <Styled.Container>
                <TextField
                  fullWidth
                  label="Celular*"
                  isMargin="0 0 16px"
                  name="mobile_phone_fin"
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const celularMasked = typeMask('PHONE', e.target.value)
                    formik.setFieldValue('mobile_phone_fin', celularMasked)
                  }}
                  value={formik.values.mobile_phone_fin}
                  inputProps={{
                    maxLength: MAX_LENGTH_PHONE_MOBILE
                  }}
                  error={
                    formik.errors.mobile_phone_fin &&
                    formik.touched.mobile_phone_fin
                  }
                  helperText={
                    formik.touched.mobile_phone_fin &&
                    formik.errors.mobile_phone_fin
                  }
                />

                <TextField
                  fullWidth
                  name="tel_fin"
                  label="Telefone (opcional)"
                  onChange={formik.handleChange}
                  value={typeMask('TELEPHONE', formik.values.tel_fin)}
                  inputProps={{
                    maxLength: MAX_LENGTH_PHONE
                  }}
                  isMargin="0"
                />
              </Styled.Container>
            </Styled.Grid>

            <Styled.Grid item xs={12} sm={12} md={12}>
              <ButtonFooterV2
                size="medium"
                type="submit"
                labelClose="Voltar"
                labelConfirm="Finalizar"
                classNameConfirm={
                  TAG_MANAGER.commercial_btn_search_for_registrations
                }
                onClickClose={() => {
                  formik.resetForm({})
                  onClickBack()
                }}
              />
            </Styled.Grid>
          </Grid>
        )}
      </Styled.Form>
    </>
  )
}

export default FormPhysicalPersonData
