import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  firstPeriod: Yup.date()
    .nullable()
    .required('Período incial é obrigatório')
    .typeError('período Inválido'),
  endPeriod: Yup.date()
    .nullable()
    .required('Período final é obrigatório')
    .typeError('período Inválido')
    .when('firstPeriod', (firstPeriod, schema) =>
      schema.min(
        firstPeriod || new Date(0),
        'A período final não pode ser menor do que a período inicial'
      )
    )
})

export { validationSchema }
