import { Paper, Table, TableBody, TableCell } from '@mui/material'

import { paginationFilter } from 'services/helpers'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'
import { defaultlistPerPage } from 'services/hooks/constants'
import { useSortTable } from 'services/hooks'

import {
  SCHOOL_LEVEL,
  tableColumns,
  KEY_COLUMNS,
  emptyValue
} from './constants'

import * as Styled from './style'

const List = ({
  limit,
  page,
  items,
  count,
  totalPage,
  handleSetPage,
  handleChange,
  handleEdit
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    items,
    KEY_COLUMNS.NIVEL
  )

  return (
    <Styled.Wrapper>
      <Paper>
        <Table size="medium">
          <TableHeaderDefault
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableColumns}
          />
          <TableBody>
            {sortTable?.map((item, index) => {
              const labelId = `table-${index}`

              return (
                <Styled.TableRow key={labelId}>
                  <TableCell style={{ width: '20%' }}>
                    <Styled.Link onClick={() => handleEdit(item)}>
                      {SCHOOL_LEVEL[item?.nivel] ?? emptyValue}
                    </Styled.Link>
                  </TableCell>
                  <TableCell style={{ width: '5%' }}>
                    {item?.type ?? emptyValue}
                  </TableCell>
                  <TableCell style={{ width: '25%' }}>
                    {item?.institution ?? emptyValue}
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    {`${item?.city ?? emptyValue} ${
                      item?.state ? `(${item?.state})` : emptyValue
                    }`}
                  </TableCell>
                  <TableCell style={{ width: '7%' }}>
                    {item?.finishedYear ? 'Sim' : 'Não'}
                  </TableCell>
                  <TableCell style={{ width: '18%' }}>
                    <Styled.DoneYear>
                      {item?.finishedYear ?? emptyValue}
                    </Styled.DoneYear>
                  </TableCell>
                </Styled.TableRow>
              )
            })}
          </TableBody>
        </Table>

        {count > defaultlistPerPage && (
          <PaginationAdvanced
            isSelectItensPerPage
            handleChange={handleChange}
            handleSetPage={handleSetPage}
            count={totalPage}
            optionsList={paginationFilter}
            page={page}
            smallSize
            value={limit}
          />
        )}
      </Paper>
    </Styled.Wrapper>
  )
}

export default List
