import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { Grid as MuiGrid } from '@mui/material'
import { AddBox, Delete } from '@mui/icons-material'
import { Button } from 'components/atoms'
import { TextField as MolecTextField } from 'components/molecules'

const BoxPhone = styled.div`
  border: 2px ${({ theme }) => theme.palette.gray.borderLight} dashed;
  padding: 17px 12px 0 12px;
`

const IconAdd = styled(AddBox)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    fill: ${({ theme }) => theme.palette.primary.main};
    outline: none;
    border: 0px;
    margin: 17px 0 0 14px;
  }
`

const IconRemove = styled(Delete)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.red.light};
    outline: none;
    border: 0px;
    margin: 17px 0 0 14px;
  }
`

const TextField = styled(MolecTextField)`
  &.MuiFormControl-root {
    margin: 0 0 20px 0;
  }
`

const GroupButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ButtonSubmit = styled(Button)`
  &.MuiButton-root {
    margin: 10px 0 50px 13px;
  }
`

const ButtonCancel = styled(Button)`
  &.MuiButton-root {
    margin: 10px 0 50px 35px;
  }
`

const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    justify-content: right;
    align-items: center;
  }
`

const ButtonPole = styled(Button)`
  &.MuiButton-root {
    margin: 0;
  }
`

const Link = styled(RouterLink)`
  text-decoration: none;
`

export {
  Grid,
  Link,
  BoxPhone,
  ButtonPole,
  IconAdd,
  IconRemove,
  TextField,
  GroupButtons,
  ButtonSubmit,
  ButtonCancel
}
