import styled from 'styled-components'
import {
  CardContent as MuiCardContent,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
  Chip as MuiChip,
  Avatar as MuiAvatar
} from '@mui/material'
import {
  getColorTypography,
  getStatusColorCard,
  getStatusColorAprove
} from './statusColorCard'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const Link = styled.a`
  &:link,
  :active,
  :visited,
  :hover,
  :active {
    text-decoration: none;
  }
`

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`
const CardTeacher = styled.div`
  display: flex;
  align-items: center;
`

const CardContent = styled(MuiCardContent)`
  min-height: 166px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ $backgroundColor }) =>
    getStatusColorCard($backgroundColor)};

  &.MuiCardContent-root:last-child {
    padding: 16px;
  }
`
const IconButton = styled(MuiIconButton)`
  padding: 0;
`

const CardInfo = styled.div`
  margin-left: 16px;
`

const Title = styled(MuiTypography)`
  &.MuiTypography-root {
    color: ${({ $color }) => getColorTypography($color)};
    font-weight: bold;
  }
`

const Chip = styled(MuiChip)`
  &.MuiChip-root {
    margin: 8px 0;
    border-color: none;
    background-color: ${({ theme }) => theme.palette.red.light};
  }
`

const Avatar = styled(MuiAvatar)`
  &.MuiAvatar-root {
    background-color: ${({ $avatarColor }) =>
      getStatusColorAprove($avatarColor)};
    font-size: 12px;
    color: ${({ theme }) => theme.palette.white};
  }
`
const Subtitle = styled(MuiTypography)`
  &.MuiTypography-root {
    color: ${({ $color }) => getColorTypography($color)};
  }
`

export {
  Container,
  CardFooter,
  CardTeacher,
  CardContent,
  Link,
  IconButton,
  CardInfo,
  Title,
  Chip,
  Avatar,
  Subtitle
}
