import { ROUTE } from 'services/constants'
import { capitalizeWords, convertToParams } from 'services/helpers'

const breadcrumbsItems = (occurrence, hasPage, name) => [
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: hasPage
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES_EDIT,
    label: capitalizeWords(name)
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES_EDIT,
    label: 'Ocorrência'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES_EDIT,
    label: `<b>${occurrence}</b>`
  }
]

const paramsUrl = ({ name, register, tab, cpf, hasPage }) =>
  convertToParams({
    name,
    register,
    tab,
    cpf,
    hasPage
  })

export { breadcrumbsItems, paramsUrl }
