import { lazy } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowBack, NavigateNext } from '@mui/icons-material'
import { TemplateListUnits as Template } from 'components/templates/Admin'
import { HeaderPage } from 'components/organisms'
import { ROUTE } from 'services/constants'
import { useSnack } from 'services/hooks'
import { Snackbar } from 'components/molecules'
import { listBreadcrumbs } from './helpers'

const Content = lazy(() => import('./components/Content'))

const ListPole = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const unitCityName = params?.unit
  const unitId = params?.unitId

  const breadcrumbs = listBreadcrumbs(unitCityName)
  const { snack, setSnack, snackOpen } = useSnack()

  return (
    <Template
      titleHeader={
        <HeaderPage
          separator={<NavigateNext size="small" />}
          breadcrumbsNotLink={breadcrumbs}
          classNameCallToAction="goBack"
          callToAction={() => {
            navigate(ROUTE.ADMIN_MANAGEMENT_UNITS_POLE, {
              state: { savedSearch: true }
            })
          }}
          startIcon={<ArrowBack />}
          labelCallToAction="voltar"
          divider
        />
      }
      content={
        <Content
          setSnack={setSnack}
          unitId={unitId}
          unitCityName={unitCityName}
        />
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default ListPole
