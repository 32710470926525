import { Grid, Typography } from '@mui/material'
import { nullValue } from '../constants'
import * as Styled from './style'

const Toolbar = ({ params, occurrence }) => (
  <Grid container spacing={{ xs: 0, sm: 3 }}>
    <Grid item xs={12} sm={12} md={12}>
      <Styled.Container>
        <Typography variant="h4">{params.name}</Typography>
        <Styled.BoxGroup>
          {params.classroom !== nullValue && (
            <Styled.Box>
              <Typography variant="h5">Turma</Typography>
              <Typography variant="subtitle1">{params.classroom}</Typography>
            </Styled.Box>
          )}
          {params.course !== nullValue && params.course !== undefined && (
            <Styled.Box>
              <Typography variant="h5">Curso</Typography>
              <Typography variant="subtitle1">
                {params.course} ({occurrence.course_id})
              </Typography>
            </Styled.Box>
          )}
        </Styled.BoxGroup>
        {params.type !== nullValue && params.type !== undefined && (
          <Styled.Box>
            <Typography variant="h5">Tipo de ocorrência</Typography>
            <Typography variant="subtitle1">{params.type}</Typography>
          </Styled.Box>
        )}
      </Styled.Container>
    </Grid>
  </Grid>
)

export default Toolbar
