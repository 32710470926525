import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'
import { Modal as MuiModal } from 'components/atoms'

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 820px;
  }
`

const Grid = styled(MuiGrid)`
  padding: 25px;
`

const Box = styled.div`
  margin-top: 20px;
`

export { Modal, Grid, Box }
