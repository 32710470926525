const maxSizePhoto = 5000000
const allowedExtensions = ['image/jpg', 'image/jpeg', 'image/png']

const MESSAGE = {
  SUCCESS:
    'Clique aqui para selecionar o arquivo. Somente arquivos .jpg, .jpeg ou .png',
  ERROR:
    'Arquivo é inválido. <br /> Os arquivos devem respeitar o formato do template e devem ser .jpg, .jpeg ou .png e ter até 5mb de tamanho'
}

export { MESSAGE, maxSizePhoto, allowedExtensions }
