import { Feed, QueryStats } from '@mui/icons-material'

const initialValues = {
  curriculum: '',
  classDuration: '',
  startDate: '',
  endDate: '',
  resolution: '',
  observation: ''
}

const maxLessons = 10

const lessonQtyList = Array.from({ length: maxLessons }, (_, index) => ({
  value: (index + 1).toString(),
  label: (index + 1).toString()
}))

const getMenuItems = (setTypeModalPole, setOpen) => [
  {
    label: 'Dashboard',
    icon: <QueryStats />,
    className: 'academico_btn_acessa_dashboard',
    onClick: () => {
      setOpen(true)
      setTypeModalPole('dash')
    }
  },
  {
    label: 'Relatório de Presença',
    icon: <Feed />,
    className: 'academico_btn_acessa_relatorioPresenca',
    onClick: () => {
      setOpen(true)
      setTypeModalPole('attendanceReport')
    }
  }
]

export { initialValues, getMenuItems, lessonQtyList }
