const defaultItemsPerPage = 10
const countDefault = 0
const defaultPage = 1
const lengthItem = 1

const MESSAGE = {
  NO_SEARCH:
    'Preencha os critérios e realize uma busca para encontrar as folhas de pagamento cadastradas.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca. Verifique os dados informados e tente novamente.',
  SUCCESS_INATCTIVE: 'Professor inativado com sucesso!',
  SUCCESS_EMAIl: 'E-mail enviado com sucesso!',
  SUCCESS_ATCTIVE: 'Professor ativado com sucesso!',
  DELETE_SUCCESS: 'Apontamento de Horas excluído com sucesso',
  SUCCESS_SEND_PAYROLL: 'Folha de pagamento enviada com sucesso'
}

const initialState = {
  unity: '',
  situation: '',
  competence: '',
  sends: false
}

const DEFAULT_ITENS_PER_PAGE = 6

const listItemsPerPage = [
  {
    value: '6',
    label: '6'
  },
  {
    value: '12',
    label: '12'
  },
  {
    value: '24',
    label: '24'
  },
  {
    value: '36',
    label: '36'
  },
  {
    value: '54',
    label: '54'
  }
]

const LIST_STATUS = [
  {
    value: 'checked',
    label: 'Conferido'
  },
  {
    value: 'partial_checked',
    label: 'Conferido Parcial'
  },
  {
    value: 'sent',
    label: 'Enviado'
  },
  {
    value: 'pending',
    label: 'Pendente'
  },
  {
    value: 'all',
    label: 'Todos'
  }
]

export {
  countDefault,
  defaultItemsPerPage,
  defaultPage,
  lengthItem,
  initialState,
  MESSAGE,
  DEFAULT_ITENS_PER_PAGE,
  listItemsPerPage,
  LIST_STATUS
}
