import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import InputAdornment from '@mui/material/InputAdornment'
import { Grid, Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import { Select, TextField } from 'components/molecules'
import { InputCheckbox, PageTitle } from 'components/atoms'
import { typeMask } from 'services/helpers/mask'
import { STATE_BRAZIL } from 'services/helpers/statesBrazil'
import { convertToParams } from 'services/helpers'
import { ROUTE } from 'services/constants'
import { getAddressForCEP } from 'services/api/shared'
import {
  disabledButton,
  ERROR_MESSAGE_SEARCH_CEP,
  EXIST_NAME_POLE,
  EXIST_NAME_UNIT,
  INFO_CEP,
  IS_EDIT,
  maxLengthCEP,
  maxLengthCNPJ,
  maxLengthPhones
} from './constants'
import * as Styled from './style'
import schema from './schema'

const FormUnitPole = ({
  nameUnit,
  editForm,
  typePage,
  setDisabledLinkedPoles,
  handleCreateOrUpdatePoleOrUnit,
  formUnitPole,
  setFormUnitPole,
  initialState,
  isNameUnit,
  unitId,
  namePoleExist,
  nameUnitExist,
  hasLinkedPoles,
  PATH
}) => {
  const navigate = useNavigate()
  const [errorsCEP, setErrorsCEP] = useState(false)

  const classNameTag = () => {
    if (typePage.unit) {
      if (editForm) {
        return TAG_MANAGER.academic_btn_save_edit_unit
      }

      return TAG_MANAGER.academic_btn_saves_new_unit
    }

    if (editForm) {
      return TAG_MANAGER.academic_btn_saves_new_polo
    }

    return TAG_MANAGER.academic_btn_save_edit_polo
  }

  const desabledTextfield = () =>
    typePage.pole ? !formUnitPole.namePole : !formUnitPole.unit

  const handleCancel = () => {
    setFormUnitPole(initialState)
    navigate(
      `${PATH}?${convertToParams({
        unit: nameUnit,
        unitId
      })}`,
      {
        state: { savedSearch: true }
      }
    )
  }

  const getForCEP = async (cep) => {
    try {
      const response = await getAddressForCEP(cep)

      if (response.erro) {
        return setErrorsCEP(true)
      }

      setErrorsCEP(false)

      return setFormUnitPole({
        ...formUnitPole,
        address: response.logradouro,
        neighborhood: response.bairro,
        city: response.localidade,
        state: response.uf
      })
    } catch {
      setFormUnitPole({
        ...formUnitPole,
        address: '',
        neighborhood: '',
        city: '',
        state: ''
      })

      if (!cep) {
        return setErrorsCEP(false)
      }

      return setErrorsCEP(true)
    }
  }

  useEffect(
    () =>
      hasLinkedPoles &&
      setDisabledLinkedPoles &&
      setDisabledLinkedPoles(hasLinkedPoles)
  )

  return (
    <Formik
      initialValues={formUnitPole}
      onSubmit={handleCreateOrUpdatePoleOrUnit}
      validationSchema={schema(typePage)}
      enableReinitialize
    >
      {({ errors, touched, handleSubmit }) => (
        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12} sm={12} md={12}>
              <PageTitle
                title={typePage.pole ? 'Dados do Polo' : 'Dados da Unidade'}
                padding="24px 0 0 0"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={typePage.pole ? 12 : 9}>
              <InputCheckbox
                label={typePage.pole ? 'Ativo' : 'Matrícula ativa'}
                checked={formUnitPole.active}
                className={
                  !typePage.pole &&
                  TAG_MANAGER.academic_checkbox_selects_Active_enrollment
                }
                handleChange={(e) =>
                  setFormUnitPole({
                    ...formUnitPole,
                    active: e.target.checked
                  })
                }
              />
            </Grid>
            {!typePage.pole && (
              <Styled.Grid item xs={12} sm={12} md={3}>
                <InputCheckbox
                  label="Incluir polo"
                  disabled={hasLinkedPoles}
                  className={TAG_MANAGER.academic_checkbox_select_includePolo}
                  checked={hasLinkedPoles || formUnitPole.includePole}
                  handleChange={(e) =>
                    setFormUnitPole({
                      ...formUnitPole,
                      includePole: e.target.checked
                    })
                  }
                />

                {hasLinkedPoles && (
                  <Styled.Link
                    to={`${ROUTE.ADMIN_MANAGEMENT_POLE}?${convertToParams({
                      unit: isNameUnit,
                      unitId
                    })}`}
                  >
                    <Styled.ButtonPole
                      className={TAG_MANAGER.academic_btn_accesses_polos}
                      value="Polos"
                    />
                  </Styled.Link>
                )}
              </Styled.Grid>
            )}

            <Grid item xs={12} sm={12} md={6}>
              {typePage.pole ? (
                <TextField
                  fullWidth
                  id="outlined-pole"
                  label="Nome do Polo"
                  disabled={typePage.edit === IS_EDIT}
                  value={formUnitPole.namePole}
                  onChange={(e) =>
                    setFormUnitPole({
                      ...formUnitPole,
                      namePole: e.target.value
                    })
                  }
                  helperText={
                    (namePoleExist && EXIST_NAME_POLE) ||
                    (errors && touched.namePole && errors.namePole)
                  }
                  error={Boolean(
                    (touched.namePole && errors.namePole) || namePoleExist
                  )}
                />
              ) : (
                <TextField
                  fullWidth
                  id="outlined-unit"
                  label="Unidade"
                  value={formUnitPole.unit}
                  disabled={typePage.edit === IS_EDIT}
                  onChange={(e) =>
                    setFormUnitPole({ ...formUnitPole, unit: e.target.value })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="O nome da unidade deve ser diferente das demais pois ele será consultado"
                          placement="top"
                          arrow
                        >
                          <InfoOutlined />
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                  helperText={
                    (nameUnitExist && EXIST_NAME_UNIT) ||
                    (errors && touched.unit && errors.unit)
                  }
                  error={Boolean(
                    (touched.unit && errors.unit) || nameUnitExist
                  )}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                id="outlined-socialReason"
                label="Razão Social"
                disabled={desabledTextfield()}
                value={formUnitPole.socialReason}
                onChange={(e) =>
                  setFormUnitPole({
                    ...formUnitPole,
                    socialReason: e.target.value
                  })
                }
                helperText={
                  errors && touched.socialReason && errors.socialReason
                }
                error={Boolean(touched.socialReason && errors.socialReason)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                id="outlined-academicName"
                label="Nome Acadêmico"
                disabled={desabledTextfield()}
                value={formUnitPole.academicName}
                onChange={(e) =>
                  setFormUnitPole({
                    ...formUnitPole,
                    academicName: e.target.value
                  })
                }
                helperText={
                  errors && touched.academicName && errors.academicName
                }
                error={Boolean(touched.academicName && errors.academicName)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                id="outlined-cnpj"
                disabled={desabledTextfield()}
                inputProps={{ maxlength: maxLengthCNPJ }}
                label={typePage.pole ? 'CNPJ (opcional)' : 'CNPJ'}
                value={typeMask('CNPJ', formUnitPole.cnpj)}
                onChange={(e) =>
                  setFormUnitPole({ ...formUnitPole, cnpj: e.target.value })
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <TextField
                fullWidth
                id="outlined-cep"
                label="CEP"
                value={typeMask('CEP', formUnitPole.cep)}
                onChange={(e) => {
                  setFormUnitPole({ ...formUnitPole, cep: e.target.value })
                }}
                onBlur={() => getForCEP(formUnitPole.cep)}
                helperText={errorsCEP && ERROR_MESSAGE_SEARCH_CEP}
                error={errorsCEP}
                InputProps={{
                  maxlength: maxLengthCEP,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={INFO_CEP} placement="top" arrow>
                        <InfoOutlined />
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Select
                isMargin="0 0 0 0"
                label=" Estado"
                disabled={!formUnitPole.cep}
                optionsList={STATE_BRAZIL}
                value={formUnitPole.state}
                onChange={(e) => {
                  setFormUnitPole({ ...formUnitPole, state: e.target.value })
                }}
                helperText={errors && touched.state && errors.state}
                error={Boolean(touched.state && errors.state)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                id="outlined-address"
                label="Endereço"
                disabled={!formUnitPole.cep}
                value={formUnitPole.address}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    address: e.target.value
                  })
                }}
                helperText={errors && touched.address && errors.address}
                error={Boolean(touched.address && errors.address)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextField
                fullWidth
                id="outlined-numberAddress"
                label="Número"
                disabled={!formUnitPole.cep}
                value={typeMask('NUMBER', formUnitPole.numberAddress)}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    numberAddress: e.target.value
                  })
                }}
                helperText={
                  errors && touched.numberAddress && errors.numberAddress
                }
                error={Boolean(touched.numberAddress && errors.numberAddress)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                id="outlined-neighborhood"
                label="Bairro"
                disabled={!formUnitPole.cep}
                value={formUnitPole.neighborhood}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    neighborhood: e.target.value
                  })
                }}
                helperText={
                  errors && touched.neighborhood && errors.neighborhood
                }
                error={Boolean(touched.neighborhood && errors.neighborhood)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <TextField
                fullWidth
                id="outlined-complement"
                label="Complemento (opcional)"
                disabled={!formUnitPole.cep}
                value={formUnitPole.complement}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    complement: e.target.value
                  })
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <TextField
                fullWidth
                id="outlined-city"
                label="Cidade"
                disabled={!formUnitPole.cep}
                value={formUnitPole.city}
                onChange={(e) => {
                  setFormUnitPole({ ...formUnitPole, city: e.target.value })
                }}
                helperText={errors && touched.city && errors.city}
                error={Boolean(touched.city && errors.city)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                id="outlined-responsible"
                label="Responsável"
                value={formUnitPole.responsible}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    responsible: e.target.value
                  })
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                id="outlined-responsiblePhone"
                label="Telefone do Responsável"
                inputProps={{ maxlength: maxLengthPhones }}
                value={typeMask('PHONE', formUnitPole.responsiblePhone)}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    responsiblePhone: e.target.value
                  })
                }}
              />
            </Grid>
            {!typePage.pole && (
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  fullWidth
                  id="outlined-pabx"
                  label="ID - PABX"
                  value={formUnitPole.pabx}
                  onChange={(e) => {
                    setFormUnitPole({
                      ...formUnitPole,
                      pabx: e.target.value
                    })
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                type="email"
                id="outlined-email"
                label="E-mail da unidade"
                value={formUnitPole.email}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    email: e.target.value
                  })
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <TextField
                fullWidth
                id="outlined-firstPhone"
                label="Primeiro Telefone"
                inputProps={{ maxlength: maxLengthPhones }}
                value={typeMask('PHONE', formUnitPole.firstPhone)}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    firstPhone: e.target.value
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                fullWidth
                id="outlined-secondPhone"
                label="Segundo Telefone"
                inputProps={{ maxlength: maxLengthPhones }}
                value={typeMask('PHONE', formUnitPole.secondPhone)}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    secondPhone: e.target.value
                  })
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <PageTitle title="Dados Adicionais" padding="24px 0 0 0" />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                id="outlined-resolution"
                label="Resolução (opcional)"
                value={formUnitPole.resolution}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    resolution: e.target.value
                  })
                }}
                InputProps={{
                  endAdornment: typePage.unit && (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Digite o texto completo usado em documentos oficiais, por exemplo “Autorizada pela Resolução:...”"
                        placement="top"
                        arrow
                      >
                        <InfoOutlined />
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                id="outlined-resolution"
                label="Credencial (opcional)"
                value={formUnitPole.credential}
                onChange={(e) => {
                  setFormUnitPole({
                    ...formUnitPole,
                    credential: e.target.value
                  })
                }}
                inputProps={{
                  endAdornment: typePage.unit && (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Digite o texto completo usado em documentos oficiais, por exemplo “Credenciada pela Resolução:...”"
                        placement="top"
                        arrow
                      >
                        <InfoOutlined />
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            {typePage.unit && (
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  id="outlined-resolution"
                  label="Orgão Fiscalizador (opcional)"
                  value={formUnitPole.supervisoryBody}
                  onChange={(e) => {
                    setFormUnitPole({
                      ...formUnitPole,
                      supervisoryBody: e.target.value
                    })
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title="Nome do Orgão/Secretaria fiscalizador de documentos oficiais, por exemplo Secretaria da Educação."
                          placement="top"
                          arrow
                        >
                          <InfoOutlined />
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={12}>
              <Styled.GroupButtons>
                <Styled.ButtonCancel
                  variant="outlined"
                  disabled={disabledButton(formUnitPole) || typePage.edit}
                  type="reset"
                  onClick={handleCancel}
                  value="Cancelar"
                />

                <Styled.ButtonSubmit
                  className={classNameTag}
                  variant="contained"
                  disabled={disabledButton(formUnitPole)}
                  type="submit"
                  value="Salvar"
                />
              </Styled.GroupButtons>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default FormUnitPole
