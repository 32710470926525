import getEnvAccess from './getEnvAccess'

function getFinancialPermissions(permissions) {
  const finance = permissions?.finance || {}
  const permissionsInvoice = finance.file?.invoice
  const permissionsReceived = finance.bank_billing?.received
  const permissionsCampaign = finance.campaign
  const permissionsGroup = permissions?.management?.course?.group
  const permissionsPaymentPlan = permissions?.finance?.payment_plan
  const permissionsCupon = finance.discount_coupon
  const permissionsCustomerSupplier = permissions?.customer_supplier
  const hasPermission = getEnvAccess()

  const FINANCIAL_PERMISSIONS = {
    FINANCIAL: Boolean(permissionsReceived),
    FINANCIAL_BILLS_RECEIVE: Boolean(finance),
    FINANCIAL_CAMPAIGN_CREATE: Boolean(permissionsCampaign?.POST),
    FINANCIAL_CAMPAIGN_UPDATE: Boolean(permissionsCampaign?.PUT),
    FINANCIAL_CAMPAIGNS: Boolean(permissionsCampaign?.GET),
    FINANCIAL_BREAKDOWN: Boolean(permissionsReceived),
    FINANCIAL_BREAKDOWN_NEW_BANK_BILLING: Boolean(permissionsReceived),
    FINANCIAL_BANK_BILLING_EDIT: Boolean(permissionsReceived),
    FINANCIAL_BANK_BILLING_CREATE: Boolean(permissionsReceived),
    FINANCIAL_INVOICES: Boolean(permissionsInvoice),
    FINANCIAL_INVOICES_BANK_BILLING_EDIT: Boolean(permissionsInvoice),
    FINANCIAL_GROUPS: Boolean(permissionsGroup?.GET),
    FINANCIAL_GROUPS_CREATE: Boolean(permissionsGroup?.POST),
    FINANCIAL_GROUPS_EDIT: Boolean(permissionsGroup?.GET),
    FINANCIAL_CUPON: Boolean(permissionsCupon?.GET),
    FINANCIAL_CUPON_CREATE: Boolean(permissionsCupon?.POST),
    FINANCIAL_CUPON_EDIT: Boolean(permissionsCupon?.GET),
    FINANCIAL_PAYMENT_PLAN: Boolean(permissionsPaymentPlan?.GET),
    FINANCIAL_PAYMENT_PLAN_CREATE: Boolean(permissionsPaymentPlan?.POST),
    FINANCIAL_PAYMENT_PLAN_EDIT: Boolean(permissionsPaymentPlan?.GET),
    FINANCIAL_REGISTER_SUPPLIER: Boolean(permissionsCustomerSupplier?.GET),
    FINANCIAL_REGISTER_SUPPLIER_CREATE: Boolean(
      permissionsCustomerSupplier?.POST
    ),
    FINANCIAL_REGISTER_SUPPLIER_EDIT: Boolean(permissionsCustomerSupplier?.PUT),
    FINANCIAL_MANAGER: Boolean(permissionsInvoice)
  }

  return FINANCIAL_PERMISSIONS
}

export default getFinancialPermissions
