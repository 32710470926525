import styled from 'styled-components'
import { Toolbar as MuiToolbar, Grid as MuiGrid } from '@mui/material'
import { Button as AtomButton } from 'components/atoms'

const Grid = styled(MuiGrid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Toolbar = styled(MuiToolbar)`
  &.MuiToolbar-root {
    padding: 0;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: ${({ $displayNone }) => ($displayNone ? 'none' : 'flex')};
  }
`

const Button = styled(AtomButton)`
  &.MuiButtonBase-root {
    padding: 4px 8px;
    margin-bottom: 0;
    margin-left: 10px;
  }
`
export { Toolbar, Button, Grid }
