import styled from 'styled-components'

const Content = styled.div`
  margin-bottom: 20px;
  margin-top: 90px;
`

const BoxForm = styled.div`
  margin-top: 24px;
`

const SwitchAdvSearch = styled.div`
  padding: 20px 0 10px;
`

export { Content, BoxForm, SwitchAdvSearch }
