const paginationFilter = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' }
]

const paginationFilterDefault = [
  { value: 5, label: '5' },
  { value: 10, label: '10' }
]

export { paginationFilter, paginationFilterDefault }
