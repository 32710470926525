import styled from 'styled-components'
import { Button } from 'components/atoms'

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $justifyContent }) => $justifyContent ?? 'end'};
  margin: ${({ $isMargin }) => $isMargin ?? '16px 0 0'};
  button:not(:first-child) {
    margin-left: 16px;
  }
`

const ButtonConfirm = styled(Button)`
  &.MuiButton-root {
    margin: 0;
  }
`

const ButtonNotConfirm = styled(Button)`
  ${({ $hasMaxWidth }) => $hasMaxWidth && 'max-width: 130px'};
  &.MuiButton-root {
    margin: 0;
  }
`

export { Footer, ButtonConfirm, ButtonNotConfirm }
