/* eslint-disable react/no-danger-with-children */
import PropTypes from 'prop-types'
import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material'
import * as Styled from './style'

const Breadcrumbs = ({ breadcrumbsItems, separator, breadcrumbsNotLink }) => (
  <>
    <MuiBreadcrumbs separator={separator}>
      {breadcrumbsItems?.map((item) => (
        <Styled.LinkBreadcrumbs to={item.path} key={item.label}>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: item.label }}
          />
        </Styled.LinkBreadcrumbs>
      ))}
    </MuiBreadcrumbs>

    <MuiBreadcrumbs separator={separator}>
      {breadcrumbsNotLink?.map((item) => (
        <Styled.Box key={item.label}>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: item.label }}
          />
        </Styled.Box>
      ))}
    </MuiBreadcrumbs>
  </>
)

export default Breadcrumbs

Breadcrumbs.propTypes = {
  breadcrumbsItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  separator: PropTypes.element
}
