import * as yup from 'yup'

const schema = (isAdvancedSearch) =>
  yup.object().shape({
    class: isAdvancedSearch
      ? yup.string()
      : yup
          .string()
          .min(2, 'Digite o nome completo da turma')
          .required('Campo é obrigatório'),
    unity: isAdvancedSearch
      ? yup.string().required('Campo é obrigatório')
      : yup.string()
  })

export default schema
