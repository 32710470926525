import { useEffect, useState } from 'react'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getTypesCatefories, getTypeOfOccurrence } from 'services/api/shared'
import { updateErrorMessage } from 'services/helpers'

const useMakeServiceOccurrence = ({
  isCategory,
  setSnack,
  categoryId,
  isCategoryName = true
}) => {
  const [categories, setCategories] = useState([])
  const [typeOfOccurrence, setTypeOfOccurrence] = useState([])

  const processTypeOfOccurrence = (data) =>
    data?.map(({ id, name }) =>
      isCategoryName ? { value: id, label: name } : { value: name, label: name }
    ) || []

  const processCategories = (data) =>
    data?.map(({ code, name }) => ({ value: code, label: name })) || []

  const handleCategory = async () => {
    const { data, error, status } = await getTypesCatefories()
    if (!error) return setCategories(processCategories(data))

    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCategory
    })
  }

  const handleTypeOfOccurrence = async () => {
    const { data, error, status } = await getTypeOfOccurrence(categoryId)
    if (!error) return setTypeOfOccurrence(processTypeOfOccurrence(data))

    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListTypeCategory
    })
  }

  useEffect(() => {
    if (isCategory) {
      handleCategory()
    }
  }, [isCategory])

  useEffect(() => {
    if (categoryId) {
      handleTypeOfOccurrence()
    }
  }, [categoryId])

  return {
    categories,
    typeOfOccurrence
  }
}

export default useMakeServiceOccurrence
