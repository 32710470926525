import PropTypes from 'prop-types'
import { useEffect, useReducer } from 'react'
import { getUserFromLocalStorage, setDateLocale } from 'services/helpers'
import GlobalContext from './GlobalContext'
import GlobalReducer from './GlobalReducer'
import initialState from './initialState'

export default function GlobalProvider({ children }) {
  const [state, dispatch] = useReducer(GlobalReducer, initialState)
  const user = getUserFromLocalStorage()

  setDateLocale(user?.locale || 'pt-br')

  function setSidebarOpenInGlobalState(data) {
    dispatch({
      type: 'TOGGLE_SIDEBAR',
      payload: data
    })
  }

  function setUserInGlobalState(data) {
    dispatch({
      type: 'SET_USER',
      payload: data
    })
  }

  function resetState(data) {
    dispatch({
      type: 'RESET_STATE',
      payload: data
    })
  }

  function resetUserFromGlobalState(data) {
    dispatch({
      type: 'RESET_USER',
      payload: data
    })
  }

  useEffect(() => {
    if (user) setUserInGlobalState(user)
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        resetState,
        resetUserFromGlobalState,
        setSidebarOpenInGlobalState,
        setUserInGlobalState,
        ui: state.ui,
        user: state.user
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

GlobalProvider.propTypes = {
  children: PropTypes.node
}
