import { ROUTE } from 'services/constants'

const breadcrumbsItems = (edit) => [
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Ocorrências'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES_EDIT,
    label: `<b>${edit}</b>`
  }
]

export { breadcrumbsItems }
