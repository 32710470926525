import PropTypes from 'prop-types'
import * as Styled from './styles'

const ButtonFooterV2 = ({
  labelClose,
  classNameCancel,
  disabledClose,
  onClickClose,
  variantClose = 'outlined',
  startIcon,
  endIcon,
  labelConfirm,
  disabledConfirm,
  classNameConfirm,
  colorClose,
  colorConfirm,
  disabled,
  typeReset,
  onClickConfirm,
  loadingButtonFooterV2,
  hasMarginLeft,
  ...props
}) => (
  <Styled.Box $hasMarginLeft={hasMarginLeft}>
    {labelClose && (
      <Styled.ButtonClose
        type={typeReset}
        variant={variantClose}
        className={classNameCancel}
        disabled={disabledClose || disabled}
        onClick={onClickClose}
        value={labelClose}
        startIcon={startIcon}
        color={colorClose}
        {...props}
      />
    )}

    {labelConfirm && (
      <Styled.ButtonSearch
        type="submit"
        className={classNameConfirm}
        loading={loadingButtonFooterV2}
        loadingPosition="start"
        disabled={disabledConfirm || disabled}
        value={labelConfirm}
        color={colorConfirm}
        onClick={onClickConfirm}
        endIcon={endIcon}
        {...props}
      />
    )}
  </Styled.Box>
)

export default ButtonFooterV2

ButtonFooterV2.propTypes = {
  labelClose: PropTypes.string,
  variantClose: PropTypes.string,
  labelConfirm: PropTypes.string,
  onClickClose: PropTypes.func
}

ButtonFooterV2.defaultProps = {
  labelClose: '',
  labelConfirm: '',
  variantClose: 'outlined',
  onClickClose: () => {}
}
