const MESSAGE = {
  NO_SEARCH_PERFOMED:
    '<b>Encontre alunos registrados no sistema</b><br/>Selecione um critério e realize uma busca para encontrar alunos ou um aluno específico ',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  SUCCESS_SMS: 'SMS enviado com sucesso!',
  SUCCESS_EMAIL: 'E-mail enviado com sucesso.'
}

const SUCCESS = 'success'

const nameAccordion = 'scretary_sudents'
const SOCIAL_NAME = 'social_name'
const NAME = 'name'
const EMAIL = 'email'
const REGISTRATION = 'register'
const CPF = 'cpf'
const defaultItemsPerPage = 10
const countDefault = 0
const defaultPage = 1
const emptyValue = ''
const password = 'password'
const tickets = 'tickets'
const requeriment = 'requeriment'
const coursesAndClasses = 'coursesAndClasses'
const PAGE_STUDENTS = 'Alunos'

export {
  MESSAGE,
  nameAccordion,
  NAME,
  SOCIAL_NAME,
  EMAIL,
  REGISTRATION,
  CPF,
  defaultItemsPerPage,
  countDefault,
  defaultPage,
  emptyValue,
  password,
  tickets,
  requeriment,
  coursesAndClasses,
  PAGE_STUDENTS,
  SUCCESS
}
