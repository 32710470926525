import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  beginAt: Yup.date()
    .nullable()
    .required('Período incial é obrigatório')
    .typeError('período Inválido'),
  endAt: Yup.date()
    .nullable()
    .typeError('período Inválido')
    .required('Período final é obrigatório')
    .when('begin_at', (startDate, schema) =>
      schema.min(
        startDate || new Date(0),
        'A período final não pode ser menor do que a período inicial'
      )
    )
})

export { validationSchema }
