/* eslint-disable react/no-danger */
import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2, Select } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import { dateAddDays, dateDifferenceInDays } from 'services/helpers'
import * as Styled from './style'
import { dateInvalidMessage, days, initialValue } from './constants'
import { validationSchema } from './schema'

const ModalDisciplineAndClass = ({
  handleClose,
  open,
  title,
  colorConfirm,
  disabledButton,
  user,
  message,
  unitys,
  setModalDonwloadsFile,
  setFormValuesDisciplines
}) => (
  <Styled.Modal open={open} onClose={handleClose}>
    <Formik
      initialValues={initialValue}
      onSubmit={(values) => {
        setFormValuesDisciplines(values)
        handleClose()
        setModalDonwloadsFile(true)
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <Styled.Grid>
            <Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Styled.IconText>
                  <Styled.Title variant="h5">{title}</Styled.Title>
                </Styled.IconText>
                <Typography variant="subtitle1">
                  <span>{`${user?.name} - ${user?.id}`}</span>
                </Typography>

                <Typography variant="message">
                  <span dangerouslySetInnerHTML={{ __html: message }} />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Select
                  isMargin="0 0 0 0"
                  label="Unidade (opcional)"
                  optionsList={unitys}
                  value={values?.level}
                  onChange={(e) => setFieldValue('unit', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <DatePicker
                  fullWidth
                  id="firstPeriod"
                  label="Período inicial"
                  value={values?.firstPeriod ?? null}
                  onChange={(newValue) => {
                    setFieldValue('firstPeriod', newValue)
                    setFieldValue('endPeriod', null)
                  }}
                  helperText={
                    errors && touched?.firstPeriod && errors?.firstPeriod
                  }
                  error={Boolean(touched?.firstPeriod && errors?.firstPeriod)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <DatePicker
                  fullWidth
                  id="endPeriod"
                  label="Período final"
                  value={values?.endPeriod ?? null}
                  onChange={(newValue) => setFieldValue('endPeriod', newValue)}
                  minDate={values?.firstPeriod}
                  disabled={!values?.firstPeriod}
                  maxDate={dateAddDays(values?.firstPeriod, days)}
                  helperText={
                    errors && touched.endPeriod && errors.endPeriodfirstPeriod
                  }
                  error={Boolean(touched.endPeriod && errors.endPeriod)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ButtonFooterV2
                  size="large"
                  labelClose="CANCELAR"
                  labelConfirm="Buscar"
                  onClickClose={handleClose}
                  colorConfirm={colorConfirm}
                  disabledConfirm={disabledButton && !values?.group}
                />
              </Grid>
            </Grid>
          </Styled.Grid>
        </Form>
      )}
    </Formik>
  </Styled.Modal>
)

export { ModalDisciplineAndClass }
