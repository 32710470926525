import styled from 'styled-components'
import { Grid } from '@mui/material'
import { WarningAmber, AddBox } from '@mui/icons-material'

export const FormBox = styled.form``

export const GroupButtons = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`

export const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export const AddIcon = styled(AddBox)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.purple.medium};
  }
`
