import { FieldArray } from 'formik'
import { Grid, IconButton, TextField, Tooltip } from '@mui/material'

import { Select } from 'components/molecules'
import { typeMask } from 'services/helpers'
import { MAX_LENGTH_PHONE, MAX_LENGTH_CNPJ } from 'services/constants'

import { hasError } from '../../helpers'
import { MAX_ADD_CONTACT } from '../../constants'

import * as Styled from './style'

const LegalPersonForm = ({
  values,
  handleChange,
  handleBlur,
  touched,
  fieldNameForm,
  listCitys,
  listStates,
  handleChangeState,
  setFieldValue,
  handleChangeCep,
  setSearchByCep
}) => (
  <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
    <Grid item xs={4.5}>
      <TextField
        label="Razão Social*"
        name={`${fieldNameForm}.razaoSocial`}
        value={values?.[fieldNameForm]?.razaoSocial}
        onChange={handleChange}
        fullWidth
        onBlur={handleBlur}
        helperText={
          hasError(touched, values, 'razaoSocial') && 'Campo obrigatório'
        }
        error={hasError(touched, values, 'razaoSocial')}
      />
    </Grid>

    <Grid item xs={3}>
      <TextField
        id={`${fieldNameForm}.cnpj`}
        name={`${fieldNameForm}.cnpj`}
        inputProps={{ maxLength: MAX_LENGTH_CNPJ }}
        label="CNPJ*"
        value={typeMask('CNPJ', values?.[fieldNameForm]?.cnpj)}
        onChange={handleChange}
        fullWidth
        onBlur={handleBlur}
        helperText={hasError(touched, values, 'cnpj') && 'Campo obrigatório'}
        error={hasError(touched, values, 'cnpj')}
      />
    </Grid>

    <Grid item xs={4.5}>
      <TextField
        label="E-mail Faturamento*"
        name={`${fieldNameForm}.email`}
        value={values?.[fieldNameForm]?.email}
        onChange={handleChange}
        fullWidth
        onBlur={handleBlur}
        helperText={hasError(touched, values, 'email') && 'Campo obrigatório'}
        error={hasError(touched, values, 'email')}
      />
    </Grid>

    <Grid item xs={6}>
      <TextField
        label="Inscrição Estadual *"
        name={`${fieldNameForm}.stateRegistration`}
        value={values?.[fieldNameForm]?.stateRegistration}
        onChange={handleChange}
        fullWidth
        onBlur={handleBlur}
        helperText={
          hasError(touched, values, 'stateRegistration') && 'Campo obrigatório'
        }
        error={hasError(touched, values, 'stateRegistration')}
      />
    </Grid>

    <Grid item xs={6}>
      <FieldArray name={`${fieldNameForm}.phone`}>
        {({ push, remove }) => (
          <Styled.Container>
            {values?.[fieldNameForm]?.phone?.map((valuephone, index) => {
              const cell = `${fieldNameForm}.phone[${index}].cell`

              return (
                <Styled.ContainerField key={valuephone.id}>
                  <TextField
                    fullWidth
                    label="Telefone / WhatsApp *"
                    name={cell}
                    onChange={handleChange}
                    value={typeMask('PHONE', valuephone?.cell)}
                    inputProps={{
                      maxLength: MAX_LENGTH_PHONE
                    }}
                    isMargin="0"
                  />

                  <Tooltip title="Excluir contato" placement="top">
                    <Styled.BoxTooltip>
                      <IconButton disableRipple onClick={() => remove(index)}>
                        <Styled.DeleteIcon />
                      </IconButton>
                    </Styled.BoxTooltip>
                  </Tooltip>
                </Styled.ContainerField>
              )
            })}

            {values?.[fieldNameForm]?.phone?.length < MAX_ADD_CONTACT && (
              <Tooltip title="Adicionar contato" placement="top">
                <Styled.BoxTooltip>
                  <IconButton
                    disableRipple
                    onClick={() =>
                      push({
                        id: Math.random(),
                        cell: ''
                      })
                    }
                  >
                    <Styled.AddIcon />
                  </IconButton>
                </Styled.BoxTooltip>
              </Tooltip>
            )}
          </Styled.Container>
        )}
      </FieldArray>
    </Grid>

    <Grid item xs={1.5}>
      <TextField
        label="CEP*"
        name={`${fieldNameForm}.zipCode`}
        value={typeMask('CEP', values?.[fieldNameForm]?.zipCode)}
        onChange={(event) =>
          handleChangeCep({
            event,
            setSearchByCep,
            setFieldValue,
            fieldNameZipCode: `${fieldNameForm}.zipCode`
          })
        }
        fullWidth
        helperText={hasError(touched, values, 'zipCode') && 'Campo obrigatório'}
        error={hasError(touched, values, 'zipCode')}
      />
    </Grid>

    <Grid item xs={3}>
      <Select
        label="Estado*"
        name={`${fieldNameForm}.state`}
        value={values?.[fieldNameForm]?.state}
        onChange={(event) =>
          handleChangeState({
            event,
            setFieldValue,
            fieldNameState: `${fieldNameForm}.state`,
            fieldNameCity: `${fieldNameForm}.city`
          })
        }
        optionsList={listStates}
        fullWidth
        onBlur={handleBlur}
        helperText={hasError(touched, values, 'state') && 'Campo obrigatório'}
        error={hasError(touched, values, 'state')}
      />
    </Grid>

    <Grid item xs={7.5}>
      <Select
        label="Cidade*"
        name={`${fieldNameForm}.city`}
        value={values?.[fieldNameForm]?.city}
        onChange={handleChange}
        optionsList={listCitys}
        fullWidth
        onBlur={handleBlur}
        helperText={hasError(touched, values, 'city') && 'Campo obrigatório'}
        error={hasError(touched, values, 'city')}
        hasDontSensitive
      />
    </Grid>

    <Grid item xs={10.5}>
      <TextField
        label="Endereço*"
        name={`${fieldNameForm}.address`}
        value={values?.[fieldNameForm]?.address}
        onChange={handleChange}
        fullWidth
        onBlur={handleBlur}
        helperText={hasError(touched, values, 'address') && 'Campo obrigatório'}
        error={hasError(touched, values, 'address')}
      />
    </Grid>

    <Grid item xs={1.5}>
      <TextField
        label="Número*"
        name={`${fieldNameForm}.addressNumber`}
        value={values?.[fieldNameForm]?.addressNumber}
        onChange={handleChange}
        fullWidth
        onBlur={handleBlur}
        helperText={
          hasError(touched, values, 'addressNumber') && 'Campo obrigatório'
        }
        error={hasError(touched, values, 'addressNumber')}
      />
    </Grid>

    <Grid item xs={6}>
      <TextField
        label="Complemento"
        name={`${fieldNameForm}.complement`}
        value={values?.[fieldNameForm]?.complement}
        onChange={handleChange}
        fullWidth
        onBlur={handleBlur}
        helperText={
          hasError(touched, values, 'complement') && 'Campo obrigatório'
        }
        error={hasError(touched, values, 'complement')}
      />
    </Grid>

    <Grid item xs={6}>
      <TextField
        label="Bairro"
        name={`${fieldNameForm}.district`}
        value={values?.[fieldNameForm]?.district}
        onChange={handleChange}
        fullWidth
        onBlur={handleBlur}
        helperText={
          hasError(touched, values, 'district') && 'Campo obrigatório'
        }
        error={hasError(touched, values, 'district')}
      />
    </Grid>
  </Grid>
)

export default LegalPersonForm
