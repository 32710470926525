import { createContext, useState, useContext } from 'react'

const initialState = {
  searchType: '',
  searchBy: '',
  firstPeriod: null,
  endPeriod: null,
  unity: '',
  course: [],
  title: '',
  checkSocialReason: false,
  switchDefaulters: false,
  switchPaid: false,
  switchAdvancedSearch: false
}

export const FormContext = createContext(initialState)
export const BillsToReceiveProvider = ({ children }) => {
  const [formValues, setFormValues] = useState(initialState)

  const resetFormValues = () => {
    setFormValues({ ...initialState })
  }

  return (
    <FormContext.Provider
      value={{
        formValues,
        setFormValues,
        resetFormValues
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useBillsToReceive = () => useContext(FormContext)
