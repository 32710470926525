import { httpGet } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getUnitsCourses = async () => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SEARCH_SIMPLES_ADVANCED_UNIT_COURSES}`,
    {}
  )

  return response
}

export const searchBillsReceiveSimplesAdvanced = async ({
  units,
  courses,
  params,
  switchAdvancedSearch
}) => {
  const coursesString = `${courses
    ?.map(({ name }) => `&courses[]=${name}`)
    .join('&')}`
  const unitsString = `&units[]=${units}`

  const response = await httpGet(
    `${
      switchAdvancedSearch
        ? ENDPOINT.ADMIN_SEARCH_ADVANCED
        : ENDPOINT.ADMIN_SEARCH_SIMPLES
    }?${params}${units ? unitsString : ''}${coursesString}`,
    {}
  )

  return response
}

export const downloadXML = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SEARCH_SIMPLES_DOWNLOAD_XML}?${params}`,
    {
      responseType: 'blob'
    }
  )

  return response
}
