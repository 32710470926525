import styled from 'styled-components'

const Container = styled.form`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    & {
      flex-direction: column;
    }
  }
`
const InputsArea = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: calc(100% - 350px);

  & > div + div {
    margin-left: 1.5rem;
  }

  & > div:last-of-type {
    max-width: 150px;
  }

  @media (max-width: 768px) {
    & {
      flex-wrap: wrap;
      width: 100%;
    }

    & > div + div {
      margin-left: 0;
    }

    & > div:last-of-type {
      margin: 1rem 0;
      max-width: 100%;
    }
  }
`
const ButtonsArea = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  max-width: 350px;
  padding-left: 1rem;
  width: 100%;

  & > button + button {
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    & {
      justify-content: flex-end;
      max-width: 100%;
    }
  }
`

export { Container, InputsArea, ButtonsArea }
