import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

const TabContents = ({ children, value, index, ...props }) => {
  const canShowTabContent = value === index

  return (
    <>
      {canShowTabContent && (
        <div
          aria-labelledby={`default-tab-${index}`}
          id={`default-tab-content-${index}`}
          {...props}
        >
          <Box>{children}</Box>
        </div>
      )}
    </>
  )
}
export default TabContents

TabContents.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}
