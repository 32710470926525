import PropTypes from 'prop-types'
import { Dialog } from '@mui/material'

const Modal = ({ children, ...rest }) => (
  <Dialog
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    {...rest}
  >
    {children}
  </Dialog>
)

Modal.propTypes = {
  children: PropTypes.node.isRequired
}

export default Modal
