import { useSnack } from 'services/hooks'
import { Snackbar } from 'components/molecules'
import FormPassword from './components/FormPassword'
import * as Styled from './style'

const initialState = {
  newPassword: '',
  confirmPassword: ''
}

const UpdatePassword = () => {
  const { snack, setSnack, snackOpen } = useSnack()

  return (
    <Styled.Box>
      <Styled.Description>
        Sua nova senha deve conter letras e números
      </Styled.Description>

      <FormPassword setSnack={setSnack} initialState={initialState} />
      <Snackbar
        message={snack.message}
        severity={snack.severity}
        shouldOpen={snackOpen}
      />
    </Styled.Box>
  )
}

export default UpdatePassword
