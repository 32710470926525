import FEEDBACK_SNACK from 'feedBackSnack'
import {
  editDetailsClassReplacement,
  getSchoolClassListDisciplines,
  postClassReplacement
} from 'services/api/admin'
import {
  convertToParams,
  currencyMask,
  formatDateEnv,
  getMoneyFormatToNumber,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'
import { ROUTE } from 'services/constants'
import {
  MESSAGE,
  defaultItemsPerPage,
  defaultPage,
  replacementType
} from './constants'

const editClass = async ({
  id,
  name,
  isEdit,
  values,
  navigate,
  setSnack,
  idProfessor,
  setIsUpdate,
  setSelected,
  disciplines,
  setLoadingOpen,
  setListSelected
}) => {
  setLoadingOpen(true)

  const params = cleanObject({
    substitution_reason: values.reasonReplacement,
    substitute_professor_id: parseFloat(idProfessor),
    is_permanent: values?.replacement === replacementType,
    start_date: values?.startDate ? formatDateEnv(values?.startDate) : null,
    end_date: values?.endDate ? formatDateEnv(values?.endDate) : null,
    disciplines
  })

  const { error, status } = await editDetailsClassReplacement(
    parseFloat(id),
    params
  )

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditClassLinked
    })
  }

  toastNotificationSuccess(MESSAGE.success, 1500)
  setSelected([])
  setListSelected([])
  navigate(
    `${ROUTE.ADMIN_HR_DETAILS_PROFESSOR}?${convertToParams({
      isEdit,
      id: idProfessor,
      name
    })}`
  )

  return setIsUpdate((prev) => !prev)
}

const postReplaceClass = async ({
  id,
  name,
  isEdit,
  values,
  navigate,
  setSnack,
  setIsUpdate,
  setSelected,
  disciplines,
  idProfessor,
  setLoadingOpen,
  setListSelected
}) => {
  setLoadingOpen(true)

  const params = cleanObject({
    school_class_id: parseFloat(id),
    substitute_professor_id: idProfessor,
    substitution_reason: values.reasonReplacement,
    is_permanent: values?.replacement === replacementType,
    start_date: values?.startDate ? formatDateEnv(values?.startDate) : null,
    end_date: values?.endDate ? formatDateEnv(values?.endDate) : null,
    disciplines
  })

  const { error, status } = await postClassReplacement(params)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditClassLinked
    })
  }

  toastNotificationSuccess(MESSAGE.success, 1500)
  setSelected([])
  setListSelected([])

  navigate(
    `${ROUTE.ADMIN_HR_DETAILS_PROFESSOR}?${convertToParams({
      isEdit,
      id: idProfessor,
      name
    })}`
  )

  return setIsUpdate((prev) => !prev)
}

const handleTransformList = (list, newValue) => {
  const transformList = list.map((item) => ({
    ...item,
    hourly_lesson_cost: getMoneyFormatToNumber(currencyMask(newValue || ''))
  }))

  return transformList
}

const getListDisciplines = async ({
  id,
  setSnack,
  setCount,
  pageActual,
  setLoadingOpen,
  setListDisciplines
}) => {
  setLoadingOpen(true)

  const params = cleanObject({
    limit: 400,
    page: pageActual || defaultPage
  })

  const { data, error, status } = await getSchoolClassListDisciplines(
    id,
    params
  )

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDisciplines
    })
  }

  const filteredList = data?.data
    .filter((discipline) => discipline.professors !== null)
    .map(({ professors, ...restDiscipline }) => {
      const newProfessors = professors?.map((item) => ({
        value: item?.id,
        label: item.name,
        position: item.position,
        hourlyLessonCost: item.hourly_lesson_cost
      }))
      return {
        ...restDiscipline,
        professors: newProfessors,
        professorsSelected: {
          id: newProfessors[0].value,
          label: newProfessors[0].label,
          value: newProfessors[0].value,
          position: newProfessors[0].position,
          hourlyLessonCost: newProfessors[0].hourlyLessonCost
        }
      }
    })

  const count = filteredList.length
  setCount(count)
  return setListDisciplines(filteredList)
}

export { editClass, postReplaceClass, getListDisciplines, handleTransformList }
