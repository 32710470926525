import styled from 'styled-components'
import {
  AddCircle as MuiAddCircle,
  Delete as MuiDelete
} from '@mui/icons-material'
import { TableCell as MuiTableCell } from '@mui/material'

import { statusColorBySituation } from '../constants'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 20%;
  }
`

const Status = styled.div`
  width: fit-content;
  padding: 6px 16px;
  background-color: red;
  border-radius: 16px;

  ${({ situation }) =>
    statusColorBySituation[situation] ?? statusColorBySituation.Vinculado}
`

const TableCellRegister = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 10%;
  }
`

const TableCellNumber = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: center;
    padding: 6px 16px;
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    width: 50%;
    padding: 6px;
    height: 40px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        padding: 0 8px;
      }
    }
  }
`

const Actions = styled.div`
  display: flex;
  gap: 10px;
  margin: 0 20px;
  cursor: pointer;
`

const ContentName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
`

const Name = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 166%;
  letter-spacing: 0.4px;
`

const AddIcon = styled(MuiAddCircle)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
  }
`

const DeleteIcon = styled(MuiDelete)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.textColors.error};
  }
`

export {
  TableCell,
  TableCellTitle,
  TableCellNumber,
  TableCellRegister,
  Status,
  AddIcon,
  DeleteIcon,
  Actions,
  ContentName,
  Name
}
