import styled, { css } from 'styled-components'
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  TableCell as MuiTableCell
} from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import theme from 'theme/designTokens'

const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${({ end }) =>
      end &&
      css`
        justify-content: flex-end;
      `}
  }
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${() => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

const GridContainer = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
  .MuiGrid-root .MuiGrid-item .MuiPaper-root {
    margin: 0 0 1px;
    border-radius: 0px;
    box-shadow: 0px 1px 3px 0px #0000001f, 0px 1px 1px 0px #00000024,
      0px 2px 1px -1px #00000033;
  }
  .Mui-expanded {
    margin-bottom: 1px;
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    width: 50%;
    padding: 6px;
    height: 40px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        padding: 0 8px;
      }
    }
  }
`

const Form = styled.form``

const Typography = styled(MuiTypography)`
  color: #00000099;
`

export {
  Grid,
  Form,
  GridContainer,
  Typography,
  TableCellTitle,
  IconWarningAmber
}
