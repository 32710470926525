import { lazy, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useMakeService } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { ModalConfirm } from 'components/molecules'
import { breadCrumbsItems, intialState } from './constants'
import { createByUpdatePayroll, onUnitsRegions, unicPayroll } from './services'
import * as Styled from './style'
import { schema } from './schema'

const Form = lazy(() => import('./Form'))

const CreateByUpdateTimeTraking = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { isEdit, id } = params
  const paramsMakeService = {
    isUnits: true,
    isLevel: true,
    unitsValue: true
  }
  const dataTypesOfActivitys = location?.state?.row
  const { level, unitys } = useMakeService(paramsMakeService)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [listPole, setListPole] = useState([])
  const [formValues, setFormValues] = useState(intialState)

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(ROUTE.ADMIN_HR_TIMEKEEPING, {
      state: { savedSearch: true }
    })
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: formValues,
    onSubmit: (values) => {
      createByUpdatePayroll({
        values,
        setLoadingOpen,
        navigate,
        unitys,
        isEdit,
        id
      })
    },
    validationSchema: schema(listPole)
  })

  useEffect(() => {
    if (isEdit) unicPayroll({ id, setLoadingOpen, unitys, setFormValues })
  }, [unitys])

  useEffect(() => {
    if (formik.values.unit)
      onUnitsRegions({ values: formik.values, setListPole })
  }, [formik.values.unit])

  return (
    <TemplateDefaultHeaderByContent
      breadcrumbsItems={breadCrumbsItems(isEdit)}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() => setOpenModal(true)}
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      content={
        <>
          <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
              <Form
                level={level}
                unitys={unitys}
                formik={formik}
                setOpenModal={setOpenModal}
                poles={listPole}
                isEdit={dataTypesOfActivitys?.id}
                setLoadingOpen={setLoadingOpen}
                navigate={navigate}
              />
            </Styled.GridContainer>
          </form>

          <ModalConfirm
            open={openModal}
            size="medium"
            handleClose={() => setOpenModal(false)}
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            onClickNotConfirm={() => setOpenModal(false)}
            onClickConfirm={() => onHandleConfirm()}
            colorButtonConfirm="error"
            icon={<Styled.IconWarningAmber />}
            title="Cancelar"
            message="Deseja cancelar a operação?
              Você perderá as alterações que ainda não foram salvas."
          />
        </>
      }
    />
  )
}

export default CreateByUpdateTimeTraking
