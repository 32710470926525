import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

const constructDownloadUrl = (occurrenceIds, schoolClassIds, params) => {
  const urlParams = `${schoolClassIds}&${convertToParams(params)}`

  if (occurrenceIds) {
    const urlParamsOccurrenceIds = `${occurrenceIds}&${urlParams}`
    return `${ENDPOINT.SCHOOL_CLASS.OCURRENCE_DOWNLOAD}?${urlParamsOccurrenceIds}`
  }

  return `${ENDPOINT.SCHOOL_CLASS.OCURRENCE_DOWNLOAD}?${urlParams}`
}

export { constructDownloadUrl }
