import theme from 'theme/designTokens'

const colors = {
  studying: theme.palette.primary.main,
  disapproved: theme.palette.gray.light,
  to_attend: theme.palette.blue.main,
  approved: theme.palette.gray.light
}

export function getStatusColorCard(sent) {
  return colors[sent] || colors.studying
}

const aprove = {
  disapproved: theme.palette.red.light,
  approved: theme.palette.success.main,
  studying: theme.palette.primary.main
}

export function getStatusColorAprove(sent) {
  return aprove[sent] || colors.approved
}

const colorTypography = {
  studying: theme.palette.background.white,
  disapproved: theme.palette.textColors.black.darkBlack,
  to_attend: theme.palette.background.white,
  approved: theme.palette.textColors.black.darkBlack
}

export function getColorTypography(sent) {
  return colorTypography[sent] || colorTypography.studying
}
