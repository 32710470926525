import buildSignature from 'components/templates/PDF/shared/BasePage/Signature'
import { clone } from 'services/helpers'

export function buildTableFooter({ classInfo, withSignature }) {
  const signature = buildSignature(
    classInfo.signatures,
    classInfo.poleCity || classInfo.unitCity,
    withSignature
  )
  return [
    [
      {
        colSpan: 7,
        text: 'Observações do curso',
        alignment: 'center',
        bold: true,
        style: ['tableHeaderCell']
      }
    ],
    [
      {
        colSpan: 7,
        stack: [
          {
            text: [
              { text: 'Sistema de Avaliação: ', bold: true },
              ' É considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, nota final igual ou superior a 6 (seis) pontos e frequência igual ou superior a 75% (setenta e cinco por cento). Até janeiro de 2023, é considerado(a) APROVADO(A) o(a) aluno(a) que obtiver, em cada unidade curricular, a nota final igual ou superior a 5 (cinco) pontos em cada unidade curricular e frequência igual ou superior a 75% (setenta e cinco por cento).'
            ],
            margin: [0, 0, 0, 10]
          },
          {
            text: [
              { text: 'Observações: ', bold: true },
              'Notas precedidas de asterisco (*) são provenientes de aproveitamento de estudos.'
            ]
          }
        ]
      }
    ],
    [{ stack: [...clone(signature)], colSpan: 7 }]
  ]
}
