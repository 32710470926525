import * as Styled from './style'

const StepTwo = () => (
  <Styled.Container>
    <Styled.BackgroundImage src="/images/onboarding_slide_2.svg" alt="#" />

    <Styled.Message>
      <Styled.MessageImage
        src="/images/onboarding_slide_2_ilustracoes.svg"
        alt="#"
      />
      <Styled.MessageText>
        No menu, ao lado esquerdo da tela, você poderá acessar todos os fluxos
        do nosso sistema.
      </Styled.MessageText>
    </Styled.Message>
  </Styled.Container>
)

export default StepTwo
