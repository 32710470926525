import {
  createReenrollClass,
  createReenrollSubmit,
  editTextReenroll,
  getListReenroll,
  inactiveReenrollClass,
  reenrollPdfSummary
} from 'services/api/admin'
import { SUCCESS } from 'services/constants'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { filterFutureDates } from './helpers'
import {
  MESSAGE,
  defaultItemsPerPage,
  defaultPage,
  payloadCreateSubmit
} from './constants'

const getReenrol = async ({
  setSnack,
  setLoadingOpen,
  filter,
  pageActual,
  id,
  setCount,
  setIsPagination,
  setListEnroll
}) => {
  setSnack('')
  setLoadingOpen(true)
  const paramsValues = {
    limit: filter || defaultItemsPerPage,
    page: pageActual || defaultPage
  }

  const { data, error, status } = await getListReenroll(paramsValues, id)
  setLoadingOpen(false)
  setCount(data?.count)
  setIsPagination(true)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListReenroll
    })
  }

  return setListEnroll(data?.data)
}

const inactiveReenroll = async ({
  setLoadingOpen,
  setSnack,
  setOpenModalInactive,
  selectedItem: selectedIds,
  setSelected,
  filter,
  pageActual,
  id,
  setCount,
  setIsPagination,
  setListEnroll
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = await inactiveReenrollClass({
    ids: selectedIds || []
  })

  setLoadingOpen(false)
  setOpenModalInactive(false)
  setSelected([])

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToInactiveListReenroll
    })
  }

  setSnack(MESSAGE.SUCCESS_INACTIVE, SUCCESS)

  return setTimeout(
    () =>
      getReenrol({
        setSnack,
        setLoadingOpen,
        filter,
        pageActual,
        id,
        setCount,
        setIsPagination,
        setListEnroll
      }),
    1500
  )
}

const handleSubmitPdfSummary = async ({ id, setLoadingOpen, setSnack }) => {
  setSnack('')
  setLoadingOpen(true)

  const { data, error, status } = await reenrollPdfSummary(id)
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToPdfSummaryReenroll
    })
  }

  setSnack(MESSAGE.SUCCESS_PDF, SUCCESS)

  return window.open(data.url, '_blank')
}

const handleSubmit = async ({
  values,
  setLoadingOpen,
  setSnack,
  idModalReenroll,
  setOpenModalReenroll
}) => {
  setSnack('')
  setLoadingOpen(true)

  const payload = filterFutureDates(values)

  const { error, status } = await createReenrollClass(idModalReenroll, payload)
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      errorMessage: MESSAGE.ERROR_EDIT,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToNewRegistration
    })
  }

  setSnack(MESSAGE.SUCCESS, SUCCESS)

  return setOpenModalReenroll(false)
}

const handleCreateSubmit = async ({
  values,
  setLoadingOpen,
  schoolClassId,
  setSnack,
  setOpenModalCreateReenroll
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = await createReenrollSubmit(
    schoolClassId,
    payloadCreateSubmit(values)
  )
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      errorMessage: MESSAGE.ERROR_CREATE,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToNewRegistration
    })
  }

  setSnack(MESSAGE.SUCCESS, SUCCESS)

  return setOpenModalCreateReenroll(false)
}

const handleSubmitEditText = async ({
  idModalReenroll,
  values,
  setLoadingOpen,
  setSnack,
  setOpenModalEditReenroll
}) => {
  setLoadingOpen(true)

  const { error, status } = await editTextReenroll(idModalReenroll, {
    text: values?.text
  })
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToNewRegistration
    })
  }

  setSnack(MESSAGE.SUCCESS_ACTIVE_TEXT, SUCCESS)

  return setOpenModalEditReenroll(false)
}

export {
  getReenrol,
  inactiveReenroll,
  handleSubmitPdfSummary,
  handleSubmit,
  handleCreateSubmit,
  handleSubmitEditText
}
