import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useMakeService, usePaginationBasic, useSnack } from 'services/hooks'
import { isEmpty, mergeInitialValues } from 'services/helpers'
import { AccordionFilter, InputSwitch, TextNotFound } from 'components/atoms'
import { ButtonFooterV2 } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import {
  MESSAGE,
  initialState,
  nameAccordion,
  breadCrumbsItems,
  defaultItemsPerPage
} from './constants'
import { useReplaceClass } from './context/FormContext'
import schema from './schema'
// import { getReplaceClass } from './services'
import { FormListReplaceClass } from './components/Forms'
import { isDisabledButton } from './helpers'

import { getListCourses, onUnitsRegions } from './services'
import TableReplaceClass from './components/Tables/TableReplaceClass'
import * as Styled from './style'
import { handleSearch, listOptionsClassCode } from '../LinkedClass/services'

const ReplaceClass = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { setSnack } = snackProps
  const [count, setCount] = useState(null)
  const [searchParams] = useSearchParams()
  const [notSearch, setNotSearch] = useState(true)
  const params = Object.fromEntries([...searchParams])
  const { name, id } = params

  const [listPole, setListPole] = useState([])
  const [listClass, setListClass] = useState([])
  const [listCourses, setListCourses] = useState([])
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [optionsCodeClass, setOptionsCodeClass] = useState([])
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false)
  const [expanded, setExpanded] = useState(nameAccordion)
  const { formValues, setFormValues } = useReplaceClass()
  const [filter, setFilter] = useState(defaultItemsPerPage)

  const paramsMakeService = {
    isUnits: true,
    isLevel: true,
    unitsValue: true,
    setSnack
  }

  const { unitys, level } = useMakeService(paramsMakeService)

  const messageInformative = notSearch
    ? MESSAGE.NOT_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  const {
    page: pageActual,
    totalPage,
    items,
    totalItems,
    setPage
  } = usePaginationBasic(listClass, filter, count)

  const initialValues = mergeInitialValues(initialState, formValues)

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setExpanded('')
      handleSearch({
        filter,
        unitys,
        values,
        setSnack,
        setCount,
        pageActual,
        setListClass,
        setNotSearch,
        setLoadingOpen
      })
      setFormValues(values)
    },
    validationSchema: schema(isAdvancedSearch)
  })

  const {
    values,
    errors,
    touched,
    setValues,
    resetForm,
    handleBlur,
    handleSubmit,
    setFieldValue
  } = formik

  const handleClear = () => {
    setNotSearch(true)
    setListClass([])
    setFormValues(initialState)
    resetForm(initialValues)
  }

  const handleswitchAdvanced = (checked) => {
    setListClass([])
    setNotSearch(true)
    setValues({
      ...initialState,
      switch_advanced_search: checked
    })
  }

  useEffect(() => {
    if (values.course)
      listOptionsClassCode({
        values,
        unitys,
        setSnack,
        setLoadingOpen,
        setOptionsCodeClass
      })
  }, [values.course])

  useEffect(() => {
    if (values.unity && values.level)
      getListCourses({ values, setSnack, setListCourses })
  }, [values.unity, values.level])

  useEffect(() => {
    setValues((prevValues) => ({
      ...initialState,
      switch_advanced_search: prevValues.switch_advanced_search
    }))
    setIsAdvancedSearch(values.switch_advanced_search)
  }, [values.switch_advanced_search])

  useEffect(() => {
    if (values.unity) onUnitsRegions({ values, setSnack, setListPole })
  }, [values.unity])

  useEffect(() => {
    handleSearch({
      values: formValues,
      filter,
      unitys,
      setSnack,
      setCount,
      pageActual,
      setListClass,
      setNotSearch,
      setLoadingOpen
    })
  }, [pageActual, filter])

  useEffect(() => {
    setValues({
      ...initialValues
    })
  }, [formValues])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      breadcrumbsNotLink={breadCrumbsItems({ name })}
      onClickButton={() => {
        navigate(-1)
      }}
      content={
        <>
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12} sm={12} md={12}>
              <Styled.SwitchAdvSearch>
                <InputSwitch
                  className={
                    TAG_MANAGER.financial_alternative_switch_typeSearch
                  }
                  label="Busca Avançada"
                  checked={values?.switch_advanced_search}
                  onClick={(e) => handleswitchAdvanced(e.target.checked)}
                />
              </Styled.SwitchAdvSearch>
              <Styled.Strong>
                Os campos com &#39;
                <Styled.Text>*</Styled.Text>&#39; são de preenchimento
                obrigatório.
              </Styled.Strong>
            </Grid>
          </Grid>
          <AccordionFilter
            title="Selecione e preencha os campos para realizar uma busca"
            expanded={expanded}
            setExpanded={setExpanded}
            nameAccordion={nameAccordion}
            margin
          >
            <Styled.Form autoComplete="off" onSubmit={handleSubmit}>
              <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
                <FormListReplaceClass
                  level={level}
                  unitys={unitys}
                  values={values}
                  errors={errors}
                  poles={listPole}
                  touched={touched}
                  courses={listCourses}
                  handleBlur={handleBlur}
                  classes={optionsCodeClass}
                  setFieldValue={setFieldValue}
                  isAdvancedSearch={isAdvancedSearch}
                />
                <Styled.Grid item xs={12} sm={12} md={3}>
                  <ButtonFooterV2
                    size="medium"
                    labelClose="Limpar"
                    labelConfirm="Buscar"
                    startIcon={<Clear />}
                    onClickClose={handleClear}
                    disabledConfirm={!isDisabledButton(values)}
                    className={TAG_MANAGER.sistema_btn_busca_contratos}
                  />
                </Styled.Grid>
              </Styled.GridContainer>
            </Styled.Form>
          </AccordionFilter>

          {listClass && !isEmpty(listClass) ? (
            <TableReplaceClass
              id={id}
              name={name}
              items={items}
              filter={filter}
              setPage={setPage}
              count={totalItems}
              setFilter={setFilter}
              totalPage={totalPage}
              pageActual={pageActual}
            />
          ) : (
            <TextNotFound
              icon={messageInformative.icon}
              text={messageInformative.text}
            />
          )}
        </>
      }
    />
  )
}

export default ReplaceClass
