import React, { useState, useEffect, useCallback } from 'react'
import { useUserStore } from 'store/GlobalContext'
import { ModalNotificationDescription } from 'components/organisms'
import { LoadingPage, Snackbar } from 'components/molecules'
import { getNotifications, updateNotifications } from 'services/api/shared'
import { useSnack, usePaginationBasic } from 'services/hooks'
import PageNotification from './components/PageNotification'
import ModalConfirmByDelete from './components/ModalConfirmByDelete'
import { ALL } from './constants'

const Notifications = () => {
  const [notification, setNotification] = useState([])
  const [openNotification, setOpenNotification] = useState(false)
  const [descriptionNotification, setDescriptionNotification] = useState({})
  const [openModal, setopenModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const { id: idUser } = useUserStore()
  const [count, setCount] = useState(null)
  const [filter, setFilter] = useState(2)

  const { snack, setSnack, snackOpen } = useSnack()

  const { page, totalPage, items, setPage, handleSetPage } = usePaginationBasic(
    notification,
    filter,
    count
  )

  const onHandleReadMessage = useCallback(
    async (item) => {
      const { id } = item
      setOpenNotification(!openNotification)
      setDescriptionNotification(item)
      await updateNotifications({ id })
    },
    [openNotification, descriptionNotification]
  )

  const handleConfirmModal = (item) => {
    setopenModal(!openModal)
    setDescriptionNotification(item)
  }

  const handleListNotifications = async () => {
    if (idUser) {
      setLoading(true)
      const params = {
        status_notification: ALL,
        limit: filter,
        page
      }

      const { data: response } = await getNotifications(params)
      setLoading(false)
      setCount(response?.count)
      setNotification(response?.data)

      setLoading(false)
    }
  }

  useEffect(() => {
    handleListNotifications()
  }, [page])

  return (
    <>
      <PageNotification
        notification={items}
        handleConfirmModal={handleConfirmModal}
        onHandleReadMessage={onHandleReadMessage}
        handleSetPage={handleSetPage}
        count={count}
        totalPage={totalPage}
        page={page}
        filter={filter}
        setFilter={setFilter}
        setPage={setPage}
      />

      <ModalNotificationDescription
        open={openNotification}
        handleClose={() => setOpenNotification(!openNotification)}
        descriptionNotification={descriptionNotification}
      />

      <ModalConfirmByDelete
        setLoading={setLoading}
        id={descriptionNotification?.id}
        openModal={openModal}
        setOpenModal={setopenModal}
        setSnack={setSnack}
        handleListNotifications={handleListNotifications}
        setPage={setPage}
        page={page}
      />

      <LoadingPage open={loading} />

      <Snackbar
        message={snack.message}
        severity={snack.severity}
        shouldOpen={snackOpen}
      />
    </>
  )
}

export default Notifications
