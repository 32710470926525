import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ListAlt, Search } from '@mui/icons-material'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { isEmpty } from 'services/helpers'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { AccordionFilter, TextNotFound } from 'components/atoms'
import { TableLog } from './components/Table'
import { FormSearchLogs } from './components/Form'
import {
  MESSAGE,
  nameAccordion,
  breadCrumbsItems,
  countDefault,
  defaultItemsPerPage,
  intialState,
  defaultPage
} from './constants'

const StudentInteractions = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { setSnack } = snackProps
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { register, name } = params
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [openModalConfirmCancel, setOpenModalConfirmCancel] = useState(false)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [formValues, setFormValues] = useState(intialState)
  const [limit, setLimit] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [listLogs, setListLogs] = useState([])

  const { page, totalPage, setPage, items, handleSetPage } = usePaginationBasic(
    listLogs,
    limit,
    count
  )

  const onHandleConfirm = () => {
    setOpenModalConfirmCancel(false)
    navigate(-1)
  }

  const resetPagination = () => {
    setLimit(defaultItemsPerPage)
    setPage(defaultPage)
  }

  const handleClean = ({ resetForm }) => {
    setListLogs([])
    setNotFound(false)
    resetForm(intialState)
    setFormValues(intialState)
    resetPagination()
  }

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      breadcrumbsItems={breadCrumbsItems({ register, name })}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() => setOpenModalConfirmCancel(true)}
      open={openModalConfirmCancel}
      handleCloseModal={() => setOpenModalConfirmCancel(false)}
      handleConfirmModal={() => onHandleConfirm()}
      content={
        <>
          <AccordionFilter
            title="Selecione e preencha os campos para realizar uma busca"
            expanded={expanded}
            setExpanded={setExpanded}
            nameAccordion={nameAccordion}
            margin
          >
            <FormSearchLogs
              register={register}
              handleClean={handleClean}
              setListLogs={setListLogs}
              formValues={formValues}
              setNotFound={setNotFound}
              setSnack={setSnack}
              setLoadingOpen={setLoadingOpen}
              setCount={setCount}
              page={page}
              limit={limit}
              resetPagination={resetPagination}
              setFormValues={setFormValues}
            />
          </AccordionFilter>

          {items && !isEmpty(items) ? (
            <TableLog
              register={register}
              rows={items}
              page={page}
              totalPage={totalPage}
              count={count}
              limit={limit}
              handleSetPage={handleSetPage}
              setLimit={setLimit}
              setPage={setPage}
              searchType={formValues.searchType}
              loadingOpen={loadingOpen}
            />
          ) : (
            <TextNotFound
              icon={notFound ? <Search /> : <ListAlt />}
              text={notFound ? MESSAGE.NOT_FOUND : MESSAGE.NOT_SEARCH}
            />
          )}
        </>
      }
    />
  )
}

export default StudentInteractions
