import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import { Clear, WarningAmber } from '@mui/icons-material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useMakeService, useSnack } from 'services/hooks'

import TAG_MANAGER from 'tagManager'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import { PageTitle } from 'components/atoms'
import { ROUTE } from 'services/constants'
import {
  initialState,
  breadCrumbsItems,
  MESSAGE,
  allUnitsOption
} from './constants'
import schema from './schema'
import { FormCampaign } from './components/Forms'
import { createCampaign, editCampaign, handleSearchCampaign } from './services'

import * as Styled from './style'

const CreateByEditCampaign = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { setSnack } = snackProps
  const [name, setName] = useState('')
  const [searchParams] = useSearchParams()
  const [openModal, setOpenModal] = useState(false)
  const [loadingOpen, setLoadingOpen] = useState(false)

  const paramsMakeService = {
    isUnits: true,
    setSnack
  }

  const { unitys } = useMakeService(paramsMakeService)
  const params = Object.fromEntries([...searchParams])
  const { isEdit, id } = params

  if (!unitys.some((item) => item.id === allUnitsOption.id)) {
    unitys.unshift(allUnitsOption)
  }
  const isToEdit = isEdit === 'true'
  const paramsToRequest = { navigate, setSnack, setLoadingOpen }

  const formik = useFormik({
    initialValues: initialState,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isToEdit) {
        editCampaign({
          id,
          values,
          ...paramsToRequest
        })
      } else {
        createCampaign({
          values,
          ...paramsToRequest
        })
      }
    },
    validationSchema: schema
  })

  const { values, errors, touched, resetForm, handleSubmit, setFieldValue } =
    formik

  const handleClear = () => {
    resetForm(initialState)
    navigate(ROUTE.ADMIN_FINANCIAL_CAMPAIGNS)
  }

  useEffect(() => {
    if (isToEdit)
      handleSearchCampaign({
        id,
        setName,
        setSnack,
        setFieldValue,
        setLoadingOpen
      })
  }, [isEdit])

  return (
    <>
      <TemplateDefaultHeaderByContent
        snackProps={snackProps}
        classNameHeader={
          isToEdit
            ? TAG_MANAGER.financeiro_btn_cancela_EditarCampanha
            : TAG_MANAGER.financeiro_btn_cancela_NovaCampanha
        }
        loadingOpen={loadingOpen}
        breadcrumbsNotLink={breadCrumbsItems(isToEdit, name)}
        onClickButton={() => {
          setOpenModal(!openModal)
        }}
        content={
          <>
            <Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12} sm={12} md={12}>
                <PageTitle title="Dados da Campanha" padding="0 0 0 0px" />
              </Grid>
              <Grid item xs={12} sm={12} md={12} marginBottom={2}>
                <Styled.Strong>
                  Os campos com &#39;
                  <Styled.Text>*</Styled.Text>&#39; são de preenchimento
                  obrigatório.
                </Styled.Strong>
              </Grid>
            </Grid>

            <Styled.Form autoComplete="off" onSubmit={handleSubmit}>
              <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
                <FormCampaign
                  unitys={unitys}
                  errors={errors}
                  values={values}
                  touched={touched}
                  optionDefault={allUnitsOption}
                  setFieldValue={setFieldValue}
                />
                <Styled.Grid item xs={12} sm={12} md={12}>
                  <ButtonFooterV2
                    size="medium"
                    labelClose="cancelar"
                    labelConfirm="salvar"
                    startIcon={<Clear />}
                    onClickClose={() => setOpenModal(true)}
                    classNameCancel={
                      isToEdit
                        ? TAG_MANAGER.financeiro_btn_canfinanceiro_btn_cancela_EditarCampanhacela_NovaCampanha
                        : TAG_MANAGER.financeiro_btn_cancela_NovaCampanha
                    }
                    classNameBtnConfirm={
                      isToEdit
                        ? TAG_MANAGER.financeiro_btn_salvar_EditarCampanha
                        : TAG_MANAGER.financeiro_btn_salvar_NovaCampanha
                    }
                  />
                </Styled.Grid>
              </Styled.GridContainer>
            </Styled.Form>
          </>
        }
      />
      <ModalConfirm
        open={openModal}
        title="Cancelar"
        textButtonConfirm="Sim"
        message={MESSAGE.cancel}
        textButtonNotConfirm="Não"
        colorButtonConfirm="error"
        onClickConfirm={() => handleClear()}
        onClickNotConfirm={() => setOpenModal(!openModal)}
        icon={<WarningAmber color="warning" fontSize="large" />}
        className={TAG_MANAGER.financeiro_btn_confirma_cancelaCampanha}
      />
    </>
  )
}

export default CreateByEditCampaign
