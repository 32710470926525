const paginationFilter = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' }
]

const headCells = [
  {
    id: 'discipline',
    label: 'Disciplina'
  },
  {
    id: 'workload_hours',
    numeric: true,
    label: 'Carga Horária'
  },
  {
    id: 'module',
    label: 'Módulo'
  },
  {
    id: 'type_score',
    label: 'Avaliação por'
  },
  {
    id: 'discipline_code',
    label: 'Código'
  },
  {
    id: 'joy_course_api_key',
    label: 'Disciplina Joy'
  },
  {
    id: 'reference_code',
    label: 'Disciplina Livre'
  }
]

const emptyValue = ''
const idNameList = 'id'
const defaultPage = 1
const defaultTotalPage = 10
const zeroWorkload = 0

export {
  headCells,
  idNameList,
  emptyValue,
  paginationFilter,
  defaultPage,
  defaultTotalPage,
  zeroWorkload
}
