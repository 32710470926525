import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

pdfMake.vfs = pdfFonts.pdfMake.vfs

const prepareFonts = () => {
  const { origin } = window.location

  return {
    Roboto: {
      normal: `${origin}/fonts/Roboto-Regular.ttf`,
      bold: `${origin}/fonts/Roboto-Bold.ttf`,
      italics: `${origin}/fonts/Roboto-Italic.ttf`,
      bolditalics: `${origin}/fonts/Roboto-BoldItalic.ttf`
    },
    RobotoLight: {
      normal: `${origin}/fonts/Roboto-Light.ttf`,
      bold: `${origin}/fonts/Roboto-Bold.ttf`,
      italics: `${origin}/fonts/Roboto-LightItalic.ttf`,
      bolditalics: `${origin}/fonts/Roboto-BoldItalic.ttf`
    }
  }
}

export default function PDF() {
  const open = ({ docDefinitions, onFinish, progressCallback, onError }) =>
    new Promise((resolve, reject) => {
      try {
        pdfMake.fonts = {
          ...pdfMake.fonts,
          ...prepareFonts()
        }
        const pdfGenerator = pdfMake.createPdf(docDefinitions)

        pdfGenerator.getBuffer(
          (buffer) => {
            pdfGenerator.open()
            onFinish()
            resolve()
          },
          {
            fontLayoutCache: true,
            progressCallback
          }
        )
      } catch (error) {
        console.error(error)
        onError(error)
        reject(error)
      }
    })

  return { open }
}
