import { ROUTE } from 'services/constants'

const defaultOption = 'Todas as Unidades'

const allUnitsOption = {
  id: null,
  polo: false,
  value: 'Todas as Unidades',
  label: 'Todas as Unidades'
}

const initialState = {
  end: '',
  name: '',
  begin: '',
  discount: '',
  installments: '',
  unit: defaultOption
}

const MESSAGE = {
  success: 'Campanha criada com sucesso.',
  success_edit: 'Campanha editada com sucesso.',
  cancel:
    'Deseja cancelar a operação? \n Você perderá as alterações que ainda não foram salvas.'
}

const breadCrumbsItems = (isEdit, name) => [
  {
    path: ROUTE.ADMIN_FINANCIAL_CAMPAIGNS,
    label: 'Financeiro'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_CAMPAIGNS,
    label: 'Campanhas'
  },
  {
    path: '',
    label: `<b>${isEdit ? name : 'Nova Campanha'} </b>`
  }
]

export {
  MESSAGE,
  initialState,
  defaultOption,
  allUnitsOption,
  breadCrumbsItems
}
