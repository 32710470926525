import FEEDBACK_SNACK from 'feedBackSnack'
import {
  createDocuments,
  deleteDocuments,
  listDocuments,
  listDocumentsTypes
} from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import { SUCCESS } from 'services/constants'
import { cleanObject } from 'services/helpers/cleanObject'
import { parseDataDocsType } from './helpers'
import { MESSAGE } from './constants'

const lisDocumentsTypes = async ({ setSnack, setDocumentsType }) => {
  setSnack('')

  const { data, error, status } = await listDocumentsTypes()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListTypesDocsProfessors
    })
  }

  return setDocumentsType(parseDataDocsType(data?.data))
}

const listDocsProfessor = async ({ id, setSnack, setListDocuments }) => {
  setSnack('')

  const { data, error, status } = await listDocuments(id)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDocsProfessors
    })
  }

  return setListDocuments(data?.data)
}

const createDocsProfessor = async ({
  id,
  setSnack,
  values,
  setLoadingOpen,
  handleClose,
  setListDocuments
}) => {
  setSnack('')
  setLoadingOpen(true)

  const payload = cleanObject({ ...values })

  const { error, status } = await createDocuments(id, payload)
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateDocsProfessors
    })
  }

  handleClose()
  setSnack(MESSAGE.SUCCESS, SUCCESS)

  return setTimeout(() => {
    listDocsProfessor({ id, setSnack, setListDocuments })
  }, 1500)
}

const deleteDocumentsProfessor = async ({
  id,
  idDocument,
  setListDocuments,
  setSnack,
  setLoadingOpen,
  setOpenModalConfirm
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = await deleteDocuments(idDocument)
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDeleteDocsProfessors
    })
  }

  setSnack(MESSAGE.DELETE_SUCCESS, SUCCESS)
  setOpenModalConfirm(false)

  return setTimeout(
    () => listDocsProfessor({ id, setSnack, setListDocuments }),
    1500
  )
}

const getAllServices = async ({
  id,
  setSnack,
  setLoadingOpen,
  setDocumentsType,
  setListDocuments
}) => {
  setLoadingOpen(true)

  await lisDocumentsTypes({
    setSnack,
    setDocumentsType
  })

  await listDocsProfessor({ id, setSnack, setListDocuments })

  return setLoadingOpen(false)
}

export { getAllServices, createDocsProfessor, deleteDocumentsProfessor }
