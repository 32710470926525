import { defaultItemsPerPage, defaultPage } from './constants'

const qntOne = 1

const paramsOnSubmit = ({ values, filter, pageActual }) =>
  new URLSearchParams({
    ...(values.unity && { unit: values?.unity }),
    ...(values.course && { course: values.course }),
    ...(values.level && { level: values.level }),
    ...(values.requiriments && { requirement: values.requiriments }),
    ...(values.inactive && { is_inactive: values.inactive }),
    limit: filter || defaultItemsPerPage,
    page: pageActual || defaultPage
  })

const payloadCreateCopy = ({ values, selectedIds }) => {
  const hasMultiSelectedIds = selectedIds?.length > qntOne

  return {
    ...(values.ammount && {
      value: parseFloat(values.ammount.replace(',', '.'))
    }),
    ...(values.unity && { unit: values.unity }),
    ...(values.requirement && { requirement: values.requirement }),
    ...(values.level && hasMultiSelectedIds && { level: values.level }),
    ...(values.course &&
      selectedIds?.length > qntOne && { course: values.course }),
    ...(values.costCenter &&
      selectedIds?.length > qntOne && {
        cost_center_name_cc3: values.costCenter
      }),
    ...(values.type &&
      selectedIds?.length > qntOne && { cost_center_type_cc4: values.type }),
    ...(values.subcategory &&
      selectedIds?.length > qntOne && {
        cost_center_subcategory_cc5: values.subcategory
      }),
    ...(values.nature && { cost_center_nature_cc6: values.nature }),
    ...(values.sector && { responsible_sector_id: values.sector }),
    ...(values.responsible && { responsible_user_id: values.responsible }),
    ids: selectedIds
  }
}

export { paramsOnSubmit, payloadCreateCopy }
