import styled from 'styled-components'
import { Alert as MuiAlert } from '@mui/material'

const Alert = styled(MuiAlert)`
  .MuiAlert-icon {
    margin-bottom: auto;
    margin-left: auto;
    margin-top: auto;
    color: ${({ theme }) => theme.palette.background.white};
  }
  .MuiAlert-message {
    margin-right: auto;
    color: ${({ theme }) => theme.palette.background.white};
  }
`

export { Alert }
