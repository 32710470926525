import { ROUTE } from 'services/constants'

const defaultPage = 1
const countDefault = 2
const defaultItemsPerPage = 10

const MESSAGE = {
  NO_SEARCH: 'Não encontramos nenhum registro de justificativas de faltas.'
}

const breadCrumbsItems = (studentName) => [
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: 'Alunos'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: studentName
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: 'Cursos e Turmas'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: 'Justificativas de faltas'
  }
]

export {
  MESSAGE,
  defaultPage,
  countDefault,
  breadCrumbsItems,
  defaultItemsPerPage
}
