import React from 'react'
import { Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { Button, DatePicker, Modal } from 'components/atoms'
import { InputFile, Select } from 'components/molecules'
import { toastNotificationError } from 'services/helpers'

import TAG_MANAGER from 'tagManager'
import * as Styled from './style'
import {
  initialValues,
  labelContent,
  messageErrorFileForbbiden,
  optionsJustifyAbsence,
  typesFileAllowed
} from './constants'
import schema from './schema'

const JustifyAbsenceModal = ({ type, open, onSubmitForm, handleClose }) => {
  const onSelectFile = (e, setFieldValue) => {
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        setFieldValue('file', reader.result.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const showErrorFileForbbiden = (setFieldValue) => {
    setFieldValue('file', '')
    toastNotificationError(messageErrorFileForbbiden, 3000)
  }

  return (
    <Modal open={open} onClose={handleClose} maxWidth="md">
      <Formik
        onSubmit={onSubmitForm}
        validationSchema={schema}
        initialValues={initialValues}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          submitForm,
          handleBlur,
          handleSubmit,
          setFieldValue
        }) => (
          <Styled.FormBox onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.CardContainer container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  Nova justificativa de falta
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <InputFile
                  hasSize
                  width="auto"
                  typeFile={type}
                  typesFileAllowed={typesFileAllowed}
                  idUpload="upload"
                  onBlur={handleBlur}
                  imgSrc={values?.file}
                  labelBox={labelContent}
                  helperText={touched.file && errors.file}
                  errorTypeFile={errors.file && touched.file}
                  onChange={(e) => onSelectFile(e, setFieldValue)}
                  showErrorFileForbbiden={() =>
                    showErrorFileForbbiden(setFieldValue)
                  }
                />
                {Boolean(touched.file && errors.file) && (
                  <Styled.TextError>{errors.file}</Styled.TextError>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <DatePicker
                  fullWidth
                  label="De"
                  id="startDate"
                  onBlur={handleBlur}
                  value={values?.startDate || ''}
                  error={errors.startDate && touched.startDate}
                  helperText={touched.startDate && errors.startDate}
                  onChange={(newValue) => setFieldValue('startDate', newValue)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <DatePicker
                  fullWidth
                  id="endDate"
                  label="Até"
                  onBlur={handleBlur}
                  value={values?.endDate || ''}
                  error={errors.endDate && touched.endDate}
                  helperText={touched.endDate && errors.endDate}
                  onChange={(newValue) => setFieldValue('endDate', newValue)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Select
                  fullWidth
                  onBlur={handleBlur}
                  id="reasonForAbsence"
                  label="Motivo da falta"
                  optionsList={optionsJustifyAbsence}
                  value={values?.reasonForAbsence || ''}
                  error={errors.reasonForAbsence && touched.reasonForAbsence}
                  helperText={
                    touched.reasonForAbsence && errors.reasonForAbsence
                  }
                  onChange={(e) =>
                    setFieldValue('reasonForAbsence', e.target.value)
                  }
                />
              </Grid>

              <Styled.WrapperButtons item xs={12}>
                <Button variant="outlined" onclick={() => handleClose()}>
                  Cancelar
                </Button>
                <Button
                  disabled={!isValid}
                  onClick={submitForm}
                  className={
                    TAG_MANAGER.secretaria_btn_acessa_justificativasDeFaltas
                  }
                >
                  Salvar
                </Button>
              </Styled.WrapperButtons>
            </Styled.CardContainer>
          </Styled.FormBox>
        )}
      </Formik>
    </Modal>
  )
}

export default JustifyAbsenceModal
