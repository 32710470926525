import AsyncSelect from 'react-select/async'
import styled from 'styled-components'

const SelectCustom = styled(AsyncSelect)`
  height: 3.513rem;

  & .react-select__control {
    background-color: transparent;
    height: 3.513rem;
  }

  & .react-select__menu {
    z-index: 999;
  }
`

export { SelectCustom }
