import { httpGet } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getCourses = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_COURSES}?${convertToParams(params)}`
  )

  return response
}

export const getEvaluations = async () => {
  const response = await httpGet(`${ENDPOINT.EVALUATIONS}`)

  return response
}

export const getModalities = async () => {
  const response = await httpGet(`${ENDPOINT.MODALITIES}`)

  return response
}

export const getUnitsRegions = async (typeEndpoint) => {
  const response = await httpGet(`${ENDPOINT.UNITS}/regions?${typeEndpoint}`)

  return response
}

export const getUnits = async () => {
  const response = await httpGet(ENDPOINT.UNITS)

  return response
}

export const getLevel = async () => {
  const response = await httpGet(ENDPOINT.ADMIN_COURSE_LEVEL)

  return response
}

export const getAddressForCEP = async (cep) => {
  if (cep) {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
    const data = await response.json()
    return data
  }

  return []
}

export const getPeriod = async () => {
  const response = await httpGet(ENDPOINT.ADMIN_COURSES_SHIFTS_LIST)

  return response
}

export const getCourse = async (level, unity, active) => {
  const params = {
    ...(unity && { unit_name: unity }),
    ...(level && { level_name: level }),
    active
  }

  const response = await httpGet(
    `${ENDPOINT.ADMIN_COURSES_LIST}?${convertToParams(params)}`
  )

  return response
}

export const getBanckAccount = async () => {
  const response = await httpGet(`${ENDPOINT.ADMIN_BANK_ACCOUNTS}`)

  return response
}

export const getDocuments = async () => {
  const response = await httpGet(`${ENDPOINT.ADMIN_MANAGEMENT_DOCUMENTS}/types`)

  return response
}

export const getContractModel = async () => {
  const response = await httpGet(`${ENDPOINT.ADMIN_CONTRACT_MODEL}`)

  return response
}

export const getModules = async () => {
  const response = await httpGet(`${ENDPOINT.ADMIN_LEVEL_MODULE}`)

  return response
}

export const getGroup = async () => {
  const response = await httpGet(`${ENDPOINT.SHARED.GROUPS}`)

  return response
}

export const getTypesCatefories = async () => {
  const response = await httpGet(ENDPOINT.OCCURRENCE.CATEGORY_LIST)

  return response
}

export const getTypeOfOccurrence = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.OCCURRENCE.LIST_TYPE_OF_OCCURRENCE}?occurrence_category=${id}`
  )

  return response
}

export const getStudentByCourse = async (register, onlyActive = false) => {
  const response = await httpGet(
    `${ENDPOINT.STUDENTS_COURSES}?register=${register}${
      onlyActive ? '&active=true' : ''
    }`
  )

  return response
}

export const getSchoolClassesByStudent = async (register, id) => {
  const response = await httpGet(
    `${ENDPOINT.SCHOOL_CLASSES_STUDENT}?register=${register}&course_id=${id}`
  )

  return response
}

export const getStudentByCpf = async (cpf) => {
  const response = await httpGet(`${ENDPOINT.STUDENTS_DETAILS}?cpf=${cpf}`)

  return response
}

export const getCountry = async () => {
  try {
    // eslint-disable-next-line global-require
    const countries = require('../country/index.json')

    return countries || []
  } catch (error) {
    console.error('Error fetching countries:', error)
    return []
  }
}

export const getStates = async () => {
  try {
    // eslint-disable-next-line global-require
    const { states } = await require('../states/index.json')

    const response = states?.map(({ value, label }) => ({ label, value }))

    return response || []
  } catch (error) {
    console.error('Error fetching states:', error)
    return []
  }
}

export const getCityByState = async (stateId) => {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { cities } = await require(`../cities/${stateId}.json`)

    const citiesFormatted = cities?.map(({ city }) => ({
      label: city,
      value: city
    }))

    return citiesFormatted || []
  } catch (error) {
    console.error('error :', error)
    console.error('Cidade não encontrada para esse Estado. Tente Novamente!')
    return []
  }
}
