const isDisabledButton = ({ beginAt, endAt, cpf }) => {
  const enabled = (beginAt && endAt) || cpf
  return !enabled
}

const handleFormated = ({ setAmoutFormated, details }) => {
  const amount = details?.amount || 0
  const formated = amount.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
  setAmoutFormated(formated)
}
export { isDisabledButton, handleFormated }
