import { httpDelete, httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getDisciplines = async (params) => {
  const response = await httpGet(`${ENDPOINT.DISCIPLINES.CRUD}`, {
    params
  })

  return response
}

export const getDisciplinesById = async (id) => {
  const response = await httpGet(`${ENDPOINT.DISCIPLINES.CRUD}/${id}`)

  return response
}

export const getTeachingPlanById = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.DISCIPLINES.CRUD}/${id}/teaching_plan`
  )

  return response
}

export const getJoyCourses = async () => {
  const response = await httpGet(ENDPOINT.ADMIN_MANAGEMENT_JOY)

  return response
}

export const getLevelCourses = async (levels) => {
  const levelsString = `levels[]=${levels?.join('&levels[]=')}`
  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_LEVEL_COURSES}?${
      levels?.length ? levelsString : ''
    }`
  )

  return response
}

export const copySubjects = async (id, payload) => {
  const response = await httpPost(`${ENDPOINT.DISCIPLINES.CRUD}/${id}/copy`, {
    ...payload
  })

  return response
}

export const saveSubjects = async (params) => {
  const response = await httpPost(ENDPOINT.DISCIPLINES.CRUD, params)

  return response
}

export const updateSubjects = async (params, idSubject) => {
  const response = await httpPut(
    `${ENDPOINT.DISCIPLINES.CRUD}/${idSubject}`,

    {
      ...params
    }
  )

  return response
}

export const updateTeachingPlanById = async (params, id) => {
  const response = await httpPut(
    `${ENDPOINT.DISCIPLINES.CRUD}/${id}/teaching_plan`,

    {
      ...params
    }
  )

  return response
}

export const getRegisterEadContent = async ({ id, params }) => {
  const response = await httpGet(
    `${ENDPOINT.DISCIPLINES.CRUD}/${id}/${
      ENDPOINT.DISCIPLINES.CONTENTS
    }?${convertToParams({ ...params })}`
  )

  return response
}

export const createByUpdateRegisterEadContent = async ({
  idSubject,
  id,
  isEdit,
  payload
}) => {
  const response = isEdit
    ? await httpPut(
        `${ENDPOINT.DISCIPLINES.CRUD}/${id}/${ENDPOINT.DISCIPLINES.CONTENTS}/${idSubject}`,
        {
          ...payload
        }
      )
    : await httpPost(
        `${ENDPOINT.DISCIPLINES.CRUD}/${id}/${ENDPOINT.DISCIPLINES.CONTENTS}`,
        {
          ...payload
        }
      )

  return response
}

export const deleteRegisterEadContent = async ({ idSubject, id }) => {
  const response = await httpDelete(
    `${ENDPOINT.DISCIPLINES.CRUD}/${id}/${ENDPOINT.DISCIPLINES.CONTENTS}/${idSubject}`
  )

  return response
}
