export const headCells = [
  {
    id: 'num',
    numeric: false,
    disablePadding: true,
    label: '#'
  },
  {
    id: 'aluno',
    numeric: false,
    disablePadding: true,
    label: 'Aluno'
  },
  {
    id: 'register',
    numeric: false,
    disablePadding: true,
    label: 'ID'
  }
]

export const VALUE_TAB_ID = 'ID'
export const VALUE_WIDTH_TAB_ID = 100
