import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'

import { DatePicker } from 'components/atoms'
import { dateAddDays, parseUnits } from 'services/helpers'
import { useMakeService } from 'services/hooks'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'

import { getSituationsList } from './service'
import { days, defaultValues, makeServiceHookParams } from './constants'

import * as Styled from './style'

export default function SearchForm({ handleSearch, setSnack }) {
  const [listSituations, setListSituations] = useState([])

  const { unitys } = useMakeService(makeServiceHookParams)
  const { handleSubmit, setFieldValue, values, resetForm } = useFormik({
    initialValues: defaultValues,
    onSubmit: handleSearch,
    validateOnMount: true
  })

  const listUnitys = unitys?.map((unity) => ({ ...unity, value: unity?.label }))

  const disabledButton = ({ unit, status }) => !unit || !status

  const handleResetForm = () => {
    handleSearch(null)
    resetForm()
  }
  useEffect(() => {
    if (values?.unit) {
      getSituationsList({
        setSnack,
        setListSituations
      })
    }
  }, [values?.unit])

  return (
    <Styled.Container>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12} sm={12} md={4}>
          <Select
            label="Unidade*"
            value={values?.unit}
            optionsList={parseUnits(listUnitys)}
            onChange={(e) => setFieldValue('unit', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Select
            label="Situação*"
            value={values.status}
            optionsList={listSituations}
            onChange={(e) => setFieldValue('status', e.target.value)}
            disabled={!values?.unit}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Nome da Disciplina (opcional)"
            value={values.discipline}
            onChange={(e) => setFieldValue('discipline', e.target.value)}
            disabled={!values?.unit}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <DatePicker
            fullWidth
            id="startDate"
            label="Período inicial"
            value={values.startDate}
            onChange={(newValue) => {
              setFieldValue('startDate', newValue)
              setFieldValue('endDate', null)
            }}
            maxDate={values?.endDate ?? undefined}
            disabled={!values?.unit}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <DatePicker
            fullWidth
            id="endDate"
            label="Período final"
            value={values.endDate}
            onChange={(newValue) => setFieldValue('endDate', newValue)}
            minDate={values?.startDate}
            maxDate={dateAddDays(values?.startDate, days)}
            error={!!values?.startDate && !values?.endDate}
            helperText={
              !!values?.startDate &&
              !values?.endDate &&
              'Informe o período final'
            }
            disabled={!values?.unit}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <ButtonFooterV2
            disabledClose={disabledButton(values)}
            disabledConfirm={disabledButton(values)}
            labelClose="Limpar"
            labelConfirm="Buscar"
            onClickClose={handleResetForm}
            onClickConfirm={() => handleSubmit()}
            size="large"
            startIcon={<Clear />}
          />
        </Grid>
      </Grid>
    </Styled.Container>
  )
}

SearchForm.propTypes = {
  handleSearch: PropTypes.func
}

SearchForm.defaultProps = {
  handleSearch: () => {}
}
