import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box, IconButton, TableRow, Tooltip } from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { ROUTE } from 'services/constants'
import { convertToParams } from 'services/helpers'
import { DropDown } from 'components/molecules'
import * as Styled from './style'
import { getMenuItems } from './helpers'

const TableFooter = ({
  row,
  handleUserSendEmail,
  handleOpenArchive,
  handleSendInteractions,
  handleDisciplinesClasses,
  handleModalConfirm
}) => {
  const emptyValue = ''
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const navigate = useNavigate()

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const paramsGetMenu = {
    item: row,
    isActive: row?.blocked,
    handleOpenArchive,
    handleSendInteractions,
    handleDisciplinesClasses,
    handleModalConfirm,
    handleClose,
    navigate
  }

  return (
    <>
      <TableRow hover tabIndex={-1} key={row?.id}>
        <Styled.TableCellTitle>
          <div>
            {!row.blocked ? (
              <Link
                to={`${ROUTE.ADMIN_HR_DETAILS_PROFESSOR}?${convertToParams({
                  isEdit: true,
                  id: row?.id,
                  name: row?.name
                })}`}
              >
                {row?.name || emptyValue}
              </Link>
            ) : (
              <span>{row?.name || emptyValue}</span>
            )}

            <DropDown
              anchorEl={anchorEl}
              handleClose={handleClose}
              open={open}
              menuItems={getMenuItems(paramsGetMenu)}
              isActive={row?.blocked}
            />
            <IconButton onClick={handleClick} disableRipple>
              <MoreVert />
            </IconButton>
          </div>
        </Styled.TableCellTitle>

        <Styled.TableCell onClick={() => handleUserSendEmail(row)}>
          <Tooltip title="Enviar e-mail" placement="top" arrow>
            <Styled.BoxLink $link>{row?.email || emptyValue}</Styled.BoxLink>
          </Tooltip>
        </Styled.TableCell>
        <Styled.TableCellEnrollment>
          <Tooltip title="CPF" placement="top" arrow>
            <Box>{row?.cpf || emptyValue}</Box>
          </Tooltip>
        </Styled.TableCellEnrollment>
        <Styled.TableCellEnrollment>
          <Tooltip title="Matrícula" placement="top" arrow>
            <Box>{row?.id || emptyValue}</Box>
          </Tooltip>
        </Styled.TableCellEnrollment>
      </TableRow>
    </>
  )
}

export { TableFooter }
