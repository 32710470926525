import { httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getCampaigns = async (params) => {
  const response = await httpGet(ENDPOINT.CAMPAIGNS.CAMPAIGN, {
    params
  })

  return response
}

export const getDetailsCampaign = async (id) => {
  const response = await httpGet(`${ENDPOINT.CAMPAIGNS.CAMPAIGN}/${id}`)

  return response
}

export const postCampaign = async (body) => {
  const response = await httpPost(ENDPOINT.CAMPAIGNS.CAMPAIGN, {
    ...body
  })

  return response
}

export const putCampaign = async (id, body) => {
  const response = await httpPut(`${ENDPOINT.CAMPAIGNS.CAMPAIGN}/${id}`, {
    ...body
  })

  return response
}
