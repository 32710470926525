/* eslint-disable no-useless-escape */
import * as yup from 'yup'

const schema = yup.object().shape({
  endDate: yup.string().nullable().required('Campo obrigatório'),
  startDate: yup.string().nullable().required('Campo obrigatório'),
  reasonForAbsence: yup.string().nullable().required('Campo obrigatório'),
  file: yup
    .string()
    .nullable()
    .required('Somente arquivos em PDF, PNG ou JPG, com no mínimo 5MB.')
})

export default schema
