import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form as FormikForm, Formik } from 'formik'
import { ListAlt, Search } from '@mui/icons-material'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { useMakeService, usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import { IsEmpty, isEmpty, mergeInitialValues } from 'services/helpers'
import {
  countDefault,
  defaultItemsPerPage,
  initialState,
  MESSAGE
} from './constants'
import { Form } from './components/Form'
import { Table } from './components/Table'
import { onSubmit } from './services'
import { useHRProfessorProvider } from './Context/FormnContext'

const ListProfessorTypes = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [isButtonSet, setIsButtonSet] = useState(false)
  const [listProfessorTypes, setListProfessorTypes] = useState([])
  const [listCategories, setListCategories] = useState([])
  const [valuesForm, setValuesForm] = useState(null)
  const [nameTypeSingle, setNameTypeSingle] = useState('')
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)

  const { formValues, setFormValues, resetFormValues } =
    useHRProfessorProvider()

  const paramsMakeService = {
    isUnits: true,
    setSnack
  }
  const { unitys } = useMakeService(paramsMakeService)

  const {
    page: pageActual,
    totalPage,
    setPage,
    items
  } = usePaginationBasic(listProfessorTypes, filter, count)

  const isSearchParam = location?.state?.savedSearch

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  const cancelAutomaticSearch = () => {
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  const onClear = () => {
    setIsButtonSet(false)
    setValuesForm(initialState)
    resetFormValues()
    setListProfessorTypes([])
    setNotSearch(true)
    cancelAutomaticSearch()
  }

  useEffect(() => {
    if (isSearchParam && formValues.name && !isEmpty(unitys)) {
      setIsButtonSet(true)
      return onSubmit({
        values: formValues,
        setSnack,
        setLoadingOpen,
        filter,
        pageActual,
        setFormValues,
        setCount,
        setNotSearch,
        setListProfessorTypes
      })
    }

    return (
      !isSearchParam && setFormValues(initialState) && setListProfessorTypes([])
    )
  }, [isSearchParam, !isEmpty(unitys)])

  useEffect(() => {
    if (isButtonSet)
      onSubmit({
        values: formValues,
        setSnack,
        setLoadingOpen,
        filter,
        pageActual,
        setFormValues,
        setCount,
        setNotSearch,
        setListProfessorTypes
      })
  }, [pageActual, filter])

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  return (
    <TemplateDefaultFormAndResult
      titleHeader="RH - Professores"
      snackProps={snackProps}
      labelButtonHeader="novo professor"
      loadingOpen={loadingOpen}
      linkHeader={ROUTE.ADMIN_HR_CREATE_PROFESSOR}
      listItems={items}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      classNameHeader={TAG_MANAGER.rh_btn_criar_novoProfessor}
      formHeader={
        <Formik
          initialValues={initialValues}
          onSubmit={(values) =>
            onSubmit({
              values,
              setSnack,
              setLoadingOpen,
              filter,
              pageActual,
              setFormValues,
              setCount,
              setNotSearch,
              setListProfessorTypes
            })
          }
          enableReinitialize
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <FormikForm onSubmit={handleSubmit} noValidate autoComplete="off">
              <Form
                setFieldValue={setFieldValue}
                setFilter={setFilter}
                setPage={setPage}
                setIsButtonSet={setIsButtonSet}
                onClear={onClear}
                values={values}
              />
            </FormikForm>
          )}
        </Formik>
      }
    >
      {listProfessorTypes && !IsEmpty(listProfessorTypes) && (
        <Table
          rows={listProfessorTypes}
          onRemove={listProfessorTypes}
          formValues={formValues}
          setFormValues={setFormValues}
          setSnack={setSnack}
          setCount={setCount}
          onCopy={listProfessorTypes}
          count={count}
          setLoadingOpen={setLoadingOpen}
          nameTypeSingle={nameTypeSingle}
          setNameTypeSingle={setNameTypeSingle}
          listCategories={listCategories}
          totalPage={totalPage}
          setFilter={setFilter}
          setNotSearch={setNotSearch}
          setListProfessorTypes={setListProfessorTypes}
          setPage={setPage}
          filter={filter}
          pages={pageActual}
          unitys={unitys}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ListProfessorTypes
