import FEEDBACK_SNACK from 'feedBackSnack'
import { ROUTE } from 'services/constants'
import {
  formatDate,
  formatDateEnv,
  formatDateIso,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'
import {
  putCampaign,
  postCampaign,
  getDetailsCampaign
} from 'services/api/admin/financial/Campaigns'
import { MESSAGE, defaultOption } from './constants'

export const handleSearchCampaign = async ({
  id,
  setSnack,
  setName,
  setFieldValue,
  setLoadingOpen
}) => {
  setLoadingOpen(true)

  const { data, error, status } = await getDetailsCampaign(id)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCampaigns
    })
  }
  setName(data.name)
  const newData = {
    ...data,
    end: formatDateIso(formatDate(data.end)),
    begin: formatDateIso(formatDate(data.begin)),
    unit: data.unit ?? defaultOption
  }

  const fieldsToUpdate = [
    'end',
    'name',
    'unit',
    'begin',
    'discount',
    'installments'
  ]

  return fieldsToUpdate.forEach((field) => {
    setFieldValue(field, newData[field] || '')
  })
}

export const createCampaign = async ({
  values,
  navigate,
  setSnack,
  setLoadingOpen
}) => {
  setLoadingOpen(true)

  const data = cleanObject({
    end: values?.end,
    name: values?.name,
    begin: values?.begin,
    discount: values?.discount,
    installments: values?.installments
  })

  const body = {
    ...data,
    unit: values?.unit !== defaultOption ? values?.unit : null
  }

  const { error, status } = await postCampaign(body)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateCampaigns
    })
  }

  navigate(`${ROUTE.ADMIN_FINANCIAL_CAMPAIGNS}`)
  return toastNotificationSuccess(MESSAGE.success, 1500)
}

export const editCampaign = async ({
  id,
  values,
  navigate,
  setSnack,
  setLoadingOpen
}) => {
  setLoadingOpen(true)

  const params = cleanObject({
    name: values?.name,
    begin: values?.begin ? formatDateEnv(values?.begin) : null,
    installments: values?.installments
  })

  const body = {
    ...params,
    end: values?.end ? formatDateEnv(values?.end) : null,
    unit: values?.unit !== defaultOption ? values?.unit : null
  }

  const { error, status } = await putCampaign(id, body)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditCampaigns
    })
  }

  navigate(`${ROUTE.ADMIN_FINANCIAL_CAMPAIGNS}`)
  return toastNotificationSuccess(MESSAGE.success_edit, 1500)
}
