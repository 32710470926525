import styled from 'styled-components'

const Link = styled.a`
  &:link,
  :active,
  :visited,
  :hover,
  :active {
    text-decoration: none;
  }
`

export { Link }
