import styled from 'styled-components'
import { TableCell as MuiTableCell } from '@mui/material'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 27rem;

    & > div {
      font-weight: 300;
      display: flex;
    }
  }
`

const TableCellDate = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 15rem;

    & > div {
      font-weight: 300;
      display: flex;
    }
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    display: flex;
    align-items: center;
    text-align: left;
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
    padding: 6px 16px;

    & > div {
      cursor: pointer !important;
      display: flex;
      align-items: center;
      width: 27rem;

      & > span {
        color: ${({ theme }) => theme.palette.primary.main} !important;
        margin-right: 20px;
        padding: 5px 0;
      }

      button {
        padding: 0 8px;
      }

      & > div {
        display: none;
      }

      &:hover > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`

const ContainerIcon = styled.div`
  padding: 0;

  & svg {
    color: ${({ theme }) => theme.palette.red.main};
  }
`

export { TableCell, TableCellTitle, TableCellDate, ContainerIcon }
