export const noteDiaryGeneralMock = {
  students: [
    {
      student: {
        enrollment: '91303469',
        name: 'Antonio Garcia Mariano Souza',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 903728,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 0,
        activitiesGrade: 0,
        partialGrade: 0,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 0,
        approvedByGrade: false
      },
      frequency: {
        absence: 55,
        justifiedAbsence: 0,
        presence: 0,
        absenceInPercentage: 73.33333333333333,
        maximumAbsence: 18.75,
        approvedByFrequency: false
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91331183',
        name: 'Haiesca Martins',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 906531,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 1,
        activitiesGrade: 0,
        partialGrade: 1,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 1,
        approvedByGrade: false
      },
      frequency: {
        absence: 15,
        justifiedAbsence: 0,
        presence: 40,
        absenceInPercentage: 20,
        maximumAbsence: 18.75,
        approvedByFrequency: true
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91330480',
        name: 'JAQUELINE MATOS FERREIRA',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 898107,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 0,
        activitiesGrade: 0,
        partialGrade: 0,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 0,
        approvedByGrade: false
      },
      frequency: {
        absence: 25,
        justifiedAbsence: 0,
        presence: 30,
        absenceInPercentage: 33.33333333333333,
        maximumAbsence: 18.75,
        approvedByFrequency: false
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91330104',
        name: 'KAWANE MATIAS DOS SANTOS',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 897636,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 1.2,
        activitiesGrade: 0,
        partialGrade: 1.2,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 1,
        approvedByGrade: false
      },
      frequency: {
        absence: 15,
        justifiedAbsence: 0,
        presence: 40,
        absenceInPercentage: 20,
        maximumAbsence: 18.75,
        approvedByFrequency: true
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91329993',
        name: 'Larissa Ferreira da Cruz',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 897513,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 1,
        activitiesGrade: 0,
        partialGrade: 1,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 1,
        approvedByGrade: false
      },
      frequency: {
        absence: 0,
        justifiedAbsence: 0,
        presence: 55,
        absenceInPercentage: 0,
        maximumAbsence: 18.75,
        approvedByFrequency: true
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91330742',
        name: 'MARIA VANESSA NERES DANTAS',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 898392,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 1.4,
        activitiesGrade: 0,
        partialGrade: 1.4,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 1,
        approvedByGrade: false
      },
      frequency: {
        absence: 10,
        justifiedAbsence: 0,
        presence: 45,
        absenceInPercentage: 13.333333333333334,
        maximumAbsence: 18.75,
        approvedByFrequency: true
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91331142',
        name: 'Paulo Cezar Dos Santos',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 898739,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 2,
        activitiesGrade: 0,
        partialGrade: 2,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 2,
        approvedByGrade: false
      },
      frequency: {
        absence: 5,
        justifiedAbsence: 0,
        presence: 50,
        absenceInPercentage: 6.666666666666667,
        maximumAbsence: 18.75,
        approvedByFrequency: true
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91330071',
        name: 'Radson Francisco Rodrigues Nascimento',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 897605,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 0.6,
        activitiesGrade: 0,
        partialGrade: 0.6,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 1,
        approvedByGrade: false
      },
      frequency: {
        absence: 0,
        justifiedAbsence: 0,
        presence: 55,
        absenceInPercentage: 0,
        maximumAbsence: 18.75,
        approvedByFrequency: true
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91330115',
        name: 'STEPHANY ALVES DOS SANTOS CERQUEIRA',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 897626,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 1.2,
        activitiesGrade: 0,
        partialGrade: 1.2,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 1,
        approvedByGrade: false
      },
      frequency: {
        absence: 10,
        justifiedAbsence: 0,
        presence: 45,
        absenceInPercentage: 13.333333333333334,
        maximumAbsence: 18.75,
        approvedByFrequency: true
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91332184',
        name: 'Sueli Mabely dias dos santos',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 903712,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 0.8,
        activitiesGrade: 0,
        partialGrade: 0.8,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 1,
        approvedByGrade: false
      },
      frequency: {
        absence: 20,
        justifiedAbsence: 0,
        presence: 35,
        absenceInPercentage: 26.666666666666668,
        maximumAbsence: 18.75,
        approvedByFrequency: false
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91332144',
        name: 'Thamires Silva Hilario',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 903689,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 1,
        activitiesGrade: 0,
        partialGrade: 1,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 1,
        approvedByGrade: false
      },
      frequency: {
        absence: 10,
        justifiedAbsence: 0,
        presence: 45,
        absenceInPercentage: 13.333333333333334,
        maximumAbsence: 18.75,
        approvedByFrequency: true
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    },
    {
      student: {
        enrollment: '91331489',
        name: 'Wellington Henrique Mascorenhas Valença Silva Do Nascimento',
        active: 1,
        has_academic_performance: 0,
        academic_performance_grade: null,
        student_course_id: 903051,
        approved: false,
        situation: 'default'
      },
      gradeResume: {
        assessmentGrade: 1.4,
        activitiesGrade: 0,
        partialGrade: 1.4,
        recoveryGrade: 0,
        reclassificationGrade: null,
        finalGrade: 1,
        approvedByGrade: false
      },
      frequency: {
        absence: 5,
        justifiedAbsence: 0,
        presence: 50,
        absenceInPercentage: 6.666666666666667,
        maximumAbsence: 18.75,
        approvedByFrequency: true
      },
      grades: {
        B1_AVC_DOCENTE: [],
        B1_AVA_INTEGRADA: [],
        B2_AVC_DOCENTE: [],
        B2_AVA_INTEGRADA: [],
        B3_AVC_DOCENTE: [],
        B3_AVA_INTEGRADA: [],
        B4_AVC_DOCENTE: [],
        B4_AVA_INTEGRADA: [],
        REC_FINAL: [],
        RECLASSIFICATION: [],
        CONCEPT_5: []
      }
    }
  ],
  companyInfo: [
    'ESSA Educação Profissional S.A.',
    'Rua Corifeu de Azevedo Marques, 15 Centro - Carapicuíba - SP',
    'Autorização: Portaria da Dirigente Regional de Ensino de 19/01/2019 - Seção I - Pág. 26'
  ],
  classInfo: {
    classStartDate: '2024-06-10',
    classEndDate: '2026-08-30',
    course: 'TÉCNICO EM ENFERMAGEM',
    classroom: 'CAR243AMTENF',
    unit: '(SP) Carapicuíba',
    pole: '',
    minimumGrade: 6,
    observation: null,
    directorUnitSignatureImage:
      'https://pincelatomicoapp.s3.sa-east-1.amazonaws.com/development/uploads/1721768577245238790a4ee2d480281c65cf8bb5bf73d.jpeg',
    directorUnitName: 'Andre Boranga Leite',
    directorUnitIdentifier: 'RG: 42.694.207-3',
    directorUnitRole: 'Diretor',
    secretaryUnitSignatureImage:
      'https://pincelatomicoapp.s3.sa-east-1.amazonaws.com/development/uploads/17217685773427decef02b8d54b3e9a26f9078d86bb57.png',
    secretaryUnitName: 'Marcos Dionisio da Silva',
    secretaryUnitIdentifier: 'RG: 53.576.747-X',
    secretaryUnitRole: 'Secretaria',
    unitCorporateReason: 'ESSA Educação Profissional S.A.',
    unitAddress: 'Rua Corifeu de Azevedo Marques',
    unitDistrict: 'Centro',
    unitAddressNumber: '15',
    unitCity: 'Carapicuíba',
    unitUf: 'SP',
    unitResolution:
      'Autorização: Portaria da Dirigente Regional de Ensino de 19/01/2019 - Seção I - Pág. 26',
    unitCNPJ: '08.690.642/0009-29',
    unitCEP: '06320-090',
    directorPoleSignatureImage: null,
    directorPoleName: null,
    directorPoleIdentifier: null,
    directorPoleRole: null,
    secretaryPoleSignatureImage: null,
    secretaryPoleName: null,
    secretaryPoleIdentifier: null,
    secretaryPoleRole: null,
    poleCorporateReason: null,
    poleAddress: null,
    poleDistrict: null,
    poleAddressNumber: null,
    poleCity: null,
    poleUf: null,
    poleResolution: null,
    poleCNPJ: null,
    poleCEP: null,
    classTime: 60,
    companyId: 81,
    module: 0,
    shift: 'Semanal - Manhã',
    level: 'Curso Técnico',
    frequencyType: 'DISCIPLINA',
    minimumFrequency: 25,
    sumModule: 1,
    yearGrade: '2024',
    disciplineStartDate: '2024-06-10T00:00:00.000Z',
    disciplineEndDate: '2024-08-16T00:00:00.000Z',
    curricularComponent: 'Assistência de Enfermagem em Clínica Cirúrgica',
    totalExpectedWorkload: 75,
    responsibleTeacher: 'DENIZE APARECIDA NHONCANCE',
    numberOfCompletedLessons: '55',
    totalWorkloadCompleted: 55,
    period: '10/06/2024 até 30/08/2026',
    signatures: [
      {
        name: 'Andre Boranga Leite',
        role: 'Diretor',
        identifier: 'RG: 42.694.207-3',
        image:
          'https://pincelatomicoapp.s3.sa-east-1.amazonaws.com/development/uploads/1721768577245238790a4ee2d480281c65cf8bb5bf73d.jpeg'
      }
    ],
    responsibleTeachers: [
      { name: 'DENIZE APARECIDA NHONCANCE', completedLessons: 55 }
    ]
  },
  activities: [
    { id: 2003, activity: 'Reclassificação' },
    { id: 2012, activity: '5_CONCEITO' },
    { id: 2013, activity: '1B_AVC_DOCENTE' },
    { id: 2014, activity: '2B_AVC_DOCENTE' },
    { id: 2015, activity: '3B_AVC_DOCENTE' },
    { id: 2016, activity: '4B_AVC_DOCENTE' },
    { id: 2017, activity: '1B_AVA_INTEGRADA' },
    { id: 2018, activity: '2B_AVA_INTEGRADA' },
    { id: 2019, activity: '3B_AVA_INTEGRADA' },
    { id: 2020, activity: '4B_AVA_INTEGRADA' },
    { id: 2025, activity: 'REC_FINAL' }
  ],
  hasGrades: true
}
