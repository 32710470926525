const MESSAGE = {
  MSG_NOT_SEARCH:
    '<b>Encontre a unidade desejada.</b><br />Selecione a unidade desejada no menu acima.',
  MSG_NOT_FOUND_SEARCH:
    '<b>Não encontramos nenhuma unidade como resultado desta busca.</b><br />Tente aplicar outro critério de filtro.',
  ERROR_UNITS: 'Erro ao carregar unidades'
}

const PAGINATION = {
  ITENS_PER_PAGE: 6,
  DEFAULT_PAGE: 1,
  listItensPerPage: [
    { value: 6, label: 6 },
    { value: 12, label: 12 },
    { value: 24, label: 24 },
    { value: 36, label: 36 },
    { value: 54, label: 54 }
  ]
}

const typeEndpointUnit = 'type=unit'

const SITUATION = {
  edit: 'edit',
  new: 'new'
}

export { MESSAGE, PAGINATION, typeEndpointUnit, SITUATION }
