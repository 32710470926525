/* eslint-disable camelcase */
const selectListType = (data, hasCC5) =>
  data?.reduce((acc, cur) => {
    const { id_cc4, id_cc5, name } = cur
    const newItem = { value: hasCC5 ? id_cc5 : id_cc4, label: name }

    return [...acc, newItem]
  }, [])

export { selectListType }
