import styled from 'styled-components'

const Container = styled('div')`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  flex-direction: column;
`

const BoxDropouts = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`

export { Container, BoxDropouts }
