const headCells = [
  {
    id: 'information',
    label: 'Dado / informação'
  },
  {
    id: 'code_tag',
    label: 'Código / TAG'
  }
]

export { headCells }
