import FEEDBACK_SNACK from 'feedBackSnack'
import {
  getBySpecificOccurrenceType,
  getCategories,
  getEvents,
  getOptionsOccurrenceTypes
} from 'services/api/admin'
import { IsEmpty, updateErrorMessage } from 'services/helpers'

const getListEvents = async ({ setSnack, setListEvents }) => {
  setSnack('')

  const { data, error, status } = await getEvents()

  const removeEvents = new Set(['discipline_remover', 'lock_system'])

  const response = data?.reduce((acc, item) => {
    if (!removeEvents.has(item.value)) {
      return [...acc, item]
    }
    return acc
  }, [])

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListEventsOccurrenciesTypes
    })
  }

  return setListEvents(response)
}

const handleGetEdit = async ({
  idType,
  listEvents,
  setSnack,
  setChecked,
  setLoadingOpen,
  setInitialState
}) => {
  setSnack('')

  const { data, error, status } = await getBySpecificOccurrenceType(idType)

  setLoadingOpen(false)
  setChecked([])

  if (!IsEmpty(data.events)) {
    const newEvent = listEvents?.reduce((acc, curr) => {
      if (data?.events.includes(curr.value)) {
        acc.push(curr.value)
      }
      return acc
    }, [])

    setChecked(newEvent)
  }

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListOccurrenciesTypes
    })
  }

  return setInitialState({
    category: data.group,
    occurrence: data.name,
    record: data.occurrence_name
  })
}

const listSelectCategories = async ({ setSnack, setListCategories }) => {
  setSnack('')

  const { data, error, status } = await getCategories()

  const newData = data?.reduce((acc, cur) => {
    const { name, code } = cur
    const newItem = { value: code, label: name }

    return [...acc, newItem]
  }, [])

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListCategoriesOccurrenciesTypes
    })
  }

  return setListCategories(newData)
}

const listSelectOccurrenceTypes = async ({
  setSnack,
  setOptionsOccurrenceTypes,
  occurrenceCategory
}) => {
  setSnack('')

  const params = {
    occurrence_category: occurrenceCategory
  }

  const { data, error, status } = await getOptionsOccurrenceTypes(params)

  const newData = data?.reduce((acc, cur) => {
    const { name } = cur
    const newItem = { value: name, label: name }

    return [...acc, newItem]
  }, [])

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListCategoriesOccurrenciesTypes
    })
  }

  return setOptionsOccurrenceTypes(newData)
}

export {
  listSelectCategories,
  listSelectOccurrenceTypes,
  getListEvents,
  handleGetEdit
}
