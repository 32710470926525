import styled from 'styled-components'
import {
  Step as MuiStep,
  Stepper as MuiStepper,
  RadioGroup as MuiRadioGroup
} from '@mui/material'
import { TextField as MuiTextField } from 'components/molecules'

const Stepper = styled(MuiStepper)`
  &.MuiStepper-root {
    align-items: center;
    margin: 24px 80px;
  }
`

const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextField = styled(MuiTextField)`
  &.MuiTextField-root {
    width: 300px;
  }
`

const BoxSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > p:last-of-type {
    text-align: center;
  }
  & > label:first-of-type {
    margin-bottom: 24px;
  }
`

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: flex-end;
`

const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    -webkit-flex-wrap: nowrap;
  }
`
const Step = styled(MuiStep)`
  &.MuiStep-root {
    &.Mui-completed {
      & > span > span > span {
        color: ${({ theme }) => theme.palette.success.main};
      }

      & > span > span > svg {
        color: ${({ theme }) => theme.palette.success.main};
      }
    }
  }
`
const Form = styled.form``

export {
  Step,
  Form,
  Stepper,
  BoxHeader,
  TextField,
  BoxSwitch,
  RadioGroup,
  ContainerButton
}
