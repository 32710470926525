const INITIAL_STEP = 0
const MIDDLE_STEP = 1
const FINAL_STEP = 2
const cepValid = 9

const MESSAGE = {
  success: 'Matrícula direta criada com sucesso!'
}

const disabledButton = (values, activeStep) => {
  if (activeStep === INITIAL_STEP) {
    return (
      !values.unity ||
      !values.group ||
      !values.level ||
      !values.course ||
      !values.shift ||
      !values.module
    )
  }

  return (
    !values.cpf ||
    !values.name ||
    !values.cep ||
    !values.email ||
    !values.cep ||
    !values.rg ||
    !values.birth ||
    !values.state ||
    !values.address ||
    !values.number ||
    !values.district ||
    !values.civilStatus ||
    !values.nationality ||
    !values.naturalness ||
    !values.naturalnessCity ||
    !values.fatherName ||
    !values.motherName
  )
}

const initialStateForm = {
  rg: '',
  uf: '',
  cpf: '',
  pai: '',
  cep: '',
  mae: '',
  nome: '',
  sexo: '',
  polo: '',
  grupo: '',
  civil: '',
  email: '',
  level: '',
  turno: '',
  modulo: '',
  curso: '',
  ead: false,
  numero: '',
  cidade: '',
  bairro: '',
  country: '',
  unidade: '',
  celular: '',
  contato: '',
  telefone: '',
  endereco: '',
  nascimento: '',
  nome_social: '',
  complemento: '',
  naturalidade: '',
  nacionalidade: '',
  naturalidade_cidade: ''
}

export {
  disabledButton,
  INITIAL_STEP,
  initialStateForm,
  FINAL_STEP,
  MIDDLE_STEP,
  MESSAGE,
  cepValid
}
