import styled, { css } from 'styled-components'
import {
  IconButton as MuiIconButton,
  Typography as MuiTypography
} from '@mui/material'
import { PlayCircleOutline } from '@mui/icons-material'
import { indexs } from 'services/constants'

const Main = styled.div`
  > div > div.MuiBox-root,
  hr.MuiDivider-root,
  &:nth-last-child(2) {
    display: none;
    margin-top: 0 !important;
  }
  .MuiContainer-root {
    margin: 0;
  }
`

const WelcomeMessage = styled(MuiTypography)`
  width: 100%;
  &.MuiTypography-root {
    font-size: 26px;
    margin: 10px 0;
  }
`

const Content = styled.div`
  margin-left: 325px;
  height: 100vh;
  padding-bottom: 100px;
`

const BackgroundImage = styled.img`
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
`

const HasDoubt = styled(MuiTypography)`
  width: 100%;

  &.MuiTypography-root {
    font-size: 16px;
    margin: 0 0 10px 0;
  }
`

const Questions = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`

const StartTutorial = styled.div`
  width: 188px;
  height: 157px;
  border-radius: 4px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  box-sizing: border-box;
`

const StartTutorialPlayIcon = styled(PlayCircleOutline)`
  color: ${({ theme }) => theme.palette.primary.main};
  transform: scale(4.5);
`

const StartTutorialIconButton = styled(MuiIconButton)`
  width: 100px;
  height: 100px;
`

const StartTutorialText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 175%;
  letter-spacing: 0.15px;
  margin: 0;
`

const ButtonHelp = styled.div`
  width: 92.5px;
  height: 60px;
  position: absolute;
  right: 10px;
  bottom: 8px;

  ${({ isHighlightHelp, theme }) =>
    isHighlightHelp &&
    css`
      padding: 10px;
      background-color: ${() => theme.palette.primary.white};
      z-index: ${() => indexs.highlight};
      box-sizing: border-box;
    `}
`

const Filter = styled.div`
  background-color: ${({ theme }) => theme.palette.dark.medium};
  overflow: hidden;
  width: 100%;
  height: 100vh;
  z-index: ${() => indexs.filter};
  bottom: 0;
  left: 0;
  position: absolute;
`

const QuestionsContainer = styled.div`
  margin-top: 20px;

  ${({ isHighlightQuestions, theme }) =>
    isHighlightQuestions &&
    css`
      border-radius: 4px;
      width: fit-content;
      padding: 0 20px 20px 20px;
      background-color: ${() => theme.palette.primary.white};
      position: relative;
      z-index: ${() => indexs.highlight};
      box-sizing: border-box;
    `}
`

export {
  Main,
  WelcomeMessage,
  Content,
  BackgroundImage,
  HasDoubt,
  Questions,
  StartTutorial,
  StartTutorialPlayIcon,
  StartTutorialIconButton,
  StartTutorialText,
  ButtonHelp,
  Filter,
  QuestionsContainer
}
