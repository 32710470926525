import React, { useEffect, useState, lazy } from 'react'
import { getErrorMessage, updateErrorMessage } from 'services/helpers'
import { Pagination } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  getAmountClasses,
  getClassList,
  updateClass
} from 'services/api/professor'
import {
  useCurrentUser,
  useActionSelected,
  usePaginationBasic
} from 'services/hooks'
import { ERROR, SUCCESS } from 'services/constants'
import { ContentTable } from 'components/organisms/Table'
import { Button } from 'components/atoms'
import { LoadingPage } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import FEEDBACK_SNACK from 'feedBackSnack'
import { useDiary } from '../context/HeaderDiary'
import { classMessage } from '../constants'
import {
  defaultPage,
  defaultStatusSuccess,
  defaultPageActive,
  minimumCharacters,
  createType,
  deleteType,
  itemsPerPage
} from './constants'
import * as Styled from './style'

const ModalConfirmByDelete = lazy(() => import('./ModalConfirmByDelete'))
const FormNewClass = lazy(() => import('../FormNewClass'))

const Content = ({ params }) => {
  const [diary, setDiary] = useState([])
  const [selectedState, setSelectedState] = useState([])
  const [amountClasses, setAmountClasses] = useState('')
  const [isLoading, setisLoading] = useState(false)
  const [invalidDate, setInvalidDate] = useState(false)

  const { onActionSelected, selected, onResetAction } = useActionSelected()

  const { disciplineId, professorId } = useCurrentUser()
  const { findProfessorsByDisciplines } = useDiary()

  const {
    page: pages,
    totalPage,
    items,
    setPage
  } = usePaginationBasic(diary, defaultPage)

  const getAmountClasse = async () => {
    setisLoading(true)

    const idSubject = disciplineId?.split(',')

    const { data, error, status } = await getAmountClasses(idSubject[0])

    setisLoading(false)

    if (error) {
      return updateErrorMessage({
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListInfoSubjects
      })
    }

    return setAmountClasses(data.school_class.amount_classes)
  }

  const onHandleGetListClass = async () => {
    setisLoading(true)
    const convertByArray = JSON.parse(`[${disciplineId}]`)

    const getParamsListClass = {
      disciplineId: convertByArray
    }
    const {
      data: response,
      status,
      error
    } = await getClassList(getParamsListClass)

    if (response?.data?.length === defaultPage) {
      setPage(defaultPageActive)
    }
    const isValidDataToTable = response?.data && status === defaultStatusSuccess
    if (isValidDataToTable) {
      setDiary(response?.data)
      setSelectedState([])
    }

    if (error) {
      const message = getErrorMessage(error, status)
      params.setSnack(message, ERROR)
    }
    setisLoading(false)
  }

  const updateTarget = async (description, id, doneAt) => {
    params.setSnack('', '')
    const getParamsToUpdateClass = {
      professor_id: professorId,
      description,
      done_at: doneAt
    }
    if (String(description)?.length > minimumCharacters) {
      const { error, status } = await updateClass(id, getParamsToUpdateClass)
      onHandleGetListClass()
      if (!error) params.setSnack(classMessage.update.success, SUCCESS)

      if (error) {
        const message = getErrorMessage(error, status)
        params.setSnack(message, ERROR)
      }
    }
  }

  const createLessonButton = (
    <Button
      className={TAG_MANAGER.btn_teacher_requests_newClass}
      startIcon={<AddCircleIcon />}
      onClick={() => onActionSelected(createType, {})}
      value="CRIAR UMA NOVA AULA"
      variant="contained"
      tab={0}
      color="primary"
    />
  )

  const handleSetPage = (event, page) => {
    setPage(+page)
  }

  useEffect(() => {
    getAmountClasse()
    findProfessorsByDisciplines({ disciplinesIds: disciplineId.split(',') })
  }, [])

  useEffect(() => {
    onHandleGetListClass()
  }, [totalPage])

  return (
    <>
      <LoadingPage open={isLoading} />

      <ContentTable
        dataSource={items}
        handleDeleteClass={(item) => onActionSelected(deleteType, item)}
        setContentCallBack={updateTarget}
        createLessonButton={createLessonButton}
        selectedState={[selectedState, setSelectedState]}
      />

      {totalPage >= itemsPerPage && (
        <Styled.Paginate>
          <Pagination count={totalPage} page={pages} onChange={handleSetPage} />
        </Styled.Paginate>
      )}

      <FormNewClass
        open={selected?.type === createType}
        amountClasses={amountClasses}
        closeDialog={onResetAction}
        onHandleGetListClass={onHandleGetListClass}
        setSnack={params.setSnack}
        invalidDate={invalidDate}
        setInvalidDate={setInvalidDate}
        itemsLength={items.length}
      />

      <ModalConfirmByDelete
        open={selected?.type === deleteType}
        closeDialog={onResetAction}
        onHandleGetListClass={onHandleGetListClass}
        lessonsToDelete={selected?.data}
        diary={diary}
        setSnack={params.setSnack}
      />
    </>
  )
}

export default Content
