import styled from 'styled-components'
import { Typography } from '@mui/material'

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 12px;
  }
`

export { Box, Title }
