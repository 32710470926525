const tableColumns = [
  { id: 'id', label: 'ID' },
  { id: 'IP', label: 'IP' },
  { id: 'user', label: 'user' },
  { id: 'page', label: 'page' },
  { id: 'datetime', label: 'datetime' }
]

const headCellsPage = [
  { id: 'datetime', label: 'Data/Hora' },
  { id: 'user', label: 'Usuário' },
  { id: 'IP', label: 'IP' },
  { id: 'page', label: 'Páginas acessadas' }
]

const headCellsDetails = [
  { id: 'datetime', label: 'Data' },
  { id: 'user', label: 'Usuário' },
  { id: 'IP', label: 'IP' },
  { id: 'details', label: 'Detalhes' }
]

export { tableColumns, headCellsPage, headCellsDetails }
