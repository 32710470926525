import { ROUTE } from 'services/constants'

const intialState = {
  unit: '',
  pole: '',
  level: '',
  counting_start_date: '',
  counting_end_date: '',
  competence: '',
  payment_date: ''
}

const oneDay = 1

const breadCrumbsItems = (isEdit) => [
  {
    path: '',
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_TIMEKEEPING,
    label: 'Apontamento de Horas'
  },
  {
    path: ROUTE.ADMIN_HR_TIMEKEEPING,
    label: `<b>${isEdit ? 'Editar' : 'Novo'} Apontamento de Horas</b>`
  }
]
const newPayrollSuccess = 'Novo Apontamento de Horas criado com sucesso.'

export { intialState, breadCrumbsItems, newPayrollSuccess, oneDay }
