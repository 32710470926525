import { NOVOTEC_UNIT } from 'services/constants/units'
import theme from 'theme/designTokens'

const getLabel = (unit) =>
  unit === NOVOTEC_UNIT ? 'Aulas realizadas' : 'Carga horária total cumprida'

const HEADER_STYLE = {
  bold: true,
  fillColor: theme.palette.background.tableHeaderPDF
}

const buildResponsibleTeacher = ({
  responsibleTeachers,
  totalWorkloadCompleted,
  unit
}) => {
  const headerRow = [
    { text: 'Docente responsável', ...HEADER_STYLE },
    { text: getLabel(unit), ...HEADER_STYLE },
    { text: 'Assinatura', ...HEADER_STYLE }
  ]

  const dataRows = responsibleTeachers?.reduce((acc, teacher) => {
    if (teacher.completedLessons > 0) {
      acc.push([
        { text: teacher.name?.toUpperCase() },
        {
          text:
            unit === NOVOTEC_UNIT
              ? `${teacher.completedLessons} aulas`
              : `${totalWorkloadCompleted} horas`
        },
        { text: '' }
      ])
    }
    return acc
  }, [])

  return [headerRow, ...(dataRows ?? [])]
}

export default buildResponsibleTeacher
