import { Table as MuiTable, TableBody } from '@mui/material'

import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'
import { useSortTable } from 'services/hooks'
import { paginationFilter } from 'services/helpers'

import { defaultItemsPerPage, headCells, headColumns } from './constants'
import TableFooter from './TableFooter'

import * as Styled from './style'

const Table = ({
  rows,
  setFilter,
  filter,
  page,
  totalPage,
  setPage,
  count,
  handleSetPage,
  handleRegisterNote,
  handleExcludeLink,
  linkTo
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    rows,
    headColumns.discipline
  )

  return (
    <>
      <Styled.Paper>
        <Styled.TableContainer>
          <MuiTable>
            <TableHeaderDefault
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />

            <TableBody>
              {sortTable?.map((row) => {
                const {
                  subjectId,
                  linkedSubject,
                  subjectWorkloadHours,
                  subjectName
                } = row

                const linkToText = linkTo({
                  subjectId,
                  subjectWorkloadHours,
                  subjectName
                })

                return (
                  <TableFooter
                    key={subjectId}
                    row={row}
                    handleRegisterNote={() =>
                      handleRegisterNote({
                        schoolClassId: linkedSubject?.schoolClassId,
                        linkedSubjectId: linkedSubject?.linkedSubjectId
                      })
                    }
                    handleExcludeLink={() =>
                      handleExcludeLink(row?.linkedSubject?.id)
                    }
                    linkTo={linkToText}
                  />
                )
              })}
            </TableBody>
          </MuiTable>
        </Styled.TableContainer>

        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={page}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setPage(defaultItemsPerPage)
              setFilter(e.target.value)
            }}
          />
        )}
      </Styled.Paper>
    </>
  )
}

export default Table
