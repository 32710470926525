import { useNavigate } from 'react-router-dom'
import { ROUTE } from 'services/constants'
import { ModalConfirm } from 'components/molecules'
import { useUserStore } from 'store/GlobalContext'

export default function ModalStudentOverdue({
  amountOverdueBills,
  open,
  onClose
}) {
  const navigate = useNavigate()
  const user = useUserStore()
  const firstName = user.name.trim().split(' ')[0]
  const isPlural = amountOverdueBills > 1
  const message = `Gostaríamos de lembrar que você possui <b>${amountOverdueBills}</b> título${
    isPlural ? 's' : ''
  } vencido${isPlural ? 's' : ''} não pago${isPlural ? 's' : ''}.`

  return (
    <ModalConfirm
      open={open}
      handleClose={onClose}
      iconWarningAmber
      title={`Olá ${firstName}!`}
      message={message}
      textButtonConfirm="Pagar agora"
      textButtonNotConfirm="Fechar"
      colorButtonConfirm="primary"
      colorButtonNotConfirm="warning"
      onClickConfirm={() => navigate(ROUTE.STUDENT_FINANCIAL)}
      onClickNotConfirm={onClose}
    />
  )
}
