import { createContext, useState, useContext } from 'react'

const initialState = {
  unity: '',
  level: '',
  course: '',
  shift: '',
  inactiveCourses: false,
  ead: false
}

export const FormContext = createContext(initialState)
export const PaymentPlansProvider = ({ children }) => {
  const [formValues, setFormValues] = useState(initialState)
  const [selectedCopy, setSelectedCopy] = useState('')

  const resetFormValues = () => {
    setFormValues({ ...initialState })
  }

  return (
    <FormContext.Provider
      value={{
        formValues,
        setFormValues,
        resetFormValues,
        setSelectedCopy,
        selectedCopy
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const usePaymentPlans = () => useContext(FormContext)
