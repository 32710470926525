/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { Welcome } from 'components/pages/Shared'
import { ACL, ROUTE } from 'services/constants'
import isAllowUserAccess from '../helpers/isAllowUserAccess'
import FINANCIAL_ROUTES from './financialRoute'
import MANAGEMENT_ROUTES from './managementRoute'
import HR_ROUTES from './hrRoute'
import SECRETARY_ROUTES from './secretaryRoute'
import COMMERCIAL_ROUTES from './commercialRoute'
import SYSTEM_ROUTES from './systemRoute'
import PDF_ROUTES from './pdfRoute'

const ADMIN_ROUTES = [
  ...FINANCIAL_ROUTES,
  ...SECRETARY_ROUTES,
  ...COMMERCIAL_ROUTES,
  ...MANAGEMENT_ROUTES,
  ...HR_ROUTES,
  ...SYSTEM_ROUTES,
  ...PDF_ROUTES,
  {
    component: Welcome,
    exact: true,
    path: ROUTE.ADMIN,
    permissions: ACL.admin,
    isAllowed: (userPermissions) => isAllowUserAccess(userPermissions, 'ADMIN')
  }
]

export default ADMIN_ROUTES
