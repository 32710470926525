import styled from 'styled-components'

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  button {
    display: flex;
    gap: 5px;
    height: 40px;
  }
`

const EmptyStateMessageContainer = styled.div`
  display: flex;
  gap: 24px;

  .MuiSvgIcon-root {
    width: 64px;
    height: 64px;
    color: ${({ theme }) => theme.palette.dark.medium};
  }
`

const EmptyStateMessage = styled.p`
  display: flex;
  gap: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.15px;
`

export { EmptyState, EmptyStateMessageContainer, EmptyStateMessage }
