const sortAsc = 'asc'
const defaultPage = 1
const emptyCell = ' '
const itemSelected = 1
const numberSelected = 0

const headCells = [
  { id: 'name', label: 'Grupo' },
  { id: 'unit_name', label: 'Unidade' },
  { id: 'enrollment_start_date', label: 'Início' },
  { id: 'enrollment_end_date', label: 'Fim' },
  { id: 'due_date', label: '1º vencimento' },
  { id: 'start_prevision_date', label: 'Previsão de Início' },
  { id: 'active', label: 'Situação' }
]

const type = {
  remove: 'remove',
  inactive: 'inactive'
}
const invalidDate = 'Invalid date'

export {
  defaultPage,
  emptyCell,
  headCells,
  sortAsc,
  type,
  itemSelected,
  invalidDate,
  numberSelected
}
