export const PROFESSOR_ENDPOINT = {
  PROFESSOR: 'professors/',
  PROFESSOR_SCHOOL_CLASSES: 'school-classes/',
  PROFESSOR_DIARY_LESSONS: 'lessons/',
  PROFESSOR_DIARY_LESSONS_CONFIRMED: 'lessons/check',
  PROFESSOR_DIARY_FREQUENCIES: 'frequencies',
  PROFESSOR_DIARY_SCORES: 'scores',
  PROFESSOR_DIARY_ACTIVITIES_SCORES: 'activities/scores',
  PROFESSOR_DIARY_ACTIVITIES: 'activities',
  PROFESSOR_SUBJECTS: 'subjects',
  PROFESSOR_LINKED_DISCIPLINES: 'professors/link/disciplines',
  PROFESSOR_LINKED_SCHOOL_CLASS: (classId) =>
    `professors/link/school-class${classId ? `/${classId}` : ''}`
}
