import { CPF, NAME, SOCIAL_NAME, EMAIL, REGISTRATION } from './constants'

const initialValue = {
  searchType: '',
  searchBy: ''
}

const simpleSearchBy = [
  { value: 'name', label: 'Nome' },
  { value: 'social_name', label: 'Nome Social' },
  { value: 'email', label: 'E-mail' },
  { value: 'cpf', label: 'CPF' },
  { value: 'register', label: 'Matrícula' }
]

const typeSearch = {
  [NAME]: {
    typeMask: 'NAME',
    type: 'text',
    label: 'Nome ou parte dele'
  },
  [SOCIAL_NAME]: {
    typeMask: 'NAME',
    type: 'text',
    label: 'Nome ou parte dele'
  },
  [REGISTRATION]: {
    typeMask: 'NUMBER',
    type: 'number',
    label: 'Digite a matrícula'
  },
  [CPF]: {
    typeMask: 'CPF',
    type: 'number',
    label: 'Digite apenas números'
  },
  [EMAIL]: {
    typeMask: 'NAME',
    type: 'text',
    label: 'E-mail ou parte dele'
  }
}

const headCells = [
  {
    id: 'register',
    numeric: false,
    disabled: false,
    label: 'Matrícula'
  },
  {
    id: 'name',
    numeric: false,
    disabled: false,
    label: 'Nome'
  },
  {
    id: 'cpf',
    numeric: false,
    disabled: false,
    label: 'CPF'
  },
  {
    id: 'status',
    numeric: false,
    disabled: false,
    label: 'Situação'
  }
]

export { initialValue, simpleSearchBy, typeSearch, headCells }
