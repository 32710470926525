import * as yup from 'yup'
import { NAME, REGISTRATION } from './constants'

const schema = yup.object().shape({
  searchType: yup.string().required('Campo obrigatório'),
  searchBy: yup
    .string()
    .when('searchType', {
      is: NAME,
      then: yup
        .string()
        .min(4, 'Digite ao menos 4 caracteres')
        .trim()
        .nullable()
    })
    .when('searchType', {
      is: REGISTRATION,
      then: yup
        .string()
        .trim()
        .matches(
          /^[1-9][0-9]*$/,
          'O número de matrícula deve ser um número inteiro positivo'
        )
        .test(
          'registration-length',
          'O número de matrícula é obrigatório',
          (value) => !value || (value && value?.length > 0)
        )
        .nullable()
    })
})

export default schema
