import { useState, useEffect, lazy } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { ListAlt, Search, Subject } from '@mui/icons-material'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import {
  useMakeService,
  usePaginationBasic,
  useSortTable
} from 'services/hooks'
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import {
  IsEmpty,
  convertToParams,
  formatDateMonthYear,
  isEmpty,
  mergeInitialValues
} from 'services/helpers'
import { ModalConfirm } from 'components/molecules'
import {
  countDefault,
  defaultItemsPerPage,
  initialState,
  LIST_STATUS,
  MESSAGE
} from './constants'
import { deletePayrollItem, onSubmit } from './services'
import schema from './schema'
import { useTimeTrakingProvider } from './Context/FormContext'
import * as Styled from './style'

const Table = lazy(() => import('./components/Table'))
const Form = lazy(() => import('./components/Form'))

const TimeTraking = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [isButtonSet, setIsButtonSet] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [idTimeTraking, setIdTimeTraking] = useState(null)
  const [listCategories, setListCategories] = useState([])
  const [nameTypeSingle, setNameTypeSingle] = useState('')

  const isSearchParam = location?.state?.savedSearch

  const {
    formValues,
    setFormValues,
    resetFormValues,
    filter,
    setFilter,
    setCurrentPage,
    listPayroll,
    setCount,
    setListPayroll,
    count
  } = useTimeTrakingProvider()

  const paramsMakeService = {
    isUnits: true
  }
  const { unitys } = useMakeService(paramsMakeService)

  const {
    page: pageActual,
    totalPage,
    setPage,
    items
  } = usePaginationBasic(listPayroll, filter, count)

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  const deletePayroll = (id) => {
    setOpenModalConfirm(true)
    setIdTimeTraking(id)
  }

  const { handleRequestSort, order, orderBy, sortTable } =
    useSortTable(listPayroll)

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  const handleCloseModalConfirm = () => setOpenModalConfirm(false)

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      onSubmit({
        values,
        setLoadingOpen,
        filter,
        pageActual,
        setCount,
        setOpenModalConfirm,
        setFormValues,
        setNotSearch,
        setListPayroll
      })
    },
    validationSchema: schema
  })

  const handleDeleteConfirmModal = () => {
    setOpenModalConfirm(false)

    return deletePayrollItem({
      id: idTimeTraking,
      setLoadingOpen,
      values: formik.values,
      filter,
      pageActual,
      setCount,
      setOpenModalConfirm,
      setFormValues,
      setNotSearch,
      setListPayroll
    })
  }

  useEffect(() => {
    if (isSearchParam && formValues.competence) {
      setIsButtonSet(true)
      return onSubmit({
        values: formValues,
        setLoadingOpen,
        filter,
        pageActual,
        setCount,
        setOpenModalConfirm,
        setFormValues,
        setNotSearch,
        setListPayroll
      })
    }

    return setFormValues(initialState)
  }, [isSearchParam])

  useEffect(() => {
    setCurrentPage(pageActual)

    if (!isEmpty(listPayroll) && isButtonSet) {
      onSubmit({
        values: formValues,
        setLoadingOpen,
        filter,
        pageActual,
        setCount,
        setFormValues,
        setNotSearch,
        setListPayroll,
        setOpenModalConfirm
      })
    }
  }, [pageActual, filter])

  return (
    <TemplateDefaultFormAndResult
      titleHeader="RH - Apontamento de Horas"
      labelButtonHeader="novo apontamento"
      loadingOpen={loadingOpen}
      linkHeader={ROUTE.ADMIN_HR_CREATE_TIMEKEEPING}
      listItems={items}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      classNameHeader={TAG_MANAGER.rh_btn_criar_novoProfessor}
      formHeader={
        <form
          onSubmit={formik.handleSubmit}
          onReset={formik.handleReset}
          noValidate
          autoComplete="off"
        >
          <Form
            setFieldValue={formik.setFieldValue}
            setFilter={setFilter}
            setPage={setPage}
            setIsButtonSet={setIsButtonSet}
            onClear={() => {
              formik.resetForm()
              resetFormValues()
            }}
            errors={formik.errors}
            touched={formik.touched}
            values={formik.values}
            unitys={unitys}
            listStatus={LIST_STATUS}
          />
        </form>
      }
    >
      {listPayroll && !IsEmpty(listPayroll) && (
        <>
          <Styled.Button
            value="Consolidação"
            startIcon={<Subject />}
            variant="outlined"
            color="warning"
            onClick={() =>
              navigate(
                `${ROUTE.ADMIN_HR_MANAGE_CONSOLIDATION}?${convertToParams({
                  competence: formatDateMonthYear(formik.values.competence)
                })}`
              )
            }
          />
          <Table
            rows={listPayroll}
            onRemove={listPayroll}
            setCount={setCount}
            count={count}
            setLoadingOpen={setLoadingOpen}
            nameTypeSingle={nameTypeSingle}
            setNameTypeSingle={setNameTypeSingle}
            listCategories={listCategories}
            totalPage={totalPage}
            setFilter={setFilter}
            deletePayroll={deletePayroll}
            setNotSearch={setNotSearch}
            setPage={setPage}
            setFormValues={setFormValues}
            filter={filter}
            pages={pageActual}
            unitys={unitys}
            values={formik.values}
            setListPayroll={setListPayroll}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            handleSetPage={handleSetPage}
            sortTable={sortTable}
            navigate={navigate}
          />

          <ModalConfirm
            open={openModalConfirm}
            size="medium"
            handleClose={() => handleCloseModalConfirm()}
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            onClickNotConfirm={() => handleCloseModalConfirm()}
            onClickConfirm={() => handleDeleteConfirmModal()}
            colorButtonConfirm="error"
            iconWarningAmber
            title="Excluir Validação do Apontamento de Horas"
            message="Você tem certeza que deseja excluir esta validação do Apontamento
          de Horas?"
          />
        </>
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default TimeTraking
