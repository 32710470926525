import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'
import { AddBox, Delete } from '@mui/icons-material'

const Title = styled.h3`
  font-size: 32px;
  font-weight: 500;
  margin: 0 0 16px 0;
`

const SubTitle = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.15px;
`

const CourseText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%; /* 21.98px */
  letter-spacing: 0.1px;
`

const Footer = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:last-child button {
      margin-left: 1rem;
    }
  }
`

const AddIcon = styled(AddBox)`
  &.MuiSvgIcon-root {
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
  }
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${({ theme, $disabled }) =>
      $disabled ? theme.palette.gray.medium : theme.palette.textColors.error};
  }
`

const WrapperAddIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WrapperDeleteIcon = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Teacher = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`

const ListTeachers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export {
  Title,
  SubTitle,
  CourseText,
  Footer,
  AddIcon,
  WrapperAddIcon,
  WrapperDeleteIcon,
  DeleteIcon,
  ListTeachers,
  Teacher
}
