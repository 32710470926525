import FEEDBACK_SNACK from 'feedBackSnack'
import { createPayroll, editPayroll, selectPayroll } from 'services/api/admin'
import { ROUTE } from 'services/constants'
import {
  formatDateEnv,
  getDataFormKeys,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import { getUnitsRegions } from 'services/api/shared'
import { newPayrollSuccess, oneDay } from './constants'
import { parseResponse, parserAddDay, parserDate } from './helpers'

const createByUpdatePayroll = async ({
  values,
  navigate,
  isEdit,
  unitys,
  id,
  setLoadingOpen
}) => {
  setLoadingOpen(true)

  const unit = unitys?.find((item) => item.value === values.unit)

  const payload = {
    ...values,
    unit: unit.label,
    counting_start_date: parserDate(values.counting_start_date),
    counting_end_date: parserDate(values.counting_end_date),
    payment_date: parserDate(values.payment_date)
  }

  const endpoint = isEdit
    ? editPayroll({ id, values: payload })
    : createPayroll(payload)

  const { error, status } = await endpoint

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreatePayroll
    })
  }

  toastNotificationSuccess(newPayrollSuccess, 1500)

  return setTimeout(
    () =>
      navigate(ROUTE.ADMIN_HR_TIMEKEEPING, {
        state: { savedSearch: true }
      }),
    1500
  )
}

export const onUnitsRegions = async ({ setListPole, values }) => {
  const isName = true

  const { data, error, status } = await getUnitsRegions(
    `type=pole&unit_id=${values.unit}`
  )

  const dataPole = getDataFormKeys([0, 'units'], data?.data)

  if (error) {
    return updateErrorMessage({
      error,
      status
    })
  }

  return setListPole(parseResponse(dataPole, isName))
}

const unicPayroll = async ({ id, setLoadingOpen, unitys, setFormValues }) => {
  setLoadingOpen(true)

  const { data, error, status } = await selectPayroll(id)
  setLoadingOpen(false)

  if (error) {
    updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditTypesActivitys
    })
  }

  const unit = unitys?.find((item) => item.label === data.unit)

  const response = {
    ...data,
    unit: unit?.value,
    counting_start_date: parserAddDay(data.counting_start_date),
    counting_end_date: parserAddDay(data.counting_end_date),
    payment_date: parserAddDay(data.payment_date)
  }

  return setFormValues(response)
}

export { createByUpdatePayroll, unicPayroll }
