import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useMakeService, useSnack } from 'services/hooks'
import { ButtonFooterV2, Snackbar } from 'components/molecules'
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import { breadcrumbsItems, initialState } from './constants'
import * as Styled from './style'

import {
  createTypeOfActivitys,
  editTypeOfActivitys,
  getListCourses,
  handleDetailsSearch,
  handleSelectUnit
} from '../service'
import FormCreateTypesOfActivity from '../Forms/FormCreateTypesOfActivity'

const CreateTypesOfActivitys = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(false)
  const [selectUnit, setSelectUnit] = useState()
  const { snack, setSnack, snackOpen } = snackProps
  const dataTypesOfActivitys = location?.state?.row
  const [listCourses, setListCourses] = useState([])
  const [isOpenModal, setIsOpenModal] = useState(false)

  const paramsMakeService = {
    isUnits: true,
    isLevel: true,
    setSnack
  }

  const { unitys, level } = useMakeService(paramsMakeService)

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: (values) => {
      const paramsToCreateUpdate = {
        values,
        setSnack,
        setLoading,
        navigate,
        setMessage
      }

      if (dataTypesOfActivitys) {
        const { id } = dataTypesOfActivitys
        editTypeOfActivitys({ id, ...paramsToCreateUpdate })
      } else {
        createTypeOfActivitys(paramsToCreateUpdate)
      }
    }
  })

  useEffect(() => {
    const { values } = formik
    if (values.unit && values.level)
      getListCourses({ values, setSnack, selectUnit, setListCourses })
  }, [formik.values.unit, formik.values.level])

  useEffect(() => {
    const { values } = formik
    handleSelectUnit({ values, unitys, setSelectUnit })
  }, [formik.values.unit])

  useEffect(() => {
    const { setFieldValue } = formik
    const id = dataTypesOfActivitys?.id
    if (dataTypesOfActivitys) {
      handleDetailsSearch({
        id,
        unitys,
        setSnack,
        setFieldValue,
        setSelectUnit
      })
    }
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      open={isOpenModal}
      loadingOpen={loading}
      snackProps={snackProps}
      classNameHeader="goBack"
      titleModalConfirm="Cancelar"
      handleCloseModal={() => setIsOpenModal(false)}
      tagmanagerModal={TAG_MANAGER.academico_btn_cancela_editaAtividade}
      messageModalConfirm="Deseja cancelar a operação? Você perderá as alterações que ainda não foram salvas."
      breadcrumbsNotLink={breadcrumbsItems(
        dataTypesOfActivitys?.name || 'Nova'
      )}
      onClickButton={() => setIsOpenModal(!isOpenModal)}
      handleConfirmModal={() => {
        navigate(ROUTE.ADMIN_MANAGEMENT_TYPE_ACTIVITY, {
          state: { savedSearch: true }
        })
      }}
      content={
        <>
          <Styled.Form
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
              <FormCreateTypesOfActivity
                level={level}
                unitys={unitys}
                message={message}
                setSnack={setSnack}
                values={formik.values}
                setMessage={setMessage}
                listCourses={listCourses}
                isEdit={dataTypesOfActivitys?.id}
                setFieldValue={formik.setFieldValue}
              />
            </Styled.GridContainer>
            <Styled.Grid end marginTop={2} item xs={12} sm={12} md={12}>
              <ButtonFooterV2
                size="medium"
                loading={loading}
                labelClose="Cancelar"
                labelConfirm="Salvar"
                disabledClose={loading}
                classNameConfirm={TAG_MANAGER.academico_btn_salva_atividade}
                onClickClose={() => setIsOpenModal(!isOpenModal)}
                disabledConfirm={!formik.values.name || loading || message}
              />
            </Styled.Grid>
          </Styled.Form>
        </>
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default CreateTypesOfActivitys
