import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ListAlt } from '@mui/icons-material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { usePaginationBasic } from 'services/hooks'
import { Button, TextNotFound } from 'components/atoms'
import { isEmpty } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import {
  breadCrumbsItems,
  defaultItemsPerPage,
  initialSubject,
  initialValues,
  messageInformative,
  modalMessageConfirm
} from './constants'
import {
  deleteEadContentRegistrations,
  getEadContentRegistrations,
  handleSearchSubject
} from './services'
import { BoxInfo } from './components/BoxInfo'
import * as Styled from './style'
import { TableEad } from './components/Table'
import ModalCreateByEditEad from './components/ModalCreateByEditEad'

const RegisterEadContent = () => {
  const [loading, setLoading] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [openCreateByEdit, setOpenCreateByEdit] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [idSubject, setIdSubject] = useState(null)
  const [subject, setSubject] = useState(initialSubject)
  const [valuesModal, setValuesModal] = useState(initialValues)
  const [listContentEad, setListContentEad] = useState([])
  const [count, setCount] = useState(null)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id } = params

  const handleClose = () => setOpenCreateByEdit(false)

  const {
    page: pageActual,
    totalPage,
    items,
    setPage,
    handleSetPage
  } = usePaginationBasic(listContentEad, filter, count)

  const handleConfirm = () => {
    if (isDelete) {
      deleteEadContentRegistrations({
        id,
        idSubject,
        setLoading,
        setListContentEad,
        count,
        filter,
        setCount,
        setFilter,
        pageActual
      })
    }

    setIsDelete(false)
    setConfirmModal(false)
    setOpenCreateByEdit(false)
  }

  const handleHoursSum = () =>
    listContentEad?.reduce((acc, row) => acc + (row.content_workload || 0), 0)

  useEffect(() => {
    getEadContentRegistrations({
      id,
      setLoading,
      setListContentEad,
      count,
      filter,
      setCount,
      setFilter,
      pageActual
    })
  }, [pageActual, filter])

  useEffect(() => {
    Promise.all([
      handleSearchSubject({
        id,
        setLoading,
        setSubject
      }),
      getEadContentRegistrations({
        id,
        setLoading,
        setListContentEad,
        count,
        filter,
        setCount,
        setFilter,
        pageActual
      })
    ])
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      tagmanagerModal={
        isDelete
          ? TAG_MANAGER.gestao_btn_confirma_excluirConteudoEAD
          : TAG_MANAGER.gestao_btn_confirma_cancelarCadastroConteudoEAD
      }
      open={confirmModal}
      titleModalConfirm={isDelete ? 'Excluir' : 'Cancelar'}
      messageModalConfirm={modalMessageConfirm(isDelete)}
      handleCloseModal={() => {
        setConfirmModal(false)
        setIsDelete(false)
      }}
      handleConfirmModal={() => handleConfirm()}
      loadingOpen={loading}
      breadcrumbsItems={breadCrumbsItems(subject)}
      classNameHeader="goBack"
      onClickButton={() =>
        navigate(ROUTE.ADMIN_MANAGEMENT_SUBJECTS, {
          state: { savedSearch: true }
        })
      }
      content={
        <>
          <BoxInfo
            listContentEad={listContentEad}
            subject={subject}
            setIsEdit={setIsEdit}
            setValuesModal={setValuesModal}
            setOpenCreateByEdit={setOpenCreateByEdit}
          />

          {isEmpty(listContentEad) ? (
            <>
              <TextNotFound icon={<ListAlt />} text={messageInformative} />

              <Styled.BoxButton>
                <Button
                  value="Novo Conteúdo"
                  size="large"
                  className={TAG_MANAGER.gestao_btn_cadastrar_novoConteudoEAD}
                  onClick={() => {
                    setIsEdit(false)
                    setOpenCreateByEdit(!openCreateByEdit)
                    setValuesModal(initialValues)
                  }}
                />
              </Styled.BoxButton>
            </>
          ) : (
            <TableEad
              count={count}
              filter={filter}
              pageActual={pageActual}
              totalPage={totalPage}
              setPage={setPage}
              handleSetPage={handleSetPage}
              setFilter={setFilter}
              setIsDelete={setIsDelete}
              handleHoursSum={handleHoursSum}
              setConfirmModal={setConfirmModal}
              setValuesModal={setValuesModal}
              setIdSubject={setIdSubject}
              listContentEad={items}
              setOpenCreateByEdit={setOpenCreateByEdit}
              openCreateByEdit={openCreateByEdit}
              setIsEdit={setIsEdit}
            />
          )}

          <ModalCreateByEditEad
            id={id}
            count={count}
            filter={filter}
            setCount={setCount}
            setFilter={setFilter}
            pageActual={pageActual}
            confirmModal={confirmModal}
            idSubject={idSubject}
            valuesModal={valuesModal}
            isEdit={isEdit}
            setListContentEad={setListContentEad}
            setLoading={setLoading}
            open={openCreateByEdit}
            setConfirmModal={setConfirmModal}
            handleClose={() => handleClose()}
          />
        </>
      }
    />
  )
}

export default RegisterEadContent
