import {
  getEnrollments,
  getDetailEnrollments,
  getDetailCopyEnrollments,
  getDetailCampaign
} from 'services/api/admin/comercial/courses'
import { cleanObject } from 'services/helpers/cleanObject'
import { formatDateEnv, updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { defaultPage } from './constants'

const handleSearch = async ({
  values,
  setSnack,
  setLoadingVisibility,
  filter,
  pageActual,
  setListRegistration,
  setNotSearch,
  setCount
}) => {
  setSnack('', '')
  setLoadingVisibility(true)
  setNotSearch(false)

  const params = cleanObject({
    uf: values.uf,
    cpf: values.cpf,
    unit: values.unit,
    end_at: values.endAt ? formatDateEnv(values.endAt) : '',
    begin_at: values.endAt ? formatDateEnv(values.beginAt) : '',
    limit: filter,
    page: pageActual || defaultPage
  })

  const { data, error, status } = await getEnrollments(params)

  setLoadingVisibility(false)
  setCount(data?.count)
  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListManualEnrollment
    })
  }

  return setListRegistration(data.data)
}

const handleDetails = async ({ id, setDetails, isViewBankingBilling }) => {
  try {
    const { data } = await getDetailEnrollments(id)
    setDetails(data)
    if (isViewBankingBilling) window.open(data?.payment_url)
  } catch (error) {
    console.error('Erro ao obter detalhes:', error)
    setDetails({})
  }
}
const handleDetailsCopy = async ({
  id,
  setDetailsCopy,
  isViewBankingBilling
}) => {
  try {
    const { data } = await getDetailCopyEnrollments(id)
    setDetailsCopy(data)
    if (isViewBankingBilling) window.open(data?.payment_url)
  } catch (error) {
    console.error('Erro ao obter detalhes:', error)
    setDetailsCopy({})
  }
}

export const getDetailsCampaign = async ({
  unit,
  setSnack,
  setDetailsCampaign
}) => {
  const params = {
    unit
  }
  const { data, error, status } = await getDetailCampaign(params)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListProfessors
    })
  }
  return setDetailsCampaign(data)
}

export { handleSearch, handleDetails, handleDetailsCopy }
