import theme from 'theme/designTokens'
import htmlToPdfmake from 'html-to-pdfmake'
import generateDocDefinitionsBase from '../../shared/BasePage'
import { DOCUMENT_TITLE_ACADEMIC_RECORD_SP } from './constants'
import { buildStudentInfo } from './studentInfo'
import { buildTable } from './Table'
import { buildCourseObservations } from './CourseObservations'
import { buildSignature } from './Signature'

function buildMainContent({
  classInfo,
  student,
  gradeDetails,
  courseInfo,
  subjects,
  withSignature
}) {
  const academicPerformanceList = gradeDetails
    .filter((gradeDetail) => gradeDetail.student.hasAcademicPerformance)
    .map((gradeDetail) => gradeDetail.student)

  return [
    [
      buildStudentInfo({ student }),
      buildTable({ classInfo, gradeDetails, courseInfo, subjects }),
      buildCourseObservations({
        courseInfo,
        classInfo,
        academicPerformanceList
      }),

      buildSignature({
        signatures: classInfo.signatures,
        city: classInfo.unitCity,
        withSignature
      })
    ]
  ]
}

function buildHeaderContent({ course }) {
  return {
    stack: [
      { text: 'HISTÓRICO ESCOLAR', bold: true, fontSize: 14 },
      {
        text: 'HABILITAÇÃO PROFISSIONAL TÉCNICA DE NÍVEL MÉDIO',
        bold: true,
        fontSize: 14
      },
      {
        text: course.toUpperCase(),
        bold: true,
        fontSize: 14
      }
    ],
    alignment: 'center'
  }
}

function buildCompanyInfo({ courseInfo, classInfo, companyInfoList }) {
  return [
    {
      text: [
        { text: 'PROZ', bold: true },
        ` - ${classInfo.unit} - ${classInfo.unitCNPJ}`
      ]
    },
    'ESSA Educação Profissional S.A: 08.690.642/0001-71',
    companyInfoList[1]
      .split('-')
      .filter((_, index, arr) => index !== arr.length - 1)
      .join('-'),
    `${classInfo.unitTelephone} - https://www.prozeducacao.com.br`,
    htmlToPdfmake(courseInfo.resolutionCourse),
    classInfo.unitResolution
  ]
}

function buildFooterPage(classInfo, companyInfoList) {
  return (currentPage, pageCount) => ({
    table: {
      body: [
        [
          {
            stack: [
              { text: `Unidade ${classInfo.unit}` },
              {
                text: [
                  `${classInfo.unitAddress}, ${classInfo.unitAddressNumber} - ${classInfo.unitDistrict} - ${classInfo.unitCity}/${classInfo.unitUf} CEP: ${classInfo.unitCEP} ${classInfo.unitTelephone}`,
                  ' - https://prozeducacao.com.br'
                ]
              }
            ]
          },
          {
            text: `${currentPage.toString()}/${pageCount}`,
            alignment: 'right'
          }
        ]
      ],
      widths: ['*', 'auto']
    },
    layout: { defaultBorder: false },
    margin: [15, 10, 15, 0],
    alignment: 'center'
  })
}

export default async function AcademicRecordPDFtypeSP({
  academicRecordData,
  withSignature
}) {
  const {
    classInfo,
    companyInfo: companyInfoList,
    students,
    studentAcademicReportInfo,
    courseInfo,
    subjects
  } = academicRecordData
  const signatureImage1 = classInfo?.signatures[0]?.image
  const signatureImage2 = classInfo?.signatures[1]?.image
  const { student, gradeDetails } = students[0]

  const studentInfo = {
    ...student,
    ...studentAcademicReportInfo
  }

  const companyInfo = buildCompanyInfo({
    courseInfo,
    classInfo,
    companyInfoList
  })

  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      pageOrientation: 'portrait',
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },
      ...(signatureImage1 || signatureImage2
        ? {
            images: {
              ...(signatureImage1
                ? {
                    signature1: {
                      url: signatureImage1,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {}),
              ...(signatureImage2
                ? {
                    signature2: {
                      url: signatureImage2,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {})
            }
          }
        : {}),
      pageBreakBefore: (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) => {
        const shouldBreakBefore =
          currentNode?.id === 'signatureInfo' &&
          currentNode.pageNumbers.length > 1

        return shouldBreakBefore
      },
      pageMargins: [20, 20, 20, 40],
      footer: buildFooterPage(classInfo, companyInfoList)
    }
  })

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE_ACADEMIC_RECORD_SP,
    mainContent: buildMainContent({
      classInfo,
      student: studentInfo,
      gradeDetails,
      courseInfo,
      subjects,
      withSignature
    }),
    companyInfo,
    headerContent: buildHeaderContent({
      course: academicRecordData?.classInfo?.course
    })
  })
}
