import { PersonAddAlt1, PersonRemoveAlt1 } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import { isEmpty } from 'services/helpers'
import * as Styled from './style'

const Toolbar = ({ hasBind, setOpenModal, selected }) => (
  <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
    <Styled.Toolbar>
      <Styled.Button
        className={
          hasBind
            ? TAG_MANAGER.secretary_academico_btn_desenturma_alunosEnturmados
            : TAG_MANAGER.secretary_academico_btn_enturma_alunosDesenturmados
        }
        type="button"
        color="warning"
        variant="outlined"
        disabled={isEmpty(selected)}
        startIcon={hasBind ? <PersonRemoveAlt1 /> : <PersonAddAlt1 />}
        value={hasBind ? 'DESENTURMAR' : 'ENTURMAR'}
        onClick={() => setOpenModal(true)}
      />
    </Styled.Toolbar>
  </Styled.Grid>
)

export default Toolbar
