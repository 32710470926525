import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  Tooltip
} from '@mui/material'

import { useSortTable } from 'services/hooks'
import { formatDate, paginationFilter } from 'services/helpers'
import { defaultlistPerPage } from 'services/hooks/constants'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'

import {
  checkboxTitleTooltip,
  emptyValue,
  invalidDate,
  keyColumns,
  tableColumns
} from './constants'

import * as Styled from './style'

const List = ({
  limit,
  page,
  items,
  count,
  totalPage,
  handleChange,
  handleSetPage,
  selected,
  isSelected,
  handleEdit,
  handleSelectAllClick
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    items,
    keyColumns.discipline
  )

  const showDate = (date) =>
    date !== invalidDate ? formatDate(date) : emptyValue

  return (
    <Styled.Wrapper isMultipleActions={!!selected?.length}>
      <Paper>
        <Table size="medium">
          <TableHeaderDefault
            checkbox
            order={order}
            orderBy={orderBy}
            headCells={tableColumns}
            rowCount={items?.length}
            numSelected={selected?.length}
            onRequestSort={handleRequestSort}
            handleSelectAllClick={handleSelectAllClick}
            checkboxTitleTooltip={checkboxTitleTooltip}
          />
          <TableBody>
            {sortTable?.map(
              ({
                id,
                discipline,
                startDate,
                endDate,
                schoolClassCourse,
                schoolClassCode,
                schoolClassGroup,
                schoolClassShift,
                activeStudent,
                schoolClassUnit
              }) => (
                <Styled.TableRow key={id}>
                  <TableCell
                    padding="checkbox"
                    onClick={() => handleEdit({ id })}
                  >
                    <Tooltip title={checkboxTitleTooltip}>
                      <Checkbox color="primary" checked={isSelected(id)} />
                    </Tooltip>
                  </TableCell>
                  <TableCell padding="checkbox" style={{ width: '30%' }}>
                    {`${discipline} [${id}]`}
                  </TableCell>
                  <TableCell>
                    <Styled.Period>
                      <Styled.PeriodDate>
                        {showDate(startDate)}
                      </Styled.PeriodDate>
                      <Styled.PeriodDate>até</Styled.PeriodDate>
                      <Styled.PeriodDate>{showDate(endDate)}</Styled.PeriodDate>
                    </Styled.Period>
                  </TableCell>
                  <TableCell>{schoolClassCourse}</TableCell>
                  <TableCell>{schoolClassCode}</TableCell>
                  <TableCell>{schoolClassGroup}</TableCell>
                  <TableCell>{schoolClassShift}</TableCell>
                  <TableCell>{schoolClassUnit}</TableCell>
                  <TableCell>{activeStudent}</TableCell>
                </Styled.TableRow>
              )
            )}
          </TableBody>
        </Table>

        {count > defaultlistPerPage && (
          <PaginationAdvanced
            isSelectItensPerPage
            handleChange={handleChange}
            handleSetPage={handleSetPage}
            count={totalPage}
            optionsList={paginationFilter}
            page={page}
            smallSize
            value={limit}
          />
        )}
      </Paper>
    </Styled.Wrapper>
  )
}

export default List
