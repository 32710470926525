export function buildTableHeader(subjects) {
  return [
    {
      text: 'Módulo',
      bold: true,
      border: [true, false, true, true],
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Ano de conclusão',
      border: [false, false, true, true],
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Componente curricular',
      border: [false, false, true, true],
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Carga horária',
      border: [false, false, true, true],
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Frequência %',
      border: [false, false, true, true],
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Média',
      border: [false, false, true, true],
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Resultado final',
      border: [false, false, true, true],
      style: ['tableHeaderCell'],
      alignment: 'center'
    }
  ]
}
