import styled from 'styled-components'
import { TableCell as MuiTableCell } from '@mui/material'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    min-width: 75px;
    max-width: 600px;
  }
`

export { TableCell }
