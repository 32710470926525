import { TYPES_OF_PAYMENT } from 'components/pages/Admin/Financial/PaymentPlans/constants'

const couponEmpty = undefined

const getInfoConfirmPayment = (typeOfPayment) => {
  switch (typeOfPayment) {
    case TYPES_OF_PAYMENT.RECURRENCE.value:
      return {
        confirmText: (
          <>
            Para confirmar a <b>matrícula</b> é necessário que o{' '}
            <b>pagamento via cartão de crédito seja aprovado</b> e assinar o
            contrato na plataforma.
            <br />
            Caso não efetue o pagamento, em até 10 dias sua matrícula será
            cancelada automaticamente.
          </>
        ),
        sendEmailText: (
          <>
            Enviamos um e-mail com as informações acima, contendo link para o
            pagamento online. As informações para acessar a plataforma e assinar
            o contrato serão enviadas logo após a confirmação do pagamento.
          </>
        )
      }

    default:
      return {
        confirmText: (
          <>
            Para confirmar a <b>matrícula</b> é necessário <b>pagar o boleto</b>
            indicado abaixo e assinar o contrato na plataforma. Caso não efetue
            o pagamento, em até 10 dias sua matrícula será cancelada
            automaticamente.
          </>
        ),
        sendEmailText: (
          <>
            Enviamos um e-mail com as informações acima, contendo link para o
            boleto e pagamento online. <br /> As informações para acessar a
            plataforma e assinar o contrato serão enviadas logo após a
            confirmação do boleto de matrícula.
          </>
        )
      }
  }
}

export { couponEmpty, getInfoConfirmPayment }
