export const DISCIPLINE_TAG_MANAGER = {
  student_select_change_course: 'aluno_select_alterna_curso',
  student_select_filters_situation_Discipline:
    'aluno_select_filtra_situacaoDisciplina',
  student_card_access_discipline: 'aluno_card_acessa_disciplina',
  btn_student_opens_summary_Notes: 'aluno_btn_abre_resumoNotas',
  btn_student_opens_report_card: 'aluno_btn_abre_boletim',
  student_switch_filters_Failed_courses:
    'aluno_switch_filtra_disciplinasReprovadas',
  student_link_accesses_Financial_shortcut:
    'aluno_link_acessa_atalhoFinanceiro',
  btn_Header_student_access_Profile_shortcut:
    'aluno_btnHeader_acessa_atalhoPerfil',
  student_btn_Header_access_notifications: 'aluno_btnHeader_acessa_notificacoes'
}
