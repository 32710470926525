import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowBack, NavigateNext } from '@mui/icons-material'
import { TemplateFormPole as Template } from 'components/templates/Admin'
import { LoadingPage, Snackbar } from 'components/molecules'
import { HeaderPage } from 'components/organisms'
import { ROUTE, SUCCESS } from 'services/constants'
import {
  convertToParams,
  getUserFromLocalStorage,
  isEmpty,
  updateErrorMessage
} from 'services/helpers'
import { useSnack } from 'services/hooks'
import {
  createOrUpdatePoleAndUnit,
  createResponsible,
  deleteResponsible,
  getPoleOrUnit,
  getResponsible
} from 'services/api/admin'
import {
  breadcrumbsItems,
  INCLUDE_POLE,
  INITIAL_RESPONSIBLE,
  INITIAL_STATE_POLE,
  MENSSAGE_RESPONSIBLE,
  MESSAGE_SUCCESS,
  PAGE_EDIT,
  PATH_UNITS_POLE
} from './constants'
import TabsUnits from '../Tabs'
import {
  convertPayload,
  convertResponse,
  linkCallToActionGoBack,
  tagManagerGoBackButton
} from './services'

const CreateByUpdateUnitOrPole = () => {
  const { snack, setSnack, snackOpen } = useSnack()
  const [loadingVisibility, setLoadingVisibility] = useState(true)
  const [namePoleExist, setNamePoleExist] = useState(false)
  const [nameUnitExist, setNameUnitExist] = useState(false)
  const [hasLinkedPoles, setHasLinkedPoles] = useState(false)
  const [responsible, setResponsible] = useState(INITIAL_RESPONSIBLE)
  const [disabledLinkedPoles, setDisabledLinkedPoles] = useState(false)
  const [formUnitPole, setFormUnitPole] = useState(INITIAL_STATE_POLE)
  const { company_id: companyId } = getUserFromLocalStorage()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const {
    unitId,
    poleId,
    situation,
    unit: isUnit,
    pole: isPole,
    nameUnit: isNameUnit,
    goBackPole
  } = params
  const typePage = {
    ...(situation === 'edit' && { edit: situation }),
    ...(isPole && { pole: isPole === 'true' }),
    ...(isUnit && { unit: isUnit === 'true' })
  }
  const validationId = isPole ? poleId : unitId

  const getUnitsOrPoles = async (id) => {
    setSnack('', '')
    setLoadingVisibility(true)

    const { data, error, status } = await getPoleOrUnit(id)

    setLoadingVisibility(false)

    if (error) {
      return updateErrorMessage({ setSnack, error, status })
    }

    setHasLinkedPoles(data?.has_linked_pole)

    const response = convertResponse({ data, typePage })

    return setFormUnitPole(response)
  }

  const navigate = useNavigate()

  const savedSuccessfully = () => {
    if (typePage.edit === PAGE_EDIT) {
      getUnitsOrPoles(validationId)

      return setSnack(MESSAGE_SUCCESS(isUnit, typePage), SUCCESS)
    }

    setFormUnitPole(INITIAL_STATE_POLE)
    return setSnack(MESSAGE_SUCCESS(isUnit, typePage), SUCCESS)
  }

  const redirectIncludePole = (data) => {
    if (disabledLinkedPoles && typePage.edit === PAGE_EDIT) {
      return savedSuccessfully()
    }

    navigate(
      `${ROUTE.ADMIN_MANAGEMENT_POLE_CREATE}?${convertToParams({
        unit: formUnitPole.unit,
        unitId: data?.data?.id_unidade || unitId || null,
        situation: INCLUDE_POLE,
        pole: isUnit
      })}`
    )

    setSnack(MESSAGE_SUCCESS(isUnit, typePage), SUCCESS)

    return setFormUnitPole(INITIAL_STATE_POLE)
  }

  const handleCreateOrUpdatePoleOrUnit = async (values) => {
    setSnack('', '')
    setLoadingVisibility(true)

    const payloadBody = convertPayload({
      typePage,
      values,
      PAGE_EDIT,
      companyId,
      unitId
    })

    const { data, error, status } = await createOrUpdatePoleAndUnit(
      payloadBody,
      typePage,
      validationId
    )

    setLoadingVisibility(false)

    if (error) {
      if (typePage.edit !== PAGE_EDIT) {
        if (error.message.pole) return setNamePoleExist(true)
        if (error.message.unit) return setNameUnitExist(true)
      }

      return updateErrorMessage({ setSnack, error, status })
    }

    setNamePoleExist(false)
    setNameUnitExist(false)

    return isUnit && formUnitPole.includePole
      ? redirectIncludePole(data)
      : savedSuccessfully()
  }

  const getSignatureResponsible = async () => {
    setSnack('', '')
    setLoadingVisibility(true)

    const { data, error, status } = await getResponsible(validationId)

    setLoadingVisibility(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: 'para visualizar responsável'
      })
    }

    return setResponsible(isEmpty(data.signatures) ? INITIAL_RESPONSIBLE : data)
  }

  const handleDeleteSignatureResponsible = async ({
    valuesForms,
    index,
    remove
  }) => {
    setSnack('', '')
    setLoadingVisibility(true)

    if (
      !valuesForms.role ||
      !valuesForms.file ||
      !valuesForms.document ||
      !valuesForms.name
    ) {
      setLoadingVisibility(false)
      remove(index)
      return setSnack(MENSSAGE_RESPONSIBLE.deleteSuccess, SUCCESS)
    }

    const payloadDelete = {
      role: valuesForms.role,
      file: valuesForms.file,
      document: valuesForms.document,
      name: valuesForms.name
    }

    const { error, status } = await deleteResponsible(
      payloadDelete,
      validationId
    )

    setLoadingVisibility(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: 'para cadastrar responsável'
      })
    }

    remove(index)
    return setSnack(MENSSAGE_RESPONSIBLE.deleteSuccess, SUCCESS)
  }

  const successFullMessage = () => {
    setSnack(MENSSAGE_RESPONSIBLE.success, SUCCESS)

    return setTimeout(window.location.reload(true), 3000)
  }

  const handleCreateResponsible = async (values) => {
    setSnack('', '')
    setLoadingVisibility(true)

    const signatures = values.signatures?.reduce((acc, cur) => {
      const { document, file, name, role } = cur
      return [...acc, { document, file, name, role }]
    }, [])

    const payload = {
      signatures
    }

    const { error: hasError, status } = await createResponsible(
      payload,
      validationId
    )

    setLoadingVisibility(false)

    if (hasError) {
      const error =
        hasError?.message?.errors?.body?.signatures?.filter(
          (item) => item !== null
        )[0] || hasError

      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: 'para cadastrar responsável'
      })
    }

    return successFullMessage()
  }

  useEffect(() => {
    setLoadingVisibility(false)

    if (situation === PAGE_EDIT) {
      getSignatureResponsible()
      getUnitsOrPoles(validationId)
    }
  }, [])

  return (
    <>
      <LoadingPage open={loadingVisibility} />
      <Template
        titleHeader={
          <HeaderPage
            separator={<NavigateNext size="small" />}
            breadcrumbsNotLink={[
              ...breadcrumbsItems({
                typePage,
                hasLinkedPoles,
                isUnit,
                isNameUnit
              })
            ]}
            classNameCallToAction={`goBack ${tagManagerGoBackButton(typePage)}`}
            callToAction={() =>
              linkCallToActionGoBack({
                goBackPole,
                situation,
                hasLinkedPoles,
                typePage,
                navigate,
                convertToParams,
                isUnit,
                unitId
              })
            }
            startIcon={<ArrowBack />}
            labelCallToAction="voltar"
            divider
          />
        }
        content={
          <TabsUnits
            nameUnit={isUnit}
            nameUnitExist={nameUnitExist}
            namePoleExist={namePoleExist}
            isNameUnit={isNameUnit}
            unitId={unitId}
            responsible={responsible}
            handleDeleteSignatureResponsible={handleDeleteSignatureResponsible}
            setDisabledLinkedPoles={setDisabledLinkedPoles}
            editForm={typePage.edit}
            hasLinkedPoles={hasLinkedPoles}
            initialState={INITIAL_STATE_POLE}
            formUnitPole={formUnitPole}
            setFormUnitPole={setFormUnitPole}
            typePage={typePage}
            handleCreateResponsible={handleCreateResponsible}
            handleCreateOrUpdatePoleOrUnit={handleCreateOrUpdatePoleOrUnit}
            PATH={PATH_UNITS_POLE}
          />
        }
        snackAlert={
          <Snackbar
            message={snack.message}
            severity={snack.severity}
            shouldOpen={snackOpen}
          />
        }
      />
    </>
  )
}

export default CreateByUpdateUnitOrPole
