import { addMonthsToDate, getDateStartOfMonth } from 'services/helpers'

const PENDING_STATUS = 'pending'
const INITIAL_DAY_PAYROLL_COMPETENCE = 16

const situationOptions = [
  { value: 'sent', label: 'Enviado' },
  { value: 'checked', label: 'Conferido' },
  { value: PENDING_STATUS, label: 'Pendente' }
]

const currentCompetence = () =>
  new Date().getDate() < INITIAL_DAY_PAYROLL_COMPETENCE
    ? getDateStartOfMonth()
    : addMonthsToDate(1, getDateStartOfMonth())

const initialTimekeeping = {
  competence: currentCompetence(),
  status: PENDING_STATUS,
  school_class_code: ''
}

const MESSAGE = {
  NOT_FOUND_TIMEKEEPING:
    'Não encontramos um resultado para essa busca.<br />Verifique os dados informados e tente novamente.'
}

export { situationOptions, initialTimekeeping, MESSAGE }
