const FEEDBACK_PROFESSORS = {
  noAccessPermissionTocreateProfessors: 'para criar professor',
  noAccessPermissionToUpdateProfessors: 'para atualizar professor',
  noAccessPermissionToListProfessors: 'para listar professores',
  noAccessPermissionToListSupervisor: 'para listar coordenadores',
  noAccessPermissionToListInteractions: 'para listar interações',
  noAccessPermissionToListDocsProfessors:
    'para listar documentos de professores',
  noAccessPermissionToCreateDocsProfessors:
    'para criar documentos de professores',
  noAccessPermissionToDeleteDocsProfessors:
    'para deletar documentos de professores',
  noAccessPermissionToListTypesDocsProfessors:
    'para listar tipos de documentos dos professores',
  noAccessPermissionToCreateSkillsProfessors: 'para criar skills',
  noAccessPermissionToDeleteSkillsProfessors: 'para deletar skills',
  noAccessPermissionToMainSkillsProfessors:
    'para torna uma formação acadêmica como principal',
  noAccessPermissionToUploadPhoto: 'para fazer upload de foto',
  noAccessPermissionToListClassLinked: 'para listar vínculo de turma',

  noAccessPermissionToListDisciplines: 'para listar disciplinas',
  noAccessPermissionToEditClassLinked: 'para editar vínculo de turma',
  noAccessPermissionToDeleteClassLinked: 'para deletar vínculo de turma',
  noAccessPermissionToInactivateClassLinked: 'para inativar vínculo de turma',
  noAccessPermissionToViewDetailsClassLinked:
    'para visualizar detalhes do vínculo da turma',

  noAccessPermissionToValidateCPF: 'para validar CPF',
  noAccessPermissionToListRegions: 'para listar regionais'
}

const FEEDBACK_PAYROLL = {
  noAccessPermissionToListPayroll: 'para listar apontamentos',
  noAccessPermissionToListSchoolClassManagement: 'para listar turmas',
  noAccessPermissionToListDetailsSchoolClassManagement:
    'para listar detalhes de turma',
  noAccessPermissionToListChecked: 'para conferir turmas',
  noAccessPermissionToListReverted: 'para reverter turmas',
  noAccessPermissionToCreatePayroll: 'para criar apontamentos',
  noAccessPermissionToListReceipts: 'para listar recibos',
  noAccessPermissionToListHeadersReceipts: 'para listar Informações do recibos',
  noAccessPermissionToListSendReceipts: 'para enviar recibos para consilidação',
  noAccessPermissionToListConsolidation: 'para listar consilidações',
  noAccessPermissionToListProfessorTimekeeping:
    'para listar apontamentos de horas do professor',
  noAccessPermissionToSendConsolidation: 'para enviar consilidações',
  noAccessPermissionToListProfessors: 'para listar professsores'
}

export { FEEDBACK_PROFESSORS, FEEDBACK_PAYROLL }
