import { useState } from 'react'

const useActionSelected = () => {
  const [selected, setSelected] = useState({
    type: null,
    data: null
  })

  const onActionSelected = (action, item) => {
    setSelected({
      type: action,
      data: item
    })
  }

  const onResetAction = () => {
    setSelected({
      type: null,
      data: null
    })
  }

  return {
    onActionSelected,
    selected,
    onResetAction
  }
}

export default useActionSelected
