import { Suspense } from 'react'
import { LoadingPage } from 'components/molecules'
import * as Styled from './style'

const TemplateChangePassword = ({ titleHeader, content, snackAlert }) => (
  <Suspense fallback={<LoadingPage open />}>
    <Styled.Content>
      <div>{titleHeader}</div>
      <div>{content}</div>
      <div>{snackAlert}</div>
    </Styled.Content>
  </Suspense>
)

export default TemplateChangePassword
