export const formatInitialValues = ({
  startDate,
  endDate,
  grouping: name,
  id
}) => ({
  id,
  startDate,
  endDate,
  name
})

export const formatCreatePayload = ({
  level,
  unit,
  startDate,
  endDate,
  name
}) => ({
  level,
  unit,
  startDate,
  endDate,
  groupingName: name
})

export const formatEditPayload = ({ startDate, endDate, name }) => ({
  startDate,
  endDate,
  groupingName: name
})
