import { Delete } from '@mui/icons-material'
import { Box, IconButton, TableRow, Tooltip } from '@mui/material'
import TAG_MANAGER from 'tagManager'
import { emptyHoursValue, emptyValue } from '../constants'
import * as Styled from './style'

const TableContent = ({
  isItemSelected,
  row,
  setIsEdit,
  setIsDelete,
  setOpenCreateByEdit,
  setConfirmModal,
  setIdSubject,
  setValuesModal,
  openCreateByEdit
}) => {
  const handleEdit = () => {
    setIsEdit(true)
    setOpenCreateByEdit(!openCreateByEdit)
    setIdSubject(row?.id)
    setValuesModal({
      content_description: row?.content_description,
      content_section: row?.content_section,
      content_workload: row?.content_workload
    })
  }

  const handleDelete = () => {
    setIsDelete(true)
    setIdSubject(row?.id)
    setConfirmModal(true)
  }

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?.id}
      selected={isItemSelected}
    >
      <Styled.TableCellTitle>
        <div>
          <Tooltip title="Editar" placement="top" arrow>
            <Box
              onClick={() => handleEdit()}
              className={TAG_MANAGER.gestao_btn_editar_ConteudoEAD}
            >
              {row?.content_description || emptyValue}
            </Box>
          </Tooltip>

          <Box className="bnt-delete">
            <Tooltip title="Excluir" placement="top" arrow>
              <IconButton
                disableRipple
                onClick={() => handleDelete()}
                className={TAG_MANAGER.gestao_btn_excluir_ConteudoEAD}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        </div>
      </Styled.TableCellTitle>

      <Styled.TableCell>{row?.content_section || emptyValue}</Styled.TableCell>
      <Styled.TableCellHours>
        {row?.content_workload || emptyHoursValue} h
      </Styled.TableCellHours>
    </TableRow>
  )
}

export default TableContent
