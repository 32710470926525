import { useState, lazy } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useMakeServiceCouseBySchoolClass, useSnack } from 'services/hooks'
import { newRequirements } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { breadcrumbsItems, payload } from './helpers'
import { MESSAGE, initialValues } from './constants'
import { FormFormik } from '../EditRequisition/components/FormHistoric/style'
import getSchema from './schema'

const Form = lazy(() => import('./Form'))

const CreateRequisition = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [register, setRegister] = useState('')
  const [courseId, setCourseId] = useState('')
  const [listRequirements, setListRequirements] = useState([])
  const [listRequirementsSchool, setListRequirementsSchool] = useState([])
  const [listSector, setListSector] = useState([])
  const [listResponsible, setListResponsible] = useState([])
  const [isActiveSector, setIsActiveSector] = useState(true)
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])
  const { searchType, searchBy } = params

  const { courseList, schoolClass } = useMakeServiceCouseBySchoolClass(
    register,
    courseId,
    setSnack
  )

  const onHandleConfirm = () => {
    setOpenModal(false)
    if (searchType || searchBy) navigate(-1)
    else
      navigate(ROUTE.ADMIN_SECRETARY_REQUISITION, {
        state: { savedSearch: true }
      })
  }

  const onSubmit = async (values) => {
    setSnack('', '')
    setLoading(true)

    const { error, status } = await newRequirements(
      payload({
        values,
        register
      })
    )

    setLoading(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateRequisition
      })
    }
    setTimeout(() => navigate(-1), 2000)

    return setSnack(MESSAGE.SUCCESS)
  }

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems()}
      onClickButton={() => setOpenModal(true)}
      classNameHeader="goBack"
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      tagmanagerModal={TAG_MANAGER.secretary_btn_BackApplication}
      content={
        <Formik
          initialValues={{
            ...initialValues,
            ...(searchType && { searchType }),
            ...(searchBy && { searchBy })
          }}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={getSchema({ hasClass: !!schoolClass.length })}
        >
          {(props) => (
            <FormFormik
              onSubmit={props.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Form
                {...props}
                setOpenModal={setOpenModal}
                setSnack={setSnack}
                setLoading={setLoading}
                setRegister={setRegister}
                register={register}
                setCourseId={setCourseId}
                courseList={courseList}
                schoolClass={schoolClass}
                listRequirements={listRequirements}
                setListRequirements={setListRequirements}
                listSector={listSector}
                setListSector={setListSector}
                isActiveSector={isActiveSector}
                listResponsible={listResponsible}
                setListResponsible={setListResponsible}
                listRequirementsSchool={listRequirementsSchool}
                setListRequirementsSchool={setListRequirementsSchool}
              />
            </FormFormik>
          )}
        </Formik>
      }
    />
  )
}

export default CreateRequisition
