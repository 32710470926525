import { lazy } from 'react'
import { Form, Formik } from 'formik'
import { Grid, Table, TableBody, TableContainer } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { useCheckedTable, useSortTable } from 'services/hooks'
import { IsEmpty } from 'services/helpers'
import { TableHeaderDefault } from 'components/organisms'
import { Button } from 'components/atoms'
import {
  ButtonFooterV2,
  ModalConfirm,
  PaginationAdvanced
} from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import {
  defaultPage,
  defaultTotalPage,
  headCells,
  idNameList,
  paginationFilter
} from './constants'
import * as Styled from './style'
import { deleteDiscipline, updateDisciplines } from '../../services'

const TableTdBody = lazy(() => import('./TableBody'))

const TableEditDisplines = ({
  id,
  setEditDisciplineList,
  setCount,
  setIsPagination,
  itemsTable,
  totalPage,
  pageActual,
  setOpenModal,
  setPage,
  setOpenModalAddDiscipline,
  openModalDelete,
  setOpenModalDelete,
  setSnack,
  setLoadingOpen,
  count,
  filter,
  setFilter,
  optionDisciplines,
  optionDisciplinesJoy,
  setFreeDisciplineCodes,
  freeDisciplineCodes
}) => {
  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    setSelected,
    selected: selectedItem
  } = useCheckedTable(itemsTable, idNameList)

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  const { handleRequestSort, order, orderBy, sortTable } =
    useSortTable(itemsTable)

  const selectedItems = selectedItem?.length

  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 2 }} alignItems="center">
        <Grid item xs={12} sm={12} md={6}>
          <Styled.Toolbar>
            <Button
              color="error"
              variant="outlined"
              startIcon={<Delete />}
              value="Excluir"
              disabled={selectedItems < defaultPage}
              onClick={() => setOpenModalDelete(true)}
            />
          </Styled.Toolbar>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Styled.BoxButton>
            <Button
              size="medium"
              startIcon={<Add />}
              className={
                TAG_MANAGER.secretary_academico_btn_adiciona_disciplina
              }
              value="Adicionar Disciplina"
              onClick={() => setOpenModalAddDiscipline(true)}
            />
          </Styled.BoxButton>
        </Grid>
      </Grid>

      {!IsEmpty(selectedItem) &&
        selectedItems &&
        `${selectedItems} itens selecionados`}

      <Formik
        initialValues={sortTable}
        onSubmit={(values) =>
          updateDisciplines({
            id,
            setSnack,
            setLoadingOpen,
            values,
            setEditDisciplineList,
            setCount,
            setFreeDisciplineCodes,
            setIsPagination,
            pageActual,
            filter
          })
        }
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Styled.TableContainer>
                  <TableContainer>
                    <Table size="small">
                      <TableHeaderDefault
                        checkbox
                        numSelected={selectedItem.length}
                        order={order}
                        orderBy={orderBy}
                        handleSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={itemsTable?.length}
                        headCells={headCells}
                      />
                      <TableBody>
                        {values?.map((row, index) => {
                          const isItemSelected = isSelected(row.id)
                          const labelId = `enhanced-table-${index}`

                          return (
                            <TableTdBody
                              key={row.id}
                              listValues={values}
                              isItemSelected={isItemSelected}
                              labelId={labelId}
                              setFieldValue={setFieldValue}
                              indexRow={index}
                              values={row}
                              handleClick={handleClick}
                              selected={selectedItem}
                              optionDisciplines={optionDisciplines}
                              optionDisciplinesJoy={optionDisciplinesJoy}
                              freeDisciplineCodes={freeDisciplineCodes}
                            />
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {count > defaultTotalPage && (
                    <PaginationAdvanced
                      value={filter}
                      optionsList={paginationFilter}
                      isSelectItensPerPage
                      count={totalPage}
                      page={pageActual}
                      handleSetPage={handleSetPage}
                      handleChange={(e) => {
                        setPage(defaultPage)
                        setFilter(e.target.value)
                      }}
                    />
                  )}
                </Styled.TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Styled.Box>
                  <ButtonFooterV2
                    labelClose="Cancelar"
                    classNameConfirm="academico_btn_salva_turma"
                    labelConfirm="Salvar"
                    size="medium"
                    onClickClose={() => setOpenModal(true)}
                  />
                </Styled.Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <ModalConfirm
        open={openModalDelete}
        size="medium"
        handleClose={() => setOpenModalDelete(false)}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        classNameBtnConfirm={
          TAG_MANAGER.secretary_academico_btn_exclui_Disciplina
        }
        onClickNotConfirm={() => setOpenModalDelete(false)}
        onClickConfirm={() =>
          deleteDiscipline({
            id,
            setSnack,
            setLoadingOpen,
            selected: selectedItem,
            setSelected,
            filter,
            pageActual,
            setCount,
            setFreeDisciplineCodes,
            setIsPagination,
            setEditDisciplineList,
            setOpenModalDelete
          })
        }
        colorButtonConfirm="error"
        icon={<Styled.IconWarningAmber />}
        title="Excluir Disciplina"
        message={`Você tem certeza que deseja excluir esta(s) ${selectedItems} disciplina(s)?`}
      />
    </>
  )
}
export { TableEditDisplines }
