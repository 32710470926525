const initialValues = {
  file: '',
  endDate: '',
  startDate: '',
  reasonForAbsence: ''
}

const optionsJustifyAbsence = [
  { value: 'Serviço Eleitoral', label: 'Serviço Eleitoral' },
  { value: 'Serviço Militar', label: 'Serviço Militar' },
  { value: 'Licença maternidade', label: 'Licença maternidade' },
  { value: 'Licença paternidade', label: 'Licença paternidade' },
  { value: 'Falecimento de conjugê', label: 'Falecimento de conjugê' },
  { value: 'Falecimento de pai/mãe', label: 'Falecimento de pai/mãe' },
  { value: 'Atestado médico', label: 'Atestado médico' },
  { value: 'Feriado religioso', label: 'Feriado religioso' },
  { value: 'Falecimento de filho (a)', label: 'Falecimento de filho (a)' },
  { value: 'grandparents_death', label: 'Falecimento de Avós' },
  { value: 'Falecimento de Avós', label: 'Alistamento ao Exército' },
  { value: 'Declaração de trabalho', label: 'Declaração de trabalho' },
  { value: 'Comparecimento em juízo', label: 'Comparecimento em juízo' },
  { value: 'Certidão de Casamento', label: 'Certidão de Casamento' },
  {
    value: 'Doação voluntária de sangue',
    label: 'Doação voluntária de sangue'
  },
  {
    value: 'Comprovante de comparecimento',
    label: 'Comprovante de comparecimento'
  },
  {
    value: 'Realização de exames preventivos de câncer',
    label: 'Realização de exames preventivos de câncer'
  },
  {
    value: 'Realização de provas de exame vestibular',
    label: 'Realização de provas de exame vestibular'
  },
  {
    value: 'Atestado médico (Acompanhante)',
    label: 'Atestado médico (Acompanhante)'
  },
  {
    value: 'Representar entidades sindicais oficiais',
    label: 'Representar entidades sindicais oficiais'
  },
  {
    value: 'Falecimento de irmão(a)',
    label: 'Falecimento de irmão(a)'
  }
]

const typesFileAllowed = ['PDF', 'PNG', 'JPG']

const lastIndex = typesFileAllowed.length - 1

const mountTextTypesFileAllowed = typesFileAllowed.reduce(
  (previousType, currentType, currentIndex) =>
    `${previousType ? '' : ' '} ${previousType}, ${currentType}${
      lastIndex === currentIndex ? '.' : ''
    }`
)

const messageErrorFileForbbiden = `Somente permitido arquivo(s) com o(s) tipo(s) ${mountTextTypesFileAllowed}`

const labelContent = `Clique aqui para enviar um documento. Somente arquivos em ${mountTextTypesFileAllowed}*`

export {
  initialValues,
  optionsJustifyAbsence,
  typesFileAllowed,
  messageErrorFileForbbiden,
  labelContent
}
