import styled from 'styled-components'
import { Grid, RadioGroup as MuiRadioGroup } from '@mui/material'

const CardContainer = styled(Grid)`
  border-radius: 8px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;

  padding: 24px;
  box-sizing: border-box;
`

const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    -webkit-flex-wrap: nowrap;
  }
`

const WrapperButtons = styled(Grid)`
  display: flex;
  gap: 25px;
  padding: 0 0 0 24px;
  margin: 0;

  button {
    margin: 0;
  }
`

const FormBox = styled.form``

const Title = styled.p`
  color: ${({ theme }) => theme.palette.gray.main};
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 123.5%;
  letter-spacing: 0.25px;
  margin: 0;
`

const SubTitle = styled(Title)`
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.15px;
`

const Description = styled(Title)`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  padding: 0;
`

const Observation = styled(Grid)`
  .MuiTextField-root {
    margin: 0;
  }
`

export {
  CardContainer,
  WrapperButtons,
  FormBox,
  RadioGroup,
  Title,
  SubTitle,
  Description,
  Observation
}
