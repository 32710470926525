import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'

import { listTypeOfResponsible } from '../../constants'

const SelectResponsible = ({ values, handleChange, fieldNameForm }) => (
  <Grid
    container
    xs={12}
    spacing={2}
    alignItems="center"
    justifyContent="center"
    m="20px 0"
  >
    <RadioGroup
      onChange={handleChange}
      name={`${fieldNameForm}.financialResponsibleType`}
      value={values?.[fieldNameForm]?.financialResponsibleType}
      row
    >
      {listTypeOfResponsible.map((item) => (
        <FormControlLabel
          value={item.fieldName}
          control={<Radio size="small" />}
          label={item.label}
        />
      ))}
    </RadioGroup>
  </Grid>
)

export default SelectResponsible
