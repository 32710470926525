import React from 'react'
import { Tooltip } from '@mui/material'
import * as Styled from './style'

const BarChart = ({ label, value, height }) => (
  <Tooltip title={`${label}:${value}`} placement="top">
    <Styled.Container $height={`${height}%`} />
  </Tooltip>
)

export default BarChart
