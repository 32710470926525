import { Grid } from '@mui/material'
import styled from 'styled-components'

const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export { Container }
