import { getActivitiesList as getActivitiesListService } from 'services/api/admin'
import { getCourse as getCourseService } from 'services/api/shared'
import {
  dateFormat,
  deserialize,
  messageErroOrSuccess,
  serialize
} from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'

export const getActivitiesList = async ({
  setSnack,
  setState,
  search: { startDate, endDate, ...restSearch },
  rowsPerPage,
  currentPage,
  setStatus
}) => {
  const {
    error,
    status,
    data: { data, count, limit, ...restProps }
  } = await getActivitiesListService(
    cleanObject(
      deserialize({
        ...restSearch,
        startDate: startDate ? dateFormat(startDate, 'YYYY-MM-DD') : '',
        endDate: endDate ? dateFormat(endDate, 'YYYY-MM-DD') : '',
        limit: rowsPerPage,
        page: currentPage
      })
    )
  )

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  setState({
    ...restProps,
    count,
    limit,
    activities: data.map(serialize)
  })

  setStatus({
    empty: false,
    notFound: !(data ?? [])?.length
  })
}

export const getListCourses = async ({ setSnack, setState, params }) => {
  const { error, status, data } = await getCourseService(
    params?.level,
    params?.unit,
    true
  )

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  const listFormatted = (data ?? [])?.map((item) => ({
    ...item,
    label: item?.name,
    name: item?.id,
    value: item?.name
  }))

  setState(listFormatted)
}
