import styled from 'styled-components'
import { Link as LinkTable } from 'react-router-dom'
import { TableCell as MuiTableCell } from '@mui/material'
import { Delete } from '@mui/icons-material'
import theme from 'theme/designTokens'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
  }
`

const TableCellNumber = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: center;
    padding: 6px 16px;
  }
`
const Link = styled(LinkTable)`
  &.link-table-school-class {
    color: ${() => theme.palette.primary.main};
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    color: ${() => theme.palette.primary.main};
    text-decoration: underline;
    width: 15%;
    padding: 6px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        padding: 0 8px;
      }

      & > div {
        display: none;
      }

      &:hover > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`

const TableCellUnit = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: center;
    width: ${({ $isLocation }) => ($isLocation ? '14%' : 'auto')};
    padding: 6px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        color: ${() => theme.palette.primary.main};
        margin: 5px;
      }
    }
  }
`

const ContainerIcon = styled.div`
  & .MuiButtonBase-root {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    color: ${() => theme.palette.textColors.error};
  }
`

export {
  TableCell,
  TableCellTitle,
  TableCellNumber,
  ContainerIcon,
  DeleteIcon,
  TableCellUnit,
  Link
}
