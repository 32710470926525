import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'

const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    padding: 1rem;
  }
`

export { Grid }
