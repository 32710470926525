const MESSAGE = {
  NO_SEARCH_PERFOMED:
    '<b>Você ainda não fez uma busca</b><br/>Selecione uma opção e você pode combinar com os filtros para obter uma pesquisa mais específica por turmas.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.'
}

const NAME = 'name'
const CONTRACT = 'contract'
const REGISTRATION = 'register'
const SCHOOL_CLASS = 'school_class_code'
const EMAIL = 'email'
const nameAccordion = 'scretary_contracts'
const defaultItemsPerPage = 10
const countDefault = 0
const defaultPage = 1
const emptyValue = ''
const PAGE_DOCUMENTS = 'Contratos'

export {
  MESSAGE,
  NAME,
  REGISTRATION,
  EMAIL,
  SCHOOL_CLASS,
  CONTRACT,
  nameAccordion,
  defaultItemsPerPage,
  countDefault,
  defaultPage,
  emptyValue,
  PAGE_DOCUMENTS
}
