const defaultName = 'name'

const MESSAGE = {
  DELETE:
    'Você tem certeza que deseja excluir esta formação? Esta ação é irreversível.',
  MAIN: 'Ao marcar esta opção como principal, a seleção anterior será substituída. Deseja continuar?'
}

const emailHeadCells = [
  {
    id: 'created_at',
    label: 'Envio'
  },
  {
    id: 'subject',
    label: 'Assunto'
  },
  {
    id: 'to',
    label: 'Destinatário'
  },
  {
    id: 'created_by',
    label: 'Enviado por'
  }
]

const smsHeadCells = [
  {
    id: 'sent_at',
    label: 'Envio'
  },
  {
    id: 'content',
    label: 'Mensagem'
  },
  {
    id: 'mobile_phone',
    label: 'Destinatário'
  },
  {
    id: 'user',
    label: 'Enviado por'
  }
]

const whatsAppHeadCells = [
  {
    id: 'sent_at',
    label: 'Envio'
  },
  {
    id: 'layout',
    label: 'Assunto'
  }
]

export { emailHeadCells, smsHeadCells, whatsAppHeadCells, defaultName, MESSAGE }
