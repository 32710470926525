import { Paper, Table, TableBody, TableCell } from '@mui/material'

import { paginationFilter } from 'services/helpers'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'
import { defaultlistPerPage } from 'services/hooks/constants'
import { useSortTable } from 'services/hooks'

import { tableColumns, KEY_COLUMNS, fieldNameByType } from './constants'

import * as Styled from './style'

const List = ({
  limit,
  page,
  items,
  count,
  totalPage,
  handleSetPage,
  handleChange,
  handleEdit,
  itemEditingContractId
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    items,
    KEY_COLUMNS.COURSE
  )

  return (
    <Styled.Wrapper>
      <Paper>
        <Table size="medium">
          <TableHeaderDefault
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableColumns}
          />
          <TableBody>
            {sortTable?.map((item, index) => {
              const labelId = `table-${index}`

              const fieldName =
                fieldNameByType?.[item?.financialResponsibleType]

              const isEditing = item?.contractId === itemEditingContractId

              return (
                <Styled.TableRow key={labelId} isEditing={isEditing}>
                  <TableCell style={{ width: '25%' }}>{item?.course}</TableCell>
                  <TableCell style={{ width: '10%' }}>
                    {item?.contractId}
                  </TableCell>
                  <TableCell style={{ width: '10%' }}>
                    {!item?.module ? 'Único' : `${item?.module}º`}
                  </TableCell>
                  <TableCell style={{ width: '30%' }}>
                    <Styled.Unity>
                      {item?.unit}
                      {item?.pole && (
                        <Styled.Tooltip title={item?.pole}>
                          <Styled.PlaceIcon />
                        </Styled.Tooltip>
                      )}
                    </Styled.Unity>
                  </TableCell>
                  <TableCell style={{ width: '25%' }}>
                    <Styled.Tooltip title="Editar Responsável Financeiro">
                      <Styled.Link onClick={() => handleEdit(item)}>
                        {item?.[fieldName]}
                      </Styled.Link>
                    </Styled.Tooltip>
                  </TableCell>
                </Styled.TableRow>
              )
            })}
          </TableBody>
        </Table>

        {count > defaultlistPerPage && (
          <PaginationAdvanced
            isSelectItensPerPage
            handleChange={handleChange}
            handleSetPage={handleSetPage}
            count={totalPage}
            optionsList={paginationFilter}
            page={page}
            smallSize
            value={limit}
          />
        )}
      </Paper>
    </Styled.Wrapper>
  )
}

export default List
