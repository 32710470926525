import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { LoadingPage } from 'components/molecules'
import { Stepper } from 'components/organisms'
import { STEPS } from './constants'
import { FINAL_STEP, initialStateForm } from '../../constants'
import { formSchema } from './schema'
import { getContractBranchList } from './services'
import * as Styled from './style'
import { confirmRegister } from '../../services'

const Content = ({
  setSnack,
  activeStep,
  contentForm,
  setActiveStep,
  setContentForm
}) => {
  const [listContractBranch, setListContractBranch] = useState([])
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [enrollmentDetails, setEnrollmentDetails] = useState({})
  const [isPole, setIsPole] = useState(false)
  const [isValidCpf, setIsValidCpf] = useState(false)

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: contentForm,
    onSubmit: (values) => {
      confirmRegister({
        values,
        setContentForm,
        setEnrollmentDetails,
        setActiveStep,
        setLoadingVisibility
      })
    },
    validationSchema: formSchema(isPole)[activeStep]
  })
  useEffect(() => {
    getContractBranchList({
      setListContractBranch,
      setLoadingVisibility,
      setSnack
    })
  }, [])

  return (
    <>
      <LoadingPage open={loadingVisibility} />
      <Styled.Form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
        <Stepper
          steps={STEPS}
          formik={formik}
          labelGoBack="Voltar"
          stepFinal={FINAL_STEP}
          labelNextStap="Avançar"
          isValidCpf={isValidCpf}
          activeStep={activeStep}
          contentForm={contentForm}
          labelFinallyStep="Finalizar"
          setActiveStep={setActiveStep}
          setContentForm={setContentForm}
          labelgoBackInitial="Matricular outro"
          disabledNext={!formik.isValid}
          initialState={initialStateForm}
        >
          {STEPS[activeStep]?.content({
            formik,
            setSnack,
            setIsPole,
            isValidCpf,
            setIsValidCpf,
            enrollmentDetails,
            listContractBranch,
            setLoadingVisibility
          })}
        </Stepper>
      </Styled.Form>
    </>
  )
}

export default Content
