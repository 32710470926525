import { useState, lazy } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Formik } from 'formik'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { SUCCESS } from 'services/constants'
import { useSnack } from 'services/hooks'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { createContract } from 'services/api/admin'
import { breadcrumbsItems } from './helpers'
import { MESSAGE, initialValues } from './constants'
import * as Styled from './style'

const Form = lazy(() => import('./Form'))

const FinalizeCreatStudent = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const {
    studentName,
    name,
    modality,
    modalityId,
    shift,
    unit,
    level,
    group,
    pole,
    register
  } = params
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [listSchoolClass, setListSchoolClass] = useState([])

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(-1)
  }
  const savedSuccessfully = () => {
    setSnack(MESSAGE.SUCCESS_CREATE, SUCCESS)

    return setTimeout(() => navigate(-2), 3000)
  }

  const onSubmit = async (values) => {
    const payload = {
      unit: unit || '',
      level: level || '',
      course: name || '',
      period: shift || '',
      group: group || '',
      pole: pole || '',
      module_id: values?.module_id,
      modality_id: Number(modalityId),
      student_register: Number(register) || '',
      class_id: values?.class_id || ''
    }

    setSnack('')
    setLoading(true)

    const { error, status } = await createContract(payload)
    setLoading(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToResetPassword
      })
    }

    return savedSuccessfully()
  }

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems({ studentName, name })}
      onClickButton={() => setOpenModal(true)}
      classNameHeader="goBack"
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      tagmanagerModal={TAG_MANAGER.secretary_btn_BackApplication}
      content={
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(props) => (
            <Styled.FormFormik
              onSubmit={props.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Form
                {...props}
                setSnack={setSnack}
                setOpenModal={setOpenModal}
                setLoading={setLoading}
                setListSchoolClass={setListSchoolClass}
                listSchoolClass={listSchoolClass}
                studentName={studentName}
                name={name}
                modality={modality}
                shift={shift}
                unit={unit}
                level={level}
                group={group}
                pole={pole}
              />
            </Styled.FormFormik>
          )}
        </Formik>
      }
    />
  )
}

export default FinalizeCreatStudent
