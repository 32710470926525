import styled, { css } from 'styled-components'
import { TextField as MuiTextField } from 'components/molecules'
import {
  Typography as MuiTypography,
  IconButton as MuiIconButton
} from '@mui/material'

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr 200px;
  grid-gap: 24px;
  margin-top: 16px;
`

const Box = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-rows: auto auto;
`
const BoxConfirm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

const BoxItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  & > h6:last-of-type {
    margin-left: 8px;
  }
`
const TextField = styled(MuiTextField)`
  &.MuiTextField-root {
    width: 190px;
    margin-right: 8px;
  }
`

const BoxLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  & > a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & > .MuiBox-root {
    cursor: pointer;
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & > a > button {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    text-align: center;
    font-size: 14px;
    margin: 24px auto;

    ${({ $maxWidth }) =>
      $maxWidth &&
      css`
        max-width: 880px;
        margin: 0 auto;
      `}
  }
`
const IconButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    margin-bottom: 4px;
    padding: 0;
    margin-left: 8px;
  }
`

const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export {
  Container,
  Box,
  BoxItem,
  BoxLink,
  TextField,
  Typography,
  IconButton,
  BoxConfirm,
  WrapperButton
}
