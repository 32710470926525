export const numberSelected = 0
export const indexDefault = -1
export const isSelected = 1
export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const defaultName = 'name'
export const typeAll = 'Tudo'
export const defaultTotalPage = 1
const day = 10

const headCellsPending = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Pré-Matrícula'
  },
  {
    id: 'cpf',
    numeric: false,
    disablePadding: false,
    label: 'CPF'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nome'
  },
  {
    id: 'course',
    numeric: false,
    disablePadding: false,
    label: 'Curso'
  },
  {
    id: 'shift',
    numeric: false,
    disablePadding: false,
    label: 'Turno'
  },
  {
    id: 'register_responsible',
    numeric: false,
    disablePadding: false,
    label: 'Responsável'
  }
]

export { headCellsPending, day }
