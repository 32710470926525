import styled from 'styled-components'
import { Box as MuiBox } from '@mui/material'

const WrapperContent = styled.div`
  & > .MuiBox-root {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const Box = styled(MuiBox)`
  &.MuiBox-root {
    display: flex;
    justify-content: ${({ $justifyContent }) => $justifyContent};
    border-bottom: ${({ $borderBottom }) => $borderBottom || '0'}px solid;
    border-top: ${({ $borderTop }) => $borderTop || '0'}px solid;
    border-right: ${({ $borderRight }) => $borderRight || '0'}px solid;
    border-left: ${({ $borderLeft }) => $borderLeft || '0'}px solid;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-color: ${({ $borderColor }) => $borderColor};
  }
`

export { WrapperContent, Box }
