import styled from 'styled-components'
import { TableRow as MuiTableRow } from '@mui/material'
import { Download as MuiDownload } from '@mui/icons-material'

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  tr:hover td {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const WrapperLink = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  button {
    font-size: 14px;
    margin-left: ${({ theme }) => theme.spacing(1)};
    padding: 0;
    text-align: left;
  }
`

const TableRow = styled(MuiTableRow)`
  ${({ $isDisabled }) =>
    $isDisabled && ' opacity: 0.5; &:hover { opacity: 1; }'}

  .MuiTableCell-root {
    padding: 10px 15px;
  }

  .downloadSummary {
    display: none;
  }

  :hover {
    .downloadSummary {
      display: flex;
    }
  }
`

const IconDownload = styled(MuiDownload)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.dark.medium};
    outline: none;
    border: 0px;
  }
`

export { TableRow, Wrapper, WrapperLink, IconDownload }
