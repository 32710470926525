import { ListAlt, Search } from '@mui/icons-material'

const messages = {
  supplierEmpty: {
    icon: <ListAlt />,
    text: '<strong>Encontre os clientes/fornecedores cadastrados aqui.</strong><br /> Você precisa selecionar a Razão Social ou CNPJ<br /> para realizar sua busca.'
  },
  supplierNotFound: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
  },
  createSupplier: {
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Cadastro realizado com sucesso'
  },
  updateSupplier: {
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Cadastro atualizado com sucesso'
  },
  inactiveSupplier: {
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Cliente/Fornecedor inativado com sucesso.'
  },
  activeSupplier: {
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Cliente/Fornecedor ativado com sucesso.'
  }
}

const defaultlistPerPage = 10

const getTitleModalConfirm = (inactive) => (inactive ? 'Inativar' : 'Ativar')

const getMessageModalConfirm = (inactive) =>
  inactive
    ? 'Deseja realmente inativar este cliente/fornecedor?'
    : 'Deseja realmente ativar este cliente/fornecedor?'

export {
  messages,
  defaultlistPerPage,
  getTitleModalConfirm,
  getMessageModalConfirm
}
