import { AttachMoney } from '@mui/icons-material'
import { ACL } from 'services/constants/acl'
import { ROUTE } from 'services/constants'
import theme from 'theme/designTokens'
import {
  BankBilling,
  FinancialBreakdown,
  Invoices,
  ListCupon,
  Campaigns,
  CreateByUpdateCupon,
  CreateByEditCampaign,
  ListPaymentPlans,
  CreateByUpdatePaymentPlan,
  ListBillsToReceive,
  ListGroup,
  CreateByUpdateGroup,
  NewBankBilling,
  RegisterSupplier,
  CreateOrUpdateSupplier
} from 'components/pages/Admin'
import Manager from 'components/pages/Admin/Financial/Manager'
import isAllowUserAccess from '../helpers/isAllowUserAccess'

export const iconProps = {
  color: theme.palette.primary.white
}

const FINANCIAL_ROUTES = [
  {
    component: ListBillsToReceive,
    exact: true,
    icon: <AttachMoney sx={{ ...iconProps }} />,
    label: 'Financeiro',
    path: ROUTE.ADMIN_FINANCIAL_BILLS_RECEIVE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL'),
    submenu: [
      {
        component: ListBillsToReceive,
        exact: true,
        label: 'Contas a Receber',
        path: ROUTE.ADMIN_FINANCIAL_BILLS_RECEIVE,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'FINANCIAL_BILLS_RECEIVE')
      },
      {
        component: Invoices,
        exact: true,
        label: 'Notas Fiscais',
        path: ROUTE.ADMIN_FINANCIAL_INVOICES,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'FINANCIAL_INVOICES')
      },
      {
        component: Manager,
        exact: true,
        label: 'Faturamento ',
        path: ROUTE.ADMIN_FINANCIAL_MANAGER,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'FINANCIAL_MANAGER')
      },
      {
        component: ListGroup,
        exact: true,
        label: 'Grupos',
        path: ROUTE.ADMIN_FINANCIAL_GROUPS,
        permissions: ACL.admin,
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'FINANCIAL_GROUPS')
      },
      {
        component: ListCupon,
        exact: true,
        label: 'Cupons',
        path: ROUTE.ADMIN_FINANCIAL_CUPON,
        permissions: ACL.admin,
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'FINANCIAL_CUPON')
      },
      {
        component: ListPaymentPlans,
        exact: true,
        label: 'Planos de Pagamento',
        path: ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN,
        permissions: ACL.admin,
        isAllowed: () => true
      },
      {
        component: RegisterSupplier,
        exact: true,
        label: 'Cadastro Cliente/Fornecedor',
        path: ROUTE.ADMIN_FINANCIAL_REGISTER_SUPPLIER,
        permissions: ACL.admin,
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'FINANCIAL_REGISTER_SUPPLIER')
      },
      {
        component: Campaigns,
        exact: true,
        label: 'Campanhas',
        path: ROUTE.ADMIN_FINANCIAL_CAMPAIGNS,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'FINANCIAL_CAMPAIGNS')
      }
    ]
  },
  {
    component: CreateByEditCampaign,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_CREATE_CAMPAIGN,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_CAMPAIGN_CREATE')
  },
  {
    component: CreateByEditCampaign,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_UPDATE_CAMPAIGN,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_CAMPAIGN_UPDATE')
  },
  {
    component: CreateByUpdateGroup,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_GROUPS_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_GROUPS_CREATE')
  },
  {
    component: CreateByUpdateGroup,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_GROUPS_EDIT,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_GROUPS_EDIT')
  },
  {
    component: BankBilling,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_BANK_BILLING_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_BANK_BILLING_EDIT')
  },
  {
    component: BankBilling,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_BANK_BILLING_CREATE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_BANK_BILLING_CREATE')
  },
  {
    component: BankBilling,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_INVOICES_BANK_BILLING_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_INVOICES_BANK_BILLING_EDIT')
  },
  {
    component: FinancialBreakdown,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_BREAKDOWN,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_BREAKDOWN')
  },
  {
    component: NewBankBilling,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_BREAKDOWN_NEW_BANK_BILLING,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_BREAKDOWN_NEW_BANK_BILLING')
  },
  {
    component: CreateByUpdatePaymentPlan,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_PAYMENT_PLAN_CREATE')
  },
  {
    component: CreateByUpdatePaymentPlan,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN_EDIT,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_PAYMENT_PLAN_EDIT')
  },
  {
    component: CreateByUpdateCupon,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_CUPON_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_CUPON_CREATE')
  },
  {
    component: CreateByUpdateCupon,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_CUPON_EDIT,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_CUPON_EDIT')
  },
  {
    component: CreateOrUpdateSupplier,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_REGISTER_SUPPLIER_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_REGISTER_SUPPLIER_CREATE')
  },
  {
    component: CreateOrUpdateSupplier,
    exact: true,
    path: ROUTE.ADMIN_FINANCIAL_REGISTER_SUPPLIER_EDIT,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'FINANCIAL_REGISTER_SUPPLIER_EDIT')
  }
]

export default FINANCIAL_ROUTES
