import { ROUTE } from 'services/constants'

const defaultItemsPerPage = 10
const countDefault = 0
const defaultPage = 1

const initialState = {
  begin_at: '',
  end_at: ''
}

const intialPesonalData = {
  name: '',
  email: '',
  phone_number: ''
}

const MESSAGE = {
  NOT_SEARCH:
    '<b>Encontre as interações do professor.</b><br /> Selecione um critério e realize uma busca para encontrar as interações com o professor.',
  NOT_FOUND:
    'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
}

const breadCrumbsItems = [
  {
    path: '',
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: 'Professores'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: '<b>Interações</b>'
  }
]

export {
  initialState,
  intialPesonalData,
  breadCrumbsItems,
  MESSAGE,
  defaultItemsPerPage,
  countDefault,
  defaultPage
}
