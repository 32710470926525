import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Form as FormikForm, Formik } from 'formik'
import { Box, Chip, Grid, Typography } from '@mui/material'
import { useMakeService } from 'services/hooks'
import { isEmpty } from 'services/helpers'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import {
  InputAutocomplete,
  InputCheckbox,
  PageTitle,
  TextNotFound
} from 'components/atoms'
import { messages } from '../../config'
import {
  defaultValues,
  makeServiceHookParams,
  validationSchema
} from './config'

export default function Form({
  sector,
  responsibles = [],
  isEdit,
  handlePayload
}) {
  const navigate = useNavigate()
  const { unitys } = useMakeService(makeServiceHookParams)
  const [optionsUnitys, setOptionsUnitys] = useState([
    {
      id: 0,
      label: 'Todas Unidades',
      value: 0
    }
  ])

  const handleSubmit = (values) => {
    const newValues = {
      ...values,
      users: values?.users?.map(({ id }) => id)
    }
    handlePayload(newValues)
  }

  const getInitialValues = isEdit ? sector : ''

  useEffect(() => {
    if (unitys?.length > 0) {
      setOptionsUnitys((prev) => [...prev, ...unitys])
    }
  }, [unitys.length])

  return (
    <>
      <Formik
        validateOnMount
        initialValues={getInitialValues || defaultValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleChange, isValid, setFieldValue, values }) => (
          <FormikForm autoComplete="off" noValidate>
            <Grid container spacing={{ xs: 2 }}>
              <Grid item xs={12}>
                <PageTitle title="Dados do Setor" />
              </Grid>

              {isEdit && (
                <Grid item xs={12}>
                  <InputCheckbox
                    checked={values?.inactive}
                    handleChange={() =>
                      setFieldValue('inactive', !values?.inactive)
                    }
                    label="Inativar"
                  />
                </Grid>
              )}

              <Grid item xs={6}>
                <Select
                  label="Unidade*"
                  onChange={(e) => setFieldValue('unityId', e.target.value)}
                  optionsList={optionsUnitys}
                  value={values?.unityId}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="sectorName"
                  label="Setor*"
                  onChange={handleChange}
                  value={values?.sectorName || ''}
                />
              </Grid>

              <Grid item xs={12} marginBottom={1}>
                <Typography variant="body1">
                  Selecione os responsáveis do setor:
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <InputAutocomplete
                  filterSelectedOptions
                  getOptionLabel={(option) =>
                    `${option?.name} - ${option?.unity}`
                  }
                  handleChange={(_, users) => {
                    setFieldValue('users', users)
                  }}
                  isOptionEqualToValue={(option, selected) =>
                    option?.id === selected?.id
                  }
                  label="Nome do responsável*"
                  labelId="users"
                  listOptions={responsibles}
                  multiple
                  noOptionsText="Nenhuma pessoa encontrada"
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option?.id}>
                      {`${option?.name} - ${option?.unity}`}
                    </Box>
                  )}
                  renderTags={(selected, getTagProps) =>
                    selected.map(({ id, name, unity }, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={id}
                        label={`${name} - ${unity}`}
                        variant="outlined"
                      />
                    ))
                  }
                  value={values?.users}
                />
              </Grid>

              {isEmpty(values?.users) && (
                <TextNotFound
                  icon={messages.usersEmpty.icon}
                  text={messages.usersEmpty.text}
                />
              )}

              <Grid item xs={12} marginTop={3}>
                <ButtonFooterV2
                  disabledConfirm={!isValid}
                  labelConfirm={isEdit ? 'Atualizar' : 'Salvar'}
                  labelClose="Cancelar"
                  onClickClose={() => navigate(-1)}
                />
              </Grid>
            </Grid>
          </FormikForm>
        )}
      </Formik>
    </>
  )
}

Form.propTypes = {
  handlePayload: PropTypes.func,
  isEdit: PropTypes.bool,
  sector: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

Form.defaultProps = {
  handlePayload: () => {},
  isEdit: false,
  sector: {}
}
