import styled from 'styled-components'
import { Paper, Toolbar as MuiToolbar } from '@mui/material'
import { Button as AtomButton } from 'components/atoms'

const TableContainer = styled(Paper)`
  &.MuiPaper-root {
    margin: ${({ $switchAdvancedSearch }) =>
      $switchAdvancedSearch ? '5px 0 0 0' : '13px 0 0 0'};
  }
`

const Toolbar = styled(MuiToolbar)`
  &.MuiToolbar-root {
    padding: 0;
    align-items: center;
    display: ${({ $displayNone }) => ($displayNone ? 'none' : 'flex')};
  }
`

const Button = styled(AtomButton)`
  &.MuiButtonBase-root {
    padding: 4px 8px;
  }
`

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export { TableContainer, Toolbar, Button, Box }
