export const cleanObject = (obj) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) =>
        value !== '0' &&
        value !== 0 &&
        value !== null &&
        value !== '' &&
        value !== undefined &&
        value?.length !== 0
    )
  )

export const cleanObjectWithZero = (obj) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) =>
        value !== '0' &&
        value !== null &&
        value !== '' &&
        value !== undefined &&
        value?.length !== 0
    )
  )
