import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import { DASH_ID, initialValues } from './constants'
import * as Styled from './style'
import { getExportFilesPole } from '../../../services'

const ModalPole = ({
  handleClose,
  open,
  typeModal,
  setSnack,
  setLoadingOpen,
  handleClosePoleDropDown,
  classId
}) => {
  const navigate = useNavigate()
  const isEmptyDash = typeModal === DASH_ID

  return (
    <Styled.Modal open={open} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) =>
          getExportFilesPole({
            setSnack,
            setLoadingOpen,
            values,
            navigate,
            handleClose,
            typeModalPole: typeModal,
            classId,
            handleClosePoleDropDown
          })
        }
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <Styled.Title variant="h5" component="h2">
                    {isEmptyDash ? 'Dashboard' : 'Relatório de Presença'}
                  </Styled.Title>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2">
                    Escolha o período para
                    {isEmptyDash
                      ? ' o dashboard '
                      : ' os relatório de presença '}
                    que você deseja gerar:
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <DatePicker
                    fullWidth
                    id="startDate"
                    label="De (opcional)"
                    value={values?.startDate || null}
                    onChange={(newValue) => {
                      setFieldValue('startDate', newValue)
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DatePicker
                    fullWidth
                    id="endDate"
                    label="Até (opcional)"
                    value={values?.endDate || null}
                    onChange={(newValue) => {
                      setFieldValue('endDate', newValue)
                    }}
                  />
                </Grid>
              </Grid>

              <Styled.Box>
                <ButtonFooterV2
                  labelClose="Cancelar"
                  labelConfirm="Confirmar"
                  classNameConfirm={
                    !isEmptyDash && 'academico_btn_baixa_relatorioPresenca'
                  }
                  size="medium"
                  onClickClose={handleClose}
                />
              </Styled.Box>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}
export { ModalPole }
