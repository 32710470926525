import { defaultItemsPerPage, defaultPage } from './constants'

const paramsOnSubmit = ({ values, filter, pageActual }) =>
  new URLSearchParams({
    ...{ blocked: values.inactive },
    ...(values.name && { name: values.name }),
    ...(values.cpf && { cpf: values.cpf }),
    ...(values.register && { register: values.register }),
    limit: filter || defaultItemsPerPage,
    page: pageActual || defaultPage
  })

const paramsProfessorCourseReport = ({ values, isValidation }) =>
  new URLSearchParams({
    ...(values?.unit && { unit: values?.unit }),
    ...(values?.firstPeriod && { start_date: values?.firstPeriod }),
    ...(values?.endPeriod && { end_date: values?.endPeriod })
  })

const payloadCreateCopy = ({ values, selectedIds }) => ({
  ...(values?.type && {
    occurrence_name: values?.type
  }),
  category: values?.category,
  ids: selectedIds || []
})

export { paramsOnSubmit, payloadCreateCopy, paramsProfessorCourseReport }
