const buildTableHeader = (subjects) => [
  [
    {
      text: '',
      border: [true, false, true, false],
      style: ['tableHeaderCell']
    },
    {
      text: '',
      border: [false, false, true, false],
      style: ['tableHeaderCell']
    },
    ...subjects
      .map((subject) => [
        {
          text: subject.name,
          bold: true,
          style: ['tableHeaderCell'],
          border: [false, false, true, false],
          alignment: 'center',
          colSpan: 2
        },
        {}
      ])
      .flat()
  ],
  [
    {
      text: 'Nº',
      bold: true,
      style: ['tableHeaderCell'],
      border: [true, false, true, false],
      alignment: 'center'
    },
    {
      text: 'Nome',
      bold: true,
      style: ['tableHeaderCell'],
      border: [false, false, true, false]
    },
    ...subjects
      .map((subject) => [
        {
          text: `${subject.workload}h`,
          bold: true,
          style: ['tableHeaderCell'],
          alignment: 'center',
          colSpan: 2
        },
        {}
      ])
      .flat()
  ],
  [
    {
      style: ['tableHeaderCell'],
      text: '',
      border: [true, false, true, true]
    },
    {
      style: ['tableHeaderCell'],
      text: '',
      border: [false, false, true, true]
    },
    ...subjects
      .map((_) => [
        {
          text: 'Nota',
          bold: true,
          style: ['tableHeaderCell'],
          alignment: 'center'
        },
        {
          text: 'Falta',
          bold: true,
          style: ['tableHeaderCell'],
          alignment: 'center'
        }
      ])
      .flat()
  ]
]
export default buildTableHeader
