import styled, { css } from 'styled-components'

const Container = styled.div``

const Warning = styled.div`
  width: 100%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  background-color: #f9efe6;
  border-radius: 4px;
  padding: 15px 0;
  gap: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
`

const WarningTitle = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 143%;
  text-transform: uppercase;
  margin: 0;
`

const WarningDescribe = styled(WarningTitle)`
  font-size: 12px;
  line-height: 166%;
  text-transform: none;
  font-weight: 500;
`

const WrapperInfo = styled.div`
  display: flex;
  gap: 20px;
`

const Info = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const InfoKey = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 175%;
  margin: 0;
`

const InfoValue = styled(InfoKey)`
  font-weight: 300;
`
const TextErro = styled.h2`
  width: 100%;
  color: #f44336;
  font-weight: 600;
  font-size: 13.5px;
  line-height: 143%;
  padding: 10px 15px;
  font-style: normal;
  letter-spacing: 0.15px;
  background-color: #feeceb;
  strong {
    font-weight: 800;
  }
`

const WarningPeriod = styled.div`
  background-color: ${({ theme }) =>
    theme.palette.textColors.secondary.mediumGray};
  margin: 50px 0;
  display: flex;
  flex-wrap: nowrap;

  ${({ $expired }) =>
    $expired &&
    css`
      background-color: #feeceb;
    `}
`
const Text = styled.p`
  margin-top: 70px;
`

const WarningPeriodContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
`

const WarningPeriodTitle = styled(WarningDescribe)`
  font-size: 32px;
`

const WarningPeriodDescribe = styled(WarningPeriodTitle)`
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
`

const WarningPeriodTextWrap = styled.div`
  gap: 5px;
  display: flex;
  margin-top: 20px;

  ${({ strong }) =>
    strong &&
    css`
      font-weight: 700;
    `}
`

const WarningPeriodTextOrange = styled(WarningPeriodDescribe)`
  color: ${({ theme }) => theme.palette.yellow.warning};
  font-weight: 700;
  width: fit-content;
  margin: 0;
  height: fit-content;
`

const WarningPeriodImage = styled.img`
  background-size: 100%;
`

const StepTitle = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
`

const Situations = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
`

const Term = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 315px;
  }
`

const TermTitle = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  width: 100%;
`

const TermContent = styled(TermTitle)`
  margin: 0 0 30px 0;
  font-size: 12px;
  font-weight: 400;
`

const TermStudentAccept = styled(TermContent)`
  font-size: 14px;
  font-weight: 700;
  width: fit-content;
  margin: 0;
`

export {
  Container,
  Warning,
  WarningTitle,
  WarningDescribe,
  WrapperInfo,
  Info,
  InfoKey,
  TextErro,
  InfoValue,
  WarningPeriod,
  WarningPeriodContent,
  WarningPeriodTitle,
  WarningPeriodDescribe,
  WarningPeriodTextWrap,
  WarningPeriodTextOrange,
  WarningPeriodImage,
  StepTitle,
  Situations,
  Term,
  Text,
  TermTitle,
  TermContent,
  TermStudentAccept
}
