import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { noBorderOnTop } = utilsPDF

export function buildTableHeader() {
  return [
    {
      text: 'Nº Ordem',
      bold: true,
      border: noBorderOnTop,
      alignment: 'center',
      style: ['tableHeaderCell']
    },
    {
      text: 'Ano/Etapa',
      bold: true,
      border: noBorderOnTop,
      alignment: 'center',
      style: ['tableHeaderCell']
    },
    {
      text: 'Componente Curricular',
      bold: true,
      border: noBorderOnTop,
      style: ['tableHeaderCell']
    },
    {
      text: 'Aproveitamento/Desempenho',
      bold: true,
      border: noBorderOnTop,
      alignment: 'center',
      style: ['tableHeaderCell']
    },
    {
      text: 'C.H Curricular',
      bold: true,
      border: noBorderOnTop,
      alignment: 'center',
      style: ['tableHeaderCell']
    },
    {
      text: 'Faltas/Horas',
      bold: true,
      border: noBorderOnTop,
      alignment: 'center',
      style: ['tableHeaderCell']
    },
    {
      text: 'Situação do Aluno',
      bold: true,
      border: noBorderOnTop,
      alignment: 'center',
      style: ['tableHeaderCell']
    }
  ]
}
