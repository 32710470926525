import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import useLogin from 'services/hooks/useLogin'
import { CardActions, CardContent } from '@mui/material'
import { setSavedDataFromUrl } from 'services/helpers'
import * as Styled from './style'

const ADMIM_URL = 'admin'
const AutomaticLogin = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const token = decodeURIComponent(escape(window.atob(params?.token)))
  const path = window.location.pathname
  const decode = decodeURIComponent(params?.route)

  const currentParams = {
    username: params?.username,
    password: token,
    type: params?.type,
    url: params?.url,
    path
  }

  const currentParamsAdmin = {
    token: params?.token,
    userType: params?.type,
    path,
    route: decode
  }

  const { handleLogin, serverMessage } = useLogin()

  const paramsAll =
    params?.type === ADMIM_URL ? currentParamsAdmin : currentParams

  useEffect(() => {
    setSavedDataFromUrl(paramsAll)
    handleLogin(paramsAll)
  }, [])

  return (
    <Styled.FormWrapper>
      <Styled.Card>
        {serverMessage ? (
          <>
            <CardContent>
              <Styled.Logotype width="160" />
              <Styled.Message>{serverMessage}</Styled.Message>
            </CardContent>
            <CardActions>
              <a href={params?.url}>Clique aqui para voltar ao login</a>
            </CardActions>
          </>
        ) : (
          <>
            <CardContent>
              <Styled.Logotype width="160" />
            </CardContent>
            <Styled.CardCircularProgress>
              <Styled.Message>Aguarde carregando</Styled.Message>
              <CircularProgress />
            </Styled.CardCircularProgress>
          </>
        )}
      </Styled.Card>
    </Styled.FormWrapper>
  )
}

export default AutomaticLogin
