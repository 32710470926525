const emptyCell = '---'
const noDate = '0000-00-00'

const headCells = [
  {
    id: 'begin_date',
    label: 'Início'
  },
  {
    id: 'end_date',
    label: 'Fim'
  },
  {
    id: 'active',
    label: 'Situação'
  }
]

const paginationFilter = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' }
]

const countDefault = 0
const defaultPage = 1
const defaultTotalPage = 10

const idNameList = 'id'

export {
  headCells,
  idNameList,
  defaultTotalPage,
  countDefault,
  defaultPage,
  paginationFilter,
  emptyCell,
  noDate
}
