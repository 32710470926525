import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel } from '@mui/material'

const InputCheckbox = ({
  className,
  label,
  checked,
  handleChange,
  disabled,
  inputProps
}) => (
  <FormControlLabel
    disabled={disabled}
    control={
      <Checkbox
        className={className}
        checked={checked}
        onChange={handleChange}
        inputProps={inputProps}
      />
    }
    label={label}
  />
)

export default InputCheckbox

InputCheckbox.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
}

InputCheckbox.defaultProps = {
  handleChange: () => {},
  checked: false,
  disabled: false
}
