import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NavigateNext, ArrowBack } from '@mui/icons-material'
import { TemplateRegistrationNewEnrollment as Template } from 'components/templates/Admin'
import { HeaderPage } from 'components/organisms'
import { Snackbar } from 'components/molecules'
import { useSnack } from 'services/hooks'
import { activeStepZero, breadcrumbsItems } from './constants'
import Content from './components/Content'
import HeaderSubscription from './components/HeaderSubscription'
import { getDetailsCampaign } from './services'

const RegistrationNewEnrollment = () => {
  const location = useLocation()
  const [dataCoupon, setDataCoupon] = useState()
  const [couponData, setCouponData] = useState('')
  const { snack, setSnack, snackOpen } = useSnack()
  const [anticipation, setAnticipation] = useState(false)
  const [detailsCampaign, setDetailsCampaign] = useState()
  const [activeStep, setActiveStep] = useState(activeStepZero)

  useEffect(() => {
    getDetailsCampaign({
      setSnack,
      setDetailsCampaign,
      unit: location?.state?.data?.unit_name
    })
  }, [location?.state?.data?.unit_name])

  return (
    <Template
      titleHeader={
        <HeaderPage
          separator={<NavigateNext size="small" />}
          breadcrumbsItems={breadcrumbsItems}
          startIcon={<ArrowBack />}
          divider
        >
          <HeaderSubscription
            setSnack={setSnack}
            activeStep={activeStep}
            couponData={couponData}
            anticipation={anticipation}
            detailsCampaign={detailsCampaign}
            setAnticipation={setAnticipation}
            data={location?.state?.data || {}}
            setCouponData={setCouponData}
            setDataCoupon={setDataCoupon}
            group={location?.state?.groups || ''}
          />
        </HeaderPage>
      }
      content={
        <Content
          setSnack={setSnack}
          dataCoupon={dataCoupon}
          couponData={couponData}
          activeStep={activeStep}
          anticipation={anticipation}
          detailsCampaign={detailsCampaign}
          dataHeader={location?.state?.data || {}}
          setActiveStep={setActiveStep}
          group={location?.state?.groups || ''}
          enrollmentId={location?.state?.data?.id || ''}
        />
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}
export default RegistrationNewEnrollment
