import styled from 'styled-components'
import { Form as FormikForm } from 'formik'
import { Grid } from '@mui/material'
import { Button as AtomButton } from 'components/atoms'

const Form = styled(FormikForm)`
  margin-top: 30px;
`

const GroupButton = styled.div`
  display: flex;
  align-itens: center;
  justify-content: flex-end;
`

const Button = styled(AtomButton)`
  &.MuiButtonBase-root {
    margin: 8px 0 0 0;

    &:last-child {
      margin: 8px 0 0 14px;
    }
  }
`

const GridInputSwitch = styled(Grid)`
  &.MuiGrid-root {
    label {
      margin-left: 1px;
      margin-right: 16px;
      margin-top: 9px;
    }
  }
`

export { Form, GroupButton, Button, GridInputSwitch }
