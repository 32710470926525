import { httpDelete, httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getListOccurrence = async (params, type, inactive) => {
  const typeString = `?occurrence_types_ids[]=${type?.join(
    '&occurrence_types_ids[]='
  )}`

  const endpoint = inactive
    ? ENDPOINT.OCCURRENCE.DELETE
    : ENDPOINT.OCCURRENCE.CRUD

  const response = await httpGet(
    `${endpoint}${type?.length ? typeString : ''}`,
    { params }
  )
  return response
}

export const getListOccurrenceById = async (id, inactive) => {
  const endpoint = inactive
    ? ENDPOINT.OCCURRENCE.DELETE
    : ENDPOINT.OCCURRENCE.CRUD

  const response = await httpGet(`${endpoint}/${id}`)
  return response
}

export const createOccurrences = async (payload) => {
  const response = await httpPost(ENDPOINT.OCCURRENCE.CRUD, { ...payload })

  return response
}

export const updateComplement = async ({ id, complement }) => {
  const response = await httpPut(
    `${ENDPOINT.OCCURRENCE.CRUD}/${id}/complement`,
    { complement }
  )

  return response
}

export const deleteOccurrence = async (params) => {
  const response = await httpDelete(`${ENDPOINT.OCCURRENCE.DELETE}`, {
    ...params
  })
  return response
}
