import styled from 'styled-components'
import { Form } from 'formik'
import {
  Grid,
  Paper as MuiPaper,
  TableCell as MuiTableCell
} from '@mui/material'
import { WarningAmber } from '@mui/icons-material'

const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 0 0 16px;
  }

  div {
    width: 350px;

    @media (max-width: 768px) {
      width: auto;
    }
  }
`

const InputFile = styled(Grid)`
  .MuiButtonBase-root {
    margin-top: 0;
    margin-bottom: 0;
    min-width: 356px;
    min-height: 156px;
  }
`

const FormFormik = styled(Form)`
  margin: 24px 0;
`

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
    margin-top: 20px;
  }
`

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
  }
`

const Attachment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    color: ${({ theme }) => theme.palette.textColors.black.darkBlack};
    opacity: 60%;
  }
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export {
  Search,
  InputFile,
  FormFormik,
  TableCell,
  Paper,
  Attachment,
  IconWarningAmber
}
