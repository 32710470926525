import FEEDBACK_SNACK from 'feedBackSnack'
import {
  getDocumentTypes,
  updateDocumentType,
  createDocumentType
} from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'
import { SUCCESS } from 'services/constants'
import { MESSAGE } from './constants'

const listDocumentTypes = async ({
  inactive,
  name,
  filter: limit,
  currentPage: page,
  setSnack,
  setDocumentTypes,
  setLoadingOpen,
  setCount
}) => {
  setLoadingOpen(true)
  setSnack('')

  const { error, status, data } = await getDocumentTypes(
    cleanObject({
      limit,
      page,
      name,
      inactive
    })
  )

  setLoadingOpen(false)
  setCount(data?.count)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDocumentTypes
    })
  }

  return setDocumentTypes(data.data)
}

const updateDocumentTypes = async ({
  id,
  payload,
  message = MESSAGE.SUCCESS_UPDATE,
  setSnack,
  setLoadingOpen
}) => {
  setLoadingOpen(true)
  setSnack('')

  const { error, status } = await updateDocumentType(id, payload)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToInactiveOccurrenciesTypes
    })
  }

  setSnack(message, SUCCESS)

  return null
}

const createDocumentTypes = async ({ setSnack, payload }) => {
  setSnack('')
  const { error, status } = await createDocumentType(payload)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToInactiveOccurrenciesTypes
    })
  }

  setSnack(MESSAGE.SUCCESS_CREATE, SUCCESS)

  return null
}

export { listDocumentTypes, updateDocumentTypes, createDocumentTypes }
