import { useState } from 'react'
import { PageTitle } from 'components/atoms'
import Tabs from 'components/organisms/Tabs'
import { TemplateProfile as Template } from 'components/templates/Shared'
import { useStore } from 'store/GlobalContext'
import { getIsValidTabs } from './perfilTabs'

const Profile = () => {
  const { user } = useStore()
  const [defaultTab] = getIsValidTabs(user?.role)
  const [value, setValue] = useState(defaultTab.index)

  return (
    <Template
      titleHeader={<PageTitle title="Perfil" />}
      content={
        <Tabs
          customValue={[value, setValue]}
          contents={getIsValidTabs(user?.role).map((tab) => tab.content)}
          navigations={getIsValidTabs(user?.role).map((tab) => tab.navigation)}
        />
      }
    />
  )
}

export default Profile
