const defaultItemsPerPage = 10
const defaultTotalPage = 1
const emptyValue = '---'
const emptyValueMoney = 'R$ 0,00'

const textTitleModalConfirmUserActive = 'Inativar Acesso'

const textTitleModalConfirmUserDisabled = 'Reativar Acesso'

const textMessageModal =
  '<b>Tem certeza que deseja resetar a conferência?</b><br /> Esta ação irá resetar a conferência anterior. Uma vez confirmada não será possível desfazer.'

const textTItleModalDisciplineAndClass = 'Disciplinas e Turmas'

const titleModalDownloadsFile = 'Baixar planilha'

const headCells = [
  {
    id: 'date',
    disabled: true,
    label: 'Data'
  },
  {
    id: 'week_day',
    disabled: true,
    label: 'Dia da semana'
  },
  {
    id: 'class_hours',
    disabled: true,
    label: 'Horas-aula'
  },
  {
    id: 'value',
    disabled: true,
    label: 'Valor'
  },
  {
    id: 'number_Classes',
    disabled: true,
    label: 'Nº de Aulas'
  },
  {
    id: 'subjects',
    disabled: true,
    label: 'Disciplina(s)'
  },
  {
    id: 'school_classes',
    disabled: true,
    label: 'Turma(s)'
  },
  {
    id: 'courses',
    disabled: true,
    label: 'Curso(s)'
  },
  {
    id: 'shift',
    disabled: true,
    label: 'Turno'
  },
  {
    id: 'activity',
    disabled: true,
    label: 'Atividade'
  }
]

export {
  headCells,
  defaultItemsPerPage,
  emptyValue,
  defaultTotalPage,
  textTitleModalConfirmUserActive,
  textMessageModal,
  textTitleModalConfirmUserDisabled,
  textTItleModalDisciplineAndClass,
  titleModalDownloadsFile,
  emptyValueMoney
}
