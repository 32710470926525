import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 900px;
  height: 250px;
`

const BackgroundImage = styled.img`
  position: absolute;
`

const Message = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const MessageText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const MessageTextTitle = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 143%;
  letter-spacing: 0.15px;
  margin: 0;
`

const MessageTextSubTitle = styled(MessageTextTitle)`
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
`

const MessageImage = styled.img``

export {
  Container,
  BackgroundImage,
  Message,
  MessageText,
  MessageTextTitle,
  MessageTextSubTitle,
  MessageImage
}
