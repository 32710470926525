import { TableRow, Tooltip } from '@mui/material'

import { formatDate } from 'services/helpers'

import { emptyValue } from '../../../constants'

import * as Styled from './style'

const TableFooter = ({ row, handleLink }) => (
  <TableRow hover>
    <Styled.TableCell style={{ width: '10%' }}>
      <div>
        <Tooltip title="Click para vincular" onClick={handleLink}>
          <Styled.Link href="#">{row?.schoolClassId}</Styled.Link>
        </Tooltip>
      </div>
    </Styled.TableCell>
    <Styled.TableCell>{row?.schoolClassCode ?? emptyValue}</Styled.TableCell>
    <Styled.TableCell>{row?.subjectName ?? emptyValue}</Styled.TableCell>
    <Styled.TableCell style={{ width: '20%' }}>
      {row?.schoolClassCourse ?? emptyValue}
    </Styled.TableCell>
    <Styled.TableCell>{row?.schoolClassGroup ?? emptyValue}</Styled.TableCell>
    <Styled.TableCell>
      {row?.schoolClassUnit ?? emptyValue}

      {row?.schoolClassPole && (
        <Styled.Tooltip title={row?.schoolClassPole ?? ''}>
          <Styled.PlaceIcon />
        </Styled.Tooltip>
      )}
    </Styled.TableCell>
    <Styled.TableCell>
      {row?.subjectStartDate ? formatDate(row?.subjectStartDate) : emptyValue}
    </Styled.TableCell>
    <Styled.TableCell>
      {row?.subjectEndDate ? formatDate(row?.subjectEndDate) : emptyValue}
    </Styled.TableCell>
  </TableRow>
)

export default TableFooter
