const REGISTRATION = {
  comercial_btn_search_Available_courses:
    'comercial_btn_busca_cursosDisponiveis',
  btn_commercial_selects_courses_registration:
    'comercial_btn_seleciona_cursosInscricao',
  btn_commercial_selects_group_registration:
    'comercial_btn_seleciona_grupoInscricao',
  commercial_btn_unsubscribe: 'comercial_btn_cancela_inscricao',
  commercial_btn_applies_couponDiscount: 'comercial_btn_aplica_cupomDesconto',
  commercial_btn_starts_inscricaoDadosAluno:
    'comercial_btn_inicia_inscricaoDadosAluno',
  commercial_btn_starts_importStudents: 'comercial_btn_inicia_importacaoAlunos',
  comercial_btn_starts_inscricaoDadosResponsavel:
    'comercial_btn_inicia_inscricaoDadosResponsavel',
  commercial_btn_ends_registration: 'comercial_btn_finaliza_inscricao',
  commercial_link_opens_Student_file: 'comercial_link_abre_fichaAluno',
  btn_commercial_opens_registration_Maual: 'comercial_btn_abre_matriculaMaual',
  commercial_btn_search_for_registrations: 'comercial_btn_busca_matriculas',
  btn_commercial_starts_new_registration: 'comercial_btn_inicia_novaInscricao',
  btn_commercial_confirms_registration_Manual:
    'comercial_btn_confirma_matriculaManual'
}

export const COMMERCIAL = {
  ...REGISTRATION
}
