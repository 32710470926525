/* eslint-disable camelcase */
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  activateCurriculum,
  getCurriculumCourses,
  getCurriculumModule,
  inactivateCurriculum
} from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'

const getListCourses = async ({ setListCourse, levels, setSnack }) => {
  setSnack('')

  const params = levels

  const { data, error, status } = await getCurriculumCourses(params)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListTypesRequirements
    })
  }

  const result = data?.map((course) => ({
    label: course.curso,
    value: course.curso
  }))

  return setListCourse(result)
}

const getCurriculumModules = async ({ setListModule, setSnack }) => {
  setSnack('')

  const { data, error, status } = await getCurriculumModule()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListTypesRequirements
    })
  }

  const result = data?.reduce((acc, cur) => {
    const { name, module_number } = cur
    const newItem = { value: module_number, label: name }

    return [...acc, newItem]
  }, [])

  return setListModule(result)
}

const savedSuccessfully = ({
  error,
  setSnack,
  status,
  handleSearch,
  valuesForm,
  setLoadingOpen,
  onResetAction
}) => {
  setLoadingOpen(false)
  onResetAction()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToInactiveCurriculum
    })
  }
  return handleSearch(valuesForm)
}

const onInactive = async ({
  setSnack,
  setLoadingOpen,
  selected,
  isModalActive,
  onResetAction,
  handleSearch,
  valuesForm
}) => {
  setSnack('')
  setLoadingOpen(true)

  const idCurriculum = selected.data

  if (isModalActive) {
    const { error, status } = await activateCurriculum(idCurriculum)

    return savedSuccessfully({
      error,
      setSnack,
      status,
      handleSearch,
      valuesForm,
      setLoadingOpen,
      onResetAction
    })
  }

  const { error, status } = await inactivateCurriculum(idCurriculum)

  return savedSuccessfully({
    error,
    setSnack,
    status,
    handleSearch,
    valuesForm,
    setLoadingOpen,
    onResetAction
  })
}

export { getListCourses, getCurriculumModules, onInactive }
