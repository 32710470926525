const statusList = [
  { value: 'all', name: 'Tudo' },
  { value: 'invoice_due', name: 'À vencer' },
  { value: 'overdue', name: 'Vencido' },
  { value: 'paid', name: 'Pago' },
]

const itensPerView = [
  { value: 0, name: '10' },
  { value: 1, name: '25' },
  { value: 2, name: '50' },
  { value: 3, name: '100' },
]

export { statusList, itensPerView }
