import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useSnack } from 'services/hooks'
import ContentMyScores from 'components/pages/Student/MyScores/components/ContentMyScores'
import { breadcrumbsItems } from './helpers'

const StudentScore = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])
  const { register, name, course, schoolClassId } = params
  const snackProps = useSnack()
  const { setSnack, updateSnack } = snackProps
  const [loading, setLoading] = useState(false)

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems(name, course)}
      onClickButton={handleGoBack}
      setLoading={setLoading}
      content={
        <ContentMyScores
          setSnack={setSnack}
          updateSnack={updateSnack}
          studentSchoolClassId={schoolClassId}
          studentRegister={register}
          studentCourseName={course}
        />
      }
    />
  )
}

export default StudentScore
