export const numberSelected = 0
export const indexDefault = -1
export const isSelected = 1
export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const defaultName = 'name'
export const typeAll = 'Tudo'
export const defaultTotalPage = 1

const headCellsPending = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nome da Declaração'
  },
  {
    id: 'level',
    numeric: false,
    disablePadding: false,
    label: 'Nível'
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Emissão'
  },
  {
    order: false,
    numeric: false,
    disabled: true,
    disablePadding: false,
    label: 'Texto Inicial'
  }
]

const listIssuance = [
  {
    value: 'online',
    label: 'Portal'
  },
  {
    value: 'face_to_face',
    label: 'Secretaria'
  },
  {
    value: 'both',
    label: 'Portal / Secretaria'
  },
  {
    value: '',
    label: 'Todos'
  }
]

const modulesReactQuill = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ['clean']
  ]
}

export { headCellsPending, listIssuance, modulesReactQuill }
