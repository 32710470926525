import { PaginationAdvanced } from 'components/molecules'

import * as Styled from './style'
import { useTableAbstract } from './hooks'
import TableHeaderDefault from '../Table/TableHeaderDefault'
import { defaultItemsPerPage, defaultPage, paginationFilter } from './constants'

const TableAbstract = ({
  rows,
  page,
  count,
  filter,
  order,
  setPage,
  orderBy,
  rowCount,
  setFilter,
  totalPage,
  numSelected,
  headerColumns,
  onRequestSort = () => {},
  elementsToolbar = [],
  checkbox = false,
  handleSelectAllClick,
  disabledCheckbox = false,
  checkboxTitleTooltip = ''
}) => {
  const { isElement, headerColumnsFormatted } = useTableAbstract({
    headerColumns
  })

  return (
    <>
      <Styled.TableToolbar>
        {elementsToolbar.map(({ content }) =>
          isElement({ content }) ? (
            content
          ) : (
            <Styled.RenderText>{content}</Styled.RenderText>
          )
        )}
      </Styled.TableToolbar>

      <Styled.Table component={Styled.Paper}>
        <TableHeaderDefault
          order={order}
          orderBy={orderBy}
          rowCount={rowCount}
          checkbox={checkbox}
          numSelected={numSelected}
          onRequestSort={onRequestSort}
          headCells={headerColumnsFormatted}
          disabledCheckbox={disabledCheckbox}
          handleSelectAllClick={handleSelectAllClick}
          checkboxTitleTooltip={checkboxTitleTooltip}
        />

        <Styled.TableBody>
          {rows.map(({ columns }) => (
            <Styled.TableRow>
              {columns.map(({ content, width }) => (
                <Styled.TableCell $width={width}>{content}</Styled.TableCell>
              ))}
            </Styled.TableRow>
          ))}
        </Styled.TableBody>
      </Styled.Table>

      {count > defaultItemsPerPage && (
        <PaginationAdvanced
          page={page}
          value={filter}
          count={totalPage}
          isSelectItensPerPage
          optionsList={paginationFilter}
          handleChange={(e) => {
            setFilter(e.target.value)
            setPage(defaultPage)
          }}
          handleSetPage={(_, newPage) => setPage(+newPage)}
        />
      )}
    </>
  )
}

export default TableAbstract
