const messagesModalBack = {
  title: 'Cancelar',
  text: 'Deseja cancelar a operação atual? Você perderá as alterações realizadas.'
}

const messagesModalUnify = {
  title: 'Unificar matrículas',
  text: 'CUIDADO! Esta ação é irreversível e terá efeito imediato.'
}
export { messagesModalBack, messagesModalUnify }
