const idNameList = 'id'
const firstOrder = 1
const positionProfessors = [1, 2, 3, 4, 5, 6, 7, 8]

const headCells = [
  {
    id: 'discipline',
    label: 'Disciplina'
  },
  {
    id: 'workload',
    label: 'Carga Horária'
  },
  {
    id: 'module',
    label: 'Módulo'
  },
  {
    id: 'professors',
    label: 'Professor'
  },
  {
    id: 'startDate',
    label: 'Início'
  },
  {
    id: 'endDate',
    label: 'Fim'
  }
]

const initialValues = {
  teacher: [
    {
      order: 1,
      professor: ''
    }
  ]
}

const initialState = [
  {
    order: 1,
    professor: ''
  }
]

export {
  firstOrder,
  positionProfessors,
  headCells,
  idNameList,
  initialState,
  initialValues
}
