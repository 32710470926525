import PropTypes from 'prop-types'
import * as Styled from './style'

const ButtonCallToAction = ({
  classNameCallToAction,
  labelCallToAction,
  callToAction,
  linkCallToAction,
  startIcon,
  width
}) => (
  <>
    {linkCallToAction ? (
      <Styled.LinkCallToAction to={linkCallToAction}>
        <Styled.CallToAction
          $width={width}
          className={classNameCallToAction}
          value={labelCallToAction}
          startIcon={startIcon}
        />
      </Styled.LinkCallToAction>
    ) : (
      <Styled.CallToAction
        className={classNameCallToAction}
        value={labelCallToAction}
        onClick={callToAction}
        startIcon={startIcon}
      />
    )}
  </>
)
export default ButtonCallToAction

ButtonCallToAction.propTypes = {
  classNameCallToAction: PropTypes.string,
  labelCallToAction: PropTypes.string,
  callToAction: PropTypes.func,
  linkCallToAction: PropTypes.string,
  startIcon: PropTypes.element
}
