import FEEDBACK_SNACK from 'feedBackSnack'
import { updateErrorMessage } from 'services/helpers'
import {
  getStudentDocumentsTypes,
  uploadStudentDocuments
} from 'services/api/admin'
import { MESSAGE, SUCCESS } from './constants'

const importIntegrateEvaluations = async ({
  register,
  values,
  setSnack,
  isLoad,
  handleClose,
  selectList
}) => {
  setSnack('')
  isLoad(true)

  const documentValue = values.document

  const selectedItem = selectList.find((item) => item.value === documentValue)

  const payload = {
    document_name: selectedItem.label,
    document_type_id: selectedItem.value,
    file: `${values.file}`
  }

  const { error, status } = await uploadStudentDocuments(register, payload)

  isLoad(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateProfessors
    })
  }

  handleClose()
  window.location.reload()
  return setSnack(MESSAGE.SUCCESS, SUCCESS)
}

const handleSelectDocuments = async ({
  register,
  isLoad,
  setSnack,
  setSelectList
}) => {
  setSnack('', '')
  isLoad(true)

  const { data, error, status } = await getStudentDocumentsTypes(register)

  isLoad(false)
  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
    })
    return
  }

  const result = data?.map((doc) => ({
    label: doc.name,
    value: doc.id
  }))

  setSelectList(result)
}

export { importIntegrateEvaluations, handleSelectDocuments }
