import humps from 'humps'
import { updateRegistrationData as updateRegistrationDataService } from 'services/api/admin'
import { messageErroOrSuccess } from 'services/helpers'

import { formatPayloadFormation } from './helpers'
import { serviceMessage } from './constants'

const createFormattion = async ({
  payload,
  registerId,
  callbackSuccess = () => {}
}) => {
  const newPayload = formatPayloadFormation(payload)

  const { feedbackMessage, successMessage } = serviceMessage
  const { error, status } = await updateRegistrationDataService(
    humps.decamelizeKeys(newPayload),
    registerId
  )

  messageErroOrSuccess({
    error,
    status,
    feedbackMessage,
    successMessage
  })

  if (!error) {
    callbackSuccess()
  }
}

const updateFormattion = async ({
  payload,
  registerId,
  callbackSuccess = () => {}
}) => {
  const newPayload = formatPayloadFormation(payload)

  const { feedbackMessage, successMessage } = serviceMessage
  const { error, status } = await updateRegistrationDataService(
    humps.decamelizeKeys(newPayload),
    registerId
  )

  messageErroOrSuccess({
    error,
    status,
    feedbackMessage,
    successMessage
  })

  if (!error) {
    callbackSuccess()
  }
}

export { createFormattion, updateFormattion }
