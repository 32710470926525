import buildObservations from 'components/templates/PDF/shared/Observation'
import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { getObservationText } from '../../constants'

const { borderLayoutDefault } = utilsPDF

const buildTableFooter = ({ observationClass }) => ({
  table: {
    body: [
      ...buildObservations({
        text: getObservationText({ observationClass })
      })
    ],
    widths: [240, 60, '*']
  },
  layout: {
    ...borderLayoutDefault,
    paddingBottom: () => 10,
    paddingLeft: () => 10,
    paddingRight: () => 10,
    paddingTop: () => 10
  }
})

export default buildTableFooter
