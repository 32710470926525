import { utilsPDF } from '../../shared/utils'
import buildClassInfo from '../ClassInfo'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export default function buildStub({ classInfo, student }) {
  return [
    {
      id: 'stub',
      stack: [
        {
          table: {
            body: [
              [
                {
                  text: 'Este canhoto deve ser devolvido assinado à coordenação/secretaria da unidade.',
                  alignment: 'center',
                  bold: true,
                  border: [false, false, false, true]
                }
              ]
            ],
            widths: '*'
          },
          layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
        },
        buildClassInfo({ classInfo, student }),
        {
          table: {
            body: [
              [
                { text: 'Data', bold: true, border: [true, true, true, false] },
                {
                  text: 'Assinatura do Responsável:',
                  bold: true,
                  border: [true, true, true, false]
                }
              ],
              [
                {
                  text: '__________/__________/__________',
                  border: [true, false, true, true]
                },
                { text: '', border: [true, false, true, true] }
              ]
            ],
            widths: [160, '*']
          },
          layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
        }
      ]
    }
  ]
}
