const sortAsc = 'asc'
const sortDesc = 'desc'
const defaultPage = 1
const defaultItemsPerPage = 10
const defaultOrder = 'reason_absence'

const headCells = [
  {
    numeric: false,
    id: 'reason_absence',
    disablePadding: false,
    label: 'Motivo da falta'
  },
  {
    label: 'De',
    numeric: false,
    id: 'start_date',
    disablePadding: true
  },

  {
    label: 'Até',
    id: 'end_date',
    numeric: false,
    disablePadding: false
  },
  {
    id: 'send_by',
    numeric: false,
    label: 'Enviado por',
    disablePadding: false
  },
  {
    numeric: false,
    id: 'send_date',
    disablePadding: false,
    label: 'Data de envio'
  }
]

export {
  sortAsc,
  sortDesc,
  headCells,
  defaultPage,
  defaultOrder,
  defaultItemsPerPage
}
