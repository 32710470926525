const ACTIVITY_NOVOTEC_IDS = {
  B1_AVC_DOCENTE: 2013,
  B1_AVA_INTEGRADA: 2017,
  B2_AVC_DOCENTE: 2014,
  B2_AVA_INTEGRADA: 2018,
  B3_AVC_DOCENTE: 2015,
  B3_AVA_INTEGRADA: 2019,
  B4_AVC_DOCENTE: 2016,
  B4_AVA_INTEGRADA: 2020,
  REC_FINAL: 2025,
  RECLASSIFICATION: 2003,
  EX_FINAL: 2012
}
const NUMBER_OF_STANDARD_ACTIVITIES_IN_NOVOTEC = 8
const QUANTITY_ACTIVITIES_IN_BIMESTER = 2

export {
  ACTIVITY_NOVOTEC_IDS,
  QUANTITY_ACTIVITIES_IN_BIMESTER,
  NUMBER_OF_STANDARD_ACTIVITIES_IN_NOVOTEC
}
