import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Edit } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { getRgba } from 'services/helpers/polished'

const BoxHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 47px 16px;
  color: ${({ theme }) => theme.palette.primary.white};
  position: relative;

  &:hover {
    & .MuiSvgIcon-root {
      display: block;
    }
  }
`

const LinkHeader = styled(Link)`
  text-decoration: none;
`

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 16px;
`
const CardName = styled.div`
  overflow: hidden;
  margin-right: 8px;
`

const IconEdit = styled(Edit)`
  &.MuiSvgIcon-root {
    position: absolute;
    top: 13px;
    right: 21px;
    width: 15px;
    height: 15px;
    display: none;
  }
`

const LinkButtonPole = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: end;
  justify-content: end;
`

const TypographyName = styled(Typography)`
  color: ${({ theme }) => getRgba(theme.palette.dark.main, 0.87)};
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TypographyBody2 = styled(Typography)`
  color: ${({ theme }) => getRgba(theme.palette.dark.main, 0.6)};
`

const Title = styled(Typography)`
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TitleName = styled(Typography)`
  &.MuiTypography-root {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${({ $sizeTitleName }) => $sizeTitleName || '1rem'};
  }
`

export {
  BoxHeader,
  CardContent,
  IconEdit,
  LinkButtonPole,
  LinkHeader,
  TypographyName,
  TypographyBody2,
  Title,
  CardName,
  TitleName
}
