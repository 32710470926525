/* eslint-disable react/jsx-pascal-case */
import { Box, Checkbox, TableRow } from '@mui/material'
import * as Styled from './style'

const TableTdBody = ({ row, isItemSelected, labelId, handleClick }) => {
  const isShowCheckbox = !row.has_grades && !row?.occurrences

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.enrollment}
        selected={isItemSelected}
      >
        <Styled.TableCellCheckbox
          padding="checkbox"
          onClick={() => isShowCheckbox && handleClick(row?.enrollment)}
        >
          {isShowCheckbox && (
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId
              }}
            />
          )}
        </Styled.TableCellCheckbox>

        <Styled.TableCellTitle>
          <Box>
            <Box>{row.enrollment}</Box>
          </Box>
        </Styled.TableCellTitle>

        <Styled.TableCellName>{row.name}</Styled.TableCellName>
        <Styled.TableCell>
          {!row?.occurrences ? (
            'Ativo'
          ) : (
            <Styled.statusInactive>Inativo</Styled.statusInactive>
          )}
        </Styled.TableCell>
        <Styled.TableCellOccurrence>
          {row?.occurrences || '---'}
        </Styled.TableCellOccurrence>
      </TableRow>
    </>
  )
}

export { TableTdBody }
