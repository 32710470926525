import { useEffect, useState } from 'react'
import { ModalContent, TextField } from 'components/molecules'
import { typeMask } from 'services/helpers'
import { initialValues } from './constants'

import * as Styled from './style'

const ModalResendCode = ({
  open,
  title,
  handleClose,
  onClickConfirm,
  telephoneContract,
  emailContract,
  ...restProps
}) => {
  const [values, setValues] = useState(initialValues)

  const setFieldValue = ({ event, fieldname }) =>
    setValues((prev) => ({ ...prev, [fieldname]: event.target.value }))

  useEffect(() => {
    setValues({
      email: emailContract || '',
      telephone: telephoneContract || ''
    })
  }, [emailContract, telephoneContract])

  return (
    <ModalContent
      open={open}
      title={title}
      onClose={handleClose}
      onClickConfirm={() => onClickConfirm(values)}
      onClickNotConfirm={handleClose}
      content={
        <Styled.WrapperFields>
          <TextField
            fullWidth
            label="E-mail"
            value={values.email}
            onChange={(event) => setFieldValue({ event, fieldname: 'email' })}
          />

          <TextField
            fullWidth
            label="Celular"
            value={typeMask('TELEPHONE', values.telephone)}
            onChange={(event) =>
              setFieldValue({ event, fieldname: 'telephone' })
            }
          />
        </Styled.WrapperFields>
      }
      {...restProps}
    />
  )
}

export default ModalResendCode
