import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { Clear } from '@mui/icons-material'
import { useActionSelected, usePaginationBasic, useSnack } from 'services/hooks'
import { ERROR, ROUTE, SUCCESS } from 'services/constants'
import {
  IsEmpty,
  getErrorMessage,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import {
  getContractsTypes,
  editStatusContractsTypes
} from 'services/api/admin/system/ContractsTypes'
import { cleanObject } from 'services/helpers/cleanObject'
import FEEDBACK_SNACK from 'feedBackSnack'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import { AccordionFilter } from 'components/atoms'
import {
  TableContractsTypes,
  FormListContractsTypes
} from './components/ListContractsTypes'
import {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  MESSAGE,
  nameAccordion,
  defaultTotalPage,
  initialState
} from './constants'
import { useContractsTypes } from './context/FormContext'
import * as Styled from './style'
import {
  getClassModalConfirm,
  getMessageModalConfirm,
  getTitleModalConfirm,
  isDisabledButton
} from './helpers'
import schema from './schema'

const ContractsTypes = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const [count, setCount] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const [listSelected, setListSelected] = useState([])
  const { onActionSelected } = useActionSelected()
  const [notSearch, setNotSearch] = useState(true)
  const [expanded, setExpanded] = useState(nameAccordion)
  const { formValues, setFormValues } = useContractsTypes()
  const [filter, setFilter] = useState(DEFAULT_ITEMS_PER_PAGE)
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [listContractsTypes, setListContractsTypes] = useState([])
  const {
    page: pageActual,
    totalPage,
    setPage
  } = usePaginationBasic(listContractsTypes, filter, count)

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  const initialValues = mergeInitialValues(initialState, formValues)

  const handleSearch = async (values) => {
    setLoadingVisibility(true)
    const params = cleanObject({
      name: values.name,
      active: !values.inactive,
      limit: filter || defaultTotalPage,
      page: pageActual || DEFAULT_PAGE
    })
    const { data, error, status } = await getContractsTypes(params)
    setLoadingVisibility(false)
    setCount(data?.count)
    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListContractStatus
      })
    }

    if (error) {
      const message = getErrorMessage(error, status)
      return setSnack(message, ERROR)
    }

    setNotSearch(false)

    return setListContractsTypes(data?.data)
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      handleSearch(values)
      setIsActive(!values.inactive)
      setFormValues(values)
    },
    validationSchema: schema
  })

  const handleUpdateContract = async () => {
    setSnack('')
    setLoadingVisibility(true)
    const params = cleanObject({
      status: !isActive,
      ids: [...listSelected]
    })

    const { error, status } = await editStatusContractsTypes(params)
    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditContractStatus
      })
    }
    setListContractsTypes([])
    setSnack(
      `Contrato ${isActive ? 'inativado' : 'ativado'} com sucesso.`,
      SUCCESS
    )
    setIsOpen(false)

    return handleSearch(formik.values)
  }

  const handleClear = () => {
    setNotSearch(true)
    setListContractsTypes([])
    setFormValues(initialState)
    formik.resetForm(initialValues)
  }

  useEffect(() => {
    handleSearch(formik.values)
  }, [pageActual, filter])
  return (
    <>
      <TemplateDefaultFormAndResult
        snackProps={snackProps}
        labelButtonHeader="Novo tipo"
        listItems={listContractsTypes}
        loadingVisibility={loadingVisibility}
        linkHeader={ROUTE.contracts.CREATE_CONTRACTS}
        iconInformative={messageInformative.icon}
        titleHeader="<b>Sistema - </b> Tipos de Contrato"
        messageInformative={messageInformative.text}
        classNameHeader={TAG_MANAGER.sistema_btn_cria_novoContrato}
        formHeader={
          <AccordionFilter
            title="Selecione e preencha os campos para realizar uma busca"
            expanded={expanded}
            setExpanded={setExpanded}
            nameAccordion={nameAccordion}
            margin
          >
            <Styled.Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
                <FormListContractsTypes formik={formik} setSnack={setSnack} />
                <Styled.Grid item xs={12} sm={12} md={3}>
                  <ButtonFooterV2
                    size="medium"
                    labelClose="Limpar"
                    labelConfirm="Buscar"
                    startIcon={<Clear />}
                    onClickClose={handleClear}
                    disabledClose={isDisabledButton(formik.values)}
                    className={TAG_MANAGER.sistema_btn_busca_contratos}
                  />
                </Styled.Grid>
              </Styled.GridContainer>
            </Styled.Form>
          </AccordionFilter>
        }
      />

      {listContractsTypes && !IsEmpty(listContractsTypes) && (
        <TableContractsTypes
          count={count}
          filter={filter}
          formik={formik}
          setPage={setPage}
          isActive={isActive}
          setFilter={setFilter}
          totalPage={totalPage}
          setIsOpen={setIsOpen}
          pageActual={pageActual}
          listSelected={listSelected}
          items={listContractsTypes}
          setListSelected={setListSelected}
          onManualEnrollment={(type, item) => onActionSelected(type, item)}
        />
      )}

      <ModalConfirm
        open={isOpen}
        size="medium"
        handleClose={() => setIsOpen(!isOpen)}
        textButtonConfirm="Sim"
        textButtonNotConfirm="Não"
        onClickNotConfirm={() => setIsOpen(!isOpen)}
        onClickConfirm={handleUpdateContract}
        colorButtonConfirm="error"
        icon={<Styled.IconWarningAmber />}
        title={getTitleModalConfirm(isActive)}
        message={getMessageModalConfirm(isActive, listSelected?.length)}
        classNameBtnConfirm={getClassModalConfirm(
          isActive,
          listSelected?.length
        )}
      />
    </>
  )
}

export default ContractsTypes
