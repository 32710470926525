import { lazy } from 'react'
import TAG_MANAGER from 'tagManager'
import { TemplateListUnits as Template } from 'components/templates/Admin'
import { HeaderPage } from 'components/organisms'
import { Snackbar } from 'components/molecules'
import { useSnack } from 'services/hooks'
import { convertToParams } from 'services/helpers'
import { ROUTE } from 'services/constants'
import { SITUATION } from './constants'

const Content = lazy(() => import('./components/Content'))

const ListUnits = () => {
  const { snack, setSnack, snackOpen } = useSnack()

  return (
    <Template
      titleHeader={
        <HeaderPage
          title="Gestão - Unidades"
          divider
          labelCallToAction="nova unidade"
          classNameCallToAction={TAG_MANAGER.academic_btn_create_new_unit}
          linkCallToAction={`${
            ROUTE.ADMIN_MANAGEMENT_UNIT_CREATE
          }?${convertToParams({
            edit: SITUATION.new,
            pole: false,
            unit: true
          })}`}
        />
      }
      content={<Content setSnack={setSnack} />}
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default ListUnits
