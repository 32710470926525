import styled from 'styled-components'
import {
  Grid as GridPageTitle,
  Typography as MuiTypography
} from '@mui/material'
import theme from 'theme/designTokens'

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    display: block;
    padding: ${({ $padding }) => $padding || '0 0 24px'};
  }
`

const Grid = styled(GridPageTitle)`
  &.MuiGrid-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0px !important;
    margin: 0;

    & .MuiButton-root {
      background-color: transparent;
      color: ${() => theme.palette.primary.main};
      box-shadow: none;

      &:hover {
        background-color: ${() => theme.palette.primary.main};
        color: ${() => theme.palette.primary.white};
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
`

export { Typography, Grid }
