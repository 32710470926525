import { getListModules } from 'services/api/admin'
import { getUnitsRegions } from 'services/api/shared'
import { getDataFormKeys, updateErrorMessage } from 'services/helpers'

const payload = (values) => ({
  unit: values?.unity || '',
  pole: values?.pole || '',
  group: values?.group || '',
  level: values?.level || '',
  course: values?.course || '',
  module_id: values?.module_id,
  modality_id: values?.modality_id,
  shift: values?.shift || '',
  workload: values?.workload || '',
  observation: values?.observation || ''
})

const mergeInitialValues = (values) => ({
  unity: values?.unit || '',
  pole: values?.pole || '',
  group: values?.group || '',
  level: values?.level || '',
  course: values?.course || '',
  module_id: values?.module,
  modality_id: values?.modality_id,
  shift: values?.shift || '',
  workload: values?.workload || '',
  observation: values?.observation || ''
})

const typeEndpointPole = (unitId) => `type=pole&unit_id=${unitId}`

const parseResponse = (data, isName) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { value: isName ? name : id, label: name }
    return [...acc, newItem]
  }, [])

const getListPole = async ({ setListPole, unity, setSnack }) => {
  const isName = true

  const {
    data: response,
    error,
    status
  } = await getUnitsRegions(typeEndpointPole(unity))

  const dataPole = getDataFormKeys([0, 'units'], response?.data)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status
    })
  }

  return setListPole(parseResponse(dataPole, isName))
}

const selectModules = async ({ setListModules, setSnack }) => {
  setSnack('')

  const { data, error, status } = await getListModules()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status
    })
  }
  const modules = data?.data

  const optionsList = modules?.map((item) => ({
    label: item.name,
    value: item.id
  }))

  return setListModules(optionsList)
}

export { payload, mergeInitialValues, getListPole, selectModules }
