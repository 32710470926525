const level = [
  {
    value: 'technicalCourse',
    label: 'Técnico em Enfermagem'
  }
]

const course = [
  {
    value: 'nursingTechnician',
    label: 'Curso Técnico'
  }
]

const MESSAGE = {
  NO_SEARCH:
    '<b>Encontre as Matrizes Curriculares aqui</b><br/>Você pode usar os critérios de filtro para realizar uma busca mais precisa.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  SUCCESS_COPY: 'Matriz copiada com sucesso'
}

const initialState = {
  level: '',
  course: '',
  IsInactive: false
}

const typeAction = {
  remove: 'remove',
  copy: 'copy',
  changeOfValues: 'changeOfValues',
  planAlreadyExists: 'planAlreadyExists',
  change: 'change'
}

const countDefault = 0
const defaultPage = 1
const defaultItemsPerPage = 10
const nameAccordion = 'curriculum'
const newCurriculum = 'newCurriculum'

export {
  MESSAGE,
  initialState,
  level,
  course,
  countDefault,
  defaultPage,
  defaultItemsPerPage,
  nameAccordion,
  newCurriculum,
  typeAction
}
