import styled from 'styled-components'
import { Box, Grid as MuiGrid, Typography } from '@mui/material'
import theme from 'theme/designTokens'

const Grid = styled(MuiGrid)`
  padding: 21px;
`

const SubTitle = styled(Typography)`
  &.MuiTypography-root {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
`

const LinkTemplate = styled(Box)`
  &.MuiBox-root {
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.palette.purple.medium};
  }
`

export { Grid, SubTitle, LinkTemplate }
