import { Checkbox, TableRow } from '@mui/material'
import * as Styled from './style'

const TableFooter = ({ row, handleClick, labelId, isItemSelected }) => {
  const ShowAbsent = ({ absentData }) => (
    <Styled.StyledTableCell>
      {absentData ? (
        <Styled.Typography variant="subtitle2">F</Styled.Typography>
      ) : (
        <Styled.Box $isActive />
      )}
    </Styled.StyledTableCell>
  )

  return (
    <TableRow hover key={row?.id} tabIndex={-1} role="checkbox">
      <Styled.StyledTableCell
        padding="checkbox"
        onClick={() => handleClick(row?.email)}
      >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': labelId
          }}
        />
      </Styled.StyledTableCell>
      <Styled.StyledTableCellTitle>
        <Styled.BoxLink>{row?.name || '-'}</Styled.BoxLink>
      </Styled.StyledTableCellTitle>

      <Styled.StyledTableCell>
        <Styled.ContainerUnitName>
          {row?.register || '-'}
        </Styled.ContainerUnitName>
      </Styled.StyledTableCell>

      <Styled.StyledTableCell $maxWidth>
        {row?.email || '-'}
      </Styled.StyledTableCell>

      <Styled.StyledTableCell>{row?.phone || '-'}</Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.cellphone || '-'}</Styled.StyledTableCell>
      <ShowAbsent absentData={row?.absents?.student_2_absents} />
      <ShowAbsent absentData={row?.absents?.student_5_absents} />
      <ShowAbsent absentData={row?.absents?.student_7_absents} />
      <ShowAbsent absentData={row?.absents?.student_15_absents} />
    </TableRow>
  )
}
export default TableFooter
