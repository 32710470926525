import styled from 'styled-components'
import { Typography, Paper, TableContainer } from '@mui/material'

const StyledCaption = styled.div`
  display: flex;
  align-items: center;
`
const StyledCaptionTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 10px;
    font-size: 14px;
  }
`

const StyledFooter = styled.div`
  display: grid;
  grid-template-columns: 90px 90px;
  grid-gap: 24px;
  margin: 24px 0;
`

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    width: 100%;
  }
`
const StyledText = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin-top: 50px;
  text-align: center;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
`

const StyledTableContainer = styled(TableContainer)`
  &.MuiTableContainer-root {
    margin-top: 24px;
  }
`
export {
  StyledText,
  StyledPaper,
  StyledFooter,
  StyledCaption,
  StyledCaptionTitle,
  StyledTableContainer
}
