import React, { useEffect, useState } from 'react'
import { TableBody } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { getComparator, stableSort } from 'services/helpers'
import { useCheckedTable } from 'services/hooks'
import { sortAsc } from './constants'
import TableFooter from './TableFooter'
import * as Styled from './style'

const ListSelectEnrollment = ({
  items,
  setSelectRegistrationMerge,
  setIsOpen,
  isOpen,
  removeUsersListUnifyById
}) => {
  const [order, setOrder] = useState(sortAsc)
  const [orderBy, setOrderBy] = useState()

  const {
    handleClick,
    isSelected,
    selected: selectedItem
  } = useCheckedTable(items, 'register')

  useEffect(() => {
    setSelectRegistrationMerge(selectedItem)
  }, [selectedItem])

  const handleMensages = ({ secondMessage, notFound }) => {
    if (items.length) {
      return secondMessage
    }
    return notFound
  }

  return (
    <>
      <Styled.Text>
        {handleMensages({
          secondMessage: 'Selecione o registro a ser mantido',
          notFound: 'Lista de Unificação',
          isUnifyStatus: false
        })}
      </Styled.Text>
      <Styled.Description>
        {handleMensages({
          secondMessage:
            'Após escolher os registros, selecione aquele que deseja manter para dar sequência ao processo de unificação.',
          notFound:
            'Você não possui registros para unificação. Para encontrá-los, clique no botão abaixo ou volte para tela inicial.',
          isUnifyStatus: false
        })}
      </Styled.Description>
      <TableBody>
        {stableSort(items, getComparator(order, orderBy)).map((row) => {
          const isItemSelected = isSelected(row.register)
          const labelId = `enhanced-table-${row.register}`

          return (
            <TableFooter
              key={row.register}
              row={row}
              labelId={labelId}
              handleClick={handleClick}
              isItemSelected={isItemSelected}
              removeUsersListUnifyById={removeUsersListUnifyById}
            />
          )
        })}
      </TableBody>
      {isOpen.listSelectUsers && (
        <Styled.ButtonBox>
          <Styled.ButtonSearch
            onClick={() =>
              setIsOpen({
                listSelectUsers: false,
                listSearch: false,
                DisplayAll: true
              })
            }
          >
            BUSCAR OUTRO REGISTRO
            <SearchOutlinedIcon />
          </Styled.ButtonSearch>
        </Styled.ButtonBox>
      )}
    </>
  )
}

export default ListSelectEnrollment
