import { cleanObject } from 'services/helpers/cleanObject'
import { toastNotificationSuccess, updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getCampaigns } from 'services/api/admin/financial/Campaigns'
import { MESSAGE, defaultItemsPerPage, defaultPage } from './constants'
import { defaultOption } from '../CreateByEditCampaign/constants'

export const handleSearch = async ({
  values,
  filter,
  setSnack,
  endDate,
  setCount,
  startDate,
  pageActual,
  setNotSearch,
  setListCampaigns,
  setLoadingVisibility
}) => {
  setLoadingVisibility(true)

  const requestBody = cleanObject({
    name: values.name,
    unit: values?.unity,
    inactive: values.inactive,
    page: pageActual || defaultPage,
    limit: filter || defaultItemsPerPage,
    end_date: values.endDate ? endDate : '',
    start_date: values.startDate ? startDate : ''
  })

  const getUnitValue = (params) =>
    params?.unity !== defaultOption && params?.unity !== ''
      ? params?.unity
      : 'null'

  if (values?.unity === defaultOption && values?.unity !== '') {
    requestBody.unit = getUnitValue(values)
  }

  const { data, error, status } = await getCampaigns(requestBody)

  setLoadingVisibility(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCampaigns
    })
  }

  setCount(data?.count)
  setNotSearch(false)

  return setListCampaigns(data?.data)
}

export const inactiveCampaign = async ({
  id,
  setSnack,
  setIsUpdate,
  setLoadingVisibility
}) => {
  setLoadingVisibility(true)

  // const { error, status } = await inactiveCampaign(id)

  // setLoadingVisibility(false)

  // if (error) {
  //   return updateErrorMessage({
  //     setSnack,
  //     error,
  //     status,
  //     feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToInactivateCampaigns
  //   })
  // }
  setIsUpdate(true)
  return toastNotificationSuccess(MESSAGE.success_inactive, 1500)
}
