import React, { lazy, useState, useEffect } from 'react'
import {
  IsEmpty,
  getClassesLocalStorage,
  getErrorMessage
} from 'services/helpers'
import { getGradebook, getStudentActivitiesDetail } from 'services/api/student'
import { usePaginationBasic } from 'services/hooks'
import { LoadingPage } from 'components/molecules'
import { useUserStore } from 'store/GlobalContext'
import { ERROR } from 'services/constants'
import { getStatusColor } from '../../statusColor'
import {
  statusInitially,
  itemsPerPage,
  typeToAttend,
  ERROR_MESSAGE
} from './constants'
import { getTypeCourse, typeMap } from './getTypeCourse'

const StudentGrades = lazy(() =>
  import('components/organisms/Table/StudentGrades')
)
const FormNotes = lazy(() => import('../FormNotes'))
const BulletinNotes = lazy(() => import('../BulletinNotes'))
const ModalSubjectActivities = lazy(() => import('../ModalSubjectActivities'))

const ContentMyScores = ({
  setSnack,
  updateSnack,
  studentRegister,
  studentCourseName,
  studentSchoolClassId
}) => {
  const { username } = useUserStore()
  const idMatricula = studentRegister ?? username
  const [open, setOpen] = useState(false)
  const [type, setType] = useState(statusInitially)
  const [scores, setScores] = useState([])
  const [scoresToShow, setScoresToShow] = useState([])
  const [loading, setLoading] = useState(true)
  const [subjecActivities, setSubjecActivities] = useState({})
  const [loadingModal, setloadingModal] = useState(false)
  const [disciplineActual, setDisciplineActual] = useState('')
  const [unit, setUnit] = useState(null)
  const nameCourse = studentCourseName ?? getClassesLocalStorage().name
  const schoolClassId =
    studentSchoolClassId ?? getClassesLocalStorage().school_classes_ids

  const typeCourse = getTypeCourse(type)

  const {
    page: pageActive,
    totalPage,
    items,
    setPage
  } = usePaginationBasic(scoresToShow, itemsPerPage)

  const handleScores = async () => {
    if (scores?.length) {
      return setScoresToShow(
        scores.filter(
          (stateEl) =>
            typeCourse === typeMap.Tudo || stateEl.type === typeCourse
        )
      )
    }

    setLoading(true)
    if (schoolClassId) {
      updateSnack({})
      const getParamsListSubjects = {
        idMatricula,
        typeCourse,
        schoolClassesIds: schoolClassId
      }
      const { data, error, status } = await getGradebook(getParamsListSubjects)
      setScores(data?.data?.gradebook)
      setScoresToShow(data?.data?.gradebook)
      setUnit(data?.data?.unit)
      if (error) {
        const message = getErrorMessage(ERROR_MESSAGE, status)
        setSnack(message, ERROR)
      }
    }
    return setLoading(false)
  }

  const handleClickScore = async (item) => {
    setDisciplineActual(item)
    setOpen(!open)
  }

  const onActivitiesDetail = async () => {
    if (disciplineActual?.id && disciplineActual?.type !== typeToAttend) {
      setloadingModal(true)
      setSubjecActivities({})
      const { data: response } = await getStudentActivitiesDetail({
        disciplineId: disciplineActual?.id,
        username: idMatricula
      })
      setSubjecActivities(response?.data)
      setloadingModal(false)
    }
  }

  const handleSetPage = (event, page) => {
    setPage(+page)
  }

  useEffect(() => {
    onActivitiesDetail()
  }, [disciplineActual])

  useEffect(() => {
    handleScores()
  }, [typeCourse])

  return (
    <>
      <LoadingPage open={loading} />

      <FormNotes setType={setType} setPage={setPage} nameCourse={nameCourse} />
      <StudentGrades
        scores={items}
        getStatusColor={getStatusColor}
        handleClickScore={handleClickScore}
        totalPages={totalPage}
        activePage={pageActive}
        handleSetPage={handleSetPage}
        type={type}
        unit={unit}
      />

      {scores && !IsEmpty(scores) && <BulletinNotes />}

      <ModalSubjectActivities
        open={open}
        setOpen={setOpen}
        subjecActivities={subjecActivities}
        loadingModal={loadingModal}
        disciplineType={disciplineActual?.type === typeToAttend}
      />
    </>
  )
}

export default ContentMyScores
