import TabStudentData from './TabStudentData'
import TabAffiliation from './TabAffiliation'
import TabSchoolData from './TabSchoolData'
import TabFinancialOfficer from './TabFinancialOfficer'

export default {
  TabStudentData,
  TabAffiliation,
  TabSchoolData,
  TabFinancialOfficer
}
