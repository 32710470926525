export const isDisabledByPassword = (values, errors) => {
  const { newPassword, confirmPassword } = values
  const isErrors = !!Object.keys(errors).length
  const isPasswordNotEqual = newPassword !== confirmPassword

  const disabled =
    !newPassword || !confirmPassword || isErrors || isPasswordNotEqual

  return disabled
}
