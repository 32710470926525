import styled from 'styled-components'

const ContainerList = styled.div``

const Title = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.4%;
`

export { ContainerList, Title }
