import { useState, Suspense, useEffect } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { getComparator, stableSort } from 'services/helpers/formatTable'
import { PaginationAdvanced } from 'components/molecules'
import { paginationFilter } from 'services/helpers'
import { TableHeaderDefault } from 'components/organisms'
import {
  defaultItemsPerPage,
  headCellsPending,
  sortAsc,
  sortDesc
} from './constants'
import TableFooter from './TableFooter'
import { StyledPaper, StyledTableContainer } from './style'
import TableToolbar from './TableToolbar'
import { useListWithRemoval } from './useListWithRemoval'

const TableReplaceClassDetails = ({
  items,
  isEdit,
  setPage,
  isSelected,
  handleClick,
  selectedItem,
  setListSelected,
  setListDisciplines,
  handleSelectAllClick
}) => {
  const [orderBy, setOrderBy] = useState()
  const [order, setOrder] = useState(sortAsc)
  const { list, removeItemsByIds } = useListWithRemoval(headCellsPending)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  useEffect(() => {
    if (selectedItem.length) {
      const transformList = items.map((item) => ({
        id: item?.id,
        linked_professor_id: item?.professorsSelected?.value,
        linked_professor_position: item?.professorsSelected?.position
      }))

      const objetosCorrespondentes = transformList.filter((item) =>
        selectedItem.includes(item?.id)
      )

      setListSelected(objetosCorrespondentes)
    } else {
      setListSelected([])
    }
  }, [selectedItem])

  useEffect(() => {
    if (!isEdit)
      removeItemsByIds(['start_date', 'end_date', 'substitution_reason'])
  }, [isEdit])

  return (
    <Suspense>
      {selectedItem?.length >= 1 && (
        <TableToolbar amount={selectedItem?.length} />
      )}
      <StyledPaper>
        <StyledTableContainer>
          <MuiTable>
            <TableHeaderDefault
              checkbox
              order={order}
              headCells={list}
              orderBy={orderBy}
              rowCount={items?.length}
              onRequestSort={handleRequestSort}
              numSelected={selectedItem?.length}
              handleSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-${index}`
                  return (
                    <TableFooter
                      row={row}
                      key={row.id}
                      isEdit={isEdit}
                      labelId={labelId}
                      selected={selectedItem}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      setListDisciplines={setListDisciplines}
                    />
                  )
                }
              )}
            </TableBody>
          </MuiTable>
        </StyledTableContainer>
      </StyledPaper>
    </Suspense>
  )
}

export default TableReplaceClassDetails
