import styled from 'styled-components'
import theme from 'theme/designTokens'

const ContainerQuill = styled.div`
  & .ql-container.ql-snow {
    height: ${(props) => props.$height};
    border-color: ${({ $error }) => ($error ? theme.palette.red.main : '#ccc')};
  }

  & .ql-toolbar.ql-snow {
    border-color: ${({ $error }) => ($error ? theme.palette.red.main : '#ccc')};
  }

  & .MuiTypography-root {
    width: 100%;
    display: block;
    margin: 5px 0 0 0;
    text-align: left;
    padding: 0 0 0 19px;
    color: ${() => theme.palette.red.main};
  }
`

export { ContainerQuill }
