import humps from 'humps'
import { httpGetV2, httpPatch, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getStudentsFrequenciesByProfessorAndClass = async ({
  id,
  classId,
  disciplineId
}) => {
  const params = {
    professor_id: id,
    class_id: classId,
    subject_ids: disciplineId
  }

  const response = await httpGetV2(
    ENDPOINT.PROFESSOR_DIARY_FREQUENCIES,
    {
      params
    },
    {},
    process.env.REACT_APP_API_V2
  )
  return response
}

export const createStudentsFrequenciesByProfessorAndClass = async (
  register,
  disciplineId,
  doneAt,
  lessonNumber,
  frequencyStatus
) => {
  const payload = {
    register,
    discipline_id: disciplineId,
    done_at: doneAt,
    lesson_number: lessonNumber,
    status: frequencyStatus
  }

  const response = await httpPost(
    `${ENDPOINT.PROFESSOR_DIARY_FREQUENCIES}`,
    payload
  )
  return response
}

export const updateStudentsFrequenciesByProfessorAndClass = async ({
  statusFrequency,
  frequencyId
}) => {
  const payload = {
    status: statusFrequency
  }

  const response = await httpPut(
    `${ENDPOINT.PROFESSOR_DIARY_FREQUENCIES}/${frequencyId}`,
    payload
  )
  return response
}

export const confirmedFrequencyByProfessorAndClass = async ({
  classId,
  professorId,
  payload
}) => {
  const params = {
    class_id: classId,
    professor_id: professorId
  }

  const response = await httpPatch(
    ENDPOINT.PROFESSOR_DIARY_LESSONS_CONFIRMED,
    { ...humps.decamelizeKeys(payload) },
    { params }
  )

  return response
}
