const HUMAN_RESOURCES = {
  PROFESSORS: {
    MAIN: 'main',
    TYPES: 'types',
    SEARCH: 'search',
    SKILLS: 'skills',
    DELETE: 'delete',
    CRUD: 'professors',
    EMAIL: 'email/send',
    DOCUMENTS: 'documents',
    SEARCH_ALL: 'search-all',
    SUBSTITUTE: 'substitute',
    EXPORT_XLSX: 'export/xlsx',
    INTERACTIONS: 'email/logs',
    DISCIPLINES: 'disciplines',
    VALIDATE_CPF: 'validate-cpf',
    SCHOOL_CLASS: 'school-class',
    SUBSTITUTION: 'substitution',
    SCHOOL_CLASSES: 'school-classes',
    ACADEMIC_PIC: 'academic-pic/upload',
    SCHOOL_CLASSES_LINK: 'link/school-class',
    SCHOOL_CLASSES_LINKED: 'linked/school-classes',
    LINKED_SCHOOL_CLASS: 'linked-school-class',
    TIMEKEEPING: 'school-class-management/professor',
    LINK: 'link'
  },
  PAYROLL: {
    LIST: 'payroll/filter',
    SIMPLE_URL_PAYROLL: 'payroll'
  },
  MANAGE_CLASSES: {
    BASE: 'school-class-management',
    LIST: 'school-class',
    COORDINATOR: 'coordinator',
    SCHOOL_CLASSES: 'school-class-management',
    DETAILS: 'details',
    CHECK: 'check',
    REVERT: 'revert',
    PROFESSORS: 'professors'
  },
  MANAGE_RECEIPT: {
    BASE: 'receipt-management'
  },
  CONSOLIDATION: {
    BASE: 'consolidation'
  }
}

export { HUMAN_RESOURCES }
