import { CIVIL_STATUS_TYPES, RACE_TYPES } from 'services/constants'

const MAX_LENGTH_PHONE = 15

const listRaces = RACE_TYPES.map((race) => ({ label: race, value: race }))

const listCivilStatus = CIVIL_STATUS_TYPES.map((race) => ({
  label: race || null,
  value: race || null
}))

const typeContactTime = {
  morning: 'morning',
  afternoon: 'afternoon',
  night: 'night'
}

export { MAX_LENGTH_PHONE, listRaces, listCivilStatus, typeContactTime }
