/* eslint-disable camelcase */
import { lazy, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { formatDate, getUserFromLocalStorage, isEmpty } from 'services/helpers'
import { ROUTE } from 'services/constants'
import { useCurrentUser, useSortTable } from 'services/hooks'
import { Button, TextNotFound } from 'components/atoms'
import { ButtonFooterV2 } from 'components/molecules'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { breadcrumbsItems, emptyValue } from './constants'
import {
  getListConsolidation,
  handleExportXLS,
  sendConsolidation
} from './services'
import * as Styled from './style'
import {
  groupByCPF,
  handleCompetence,
  parserPayrollIdConsolodation
} from './helpers'
import { sendPayrollsToPaymentAPI } from '../TimeTraking/List/services'

const Table = lazy(() => import('./components/Table'))

const Consolidation = () => {
  const navigate = useNavigate()
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [listConsolidation, setListConsolidation] = useState([])
  const [infoHeaders, setInfoHeaders] = useState({})
  const { setLoading } = useLoading()
  const { company_name } = getUserFromLocalStorage()
  const { userHasSomeLevels } = useCurrentUser()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const shouldShowSendSenior = userHasSomeLevels([33, 14, 4])
  const grouped = groupByCPF(listConsolidation)

  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    grouped,
    'professor_name'
  )

  useEffect(async () => {
    setLoadingOpen(true)
    await Promise.all([
      getListConsolidation({
        params,
        setListConsolidation
      })
    ])

    if (params.competence) {
      handleCompetence({
        competence: params.competence,
        setInfoHeaders
      })
    }
    setLoadingOpen(false)
  }, [])

  const sendConsolidationToAPI = async () => {
    setLoading(true)

    const { error } = await sendConsolidation({
      listConsolidation: parserPayrollIdConsolodation(listConsolidation)
    })

    if (error) return setLoading(false)

    sendPayrollsToPaymentAPI({
      payrollIds: parserPayrollIdConsolodation(listConsolidation)
    })

    await getListConsolidation({
      params,
      setListConsolidation
    })

    navigate(ROUTE.ADMIN_HR_TIMEKEEPING, {
      state: { savedSearch: true }
    })

    return setLoading(false)
  }

  return (
    <TemplateDefaultHeaderByContent
      breadcrumbsItems={breadcrumbsItems(params)}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() =>
        navigate(ROUTE.ADMIN_HR_TIMEKEEPING, {
          state: { savedSearch: true }
        })
      }
      content={
        <>
          {isEmpty(listConsolidation) ? (
            <>
              <TextNotFound
                icon={<Search />}
                text="Não foram encontrados dados de consolidação<br/> para essa unidade."
              />

              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Styled.GridButtonEmpty item xs={12} sm={12} md={12}>
                  <Button
                    size="medium"
                    value="Gerenciar turmas"
                    onClick={() => navigate(ROUTE.ADMIN_HR_MANAGE_CLASSES)}
                  />
                </Styled.GridButtonEmpty>
              </Grid>
            </>
          ) : (
            <>
              <Box>
                <Grid container spacing={{ xs: 3, sm: 3 }}>
                  <Styled.Grid item xs={12} sm={12} md={11}>
                    <Typography variant="body1">
                      <b>Empresa:</b> {company_name || emptyValue}
                    </Typography>

                    <Typography variant="body1">
                      <b>Apuração:</b>{' '}
                      {formatDate(infoHeaders.start_period) || emptyValue} a{' '}
                      {formatDate(infoHeaders.end_period) || emptyValue}
                    </Typography>
                    <Typography variant="body1">
                      <b>Pagamento:</b>{' '}
                      {formatDate(infoHeaders.date_payment) || emptyValue}
                    </Typography>
                  </Styled.Grid>

                  <Styled.GridIcon item xs={12} sm={12} md={1}>
                    <Tooltip
                      title="Baixar comprovante em Excel"
                      placement="top"
                      arrow
                    >
                      <Styled.Download
                        onClick={() =>
                          handleExportXLS({
                            listConsolidation,
                            setLoadingOpen
                          })
                        }
                      />
                    </Tooltip>
                  </Styled.GridIcon>
                </Grid>
              </Box>

              <Table
                order={order}
                rows={sortTable}
                orderBy={orderBy}
                handleRequestSort={handleRequestSort}
              />

              {shouldShowSendSenior && (
                <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonFooterV2
                      size="medium"
                      labelConfirm="Enviar para Senior"
                      onClickConfirm={sendConsolidationToAPI}
                    />
                  </Grid>
                </Styled.GridContainer>
              )}
            </>
          )}
        </>
      }
    />
  )
}

export default Consolidation
