import { httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getFinancialBreakDown = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.FINANCIAL_BREAKDOWND.LIST}?${convertToParams(params)}`,
    {}
  )
  return response
}

export const inactiveFinancialBreakDown = async (payload) => {
  const response = await httpPut(ENDPOINT.FINANCIAL_BREAKDOWND.INACTIVE, {
    ...payload
  })
  return response
}

export const linkToContractFinancialBreakDown = async (payload) => {
  const response = await httpPut(
    `${ENDPOINT.FINANCIAL_BREAKDOWND.LINK_CONTTRACT}`,
    {
      ...payload
    }
  )
  return response
}

export const activeFinancialBreakDown = async (payload) => {
  const response = await httpPut(ENDPOINT.FINANCIAL_BREAKDOWND.ACTIVE, {
    ...payload
  })
  return response
}

export const sendEmailFinancialBreakDown = async (payload) => {
  const response = await httpPost(ENDPOINT.FINANCIAL_BREAKDOWND.SEND_EMAIL, {
    ...payload
  })
  return response
}

export const saveUnification = async (payload) => {
  const response = await httpPost(
    ENDPOINT.FINANCIAL_BREAKDOWND.UNIFICATION_OF_BANK_SLIP,
    {
      ...payload
    }
  )
  return response
}

export const updatePaymentInformation = async (payload) => {
  const response = await httpPut(ENDPOINT.FINANCIAL_BREAKDOWND.UPDATE, {
    payment_info: payload
  })

  return response
}

export const saveNegotiation = async (payload) => {
  const response = await httpPost(ENDPOINT.FINANCIAL_BREAKDOWND.NEGOTIATION, {
    ...payload
  })
  return response
}

export const findUnifyDiscounts = async () => {
  const response = await httpGet(ENDPOINT.FINANCIAL_BREAKDOWND.UNIFY_DISCOUNTS)
  return response
}
