import { httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getSchoolClassesCourses = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.BANK_BILLING.SCHOOL_CLASSES}/${id}/courses`
  )
  return response
}

export const getDocumentsRequeriments = async (params) => {
  const response = await httpGet(ENDPOINT.BANK_BILLING.DOCUMENTS_REQUERIMENTS, {
    params
  })

  return response
}

export const saveBankBilling = async (params) => {
  const response = await httpPost(ENDPOINT.BANK_BILLING.LIST, { ...params })

  return response
}

export const getBankBillingDetails = async (id) => {
  const response = await httpGet(`${ENDPOINT.BANK_BILLING.DETAILS}/${id}`)

  return response
}

export const getCostCenterNature = async () => {
  const response = await httpGet(`${ENDPOINT.BANK_BILLING.COST_CENTER}/nature`)

  return response
}

export const getCostCenterUnit = async () => {
  const response = await httpGet(`${ENDPOINT.BANK_BILLING.COST_CENTER}/unit`)

  return response
}

export const getCostCenterName = async () => {
  const response = await httpGet(`${ENDPOINT.BANK_BILLING.COST_CENTER}/name`)

  return response
}

export const getCostCenterType = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.BANK_BILLING.COST_CENTER}/type?id_cc3=${id}`
  )

  return response
}

export const getCostCenterSubCategory = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.BANK_BILLING.COST_CENTER}/subcategory?cc4=${id}`
  )

  return response
}

export const updateBankBilling = async (params, id) => {
  const response = await httpPut(`${ENDPOINT.BANK_BILLING.CRUD}/${id}`, params)

  return response
}

export const getInformationPaymentMethods = async () => {
  const response = await httpGet(ENDPOINT.BANK_BILLING.PAYMENT_METHODS)

  return response
}

export const saveBankBillingReplacement = async (params) => {
  const response = await httpPost(ENDPOINT.BANK_BILLING.REPLACEMENT, {
    ...params
  })

  return response
}

export const newBankBilling = async (payload) => {
  const response = await httpPost(ENDPOINT.BANK_BILLING.CRUD, payload)
  return response
}
