import styled from 'styled-components'
import {
  Box as MuiBox,
  Table as MuiTable,
  Paper as MuiPaper
} from '@mui/material'

const Box = styled(MuiBox)`
  width: 100%;
`

const BoxFooter = styled(MuiBox)`
  width: 100%;
  text-align: center;
`

const Paper = styled(MuiPaper)`
  width: 100%;
  margin-bottom: 24px;
`

const Table = styled(MuiTable)`
  min-width: 750px;
`

export { Box, Paper, BoxFooter, Table }
