import styled from 'styled-components'
import { Box, Grid as MuiGrid, Typography } from '@mui/material'
import { Close as MuiClose } from '@mui/icons-material'
import { Modal as MuiModal } from 'components/atoms'
import theme from 'theme/designTokens'

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 790px;
  }
`

const Grid = styled(MuiGrid)`
  padding: 21px;
`

const GridClose = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    justify-content: flex-end;
  }
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    display: block;
    text-align: left;
  }
`

const SubTitle = styled(Typography)`
  &.MuiTypography-root {
    display: flex;
    align-items: center;

    & svg {
      color: ${theme.palette.warning.main};
      margin-right: 10px;
    }
  }
`

const LinkTemplate = styled(Box)`
  &.MuiBox-root {
    margin: 7px 0 0 0;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.palette.purple.medium};
  }
`

const Close = styled(MuiClose)`
  color: rgba(0, 0, 0, 0.54);
  :hover {
    cursor: pointer;
  }
`

export { Grid, Title, Close, Modal, SubTitle, LinkTemplate, GridClose }
