import styled from 'styled-components'
import { Typography as MuiTypography } from '@mui/material'

const Link = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    margin-right: 10px;
    font-size: 16px;
  }
`

export { Link, Typography }
