import { Delete, Edit } from '@mui/icons-material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { deleteDetailsClassLinked } from 'services/api/admin'
import { toastNotificationSuccess, updateErrorMessage } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import { cleanObject } from 'services/helpers/cleanObject'
import { MESSAGE } from './constants'

const getMenuItems = ({ handleEditLinkedClass, handleClose }) => [
  {
    label: 'Editar',
    icon: <Edit />,
    className: TAG_MANAGER.rh_btn_edita_vinculo,
    onClick() {
      handleClose()
      handleEditLinkedClass()
    }
  }
]

const deleteClassLinked = async ({
  id,
  idClass,
  setIsOpen,
  setIsUpdate,
  setLoadingOpen
}) => {
  setLoadingOpen(true)
  const payload = cleanObject({
    professor_id: parseFloat(id)
  })

  const { error, status } = await deleteDetailsClassLinked(idClass, payload)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDeleteClassLinked
    })
  }
  setIsOpen(false)
  toastNotificationSuccess(MESSAGE.success, 1500)

  return setIsUpdate((prev) => !prev)
}

export { getMenuItems, deleteClassLinked }
