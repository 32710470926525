import Timekeeping from 'components/pages/Professor/Timekeeping'
import PersonalData from './components/PersonalData'
import UpdatePassword from './components/UpdatePassword'

const teacherTab = [
  {
    index: 'profile',
    content: <PersonalData />,
    navigation: 'MEUS DADOS',
    title: 'Perfil'
  },
  {
    index: 'password',
    content: <UpdatePassword />,
    navigation: 'Senha',
    title: 'Senha'
  },
  {
    index: 'timekeeping',
    content: <Timekeeping />,
    navigation: 'Apontamento de horas',
    title: 'Apontamento de horas'
  }
]

const studentTab = [
  {
    index: 'profile',
    content: <PersonalData />,
    navigation: 'MEUS DADOS',
    title: 'Perfil'
  }
]

const tabs = {
  professor: teacherTab,
  student: studentTab
}

export function getIsValidTabs(sent) {
  return tabs[sent] || tabs.student
}
