import * as XLSX from 'xlsx'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getConsolidation, sendManageConsolidation } from 'services/api/admin'
import {
  stringToArrayBuffer,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import { parserObjforXLS, parserPayrollIdConsolodation } from './helpers'

const getListConsolidation = async ({ params, setListConsolidation }) => {
  const { data, error, status } = await getConsolidation({
    competence: params.competence
  })

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListConsolidation
    })
  }

  return setListConsolidation(data)
}

const sendConsolidation = async ({ listConsolidation }) => {
  const payload = {
    payroll_ids: listConsolidation
  }

  const { error, status } = await sendManageConsolidation(payload)

  if (error) {
    updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToSendConsolidation
    })
  } else {
    toastNotificationSuccess('Consolidação enviada com sucesso!', 1500)
  }

  return { error, status }
}

const handleExportXLS = ({ listConsolidation, setLoadingOpen }) => {
  setLoadingOpen(true)

  const data = [
    {
      coluna1: 'Unidade',
      coluna2: 'Enviado por',
      coluna3: 'Nome',
      coluna4: 'CPF',
      coluna5: 'Total de Horas',
      coluna6: 'Valor Total'
    },
    ...parserObjforXLS(listConsolidation)
  ]

  const ws = XLSX.utils.json_to_sheet(data, { skipHeader: true })
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Planilha1')

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
  const blob = new Blob([stringToArrayBuffer(wbout)], {
    type: 'application/octet-stream'
  })

  setLoadingOpen(false)

  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = 'tabela.xlsx'
  document.body.appendChild(link)
  link.click()

  URL.revokeObjectURL(url)
  link.remove()
}

export { getListConsolidation, handleExportXLS, sendConsolidation }
