import PropTypes from 'prop-types'
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material'

const InputSelect = ({
  className,
  idSelect,
  labelId,
  value,
  handleChange,
  listOptions,
  title,
  name,
  disabledSelect,
  disabledInput,
  sx,
  width,
  defaultValue
}) => (
  <Box sx={{ marginBottom: '16px', width: { width } }}>
    <FormControl fullWidth disabled={disabledSelect} sx={sx}>
      <InputLabel id={labelId} disabled={disabledInput}>
        {title}
      </InputLabel>
      <Select
        defaultValue={defaultValue}
        className={className}
        labelId={labelId}
        id={idSelect}
        value={value ?? ''}
        name={name}
        onChange={handleChange}
        label={title}
      >
        {listOptions?.map((item) => (
          <MenuItem key={item.value} value={item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
)

export default InputSelect

InputSelect.propTypes = {
  handleChange: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  disabledSelect: PropTypes.bool,
  disabledInput: PropTypes.bool,
  listOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

InputSelect.defaultProps = {
  handleChange: () => {},
  title: '',
  name: '',
  disabledSelect: false,
  disabledInput: false
}
