const formatPayloadFormation = ({
  nivel,
  finishedYear,
  institution,
  city,
  state,
  type,
  isFinished
}) => ({
  [`${nivel}_finished_year`]: isFinished ? finishedYear : '',
  [`${nivel}_institution`]: institution,
  [`${nivel}_city`]: city,
  [`${nivel}_state`]: state,
  [`${nivel}_type`]: type
})

export { formatPayloadFormation }
