import styled from 'styled-components'
import { Button } from 'components/atoms'

const ButtonSearch = styled(Button)`
  &.MuiButton-root {
    margin: 0 0 0 16px;
  }
`

const ButtonClose = styled(Button)`
  &.MuiButton-root {
    margin: 0;
  }
`

const Box = styled.div`
  text-align: end;
  margin-left: ${({ $hasMarginLeft }) => ($hasMarginLeft ? '16px' : '0')};
`

export { ButtonSearch, ButtonClose, Box }
