import { ROUTE } from 'services/constants'

const MESSAGE = {
  SUCCESS: 'Novo grupo criado com sucesso! ',
  SUCCESS_EDIT: 'Grupo alterado com sucesso !'
}

const initialState = {
  active: false,
  name: '',
  unity: '',
  enrollmentStartDate: null,
  enrollmentEndDate: null,
  dueDate: null,
  startPrevisionDate: '',
  pageEdit: null
}

const activity = 1
const typePage = 'edit'

const breadcrumbsItems = (nameGroup) => [
  {
    path: ROUTE.ADMIN_FINANCIAL_BILLS_RECEIVE,
    label: 'Financeiro'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_GROUPS,
    label: 'Grupos'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_GROUPS_CREATE,
    label: `<b>${nameGroup || 'Novo Grupo'}</b>`
  }
]

export { MESSAGE, initialState, activity, typePage, breadcrumbsItems }
