import styled from 'styled-components'
import { Grid as MuiGrid, Typography as MuiTypography } from '@mui/material'
import { Button as AtomButton } from 'components/atoms'
import { getRgba } from 'services/helpers/polished'

const Grid = styled(MuiGrid)`
  &.MuiGrid-container {
    min-width: 300px;
    min-height: 160px;
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px ${({ theme }) => getRgba(theme.palette.dark.main, 0.2)},
      0px 8px 10px 1px ${({ theme }) => getRgba(theme.palette.dark.main, 0.14)},
      0px 3px 14px 2px ${({ theme }) => getRgba(theme.palette.dark.main, 0.12)};
    background: ${({ theme }) => theme.palette.background.white};
    padding: 24px;
  }
`

const Title = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: bold;
    margin-left: 5px;
  }
`

const IconText = styled.div`
  display: flex;
`

const GridButtons = styled(MuiGrid)`
  &.MuiGrid-item {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin: 6px 0 0;
  }
`

const Button = styled(AtomButton)`
  ${({ $hasMaxWidth }) => $hasMaxWidth && 'max-width: 130px'};
  &.MuiButton-root {
    margin: 0;
  }
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-subtitle2 {
    width: 97%;
    word-break: break-all;
  }
`

export { Grid, GridButtons, Title, IconText, Button, Typography }
