import { LinearProgress } from '@mui/material'

import { formatDate } from 'services/helpers'

import { emptyValue } from '../../constants'
import { getColorProgressBar } from './constants'

import * as Styled from './style'

const Sumary = ({
  schoolClassId,
  schoolClassCode,
  schoolClassCourse,
  schoolClassLevel,
  schoolClassGroup,
  schoolClassStartDate,
  schoolClassEndDate,
  schoolClassUnit,
  schoolClassShift,
  schoolClassModule,
  schoolClassType,
  progressPercent,
  isCanceled
}) => (
  <Styled.AccordionSummary>
    <Styled.Box>
      <Styled.Text>
        <strong>
          {schoolClassLevel ?? emptyValue}: {schoolClassCourse ?? emptyValue}
        </strong>
        <Styled.LightText>
          {schoolClassId ? `[${schoolClassId}]` : emptyValue}
        </Styled.LightText>
      </Styled.Text>

      <Styled.LightText>
        {schoolClassCode ?? emptyValue} - {schoolClassGroup ?? emptyValue} /{' '}
        {schoolClassModule ?? emptyValue} - {schoolClassShift ?? emptyValue}
      </Styled.LightText>
      <Styled.LightText>{schoolClassUnit ?? emptyValue}</Styled.LightText>

      <Styled.Text>
        <strong>Método de Avaliação:</strong>
        <Styled.LightText>{schoolClassType ?? emptyValue}</Styled.LightText>
      </Styled.Text>
    </Styled.Box>

    <Styled.Box>
      <div>
        <Styled.Text>
          <strong>Início:</strong>
          <Styled.LightText>
            {schoolClassStartDate
              ? formatDate(schoolClassStartDate)
              : emptyValue}
          </Styled.LightText>
        </Styled.Text>

        <Styled.Text>
          <strong>Fim:</strong>
          <Styled.LightText>
            {schoolClassEndDate ? formatDate(schoolClassEndDate) : emptyValue}
          </Styled.LightText>
        </Styled.Text>
      </div>

      <div>
        <Styled.LightText>
          {!isCanceled ? `${progressPercent}%` : 'Cancelado'}
        </Styled.LightText>
        <LinearProgress
          value={progressPercent}
          variant="determinate"
          color={getColorProgressBar({
            isCanceled,
            porcentage: progressPercent
          })}
        />
      </div>
    </Styled.Box>
  </Styled.AccordionSummary>
)
export default Sumary
