import PropTypes from 'prop-types'
import * as Styled from './styles'

const ButtonFooter = ({
  textButtonNotConfirm,
  textButtonConfirm,
  onClickNotConfirm,
  onClickConfirm,
  colorButtonConfirm,
  colorButtonNotConfirm,
  isButtonConfirm = true,
  hasMaxWidth,
  sizeType,
  disabledClose,
  disabledConfirm,
  classNameConfirm,
  classNameCancel,
  startIcon,
  typeNotConfirm,
  isMargin,
  justifyContent,
  isToShowButtonNotConfirm
}) => (
  <Styled.Footer $isMargin={isMargin} $justifyContent={justifyContent}>
    {isToShowButtonNotConfirm && (
      <Styled.ButtonNotConfirm
        className={classNameCancel}
        size={sizeType}
        value={textButtonNotConfirm}
        type={typeNotConfirm ?? 'submit'}
        variant="outlined"
        onClick={onClickNotConfirm}
        color={colorButtonNotConfirm}
        $hasMaxWidth={hasMaxWidth}
        disabled={disabledClose}
        startIcon={startIcon}
      />
    )}

    {isButtonConfirm && (
      <Styled.ButtonConfirm
        className={classNameConfirm}
        size={sizeType ?? 'medium'}
        value={textButtonConfirm}
        type="submit"
        color={colorButtonConfirm}
        onClick={onClickConfirm}
        disabled={disabledConfirm}
      />
    )}
  </Styled.Footer>
)

export default ButtonFooter

ButtonFooter.propTypes = {
  sizeType: PropTypes.string,
  textButtonNotConfirm: PropTypes.string,
  textButtonConfirm: PropTypes.string,
  colorButtonConfirm: PropTypes.string,
  colorButtonNotConfirm: PropTypes.string,
  onClickNotConfirm: PropTypes.func,
  onClickConfirm: PropTypes.func,
  isToShowButtonNotConfirm: PropTypes.bool
}

ButtonFooter.defaultProps = {
  sizeType: 'medium',
  textButtonNotConfirm: '',
  textButtonConfirm: '',
  onClickNotConfirm: () => {},
  onClickConfirm: () => {},
  isToShowButtonNotConfirm: true
}
