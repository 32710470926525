import { useState } from 'react'

import { defaultTotalPage, defaultActivePage } from './constants'

const usePagination = () => {
  const [activePage, setActivePage] = useState(defaultActivePage)
  const [totalPages, setTotalPages] = useState(defaultTotalPage)

  const handleSetPage = (event, page) => {
    setActivePage(+page)
  }

  const handleSetMaxPageNumber = (count, limit) => {
    const maxPageNumber = Math.ceil(count / limit)
    setTotalPages(maxPageNumber)
  }

  return { activePage, totalPages, handleSetMaxPageNumber, handleSetPage }
}

export default usePagination
