import styled from 'styled-components'
import { Paper, TableContainer } from '@mui/material'

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    width: 100%;
  }
`

const StyledTableContainer = styled(TableContainer)`
  &.MuiTableContainer-root {
    margin-top: 24px;
  }
`
export { StyledPaper, StyledTableContainer }
