import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { Form as FormikForm, Formik } from 'formik'

import { InputSwitch } from 'components/atoms'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'

import { typeMask } from 'services/helpers'
import { defaultValues } from './config'
import { typeSearchOptions, formModel, SEARCH_TYPE } from './constants'

import * as Styled from './style'

export default function SearchForm({ handleSearch }) {
  const isDisabled = (values) => !values?.searchType && !values?.fieldValue

  const handleResetForm = (resetForm) => {
    handleSearch(null)
    resetForm()
  }

  const { searchType, fieldValue, inactive } = formModel

  const fieldValueMask = (values) =>
    values[searchType.key] === SEARCH_TYPE.CNPJ ? 'CNPJ' : ''

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      onSubmit={(values, formikHelpers) =>
        handleSearch(values, formikHelpers, true)
      }
    >
      {({
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue,
        values,
        errors
      }) => (
        <FormikForm autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12} sm={12} md={4}>
              <Select
                label={searchType.label}
                onChange={(e) => setFieldValue(searchType.key, e.target.value)}
                optionsList={typeSearchOptions}
                value={values[searchType.key]}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                label={fieldValue.getLalel(
                  values[searchType.key] || SEARCH_TYPE.REASON_SOCIAL
                )}
                id={fieldValue.key}
                onChange={handleChange}
                value={typeMask(fieldValueMask(values), values[fieldValue.key])}
                error={errors?.[fieldValue.key]}
                helperText={errors?.[fieldValue.key]}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Styled.WrapperButtons>
                <InputSwitch
                  checked={values[inactive.key]}
                  label={inactive.label}
                  onClick={() =>
                    setFieldValue(inactive.key, !values[inactive.key])
                  }
                />
                <ButtonFooterV2
                  disabledClose={isDisabled(values)}
                  disabledConfirm={isDisabled(values)}
                  labelClose="Limpar"
                  labelConfirm="Buscar"
                  onClickClose={() => handleResetForm(resetForm)}
                  size="large"
                  startIcon={<Clear />}
                />
              </Styled.WrapperButtons>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  )
}

SearchForm.propTypes = {
  handleSearch: PropTypes.func
}

SearchForm.defaultProps = {
  handleSearch: () => {}
}
