const createTypeGroupList = (items) =>
  items.map(([id, name]) => ({ id, name, group: '0' }))

const typeGroupListData = (isLikeNovoTech) => ({
  A: [
    [32, 'Avaliação Objetiva/Discursiva'],
    [10, 'Avaliação Prática'],
    [27, 'Apresentação de Trabalho'],
    [2009, 'Seminário'],
    [2008, 'Avaliação Integrada'],
    ...(isLikeNovoTech
      ? [
          [2013, '1B AVC DOCENTE'],
          [2014, '2B AVC DOCENTE'],
          [2015, '3B AVC DOCENTE'],
          [2016, '4B AVC DOCENTE']
        ]
      : [])
  ],
  B: [
    [2005, 'Apresentação de Trabalho'],
    [19, 'Estudo Dirigido'],
    [2004, 'Exercício'],
    [2006, 'Relatório de aula prática'],
    ...(isLikeNovoTech
      ? [
          [2017, '1B AVA INTEGRADA'],
          [2018, '2B AVA INTEGRADA'],
          [2019, '3B AVA INTEGRADA'],
          [2020, '4B AVA INTEGRADA']
        ]
      : [])
  ],
  C: [
    [2007, 'Recuperação'],
    ...(isLikeNovoTech
      ? [
          [2021, '1B REC'],
          [2022, '2B REC'],
          [2023, '3B REC'],
          [2024, '4B REC'],
          [2025, 'REC FINAL']
        ]
      : [])
  ],
  D: [[2003, 'Reclassificação']],
  E: [[2012, 'Exame Final']]
})

const listGroup = (isLikeNovoTech) =>
  Object.fromEntries(
    Object.entries(typeGroupListData(isLikeNovoTech)).map(([key, value]) => [
      key,
      createTypeGroupList(value)
    ])
  )

export const getListGroup = (sent, isLikeNovoTech) =>
  listGroup(isLikeNovoTech)[sent]
