import { lazy, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListAlt, Search } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { getInvoicesByUnit } from 'services/api/admin'
import {
  IsEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import {
  ALL,
  MESSAGE,
  bankBillingUpdatedSucess,
  defaultPage,
  defaultTotalPage,
  nameAccordion,
  totalItems,
  initialState
} from './constants'
import { useInvoices } from './Context/FormContext'
import { getParamsByInvoices } from './services'

const TableInvoice = lazy(() => import('./component/TableInvoice'))
const FormSearchInvoices = lazy(() => import('./component/FormSearchInvoices'))

const Invoices = () => {
  const navigate = useNavigate()
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [filter, setFilter] = useState(defaultTotalPage)
  const [count, setCount] = useState(null)
  const [listNF, setListNF] = useState([])
  const [cardNumber, setCardNumber] = useState({})
  const [valuesAll, setValuesAll] = useState([])
  const [expanded, setExpanded] = useState(nameAccordion)

  const location = useLocation()
  const isSearchParam = location?.state?.savedSearch
  const isParamSavedBankBilling = location?.state?.savedNumberBankBilling
  const isParamUpdateBankBilling = location?.state?.updateNumberBankBilling
  const paramSnackOpen = isParamSavedBankBilling || isParamUpdateBankBilling

  const snackProps = useSnack()
  const { setSnack, snackOpen } = snackProps

  const {
    formValues,
    setFormValues,
    resetFormValues,
    cards,
    setCards,
    validCardNumber,
    setValidCardNumber
  } = useInvoices()

  const {
    page: pageActual,
    totalPage,
    items,
    setPage
  } = usePaginationBasic(listNF, filter, count)

  const onResult = async (params, valueUnity) => {
    const { data, error, status } = await getInvoicesByUnit(params, valueUnity)
    setLoadingOpen(false)

    if (error) {
      setNotSearch(true)
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListInvoices
      })
    }

    if (data?.list?.count === totalItems) {
      setNotSearch(false)

      return setListNF([])
    }
    setExpanded(false)
    setListNF(data?.list?.data)
    setCount(data?.list?.count)
    setCardNumber(data?.cards_info)
    return setValidCardNumber(false)
  }

  const handleSearchInvoices = async (values) => {
    setSnack('', '')
    const valueUnity = values?.unity?.map(({ label }) => label)
    const statusCards = cards === ALL ? '' : cards

    const params = {
      values,
      filter: validCardNumber ? defaultTotalPage : filter,
      pageActual: validCardNumber ? defaultPage : pageActual,
      cards: statusCards
    }
    if (validCardNumber) {
      setPage(defaultPage)
      setFilter(defaultTotalPage)
    }

    setValuesAll(values)
    setFormValues(values)
    setLoadingOpen(true)
    setValidCardNumber(false)
    return onResult(getParamsByInvoices(params), valueUnity)
  }

  const onClear = () => {
    resetFormValues()
    setListNF([])
    setNotSearch(true)
    setFormValues(initialState)
    setCards('')
  }

  useEffect(() => {
    if (formValues && cards && validCardNumber) {
      handleSearchInvoices(formValues)
    }
  }, [cards])

  useEffect(() => {
    if (valuesAll?.beginAt && !validCardNumber) {
      handleSearchInvoices(valuesAll)
    }
  }, [pageActual, filter])

  useEffect(() => {
    if (isSearchParam && formValues?.beginAt) {
      return handleSearchInvoices(formValues)
    }

    return !isSearchParam && setFormValues(initialState)
  }, [isSearchParam])

  useEffect(() => {
    if (paramSnackOpen) setSnack(bankBillingUpdatedSucess)
  }, [paramSnackOpen])

  useEffect(() => {
    if (paramSnackOpen && !snackOpen) {
      navigate(location?.pathname, {
        state: {
          updateNumberBankBilling: undefined,
          savedNumberBankBilling: undefined,
          savedSearch: true
        },
        replace: true
      })
    }
  }, [paramSnackOpen, snackOpen])

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH_INVOICES
    : MESSAGE.NOT_FOUND_SEARCH_INVOICES

  return (
    <TemplateDefaultFormAndResult
      titleHeader="Financeiro - Notas Fiscais"
      snackProps={snackProps}
      nameAccordion={nameAccordion}
      loadingOpen={loadingOpen}
      listItems={listNF}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      linkHeader={ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN_CREATE}
      classNameHeader={TAG_MANAGER.financeiro_btn_inicia_novoPlano}
      expanded={expanded}
      setExpanded={setExpanded}
      accordion={
        <FormSearchInvoices
          onSubmit={handleSearchInvoices}
          initialState={initialValues}
          onClear={onClear}
          setPage={setPage}
          setSnack={setSnack}
        />
      }
    >
      {listNF && !IsEmpty(listNF) && (
        <TableInvoice
          setSnack={setSnack}
          cardNumber={cardNumber}
          filter={filter}
          setFilter={setFilter}
          pageActual={pageActual}
          totalPage={totalPage}
          items={items}
          setPage={setPage}
          setLoadingVisibility={setLoadingOpen}
          count={count}
          onSubmit={handleSearchInvoices}
          handleSearchInvoices={handleSearchInvoices}
          formValues={formValues}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}
export default Invoices
