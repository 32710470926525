import { STUDENT_DISCIPLINE_SITUATION } from 'components/templates/PDF/SchoolReport/General/Table/Body'
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { addAsteristic } = utilsPDF

const handleGrade = ({ grade, hasAcademicPerformance }) => {
  if (grade) {
    const gradeFixed = grade.toFixed(2).replace('.', ',')
    return hasAcademicPerformance ? addAsteristic(gradeFixed) : gradeFixed
  }

  return '0,0'
}

const getFinalResult = ({ approved, situation, endDisciplineYear }) => {
  if (
    [situation].includes(
      STUDENT_DISCIPLINE_SITUATION.STUDYING,
      STUDENT_DISCIPLINE_SITUATION.TO_ATTEND
    ) ||
    !endDisciplineYear
  ) {
    return 'A cursar'
  }

  return approved ? 'Aprovado' : 'Reprovado'
}

export function buildTableBody({ gradeDetails, subjects }) {
  const subjectsMap = new Map()
  subjects.forEach((subject) => subjectsMap.set(subject.subjectId, subject))

  return gradeDetails.map((gradeDetail) => {
    const { discipline, student, grade, frequency } = gradeDetail
    const endDisciplineYear = subjectsMap.get(discipline.subjectId).endAt

    return [
      {
        text: `${discipline.module}°`,
        alignment: 'center',
        style: ['tableBodyCell']
      },
      {
        text: endDisciplineYear
          ? new Date(endDisciplineYear).getFullYear()
          : '',
        alignment: 'center',
        style: ['tableBodyCell']
      },
      { text: discipline.name, style: ['tableBodyCell'] },
      {
        text: discipline.workload,
        alignment: 'center',
        style: ['tableBodyCell']
      },
      {
        text: (100 - +frequency.absenceInPercentage).toFixed(),
        alignment: 'center',
        style: ['tableBodyCell']
      },
      {
        text: handleGrade({
          grade: grade.finalGrade,
          hasAcademicPerformance: student.hasAcademicPerformance
        }),
        alignment: 'center',
        style: ['tableBodyCell']
      },
      {
        text: getFinalResult({
          approved: student.approved,
          situation: student.situation,
          endDisciplineYear
        }),
        alignment: 'center',
        style: ['tableBodyCell']
      }
    ]
  })
}
