const LEVEL_TYPES = {
  HIGH_SCHOOL: 'highSchool',
  ELEMENTARY_SCHOOL: 'elementarySchool'
}

const SCHOOL_LEVEL = {
  [LEVEL_TYPES.HIGH_SCHOOL]: 'Ensino Médio',
  [LEVEL_TYPES.ELEMENTARY_SCHOOL]: 'Ensino Fundamental'
}

const KEY_COLUMNS = {
  NIVEL: 'nivel',
  TYPE: 'type',
  INSTITUTION: 'institution',
  CITY_OR_STATE: 'cityOrState',
  DONE: 'done',
  YEAR_DONE: 'yearDone'
}

const tableColumns = [
  { id: KEY_COLUMNS.NIVEL, label: 'Nível' },
  { id: KEY_COLUMNS.TYPE, label: 'Tipo' },
  { id: KEY_COLUMNS.INSTITUTION, label: 'Instituição' },
  { id: KEY_COLUMNS.CITY_OR_STATE, label: 'Cidade/Estado' },
  { id: KEY_COLUMNS.DONE, label: 'Concluido' },
  { id: KEY_COLUMNS.YEAR_DONE, label: 'Ano de Conclusão' }
]

const emptyValue = ''

export { SCHOOL_LEVEL, tableColumns, KEY_COLUMNS, emptyValue }
