import { ListAlt, Search } from '@mui/icons-material'

import { addDisciplinesInGrouping } from './service'
import { initialStateStatus, messages } from './constants'

export const formatFormValues = ({
  listUnitys,
  values: { unit: unitId, ...restValues }
}) => {
  const unit = listUnitys.find(({ value }) => value === unitId)?.label

  return {
    ...restValues,
    unit
  }
}

export const noResultMessage = ({ status }) => {
  if (status.empty) return messages.empty.text
  if (status.notFound) return messages.notFound.text

  return null
}

export const noResultIcon = ({ status }) => {
  if (status.empty) return <ListAlt />
  if (status.notFoundByUnity || status.notFound) return <Search />

  return null
}

export const handleSearch = ({
  listUnitys,
  values,
  setListDiscipline,
  setStatus,
  setFormValues
}) => {
  const shouldClear = [null, undefined].includes(values)

  if (shouldClear) {
    setListDiscipline([])
    setStatus(initialStateStatus)
    setFormValues({ search: null })
  } else {
    setFormValues({ search: formatFormValues({ listUnitys, values }) })
  }
}

export const handleEdit = ({ id, selected, setSelected }) => {
  if (!selected.includes(id)) {
    setSelected([...selected, id])
  }
}

export const handleAddDisciplinesInGrouping = ({
  groupingId,
  selected,
  setSnack,
  callbackSuccess,
  setLoading
}) => {
  addDisciplinesInGrouping({
    setSnack,
    groupingId,
    selected,
    callbackSuccess,
    setLoading
  })
}
