import styled from 'styled-components'

const WrapperTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  padding: 16px;
`

export { WrapperTitle }
