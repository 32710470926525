import { ROUTE } from 'services/constants'

const listBreadcrumbs = (unitCityName) => {
  const breadcrumbs = [
    {
      path: ROUTE.ADMIN_MANAGEMENT_UNITS_POLE,
      label: 'Gestão'
    },
    {
      path: ROUTE.ADMIN_MANAGEMENT_UNITS_POLE,
      label: 'Unidades'
    },
    {
      path: `${ROUTE.ADMIN_MANAGEMENT_POLE}?unit=${unitCityName}`,
      label: unitCityName
    },
    {
      path: ROUTE.ADMIN_MANAGEMENT_POLE,
      label: '<b>Polos</b>'
    }
  ]

  return breadcrumbs
}

export { listBreadcrumbs }
