import theme from 'theme/designTokens'

export const defaultListPerPage = 10
export const defaultStatusSuccess = 200
export const defaultPageActive = 1

export const PRESENT = 'P'
export const ABSENCE = 'F'
export const JUSTIFIED_ABSENCE = 'J'

export const NEGATIVE_ONE = -1
export const ZERO_NUMBER = 0
export const ONE_NUMBER = 1
export const TEN_NUMBER = 10
export const messageInformative =
  'Você não possui nenhuma frequência cadastrada. <br/> Para mais informações, contate a secretaria da sua unidade.'

export const messageError = 'Erro ao carregar dados da Frequência.'

export const captionStatus = [
  {
    value: PRESENT,
    label: 'Presente'
  },
  {
    value: ABSENCE,
    label: 'Falta'
  },
  {
    value: JUSTIFIED_ABSENCE,
    label: 'Falta justificada'
  }
]

const color = {
  [PRESENT]: theme.palette.primary.main,
  [ABSENCE]: theme.palette.textColors.error,
  [JUSTIFIED_ABSENCE]: theme.palette.success.main
}

export const getStatusColor = (sent) => color[sent]
