import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from '../Button'

const ButtonLinkTo = ({
  toUrl,
  labelBtn,
  color = 'primary',
  variant = 'contained',
  props
}) => (
  <Button
    variant={variant}
    component={Link}
    to={toUrl}
    color={color}
    size="large"
    {...props}
  >
    {labelBtn}
  </Button>
)

ButtonLinkTo.propTypes = {
  toUrl: PropTypes.string,
  labelBtn: PropTypes.string
}

export default ButtonLinkTo
