import { httpGet, httpPost } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getInvoicesByUnit = async (params, units) => {
  const unitsString = `?units[]=${units?.join('&units[]=')}`
  const response = await httpGet(
    `${ENDPOINT.INVOICES.CRUD}${units?.length ? unitsString : ''}`,
    { params }
  )
  return response
}

export const saveGenerateNotes = async (params) => {
  const response = await httpPost(ENDPOINT.INVOICES.CRUD, { ...params })

  return response
}

export const editInvoices = async (params) => {
  const response = await httpPost(ENDPOINT.INVOICES.EDIT, { ...params })

  return response
}

export const downloadXMLGrades = async (params, units) => {
  const unitsString = `units[]=${units?.join('&units[]=')}`
  const response = await httpGet(
    `${ENDPOINT.INVOICES.XLSX}?${
      units?.length ? `${unitsString}&${params}` : params
    }`,
    {
      responseType: 'blob'
    }
  )

  return response
}
