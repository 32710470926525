import styled, { css } from 'styled-components'
import {
  TableRow as MuiTableRow,
  Tooltip as MuiTooltip,
  Link as MuiLink
} from '@mui/material'
import { Place } from '@mui/icons-material'

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  tr:hover td {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const WrapperLink = styled.div`
  width: fit-content;
  align-items: center;
  display: flex;

  button {
    font-size: 14px;
    margin-left: ${({ theme }) => theme.spacing(1)};
    padding: 0;
    text-align: left;
  }

  .icon {
    display: none;
  }

  :hover {
    .icon {
      display: flex;
      cursor: pointer;
    }
  }
`

const TableRow = styled(MuiTableRow)`
  ${({ $isDisabled }) =>
    $isDisabled && ' opacity: 0.5; &:hover { opacity: 1; }'}

  background-color: ${({ isEditing, theme }) =>
    isEditing ? theme.palette.purple.light : 'none'};
  color: ${({ isEditing, theme }) =>
    isEditing ? theme.palette.dark.light : theme.palette.dark.main};
`

const Tooltip = styled(MuiTooltip)`
  &.MuiToolbar-root {
    padding: 0;
    align-items: center;
  }
`

const Link = styled(MuiLink)`
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `}
`

const DoneYear = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`

const PlaceIcon = styled(Place)`
  &.MuiSvgIcon-root {
    color: #593493;
  }
  margin-left: 5px;
`

const Unity = styled.div`
  display: flex;
  align-items: center;
`

export {
  TableRow,
  Wrapper,
  WrapperLink,
  Tooltip,
  Link,
  PlaceIcon,
  DoneYear,
  Unity
}
