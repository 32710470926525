import styled from 'styled-components'
import { Pagination as MuiPagination } from '@mui/material'

const Pagination = styled(MuiPagination)`
  ul {
    justify-content: center;
    padding: 24px 0;
  }
}
`

export { Pagination }
