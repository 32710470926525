import styled from 'styled-components'
import { Paper, Box as BoxMui } from '@mui/material'
import theme from 'theme/designTokens'

const TableContainer = styled(Paper)`
  &.MuiPaper-root {
    margin: ${({ $switchAdvancedSearch }) =>
      $switchAdvancedSearch ? '5px 0 0 0' : '33px 0 0 0'};
  }
`

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 16px 0;
`

const BoxGroupingCaptionWarning = styled(BoxMui)`
  &.MuiBox-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 21px;

    & > div {
      width: 21px;
      height: 21px;
      color: ${() => theme.palette.primary.white};
      background-color: ${() => theme.palette.primary.warningText};
      border-radius: 2px;
      margin-left: 7px;

      & p {
        text-align: center;
        width: 100%;
      }
    }

    & .MuiTypography-caption {
      font-weight: 500;
      margin-left: 8px;
      margin-top: 2px;
      font-size: 12px;
      line-height: 19.92px;
      letter-spacing: 0.4px;
    }
  }
`
const BoxGroupingCaptionSub = styled(BoxMui)`
  &.MuiBox-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 21px;

    & > div {
      width: 21px;
      height: 21px;
      color: ${() => theme.palette.primary.white};
      background-color: ${() => theme.palette.blue.main};
      border-radius: 2px;
      margin-left: 7px;

      & p {
        text-align: center;
        width: 100%;
      }
    }

    & .MuiTypography-caption {
      font-weight: 500;
      margin-left: 8px;
      margin-top: 2px;
      font-size: 12px;
      line-height: 19.92px;
      letter-spacing: 0.4px;
    }
  }
`
const BoxGroupingCaptionInfo = styled(BoxMui)`
  &.MuiBox-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 21px;

    & > div {
      width: 21px;
      height: 21px;
      color: ${() => theme.palette.primary.white};
      background-color: ${() => theme.palette.primary.main};
      border-radius: 10px;
      margin-left: 7px;

      & p {
        text-align: center;
        width: 100%;
      }
    }

    & .MuiTypography-caption {
      font-weight: 500;
      margin-left: 8px;
      margin-top: 2px;
      font-size: 12px;
      line-height: 19.92px;
      letter-spacing: 0.4px;
    }
  }
`


export { TableContainer, Box, BoxGroupingCaptionWarning, BoxGroupingCaptionSub, BoxGroupingCaptionInfo }
