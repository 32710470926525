import { ROUTE, SUCCESS } from 'services/constants'
import { dateFormat, formatDateEnv, updateErrorMessage } from 'services/helpers'
import { activity } from './constants'

const mergeInitialValues = (values) => {
  const startDate = dateFormat(values?.enrollment_start_date)
  const endDate = dateFormat(values?.enrollment_end_date)
  const dueDate = dateFormat(values?.due_date)

  return {
    id: values?.id,
    active: values?.active !== activity,
    name: values?.name,
    unity: values?.unit,
    enrollmentStartDate: startDate,
    enrollmentEndDate: endDate,
    dueDate,
    startPrevisionDate: values?.start_prevision_date,
    pageEdit: values?.name
  }
}

const getParamsGroup = (values) => ({
  name: values.pageEdit !== null ? undefined : values.name,
  unit: values.unity || undefined,
  enrollment_start_date: formatDateEnv(values.enrollmentStartDate),
  enrollment_end_date: formatDateEnv(values.enrollmentEndDate),
  due_date: formatDateEnv(values.dueDate),
  start_prevision_date: values.startPrevisionDate,
  active: values.active === true ? false : undefined
})

const updateSnask = ({
  error,
  status,
  message,
  updateGroup,
  savedGroup,
  feedbackMessage,
  setSnack,
  navigate
}) => {
  if (!error) {
    navigate(ROUTE.ADMIN_FINANCIAL_GROUPS, {
      state: { savedGroup, updateGroup, savedSearch: true }
    })
    return setSnack(message, SUCCESS)
  }
  return updateErrorMessage({
    setSnack,
    error,
    status,
    feedbackMessage
  })
}

export { mergeInitialValues, getParamsGroup, updateSnask }
