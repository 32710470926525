import { httpGet, httpPost } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getBackReturnProcessed = async (params) => {
  const response = await httpGet(ENDPOINT.BANK_RETURN.PROCESSED, {
    params
  })

  return response
}

export const backReturnProcessFile = async (payload) => {
  const response = await httpPost(ENDPOINT.BANK_RETURN.PROCESS_FILE, {
    ...payload
  })
  return response
}
