import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  h4 {
    font-size: 24px;
    font-weight: 400;
  }

  h5 {
    font-size: 16px;
    font-weight: 700;
  }

  h6 {
    margin-left: 8px;
  }
`

const BoxGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin-right: 24px;
  }
`

export { Container, Box, BoxGroup }
