import React, { useState, useEffect, lazy } from 'react'
import { useUserStore } from 'store/GlobalContext'
import { UploadPhoto } from 'components/organisms'
import { LoadingPage, Snackbar } from 'components/molecules'
import { useSnack } from 'services/hooks'
import { getProfile } from 'services/api/shared'
import { getErrorMessage } from 'services/helpers'
import { ERROR } from 'services/constants'
import { SEO_TRAKING } from '../../constants'
import { civilStatusList, stateList } from './mock'
import { ERROR_MESSAGE } from './constants'

const FormProfile = lazy(() => import('./components/FormProfile'))
const HeaderProfile = lazy(() => import('./components/HeaderProfile'))

const PersonalData = () => {
  const [open, setOpen] = useState(false)
  const [isloading, setIsLoading] = useState(false)
  const [photo, setPhoto] = useState('')
  const [profile, setProfile] = useState({})
  const { username: id } = useUserStore()
  const img = profile?.academic_photo

  const { snack, setSnack, snackOpen } = useSnack()

  const onHandleProfile = async () => {
    setIsLoading(true)
    setSnack('', '')
    const { data: response, error, status } = await getProfile({ id })
    setProfile(response)
    if (error) {
      const message = getErrorMessage(ERROR_MESSAGE, status)
      setSnack(message, ERROR)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    onHandleProfile()
  }, [id])

  useEffect(() => {
    if (profile && Object.keys(profile).length) setIsLoading(false)
  }, [profile])

  const state = {
    ...profile,
    socialName: profile?.social_name,
    civilStatus: profile?.civil_status,
    zipCode: profile?.zip_code,
    birth: profile?.birth_date
  }

  return (
    <>
      <LoadingPage open={isloading} />
      <HeaderProfile onClick={() => setOpen(!open)} photo={photo || img} />

      <FormProfile
        initialValues={state}
        civilStatusList={civilStatusList}
        stateList={stateList}
      />

      <Snackbar
        message={snack.message}
        severity={snack.severity}
        shouldOpen={snackOpen}
      />

      <UploadPhoto
        open={open}
        setOpen={setOpen}
        setPhoto={setPhoto}
        classNameModal={SEO_TRAKING.btn_user_requests_change_photo}
        classNameTrakingBtn={SEO_TRAKING.btn_user_confirms_sends_Photo}
      />
    </>
  )
}

export default PersonalData
