import * as yup from 'yup'

const schema = (courseList, schoolClass) =>
  yup.object().shape({
    student: yup.string().required(),
    category: yup.string().required(),
    type: yup.string().required(),
    dueDate: yup.date().required(),
    ...(courseList.length
      ? { course: yup.string().required() }
      : { course: yup.string() }),
    ...(schoolClass.length
      ? { schoolClass: yup.string().required() }
      : { schoolClass: yup.string() }),
    observation: yup.string().required()
  })

export default schema
