import { useState, useEffect } from 'react'
import { Clear } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { useMakeService, usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { isEmpty, mergeInitialValues } from 'services/helpers'
import { useFormik } from 'formik'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import {
  MESSAGE,
  countDefault,
  defaultItemsPerPage,
  initialState
} from './constants'
import Table from './Table'
import { useTypesOfActivitys } from '../Context/FormContext'
import * as Styled from './style'
import FormListTypesOfActivity from '../Forms/FormListTypesOfActivity'
import {
  getListCourses,
  handleSearch,
  handleSelectUnit,
  handleUpdateActivitys
} from '../service'
import schema from './schema'
import { getClassModalConfirm } from './helpers'

const ListTypesOfActivitys = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [activitys, setActivitys] = useState([])
  const [selectUnit, setSelectUnit] = useState()
  const [isActive] = useState(true)
  const [isSearch, setIsSearch] = useState(false)
  const [count, setCount] = useState(countDefault)
  const [notSearch, setNotSearch] = useState(false)
  const [listCourses, setListCourses] = useState([])
  const [listSelected, setListSelected] = useState([])
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [loadingVisibility, setLoadingVisibility] = useState(false)

  const snackProps = useSnack()
  const { setSnack } = snackProps

  const { formValues, setFormValues } = useTypesOfActivitys()

  const paramsMakeService = {
    isUnits: true,
    isLevel: true,
    setSnack
  }

  const { unitys, level } = useMakeService(paramsMakeService)

  const {
    page: pageActual,
    totalPage,
    setPage
  } = usePaginationBasic(activitys, filter, count)

  const initialValues = mergeInitialValues(initialState, formValues)

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      handleSearch({
        values,
        filter,
        setSnack,
        setCount,
        pageActual,
        setActivitys,
        setNotSearch,
        setLoadingVisibility
      })
      setFormValues(values)
    },
    validationSchema: schema
  })

  const handleClear = () => {
    setNotSearch(false)
    setActivitys([])
    setFormValues(initialState)
    formik.resetForm(initialValues)
  }

  const messageInformative = notSearch
    ? MESSAGE.NOT_FOUND_SEARCH
    : MESSAGE.NO_SEARCH

  const isEmptyUnity = formik.values.unit !== ''
  const isEmptyLevel = formik.values.unit !== ''

  const { values } = formik

  useEffect(() => {
    if (isSearch)
      handleSearch({
        values,
        filter,
        setSnack,
        setCount,
        pageActual,
        setActivitys,
        setNotSearch,
        setLoadingVisibility
      })
  }, [pageActual, filter])

  useEffect(() => {
    if (isEmptyUnity && isEmptyLevel)
      getListCourses({ values, setSnack, selectUnit, setListCourses })
  }, [formik.values.unit, formik.values.level])

  useEffect(() => {
    handleSelectUnit({ values, unitys, setSelectUnit })
  }, [formik.values.unit])

  return (
    <TemplateDefaultFormAndResult
      snackProps={snackProps}
      labelButtonHeader="novo tipo"
      loadingVisibility={loadingVisibility}
      iconInformative={messageInformative.icon}
      messageInformative={messageInformative.text}
      titleHeader="Gestão - Tipos de atividade"
      linkHeader={ROUTE.ADMIN_MANAGEMENT_TYPE_ACTIVITY_CREATE}
      classNameHeader={TAG_MANAGER.academico_btn_criar_novoTipoAtividade}
      listItems={activitys}
      formHeader={
        <Styled.Form
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
            <FormListTypesOfActivity
              level={level}
              unitys={unitys}
              formik={formik}
              setSnack={setSnack}
              listCourses={listCourses}
            />
            <Styled.Grid item $small xs={12} sm={12} md={3}>
              <ButtonFooterV2
                size="medium"
                labelClose="Limpar"
                labelConfirm="Buscar"
                startIcon={<Clear />}
                className={TAG_MANAGER.sistema_btn_busca_atividades}
                disabledConfirm={formik.touched.name && formik.errors.name}
                onClickConfirm={() => setIsSearch(true)}
                onClickClose={handleClear}
              />
            </Styled.Grid>
          </Styled.GridContainer>
        </Styled.Form>
      }
    >
      {activitys && !isEmpty(activitys) && (
        <Table
          count={count}
          filter={filter}
          values={values}
          items={activitys}
          setPage={setPage}
          isActive={isActive}
          setIsOpen={setIsOpen}
          totalPage={totalPage}
          setFilter={setFilter}
          pageActual={pageActual}
          setListSelected={setListSelected}
        />
      )}

      <ModalConfirm
        open={isOpen}
        size="medium"
        handleClose={() => setIsOpen(!isOpen)}
        textButtonConfirm="Sim"
        textButtonNotConfirm="Não"
        onClickNotConfirm={() => setIsOpen(!isOpen)}
        onClickConfirm={() =>
          handleUpdateActivitys({
            values,
            filter,
            setCount,
            isActive,
            setSnack,
            setIsOpen,
            pageActual,
            setNotSearch,
            setActivitys,
            listSelected,
            setLoadingVisibility
          })
        }
        colorButtonConfirm="error"
        icon={<Styled.IconWarningAmber />}
        title="Inativar"
        message="Deseja realmente inativar o(s) Tipo(s) de Atividade?   
        Ao inativar não será possível ativá-lo(s) novamente."
        classNameBtnConfirm={getClassModalConfirm(
          isActive,
          listSelected?.length
        )}
      />
    </TemplateDefaultFormAndResult>
  )
}
export default ListTypesOfActivitys
