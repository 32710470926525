/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Slide, Snackbar as MuiSnackbar } from '@mui/material'
import {
  AUTO_HIDE_TIME,
  ERROR,
  INFO,
  SUCCESS,
  WARNING
} from 'services/constants'
import * as Styled from './style'

const Snackbar = ({ message, shouldOpen, severity }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return
    setIsOpen(false)
  }

  useEffect(() => {
    setIsOpen(shouldOpen)
  }, [shouldOpen])

  return (
    <MuiSnackbar
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      autoHideDuration={AUTO_HIDE_TIME}
      key={severity}
      onClick={handleClose}
      onClose={handleClose}
      open={isOpen}
      TransitionComponent={Slide}
    >
      <Styled.Alert severity={severity} variant="filled">
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </Styled.Alert>
    </MuiSnackbar>
  )
}

export default Snackbar

Snackbar.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.oneOf([ERROR, INFO, SUCCESS, WARNING, '']),
  shouldOpen: PropTypes.bool
}

Snackbar.defaultProps = {
  message: '',
  severity: 'success',
  shouldOpen: false
}
