import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import { Grid } from '@mui/material'
import TAG_MANAGER from 'tagManager'
import FEEDBACK_SNACK from 'feedBackSnack'
import { Select, TextField } from 'components/molecules'
import { Button, DatePicker, InputSwitch } from 'components/atoms'
import { ROUTE } from 'services/constants'
import { createGroup, updateGroups } from 'services/api/admin'
import { useMakeService } from 'services/hooks'
import { getParamsGroup, updateSnask } from '../services'
import { MESSAGE } from '../constants'
import * as Styled from './style'
import { disabledSubmit } from './helpers'

const FormGroup = ({
  setSnack,
  setLoadingOpen,
  initialValues,
  disabledFields,
  nameGroup
}) => {
  const navigate = useNavigate()
  const { unitys } = useMakeService({ isUnits: true, setSnack })
  const allUnitys = 'Todas as Unidades'

  const updateGroup = async (values) => {
    const { error, status } = await updateGroups(
      getParamsGroup(values),
      values?.id
    )
    setLoadingOpen(false)

    return updateSnask({
      error,
      status,
      message: MESSAGE.SUCCESS_EDIT,
      updateGroup: true,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateGroup,
      setSnack,
      navigate
    })
  }

  const onSubmit = async (values) => {
    setSnack('', '')
    setLoadingOpen(true)

    if (nameGroup) {
      return updateGroup(values)
    }
    const { error, status } = await createGroup(getParamsGroup(values))
    setLoadingOpen(false)
    return updateSnask({
      error,
      status,
      message: MESSAGE.SUCCESS,
      savedGroup: true,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateGroup,
      setSnack,
      navigate
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit, setFieldValue, handleChange }) => (
        <Styled.Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            {nameGroup && (
              <Grid item xs={12} sm={12} md={12}>
                <InputSwitch
                  label="Inativar"
                  checked={values.active}
                  onClick={(e) => setFieldValue('active', e.target.checked)}
                  disabled={disabledFields}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Nome do grupo"
                disabled={nameGroup}
                value={values?.name || ''}
                onChange={handleChange}
                isMargin="0"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Select
                id="unity"
                value={values?.unity || ''}
                label="Unidade"
                optionsList={[
                  { value: allUnitys, label: allUnitys }, // Caso unico não copiar
                  ...unitys
                ]}
                onChange={(e) => setFieldValue('unity', e.target.value)}
                disabled={disabledFields}
                isColor
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <DatePicker
                fullWidth
                id="enrollmentStartDate"
                value={values.enrollmentStartDate || null}
                label="Início"
                onChange={(newValue) => {
                  setFieldValue('enrollmentStartDate', newValue)
                  setFieldValue('enrollmentEndDate', null)
                }}
                disabled={disabledFields}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <DatePicker
                fullWidth
                id="enrollmentEndDate"
                value={values.enrollmentEndDate || null}
                label="Fim"
                onChange={(newValue) =>
                  setFieldValue('enrollmentEndDate', newValue)
                }
                disabled={disabledFields}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <DatePicker
                fullWidth
                id="dueDate"
                value={values.dueDate || null}
                label="1° pagamento"
                onChange={(newValue) => setFieldValue('dueDate', newValue)}
                disabled={disabledFields}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <TextField
                fullWidth
                id="startPrevisionDate"
                name="startPrevisionDate"
                label="Previsão de Início das aulas"
                value={values?.startPrevisionDate || ''}
                isMargin="0"
                disabled={disabledFields}
                onChange={handleChange}
              />
            </Grid>

            <Styled.Grid item xs={12} sm={12} md={12}>
              <Button
                onClick={() =>
                  navigate(ROUTE.ADMIN_FINANCIAL_GROUPS, {
                    state: { savedSearch: true }
                  })
                }
                value="Cancelar"
                variant="outlined"
                type="reset"
                disabled={disabledSubmit(values, nameGroup) || disabledFields}
              />

              <Button
                className={TAG_MANAGER.financial_btn_saves_new_group}
                value="Salvar"
                variant="contained"
                type="submit"
                disabled={disabledSubmit(values, nameGroup) || disabledFields}
              />
            </Styled.Grid>
          </Grid>
        </Styled.Form>
      )}
    </Formik>
  )
}

export default FormGroup
