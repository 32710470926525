const defaultItemsPerPage = 10
const countDefault = 0
const defaultPage = 1
const lengthItem = 1

const MESSAGE = {
  NO_SEARCH:
    '<b>Encontre os professores cadastrados</b><br/>Você pode buscar por todos os professores ativos clicando em ”Buscar” ou então filtrar por Nome e/ou Inativos.',
  NOT_FOUND_SEARCH: 'Não encontramos nenhum professor cadastrado.',
  SUCCESS_INATCTIVE: 'Professor inativado com sucesso!',
  SUCCESS_EMAIl: 'E-mail enviado com sucesso!',
  SUCCESS_ATCTIVE: 'Professor ativado com sucesso!'
}

const initialState = {
  name: '',
  inactive: false
}

const DEFAULT_ITENS_PER_PAGE = 6

const listItemsPerPage = [
  {
    value: '6',
    label: '6'
  },
  {
    value: '12',
    label: '12'
  },
  {
    value: '24',
    label: '24'
  },
  {
    value: '36',
    label: '36'
  },
  {
    value: '54',
    label: '54'
  }
]

const typeAction = {
  remove: 'remove',
  copy: 'copy',
  changeOfValues: 'changeOfValues',
  planAlreadyExists: 'planAlreadyExists',
  change: 'change'
}

export {
  countDefault,
  defaultItemsPerPage,
  defaultPage,
  lengthItem,
  initialState,
  MESSAGE,
  typeAction,
  DEFAULT_ITENS_PER_PAGE,
  listItemsPerPage
}
