import { useFormikContext } from 'formik'
import { FormLabel, Grid, TextField } from '@mui/material'

import { typeMask } from 'services/helpers'

import * as Styled from './style'

const CardAffiliation = ({ label, fieldName, fieldNameAffiliation }) => {
  const { values, handleChange } = useFormikContext()

  return (
    <Styled.Container item xs={12}>
      <FormLabel>{label}</FormLabel>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Nome Completo*"
            name={`${fieldNameAffiliation}.${fieldName}`}
            value={values?.[fieldNameAffiliation]?.[fieldName]}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="CPF*"
            name={`${fieldNameAffiliation}.${fieldName}Cpf`}
            value={typeMask(
              'CPF',
              values?.[fieldNameAffiliation]?.[`${fieldName}Cpf`]
            )}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="RG"
            name={`${fieldNameAffiliation}.${fieldName}Rg`}
            value={values?.[fieldNameAffiliation]?.[`${fieldName}Rg`]}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Telefone/Celular"
            name={`${fieldNameAffiliation}.${fieldName}Phone`}
            value={values?.[fieldNameAffiliation]?.[`${fieldName}Phone`]}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="E-mail"
            name={`${fieldNameAffiliation}.${fieldName}Email`}
            value={values?.[fieldNameAffiliation]?.[`${fieldName}Email`]}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </Styled.Container>
  )
}

export default CardAffiliation
