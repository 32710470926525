import { ROUTE } from 'services/constants'

const breadcrumbsItems = (studentName, courseName) => [
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: 'Secretaria' },
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: 'Alunos' },
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: studentName },
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: 'Curso' },
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: courseName },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_SCORE,
    label: '<b>Boletim</b>'
  }
]

export { breadcrumbsItems }
