const headCells = [
  {
    id: '1',
    numeric: false,
    disablePadding: true,
    label: 'Aula'
  },
  {
    id: '2',
    numeric: false,
    disablePadding: false,
    label: 'Data'
  },

  {
    id: '4',
    numeric: false,
    disablePadding: false,
    label: 'Professor'
  },
  {
    id: '5',
    numeric: false,
    disablePadding: false,
    label: 'Conteúdo',
    last: true
  },
  {
    id: '3',
    numeric: false,
    disablePadding: false,
    label: 'Criado Por'
  }
]

export { headCells }
