import styled, { css } from 'styled-components'
import { Link as RouteLink } from 'react-router-dom'
import {
  TableCell,
  TextField as MuiTextField,
  Typography,
  Box as BoxMui
} from '@mui/material'

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: left;
    max-width: 200px;
    overflow: hidden;
    padding: 6px 16px;
    text-overflow: ellipsis;
    width: ${({ $width }) => $width}%;
  }
  &:nth-child(3).MuiTableCell-root {
    max-width: 165px;
  }
  &:nth-child(5).MuiTableCell-root {
    max-width: 130px;
  }
`

const BoxLink = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`
const Text = styled(Typography)`
  gap: 5px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.dark.main};
`
const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ContainerStudentStatus = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ContainerUnitName = styled(ContainerStudentStatus)`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

const StyledTableCellTitle = styled(TableCell)`
  &.MuiTableCell-root {
    max-width: 235px;
    text-align: left;
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
    padding: 6px 16px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
      }
    }
  }
`

const Link = styled(RouteLink)`
  text-transform: uppercase;
`
const TextField = styled(MuiTextField)`
  max-width: 150px;

  .MuiTypography-root {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
  }
  .Mui-disabled {
    -webkit-text-fill-color: rgb(0 0 0) !important;
  }
`

const ContainerIcon = styled.div`
  & .MuiButtonBase-root {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`
const BoxGrouping = styled(BoxMui)`
  &.MuiBox-root {
    width: 21px;
    height: 21px;
    color: ${({ theme }) => theme.palette.primary.white};
    background-color: ${({ theme }) => theme.palette.primary.warningText};
    border-radius: 2px;
    margin-left: 7px;

    & p {
      text-align: center;
      width: 100%;
    }
  }
`

export {
  Box,
  Link,
  Text,
  BoxLink,
  TextField,
  BoxGrouping,
  ContainerIcon,
  StyledTableCell,
  ContainerUnitName,
  StyledTableCellTitle,
  ContainerStudentStatus
}
