import { UNITS } from 'services/constants'
import { BIMESTER_MS_MAP, BIMESTER_NOVOTEC_MAP } from 'services/constants/units'
import { formatDate } from 'services/helpers'

const disabledButton = (values) => !values.typeFile
const getBimesterPeriodText = ({ begin, end, year, bimester }) =>
  `${bimester}º Bimestre ${year} - De ${formatDate(begin)} até ${formatDate(
    end
  )}`

const getBimesterMap = (unit) => {
  switch (+unit) {
    case UNITS.NOVOTEC.id:
      return BIMESTER_NOVOTEC_MAP

    case UNITS.MS.id:
      return BIMESTER_MS_MAP

    default:
      return null
  }
}

export { disabledButton, getBimesterPeriodText, getBimesterMap }
