import { ListAlt } from '@mui/icons-material'
import { ROUTE } from 'services/constants'

const messages = {
  empty: {
    icon: <ListAlt />,
    text: 'Não encontramos nenhuma turma/disciplina neste agrupamento.'
  }
}

const breadcrumbsItems = ({ grouping, groupingId }) => [
  { path: '', label: 'Secretaria' },
  { path: '', label: 'Agrupamentos' },
  {
    path: ROUTE.ADMIN_SECRETARY_GROUPING,
    label: `<b>${grouping} [${groupingId}]</b>`
  }
]

const textModalConfirm = {
  title: 'Excluir Turma',
  message: 'Deseja realmente remover esta turma do agrupamento?',
  buttonConfirm: 'Sim',
  buttonNotConfirm: 'Não'
}

export { messages, breadcrumbsItems, textModalConfirm }
