const initialValues = {
  cnpj: '',
  ieCnpj: '',
  uf_fin: '',
  cep_fin: '',
  end_fin: '',
  tel_fin: '',
  cpf_fin: '',
  comp_fin: '',
  nome_fin: '',
  email_fin: '',
  numero_fin: '',
  bairro_fin: '',
  observacao_fin: '',
  mobile_phone_fin: ''
}

export { initialValues }
