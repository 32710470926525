import styled from 'styled-components'

import {
  Typography,
  Card as MuiCard
} from '@mui/material'


const TitleLabel = styled(Typography)`
  color: #fff;
  font-weight: 300;
  padding-left: 10px;
  padding-right: 5px;
`

const TitleValue = styled(Typography)`
  color: #fff;
  font-weight: bold;
  padding-right: 20px;
`

const Card = styled(MuiCard)`
  &.MuiCard-root {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
`

const CardHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  padding: 16px;
`
const CardContent = styled.div`
  padding: 16px 26px;
`

export {
  Card,
  CardHeader,
  CardContent,
  TitleLabel,
  TitleValue
}
