import { createContext, useState, useContext } from 'react'

const initialState = {
  beginAt: '',
  endAt: '',
  unity: [],
  type: '',
  bankBillingId: '',
  localPayment: false,
  paid: false,
  sent: false,
  status: '',
  razaoSocial: false
}

export const FormContext = createContext(initialState)
export const InvoicesProvider = ({ children }) => {
  const [formValues, setFormValues] = useState(initialState)
  const [cards, setCards] = useState('')
  const [cardsIsValid, setCardsIsValid] = useState(false)
  const [validCardNumber, setValidCardNumber] = useState(false)

  const resetFormValues = () => {
    setFormValues({ ...initialState })
  }

  return (
    <FormContext.Provider
      value={{
        formValues,
        setFormValues,
        resetFormValues,
        cards,
        setCards,
        cardsIsValid,
        setCardsIsValid,
        validCardNumber,
        setValidCardNumber
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useInvoices = () => useContext(FormContext)
