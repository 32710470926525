export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const defaultTotalPage = 1
export const defaultItemsPerPage = 10

const headCellsPending = [
  {
    id: 'name',
    label: 'Alunos'
  },
  {
    id: 'register',
    label: 'Matrícula'
  },
  {
    id: 'email',
    label: 'E-mail'
  },
  {
    id: 'phone',
    label: 'Telefone'
  },
  {
    id: 'cellphone',
    label: 'Celular'
  },
  {
    id: 'student_2_absents',
    label: '2 Dias de falta consecutivos'
  },
  {
    id: 'student_5_absents',
    label: '5 Dias de falta consecutivos'
  },
  {
    id: 'student_7_absents',
    label: '7 Dias de falta consecutivos'
  },
  {
    id: 'student_15_absents',
    label: '15 Dias de falta consecutivos'
  }
]

export { headCellsPending }
