import { ROUTE } from 'services/constants'

const breadcrumbsItems = (lastLabel) => [
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: 'Secretaria' },
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: 'Alunos' },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER,
    label: `${lastLabel}`
  },
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS_HISTORY, label: 'Histórico' },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_NEW_DOCUMENT_HISTORY,
    label: '<b>Novo Histórico</b>'
  }
]

  const getHistoyData = (data) =>
  data.reduce(
    (acc, cur) => {
  const getStr = (accSchoollData,schoollData, key) => {
  const space = accSchoollData[key].indexOf('') === 0 ? '': ' '
  return {[key]: `${accSchoollData[key]}${space}${schoollData[key]}`}
}
return {
      ...acc,
      ...(cur.high_school_institution &&  getStr(acc, cur, 'high_school_institution')),
      ...(cur.high_school_location && getStr(acc, cur, 'high_school_location')),
      ...(cur.high_school_finished_year && getStr(acc, cur, 'high_school_finished_year')),
      ...(cur.elementary_school_institution && getStr(acc, cur, 'elementary_school_institution')),
      ...(cur.elementary_school_location && getStr(acc, cur, 'elementary_school_location')),
      ...(cur.elementary_school_finished_year && getStr(acc, cur, 'elementary_school_finished_year'))
    }
},
    {
      high_school_institution: '',
      high_school_location: '',
      high_school_finished_year: '',
      elementary_school_institution: '',
      elementary_school_location: '',
      elementary_school_finished_year: ''
    }
  )

const querySetForm = (value, studentInfo) => ({
  register: studentInfo?.student?.register || '',
  course_id: value.course_id || '',
  observation: value.observation || '',
  nationality: studentInfo?.student?.nationality || '',
  place_of_birth_state: studentInfo?.student?.place_of_birth_state || '',
  place_of_birth_city: studentInfo?.student?.place_of_birth_city || '',
  birth_date: studentInfo?.student?.birth_date || '',
  gender: studentInfo?.student?.sex || '',
  father: studentInfo?.affiliation?.father || '',
  mother: studentInfo?.affiliation?.mother || '',
  cpf: studentInfo?.student?.cpf || '',
  vote_registration_number:
    studentInfo?.student?.vote_registration_number || '',
  vote_registration_zone: studentInfo?.student?.vote_registration_zone || '',
  vote_registration_section:
    studentInfo?.student?.vote_registration_section || '',
  rg: studentInfo?.student?.rg || '',
  rg_dispatcher_date: studentInfo?.student?.rg_dispatcher_date || '',
  rg_dispatcher: studentInfo?.student?.rg_dispatcher || '',
  rg_state: studentInfo?.student?.rg_state || '',
  reservist_number: studentInfo?.student?.reservist_number || '',
  reservist_dispatcher: studentInfo?.student?.rg_dispatcher || '',
  reservist_date_dispatcher:
    studentInfo?.student?.reservist_date_dispatcher || '',
  ...getHistoyData(studentInfo?.school_data)
})

export { breadcrumbsItems, querySetForm }
