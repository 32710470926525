const sortAsc = 'asc'
const sortDesc = 'desc'
const defaultName = 'name'
const typeAll = 'Tudo'
const defaultTotalPage = 1
const numberSelected = 0

export {
  numberSelected,
  sortAsc,
  sortDesc,
  defaultName,
  typeAll,
  defaultTotalPage
}
