import { useEffect, useState } from 'react'
import { FieldArray, useFormik } from 'formik'
import { Box, Button, Grid } from '@mui/material'

import { MIN_INPUT_SEARCH } from 'services/constants'
import { InputAutocomplete, Modal } from 'components/atoms'

import * as Styled from './style'
import { getTeachersGroupingList, updateTeacherGrouping } from './service'

const TeacherModal = ({
  open,
  isEdit,
  handleClose,
  setSnack,
  item,
  callbackSuccess
}) => {
  const [listTeachers, setListTeachers] = useState([])
  const [inputSearched, setInputSearched] = useState('')

  const initialValues = {
    teachers: [
      {
        id: Math.random(),
        name: isEdit
          ? {
              label: item?.professor,
              value: item?.professorId
            }
          : null
      }
    ]
  }

  const { values, submitForm, setFieldValue } = useFormik({
    onSubmit: async () => {
      updateTeacherGrouping({
        setSnack,
        groupingId: item.id,
        teacherId: values?.teachers?.[0]?.name?.value,
        callbackSuccess
      })
    },
    initialValues
  })

  const isAvailableToDeleteTeacher = true

  useEffect(() => {
    if (
      (inputSearched?.length > MIN_INPUT_SEARCH &&
        inputSearched !== values?.name) ||
      !!item?.professor
    ) {
      getTeachersGroupingList({
        setSnack,
        setListTeachers,
        name: isEdit ? item?.professor : inputSearched
      })
    }
  }, [inputSearched, item?.professor])

  return (
    <Modal open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Box p={3}>
        <Styled.Title>{isEdit ? 'Editar' : 'Adicionar'} Professor</Styled.Title>

        <>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Styled.SubTitle>
                Preencha os campos abaixo para definir quem deverá ministrar a
                seguinte disciplina:
              </Styled.SubTitle>
              <Styled.CourseText>
                {`${item?.grouping} [${item?.id}]`}
              </Styled.CourseText>
            </Grid>

            <FieldArray name="teachers" validateOnChange={false}>
              <Styled.ListTeachers>
                {values.teachers.map((teacher, index) => {
                  const name = `teachers[${index}].name`

                  return (
                    <Styled.Teacher>
                      <InputAutocomplete
                        width="100%"
                        name={name}
                        label="Nome do professor"
                        value={teacher?.name}
                        listOptions={listTeachers}
                        getOptionLabel={(option) => option.label}
                        onInputChange={(event, value) => {
                          if (event?.type === 'click') return
                          setInputSearched(value)
                        }}
                        onChange={(_, value) => setFieldValue(name, value)}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option?.value}>
                            {option.label}
                          </Box>
                        )}
                        noOptionsText="Sem Opções"
                        helperText="Digite ao menos 4 caracteres"
                        isColor
                      />

                      <Styled.WrapperDeleteIcon>
                        <Styled.DeleteIcon
                          $disabled={!isAvailableToDeleteTeacher}
                          onClick={() => setFieldValue(name, null)}
                        />
                      </Styled.WrapperDeleteIcon>
                    </Styled.Teacher>
                  )
                })}
              </Styled.ListTeachers>
            </FieldArray>
          </Grid>

          <Styled.Footer>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button size="large" variant="contained" onClick={submitForm}>
              Confirmar
            </Button>
          </Styled.Footer>
        </>
      </Box>
    </Modal>
  )
}

export default TeacherModal
