import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FieldArray, useFormik } from 'formik'
import { Box, Grid, IconButton, Tooltip } from '@mui/material'

import { PageTitle } from 'components/atoms'
import {
  ButtonFooterV2,
  LoadingPage,
  Select,
  TextField
} from 'components/molecules'
import { useAddress } from 'services/hooks'
import { typeMask } from 'services/helpers'
import { isEqualObjects } from 'services/helpers/isEqualObjects'

import * as Styled from './style'

import {
  defaultValues,
  validationSchema,
  MAX_LENGTH_PHONE,
  MAX_LENGTH_CEP,
  MAX_LENGTH_CNPJ,
  MAX_PHONE_ADDED,
  typeOptionsToSelect
} from './config'

const Form = ({
  loadingVisibility,
  setLoadingVisibility,
  handlePayload,
  isEdit,
  formValues,
  openModalConfirm
}) => {
  const [isSearchByCep, setSearchByCep] = useState(false)
  const [address, setAddress] = useState({
    state: isEdit ? formValues?.state : ''
  })

  const {
    isUpdated,
    listCitys,
    listStates,
    fetchAddress,
    toggleUpdate,
    handleChangeCep,
    handleChangeState
  } = useAddress({
    address,
    setAddress
  })

  const handleSubmit = (values) => handlePayload(values)

  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    submitForm,
    handleChange,
    setFieldValue
  } = useFormik({
    initialValues: isEdit ? formValues : defaultValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnMount: true
  })

  useEffect(async () => {
    if (isSearchByCep) {
      setLoadingVisibility(true)

      await fetchAddress(values?.zipCode)

      setSearchByCep(false)
      setLoadingVisibility(false)
    }
  }, [isSearchByCep])

  useEffect(() => {
    if (isSearchByCep) {
      setLoadingVisibility(true)

      setFieldValue('district', address?.district)
      setFieldValue('state', address?.state)
      setFieldValue('city', address?.city)
      setFieldValue('address', address?.address)

      setLoadingVisibility(false)
      toggleUpdate()
    }
  }, [isUpdated])

  const isAvailabeToAddContact = (_values) =>
    _values?.phone?.length < MAX_PHONE_ADDED

  const disabledButton = (_values, _isValid) =>
    !_isValid || (isEdit && isEqualObjects(_values, formValues))

  return (
    <>
      <LoadingPage open={loadingVisibility} />

      <Box>
        <Grid container spacing={{ xs: 2 }}>
          <Grid item xs={12}>
            <PageTitle title="Dados do Fornecedor" />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="razaoSocial"
              label="Razão Social*"
              onChange={handleChange}
              value={values?.razaoSocial || ''}
              onBlur={handleBlur}
              helperText={errors && touched.razaoSocial && errors.razaoSocial}
              error={Boolean(touched.razaoSocial && errors.razaoSocial)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="tradeName"
              label="Nome Fantasia*"
              onChange={handleChange}
              value={values?.tradeName || ''}
              onBlur={handleBlur}
              helperText={errors && touched.tradeName && errors.tradeName}
              error={Boolean(touched.tradeName && errors.tradeName)}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              id="cnpj"
              inputProps={{ maxLength: MAX_LENGTH_CNPJ }}
              label="CNPJ*"
              value={typeMask('CNPJ', values?.cnpj)}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors && touched.cnpj && errors.cnpj}
              error={Boolean(touched.cnpj && errors.cnpj)}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              id="stateRegistration"
              label="Inscrição Estadual"
              onChange={handleChange}
              value={typeMask('NUMBER', String(values?.stateRegistration))}
              onBlur={handleBlur}
              helperText={
                errors && touched.stateRegistration && errors.stateRegistration
              }
              error={Boolean(
                touched.stateRegistration && errors.stateRegistration
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="email"
              label="E-mail"
              onChange={handleChange}
              value={values?.email || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              id="municipalRegistration"
              label="Inscrição Municipal"
              onChange={handleChange}
              value={values?.municipalRegistration || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              label="Tipo"
              onChange={(e) => setFieldValue('type', e.target.value)}
              optionsList={typeOptionsToSelect}
              value={values?.type}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              fullWidth
              id="zipCode"
              inputProps={{ maxLength: MAX_LENGTH_CEP }}
              label="CEP"
              value={typeMask('CEP', values?.zipCode)}
              onChange={(event) =>
                handleChangeCep({
                  event,
                  setSearchByCep,
                  setFieldValue
                })
              }
            />
          </Grid>

          <Grid item xs={3}>
            <Select
              label="Estado"
              onChange={(event) =>
                handleChangeState({
                  event,
                  setFieldValue
                })
              }
              optionsList={listStates}
              value={values?.state}
            />
          </Grid>

          <Grid item xs={7}>
            <Select
              label="Cidade"
              onChange={(e) => setFieldValue('city', e.target.value)}
              optionsList={listCitys}
              value={values?.city}
            />
          </Grid>

          <Grid item xs={10}>
            <TextField
              fullWidth
              id="address"
              label="Endereço"
              onChange={handleChange}
              value={values?.address || ''}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              fullWidth
              id="addressNumber"
              label="Número"
              onChange={handleChange}
              value={values?.addressNumber || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="complement"
              label="Complemento (opcional)"
              onChange={handleChange}
              value={values?.complement || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              id="district"
              label="Bairro"
              onChange={handleChange}
              value={values?.district || ''}
            />
          </Grid>

          <Grid item xs={12}>
            <FieldArray name="phone" validateOnChange={false}>
              {({ push, remove }) => (
                <Styled.Container>
                  {values.phone.map((valuephone, index) => {
                    const cell = `phone[${index}].cell`

                    return (
                      <Styled.ContainerField key={valuephone.id}>
                        <TextField
                          fullWidth
                          label="Telefone / WhatsApp"
                          name={cell}
                          onChange={handleChange}
                          value={typeMask('PHONE', valuephone?.cell)}
                          inputProps={{
                            maxLength: MAX_LENGTH_PHONE
                          }}
                          isMargin="0"
                        />

                        <Tooltip title="Excluir contato" placement="top">
                          <Styled.BoxTooltip>
                            <IconButton
                              disableRipple
                              onClick={() => remove(index)}
                            >
                              <Styled.DeleteIcon />
                            </IconButton>
                          </Styled.BoxTooltip>
                        </Tooltip>
                      </Styled.ContainerField>
                    )
                  })}

                  {isAvailabeToAddContact(values) && (
                    <Tooltip title="Adicionar contato" placement="top">
                      <Styled.BoxTooltip>
                        <IconButton
                          disableRipple
                          onClick={() =>
                            push({
                              id: Math.random(),
                              cell: ''
                            })
                          }
                        >
                          <Styled.AddIcon />
                        </IconButton>
                      </Styled.BoxTooltip>
                    </Tooltip>
                  )}
                </Styled.Container>
              )}
            </FieldArray>
          </Grid>

          <Grid item xs={12} marginTop={3}>
            <ButtonFooterV2
              disabledConfirm={disabledButton(values, isValid)}
              labelConfirm="Salvar"
              labelClose="Cancelar"
              onClickClose={openModalConfirm}
              onClickConfirm={submitForm}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

Form.propTypes = {
  handlePayload: PropTypes.func,
  isEdit: PropTypes.bool,
  formValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

Form.defaultProps = {
  handlePayload: () => {},
  isEdit: false,
  formValues: {}
}

export default Form
