import { ADMIN_TAG_MANAGER } from './Admin'
import { PROFESSOR_TAG_MANAGER } from './Professor'
import { ALUNO_TAG_MANAGER } from './Student'

const TAG_MANAGER = {
  ...ADMIN_TAG_MANAGER,
  ...PROFESSOR_TAG_MANAGER,
  ...ALUNO_TAG_MANAGER
}

export default TAG_MANAGER
