import * as Styled from './style'

const StepThree = () => (
  <Styled.Container>
    <Styled.BackgroundImage src="/images/onboarding_slide_3.svg" alt="#" />

    <Styled.Message>
      <Styled.MessageText>
        Caso tenha alguma dúvida, abaixo você poderá acessar os vídeos tutoriais
        de todos os fluxos do sistema.
      </Styled.MessageText>
      <Styled.MessageImage
        src="/images/onboarding_slide_3_ilustracoes.svg"
        alt="#"
      />
    </Styled.Message>
  </Styled.Container>
)

export default StepThree
