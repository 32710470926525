import styled from 'styled-components'
import { Typography as MuiTypography } from '@mui/material'

const Switch = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const Box = styled.div`
  margin: 23px 0;
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    display: block;
    color: #00000099;
    text-align: center;
    margin-top: 25px;
  }
`

export { Switch, Box, Typography }
