import { createContext, useState } from 'react'

import { defaultIndexStep } from './constants'

export const initialState = {
  hasViewedSecretary: false,
  isPending: false,
  currentStep: defaultIndexStep,
  completedTutorial: false
}

export const TutorialContext = createContext(initialState)

export const TutorialProvider = ({ children }) => {
  const [values, setValues] = useState(initialState)

  return (
    <TutorialContext.Provider
      value={{
        values,
        setValues
      }}
    >
      {children}
    </TutorialContext.Provider>
  )
}
