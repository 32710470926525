import * as yup from 'yup'

const validationSchema = yup.object().shape({
  startDate: yup
    .string()
    .nullable()
    .required('A data inicial é obrigatório')
    .typeError('Data Inválida'),
  endDate: yup
    .string()
    .nullable()
    .required('A data final é obrigatório')
    .typeError('Data Inválida')
})

const validationSchemaOcurrence = yup.object().shape({
  startDate: yup
    .string()
    .nullable()
    .required('A data inicial é obrigatório')
    .typeError('Data Inválida'),
  endDate: yup
    .string()
    .nullable()
    .required('A data final é obrigatório')
    .typeError('Data Inválida'),
  category: yup.string().nullable().required('A categoria é obrigatório')
})

export { validationSchema, validationSchemaOcurrence }
