/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-danger */
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { RemoveCircleOutline } from '@mui/icons-material'
import { IconButton, TableRow, Tooltip } from '@mui/material'
import { ROUTE } from 'services/constants'
import { convertToParams, formatDate } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'

const TableFooter = ({ row, setSelected, setOpenModal, isItemSelected }) => {
  const navigate = useNavigate()
  const [isMouseOver, setIsMouseOver] = useState(false)

  const handleInactivate = (id) => {
    setSelected(id)
    setOpenModal(true)
  }

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row?.id}
      aria-checked={isItemSelected}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      selected={isItemSelected}
    >
      <Styled.StyledTableCellTitle>
        <div className="content">
          <Tooltip title="Editar" placement="top" arrow>
            <Styled.BoxLink
              onClick={() =>
                navigate(
                  `${ROUTE.ADMIN_FINANCIAL_UPDATE_CAMPAIGN}?${convertToParams({
                    isEdit: true,
                    id: row?.id
                  })}`
                )
              }
              className={TAG_MANAGER.financeiro_btn_acessa_Campanha}
            >
              {row?.name}
            </Styled.BoxLink>
          </Tooltip>
        </div>
      </Styled.StyledTableCellTitle>

      <Styled.StyledTableCell $isCenter>
        {formatDate(row?.begin)}
      </Styled.StyledTableCell>
      <Styled.StyledTableCell $isCenter>
        {formatDate(row?.end)}
      </Styled.StyledTableCell>
      <Styled.StyledTableCell $isCenter>
        {row?.installments}
      </Styled.StyledTableCell>
      <Styled.StyledTableCell $isCenter>
        {row?.discount}%
      </Styled.StyledTableCell>
    </TableRow>
  )
}

export default TableFooter
