const listType = [
  {
    value: '1',
    label: '1'
  },
  {
    value: '2',
    label: '2'
  }
]

const type = {
  action: {
    clean: 'clean',
    toDiscard: 'toDiscard',
    remove: 'remove'
  },
  paramsModalConfirm: {
    remove: {
      title: 'Excluir Centro de Custo',
      message: 'Você tem certeza que deseja excluir este centro de custo?'
    },
    toDiscard: {
      title: 'Descartar alterações?',
      message: 'Você tem alterações não salvas. Deseja descartar e sair?'
    },
    clean: {
      title: 'Limpar Centro de Custo',
      message: 'Você tem certeza que deseja limpar este centro de custo?'
    }
  }
}

const defaultIndex = 1
const maxValuePercentage = 100
const balanceAmountIsZero = 'R$ 0,00'
const savedSearch = true
const situationEffective = 'effective'
const situationInactive = 'inactive'
const toogleValue = 'Valor'
const tooglePercentage = 'Porcentagem'
const invalidDate = 'Invalid date'
const installmentsNumber = 1
const notFoundNF = 0

const cardCode = {
  creditCard: 80,
  debitCard: 81,
  creditCardEssa: 76,
  debitCardEssa: 77
}

const zeroValue = 0

export {
  defaultIndex,
  type,
  listType,
  maxValuePercentage,
  balanceAmountIsZero,
  savedSearch,
  situationEffective,
  situationInactive,
  toogleValue,
  tooglePercentage,
  invalidDate,
  cardCode,
  installmentsNumber,
  notFoundNF,
  zeroValue
}
