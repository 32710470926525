import { lazy, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ListAlt, Search } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { useMakeService, usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE, SUCCESS } from 'services/constants'
import {
  IsEmpty,
  isEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  copyTypesRequirements,
  deleteTypesRequirements,
  getTypesRequirements,
  inactiveTypesRequirements
} from 'services/api/admin'
import {
  MESSAGE,
  countDefault,
  defaultItemsPerPage,
  initialState,
  nameAccordion
} from './constants'
import { useTypedOfRequirements } from '../Context/FormContext'
import { paramsOnSubmit, payloadCreateCopy } from './helpers'
import { handleCopyResponse } from '../services'

const AccordionForm = lazy(() => import('./AccordionForm'))
const Table = lazy(() => import('./Table'))

const ListTypedOfRequirements = () => {
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(false)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [requirements, setRequirements] = useState([])
  const [courses, setCourses] = useState([])
  const [
    openModalInactiveOrDeleteTypesRequirements,
    setOpenModalInactiveOrDeleteTypesRequirements
  ] = useState(false)
  const [
    openModalCreateCopyTypesRequirements,
    setOpenModalCreateCopyTypesRequirements
  ] = useState(false)
  const [isButtonSet, setIsButtonSet] = useState(false)
  const [isFilterInactive, setIsFilterInactive] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [count, setCount] = useState(countDefault)
  const [expanded, setExpanded] = useState(nameAccordion)

  const location = useLocation()
  const isSearchParam = location?.state?.savedSearch
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const { formValues, setFormValues } = useTypedOfRequirements()

  const paramsMakeService = {
    isUnits: true,
    isLevel: true,
    setSnack
  }
  const { unitys, level } = useMakeService(paramsMakeService)

  const {
    page: pageActual,
    totalPage,
    setPage
  } = usePaginationBasic(requirements, filter, count)

  const onSubmit = async (values) => {
    setLoadingOpen(true)
    setSnack('')

    const { data, error, status } = await getTypesRequirements(
      paramsOnSubmit({ values, filter, pageActual, unitys })
    )

    setLoadingOpen(false)
    setIsFilterInactive(values.inactive)
    setFormValues(values)
    setRequirements([])

    if (IsEmpty(data?.data)) {
      return setNotSearch(true)
    }

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToListTypesRequirements
      })
    }

    setCount(data?.count)

    return setRequirements(data?.data)
  }

  const successFullyOrError = ({ setSelected, error, status }) => {
    setLoadingOpen(false)
    setOpenModalInactiveOrDeleteTypesRequirements(false)
    setSelected([])

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: !isDelete
          ? FEEDBACK_SNACK.noAccessPermissionToInactiveTypesRequirements
          : FEEDBACK_SNACK.noAccessPermissionToDeleteTypesRequirements
      })
    }

    setSnack(
      !isDelete ? MESSAGE.SUCCESS_INACTIVE : MESSAGE.SUCCESS_DELETE,
      SUCCESS
    )

    return setTimeout(() => onSubmit(formValues), 3000)
  }

  const inactiveOrDeleteRequirements = async ({
    selected: selectedIds,
    setSelected
  }) => {
    setLoadingOpen(true)
    setSnack('')

    if (!isDelete) {
      const { error, status } = await inactiveTypesRequirements({
        ids: selectedIds || []
      })

      return successFullyOrError({
        setSelected,
        error,
        status,
        isDelete
      })
    }

    const { error, status } = await deleteTypesRequirements({
      ids: selectedIds || []
    })

    return successFullyOrError({
      setSelected,
      error,
      status,
      isDelete
    })
  }

  const createCopyTypesRequirements = async ({
    values,
    selected: selectedIds,
    setSelected
  }) => {
    setLoadingOpen(true)
    setSnack('')

    const { data, error, status } = await copyTypesRequirements(
      payloadCreateCopy({ values, selectedIds })
    )

    return handleCopyResponse({
      setLoadingOpen,
      onSubmit,
      formValues,
      setSnack,
      error,
      data,
      status,
      setOpenModalCreateCopyTypesRequirements,
      setSelected
    })
  }

  const onClear = () => {
    setNotSearch(false)
    setFormValues(initialState)
    setIsButtonSet(false)
    setRequirements([])
  }

  const messageInformative = notSearch
    ? MESSAGE.NOT_FOUND_SEARCH
    : MESSAGE.NO_SEARCH

  useEffect(() => {
    setOpenModalInactiveOrDeleteTypesRequirements(false)
    setOpenModalCreateCopyTypesRequirements(false)
  }, [])

  useEffect(() => {
    if (isButtonSet) onSubmit(formValues)
  }, [pageActual, filter])

  useEffect(() => {
    if (isSearchParam && formValues?.unity) {
      return onSubmit(formValues)
    }

    return setFormValues(initialState) && setRequirements([])
  }, [isSearchParam])

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  return (
    <TemplateDefaultFormAndResult
      classNameHeader={TAG_MANAGER.academic_btn_create_typeRequirement}
      titleHeader="Gestão - Tipos de Requerimento"
      labelButtonHeader="novo tipo"
      snackProps={snackProps}
      nameAccordion={nameAccordion}
      loadingOpen={loadingOpen}
      linkHeader={ROUTE.ADMIN_MANAGEMENT_TYPE_REQUIREMENTS_CREATE}
      iconInformative={notSearch ? <Search /> : <ListAlt />}
      messageInformative={messageInformative}
      listItems={requirements}
      expanded={expanded}
      setExpanded={setExpanded}
      accordion={
        <AccordionForm
          onSubmit={onSubmit}
          unitys={unitys}
          setIsButtonSet={setIsButtonSet}
          courses={courses}
          setPage={setPage}
          setSnack={setSnack}
          setCourses={setCourses}
          levels={level}
          initialState={initialValues}
          onClear={onClear}
        />
      }
    >
      {requirements && !isEmpty(requirements) && (
        <Table
          rowsItems={requirements}
          filter={filter}
          setFilter={setFilter}
          isFilterInactive={isFilterInactive}
          count={count}
          setIsDelete={setIsDelete}
          isDelete={isDelete}
          level={level}
          createCopyTypesRequirements={createCopyTypesRequirements}
          openModalInactiveOrDeleteTypesRequirements={
            openModalInactiveOrDeleteTypesRequirements
          }
          setOpenModalInactiveOrDeleteTypesRequirements={
            setOpenModalInactiveOrDeleteTypesRequirements
          }
          openModalCreateCopyTypesRequirements={
            openModalCreateCopyTypesRequirements
          }
          setOpenModalCreateCopyTypesRequirements={
            setOpenModalCreateCopyTypesRequirements
          }
          inactiveOrDeleteRequirements={inactiveOrDeleteRequirements}
          pages={pageActual}
          totalPage={totalPage}
          setPage={setPage}
          unitys={unitys}
          setSnack={snackProps.setSnack}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}
export default ListTypedOfRequirements
