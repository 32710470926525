import { ROUTE } from 'services/constants'

const breadcrumbItems = (isEdit, sectorName) => [
  {
    path: ROUTE.sectors.list,
    label: 'Sistema'
  },
  {
    path: ROUTE.sectors.list,
    label: 'Setores'
  },
  {
    path: '',
    label: `<b>${isEdit ? sectorName : 'Novo Setor'}</b>`
  }
]
export { breadcrumbItems }
