import { utilsPDF } from 'components/templates/PDF/shared/utils'
import buildTableHeader from './Header'
import buildTableBody from './Body'
import buildTableFooter from './Footer'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export default function BuildTable({ classInfo, student }) {
  return [
    {
      table: {
        body: [buildTableHeader(), ...buildTableBody({ classInfo, student })],
        widths: [320, ...new Array(6).fill('*'), 20, 60]
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    },
    buildTableFooter()
  ]
}
