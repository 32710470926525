import { TextField } from '@mui/material'
import { Select } from 'components/molecules'
import { DatePicker, InputSwitch } from 'components/atoms'

import * as Styled from './style'

const FormListCampaigns = ({ values, unitys, setFieldValue }) => (
  <>
    <Styled.Grid item xs={12} sm={12} md={6}>
      <TextField
        fullWidth
        id="name"
        name="name"
        isMargin="0"
        label="Nome"
        value={values?.name}
        onChange={(e) => setFieldValue('name', e.target.value)}
      />
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={4}>
      <Select
        isColor
        id="unity"
        label="Unidade"
        optionsList={unitys}
        value={values?.unity || ''}
        onChange={(e) => setFieldValue('unity', e.target.value)}
      />
    </Styled.Grid>

    <Styled.Grid item xs={12} sm={12} md={3}>
      <DatePicker
        fullWidth
        id="startDate"
        label="Período inicial"
        value={values?.startDate || null}
        onChange={(newValue) => {
          setFieldValue('startDate', newValue)
        }}
      />
    </Styled.Grid>

    <Styled.Grid item xs={12} sm={12} md={3}>
      <DatePicker
        fullWidth
        id="endDate"
        label="Período final"
        value={values?.endDate || null}
        onChange={(newValue) => {
          setFieldValue('endDate', newValue)
        }}
      />
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={3}>
      <Styled.Switch>
        <InputSwitch
          label="Inativas"
          checked={values.inactive}
          onClick={(e) => setFieldValue('inactive', e.target.checked)}
        />
      </Styled.Switch>
    </Styled.Grid>
  </>
)

export { FormListCampaigns }
