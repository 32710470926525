/* eslint-disable import/no-cycle */
import { useLocation } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import { usePaginationBasic } from 'services/hooks'
import { defaultlistPerPage } from 'services/hooks/constants'
import { LoadingPage } from 'components/molecules'

import { List, ResponsibleForm, SelectResponsible } from './components'
import { getResponsibleFinancial } from './services'
import { fieldsNameForm } from './constants'

const TabFinancialOfficer = () => {
  const { fieldNameListResponsibles, fieldNameResponsibleEdit } = fieldsNameForm

  const location = useLocation()

  const { values, handleChange, touched, handleBlur, setFieldValue } =
    useFormikContext()

  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])

  const [countPeerPage, setCountPeerPage] = useState(defaultlistPerPage)
  const [loading, setLoading] = useState(false)

  const { items, handleSetPage, totalPage, page } = usePaginationBasic(
    values?.[fieldNameListResponsibles],
    countPeerPage
  )

  const setResponsibles = (list) =>
    setFieldValue(fieldNameListResponsibles, list)

  const handleEdit = (item) => {
    setFieldValue(fieldNameResponsibleEdit, item)
  }

  useEffect(() => {
    getResponsibleFinancial({
      setLoading,
      setResponsibles,
      registerId: params?.register
    })
  }, [])

  return (
    <Box>
      <LoadingPage open={loading} />

      <List
        limit={countPeerPage}
        page={page}
        items={items ?? []}
        totalPage={totalPage}
        handleSetPage={handleSetPage}
        handleChange={(e) => setCountPeerPage(e.target.value)}
        handleEdit={handleEdit}
        itemEditingContractId={values?.[fieldNameResponsibleEdit]?.contractId}
      />

      {values?.[fieldNameResponsibleEdit] && (
        <>
          <SelectResponsible
            values={values}
            handleChange={handleChange}
            fieldNameForm={fieldNameResponsibleEdit}
          />
          {values?.[fieldNameResponsibleEdit]?.financialResponsibleType && (
            <ResponsibleForm
              values={values}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              fieldNameForm={fieldNameResponsibleEdit}
              setFieldValue={setFieldValue}
              setLoading={setLoading}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default TabFinancialOfficer
