import { useEffect, useState } from 'react'
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Tooltip
} from '@mui/material'
import { TextField, Select, ButtonFooterV2 } from 'components/molecules'
import { DatePicker } from 'components/atoms'

import { typeMask } from 'services/helpers/mask'
import { getValidateAge } from 'services/api/admin/comercial/courses'
import { useAddress } from 'services/hooks'
import TAG_MANAGER from 'tagManager'
import { useFormik } from 'formik'
import { MAX_LENGTH_CPF } from 'services/constants'
import {
  formatDate,
  formatDateIso,
  isBrazilianDateFormatValid,
  isEmpty,
  mergeInitialValues,
  validateCPF
} from 'services/helpers'
import { getCityByState, getCountry, getStates } from 'services/api/shared'
import {
  cepValid,
  civilStatusList,
  listMetUs,
  MAX_LENGTH_CEP,
  MAX_LENGTH_PHONE_MOBILE,
  MAX_LENGTH_RG,
  STUDENT,
  enrollmentOriginList
} from '../../constants'
import * as Styled from './style'
import { formSchema } from './schema'
import { DATESTRLENGHT, initialValues as initialState } from './constants'
import { searchCep } from '../../services'
import { parserCountriesOptions } from './helpers'

const FormStudentData = ({
  isLoad,
  showError,
  setIsLoad,
  isValidCpf,
  handleNext,
  onClickBack,
  setIsUnderage,
  setFinancialOfficerType,
  setIsValidCpf,
  valuesAllForms,
  userActiveStep,
  errorMessageCpf,
  setIsEmptyCountry,
  setValuesAllForms,
  handleValidateCpf
}) => {
  const [citys, setCitys] = useState([])
  const [states, setStates] = useState([])
  const [countrys, setCountrys] = useState([])
  const [naturalityCitys, setNaturalityCitys] = useState([])
  const [selectedState, setSelectedState] = useState('')
  const [isForeigner, setIsForeigner] = useState(true)
  const [address, setAddress] = useState({
    district: '',
    address: '',
    city: '',
    state: ''
  })

  const { fetchAddress } = useAddress({ setAddress })

  const formatBirthDateValues = (birthDateValues) =>
    isBrazilianDateFormatValid(birthDateValues)
      ? formatDateIso(birthDateValues)
      : birthDateValues || null

  const handleValidateAge = async (value) => {
    const verifyLenghtDate = value.length === DATESTRLENGHT

    if (verifyLenghtDate) {
      const { data } = await getValidateAge({
        birthdate: value
      })

      if (data?.is_underage) {
        setFinancialOfficerType({
          value: 'PessoaFisica',
          type: 'cpf'
        })
        setValuesAllForms((prev) => ({
          ...prev,
          financialOfficer: false
        }))
      }

      setIsUnderage(data?.is_underage)
    }
  }

  const initialValues = mergeInitialValues(initialState, valuesAllForms)

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleNext(values)
      setValuesAllForms((prev) => ({
        ...prev,
        ...values
      }))
    },
    validationSchema: formSchema(isForeigner)
  })

  const isCountryBr =
    formik.values.country === 'br' || formik.values.country === ''

  const hasErroCpf = !isValidCpf && showError

  useEffect(async () => {
    if (formik.values.naturalidade) {
      const cities = await getCityByState(formik.values.naturalidade)
      setNaturalityCitys(cities)
    }
  }, [formik.values.naturalidade])

  useEffect(() => {
    if (isCountryBr) return setIsEmptyCountry(true)

    return setIsEmptyCountry(false)
  }, [formik.values.country])

  useEffect(async () => {
    if (selectedState) {
      const cities = await getCityByState(selectedState)
      setCitys(cities)
    }
  }, [selectedState])

  useEffect(() => {
    setSelectedState(formik.values.uf)
  }, [formik.values.uf])

  useEffect(() => {
    const { cep } = formik.values

    if (cep.length === cepValid) searchCep({ fetchAddress, cepFin: cep })
  }, [formik.values.cep])

  useEffect(() => {
    formik.setFieldValue('uf', address?.state)
    formik.setFieldValue('cidade', address?.city)
    formik.setFieldValue('bairro', address?.district)
    formik.setFieldValue('endereco', address?.address)
  }, [address])

  useEffect(() => {
    const countryUppercase = formik.values.country?.toUpperCase()
    const data = countrys?.find((item) => item.value === countryUppercase)

    formik.setFieldValue('nacionalidade', data?.nationality)
  }, [formik.values.country, countrys])

  useEffect(() => {
    if (isCountryBr) {
      formik.setFieldValue('naturalidade', '')
      formik.setFieldValue('naturalidade_cidade', '')
    }
  }, [formik.values.country])

  useEffect(() => {
    const isCpfValid = formik.values.cpf
    const birthDate = formik.values.nascimento

    setIsValidCpf(validateCPF(isCpfValid))

    if (birthDate) {
      handleValidateAge(birthDate)
    }

    Promise.all([getStates(), getCountry()]).then(([statesBrasil, country]) => {
      setStates(statesBrasil)
      setCountrys(country)
    })
  }, [])

  return (
    <>
      <Styled.Form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
        <Styled.BoxHeader>
          <Styled.TextField
            id="cpf"
            label="CPF"
            type="text"
            error={hasErroCpf}
            isMargin="8px 16px 8px 0"
            helperText={hasErroCpf && errorMessageCpf}
            inputProps={{ maxLength: MAX_LENGTH_CPF }}
            value={formik.values.cpf}
            onChange={(e) => {
              const cpfMasked = typeMask('CPF', e.target.value)
              setIsLoad(false)
              setIsValidCpf(false)

              formik.setFieldValue('cpf', cpfMasked)
            }}
          />
          <Styled.Button
            size="large"
            loading={isLoad}
            disabled={isLoad}
            value="Continuar"
            onClick={() => handleValidateCpf(formik.values.cpf)}
            $isError={formik.errors.cpf && formik.touched.cpf}
            className={TAG_MANAGER.commercial_btn_starts_inscricaoDadosAl0uno}
          />
        </Styled.BoxHeader>
        {isValidCpf && (
          <Grid
            container
            spacing={{ xs: 0, sm: 2 }}
            style={{ marginTop: '2%' }}
          >
            <Styled.Grid item xs={12} md={6}>
              <Styled.TextField
                fullWidth
                name="nome"
                label="Nome Completo*"
                onBlur={formik.handleBlur}
                value={formik.values.nome}
                onChange={formik.handleChange}
                error={formik.errors.nome && formik.touched.nome}
                helperText={formik.touched.nome && formik.errors.nome}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={3}>
              <Styled.FormControl
                error={formik.errors.sexo && formik.touched.sexo}
              >
                <Tooltip
                  title="O que consta no documento oficial"
                  placement="top-start"
                >
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Gênero*
                  </FormLabel>
                </Tooltip>

                <Styled.RadioGroup
                  row
                  name="sexo"
                  onBlur={formik.handleBlur}
                  value={formik.values.sexo}
                  onChange={formik.handleChange}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                >
                  <FormControlLabel
                    value="F"
                    control={<Radio size="small" />}
                    label="Feminino"
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio size="small" />}
                    label="Masculino"
                  />
                </Styled.RadioGroup>
                {formik.touched.sexo && formik.errors.sexo && (
                  <Styled.TextError>{formik.errors.sexo}</Styled.TextError>
                )}
              </Styled.FormControl>
            </Styled.Grid>

            <Styled.Grid item xs={12} md={3}>
              <Select
                id="civil"
                name="civil"
                isMargin="0 0 16px"
                label="Estado Civil*"
                onBlur={formik.handleBlur}
                value={formik.values.civil}
                labelId="select-civilStatus"
                optionsList={civilStatusList}
                onChange={formik.handleChange}
                error={formik.errors.civil && formik.touched.civil}
                helperText={formik.touched.civil && formik.errors.civil}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="nome_social"
                label="Nome Social (opcional)"
                onChange={formik.handleChange}
                value={formik.values.nome_social}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={3}>
              <Select
                id="countrys"
                name="countrys"
                labelId="select-state"
                onBlur={formik.handleBlur}
                value={formik.values.country}
                optionsList={parserCountriesOptions(countrys)}
                onChange={(e) => {
                  formik.handleChange(e)
                  setIsForeigner(e.target.value === 'br')
                  formik.setFieldValue('country', e.target.value)
                }}
                label="País*"
                error={formik.errors.country && formik.touched.country}
                helperText={formik.touched.country && formik.errors.country}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={3}>
              <TextField
                fullWidth
                name="nacionalidade"
                label="Nacionalidade*"
                disabled
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.nacionalidade}
                error={
                  formik.errors.nacionalidade && formik.touched.nacionalidade
                }
                helperText={
                  formik.touched.nacionalidade && formik.errors.nacionalidade
                }
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={3}>
              <TextField
                fullWidth
                name="rg"
                maxLength={10}
                label={`RG${isForeigner ? '*' : ''}`}
                value={formik.values.rg}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.errors.rg && formik.touched.rg}
                helperText={formik.touched.rg && formik.errors.rg}
                inputProps={{
                  maxLength: MAX_LENGTH_RG
                }}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={3}>
              <DatePicker
                fullWidth
                id="nascimento"
                name="nascimento"
                label="Nascimento*"
                onBlur={(event) => {
                  handleValidateAge(event.target.value)
                  formik.handleBlur(event)
                }}
                value={formatBirthDateValues(formik.values.nascimento)}
                onChange={(newValue) => {
                  formik.setFieldValue('nascimento', newValue)
                }}
                error={formik.errors.nascimento && formik.touched.nascimento}
                helperText={
                  formik.touched.nascimento && formik.errors.nascimento
                }
              />
            </Styled.Grid>

            {!isCountryBr && (
              <Styled.Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  name="crnm"
                  label="CRNM"
                  value={formik.values.crnm}
                  onChange={formik.handleChange}
                />
              </Styled.Grid>
            )}
            {isCountryBr ? (
              <>
                <Styled.Grid item xs={12} md={!isCountryBr ? 6 : 3}>
                  <Select
                    id="naturalidade"
                    name="naturalidade"
                    labelId="select-state"
                    onBlur={formik.handleBlur}
                    optionsList={states}
                    label="Naturalidade (UF) *"
                    value={formik.values.naturalidade}
                    onChange={(e) => {
                      formik.handleChange(e)
                      formik.setFieldValue('naturalidade', e.target.value)
                    }}
                    error={
                      formik.errors.naturalidade && formik.touched.naturalidade
                    }
                    helperText={
                      formik.touched.naturalidade && formik.errors.naturalidade
                    }
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} md={!isCountryBr ? 6 : 3}>
                  <Select
                    isMargin="0 0 16px"
                    id="naturalidade_cidade"
                    name="naturalidade_cidade"
                    label="Naturalidade (Cidade) *"
                    labelId="select-naturalnessCity"
                    disabled={!formik.values.naturalidade}
                    value={formik.values.naturalidade_cidade}
                    defaultValue={formik.values.naturalidade_cidade}
                    optionsList={naturalityCitys}
                    onChange={(e) => {
                      formik.handleChange(e)
                      formik.setFieldValue(
                        'naturalidade_cidade',
                        e.target.value
                      )
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.naturalidade_cidade &&
                      formik.touched.naturalidade_cidade
                    }
                    helperText={
                      formik.touched.naturalidade_cidade &&
                      formik.errors.naturalidade_cidade
                    }
                  />
                </Styled.Grid>
              </>
            ) : (
              <>
                <Styled.Grid item xs={12} md={!isCountryBr ? 6 : 3}>
                  <TextField
                    fullWidth
                    name="naturalidade"
                    label="Naturalidade (UF) *"
                    value={formik.values.naturalidade}
                    onChange={(e) =>
                      formik.setFieldValue('naturalidade', e.target.value)
                    }
                    error={
                      formik.errors.naturalidade && formik.touched.naturalidade
                    }
                    helperText={
                      formik.touched.naturalidade && formik.errors.naturalidade
                    }
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} md={!isCountryBr ? 6 : 3}>
                  <TextField
                    fullWidth
                    name="naturalidade_cidade"
                    label="Naturalidade (Cidade) *"
                    value={formik.values.naturalidade_cidade}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'naturalidade_cidade',
                        e.target.value
                      )
                    }
                    error={
                      formik.errors.naturalidade_cidade &&
                      formik.touched.naturalidade_cidade
                    }
                    helperText={
                      formik.touched.naturalidade_cidade &&
                      formik.errors.naturalidade_cidade
                    }
                  />
                </Styled.Grid>
              </>
            )}

            <Styled.Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="email"
                type="email"
                label="E-mail*"
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email && formik.touched.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="pai"
                label="Nome do Pai"
                value={formik.values.pai}
                onChange={formik.handleChange}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={3}>
              <TextField
                fullWidth
                name="cep"
                label="CEP*"
                onBlur={(e) => {
                  formik.handleBlur(e)
                }}
                onChange={formik.handleChange}
                value={typeMask('CEP', formik.values.cep)}
                inputProps={{
                  maxLength: MAX_LENGTH_CEP
                }}
                error={formik.errors.cep && formik.touched.cep}
                helperText={formik.touched.cep && formik.errors.cep}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={3}>
              <Select
                id="uf"
                name="uf"
                labelId="select-state"
                value={formik.values.uf}
                onBlur={formik.handleBlur}
                optionsList={states}
                onChange={(e) => {
                  formik.handleChange(e)
                  formik.setFieldValue('uf', e.target.value)
                }}
                label="Estado*"
                error={formik.errors.uf && formik.touched.uf}
                helperText={formik.touched.uf && formik.errors.uf}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="mae"
                label="Nome da Mãe*"
                value={formik.values.mae}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.errors.mae && formik.touched.mae}
                helperText={formik.touched.mae && formik.errors.mae}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12} md={6}>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Styled.Grid item xs={12}>
                  <Select
                    id="cidade"
                    name="cidade"
                    label="Cidade*"
                    labelId="select-cidade"
                    onBlur={formik.handleBlur}
                    value={formik.values.cidade}
                    error={formik.errors.cidade && formik.touched.cidade}
                    helperText={formik.touched.cidade && formik.errors.cidade}
                    optionsList={citys}
                    disabled={!formik.values.uf}
                    onChange={formik.handleChange}
                    isMargin="0 0 16px"
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    label="Endereço*"
                    name="endereco"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.endereco}
                    error={formik.errors.endereco && formik.touched.endereco}
                    helperText={
                      formik.touched.endereco && formik.errors.endereco
                    }
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    name="numero"
                    label="Número*"
                    onBlur={formik.handleBlur}
                    value={formik.values.numero}
                    onChange={formik.handleChange}
                    error={formik.errors.numero && formik.touched.numero}
                    helperText={formik.touched.numero && formik.errors.numero}
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="complemento"
                    label="Complemento (opcional)"
                    onChange={formik.handleChange}
                    value={formik.values.complemento}
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="bairro"
                    label="Bairro*"
                    onBlur={formik.handleBlur}
                    value={formik.values.bairro}
                    onChange={formik.handleChange}
                    error={formik.errors.bairro && formik.touched.bairro}
                    helperText={formik.touched.bairro && formik.errors.bairro}
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12}>
                  <Select
                    id="metUs"
                    name="metUs"
                    isMargin="0 0 16px"
                    labelId="select-metUs"
                    optionsList={listMetUs}
                    value={formik.values.metUs || ''}
                    label="Como conheceu a Proz (opcional)"
                    onChange={(e) =>
                      formik.setFieldValue('metUs', e.target.value)
                    }
                  />
                </Styled.Grid>
              </Grid>
            </Styled.Grid>

            <Styled.Grid item xs={12} sm={12} md={6} $notMargin>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Styled.Grid item xs={12} sm={12} md={12} $notMargin>
                  <TextField
                    fullWidth
                    name="celular"
                    label="Celular*"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const celularMasked = typeMask('PHONE', e.target.value)
                      formik.setFieldValue('celular', celularMasked)
                    }}
                    value={formik.values.celular}
                    inputProps={{
                      maxLength: MAX_LENGTH_PHONE_MOBILE
                    }}
                    isMargin="0 0 16px"
                    error={formik.errors.celular && formik.touched.celular}
                    helperText={formik.touched.celular && formik.errors.celular}
                  />

                  <TextField
                    fullWidth
                    isMargin="0"
                    name="telefone"
                    label="Telefone (opcional)"
                    onChange={formik.handleChange}
                    value={typeMask('PHONE', formik.values.telefone)}
                    inputProps={{
                      maxLength: MAX_LENGTH_PHONE_MOBILE
                    }}
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12}>
                  <Select
                    id="enrollmentOrigin"
                    name="enrollmentOrigin"
                    isMargin="0 0 16px"
                    labelId="select-enrollmentOrigin"
                    optionsList={enrollmentOriginList}
                    value={formik.values.enrollmentOrigin || ''}
                    label="Qual a origem da matrícula?*"
                    onChange={formik.handleChange}
                    error={
                      formik.errors.enrollmentOrigin &&
                      formik.touched.enrollmentOrigin
                    }
                    helperText={
                      formik.touched.enrollmentOrigin &&
                      formik.errors.enrollmentOrigin
                    }
                  />
                </Styled.Grid>

                <Styled.Grid item xs={12}>
                  {userActiveStep === STUDENT && (
                    <Styled.FormControl
                      error={formik.errors.contato && formik.touched.contato}
                    >
                      <FormLabel component="legend">
                        Melhor horário para contato*
                      </FormLabel>

                      <Styled.RadioGroup
                        row
                        name="contato"
                        onBlur={formik.handleBlur}
                        value={formik.values.contato}
                        onChange={formik.handleChange}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        error={formik.errors.contato && formik.touched.contato}
                        helperText={
                          formik.touched.celular && formik.errors.celular
                        }
                      >
                        <FormControlLabel
                          value="morning"
                          control={<Radio size="small" />}
                          label="Manhã"
                        />
                        <FormControlLabel
                          value="afternoon"
                          control={<Radio size="small" />}
                          label="Tarde"
                        />
                        <FormControlLabel
                          value="night"
                          control={<Radio size="small" />}
                          label="Noite"
                        />
                      </Styled.RadioGroup>

                      {formik.touched.celular && formik.errors.celular && (
                        <Styled.TextError>
                          {formik.errors.contato}
                        </Styled.TextError>
                      )}
                    </Styled.FormControl>
                  )}
                </Styled.Grid>
              </Grid>
            </Styled.Grid>

            <Styled.Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                name="observacao"
                label="Observações (opcional)"
                onChange={formik.handleChange}
                value={formik.values.observacao}
              />
            </Styled.Grid>

            <Styled.Grid item xs={12}>
              <ButtonFooterV2
                size="medium"
                type="submit"
                labelClose="Voltar"
                labelConfirm="Avançar"
                classNameConfirm={
                  TAG_MANAGER.commercial_btn_search_for_registrations
                }
                onClickClose={() => {
                  formik.resetForm({})
                  onClickBack()
                }}
              />
            </Styled.Grid>
          </Grid>
        )}
      </Styled.Form>
    </>
  )
}

export default FormStudentData
