import { useState } from 'react'
import { Tabs } from 'components/organisms'
import { optionsTabs } from './constants'

const TabsUnits = ({
  nameUnit,
  isNameUnit,
  unitId,
  editForm,
  initialState,
  responsible,
  setDisabledLinkedPoles,
  formUnitPole,
  nameUnitExist,
  namePoleExist,
  handleDeleteSignatureResponsible,
  handleCreateOrUpdatePoleOrUnit,
  hasLinkedPoles,
  setFormUnitPole,
  typePage,
  handleCreateResponsible,
  handleCreateOrUpdateResponsible,
  PATH
}) => {
  const tabs = optionsTabs(typePage.pole, editForm)
  const [value, setValue] = useState(tabs[0].index)

  return (
    <Tabs
      customValue={[value, setValue]}
      contents={tabs?.map(({ getContent }) =>
        getContent({
          nameUnit,
          nameUnitExist,
          namePoleExist,
          isNameUnit,
          responsible,
          handleCreateOrUpdatePoleOrUnit,
          handleDeleteSignatureResponsible,
          setDisabledLinkedPoles,
          unitId,
          hasLinkedPoles,
          editForm,
          initialState,
          formUnitPole,
          setFormUnitPole,
          typePage,
          handleCreateResponsible,
          handleCreateOrUpdateResponsible,
          PATH
        })
      )}
      navigations={tabs.map((gradeTab) => gradeTab.navigation)}
    />
  )
}

export default TabsUnits
