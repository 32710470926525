import { useEffect, useState } from 'react'
import {
  IconButton,
  Switch,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  InputAdornment
} from '@mui/material'
import TAG_MANAGER from 'tagManager'
import { ModalConfirm } from 'components/molecules'
import { maxValuePercentage } from '../../constants'
import { totalBalance } from '../../services'
import * as Styled from './style'
import { handleSubCategory, handleTypes } from './services'

const CostCenter = ({
  values,
  setFieldValue,
  setValues,
  cancelAction,
  confirmAction = () => {},
  listNature,
  listUnits,
  listCostCenter,
  isDisabledFields
}) => {
  const [listType, setListType] = useState([])
  const [listSubcategory, setListSubcategory] = useState([])
  const [open, setOpen] = useState(false)

  const onHandleModalClear = () => {
    const defaultValues = {
      unit: '',
      costCenter: '',
      type: '',
      subcategory: '',
      nature: '',
      checked: false,
      amount: ''
    }
    setValues({ ...values, ...defaultValues })
    setOpen(false)
    confirmAction()
  }

  const onHandleChecked = (e) => {
    const { checked, name } = e.target
    setFieldValue(name, checked)
    setFieldValue('amount', '')
  }

  const handleCenterCost = (e) => {
    const selectedCostCenter = e.target.value
    setValues({
      ...values,
      subcategory: '',
      type: '',
      costCenter: selectedCostCenter
    })

    if (selectedCostCenter) {
      handleTypes({ idCenterCost: selectedCostCenter, setListType })
    }
  }

  const handleType = (e) => {
    const selectedType = e.target.value
    setValues({
      ...values,
      type: selectedType,
      subcategory: ''
    })

    if (selectedType) {
      handleSubCategory({ idType: selectedType, setListSubcategory })
    }
  }

  const handleAmount = (e) => {
    const value = values?.checked
      ? Math.min(parseInt(e.target.value, 10), maxValuePercentage)
      : e.target.value

    setFieldValue('amount', value)
  }

  useEffect(() => {
    if (values?.costCenter && values?.type) {
      handleTypes({ idCenterCost: values?.costCenter, setListType })
      handleSubCategory({ idType: values?.type, setListSubcategory })
    }
  }, [values?.costCenter && values?.type])

  return (
    <>
      <Styled.Header>
        <FormControl fullWidth>
          <InputLabel id="unit-label">Unidade*</InputLabel>
          <MuiSelect
            fullWidth
            labelId="unit-label"
            id="unit"
            label="Unidade*"
            value={values?.unit || ''}
            onChange={(e) => setFieldValue('unit', e.target.value)}
            disabled={Boolean(isDisabledFields)}
          >
            {listUnits?.map((item) => (
              <MenuItem key={item?.id} value={item?.id}>
                {item?.name}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="costCenter-label">Centro de custo*</InputLabel>
          <MuiSelect
            fullWidth
            labelId="costCenter-label"
            id="costCenter*"
            label="Centro de custo"
            value={values?.costCenter || ''}
            onChange={(e) => handleCenterCost(e)}
            disabled={Boolean(isDisabledFields)}
          >
            {listCostCenter?.map((item) => (
              <MenuItem key={item?.id} value={item?.id}>
                {item?.name}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="type-label">Tipo*</InputLabel>
          <MuiSelect
            fullWidth
            labelId="type-label"
            id="type"
            label="Tipo"
            value={values?.type || ''}
            onChange={(e) => handleType(e)}
            disabled={!values?.costCenter || isDisabledFields}
          >
            {listType?.map((item) => (
              <MenuItem key={item?.id} value={item?.id_cc4}>
                {item?.name}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="subcategory-label">Sub-categoria</InputLabel>
          <MuiSelect
            fullWidth
            labelId="subcategory-label"
            id="subcategory"
            label="Sub-categoria"
            value={values?.subcategory || ''}
            onChange={(e) => setFieldValue('subcategory', e.target.value)}
            disabled={!values?.type || isDisabledFields}
          >
            {listSubcategory?.map((item) => (
              <MenuItem key={item?.id_cc5} value={item?.id_cc5}>
                {item?.name}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="nature-label">Natureza*</InputLabel>
          <MuiSelect
            fullWidth
            labelId="nature-label"
            id="nature"
            label="Natureza"
            value={values?.nature || ''}
            onChange={(e) => setFieldValue('nature', e.target.value)}
            disabled={Boolean(isDisabledFields)}
          >
            {listNature?.map((item) => (
              <MenuItem key={item?.id} value={item?.id}>
                {item?.name}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
        <Styled.BoxValue>
          <Tooltip title="Habilitar para porcentagem" placement="top">
            <Styled.BoxTooltip>
              <Styled.Switch>
                <Switch
                  name="checked"
                  checked={values?.checked || false}
                  onClick={(e) => onHandleChecked(e)}
                  disabled={Boolean(isDisabledFields)}
                />

                <Typography>%</Typography>
              </Styled.Switch>
            </Styled.BoxTooltip>
          </Tooltip>

          <Styled.TextField
            fullWidth
            name="amount"
            variant="outlined"
            label="Valor*"
            onChange={(e) => handleAmount(e)}
            type="number"
            disabled={Boolean(isDisabledFields)}
            InputProps={{
              value: values?.amount || '',
              startAdornment: (
                <InputAdornment position="start">
                  {values?.checked ? '%' : 'R$'}
                </InputAdornment>
              )
            }}
          />
          <Tooltip title="Limpar" placement="top">
            <Styled.BoxTooltip>
              <IconButton
                className={TAG_MANAGER.finance_btn_excludes_title_cost_center}
                disableRipple
                onClick={() => setOpen(true)}
                disabled={Boolean(isDisabledFields)}
              >
                <Styled.DeleteIcon />
              </IconButton>
            </Styled.BoxTooltip>
          </Tooltip>
        </Styled.BoxValue>
      </Styled.Header>
      {values?.paymentMethod && values?.amountPaid && (
        <Styled.InfoFooter>
          <Typography variant="subtitle2">SALDO</Typography>
          <Styled.Total variant="subtitle2">
            {totalBalance(values)}
          </Styled.Total>
        </Styled.InfoFooter>
      )}

      <ModalConfirm
        open={open}
        size="medium"
        handleClose={(event) =>
          cancelAction ? cancelAction(event) : setOpen(false)
        }
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={(event) =>
          cancelAction ? cancelAction(event) : setOpen(false)
        }
        onClickConfirm={() => onHandleModalClear(setValues)}
        colorButtonConfirm="error"
        icon={<Styled.IconWarningAmber />}
        title="Limpar Centro de Custo"
        message="Você tem certeza que deseja limpar este centro de custo?"
      />
    </>
  )
}
export default CostCenter
