const isDisabledButton = ({ requirement }) => !requirement

const parseResponse = (data, isName) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { value: isName ? name : id, label: name }
    return [...acc, newItem]
  }, [])

const findResponsible = async ({ sectors, sector, setResponsable }) => {
  const resp = await sectors?.find((item) => item.value === sector)

  const res = parseResponse(resp?.responsibles)

  setResponsable(res || [])
}

export { isDisabledButton, parseResponse, findResponsible }
