import styled from 'styled-components'
import { Typography } from '@mui/material'

const Title = styled.h3`
  font-size: 32px;
  font-weight: 500;
  margin: ${({ $isMargin }) => ($isMargin ? '0' : '0 0 16px 0')};
`

const Card = styled.div`
  padding: 24px 24px 10px 24px;
`

const CardFooter = styled.div`
  margin-top: 24px;
  text-align: end;
`

const Label = styled(Typography)`
  padding-right: 5px;
`

const Value = styled(Typography)`
  padding-right: 20px;
`

const CardStudent = styled.div`
  display: flex;
  padding: 16px 0;
`

export { Card, Title, CardFooter, CardStudent, Label, Value }
