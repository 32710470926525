import { CPF, REGISTRATION } from './constants'

const initialValues = {
  unity: '',
  sector: '',
  requisitionId: '',
  category: '',
  finalized: false,
  dueDate: '',
  endDate: '',
  id: '',
  type: [],
  searchType: CPF,
  searchBy: ''
}

const simpleSearchBy = [
  { value: CPF, label: 'CPF' },
  { value: REGISTRATION, label: 'Matrícula' }
]

const typeSearch = {
  [REGISTRATION]: {
    typeMask: 'NUMBER',
    type: 'number',
    label: 'Digite a matrícula'
  },
  [CPF]: {
    typeMask: 'CPF',
    type: 'number',
    label: 'Digite apenas números'
  }
}

export { initialValues, simpleSearchBy, typeSearch }
