import { useState } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { useSortTable } from 'services/hooks'
import { TableHeaderDefault } from 'components/organisms'
import { ModalConfirm } from 'components/molecules'
import { MESSAGE, headCells } from './constants'
import * as Styled from './style'
import { TableFooter } from './TableFooter'
import { deleteProfessorsSkills, mainProfessorsSkills } from '../../services'

const Table = ({
  rows,
  setSnack,
  setLoadingOpen,
  idProfessor,
  setListAcademicEducation,
  setFormPesonalData
}) => {
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [idSkill, setIdSkill] = useState(null)

  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    rows,
    'main_skill'
  )

  return (
    <>
      <Styled.Paper>
        <Styled.TableContainer>
          <MuiTable>
            <TableHeaderDefault
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {sortTable?.reverse().map((row) => (
                <TableFooter
                  key={row.register}
                  row={row}
                  setIdSkill={setIdSkill}
                  setOpenModalConfirm={setOpenModalConfirm}
                  setIsDeleteModal={setIsDeleteModal}
                />
              ))}
            </TableBody>
          </MuiTable>
        </Styled.TableContainer>
      </Styled.Paper>

      <ModalConfirm
        open={openModalConfirm}
        colorButtonConfirm={isDeleteModal ? 'error' : 'primary'}
        handleClose={() => setOpenModalConfirm(!openModalConfirm)}
        title={isDeleteModal ? 'Excluir Formação' : 'Formação principal'}
        iconWarningAmber
        message={isDeleteModal ? MESSAGE.DELETE : MESSAGE.MAIN}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={() => setOpenModalConfirm(!openModalConfirm)}
        onClickConfirm={() => {
          if (isDeleteModal) {
            return deleteProfessorsSkills({
              setSnack,
              setLoadingOpen,
              idSkill,
              idProfessor,
              setListAcademicEducation,
              setFormPesonalData,
              setOpenModalConfirm,
              setIdSkill,
              setIsDeleteModal
            })
          }

          return mainProfessorsSkills({
            setSnack,
            setLoadingOpen,
            idSkill,
            idProfessor,
            setListAcademicEducation,
            setFormPesonalData,
            setOpenModalConfirm,
            setIdSkill
          })
        }}
      />
    </>
  )
}

export { Table }
