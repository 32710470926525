import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Checkbox, IconButton, TableRow, Tooltip } from '@mui/material'
import {
  Download,
  CopyAll,
  RemoveCircleOutline,
  ControlPoint
} from '@mui/icons-material'
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import { formatStringTagToString, makeURLAccessLegacy } from 'services/helpers'
import * as Styled from './style'

const TableFooter = ({
  row,
  labelId,
  isActive,
  setIsOpen,
  handleClick,
  setListSelected,
  isItemSelected
}) => {
  const navigate = useNavigate()
  const [isMouseOver, setIsMouseOver] = useState(false)

  const linkClassDetail = (item) =>
    makeURLAccessLegacy(
      `/acesso/sistema_contratos_pdf.php?id_empresa=${
        item?.company_id || ''
      }&tipo=${item?.name || ''}`,
      true
    )

  return (
    <TableRow
      hover
      key={row?.id}
      tabIndex={-1}
      role="checkbox"
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <Styled.StyledTableCell
        padding="checkbox"
        onClick={() => handleClick(row?.id)}
      >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': labelId
          }}
        />
      </Styled.StyledTableCell>
      <Styled.StyledTableCellTitle>
        <div className="content">
          <Tooltip title="Editar contrato" placement="top">
            <Styled.BoxLink
              onClick={() =>
                navigate(ROUTE.contracts.EDIT_CONTRACTS, {
                  state: { savedSearch: true, row }
                })
              }
              className={TAG_MANAGER.sistema_btn_edita_contrato}
            >
              {row?.name}
            </Styled.BoxLink>
          </Tooltip>
          {isMouseOver && (
            <div className="container_icons">
              <IconButton
                disableRipple
                onClick={() => linkClassDetail(row)}
                className={TAG_MANAGER.sistema_btn_baixa_contrato}
              >
                <Tooltip title="Baixar contrato" placement="top">
                  <Download />
                </Tooltip>
              </IconButton>
              <IconButton
                disableRipple
                className={TAG_MANAGER.sistema_btn_cria_novoContrato}
                onClick={() =>
                  navigate(ROUTE.contracts.CREATE_CONTRACTS, {
                    state: { savedSearch: true, row }
                  })
                }
              >
                <Tooltip title="Criar cópia" placement="top">
                  <CopyAll />
                </Tooltip>
              </IconButton>
              <IconButton
                disableRipple
                className={
                  isActive
                    ? TAG_MANAGER.sistema_btn_inativa_contrato
                    : TAG_MANAGER.sistema_btn_ativa_contrato
                }
                onClick={() => {
                  setIsOpen(true)
                  setListSelected([row?.id])
                }}
              >
                {isActive ? (
                  <Tooltip title="Inativar" placement="top">
                    <RemoveCircleOutline sx={{ color: '#ED6C02' }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Ativar" placement="top">
                    <ControlPoint sx={{ color: '#ED6C02' }} />
                  </Tooltip>
                )}
              </IconButton>
            </div>
          )}
        </div>
      </Styled.StyledTableCellTitle>

      <Styled.StyledTableCell maxHeight>
        {formatStringTagToString(row?.clause)}
      </Styled.StyledTableCell>
      <Styled.StyledTableCell>
        <Styled.Box isElectronicSignature={!row?.is_electronic_signature}>
          {row?.is_electronic_signature ? 'Sim' : 'Não'}
        </Styled.Box>
      </Styled.StyledTableCell>
    </TableRow>
  )
}

export default TableFooter
