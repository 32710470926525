import { Grid } from '@mui/material'
import { Select } from 'components/molecules'
import { Button } from 'components/atoms'
import * as Styled from './style'
import { handleSelectUnit } from '../../helpers'

const FirstStage = ({
  values,
  setFieldValue,
  unitys,
  setFirstStageCompleted,
  selectedLevel,
  setSelectedLevel,
  setHasPole,
  setFormValues
}) => (
  <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
    <Grid item xs={12} sm={12} md={4}>
      <Select
        id="unity"
        label="Unidade"
        value={values?.unity || ''}
        optionsList={unitys}
        onChange={(e) =>
          handleSelectUnit({
            event: e,
            values,
            selectedLevel,
            setFieldValue,
            setSelectedLevel,
            setHasPole,
            unitys,
            setFormValues
          })
        }
      />
    </Grid>

    <Grid item xs={12} sm={12} md={1}>
      <Button
        value="Continuar"
        size="medium"
        disabled={!values?.unity}
        onclick={() => setFirstStageCompleted(true)}
      />
    </Grid>
  </Styled.Grid>
)

export { FirstStage }
