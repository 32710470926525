const days = 45
const dateInvalidMessage =
  'O período máximo entre a data inicial e final é de 45 dias'

const initialValue = {
  firstPeriod: null,
  endPeriod: null,
  unity: ''
}

export { days, dateInvalidMessage, initialValue }
