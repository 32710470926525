import { useState, Suspense, useEffect } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { getComparator, stableSort } from 'services/helpers/formatTable'
import { PaginationAdvanced } from 'components/molecules'
import { paginationFilter } from 'services/helpers'
import { useCheckedTable } from 'services/hooks'
import { TableHeaderDefault } from 'components/organisms'
import { headCellsPending, sortAsc, sortDesc } from './constants'
import TableFooter from './TableFooter'
import TableToolbar from './TableToolbar'
import { StyledPaper, StyledTableContainer } from './style'
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants'

const TableContractsTypes = ({
  items,
  setPage,
  totalPage,
  pageActual,
  filter,
  count,
  isActive,
  setIsOpen,
  setListSelected,
  setFilter
}) => {
  const [order, setOrder] = useState(sortAsc)
  const [orderBy, setOrderBy] = useState()

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected: selectedItem
  } = useCheckedTable(items, 'id')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  useEffect(() => {
    setListSelected(selectedItem)
  }, [selectedItem])

  return (
    <Suspense>
      {selectedItem?.length >= 1 && (
        <TableToolbar
          isActive={isActive}
          setIsOpen={setIsOpen}
          amount={selectedItem?.length}
        />
      )}
      <StyledPaper>
        <StyledTableContainer>
          <MuiTable>
            <TableHeaderDefault
              checkbox
              order={order}
              orderBy={orderBy}
              headCells={headCellsPending}
              rowCount={items?.length}
              onRequestSort={handleRequestSort}
              numSelected={selectedItem?.length}
              handleSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-${index}`

                  return (
                    <TableFooter
                      row={row}
                      key={row.id}
                      labelId={labelId}
                      isActive={isActive}
                      setIsOpen={setIsOpen}
                      selected={selectedItem}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      setListSelected={setListSelected}
                    />
                  )
                }
              )}
            </TableBody>
          </MuiTable>
        </StyledTableContainer>

        {count > DEFAULT_ITEMS_PER_PAGE && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={pageActual}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setFilter(e.target.value)
            }}
          />
        )}
      </StyledPaper>
    </Suspense>
  )
}

export default TableContractsTypes
