import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { noBorderOnTop } = utilsPDF

export function buildTableHeader() {
  return [
    {
      text: 'Nº',
      border: noBorderOnTop,
      style: ['tableHeaderCell']
    },
    {
      text: 'Nome',
      border: noBorderOnTop,
      style: ['tableHeaderCell']
    },
    {
      text: 'Faltas',
      border: noBorderOnTop,
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: '% Faltas',
      border: noBorderOnTop,
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Avaliações presenciais ',
      border: noBorderOnTop,
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Atividades online',
      border: noBorderOnTop,
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Nota parcial',
      border: noBorderOnTop,
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Recuperação',
      border: noBorderOnTop,
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Reclassificação',
      border: noBorderOnTop,
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Nota final',
      border: noBorderOnTop,
      style: ['tableHeaderCell'],
      alignment: 'center'
    },
    {
      text: 'Resultado',
      border: noBorderOnTop,
      style: ['tableHeaderCell'],
      alignment: 'center'
    }
  ]
}
