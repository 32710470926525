import { Box, Tooltip, Typography, styled } from '@mui/material'

const BoxGrouping = styled(Box)(({ theme, size, fontSize }) => ({
  width: size ?? '21px',
  height: size ?? '21px',
  display: 'inline-block',
  background: theme.palette.primary.warningText,
  borderRadius: '2px'
}))

export default function GroupingIcon({ tooltipTitle, size, fontSize }) {
  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <BoxGrouping size={size}>
        <Typography color="white" textAlign="center" fontSize={fontSize}>
          A
        </Typography>
      </BoxGrouping>
    </Tooltip>
  )
}
