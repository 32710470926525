const isPastDate = (date) => {
  const currentDate = new Date()
  return new Date(date) < currentDate
}

const disabledButton = ({ values, beginDate }) =>
  !(values.beginDate && values.endDate) || !isPastDate(beginDate)
    ? isPastDate(values.beginDate) || isPastDate(values.endDate)
    : null

export { disabledButton }
