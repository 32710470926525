import { Box, Grid } from '@mui/material'
import { Formik, Form } from 'formik'
import { useUserStore } from 'store/GlobalContext'
import { updatePassword } from 'services/api/shared'
import { SUCCESS } from 'services/constants'
import { Button } from 'components/atoms'
import { TextField } from 'components/molecules'
import { isDisabledByPassword } from 'services/helpers/isDisabledByPassword'
import { passwordSuccessUpdated } from '../../constants'
import schema from './schema'
import * as Styled from '../../style'

const FormPassword = ({ initialState, setSnack }) => {
  const { username: id } = useUserStore()

  const handleUpdatePassword = async (values) => {
    setSnack('', '')
    const params = {
      password: values.newPassword
    }

    const response = await updatePassword({ id, params })

    if (!response.error) {
      setSnack(passwordSuccessUpdated, SUCCESS)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialState}
        validationSchema={schema}
        onSubmit={handleUpdatePassword}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Box sx={{ maxWidth: '400px' }}>
            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Nova senha"
                  name="newPassword"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  helperText={
                    errors &&
                    errors.newPassword &&
                    touched.newPassword &&
                    errors.newPassword
                  }
                  error={errors && errors.newPassword && touched.newPassword}
                  isMargin="0 0 16px"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  label="Confirme a nova senha"
                  type="password"
                  name="confirmPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  helperText={
                    errors &&
                    errors.confirmPassword &&
                    touched.confirmPassword &&
                    errors.confirmPassword
                  }
                  error={
                    errors && errors.confirmPassword && touched.confirmPassword
                  }
                  isMargin="0 0 16px"
                />
              </Grid>
              <Styled.BoxFooter>
                <Button
                  size="large"
                  value="Alterar Senha"
                  type="submit"
                  disabled={isDisabledByPassword(values, errors)}
                />
              </Styled.BoxFooter>
            </Form>
          </Box>
        )}
      </Formik>
    </>
  )
}

export default FormPassword
