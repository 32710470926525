/* eslint-disable camelcase */
import { toastNotificationWarning } from './nofications'

/* eslint-disable camelcase */
const hasQueryParams = (params) => params && params?.length

const reduceParams = (params) => (stringParams, key) => {
  let paramChaining = stringParams
  paramChaining += key && params[key] ? `${key}=${params[key]}&` : ''
  return paramChaining
}

const getQueryParams = (paramKeys, queryParamsObject) => {
  const queryStrings = paramKeys.reduce(reduceParams(queryParamsObject), '')
  return queryStrings.replace(/&+$/, '')
}

const makeURL = (path, queryStringsObject = {}) => {
  const paramsKeys = Object.keys(queryStringsObject)
  const queryParams = getQueryParams(paramsKeys, queryStringsObject)
  return hasQueryParams(queryParams) ? `${path}?${queryParams}` : path
}

const makeURLAccessLegacy = (path, blank) => {
  const getToken = localStorage.getItem('PROZ.savedDataFromUrl')
  const getPROZuser = localStorage.getItem('PROZ.user')
  const { company_id } = JSON.parse(getPROZuser)
  const { REACT_APP_ENV } = process.env
  const parseToken = JSON?.parse(getToken)

  const host = REACT_APP_ENV !== 'prod' ? REACT_APP_ENV : ''
  const subdomain = `${host}${company_id === 81 ? 'essa' : 'enferminas'}`

  if (!parseToken) {
    return toastNotificationWarning(
      'Por favor, faça o login no sistema antigo e acesse o novo através dele para obter o token e liberar o redirecionamento.',
      2000
    )
  }

  const url = `https://${subdomain}.prozeducacao.com.br/index.php?token=${
    parseToken.token
  }&route=${window.btoa(path)}`

  return blank ? window.open(url) : url
}

export { makeURL, makeURLAccessLegacy }
