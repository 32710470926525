import { Suspense, useState } from 'react'
import { ToastComponent } from 'components/atoms'
import { LoadingPage, Snackbar } from 'components/molecules'
import { HeaderPage } from 'components/organisms'
import { useSnack } from 'services/hooks'
import * as Styled from './style'
import List from './components/List'

const Imports = () => {
  const { snack, setSnack, snackOpen } = useSnack()
  const [loadingOpen, setLoadingOpen] = useState(false)
  return (
    <Suspense fallback={<LoadingPage open />}>
      <Styled.Content>
        <LoadingPage open={loadingOpen} />
        <div>
          <HeaderPage title="<strong>Sistema -</strong> Importações" divider />
        </div>
        <List setSnack={setSnack} setLoadingOpen={setLoadingOpen} />
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      </Styled.Content>

      <ToastComponent />
    </Suspense>
  )
}

export default Imports
