const paginationFilter = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' }
]

const defaultItemsPerPage = 10
const defaultPage = 1

const sortAsc = 'asc'
const sortDesc = 'desc'

export { defaultPage, defaultItemsPerPage, paginationFilter, sortAsc, sortDesc }
