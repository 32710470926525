import { lazy, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import TAG_MANAGER from 'tagManager'
import {
  TextField,
  Select,
  ButtonFooterV2,
  InputFile,
  ModalConfirm
} from 'components/molecules'
import { newRequirementsById } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { initialValues, statusHistoric, payload, MESSAGE } from './config'
import { handleListResponsible, handleListSector } from '../../services'
import { isDisabledButton } from '../../helpers'
import * as Styled from './style'

const Table = lazy(() => import('./component/Table'))

const FormHistoric = ({
  setOpenModal,
  requirement,
  listSector,
  setListSector,
  setSnack,
  isActiveSector,
  setListResponsible,
  listResponsible,
  isRequirementId,
  rows,
  filter,
  setFilter,
  setPage,
  page,
  totalPage,
  setShouldUpdateList,
  count
}) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [imgSrc, setImgSrc] = useState(null)
  const [nameFile, setNameFile] = useState('')

  const onSelectFile = (e, setFieldValue) => {
    if (e.target.files && e.target.files.length) {
      setImgSrc(null)
      const reader = new FileReader()
      const { name } = e.target.files[0]
      setNameFile(name)

      reader.addEventListener('load', () => {
        setFieldValue('file', reader.result.toString() || '')
        setImgSrc(reader.result.toString() || '')
      })

      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onHandleConfirm = async (values, { resetForm }) => {
    setOpen(false)

    setSnack('', '')

    const { error, status } = await newRequirementsById(
      Number(isRequirementId),
      payload({
        values,
        listSector,
        listResponsible
      })
    )
    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateRequisition
      })
    }
    setTimeout(() => {
      resetForm()
      setShouldUpdateList(true)
    }, 2000)

    return setSnack(MESSAGE.SUCCESS)
  }

  const selectResponsible = (e) => {
    const selectedSector = listSector.find(
      (sector) => sector.value === e.target.value
    )

    handleListResponsible({
      setListResponsible,
      sectorId: selectedSector?.id || '',
      setSnack
    })
  }

  useEffect(() => {
    handleListSector({ setSnack, setListSector, isActiveSector })
  }, [])

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onHandleConfirm}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Styled.FormFormik noValidate autoComplete="off">
            <Grid container spacing={{ xs: 3, sm: 3 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="subtitle1">
                  Para adicionar uma atualização, preencha algum dos campos
                  abaixo e salve
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Select
                  id="serviceSector"
                  value={values?.serviceSector || ''}
                  label="Setor de atendimento"
                  optionsList={listSector}
                  onChange={(e) => {
                    selectResponsible(e)
                    setFieldValue('serviceSector', e.target.value)
                  }}
                  disabled={
                    requirement?.status === 'Indeferido' ||
                    requirement?.status === 'Cancelado' ||
                    requirement?.status === 'Deferido'
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Select
                  id="responsible"
                  value={values?.responsible || ''}
                  label="Responsável"
                  optionsList={listResponsible}
                  onChange={(e) => setFieldValue('responsible', e.target.value)}
                  disabled={
                    requirement?.status === 'Indeferido' ||
                    requirement?.status === 'Cancelado' ||
                    requirement?.status === 'Deferido'
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Select
                  id="situation"
                  value={values?.situation || ''}
                  label="Situação"
                  optionsList={statusHistoric}
                  onChange={(e) => {
                    setFieldValue('situation', e.target.value)
                    setFieldValue(
                      'isInputFileDisabled',
                      e.target.value === 'Em análise'
                    )
                  }}
                  disabled={
                    requirement?.status === 'Indeferido' ||
                    requirement?.status === 'Cancelado' ||
                    requirement?.status === 'Deferido'
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={8}>
                <TextField
                  fullWidth
                  multiline
                  rows={5.4}
                  required
                  label="Observação"
                  name="description"
                  value={values?.description ?? ''}
                  isMargin="0"
                  onChange={handleChange}
                  disabled={
                    requirement?.status === 'Indeferido' ||
                    requirement?.status === 'Cancelado' ||
                    requirement?.status === 'Deferido'
                  }
                />
              </Grid>

              <Styled.InputFile item xs={12} sm={12} md={4}>
                <InputFile
                  idUpload="upload"
                  onChange={(e) => onSelectFile(e, setFieldValue)}
                  labelBox="Clique aqui para enviar (PDF)"
                  typeFile="pdf"
                  imgSrc={imgSrc}
                  nameFile={nameFile}
                  disabled={
                    values?.isInputFileDisabled ||
                    requirement?.status === 'Indeferido' ||
                    requirement?.status === 'Cancelado' ||
                    requirement?.status === 'Deferido'
                  }
                  noPreview
                />
              </Styled.InputFile>

              <Grid item xs={12} sm={12} md={12}>
                <ButtonFooterV2
                  labelClose="Cancelar"
                  labelConfirm="Salvar"
                  size="large"
                  onClickClose={() => setOpenModal(true)}
                  disabled={isDisabledButton(values)}
                />
              </Grid>
            </Grid>
          </Styled.FormFormik>
        )}
      </Formik>
      <Table
        rows={rows}
        filter={filter}
        setFilter={setFilter}
        setPage={setPage}
        page={page}
        totalPage={totalPage}
        count={count}
      />

      <ModalConfirm
        open={open}
        size="medium"
        handleClose={() => setOpen(false)}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={() => setOpen(false)}
        onClickConfirm={() => onHandleConfirm()}
        icon={<Styled.IconWarningAmber />}
        title="Atualizar situação"
        message="Esta ação envia uma notificação ao aluno
        Deseja atualizar a situação desta ocorrência?"
        classNameBtnConfirm={TAG_MANAGER.secretary_btn_updates_situationRequest}
      />
    </>
  )
}
export default FormHistoric
