const initialValues = {
  startDate: '',
  endDate: '',
  typeFile: ''
}

const optionTypeFile = [
  {
    value: 'pdf',
    label: 'PDF'
  },

  {
    value: 'xls',
    label: 'XLS'
  }
]

export { optionTypeFile, initialValues }
