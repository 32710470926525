import { ListAlt, Search } from '@mui/icons-material'

const messages = {
  empty: {
    icon: <ListAlt />,
    text: '<strong>Encontre o setor desejado</strong><br />Selecione pelo menos a unidade para realizar a busca de setores.'
  },
  notFoundByUnity: {
    icon: <Search />,
    text: 'Não encontramos nenhum setor cadastrado para a unidade escolhida.<br /> Você pode criar um setor clicando no botão acima.'
  },
  notFound: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
  },
  usersEmpty: {
    icon: <Search />,
    text: 'Ainda não há nenhum responsável selecionado. Escolha a(s) pessoa(s) acima.'
  }
}

export { messages }
