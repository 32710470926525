const days = 45

const defaultValues = {
  unit: '',
  level: '',
  course: '',
  startDate: null,
  endDate: null
}

const makeServiceHookParams = {
  unitsValue: true,
  isUnits: true,
  isLevel: true
}

export { days, defaultValues, makeServiceHookParams }
