import styled, { css } from 'styled-components'
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  ListItem as MuiListItem,
  List as MuiList,
  ListItemText as MuiListItemText
} from '@mui/material'
import { Modal as MuiModal } from 'components/atoms'

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 790px;
  }
`

const Grid = styled(MuiGrid)`
  padding: 21px;
  width: 433px;
  height: auto;
`

const List = styled(MuiList)`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 368px;
  padding: 0px 12px 0px 0px !important;
  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    height: 178px;
    background-color: #00000099;
    border: 4px transparent solid;
    border-radius: 30px;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 1px 0 0 #dedede;
    background-color: #f9f9f9;
  }
  > li:first-child {
    margin-bottom: 8px;
  }
`

const ListItem = styled(MuiListItem)`
  padding: 0px 2px 0 0 !important;
  border-bottom: 1.5px solid #e0e0e0;
  justify-content: space-between !important;
`

const ListItemText = styled(MuiListItemText)`
  ${({ $isTitle }) =>
    $isTitle &&
    css`
      > span:first-child {
        font-weight: bold;
      }
    `}
  ${({ $small }) =>
    $small &&
    css`
      width: 75px;
      flex: auto;
      max-width: 67px;
      text-align: end;
      font-weight: bold;
    `}
`

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

const Title = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

const Typography = styled(MuiTypography)`
  gap: 5px;
  display: flex;
  cursor: pointer;
  align-items: center;

  svg {
    font-size: 21px;
    color: #0000008a;
    transition: all 0.3s ease-out;
  }

  ${({ $isTop }) =>
    $isTop &&
    css`
      svg {
        transform: rotateZ(180deg);
        transition: all 0.3s ease-out;
      }
    `}
`

export { Modal, Grid, Typography, Title, Box, ListItemText, ListItem, List }
