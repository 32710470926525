import { ListAlt, Search } from '@mui/icons-material'
import { ROUTE } from 'services/constants'

const isUF = true
const defaultPage = 1
const defaultItemsPerPage = 10

const nameAccordion = 'replace_class'

const initialState = {
  unity: '',
  pole: '',
  level: '',
  class: '',
  course: '',
  switch_advanced_search: false
}

const breadCrumbsItems = ({ name }) => [
  {
    path: '',
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: `${name}`
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS,
    label: '<b>Substituir turma</b>'
  }
]

const MESSAGE = {
  NOT_FOUND_SEARCH: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
  },
  NOT_SEARCH: {
    icon: <ListAlt />,
    text: '<b>Você ainda não fez uma busca</b><br />Selecione uma opção e você pode combinar com os filtros para obter uma pesquisa mais específica por turmas.'
  }
}

export {
  isUF,
  MESSAGE,
  defaultPage,
  initialState,
  nameAccordion,
  breadCrumbsItems,
  defaultItemsPerPage
}
