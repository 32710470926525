import { ROUTE } from 'services/constants'

const defaultItemsPerPage = 10
const defaultPage = 1

const breadCrumbsItems = (id) => [
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS,
    label: 'Turmas'
  },
  {
    path: `${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS}?id=${id}`,
    label: 'Detalhes da Turma'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_BIND_UNBINDED,
    label: '<b>Enturmação</b>'
  }
]

const MESSAGE = {
  NOT_FOUND_SEARCH:
    'Não encontramos nenhum resultado.<br/> Volte a detalhes de turma e acesse outra turma',
  UNCROWDED_SUCCESS: 'Aluno(s) desenturmado(s) com sucesso!',
  EUTOURAGE_SUCCESS: 'Aluno(s) enturmado(s) com sucesso!'
}

export { breadCrumbsItems, defaultItemsPerPage, defaultPage, MESSAGE }
