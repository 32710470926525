import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowBack, NavigateNext } from '@mui/icons-material'
import { TemplateFormUnit as Template } from 'components/templates/Admin'
import { HeaderPage } from 'components/organisms'
import { ROUTE } from 'services/constants'
import { breadcrumbsItems } from './contants'
import Content from './Content'

const CreateByUpdateCourse = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const typePage = {
    isEdit: params?.isEdit,
    course: params?.course
  }

  const navigate = useNavigate()
  return (
    <>
      <Template
        titleHeader={
          <HeaderPage
            separator={<NavigateNext size="small" />}
            breadcrumbsItems={[
              ...breadcrumbsItems(typePage.isEdit, typePage.course)
            ]}
            callToAction={() =>
              navigate(ROUTE.ADMIN_MANAGEMENT_COURSE, {
                state: { savedSearch: true }
              })
            }
            startIcon={<ArrowBack />}
            labelCallToAction="voltar"
            divider
          />
        }
        content={<Content />}
      />
    </>
  )
}

export default CreateByUpdateCourse
