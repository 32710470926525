import { httpGet, httpDelete, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getListCupon = async (params) => {
  const response = await httpGet(ENDPOINT.CUPON.CRUD, { params })
  return response
}

export const removeCupon = async (id) => {
  const response = await httpDelete(`${ENDPOINT.CUPON.CRUD}/${id}`)

  return response
}

export const downloadXMLCupon = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.CUPON.CRUD}/${id}/usage-history/xlsx`,
    {
      responseType: 'blob'
    }
  )

  return response
}

export const saveCupon = async (params) => {
  const response = await httpPost(ENDPOINT.CUPON.CRUD, params)

  return response
}

export const getCuponById = async (id) => {
  const response = await httpGet(`${ENDPOINT.CUPON.CRUD}/${id}`)

  return response
}

export const updateCupon = async (params) => {
  const response = await httpPut(ENDPOINT.CUPON.CRUD, {
    ...params
  })

  return response
}
