import { getListSchool } from 'services/api/admin'
import { getUnitsRegions } from 'services/api/shared'
import { getDataFormKeys, updateErrorMessage } from 'services/helpers'

const payload = ({ values, register, selectedSchoolClass }) => ({
  register: Number(register),
  unit: values?.unity || '',
  pole: values?.pole || '',
  group: values?.group || '',
  level: values?.level || '',
  course: values?.course || '',
  module: values?.module_id || 0,
  modality: values?.modality_id,
  shift: values?.shift || '',
  workload: Number(values?.workload) || null,
  observation: values?.observation || '',
  school_class_id: values?.schoolClass || '',
  school_class_code: selectedSchoolClass?.code || ''
})

const typeEndpointPole = (unitId) => `type=pole&unit_id=${unitId}`

const parseResponse = (data, isName) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { value: isName ? name : id, label: name }
    return [...acc, newItem]
  }, [])

const getListPole = async ({ setListPole, unity, setSnack }) => {
  const isName = true

  const {
    data: response,
    error,
    status
  } = await getUnitsRegions(typeEndpointPole(unity))

  const dataPole = getDataFormKeys([0, 'units'], response?.data)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status
    })
  }

  return setListPole(parseResponse(dataPole, isName))
}

const selectSchoolClass = async ({ setListSchoolClass, setSnack, values }) => {
  setSnack('')

  const params = {
    unit: values.unity,
    level: values.level,
    course: values.course,
    shift: values.shift,
    modality: values.modality_id,
    group: values.group
  }

  const { data, error, status } = await getListSchool(params)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: ''
    })
  }
  const schoolClass = data

  const optionsList = schoolClass?.map((item) => ({
    label: item.school_class_code,
    value: item.id,
    code: item.school_class_code
  }))

  return setListSchoolClass(optionsList)
}

export { payload, getListPole, selectSchoolClass }
