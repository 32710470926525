import { cleanObject } from 'services/helpers/cleanObject'
import {
  convertToParamsArrayString,
  deserialize,
  messageErroOrSuccess,
  serialize
} from 'services/helpers'
import {
  addDisciplinesInGrouping as addDisciplinesInGroupingService,
  getDisciplinesToAddInGrouping as getDisciplinesToAddInGroupingService
} from 'services/api/admin'

export const getDisciplinesToAddInGrouping = async ({
  setSnack,
  setListDiscipline,
  search,
  setStatus,
  groupingDisciplines
}) => {
  const { error, status, data } = await getDisciplinesToAddInGroupingService({
    params: cleanObject(deserialize(search)),
    stringGroupingDisciplines: convertToParamsArrayString(
      groupingDisciplines,
      'grouping_disciplines'
    )
  })

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  setListDiscipline(data.map(serialize))

  setStatus({
    empty: false,
    notFound: !(data ?? [])?.length
  })
}

export const addDisciplinesInGrouping = async ({
  groupingId,
  selected: disciplineIds,
  setSnack,
  callbackSuccess,
  setLoading
}) => {
  setLoading(true)
  const { error, status } = await addDisciplinesInGroupingService({
    groupingId,
    payload: deserialize({
      disciplineIds
    })
  })

  messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Disciplina  adicionada com sucesso.'
  })

  if (!error) {
    return setTimeout(callbackSuccess, 1000)
  }

  return setLoading(false)
}
