import * as yup from 'yup'

const validationSchema = yup.object({
  inactive: yup.boolean(),
  sectorName: yup.string().optional(),
  unityId: yup.string().required('Você deve selecionar uma unidade.'),
  users: yup.array().optional()
})

const defaultValues = {
  inactive: false,
  sectorName: '',
  unityId: 0,
  users: []
}

const makeServiceHookParams = {
  unitsValue: true,
  isUnits: true
}

export { defaultValues, makeServiceHookParams, validationSchema }
