import { Grid, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useUserStore } from 'store/GlobalContext'

const getRegionByCompanyId = ({ company, height }) => {
  const fontSize = height ? `${+height?.replace('px', '') / 3.38}px` : null

  const regionMapedByCompanyId = {
    81: ['SP', 'PR'],
    85: ['MG', 'MS']
  }
  const regionsToPlot = regionMapedByCompanyId[company]

  if (!regionsToPlot) return false

  return (
    <Grid item height={height}>
      <Grid
        container
        height={height}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {regionsToPlot.map((region, index) => (
          <Grid item key={region}>
            <Typography
              fontSize={fontSize}
              color="white"
              lineHeight={index ? 1.2 : 1.1}
            >
              {region}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

const Logotype = ({ alt, height, isDark, width, ...props }) => {
  const { company_id: companyId } = useUserStore()

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <img
          alt={alt}
          height={height}
          src={`/images/logo-proz-erp-${isDark ? 'dark' : 'light'}.svg`}
          width={width}
          {...props}
        />
      </Grid>
      {getRegionByCompanyId({ company: companyId, height })}
    </Grid>
  )
}

export default Logotype

Logotype.propTypes = {
  alt: PropTypes.string,
  height: PropTypes.string,
  isDark: PropTypes.bool,
  width: PropTypes.string
}

Logotype.defaultProps = {
  alt: 'PROZ',
  isDark: true
}
