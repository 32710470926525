import styled from 'styled-components'
import { Typography as MuiTypography } from '@mui/material'

const ContainerError = styled.div`
  margin-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-size: 4.5rem;
    text-align: center;
  }

  span {
    display: block;
    font-size: 1rem;
    text-align: center;
    width: 100%;
  }
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: 400;
    margin-top: 24px;
  }
`

const Link = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: 400;
    color: ${({ theme }) => theme.palette.textColors.primary.lightGray};
    margin-top: 24px;
  }
`

export { ContainerError, Typography, Link }
