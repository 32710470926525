import {
  createSector as createSectorService,
  getListResponsiblesByUnityId as getListResponsiblesByUnityIdService,
  getListSectors as getListSectorsService,
  updateSector as updateSectorService
} from 'services/api/admin'
import { messageErroOrSuccess } from 'services/helpers'

import { cleanObject } from 'services/helpers/cleanObject'
import { formatValuesInitialSectors } from './Sectors/helpers'
import {
  formatValuesToCreateSector,
  formatValuesToResponsible,
  formatValuesToUpdateSector
} from './Sectors/Mutate/helpers'
import { messagesServices } from './Sectors/Mutate/constants'

export const getSectors = async ({
  setSnack,
  setState,
  search,
  rowsPerPage,
  currentPage,
  setStatus
}) => {
  const searchFormatted = cleanObject(search)

  const {
    error,
    status,
    data: { data: sectors, count, limit, ...restProps }
  } = await getListSectorsService({
    ...(searchFormatted?.inactive
      ? { active: !searchFormatted?.inactive }
      : {}),
    unit_id: searchFormatted?.unityId,
    sector: searchFormatted?.sectorName,
    limit: rowsPerPage,
    page: currentPage
  })

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  setState({
    ...restProps,
    count,
    limit,
    sectors: (sectors ?? [])?.map(formatValuesInitialSectors)
  })

  const notFound =
    !(sectors ?? [])?.length &&
    !!searchFormatted?.unityId &&
    !!searchFormatted?.sectorName

  const notFoundByUnity =
    !(sectors ?? [])?.length &&
    !notFound &&
    (!!searchFormatted?.unityId || !!searchFormatted?.sectorName)

  setStatus({
    empty: false,
    notFound,
    notFoundByUnity
  })
}

export const createSector = async ({ navigate, setSnack, values }) => {
  const newValues = formatValuesToCreateSector(values)

  const { error, status } = await createSectorService(newValues)

  messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage: messagesServices.createSector.feedback,
    successMessage: messagesServices.createSector.success,
    isResturnBackPage: true,
    navigate
  })
}

export const getListResponsibleSector = async ({
  setSnack,
  setResponsibles,
  unityId
}) => {
  const params = cleanObject({
    unit_id: unityId
  })
  const { error, status, data } = await getListResponsiblesByUnityIdService(
    params
  )

  messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage: messagesServices.listSectors.feedback,
    successMessage: messagesServices.listSectors.success
  })

  const newData = (data || [])?.map(formatValuesToResponsible)

  setResponsibles(newData)
}

export const updateSector = async ({ id, payload, setSnack, navigate }) => {
  const newPayload = formatValuesToUpdateSector(payload)

  const { error, status } = await updateSectorService(id, newPayload)

  messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage: messagesServices.updateSector.feedback,
    successMessage: messagesServices.updateSector.success,
    isResturnBackPage: true,
    navigate
  })
}
