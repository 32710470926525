import { httpGet, httpGetDownload, httpPost } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

const DECLARATION_TYPE = 'declaracao'

export const getDocumentsStudent = async (params) => {
  const getDocumentsStudents = await httpGet(ENDPOINT.STUDENT_DOCUMENTS, {
    params,
  })

  return getDocumentsStudents
}

export const getDownloadPDFDecreesStudent = async (idDecrees) => {
  const getDownloadPDFDecreesStudents = await httpGetDownload(
    `${ENDPOINT.STUDENT_DOWNLOAD_PDF_DECREES}/${idDecrees}/pdf`,
  )

  return getDownloadPDFDecreesStudents
}

export const getListDeclarationClassesStudent = async (params, id) => {
  const getListDeclarationClassesStudents = await httpGet(
    `${ENDPOINT.STUDENT_LIST_DECLARATION_CLASSES}/${id}`,
    { params },
  )

  return getListDeclarationClassesStudents
}

export const getListTypesDeclarationsRequerimentsStudent = async (params) => {
  const getListTypesDeclarationsRequerimentsStudents = await httpGet(
    ENDPOINT.STUDENT_LIST_TYPES_DECLARATION_REQUIREMENTS,
    { params },
  )

  return getListTypesDeclarationsRequerimentsStudents
}

export const getTypesDecreesRequerimentsStudent = async (params) => {
  const getTypesDecreesRequerimentsStudents = await httpGet(
    ENDPOINT.STUDENT_DECREES_REQUEST,
    { params },
  )

  return getTypesDecreesRequerimentsStudents
}

export const saveNewRequestDocs = async (payload, typeDecree) => {
  const response = await httpPost(
    typeDecree === DECLARATION_TYPE
      ? ENDPOINT.STUDENT_LIST_DECLARATION_CLASSES
      : ENDPOINT.STUDENT_LIST_APPLICATION_CLASSES,
    {
      ...payload,
    },
  )
  return response
}

export const saveNewDocs = async (payload, idUser, userUnit) => {
  const response = await httpPost(
    `${ENDPOINT.STUDENT_UPLOAD_PDF_DOCS}?register=${idUser}&user_unit=${userUnit}`,
    {
      ...payload,
    },
  )
  return response
}
