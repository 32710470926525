import { useState } from 'react'

import * as Styled from './style'
import { defaultIndexStep } from './constants'

const Carousel = ({
  steps = [],
  width,
  height,
  currentStep,
  isHighlight,
  setCurrentStep,
  isHighlightNext
}) => {
  const firstStepIndex = defaultIndexStep
  const lastStepIndex = steps.length - 1
  const isFirstStepIndex = currentStep === firstStepIndex
  const lengthSteps = steps.map((_, index) => index)

  const isAvailableToPrevStep = (newStepIndex) => newStepIndex >= firstStepIndex
  const isAvailableToNextStep = (newStepIndex) => newStepIndex <= lastStepIndex

  const handleStep = (newStepIndex) => {
    if (
      isAvailableToPrevStep(newStepIndex) &&
      isAvailableToNextStep(newStepIndex)
    ) {
      setCurrentStep(newStepIndex)
    }
  }

  const renderText = (text) => text ?? ''

  return (
    <Styled.Container width={width} height={height} isHighlight={isHighlight}>
      {steps.map(({ content }, index) => (
        <Styled.Step shouldShow={currentStep === index}>{content}</Styled.Step>
      ))}

      <Styled.Arrows isFirstStepIndex={isFirstStepIndex}>
        {isAvailableToPrevStep(currentStep - 1) && (
          <Styled.Arrow onClick={() => handleStep(currentStep - 1)}>
            &#8249;
          </Styled.Arrow>
        )}
        {isAvailableToNextStep(currentStep + 1) && (
          <Styled.Tooltip
            open
            arrow
            placement="top"
            title={renderText(isHighlightNext && 'Clique aqui para prosseguir')}
          >
            <Styled.Arrow onClick={() => handleStep(currentStep + 1)}>
              &#8250;
            </Styled.Arrow>
          </Styled.Tooltip>
        )}
      </Styled.Arrows>

      <Styled.Dots>
        {lengthSteps.map((_, index) => (
          <Styled.Dot
            isSelected={currentStep === index}
            onClick={() => handleStep(index)}
          />
        ))}
      </Styled.Dots>
    </Styled.Container>
  )
}

export default Carousel
