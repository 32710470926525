import { useState, lazy, useEffect } from 'react'
import { Form as FormikForm, Formik } from 'formik'
import { ListAlt, Search } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import {
  IsEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE, SUCCESS } from 'services/constants'
import FEEDBACK_SNACK from 'feedBackSnack'
import { useLocation, useNavigate } from 'react-router-dom'
import { getListOccurrence } from 'services/api/admin'
import { useStore } from '../Store/useStore'
import {
  MESSAGE,
  countDefault,
  defaultItemsPerPage,
  nameAccordion,
  occurrenceCreatedSuccess
} from './constants'
import { getParams } from './services'
import { initialValues } from './config'

const Form = lazy(() => import('./components/Form'))
const Table = lazy(() => import('./components/Table'))

const ListOccurrences = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const snackProps = useSnack()
  const { setSnack, snackOpen } = snackProps
  const [loading, setLoading] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [list, setList] = useState([])

  const { formValues, setFormValues, resetFormValues, open, toggleOpen } =
    useStore()

  const { page, totalPage, items, setPage } = usePaginationBasic(
    list,
    filter,
    count
  )

  const isSearchParam = location?.state?.savedSearch || false

  const onSubmit = async (values) => {
    setSnack('', '')
    setList([])

    const valueType = values?.type?.map(({ value }) => value)
    setLoading(true)
    const inactive = values?.inactive

    const { error, status, data } = await getListOccurrence(
      getParams(values, page, filter),
      valueType,
      inactive
    )
    setLoading(false)
    setFormValues(values)

    if (data?.count === countDefault) {
      setFormValues(values)
      setList([])
      return setNotSearch(false)
    }

    if (data?.data) {
      setList(data?.data)
      setCount(data?.count)

      setExpanded(false)
      return setNotSearch(false)
    }

    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListOccurrence
    })
  }
  const cancelAutomaticSearch = () => {
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  useEffect(() => {
    if (formValues !== null) {
      onSubmit(formValues)
    }
  }, [page, filter])

  useEffect(() => {
    if (!isSearchParam) {
      resetFormValues()
      cancelAutomaticSearch()
      return setList([])
    }

    return isSearchParam && onSubmit(formValues)
  }, [isSearchParam])

  useEffect(() => {
    if (open) setSnack(occurrenceCreatedSuccess, SUCCESS)
  }, [open])

  useEffect(() => {
    if (open && !snackOpen) {
      toggleOpen()
    }
  }, [open, snackOpen])

  const onClear = () => {
    setFormValues(null)
    resetFormValues()
    setList([])
    setNotSearch(true)
    cancelAutomaticSearch()
  }

  const messageInformative = notSearch
    ? MESSAGE.MAIN_MESSAGE
    : MESSAGE.MSG_NOT_FOUND_SEARCH

  const initialState = mergeInitialValues(
    initialValues,
    isSearchParam && formValues
  )

  return (
    <TemplateDefaultFormAndResult
      classNameHeader={TAG_MANAGER.btn_secretary_create_occurrence}
      linkHeader={ROUTE.ADMIN_SECRETARY_OCCURRENCES_CREATE}
      titleHeader="Secretaria - Ocorrências"
      labelButtonHeader="Nova Ocorrência"
      snackProps={snackProps}
      nameAccordion={nameAccordion}
      loadingOpen={loading}
      listItems={list}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      expanded={expanded}
      setExpanded={setExpanded}
      accordion={
        <Formik
          initialValues={initialState}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(props) => (
            <FormikForm
              onSubmit={props.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Form
                {...props}
                setFilter={setFilter}
                setPage={setPage}
                setSnack={setSnack}
                onClear={onClear}
              />
            </FormikForm>
          )}
        </Formik>
      }
    >
      {list && !IsEmpty(list) && (
        <Table
          rows={items}
          filter={filter}
          snackProps={snackProps}
          setFilter={setFilter}
          setPage={setPage}
          page={page}
          totalPage={totalPage}
          count={count}
          setSnack={setSnack}
          setLoading={setLoading}
          onSubmit={onSubmit}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ListOccurrences
