import { TableBindByUnbinded } from '../TableBindByUnbinded'

const optionsTabs = ({
  filter,
  itemUnbinded,
  itemBind,
  countUnbinded,
  countBind,
  totalPageUnbinded,
  setPageUnbinded,
  totalPageBind,
  setPageBind,
  pageUnbinded,
  setFilter,
  pageBind,
  classId,
  setSnack,
  setLoadingOpen,
  setListUnbinded,
  setCountUnbinded,
  setCountBind,
  setListBind,
  setTabSelected
}) => {
  const renderTableBindByUnbinded = (rowsItems, unbinded = false) => (
    <TableBindByUnbinded
      unbinded={unbinded}
      filter={filter}
      setListBind={setListBind}
      setFilter={setFilter}
      pageUnbinded={pageUnbinded}
      pageBind={pageBind}
      rowsItems={rowsItems}
      countUnbinded={countUnbinded}
      countBind={countBind}
      totalPageUnbinded={totalPageUnbinded}
      totalPageBind={totalPageBind}
      setPageUnbinded={setPageUnbinded}
      setPageBind={setPageBind}
      classId={classId}
      setSnack={setSnack}
      setLoadingOpen={setLoadingOpen}
      setListUnbinded={setListUnbinded}
      setCountUnbinded={setCountUnbinded}
      setCountBind={setCountBind}
      setTabSelected={setTabSelected}
    />
  )

  const formUnbinded = countUnbinded && {
    label: `Desenturmados (${countUnbinded})`,
    component: () => renderTableBindByUnbinded(itemUnbinded, true)
  }

  const formBind = countBind && {
    label: `Enturmados  (${countBind})`,
    component: () => renderTableBindByUnbinded(itemBind)
  }

  return [formUnbinded, formBind].filter(Boolean)
}

export { optionsTabs }
