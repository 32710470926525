/* eslint-disable camelcase */
import FEEDBACK_SNACK from 'feedBackSnack'
import { getListResponsible, getListSector } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'

const handleListSector = async ({
  setSnack,
  setListSector,
  isActiveSector
}) => {
  setSnack('')

  const params = cleanObject({
    active: isActiveSector
  })

  const { data, error, status } = await getListSector(params)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListTypesRequirements
    })
  }

  const selectSectorList = data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { id, value: name, label: name }
    return [...acc, newItem]
  }, [])

  return setListSector(selectSectorList)
}

const handleListResponsible = async ({
  setListResponsible,
  sectorId,
  setSnack
}) => {
  setSnack('')

  const { data, error, status } = await getListResponsible({
    sector_id: sectorId
  })

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListTypesRequirements
    })
  }

  const selectResponsible = data?.reduce((acc, cur) => {
    const { responsible_name, responsible_sector_user, responsible_id } = cur
    const newItem = {
      id: responsible_id,
      value: responsible_name,
      label: responsible_name,
      responsible_sector_user
    }
    return [...acc, newItem]
  }, [])

  return setListResponsible(selectResponsible)
}

export { handleListSector, handleListResponsible }
