import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { Clear, WarningAmber } from '@mui/icons-material'
import { useMakeService, usePaginationBasic, useSnack } from 'services/hooks'
import { IsEmpty, formatDateEnv, mergeInitialValues } from 'services/helpers'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import { AccordionFilter } from 'components/atoms'
import { ROUTE } from 'services/constants'
import { useNavigate } from 'react-router-dom'
import TAG_MANAGER from 'tagManager'
import { FormListCampaigns } from './components/Forms'
import { TableCampaigns } from './components/Table'
import {
  MESSAGE,
  defaultItemsPerPage,
  initialStateForm,
  nameAccordion
} from './constants'
import { useCampaigns } from './context/FormContext'

import * as Styled from './style'
import { isDisabledButton } from './helpers'
import schema from './schema'
import { handleSearch, inactiveCampaign } from './services'

const Campaigns = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { setSnack } = snackProps
  const [count, setCount] = useState(null)
  const [selected, setSelected] = useState('')
  const [notSearch, setNotSearch] = useState(true)
  const [isUpdate, setIsUpdate] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [expanded, setExpanded] = useState(nameAccordion)
  const { formValues, setFormValues } = useCampaigns()
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [listCampaigns, setListCampaigns] = useState([])
  const {
    page: pageActual,
    totalPage,
    items,
    setPage
  } = usePaginationBasic(listCampaigns, filter, count)

  const paramsMakeService = {
    isUnits: true,
    setSnack
  }

  const { unitys } = useMakeService(paramsMakeService)

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  const initialValues = mergeInitialValues(initialStateForm, formValues)

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      const endDate = formatDateEnv(values.endDate)
      const startDate = formatDateEnv(values.startDate)
      setExpanded('')
      handleSearch({
        values,
        filter,
        endDate,
        setSnack,
        setCount,
        startDate,
        pageActual,
        setNotSearch,
        setListCampaigns,
        setLoadingVisibility
      })
      setFormValues(values)
    },
    validationSchema: schema
  })

  const { values } = formik

  const allUnitsOption = {
    id: null,
    polo: false,
    value: 'Todas as Unidades',
    label: 'Todas as Unidades'
  }

  if (!unitys.some((item) => item.id === allUnitsOption.id)) {
    unitys.unshift(allUnitsOption)
  }

  const handleClear = () => {
    setNotSearch(true)
    setListCampaigns([])
    setFormValues(initialStateForm)
    formik.resetForm(initialValues)
  }

  const handleInactivateCampaign = () => {
    inactiveCampaign({
      setSnack,
      setIsUpdate,
      id: selected,
      setLoadingVisibility
    })
  }

  useEffect(() => {
    if (!notSearch) {
      const endDate = formatDateEnv(values.endDate)
      const startDate = formatDateEnv(values.startDate)
      handleSearch({
        values,
        filter,
        endDate,
        setSnack,
        setCount,
        startDate,
        pageActual,
        setNotSearch,
        setListCampaigns,
        setLoadingVisibility
      })
    }
  }, [isUpdate, pageActual, filter])

  return (
    <>
      <TemplateDefaultFormAndResult
        snackProps={snackProps}
        listItems={listCampaigns}
        labelButtonHeader="nova campanha"
        loadingVisibility={loadingVisibility}
        iconInformative={messageInformative.icon}
        titleHeader="<b>Financeiro - </b>  Campanha"
        messageInformative={messageInformative.text}
        onClickButton={() => {
          navigate(ROUTE.ADMIN_FINANCIAL_CREATE_CAMPAIGN)
        }}
        classNameHeader={TAG_MANAGER.financeiro_btn_acessa_NovaCampanha}
        formHeader={
          <AccordionFilter
            title="Selecione e preencha os campos para realizar uma busca"
            expanded={expanded}
            setExpanded={setExpanded}
            nameAccordion={nameAccordion}
            margin
          >
            <Styled.Form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
                <FormListCampaigns
                  unitys={unitys}
                  values={formik.values}
                  setFieldValue={formik.setFieldValue}
                />
                <Styled.Grid item xs={12} sm={12} md={3}>
                  <ButtonFooterV2
                    size="medium"
                    labelClose="Limpar"
                    labelConfirm="Buscar"
                    startIcon={<Clear />}
                    onClickClose={handleClear}
                    disabledClose={isDisabledButton(formik.values)}
                    className={TAG_MANAGER.financeiro_btn_busca_Campanhas}
                  />
                </Styled.Grid>
              </Styled.GridContainer>
            </Styled.Form>
          </AccordionFilter>
        }
      />

      {listCampaigns && !IsEmpty(listCampaigns) && (
        <TableCampaigns
          items={items}
          count={count}
          filter={filter}
          setPage={setPage}
          setFilter={setFilter}
          totalPage={totalPage}
          pageActual={pageActual}
          setSelected={setSelected}
          setOpenModal={setOpenModal}
        />
      )}
      <ModalConfirm
        open={openModal}
        minWidth="450px"
        textButtonConfirm="Sim"
        title="Inativar Campanha"
        textButtonNotConfirm="Não"
        colorButtonConfirm="error"
        message={MESSAGE.modal_message}
        onClickConfirm={handleInactivateCampaign}
        onClickNotConfirm={() => setOpenModal(!openModal)}
        icon={<WarningAmber color="warning" fontSize="large" />}
        classNameBtnConfirm={
          TAG_MANAGER.financeiro_btn_confirma_InativaCampanha
        }
      />
    </>
  )
}

export default Campaigns
