import { Form as FormikForm, Formik } from 'formik'
import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'

import { ButtonFooterV2, TextField } from 'components/molecules'

import * as Styled from './style'

const FormSearch = ({ onSubmit }) => {
  const disabledButton = ({ searchBy }) => !searchBy

  return (
    <Formik
      initialValues={{ searchBy: '' }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, handleSubmit, handleChange, resetForm, submitForm }) => (
        <FormikForm onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid
            container
            xs={12}
            alignItems="center"
            justifyContent="space-around"
          >
            <Styled.Box item xs={9.5}>
              <TextField
                isMargin="0"
                fullWidth
                id="searchBy"
                label="Digite o nome de uma Disciplina Equivalente para pesquisar"
                value={values.searchBy}
                onChange={handleChange}
              />
            </Styled.Box>

            <Styled.Box item xs={2.5}>
              <ButtonFooterV2
                startIcon={<Clear />}
                labelClose="Limpar"
                labelConfirm="Buscar"
                size="medium"
                typeReset="reset"
                disabled={disabledButton(values)}
                onClickClose={() => resetForm()}
                onClickConfirm={() => submitForm()}
              />
            </Styled.Box>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  )
}

export default FormSearch
