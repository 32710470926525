import * as yup from 'yup'

const schema = yup.object().shape({
  lesson_date: yup
    .date()
    .required('Data é obrigatório')
    .typeError('Data em formato inválido'),
  hourly_lesson_cost: yup.string().required('Campo é obrigado'),
  lesson_hour: yup.string().required('Campo é obrigado'),
  professor_id: yup.string().required('Campo é obrigado'),
  activity: yup.string().required('Campo é obrigado')
})

export default schema
