import { Box, Grid, Typography } from '@mui/material'
import { Formik, Form } from 'formik'

import Button from 'components/atoms/Button'
import TextField from 'components/molecules/TextField'
import useLogin from 'services/hooks/useLogin'
import schema from './schema'
import { PINCEL_TYPE } from './constants'

const initialState = {
  username: '',
  password: ''
}

const FormLogin = ({ isProduction }) => {
  const { handleLogin, isLoading, serverMessage, hasServerMessage } = useLogin()

  const handleAuth = (values) => {
    const userData = {
      username: values?.username,
      password: values?.password,
      type: PINCEL_TYPE,
      path: window.location.pathname
    }
    if (userData) {
      handleLogin(userData)
    }
  }

  return (
    <Formik
      initialValues={initialState}
      validationSchema={schema}
      onSubmit={handleAuth}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Box sx={{ maxWidth: '400px' }}>
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                autoComplete="on"
                name="username"
                label="Matrícula"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors && touched.username && errors.username}
                error={
                  (touched.username && errors.username) || hasServerMessage
                }
                isMargin="16px 0"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                autoComplete="on"
                name="password"
                label="Senha"
                type="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  (errors && touched.password && errors.password) ||
                  serverMessage
                }
                error={
                  (touched.password && errors.password) || hasServerMessage
                }
                isMargin="0 0 16px"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color={isProduction ? 'primary' : 'secondary'}
                fullWidth
                disabled={Boolean(isLoading)}
                loading={isLoading}
                loadingPosition="end"
                size="large"
                type="submit"
              >
                <Typography variant="body" color="white">
                  {isProduction ? 'Acessar' : 'acessar ambiente de teste'}
                </Typography>
              </Button>
            </Grid>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

export default FormLogin
