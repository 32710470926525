import { useEffect } from 'react'
import * as Styled from './style'

const JoyClassRedirection = () => {
  const { joy_class_url: joyClassUrl } = JSON.parse(
    localStorage.getItem('PROZ.user.schoolClasses')
  )

  useEffect(() => {
    window.location.replace(joyClassUrl ?? 'https://proz.joyclass.com/login')
  }, [])

  return (
    <>
      <Styled.Link href={joyClassUrl} target="_blank">
        {' '}
      </Styled.Link>
    </>
  )
}

export default JoyClassRedirection
