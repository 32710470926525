import { ROUTE } from 'services/constants'

const activeText = 'Ativa'
const inactiveText = 'Inativo'
const doneText = 'Concluída'

const defaultItemsPerPage = 10
const defaultPage = 1
const defaultTotalPage = 10

const breadCrumbsItems = [
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS,
    label: 'Turmas'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS,
    label: 'Detalhes da Turma'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_REENROLL,
    label: '<b>Rematrícula</b>'
  }
]

const MESSAGE = {
  SUCCESS_INACTIVE: 'Rematrícula inativada com sucesso.',
  SUCCESS_PDF: 'PDF de Rematrícula baixado com sucesso.',
  SUCCESS: 'Rematrícula salva com sucesso!',
  SUCCESS_ACTIVE_TEXT: 'Texto de rematrícula salvo com sucesso!',
  ERROR_EDIT: 'Houve um erro ao editar rematrícula. Tente novamente.',
  ERROR_CREATE: 'Houve um erro ao criar rematrícula. Tente novamente.'
}

const payloadEditText = (values) => ({
  text: values?.text
})

const payloadCreateSubmit = (values) => ({
  begin_date: values?.beginDate,
  end_date: values?.endDate
})

export {
  breadCrumbsItems,
  defaultItemsPerPage,
  defaultPage,
  defaultTotalPage,
  MESSAGE,
  payloadEditText,
  payloadCreateSubmit,
  activeText,
  inactiveText,
  doneText
}
