import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { getRgba } from 'services/helpers/polished'
import { Button } from 'components/atoms'

const LinkCallToAction = styled(Link)`
  text-decoration: none;
`

const CallToAction = styled(Button)`
  &.MuiButton-root {
    margin: 0;
    width: ${({ $width }) => $width || 'auto'};

    &.goBack {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.primary.main};
      box-shadow: none;

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.white};
        box-shadow: 0px 2px 4px -1px ${({ theme }) => getRgba(theme.palette.dark.main, 0.2)},
          0px 4px 5px 0px
            ${({ theme }) => getRgba(theme.palette.dark.main, 0.14)},
          0px 1px 10px 0px
            ${({ theme }) => getRgba(theme.palette.dark.main, 0.12)};
      }
    }

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }
`

export { CallToAction, LinkCallToAction }
