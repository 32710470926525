import { Paper, Table, TableBody, Tooltip } from '@mui/material'

import { useSortTable } from 'services/hooks'
import { defaultlistPerPage } from 'services/hooks/constants'
import { dateFormat, paginationFilter } from 'services/helpers'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'

import { KEY_COLUMNS, tableColumns } from './constants'

import * as Styled from './style'

const List = ({
  limit,
  page,
  items,
  count,
  totalPage,
  handleSetPage,
  handleChange,
  isLastPageAvaliation,
  handleViewPdf,
  handleRate
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    items,
    KEY_COLUMNS.ID
  )

  const workloadTotal = sortTable.reduce(
    (prev, current) => prev + current.workload,
    0
  )

  const maxWorkloadTotal = sortTable.reduce(
    (prev, current) => prev + (current.maxWorkload ?? 0),
    0
  )

  return (
    <Styled.Wrapper>
      <Paper>
        <Table size="medium">
          <TableHeaderDefault
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableColumns({ isLastPageAvaliation })}
          />
          <TableBody>
            {sortTable?.map((item) => (
              <Styled.TableRow key={item?.id}>
                {!isLastPageAvaliation && (
                  <Styled.Column padding="checkbox" style={{ width: '20%' }}>
                    {item?.id}
                  </Styled.Column>
                )}
                <Styled.Column padding="checkbox" style={{ width: '25%' }}>
                  <Styled.ColumnActivity>
                    <>{item?.complementaryActivityType}</>

                    <Styled.Actions className="actions">
                      <Tooltip title="Visualizar documento">
                        <Styled.PictureAsPdf
                          onClick={() => handleViewPdf(item)}
                        />
                      </Tooltip>

                      {isLastPageAvaliation && (
                        <Tooltip title="Avaliar">
                          <Styled.BookmarkAdd
                            onClick={() => handleRate(item)}
                          />
                        </Tooltip>
                      )}
                    </Styled.Actions>
                  </Styled.ColumnActivity>
                </Styled.Column>
                {!isLastPageAvaliation && (
                  <Styled.Column style={{ width: '20%' }}>
                    {item?.lastUpdate
                      ? dateFormat(item?.lastUpdate, 'DD/MM/YYYY HH:MM')
                      : '-'}
                  </Styled.Column>
                )}
                {isLastPageAvaliation && (
                  <Styled.Column style={{ width: '20%' }}>
                    {item?.observation ?? '-'}
                  </Styled.Column>
                )}
                {!isLastPageAvaliation && (
                  <Styled.Column style={{ width: '15%' }}>
                    {item?.responsible}
                  </Styled.Column>
                )}
                <Styled.Column style={{ width: '15%' }}>
                  {item?.workload}{' '}
                  {isLastPageAvaliation ? `/ ${item?.maxWorkload ?? 0}` : ''}
                </Styled.Column>
                <Styled.Column style={{ width: '10%' }}>
                  <Styled.Status status={item?.progress}>
                    {item?.progress}
                  </Styled.Status>
                </Styled.Column>
              </Styled.TableRow>
            ))}

            <Styled.TableRow>
              {!isLastPageAvaliation && (
                <Styled.Column padding="checkbox" style={{ width: '20%' }} />
              )}
              {!isLastPageAvaliation && (
                <Styled.Column style={{ width: '25%' }} />
              )}
              <Styled.Column style={{ width: '20%' }} />
              <Styled.ColumnTotalWorkLoad>
                Carga horária total
              </Styled.ColumnTotalWorkLoad>
              <Styled.ColumnTotalWorkLoad>
                {workloadTotal}{' '}
                {isLastPageAvaliation ? `/ ${maxWorkloadTotal ?? 0}` : ''}
              </Styled.ColumnTotalWorkLoad>
              <Styled.Column style={{ width: '10%' }} />
            </Styled.TableRow>
          </TableBody>
        </Table>

        {count > defaultlistPerPage && (
          <PaginationAdvanced
            isSelectItensPerPage
            handleChange={handleChange}
            handleSetPage={handleSetPage}
            count={totalPage}
            optionsList={paginationFilter}
            page={page}
            smallSize
            value={limit}
          />
        )}
      </Paper>
    </Styled.Wrapper>
  )
}

export default List
