const MESSAGE = {
  MAIN_MESSAGE:
    '<b>Encontre os planos de pagamento disponíveis aqui</b><br/>Você precisa selecionar ao menos a unidade para buscar e pode utilizar outros critérios para filtrar os resultados.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  newPaymentPlanCreated: 'Novo plano de pagamento criado',
  deletionDone: 'Exclusão realizada',
  updatedValues: 'Valores atualizados',
  SUCCESS: 'Pagamento salvo'
}

const typeAction = {
  remove: 'remove',
  copy: 'copy',
  changeOfValues: 'changeOfValues',
  planAlreadyExists: 'planAlreadyExists',
  change: 'change'
}

const headCells = [
  { id: 'shift', label: 'Turno' },
  { id: 'modality', label: 'Modalidade' },
  { id: 'course', label: 'Curso' },
  { id: 'group', label: 'Grupo' },
  { id: 'type_of_payment', label: 'Tipo de pagamento' },
  { id: 'registration_tax', label: 'Matrícula' },
  { id: 'installment_amount', label: 'Mensalidade' },
  { id: 'max_installment', label: 'Parcelas' },
  { id: 'cash_discount', label: 'Desc.' }
]

const idNameList = 'id'
const nameAccordion = 'paymentPlans'
const defaultItemsPerPage = 10
const defaultPage = 1
const itemSelected = 1
const countDefault = 0

export {
  MESSAGE,
  typeAction,
  nameAccordion,
  defaultItemsPerPage,
  defaultPage,
  itemSelected,
  headCells,
  idNameList,
  countDefault
}
