import { Popover } from 'components/atoms'
import * as Styled from './style'

const DropDown = (props) => {
  const { menuItems } = props

  return (
    <Popover {...props}>
      {menuItems?.map(
        (item) =>
          !item?.isHide && (
            <Styled.MenuItem
              key={item.label}
              onClick={() => item.onClick()}
              disableRipple
              className={item.className}
              disabled={item.disabled}
            >
              {item.icon}
              {item.label}
            </Styled.MenuItem>
          )
      )}
    </Popover>
  )
}

export default DropDown
