import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material'
import { ReportProblemOutlined } from '@mui/icons-material'
import { ExpandMoreIcon } from 'components/atoms/AccordionFilter/style'
import { useState } from 'react'
import { useUserStore } from 'store/GlobalContext'
import { COMPANY } from 'services/constants/company'
import Form from '../Form'
import { importsList } from './config'
import * as Styled from './style'

const List = ({ setSnack, setLoadingOpen }) => {
  const [expanded, setExpanded] = useState('')
  const { company_id: companyId } = useUserStore()

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  const showNovotecLabel = companyId === COMPANY.SP.id

  return (
    <div>
      <Styled.SubTitle variant="subtitle2" component="div">
        <ReportProblemOutlined />
        Atenção: antes de enviar o arquivo, recomenda-se testá-lo no ambiente de
        homologação, pois essa ação não poderá ser desfeita.
      </Styled.SubTitle>
      {importsList({ showNovotecLabel }).map((item) => (
        <Accordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${item.id}-content`}
            id={`${item.id}-header`}
          >
            <Typography>{item.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Form
              setSnack={setSnack}
              setLoadingOpen={setLoadingOpen}
              importConfig={item}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}

export default List
