import { httpGet } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getActivitiesList = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_ADDITIONAL_ACTIVITIES.SEARCH}?${convertToParams(
      params
    )}`
  )
  return response
}
