import { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Box, Grid, Typography } from '@mui/material'
import { Clear, ListAlt } from '@mui/icons-material'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import { TextNotFound, DatePicker } from 'components/atoms'
import { addMonthsToDate, getDateStartOfMonth, isEmpty } from 'services/helpers'
import * as Styled from './style'
import Table from './Table'
import { getProfessorTimekeeping } from './services'
import { situationOptions, MESSAGE, initialTimekeeping } from './constants'

const FormTimekeeping = ({
  formTimekeeping,
  setLoadingOpen,
  idProfessor,
  listTimekeeping,
  setListTimekeeping
}) => {
  useEffect(async () => {
    getProfessorTimekeeping({
      values: { ...formTimekeeping, professor_id: idProfessor },
      setLoadingOpen,
      setListTimekeeping
    })
  }, [])

  // const onDownload = () => {}

  return (
    <>
      <Formik
        initialValues={formTimekeeping}
        onSubmit={(values) =>
          getProfessorTimekeeping({
            values: { ...values, professor_id: idProfessor },
            setLoadingOpen,
            setListTimekeeping
          })
        }
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, touched, errors, dirty }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12} sm={12} md={2}>
                <DatePicker
                  viewValue={['month', 'year']}
                  fullWidth
                  id="competence"
                  value={values.competence || null}
                  label="Competência"
                  onChange={(newValue) => {
                    setFieldValue('competence', newValue)
                  }}
                  maxDate={addMonthsToDate(1, getDateStartOfMonth())}
                  helperText={errors && touched.competence && errors.competence}
                  error={Boolean(touched.competence && errors.competence)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Select
                  id="situation"
                  value={values?.status}
                  label="Situação"
                  optionsList={situationOptions}
                  onChange={(e) => setFieldValue('status', e.target.value)}
                  helperText={errors && touched.status && errors.status}
                  error={Boolean(touched.status && errors.status)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  fullWidth
                  id="school_class_code"
                  name="school_class_code"
                  label="Turma"
                  value={values?.school_class_code}
                  onChange={(e) =>
                    setFieldValue('school_class_code', e.target.value)
                  }
                  helperText={
                    errors &&
                    touched.school_class_code &&
                    errors.school_class_code
                  }
                  error={Boolean(
                    touched.school_class_code && errors.school_class_code
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3} sx={{ mt: 1 }}>
                <ButtonFooterV2
                  startIcon={<Clear />}
                  labelClose="Limpar"
                  labelConfirm="Buscar"
                  size="medium"
                  typeReset="reset"
                  onClickClose={() => setListTimekeeping([])}
                  disabledClose={!dirty}
                />
              </Grid>
            </Styled.Grid>
          </Form>
        )}
      </Formik>

      {!isEmpty(listTimekeeping) ? (
        <>
          {/* <Box sx={{ display: 'flex', pt: 2 }}>
            <Styled.BoxDownload onClick={onDownload}>
              <Styled.PictureAsPdf />
              <Typography
                sx={{ fontSize: 11, px: 0.5, pt: 0.5 }}
                variant="button"
                display="block"
                gutterBottom
              >
                baixar apontamento
              </Typography>
            </Styled.BoxDownload>
          </Box> */}
          <Table rows={listTimekeeping} />
        </>
      ) : (
        <TextNotFound icon={<ListAlt />} text={MESSAGE.NOT_FOUND_TIMEKEEPING} />
      )}
    </>
  )
}

export { FormTimekeeping }
