import { lazy, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useSnack } from 'services/hooks'
import { ButtonFooterV2 } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import {
  breadcrumbsItems,
  initialState,
  messageModalConfirm
} from './constants'
import * as Styled from './styles'
import schema from './schema'
import {
  createExpedition,
  editExpedition,
  getCourseList,
  handleSearch
} from './services'

const Form = lazy(() => import('./components/Form'))

const CreateExpedition = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])
  const { studentName, diplomaId, register, isEdit } = params
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [courseList, setCourseList] = useState([])

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(-1)
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: (values) => {
      if (isEdit === 'true') {
        editExpedition({
          values,
          register,
          setSnack,
          navigate,
          diplomaId,
          setLoading
        })
      } else {
        createExpedition({
          values,
          register,
          setSnack,
          navigate,
          setLoading
        })
      }
    },
    validationSchema: schema
  })

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    formik

  useEffect(() => {
    if (isEdit === 'true')
      handleSearch({
        setSnack,
        diplomaId,
        setLoading,
        setFieldValue
      })

    getCourseList({
      setSnack,
      register,
      setCourseList
    })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      open={openModal}
      loadingOpen={loading}
      classNameHeader="goBack"
      snackProps={snackProps}
      setLoading={setLoading}
      onClickButton={() => setOpenModal(true)}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      titleModalConfirm={messageModalConfirm.title}
      breadcrumbsNotLink={breadcrumbsItems({ studentName, isEdit })}
      messageModalConfirm={messageModalConfirm.message}
      content={
        <>
          <Styled.Form autoComplete="off" onSubmit={handleSubmit}>
            <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
              <Form
                values={values}
                errors={errors}
                touched={touched}
                courseList={courseList}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
              />
              <Styled.Grid item xs={12} sm={12} md={12}>
                <ButtonFooterV2
                  size="medium"
                  labelClose="Cancelar"
                  labelConfirm="Salvar"
                  onClickClose={() => setOpenModal(true)}
                  classNameCancel={
                    TAG_MANAGER.secretaria_btn_cancelar_registroExpedicao
                  }
                  classNameConfirm={
                    TAG_MANAGER.secretaria_btn_salvar_registroExpedicao
                  }
                />
              </Styled.Grid>
            </Styled.GridContainer>
          </Styled.Form>
        </>
      }
    />
  )
}

export default CreateExpedition
