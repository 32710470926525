import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListAlt, Search } from '@mui/icons-material'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  IsEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import { getGroups } from 'services/api/admin'
import { ROUTE } from 'services/constants'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import {
  defaultItemsPerPage,
  countDefault,
  MESSAGE,
  initialState
} from './constants'
import { useGroups } from './context/FormContext'
import FormSearchGroups from './components/FormSearchGroups'
import Table from './components/Table'

const ListGroup = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const snackProps = useSnack()
  const { setSnack, snackOpen } = snackProps
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [groups, setGroups] = useState([])
  const [notSearch, setNotSearch] = useState(true)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(null)
  const [valuesForm, setvaluesForm] = useState(null)

  const isSearchParam = location?.state?.savedSearch || false
  const isParamSavedGroup = location?.state?.savedGroup || false
  const isParamUpdateGroup = location?.state?.updateGroup || false
  const paramSnackOpen = isParamSavedGroup || isParamUpdateGroup

  const { formValues, setFormValues } = useGroups()

  const {
    page: pageActual,
    totalPage,
    items,
    setPage,
    handleSetPage
  } = usePaginationBasic(groups, filter, count)

  const onSubmit = async (values) => {
    setSnack('')
    setLoadingOpen(true)

    const params = {
      inactive: values?.active,
      limit: filter,
      page: pageActual
    }

    const { data: response, error, status } = await getGroups(params, values)

    setLoadingOpen(false)
    setNotSearch(false)
    setCount(response?.count)
    setFormValues(values)
    setvaluesForm(values)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGroup
      })
    }

    if (response?.count === countDefault) {
      setGroups([])
      return setNotSearch(false)
    }

    return setGroups(response?.data)
  }

  const handleClear = () => {
    setFormValues(initialState)
    setGroups([])
    setNotSearch(true)
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  useEffect(() => {
    if (valuesForm?.unit || valuesForm?.name) {
      onSubmit(valuesForm)
    }
  }, [pageActual, filter])

  useEffect(() => {
    if (isSearchParam && (formValues?.name || formValues?.unit)) {
      return onSubmit(formValues)
    }

    return !isSearchParam && setFormValues(initialState) && setGroups([])
  }, [isSearchParam])

  useEffect(() => {
    if (paramSnackOpen)
      setSnack(isParamUpdateGroup ? MESSAGE.updateGroup : MESSAGE.savedGroup)
  }, [paramSnackOpen])

  useEffect(() => {
    if (paramSnackOpen && !snackOpen) {
      navigate(location?.pathname, {
        state: {
          updateGroup: undefined,
          savedGroup: undefined,
          savedSearch: true
        },
        replace: true
      })
    }
  }, [paramSnackOpen, snackOpen])

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  return (
    <TemplateDefaultFormAndResult
      titleHeader="Financeiro - Grupos"
      labelButtonHeader="novo grupo"
      snackProps={snackProps}
      linkHeader={ROUTE.ADMIN_FINANCIAL_GROUPS_CREATE}
      loadingOpen={loadingOpen}
      listItems={groups}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={
        notSearch ? MESSAGE.NO_SEARCH_GROUPS : MESSAGE.NOT_FOUND_SEARCH
      }
      formHeader={
        <FormSearchGroups
          handleClear={handleClear}
          initialValues={initialValues}
          onSubmit={onSubmit}
          setSnack={setSnack}
          setPage={setPage}
          setFilter={setFilter}
        />
      }
    >
      {groups && !IsEmpty(groups) && (
        <Table
          itemsTable={items}
          defaultValue={defaultItemsPerPage}
          totalPage={totalPage}
          pageActual={pageActual}
          filter={filter}
          setFilter={setFilter}
          setPage={setPage}
          count={count}
          setLoadingOpen={setLoadingOpen}
          setSnack={setSnack}
          onSubmit={onSubmit}
          handleSetPage={handleSetPage}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ListGroup
