import { useSearchParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { ButtonFooterV2, TextField } from 'components/molecules'
import {
  convertToParams,
  formatDate,
  makeURLAccessLegacy
} from 'services/helpers'
import { DatePicker } from 'components/atoms'
import * as Styled from './style'
import { validationSchema } from './schema'

const ModalCards = ({ setOpenModalCards, openModalCards }) => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id } = params
  const initalValues = { title: '', expirationDate: '' }

  const handleModalSubmit = (values) => {
    makeURLAccessLegacy(
      `acesso/turma_lista_alunos_carteirinha.php?${convertToParams({
        tituloC: values.title,
        modeloL: 1,
        id_turma: id,
        validadeC: formatDate(values.expirationDate)
      })}`,
      true
    )
    return setOpenModalCards(false)
  }

  return (
    <Styled.Modal
      open={openModalCards}
      onClose={() => setOpenModalCards(false)}
      maxWidth="sm"
    >
      <Formik
        initialValues={initalValues}
        onSubmit={handleModalSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, handleSubmit, touched, errors, dirty, setFieldValue }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <Styled.Title variant="h5" component="h2">
                    Carteira Estudantil
                    <Styled.Close onClick={() => setOpenModalCards(false)} />
                  </Styled.Title>
                </Grid>
              </Grid>

              <Styled.GridForm container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    label="Título da Carteirinha"
                    id="outlined-title"
                    value={values.title}
                    helperText={touched.title && errors.title}
                    error={touched.title && Boolean(errors.title)}
                    onChange={(e) => setFieldValue('title', e.target.value)}
                  />

                  <DatePicker
                    fullWidth
                    label="Validade"
                    id="outlined-expirationDate"
                    value={values?.expirationDate || ''}
                    helperText={
                      touched?.expirationDate && errors?.expirationDate
                    }
                    error={
                      touched?.expirationDate && Boolean(errors?.expirationDate)
                    }
                    onChange={(newValue) =>
                      setFieldValue('expirationDate', newValue)
                    }
                  />
                  {/* 
                  <TextField
                    fullWidth
                    label="Validade"
                    id="outlined-expirationDate"
                    value={values.expirationDate}
                    helperText={touched.expirationDate && errors.expirationDate}
                    error={
                      touched.expirationDate && Boolean(errors.expirationDate)
                    }
                    onChange={(e) =>
                      setFieldValue('expirationDate', e.target.value)
                    }
                  /> */}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Styled.Box>
                    <ButtonFooterV2
                      labelClose="Cancelar"
                      labelConfirm="Visualizar carteirinha"
                      size="medium"
                      disabled={!dirty}
                      onClickClose={() => () => setOpenModalCards(false)}
                    />
                  </Styled.Box>
                </Grid>
              </Styled.GridForm>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export { ModalCards }
