import { utilsPDF } from '../../utils'

const { noBorderOnTop, borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export default function buildCompanyInfo(companyInfo = []) {
  if (!companyInfo || !companyInfo?.length) return []
  return [
    {
      table: {
        body: [
          [
            {
              stack: [
                companyInfo.map((info) => ({
                  text: info
                }))
              ],
              border: noBorderOnTop,
              alignment: 'center'
            }
          ]
        ],
        widths: '*'
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    }
  ]
}
