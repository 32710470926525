export default function buildFooter(currentPage, pageCount) {
  return {
    table: {
      body: [
        [
          {
            text: `${currentPage.toString()}/${pageCount}`,
            alignment: 'right'
          }
        ]
      ],
      widths: '*'
    },
    layout: { defaultBorder: false },
    margin: [10, 10, 10, 0]
  }
}
