import styled from 'styled-components'
import { Grid as MuiGrid, Paper as MuiPaper } from '@mui/material'

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
    padding: 30px;
  }
`

const Grid = styled(MuiGrid)`
  &.MuiGrid-container {
    margin-top: 25px;
  }
`

const GridButton = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

export { Grid, Paper, GridButton }
