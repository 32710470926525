const nameAccordion = 'schoolClass'
const openAccordion = 'openAccordion'
const defaultItemsPerPage = 6
const initUnit = 0
const countDefault = 0
const defaultPage = 1
const defaultItensPerPage = 10
const messageSuccessDownload = 'Download realizado com sucesso!'

const MESSAGE = {
  NO_SEARCH:
    '<b>Você ainda não fez uma busca</b><br/>Selecione um período e você pode combinar com os filtros para<br/>obter uma pesquisa mais específica por turmas.',
  MSG_NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/> Verifique os dados informados e tente novamente.',
  SUCCESS_CREATE_CLASS: 'Turma criada com sucesso!',
  SUCCESS_DELETE_CLASS: 'Turma excluída com sucesso!',
  SUCCESS_SEND_JOY: 'Enviado para Joy com sucesso!',
  SUCCESS_DOWNLOAD_REPORT: 'Relatório baixado com sucesso!',
  SUCCESS_SEND_EMAIL: 'E-mail enviado com sucesso!'
}

const typeAction = {
  remove: 'remove',
  copy: 'copy',
  changeOfValues: 'changeOfValues',
  planAlreadyExists: 'planAlreadyExists',
  change: 'change'
}

const payloadDelete = ({ selectedIds }) => ({
  ids: selectedIds
})

export {
  MESSAGE,
  defaultItensPerPage,
  initUnit,
  openAccordion,
  typeAction,
  defaultItemsPerPage,
  countDefault,
  defaultPage,
  payloadDelete,
  nameAccordion,
  messageSuccessDownload
}
