import styled from 'styled-components'

const WrapperButtons = styled.div`
  align-items: start;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(0.96)};

  @media screen and (min-width: 899px) and (max-width: 1177px) {
    flex-direction: column-reverse;
    margin-top: 0;
  }

  @media screen and (max-width: 397px) {
    flex-direction: column;
  }
`

export { WrapperButtons }
