/* eslint-disable no-useless-escape */
import * as yup from 'yup'

const schema = (typePage) =>
  yup.object().shape({
    ...(typePage.pole && {
      namePole: yup.string().required('Nome do polo é obrigatório')
    }),
    ...(typePage.unit && {
      unit: yup.string().required('Nome da unidade é obrigatório')
    }),
    socialReason: yup.string().required('Razão social é obrigatório'),
    academicName: yup.string().required('Nome acadêmico é obrigatório'),
    address: yup.string().required('Endereço é obrigatório'),
    numberAddress: yup.string().required('Numero do endereço é obrigatório'),
    city: yup.string().required('Cidade é obrigatório'),
    neighborhood: yup.string().required('Bairro é obrigatório'),
    state: yup.string().required('Estado é obrigatório')
  })

export default schema
