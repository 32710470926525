import styled from 'styled-components'
import { Chip, Typography, Paper as MuiPaper } from '@mui/material'
import { getStatusColor } from './helpers'

const ColorInformation = styled(Chip)`
  &.MuiChip-root {
    max-width: 34px;
    max-height: 15px;
    border-radius: 0;
    background-color: ${({ $isColor }) => getStatusColor($isColor)};
  }
`

const Caption = styled.div`
  display: flex;
  align-items: center;
`
const CaptionTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 10px;
    font-size: 14px;
  }
`

const Footer = styled.div`
  display: grid;
  grid-template-columns: 90px 90px;
  grid-gap: 24px;
  margin: 24px 0;
`

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
    margin-top: 24px;
  }
`

export { Paper, ColorInformation, Caption, CaptionTitle, Footer }
