import {
  typeEndpointPole,
  parseResponse
} from 'components/pages/Admin/Secretary/SchoolClass/ListSchoolClass/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getCourses, listClassLinked, listCodeClass } from 'services/api/admin'
import { getUnitsRegions } from 'services/api/shared'
import { getDataFormKeys, updateErrorMessage } from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'
import { defaultItemsPerPage, defaultPage } from './constants'

export const getListCourses = async ({ values, setSnack, setListCourses }) => {
  const params = cleanObject({
    ...(values.level && { level: values.level }),
    unit_id: values?.unity
  })

  const { data, error, status } = await getCourses(params)
  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToForm
    })
  }

  return setListCourses(parseResponse(data?.data, true))
}

export const onUnitsRegions = async ({ setSnack, setListPole, values }) => {
  const isName = true

  const { data, error, status } = await getUnitsRegions(
    typeEndpointPole(values.unity)
  )

  const dataPole = getDataFormKeys([0, 'units'], data?.data)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToRedirectDashboard
    })
  }

  return setListPole(parseResponse(dataPole, isName))
}

export const handleSearch = async ({
  filter,
  unitys,
  values,
  setCount,
  setSnack,
  pageActual,
  setNotSearch,
  setListClass,
  setLoadingOpen
}) => {
  setLoadingOpen(true)
  const unity = unitys.find(({ id }) => id === values?.unity)

  const poles = values?.pole ? [values?.pole] : null

  const params = cleanObject({
    unit_name: unity?.label,
    class_code: values?.class,
    level_name: values?.level,
    course_name: values?.course,
    limit: filter || defaultItemsPerPage,
    page: pageActual || defaultPage,
    'pole_name[]': poles
  })

  const { data, error, status } = await listClassLinked(params)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListClassLinked
    })
  }
  setCount(data?.count)
  setNotSearch(data?.count)
  return setListClass(data?.data)
}

export const listOptionsClassCode = async ({
  values,
  unitys,
  setSnack,
  setLoadingOpen,
  setOptionsCodeClass
}) => {
  setLoadingOpen(true)

  const unity = unitys.find(({ id }) => id === values.unity)

  const params = cleanObject({
    unit: unity?.label,
    level: values?.level,
    course: values?.course,
    pole: values?.pole
  })

  const { data, error, status } = await listCodeClass(params)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListClassLinked
    })
  }

  const transformList = data.map((item) => ({
    label: item.school_class_code,
    value: item.school_class_code
  }))

  return setOptionsCodeClass(transformList)
}
