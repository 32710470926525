const MESSAGE = {
  NO_SEARCH:
    '<b>Encontre alunos registrados/duplicados no sistema</b><br/> Selecione um critério e realize uma busca para encontrar uma matrícula e realizar a unificação',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.Verifique os dados informados e tente novamente.',
  OBS: 'O resultado está limitado em 1000 registros, por questão de performance do sistema.'
}

const initialState = {
  start_date: null,
  end_date: null,
  search_2: '',
  search_1: '',
  search_type: 'pages',
  user: ''
}

const optionsList = [
  { value: 'pages', label: 'Páginas' },
  { value: 'operations', label: 'Operações' }
]

const selectTypeSearch = {
  pages: 'pages',
  operations: 'operations'
}

const LIMIT = 1
const DEFAULT_ITEMS_PER_PAGE = 10

export {
  MESSAGE,
  initialState,
  optionsList,
  LIMIT,
  DEFAULT_ITEMS_PER_PAGE,
  selectTypeSearch
}
