import MenuBookIcon from '@mui/icons-material/MenuBook'
import ArchiveIcon from '@mui/icons-material/Archive'
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined'
import CampaignIcon from '@mui/icons-material/Campaign'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import TAG_MANAGER from 'tagManager'
import { ROUTE } from 'services/constants'
import { convertToParams } from 'services/helpers'

const getMenuItems = ({
  item,
  isActive,
  handleSendInteractions,
  handleDisciplinesClasses,
  handleModalConfirm,
  handleClose,
  navigate
}) => [
  {
    label: 'Arquivo de Documentos',
    icon: <ArchiveIcon />,
    className: TAG_MANAGER.human_resources_btn_open_archive,
    onClick() {
      handleClose()
      navigate(
        `${ROUTE.ADMIN_HR_CREATE_DOCUMENT_FILES}?${convertToParams({
          id: item?.id,
          name: item?.name
        })}`
      )
    }
  },
  {
    label: 'Interações',
    icon: <CampaignIcon />,
    className: TAG_MANAGER.human_resources_btn_interactions,
    onClick() {
      handleClose()
      navigate(
        `${ROUTE.ADMIN_HR_CREATE_INTERACTIONS}?${convertToParams({
          id: item?.id,
          name: item?.name
        })}`
      )
    }
  },
  {
    label: 'Disciplinas e Turmas',
    icon: <MenuBookIcon />,
    className: TAG_MANAGER.human_resources_btn_open_disciplines_and_classes,
    disabled: isActive,
    onClick() {
      handleClose()
      handleDisciplinesClasses(item)
    }
  },
  {
    label: isActive ? 'Ativar' : 'Inativar',
    icon: isActive ? <AddCircleOutlineIcon /> : <DoDisturbOnOutlinedIcon />,
    className: isActive
      ? TAG_MANAGER.human_resources_btn_ativar
      : TAG_MANAGER.human_resources_btn_inactivate,
    onClick() {
      handleClose()
      handleModalConfirm(item)
    }
  }
]

export { getMenuItems }
