import styled from 'styled-components'
import { Tooltip as MuiTooltip } from '@mui/material'
import { Delete, AddBox } from '@mui/icons-material'

const Tooltip = styled(MuiTooltip)`
  &.MuiTooltip-root {
    margin-top: 2rem;
  }
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.textColors.error};
  }
`

const AddIcon = styled(AddBox)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
  }
`
const ContainerField = styled.div`
  display: flex;
  width: 100%;
`

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: 1px dashed ${({ theme }) => theme.palette.gray.bgLight};
  padding: 16px;
  width: 100%;
  margin-bottom: 24px;

  & > div {
    margin-bottom: 16px;
  }
`

const BoxTooltip = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

export { Tooltip, DeleteIcon, AddIcon, ContainerField, Container, BoxTooltip }
