import styled from 'styled-components'
import { Box, Typography as MuiTypography } from '@mui/material'

const BoxPagination = styled(Box)`
  &.MuiBox-root {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: ${({ $smallSize }) => ($smallSize ? '15px 0' : '34px 0')};
    align-items: center;

    & .MuiFormControl-root {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`

const BoxFormPagination = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    margin-left: 12px;
  }
`

export { BoxPagination, BoxFormPagination, Typography }
