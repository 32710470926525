import { httpGet, httpPost, httpPut, httpDelete } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getPaymentPlan = async (params, values) => {
  const isEad = values?.ead !== false ? '?is_ead=false' : ''

  const response = await httpGet(`${ENDPOINT.PAYMENT_PLAN.CRUD}${isEad}`, {
    params
  })

  return response
}

export const removePaymentPlan = async (id) => {
  const idString = id?.length ? `?ids[]=${id?.join('&ids[]=')}` : `?ids[]=${id}`

  const response = await httpDelete(`${ENDPOINT.PAYMENT_PLAN.CRUD}${idString}`)

  return response
}

export const copyPaymentPlan = async (params) => {
  const response = await httpPost(`${ENDPOINT.PAYMENT_PLAN.COPY}`, {
    ...params
  })

  return response
}

export const changePaymentPlanAmounts = async (params) => {
  const response = await httpPut(`${ENDPOINT.PAYMENT_PLAN.CHARGE}`, {
    payment_plan: params
  })

  return response
}

export const savePaymentPlanAmounts = async (params) => {
  const response = await httpPost(`${ENDPOINT.PAYMENT_PLAN.CRUD}`, {
    ...params
  })

  return response
}

export const getPaymentPlanById = async (id) => {
  const response = await httpGet(`${ENDPOINT.PAYMENT_PLAN.CRUD}/${id}`)
  return response
}

export const updatePaymentPlan = async (params, paymentPlanId) => {
  const response = await httpPut(
    `${ENDPOINT.PAYMENT_PLAN.CRUD}/${paymentPlanId}`,
    {
      ...params
    }
  )

  return response
}
