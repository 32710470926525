import styled from 'styled-components'
import { TableCell as MuiTableCell } from '@mui/material'

const TableSentAt = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 15%;
  }
`

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 10%;
  }
`

const TableCellPhone = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 20%;
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 16px;
    width: 40%;
  }
`

export { TableCell, TableCellTitle, TableCellPhone, TableSentAt }
