import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { InputSwitch } from 'components/atoms'
import { ButtonFooterV2 } from 'components/molecules'
import { typeMask } from 'services/helpers'
import { defaultItemsPerPage, defaultPage } from '../../constants'
import * as Styled from './style'

const Form = ({
  setFilter,
  setPage,
  setIsButtonSet,
  setFieldValue,
  onClear,
  values
}) => (
  <>
    <Grid container spacing={{ xs: 3, sm: 3 }}>
      <Grid item xs={12} sm={12} md={3}>
        <Styled.Input
          fullWidth
          id="name"
          value={values?.name}
          label="Nome"
          onChange={(e) => setFieldValue('name', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Styled.Input
          fullWidth
          id="cpf"
          value={typeMask('CPF', values.cpf)}
          label="CPF"
          onChange={(e) => setFieldValue('cpf', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <Styled.Input
          fullWidth
          id="register"
          value={values?.register}
          label="Matrícula"
          onChange={(e) => setFieldValue('register', e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2}>
        <InputSwitch
          label="Inativos"
          checked={values.inactive}
          onClick={(e) => setFieldValue('inactive', e.target.checked)}
        />
      </Grid>
      <Styled.CardFooter>
        <ButtonFooterV2
          startIcon={<Clear />}
          labelClose="Limpar"
          labelConfirm="Buscar"
          size="medium"
          onClickClose={() => onClear()}
          onClickConfirm={() => {
            setIsButtonSet(true)
            setPage(defaultPage)
            setFilter(defaultItemsPerPage)
          }}
          typeReset="reset"
        />
      </Styled.CardFooter>
    </Grid>
  </>
)

export { Form }
