import React from 'react'
import { TableRow } from '@mui/material'
import { indexInicial, finalGrade, emptyValue } from './constants'
import * as Styled from './style'

const TableHeader = ({ row, labelId, index, dropouts, config }) => (
  <Styled.TableBody>
    <TableRow
      hover
      role="checkbox"
      id={labelId}
      tabIndex={-indexInicial}
      key={row.register}
    >
      <Styled.TableCell $fieldSize="6%">
        {index + indexInicial}
      </Styled.TableCell>
      <Styled.TableCell $fieldSize="40%" $fiedlName>
        {row.name || '-'}
        {!!row.has_academic_performance && (
          <Styled.TagAcademicPerformances>
            Aluno com Aproveitamento de Estudos na Disciplina
          </Styled.TagAcademicPerformances>
        )}
      </Styled.TableCell>
      <Styled.TableCell $fieldSize="10%">{row.register}</Styled.TableCell>

      {dropouts && (
        <>
          <Styled.TableCell $fieldSize="18%">
            {row.assessment_grade || emptyValue}
          </Styled.TableCell>
          <Styled.TableCell $fieldSize="18%">
            {row.activities_grade || emptyValue}
          </Styled.TableCell>
          <Styled.TableCell $fieldSize="18%">
            {row.recovery_grade || emptyValue}
          </Styled.TableCell>
          <Styled.TableCell $fieldSize="18%">
            {row.reclassification_grade || emptyValue}
          </Styled.TableCell>
          {config.showExFinal && (
            <Styled.TableCell $fieldSize="18%">
              {row.final_exam_grade || emptyValue}
            </Styled.TableCell>
          )}
          <Styled.TableCellScore
            $colorFinalGrade={row.approved ?? row.final_grade > finalGrade}
          >
            {row.final_grade || emptyValue}
          </Styled.TableCellScore>
        </>
      )}
      {!dropouts && <Styled.TableCell $fieldSize="65%" />}
    </TableRow>
  </Styled.TableBody>
)

export default TableHeader
