import { useState } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { ModalConfirm, PaginationAdvanced } from 'components/molecules'
import { useSortTable } from 'services/hooks'
import { TableHeaderDefault } from 'components/organisms'
import { paginationFilter } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import {
  defaultItemsPerPage,
  headCells,
  defaultTotalPage,
  textMessageModalConfirmUserActive,
  textTitleModalConfirmUserActive,
  textTitleModalConfirmUserDisabled,
  textTItleModalDisciplineAndClass,
  titleModalDownloadsFile
} from './constants'
import * as Styled from './style'
import { TableFooter } from './TableFooter'
import { onSubmitActiveProfessor } from '../../services'
import { ModalDisciplineAndClass, ModalEmail, ModalWorksheets } from '../Modals'

const Table = ({
  rows,
  count,
  totalPage,
  setFilter,
  filter,
  setPage,
  setSnack,
  formValues,
  setFormValues,
  pages,
  unitys,
  setLoadingOpen,
  setNotSearch,
  setListProfessorTypes,
  setCount
}) => {
  const [openModalEmail, setOpenModalEmail] = useState(false)
  const [formValuesDisciplines, setFormValuesDisciplines] = useState({})
  const [user, setUser] = useState()
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [openModalDisciplineAndClass, setDisciplineAndClass] = useState(false)
  const [openModalDonwloadsFile, setModalDonwloadsFile] = useState(false)

  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(rows)

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  const handleSendEmail = (item) => {
    setOpenModalEmail(true)
    setUser(item)
  }

  const handleOpenArchive = () => {}

  const handleSendInteractions = () => {}

  const handleDisciplinesClasses = (item) => {
    setDisciplineAndClass(true)
    setUser(item)
  }

  const handleModalConfirm = (item) => {
    setOpenModalConfirm(true)
    setUser(item)
  }

  const handleConfirmModal = (item) => {
    setUser(item)
    onSubmitActiveProfessor({
      values: formValues,
      setLoadingOpen,
      filter,
      pageActual: pages,
      setFormValues,
      setCount,
      setSnack,
      setNotSearch,
      setListProfessorTypes,
      user
    })

    setOpenModalConfirm()
  }

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm()
  }

  return (
    <>
      <Styled.Paper>
        <Styled.TableContainer>
          <MuiTable>
            <TableHeaderDefault
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {sortTable?.map((row) => (
                <TableFooter
                  key={row.register}
                  row={row}
                  handleUserSendEmail={(item) => handleSendEmail(item)}
                  handleOpenArchive={handleOpenArchive}
                  handleSendInteractions={handleSendInteractions}
                  handleDisciplinesClasses={(item) =>
                    handleDisciplinesClasses(item)
                  }
                  handleModalConfirm={(item) => handleModalConfirm(item)}
                />
              ))}
            </TableBody>
          </MuiTable>
        </Styled.TableContainer>

        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={pages}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setFilter(e.target.value)
              setPage(defaultTotalPage)
            }}
          />
        )}
      </Styled.Paper>

      <ModalEmail
        open={openModalEmail}
        handleClose={() => setOpenModalEmail(false)}
        user={user}
        setLoadingOpen={setLoadingOpen}
        setOpenModalEmail={setOpenModalEmail}
        title="Enviar E-mail"
        message="Preencha os campos abaixo para enviar um e-mail ao destinatário:"
        setSnack={setSnack}
        className={TAG_MANAGER.rh_btn_confirma_enviarEmail}
      />

      <ModalConfirm
        open={openModalConfirm}
        size="medium"
        handleClose={handleCloseModalConfirm}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={handleCloseModalConfirm}
        onClickConfirm={handleConfirmModal}
        colorButtonConfirm="error"
        iconWarningAmber
        title={
          user?.blocked
            ? textTitleModalConfirmUserDisabled
            : textTitleModalConfirmUserActive
        }
        message={
          user?.blocked
            ? `${user?.name} - ${user?.id}`
            : `${textMessageModalConfirmUserActive} ${user?.name} - ${user?.id}`
        }
        classNameBtnConfirm={TAG_MANAGER.rh_btn_confirma_inativarProfessor}
      />

      <ModalDisciplineAndClass
        open={openModalDisciplineAndClass}
        handleClose={() => setDisciplineAndClass(false)}
        title={textTItleModalDisciplineAndClass}
        user={user}
        setFormValuesDisciplines={setFormValuesDisciplines}
        setSnack={setSnack}
        unitys={unitys}
        setLoading={setLoadingOpen}
        setDisciplineAndClass={setDisciplineAndClass}
        setModalDonwloadsFile={setModalDonwloadsFile}
        isValidation
      />

      <ModalWorksheets
        open={openModalDonwloadsFile}
        handleClose={() => setModalDonwloadsFile(false)}
        title={titleModalDownloadsFile}
        setModalDonwloadsFile={setModalDonwloadsFile}
        setSnack={setSnack}
        setLoading={setLoadingOpen}
        values={formValuesDisciplines}
        id={user?.id}
        setDisciplineAndClass={setDisciplineAndClass}
        url
      />
    </>
  )
}

export { Table }
