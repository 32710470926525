import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useSnack } from 'services/hooks'
import { loadStudentInfo } from '../History/services'
import { useStudentOccurrence } from '../StudentRegister/components/TabOccurrences/Store/useStudentOccurrence'
import { OptionsCourse } from './components/OptionsCourse'
import { breadcrumbsItems } from './helpers'
import { loadCourseStudent } from './services'

const NewDocumentHistory = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])
  const { student, name } = params
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const { resetFormValues } = useStudentOccurrence()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [studentInfo, setStudentInfo] = useState({})
  const [studentListCourse, setStudentListCourse] = useState()
  const PATH_STUDENT = ROUTE.ADMIN_SECRETARY_STUDENTS

  useEffect(() => {
    loadStudentInfo({
      setSnack,
      setLoading,
      setStudentInfo,
      register: student
    })

    loadCourseStudent({
      setSnack,
      setLoading,
      setStudentListCourse,
      register: student
    })
  }, [])

  const onHandleConfirm = () => {
    setOpen(false)
    resetFormValues()
    navigate(PATH_STUDENT, {
      state: { savedSearch: true }
    })
  }

  const handleGoBack = () => {
    setOpen(false)
    resetFormValues()
    navigate(PATH_STUDENT, {
      state: { savedSearch: false }
    })
  }

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems(name)}
      onClickButton={() => setOpen(true)}
      open={open}
      handleCloseModal={() => handleGoBack()}
      handleConfirmModal={() => onHandleConfirm()}
      setLoading={setLoading}
      content={
        <>
          <OptionsCourse
            setSnack={setSnack}
            studentListCourse={studentListCourse}
            studentInfo={studentInfo}
            params={params}
          />
        </>
      }
    />
  )
}

export default NewDocumentHistory
