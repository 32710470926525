import { useState } from 'react'
import { Search } from '@mui/icons-material'

import { EmptyState, ModalConfirm } from 'components/molecules'

import FormSearch from '../FormSearch'
import Table from '../Table'

import { messageModalConfirm, titleModalConfirm } from './constants'

import Styled from './style'

const Content = ({
  hasResult,
  rows,
  handleSearch,
  handleLinkDiscipline,
  schoolClassLevel,
  schoolClassCourse,
  subjectWorkloadHours,
  subjectName,
  filter,
  setFilter,
  totalPage,
  handleSetPage,
  setPage,
  count
}) => {
  const [linkModal, setLinkModal] = useState({ open: false, subjectId: null })

  const handleLinkModal = (subjectId) => {
    setLinkModal({ open: true, subjectId })
  }

  const handleCloseLinkModal = () => {
    setLinkModal({ open: false, subjectId: null })
  }

  return (
    <>
      <Styled.Title>
        {subjectName} [{subjectWorkloadHours}h]
      </Styled.Title>
      <Styled.SubTitle>
        {schoolClassLevel}: {schoolClassCourse}
      </Styled.SubTitle>

      <FormSearch onSubmit={handleSearch} />

      {hasResult && (
        <Table
          rows={rows}
          handleLink={handleLinkModal}
          filter={filter}
          setFilter={setFilter}
          totalPage={totalPage}
          handleSetPage={handleSetPage}
          setPage={setPage}
          count={count}
        />
      )}

      {!hasResult && (
        <EmptyState
          icon={<Search />}
          labelEmptyState="Não encontramos um resultado para essa busca. <br />Verifique os dados informados e tente novamente."
        />
      )}

      <ModalConfirm
        open={linkModal.open}
        size="medium"
        handleClose={handleCloseLinkModal}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={handleCloseLinkModal}
        onClickConfirm={() => handleLinkDiscipline(linkModal.subjectId)}
        colorButtonConfirm="error"
        iconWarningAmber
        title={titleModalConfirm}
        message={messageModalConfirm}
      />
    </>
  )
}

export default Content
