import { WarningAmber } from '@mui/icons-material'
import {
  Divider as DividerSchoolClass,
  Grid as GridHeaderCode
} from '@mui/material'
import { TextField as CompTextField } from 'components/molecules'
import styled from 'styled-components'
import theme from 'theme/designTokens'

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 16px 0;
`

const TextField = styled(CompTextField)`
  &.MuiTextField-root {
    width: 100%;
  }
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${() => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

const Grid = styled(GridHeaderCode)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    position: relative;

    & button {
      margin-left: 20px;
    }
  }
`

const Divider = styled(DividerSchoolClass)`
  &.MuiDivider-root {
    width: 100%;
    margin: 25px 0 0 0;
  }
`

export { Box, Grid, Divider, TextField, IconWarningAmber }
