import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import * as Styled from './style'

export default function LoadingPage({ open }) {
  return (
    <Styled.LoadPage open={open}>
      <CircularProgress color="inherit" />
    </Styled.LoadPage>
  )
}

LoadingPage.propTypes = {
  open: PropTypes.bool
}

LoadingPage.defaultProps = {
  open: false
}
