const disabledButton = ({ values, dirty }) =>
  !(
    values.curriculum ||
    values.classDuration ||
    values.startDate ||
    values.endDate ||
    values.resolution
  ) || !dirty

const disabledButtonModal = (valuesForm) =>
  !(valuesForm.startDate || valuesForm.endDate)

export { disabledButton, disabledButtonModal }
