import buildCompanyInfo from './CompanyInfo'
import buildFooterDefault from './Footer'
import buildHeader from './Header'
import buildSignature from './Signature'

const getContent = ({
  mainContent,
  documentTitle,
  companyInfo,
  headerContent
}) =>
  mainContent.map((element, index) => {
    const signature = element?.signature
      ? buildSignature(element?.signature)
      : []

    return [
      buildHeader({
        indexOfCurrentElement: index,
        documentTitle,
        content: headerContent
      }),
      ...buildCompanyInfo(companyInfo),
      element,
      ...signature
    ]
  })

export default async function generateDocDefinitionsBase({
  definitionsToReplace = {}
} = {}) {
  const docDefinitions = {
    defaultStyle: {
      fontSize: 8,
      font: 'RobotoLight',
      lineHeight: 1.1
    },
    pageMargins: [20, 20, 20, 30],
    pageOrientation: 'landscape',
    footer: buildFooterDefault,
    ...definitionsToReplace
  }

  return async ({
    mainContent = [],
    companyInfo,
    documentTitle = 'Título do documento',
    headerContent
  }) => {
    docDefinitions.content = getContent({
      documentTitle,
      mainContent,
      companyInfo,
      headerContent
    })
    docDefinitions.info = {
      title: documentTitle
    }
    docDefinitions.pageBreakBefore = (
      currentNode,
      followingNodesOnPage,
      nodesOnNextPage,
      previousNodesOnPage
    ) => {
      const shouldBreakBefore =
        currentNode?.id === 'signature' && currentNode.pageNumbers.length > 1

      return (
        shouldBreakBefore ||
        definitionsToReplace?.pageBreakBefore?.(
          currentNode,
          followingNodesOnPage,
          nodesOnNextPage,
          previousNodesOnPage
        )
      )
    }

    return docDefinitions
  }
}
