import PropTypes from 'prop-types'
import { Divider } from '@mui/material'
import { Breadcrumbs, ButtonCallToAction } from 'components/molecules'
import { PageTitle } from 'components/atoms'
import * as Styled from './style'

const HeaderPage = ({
  classNameCallToAction,
  title,
  divider,
  labelCallToAction,
  callToAction,
  linkCallToAction,
  breadcrumbsItems,
  startIcon,
  separator,
  children,
  width,
  breadcrumbsNotLink,
  infoDownBreadcrumbs
}) => (
  <>
    <Styled.Container>
      <Styled.BoxInfo>
        {breadcrumbsItems || breadcrumbsNotLink ? (
          <>
            <Breadcrumbs
              separator={separator}
              breadcrumbsItems={breadcrumbsItems}
              breadcrumbsNotLink={breadcrumbsNotLink}
            />
            {infoDownBreadcrumbs && (
              <Styled.BoxInfoDownBreadcrumbs>
                {infoDownBreadcrumbs}
              </Styled.BoxInfoDownBreadcrumbs>
            )}
          </>
        ) : (
          <PageTitle title={title} />
        )}

        <div>{children}</div>
      </Styled.BoxInfo>

      {labelCallToAction && (
        <ButtonCallToAction
          width={width}
          classNameCallToAction={classNameCallToAction}
          labelCallToAction={labelCallToAction}
          callToAction={callToAction}
          linkCallToAction={linkCallToAction}
          startIcon={startIcon}
        />
      )}
    </Styled.Container>

    {divider && <Divider />}
  </>
)

export default HeaderPage

HeaderPage.propTypes = {
  classNameCallToAction: PropTypes.string,
  title: PropTypes.string,
  divider: PropTypes.bool,
  labelCallToAction: PropTypes.string,
  callToAction: PropTypes.func,
  linkCallToAction: PropTypes.string,
  breadcrumbsItems: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  startIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  separator: PropTypes.element
}
