import { useEffect } from 'react'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'

import { useGetCourseByUnit, useMakeService } from 'services/hooks'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'

import { parseUnits } from 'services/helpers'
import { defaultValues } from './constants'

import * as Styled from './style'

export default function SearchForm({ handleSearch, setSnack, formValues }) {
  const paramsMakeService = {
    isPeriod: true,
    isModule: true,
    isGroup: true,
    isUnits: true,
    unitsValue: true,
    isLevel: true,
    setSnack
  }

  const {
    level: listLevel,
    unitys: listUnitys,
    group: listGroup,
    modules: listModules,
    period: listShift
  } = useMakeService(paramsMakeService)

  const { handleSubmit, setFieldValue, values, resetForm } = useFormik({
    initialValues: defaultValues,
    onSubmit: () => handleSearch({ listUnitys, values }),
    validateOnMount: true
  })

  const disabledButton = ({ discipline }) => !discipline

  const handleResetForm = () => {
    handleSearch(null)
    resetForm()
  }

  const { handleLevel } = useGetCourseByUnit({
    setSnack,
    courseValue: true,
    formValues,
    unitys: listUnitys,
    setFieldValue,
    active: true
  })

  const handleUnity = (e) => {
    setFieldValue('unit', e.target.value)
    setFieldValue('course', '')
    setFieldValue('level', '')
  }

  useEffect(() => {
    if (formValues?.level && formValues?.unity) {
      handleLevel({
        value: values?.level,
        unity: values.unity,
        type: false
      })
    }
  }, [listUnitys])

  return (
    <Styled.Container>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <Select
            label="Unidade"
            value={values?.unit}
            optionsList={parseUnits(listUnitys)}
            onChange={(e) => handleUnity(e, setFieldValue)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Select
            label="Nível"
            value={values?.level}
            optionsList={listLevel}
            onChange={(e) => setFieldValue('level', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            label="Nome da Disciplina*"
            value={values.discipline}
            onChange={(e) => setFieldValue('discipline', e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Select
            label="Grupo"
            value={values?.group}
            optionsList={listGroup}
            onChange={(e) => setFieldValue('group', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4.5}>
          <Select
            label="Módulo"
            value={values.module}
            optionsList={listModules}
            onChange={(e) => setFieldValue('module', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4.5}>
          <Select
            label="Turno"
            value={values.shift}
            optionsList={listShift}
            onChange={(e) => setFieldValue('shift', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <ButtonFooterV2
            disabledClose={disabledButton(values)}
            disabledConfirm={disabledButton(values)}
            labelClose="Limpar"
            labelConfirm="Buscar"
            onClickClose={handleResetForm}
            onClickConfirm={() => handleSubmit()}
            size="large"
            startIcon={<Clear />}
          />
        </Grid>
      </Grid>
    </Styled.Container>
  )
}
