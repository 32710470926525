import { Form, Formik } from 'formik'
import { Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'
import { createByEditEadContentRegistrations } from '../../services'
import schema from './schema'

const ModalCreateByEditEad = ({
  id,
  handleClose,
  idSubject,
  isEdit,
  valuesModal,
  confirmModal,
  open,
  setConfirmModal,
  setListContentEad,
  setLoading,
  count,
  filter,
  setCount,
  setFilter,
  pageActual
}) => (
  <>
    <Styled.Modal
      open={open}
      onClose={!confirmModal && handleClose}
      maxWidth="md"
    >
      <Formik
        initialValues={valuesModal}
        onSubmit={(values) =>
          createByEditEadContentRegistrations({
            id,
            isEdit,
            idSubject,
            values,
            setLoading,
            setListContentEad,
            handleClose,
            count,
            filter,
            setCount,
            setFilter,
            pageActual
          })
        }
        validationSchema={schema}
        enableReinitialize
      >
        {({ values, setFieldValue, dirty, handleSubmit, touched, errors }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h5" component="h1" fontWeight="600">
                    {isEdit ? 'Editar' : 'Criar'} conteúdo EAD
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    id="content_description"
                    fullWidth
                    label="Descrição do conteúdo"
                    value={values?.content_description || ''}
                    onChange={(e) =>
                      setFieldValue('content_description', e.target.value)
                    }
                    multiline
                    rows={4}
                    helperText={
                      errors &&
                      touched.content_description &&
                      errors.content_description
                    }
                    error={Boolean(
                      touched.content_description && errors.content_description
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                  <TextField
                    id="content_section"
                    fullWidth
                    label="Capítulo/Unidade"
                    value={values?.content_section || ''}
                    onChange={(e) =>
                      setFieldValue('content_section', e.target.value)
                    }
                    helperText={
                      errors &&
                      touched.content_section &&
                      errors.content_section
                    }
                    error={Boolean(
                      touched.content_section && errors.content_section
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    id="content_workload"
                    fullWidth
                    label="Carga horária"
                    value={values?.content_workload || ''}
                    onChange={(e) =>
                      setFieldValue('content_workload', e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end"> horas </InputAdornment>
                      )
                    }}
                    helperText={
                      errors &&
                      touched.content_workload &&
                      errors.content_workload
                    }
                    error={Boolean(
                      touched.content_workload && errors.content_workload
                    )}
                  />
                </Grid>
              </Grid>

              <Styled.Box>
                <ButtonFooterV2
                  labelClose="Cancelar"
                  labelConfirm="Salvar"
                  size="medium"
                  disabledConfirm={!dirty}
                  classNameConfirm={
                    isEdit
                      ? TAG_MANAGER.gestao_btn_confirma_editarConteudoEAD
                      : TAG_MANAGER.gestao_btn_confirma_cadastrarConteudoEAD
                  }
                  onClickClose={() =>
                    !dirty ? handleClose() : setConfirmModal(true)
                  }
                />
              </Styled.Box>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  </>
)

export default ModalCreateByEditEad
