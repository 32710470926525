import { getMoneyFormatToNumber } from 'services/helpers'

export const convertedValuesToNewBankBilling = ({
  registerId,
  account,
  documentDate,
  dueDate,
  installmentNumber,
  installmentsAmount,
  installmentValue,
  description,
  additionalDescription,
  amount,
  costCenter,
  nature,
  subcategory,
  type,
  unit,
  checked,
  course,
  isCnpj
}) => ({
  register: Number(registerId),
  account: account?.value,
  document_date: documentDate,
  due_date: dueDate,
  installment_number: Number(installmentNumber),
  installments_amount: Number(installmentsAmount),
  installment_value: getMoneyFormatToNumber(installmentValue),
  additional_description: additionalDescription || undefined,
  description,
  cost_center_value: Number(amount),
  id_center_cost_name: costCenter,
  id_center_cost_nature: nature,
  ...(subcategory && { id_center_cost_subcategory: subcategory }),
  id_center_cost_type: type,
  value_type: checked ? 'Porcentagem' : 'Valor',
  subsidiary_id: unit,
  ...(!isCnpj && { course_id: course?.value })
})
