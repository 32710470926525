const defaultPage = 1
const defaultItemsPerPage = 10
const initialState = {
  endDate: '',
  startDate: ''
}
const MESSAGE = {
  SUCCESS_EMAIL: 'E-mail enviado com sucesso.'
}

export { initialState, defaultPage, defaultItemsPerPage, MESSAGE }
