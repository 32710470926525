const TAB = {
  HEADQUARTERS: 'headquarters',
  DISCIPLINES: 'disciplines'
}

const initDisciplines = [
  {
    id: '',
    active: true,
    discipline: '',
    code: '',
    workload_hours: '',
    workload_minutes: '',
    avaliation_type: '',
    reference_code: '',
    module_number: ''
  }
]

const headquarters = 'headquarters'
const disciplines = 'disciplines'

export { headquarters, TAB, disciplines, initDisciplines }
