import { useEffect, useState } from 'react'
import { TextField, Select, ButtonFooterV2 } from 'components/molecules'
import { typeMask } from 'services/helpers/mask'
import { useAddress } from 'services/hooks'
import { useFormik } from 'formik'
import { MAX_LENGTH_CNPJ } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import { mergeInitialValues } from 'services/helpers'
import { getCityByState, getStates } from 'services/api/shared'
import {
  MAX_LENGTH_CEP,
  MAX_LENGTH_PHONE_MOBILE,
  cepValid
} from '../../constants'
import { initialValues as initialState } from './constants'
import { formSchema } from './schema'
import { searchCep } from '../../services'
import * as Styled from './style'

const FormJuridicalPersonData = ({
  onClickBack,
  valuesAllForms,
  setValuesAllForms,
  handleConfirmRegister
}) => {
  const [states, setStates] = useState([])
  const [selectedState, setSelectedState] = useState('')
  const [citys, setCitys] = useState([])
  const [address, setAddress] = useState({
    district: '',
    address: '',
    city: '',
    state: ''
  })

  const initialValues = mergeInitialValues(initialState, valuesAllForms)

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = mergeInitialValues(valuesAllForms, values)

      setValuesAllForms({ ...valuesAllForms, ...values })

      handleConfirmRegister(payload)
    },
    validationSchema: formSchema
  })

  const { fetchAddress } = useAddress({ setAddress })

  useEffect(() => {
    setSelectedState(formik.values.uf)
  }, [formik.values.uf])

  useEffect(async () => {
    const lisStates = await getStates()
    setStates(lisStates)
  }, [])

  useEffect(async () => {
    if (address?.state) {
      const cities = await getCityByState(selectedState)
      setCitys(cities)
    }
  }, [address?.state])

  useEffect(() => {
    const cepFin = formik.values.cep_fin

    searchCep({ fetchAddress, cepFin })
  }, [formik.values.cep_fin])

  useEffect(() => {
    formik.setFieldValue('bairro_fin', address?.district)
    formik.setFieldValue('uf_fin', address?.state)
    formik.setFieldValue('cidade_fin', address?.city)
    formik.setFieldValue('end_fin', address?.address)
  }, [address])

  return (
    <>
      <Styled.Form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
        <Styled.BoxHeader>
          <Styled.TextField
            type="text"
            name="cnpj"
            label="CNPJ"
            isMargin="8px 16px 8px 0"
            onBlur={formik.handleBlur}
            id="financialResponsible.cnpj"
            onChange={formik.handleChange}
            inputProps={{ maxLength: MAX_LENGTH_CNPJ }}
            value={typeMask('CNPJ', formik.values.cnpj)}
            error={formik.errors.cnpj && formik.touched.cnpj}
            helperText={formik.touched.cnpj && formik.errors.cnpj}
          />
        </Styled.BoxHeader>
        <Styled.BoxContainer>
          <div>
            <TextField
              fullWidth
              name="nome_fin"
              label="Nome Completo*"
              onBlur={formik.handleBlur}
              value={formik.values.nome_fin}
              onChange={formik.handleChange}
              error={formik.errors.nome_fin && formik.touched.nome_fin}
              helperText={formik.touched.nome_fin && formik.errors.nome_fin}
            />
            <TextField
              fullWidth
              name="ieCnpj"
              type="number"
              onBlur={formik.handleBlur}
              label="Inscrição Estadual*"
              value={formik.values.ieCnpj}
              onChange={formik.handleChange}
              error={formik.errors.ieCnpj && formik.touched.ieCnpj}
              helperText={formik.touched.ieCnpj && formik.errors.ieCnpj}
            />

            <Styled.BoxCard>
              <TextField
                fullWidth
                label="CEP*"
                name="cep_fin"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={typeMask('CEP', formik.values.cep_fin)}
                error={formik.errors.cep_fin && formik.touched.cep_fin}
                helperText={formik.touched.cep_fin && formik.errors.cep_fin}
                inputProps={{
                  maxLength: MAX_LENGTH_CEP
                }}
              />
              <Select
                id="state"
                name="uf_fin"
                label="Estado*"
                labelId="select-state"
                optionsList={states}
                onBlur={formik.handleBlur}
                value={formik.values.uf_fin}
                error={formik.errors.uf_fin && formik.touched.uf_fin}
                helperText={formik.touched.uf_fin && formik.errors.uf_fin}
                onChange={(e) => {
                  setSelectedState(e.target.value)
                  formik.setFieldValue('uf_fin', e.target.value)
                }}
              />
            </Styled.BoxCard>

            <Select
              label="Cidade*"
              id="cidade_fin"
              name="cidade_fin"
              isMargin="0 0 16px"
              labelId="select-city"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.cidade_fin}
              disabled={formik.values.uf_fin === ''}
              error={formik.errors.cidade_fin && formik.touched.cidade_fin}
              helperText={formik.touched.cidade_fin && formik.errors.cidade_fin}
              optionsList={citys}
            />

            <Styled.BoxCard>
              <TextField
                fullWidth
                name="end_fin"
                label="Endereço*"
                onBlur={formik.handleBlur}
                value={formik.values.end_fin}
                onChange={formik.handleChange}
                error={formik.errors.end_fin && formik.touched.end_fin}
                helperText={formik.touched.end_fin && formik.errors.end_fin}
              />
              <TextField
                fullWidth
                label="Número*"
                name="numero_fin"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.numero_fin}
                error={formik.errors.numero_fin && formik.touched.numero_fin}
                helperText={
                  formik.touched.numero_fin && formik.errors.numero_fin
                }
              />
            </Styled.BoxCard>

            <TextField
              fullWidth
              name="comp_fin"
              label="Complemento (opcional)"
              value={formik.values.comp_fin}
              onChange={formik.handleChange}
            />

            <TextField
              fullWidth
              label="Bairro*"
              name="bairro_fin"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.bairro_fin}
              error={formik.errors.bairro_fin && formik.touched.bairro_fin}
              helperText={formik.touched.bairro_fin && formik.errors.bairro_fin}
            />
          </div>
          <div>
            <TextField
              fullWidth
              name="nome_fantasia_fin"
              onChange={formik.handleChange}
              label="Nome Fantasia (Opcional)"
              value={formik.values.nome_fantasia_fin}
            />

            <TextField
              fullWidth
              type="email"
              label="E-mail*"
              name="email_fin"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email_fin}
              error={formik.errors.email_fin && formik.touched.email_fin}
              helperText={formik.touched.email_fin && formik.errors.email_fin}
            />

            <Styled.Container>
              <TextField
                fullWidth
                label="Celular*"
                isMargin="0 0 16px"
                name="mobile_phone_fin"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={typeMask('PHONE', formik.values.mobile_phone_fin)}
                error={
                  formik.errors.mobile_phone_fin &&
                  formik.touched.mobile_phone_fin
                }
                helperText={
                  formik.touched.mobile_phone_fin &&
                  formik.errors.mobile_phone_fin
                }
                inputProps={{
                  maxLength: MAX_LENGTH_PHONE_MOBILE
                }}
              />

              <TextField
                fullWidth
                name="tel_fin"
                label="Telefone"
                onChange={formik.handleChange}
                value={typeMask('TELEPHONE', formik.values.tel_fin)}
                inputProps={{
                  maxLength: MAX_LENGTH_PHONE_MOBILE
                }}
                isMargin="0"
              />
            </Styled.Container>
          </div>
        </Styled.BoxContainer>
        <TextField
          fullWidth
          multiline
          label="Observações (opcional)"
          name="observation"
          onChange={formik.handleChange}
          InputProps={{
            value: formik.values.observation
          }}
        />

        <ButtonFooterV2
          size="medium"
          type="submit"
          labelClose="Voltar"
          labelConfirm="Finalizar"
          classNameConfirm={TAG_MANAGER.commercial_btn_search_for_registrations}
          onClickClose={() => {
            formik.resetForm({})
            onClickBack()
          }}
        />
      </Styled.Form>
    </>
  )
}

export default FormJuridicalPersonData
