import { DOCUMENT_TITLE_ACADEMIC_RECORD_SP } from 'components/templates/PDF/academicRecord/SP/constants'
import { DOCUMENT_TITLE_ACADEMIC_RECORD_MG } from 'components/templates/PDF/academicRecord/MG/constants'
import { DOCUMENT_TITLE_ACADEMIC_RECORD_PR } from 'components/templates/PDF/academicRecord/PR/constants'
import { utilsPDF } from '../../utils'

const { borderLayoutDefault, paddingLayoutDefault, logoProzSVG } = utilsPDF

export function HeaderDefault({ content, indexOfCurrentElement }) {
  return {
    table: {
      body: [
        [
          { svg: logoProzSVG, width: 100, margin: 5, alignment: 'center' },
          content || { text: 'HeaderContentRightSide' }
        ]
      ],
      widths: [120, '*']
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault },
    ...(indexOfCurrentElement &&
      indexOfCurrentElement > 0 && { pageBreak: 'before' })
  }
}

function HeaderFree({ content, indexOfCurrentElement }) {
  return {
    ...(content ?? {}),
    ...(indexOfCurrentElement &&
      indexOfCurrentElement > 0 && { pageBreak: 'before' })
  }
}

const headersRightSideFree = [DOCUMENT_TITLE_ACADEMIC_RECORD_SP]

const headersFree = [
  DOCUMENT_TITLE_ACADEMIC_RECORD_MG,
  DOCUMENT_TITLE_ACADEMIC_RECORD_PR
]

const getContentDefault = ({ documentTitle }) => ({
  stack: [
    {
      text: 'Proz Educação',
      bold: true,
      fontSize: 16,
      lineHeight: 1.2,
      margin: [1, 0, 0, 0]
    },
    { text: documentTitle, bold: true, fontSize: 14 }
  ],
  alignment: 'center'
})

export default function BuildHeader({
  indexOfCurrentElement,
  documentTitle,
  content
}) {
  switch (true) {
    case headersRightSideFree.includes(documentTitle):
      return HeaderDefault({ indexOfCurrentElement, content })

    case headersFree.includes(documentTitle):
      return HeaderFree({ content, indexOfCurrentElement })

    default: {
      const contentDefault = getContentDefault({ documentTitle })
      return HeaderDefault({ content: contentDefault, indexOfCurrentElement })
    }
  }
}
