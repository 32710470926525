const nameSteps = {
  one: 0,
  two: 1,
  three: 2,
  four: 3
}

const academicJoyClass = 'https://academiaproz.joyclass.com/login'

export { nameSteps, academicJoyClass }
