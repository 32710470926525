import styled from 'styled-components'
import { Tabs as MuiTabs } from '@mui/material'

const Tabs = styled(MuiTabs)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${({ theme }) => theme.palette.gray.light};
    margin-top: 20px;
    margin-bottom: 30px;
  }
`
export { Tabs }
