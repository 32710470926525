import PropTypes from 'prop-types'
import { Button as MuiButon, CircularProgress } from '@mui/material'

const Button = ({
  className,
  children,
  color,
  disabled,
  loading,
  loadingPosition,
  onclick,
  size,
  type,
  value,
  variant,
  startIcon,
  endIcon,
  ...props
}) => {
  const canShowLoadingAtStart = loading && loadingPosition === 'start'
  const canShowLoadingAtEnd = loading && loadingPosition === 'end'
  const loadingIcon = (
    <CircularProgress
      color="inherit"
      size={15}
      sx={{
        ml: canShowLoadingAtStart ? -1 / 2 : 1,
        mr: canShowLoadingAtEnd ? -1 / 2 : 1
      }}
    />
  )

  return (
    <MuiButon
      className={className}
      color={color}
      disabled={disabled || loading}
      onClick={onclick}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      sx={{ my: 2 }}
      type={type}
      value={value}
      variant={variant}
      {...props}
    >
      {canShowLoadingAtStart && loadingIcon}
      {children || value}
      {canShowLoadingAtEnd && loadingIcon}
    </MuiButon>
  )
}

export default Button

Button.propTypes = {
  /** Just in case some HTML tag is needed inside the button. Otherwise, just use 'value' to show some text */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  color: PropTypes.oneOf([
    'error',
    'info',
    'inherit',
    'primary',
    'secondary',
    'success',
    'warning'
  ]),
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingPosition: PropTypes.oneOf(['start', 'end']),
  onclick: PropTypes.func,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  /** What the button's type should be? */
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  value: PropTypes.string,
  /** The UI style */
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  startIcon: PropTypes.element,
  endIcon: PropTypes.element
}

Button.defaultProps = {
  color: 'primary',
  loading: false,
  loadingPosition: 'start',
  size: 'medium',
  type: 'button',
  variant: 'contained'
}
