import styled from 'styled-components'
import { IconButton as MuiIconButton } from '@mui/material'
import { MenuRounded as MuiMenuRounded } from '@mui/icons-material'

const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  padding: 0 24px;
`

const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & > div + div {
    margin-left: 0.5rem;
  }
`
const ItemWrapperUser = styled.div`
  display: flex;
  justify-content: flex-end;

  & > div + div {
    margin-left: 0.5rem;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const IconButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    margin-right: 24px;
  }
`

const MenuRounded = styled(MuiMenuRounded)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.background.white};
  }
`

export { Container, ItemWrapper, ItemWrapperUser, IconButton, MenuRounded }
