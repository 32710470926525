import styled from 'styled-components'
import { pathCheck } from 'services/helpers/ramda'
import theme from 'theme/designTokens'

const Container = styled.div`
  padding: 0;
  margin: 90px 0 20px;
`

const TabContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  @media (max-width: 780px) {
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }
`

const TabFilterWrapper = styled.div`
  display: flex;
  min-width: 103px;
  position: absolute;
  right: -15px;
  top: -80px;
  background-color: ${pathCheck(['palette', 'third', 'bgLight'], theme)};

  @media (max-width: 780px) {
    left: 0;
    margin-bottom: 1rem;
    position: inherit;
    right: auto;
    top: 0;
    width: 100%;
  }
`

const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
`

export { TabContent, TabFilterWrapper, PaginationWrapper, Container }
