import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { InputFile } from 'components/molecules'
import { Modal } from 'components/atoms'
import FooterModal from './components/FooterModal'
import HeaderModal from './components/HeaderModal/index'
import * as Styled from './style'

const ModalUploadPhoto = ({
  classNameModal,
  open,
  handleClose,
  onChange,
  labelHeader,
  labelContent,
  type,
  classNameTrakingBtn,
  imgSrc,
  colorInformation,
  onClickConfirm,
  content
}) => (
  <Modal className={classNameModal} open={open} onClose={handleClose}>
    <Styled.CardContainer>
      <HeaderModal label={labelHeader} onClick={handleClose} />

      <InputFile
        idUpload="upload"
        onChange={onChange}
        typeFile={type}
        labelBox={labelContent}
        imgSrc={imgSrc}
      />

      <Typography
        variant="subtitle2"
        sx={{ margin: '16px', color: colorInformation }}
      >
        Somente{' '}
        {type === 'pdf' ? 'arquivos PDF' : 'imagens em PNG, JPG ou JPEG'}, com
        até 5mb
      </Typography>

      {content}

      <FooterModal
        classNameTrakingBtn={classNameTrakingBtn}
        onClickConfirm={onClickConfirm}
        onClickCancel={handleClose}
      />
    </Styled.CardContainer>
  </Modal>
)

export default ModalUploadPhoto

ModalUploadPhoto.propTypes = {
  content: PropTypes.element
}
