import { Box, TableRow } from '@mui/material'
import { formatDateTimePtBr } from 'services/helpers'
import * as Styled from './style'

const TableFooter = ({ row }) => (
  <>
    <TableRow hover tabIndex={-1} key={Math.random()}>
      <Styled.TableCellTitle>
        <Box>{formatDateTimePtBr(row?.created_at) || ''}</Box>
      </Styled.TableCellTitle>

      <Styled.TableCellEmail>
        <Box>{row?.to || ''}</Box>
      </Styled.TableCellEmail>

      <Styled.TableCellAction>
        <Box>{row?.subject || ''}</Box>
      </Styled.TableCellAction>

      <Styled.TableCell>
        <Box>{row?.created_by || ''}</Box>
      </Styled.TableCell>
    </TableRow>
  </>
)

export { TableFooter }
