const defaultItemsPerPage = 10
const defaultTotalPage = 1
const emptyValue = '---'
const emptyValueMoney = 'R$ 0,00'

const textTitleModalConfirmUserActive = 'Inativar Acesso'

const textTitleModalConfirmUserDisabled = 'Reativar Acesso'

const textMessageModal =
  '<b>Tem certeza que deseja resetar a conferência?</b><br /> Esta ação irá resetar a conferência anterior. Uma vez confirmada não será possível desfazer.'

const textTItleModalDisciplineAndClass = 'Disciplinas e Turmas'

const titleModalDownloadsFile = 'Baixar planilha'

const headCells = [
  {
    id: 'lesson_date',
    disabled: true,
    label: 'Data'
  },
  {
    id: 'lesson_minutes',
    disabled: true,
    label: 'Horas-aula'
  },
  {
    id: 'hourly_lesson_cost',
    disabled: true,
    label: 'Valor Hora'
  },
  {
    id: 'total_lessons_cost',
    disabled: true,
    label: 'Valor Total'
  },
  {
    id: 'lesson_number',
    disabled: true,
    label: 'Nº de Aulas'
  },
  {
    id: 'discipline_name',
    disabled: true,
    label: 'Disciplinas'
  },
  {
    id: 'professor_name',
    disabled: true,
    label: 'Professor(es)'
  },
  {
    id: 'courses',
    disabled: true,
    label: 'Curso(s)'
  },
  {
    id: 'shift',
    disabled: true,
    label: 'Turno'
  },
  {
    id: 'lesson_type',
    disabled: true,
    label: 'Atividade'
  }
]

export {
  headCells,
  defaultItemsPerPage,
  emptyValue,
  defaultTotalPage,
  textTitleModalConfirmUserActive,
  textMessageModal,
  textTitleModalConfirmUserDisabled,
  textTItleModalDisciplineAndClass,
  titleModalDownloadsFile,
  emptyValueMoney
}
