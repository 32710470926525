const filterByIdCheckbox = 'enrollment'

const headCells = [
  {
    id: 'requirement',
    numeric: true,
    label: 'Matrícula',
    disablePadding: false
  },
  { id: 'name', label: 'Nome', disablePadding: false },
  { id: 'situation', label: 'Situação', disablePadding: false },
  { id: 'ocurrence', label: 'Ocorrência', disablePadding: false }
]

const MESSAGES = {
  CONFIRM_UNBINDED: 'Deseja realmente desenturmar estes alunos?',
  CONFIRM_BIND: 'Deseja realmente enturmar estes alunos?'
}

export { filterByIdCheckbox, headCells, MESSAGES }
