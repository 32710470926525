import { createContext, useContext, useState } from 'react'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { loadStudentHasOverdue, loadStudentInfo } from '../services'

const initialState = {
  studentInfo: {
    student: { name: '', register: '' },
    affiliation: {},
    school_data: {},
    overdue: { hasOverdue: false, debitValue: 0, totalBills: 0 }
  },
  getStudentInfo: ({ register }) => {}
}

export const StudentRegisterContext = createContext(initialState)

export function StudentRegisterProvider({ children }) {
  const { setLoading } = useLoading()
  const [studentInfo, setStudentInfo] = useState(initialState.studentInfo)

  const getStudentInfo = async ({ register }) => {
    setLoading(true)

    const findStudentInfoData = loadStudentInfo({
      register,
      onError: () => setLoading(false)
    })
    const findHasOverdueData = loadStudentHasOverdue({
      register,
      onError: () => setLoading(false)
    })

    const [studentInfoData, hasOverdueData] = await Promise.all([
      findStudentInfoData,
      findHasOverdueData
    ])

    if (studentInfoData?.student) {
      setStudentInfo({
        ...studentInfoData,
        overdue: hasOverdueData
      })
    }

    setLoading(false)
  }

  return (
    <StudentRegisterContext.Provider value={{ getStudentInfo, studentInfo }}>
      {children}
    </StudentRegisterContext.Provider>
  )
}

export const useStudentRegisterContext = () =>
  useContext(StudentRegisterContext)
