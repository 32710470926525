const defaultItemsPerPage = 10
const defaultPage = 1
const countDefault = 0
const occurrenceCreatedSuccess = 'Ocorrência criada com sucesso'
const ALL = 'all'

const MESSAGE = {
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.'
}
export {
  defaultItemsPerPage,
  defaultPage,
  countDefault,
  occurrenceCreatedSuccess,
  ALL,
  MESSAGE
}
