import { lazy } from 'react'
import { AttachMoney } from '@mui/icons-material'
import { Table as MuiTable } from '@mui/material'
import { TextNotFound } from 'components/atoms'
import { useSortTable } from 'services/hooks'
import { IsEmpty } from 'services/helpers'
import * as Styled from './style'

const TableHeader = lazy(() => import('./TableHeader'))
const TableFooter = lazy(() => import('./TableFooter'))

const Table = (props) => {
  const {
    dataTable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleDownloadTicket,
    handleOpenPaymentScreen
  } = props
  const { handleRequestSort, order, orderBy } = useSortTable(dataTable)

  return (
    <>
      {IsEmpty(dataTable) ? (
        <TextNotFound
          icon={<AttachMoney />}
          text={
            IsEmpty(dataTable) &&
            'Não encontramos nenhum registro financeiro no momento.'
          }
        />
      ) : (
        <>
          <Styled.Paper>
            <MuiTable>
              <TableHeader
                {...props}
                order={order}
                orderBy={orderBy}
                rowCount={dataTable?.length}
                handleRequestSort={handleRequestSort}
              />

              <TableFooter
                {...props}
                page={page}
                order={order}
                setPage={setPage}
                orderBy={orderBy}
                dataTable={dataTable}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleDownloadTicket={handleDownloadTicket}
                handleOpenPaymentScreen={handleOpenPaymentScreen}
              />
            </MuiTable>
          </Styled.Paper>
        </>
      )}
    </>
  )
}

export default Table
