import PropTypes from 'prop-types'
import { Divider, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from 'components/atoms'
import * as Styled from './style'

const ModalSkeleton = ({
  children,
  onClickClose,
  sizeButton,
  valueButton,
  variantButton,
  isVisibleFooter,
  paddingHeader,
  onClickReadMessage
}) => (
  <Styled.CardContainer>
    <Styled.CardContainerHeader paddingHeader={paddingHeader}>
      <Styled.CardContainerContent>{children}</Styled.CardContainerContent>

      <IconButton
        sx={{ padding: 0, height: 22, width: 22 }}
        onClick={onClickClose}
      >
        <CloseIcon color="primary" sx={{ padding: 0 }} />
      </IconButton>
    </Styled.CardContainerHeader>
    {isVisibleFooter && (
      <>
        <Divider />

        <Styled.CardContainerFooter>
          <Button
            size={sizeButton}
            value={valueButton}
            type="submit"
            variant={variantButton}
            onClick={onClickReadMessage}
          />
        </Styled.CardContainerFooter>
      </>
    )}
  </Styled.CardContainer>
)

export default ModalSkeleton

ModalSkeleton.propTypes = {
  children: PropTypes.element,
  sizeButton: PropTypes.string,
  valueButton: PropTypes.string,
  variantButton: PropTypes.string,
  isVisibleFooter: PropTypes.bool,
  paddingHeader: PropTypes.string,
  onClickClose: PropTypes.func,
  onClickReadMessage: PropTypes.func
}

ModalSkeleton.defaultProps = {
  children: '',
  valueButton: '',
  sizeButton: 'large',
  variantButton: 'outlined',
  isVisibleFooter: true,
  paddingHeader: '',
  onClickClose: () => {},
  onClickReadMessage: () => {}
}
