import { useState } from 'react'
import TAG_MANAGER from 'tagManager'
import FEEDBACK_SNACK from 'feedBackSnack'
import { ModalConfirm } from 'components/molecules'
import { resetStudentPassword } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import * as Styled from './style'

const ModalConfirmResetPassword = ({
  open,
  handleClose,
  onClickNotConfirm,
  onClickConfirm,
  student,
  setLoading,
  setSnack
}) => {
  const [showSuccessDialog, setShowSuccessDialog] = useState(false)
  const password = `ProzEducacao.${new Date().getFullYear()}`

  const onConfirm = async () => {
    onClickConfirm()
    setSnack('')
    setLoading(true)
    const { error, status } = await resetStudentPassword(student.register)
    setLoading(false)
    if (error) {
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToResetPassword
      })
    } else {
      setShowSuccessDialog(true)
    }
  }

  const closeSuccessDialog = () => setShowSuccessDialog(false)

  return (
    <>
      <ModalConfirm
        open={showSuccessDialog}
        handleClose={closeSuccessDialog}
        icon={<Styled.CheckCircleOutlineIcon />}
        title="Senha redefinida com sucesso!"
        message={`A nova senha de <b>${student?.name}</b> é <b>${password}</b> e o
        usuário poderá alterá-la ao acessar o sistema.`}
        textButtonNotConfirm="CONCLUIR"
        onClickNotConfirm={closeSuccessDialog}
      />

      <ModalConfirm
        open={open}
        size="medium"
        handleClose={handleClose}
        textButtonNotConfirm="cancelar"
        textButtonConfirm="redefinir"
        onClickNotConfirm={onClickNotConfirm}
        onClickConfirm={onConfirm}
        iconWarningAmber
        title="Redefinir senha"
        message={`Deseja redefinir a senha de <b>${student?.name}</b>?`}
        classNameBtnConfirm={
          TAG_MANAGER.secretary_btn_confirm_changeStudentPassword
        }
      />
    </>
  )
}

export default ModalConfirmResetPassword
