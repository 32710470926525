const handleCell = (rows) => [
  { label: 'Horário' },
  { label: 'Segunda' },
  { label: 'Terça' },
  { label: 'Quarta' },
  { label: 'Quinta' },
  { label: 'Sexta' },
  { label: 'Sábado' },
  ...(rows.classSchedule?.length ? [{ label: '' }] : [])
]

const initialValues = {
  classSchedule: [
    {
      id: '',
      startTime: '',
      endTime: '',
      monday: '',
      tuesday: '',
      wednesday: '',
      thursday: '',
      friday: '',
      saturday: ''
    }
  ]
}

const disabledButtonSchedule = (values) =>
  values?.classSchedule?.some((i) => i.startTime === '' || i.endTime === '')

export { handleCell, initialValues, disabledButtonSchedule }
