import { Box } from '@mui/material'
import { OpenInNew, Download } from '@mui/icons-material'
import { Button } from 'components/atoms'
import { makeURLAccessLegacy } from 'services/helpers'
import { TYPES_OF_PAYMENT } from 'components/pages/Admin/Financial/PaymentPlans/constants'
import BoxItem from '../BoxItem'
import * as Styled from './style'
import { getEnrollmentOriginLabel } from '../../helpers'
import { calculateFormattedCouponValue } from './helpers'
import { getInfoConfirmPayment } from './constants'

const EnrollmentCompleted = ({
  data,
  handleNewPreEnrollment,
  dataCoupon,
  detailsCampaign,
  dataHeader
}) => {
  const enrollmentOrigin = getEnrollmentOriginLabel(data?.enrollment_origin)

  const isRecorrencePayment =
    data.type_of_payment === TYPES_OF_PAYMENT.RECURRENCE.value

  return (
    <>
      <Styled.Typography $maxWidth>
        {getInfoConfirmPayment(data.type_of_payment)?.confirmText}
      </Styled.Typography>
      <Styled.Container>
        <Styled.Box>
          <BoxItem title="CPF" subTitle={data.cpf} />
          <BoxItem title="Nome" subTitle={data.name} />
          <BoxItem title="Curso" subTitle={data.course} />
          <BoxItem title="Turno" subTitle={data.shift} />
          <BoxItem title="Modalidade" subTitle={data.modality} />
        </Styled.Box>
        <Styled.Box>
          <BoxItem title="Matrícula nº" subTitle={data.pre_enrollment_id} />
          <BoxItem
            title="Forma de pagamento"
            subTitle={`${calculateFormattedCouponValue({
              dataCoupon,
              dataHeader,
              detailsCampaign,
              dataPayment: data.payment_method
            })}`}
          />
          <BoxItem
            title="Origem da matrícula"
            subTitle={enrollmentOrigin ?? '--'}
          />
          <Styled.BoxLink>
            <a href={data.contract_path?.url} target="_blank" rel="noreferrer">
              Visualizar contrato
              <Styled.IconButton disableRipple>
                <OpenInNew />
              </Styled.IconButton>
            </a>
          </Styled.BoxLink>
        </Styled.Box>
        <Styled.BoxConfirm>
          <BoxItem title="Pagar com" />

          {!isRecorrencePayment && (
            <Button
              size="large"
              value="Baixar boleto"
              variant="outlined"
              onClick={() => window.open(data.bank_billing?.url, '_blank')}
              endIcon={<Download />}
            />
          )}

          <Styled.BoxLink>
            <Box
              onClick={() => {
                makeURLAccessLegacy(
                  `/externos/pagarGetNet.php?id=${data.pre_enrollment_id}`,
                  true
                )
              }}
            >
              Pagar via cartão
              <Styled.IconButton disableRipple>
                <OpenInNew />
              </Styled.IconButton>
            </Box>
          </Styled.BoxLink>
        </Styled.BoxConfirm>
      </Styled.Container>
      <Styled.Typography>
        {getInfoConfirmPayment(data.type_of_payment)?.sendEmailText}
      </Styled.Typography>

      <Styled.WrapperButton>
        <Button onClick={handleNewPreEnrollment}>Nova Pré-Matrícula</Button>
      </Styled.WrapperButton>
    </>
  )
}

export default EnrollmentCompleted
