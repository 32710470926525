import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import { typeMask } from 'services/helpers'
import { DatePicker } from 'components/atoms'
import { useAddress } from 'services/hooks'
import {
  createProfessors,
  handleGetCity,
  handleGetCityByState,
  updateProfessors,
  validateCPF
} from '../../services'
import { hasCPFText, optionsMaritalStatus } from './constants'
import * as Styled from './style'
import { schema } from './schema'

const FormPersonalData = ({
  isEdit,
  idProfessor,
  formPesonalData,
  setSnack,
  setLoadingOpen,
  setOptionsCity,
  optionsState,
  optionsCity,
  optionsRegional,
  setOptionsCityState,
  setNotPrefessorCreated,
  setFormPesonalData,
  setListAcademicEducation,
  setOpenModalConfirmCancel
}) => {
  const [address, setAddress] = useState({
    state: !isEdit ? '' : formPesonalData?.state
  })

  const {
    isUpdated,
    listCitys,
    listStates,
    fetchAddress,
    toggleUpdate,
    handleChangeCep,
    handleChangeState
  } = useAddress({
    address,
    setAddress
  })

  const navigate = useNavigate()
  const [isSearchByCep, setSearchByCep] = useState(false)
  const [hasCPFRegistered, setHasCPFRegistered] = useState(false)
  const { values, setFieldValue, handleSubmit, touched, errors, dirty } =
    useFormik({
      validateOnMount: true,
      enableReinitialize: true,
      initialValues: formPesonalData,
      onSubmit: () => {
        if (isEdit) {
          return updateProfessors({
            idProfessor,
            values,
            navigate,
            setSnack,
            setLoadingOpen,
            setNotPrefessorCreated,
            setFormPesonalData,
            setListAcademicEducation
          })
        }
        return createProfessors({
          values,
          setSnack,
          navigate,
          setLoadingOpen,
          setNotPrefessorCreated
        })
      },
      validationSchema: schema
    })

  useEffect(() => {
    if (isEdit) {
      if (formPesonalData.birth_uf) {
        handleGetCity({ value: formPesonalData.birth_uf, setOptionsCity })
      }

      if (formPesonalData.state) {
        handleGetCityByState({
          value: formPesonalData.state,
          setOptionsCityState
        })

        setAddress({
          city: formPesonalData.city,
          state: formPesonalData.state
        })
      }
    }
  }, [formPesonalData])

  useEffect(() => {
    if (isSearchByCep) {
      setFieldValue('neighborhood', address?.district)
      setFieldValue('state', address?.state)
      setFieldValue('city', address?.city)
      setFieldValue('street', address?.address)
      toggleUpdate()
    }
  }, [isUpdated])

  useEffect(async () => {
    if (isSearchByCep) {
      await fetchAddress(values?.zip_code)

      setSearchByCep(false)
    }
  }, [isSearchByCep])

  return (
    <form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            id="cpf"
            name="cpf"
            label="CPF"
            disabled={isEdit}
            value={typeMask('CPF', values?.cpf) || ''}
            inputProps={{ maxLength: 14 }}
            onChange={(e) => {
              setFieldValue('cpf', e.target.value)

              if (!isEdit) {
                setTimeout(
                  validateCPF({
                    cpf: e.target.value,
                    setSnack,
                    setHasCPFRegistered
                  }),
                  3000
                )
              }
            }}
            helperText={
              (errors && touched.cpf && errors.cpf) ||
              (hasCPFRegistered && hasCPFText)
            }
            error={Boolean((touched.cpf && errors.cpf) || hasCPFRegistered)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Nome Completo"
            value={values?.name || ''}
            onChange={(e) => setFieldValue('name', e.target.value)}
            helperText={errors && touched.name && errors.name}
            error={Boolean(touched.name && errors.name)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            id="social_name"
            name="social_name"
            label="Nome Social (opcional)"
            value={values?.social_name || ''}
            onChange={(e) => setFieldValue('social_name', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="E-mail"
            value={values?.email || ''}
            helperText={errors && touched.email && errors.email}
            error={Boolean(touched.email && errors.email)}
            onChange={(e) => setFieldValue('email', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextField
            fullWidth
            id="rg"
            name="rg"
            label="RG"
            value={values?.rg || ''}
            onChange={(e) => setFieldValue('rg', e.target.value)}
            helperText={errors && touched.rg && errors.rg}
            error={Boolean(touched.rg && errors.rg)}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextField
            fullWidth
            id="document_issue"
            name="document_issue"
            label="Orgão Emissor"
            value={values?.document_issue || ''}
            onChange={(e) => setFieldValue('document_issue', e.target.value)}
            helperText={
              errors && touched.document_issue && errors.document_issue
            }
            error={Boolean(touched.document_issue && errors.document_issue)}
            inputProps={{ maxLength: 6 }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextField
            fullWidth
            id="inep"
            name="inep"
            label="INEP/Educa Censo"
            value={values?.inep || ''}
            onChange={(e) => setFieldValue('inep', e.target.value)}
            helperText={errors && touched.inep && errors.inep}
            error={Boolean(touched.inep && errors.inep)}
            inputProps={{ maxLength: 12 }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextField
            fullWidth
            id="nis"
            name="nis"
            label="PIS/NIS"
            value={typeMask('PIS', values?.nis) || ''}
            onChange={(e) => setFieldValue('nis', e.target.value)}
            helperText={errors && touched.nis && errors.nis}
            error={Boolean(touched.nis && errors.nis)}
            inputProps={{ maxLength: 11 }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            id="mother_name"
            name="mother_name"
            label="Nome da Mãe"
            value={values?.mother_name || ''}
            onChange={(e) => setFieldValue('mother_name', e.target.value)}
            helperText={errors && touched.mother_name && errors.mother_name}
            error={Boolean(touched.mother_name && errors.mother_name)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            id="father_name"
            name="father_name"
            label="Nome do Pai"
            value={values?.father_name || ''}
            onChange={(e) => setFieldValue('father_name', e.target.value)}
            helperText={errors && touched.father_name && errors.father_name}
            error={Boolean(touched.father_name && errors.father_name)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Select
            id="marital_status"
            value={values?.marital_status || ''}
            label="Estado civil"
            optionsList={optionsMaritalStatus}
            onChange={(e) => setFieldValue('marital_status', e.target.value)}
            helperText={
              errors && touched.marital_status && errors.marital_status
            }
            error={Boolean(touched.marital_status && errors.marital_status)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <DatePicker
            fullWidth
            id="birth_date"
            label="Data de Nascimento"
            value={values?.birth_date || ''}
            onChange={(newValue) => setFieldValue('birth_date', newValue)}
            helperText={errors && touched.birth_date && errors.birth_date}
            error={Boolean(touched.birth_date && errors.birth_date)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Select
            id="birth_uf"
            value={values?.birth_uf || ''}
            label="Naturalidade (UF)"
            optionsList={optionsState}
            onChange={(e) => {
              handleGetCity({ value: e.target.value, setOptionsCity })
              setFieldValue('birth_uf', e.target.value)
            }}
            helperText={errors && touched.birth_uf && errors.birth_uf}
            error={Boolean(touched.birth_uf && errors.birth_uf)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Select
            id="birth_city"
            value={values?.birth_city || ''}
            disabled={!values?.birth_uf}
            label="Naturalidade (cidade)"
            optionsList={optionsCity}
            onChange={(e) => setFieldValue('birth_city', e.target.value)}
            helperText={errors && touched.birth_city && errors.birth_city}
            error={Boolean(touched.birth_city && errors.birth_city)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <DatePicker
            fullWidth
            id="admission_date"
            label="Data de Admissão"
            value={values?.admission_date || ''}
            onChange={(newValue) => setFieldValue('admission_date', newValue)}
            helperText={
              errors && touched.admission_date && errors.admission_date
            }
            error={Boolean(touched.admission_date && errors.admission_date)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            id="phone_number"
            name="phone_number"
            label="Telefone"
            value={typeMask('PHONE', values?.phone_number) || ''}
            onChange={(e) => setFieldValue('phone_number', e.target.value)}
            helperText={errors && touched.phone_number && errors.phone_number}
            error={Boolean(touched.phone_number && errors.phone_number)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Select
            id="regional"
            value={values?.regional || ''}
            label="Regional"
            optionsList={optionsRegional}
            onChange={(e) => setFieldValue('regional', e.target.value)}
            helperText={errors && touched.regional && errors.regional}
            error={Boolean(touched.regional && errors.regional)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            id="cell_number"
            name="cell_number"
            label="Celular"
            value={typeMask('PHONE', values?.cell_number) || ''}
            onChange={(e) => setFieldValue('cell_number', e.target.value)}
            helperText={errors && touched.cell_number && errors.cell_number}
            error={Boolean(touched.cell_number && errors.cell_number)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="h5">Endereço</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextField
            fullWidth
            label="CEP"
            id="zip_code"
            name="zip_code"
            onChange={(event) =>
              handleChangeCep({
                event,
                setFieldValue,
                setSearchByCep,
                fieldNameZipCode: 'zip_code'
              })
            }
            value={typeMask('CEP', values?.zip_code) || ''}
            error={Boolean(touched.zip_code && errors.zip_code)}
            helperText={errors && touched.zip_code && errors.zip_code}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Select
            id="state"
            value={values?.state || ''}
            label="Estado"
            optionsList={listStates}
            onChange={(event) => handleChangeState({ event, setFieldValue })}
            helperText={errors && touched.state && errors.state}
            error={Boolean(touched.state && errors.state)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Select
            id="city"
            label="Cidade"
            hasDontSensitive
            disabled={!values?.state}
            value={values?.city || ''}
            optionsList={listCitys}
            error={Boolean(touched.city && errors.city)}
            helperText={errors && touched.city && errors.city}
            onChange={(e) => setFieldValue('city', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            fullWidth
            id="street"
            name="street"
            label="Endereço"
            value={values?.street || ''}
            onChange={(e) => setFieldValue('street', e.target.value)}
            helperText={errors && touched.street && errors.street}
            error={Boolean(touched.street && errors.street)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextField
            fullWidth
            id="number"
            name="number"
            label="Número"
            value={values?.number || ''}
            onChange={(e) => setFieldValue('number', e.target.value)}
            helperText={errors && touched.number && errors.number}
            error={Boolean(touched.number && errors.number)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextField
            fullWidth
            id="complement_address"
            name="complement_address"
            label="Complemento (opcional)"
            value={values?.complement_address || ''}
            onChange={(e) =>
              setFieldValue('complement_address', e.target.value)
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextField
            fullWidth
            id="neighborhood"
            name="neighborhood"
            label="Bairro"
            value={values?.neighborhood || ''}
            onChange={(e) => setFieldValue('neighborhood', e.target.value)}
            helperText={errors && touched.neighborhood && errors.neighborhood}
            error={Boolean(touched.neighborhood && errors.neighborhood)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <ButtonFooterV2
            labelClose="Cancelar"
            labelConfirm="Salvar"
            size="large"
            onClickClose={() => setOpenModalConfirmCancel(true)}
            disabledConfirm={!dirty || hasCPFRegistered}
          />
        </Grid>
      </Styled.Grid>
    </form>
  )
}

export { FormPersonalData }
