import { InputAdornment, Tooltip, Typography } from '@mui/material'
import { Select, TextField } from 'components/molecules'
import { InputCheckbox } from 'components/atoms'
import * as Styled from './style'

const FormCreateTypesOfActivity = ({
  level,
  unitys,
  values,
  isEdit,
  message,
  setMessage,
  listCourses,
  setFieldValue
}) => (
  <>
    <Styled.Grid item xs={12} sm={12} md={7}>
      <TextField
        fullWidth
        isMargin="0"
        error={message}
        id="name"
        name="name"
        disabled={isEdit}
        helperText={message}
        label="Nome da atividade*"
        value={values?.name}
        onChange={(e) => {
          setMessage('')
          setFieldValue('name', e.target.value)
        }}
      />
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={2.8}>
      <TextField
        fullWidth
        isMargin="0"
        type="number"
        id="workload"
        name="workload"
        label="Carga horária"
        value={values?.workload}
        onChange={(e) => setFieldValue('workload', e.target.value)}
        InputProps={{
          endAdornment: <InputAdornment position="end">horas</InputAdornment>
        }}
      />
    </Styled.Grid>
    <Styled.Grid flex item xs={12} sm={12} md={2.2}>
      <Styled.Checkbox>
        <InputCheckbox
          label="Estágio"
          checked={values.is_intership}
          handleChange={(e) => setFieldValue('is_intership', e.target.checked)}
        />
      </Styled.Checkbox>
      <Tooltip title="Permitir solicitação via Portal do Aluno" placement="top">
        <Styled.Checkbox>
          <InputCheckbox
            label="Portal"
            checked={values.is_portal}
            handleChange={(e) => setFieldValue('is_portal', e.target.checked)}
          />
        </Styled.Checkbox>
      </Tooltip>
    </Styled.Grid>

    <Styled.Grid item flex xs={12} sm={12} md={6}>
      <Typography variant="subtitle2" fontWeight="bold">
        Restrições
      </Typography>
      <Tooltip title="Deixe em branco para usar em TODAS" placement="top">
        <Styled.Grid item xs={12} sm={12} md={12}>
          <Select
            id="unit"
            name="unit"
            label="Unidade"
            optionsList={unitys}
            value={values?.unit || ''}
            onChange={(e) => setFieldValue('unit', e.target.value)}
          />
        </Styled.Grid>
      </Tooltip>
    </Styled.Grid>

    <Styled.Grid item xs={12} sm={12} md={2}>
      <Select
        id="level"
        name="level"
        label="Nível"
        optionsList={level}
        value={values?.level || ''}
        onChange={(e) => setFieldValue('level', e.target.value)}
      />
    </Styled.Grid>

    <Styled.Grid item xs={12} sm={12} md={4}>
      <Select
        id="course"
        label="Curso"
        name="course"
        value={values?.course || ''}
        optionsList={listCourses}
        onChange={(e) => setFieldValue('course', e.target.value)}
      />
    </Styled.Grid>

    <Styled.Grid item xs={12} sm={12} md={12}>
      <TextField
        rows={6}
        fullWidth
        multiline
        isMargin="0"
        id="considerations"
        label="Orientações"
        name="considerations"
        value={values?.considerations}
        onChange={(e) => setFieldValue('considerations', e.target.value)}
      />
    </Styled.Grid>
  </>
)

export default FormCreateTypesOfActivity
