export const DIARY_TAG_MANAGER = {
  btn_teacher_requests_newClass: 'professor_btn_solicita_novaAula',
  btn_teacher_requests_deletes_Classes: 'professor_btn_solicita_excluiAulas',
  btn_teacher_confirms_deletes_class: 'professor_btn_confirma_excluiAula',
  btn_teacher_confirms_new_Class: 'professor_btn_confirma_novaAula',
  btn_teacher_requests_new_class: 'professor_btn_solicita_novaAula',
  btn_teacher_changes_activity: 'professor_btn_altera_atividade',
  btn_teacher_deletes_activity: 'professor_btn_exclui_atividade',
  btn_teacher_confirms_create_Activity: 'professor_btn_confirma_criarAtividade',
  btn_teacher_changes_frequency: 'professor_btn_altera_frequencia',
  btn_teacher_requests_create_Activity: 'professor_btn_solicita_criarAtividade',
  btn_teacher_confirms_attendance_Lot: 'professor_btn_confirma_frequenciaLote',
  btn_teacher_requests_attendance_Lot: 'professor_btn_solicita_frequenciaLote',
  btn_teacher_changes_atividadeOnline: 'professor_btn_altera_atividadeOnline',
  btn_teacher_requests_criarAtividadeOnline:
    'professor_btn_solicita_criarAtividadeOnline',
  btn_teacher_delete_atividadeOnline: 'professor_btn_exclui_atividadeOnline',
  btn_teacher_changes_recovery: 'professor_btn_altera_recuperacao',
  btn_teacher_delete_recovery: 'professor_btn_exclui_recuperacao',
  btn_teacher_request_newRecovery: 'professor_btn_solicita_criarRecuperacao'
}
