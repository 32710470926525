import { Formik } from 'formik'
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material'

import { Select, TextField } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import { convertDateToUTC } from 'services/helpers'
import schema from './schema'
import * as Styled from './style'
import { getHistoryStudent } from '../../../../services'

const FormSearch = ({
  setResponseCreateHitory,
  setLoading,
  setSnack,
  studentListCourse,
  studentInfo,
  setOpen,
  setStatus
}) => {
  const initialState = {
    ...studentInfo.student,
    ...studentInfo?.affiliation,
    school_data: studentInfo?.school_data
  }

  return (
    <Formik
      initialValues={initialState}
      onSubmit={(value) =>
        getHistoryStudent({
          value,
          studentInfo,
          setResponseCreateHitory,
          setLoading,
          setSnack,
          setStatus
        })
      }
      validationSchema={schema}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        handleChange
      }) => (
        <Styled.Form onSubmit={handleSubmit} noValidate autoComplete="true">
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12} sm={12} md={100}>
              <Select
                id="course"
                value={values?.course_id || ''}
                label="Selecione o curso"
                optionsList={studentListCourse}
                onChange={(e) => setFieldValue('course_id', e.target.value)}
              />
            </Grid>
          </Grid>

          {values?.course_id && (
            <>
              <Styled.TextTitle>Dados Pessoais</Styled.TextTitle>
              <Styled.Text>
                Obs: As informações abaixo são automáticas da Ficha do Aluno, ao
                alterar irá refletir na ficha.
              </Styled.Text>
              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Nacionalidade"
                    value={values.nationality || ''}
                    helperText={
                      errors && touched.nationality && errors.nationality
                    }
                    error={Boolean(touched.nationality && errors.nationality)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Naturalidade (UF)"
                    value={values.place_of_birth_state || ''}
                    helperText={
                      errors &&
                      touched.place_of_birth_state &&
                      errors.place_of_birth_state
                    }
                    error={Boolean(
                      touched.place_of_birth_state &&
                        errors.place_of_birth_state
                    )}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Naturalidade (Cidade)"
                    value={values.place_of_birth_city || ''}
                    helperText={
                      errors &&
                      touched.place_of_birth_city &&
                      errors.place_of_birth_city
                    }
                    error={Boolean(
                      touched.place_of_birth_city && errors.place_of_birth_city
                    )}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={6}>
                  <DatePicker
                    label="Data de Nascimento"
                    value={
                      (values.birth_date &&
                        convertDateToUTC(values.birth_date)) ||
                      null
                    }
                    helperText={
                      errors && touched.birth_date && errors.birth_date
                    }
                    error={Boolean(touched.birth_date && errors.birth_date)}
                    fullWidth
                    disabled
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormLabel>Gênero</FormLabel>
                  <RadioGroup value={values.sex || ''} row disabled>
                    <FormControlLabel
                      value="F"
                      control={<Radio size="small" />}
                      label="Feminino"
                    />
                    <FormControlLabel
                      value="M"
                      control={<Radio size="small" />}
                      label="Masculino"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={6}>
                  <TextField
                    label="Nome Completo do Pai"
                    value={values.father || ''}
                    fullWidth
                    disabled
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Nome Completo do Mãe"
                    value={values.mother || ''}
                    helperText={errors && touched.mother && errors.mother}
                    error={Boolean(touched.mother && errors.mother)}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={3}>
                  <TextField
                    label="CPF"
                    value={values.cpf || ''}
                    helperText={errors && touched.cpf && errors.cpf}
                    error={Boolean(touched.cpf && errors.cpf)}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Título de Eleitor"
                    value={values.vote_registration_number || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Zona"
                    value={values.vote_registration_zone || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Seção"
                    value={values.vote_registration_section || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={3}>
                  <TextField
                    label="RG"
                    value={values.rg || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    label="Data de Expedição (RG)*"
                    value={values.rg_dispatcher_date || null}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Orgão Expedidor"
                    value={values.rg_dispatcher || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="UF"
                    value={values.rg_state || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={3}>
                  <TextField
                    label="Documento Militar/Reservista"
                    value={values.reservist_number || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <DatePicker
                    label="Data de Expedição"
                    value={values.reservist_date_dispatcher || null}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Styled.TextTitle>Formação</Styled.TextTitle>
              <Styled.Text>
                Dados de formação do aluno, informe quando necessário ao
                documento.
              </Styled.Text>
              <Styled.Text>
                <strong>Ensino Fundamental</strong>
              </Styled.Text>

              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    label="Nome da Instituição"
                    name=""
                    value={
                      studentInfo.school_data
                        .map((elem) => elem.elementary_school_institution)
                        .join('') || ''
                    }
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={6}>
                  <TextField
                    label="Cidade / UF"
                    name=""
                    value={
                      studentInfo.school_data
                        .map((elem) => elem.elementary_school_location)
                        .join('') || ''
                    }
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Ano de Conclusão"
                    name=""
                    value={
                      studentInfo.school_data
                        .map((elem) => elem.elementary_school_finished_year)
                        .join('') || ''
                    }
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Styled.Text>
                <strong>Ensino Médio</strong>
              </Styled.Text>
              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    label="Nome da Instituição"
                    name=""
                    value={
                      studentInfo.school_data
                        .map((elem) => elem.high_school_institution)
                        .join('') || ''
                    }
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={6}>
                  <TextField
                    label="Cidade / UF"
                    name=""
                    value={
                      studentInfo.school_data
                        .map((elem) => elem.high_school_location)
                        .join('') || ''
                    }
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Ano de Conclusão"
                    name=""
                    value={
                      studentInfo.school_data
                        .map((elem) => elem.high_school_finished_year)
                        .join('') || ''
                    }
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Styled.Text>
                <strong>Observações</strong>
              </Styled.Text>
              <Styled.Text>
                Obs: Use as opções abaixo se necessário; O campo de Observações
                serve para todos os textos fora dos cadastros
                (aluno/curso/turma) e irá aparecer no final do documento{' '}
              </Styled.Text>

              <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    label="Observações"
                    name=""
                    value={values.observation || ''}
                    onChange={(e) =>
                      setFieldValue('observation', e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Styled.GroupButtons>
                  <Styled.ButtonCancel
                    variant="outlined"
                    type="reset"
                    value="Cancelar"
                    onClick={() => setOpen(true)}
                  />

                  <Styled.ButtonSubmit
                    variant="contained"
                    type="submit"
                    value="Salvar"
                  />
                </Styled.GroupButtons>
              </Grid>
            </>
          )}
        </Styled.Form>
      )}
    </Formik>
  )
}

export default FormSearch
