import { Grid } from '@mui/material'
import { Select, TextField } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import { typeMask } from 'services/helpers'
import { MAX_LENGTH_CPF } from '../../constants'

const FormListEnrollment = ({
  values,
  unitys,
  stateOptions,
  setFieldValue
}) => (
  <>
    <Grid item xs={12} sm={12} md={2}>
      <DatePicker
        fullWidth
        id="beginAt"
        name="beginAt"
        label="Período inicial*"
        value={values.beginAt || null}
        onChange={(newValue) => setFieldValue('beginAt', newValue)}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={2}>
      <DatePicker
        fullWidth
        id="endAt"
        name="endAt"
        label="Período final*"
        value={values.endAt || null}
        onChange={(newValue) => setFieldValue('endAt', newValue)}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={1}>
      <Select
        id="uf"
        name="uf"
        label="UF"
        value={values.uf}
        labelId="select-state"
        optionsList={stateOptions}
        onChange={(e) => {
          setFieldValue('uf', e.target.value)
        }}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={2}>
      <Select
        id="unity"
        label="Unidade"
        name="unity"
        optionsList={unitys}
        value={values?.unity}
        onChange={(e) => {
          setFieldValue('unity', e.target.value)
        }}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={2}>
      <TextField
        id="cpf"
        label="CPF"
        type="text"
        name="cpf"
        isMargin="0"
        value={typeMask('CPF', values.cpf)}
        inputProps={{ maxLength: MAX_LENGTH_CPF }}
        onChange={(e) => setFieldValue('cpf', e.target.value)}
      />
    </Grid>
  </>
)

export default FormListEnrollment
