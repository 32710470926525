import * as yup from 'yup'
import { PASSWORD_REGEX } from 'services/constants'

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .matches(
      PASSWORD_REGEX,
      'A senha deve conter pelo menos um número e uma letra',
    )
    .required('Esse campo é obrigatório'),
  confirmPassword: yup
    .string()
    .required('Esse campo é obrigatório')
    .when('newPassword', {
      is: (val) => !!(val && val.length > 0),
      then: yup
        .string()
        .oneOf([yup.ref('newPassword')], 'As senhas não conferem'),
    }),
})

export default schema
