import FEEDBACK_SNACK from 'feedBackSnack'
import {
  createByUpdateRegisterEadContent,
  deleteRegisterEadContent,
  getDisciplinesById,
  getRegisterEadContent
} from 'services/api/admin'
import { toastNotificationSuccess, updateErrorMessage } from 'services/helpers'
import {
  defaultItemsPerPage,
  defaultPage,
  messageSuccess,
  messageSuccessDelete
} from './constants'

const handleSearchSubject = async ({ id, setSubject, setLoading }) => {
  setLoading(true)

  const { data, error, status } = await getDisciplinesById(id)

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListRegisterEadContent
    })
  }

  return setSubject(data)
}

const getEadContentRegistrations = async ({
  id,
  setListContentEad,
  setLoading,
  filter,
  setCount,
  setFilter,
  pageActual
}) => {
  setLoading(true)

  const params = {
    page: pageActual || defaultPage,
    limit: filter || defaultItemsPerPage
  }

  const { data, error, status } = await getRegisterEadContent({ id, params })

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListRegisterEadContent
    })
  }

  setCount(data.count)
  setFilter(data.limit)

  return setListContentEad(data.data)
}

const createByEditEadContentRegistrations = async ({
  id,
  isEdit,
  idSubject,
  values,
  setLoading,
  setListContentEad,
  handleClose,
  count,
  filter,
  setCount,
  setFilter,
  pageActual
}) => {
  setLoading(true)

  const { error, status } = await createByUpdateRegisterEadContent({
    id,
    idSubject,
    isEdit,
    payload: values
  })

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListRegisterEadContent
    })
  }

  toastNotificationSuccess(messageSuccess(isEdit), 1200)
  handleClose()

  return getEadContentRegistrations({
    id,
    setListContentEad,
    setLoading,
    count,
    filter,
    setCount,
    setFilter,
    pageActual
  })
}

const deleteEadContentRegistrations = async ({
  id,
  idSubject,
  setLoading,
  setListContentEad,
  count,
  filter,
  setCount,
  setFilter,
  pageActual
}) => {
  setLoading(true)

  const { error, status } = await deleteRegisterEadContent({ idSubject, id })

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListRegisterEadContent
    })
  }

  toastNotificationSuccess(messageSuccessDelete, 1500)

  return getEadContentRegistrations({
    id,
    setListContentEad,
    setLoading,
    count,
    filter,
    setCount,
    setFilter,
    pageActual
  })
}

export {
  handleSearchSubject,
  getEadContentRegistrations,
  createByEditEadContentRegistrations,
  deleteEadContentRegistrations
}
