const initialValues = {
  nivel: '',
  type: '',
  isFinished: false,
  finishedYear: '',
  institution: '',
  state: '',
  city: ''
}

const levelList = [
  {
    label: 'Ensino Médio',
    value: 'highSchool'
  },
  {
    label: 'Ensino Fundamental',
    value: 'elementarySchool'
  }
]

const typeList = [
  {
    label: 'Regular',
    value: 'Regular'
  },
  {
    label: 'Eja',
    value: 'Eja'
  },
  {
    label: 'Regular - EAD',
    value: 'Regular - EAD'
  },
  {
    label: 'Eja - EAD',
    value: 'Eja - EAD'
  }
]

const serviceMessage = {
  successMessage: 'Dados salvo com sucesso.',
  feedbackMessage: 'Houve um erro ao salvar. Tente novamente.'
}

export { initialValues, levelList, typeList, serviceMessage }
