import { Unstable_Grid2 as Grid, Typography } from '@mui/material'
import { formatDate } from 'services/helpers'

export default function ClassInfo({ info, contentToAction }) {
  return (
    <Grid container spacing={{ xs: 1, sm: 2 }}>
      <Grid xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Grid xs={contentToAction ? 6 : 12}>
            <Typography variant="h5">Turma {info.code}</Typography>
          </Grid>
          {contentToAction && <Grid xs={6}>{contentToAction}</Grid>}
        </Grid>
      </Grid>
      <Grid xs={12} sm={4}>
        <Typography>
          <b>Unidade</b> {info.unit}
        </Typography>
      </Grid>
      <Grid xs={12} sm={4}>
        <Typography>
          <b>Grupo</b> {info.group}
        </Typography>
      </Grid>
      <Grid xs={12} sm={4}>
        <Typography>
          <b>Modalidade</b> {info.modality}
        </Typography>
      </Grid>
      <Grid xs={12} sm={4}>
        <Typography>
          <b>Nível</b> {info.level}
        </Typography>
      </Grid>
      <Grid xs={12} sm={4}>
        <Typography>
          <b>Curso</b> {info.name}
        </Typography>
      </Grid>
      <Grid xs={12} sm={4}>
        <Typography>
          <b>Turno</b> {info.shift}
        </Typography>
      </Grid>
      <Grid xs={12} sm={4}>
        <Typography>
          <b>Início</b> {formatDate(info.begin_at)}
        </Typography>
      </Grid>
      <Grid xs={12} sm={4}>
        <Typography>
          <b>Fim</b> {formatDate(info.end_at)}
        </Typography>
      </Grid>
    </Grid>
  )
}
