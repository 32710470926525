import { ENDPOINT } from 'services/constants'
import { httpGet, httpPut } from 'services/api/http'

export const listReEnrollment = async ({ username }) => {
  const response = await httpGet(
    `${ENDPOINT.RE_ENROLLMENT.DETAILS}/term?register=${username}`
  )
  return response
}

export const acceptTermReEnrollment = async (id, isAutomatic = false) => {
  const response = await httpPut(
    `${ENDPOINT.RE_ENROLLMENT.DETAILS}/confirm/${id}`,
    { automatic: isAutomatic }
  )
  return response
}
