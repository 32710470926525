import { Feed, QueryStats } from '@mui/icons-material'

const reasonReplacements = [
  {
    value: 'Curso de Aprimoramento Profissional',
    label: 'Curso de Aprimoramento Profissional'
  },
  { value: 'Aula normal', label: 'Aula normal' },
  { value: 'Aula Prática', label: 'Aula Prática' },
  { value: 'Reposição de aulas', label: 'Reposição de aulas' },
  { value: 'Produção de conteúdo', label: 'Produção de conteúdo' },
  { value: 'Prova de recuperação', label: 'Prova de recuperação' },
  { value: 'Aula extra/adicional', label: 'Aula extra/adicional' },
  { value: 'Prova de segunda chamada', label: 'Prova de segunda chamada' },
  { value: 'Substituição com atestado', label: 'Substituição com atestado' },
  { value: 'Substituição sem atestado', label: 'Substituição sem atestado' }
]

const getMenuItems = (setTypeModalPole, setOpen) => [
  {
    label: 'Dashboard',
    icon: <QueryStats />,
    className: 'academico_btn_acessa_dashboard',
    onClick: () => {
      setOpen(true)
      setTypeModalPole('dash')
    }
  },
  {
    label: 'Relatório de Presença',
    icon: <Feed />,
    className: 'academico_btn_acessa_relatorioPresenca',
    onClick: () => {
      setOpen(true)
      setTypeModalPole('attendanceReport')
    }
  }
]

export { getMenuItems, reasonReplacements }
