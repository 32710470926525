import { TableHeaderDefault } from 'components/organisms'
import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  Typography
} from '@mui/material'
import * as Styled from './style'
import { emptyValueMoney, headCells } from './constants'
import { TableFooter } from './TableFooter'
import { parserTotalHours, parserTotalValue } from './helpers'

const Table = ({ rows }) => (
  <Styled.Paper>
    <Styled.TableContainer>
      <MuiTable>
        <TableHeaderDefault headCells={headCells} rowCount={rows?.length} />
        <TableBody>
          {rows?.map((row) => (
            <TableFooter
              key={`${row.lesson_date}-${row.discipline_id}`}
              row={row}
            />
          ))}

          <Styled.TableRowTotalHoursValues tabIndex={-1}>
            <TableCell colSpan={3}>
              <Box>
                <Typography variant="body2" compoent="body2">
                  Total de horas
                  <br />
                  <b>{parserTotalHours(rows)}</b>
                </Typography>
              </Box>
            </TableCell>

            <TableCell className="totalValue" colSpan={9}>
              <Box>
                <Typography variant="body2" compoent="body2">
                  Valor Total
                  <br />
                  <b>{parserTotalValue(rows) || emptyValueMoney}</b>
                </Typography>
              </Box>
            </TableCell>
          </Styled.TableRowTotalHoursValues>
        </TableBody>
      </MuiTable>
    </Styled.TableContainer>
  </Styled.Paper>
)

export default Table
