import styled from 'styled-components'
import {
  Toolbar as MuiToolbar,
  Paper as MuiPaper,
  TableContainer as MuiTableContainer
} from '@mui/material'
import { Button as AtomButton } from 'components/atoms'

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
  }
`

const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    margin-top: 24px;
  }
`

const Toolbar = styled(MuiToolbar)`
  &.MuiToolbar-root {
    padding: 0;
    margin-top: 24px;
    align-items: center;
    display: ${({ $displayNone }) => ($displayNone ? 'none' : 'flex')};
  }
`

const Button = styled(AtomButton)`
  &.MuiButtonBase-root {
    padding: 4px 8px;
  }
`
const GroupButtons = styled.div`
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-end;
  margin: 1rem 0;
`


const ButtonSubmit = styled(Button)`
  &.MuiButton-root {
    margin: 10px 0 50px 35px;
  }
`

const ButtonCancel = styled(Button)`
  &.MuiButton-root {
    margin: 10px 0 50px 35px;
  }
`

export { Toolbar, Button, Paper, TableContainer, ButtonSubmit, ButtonCancel, GroupButtons }
