import React, { lazy, useEffect, useState } from 'react'
import humps from 'humps'
import { AttachMoney, Search } from '@mui/icons-material'
import { TemplateStudentFinancial as Template } from 'components/templates/Student'
import { PageTitle, TextNotFound } from 'components/atoms'
import { LoadingPage, Snackbar } from 'components/molecules'
import {
  dateFormat,
  DATE_FORMAT,
  getErrorMessage,
  getUserFromLocalStorage,
  IsEmpty
} from 'services/helpers'
import { usePagination, useSnack } from 'services/hooks'
import { generatePDF } from 'services/helpers/generatePDF'
import { getFinancial } from 'services/api/student'
import { ERROR } from 'services/constants'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { TabView } from 'components/organisms'
import { statusList } from './components/FilterDetail/constants'
import {
  defaultPage,
  DEFAULT_ITEMS,
  STATUS_ALL,
  textFilterNotFound,
  textNotFound,
  ERROR_MESSAGE
} from './constants'
import * as Styled from './style'
import ManagePayments from './components/ManagePayments'
import { StudentFinancialProvider } from './context'

const FilterDetail = lazy(() => import('./components/FilterDetail'))
const TableFinancial = lazy(() => import('./components/TableFinancial'))

export default function FinancialStudent() {
  return (
    <StudentFinancialProvider>
      <Content />
    </StudentFinancialProvider>
  )
}

const Content = () => {
  const [contentTable, setContentTable] = useState([])
  const [filterEmpty, setFilterEmpty] = useState(false)
  const { setLoading } = useLoading()
  const { username } = getUserFromLocalStorage()

  const { activePage, totalPages, handleSetMaxPageNumber, handleSetPage } =
    usePagination()

  const { snack, setSnack, snackOpen, updateSnack } = useSnack()

  const InitialDetailsFinancial = {
    register: username,
    page: activePage,
    limit: defaultPage
  }

  const getDetailsFinancial = async () => {
    setLoading(true)
    updateSnack({})
    const { data, error, status } = await getFinancial(InitialDetailsFinancial)

    handleSetMaxPageNumber(data?.count, defaultPage)
    setFilterEmpty(false)
    setContentTable(humps.camelizeKeys(data?.data))
    setLoading(false)
    if (error) {
      const message = getErrorMessage(ERROR_MESSAGE, status)
      setSnack(message, ERROR)
    }
  }

  const getFilter = async ({ status, firstPeriod, endPeriod, limit }) => {
    setLoading(true)
    const typeStatus = statusList.filter(({ name }) => name === status)[0].value

    const filterParms = {
      register: username,
      start_date: dateFormat(firstPeriod, DATE_FORMAT.YYYY_MM_DD),
      limit
    }

    const filters = {
      ...filterParms,
      ...(typeStatus !== STATUS_ALL && { status: typeStatus }),
      ...(endPeriod && {
        end_date: dateFormat(endPeriod, DATE_FORMAT.YYYY_MM_DD)
      })
    }

    const { data, error, status: statusCode } = await getFinancial(filters)

    if (IsEmpty(data?.data)) {
      setFilterEmpty(true)
      setLoading(false)
      return setContentTable(humps.camelizeKeys(data?.data))
    }
    handleSetMaxPageNumber(data?.count, limit)
    setLoading(false)
    if (error) {
      const message = getErrorMessage(ERROR_MESSAGE, statusCode)
      setSnack(message, ERROR)
    }
    return setContentTable(humps.camelizeKeys(data?.data))
  }

  useEffect(() => getDetailsFinancial(), [activePage])

  return (
    <Template
      titleHeader={<PageTitle title="Detalhamento Financeiro" divider />}
      content={
        <>
          <FilterDetail
            onClear={getDetailsFinancial}
            onHandleFilter={getFilter}
            exportPDF={() => generatePDF(contentTable)}
          />
          <TabView
            tabs={[
              {
                label: 'Meus Títulos',
                value: 'myBankBillings',
                component: () => (
                  <>
                    {!IsEmpty(contentTable) ? (
                      <>
                        <TableFinancial contentFinancial={contentTable} />
                        {totalPages > DEFAULT_ITEMS && (
                          <Styled.Pagination
                            count={totalPages}
                            page={activePage}
                            onChange={handleSetPage}
                          />
                        )}
                      </>
                    ) : (
                      <TextNotFound
                        icon={filterEmpty ? <Search /> : <AttachMoney />}
                        text={filterEmpty ? textFilterNotFound : textNotFound}
                      />
                    )}
                  </>
                )
              },
              {
                label: 'Gerenciar pagamentos',
                value: 'managePayments',
                component: ManagePayments
              }
            ]}
          />
        </>
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}
