import { deserialize, messageErroOrSuccess, serialize } from 'services/helpers'
import {
  getGroupedDisciplines as getGroupedDisciplinesService,
  excludeSchoolClassGrouping as excludeSchoolClassGroupingService
} from 'services/api/admin'

const getGroupedDisciplines = async ({
  setSnack,
  setDisciplines,
  setLoading,
  groupingDisciplines,
  callbackSuccess
}) => {
  setLoading(true)

  const { error, status, data } = await getGroupedDisciplinesService({
    groupingDisciplines
  })

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  if (!error) {
    setDisciplines(data?.map(serialize))
    setTimeout(callbackSuccess, 1000)
  }

  setLoading(false)
}

const excludeSchoolClassGrouping = async ({
  groupingId,
  disciplineId,
  setSnack,
  callbackSuccess
}) => {
  const { error, status } = await excludeSchoolClassGroupingService({
    groupingId,
    payload: deserialize({
      disciplineId: `${disciplineId}`
    })
  })

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  if (!error) {
    setTimeout(callbackSuccess, 1000)
  }
}

export { getGroupedDisciplines, excludeSchoolClassGrouping }
