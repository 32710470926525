const FREE_DISCIPLINE = 'LIVRE'

const assessmentList = [
  {
    value: 'SOMA',
    label: 'NOTA'
  },
  {
    value: 'CONCEITO',
    label: 'CONCEITO'
  },
  {
    value: FREE_DISCIPLINE,
    label: FREE_DISCIPLINE
  }
]

const ZERO_NUMBER = 0
const ZERO_DECIMAL = '00'

const defaultItem = 1

export {
  assessmentList,
  defaultItem,
  ZERO_NUMBER,
  ZERO_DECIMAL,
  FREE_DISCIPLINE
}
