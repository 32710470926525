import styled from 'styled-components'
import { Typography } from '@mui/material'
import theme from 'theme/designTokens'


const SubTitle = styled(Typography)`
  &.MuiTypography-root {
    display: flex;
    align-items: center;
    padding: 15px 0;
    font-weight: 600;
    & svg {
      color: ${theme.palette.warning.main};
      margin-right: 10px;
    }
  }
`

export { SubTitle }
