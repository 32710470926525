import React, { useState, lazy } from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { ActivityTable } from 'components/organisms/Table'
import { PageTitle, PaginationBasic } from 'components/atoms'
import {
  useGetGrades,
  useActionSelected,
  usePaginationBasic
} from 'services/hooks'
import { isEmpty } from 'services/helpers'
import {
  maxPage,
  itemsPerPage,
  typeCreate,
  typeEdit,
  typeDelete,
  typeScore,
  validButtonText,
  classManager
} from '../constants'
import { activityTypeEnum } from '../config'
import { getListGroup } from '../getListGroup'
import * as Styled from '../style'

const CreateNewActivity = lazy(() => import('../components/CreateNewActivity'))
const EditActivity = lazy(() => import('../components/EditActivity'))
const ModalConfirmByDelete = lazy(() =>
  import('../components/ModalConfirmByDelete')
)
const ModalInformative = lazy(() => import('../components/ModalInformative'))

const Diversified = ({ params }) => {
  const { setIsLoading, isLikeNovoTech } = params
  const [activityUseList, setActivityUseList] = useState(
    getListGroup(activityTypeEnum.DIVERSIFIED, isLikeNovoTech)
  )

  const { activity, studentsAll, onHandleListClass, dropouts } = useGetGrades(
    activityTypeEnum.DIVERSIFIED,
    setIsLoading
  )

  const { onActionSelected, selected, onResetAction } = useActionSelected()

  const { page, totalPage, items, handlePrevPage, handleNextPage, setPage } =
    usePaginationBasic(activity, itemsPerPage)

  const onReset = async () => {
    setActivityUseList(
      getListGroup(activityTypeEnum.DIVERSIFIED, isLikeNovoTech)
    )
    onResetAction()
  }

  const listItems = items?.length
  const messageButton = listItems >= validButtonText ? '' : 'CRIAR ATIVIDADE'

  return (
    <Styled.Container>
      {items && totalPage >= maxPage && (
        <PaginationBasic
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          from={page}
          to={totalPage}
        />
      )}
      <ActivityTable
        classButtonRemove={classManager.diversifiedButtonRemove}
        classButtonAdd={classManager.diversifiedButtonAdd}
        classButtonNew={classManager.diversifiedButtonNew}
        activity={items}
        studentsAll={[
          ...studentsAll,
          ...dropouts.map((item) => ({ ...item, hasDropouts: true }))
        ]}
        entityTitle="atividades onlines"
        titleButton={messageButton}
        startIcon={<AddCircleIcon />}
        activityTypeInitiated={activityTypeEnum.DIVERSIFIED}
        handleCreateActivity={() => onActionSelected(typeCreate, {})}
        handleEditActivity={(item) => onActionSelected(typeEdit, item)}
        handleDeleteActivity={(item) => onActionSelected(typeDelete, item)}
        handleScore={(item) => onActionSelected(typeScore, item)}
        setSnack={params?.setSnack}
        dropouts
      />

      {!isEmpty(studentsAll) && !isEmpty(dropouts) && (
        <Styled.BoxDropouts>
          <PageTitle title="Alunos desistentes/cancelados/remanejados" />
          <ActivityTable
            activity={items}
            studentsAll={dropouts}
            activityTypeInitiated={activityTypeEnum.DIVERSIFIED}
            dropouts={false}
            disableActions
          />
        </Styled.BoxDropouts>
      )}

      <CreateNewActivity
        isNovoTech={isLikeNovoTech}
        open={selected?.type === typeCreate}
        closeDialog={onReset}
        titleActivity="Nova Atividade Online"
        titleAdd="Criar"
        activityTypeInitiated={activityTypeEnum.DIVERSIFIED}
        onHandleListClass={onHandleListClass}
        setSnack={params?.setSnack}
        setActivityUseList={setActivityUseList}
        activityUseList={activityUseList}
      />

      <EditActivity
        isNovoTech={isLikeNovoTech}
        open={selected?.type === typeEdit}
        closeDialog={onResetAction}
        titleActivity="Editar Atividade Online"
        titleAdd="Salvar"
        activityTypeInitiated={activityTypeEnum.DIVERSIFIED}
        onHandleListClass={onHandleListClass}
        activityToEditItem={selected?.data}
        setSnack={params?.setSnack}
      />

      <ModalConfirmByDelete
        open={selected?.type === typeDelete}
        closeDialog={onResetAction}
        activityToDelete={selected?.data}
        onHandleListClass={onHandleListClass}
        setPage={setPage}
        listItems={listItems}
        setSnack={params?.setSnack}
      />

      <ModalInformative
        open={selected?.type === typeScore}
        closeDialog={onResetAction}
        score={selected?.data}
      />
    </Styled.Container>
  )
}

export default Diversified
