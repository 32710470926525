import { useEffect, useRef, useState } from 'react'
import { Typography } from '@mui/material'
import { Button, Modal, InputQuill } from 'components/atoms'
import { ModalConfirm } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'

const ModalFeedback = ({
  open,
  setShowConfirmFeedback,
  handleSubmit,
  values,
  setFieldValue,
  student
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const clearEditor = () => setFieldValue('feedback', '')

  const handleCancel = () => {
    clearEditor()
    setShowConfirmFeedback(false)
  }
  const handleConfirm = () => {
    setShowConfirmFeedback(false)
    setShowConfirmDialog(true)
  }

  const handleCancelConfirm = () => {
    clearEditor()
    setShowConfirmDialog(false)
  }
  const handleConfirmConfirm = async () => {
    setShowConfirmDialog(false)
    await handleSubmit()
  }

  const quillRef = useRef()

  useEffect(() => {
    if (open) clearEditor()
  }, [open])

  const isEmpty = quillRef?.current?.getEditor()?.getLength() === 1

  return (
    <>
      <Modal open={open} onClose={handleCancel} fullWidth maxWidth="md">
        <Styled.Card>
          <Styled.Title>
            Parecer Final do Aproveitamendo de Estudos
          </Styled.Title>

          <Styled.CardStudent>
            <Styled.Label>
              <b>Matricula</b>
            </Styled.Label>
            <Styled.Value>{student.register}</Styled.Value>
            <Styled.Label>
              <b>Aluno</b>
            </Styled.Label>
            <Styled.Value>{student.name}</Styled.Value>
          </Styled.CardStudent>

          {open && (
            <InputQuill
              values={values.feedback || ''}
              referencie={quillRef}
              label="Texto do Parecer Final"
              handleChange={(value) => setFieldValue('feedback', value)}
            />
          )}

          <Typography variant="caption" sx={{ margin: '8px' }}>
            Esta é uma etapa de conclusão da análise de aproveitamento. Ao
            confirmar o Parecer Final, este aproveitamento <b>não</b> poderá ser
            mais editado.
          </Typography>

          <Styled.CardFooter>
            <Button
              onClick={handleCancel}
              size="large"
              variant="outlined"
              sx={{ marginRight: '10px' }}
            >
              Cancelar
            </Button>

            <Button
              disabled={isEmpty}
              onClick={handleConfirm}
              size="large"
              variant="contained"
              className={TAG_MANAGER.secretary_btn_confirm_create_feedback_academic_performance}
            >
              Confirmar
            </Button>
          </Styled.CardFooter>
        </Styled.Card>
      </Modal>

      <ModalConfirm
        open={showConfirmDialog}
        size="medium"
        textButtonNotConfirm="não"
        textButtonConfirm="sim"
        onClickNotConfirm={handleCancelConfirm}
        onClickConfirm={handleConfirmConfirm}
        iconWarningAmber
        title="Confirmar Parecer Final"
        classNameBtnConfirm={TAG_MANAGER.secretary_btn_confirm_creation_feedback_academic_performance}
        message={
          <>
            Deseja mesmo confirmar o Parecer Final?
            <br />
            Ao confirmar, você <b>não</b> poderá mais alterar essa solicitação
            de aproveitamento de estudos.
          </>
        }
      />
    </>
  )
}

export default ModalFeedback
