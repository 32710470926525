import { httpDelete, httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getCourses = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_COURSES}?${convertToParams(params)}`
  )

  return response
}

export const getCourse = async (id) => {
  const response = await httpGet(`${ENDPOINT.ADMIN_MANAGEMENT_COURSES}/${id}`)

  return response
}

export const getDocsRequiredSaved = async (idCourse) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_COURSES}/${idCourse}/required-documents`
  )

  return response
}

export const createByUpdateDocsRequired = async (idCourse, payload) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_MANAGEMENT_COURSES}/${idCourse}/required-documents`,
    {
      ...payload
    }
  )

  return response
}

export const updateCourse = async (id, payload) => {
  const response = await httpPut(`${ENDPOINT.ADMIN_MANAGEMENT_COURSES}/${id}`, {
    ...payload
  })

  return response
}

export const deleteCourses = async (payload) => {
  const response = await httpDelete(`${ENDPOINT.ADMIN_MANAGEMENT_COURSES}`, {
    ...payload
  })

  return response
}

export const createCourses = async (payload) => {
  const response = await httpPost(`${ENDPOINT.ADMIN_MANAGEMENT_COURSES}`, {
    ...payload
  })

  return response
}
