import styled from 'styled-components'
import { Toolbar as MuiToolbar, Grid as MuiGrid, Box } from '@mui/material'
import { Button as AtomButton } from 'components/atoms'
import theme from 'theme/designTokens'

const Grid = styled(MuiGrid)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Toolbar = styled(MuiToolbar)`
  &.MuiToolbar-root {
    padding: 0;
    margin-top: 24px;
    width: 100%;
    display: ${({ $displayNone }) => ($displayNone ? 'none' : 'block')};
  }
`

const ToolbarBatchActions = styled(Box)`
  &.MuiBox-root {
    padding: 0;
    margin-top: 24px;
    width: 100%;
    display: flex;
  }
`

const Button = styled(AtomButton)`
  &.MuiButtonBase-root {
    padding: 4px 8px;
    margin-bottom: 0;
    margin-left: 10px;
    width: 100%;
  }
`

const ButtonBatchActions = styled(AtomButton)`
  &.MuiButtonBase-root {
    padding: 4px 8px;
    margin: 0 10px 0;
    border-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.main};
    width: 25%;
  }
`

const GridBatchActions = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    margin-top: 45px;
  }
`

export {
  Toolbar,
  Button,
  Grid,
  ToolbarBatchActions,
  ButtonBatchActions,
  GridBatchActions
}
