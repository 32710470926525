import { ROUTE } from 'services/constants'

const breadcrumbsItems = (lastLabel) => [
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: 'Secretaria' },
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: 'Alunos' },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER,
    label: `<b>${lastLabel}</b>`
  }
]

export { breadcrumbsItems }
