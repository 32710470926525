import { useState, lazy } from 'react'
import { Table as MuiTable, TableBody, TableContainer } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { ModalConfirm, PaginationAdvanced } from 'components/molecules'
import {
  useActionSelected,
  useCheckedTable,
  useSortTable
} from 'services/hooks'
import { IsEmpty, paginationFilter, updateErrorMessage } from 'services/helpers'
import { inactiveGroup, removeGroup } from 'services/api/admin'
import { SUCCESS } from 'services/constants'
import { useGroups } from '../../context/FormContext'
import { MESSAGE } from '../../constants'
import { defaultPage, headCells, itemSelected, type } from './constants'
import * as Styled from './style'

const TableHeader = lazy(() => import('./TableHeader'))
const TableFooter = lazy(() => import('./TableFooter'))
const Toolbar = lazy(() => import('./Toolbar'))

const Table = ({
  itemsTable,
  defaultValue,
  totalPage,
  pageActual,
  filter,
  setFilter,
  setPage,
  count,
  setLoadingOpen,
  setSnack,
  onSubmit,
  handleSetPage
}) => {
  const [openModalInactive, setOpenModalInactive] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const { formValues } = useGroups()

  const { onActionSelected, selected, onResetAction } = useActionSelected()

  const { handleRequestSort, order, orderBy, sortTable } =
    useSortTable(itemsTable)

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected: selectedItem,
    setSelected
  } = useCheckedTable(itemsTable, 'id')

  const handleInactive = async () => {
    setSnack('', '')
    setLoadingOpen(true)
    const params = {
      ids: selectedItem,
      active: false
    }

    const { error, status } = await inactiveGroup(params)
    setLoadingOpen(false)
    if (!error) {
      if (pageActual === defaultPage) {
        onSubmit(formValues)
      }
      setPage(defaultPage)
      onResetAction()
      setSelected([])
      return setSnack(MESSAGE.inactiveSucces, SUCCESS)
    }

    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToInactiveGroup
    })
  }

  const handleRemove = async () => {
    setSnack('', '')
    setLoadingOpen(true)
    const params = {
      ids: selectedItem
    }
    const { error, status } = await removeGroup(params)
    setLoadingOpen(false)
    if (!error) {
      if (pageActual === defaultPage) {
        onSubmit(formValues)
      }
      setPage(defaultPage)
      onResetAction()
      setSelected([])
      return setSnack(MESSAGE.removedSucces, SUCCESS)
    }

    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToRemoveGroup
    })
  }

  return (
    <>
      <Toolbar
        selected={selectedItem}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setOpenModalInactive={setOpenModalInactive}
        openModalInactive={openModalInactive}
        handleInactive={(item) => onActionSelected(type.inactive, item)}
        handleRemove={(item) => onActionSelected(type.remove, item)}
      />

      {!IsEmpty(selectedItem) &&
        selectedItem?.length > itemSelected &&
        `${selectedItem?.length} itens selecionados`}
      <Styled.TableContainer>
        <TableContainer>
          <MuiTable size="small">
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              numSelected={selectedItem.length}
              handleSelectAllClick={handleSelectAllClick}
              rowCount={itemsTable?.length}
              headCells={headCells}
              checkbox
            />

            <TableBody>
              {sortTable?.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-${index}`

                return (
                  <TableFooter
                    key={row.id}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    item={row}
                    handleClick={handleClick}
                    selected={selectedItem}
                  />
                )
              })}
            </TableBody>
          </MuiTable>
        </TableContainer>
        {count > defaultValue && (
          <PaginationAdvanced
            smallSize
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={pageActual}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setFilter(e.target.value)
              setPage(defaultPage)
            }}
          />
        )}
      </Styled.TableContainer>

      <ModalConfirm
        open={selected?.type === type.remove}
        icon={<WarningAmber color="warning" fontSize="large" />}
        title="Excluir Grupo"
        message="Você tem certeza que deseja excluir este grupo?"
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        colorButtonConfirm="error"
        onClickNotConfirm={onResetAction}
        onClickConfirm={(item) => handleRemove(item)}
      />

      <ModalConfirm
        open={selected?.type === type.inactive}
        icon={<WarningAmber color="warning" fontSize="large" />}
        title="Inativar Grupo"
        message="Você tem certeza que deseja inativar este grupo?"
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        colorButtonConfirm="error"
        onClickNotConfirm={onResetAction}
        onClickConfirm={(item) => handleInactive(item)}
      />
    </>
  )
}
export default Table
