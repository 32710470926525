import getEnvAccess from './getEnvAccess'

function getHumanResourcesPermissions(permissions) {
  const hasPermission = getEnvAccess()
  const humanResources = permissions?.hr || {}
  const permissionsProfessor = humanResources.professor || {}
  const permissionsDocuments = permissionsProfessor.document || {}
  const permissionsInteractions = permissionsProfessor || {}
  const permissionsTimeTracking = humanResources?.timekeeping?.payroll || {}
  const permissionsSchoolClass =
    humanResources?.timekeeping?.school_class_management || {}
  const permissionsReceipts =
    humanResources?.timekeeping?.receipt_management || {}
  const permissionsConsolidation =
    humanResources?.timekeeping?.consolidation || {}

  const HUMAN_RESOURCES_PERMISSIONS = {
    PROFESSOR: Boolean(permissionsProfessor),
    HUMAN_RESOURCES: Boolean(humanResources),
    CREATE_PROFESSOR: Boolean(permissionsProfessor?.POST),
    DETAILS_PROFESSOR: Boolean(permissionsProfessor?.GET),
    PROFESSOR_REPLACE_CLASS: Boolean(permissionsProfessor?.GET),
    PROFESSOR_CLASS_LINKED: Boolean(permissionsProfessor?.GET),
    DOCUMENT_FILES_PROFESSOR: Boolean(permissionsDocuments?.GET),
    INTERACTIONS_PROFESSOR: Boolean(permissionsInteractions?.GET),
    PROFESSOR_REPLACE_CLASS_DETAILS: Boolean(permissionsProfessor?.GET),
    PROFESSOR_CLASS_LINKED_DETAILS: Boolean(permissionsProfessor?.GET),
    TIME_TRACKING_PAYROLL_LIST: Boolean(permissionsTimeTracking?.GET),
    TIME_TRACKING_PAYROLL_CREATE: Boolean(permissionsTimeTracking?.POST),
    TIME_TRACKING_PAYROLL_UPDATE: Boolean(permissionsTimeTracking?.PUT),
    TIME_TRACKING_MANAGE_CLASSES: Boolean(permissionsSchoolClass?.GET),
    TIME_TRACKING_MANAGE_RECEIPTS: Boolean(permissionsReceipts?.GET),
    TIME_TRACKING_MANAGE_CONSOLIDATION: Boolean(permissionsConsolidation?.GET)
  }
  return HUMAN_RESOURCES_PERMISSIONS
}

export default getHumanResourcesPermissions
