export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const defaultPage = 1
export const defaultItemsPerPage = 10

const headCellsPending = [
  {
    id: 'name',
    label: 'Atividade'
  },
  {
    id: 'unit',
    label: 'Unidade'
  },
  {
    id: 'level',
    label: 'Nível'
  },
  {
    id: 'course',
    label: 'Curso'
  },
  {
    id: 'workload',
    label: 'Carga Horária'
  },
  {
    id: 'is_portal',
    label: 'Portal'
  }
]

export { headCellsPending }
