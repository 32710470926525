import { lazy, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListAlt, Search } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { getListCupon } from 'services/api/admin'
import { IsEmpty, mergeInitialValues } from 'services/helpers'
import { Form, Formik } from 'formik'
import {
  MESSAGE,
  defaultItemsPerPage,
  lengthUnits,
  nameAccordion,
  newCupon
} from './constants'
import { useCupon } from './Context/FormContext'
import { fetchData, getParams } from './services'

const initialState = {
  unity: '',
  polo: '',
  level: '',
  course: ''
}

const FormCupon = lazy(() => import('./components/FormCupon'))
const TableCupon = lazy(() => import('./components/TableCupon'))

const ListCupon = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const snackProps = useSnack()
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [valuesForm, setvaluesForm] = useState(null)
  const [apiResult, setApiResult] = useState({
    list: [],
    count: null
  })

  const { formValues, setFormValues, resetFormValues } = useCupon()
  const { snackOpen, setSnack } = snackProps

  const isSearchParam = location?.state?.savedSearch || false
  const isParamSavedCupon = location?.state?.savedCupon || false
  const isParamUpdateCupon = location?.state?.updateCupon || false
  const paramSnackOpen = isParamSavedCupon || isParamUpdateCupon

  const {
    page: pageActual,
    totalPage,
    items,
    setPage,
    handleSetPage
  } = usePaginationBasic(apiResult.list, filter, apiResult.count)

  const submitForm = (params) => {
    setNotSearch(false)
    setLoadingOpen(true)

    const paramsfetchData = {
      params,
      endpoint: getListCupon,
      setState: setApiResult,
      setLoading: setLoadingOpen,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCupon,
      setSnack,
      setSearch: setNotSearch,
      setAccordion: setExpanded
    }

    fetchData(paramsfetchData)
  }

  const onHandleCupon = () => {
    submitForm(getParams({ values: {}, filter, pageActual }))
  }

  const onSubmit = (values) => {
    if (!values?.unity) {
      setvaluesForm({ unity: null })
      return onHandleCupon()
    }

    setFormValues(values)
    setvaluesForm(values)
    return submitForm(getParams({ values, filter, pageActual }))
  }

  const onClear = () => {
    resetFormValues()
    setLoadingOpen(false)
    setNotSearch(true)
    setApiResult({
      list: [],
      count: null
    })
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  useEffect(() => {
    if (paramSnackOpen) setSnack(MESSAGE.SUCCESS)
  }, [paramSnackOpen])

  useEffect(() => {
    if (paramSnackOpen && !snackOpen) {
      navigate(location?.pathname, {
        state: {
          updatePayment: undefined,
          savedPayment: undefined,
          savedSearch: true
        },
        replace: true
      })
    }
  }, [paramSnackOpen, snackOpen])

  useEffect(() => {
    if (
      (valuesForm?.unity?.length >= lengthUnits && valuesForm?.unity) ||
      valuesForm?.unity === null
    ) {
      onSubmit(valuesForm)
    }
  }, [pageActual, filter])

  useEffect(() => {
    if (isSearchParam && formValues?.unity) {
      return onSubmit(formValues)
    }

    return setFormValues(initialState)
  }, [isSearchParam])

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  return (
    <TemplateDefaultFormAndResult
      classNameHeader={TAG_MANAGER.financeiro_btn_inicia_novoCupom}
      listItems={apiResult.list}
      titleHeader="Financeiro - Cupons de desconto"
      labelButtonHeader="novo cupom"
      snackProps={snackProps}
      nameAccordion={nameAccordion}
      loadingOpen={loadingOpen}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      expanded={expanded}
      setExpanded={setExpanded}
      onClickButton={() => {
        navigate(ROUTE.ADMIN_FINANCIAL_CUPON_CREATE, {
          state: { newCupon }
        })
      }}
      accordion={
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ ...props }) => (
              <>
                <Form noValidate autoComplete="off">
                  <FormCupon
                    {...props}
                    nameAccordion={nameAccordion}
                    setSnack={snackProps.setSnack}
                    onClear={onClear}
                    setPage={setPage}
                    setFilter={setFilter}
                  />
                </Form>
              </>
            )}
          </Formik>
        </>
      }
    >
      {apiResult.list && !IsEmpty(apiResult.list) && (
        <TableCupon
          onSubmit={onSubmit}
          rowsItems={items}
          count={apiResult.count}
          setSnack={snackProps.setSnack}
          setFilter={setFilter}
          setPage={setPage}
          totalPage={totalPage}
          filter={filter}
          pageActual={pageActual}
          handleSetPage={handleSetPage}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}
export default ListCupon
