/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { Modal } from 'components/atoms'
import { ButtonFooterV2 } from 'components/molecules'
import * as Styled from './style'

const ModalConfirm = ({
  open,
  handleClose,
  icon,
  title,
  message,
  minWidth,
  textButtonNotConfirm,
  textButtonConfirm,
  onClickNotConfirm,
  onClickConfirm,
  colorButtonConfirm = 'error',
  colorButtonNotConfirm,
  classNameBtnConfirm,
  classNameBtnCancel,
  iconWarningAmber,
  ...props
}) => (
  <Modal open={open} onClose={handleClose}>
    <Styled.Container $minWidth={minWidth}>
      <Styled.Header>
        <Styled.IconText>
          {icon}
          {iconWarningAmber && <Styled.IconWarningAmber />}
          <Styled.Title variant="h5">{title}</Styled.Title>
        </Styled.IconText>
        <Typography variant="subtitle1">
          <span dangerouslySetInnerHTML={{ __html: message }} />
        </Typography>
      </Styled.Header>
      <ButtonFooterV2
        classNameConfirm={classNameBtnConfirm}
        classNameCancel={classNameBtnCancel}
        labelClose={textButtonNotConfirm}
        labelConfirm={textButtonConfirm}
        onClickClose={onClickNotConfirm}
        onClickConfirm={onClickConfirm}
        colorClose={colorButtonNotConfirm}
        colorConfirm={colorButtonConfirm}
        {...props}
      />
    </Styled.Container>
  </Modal>
)

export default ModalConfirm

ModalConfirm.propTypes = {
  open: PropTypes.bool,
  icon: PropTypes.element,
  title: PropTypes.string,
  message: PropTypes.node,
  handleClose: PropTypes.func
}

ModalConfirm.defaultProps = {
  open: false,
  title: '',
  message: ''
}
