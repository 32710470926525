import { School, OpenInNew } from '@mui/icons-material'
import { ClassesProfessor } from 'components/pages/Professor'
import { ChangePassword, InternshipRedirection } from 'components/pages/Shared'
import { ACL } from 'services/constants/acl'
import { ROUTE } from 'services/constants'
import theme from 'theme/designTokens'
import { PageDiaryProfessor } from 'components/organisms'

export const iconProps = {
  color: theme.palette.primary.white
}

const PROFESSOR_ROUTES = [
  {
    component: ClassesProfessor,
    exact: true,
    icon: <School sx={{ ...iconProps }} />,
    label: 'Disciplinas',
    path: ROUTE.PROFESSOR_SCHOOL_CLASSES,
    permissions: ACL.professor,
    isAllowed: () => true
  },
  {
    component: PageDiaryProfessor,
    exact: true,
    path: ROUTE.PROFESSOR_DIARY,
    permissions: ACL.professor,
    isAllowed: () => true
  },
  {
    component: ChangePassword,
    exact: true,
    path: ROUTE.PROFESSOR_UPDATE_PASSWORD,
    permissions: ACL.professor,
    isAllowed: () => true
  },
  {
    component: InternshipRedirection,
    exact: true,
    icon: <OpenInNew sx={{ ...iconProps }} />,
    label: 'Estágio',
    path: ROUTE.PROFESSOR_INTERNSHIP,
    permissions: ACL.professor,
    isAllowed: () => true
  }
]

export default PROFESSOR_ROUTES
