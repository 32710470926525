/* eslint-disable camelcase */
import { typeMask } from 'services/helpers'
import { couponEmpty } from './constants'

const formatInstallment = (count, value) =>
  `${count}x de ${typeMask('MONEY_NUMBER', value)}`

const calculateFormattedCouponValue = ({
  dataCoupon,
  dataHeader,
  detailsCampaign,
  dataPayment
}) => {
  if (dataCoupon === couponEmpty) return dataPayment

  const { enrollment_fee } = dataHeader || {}
  const { discounted_installments, installments, enrollment_tax } = dataCoupon

  const payments = dataPayment.split('+')

  const lastPayment = payments[payments.length - 1].trim()

  const enrollmentString = `1x de ${typeMask(
    'MONEY_NUMBER',
    enrollment_tax || enrollment_fee
  )}`

  const discountedString = discounted_installments
    ? ` + ${discounted_installments.count - 1}x de ${typeMask(
        'MONEY_NUMBER',
        discounted_installments.value
      )}`
    : ''

  const installmentString = installments
    ? ` + ${formatInstallment(installments.count, installments.value)}`
    : ''

  if (!enrollment_fee && !discounted_installments) {
    return formatInstallment(installments?.count, installments?.value)
  }

  return `${enrollmentString}${discountedString}${installmentString}${
    Object.keys(detailsCampaign).length > 0 ? ` + ${lastPayment}` : ''
  }`
}

export { calculateFormattedCouponValue }
