import { lazy, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { ModalConfirm, ModalContent } from 'components/molecules'
import { Grid } from '@mui/material'
import { Button, TextNotFound } from 'components/atoms'
import { FileDownload, ListAlt } from '@mui/icons-material'
import { isEmpty } from 'services/helpers'
import {
  TYPE_FILE_XLSX,
  buttonsActions,
  initialState,
  nameAccordion,
  textMessageConfirmedReporcessing
} from './contants'
import { createReprocessing, handleManager, sendConciliation } from './services'
import * as Styled from './style'
import { PreviewConciliation } from './components/PreviewConciliation'

const Conciliation = lazy(() => import('./components/Conciliation'))
const Reprocessing = lazy(() => import('./components/Reprocessing'))
const Form = lazy(() => import('./components/Form'))
const BigNumbers = lazy(() => import('./components/BigNumbers'))

const Manager = () => {
  const [loading, setLoading] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [confirmedReprocessing, setConfirmedReprocessing] = useState(false)
  const [confirmedEmptyConciliation, setConfirmedEmptyConciliation] =
    useState(false)
  const [listManager, setListManager] = useState([])
  const [nameFile, setNameFile] = useState('excel')
  const [errorTypeFile, setErrorTypeFile] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [previewNF, setPreviewNF] = useState([])
  const [optionsReprocessing, setOptionsReprocessing] = useState([])
  const [expandedReprocessing, setExpandedReprocessing] =
    useState('reprocessing')
  const [expandedConciliation, setExpandedConciliation] =
    useState('conciliation')
  const [expanded, setExpanded] = useState(nameAccordion)

  const formikBillingSearch = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: (values) => {
      handleManager({
        values,
        setLoading,
        setNotSearch,
        setListManager,
        setOptionsReprocessing
      })
    }
  })

  const formikConciliation = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: { file: '' },
    onSubmit: (values) => {
      sendConciliation({
        values,
        stateFormSearch: formikBillingSearch.values,
        setLoading,
        setNotSearch,
        setOpenModal,
        setPreviewNF
      })
    }
  })

  const formikReprocessing = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: { cnpj: '' },
    onSubmit: () => setConfirmedReprocessing(true)
  })

  const handleFileChange = ({ event, setFieldValue }) => {
    if (event === '') {
      return setFieldValue('file', '')
    }

    const selectedFile = event.target.files[0]

    if (selectedFile.type === TYPE_FILE_XLSX) {
      setNameFile(selectedFile.name)
      setFieldValue('file', selectedFile)
    }

    return setErrorTypeFile(false)
  }

  const handleCloseModal = () => {
    formikReprocessing.setFieldValue('cnpj', '')
    setConfirmedReprocessing(false)
  }

  useEffect(() => {
    handleManager({
      values: initialState,
      setLoading,
      setNotSearch,
      setListManager,
      setOptionsReprocessing
    })
  }, [])

  return (
    <>
      <TemplateDefaultFormAndResult
        titleHeader="<b>Financeiro</b> - Faturamento"
        loadingOpen={loading}
        nameAccordion={nameAccordion}
        titleAccordion={<b>Selecione o mês e o ano para realizar uma busca</b>}
        expanded={expanded}
        setExpanded={setExpanded}
        accordion={
          <Form
            values={formikBillingSearch.values}
            setFieldValue={formikBillingSearch.setFieldValue}
            handleSubmit={formikBillingSearch.handleSubmit}
          />
        }
      >
        <>
          {isEmpty(listManager) ? (
            <TextNotFound
              icon={<ListAlt />}
              text="
              <b>O período selecionado ainda não possui resultados.</b></br>
              Altere a busca para visualizar as notas de outros meses.
            "
            />
          ) : (
            <>
              <Grid container spacing={{ xs: 2, sm: 2 }}>
                {buttonsActions({
                  formikBillingSearch,
                  setLoading,
                  setNotSearch
                })?.map(({ id, label, sizeGrid, onClickAction }) => (
                  <Grid item xs={12} sm={12} md={sizeGrid} key={id}>
                    <Button
                      color="warning"
                      size="large"
                      variant="outlined"
                      startIcon={<FileDownload />}
                      value={label}
                      onclick={() => onClickAction()}
                    />
                  </Grid>
                ))}
              </Grid>

              <BigNumbers list={listManager} />
            </>
          )}

          <Grid container spacing={{ xs: 2, sm: 2 }}>
            <Grid item xs={12} sm={12} md={12}>
              <Conciliation
                values={formikConciliation.values}
                errorTypeFile={errorTypeFile}
                nameFile={nameFile}
                handleFileChange={handleFileChange}
                setFieldValue={formikConciliation.setFieldValue}
                handleSubmit={formikConciliation.handleSubmit}
                setLoading={setLoading}
                setNotSearch={setNotSearch}
                setOpenModal={setOpenModal}
                expandedConciliation={expandedConciliation}
                setExpandedConciliation={setExpandedConciliation}
                setPreviewNF
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <Reprocessing
                expandedReprocessing={expandedReprocessing}
                setExpandedReprocessing={setExpandedReprocessing}
                dirty={!formikReprocessing.dirty}
                values={formikReprocessing.values}
                setFieldValue={formikReprocessing.setFieldValue}
                handleSubmit={formikReprocessing.handleSubmit}
                optionsReprocessing={optionsReprocessing}
              />
            </Grid>
          </Grid>
        </>
      </TemplateDefaultFormAndResult>

      <ModalContent
        isBoxShadow="initial"
        maxWidth="md"
        open={openModal}
        title="Status"
        content={
          <PreviewConciliation
            previewNF={previewNF}
            setOpenModal={setOpenModal}
            valuesSearch={formikBillingSearch.values}
            setLoading={setLoading}
            setListManager={setListManager}
            setOptionsReprocessing={setOptionsReprocessing}
            setConfirmedEmptyConciliation={setConfirmedEmptyConciliation}
            setNotSearch={setNotSearch}
            setPreviewNF={setPreviewNF}
            setFieldValue={formikConciliation.setFieldValue}
          />
        }
      />

      <ModalConfirm
        icon={<Styled.IconWarningAmber />}
        open={confirmedReprocessing}
        handleClose={() => handleCloseModal()}
        title="Reprocessamento"
        message={textMessageConfirmedReporcessing}
        textButtonNotConfirm="Cancelar"
        textButtonConfirm="Sim"
        colorButtonConfirm="error"
        colorButtonNotConfirm="primary"
        onClickNotConfirm={() => handleCloseModal()}
        onClickConfirm={() =>
          createReprocessing({
            setLoading,
            setFieldValue: formikReprocessing.setFieldValue,
            values: formikReprocessing.values,
            stateFormSearch: formikBillingSearch.values,
            setNotSearch,
            setListManager,
            setOptionsReprocessing,
            setConfirmedReprocessing
          })
        }
      />

      <ModalConfirm
        size="large"
        icon={<Styled.IconWarningAmber />}
        open={confirmedEmptyConciliation}
        handleClose={() => setConfirmedEmptyConciliation(false)}
        title="Conciliação Não Necessária"
        message="Atualmente, não há itens a serem conciliados."
        textButtonConfirm="Confirmar"
        colorButtonConfirm="primary"
        onClickNotConfirm={() => setConfirmedEmptyConciliation(false)}
        onClickConfirm={() => setConfirmedEmptyConciliation(false)}
      />
    </>
  )
}

export default Manager
