import { INACTIVE } from 'components/pages/Admin/Financial/BillsReceive/FinancialBreakdown/components/Table/constants'
import { ROUTE } from 'services/constants'

const breadcrumbsItems = (hasCreateCurriculum, isIdCurriculum, courseName) => [
  {
    path: ROUTE.ADMIN_MANAGEMENT_LIST_CURRICULUM,
    label: 'Gestão'
  },
  {
    path: ROUTE.ADMIN_MANAGEMENT_LIST_CURRICULUM,
    label: 'Matriz Curricular'
  },
  {
    path: hasCreateCurriculum
      ? ROUTE.ADMIN_MANAGEMENT_CURRICULUM_CREATE
      : ROUTE.ADMIN_MANAGEMENT_CURRICULUM_EDIT,
    label: hasCreateCurriculum ? '<b>Nova Matriz</b>' : `<b> ${courseName}</b>`
  }
]

const MESSAGE = {
  SUCCESS: 'Matriz criada com sucesso',
  SUCCESS_EDIT: 'Matriz editada com sucesso',
  SUCCESS_INACTIVE: 'Matriz inativada com sucesso',
  DISPLINES_IS_EMPTY: 'Preencha a aba de Disciplinas'
}

const modulesReactQuill = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ['clean']
  ]
}

const initialState = {
  active: false,
  level: '',
  course: '',
  yearGrid: '',
  resolution: '',
  complement: '',
  disciplines: []
}

export { initialState, breadcrumbsItems, MESSAGE, modulesReactQuill }
