import styled from 'styled-components'

const Container = styled.div`
  .MuiTabs-flexContainer {
    display: flex;
    justify-content: flex-end;
  }
`

const FormContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`

export { Container, ButtonsWrapper, FormContent }
