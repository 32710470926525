import styled from 'styled-components'
import { Typography as MuiTypography } from '@mui/material'
import {
  PictureAsPdf as MuiPictureAsPdf,
  WarningAmber
} from '@mui/icons-material'

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
  margin: 16px 0;
`

const Typography = styled(MuiTypography)`
  margin-bottom: 10px;
`

const PictureAsPdf = styled(MuiPictureAsPdf)`
  color: rgba(0, 0, 0, 0.54);
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export { Box, PictureAsPdf, Typography, IconWarningAmber }
