const MESSAGE = {
  NO_SEARCH:
    '<b>Monitore as matrículas</b><br/>Escolha as opções de filtro para encontrar as matrículas por período ou digite um CPF para buscar uma inscrição específica.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.'
}

const initialState = {
  uf: '',
  cpf: '',
  unity: '',
  endAt: '',
  beginAt: ''
}
const listOptions = [
  { value: 'Item 1', label: 'Item 1' },
  { value: 'Item 2', label: 'Item 2' }
]

const MAX_LENGTH_CPF = 14
const defaultItemsPerPage = 10
const defaultPage = 1
const modalConfirm = 'modalConfirm'
const viewBankingBilling = 'viewBankingBilling'
const modalManualEnrollment = 'modalManualEnrollment'
const modalDuplicateEnrollment = 'modalDuplicateEnrollment'
const modalConfirmManualEnrollment = 'modalConfirmManualEnrollment'

export const rows = [
  {
    id: 1,
    title: '100429558',
    cpf: '30111069831',
    course: 'Técnico em Enfermagem',
    shift: 'Manhã - Presencial',
    name: 'ELAINE GONCALVES'
  },
  {
    id: 2,
    title: '238232893',
    cpf: '41389518817',
    course: 'Técnico em Segurança do Trabalho',
    shift: 'Tarde',
    name: 'Crislania Emanoel'
  },
  {
    id: 3,
    title: '238232893',
    cpf: '41389518817',
    course: 'Técnico em Segurança do Trabalho',
    shift: 'Tarde',
    name: 'Crislania Emanoel'
  },
  {
    id: 4,
    title: '238232893',
    cpf: '41389518817',
    course: 'Técnico em Segurança do Trabalho',
    shift: 'Tarde',
    name: 'Crislania Emanoel'
  },
  {
    id: 5,
    title: '238232893',
    cpf: '41389518817',
    course: 'Técnico em Segurança do Trabalho',
    shift: 'Tarde',
    name: 'Crislania Emanoel'
  },
  {
    id: 6,
    title: '238232893',
    cpf: '41389518817',
    course: 'Técnico em Segurança do Trabalho',
    shift: 'Tarde',
    name: 'Crislania Emanoel'
  },
  {
    id: 7,
    title: '238232893',
    cpf: '41389518817',
    course: 'Técnico em Segurança do Trabalho',
    shift: 'Tarde',
    name: 'Crislania Emanoel'
  },
  {
    id: 8,
    title: '238232893',
    cpf: '41389518817',
    course: 'Técnico em Segurança do Trabalho',
    shift: 'Tarde',
    name: 'Crislania Emanoel'
  },
  {
    id: 9,
    title: '238232893',
    cpf: '41389518817',
    course: 'Técnico em Segurança do Trabalho',
    shift: 'Tarde',
    name: 'Crislania Emanoel'
  },
  {
    id: 10,
    title: '238232893',
    cpf: '41389518817',
    course: 'Técnico em Segurança do Trabalho',
    shift: 'Tarde',
    name: 'Crislania Emanoel'
  },
  {
    id: 11,
    title: '238232893',
    cpf: '41389518817',
    course: 'Técnico em Segurança do Trabalho',
    shift: 'Tarde',
    name: 'Crislania Emanoel'
  }
]

const paginationFilter = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' }
]

const typeMessage = {
  error: 'Houve um erro. Tente novamente.',
  create_pre_enrollment: 'Pré-Matrícula realizada.',
  create_duplication_enrollment: 'Matrícula duplicada com sucesso.'
}

export {
  MESSAGE,
  listOptions,
  defaultPage,
  typeMessage,
  modalConfirm,
  initialState,
  MAX_LENGTH_CPF,
  paginationFilter,
  viewBankingBilling,
  defaultItemsPerPage,
  modalManualEnrollment,
  modalDuplicateEnrollment,
  modalConfirmManualEnrollment
}
