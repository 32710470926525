import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { TableReenroll } from './components/Table'
import ModalEditReenroll from './components/ModalEditReenroll'
import ModalEditPeriod from './components/ModalEditPeriod'
import ModalCreatePeriod from './components/ModalCreatePeriod'
import {
  breadCrumbsItems,
  defaultItemsPerPage,
  defaultTotalPage
} from './constants'
import {
  getReenrol,
  handleCreateSubmit,
  handleSubmit,
  handleSubmitPdfSummary,
  inactiveReenroll
} from './services'

const Reenroll = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [ListEnroll, setListEnroll] = useState([])
  const [filter, setFilter] = useState(defaultTotalPage)
  const [itensPerPage, setItensPerPage] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [openModalInactive, setOpenModalInactive] = useState(false)
  const [openModalReenroll, setOpenModalReenroll] = useState(false)
  const [openModalEditReenroll, setOpenModalEditReenroll] = useState(false)
  const [openModalCreateReenroll, setOpenModalCreateReenroll] = useState(false)
  const [searchParams] = useSearchParams()
  const [isPagination, setIsPagination] = useState(false)
  const [idModalReenroll, setIdModalReenroll] = useState(0)
  const params = Object.fromEntries([...searchParams])
  const { id, className, course, modules } = params

  const {
    page: pageActual,
    totalPage,
    setPage
  } = usePaginationBasic(ListEnroll, filter, count)

  useEffect(() => {
    if (isPagination)
      getReenrol({
        setSnack,
        setLoadingOpen,
        filter,
        pageActual,
        id,
        setCount,
        setIsPagination,
        setListEnroll
      })
  }, [pageActual, filter])

  useEffect(() => {
    getReenrol({
      setSnack,
      setLoadingOpen,
      filter,
      pageActual,
      id,
      setCount,
      setIsPagination,
      setListEnroll
    })
  }, [openModalCreateReenroll, openModalEditReenroll, openModalReenroll])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      breadcrumbsItems={breadCrumbsItems}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() =>
        navigate(`${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS}?id=${id}`)
      }
      content={
        <>
          <TableReenroll
            itemsTable={ListEnroll}
            openModalInactive={openModalInactive}
            setOpenModalInactive={setOpenModalInactive}
            setOpenModalReenroll={setOpenModalReenroll}
            setOpenModalEditReenroll={setOpenModalEditReenroll}
            setOpenModalCreateReenroll={setOpenModalCreateReenroll}
            openModalEditReenroll={openModalEditReenroll}
            totalPage={totalPage}
            pageActual={pageActual}
            setPage={setPage}
            count={count}
            setEndDate={setEndDate}
            itensPerPage={itensPerPage}
            filter={filter}
            setSnack={setSnack}
            setStartDate={setStartDate}
            setLoadingOpen={setLoadingOpen}
            setFilter={setFilter}
            inactiveReenroll={inactiveReenroll}
            handleSubmitPdfSummary={handleSubmitPdfSummary}
            setIdModalReenroll={setIdModalReenroll}
            id={id}
            setCount={setCount}
            setIsPagination={setIsPagination}
            setListEnroll={setListEnroll}
          />
          <ModalEditPeriod
            setSnack={setSnack}
            setLoadingOpen={setLoadingOpen}
            open={openModalReenroll}
            date={{
              beginDate: startDate,
              endDate
            }}
            setOpenModalReenroll={setOpenModalReenroll}
            handleClose={() => setOpenModalReenroll(false)}
            handleSubmit={handleSubmit}
            idModalReenroll={idModalReenroll}
            className={className}
            course={course}
            modules={modules}
          />

          <ModalEditReenroll
            setSnack={setSnack}
            setLoadingOpen={setLoadingOpen}
            open={openModalEditReenroll}
            idModalReenroll={idModalReenroll}
            setOpenModalEditReenroll={setOpenModalEditReenroll}
            handleClose={() => {
              setOpenModalEditReenroll(false)
            }}
            className={className}
            course={course}
            modules={modules}
          />

          <ModalCreatePeriod
            setSnack={setSnack}
            setLoadingOpen={setLoadingOpen}
            open={openModalCreateReenroll}
            setOpenModalCreateReenroll={setOpenModalCreateReenroll}
            handleClose={() => setOpenModalCreateReenroll(false)}
            handleCreateSubmit={handleCreateSubmit}
            schoolClassId={id}
            className={className}
            course={course}
            modules={modules}
          />
        </>
      }
    />
  )
}

export default Reenroll
