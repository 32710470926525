import { lazy, useState, useEffect } from 'react'
import FEEDBACK_SNACK from 'feedBackSnack'
import { Button, TextNotFound } from 'components/atoms'
import { updateErrorMessage } from 'services/helpers'
import { getStudentDocuments } from 'services/api/admin'
import { ListAlt } from '@mui/icons-material'
import { MESSAGE } from './constants'
import * as Styled from './style'
import { ModalUpload } from './components/ModalUpload'

const Table = lazy(() => import('./components/Table'))

const StudentDocuments = ({ register, setSnack, setLoading }) => {
  const [notSearch, setNotSearch] = useState(true)
  const [docsList, setDocsList] = useState([])
  const [openUploadPDF, setOpenUploadPDF] = useState(false)

  const handleListDocuments = async () => {
    setSnack('', '')
    setLoading(true)

    const { data, error, status } = await getStudentDocuments(register)

    setLoading(false)
    if (error) {
      setNotSearch(true)
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
      })
      return
    }

    setDocsList(data)
    setNotSearch(false)
  }

  useEffect(() => {
    handleListDocuments()
  }, [])

  let messageInformative = ''

  if (notSearch) {
    messageInformative = MESSAGE.NO_SEARCH_PERFOMED
  } else if (docsList.length === 0) {
    messageInformative = MESSAGE.NOT_FOUND_SEARCH
  }
  return (
    <>
      {messageInformative && (
        <TextNotFound icon={<ListAlt />} text={messageInformative} />
      )}
      <Styled.ButtonGroup
        messageExists={docsList.length > 0}
        messageCenter={messageInformative === MESSAGE.NOT_FOUND_SEARCH}
      >
        <Button
          value="novo documento"
          size="large"
          onclick={setOpenUploadPDF}
        />
      </Styled.ButtonGroup>

      {docsList.length > 0 && (
        <Table rows={docsList} setLoading={setLoading} setSnack={setSnack} />
      )}
      <ModalUpload
        open={openUploadPDF}
        isLoad={setLoading}
        setOpen={setOpenUploadPDF}
        setSnack={setSnack}
        register={register}
      />
    </>
  )
}

export default StudentDocuments
