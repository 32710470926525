import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { MINUTES_TO_1_HOUR } from 'services/constants/time'

const {
  borderLayoutDefault,
  paddingLayoutDefault,
  noBorderOnTop,
  getIsLikeNovotec
} = utilsPDF

const buildClassInfo = (info) => {
  const isLikeNovoTech = getIsLikeNovotec(info.unit)
  return {
    table: {
      body: [
        [
          {
            stack: [{ text: 'Curso', bold: true }, { text: info.course }],
            colSpan: 4,
            border: noBorderOnTop
          },
          {},
          {},
          {},
          ...(isLikeNovoTech
            ? [
                {
                  stack: [
                    { text: 'Módulo/Série', bold: true },
                    { text: info.module ? `${info.module}º` : 'Único' }
                  ],
                  border: noBorderOnTop
                }
              ]
            : []),
          {
            stack: [{ text: 'Turno', bold: true }, { text: info.shift }],
            border: noBorderOnTop,
            colSpan: isLikeNovoTech ? undefined : 2
          },
          ...(isLikeNovoTech ? [] : [{}]),
          {
            stack: [{ text: 'Período', bold: true }, { text: info.period }],
            border: noBorderOnTop,
            colSpan: 2
          },
          {},
          {
            stack: [
              { text: 'Unidade/Polo', bold: true },
              { text: `${info.unit}${info.pole ? ` / ${info.pole}` : ''}` }
            ],
            border: noBorderOnTop,
            colSpan: 4
          },
          {},
          {},
          {}
        ],
        [
          {
            stack: [{ text: 'Turma', bold: true }, { text: info.classroom }],
            border: noBorderOnTop,
            colSpan: 4
          },
          {},
          {},
          {},
          {
            stack: [
              { text: 'Componente curricular:', bold: true },
              { text: info.curricularComponent }
            ],
            colSpan: 6,
            border: noBorderOnTop
          },
          {},
          {},
          {},
          {},
          {},
          {
            stack: [
              {
                text: isLikeNovoTech ? 'Aulas' : 'Carga horária total prevista',
                bold: true
              },
              {
                text: isLikeNovoTech
                  ? `${
                      info.totalExpectedWorkload /
                      (info.classTime / MINUTES_TO_1_HOUR)
                    }`
                  : `${info.totalExpectedWorkload}h`
              }
            ],
            border: noBorderOnTop,
            colSpan: 2
          },
          {}
        ]
      ],
      widths: [
        'auto',
        'auto',
        'auto',
        'auto',
        '*',
        'auto',
        'auto',
        'auto',
        '*',
        'auto',
        'auto',
        'auto'
      ]
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}

export default buildClassInfo
