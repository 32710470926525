const numberSelected = 0
const idSelected = 1
const defaultName = 'name'
const typeAll = 'Tudo'
const defaultPage = 1
const defaultItemsPerPage = 10
const emptyValue = ' '
const inactive = 'inactive'
const complement = 'complement'
const successComplement = 'Alteração de ocorrência salva'
const inactiveComplement = 'Cancelamento realizado'
const noComplement = 'false'

export {
  defaultItemsPerPage,
  numberSelected,
  idSelected,
  defaultName,
  typeAll,
  defaultPage,
  inactive,
  emptyValue,
  complement,
  successComplement,
  inactiveComplement,
  noComplement
}
