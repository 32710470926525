import { useEffect, useState } from 'react'
import { Formik } from 'formik'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Tooltip
} from '@mui/material'

import { Button, Modal } from 'components/atoms'
import { Select, TextField } from 'components/molecules'

import { getCityByState, getStates } from 'services/api/shared'
import { MAX_FORMATION } from '../../constants'
import { initialValues, levelList, typeList } from './constants'
import { createFormattion, updateFormattion } from './service'

const FormationModal = ({
  registerId,
  initialValuesToEdit,
  open,
  handleClose,
  callbackSuccess,
  itemsList = []
}) => {
  const [states, setStates] = useState([])
  const [selectedState, setSelectedState] = useState(
    initialValuesToEdit?.state ?? ''
  )
  const [cities, setCities] = useState([])

  const isEditing = !!initialValuesToEdit

  const handleSubmit = (payload) => {
    const serviceFunction = isEditing ? updateFormattion : createFormattion

    serviceFunction({
      payload,
      registerId,
      callbackSuccess
    })
  }

  useEffect(async () => {
    const listUfs = await getStates()

    setStates(listUfs)
  }, [])

  useEffect(async () => {
    if (selectedState) {
      const listCities = await getCityByState(selectedState)
      setCities(listCities)
    }
  }, [selectedState])

  const disabledButton = ({ nivel, type }) => !nivel || !type

  const itemsListFormatted = itemsList?.map((item) => item?.nivel)
  const totalItemsOnList = itemsList?.length
  const hasOneOptionAvailable = totalItemsOnList === MAX_FORMATION - 1
  const listNivelAvailable = levelList?.filter(
    (item) => !itemsListFormatted.includes(item?.value)
  )
  const isAvailableToChangeNivel = totalItemsOnList !== MAX_FORMATION

  return (
    <Modal open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Formik
        initialValues={initialValuesToEdit ?? initialValues}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {({ handleChange, values, submitForm, setFieldValue }) => (
          <Grid container xs={12} spacing={2} padding="24px">
            <Grid item xs={12}>
              <FormLabel
                sx={{
                  fontSize: 32,
                  fontWeight: 500,
                  color: '#000'
                }}
              >
                {isEditing ? 'Editar' : 'Nova'} formação
              </FormLabel>
            </Grid>

            {hasOneOptionAvailable && (
              <Tooltip title="Só é possivel uma formação de cada nível">
                <Grid item xs={12}>
                  <Select
                    name="nivel"
                    label="Nivel*"
                    value={values?.nivel}
                    onChange={handleChange}
                    optionsList={listNivelAvailable}
                    disabled={!isAvailableToChangeNivel}
                  />
                </Grid>
              </Tooltip>
            )}

            {!hasOneOptionAvailable && (
              <Grid item xs={12}>
                <Select
                  name="nivel"
                  label="Nivel*"
                  value={values?.nivel}
                  onChange={handleChange}
                  optionsList={levelList}
                  disabled={!isAvailableToChangeNivel}
                />
              </Grid>
            )}

            <Grid item xs={5}>
              <Select
                name="type"
                label="Tipo*"
                value={values?.type}
                onChange={handleChange}
                optionsList={typeList}
              />
            </Grid>

            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values?.isFinished}
                    onChange={handleChange}
                    name="isFinished"
                  />
                }
                label="Concluido"
              />
            </Grid>

            <Grid item xs={4}>
              {!!values?.isFinished && (
                <TextField
                  name="finishedYear"
                  label="Ano de conclusão"
                  value={values?.finishedYear}
                  onChange={handleChange}
                  fullWidth
                  isMargin={false}
                />
              )}
            </Grid>

            <Grid item xs={4}>
              <TextField
                name="institution"
                label="Instituição"
                value={values?.institution}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={4}>
              <Select
                label="Estado"
                name="state"
                value={values?.state}
                onChange={(e) => {
                  const option = e.target.value

                  setFieldValue('state', option)
                  setFieldValue('city', '')
                  setSelectedState(option)
                }}
                optionsList={states}
                hasDontSensitive
              />
            </Grid>

            <Grid item xs={4}>
              <Select
                label="Cidade"
                name="city"
                value={values?.city}
                onChange={(e) => setFieldValue('city', e.target.value)}
                optionsList={cities}
                hasDontSensitive
              />
            </Grid>

            <Box
              sx={{
                display: 'flex',
                gap: '24px',
                justifyContent: 'flex-end',
                width: '100%'
              }}
            >
              <Button variant="outlined" onClick={() => handleClose()}>
                Cancelar
              </Button>
              <Button
                disabled={disabledButton(values)}
                onClick={() => submitForm()}
              >
                Salvar
              </Button>
            </Box>
          </Grid>
        )}
      </Formik>
    </Modal>
  )
}

export default FormationModal
