import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'

const Grid = styled(MuiGrid)`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export { Grid, Box }
