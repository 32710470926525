import FEEDBACK_SNACK from 'feedBackSnack'
import { getErrorMessage, updateErrorMessage } from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'
import {
  editStatusActivitysTypes,
  editTypesOfActivity,
  getCourses,
  getDetailsTypesOfActivity,
  getTypesOfActivitys,
  postTypesOfActivity
} from 'services/api/admin'
import { ROUTE, SUCCESS } from 'services/constants'
import { parseResponse } from '../TypesOfRequirements/List/ModalCopy/helpers'
import { defaultItemsPerPage, defaultPage } from './List/constants'

export const getListCourses = async ({
  values,
  setSnack,
  selectUnit,
  setListCourses
}) => {
  const params = cleanObject({
    ...(values.level && { level: values.level }),
    unit_id: selectUnit?.id
  })

  const { data, error, status } = await getCourses(params)
  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToForm
    })
  }

  return setListCourses(parseResponse(data?.data, true))
}

export const handleSearch = async ({
  values,
  filter,
  setSnack,
  setCount,
  pageActual,
  setActivitys,
  setNotSearch,
  setLoadingVisibility
}) => {
  setLoadingVisibility(true)

  const params = cleanObject({
    ...(values.level && { level: values.level }),
    unit: values.unit,
    name: values.name,
    course: values.course,
    inactive: values.inactive,
    limit: filter || defaultItemsPerPage,
    page: pageActual || defaultPage
  })

  const { data, error, status } = await getTypesOfActivitys(params)
  setLoadingVisibility(false)
  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListTypesActivitys
    })
  }

  if (!data?.count) {
    setNotSearch(true)

    return setActivitys([])
  }

  setCount(data?.count)
  setActivitys(data?.data)
  return setNotSearch(false)
}

export const createTypeOfActivitys = async ({
  values,
  setSnack,
  setLoading,
  navigate,
  setMessage
}) => {
  setLoading(true)
  setSnack('', '')

  const payload = cleanObject({
    unit: values.unit,
    level: values.level,
    course: values.course,
    is_portal: values.is_portal,
    workload: values.workload,
    name: values.name,
    is_intership: values.is_intership,
    considerations: values.considerations
  })

  const { error, status } = await postTypesOfActivity(payload)
  setLoading(false)

  if (error) {
    const message = getErrorMessage(error, status)
    const isValidMessage = message.includes('já existe')
    if (isValidMessage) setMessage(message)
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateTypesActivitys
    })
  }

  setSnack('Tipo de Atividade criado com sucesso.', SUCCESS)

  return setTimeout(
    () =>
      navigate(ROUTE.ADMIN_MANAGEMENT_TYPE_ACTIVITY, {
        state: { savedSearch: true }
      }),
    1500
  )
}

export const editTypeOfActivitys = async ({
  id,
  values,
  setSnack,
  setLoading,
  navigate
}) => {
  setLoading(true)
  setSnack('', '')

  const payload = cleanObject({
    unit: values.unit,
    level: values.level,
    course: values.course,
    workload: values.workload,
    is_portal: values.is_portal,
    is_intership: values.is_intership,
    considerations: values.considerations
  })

  const { error, status } = await editTypesOfActivity(id, payload)
  setLoading(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditTypesActivitys
    })
  }

  setSnack('Atividade atualizada com sucesso.', SUCCESS)

  return setTimeout(
    () =>
      navigate(ROUTE.ADMIN_MANAGEMENT_TYPE_ACTIVITY, {
        state: { savedSearch: true }
      }),
    1500
  )
}

export const handleSelectUnit = async ({ values, unitys, setSelectUnit }) => {
  const unity = unitys.find(({ label }) => label === values.unit)
  setSelectUnit(unity)
}

export const handleDetailsSearch = async ({
  id,
  unitys,
  setSnack,
  setFieldValue,
  setSelectUnit
}) => {
  const { data, error, status } = await getDetailsTypesOfActivity(id)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditTypesActivitys
    })
  }

  handleSelectUnit({ data, unitys, setSelectUnit })

  const fieldsToSet = [
    'unit',
    'level',
    'course',
    'considerations',
    'workload',
    'is_portal',
    'name',
    'is_intership'
  ]
  return fieldsToSet.map((field) => setFieldValue(field, data[field]))
}

export const handleUpdateActivitys = async ({
  values,
  filter,
  setCount,
  isActive,
  setSnack,
  setIsOpen,
  pageActual,
  setNotSearch,
  setActivitys,
  listSelected,
  setLoadingVisibility
}) => {
  setSnack('')
  setLoadingVisibility(true)
  const params = cleanObject({
    id: [...listSelected]
  })

  const { error, status } = await editStatusActivitysTypes(params)
  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditStatusActivitys
    })
  }
  setActivitys([])
  setSnack(
    `Tipo(s) de Atividade ${
      isActive ? 'inativado(s)' : 'ativado(s)'
    } com sucesso.`,
    SUCCESS
  )
  setIsOpen(false)

  return handleSearch({
    values,
    filter,
    setSnack,
    setCount,
    pageActual,
    setActivitys,
    setNotSearch,
    setLoadingVisibility
  })
}
