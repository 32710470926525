import { obscureEmail, obscurePhone } from 'services/helpers'

const getMessageWarning = ({ email, cellphone, expireDays }) => (
  <>
    Para aceitar os termos do seu contrato de prestação de serviços educacionais
    por meio eletrônico (internet), leia-o e após a leitura digite o Código de
    Aceite que enviamos para o seu e-mail cadastrado{' '}
    <strong>{email && obscureEmail(email)}</strong> e por SMS para o número{' '}
    <strong>{cellphone && obscurePhone(cellphone)}</strong> e clique em
    Confirmar Assinatura de Contrato. Você tem até{' '}
    <strong>{expireDays} dias</strong> para confrimar a assinatura do contrato.
  </>
)

const codeLengthCorrect = 4

const messageErrorLengthCode = `O código de validação precisa de ${codeLengthCorrect} dígitos`

export { getMessageWarning, codeLengthCorrect, messageErrorLengthCode }
