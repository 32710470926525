import { httpGet, httpPatch, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams, isEmpty } from 'services/helpers'

export const getCategories = async () => {
  const response = await httpGet(
    ENDPOINT.ADMIN_MANAGEMENT_OCCURRENCE_TYPES_CATEGORIES
  )

  return response
}

export const getEvents = async () => {
  const response = await httpGet(
    ENDPOINT.ADMIN_MANAGEMENT_OCCURRENCE_TYPES_EVENTS
  )

  return response
}

export const getOptionsOccurrenceTypes = async (params) => {
  const response = await httpGet(
    `${
      ENDPOINT.ADMIN_MANAGEMENT_OCCURRENCE_TYPES_SECRETARY_TYPES
    }?${convertToParams(params)}`
  )

  return response
}

export const getOccurrenceTypes = async (params, occurrenceTypes) => {
  const queryString = occurrenceTypes
    ?.map((item) => `occurrences[]=${encodeURIComponent(item)}`)
    .join('&')

  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_LIST_OCCURRENCE_TYPES}?${
      !isEmpty(occurrenceTypes) ? `${queryString}&` : ''
    }${params}`
  )

  return response
}

export const getBySpecificOccurrenceType = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_LIST_OCCURRENCE_TYPES}/${id}`
  )

  return response
}

export const updateOccurrenceType = async (id, payload) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_MANAGEMENT_LIST_OCCURRENCE_TYPES}/${id}`,
    {
      ...payload
    }
  )

  return response
}

export const inactiveOccurrenceTypes = async (payload) => {
  const response = await httpPatch(
    `${ENDPOINT.ADMIN_MANAGEMENT_INACTIVE_OCCURRENCE_TYPES}`,
    {
      ...payload
    }
  )

  return response
}

export const activeOccurrenceTypes = async (payload) => {
  const response = await httpPatch(
    `${ENDPOINT.ADMIN_MANAGEMENT_ACTIVE_OCCURRENCE_TYPES}`,
    {
      ...payload
    }
  )

  return response
}

export const copyOccurrenceTypes = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_MANAGEMENT_COPY_OCCURRENCE_TYPES}`,
    {
      ...payload
    }
  )

  return response
}

export const createOccurrenceTypes = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_MANAGEMENT_CREATE_OCCURRENCE_TYPES}`,
    {
      ...payload
    }
  )

  return response
}
