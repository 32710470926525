import styled from 'styled-components'
import { Typography } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'

const Container = styled.div`
  min-height: 160px;
  border-radius: 4px;
  min-width: ${({ $minWidth }) => $minWidth || '300px'};
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: ${({ theme }) => theme.palette.background.white};
  padding: 24px;
`

const Header = styled.div`
  margin-bottom: 16px;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

const Text = styled(Typography)`
  &.MuiTypography-root {
    white-space: pre-line;
  }
`

const IconText = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export { Container, Header, Title, Text, IconText, IconWarningAmber }
