import * as Styled from './style'
import { weekDays, yearmMonth } from './constants'

const StepWelcome = () => {
  const dateToday = new Date()

  const today = {
    weekDay: weekDays[dateToday.getDay()],
    day: dateToday.getDate(),
    month: yearmMonth[dateToday.getMonth()],
    year: dateToday.getFullYear()
  }

  return (
    <Styled.Container>
      <Styled.BackgroundImage
        src="/images/slide_welcome_background.svg"
        alt="#"
      />

      <Styled.Message>
        <Styled.MessageImage
          src="/images/slide_welcome_Ilustracoes.svg"
          alt="#"
        />
        <Styled.MessageText>
          <Styled.MessageTextTitle>
            {`Hoje é ${today.weekDay}, ${today.day} de ${today.month} de ${today.year}`}
          </Styled.MessageTextTitle>
          <Styled.MessageTextSubTitle>
            Te desejamos um excelente dia de trabalho!
          </Styled.MessageTextSubTitle>
        </Styled.MessageText>
      </Styled.Message>
    </Styled.Container>
  )
}

export default StepWelcome
