import FEEDBACK_SNACK from 'feedBackSnack'
import { updateErrorMessage } from 'services/helpers'
import { SUCCESS } from 'services/constants'
import { getListOccurrence } from 'services/api/admin'
import { ALL, countDefault, defaultPage } from './constants'

const getParams = ({ register, values, page, filter, cpfStudent }) => {
  const { category } = values || {}

  return {
    register,
    cpf: cpfStudent,
    ...(category !== ALL && { occurrence_category: category }),
    page,
    limit: filter
  }
}

const fetchData = async ({
  setLoading,
  setSnack,
  payload,
  page,
  onSubmit,
  formValues,
  setPage,
  onResetAction,
  setSelected,
  endpoint,
  messageSuccess,
  feedbackMessage
}) => {
  setSnack('', '')
  setLoading(true)

  const { error, status } = await endpoint(payload)
  setLoading(false)
  if (!error) {
    if (page === defaultPage) {
      onSubmit(formValues)
      return setSnack(messageSuccess, SUCCESS)
    }
    setPage(defaultPage)
    onResetAction()
    setSelected([])
    return setSnack(messageSuccess, SUCCESS)
  }

  return updateErrorMessage({
    setSnack,
    error,
    status,
    feedbackMessage
  })
}

const onSubmit = async ({
  values,
  setList,
  setLoading,
  setFormValues,
  setNotSearch,
  setCount,
  setSnack,
  params,
  page,
  filter,
  formValues
}) => {
  setList([])

  setLoading(true)
  const inactive = values?.inactive
  const type = null

  const { error, status, data } = await getListOccurrence(
    getParams(values, page, filter, params?.cpf),
    type,
    inactive
  )
  setLoading(false)
  setFormValues(values)

  if (data?.count === countDefault) {
    setFormValues(formValues)
    setList([])
    return setNotSearch(false)
  }

  if (data?.data) {
    setList(data?.data)
    setCount(data?.count)

    return setNotSearch(false)
  }

  return updateErrorMessage({
    setSnack,
    error,
    status,
    feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListOccurrence
  })
}

export { getParams, fetchData, onSubmit }
