import { Grid } from '@mui/material'
import { DatePicker } from 'components/atoms'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import { optionsList } from '../../constants'
import * as Styled from './style'

const Form = ({ values, setFieldValue, touched, errors, onClear }) => (
  <>
    <Styled.Box>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={2}>
          <DatePicker
            value={values?.start_date}
            label="Período inicial*"
            id="start_date"
            error={errors && touched.start_date && errors.start_date}
            helperText={touched.start_date && errors.start_date}
            onChange={(newValue) => setFieldValue('start_date', newValue)}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            label="Período final*"
            id="end_date"
            value={values?.end_date || null}
            error={errors && touched.end_date && errors.end_date}
            helperText={touched.end_date && errors.end_date}
            onChange={(newValue) => setFieldValue('end_date', newValue)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            name="user"
            label="Usuário ou matrícula"
            isMargin="0"
            value={values?.user}
            onChange={(e) => setFieldValue('user', e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            fullWidth
            id="search_type"
            value={values?.search_type}
            label="Tipo de consulta*"
            optionsList={optionsList}
            error={errors && touched.search_type && errors.search_type}
            helperText={touched.search_type && errors.search_type}
            onChange={(e) => {
              setFieldValue('search_type', e.target.value)
            }}
            isMargin="0"
          />
        </Grid>
      </Grid>
    </Styled.Box>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name="search_1"
          isMargin="0"
          value={values?.search_1}
          label="Termo de busca"
          onChange={(e) => setFieldValue('search_1', e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name="search_2"
          isMargin="0"
          value={values?.search_2}
          label="Termo de busca"
          onChange={(e) => setFieldValue('search_2', e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <ButtonFooterV2
          disabledConfirm=""
          labelConfirm="Buscar"
          labelClose="Limpar"
          onClickClose={() => onClear()}
        />
      </Grid>
    </Grid>
  </>
)

export default Form
