import styled from 'styled-components'
import { Typography } from '@mui/material'
import { Close, AddBox, WarningAmber } from '@mui/icons-material'
import { TextField as MuiTextField } from 'components/molecules'

const Card = styled.div`
  padding: 24px 0;
`
const Switch = styled.div`
  display: flex;
  align-items: center;
`
const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
  grid-gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-bottom: 16px;
  }
`

const BoxValue = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 16px;
  margin-bottom: 16px;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 16px;
  }
`

const StasrtAdornment = styled.p`
  color: ${({ theme }) => theme.palette.gray.bgLight};
  margin-right: 8px;
`

const TextField = styled(MuiTextField)`
  &.MuiTextField-root {
    margin: 0;
  }
`

const InfoFooter = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`

const CardFooter = styled.div`
  margin: 24px 0;
`

const Total = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ $isColor, theme }) =>
      $isColor ? theme.palette.gray.main : theme.palette.dark.main};
    margin-left: 8px;
    min-width: 66px;
    text-align: end;
    font-weight: bold;
  }
`

const DeleteIcon = styled(Close)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.textColors.error};
  }
`

const IconAddBox = styled(AddBox)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
  }
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

const BoxTooltip = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

export {
  Card,
  Header,
  StasrtAdornment,
  Switch,
  TextField,
  InfoFooter,
  CardFooter,
  Total,
  DeleteIcon,
  IconAddBox,
  IconWarningAmber,
  BoxTooltip,
  BoxValue
}
