import styled from 'styled-components'
import { TableCell as MuiTableCell, Box as MuiBox } from '@mui/material'
import theme from 'theme/designTokens'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 26rem;
    cursor: pointer;
  }
`

const Box = styled(MuiBox)`
  &.MuiBox-root  {
    width: 22px;
    height: 22px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    texte-align: center:
    text-transform: uppercase;
    font-weight: bold;
    color: ${() => theme.palette.primary.white};
    background-color: ${() => theme.palette.yellow.dark};
  }
`

const TableCellCheckbox = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 0 6px 5px;
    width: 26rem;
    cursor: pointer;
  }
`

const TableCellNumberLessons = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    cursor: pointer;

    & div {
      width: 4rem;
      display: block;
      word-break: break-all;
    }
  }
`

export { TableCell, TableCellNumberLessons, TableCellCheckbox, Box }
