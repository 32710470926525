import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TabView } from 'components/organisms'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { LoadingPage, ModalConfirm } from 'components/molecules'
import { Button, ToastComponent } from 'components/atoms'
import { ROUTE } from 'services/constants'
import { TABS, tabName, fieldsNameForm, modalMessage } from './constants'
import { getStudentDataByRegisterId, updateRegistrationData } from './services'
import { disabledButton } from './helpers'

import * as Styled from './style'

const StudentData = ({ shouldUpdateData, setShouldUpdateData }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { setLoading } = useLoading()

  const initialData = {
    [fieldsNameForm.studentData]: {},
    [fieldsNameForm.schoolData]: [],
    [fieldsNameForm.affiliation]: [],
    [fieldsNameForm.fieldNameResponsibleEdit]: {},
    [fieldsNameForm.fieldNameListResponsibles]: []
  }

  const [data, setData] = useState(initialData)
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])
  const currentTab = params?.[tabName]

  const isAvailableToSubmit = (values) =>
    [fieldsNameForm.studentData, fieldsNameForm.affiliation].includes(
      currentTab
    ) ||
    (currentTab === fieldsNameForm.financialOfficer &&
      !!values?.[fieldsNameForm.fieldNameResponsibleEdit])

  const handleCloseModal = () => setOpenModal(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleConfirmModal = () => navigate(ROUTE.ADMIN_SECRETARY_STUDENTS)
  const handleUpdateData = () => setShouldUpdateData(true)

  const initialValues = { ...data }

  const handleSubmit = async (values) => {
    setLoading(true)
    await updateRegistrationData({
      registerId: params?.register,
      values,
      currentTab,
      setShouldUpdateData
    })
    setLoading(false)
  }

  useEffect(() => {
    if (shouldUpdateData) {
      getStudentDataByRegisterId({
        setData,
        registerId: params?.register,
        setLoadingVisibility,
        setShouldUpdateData
      })
    }
  }, [shouldUpdateData, params?.register])

  useEffect(() => {
    if (currentTab) {
      setShouldUpdateData(true)
    }
  }, [currentTab])

  return !initialValues?.[fieldsNameForm.studentData]?.register ? null : (
    <Styled.Container>
      <LoadingPage open={loadingVisibility} />

      {!loadingVisibility && (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ submitForm, values }) => (
            <Styled.FormContent>
              <TabView
                tabs={TABS}
                setLoading={setLoadingVisibility}
                params={params}
                tabName={tabName}
                fullWidth
                handleUpdateData={handleUpdateData}
                initialParams={fieldsNameForm.studentData}
              />
              {isAvailableToSubmit(values) && (
                <Styled.ButtonsWrapper>
                  <Button variant="outlined" onClick={handleOpenModal}>
                    Cancelar
                  </Button>
                  <Button
                    onClick={submitForm}
                    disabled={disabledButton(values, initialValues, currentTab)}
                  >
                    Salvar
                  </Button>
                </Styled.ButtonsWrapper>
              )}
            </Styled.FormContent>
          )}
        </Formik>
      )}

      <ModalConfirm
        open={openModal}
        size="medium"
        handleClose={handleCloseModal}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={handleCloseModal}
        onClickConfirm={handleConfirmModal}
        colorButtonConfirm="error"
        iconWarningAmber
        title={modalMessage.title}
        message={modalMessage.message}
      />

      <ToastComponent />
    </Styled.Container>
  )
}

export default StudentData
