import styled from 'styled-components'
import { Typography as MuiTypography } from '@mui/material'

const ResumeHead = styled('div')`
  margin: 24px 0;
`

const SubTitle = styled(MuiTypography)`
  &.MuiTypography-root {
    font-size: 16px;
    margin-left: 8px;
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-size: 14px;
    margin-left: 8px;
  }
`

export { ResumeHead, Box, SubTitle, Typography }
