const TAB = {
  SCHOOL_CLASS_DATA: 'SCHOOL_CLASS_DATA',
  DISCIPLINES: 'CLASS_PARAMETERS'
}

const single = 0

const schoolClassData = 'schoolClassData'
const classParameters = 'classParameters'

export { schoolClassData, TAB, classParameters, single }
