import { lazy, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ChevronRight, ListAlt, Search } from '@mui/icons-material'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import {
  useMakeService,
  usePaginationBasic,
  useSortTable
} from 'services/hooks'
import { isEmpty, mergeInitialValues } from 'services/helpers'
import {
  MESSAGE,
  breadcrumbsItems,
  defaultItemsPerPage,
  countDefault,
  initialState
} from './constants'
import {
  getListCourses,
  getListSchoolClasses,
  revertedHRSchoolClass
} from './services'
import { useManageClassesProvider } from './Context/FormContext'

const Form = lazy(() => import('./components/Form'))
const Table = lazy(() => import('./components/Table'))

const ManageClasses = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [isButtonSet, setIsButtonSet] = useState(false)
  const [schoolClasses, setSchoolClasses] = useState([])
  const [coordinators, setCoordinators] = useState([])
  const [courses, setCourses] = useState([])
  const [schoolClassesOptions, setSchoolClassesOptions] = useState([])
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [valuesReset, setValuesReset] = useState({})

  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])

  const isSearchParam = location?.state?.savedSearch

  const { formValues, setFormValues, resetFormValues } =
    useManageClassesProvider()

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  const paramsMakeService = {
    isUnits: true,
    unitsValue: true
  }

  const { unitys } = useMakeService(paramsMakeService)

  const {
    page: pageActual,
    totalPage,
    setPage,
    handleSetPage,
    items
  } = usePaginationBasic(schoolClasses, filter, count)

  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(items)

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      setNotSearch(false)
      getListSchoolClasses({
        payrollId: params.payroll_id,
        values,
        setLoadingOpen,
        page: pageActual,
        filter,
        setCount,
        setSchoolClasses
      })
    }
  })

  const unitId = unitys?.find(({ label }) => label === params.unit)

  useEffect(() => unitId && getListCourses({ unitId, setCourses }), [unitId])

  useEffect(() => {
    if (!isSearchParam) {
      setFormValues(initialState)
    }

    if (isButtonSet || isSearchParam) {
      setIsButtonSet(true)
      getListSchoolClasses({
        payrollId: params.payroll_id,
        values: formik.values,
        setLoadingOpen,
        page: pageActual,
        filter,
        setCount,
        setSchoolClasses
      })
    }
  }, [isSearchParam, pageActual, filter])

  return (
    <TemplateDefaultFormAndResult
      breadcrumbsItems={breadcrumbsItems}
      hasIconButton
      classNameHeader="goBack"
      labelButtonHeader="Voltar"
      loadingOpen={loadingOpen}
      onClickButton={() =>
        navigate(ROUTE.ADMIN_HR_TIMEKEEPING, {
          state: { savedSearch: true }
        })
      }
      listItems={schoolClasses}
      separator={<ChevronRight />}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={notSearch ? MESSAGE.NOT_SEARCH : MESSAGE.EMPTY_RESULT}
      formHeader={
        <Form
          values={formik.values}
          setFieldValue={formik.setFieldValue}
          setIsButtonSet={setIsButtonSet}
          handleSubmit={formik.handleSubmit}
          onClear={() => {
            setSchoolClasses([])
            setNotSearch(true)
            formik.handleReset()
          }}
          setSchoolClassesOptions={setSchoolClassesOptions}
          courses={courses}
          schoolClassesOptions={schoolClassesOptions}
          coordinators={coordinators}
          setCoordinators={setCoordinators}
          setPage={setPage}
          setFilter={setFilter}
        />
      }
    >
      {!isEmpty(schoolClasses) && (
        <Table
          order={order}
          orderBy={orderBy}
          payrollId={params.payroll_id}
          handleRequestSort={handleRequestSort}
          rows={items}
          sortTable={sortTable}
          count={count}
          filter={filter}
          setValuesReset={setValuesReset}
          setPage={setPage}
          totalPage={totalPage}
          handleSetPage={handleSetPage}
          pages={pageActual}
          handleConfirmModal={() =>
            revertedHRSchoolClass({
              setLoadingOpen,
              setValuesReset,
              setOpenModalConfirm,
              schoolClassesId: valuesReset.schoolClassesId,
              nameClass: valuesReset.nameClass,
              formik,
              pageActual,
              filter,
              setCount,
              setSchoolClasses,
              params
            })
          }
          handleCloseModalConfirm={() => setOpenModalConfirm(false)}
          openModalConfirm={openModalConfirm}
          setOpenModalConfirm={setOpenModalConfirm}
          setFilter={setFilter}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ManageClasses
