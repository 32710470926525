import React from 'react'
import { TableContainer } from '@mui/material'
import { IsEmpty } from 'services/helpers/ramda'
import { EX_FINAL_GRADE_KEY } from 'components/organisms/Diary/Grades/Resume/config'
import TableHeader from './TableHead'
import TableFooter from './TableFooter'
import * as Styled from './style'

const ResumeTable = ({ resume, headCells, dropouts }) => {
  const config = {
    showExFinal: headCells.some((cell) => cell.id === EX_FINAL_GRADE_KEY)
  }
  return (
    <Styled.Box>
      <Styled.Paper>
        <TableContainer>
          <Styled.Table aria-labelledby="tableTitle" size="medium">
            <TableHeader headCells={headCells} dropouts={dropouts} />

            {resume &&
              resume.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableFooter
                    key={labelId}
                    labelId={labelId}
                    row={row}
                    index={index}
                    dropouts={dropouts}
                    config={config}
                  />
                )
              })}
          </Styled.Table>
        </TableContainer>
      </Styled.Paper>

      {!dropouts && resume && IsEmpty(resume) && (
        <Styled.BoxFooter>
          <p>Ainda não há notas registradas</p>
        </Styled.BoxFooter>
      )}
    </Styled.Box>
  )
}

export default ResumeTable
