import theme from 'theme/designTokens'
import { clone } from 'services/helpers'
import { UNITS } from 'services/constants'
import generateDocDefinitionsBase from '../shared/BasePage'
import { utilsPDF } from '../shared/utils'
import buildClassInfo from './ClassInfo'
import { DOCUMENT_TITLE, MAX_LESSONS_PER_PAGE } from './constants'
import buildTable from './Table'
import buildSignature from '../shared/BasePage/Signature'
import buildTableFooter from './Table/Footer'

const { chunkArray } = utilsPDF

const buildFrequencyDiaryContent = async ({
  classInfo,
  students,
  lessons,
  frequencies = []
}) => {
  const locale =
    classInfo.unit === UNITS.TRAILS_OF_THE_FUTURE.name
      ? classInfo.poleCity
      : classInfo.unitCity
  const lessonsChunk = chunkArray(lessons, MAX_LESSONS_PER_PAGE)
  const signature = buildSignature(classInfo.signatures, locale)
  const content = lessonsChunk.map((chunk, index) => [
    buildClassInfo(classInfo),
    ...buildTable({
      frequencies,
      lessons: chunk,
      students,
      classInfo,
      multiplyLessonBy: index
    })
  ])

  const lastChunkOfContent = content[content.length - 1]
  lastChunkOfContent.push(buildTableFooter(classInfo), clone(signature))

  return content
}

export default async function FrequencyDiaryPDF(frequencyData) {
  const signatureImage = frequencyData?.classInfo?.signatures[0]?.image
  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },
      ...(signatureImage
        ? {
            images: {
              signature1: {
                url: frequencyData.classInfo.signatures[0].image,
                headers: {
                  'Access-Control-Allow-Origin': '*'
                }
              }
            }
          }
        : {})
    }
  })

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE,
    mainContent: await buildFrequencyDiaryContent({
      lessons: frequencyData.lessons.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      ),
      classInfo: frequencyData.classInfo,
      frequencies: frequencyData.frequencies,
      students: frequencyData.students
    }),
    companyInfo: frequencyData.companyInfo
  })
}
