import { Grid, Tooltip } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { InputSwitch } from 'components/atoms'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import { useMakeService } from 'services/hooks'
import { defaultItemsPerPage, defaultPage } from '../constants'

const FormSearchSubjects = ({
  onClear,
  values,
  setSnack,
  setFieldValue,
  setPage,
  setFilter
}) => {
  const paramsMakeService = {
    isLevel: true,
    setSnack
  }
  const { level } = useMakeService(paramsMakeService)

  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12} sm={12} md={4}>
          <Tooltip
            title="Digite parte ou o nome completo da disciplina"
            placement="top"
            arrow
          >
            <span>
              <TextField
                fullWidth
                id="outlined-discipline"
                label="Disciplina"
                value={values?.discipline || ''}
                onChange={(e) => setFieldValue('discipline', e.target.value)}
              />
            </span>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <Select
            id="level"
            label="Nível"
            value={values?.level || ''}
            optionsList={level}
            onChange={(e) => setFieldValue('level', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={1}>
          <InputSwitch
            label="EAD"
            checked={!!values?.is_ead}
            onClick={(e) => {
              setFieldValue('is_ead', e.target.checked)
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={1}>
          <InputSwitch
            label="Inativos"
            checked={!!values.is_inactive}
            onClick={(e) => {
              setFieldValue('is_inactive', e.target.checked)
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <ButtonFooterV2
            startIcon={<Clear />}
            labelClose="Limpar"
            labelConfirm="Buscar"
            size="medium"
            typeReset="reset"
            onClickClose={onClear}
            classNameConfirm={TAG_MANAGER.management_btn_seek_discipline}
            onClickConfirm={() => {
              setPage(defaultPage)
              setFilter(defaultItemsPerPage)
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export { FormSearchSubjects }
