/* eslint-disable react/no-array-index-key */
import { useRef, useState, useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { InputQuill } from 'components/atoms'
import TableVariables from '../Table'
import { sections } from '../constants'
import * as Styled from './style'

const FormDeclarationContent = ({ values, setFieldValue }) => {
  const quillRefBody = useRef()
  const quillRefHeader = useRef()
  const quillRefFooter = useRef()
  const quillRefSignature = useRef()
  const [element, setElement] = useState(quillRefHeader)
  const [positionCursor, setPositionCursor] = useState(0)

  const handleTagClick = (tag) => {
    const quill = element.current.getEditor()
    const cursorPosition = positionCursor || 0
    return quill.insertText(cursorPosition, tag)
  }

  useEffect(() => {
    const quill = element.current.getEditor()

    quill.on('selection-change', (range) => {
      if (range) setPositionCursor(range.index)
    })
  }, [])

  return (
    <Styled.GridContainer container spacing={{ xs: 0, sm: 0 }}>
      <Grid item xs={12} sm={12} md={5.9}>
        <Grid marginBottom={2} item xs={12} sm={12} md={12}>
          <InputQuill
            id="header"
            referencie={quillRefHeader}
            label="Título da Declaração"
            values={values?.header || ''}
            handleBlur={() => setElement(quillRefHeader)}
            handleChange={(value) => setFieldValue('header', value)}
          />
        </Grid>
        <Grid marginBottom={2} item xs={12} sm={12} md={12}>
          <InputQuill
            id="requestText"
            referencie={quillRefBody}
            values={values?.body || ''}
            label="Texto da Declaração"
            handleBlur={() => setElement(quillRefBody)}
            handleChange={(value) => {
              setFieldValue('body', value)
            }}
          />
        </Grid>
        <Grid marginBottom={2} item xs={12} sm={12} md={12}>
          <InputQuill
            id="requestText"
            referencie={quillRefSignature}
            values={values?.signature || ''}
            label="Assinaturas da Declaração"
            handleBlur={() => setElement(quillRefSignature)}
            handleChange={(value) => {
              setFieldValue('signature', value)
            }}
          />
        </Grid>
        <Grid marginBottom={2} item xs={12} sm={12} md={12}>
          <InputQuill
            id="requestText"
            referencie={quillRefFooter}
            label="Rodapé da Declaração"
            values={values?.footer || ''}
            handleBlur={() => setElement(quillRefFooter)}
            handleChange={(value) => {
              setFieldValue('footer', value)
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={5.9}>
        <Grid item xs={12} sm={12} md={12}>
          {sections?.map((section, index) => (
            <Accordion key={`section.title-${index}`}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography>{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableVariables
                  itemsTable={section.tags}
                  handleTagClick={handleTagClick}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Styled.GridContainer>
  )
}
export default FormDeclarationContent
