import { Select, TextField } from 'components/molecules'
import { isEmpty } from 'services/helpers'
import * as Styled from './style'

const FormListLinkedClass = ({
  poles,
  level,
  values,
  errors,
  unitys,
  courses,
  classes,
  touched,
  handleBlur,
  setFieldValue,
  isAdvancedSearch
}) => (
  <>
    {isAdvancedSearch ? (
      <>
        <Styled.Grid item xs={12} sm={12} md={4}>
          <Select
            isColor
            id="unity"
            label="Unidade*"
            onBlur={handleBlur}
            optionsList={unitys}
            value={values?.unity || ''}
            error={touched.unity && errors.unity}
            helperText={touched.unity && errors.unity}
            onChange={(e) => setFieldValue('unity', e.target.value)}
          />
        </Styled.Grid>

        {!isEmpty(poles) && (
          <Styled.Grid item xs={12} sm={12} md={4}>
            <Select
              id="pole"
              label="Polo"
              optionsList={poles}
              disabled={!values?.unity}
              value={values?.pole || ''}
              onChange={(e) => setFieldValue('pole', e.target.value)}
            />
          </Styled.Grid>
        )}

        <Styled.Grid item xs={12} sm={12} md={4}>
          <Select
            id="level"
            label="Nível"
            optionsList={level}
            disabled={!values?.unity}
            value={values?.level || ''}
            onChange={(e) => setFieldValue('level', e.target.value)}
          />
        </Styled.Grid>
        <Styled.Grid item xs={12} sm={12} md={4}>
          <Select
            id="course"
            label="Curso"
            optionsList={courses}
            disabled={!values?.level}
            value={values?.course || ''}
            onChange={(e) => setFieldValue('course', e.target.value)}
          />
        </Styled.Grid>
        <Styled.Grid item xs={12} sm={12} md={4}>
          <Select
            id="class"
            label="Turma"
            optionsList={classes}
            disabled={!values?.course}
            value={values?.class || ''}
            onChange={(e) => setFieldValue('class', e.target.value)}
          />
        </Styled.Grid>
      </>
    ) : (
      <Styled.Grid item xs={12} sm={12} md={5}>
        <TextField
          fullWidth
          id="class"
          name="class"
          isMargin="0"
          label="Turma*"
          onBlur={handleBlur}
          value={values?.class}
          error={touched.class && errors.class}
          helperText={touched.class && errors.class}
          onChange={(e) => setFieldValue('class', e.target.value)}
        />
      </Styled.Grid>
    )}
  </>
)

export default FormListLinkedClass
