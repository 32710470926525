import { useState, lazy } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Formik } from 'formik'
import TAG_MANAGER from 'tagManager'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { SUCCESS } from 'services/constants'
import { useSnack } from 'services/hooks'
import { updateErrorMessage } from 'services/helpers'
import { newCourse } from 'services/api/admin'
import { MESSAGE, initialValues } from './constants'
import { breadcrumbsItems } from './helpers'
import * as Styled from './style'
import { payload } from './services'

const Form = lazy(() => import('./components/Form'))

const CreateStudent = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { studentName, studentCourse, register } = params
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [valuesSearch, setValuesSearch] = useState(initialValues)
  const [listPole, setListPole] = useState('')
  const [unitId, setUnitId] = useState('')
  const [selectedSchoolClass, setSelectedSchoolClass] = useState('')

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(-1)
  }

  const savedSuccessfully = () => {
    setSnack(MESSAGE.SUCCESS, SUCCESS)

    return setTimeout(() => navigate(-1), 3000)
  }

  const onSubmit = async (values) => {
    setSnack('', '')
    setLoading(true)

    const { error, status } = await newCourse(
      payload({ values, register, selectedSchoolClass })
    )

    setLoading(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListRequisition
      })
    }

    setNotSearch(false)
    return savedSuccessfully()
  }

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems({ studentName, studentCourse })}
      onClickButton={() => setOpenModal(true)}
      classNameHeader="goBack"
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      tagmanagerModal={TAG_MANAGER.secretary_btn_BackApplication}
      content={
        <>
          <Formik
            initialValues={valuesSearch}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(props) => (
              <Styled.FormFormik
                onSubmit={props.handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Form
                  {...props}
                  listPole={listPole}
                  setListPole={setListPole}
                  unitId={unitId}
                  setUnitId={setUnitId}
                  setSnack={setSnack}
                  setOpenModal={setOpenModal}
                  setSelectedSchoolClass={setSelectedSchoolClass}
                />
              </Styled.FormFormik>
            )}
          </Formik>
        </>
      }
    />
  )
}

export default CreateStudent
