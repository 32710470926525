const RACE_TYPES = [
  'BRANCO',
  'PARDO',
  'AMARELO',
  'PRETO',
  'INDÍGENA',
  'NÃO DECLARADA'
]

const CIVIL_STATUS_TYPES = [
  'Solteiro(a)',
  'Casado(a)',
  'Divorciado(a)',
  'Viúvo(a)'
]

export { RACE_TYPES, CIVIL_STATUS_TYPES }
