const MESSAGE = {
  NO_SEARCH:
    '<b>Encontre as Disciplinas aqui</b><br/>Você pode usar os critérios de filtro para realizar uma busca mais precisa.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  newSubjectCreated: 'Nova disciplina criada com sucesso'
}

const initialState = {
  subjects: '',
  level: '',
  isEAD: false,
  IsInactive: false
}

const typeAction = {
  remove: 'remove',
  copy: 'copy',
  changeOfValues: 'changeOfValues',
  planAlreadyExists: 'planAlreadyExists',
  change: 'change'
}

const countDefault = 0
const defaultPage = 1
const defaultItemsPerPage = 10
const nameAccordion = 'subjects'
const newDiscipline = 'newDiscipline'

export {
  MESSAGE,
  initialState,
  typeAction,
  countDefault,
  defaultItemsPerPage,
  nameAccordion,
  newDiscipline,
  defaultPage
}
