import humps from 'humps'
import FEEDBACK_SNACK from 'feedBackSnack'
import { listStudentPaymentMethods } from 'services/api/student'
import { updateErrorMessage } from 'services/helpers'
import { authTokenToChangeCard } from 'services/api/admin/financial/getNet'

export async function getStudentPaymentMethods({ register, onError }) {
  const { data, status, error } = await listStudentPaymentMethods({ register })

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListContract
    })
  }

  return humps.camelizeKeys(data)
}

export async function getAuthTokenToChangeCard({
  register,
  contractId,
  onError
}) {
  const payload = humps.decamelizeKeys({ register, contractId })

  const { data, status, error } = await authTokenToChangeCard(payload)

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateContract
    })
  }

  return humps.camelizeKeys(data)
}
