import { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { ButtonFooterV2, InputFile, Select } from 'components/molecules'
import theme from 'theme/designTokens'
import { getRgba } from 'services/helpers/polished'
import { MESSAGE } from './constants'
import {
  handleSelectDocuments,
  importIntegrateEvaluations
} from '../../service'
import * as Styled from './style'

const ModalUpload = ({ open, setOpen, setSnack, isLoad, register }) => {
  const [nameFile, setNameFile] = useState('')
  const [selectList, setSelectList] = useState([])
  const [errorTypeFile, setErrorTypeFile] = useState(false)
  const [formAddIA, setFormIA] = useState({
    file: '',
    document: ''
  })

  const handleClose = () => {
    setOpen(false)
  }

  const acceptedMimeTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/jpg'
  ]

  const handlePDF = (file, setFieldValue) => {
    const reader = new FileReader()

    reader.onload = () => {
      const base64String = reader.result
      setErrorTypeFile(false)
      setFieldValue('file', base64String)
    }

    reader.readAsDataURL(file)
  }

  const handleImage = (file, setFieldValue) => {
    const reader = new FileReader()

    reader.onload = () => {
      const base64String = reader.result
      setErrorTypeFile(false)
      setFieldValue('file', base64String)
    }

    reader.readAsDataURL(file)
  }

  const handleFileChange = ({ event, setFieldValue }) => {
    if (!event.target.files || event.target.files.length === 0) {
      return setFieldValue('file', '')
    }

    const selectedFile = event.target.files[0]

    if (acceptedMimeTypes.includes(selectedFile.type)) {
      if (selectedFile.type === 'application/pdf') {
        handlePDF(selectedFile, setFieldValue)
      } else {
        handleImage(selectedFile, setFieldValue)
      }
    }

    setNameFile(selectedFile.name)
    return setErrorTypeFile(true)
  }

  useEffect(() => {
    handleSelectDocuments({ register, isLoad, setSnack, setSelectList })
  }, [])

  return (
    <Styled.Modal open={open} onClose={handleClose} maxWidth="md">
      <Formik
        initialValues={formAddIA}
        onSubmit={(values) =>
          importIntegrateEvaluations({
            register,
            values,
            setSnack,
            isLoad,
            handleClose,
            setFormIA,
            selectList
          })
        }
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, dirty }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Styled.GridHeader item xs={12} sm={12} md={11}>
                  <Styled.Title variant="h4" component="h4">
                    Enviar arquivo
                  </Styled.Title>
                  <Styled.GridClose>
                    <Styled.Close onClick={() => handleClose()} />
                  </Styled.GridClose>
                </Styled.GridHeader>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  $errorTypeFile={errorTypeFile}
                >
                  <InputFile
                    idUpload="upload"
                    typeFile="pdf,image/*" // Update the supported file types here
                    labelBox={errorTypeFile ? MESSAGE.ERROR : MESSAGE.SUCCESS}
                    imgSrc={values?.file}
                    name="Enviar Documentos"
                    nameFile={nameFile}
                    hasSize
                    noPreview
                    errorTypeFile={errorTypeFile}
                    border={`2px dashed ${theme.palette.dark.light}`}
                    backgroundColor={
                      errorTypeFile
                        ? getRgba(theme.palette.red.lightRgb, 0.21)
                        : theme.palette.purple.light
                    }
                    width="auto"
                    onChange={(e) =>
                      handleFileChange({ event: e, setFieldValue })
                    }
                  />
                  <Styled.SectionSelect>
                    <Select
                      fullWidth
                      id="document"
                      value={values?.document || ''}
                      label="Tipo de documento"
                      optionsList={selectList}
                      onChange={(e) => {
                        setFieldValue('document', e.target.value)
                      }}
                    />
                  </Styled.SectionSelect>

                  <Styled.TextAlert variant="subtitle2">
                    Somente arquivos em PDF ou JPG, com até 5MB
                  </Styled.TextAlert>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <ButtonFooterV2
                    labelClose="Cancelar"
                    labelConfirm="Importar"
                    size="large"
                    onClickClose={() => handleClose()}
                    disabledConfirm={errorTypeFile || !dirty}
                  />
                </Grid>
              </Grid>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export { ModalUpload }
