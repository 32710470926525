import { lazy } from 'react'
import { INITIAL_TRANSFERLIST_RIGH } from './constants'

const Form = lazy(() => import('../Form'))
const TransferList = lazy(() => import('components/organisms/TransferList'))

const optionsTabs = (idCourse) => {
  const formCourse = {
    label: 'Dados do Curso',
    component: ({
      nameCourse,
      conditionFormValues,
      level,
      unitys,
      isEdit,
      listPole,
      onUnitsRegions,
      filterListPole,
      formNewCourse,
      setFormCourse,
      hasPole,
      setHasPole,
      ...props
    }) => (
      <Form
        {...props}
        nameCourse={nameCourse}
        unitForm
        isEdit={isEdit}
        listPole={listPole}
        unitys={unitys}
        hasPole={hasPole}
        loadOptions={filterListPole}
        setHasPole={setHasPole}
        onUnitsRegions={onUnitsRegions}
        level={level}
        initialState={conditionFormValues}
        formNewCourse={formNewCourse}
        setFormCourse={setFormCourse}
      />
    )
  }

  if (idCourse) {
    return [
      formCourse,
      {
        label: 'Documentos obrigatórios',
        component: ({
          listDocumentsRequired,
          listSavedDocRequired,
          setListDocsSubmit,
          ...props
        }) => (
          <TransferList
            {...props}
            setListDocsSubmit={setListDocsSubmit}
            leftList={listDocumentsRequired}
            rightList={listSavedDocRequired || INITIAL_TRANSFERLIST_RIGH}
          />
        )
      }
    ]
  }

  return [formCourse]
}

export { optionsTabs }
