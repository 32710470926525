import FEEDBACK_SNACK from 'feedBackSnack'
import {
  convertToParams,
  currencyMask,
  getMoneyFormatToNumber,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import {
  editDetailsClassLinked,
  getDetailsClassLinked,
  getSchoolClassListDisciplines,
  postClassLinked
} from 'services/api/admin'
import { cleanObject } from 'services/helpers/cleanObject'
import { ROUTE } from 'services/constants'
import { MESSAGE, defaultItemsPerPage, defaultPage } from './constants'

const getListDisciplines = async ({
  id,
  filter,
  isEdit,
  setSnack,
  setCount,
  pageActual,
  isReplacement,
  setLoadingOpen,
  setListDisciplines
}) => {
  setLoadingOpen(true)

  const params = cleanObject({
    limit:
      isReplacement || isEdit === 'true' ? 200 : filter || defaultItemsPerPage,
    page: pageActual || defaultPage
  })

  const { data, error, status } = await getSchoolClassListDisciplines(
    id,
    params
  )

  setLoadingOpen(false)

  setCount(data?.count)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDisciplines
    })
  }

  return setListDisciplines(data?.data)
}

const getDetails = async ({
  id,
  setSnack,
  setLoadingOpen,
  setClassDetails
}) => {
  setLoadingOpen(false)

  const { data, error, status } = await getDetailsClassLinked(id)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToViewDetailsClassLinked
    })
  }

  return setClassDetails(data)
}

const editClass = async ({
  id,
  name,
  isEdit,
  navigate,
  setSnack,
  idProfessor,
  setIsUpdate,
  setSelected,
  disciplines,
  setLoadingOpen,
  setListSelected
}) => {
  setLoadingOpen(true)

  const body = cleanObject({
    disciplines,
    professor_id: parseFloat(idProfessor)
  })

  const { error, status } = await editDetailsClassLinked(id, body)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditClassLinked
    })
  }
  setSelected([])
  setListSelected([])
  toastNotificationSuccess(MESSAGE.success, 1500)

  navigate(
    `${ROUTE.ADMIN_HR_DETAILS_PROFESSOR}?${convertToParams({
      isEdit,
      id: idProfessor,
      name
    })}`
  )

  return setIsUpdate((prev) => !prev)
}

const postLinkedClass = async ({
  id,
  name,
  isEdit,
  navigate,
  setSnack,
  idProfessor,
  setIsUpdate,
  setSelected,
  disciplines,
  setLoadingOpen,
  setListSelected
}) => {
  setLoadingOpen(true)

  const body = cleanObject({
    disciplines,
    school_class_id: parseFloat(id),
    professor_id: parseFloat(idProfessor)
  })

  const { error, status } = await postClassLinked(body)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditClassLinked
    })
  }
  setSelected([])
  setListSelected([])
  toastNotificationSuccess(MESSAGE.success, 1500)
  navigate(
    `${ROUTE.ADMIN_HR_DETAILS_PROFESSOR}?${convertToParams({
      isEdit,
      id: idProfessor,
      name
    })}`
  )

  return setIsUpdate((prev) => !prev)
}

const handleTransformList = (list, newValue) => {
  const transformList = list.map((item) => ({
    id: item,
    hourly_lesson_cost: getMoneyFormatToNumber(currencyMask(newValue || ''))
  }))

  return transformList
}

const handleTransformListByEdit = (list, positions, newValue) => {
  const transformList = list.map((item, index) => ({
    id: item,
    position: positions[index] || positions[0],
    hourly_lesson_cost: getMoneyFormatToNumber(currencyMask(newValue || ''))
  }))

  return transformList
}
export {
  editClass,
  getDetails,
  postLinkedClass,
  getListDisciplines,
  handleTransformList,
  handleTransformListByEdit
}
