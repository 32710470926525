/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import {
  Alert,
  Box,
  FormControlLabel,
  Radio,
  Snackbar,
  StepLabel,
  Typography
} from '@mui/material'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTE } from 'services/constants'
import { TextNotFound } from 'components/atoms'
import { Contacts } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import FormStudentData from '../FormStudentData'
import EnrollmentCompleted from '../EnrollmentCompleted'
import {
  activeStepPayment,
  FINANCIAL_RESPONSIBLE,
  steps,
  STUDENT,
  activeStepZero,
  activeStepOne,
  MESSAGE
} from '../../constants'
import { initialValues as initialValuesForm } from './initialValues'
import * as Styled from './style'
import FormJuridicalPersonData from '../FormJuridicalPersonData'
import FormPhysicalPersonData from '../FormPhysicalPersonData'
import { formSchema } from './schema'
import {
  confirmRegister,
  handleBack,
  handleClose,
  handleNext,
  validateCpf
} from '../../services'

const Content = ({
  group,
  setSnack,
  dataCoupon,
  dataHeader,
  activeStep,
  couponData,
  anticipation,
  enrollmentId,
  setActiveStep,
  detailsCampaign
}) => {
  const { setLoading } = useLoading()
  const location = useLocation()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [isLoad, setIsLoad] = useState(false)
  const [skipped, setSkipped] = useState(new Set())
  const [isUnderage, setIsUnderage] = useState(false)
  const [isValidCpf, setIsValidCpf] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [valuesAllForms, setValuesAllForms] = useState(initialValuesForm)
  const [isEmptyCountry, setIsEmptyCountry] = useState(true)
  const [detailsRegistration, setDetailsRegistration] = useState()
  const [financialOfficerType, setFinancialOfficerType] = useState({
    type: 'cpf',
    value: 'PessoaFisica'
  })

  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const courseName = location.state.data?.course_name

  const isStepSkipped = (step) => skipped.has(step)

  const userActiveStep =
    activeStep === activeStepZero ? STUDENT : FINANCIAL_RESPONSIBLE

  const errorMessageCpf =
    errorMessage.toUpperCase() === MESSAGE.PENDING_TRADE.toUpperCase() &&
    MESSAGE.ACTIVE_CPF

  const handleConfirmRegister = async (payload) => {
    setLoading(true)
    await confirmRegister({
      group,
      skipped,
      setSnack,
      setIsLoad,
      activeStep,
      setSkipped,
      dataCoupon,
      setMessage,
      anticipation,
      enrollmentId,
      isStepSkipped,
      setActiveStep,
      setShowMessage,
      financialOfficerType,
      setDetailsRegistration,
      values: payload
    })
    setLoading(false)
  }

  const handleNewPreEnrollment = () => {
    navigate(`${ROUTE.ADMIN_COMMERCIAL_REGISTRATION_CREATE}`)
  }

  return (
    <Box>
      <Styled.Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Styled.Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Styled.Step>
        ))}
      </Styled.Stepper>
      {activeStep !== activeStepPayment && (
        <div>
          {userActiveStep === FINANCIAL_RESPONSIBLE && (
            <Styled.BoxSwitch>
              <Styled.RadioGroup row>
                <FormControlLabel
                  value="student"
                  disabled={isUnderage}
                  control={<Radio size="small" />}
                  label="O aluno é o responsável financeiro"
                  checked={
                    valuesAllForms.financialOfficer &&
                    financialOfficerType.value === 'student'
                  }
                  onClick={(e) => {
                    if (!isUnderage) {
                      setFinancialOfficerType({
                        value: e.target.value,
                        type: 'student'
                      })
                      setValuesAllForms((prev) => ({
                        ...prev,
                        financialOfficer: true
                      }))
                    }
                  }}
                />
                <FormControlLabel
                  value="PessoaFisica"
                  label="Pessoa Física"
                  control={<Radio size="small" />}
                  checked={financialOfficerType.value === 'PessoaFisica'}
                  onClick={(e) => {
                    setFinancialOfficerType({
                      value: e.target.value,
                      type: 'cpf'
                    })
                    setValuesAllForms((prev) => ({
                      ...prev,
                      financialOfficer: false
                    }))
                  }}
                />
                <FormControlLabel
                  value="PessoaJuridica"
                  label="Pessoa Jurídica"
                  checked={financialOfficerType.value === 'PessoaJuridica'}
                  control={<Radio size="small" />}
                  onClick={(e) => {
                    setFinancialOfficerType({
                      value: e.target.value,
                      type: 'cnpj'
                    })
                    setValuesAllForms((prev) => ({
                      ...prev,
                      financialOfficer: false
                    }))
                  }}
                />
              </Styled.RadioGroup>

              {financialOfficerType.value === 'student' && (
                <>
                  <Typography>
                    Selecione esta opção caso o aluno seja maior de idade e
                    responsável pelo pagamento das mensalidades. Isso fará com
                    que o contrato e os boletos sejam gerados em nome do aluno.
                    Caso contrário, desmarque esta opção e informe os dados do
                    responsável financeiro.
                  </Typography>

                  <Styled.ContainerButton>
                    <ButtonFooterV2
                      size="medium"
                      type="submit"
                      labelClose="Voltar"
                      labelConfirm="Finalizar"
                      classNameConfirm={
                        TAG_MANAGER.commercial_btn_search_for_registrations
                      }
                      onClickConfirm={() =>
                        handleConfirmRegister(valuesAllForms)
                      }
                      onClickClose={() => {
                        handleBack({
                          open,
                          setOpen,
                          activeStep,
                          setActiveStep,
                          valuesAllForms,
                          setValuesAllForms
                        })
                      }}
                    />
                  </Styled.ContainerButton>
                </>
              )}
            </Styled.BoxSwitch>
          )}

          {activeStep === activeStepZero && (
            <FormStudentData
              isLoad={isLoad}
              setIsLoad={setIsLoad}
              showError={showError}
              isValidCpf={isValidCpf}
              setIsUnderage={setIsUnderage}
              setIsValidCpf={setIsValidCpf}
              setFinancialOfficerType={setFinancialOfficerType}
              valuesAllForms={valuesAllForms}
              isEmptyCountry={isEmptyCountry}
              userActiveStep={userActiveStep}
              errorMessageCpf={errorMessageCpf}
              setIsEmptyCountry={setIsEmptyCountry}
              setValuesAllForms={setValuesAllForms}
              handleNext={(values) => {
                handleNext({
                  values,
                  skipped,
                  activeStep,
                  setSkipped,
                  setMessage,
                  setActiveStep,
                  isStepSkipped,
                  setShowMessage,
                  setValuesAllForms
                })
              }}
              onClickBack={() =>
                handleBack({
                  open,
                  setOpen,
                  activeStep,
                  setActiveStep,
                  valuesAllForms,
                  setValuesAllForms
                })
              }
              handleValidateCpf={(cpf) => {
                validateCpf({
                  cpf,
                  setIsLoad,
                  courseName,
                  setShowError,
                  setIsValidCpf,
                  valuesAllForms,
                  setErrorMessage,
                  setValuesAllForms
                })
              }}
            />
          )}

          {activeStep === activeStepOne &&
            financialOfficerType.value === 'PessoaFisica' && (
              <FormPhysicalPersonData
                isLoad={isLoad}
                valuesAllForms={valuesAllForms}
                setValuesAllForms={setValuesAllForms}
                handleConfirmRegister={(payload) =>
                  handleConfirmRegister(payload)
                }
                onClickBack={() =>
                  handleBack({
                    open,
                    setOpen,
                    activeStep,
                    setActiveStep,
                    valuesAllForms,
                    setValuesAllForms
                  })
                }
              />
            )}
          {activeStep === activeStepOne &&
            financialOfficerType.value === 'PessoaJuridica' && (
              <FormJuridicalPersonData
                valuesAllForms={valuesAllForms}
                setValuesAllForms={setValuesAllForms}
                handleConfirmRegister={(payload) =>
                  handleConfirmRegister(payload)
                }
                onClickBack={() =>
                  handleBack({
                    open,
                    setOpen,
                    activeStep,
                    setActiveStep,
                    valuesAllForms,
                    setValuesAllForms
                  })
                }
              />
            )}

          {activeStep === activeStepZero && !isValidCpf && showError && (
            <TextNotFound
              widthText="400px"
              icon={<Contacts />}
              text={errorMessage}
            />
          )}
        </div>
      )}

      {open && (
        <ModalConfirm
          open={open}
          handleClose={() => setOpen(!open)}
          title="Cancelar processo"
          message="Deseja cancelar o processo atual e perder as alterações?"
          textButtonNotConfirm="Não"
          textButtonConfirm="Sim"
          onClickNotConfirm={() => setOpen(!open)}
          onClickConfirm={() =>
            navigate(ROUTE.ADMIN_COMMERCIAL_REGISTRATION_CREATE, {
              state: { savedSearch: true }
            })
          }
          colorButtonConfirm="primary"
        />
      )}

      {activeStep === activeStepPayment && (
        <EnrollmentCompleted
          dataCoupon={dataCoupon}
          dataHeader={dataHeader}
          couponData={couponData}
          data={detailsRegistration}
          detailsCampaign={detailsCampaign}
          handleNewPreEnrollment={handleNewPreEnrollment}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={showMessage}
        onClose={(e, reason) => handleClose({ reason, setShowMessage })}
        autoHideDuration={6000}
      >
        <Alert variant="filled" severity="error">
          {message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Content
