import { Grid as MuiGrid, Snackbar as MuiSnackbar } from '@mui/material'
import styled from 'styled-components'

const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const GridContainer = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
`

const Snackbar = styled(MuiSnackbar)`
  .MuiPaper-root {
    color: #fff;
    min-width: 250px;
  }
`
const Form = styled.form``

export { Grid, GridContainer, Snackbar, Form }
