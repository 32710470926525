import { httpPost } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const login = async (data) => {
  const tokenLocalhost = process.env.REACT_APP_TOKEN_LOCALHOST
  const dataToLogin = {
    ...data,
    ...(tokenLocalhost ? { token_localhost: tokenLocalhost } : {})
  }
  const response = await httpPost(ENDPOINT.LOGIN, dataToLogin)
  return response
}

export const loginAutomaticToken = async (token) => {
  const response = await httpPost(ENDPOINT.LOGIN_LEGACY, { token })
  return response
}
