const password = 'password'
const tickets = 'tickets'
const requeriment = 'requeriment'
const coursesAndClasses = 'coursesAndClasses'
const occurrences = 'occurrences'
const documents = 'documents'
const registrationData = 'registrationData'

const noSchoolClass = 'no_school_class'
const inProgress = 'in_progress'
const pendingReEnrollment = 'pending_re_enrollment'
const occurrence = 'occurrence'
const pendingContract = 'pending_contract'

const messageServiceExcludeCourse = {
  successMessage: 'Curso excluído com sucesso',
  feedbackMessage: 'Houve um erro ao excluir o curso. Tente novamente.'
}

const messageModalConfirm = {
  title: 'Excluir Curso',
  message: 'Você tem certeza que deseja excluir este curso?',
  textButtonConfirm: 'Sim',
  textButtonNotConfirm: 'Não'
}

export {
  password,
  tickets,
  requeriment,
  coursesAndClasses,
  occurrences,
  documents,
  registrationData,
  noSchoolClass,
  inProgress,
  pendingReEnrollment,
  occurrence,
  pendingContract,
  messageServiceExcludeCourse,
  messageModalConfirm
}
