import { useState } from 'react'
import { TableContainer, Table, TableBody, Paper } from '@mui/material'
import { TableHeaderDefault } from 'components/organisms'
import { getComparator, stableSort } from 'services/helpers'
import { useReplaceClassDetailsContext } from '../../Context'
import { headerData } from './constants'
import TableBodyRow from './TableBodyRow'

export default function ReplaceClassDetailsTable() {
  const { disciplines } = useReplaceClassDetailsContext()
  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()

  const handleRequestSort = (_, property) => {
    const sortAsc = 'asc'
    const sortDesc = 'desc'

    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHeaderDefault
          headCells={headerData}
          order={order}
          orderBy={orderBy}
          rowCount={disciplines?.length}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(disciplines, getComparator(order, orderBy)).map(
            (discipline) => (
              <TableBodyRow discipline={discipline} key={discipline.id} />
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
