import USER_ROUTES from './userRoutes'
import PROFESSOR_ROUTES from './professorRoutes'
import STUDENTS_ROUTES from './studentRoutes'
import ADMIN_ROUTES from './adminRoutes'

export const PRIVATE_ROUTES = [
  ...USER_ROUTES,
  ...PROFESSOR_ROUTES,
  ...STUDENTS_ROUTES,
  ...ADMIN_ROUTES
]

const ALL_ROUTES = [...PRIVATE_ROUTES]

export const getPriviteRoutes = () => PRIVATE_ROUTES

export default ALL_ROUTES
