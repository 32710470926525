const STATUS_SUCCESS = 200

const defaultItemsPerPage = 10
const nameAccordion = 'billsReceive'
const INITIAL_PAGE = 1

const initialValue = {
  searchType: '',
  searchBy: '',
  firstPeriod: null,
  endPeriod: null,
  unity: '',
  course: [],
  title: '',
  level: '',
  checkSocialReason: false,
  switchDefaulters: false,
  switchPaid: false,
  switchAdvancedSearch: false
}

const MESSAGE = {
  NO_SEARCH_PERFOMED:
    '<b>Você ainda não fez uma busca</b><br/>Utilize a busca simplificada para usar apenas um dos campos pré-<br/>definidos, ou a avançada para utilizar um conjunto de critérios na<br/> pesquisa.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  success: 'Download realizado com sucesso',
  error: 'Falha ao realizar o download, tente mais tarde'
}

const DEFAULT_LIMIT = 10
const bankBillingSavedSucess = 'Título salvo'
const countOfEmptyList = 0

export {
  STATUS_SUCCESS,
  MESSAGE,
  defaultItemsPerPage,
  nameAccordion,
  initialValue,
  INITIAL_PAGE,
  DEFAULT_LIMIT,
  bankBillingSavedSucess,
  countOfEmptyList
}
