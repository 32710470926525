import {
  getCostCenterName,
  getCostCenterNature,
  getCostCenterUnit,
  newBankBilling
} from 'services/api/admin'
import {
  formatDate,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getStudentByCourse } from 'services/api/shared'
import { convertedValuesToNewBankBilling } from './helpers'
import { successMessage } from './contants'

const createTitle = async ({ values, params, navigate }) => {
  const payload = convertedValuesToNewBankBilling({
    ...values,
    registerId: params?.register
  })

  const { error, status } = await newBankBilling(payload)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateTitleDetails
    })
  }

  toastNotificationSuccess(successMessage)

  return setTimeout(() => navigate(-1), 3000)
}

const handleCostCenterUnit = async ({ setListUnits }) => {
  const { data, error, status } = await getCostCenterUnit()

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCostCenterUnit
    })
  }

  return setListUnits(data?.data)
}

const handleCostCenterNature = async ({ setListNature }) => {
  const { data: response, error, status } = await getCostCenterNature()
  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCostCenterNature
    })
  }

  return setListNature(response?.data)
}

const handleCostCenter = async ({ setListCostCenter }) => {
  const { data: response, error, status } = await getCostCenterName()

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCostCenter
    })
  }

  return setListCostCenter(response?.data)
}

const handleCourses = async ({ registerId, setListCourses }) => {
  const {
    data: response,
    error,
    status
  } = await getStudentByCourse(registerId, true)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCourses
    })
  }

  const listFormatted = (response ?? [])?.map((item) => ({
    ...item,
    label: `${item?.level}: ${item?.course} [${item?.id}] / Grupo: ${item?.group} / ${item?.shift} / ${item?.unit}`,
    name: item?.id,
    value: item?.id
  }))

  return setListCourses(listFormatted)
}

const initialAllServices = ({
  registerId,
  setListCourses,
  setListUnits,
  setListNature,
  setListCostCenter
}) => {
  Promise.all([
    handleCostCenterUnit({ setListUnits }),
    handleCostCenterNature({ setListNature }),
    handleCostCenter({ setListCostCenter }),
    handleCourses({ registerId, setListCourses })
  ])
}

export { createTitle, initialAllServices }
