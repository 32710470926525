import { ROUTE } from 'services/constants'

const breadcrumbsItems = [
  {
    path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION,
    label: 'Comercial'
  },
  {
    path: ROUTE.ADMIN_COMMERCIAL_DIRECT_ENROLLMENT,
    label: 'Matrícula Manual'
  },
  {
    path: '',
    label: '<b>Pagamento no cartão de crédito</b>'
  }
]

export { breadcrumbsItems }
