import FEEDBACK_SNACK from 'feedBackSnack'
import { formatDateEnv, isEmpty, updateErrorMessage } from 'services/helpers'
import {
  getListStudentEmailLogs,
  getListStudentSmsLogs,
  getListStudentWhatsAppLogs
} from 'services/api/admin'
import { EMAIL, WHATSAPP, SMS } from './constants'

const getInteractionsStudent = async ({
  register,
  values,
  setSnack,
  setLoadingOpen,
  setListLogs,
  setNotFound,
  setCount,
  page,
  limit
}) => {
  setSnack('')
  setLoadingOpen(true)

  const params = {
    ...(values.beginAt && { start_at: formatDateEnv(values.beginAt) }),
    ...(values.endAt && { end_at: `${formatDateEnv(values.endAt)} 23:59:59` }),
    page,
    limit
  }

  const getIntectionsList = async (type) =>
    ({
      [EMAIL]: async () => getListStudentEmailLogs({ register, params }),
      [SMS]: async () => getListStudentSmsLogs({ register, params }),
      [WHATSAPP]: async () => getListStudentWhatsAppLogs({ register, params })
    }[type]())

  const {
    data: response,
    error,
    status
  } = await getIntectionsList(values.searchType)

  setLoadingOpen(false)
  setCount(response?.count)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListStudentsInteractions
    })
  }

  setNotFound(!!isEmpty(response.data))

  return setListLogs(response.data)
}

export { getInteractionsStudent }
