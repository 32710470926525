import { Formik } from 'formik'
import { Close } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { Select, TextField } from 'components/molecules'
import { InputSwitch } from 'components/atoms'
import { useMakeService } from 'services/hooks'
import { defaultItemsPerPage, defaultPage } from '../../constants'
import * as Styled from './style'

const FormSearchGroups = ({
  handleClear,
  onSubmit,
  initialValues,
  setSnack,
  setPage,
  setFilter
}) => {
  const paramsMakeService = {
    isUnits: true,
    unitsValue: true,
    setSnack
  }
  const { unitys } = useMakeService(paramsMakeService)

  const disabledButton = ({ name, unit }) => {
    const disabled = name || unit

    return !disabled
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue, handleSubmit, handleChange }) => (
        <Styled.Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Nome do Grupo"
                value={values?.name || ''}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Select
                id="unit"
                value={values?.unit || ''}
                label="Unidade"
                optionsList={unitys}
                onChange={(e) => setFieldValue('unit', e.target.value)}
              />
            </Grid>
            <Styled.GridInputSwitch item xs={12} sm={12} md={1.5}>
              <InputSwitch
                label="Inativos"
                checked={values.active}
                onClick={(e) => setFieldValue('active', e.target.checked)}
              />
            </Styled.GridInputSwitch>

            <Grid item xs={12} sm={12} md={2.5}>
              <Styled.GroupButton>
                <Styled.Button
                  variant="outlined"
                  type="reset"
                  onClick={() => handleClear()}
                  disabled={disabledButton(values)}
                  startIcon={<Close />}
                  value="Limpar"
                />

                <Styled.Button
                  variant="contained"
                  disabled={disabledButton(values)}
                  type="submit"
                  value="Buscar"
                  onClick={() => {
                    setPage(defaultPage)
                    setFilter(defaultItemsPerPage)
                  }}
                />
              </Styled.GroupButton>
            </Grid>
          </Grid>
        </Styled.Form>
      )}
    </Formik>
  )
}

export default FormSearchGroups
