import * as yup from 'yup'

const replaceFormSchemaBase = yup.object().shape({
  id: yup.number().required('Id da disciplina é requerido'),
  substitutionReason: yup.string().required(),
  hourlyLessonCost: yup.string().required(),
  isPermanent: yup.boolean().required('Tipo de substituição é obrigatório'),
  startDate: yup
    .date()
    .nullable(true)
    .optional()
    .when('is_permanent', {
      is: (value) => value === false,
      then: yup
        .date()
        .required('Data de início requerida')
        .typeError('Data Inválida')
    }),
  endDate: yup
    .date()
    .nullable(true)
    .optional()
    .when('is_permanent', {
      is: (value) => value === false,
      then: yup
        .date()
        .required('Data de fim requerida')
        .typeError('Data Inválida')
    }),
  substituteProfessorId: yup
    .number()
    .required('Id do professor a ser substituído é requirido')
})

export const validateReplaceForm = (values) => {
  const errors = { replaces: {} }

  values.replaces.forEach((replace) => {
    try {
      replaceFormSchemaBase.validateSync(replace, {
        abortEarly: false
      })
    } catch (error) {
      const replaceErrors = {}
      error.inner.forEach((validationError) => {
        const { path } = validationError.params
        replaceErrors[path] = validationError.message
      })
      errors.replaces[replace.id] = replaceErrors
    }
  })
  return Object.keys(errors.replaces).length ? errors : null
}
