const initialState = {
  classIdentifier: '',
  unit: '',
  closed: false,
  ead: false,
  pole: [],
  group: [],
  level: '',
  course: '',
  shift: '',
  module: ''
}

const headCells = [
  {
    id: 'class_code',
    numeric: false,
    disablePadding: true,
    label: 'Turma'
  },
  {
    id: 'situation',
    numeric: false,
    disablePadding: false,
    label: 'Situação'
  },
  {
    id: 'unit_name',
    numeric: false,
    disablePadding: false,
    label: 'Unidade'
  },
  {
    id: 'group_name',
    numeric: false,
    disablePadding: false,
    label: 'Grupo'
  },
  {
    id: 'course_name',
    numeric: false,
    disablePadding: false,
    label: 'Curso'
  },
  {
    id: 'class_shift',
    numeric: false,
    disablePadding: false,
    label: 'Turno'
  }
]

export { initialState, headCells }
