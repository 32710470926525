import { useNavigate } from 'react-router-dom'
import { Link, Paper, Table, TableBody, TableCell } from '@mui/material'

import { useSortTable } from 'services/hooks'
import { defaultlistPerPage } from 'services/hooks/constants'
import { paginationFilter } from 'services/helpers'
import { ROUTE } from 'services/constants'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'

import { KEY_COLUMNS, tableColumns } from './config'

import * as Styled from './style'

export default function List({
  limit,
  page,
  sectors,
  count,
  totalPage,
  handleSetPage,
  handleChange
}) {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    sectors,
    KEY_COLUMNS.SECTOR
  )

  const navigate = useNavigate()

  const redirectToEidt = (sector) => {
    navigate(ROUTE.sectors.edit, {
      state: { sector }
    })
  }

  return (
    <Styled.Wrapper>
      <Paper>
        <Table size="medium">
          <TableHeaderDefault
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableColumns}
          />
          <TableBody>
            {sortTable?.map((sector, index) => {
              const labelId = `sectors-table-${index}`

              return (
                <Styled.TableRow key={labelId}>
                  <TableCell padding="checkbox" style={{ width: '50%' }}>
                    <Styled.WrapperLink>
                      <Link
                        onClick={() => redirectToEidt(sector)}
                        component="button"
                        href="#root"
                      >
                        {sector?.unity}
                      </Link>
                    </Styled.WrapperLink>
                  </TableCell>
                  <TableCell>{sector?.sectorName}</TableCell>
                </Styled.TableRow>
              )
            })}
          </TableBody>
        </Table>

        {count > defaultlistPerPage && (
          <PaginationAdvanced
            isSelectItensPerPage
            handleChange={handleChange}
            handleSetPage={handleSetPage}
            count={totalPage}
            optionsList={paginationFilter}
            page={page}
            smallSize
            value={limit}
          />
        )}
      </Paper>
    </Styled.Wrapper>
  )
}
