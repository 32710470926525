import { useSnack } from 'services/hooks'

import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { useState } from 'react'
import { Content } from './components'

const ReEnrollment = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps

  return (
    <TemplateDefaultFormAndResult snackProps={snackProps}>
      <Content setSnack={setSnack} />
    </TemplateDefaultFormAndResult>
  )
}

export default ReEnrollment
