export default function buildDividerDash({ color, margin } = {}) {
  return {
    table: {
      body: [[{ text: '', border: [false, false, false, true] }]],
      widths: '*'
    },
    layout: {
      hLineWidth(i, node) {
        return 1
      },
      hLineStyle(i, node) {
        return { dash: { length: 5, space: 5 } }
      },
      vLineStyle(i, node) {
        return { dash: { length: 4 } }
      },
      hLineColor(i, node) {
        return color ?? '#FF0000'
      }
    },
    margin: margin ?? [0, 10, 0, 20]
  }
}
