import { ModalConfirm } from 'components/molecules'
import { removeNotifications } from 'services/api/shared'
import { SUCCESS } from 'services/constants'
import { updateErrorMessage } from 'services/helpers'
import { defaultPage, messageNotificationDeleted } from '../../constants'

const ModalConfirmByDelete = ({
  setLoading,
  id,
  openModal,
  setOpenModal,
  setSnack,
  handleListNotifications,
  page,
  setPage
}) => {
  const onConfirmModal = async () => {
    setLoading(true)
    setSnack('', '')
    const { error, status } = await removeNotifications({ id })
    setLoading(false)
    setOpenModal(false)
    setSnack(messageNotificationDeleted, SUCCESS)
    if (!error) {
      if (page === defaultPage) {
        handleListNotifications()
      }
      setPage(defaultPage)

      setOpenModal(false)
      return setSnack(messageNotificationDeleted, SUCCESS)
    }

    return updateErrorMessage({
      setSnack,
      error,
      status
    })
  }

  return (
    <ModalConfirm
      open={openModal}
      handleClose={() => setOpenModal(!openModal)}
      iconWarningAmber
      title="Excluir Notificação"
      message="Deseja realmente excluir a notificação?"
      textButtonNotConfirm="Não"
      textButtonConfirm="Sim"
      onClickNotConfirm={() => setOpenModal(!openModal)}
      onClickConfirm={() => onConfirmModal()}
    />
  )
}

export default ModalConfirmByDelete
