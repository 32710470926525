import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { COMPANY_MINAS_ID } from 'services/constants/company'
import buildObservations from 'components/templates/PDF/shared/Observation'
import buildResponsibleTeacher from 'components/templates/PDF/shared/ResponsibleTeacher'
import {
  MAX_SCORE_IN_NEW_GRADE,
  OBSERVATION_MINAS_NEW_GRADE,
  OBSERVATION_MINAS_OLD_GRADE,
  OBSERVATION_OUTHER_COMPANIES
} from '../../constants'

const { borderLayoutDefault } = utilsPDF

const handleObservationText = ({ companyId, minimumGrade }) => {
  if (companyId === COMPANY_MINAS_ID && minimumGrade <= MAX_SCORE_IN_NEW_GRADE)
    return OBSERVATION_MINAS_NEW_GRADE
  if (companyId === COMPANY_MINAS_ID && minimumGrade > MAX_SCORE_IN_NEW_GRADE)
    return OBSERVATION_MINAS_OLD_GRADE
  return OBSERVATION_OUTHER_COMPANIES
}

const buildTableFooter = ({
  responsibleTeachers,
  totalWorkloadCompleted,
  unit,
  numberOfCompletedLessons,
  companyId,
  minimumGrade
}) => ({
  table: {
    body: [
      ...buildObservations({
        text: handleObservationText({ companyId, minimumGrade })
      }),
      ...buildResponsibleTeacher({
        responsibleTeachers,
        totalWorkloadCompleted,
        unit,
        numberOfCompletedLessons
      })
    ],
    widths: [240, 60, '*']
  },
  layout: {
    ...borderLayoutDefault,
    paddingBottom: () => 10,
    paddingLeft: () => 10,
    paddingRight: () => 10,
    paddingTop: () => 10
  }
})
export default buildTableFooter
