import styled from 'styled-components'
import { Form as FormikForm } from 'formik'
import { Grid } from '@mui/material'
import { Button as AtomButton } from 'components/atoms'

const Form = styled(FormikForm)`
  margin-top: 30px;
`

const GroupButton = styled.div`
  display: flex;
  align-itens: center;
  justify-content: flex-end;
`

const Button = styled(AtomButton)`
  &.MuiButtonBase-root {
    margin: 8px 0 0 0;

    &:last-child {
      margin: 8px 0 0 14px;
    }
  }
`

const GridInputSwitch = styled(Grid)`
  &.MuiGrid-root {
    label {
      margin-left: 1px;
      margin-right: 16px;
      margin-top: 9px;
    }
  }
`

const TextTitle = styled.h1`
   `

const Text = styled.p`
 padding: 0  !important;`


const RadioGroup = styled.div`
display: flex;
flex-direction: row;
`

const GroupButtons = styled.div`
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-end;
`

const ButtonSubmit = styled(Button)`
  &.MuiButton-root {
    margin: 10px 0 50px 35px;
  }
`

const ButtonCancel = styled(Button)`
  &.MuiButton-root {
    margin: 10px 0 50px 35px;
  }
`

export { Form, GroupButton, Button, GridInputSwitch, TextTitle, Text, RadioGroup, GroupButtons, ButtonSubmit, ButtonCancel }
