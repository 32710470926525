import { useEffect, useState, lazy } from 'react'
import { Info } from '@mui/icons-material'
import {
  getDocumentsStudent,
  getDownloadPDFDecreesStudent
} from 'services/api/student'
import { useSnack } from 'services/hooks'
import {
  getClassesLocalStorage,
  getDataFormKeys,
  getErrorMessage,
  getUserFromLocalStorage
} from 'services/helpers'
import { ERROR } from 'services/constants'
import { TemplateStudentDocuments as Template } from 'components/templates/Student'
import { LoadingPage, Snackbar } from 'components/molecules'
import { TextNotFound } from 'components/atoms'
import { HeaderPage } from 'components/organisms'
import TAG_MANAGER from 'tagManager'
import {
  INITIAL_DOCS_UPLOAD_PERSONAL,
  INITIAL_DOCUMENTS,
  MESSAGE_INFO,
  STATUS_NOT_COURSE,
  ERROR_MESSAGE
} from './constants'

const AccordionDocuments = lazy(() => import('./components/AccordionDocuments'))
const FormRequests = lazy(() => import('./components/FormRequests'))
const UploadPDF = lazy(() => import('./components/UploadPDF'))

const DocumentsRequestsStudent = () => {
  const [documents, seDocuments] = useState(INITIAL_DOCUMENTS)
  const [openUploadPDF, setOpenUploadPDF] = useState(false)
  const [contentUploadDocs, setContentUploadDocs] = useState(
    INITIAL_DOCS_UPLOAD_PERSONAL
  )
  const [openRequest, setOpenRequest] = useState(false)
  const [loadingVisibility, setLoadingVisibility] = useState(true)
  const [notCourse, setNotCourse] = useState(false)

  const { username } = getUserFromLocalStorage()

  const { school_classes: schoolClasses } = getClassesLocalStorage()

  const INITIAL_PARAMS = {
    register: username,
    school_class_id: getDataFormKeys([0, 'id'], schoolClasses)
  }

  const { snack, setSnack, snackOpen, updateSnack } = useSnack()

  const actionDownloadPDF = async (idDecrees) => {
    updateSnack({})
    setLoadingVisibility(true)
    const response = await getDownloadPDFDecreesStudent(idDecrees)
    setLoadingVisibility(false)

    if (response.error) {
      const message = getErrorMessage(response.error, response.status)
      setSnack(message, ERROR)
    }

    window.open(response.data.url)
  }

  const getDocuments = async () => {
    updateSnack({})
    const { data, error, status } = await getDocumentsStudent(INITIAL_PARAMS)
    if (status === STATUS_NOT_COURSE) setNotCourse(true)
    seDocuments(data)
    setLoadingVisibility(false)

    if (error) {
      const message = getErrorMessage(ERROR_MESSAGE, status)
      setSnack(message, ERROR)
    }
  }

  useEffect(() => getDocuments(), [])

  return (
    <Template
      titleHeader={
        <HeaderPage
          classNameCallToAction={
            TAG_MANAGER.btn_student_starts_requesting_Document
          }
          title="Documentos e Solicitações"
          callToAction={() => setOpenRequest(true)}
          labelCallToAction={!notCourse && 'SOLICITAR'}
          divider
        />
      }
      content={
        <>
          <LoadingPage open={loadingVisibility} />

          {notCourse ? (
            <TextNotFound icon={<Info />} text={MESSAGE_INFO} />
          ) : (
            <>
              <AccordionDocuments
                content={documents}
                actionDownloadPDF={actionDownloadPDF}
                actionUploadPDF={setOpenUploadPDF}
                docsUpload={setContentUploadDocs}
                actionRequestDocs={setOpenRequest}
              />

              <UploadPDF
                open={openUploadPDF}
                isLoad={setLoadingVisibility}
                updateList={getDocuments}
                setOpen={setOpenUploadPDF}
                contentDoc={contentUploadDocs}
                setSnack={setSnack}
                updateSnack={updateSnack}
              />

              <FormRequests
                openRequest={openRequest}
                isLoad={setLoadingVisibility}
                setOpenRequest={setOpenRequest}
                onHandleGetDocuments={getDocuments}
                setSnack={setSnack}
                updateSnack={updateSnack}
              />
            </>
          )}
        </>
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}
export default DocumentsRequestsStudent
