import { Grid, Typography } from '@mui/material'
// import LaunchIcon from '@mui/icons-material/Launch'
import { BoxItem } from 'components/atoms'
import * as Styled from './style'

const ThirdStep = ({ enrollmentDetails, formik }) => (
  <>
    <Grid
      alignItems="center"
      direction="column"
      container
      spacing={{ xs: 0, sm: 2 }}
    >
      <Styled.Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5"> Resumo da matrícula </Typography>
      </Styled.Grid>

      <Styled.Grid
        alignItems="start"
        direction="column"
        item
        xs={12}
        sm={12}
        md={12}
      >
        <BoxItem title="Matrícula n°" subTitle={enrollmentDetails.id} />
        <BoxItem title="CPF" subTitle={formik.values.cpf} />
        <BoxItem title="Nome" subTitle={formik.values.nome} />
        <BoxItem title="Email" subTitle={formik.values.email} />

        {/* TO DO: Em breve será habilitado */}
        {/* <Styled.BoxLink>
          <a
            href={enrollmentDetails.contract_path?.url}
            target="_blank"
            rel="noreferrer"
          >
            Visualizar contrato
            <Styled.IconButton disableRipple>
              <LaunchIcon />
            </Styled.IconButton>
          </a>
        </Styled.BoxLink> */}

        <Typography variant="body2">
          Enviamos um e-mail com as informações para acessar a plataforma e
          assinar o contrato.
        </Typography>
      </Styled.Grid>
    </Grid>
  </>
)

export default ThirdStep
