import PropTypes from 'prop-types'
import * as Styled from './style'

const SelectFilter = ({
  loadOptions,
  value,
  isClearable,
  placeholder,
  defaultOptions,
  handleChange,
  classNames
}) => (
  <Styled.SelectCustom
    defaultOptions={defaultOptions}
    cacheOptions
    classNamePrefix="react-select"
    classNames={classNames}
    placeholder={placeholder}
    value={value}
    isClearable={isClearable}
    onChange={handleChange}
    loadOptions={loadOptions}
  />
)

SelectFilter.propTypes = {
  classNames: PropTypes.string,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  loadOptions: PropTypes.func
}

export default SelectFilter
