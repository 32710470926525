const nameAccordion = 'typesRequerements'
const defaultItemsPerPage = 10
const defaultPage = 1
const countDefault = 2

const invoicesType = [
  { value: 'Mensalidade', label: 'Mensalidade' },
  { value: 'Matrícula', label: 'Matrícula' },
  { value: 'Taxa', label: 'Taxa' },
  { value: 'Acordo', label: 'Acordo' }
]

const MESSAGE = {
  NO_SEARCH:
    '<b>Encontre os tipos de requerimento disponíveis aqui</b><br/>Você pode filtrar com outros critérios a sua busca ou buscar pelo título de um requerimento .',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  SUCCESS_INACTIVE:
    'Os tipo(s) de requerimento(s) selecionado(s) foram desativado(s) com sucesso.',
  SUCCESS_DELETE:
    'Os tipo(s) de requerimento(s) selecionado(s) foram deletados(s) com sucesso.',
  SUCCESS_CREATE_COPY:
    'A cópia dos tipo(s) de requerimento(s) selecionado(s) foi criada(s) com sucesso.'
}

const initialState = {
  unity: '',
  level: '',
  course: '',
  inactive: false,
  requiriments: ''
}

export {
  MESSAGE,
  countDefault,
  nameAccordion,
  invoicesType,
  defaultPage,
  defaultItemsPerPage,
  initialState
}
