import * as yup from 'yup'
import { replacementType } from './constants'

const schema = yup.object().shape({
  endDate: yup
    .date()
    .optional()
    .when('replacement', {
      is: (value) => value !== replacementType,
      then: yup.date().required('Campo é obrigatório')
    }),
  startDate: yup
    .date()
    .optional()
    .when('replacement', {
      is: (value) => value !== replacementType,
      then: yup.date().required('Campo é obrigatório')
    }),
  reasonReplacement: yup.string().required('Campo é obrigatório')
})

export { schema }
