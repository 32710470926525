import { ROUTE } from 'services/constants'

const PATH_UNITS_POLE = ROUTE.ADMIN_MANAGEMENT_POLE
const PATH_UNITS = ROUTE.ADMIN_MANAGEMENT_UNITS_POLE
const CREATE = 'create'
const MENSSAGE_RESPONSIBLE = {
  success: 'Responsável criado com sucesso!',
  deleteSuccess: 'Responsável deletado com sucesso!'
}

const MESSAGE_SUCCESS = (isUnit, type) =>
  `${
    type.unit
      ? `Unidade ${type.edit ? 'atualizada' : 'criada'}`
      : `Polo ${type.edit ? 'atualizado' : 'criado'}`
  } com sucesso!`
const PAGE_EDIT = 'edit'
const INCLUDE_POLE = 'create'

const INITIAL_STATE_POLE = {
  includePole: false,
  unit: '',
  pabx: '',
  supervisoryBody: '',
  active: false,
  namePole: '',
  socialReason: '',
  academicName: '',
  cnpj: '',
  cep: '',
  state: '',
  address: '',
  numberAddress: '',
  neighborhood: '',
  complement: '',
  city: '',
  responsible: '',
  responsiblePhone: '',
  email: '',
  first_phone: '',
  second_phone: '',
  resolution: '',
  credential: ''
}

const INITIAL_RESPONSIBLE = {
  signatures: [
    {
      file: null,
      role: '',
      name: '',
      document: '',
      notEditable: false
    }
  ]
}

const breadcrumbsNew = [
  {
    path: ROUTE.ADMIN_MANAGEMENT_UNITS_POLE,
    label: 'Gestão'
  },
  {
    path: ROUTE.ADMIN_MANAGEMENT_UNITS_POLE,
    label: 'Unidades'
  }
]

const breadcrumbsPoleEdit = (type, unit) => [
  ...breadcrumbsNew,
  {
    label: unit
  },
  {
    label: type.edit === 'edit' ? '<b>Editar Polo</b>' : '<b>Novo Polo</b>'
  }
]

const breadcrumbsUnitEdit = (isEdit, isNameUnit) => [
  ...breadcrumbsNew,
  {
    label: isEdit ? `<b>${isNameUnit}</b>` : '<b>Nova Unidade</b>'
  }
]

const breadcrumbsItems = ({ typePage, hasLinkedPoles, isUnit, isNameUnit }) => {
  if (typePage) {
    if (typePage.pole && !hasLinkedPoles)
      return breadcrumbsPoleEdit(typePage, isUnit)

    return breadcrumbsUnitEdit(typePage.edit, isNameUnit)
  }

  return breadcrumbsNew
}

export {
  breadcrumbsItems,
  INITIAL_RESPONSIBLE,
  MENSSAGE_RESPONSIBLE,
  INCLUDE_POLE,
  CREATE,
  MESSAGE_SUCCESS,
  PATH_UNITS_POLE,
  PATH_UNITS,
  INITIAL_STATE_POLE,
  PAGE_EDIT
}
