import styled from 'styled-components'
import { pathCheck } from 'services/helpers/ramda'
import theme from 'theme/designTokens'

const TextLine = styled.div`
  color: ${pathCheck(['palette', 'textColors', 'primary', 'lightGray'], theme)};
  display: inline-block;
  font-size: 1rem;
  font-weight: ${({ type }) => (type === 'bold' ? '600' : 'normal')};
  line-height: 1.3rem;
  margin: 0;
  text-decoration: ${({ type }) =>
    type === 'underline' ? 'underline' : 'none'};
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
`

export { TextLine }
