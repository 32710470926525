import { Box, FormHelperText } from '@mui/material'
import styled from 'styled-components'
import theme from 'theme/designTokens'

const Container = styled.div`
  margin-top: ${(props) =>
    props.activeMarginTop ? props.activeMarginTop : '20px'};
`

const FormText = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    color: ${({ $isColor }) =>
      !$isColor ? theme.palette.textColors.error : theme.palette.gray.main};

    a {
      text-decoration: none;
      color: ${theme.palette.primary.main};
    }
  }
`

const BoxAutoComplete = styled(Box)`
  margin-bottom: ${({ $hasMarginBottom }) => ($hasMarginBottom ? '0' : '16px')};
  width: ${(props) => props.$width};
`

export { Container, BoxAutoComplete, FormText }
