import { useState } from 'react'
import { Box, TableRow, Tooltip } from '@mui/material'
import { formatDateTimePtBr } from 'services/helpers'
import * as Styled from './style'
import ModalEmail from '../../ModalEmail'

const TableFooterEmail = ({ row }) => {
  const [openModalEmail, setOpenModalEmail] = useState(false)
  const showEmail = () => setOpenModalEmail(true)

  return (
    <>
      <TableRow hover tabIndex={-1} key={row.id}>
        <Styled.TableCreatedAt>
          <Box>{formatDateTimePtBr(row?.created_at) || ''}</Box>
        </Styled.TableCreatedAt>

        <Styled.TableCellTitle>
          <Tooltip title="Visualizar e-mail" placement="top">
            <Box onClick={showEmail}>{row?.subject || ''}</Box>
          </Tooltip>
        </Styled.TableCellTitle>

        <Styled.TableCellEmail>
          <Box>{row?.to || ''}</Box>
        </Styled.TableCellEmail>

        <Styled.TableCell>
          <Box>{row?.created_by || ''}</Box>
        </Styled.TableCell>
      </TableRow>

      <ModalEmail
        open={openModalEmail}
        handleClose={() => setOpenModalEmail(false)}
        email={row}
      />
    </>
  )
}

export { TableFooterEmail }
