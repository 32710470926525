const DEFAULT_ITEMS_PER_PAGE = 10
const DEFAULT_PAGE = 1

const defaultValues = {
  level: '',
  issuance: '',
  declaration: ''
}

const makeServiceHookParams = {
  isLevel: true,
}

export {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  defaultValues,
  makeServiceHookParams
}
