import { Link, useLocation } from 'react-router-dom'
import { useMainRoute } from 'services/hooks'
import { routesCheckedPermission } from 'services/helpers'
import * as Styled from './style'
import {
  defaultMessageTitle,
  defaultMessageSubTitle,
  noAccessPermission,
  messageSubTitleCode,
  errorPath
} from './constants'

const ErrorPage = ({ path }) => {
  const { url } = useMainRoute()
  const location = useLocation()
  const roles = routesCheckedPermission()

  return (
    <>
      <Styled.ContainerError>
        <Styled.Typography variant="h2">
          {path === errorPath && roles[location?.pathname] !== undefined
            ? noAccessPermission
            : defaultMessageTitle}
        </Styled.Typography>
        <Styled.Typography variant="p">
          {path === errorPath && roles[location?.pathname] !== undefined
            ? messageSubTitleCode
            : defaultMessageSubTitle}
        </Styled.Typography>
        <Styled.Link>
          Você pode
          <Link to={url}> Voltar para a página principal </Link> ou entrar em
          contado com a sua secretaria.
        </Styled.Link>
      </Styled.ContainerError>
    </>
  )
}

export default ErrorPage
