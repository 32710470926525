import {
  getErrorMessage,
  toastNotificationErrorServer,
  toastNotificationSuccess
} from 'services/helpers'
import { addGrade, editGrade } from 'services/api/professor'
import { alertData, noteSuccessfullyUpdated } from './constants'

const updateGrade = async ({
  event,
  toEditObj,
  activityData,
  student,
  setGradeValue,
  disciplineId,
  setAlertInfo,
  handleScore
}) => {
  const isScores = activityData?.amount >= event.target.value
  setAlertInfo(null)

  if (!isScores) {
    handleScore(activityData?.amount)
    setAlertInfo({
      activityId: activityData.id,
      studentId: student.register
    })
    setGradeValue(0)
    return
  }

  if (!toEditObj && isScores) {
    const activityIds = Array.isArray(activityData.id) ? activityData.id : []

    const foundActivities = student?.activities?.find((item) =>
      activityIds.includes(item.id)
    )

    if (foundActivities) {
      const payload = {
        register: student.register,
        activity_id: foundActivities?.id || null,
        discipline_id: disciplineId || null,
        grade: event.target.value
      }

      const { error, status } = await addGrade(payload)

      if (error) {
        getErrorMessage(error, status)
        return
      }

      toastNotificationSuccess(noteSuccessfullyUpdated, 2000)
      return
    }

    toastNotificationErrorServer(alertData, 2000)
    return
  }

  if (toEditObj && isScores) {
    const { id } = toEditObj
    const payload = {
      register: toEditObj.register,
      activity_id: toEditObj.activity_id,
      discipline_id: disciplineId,
      stage_id: activityData.stage.id,
      grade: event.target.value
    }

    const { error, status } = await editGrade(payload, id)

    if (error) {
      getErrorMessage(error, status)
      return
    }

    toastNotificationSuccess(noteSuccessfullyUpdated, 2000)
  }
}

export { updateGrade }
