import FEEDBACK_SNACK from 'feedBackSnack'
import {
  createProfessor,
  createSkills,
  deleteSkills,
  getClassLinked,
  getListProfessor,
  getReplaceClass,
  mainSkills,
  updateProfessor,
  uploadPhoto,
  validationCPF
} from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import { ROUTE, SUCCESS } from 'services/constants'
import { getCityByState, getStates, getUnitsRegions } from 'services/api/shared'
import { cleanObject } from 'services/helpers/cleanObject'
import moment from 'moment'
import { MESSAGE, initialAcademicEducation, isUF } from './constants'
import { parseList, parseRegional, parseStateByCity } from './helpers'

const listProfessors = async ({
  id,
  setSnack,
  setFormPesonalData,
  setListAcademicEducation,
  setListAccess
}) => {
  setSnack('')

  const { data, error, status } = await getListProfessor(id)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListProfessors
    })
  }

  if (setListAccess) setListAccess(data?.access)

  setListAcademicEducation(data?.skills)

  return setFormPesonalData(parseList(data))
}

const getListClassLinked = async ({
  id,
  setSnack,
  setNotSearch,
  setLoadingOpen,
  setListClassLinked,
  isFinalizedClassLinked
}) => {
  setLoadingOpen(true)

  const params = cleanObject({
    finalized: isFinalizedClassLinked
  })

  const { data, error, status } = await getClassLinked(id, params)
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListClassLinked
    })
  }

  setNotSearch(true)
  return setListClassLinked(data)
}

const getListReplaceClass = async ({
  id,
  setSnack,
  isFinalized,
  setNotSearch,
  setLoadingOpen,
  setListReplacement
}) => {
  setLoadingOpen(true)

  const params = cleanObject({
    finalized: isFinalized
  })

  const { data, error, status } = await getReplaceClass(id, params)
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListClassLinked
    })
  }

  setNotSearch(true)
  return setListReplacement(data)
}

const formatAddress = (objBody)=>{
const objFormated =  {
    ...objBody,
    address: {
        'zip_code': objBody.zip_code,
        'state': objBody.state,
        'city': objBody.city,
        'street': objBody.street,
        'number': objBody.number,
        'complement_address': objBody.complement_address,
        'neighborhood': objBody.neighborhood
    }
}

delete objFormated.zip_code
delete objFormated.state
delete objFormated.city
delete objFormated.street
delete objFormated.number
delete objFormated.complement_address
delete objFormated.neighborhood

return objFormated
}

const createProfessors = async ({
  values,
  setSnack,
  setLoadingOpen,
  navigate,
  setNotPrefessorCreated
}) => {
  setSnack('')
  setLoadingOpen(true)
  const body = formatAddress(cleanObject({ ...values}))
  const { error, status } = await createProfessor(body)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionTocreateProfessors
    })
  }

  setSnack(MESSAGE.CREATE_SUCCESS, SUCCESS)
  setNotPrefessorCreated(false)

  return setTimeout(
    () =>
      navigate(ROUTE.ADMIN_HR_PROFESSOR, {
        state: { savedSearch: true }
      }),
    1500
  )
}

const createProfessorsSkills = async ({
  values,
  setSnack,
  setLoadingOpen,
  setFormAcademicEducation,
  idProfessor,
  setListAcademicEducation,
  setFormPesonalData
}) => {
  setSnack('')
  setLoadingOpen(true)

  const payload = {
    ...values,
    professor_id: idProfessor
  }

  const { error, status } = await createSkills(payload)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateSkillsProfessors
    })
  }

  setSnack(MESSAGE.CREATE_SKILL_SUCCESS, SUCCESS)
  setFormAcademicEducation(initialAcademicEducation)

  return listProfessors({
    id: idProfessor,
    setSnack,
    setFormPesonalData,
    setListAcademicEducation
  })
}

const deleteProfessorsSkills = async ({
  setSnack,
  setLoadingOpen,
  idSkill,
  idProfessor,
  setListAcademicEducation,
  setFormPesonalData,
  setOpenModalConfirm,
  setIdSkill,
  setIsDeleteModal
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = await deleteSkills(idSkill)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDeleteSkillsProfessors
    })
  }

  setSnack(MESSAGE.DELETE_SKILL_SUCCESS, SUCCESS)
  setOpenModalConfirm(false)
  setIdSkill(null)
  setIsDeleteModal(false)

  return listProfessors({
    id: idProfessor,
    setSnack,
    setFormPesonalData,
    setListAcademicEducation
  })
}

const mainProfessorsSkills = async ({
  setSnack,
  setLoadingOpen,
  idSkill,
  idProfessor,
  setListAcademicEducation,
  setFormPesonalData,
  setOpenModalConfirm,
  setIdSkill
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = await mainSkills(idSkill)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToMainSkillsProfessors
    })
  }

  setSnack(MESSAGE.DELETE_SKILL_SUCCESS, SUCCESS)
  setOpenModalConfirm(false)
  setIdSkill(null)

  return listProfessors({
    id: idProfessor,
    setSnack,
    setFormPesonalData,
    setListAcademicEducation
  })
}

const uploadProfessorsPhoto = async ({
  idProfessor,
  file,
  setSnack,
  setLoadingOpen,
  setFormPesonalData,
  setListAcademicEducation,
  setNotPrefessorCreated,
  setListAccess,
  setOpenPhoto
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = await uploadPhoto(idProfessor, { file })

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUploadPhoto
    })
  }

  setSnack(MESSAGE.UPLOAD_PHOTO_SUCCESS, SUCCESS)
  setOpenPhoto(false)
  setNotPrefessorCreated(false)

  return setTimeout(() => {
    listProfessors({
      id: idProfessor,
      setSnack,
      setFormPesonalData,
      setListAcademicEducation,
      setListAccess
    })
  }, 1500)
}

const updateProfessors = async ({
  idProfessor,
  values,
  setSnack,
  navigate,
  setLoadingOpen,
  setFormPesonalData,
  setListAcademicEducation,
  setNotPrefessorCreated
}) => {
  setSnack('')
  setLoadingOpen(true)

  const birthDate = moment(values?.birth_date)
  const isValidBirthDate = birthDate.isValid() ? birthDate : ''

  const body = formatAddress(cleanObject({ ...values, birth_date: isValidBirthDate }))
  const { error, status } = await updateProfessor(idProfessor, body)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateProfessors
    })
  }

  setNotPrefessorCreated(false)

  listProfessors({
    id: idProfessor,
    setSnack,
    setFormPesonalData,
    setListAcademicEducation
  })

  setSnack(MESSAGE.UPDATE_SUCCESS, SUCCESS)

  return setTimeout(
    () =>
      navigate(ROUTE.ADMIN_HR_PROFESSOR, {
        state: { savedSearch: true }
      }),
    1500
  )
}

const validateCPF = async ({ cpf, setSnack, setHasCPFRegistered }) => {
  setSnack('')

  const { data, error, status } = await validationCPF(cpf)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToValidateCPF
    })
  }

  return setHasCPFRegistered(!!Object.keys(data).length)
}

const onUnitsRegions = async ({ setSnack }) => {
  setSnack('')

  const { data, error, status } = await getUnitsRegions('type=unit')

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListRegions
    })
  }

  return data
}

const handleGetCity = async ({ value, setOptionsCity }) => {
  setOptionsCity([])

  const resCity = await getCityByState(value)

  setOptionsCity(resCity)
}



const handleGetCityByState = async ({ value, setOptionsCityState }) => {
  setOptionsCityState([])

  const resCity = await getCityByState(value)
  setOptionsCityState(resCity)
}

const getAllInitialServices = async ({
  id,
  isEdit,
  setSnack,
  setListAccess,
  setLoadingOpen,
  setOptionsState,
  setFormPesonalData,
  setOptionsRegional,
  setListAcademicEducation
}) => {
  setLoadingOpen(true)

  const resUF = await getStates()
  const { data } = await onUnitsRegions({ setSnack })

  setOptionsState(resUF)
  setOptionsRegional(parseRegional(data))

  if (isEdit)
    listProfessors({
      id,
      setSnack,
      setFormPesonalData,
      setListAcademicEducation,
      setListAccess
    })

  return setLoadingOpen(false)
}

const createReplacement = async () => {}




export {
  validateCPF,
  handleGetCity,
  updateProfessors,
  createProfessors,
  createReplacement,
  getListClassLinked,
  getListReplaceClass,
  mainProfessorsSkills,
  handleGetCityByState,
  getAllInitialServices,
  uploadProfessorsPhoto,
  createProfessorsSkills,
  deleteProfessorsSkills
}
