import humps from 'humps'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getStudentInfo } from 'services/api/admin'
import { getStudentHasOverdue } from 'services/api/student'
import { updateErrorMessage } from 'services/helpers'

const loadStudentInfo = async ({ register, onError }) => {
  const { data: response, error, status } = await getStudentInfo(register)

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfo
    })
  }
  return response
}

const loadStudentHasOverdue = async ({ register, onError }) => {
  const { data, error, status } = await getStudentHasOverdue({ register })

  if (error) {
    onError?.({ error, status })

    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfo
    })
  }

  return humps.camelizeKeys(data)
}

export { loadStudentInfo, loadStudentHasOverdue }
