export const CLASSES_TAG_MANAGER = {
  btn_teacher_apply_filter: 'btn_teacher_apply_filter',
  teacher_btn_clean_filter: 'professor_btn_limpa_filtro',
  teacher_tab_access_course_in_progress:
    'professor_tab_acessa_disciplinaEmAndamento',
  teacher_tab_access_subject_closed: 'professor_tab_acessa_disciplinaEncerrada',
  teacher_guide_access_course_next: 'professor_tab_acessa_disciplinaProxima',
  btn_teacher_accesses_joyClass_discipline:
    'professor_btn_acessa_joyClassDisciplina',
  btn_teacher_access_Discipline_detail: 'professor_btn_acessa_detalheDisciplina'
}
