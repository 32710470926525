const days = 45

const defaultValues = {
  unit: '',
  status: '',
  discipline: '',
  course: '',
  startDate: null,
  endDate: null
}

const makeServiceHookParams = {
  unitsValue: true,
  isUnits: true,
  isLevel: true
}

export { days, defaultValues, makeServiceHookParams }
