import {
  getListCoursesClassesByStudent as getListCoursesClassesByStudentService,
  excludeClassLinkOfDiscilple as excludeClassLinkOfDiscilpleService
} from 'services/api/admin'
import { messageErroOrSuccess, serialize } from 'services/helpers'

const getListCoursesClassesByStudent = async ({
  setSnack,
  register,
  setList,
  setUpdateState
}) => {
  const { error, status, data } = await getListCoursesClassesByStudentService(
    register
  )

  await messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  const response = (data ?? [])?.map((item) => serialize(item))

  setList(response)
  setUpdateState(false)
}

const excludeClassLinkOfDiscilple = async ({
  setSnack,
  linkedId,
  callbackSuccess
}) => {
  const { error, status } = await excludeClassLinkOfDiscilpleService(linkedId)

  await messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Vínculo excluído com sucesso.'
  })

  if (!error) {
    setTimeout(callbackSuccess, 1000)
  }
}

export { getListCoursesClassesByStudent, excludeClassLinkOfDiscilple }
