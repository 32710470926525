import { lazy } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSnack } from 'services/hooks'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { breadcrumbsItems } from './constants'

const Content = lazy(() => import('./components/Content'))

const EnrollmentPayment = () => {
  const [searchParams] = useSearchParams()
  const { registerId } = Object.fromEntries([...searchParams])
  const snackProps = useSnack()

  return (
    <TemplateDefaultHeaderByContent
      labelButtonHeader=""
      snackProps={snackProps}
      breadcrumbsItems={breadcrumbsItems}
      content={<Content registerId={registerId} />}
    />
  )
}
export default EnrollmentPayment
