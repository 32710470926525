import { DISCIPLINE_TAG_MANAGER } from './discipline'
import { DOCUMENTS_TAG_MANAGER } from './documents'
import { FINANCIAL_TAG_MANAGER } from './financial'
import { MYSCORES_TAG_MANAGER } from './MyScores'

export const ALUNO_TAG_MANAGER = {
  ...DISCIPLINE_TAG_MANAGER,
  ...FINANCIAL_TAG_MANAGER,
  ...MYSCORES_TAG_MANAGER,
  ...DOCUMENTS_TAG_MANAGER
}
