import { SCHOOL_CLASS, NAME, REGISTRATION, EMAIL } from './constants'

const initialValue = {
  searchType: REGISTRATION,
  searchBy: '',
  unit: '',
  beginAt: '',
  endAt: ''
}

const simpleSearchBy = [
  { value: REGISTRATION, label: 'Matrícula' },
  { value: NAME, label: 'Nome' },
  { value: EMAIL, label: 'E-mail' },
  { value: SCHOOL_CLASS, label: 'Turma' }
]

const typeSearch = {
  [NAME]: {
    typeMask: 'NAME',
    type: 'text',
    label: 'Nome do aluno ou parte dele'
  },
  [REGISTRATION]: {
    typeMask: 'NUMBER',
    type: 'number',
    label: 'Número de matrícula'
  },
  [SCHOOL_CLASS]: {
    typeMask: 'NAME',
    type: 'text',
    label: 'Nome da turma'
  },
  [EMAIL]: {
    typeMask: 'NAME',
    type: 'text',
    label: 'E-mail ou parte dele'
  },
}

const headCells = [
  {
    id: REGISTRATION,
    numeric: false,
    disabled: false,
    label: 'Matrícula'
  },
  {
    id: NAME,
    numeric: false,
    disabled: false,
    label: 'Aluno'
  },
  {
    id: SCHOOL_CLASS,
    numeric: false,
    disabled: false,
    label: 'Turma'
  },
  {
    id: 'unit',
    numeric: false,
    disabled: false,
    label: 'Unidade'
  },
  {
    id: 'pending',
    numeric: false,
    disabled: false,
    label: 'Pendentes'
  },
  {
    id: 'conferred',
    numeric: false,
    disabled: false,
    label: 'Conferidos'
  },
  {
    id: 'rejected',
    numeric: true,
    disabled: false,
    label: 'Indeferidos'
  }
]

export { initialValue, simpleSearchBy, typeSearch, headCells }
