import { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { InputAdornment, TextField } from '@mui/material'
import TAG_MANAGER from 'tagManager'

import { ModalConfirm, Select } from 'components/molecules'
import { BoxItem, Button } from 'components/atoms'
import { registrationConfirm } from 'services/api/admin/comercial/courses'
import { toastNotificationSuccess, updateErrorMessage } from 'services/helpers'
import { cleanObjectWithZero } from 'services/helpers/cleanObject'
import FEEDBACK_SNACK from 'feedBackSnack'
import { modalConfirmManualEnrollment } from '../../constants'
import { formSchema } from '../../schema'
import * as Styled from './style'
import { handleFormated } from '../../helpers'
import { getEnrollmentOriginLabel } from '../../../RegistrationNewEnrollment/helpers'
import { remapInstallmentsToSelect } from './helpers'

const ModalManualEnrollment = ({
  open,
  details,
  isOpen,
  setIsOpen,
  handleClose,
  onActionSelected,
  onResetAction
}) => {
  const [list, setList] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  const [amoutFormated, setAmoutFormated] = useState('')
  const enrollmentOrigin = getEnrollmentOriginLabel(details?.enrollment_origin)

  const confirmEnrollment = async (values) => {
    setIsLoad(true)

    const params = cleanObjectWithZero({
      min_installment: values.payment
    })

    const { error, status } = await registrationConfirm({
      params,
      id: details.register
    })

    setIsLoad(false)

    if (error) {
      return updateErrorMessage({
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToForm
      })
    }

    toastNotificationSuccess('Pré-Matrícula realizada.', 1500)

    onActionSelected(modalConfirmManualEnrollment, {})
    return setIsOpen(true)
  }

  useEffect(() => {
    handleFormated({ setAmoutFormated, details })
    remapInstallmentsToSelect({
      setList,
      details: details?.installments ?? []
    })
  }, [details])

  return (
    <>
      <Styled.Modal open={open} onClose={handleClose}>
        <Formik
          enableReinitialize
          onSubmit={confirmEnrollment}
          validationSchema={formSchema}
          initialValues={{
            payment: ''
          }}
        >
          {({ setFieldValue, values, errors, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Styled.Container>
                <Styled.Header>
                  <Styled.IconText>
                    <Styled.Title variant="h5">Matrícula manual</Styled.Title>
                  </Styled.IconText>
                  <Styled.CardInfo>
                    <Styled.BoxInfo>
                      <BoxItem title="Nome" subTitle={details?.name} />
                      <BoxItem title="Curso" subTitle={details?.course} />
                      <BoxItem title="Grupo" subTitle={details?.group} />
                      <BoxItem
                        title="1ª parcela em"
                        subTitle={details?.due_at}
                      />
                      <BoxItem
                        title="Origem da matrícula"
                        subTitle={enrollmentOrigin ?? '--'}
                      />
                    </Styled.BoxInfo>
                    <Styled.BoxInfo>
                      <BoxItem title="Matrícula" subTitle={details?.register} />
                      <BoxItem title="CPF" subTitle={details?.cpf} />
                      <BoxItem
                        title="Cupom Desconto"
                        subTitle={details?.coupon}
                      />
                      <BoxItem
                        title="Valor total do curso"
                        subTitle={amoutFormated}
                      />
                    </Styled.BoxInfo>
                  </Styled.CardInfo>

                  <Styled.Box>
                    <Select
                      fullWidth
                      id="payment"
                      name="payment"
                      onBlur={handleBlur}
                      error={errors.payment}
                      value={values?.payment}
                      label="Plano de pagamento"
                      optionsList={list}
                      onChange={(e) => {
                        setFieldValue('payment', e.target.value)
                      }}
                      helperText={errors.payment}
                    />
                  </Styled.Box>
                </Styled.Header>

                <Styled.ContainerButtons>
                  <Button
                    size="large"
                    value="Cancelar"
                    disabled={isLoad}
                    onClick={handleClose}
                  />
                  <Button
                    size="large"
                    type="submit"
                    loading={isLoad}
                    value="matricular"
                    disabled={!values?.payment || isLoad}
                  />
                </Styled.ContainerButtons>
              </Styled.Container>
            </Form>
          )}
        </Formik>
      </Styled.Modal>

      <ModalConfirm
        icon={<Styled.IconWarningAmber />}
        open={isOpen}
        handleClose={onResetAction}
        title="Taxa de matrícula"
        message="Abriremos um requerimento para o financeiro realizar a baixa manual da taxa de matrícula desta inscrição."
        textButtonConfirm="OK"
        onClickConfirm={onResetAction}
        colorButtonConfirm="primary"
        classNameBtnConfirm={
          TAG_MANAGER.btn_commercial_confirms_registration_Manual
        }
      />
    </>
  )
}
export default ModalManualEnrollment
