import getEnvAccess from './getEnvAccess'

export default function getPDFPermissions(permissions) {
  const hasPermission = getEnvAccess()

  const PDF_PERMISSIONS = {
    PDF_POC: Boolean(hasPermission)
  }

  return PDF_PERMISSIONS
}
