export const sortAsc = 'asc'
export const defaultPage = 1
export const sortDesc = 'desc'
export const defaultItemsPerPage = 10

const headCellsPending = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nome'
  },
  {
    id: 'begin',
    numeric: false,
    disablePadding: false,
    label: 'Início da Vigência'
  },
  {
    id: 'end',
    numeric: false,
    disablePadding: false,
    label: 'Final da Vigência'
  },
  {
    id: 'installments',
    numeric: false,
    disablePadding: false,
    label: 'Parcelas Incrementais'
  },
  {
    id: 'discount',
    numeric: false,
    disablePadding: false,
    label: 'Desconto de Antecipação'
  }
]

export { headCellsPending }
