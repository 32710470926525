import { lazy, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { ROUTE } from 'services/constants'
import {
  getPaymentPlanById,
  savePaymentPlanAmounts,
  updatePaymentPlan
} from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import { useUserStore } from 'store/GlobalContext'
import { breadcrumbsItems } from './helpers'
import {
  codeAccountEnferminas,
  codeAccountEssa,
  companyIdEnferminas
} from './constants'
import {
  getParamsCreateByUpdate,
  mergeInitialValues,
  updateSnask
} from './services'
import { initialState } from './config'
import { paymentPlanFormSchema } from './schema'
import { TYPES_OF_PAYMENT } from '../constants'

const FormCreateByUpdate = lazy(() => import('./FormCreateByUpdate'))

const CreateByUpdatePaymentPlan = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { period, paymentPlanId, page } = params
  const [openModal, setOpenModal] = useState(false)
  const [paymentEdit, setPaymentEdit] = useState(initialState)
  const { setLoading } = useLoading()
  const { company_id: companyId } = useUserStore()

  const isEditMode = page === 'edit'

  const onHandleConfirm = () => {
    navigate(ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN, {
      state: { savedSearch: true }
    })
  }

  const updatePayment = async (values) => {
    const { error, status } = await updatePaymentPlan(
      getParamsCreateByUpdate(values),
      paymentPlanId
    )

    setLoading(false)
    return updateSnask({
      error,
      status,
      updatePayment: true,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdatePaymentPlan,
      navigate
    })
  }

  const onSubmit = async (values) => {
    setLoading(true)
    if (isEditMode && paymentPlanId) {
      return updatePayment(values)
    }
    const { error, status } = await savePaymentPlanAmounts(
      getParamsCreateByUpdate(values)
    )
    setLoading(false)

    return updateSnask({
      error,
      status,
      savedPayment: true,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreatePaymentPlan,
      navigate
    })
  }

  const handlePaymentPlanById = async () => {
    setLoading(true)
    const {
      data: response,
      error,
      status
    } = await getPaymentPlanById(Number(paymentPlanId))

    setLoading(false)

    if (error) {
      return updateErrorMessage({
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToViewPaymentPlan
      })
    }

    return setPaymentEdit(response)
  }

  const accountBilletDefault =
    companyId === companyIdEnferminas ? codeAccountEnferminas : codeAccountEssa

  const state = {
    ...initialState,
    account: accountBilletDefault
  }

  const initialValues = isEditMode ? mergeInitialValues(paymentEdit) : state

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
    validationSchema: paymentPlanFormSchema
  })

  useEffect(() => {
    if (isEditMode) handlePaymentPlanById()
  }, [isEditMode])

  useEffect(() => {
    if (values.typeOfPayment === TYPES_OF_PAYMENT.DEFAULT.value) {
      setFieldValue('account', accountBilletDefault)
    }
  }, [values.typeOfPayment])

  return (
    <TemplateDefaultHeaderByContent
      classNameHeader="goBack"
      breadcrumbsNotLink={[...breadcrumbsItems(paymentPlanId, period)]}
      onClickButton={() => setOpenModal(true)}
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      content={
        <FormCreateByUpdate
          setOpenModal={setOpenModal}
          handleSubmit={handleSubmit}
          values={values}
          setFieldValue={setFieldValue}
          isEditMode={isEditMode}
          errors={errors}
        />
      }
    />
  )
}

export default CreateByUpdatePaymentPlan
