import theme from 'theme/designTokens'
import { utilsPDF } from '../utils'

const { noBorderOnTop } = utilsPDF

const buildObservations = (contentObseervation, title) => [
  [
    {
      text: title ?? 'Observações',
      border: noBorderOnTop,
      bold: true,
      fillColor: theme.palette.background.tableHeaderPDF,
      colSpan: 3
    },
    {},
    {}
  ],
  [
    {
      ...contentObseervation,
      colSpan: 3,
      lineHeight: 1.3
    },
    {},
    {}
  ]
]

export default buildObservations
