const initialValues = {
  name: '',
  code: '',
  module: '',
  type_score: '',
  workload_hours: '',
  workload_minutes: ''
}

export { initialValues }
