import * as yup from 'yup'

const validationSchema = yup.object().shape({
  start_date: yup
    .string()
    .nullable()
    .required('A data inicial é obrigatório')
    .typeError('Data Inválida'),
  end_date: yup
    .string()
    .nullable()
    .required('A data final é obrigatório')
    .typeError('Data Inválida'),
  search_type: yup
    .string()
    .required('O tipo de consulta é obrigatório')
    .typeError('Data Inválida')
})

export { validationSchema }
