import { FieldArray } from 'formik'
import { Grid, IconButton, TextField, Tooltip } from '@mui/material'

import { DatePicker } from 'components/atoms'
import { Select } from 'components/molecules'
import { typeMask } from 'services/helpers'
import { MAX_LENGTH_PHONE } from 'services/constants'

import { listCivilStatus, MAX_ADD_CONTACT } from '../../constants'
import { hasError } from '../../helpers'

import * as Styled from './style'

const StudentForm = ({
  values,
  handleChange,
  touched,
  fieldNameForm,
  listCitys,
  listStates,
  handleChangeState,
  setFieldValue,
  handleChangeCep,
  setSearchByCep
}) => (
  <Grid container xs={12} spacing={{ xs: 0, sm: 2 }}>
    <Grid item xs={6}>
      <TextField
        label="Nome Completo*"
        name={`${fieldNameForm}.name`}
        value={values?.[fieldNameForm]?.name}
        onChange={handleChange}
        fullWidth
        disabled
        helperText={hasError(touched, values, 'name') && 'Campo obrigatório'}
        error={hasError(touched, values, 'name')}
      />
    </Grid>
    <Grid item xs={3}>
      <Select
        label="Estado Civil"
        name={`${fieldNameForm}.civilStatus`}
        value={values?.[fieldNameForm]?.civilStatus}
        onChange={handleChange}
        optionsList={listCivilStatus}
        fullWidth
        disabled
        helperText={
          hasError(touched, values, 'civilStatus') && 'Campo obrigatório'
        }
        error={hasError(touched, values, 'civilStatus')}
      />
    </Grid>

    <Grid item xs={3}>
      <TextField
        label="CPF*"
        name={`${fieldNameForm}.cpf`}
        value={typeMask('CPF', values?.[fieldNameForm]?.cpf)}
        onChange={handleChange}
        fullWidth
        disabled
        helperText={hasError(touched, values, 'cpf') && 'Campo obrigatório'}
        error={hasError(touched, values, 'cpf')}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        label="RG"
        name={`${fieldNameForm}.rg`}
        value={values?.[fieldNameForm]?.rg}
        onChange={handleChange}
        fullWidth
        disabled
        helperText={hasError(touched, values, 'rg') && 'Campo obrigatório'}
        error={hasError(touched, values, 'rg')}
      />
    </Grid>
    <Grid item xs={2}>
      <DatePicker
        label="Nascimento"
        name={`${fieldNameForm}.birth`}
        value={values?.[fieldNameForm]?.birth}
        onChange={(date) => setFieldValue(`${fieldNameForm}.birth`, date)}
        fullWidth
        disabled
        helperText={hasError(touched, values, 'birth') && 'Campo obrigatório'}
        error={hasError(touched, values, 'birth')}
      />
    </Grid>

    <Grid item xs={1}>
      <TextField
        label="UF"
        name={`${fieldNameForm}.rgState`}
        value={values?.[fieldNameForm]?.rgState}
        onChange={handleChange}
        fullWidth
        disabled
        helperText={hasError(touched, values, 'rgState') && 'Campo obrigatório'}
        error={hasError(touched, values, 'rgState')}
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Nacionalidade*"
        name={`${fieldNameForm}.nationality`}
        value={values?.[fieldNameForm]?.nationality}
        onChange={handleChange}
        fullWidth
        disabled
        helperText={
          hasError(touched, values, 'nationality') && 'Campo obrigatório'
        }
        error={hasError(touched, values, 'nationality')}
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="E-mail*"
        name={`${fieldNameForm}.email`}
        value={values?.[fieldNameForm]?.email}
        onChange={handleChange}
        fullWidth
        disabled
        helperText={hasError(touched, values, 'email') && 'Campo obrigatório'}
        error={hasError(touched, values, 'email')}
      />
    </Grid>

    <Grid item xs={3}>
      <TextField
        label="CEP*"
        name={`${fieldNameForm}.zipCode`}
        value={typeMask('CEP', values?.[fieldNameForm]?.zipCode)}
        onChange={(event) =>
          handleChangeCep({
            event,
            setSearchByCep,
            setFieldValue,
            fieldNameZipCode: `${fieldNameForm}.zipCode`
          })
        }
        fullWidth
        disabled
        helperText={hasError(touched, values, 'zipCode') && 'Campo obrigatório'}
        error={hasError(touched, values, 'zipCode')}
      />
    </Grid>
    <Grid item xs={3}>
      <Select
        label="Estado*"
        name={`${fieldNameForm}.state`}
        value={values?.[fieldNameForm]?.state}
        onChange={(event) =>
          handleChangeState({
            event,
            setFieldValue,
            fieldNameState: `${fieldNameForm}.state`,
            fieldNameCity: `${fieldNameForm}.city`
          })
        }
        optionsList={listStates}
        fullWidth
        disabled
        helperText={hasError(touched, values, 'state') && 'Campo obrigatório'}
        error={hasError(touched, values, 'state')}
      />
    </Grid>

    <Grid item xs={6}>
      <Select
        label="Cidade*"
        name={`${fieldNameForm}.city`}
        value={values?.[fieldNameForm]?.city}
        onChange={handleChange}
        optionsList={listCitys}
        fullWidth
        disabled
        helperText={hasError(touched, values, 'city') && 'Campo obrigatório'}
        error={hasError(touched, values, 'city')}
        hasDontSensitive
      />
    </Grid>

    <Grid item xs={4.5}>
      <TextField
        label="Endereço*"
        name={`${fieldNameForm}.address`}
        value={values?.[fieldNameForm]?.address}
        onChange={handleChange}
        fullWidth
        disabled
        helperText={hasError(touched, values, 'address') && 'Campo obrigatório'}
        error={hasError(touched, values, 'address')}
      />
    </Grid>
    <Grid item xs={1.5}>
      <TextField
        label="Número*"
        name={`${fieldNameForm}.addressNumber`}
        value={values?.[fieldNameForm]?.addressNumber}
        onChange={handleChange}
        fullWidth
        disabled
        helperText={
          hasError(touched, values, 'addressNumber') && 'Campo obrigatório'
        }
        error={hasError(touched, values, 'addressNumber')}
      />
    </Grid>

    <Grid
      item
      xs={6}
      spacing={2}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        gap: '16px'
      }}
    >
      <TextField
        label="Complemento"
        name={`${fieldNameForm}.complement`}
        value={values?.[fieldNameForm]?.complement}
        onChange={handleChange}
        fullWidth
        disabled
        helperText={
          hasError(touched, values, 'complement') && 'Campo obrigatório'
        }
        error={hasError(touched, values, 'complement')}
      />

      <TextField
        label="Bairro"
        name={`${fieldNameForm}.district`}
        value={values?.[fieldNameForm]?.district}
        onChange={handleChange}
        fullWidth
        disabled
        helperText={
          hasError(touched, values, 'district') && 'Campo obrigatório'
        }
        error={hasError(touched, values, 'district')}
      />
    </Grid>
    <Grid item xs={6}>
      <FieldArray name={`${fieldNameForm}.phone`}>
        {({ push, remove }) => (
          <Styled.Container>
            {values?.[fieldNameForm]?.phone?.map((valuephone, index) => {
              const cell = `.phone[${index}].cell`

              return (
                <Styled.ContainerField key={valuephone.id}>
                  <TextField
                    fullWidth
                    label="Telefone / WhatsApp *"
                    name={cell}
                    onChange={handleChange}
                    value={typeMask('PHONE', valuephone?.cell)}
                    inputProps={{
                      maxLength: MAX_LENGTH_PHONE
                    }}
                    isMargin="0"
                    disabled
                  />

                  <Tooltip title="Excluir contato" placement="top">
                    <Styled.BoxTooltip>
                      <IconButton
                        disableRipple
                        onClick={() => false && remove(index)}
                      >
                        <Styled.DeleteIcon />
                      </IconButton>
                    </Styled.BoxTooltip>
                  </Tooltip>
                </Styled.ContainerField>
              )
            })}

            {values?.[fieldNameForm]?.phone?.length < MAX_ADD_CONTACT && (
              <Tooltip title="Adicionar contato" placement="top">
                <Styled.BoxTooltip>
                  <IconButton
                    disableRipple
                    onClick={() =>
                      push({
                        id: Math.random(),
                        cell: ''
                      })
                    }
                  >
                    <Styled.AddIcon />
                  </IconButton>
                </Styled.BoxTooltip>
              </Tooltip>
            )}
          </Styled.Container>
        )}
      </FieldArray>
    </Grid>
  </Grid>
)

export default StudentForm
