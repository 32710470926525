import { lazy, useEffect, useState } from 'react'
import { Form as FormikForm, Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { ListAlt, Search } from '@mui/icons-material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { ERROR, SUCCESS } from 'services/constants'
import {
  IsEmpty,
  formatDateEnv,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import {
  getAcademicPerformances,
  deleteAcademicPerformance
} from 'services/api/admin'
import {
  defaultPage,
  nameAccordion,
  MESSAGE,
  defaultItemsPerPage,
  countDefault,
  ERROR_DELETE_ACADEMIC_PERFORMANCE,
  SUCCESS_DELETE_ACADEMIC_PERFORMANCE
} from './constants'
import schema from './schema'
import { initialValue } from './config'
import { useAcademicPerformances } from './Context/FormContext'
import ModalConfirmDelete from './components/ModalConfirmDelete'

const Form = lazy(() => import('./components/Form'))
const Table = lazy(() => import('./components/Table'))

const ListAcademicPerformances = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const snackProps = useSnack()
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [notSearch, setNotSearch] = useState(true)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [academicPerformancesList, setAcademicPerformancesList] = useState([])
  const [academicPerformanceDelete, setAcademicPerformanceDelete] =
    useState(null)

  const isSearchParam = location?.state?.savedSearch || false

  const { formValues, setFormValues, resetFormValues } =
    useAcademicPerformances()

  const { page, totalPage, items, setPage, handleSetPage } = usePaginationBasic(
    academicPerformancesList,
    filter,
    count
  )
  const { setSnack } = snackProps

  const requestFilters = (values) => ({
    ...(values.searchType &&
      values.searchBy && {
        filter_type: values.searchType,
        filter_value: values.searchBy
      }),
    ...(values.unit && { unit: values.unit }),
    is_finished: values.done,
    start_date: formatDateEnv(values.beginAt),
    end_date: formatDateEnv(values.endAt)
  })

  const onSubmit = async (values) => {
    setSnack('')
    setLoading(true)
    const paginationData = {
      limit: filter,
      page: page || defaultPage
    }

    const params = {
      ...paginationData,
      ...requestFilters(values)
    }
    const {
      data: response,
      error,
      status
    } = await getAcademicPerformances(params)

    setLoading(false)
    if (error) {
      setNotSearch(true)
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToListAcademicPerformance
      })
      return
    }

    setCount(response?.count)
    setAcademicPerformancesList(response?.data)
    setNotSearch(false)
    setFormValues(values)
    setExpanded('')
  }

  const cancelAutomaticSearch = () => {
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  const onClear = () => {
    setFormValues(null)
    resetFormValues()
    setAcademicPerformancesList([])
    setNotSearch(true)
    cancelAutomaticSearch()
  }

  useEffect(
    () => formValues?.beginAt && formValues?.endAt && onSubmit(formValues),
    [page, filter]
  )

  useEffect(() => {
    if (isSearchParam && formValues?.beginAt && formValues?.endAt) {
      return onSubmit(formValues)
    }

    resetFormValues()
    cancelAutomaticSearch()
    return setAcademicPerformancesList([])
  }, [isSearchParam])

  const initialState = mergeInitialValues(
    initialValue,
    isSearchParam && formValues
  )

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH_PERFORMED
    : MESSAGE.NOT_FOUND_SEARCH

  const onConfirmDelete = async (academicPerformance) => {
    setSnack('')
    setLoading(true)
    const { error } = await deleteAcademicPerformance({
      register: academicPerformance.register,
      requirementNumber: academicPerformance.requirement_number
    })
    setLoading(false)
    const snackParams = [
      ...(error
        ? [ERROR_DELETE_ACADEMIC_PERFORMANCE, ERROR]
        : [SUCCESS_DELETE_ACADEMIC_PERFORMANCE, SUCCESS])
    ]
    setSnack(...snackParams)
    if (!error) setTimeout(() => onSubmit(formValues), 1500)
  }

  return (
    <>
      <TemplateDefaultFormAndResult
        titleHeader="Secretaria - Aproveitamento de Estudos"
        snackProps={snackProps}
        nameAccordion={nameAccordion}
        loadingOpen={loading}
        listItems={academicPerformancesList}
        iconInformative={notSearch ? <ListAlt /> : <Search />}
        messageInformative={messageInformative}
        expanded={expanded}
        setExpanded={setExpanded}
        accordion={
          <Formik
            initialValues={initialState}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={schema}
          >
            {(props) => (
              <FormikForm
                onSubmit={props.handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Form
                  setLoading={setLoading}
                  setCount={setCount}
                  setFilter={setFilter}
                  setPage={setPage}
                  onClear={onClear}
                  setSnack={setSnack}
                  {...props}
                />
              </FormikForm>
            )}
          </Formik>
        }
      >
        {academicPerformancesList && !IsEmpty(academicPerformancesList) && (
          <>
            <Table
              rows={items}
              filter={filter}
              setFilter={setFilter}
              setPage={setPage}
              page={page}
              totalPage={totalPage}
              count={count}
              handleSetPage={handleSetPage}
              setAcademicPerformanceDelete={setAcademicPerformanceDelete}
            />
            <Typography variant="caption" sx={{ pt: '20px', display: 'block' }}>
              Para excluir uma solicitação de aproveitamento de forma
              individual, clique no nome do aluno para “Editar solicitação” e
              altere a situação da disciplina que deseja excluir para a opção
              “Incorreto”.
            </Typography>
          </>
        )}
      </TemplateDefaultFormAndResult>

      {academicPerformanceDelete && (
        <ModalConfirmDelete
          academicPerformance={academicPerformanceDelete}
          setAcademicPerformance={setAcademicPerformanceDelete}
          onConfirm={onConfirmDelete}
        />
      )}
    </>
  )
}

export default ListAcademicPerformances
