const initialValues = {
  requirement: '',
  unity: '',
  term: '',
  amount: '',
  account: '',
  costCenter: '',
  type: '',
  subcategory: '',
  nature: '',
  level: '',
  course: '',
  sector: '',
  observation: '',
  requestText: '',
  portal: false
}

const breadCrumbsItems = (pageEdit) => [
  {
    path: '',
    label: 'Gestão'
  },
  {
    path: '',
    label: 'Tipos de Requerimento'
  },
  {
    path: '',
    label: `<b>${pageEdit || 'Novo Tipo'}</b>`
  }
]

export { initialValues, breadCrumbsItems }
