const initialValues = {
  typeRequirement: '',
  initialSector: '',
  responsible: '',
  class: '',
  course: '',
  description: ''
}

export { initialValues }
