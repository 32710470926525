import FEEDBACK_SNACK from 'feedBackSnack'
import {
  createCheckedHRSchoolClass,
  getDetailsHRSchoolClass,
  getOptionsProfessors,
  revertHRSchoolClass
} from 'services/api/admin'
import {
  isEmpty,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import { ROUTE } from 'services/constants'
import { MESSAGE } from './constants'
import { parserClasses, parserNewLesson, parserProfessors } from './helpers'

const getDetailsSchoolClasses = async ({
  params,
  setDetailsSchoolClasses,
  setNotFound
}) => {
  const { data, error, status } = await getDetailsHRSchoolClass({
    payroll_id: params.payrollId,
    school_class_id: params.schoolClassId,
    status: params.status
  })

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListDetailsSchoolClassManagement
    })
  }

  if (isEmpty(data)) {
    return setNotFound(true)
  }

  setNotFound(false)
  return setDetailsSchoolClasses(data)
}

const createCheckSchoolClasses = async ({
  checkedIdList,
  classes,
  params,
  detailsSchoolClasses
}) => {
  const payload = {
    payroll_id: params.payrollId,
    school_classes_id: params.schoolClassId,
    lessons: [
      ...parserNewLesson({ checkedIdList, detailsSchoolClasses }),
      ...parserClasses({ classes, detailsSchoolClasses })
    ]
  }

  const { error, status } = await createCheckedHRSchoolClass(payload)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListChecked
    })
  }

  return toastNotificationSuccess(MESSAGE.SUCCESS_CHECKED, 1500)
}

const resetHoursClass = async ({ payrollId, classId }) => {
  const { error, status } = await revertHRSchoolClass({
    payrollId,
    schoolClassesId: String(classId)
  })

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListReverted
    })
  }

  return toastNotificationSuccess('Dados atualizados com sucesso', 2000)
}

const getListProfessors = async ({ params, setOptionsProfessors }) => {
  const { data, error, status } = await getOptionsProfessors({
    school_class_id: params.schoolClassId
  })

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListProfessors
    })
  }

  return setOptionsProfessors(parserProfessors(data))
}

export {
  getDetailsSchoolClasses,
  createCheckSchoolClasses,
  resetHoursClass,
  getListProfessors
}
