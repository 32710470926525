import { Typography } from '@mui/material'
import { RemoveCircleOutline, ControlPoint } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'

const TableToolbar = ({ setIsOpen, isActive, amount }) => (
  <Styled.ToolbarArea>
    <Styled.Button
      variant="outlined"
      onClick={() => setIsOpen(true)}
      startIcon={
        isActive ? (
          <RemoveCircleOutline sx={{ color: '#ED6C02' }} />
        ) : (
          <ControlPoint sx={{ color: '#ED6C02' }} />
        )
      }
      className={
        isActive
          ? TAG_MANAGER.sistema_btn_inativa_Contratos
          : TAG_MANAGER.sistema_btn_ativa_contratos
      }
    >
      {isActive ? 'inativar' : 'ativar'}
    </Styled.Button>
    <Typography variant="body2">
      {amount > 1
        ? `${amount} itens selecionados`
        : `${amount} item selecionado`}
    </Typography>
  </Styled.ToolbarArea>
)
export default TableToolbar
