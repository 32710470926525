import { ROUTE } from 'services/constants'

const basePath = ROUTE.ADMIN_NEW_REGISTRATION
const cepValid = 9

const breadcrumbsItems = [
  {
    path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION,
    label: 'Comercial'
  },
  {
    path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION_CREATE,
    label: 'Pré matrícula'
  },
  {
    label: '<b>Inscrição</b>',
    path: ''
  }
]

const listOptions = [
  { value: 1, label: 'Item 1' },
  { value: 2, label: 'Item 2' }
]

const steps = ['Dados do Aluno', 'Responsável Financeiro', 'Pagamento']

const MESSAGE = {
  PENDING_TRADE:
    'Verifique o detalhamento financeiro deste cadastro, pois existem negociações pendentes.',
  ACTIVE_CPF: 'Já existe um cadastro com este CPF',
  SUCCESS: 'Pré Matricula criada com sucesso!',
  ERROR_VALIDATE_CPF: 'CPF inválido'
}

const listMetUs = [
  { value: 'friendsIndication', label: 'Indicação de amigos' },
  { value: 'TVAdvertising', label: 'Propaganda na TV' },
  { value: 'publicTransport', label: 'Propaganda em transporte público' },
  { value: 'internet', label: 'Na internet' }
]

const enrollmentOriginList = [
  { value: 'site', label: 'Site' },
  { value: 'enrollment_center', label: 'Central de matrícula' },
  { value: 'unit', label: 'Unidade' },
  { value: 'secretary_movement', label: 'Movimentação Secretaria' }
]

export const civilStatusList = [
  { value: 'Solteiro(a)', label: 'Solteiro(a)' },
  { value: 'Casado(a)', label: 'Casado(a)' },
  { value: 'Viúvo(a)', label: 'Viúvo(a)' },
  { value: 'Divorciado(a)', label: 'Divorciado(a)' }
]

const allowedCountry = [
  'Brasileiro',
  'brasileiro',
  'Brasileira',
  'Brasil',
  'Brasileiro(a)',
  'brasileiro (a)',
  'brasileira',
  'brasil',
  'brasileiro(a)',
  'brasileiro (a)',
  'br'
]

const activeStepZero = 0
const activeStepOne = 1
const activeStepPayment = 2
const STUDENT = 'student'
const FINANCIAL_RESPONSIBLE = 'financialResponsible'
const MAX_LENGTH_CPF = 14
const MAX_LENGTH_PHONE_MOBILE = 15
const MAX_LENGTH_PHONE = 14
const MAX_LENGTH_RG = 12
const MAX_LENGTH_CEP = 9
const MIN_AGE = 18

export {
  basePath,
  listOptions,
  activeStepOne,
  MAX_LENGTH_RG,
  activeStepZero,
  breadcrumbsItems,
  activeStepPayment,
  steps,
  STUDENT,
  FINANCIAL_RESPONSIBLE,
  MESSAGE,
  listMetUs,
  MAX_LENGTH_CPF,
  MAX_LENGTH_PHONE,
  MAX_LENGTH_PHONE_MOBILE,
  MAX_LENGTH_CEP,
  MIN_AGE,
  cepValid,
  allowedCountry,
  enrollmentOriginList
}
