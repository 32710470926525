import styled from 'styled-components'
import { Box as BoxInfos, Grid, Typography } from '@mui/material'

const Box = styled(BoxInfos)`
  &.MuiBox-root {
    display: block;
    align-items: center;
    margin: 0 30px 0 0;

    & .MuiTypography-subtitle1 {
      display: block;
      margin: 0 10px 0 0;
      width: 100%;
    }
  }
`
const Title = styled(Typography)`
  &.MuiTypography-root {
    display: flex;
    margin: 0 0 0 0;
  }
`

const BoxContent = styled(BoxInfos)`
  &.MuiBox-root {
    display: flex;
    width: 100%;
  }
`

const TextName = styled(Typography)`
  &.MuiTypography-root {
    width: 53%;
  }
`

const GridText = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    margin: 20px 0 0 0;
    align-items: center;
  }
`

const BoxButton = styled(Box)`
  &.MuiBox-root {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 0 0;

    & .MuiButtonBase-root {
      padding: 6px 7px;
      width: 50%;
      margin: 0;
    }
  }
`

export { Box, BoxContent, Title, BoxButton, TextName, GridText }
