import * as yup from 'yup'
import { isEmpty } from 'services/helpers'

const schema = (listPole) =>
  yup.object().shape({
    unit: yup.string().required('O campo unidade é obrigatório.'),
    ...(!isEmpty(listPole) && {
      pole: yup.string()
    }),
    competence: yup.date().required('O campo competência é obrigatório.'),
    counting_start_date: yup
      .date()
      .required('O campo início da apuração é obrigatório.'),
    counting_end_date: yup
      .date()
      .required('O campo fim da apuração é obrigatório.'),
    payment_date: yup
      .date()
      .required('O campo data do pagamento é obrigatório.')
  })

export { schema }
