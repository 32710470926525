import { ListAlt, Search } from '@mui/icons-material'

const DEFAULT_ITEMS_PER_PAGE = 10
const DEFAULT_PAGE = 1

const initialState = {
  name: '',
  level: ''
}

const MESSAGE = {
  NO_SEARCH: {
    icon: <ListAlt />,
    text: '<strong>Encontre as Declarações existentes no sistema</strong><br />Faça uma busca selecionando o Nível ou o Nome da Declaração.'
  },
  NOT_FOUND_SEARCH: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
  }
}

const defaultTotalPage = 10

const listItensPerPage = [
  {
    value: '6',
    label: '6'
  },
  {
    value: '12',
    label: '12'
  },
  {
    value: '24',
    label: '24'
  },
  {
    value: '36',
    label: '36'
  },
  {
    value: '54',
    label: '54'
  }
]

const paginationFilter = [
  { value: '10', label: '10' },
  { value: '25', label: '25' },
  { value: '50', label: '50' },
  { value: '100', label: '100' }
]

const modalConfirm = 'modalConfirm'

export {
  MESSAGE,
  modalConfirm,
  paginationFilter,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  defaultTotalPage,
  initialState,
  listItensPerPage
}
