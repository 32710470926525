import { Button, Card, Grid } from '@mui/material'
import TAG_MANAGER from 'tagManager'
import { convertToParams } from 'services/helpers'
import { ROUTE } from 'services/constants'
import * as Styled from './style'

const CardBasicUnitsByPole = ({
  item,
  redirectLink,
  typeUnit,
  sizeTitleName
}) => {
  const emptyValue = '-'
  return (
    <Grid item xs={12} sm={12} md={4}>
      <Card>
        <Styled.LinkHeader to={redirectLink}>
          <Styled.BoxHeader className={TAG_MANAGER.academic_card_edit_unit}>
            <Styled.TitleName
              $sizeTitleName={sizeTitleName}
              variant="subtitle2"
            >
              {item?.name || emptyValue}
            </Styled.TitleName>
            <Styled.Title variant="body2">
              {item?.email || emptyValue}
            </Styled.Title>
            <Styled.IconEdit />
          </Styled.BoxHeader>
        </Styled.LinkHeader>
        <Styled.CardContent>
          <Styled.CardName>
            <Styled.TypographyName>
              {item?.owner || emptyValue}
            </Styled.TypographyName>
            <Styled.TypographyBody2>
              <b>Diretor(a)</b>
            </Styled.TypographyBody2>
          </Styled.CardName>
          {item?.has_polo && typeUnit && (
            <Styled.LinkButtonPole
              to={`${ROUTE.ADMIN_MANAGEMENT_POLE}?${convertToParams({
                unit: item?.name,
                unitId: item?.id
              })}`}
            >
              <Button
                variant="contained"
                className={TAG_MANAGER.academic_btn_accesses_polos}
              >
                polos
              </Button>
            </Styled.LinkButtonPole>
          )}
        </Styled.CardContent>
      </Card>
    </Grid>
  )
}

export default CardBasicUnitsByPole
