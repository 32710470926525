import { defaultItemsPerPage, defaultPage } from './constants'

const paramsOnSubmit = ({ values, filter, pageActual }) =>
  new URLSearchParams({
    ...(values.inactive && { is_inactive: values.inactive }),
    ...(values.category && { category: values.category }),
    limit: filter || defaultItemsPerPage,
    page: pageActual || defaultPage
  })

const newOccurrenceTypes = (data) =>
  data?.reduce((acc, cur) => {
    const { value } = cur
    const newItem = value

    return [...acc, newItem]
  }, [])

const payloadCreateCopy = ({ values, selectedIds }) => ({
  ...(values?.type && {
    occurrence_name: values?.type
  }),
  category: values?.category,
  ids: selectedIds || []
})

export { newOccurrenceTypes, paramsOnSubmit, payloadCreateCopy }
