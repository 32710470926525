import styled from 'styled-components'
import { Toolbar } from '@mui/material'

const ToolbarArea = styled(Toolbar)`
  &.MuiToolbar-root { 
    padding: 0;
    display: flex;
    margin-top: 24px;
    align-items: center;
  }
`

export { ToolbarArea }
