import buildObservations from 'components/templates/PDF/shared/Observation'
import buildResponsibleTeacher from 'components/templates/PDF/shared/ResponsibleTeacher'
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { borderLayoutDefault } = utilsPDF

const buildTableFooter = ({
  totalWorkloadCompleted,
  unit,
  numberOfCompletedLessons,
  classTime,
  responsibleTeachers
}) => ({
  table: {
    body: [
      ...buildObservations({
        stack: [
          {
            text: [
              { text: 'Cada aula corresponde a ' },
              { text: `${classTime} minutos`, bold: true },
              { text: '.' }
            ]
          },
          { text: ' ' },
          {
            text: 'Recuperação contínua ao longo do processo, não apenas ao término, a fim de que o estudante possa demonstrar o desenvolvimento das competências e habilidades previstas.'
          }
        ]
      }),
      ...buildResponsibleTeacher({
        responsibleTeachers,
        totalWorkloadCompleted,
        unit,
        numberOfCompletedLessons
      })
    ],
    widths: [240, 60, '*']
  },
  layout: {
    ...borderLayoutDefault,
    paddingBottom: () => 10,
    paddingLeft: () => 10,
    paddingRight: () => 10,
    paddingTop: () => 10
  }
})

export default buildTableFooter
