const keyColumns = {
  discipline: 'discipline',
  period: 'period',
  course: 'course',
  schoolClass: 'schoolClass',
  schoolClassGroup: 'schoolClassGroup',
  schoolClassShift: 'schoolClassShift',
  schoolClassUnit: 'schoolClassUnit',
  schoolClassStudentsCount: 'schoolClassStudentsCount'
}

const tableColumns = [
  { id: keyColumns.discipline, label: 'Disciplina' },
  { id: keyColumns.period, label: 'Período' },
  { id: keyColumns.schoolClass, label: 'Curso' },
  { id: keyColumns.schoolClass, label: 'Turma' },
  { id: keyColumns.schoolClassGroup, label: 'Grupo' },
  { id: keyColumns.schoolClassShift, label: 'Turno' },
  { id: keyColumns.schoolClassUnit, label: 'Unidade' },
  {
    id: keyColumns.schoolClassStudentsCount,
    label: 'Alunos'
  }
]

const invalidDate = '0000-00-00'

const emptyValue = '- -'

const checkboxTitleTooltip = 'Selecione para adicionar.'

export {
  keyColumns,
  tableColumns,
  invalidDate,
  emptyValue,
  checkboxTitleTooltip
}
