/* eslint-disable camelcase */
import { getLenght } from 'services/helpers'

const isDisabledButton = ({ unity, classIdentifier }) => {
  const disabled = !!unity && !!classIdentifier

  return disabled
}

const processData = (data = []) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { id, value: name, label: name }
    return [...acc, newItem]
  }, [])

const parseParamsSearchAdvanced = ({
  classIdentifier,
  unit,
  level,
  course,
  closed,
  shift,
  filter,
  module,
  unitys,
  isSearchAdvanced,
  ead,
  page
}) => {
  const filterUnits = unitys?.find((i) => i.id === unit)

  return {
    ...(classIdentifier && { class_code: classIdentifier }),
    ...(unit && { unit_name: filterUnits?.label }),
    ...(level && { level_name: level }),
    ...(course && { course_name: course }),
    ...(closed && { finished: closed }),
    ...(isSearchAdvanced && { ead }),
    ...(shift && { class_shift: shift }),
    ...(module && { module }),
    limit: filter,
    page
  }
}

const convertPoles = ({ pole }) => {
  const newArray = pole?.map((objPole) => objPole.value)

  return `pole_name[]=${newArray?.join('&pole_name[]=')}` || []
}

const makeURLDash = ({ unitys, formValues }) => {
  const { company_id } = JSON.parse(localStorage.getItem('PROZ.user'))
  const filterUnits = unitys?.find((i) => i.id === formValues.unit)

  const searchParams = new URLSearchParams()
  searchParams.append('busca', '1')
  searchParams.append('empresa', company_id)
  searchParams.append('cod_turma', formValues.classIdentifier || '')
  searchParams.append(
    'situacao',
    formValues.closed ? 'encerradas' : 'andamento'
  )
  searchParams.append('unidade', filterUnits?.label || '')
  searchParams.append(
    'polo',
    getLenght(formValues.pole) === 1 ? formValues.pole[0].label : ''
  )
  searchParams.append(
    'grupo',
    getLenght(formValues.group) === 1 ? formValues.group[0].label : ''
  )
  searchParams.append('nivel', formValues.level)
  searchParams.append('modalidade', formValues.ead ? '2' : '')
  searchParams.append('modulo', formValues.module)
  searchParams.append('turno', formValues.shift)

  return searchParams.toString()
}

const typeEndpointPole = (unitId) => `type=pole&unit_id=${unitId}`

const parseResponse = (data, isName) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { value: isName ? name : id, label: name }
    return [...acc, newItem]
  }, [])

const processDataValuesId = (data = []) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { value: id, label: name }
    return [...acc, newItem]
  }, [])

export {
  isDisabledButton,
  typeEndpointPole,
  parseResponse,
  parseParamsSearchAdvanced,
  convertPoles,
  makeURLDash,
  processData,
  processDataValuesId
}
