import * as R from 'ramda'

const getTwoFirstWords = (name) => name?.split(' ').slice(0, 2).join(' ')

const API_URL = (versionAPI) =>
  `${process.env.REACT_APP_API_HOST}/${
    versionAPI === 'v2'
      ? process.env.REACT_APP_API_V2
      : process.env.REACT_APP_API_VERSION
  }`
const USER_PREFIX = 'PROZ.user'
const USER_UNIT_PREFIX = 'PROZ.user.unit'
const USER_PREFIX_SCHOOLCLASS = `${USER_PREFIX}.schoolClasses`
const USER_FISRT_ACCESS = `${USER_PREFIX}.firstAccess`
const USER_SAVED_DATA_FROM_URL = 'PROZ.savedDataFromUrl'

const getUserFromLocalStorage = () =>
  JSON.parse(localStorage.getItem(USER_PREFIX)) || {}

const getUserUnitFromLocalStorage = () =>
  JSON.parse(localStorage.getItem(USER_UNIT_PREFIX)) || ''

const removeUserFromLocalStorage = () => localStorage.removeItem(USER_PREFIX)

const removeClassesLocalStorage = () =>
  localStorage.removeItem(USER_PREFIX_SCHOOLCLASS)

const setClassesLocalStorage = (user) => {
  const userCourse = {
    ...user
  }
  localStorage.setItem(USER_PREFIX_SCHOOLCLASS, JSON.stringify(userCourse))
}

const getClassesLocalStorage = () => {
  const userCourse = JSON.parse(localStorage.getItem(USER_PREFIX_SCHOOLCLASS))
  return userCourse || {}
}

const setUserInLocalStorage = (user) => {
  const ONE_WEEK_IN_MILLISEC = 604800 * 10000
  const dateNow = new Date()
  const expiresAt = dateNow.getTime() + ONE_WEEK_IN_MILLISEC
  const userWithExpiration = {
    ...user,
    expiresAt
  }

  localStorage.setItem(USER_PREFIX, JSON.stringify(userWithExpiration))
}

export const isEmpty = (value) => R.isEmpty(value) || R.isNil(value)

const setVisibleUpdatePassword = (action) => {
  localStorage.setItem(USER_FISRT_ACCESS, action)
}
const removeVisibleUpdatePassword = (action) => {
  localStorage.removeItem(USER_FISRT_ACCESS, action)
}

const getVisibleUpdatePassword = () => {
  const firstAccess = localStorage.getItem(USER_FISRT_ACCESS)

  return firstAccess !== 'false'
}

const getSavedDataFromUrl = () => {
  const savedDataFromUrl = JSON.parse(
    localStorage.getItem(USER_SAVED_DATA_FROM_URL)
  )
  return savedDataFromUrl || {}
}

const removeSavedDataFromUrl = () =>
  localStorage.removeItem(USER_SAVED_DATA_FROM_URL)

const setSavedDataFromUrl = (url) => {
  const data = {
    ...url
  }

  localStorage.setItem(USER_SAVED_DATA_FROM_URL, JSON.stringify(data))
}

export {
  API_URL,
  getUserFromLocalStorage,
  getTwoFirstWords,
  removeUserFromLocalStorage,
  setUserInLocalStorage,
  removeClassesLocalStorage,
  setClassesLocalStorage,
  getUserUnitFromLocalStorage,
  getClassesLocalStorage,
  setVisibleUpdatePassword,
  removeVisibleUpdatePassword,
  getVisibleUpdatePassword,
  getSavedDataFromUrl,
  removeSavedDataFromUrl,
  setSavedDataFromUrl
}
