/* eslint-disable import/no-cycle */
import GroupIcon from '@mui/icons-material/Group'
import { ACL } from 'services/constants/acl'
import { ROUTE } from 'services/constants'
import theme from 'theme/designTokens'
import {
  ReplaceClass,
  LinkedClass,
  Interactions,
  DocumentFiles,
  ListProfessorTypes,
  ReplaceClassDetails,
  ReplaceClassDetailsEdit,
  LinkedClassDetails,
  LinkedClassDetailsEdit,
  CreateByUpdateProfessors,
  ManageClasses,
  TimeTraking,
  CreateByUpdateTimeTraking,
  DetailsManageClasses,
  ManageReceipts,
  Consolidation
} from 'components/pages/Admin/HumanResources'
import isAllowUserAccess from '../helpers/isAllowUserAccess'

export const iconProps = {
  color: theme.palette.primary.white
}

const HR_ROUTES = [
  {
    component: ListProfessorTypes,
    exact: true,
    icon: <GroupIcon sx={{ ...iconProps }} />,
    label: 'RH',
    path: ROUTE.ADMIN_HR_PROFESSOR,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'HUMAN_RESOURCES'),
    submenu: [
      {
        component: ListProfessorTypes,
        exact: true,
        label: 'Professores',
        path: ROUTE.ADMIN_HR_PROFESSOR,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'PROFESSOR')
      },
      {
        component: TimeTraking,
        exact: true,
        label: 'Apontamento de Horas',
        path: ROUTE.ADMIN_HR_TIMEKEEPING,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'TIME_TRACKING_PAYROLL_LIST')
      }
    ]
  },
  {
    component: CreateByUpdateProfessors,
    exact: true,
    path: ROUTE.ADMIN_HR_CREATE_PROFESSOR,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'CREATE_PROFESSOR')
  },
  {
    component: CreateByUpdateProfessors,
    exact: true,
    path: ROUTE.ADMIN_HR_DETAILS_PROFESSOR,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'DETAILS_PROFESSOR')
  },
  {
    component: ReplaceClass,
    exact: true,
    path: ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'PROFESSOR_REPLACE_CLASS')
  },
  {
    component: LinkedClass,
    exact: true,
    path: ROUTE.ADMIN_HR_PROFESSOR_CLASS_LINKED,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'PROFESSOR_CLASS_LINKED')
  },
  {
    component: ReplaceClassDetailsEdit,
    exact: true,
    path: ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS_DETAILS_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'PROFESSOR_REPLACE_CLASS_DETAILS')
  },
  {
    component: ReplaceClassDetails,
    exact: true,
    path: ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS_DETAILS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'PROFESSOR_REPLACE_CLASS_DETAILS')
  },
  {
    component: LinkedClassDetails,
    exact: true,
    path: ROUTE.ADMIN_HR_PROFESSOR_CLASS_LINKED_DETAILS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'PROFESSOR_CLASS_LINKED_DETAILS')
  },
  {
    component: LinkedClassDetailsEdit,
    exact: true,
    path: ROUTE.ADMIN_HR_PROFESSOR_CLASS_LINKED_DETAILS_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'PROFESSOR_CLASS_LINKED_DETAILS')
  },
  {
    component: DocumentFiles,
    exact: true,
    path: ROUTE.ADMIN_HR_CREATE_DOCUMENT_FILES,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'DOCUMENT_FILES_PROFESSOR')
  },
  {
    component: Interactions,
    exact: true,
    path: ROUTE.ADMIN_HR_CREATE_INTERACTIONS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'INTERACTIONS_PROFESSOR')
  },
  {
    component: CreateByUpdateTimeTraking,
    exact: true,
    path: ROUTE.ADMIN_HR_CREATE_TIMEKEEPING,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'TIME_TRACKING_PAYROLL_CREATE')
  },
  {
    component: CreateByUpdateTimeTraking,
    exact: true,
    path: ROUTE.ADMIN_HR_EDIT_TIMEKEEPING,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'TIME_TRACKING_PAYROLL_UPDATE')
  },
  {
    component: ManageClasses,
    exact: true,
    path: ROUTE.ADMIN_HR_MANAGE_CLASSES,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'TIME_TRACKING_MANAGE_CLASSES')
  },
  {
    component: DetailsManageClasses,
    exact: true,
    path: ROUTE.ADMIN_HR_MANAGE_CLASSES_DETAILS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'TIME_TRACKING_MANAGE_CLASSES')
  },
  {
    component: ManageReceipts,
    exact: true,
    path: ROUTE.ADMIN_HR_MANAGE_RECEIPTS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'TIME_TRACKING_MANAGE_RECEIPTS')
  },
  {
    component: Consolidation,
    exact: true,
    path: ROUTE.ADMIN_HR_MANAGE_CONSOLIDATION,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'TIME_TRACKING_MANAGE_CONSOLIDATION')
  }
]

export default HR_ROUTES
