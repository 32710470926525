import * as XLSX from 'xlsx'
/* eslint-disable no-bitwise */
/* eslint-disable no-return-assign */
export const exportSpreadsheetByXLSX = (data, fileName = 'Planilha.xlsx') => {
  const blob = new Blob([data], {
    type: 'file.xlsx',
    encoding: 'UTF-8'
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  return link.click()
}

export const exportXLSX = (data, fileName = 'Planilha.xlsx') => {
  const link = document.createElement('a')
  document.body.appendChild(link)
  link.style = 'display: none'

  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
    encoding: 'UTF-8'
  })

  const url = window.URL.createObjectURL(blob)

  link.href = url
  link.download = fileName
  link.click()
  window.URL.revokeObjectURL(url)
  return link.remove()
}

export const stringToArrayBuffer = (s) => {
  const buffer = new ArrayBuffer(s.length)
  const view = new Uint8Array(buffer)

  Array.from(s).map((ch, i) => (view[i] = ch.charCodeAt(0) & 0xff))

  return buffer
}

export const downloadXLSXByBuffer = (buffer, fileName = 'arquivo.xlsx') => {
  // Converter o buffer base64 para um Uint8Array
  const binary = atob(buffer)
  const array = new Uint8Array(binary.length)
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binary.length; i++) {
    array[i] = binary.charCodeAt(i)
  }

  // Criar um workbook a partir do buffer
  const workbook = XLSX.read(array, { type: 'array' })

  // Converter o workbook em um arquivo Blob
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })

  // Criar um link temporário para download
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName) // Nome do arquivo que será baixado

  // Simular um clique no link para baixar o arquivo
  document.body.appendChild(link)
  link.click()

  // Remover o link do DOM
  document.body.removeChild(link)
}
