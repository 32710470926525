export const useTableAbstract = ({ headerColumns }) => {
  const isElement = ({ content }) => !!content?.$$typeof

  const headerColumnsFormatted = headerColumns.map(
    ({ content: label, disabled }) => ({
      label,
      disabled
    })
  )

  return {
    isElement,
    headerColumnsFormatted
  }
}
