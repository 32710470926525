import styled, { css } from 'styled-components'
import {
  Grid as MuiGrid,
  Typography as MuiTypography,
  RadioGroup as MuiRadioGroup
} from '@mui/material'

const Grid = styled(MuiGrid)`
  ${({ flex }) =>
    flex &&
    css`
      gap: 10px;
      display: flex;
      align-items: center;
    `}
`

const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    -webkit-flex-wrap: nowrap;
  }
`

const Checkbox = styled.div`
  margin-top: 8px;
`


const Typography = styled(MuiTypography)``

export { Grid, RadioGroup, Typography, Checkbox }