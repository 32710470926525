import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useCheckedTable, usePaginationBasic, useSnack } from 'services/hooks'
import { defaultlistPerPage } from 'services/hooks/constants'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'

import { ROUTE } from 'services/constants'
import {
  nameAccordion,
  initialStateStatus,
  breadcrumbsItems,
  listIdName
} from './constants'
import { getDisciplinesToAddInGrouping } from './service'
import { Actions, List, SearchForm } from './components'
import { useGroupingIncludeDiscipline } from './Context/FormContext'
import {
  handleAddDisciplinesInGrouping as handleAddDisciplinesInGroupingService,
  handleEdit,
  handleSearch,
  noResultIcon,
  noResultMessage
} from './helpers'

const IncludeDiscipline = () => {
  const [listDiscipline, setListDiscipline] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(defaultlistPerPage)
  const [status, setStatus] = useState(initialStateStatus)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [loading, setLoading] = useState(false)
  const snackProps = useSnack()
  const location = useLocation()
  const navigate = useNavigate()

  const { groupingDisciplines, grouping, groupingId } = location.state
  const { setSnack } = snackProps
  const { formValues, setFormValues } = useGroupingIncludeDiscipline()
  const { items, totalPage, page, handleSetPage } = usePaginationBasic(
    listDiscipline,
    rowsPerPage
  )
  const { handleSelectAllClick, isSelected, selected, setSelected } =
    useCheckedTable(items, listIdName)

  useEffect(() => {
    if (formValues?.search) {
      getDisciplinesToAddInGrouping({
        setSnack,
        setListDiscipline,
        search: formValues?.search,
        rowsPerPage,
        currentPage: page,
        setStatus,
        groupingDisciplines
      })
    }
  }, [JSON.stringify(formValues?.search), page, rowsPerPage])

  const handleRedirectToGroupingManager = () => {
    navigate(`${ROUTE.ADMIN_SECRETARY_GROUPING_MANAGER}`, {
      ...location
    })
  }

  const handleAddDisciplinesInGrouping = () =>
    handleAddDisciplinesInGroupingService({
      groupingId,
      selected,
      setSnack,
      setLoading,
      callbackSuccess: () => {
        navigate(`${ROUTE.ADMIN_SECRETARY_GROUPING_MANAGER}`, {
          state: {
            ...location.state,
            groupingDisciplines: [
              ...groupingDisciplines,
              ...selected.filter((id) => !groupingDisciplines.includes(id))
            ]
          }
        })
      }
    })

  const hasResult = !!items?.length

  return (
    <TemplateDefaultFormAndResult
      accordion={
        <SearchForm
          handleSearch={(props) =>
            handleSearch({
              ...props,
              setListDiscipline,
              setStatus,
              setFormValues
            })
          }
          setSnack={setSnack}
          groupingId={groupingId}
          formValues={formValues}
        />
      }
      hasIconButton
      listItems={items}
      expanded={expanded}
      snackProps={snackProps}
      classNameHeader="goBack"
      setExpanded={setExpanded}
      labelButtonHeader="Voltar"
      nameAccordion={nameAccordion}
      iconInformative={noResultIcon({ status })}
      messageInformative={noResultMessage({ status })}
      onClickButton={handleRedirectToGroupingManager}
      breadcrumbsItems={breadcrumbsItems({ grouping, groupingId })}
    >
      {!!selected?.length && (
        <Actions
          handleAddDisciplinesInGrouping={handleAddDisciplinesInGrouping}
          loading={loading}
        />
      )}

      {!!hasResult && (
        <List
          page={page}
          items={items}
          limit={rowsPerPage}
          selected={selected}
          count={listDiscipline.length}
          totalPage={totalPage}
          isSelected={isSelected}
          handleEdit={(props) =>
            handleEdit({ ...props, selected, setSelected })
          }
          handleSetPage={handleSetPage}
          handleSelectAllClick={handleSelectAllClick}
          handleChange={(e) => setRowsPerPage(e.target.value)}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default IncludeDiscipline
