import FEEDBACK_SNACK from 'feedBackSnack'
import { updateErrorMessage } from 'services/helpers'
import { ERROR, SUCCESS } from 'services/constants'
import {
  getAcademicPerformancesDiciplines,
  updateAcademicPerformancesDiciplines
} from 'services/api/admin'
import { parseUpdateAcademicPerformanceDisciplines } from './helpers'
import { MESSAGE } from './constants'

const getAcademicPerformances = async ({
  register,
  classId,
  requirementNumber,
  setSnack,
  setAcademicPerformances,
  setLoadingOpen
}) => {
  if (setLoadingOpen) setLoadingOpen(true)

  const {
    data: { academic_performance_disciplines: disciplines, course },
    error,
    status
  } = await getAcademicPerformancesDiciplines({
    register,
    requirementNumber,
    classId
  })

  if (setLoadingOpen) setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListAcademicPerformanceDiscipline
    })
  }

  return setAcademicPerformances({ course, disciplines })
}

const updateAcademicPerformance = async ({
  register,
  classId,
  requirementNumber,
  setSnack,
  setLoadingOpen,
  values,
  setAcademicPerformances
}) => {
  setSnack('')
  setLoadingOpen(true)

  const body = parseUpdateAcademicPerformanceDisciplines(values)

  const { error } = await updateAcademicPerformancesDiciplines({
    register,
    body
  })

  setLoadingOpen(false)

  if (error) {
    return setSnack(
      body.feedback
        ? MESSAGE.ERROR_SAVE_ACADEMIC_PERFORMANCE_FEEDBACK
        : MESSAGE.ERROR_SAVE_ACADEMIC_PERFORMANCE,
      ERROR
    )
  }

  setSnack(
    body.feedback
      ? MESSAGE.SUCCESS_SAVE_ACADEMIC_PERFORMANCE_FEEDBACK
      : MESSAGE.SUCCESS_SAVE_ACADEMIC_PERFORMANCE,
    SUCCESS
  )

  return getAcademicPerformances({
    register,
    classId,
    requirementNumber,
    setSnack,
    setAcademicPerformances,
    setLoadingOpen
  })
}

export { getAcademicPerformances, updateAcademicPerformance }
