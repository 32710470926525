import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import {
  ButtonFooterV2,
  InputFile,
  Select,
  TextField
} from 'components/molecules'
import theme from 'theme/designTokens'
import { getRgba } from 'services/helpers/polished'
import { DatePicker } from 'components/atoms'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'
import { MESSAGE, TYPE_FILE_PDF } from './constants'
import { createDocsProfessor } from '../../services'
import { schema } from './schema'

const ModalForm = ({
  open,
  setSnack,
  setLoadingOpen,
  documentsType,
  setListDocuments,
  handleClose,
  formModal,
  setErrorTypeFile,
  errorTypeFile
}) => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id } = params
  const [nameFile, setNameFile] = useState('pdf')

  const handleFileChange = ({ event, setFieldValue }) => {
    if (event === '') {
      return setFieldValue('file', '')
    }

    const selectedFile = event.target.files[0]

    if (selectedFile.type === TYPE_FILE_PDF) {
      setNameFile(selectedFile.name)
      const readFileAsBase64 = (file) =>
        new Promise((resolve) => {
          const reader = new FileReader()
          reader.onload = () => resolve(reader.result)
          reader.readAsDataURL(file)
        })

      return readFileAsBase64(selectedFile).then((base64String) => {
        setErrorTypeFile(false)
        setFieldValue('file', base64String)
      })
    }

    return setErrorTypeFile(true)
  }

  return (
    <Styled.Modal open={open} onClose={handleClose} maxWidth="sm">
      <Formik
        initialValues={formModal}
        onSubmit={(values) =>
          createDocsProfessor({
            id,
            setSnack,
            setLoadingOpen,
            handleClose,
            setListDocuments,
            values
          })
        }
        validationSchema={schema}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, dirty, errors, touched }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12} sm={12} md={11}>
                  <Styled.Title variant="h5" component="h5">
                    Enviar Documento
                  </Styled.Title>
                </Grid>

                <Styled.GridClose item xs={12} sm={12} md={1}>
                  <Styled.Close onClick={() => handleClose()} />
                </Styled.GridClose>

                <Grid item xs={12} sm={12} md={12}>
                  <Select
                    id="typeDocs"
                    value={values?.document_type_id || null}
                    label="Selecione o Tipo de Documento"
                    optionsList={documentsType}
                    onChange={(e) =>
                      setFieldValue('document_type_id', e.target.value)
                    }
                    helperText={
                      errors &&
                      touched.document_type_id &&
                      errors.document_type_id
                    }
                    error={Boolean(
                      touched.document_type_id && errors.document_type_id
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Nome do Curso"
                    value={values.name || ''}
                    onChange={(e) => {
                      setFieldValue('name', e.target.value)
                    }}
                    helperText={errors && touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <DatePicker
                    fullWidth
                    id="done_at"
                    value={values?.done_at || null}
                    label="Data do Documento"
                    onChange={(newValue) => setFieldValue('done_at', newValue)}
                    helperText={errors && touched.done_at && errors.done_at}
                    error={Boolean(touched.done_at && errors.done_at)}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  $errorTypeFile={errorTypeFile || !values?.file}
                >
                  <InputFile
                    idUpload="upload"
                    typeFile="pdf"
                    labelBox={errorTypeFile ? MESSAGE.ERROR : MESSAGE.SUCCESS}
                    imgSrc={values?.file || ''}
                    name="Documento"
                    nameFile={nameFile}
                    hasSize
                    errorTypeFile={errorTypeFile}
                    border={`2px dashed ${theme.palette.dark.light}`}
                    backgroundColor={
                      errorTypeFile
                        ? getRgba(theme.palette.red.lightRgb, 0.21)
                        : theme.palette.purple.light
                    }
                    width="auto"
                    onChange={(e) =>
                      handleFileChange({ event: e, setFieldValue })
                    }
                  />
                </Grid>
                {Boolean(touched.file && errors.file) && (
                  <Styled.TextError>
                    {errors && touched.file && errors.file}
                  </Styled.TextError>
                )}

                <Grid item xs={12} sm={12} md={12}>
                  <ButtonFooterV2
                    labelClose="Cancelar"
                    labelConfirm="Enviar"
                    classNameConfirm={
                      TAG_MANAGER.rh_btn_confirma_envioDocumento
                    }
                    size="large"
                    onClickClose={() => {
                      setErrorTypeFile(false)
                      handleClose()
                      handleFileChange({ event: '', setFieldValue })
                    }}
                    disabledConfirm={errorTypeFile || !dirty}
                  />
                </Grid>
              </Grid>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export { ModalForm }
