import { TableHead, TableRow } from '@mui/material'
import theme from 'theme/designTokens'
import { headCells } from './config'
import * as Styled from './style'

const TableHeader = () => (
  <TableHead>
    <TableRow sx={{ backgroundColor: theme.palette.gray.light }}>
      {headCells.map((headCell) => (
        <Styled.TableCell
          key={headCell.id}
          align={headCell.numeric ? 'right' : 'center'}
          padding={headCell.disablePadding ? 'none' : 'normal'}
          colSpan={headCells[0].id === headCell.id ? 2 : 1}
        >
          {headCell.label}
        </Styled.TableCell>
      ))}
    </TableRow>
  </TableHead>
)

export default TableHeader
