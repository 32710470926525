import { Grid, Switch, Typography } from '@mui/material'
import { TextField } from 'components/molecules'
import * as Styled from './style'

const FormListContractsTypes = ({ formik }) => (
  <>
    <Grid item xs={12} sm={12} md={7}>
      <TextField
        fullWidth
        name="name"
        isMargin="0"
        label="Título do contrato"
        onBlur={formik.handleBlur}
        value={formik.values?.name || ''}
        error={formik.errors.name && formik.touched.name}
        helperText={formik.touched.name && formik.errors.name}
        onChange={(e) => formik.setFieldValue('name', e.target.value)}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={2}>
      <Styled.Switch>
        <Switch
          name="inactive"
          checked={formik.values?.inactive}
          onClick={(e) => formik.setFieldValue('inactive', e.target.checked)}
        />
        <Typography>Inativos</Typography>
      </Styled.Switch>
    </Grid>
  </>
)

export default FormListContractsTypes
