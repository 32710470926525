import styled from 'styled-components'
import { Form as FormFormik } from 'formik'
import { Typography } from '@mui/material'

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 16px 0;
`

const Form = styled(FormFormik)`
  margin: 23px 0;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 32px;
  }
`
const Text = styled.p`
  font-weight: 700;
  font-size: 16px;
`

const Link = styled.a`
  margin-left: 0.5rem;
`

export { Box, Form, Title, Link, Text }
