const disabledButton = (valuesForm) =>
  !(
    valuesForm.name &&
    valuesForm.code &&
    valuesForm.module !== '' &&
    valuesForm.type_score &&
    valuesForm.workload_hours &&
    valuesForm.workload_minutes
  )

export { disabledButton }
