import styled from 'styled-components'
import { Button } from 'components/atoms'
import { Avatar as MuiAvatar, Box as MuiBox } from '@mui/material'

const WrapperButton = styled(Button)`
  border-radius: 50%;
  &.MuiButton-root {
    margin: 0;
    padding: 0 0 12px;
  }
`

const Avatar = styled(MuiAvatar)`
  &.MuiAvatar-root {
    background-color: ${({ theme, $avatarAdmin }) =>
      $avatarAdmin
        ? theme.palette.primary.light
        : theme.palette.textColors.secondary.lightBlue};
    color: ${({ theme, $avatarAdmin }) =>
      $avatarAdmin
        ? theme.palette.white
        : theme.palette.textColors.secondary.darkBlue};
    width: 59px;
    height: 59px;
  }
`

const Box = styled(MuiBox)`
  position: absolute;
  bottom: -60px;
  right: 136%;

  svg {
    color: ${({ theme, $avatarAdmin }) =>
      $avatarAdmin
        ? theme.palette.textColors.black.darkBlack
        : theme.palette.gray.main};
  }
`

export { WrapperButton, Avatar, Box }
