import { convertDateToUTC } from 'services/helpers'
import { activeText, inactiveText } from './constants'

const currentDate = new Date()

const disabledLinkedStartDate = (item) => {
  const beginDate = convertDateToUTC(item.begin_date)
  return currentDate >= beginDate
}

const disabledLinkedEndDate = (item) => {
  const endDate = convertDateToUTC(item.end_date)
  endDate.setDate(endDate.getDate() + 1)
  return endDate < currentDate
}

const labelTable = ({ doneText, item }) => {
  const endDate = convertDateToUTC(item.end_date)
  endDate.setDate(endDate.getDate() + 1)

  if (currentDate > endDate && item?.active) {
    return doneText
  }
  return item?.active ? activeText : inactiveText
}

const filterFutureDates = (obj) => {
  const currentDates = new Date()

  return Object.keys(obj).reduce((filteredObj, key) => {
    const dateValue = new Date(obj[key])

    return {
      ...filteredObj,
      ...(dateValue > currentDates && { [key]: obj[key] })
    }
  }, {})
}

export {
  disabledLinkedStartDate,
  disabledLinkedEndDate,
  labelTable,
  currentDate,
  filterFutureDates
}
