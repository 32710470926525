function getCommercialPermissions(permissions) {
  const commercial = permissions?.commercial || {}
  const preEnrollment = commercial?.pre_enrollment || {}
  const directEnrollment = commercial?.direct_enrollment || {}
  const manualEnrollment = commercial?.manual_enrollment || {}

  const COMMERCIAL_PERMISSIONS = {
    COMMERCIAL: Boolean(commercial),
    COMMERCIAL_REGISTRATION: Boolean(preEnrollment?.POST),
    COMMERCIAL_REGISTRATION_CREATE: Boolean(directEnrollment?.POST),
    COMMERCIAL_DIRECT_ENROLLMENT: Boolean(directEnrollment?.POST),
    COMMERCIAL_REGISTRATION_SUBSCRIPTION: Boolean(manualEnrollment?.GET)
  }
  return COMMERCIAL_PERMISSIONS
}

export default getCommercialPermissions
