import PropTypes from 'prop-types'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker'
import { getGlobalLocale } from 'services/helpers'

import { TextField } from '@mui/material'

const VIEW_DEFAULT = ['day']
const DatePicker = ({
  value,
  onChange,
  id,
  disabled,
  helperText,
  onBlur,
  disableFuture,
  error,
  label,
  size,
  defaultValue,
  fullWidth,
  disabledInput,
  viewValue = VIEW_DEFAULT,
  readOnly,
  fontSize,
  ...props
}) => {
  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={getGlobalLocale()}
    >
      <Picker
        views={viewValue}
        id={id}
        label={label}
        disabled={disabled}
        value={value}
        onChange={onChange}
        disableFuture={disableFuture}
        defaultValue={defaultValue}
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            name={props.name}
            size={size}
            helperText={helperText}
            error={error}
            fullWidth={fullWidth}
            disabled={disabledInput}
            onBlur={handleBlur}
            inputProps={{ ...params.inputProps, readOnly }}
            sx={{ '& .MuiInputBase-input': { fontSize } }}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default DatePicker

DatePicker.propTypes = {
  onChange: PropTypes.func,
  size: PropTypes.string,
  id: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disableFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  inputFormat: PropTypes.string,
  readOnly: PropTypes.bool,
  fontSize: PropTypes.string
}

DatePicker.defaultProps = {
  onChange: () => {},
  size: '',
  id: '',
  helperText: '',
  disableFuture: false,
  error: false,
  disabled: false,
  readOnly: false
}
