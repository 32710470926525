import styled from 'styled-components'
import { Pagination, Paper } from '@mui/material'

const TableContainer = styled(Paper)`
  &.MuiPaper-root {
    margin: ${({ $switchAdvancedSearch }) =>
      $switchAdvancedSearch ? '5px 0 0 0' : '33px 0 0 0'};
  }
`

const PaginationTable = styled(Pagination)`
  ul {
    justify-content: center;
    padding: 4px 0;
  }
`

const TableFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export { TableContainer, PaginationTable, TableFooter }
