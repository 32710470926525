/* eslint-disable camelcase */
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  getDisciplinesById,
  getJoyCourses,
  getLevelCourses,
  saveSubjects,
  updateSubjects
} from 'services/api/admin'
import { toastNotificationSuccess, updateErrorMessage } from 'services/helpers'
import { MESSAGE } from './constants'
import { parserGetSubject, parserPutSubject } from './helpers'

const getSubjectById = async ({
  idSubject,
  setLoading,
  setSubject,
  disciplineJoy
}) => {
  const {
    data: response,
    error,
    status
  } = await getDisciplinesById(Number(idSubject))

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToViewSubjects
    })
  }

  return setSubject({
    ...parserGetSubject(response, disciplineJoy)
  })
}

const updatedSubjects = async ({
  values,
  setLoading,
  navigate,
  idSubject,
  isEdit
}) => {
  setLoading(true)

  const { error, status } = await updateSubjects(
    parserPutSubject(values, isEdit),
    idSubject
  )

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateTeachingPlan
    })
  }

  toastNotificationSuccess(MESSAGE.SUCCESS_EDIT, 1500)

  return setTimeout(() => navigate(-1), 3000)
}

const createByUpdateSubjects = async ({
  values,
  setLoading,
  navigate,
  isEdit,
  idSubject
}) => {
  setLoading(true)

  if (isEdit) {
    return updatedSubjects({
      values,
      setLoading,
      navigate,
      idSubject,
      isEdit
    })
  }

  const { error, status } = await saveSubjects(parserPutSubject(values))

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateTeachingPlan
    })
  }

  toastNotificationSuccess(MESSAGE.SUCCESS, 1500)

  return setTimeout(() => navigate(-1), 3000)
}

const listJoyCourses = async ({ setLoading, setDisciplineJoy, isEdit }) => {
  setLoading(true)

  const { data, error, status } = await getJoyCourses()

  if (!isEdit) setLoading(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListListJoyCourses
    })
  }

  const newList = data?.reduce((acc, cur) => {
    const { api_key, name } = cur
    return [...acc, { value: api_key, label: name }]
  }, [])

  return setDisciplineJoy(newList)
}

const listLevelCourses = async ({
  setLoading,
  setLevelCourse,
  levelCourse
}) => {
  setLoading(true)

  const { data, error, status } = await getLevelCourses(
    levelCourse?.map(({ label }) => label)
  )

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListLevelCourses
    })
  }

  const listCourses = data.reduce((accumulator, item) => {
    const { courses } = item
    const courseNames =
      courses?.map((course) => ({
        label: course?.name,
        value: course?.name
      })) ?? []
    return [...accumulator, ...courseNames]
  }, [])

  return setLevelCourse(listCourses)
}

export {
  createByUpdateSubjects,
  getSubjectById,
  listJoyCourses,
  listLevelCourses
}
