import {
  searchSuppliers as searchSuppliersService,
  createSupplier as createSupplierService,
  updateSupplier as updateSupplierService,
  searchSupplierById as searchSupplierByIdService
} from 'services/api/admin'
import { messageErroOrSuccess } from 'services/helpers'

import {
  formatInitialSuppliers,
  formatInitialValuesSupplier,
  formatParamsSearchSupplier,
  formatPayloadCreateSupplier,
  formatPayloadUpdateSupplier
} from './helpers'
import { messages } from './constants'

const searchSuppliers = async ({ setSnack, search, setStatus, setData }) => {
  const params = formatParamsSearchSupplier(search)

  const {
    data: { data, ...restResult },
    error,
    status
  } = await searchSuppliersService(params)

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  const suppliers = (data ?? [])?.map(formatInitialSuppliers)

  setData({
    ...restResult,
    suppliers
  })

  setStatus({
    empty: false,
    notFound: !!error || !suppliers?.length
  })
}

const createSupplier = async ({
  setSnack,
  payload,
  navigate,
  setLoadingVisibility
}) => {
  setLoadingVisibility(true)

  const newPayload = formatPayloadCreateSupplier(payload)

  const { error, status } = await createSupplierService(newPayload)

  await messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage: messages.createSupplier.feedbackMessage,
    successMessage: messages.createSupplier.successMessage,
    isResturnBackPage: true,
    navigate
  })

  setLoadingVisibility(false)
}

const updateSupplier = async ({
  setSnack,
  payload,
  navigate,
  setLoadingVisibility
}) => {
  setLoadingVisibility(true)

  const newPayload = formatPayloadUpdateSupplier(payload)

  const { error, status } = await updateSupplierService(newPayload, payload?.id)

  await messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage: messages.updateSupplier.feedbackMessage,
    successMessage: messages.updateSupplier.successMessage,
    isResturnBackPage: true,
    navigate
  })

  setLoadingVisibility(false)
}

const searchSupplierById = async ({
  setSnack,
  idSupplier,
  setSupplier,
  setLoadingVisibility
}) => {
  setLoadingVisibility(true)

  const { data, error, status } = await searchSupplierByIdService(idSupplier)

  await messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  const response = formatInitialValuesSupplier(data)

  setSupplier(response)

  setLoadingVisibility(false)
}

const updateStatusSupplier = async ({
  setSnack,
  idSupplier,
  inactive,
  handleCloseModal
}) => {
  const { error, status } = await updateSupplierService(
    { inactive },
    idSupplier
  )

  await messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage:
      messages?.[inactive ? 'inactiveSupplier' : 'activeSupplier']
        ?.feedbackMessage,
    successMessage:
      messages?.[inactive ? 'inactiveSupplier' : 'activeSupplier']
        ?.successMessage
  })

  if (!error) {
    handleCloseModal()
  }
}

export {
  searchSuppliers,
  createSupplier,
  updateSupplier,
  searchSupplierById,
  updateStatusSupplier
}
