import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useCheckedTable, usePaginationBasic, useSnack } from 'services/hooks'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import { currencyMask, getMoneyFormatToNumber, isEmpty } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import { FormSchoolClassDatails } from './components/Form'
import { breadCrumbsItems, defaultItemsPerPage } from './constants'
import {
  editClass,
  getDetails,
  getListDisciplines,
  handleTransformList,
  handleTransformListByEdit,
  postLinkedClass
} from './services'
import TableLinkedClassDetails from './components/Table'
import * as Styled from './style'

const LinkedClassDetails = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { setSnack } = snackProps
  const [searchParams] = useSearchParams()
  const [count, setCount] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [listSelected, setListSelected] = useState([])
  const [idClassLinked, setIdClassLinked] = useState(null)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [listDisciplines, setListDisciplines] = useState([])
  const [hourlyLessonCost, setHourlyLessonCost] = useState('')
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [classDetails, setClassDetails] = useState()

  const params = Object.fromEntries([...searchParams])
  const { isEdit, name, id, idProfessor } = params

  const {
    page: pageActual,
    totalPage,
    items,
    totalItems,
    setPage
  } = usePaginationBasic(listDisciplines, filter, count)

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    setSelected,
    selected: selectedItem
  } = useCheckedTable(items, 'id')

  const handleUpdateDisciplines = () => {
    const findProfessor = (item) =>
      (item?.professors ?? []).find(
        (professor) => professor.id === parseFloat(idProfessor) && professor.linked_professor_id
      )

    const disciplinesToCreate = items
      .filter((item) => listSelected.includes(item.id) && !findProfessor(item))
      .map((item) => ({
        id: item.id,
        hourly_lesson_cost: getMoneyFormatToNumber(
          currencyMask(hourlyLessonCost || '')
        )
      }))

    const disciplinesToEdit = items
      .filter((item) => listSelected.includes(item.id) && findProfessor(item))
      .map((item) => {
        const professor = findProfessor(item)
        return {
          ...(!!professor && {
            position: professor.position
          }),

          id: item.id,
          hourly_lesson_cost: getMoneyFormatToNumber(
            currencyMask(hourlyLessonCost || '')
          )
        }
      })

    if (disciplinesToEdit.length) {
      editClass({
        id,
        name,
        navigate,
        setSnack,
        idProfessor,
        setSelected,
        setIsUpdate,
        isEdit: true,
        idClassLinked,
        setLoadingOpen,
        setListSelected,
        disciplines: disciplinesToEdit
      })
    }
    if (disciplinesToCreate.length) {
      postLinkedClass({
        id,
        name,
        setSnack,
        navigate,
        idProfessor,
        setSelected,
        setIsUpdate,
        isEdit: true,
        setLoadingOpen,
        setListSelected,
        disciplines: disciplinesToCreate
      })
    }
  }

  const handleClean = () => {
    setSelected([])
    setListSelected([])
    setHourlyLessonCost('')
  }

  const onHandleConfirm = () => {
    handleClean()
    setOpenModalConfirm(false)
    navigate(-1)
  }

  useEffect(() => {
    getDetails({
      id,
      setSnack,
      setLoadingOpen,
      setClassDetails
    })
  }, [isUpdate])

  useEffect(() => {
    getListDisciplines({
      id,
      filter,
      setSnack,
      setPage,
      setCount,
      pageActual,
      setLoadingOpen,
      setListDisciplines
    })
  }, [pageActual, filter, isUpdate])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      open={openModalConfirm}
      loadingOpen={loadingOpen}
      handleConfirmModal={() => onHandleConfirm()}
      onClickButton={() => setOpenModalConfirm(true)}
      handleCloseModal={() => setOpenModalConfirm(false)}
      classNameHeader={TAG_MANAGER.rh_btn_cancela_vinculo}
      breadcrumbsNotLink={breadCrumbsItems({
        name,
        code: classDetails?.code,
        isEdit: isEdit === 'true'
      })}
      content={
        <>
          <FormSchoolClassDatails
            values={classDetails}
            hourlyLessonCost={hourlyLessonCost}
            setHourlyLessonCost={setHourlyLessonCost}
          />

          {listDisciplines && !isEmpty(listDisciplines) && (
            <>
              <TableLinkedClassDetails
                items={items}
                filter={filter}
                setPage={setPage}
                count={totalItems}
                setFilter={setFilter}
                totalPage={totalPage}
                pageActual={pageActual}
                isSelected={isSelected}
                handleClick={handleClick}
                setSelected={setSelected}
                isEdit={isEdit === 'false'}
                selectedItem={selectedItem}
                setListSelected={setListSelected}
                handleSelectAllClick={handleSelectAllClick}
              />

              <Grid item xs={12} sm={12} md={6}>
                <Styled.BoxGroupingCaption>
                  <Box className="caption">
                    <Typography variant="body1">A</Typography>
                  </Box>
                  <Typography variant="caption">Em agrupamento</Typography>
                </Styled.BoxGroupingCaption>
              </Grid>

              <Grid marginTop="30px" item xs={12} sm={12} md={12}>
                <ButtonFooterV2
                  size="large"
                  labelClose="Cancelar"
                  labelConfirm="salvar VÍNCULO"
                  disabledConfirm={isEmpty(listSelected)}
                  onClickClose={() => setOpenModalConfirm(true)}
                  onClickConfirm={() => handleUpdateDisciplines()}
                  classNameCancel={TAG_MANAGER.rh_btn_cancela_vinculo}
                  classNameBtnConfirm={TAG_MANAGER.rh_btn_salva_vinculo}
                />
              </Grid>
            </>
          )}

          <ModalConfirm
            size="medium"
            title="Cancelar"
            open={openModalConfirm}
            textButtonConfirm="Sim"
            textButtonNotConfirm="Não"
            colorButtonConfirm="error"
            icon={<Styled.IconWarningAmber />}
            handleClose={() => setOpenModalConfirm(false)}
            onClickConfirm={() => {
              // setTabActive(2)
              setOpenModalConfirm(false)
            }}
            onClickNotConfirm={() => setOpenModalConfirm(false)}
            classNameBtnConfirm={TAG_MANAGER.rh_btn_confirma_cancelarVinculo}
            message="Deseja cancelar a operação atual? Você perderá as alterações realizadas."
          />
        </>
      }
    />
  )
}

export default LinkedClassDetails
