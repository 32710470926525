import styled from 'styled-components'

const Title = styled.h3`
  font-size: 32px;
  font-weight: 500;
  margin: ${({ $isMargin }) => ($isMargin ? '0' : '0 0 16px 0')};
`

const Card = styled.div`
  padding: 24px;
`

const CardFooter = styled.div`
  margin-top: 24px;
  text-align: end;

  button {
    margin: 0;
  }
`

const CardLink = styled.div`
  margin-top: 10px;
  text-align: end;
`

export { Card, Title, CardFooter, CardLink }
