import styled, { css } from 'styled-components'
import { Grid as MuiGrid, Box } from '@mui/material'

const Container = styled.div`
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const ContainerCards = styled(Container)`
  > div:first-child {
    height: 122.76px;
  }
  > div {
    flex: 1 0 210px;
    margin-bottom: 20px;
    > div:first-child {
      width: 100% !important;
      height: 100% !important;
    }
    > div:last-child {
      display: none;
    }
  }
`

const Grid = styled(MuiGrid)`
  ${({ isRow }) =>
    isRow &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}

  .MuiPaper-root {
    width: 100% !important;
  }
`

const Form = styled.form``

const GridContainer = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
  .MuiGrid-root .MuiGrid-item .MuiPaper-root {
    margin: 0 0 1px;
    border-radius: 0px;
    box-shadow: 0px 1px 3px 0px #0000001f, 0px 1px 1px 0px #00000024,
      0px 2px 1px -1px #00000033;
  }
  .Mui-expanded {
    margin-bottom: 1px;
  }
`

const Title = styled(Box)`
  display: flex;
  margin: 5px 0;
  align-items: start;
  justify-content: flex-start;
`
export { Form, Grid, Title, Container, GridContainer, ContainerCards }
