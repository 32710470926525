import styled from 'styled-components'
import { WarningAmber as MuiWarningAmber } from '@mui/icons-material'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  .warning {
    min-width: 100vw;
  }
`

const ContainerWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const TextWarning = styled.p``

const Warning = styled(MuiWarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`
const CodeWrapper = styled.div`
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FakeLink = styled.p`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
`

export {
  Warning,
  Container,
  ContainerWarning,
  TextWarning,
  CodeWrapper,
  FakeLink
}
