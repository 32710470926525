import styled, { css } from 'styled-components'
import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell
} from '@mui/material'
import { Delete } from '@mui/icons-material'

const TableRow = styled(MuiTableRow)`
  ${({ $isDisabled }) =>
    $isDisabled && ' opacity: 0.5; &:hover { opacity: 1; }'}

  .actions {
    display: none;
  }

  :hover {
    .actions {
      display: flex;
    }

    .nameContainer {
      justify-content: space-between;
    }
  }

  ${({ $total }) =>
    $total &&
    css`
      background-color: ${({ theme }) => theme.palette.gray.secondaryLight};
    `}
`

const Name = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin: 2.5px;
  line-height: normal;
`

const Total = styled(Name)`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.dark.main};
`

const Group = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  margin: 2.5px;
`
const TableCell = styled(MuiTableCell)`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.15px;

  ${({ $hasBorder }) =>
    $hasBorder &&
    css`
      border-left: 1px solid ${({ theme }) => theme.palette.primary.white};
    `}
`

const NameContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

const NameTexts = styled.div`
  display: flex;
  flex-direction: column;
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.textColors.error};
    cursor: pointer;
  }
`

export {
  TableRow,
  Name,
  Total,
  Group,
  TableCell,
  NameContainer,
  NameTexts,
  DeleteIcon
}
