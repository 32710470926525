const defaultName = 'name'

const MESSAGE = {
  DELETE:
    'Você tem certeza que deseja excluir esta formação? Esta ação é irreversível.',
  MAIN: 'Ao marcar esta opção como principal, a seleção anterior será substituída. Deseja continuar?'
}

const headCells = [
  {
    id: 'created_at',
    label: 'Data/Hora'
  },
  {
    id: 'to',
    label: 'Usuário'
  },
  {
    id: 'subject',
    label: 'Ação'
  },
  {
    id: 'created_by',
    label: 'Responsável'
  }
]

export { headCells, defaultName, MESSAGE }
