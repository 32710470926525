import styled from 'styled-components'
import { Typography } from '@mui/material'

const Container = styled.div`
  min-width: 300px;
  min-height: 160px;
  border-radius: 4px;
  /* box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); */
  box-shadow: ${({ $isBoxShadow }) =>
    $isBoxShadow ??
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)'};
  background: ${({ theme }) => theme.palette.background.white};
  padding: 24px;
`

const Header = styled.div`
  margin-bottom: 16px;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

const IconText = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`

export { Container, Header, Title, IconText }
