import {
  typeEndpointPole,
  parseResponse
} from 'components/pages/Admin/Secretary/SchoolClass/ListSchoolClass/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getCourses } from 'services/api/admin'
import { getUnitsRegions } from 'services/api/shared'
import { getDataFormKeys, updateErrorMessage } from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'

export const getListCourses = async ({ values, setSnack, setListCourses }) => {
  const params = cleanObject({
    ...(values.level && { level: values.level }),
    unit_id: values?.unity
  })

  const { data, error, status } = await getCourses(params)
  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToForm
    })
  }

  return setListCourses(parseResponse(data?.data, true))
}

export const onUnitsRegions = async ({ setSnack, setListPole, values }) => {
  const isName = true

  const { data, error, status } = await getUnitsRegions(
    typeEndpointPole(values.unity)
  )

  const dataPole = getDataFormKeys([0, 'units'], data?.data)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToRedirectDashboard
    })
  }

  return setListPole(parseResponse(dataPole, isName))
}
