import theme from 'theme/designTokens'
import { extractManager, nfeZip, nfsZip } from './services'

const JANUARY = 1
const nameAccordion = 'formManager'
const textMessageConfirmedReporcessing =
  'Deseja realizar o reprocessamento?<br/> Antes de confirmar, certifique-se de que a conciliação foi realizada para evitar  duplicação de notas.'

const getMonthsUpToCurrent = (year) => {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]

  const months = monthNames.map((name, index) => ({
    value: index + 1,
    label: name
  }))

  return year === currentYear ? months.slice(0, currentMonth + 1) : months
}

const objResponse = [
  {
    id: 'fila',
    label: 'Fila',
    value: 0,
    color: theme.palette.primary.main
  },
  {
    id: 'processando_autorizacao',
    label: 'Processando',
    value: 0,
    color: theme.palette.blue.main
  },
  {
    id: 'erro_autorizacao',
    label: 'Erro',
    value: 0,
    color: theme.palette.secondary.main
  },
  {
    id: 'autorizado',
    label: 'Autorizadas',
    value: 0,
    color: theme.palette.success.main
  },
  {
    id: 'cancelado',
    label: 'Canceladas',
    value: 0,
    color: theme.palette.red.light
  }
]

const buttonsActions = ({ formikBillingSearch, setLoading, setNotSearch }) => [
  {
    id: '1',
    label: 'Baixar GL NF-E',
    sizeGrid: '2.1',
    onClickAction: () =>
      nfeZip({
        values: formikBillingSearch.values,
        setLoading,
        setNotSearch
      })
  },
  {
    id: '2',
    label: 'Baixar GL NF-S',
    sizeGrid: '2.1',
    onClickAction: () =>
      nfsZip({
        values: formikBillingSearch.values,
        setLoading,
        setNotSearch
      })
  },
  {
    id: '3',
    label: 'Baixar extrato',
    sizeGrid: '2.2',
    onClickAction: () =>
      extractManager({
        values: formikBillingSearch.values,
        setLoading,
        setNotSearch
      })
  }
]

const startYear = 2023
const endYear = new Date().getFullYear()

const yearsList = Array.from(
  { length: endYear - startYear + 1 },
  (_, index) => {
    const yearValue = startYear + index
    return { value: yearValue, label: `${yearValue}` }
  }
)

const getCurrentMonth = () => {
  const currentDate = new Date()
  return currentDate.getMonth() + 1
}

const getCurrentYear = () => {
  const currentDate = new Date()
  return currentDate.getFullYear()
}

const initialState = {
  month: getCurrentMonth(),
  year: getCurrentYear()
}

const MESSAGE = {
  SUCCESS: 'Clique aqui para enviar (XLSX).',
  ERROR: 'Somente arquivos em XLSX ',
  SUCCESS_SEND: 'Conciliação enviada com sucesso.'
}
const TYPE_FILE_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const TYPE_FILE_XLS = 'application/vnd.ms-excel'
const REMOVE_CHARACTERS =
  'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'

export {
  getMonthsUpToCurrent,
  yearsList,
  initialState,
  MESSAGE,
  TYPE_FILE_XLS,
  REMOVE_CHARACTERS,
  TYPE_FILE_XLSX,
  objResponse,
  nameAccordion,
  buttonsActions,
  textMessageConfirmedReporcessing,
  JANUARY
}
