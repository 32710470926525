import { useState } from 'react'
import { Box, TableRow, Tooltip } from '@mui/material'
import { formatDateTimePtBr } from 'services/helpers'
import * as Styled from './style'
import ModalWhatsApp from '../../ModalWhatsApp'

const TableFooterWhatsApp = ({ row }) => {
  const [openModalMessage, setOpenModalMessage] = useState(false)
  const showMessage = () => setOpenModalMessage(true)

  return (
    <>
      <TableRow hover tabIndex={-1} key={row.id}>
        <Styled.TableSentAt>
          <Box>{formatDateTimePtBr(row?.sent_at) || ''}</Box>
        </Styled.TableSentAt>

        <Styled.TableCellTitle>
          <Tooltip title="Visualizar mensagem" placement="top">
            <Box onClick={showMessage}>{row?.layout || ''}</Box>
          </Tooltip>
        </Styled.TableCellTitle>
      </TableRow>

      <ModalWhatsApp
        open={openModalMessage}
        handleClose={() => setOpenModalMessage(false)}
        message={row}
      />
    </>
  )
}
export { TableFooterWhatsApp }
