/* eslint-disable camelcase */
import { useContext, useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import GlobalContext from 'store/GlobalContext'
import { login, loginAutomaticToken } from 'services/api/shared'
import { ROLES, ROUTE } from 'services/constants'
import {
  removeUserFromLocalStorage,
  setUserInLocalStorage,
  removeClassesLocalStorage,
  removeVisibleUpdatePassword,
  removeSavedDataFromUrl
} from 'services/helpers'
import useMainRoute from './useMainRoute'
import {
  getSuccess,
  typeStudent,
  typeProfessor,
  messageErrorAdmin,
  adminAllowedAccess,
  adminAllowedAccessLegacy
} from './constants'

const useLogin = () => {
  const navigate = useNavigate()
  const { setUserInGlobalState, resetUserFromGlobalState } =
    useContext(GlobalContext)
  const { roles } = useMainRoute()
  const [isLoading, setIsLoading] = useState(false)
  const [serverMessage, setServerMessage] = useState('')
  const hasServerMessage = !!serverMessage
  const [response, setResponse] = useState('')

  const allRoles = [
    ROLES.STUDENT.name,
    ROLES.PROFESSOR.name,
    ROLES.ADMIN.name,
    ROLES.ACADEMIC.name
  ]

  const mapErrors = (errors) =>
    Object.keys(errors)?.map((key) => {
      if (typeof errors[key] === 'object') {
        return mapErrors(errors[key])
      }

      if (Array.isArray(errors[key])) {
        return mapErrors(errors[key][0])
      }

      return errors[key]
    })

  const redirectTermsEnrollment = () => {
    navigate(ROUTE.STUDENT_RE_ENROLLMENT, {
      state: { savedSearch: true }
    })
  }

  const redirectTermsContract = () => {
    navigate(ROUTE.STUDENT_CONTRACT, {
      state: { savedSearch: true }
    })
  }

  const handleLogin = async ({
    username,
    password,
    type,
    url,
    token,
    userType,
    path
  }) => {
    setIsLoading(true)

    const setServerMessageAndCleanup = (message) => {
      setServerMessage(message)
      setIsLoading(false)
      removeUserFromLocalStorage()
      resetUserFromGlobalState()
      removeClassesLocalStorage()
      removeSavedDataFromUrl()
      removeVisibleUpdatePassword()
    }

    const hastToken = (hasToken, data, status, error, messageError) => {
      const hasUserWithoutAccessPermission =
        data?.role === typeStudent || data?.role === typeProfessor

      if (
        !hasUserWithoutAccessPermission &&
        ![adminAllowedAccess, adminAllowedAccessLegacy].includes(path)
      ) {
        setServerMessageAndCleanup(messageError || messageErrorAdmin)
      }

      if (hasToken) {
        const user = { ...data, type, url }

        setUserInLocalStorage(user)
        setUserInGlobalState(user)

        if (user?.role === 'student' && user?.has_pending_re_enroll) {
          return redirectTermsEnrollment()
        }

        if (user?.role === 'student' && user?.has_pending_contract) {
          return redirectTermsContract()
        }
      }

      if (status !== getSuccess && error) {
        setIsLoading(false)
        setServerMessage(messageError || error?.message)
      }

      return setResponse(data)
    }

    if (userType && token) {
      const { data, status, error } = await loginAutomaticToken(token)
      const hasToken = !!data?.token
      const messageError = error && mapErrors(error)
      return hastToken(hasToken, data, status, error, messageError)
    }

    const { data, status, error } = await login({ username, password })
    const hasToken = !!data?.token
    const messageError = error && mapErrors(error)

    return hastToken(hasToken, data, status, error, messageError)
  }

  useEffect(() => {
    if (
      response?.role &&
      allRoles.includes(response.role) &&
      !response?.error
    ) {
      navigate(roles[response.role], { replace: true })
    }
  }, [response])

  return { hasServerMessage, isLoading, handleLogin, serverMessage }
}

export default useLogin
