const initialState = {
  body: '',
  name: '',
  level: '',
  header: '',
  footer: '',
  online: false,
  signature: '',
  check_date: false,
  face_to_face: true,
  sending_required: 0
}

const variablesSystem = [
  {
    label: 'Nome do Usuário',
    value: '#sistema_usuario'
  },
  {
    label: 'Data atual no formato escrito',
    value: '#data_completa'
  },
  {
    label: 'Data e Hora no formato (dd/mm/aaaa h:m:s)',
    value: '#data_hora'
  }
]

const variableUnit = [
  { label: 'Endereço/Rua da Unidade', value: '#unidade_endereco' },
  { label: 'Bairro da Unidade', value: '#unidade_bairro' },
  { label: 'Número do endereço da Unidade', value: '#unidade_num' },
  { label: 'Cidade da Unidade', value: '#unidade_cidade' },
  { label: 'Estado da Unidade', value: '#unidade_uf' },
  { label: 'CEP da Unidade', value: '#unidade_cep' },
  { label: 'Telefone Fixo da Unidade', value: '#unidade_telfixo' },
  { label: 'Celular da Unidade', value: '#unidade_celular' },
  { label: 'Email da Unidade', value: '#unidade_email' },
  { label: 'URL/WWW Site da Unidade', value: '#unidade_linksite' },
  { label: 'CNPJ da Unidade', value: '#unidade_cnpj' },
  { label: 'Razão Social da Unidade', value: '#unidade_razaosocial' },
  { label: 'Dados Resolução da Unidade', value: '#unidade_resolucao' },
  { label: 'Dados Credencial da Unidade', value: '#unidade_credencial' }
]

const variableStudent = [
  { label: 'Nome completo do Aluno', value: '#aluno_nome' },
  { label: 'Nome Social do Aluno', value: '#aluno_nome_social' },
  { label: 'Nome completo do Pai', value: '#aluno_pai' },
  { label: 'Nome completo da Mãe', value: '#aluno_mae' },
  { label: 'Data nascimento do Aluno', value: '#aluno_nascimento' },
  { label: 'Nacionalidade do Aluno', value: '#aluno_nacionalidade' },
  { label: 'Naturalidade do Aluno', value: '#aluno_naturalidade' },
  { label: 'Código da Matricula', value: '#aluno_matricula' },
  { label: 'Senha', value: '#aluno_senha' },
  { label: 'CPF do Aluno', value: '#aluno_cpf' },
  { label: 'RG / Identidade do Aluno', value: '#aluno_rg' },
  { label: 'Certificado Reservista', value: '#aluno_reservista' },
  { label: 'Título Eleitor', value: '#aluno_titulo' },
  { label: 'Zona Título Eleitor', value: '#aluno_titulo_zona' },
  { label: 'Seção Título Eleitor', value: '#aluno_titulo_secao' }
]

const variableClass = [
  { label: 'Nível do Curso', value: '#turma_nivel' },
  { label: 'Unidade do Curso', value: '#turma_unidade' },
  { label: 'Polo do Curso', value: '#curso_polo' },
  { label: 'Nome do Curso', value: '#turma_curso' },
  { label: 'Grupo da Turma', value: '#turma_grupo' },
  { label: 'Módulo da Turma', value: '#turma_modulo' },
  { label: 'Turno da Turma', value: '#turma_turno' },
  { label: 'Resolução de Autorização', value: '#resolucao' },
  { label: 'Data início da Turma', value: '#turma_inicio' },
  { label: 'Data final da Turma', value: '#turma_fim' },
  { label: 'Ano letivo da Turma', value: '#ano_letivo' },
  { label: 'Diretor da Unidade', value: '#direcao_nome' },
  { label: 'Secretária da Unidade', value: '#secretaria_nome' },
  { label: 'Diretor Assinatura Scanneada', value: '#direcao_scanner' },
  { label: 'Diretor Número Autorização', value: '#direcao_autorizacao' },
  { label: 'Secretária Assinatura Scanneada', value: '#secretaria_scanner' },
  { label: 'Secretária Número Autorização', value: '#secretaria_autorizacao' }
]

const variableRequirement = [
  { label: 'Data solicitação', value: '#data_solicitacao' },
  { label: 'Data situação', value: '#data_modificacao' },
  { label: 'Número Requerimento', value: '#numero_requerimento' },
  { label: 'Observação Inicial', value: '#observacaoinicial' },
  { label: 'Situação Requerimento', value: '#andamento' },
  { label: 'Tipo Requerimento', value: '#requerimento' }
]

const sections = [
  { title: 'Sistema', tags: [...variablesSystem] },
  { title: 'Unidade', tags: [...variableUnit] },
  { title: 'Aluno', tags: [...variableStudent] },
  { title: 'Turma', tags: [...variableClass] },
  { title: 'Requerimento', tags: [...variableRequirement] }
]

export { initialState, sections }
