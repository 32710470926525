import { httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const createSupplier = async (payload) => {
  const response = await httpPost(ENDPOINT.SUPPLIER.CRUD, payload)

  return response
}

export const updateSupplier = async (payload, id) => {
  const response = await httpPut(`${ENDPOINT.SUPPLIER.CRUD}/${id}`, payload)

  return response
}

export const searchSuppliers = async (params) => {
  const response = await httpGet(`${ENDPOINT.SUPPLIER.CRUD}?${params}`)

  return response
}

export const searchSupplierById = async (id) => {
  const response = await httpGet(`${ENDPOINT.SUPPLIER.CRUD}/${id}`)

  return response
}
