export const SEARCH_TYPE = {
  CNPJ: 'cnpj',
  REASON_SOCIAL: 'razao_social'
}

const typeSearchOptions = [
  {
    value: SEARCH_TYPE.CNPJ,
    label: 'CNPJ'
  },
  {
    value: SEARCH_TYPE.REASON_SOCIAL,
    label: 'Razão Social'
  }
]

const formModel = {
  searchType: {
    key: 'searchType',
    label: 'Selecione'
  },
  fieldValue: {
    key: 'fieldValue',
    getLalel: (searchType) => {
      const labels = {
        [SEARCH_TYPE.CNPJ]: 'Digite somente números',
        [SEARCH_TYPE.REASON_SOCIAL]: 'Digite o nome ou parte dele'
      }
      return labels[searchType]
    }
  },
  inactive: {
    key: 'inactive',
    label: 'Inativos'
  }
}

export { typeSearchOptions, formModel }
