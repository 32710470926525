import { useEffect, useState } from 'react'
import {
  getStudentsGradeActivity,
  getActivityStageList
} from 'services/api/professor'
import useCurrentUser from './useCurrentUser'

const useGetGrades = (activityTypeEnum, setisLoading) => {
  const [activity, setActivity] = useState([])
  const [studentsAll, setStudentsAll] = useState([])
  const [dropouts, setDropouts] = useState([])
  const [activityStageList, setActivityStageList] = useState([])

  const { professorId, classId, disciplineId } = useCurrentUser()

  const onHandleListClass = async () => {
    setisLoading(true)
    const convertByArray = JSON.parse(`[${disciplineId}]`)
    const params = {
      professorId,
      classCode: disciplineId,
      subgroup: activityTypeEnum,
      classId: Number(classId),
      disciplineId: convertByArray
    }

    const { data: response } = await getStudentsGradeActivity(params)
    setActivity(response?.activities)
    setStudentsAll(response?.students)
    setDropouts(response?.dropouts)
    setisLoading(false)
  }

  const handleStageList = async () => {
    const { data: response } = await getActivityStageList()
    setActivityStageList(response?.data[0])
  }

  useEffect(() => {
    handleStageList()
  }, [])

  useEffect(() => {
    onHandleListClass()
  }, [activityStageList])

  return {
    activity,
    studentsAll,
    activityStageList,
    onHandleListClass,
    dropouts
  }
}

export default useGetGrades
