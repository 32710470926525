import { convertDateToUTC } from 'services/helpers'

const { utilsPDF } = require('../../shared/utils')

const { borderLayoutDefault, paddingLayoutDefault, noBorderOnTop } = utilsPDF

export default function buildClassInfo({ classInfo, student }) {
  return {
    table: {
      body: [
        [
          {
            stack: [
              { text: 'Nome', bold: true },
              { text: student.student.name }
            ],
            border: noBorderOnTop,
            colSpan: 2
          },
          {},
          {
            stack: [
              { text: 'Matrícula', bold: true },
              { text: student.student.enrollment }
            ],
            border: noBorderOnTop
          },
          {
            stack: [{ text: 'Curso', bold: true }, { text: classInfo.course }],
            border: noBorderOnTop
          }
        ],
        [
          {
            stack: [
              { text: 'Unidade/Polo', bold: true },
              {
                text: `${classInfo.unit}${
                  classInfo.pole ? ` / ${classInfo.pole}` : ''
                }`
              }
            ]
          },
          {
            stack: [
              { text: 'Turma', bold: true },
              { text: classInfo.classroom }
            ]
          },
          {
            stack: [
              { text: 'Ano/Semestre', bold: true },
              {
                text: convertDateToUTC(classInfo.classStartDate).toLocaleString(
                  'pt-BR',
                  { month: '2-digit', year: 'numeric', day: '2-digit' }
                )
              }
            ]
          },
          {
            stack: [
              { text: 'Módulo/Série', bold: true },
              { text: classInfo.module ? `${classInfo.module}º` : 'Único' }
            ]
          }
        ]
      ],
      widths: '*'
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}
