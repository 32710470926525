import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import { Select } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import {
  currencyMaskWithoutPrefix,
  formatDate,
  formatDateTime
} from 'services/helpers'
import { reasonReplacements } from './constants'

import * as Styled from './style'
import { replacementType } from '../../constants'

const FormSchoolClassDatails = ({
  values,
  valuesForm,
  setFieldValue,
  hourlyLessonCost,
  setHourlyLessonCost
}) => (
  <>
    <Grid container spacing={{ xs: 0, sm: 2 }}>
      <Styled.Grid $margin="0 0 15px" item xs={12} sm={12} md={12}>
        <Typography variant="h5">Turma {valuesForm?.code}</Typography>
      </Styled.Grid>

      <Styled.Grid item xs={12} sm={12} md={4}>
        <Styled.Text>
          <b>Unidade</b>
          {valuesForm?.unit}
        </Styled.Text>
      </Styled.Grid>
      <Styled.Grid item xs={12} sm={12} md={4}>
        <Styled.Text>
          <b>Grupo</b> {valuesForm?.group}
        </Styled.Text>
      </Styled.Grid>
      <Styled.Grid item xs={12} sm={12} md={4}>
        <Styled.Text>
          <b>Modalidade</b> {valuesForm?.modality}
        </Styled.Text>
      </Styled.Grid>

      <Styled.Grid item xs={12} sm={12} md={4}>
        <Styled.Text>
          <b>Nível</b> {valuesForm?.level}
        </Styled.Text>
      </Styled.Grid>
      <Styled.Grid item xs={12} sm={12} md={4}>
        <Styled.Text>
          <b>Curso</b> {valuesForm?.name}
        </Styled.Text>
      </Styled.Grid>
      <Styled.Grid item xs={12} sm={12} md={4}>
        <Styled.Text>
          <b>Turno</b> {valuesForm?.shift}
        </Styled.Text>
      </Styled.Grid>

      <Styled.Grid item xs={12} sm={12} md={4}>
        <Styled.Text>
          <b>Início</b> {formatDate(valuesForm?.begin_at)}
        </Styled.Text>
      </Styled.Grid>
      <Styled.Grid item xs={12} sm={12} md={4}>
        <Styled.Text>
          <b>Fim</b> {formatDate(valuesForm?.end_at)}
        </Styled.Text>
      </Styled.Grid>

      <Styled.Grid item xs={12} sm={12} md={12} $margin="15px 0 12px">
        <Styled.Text>
          <b>Substituição</b>
        </Styled.Text>
      </Styled.Grid>

      <Styled.Grid
        item
        xs={12}
        sm={12}
        md={12}
        $padding="0 0 0 16px !important"
      >
        <RadioGroup
          row
          value={values?.replacement}
          onChange={(e) => {
            setFieldValue('replacement', e.target.value)
            setFieldValue('startDate', '')
            setFieldValue('endDate', '')
          }}
        >
          <FormControlLabel
            value="permanent"
            label="Permanente"
            control={<Radio size="small" />}
          />
          <FormControlLabel
            value="temporary"
            label="Temporária"
            control={<Radio size="small" />}
          />
        </RadioGroup>
      </Styled.Grid>

      <Styled.Grid item xs={12} sm={12} md={6}>
        <Select
          id="reasonReplacement"
          name="reasonReplacement"
          label="Motivo de substituição*"
          optionsList={reasonReplacements}
          value={values?.reasonReplacement || ''}
          onChange={(e) => {
            setFieldValue('reasonReplacement', e.target.value)
          }}
        />
      </Styled.Grid>
      {values?.replacement !== replacementType && (
        <>
          <Styled.Grid item xs={12} sm={12} md={3}>
            <DatePicker
              fullWidth
              id="startDate"
              label="Início*"
              value={values?.startDate || null}
              onChange={(newValue) => {
                setFieldValue('startDate', newValue)
              }}
            />
          </Styled.Grid>

          <Styled.Grid item xs={12} sm={12} md={3}>
            <DatePicker
              fullWidth
              id="endDate"
              label="Fim*"
              value={values?.endDate || null}
              onChange={(newValue) => {
                setFieldValue('endDate', newValue)
              }}
            />
          </Styled.Grid>
        </>
      )}

      <Styled.Grid item xs={12} sm={12} md={12}>
        <Styled.Text>
          <b>Valor hora-aula</b>
        </Styled.Text>
      </Styled.Grid>
      <Styled.Grid item xs={12} sm={12} md={8}>
        Informe o valor hora-aula para as disciplinas selecionadas:
        <Styled.TextField
          fullWidth
          id="formatted-numberformat-input"
          name="value"
          onChange={(e) => {
            setHourlyLessonCost(e.target.value)
          }}
          InputProps={{
            value: currencyMaskWithoutPrefix(hourlyLessonCost || ''),
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          isMargin="0"
        />
      </Styled.Grid>
    </Grid>
  </>
)

export { FormSchoolClassDatails }
