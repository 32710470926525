import { lazy, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form as FormikForm, Formik } from 'formik'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { createOccurrenceTypes, updateOccurrenceType } from 'services/api/admin'
import { breadCrumbsItems, initialValues } from './constant'
import { getListEvents, handleGetEdit, listSelectCategories } from '../services'
import { payload, savedSuccessfully } from './helpers'
import * as Styled from './style'

const Form = lazy(() => import('./Form'))

const CreateByUpdateOccurrenceTypes = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const snackProps = useSnack()
  const [openModal, setOpenModal] = useState(false)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [listCategories, setListCategories] = useState([])
  const [listEvents, setListEvents] = useState([])
  const [checked, setChecked] = useState([])
  const [initialState, setInitialState] = useState(initialValues)

  const params = Object.fromEntries([...searchParams])
  const isEdit = params?.isEdit
  const nameTypeEdit = params?.name
  const idType = params?.id

  const { setSnack } = snackProps

  const handleOnSubmit = async (values) => {
    setLoadingOpen(true)
    setSnack('')

    if (isEdit) {
      const { error, status } = await updateOccurrenceType(
        idType,
        payload({ values, checked })
      )

      return savedSuccessfully({
        isEdit,
        setLoadingOpen,
        setSnack,
        error,
        navigate,
        status
      })
    }

    const { error, status } = await createOccurrenceTypes(
      payload({ values, checked })
    )

    return savedSuccessfully({
      isEdit,
      setLoadingOpen,
      setSnack,
      error,
      navigate,
      status
    })
  }

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(ROUTE.ADMIN_MANAGEMENT_OCCURRENCE, {
      state: { savedSearch: true }
    })
  }

  useEffect(() => {
    if (isEdit) {
      handleGetEdit({
        idType,
        listEvents,
        setSnack,
        setChecked,
        setLoadingOpen,
        setInitialState
      })
    }
  }, [listEvents?.length])

  useEffect(() => {
    setChecked([])
    listSelectCategories({ setSnack, setListCategories })
    getListEvents({ setSnack, setListEvents })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loadingOpen}
      snackProps={snackProps}
      breadcrumbsNotLink={breadCrumbsItems({ isEdit, nameTypeEdit })}
      onClickButton={() => setOpenModal(true)}
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      content={
        <Styled.FormSection>
          <Formik
            initialValues={initialState}
            onSubmit={handleOnSubmit}
            enableReinitialize
          >
            {(props) => (
              <FormikForm
                onSubmit={props.handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Form
                  {...props}
                  setSnack={setSnack}
                  listEvents={listEvents}
                  checked={checked}
                  setChecked={setChecked}
                  categories={listCategories}
                />
              </FormikForm>
            )}
          </Formik>
        </Styled.FormSection>
      }
    />
  )
}

export default CreateByUpdateOccurrenceTypes
