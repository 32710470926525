import styled from 'styled-components'
import { Accordion as MuiAccordion, Chip as MuiChip, Grid } from '@mui/material'
import { Button } from 'components/atoms'

const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    margin: 0;

    &:before {
      display: none;
    }

    &.Mui-expanded {
      min-height: 0;
      margin: 0 0;
    }
  }
`

const Chip = styled(MuiChip)`
  &.MuiChip-root {
    font-weight: bold;
  }
`

const ButtonSearch = styled(Button)`
  &.MuiButton-root {
    margin: 0 0 0 16px;
  }
`

const ButtonClear = styled(Button)`
  &.MuiButton-root {
    margin: 10px 0;
  }
`

const Checkbox = styled.div`
  margin-top: 8px;
`

const Switch = styled.div`
  margin-top: 4px;
`

const GroupSwitch = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;

    @media (max-width: 769px) {
      flex-wrap: wrap;
    }
  }
`

export {
  Accordion,
  ButtonSearch,
  ButtonClear,
  Chip,
  Checkbox,
  Switch,
  GroupSwitch
}
