import { ROUTE } from 'services/constants'

const breadcrumbsItems = (title) => [
  {
    path: ROUTE.ADMIN_MANAGEMENT_TYPE_ACTIVITY,
    label: 'Gestão'
  },
  {
    path: ROUTE.ADMIN_MANAGEMENT_TYPE_ACTIVITY,
    label: 'Tipos de Atividade'
  },
  {
    path: ROUTE.ADMIN_MANAGEMENT_TYPE_ACTIVITY_CREATE,
    label: `<b> ${title} </b>`
  }
]

const initialState = {
  name: '',
  unit: '',
  level: '',
  course: '',
  workload: 0,
  is_portal: false,
  considerations: '',
  is_intership: false
}

export { breadcrumbsItems, initialState }
