import styled from 'styled-components'

import {
  TextField as MuiTextField,
  InputLabel as MuiInputLabel
} from '@mui/material'

const Container = styled.div`
  display: flex;
`

const InputLabel = styled(MuiInputLabel)`
  &.MuiInputLabel-root {
    margin-top: 16px;
    padding-right: 8px;
  }
`

const TextField = styled(MuiTextField)`
  &.MuiTextField-root {
    width: 150px;
  }
`

export { Container, InputLabel, TextField }
