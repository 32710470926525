import styled from 'styled-components'
import { Tooltip as MuiTooltip } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'
import { indexs } from 'services/constants'
import theme from 'theme/designTokens'

const Container = styled.div`
  width: ${({ width }) => width ?? '926px'};
  height: ${({ height }) => height ?? '302px'};
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  z-index: ${({ isHighlight }) =>
    isHighlight ? indexs.highlight : indexs.default};
`

const Step = styled.div`
  min-width: 100%;
  height: 100%;
  display: ${({ shouldShow }) => (shouldShow ? 'block' : 'none')};
`

const Dots = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 16px 0;
  bottom: 0;
  position: absolute;
`

const Dot = styled.div`
  background-color: ${({ isSelected }) =>
    isSelected ? theme.palette.primary.main : 'none'};
  border: 2px solid ${() => theme.palette.primary.main};
  border-radius: 6.25px;
  width: 12.5px;
  height: 12.5px;
  cursor: pointer;
`

const Arrows = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ isFirstStepIndex }) =>
    isFirstStepIndex ? 'flex-end' : 'space-between'};
  padding: 0 30px;
  box-sizing: border-box;
`

const Arrow = styled.div`
  width: 40px;
  height: 40px;
  font-size: 36px;
  user-select: none;
  background-color: ${() => theme.palette.gray.light};
  transition: background-color 0.3s;
  border-radius: 20px;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-align: center;
  cursor: pointer;

  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
`

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main
  }
})

export { Container, Step, Dots, Dot, Arrows, Arrow, Tooltip }
