const SYSTEM = {
  SECTORS: {
    LIST_RESPONSIBLES: 'sector/responsibles',
    LIST: 'sector/paginate',
    CRUD: 'sector'
  },
  DOCUMENT_TYPES: {
    CRUD: 'documents',
    LIST: 'documents/paginate'
  },
  DECLARATIONS: {
    BASE_DECLARATIONS: 'declarations'
  },
  CONTRACTS_TYPES: {
    BASE_CONTRACTS_TYPES: 'contracts/types'
  },
  IMPORTS: {
    CRUD: 'imports'
  },
}

export { SYSTEM }
