import FEEDBACK_SNACK from 'feedBackSnack'
import { toastNotificationSuccess, updateErrorMessage } from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'
import {
  deleteJustifyAbsence,
  getJustifyAbsence,
  postJustifyAbsence
} from 'services/api/admin'
import { defaultItemsPerPage, defaultPage } from './constants'

export const getListJustifyAbsence = async ({
  filter,
  setCount,
  setSnack,
  studentId,
  pageActual,
  setAbsence,
  setLoadingOpen
}) => {
  setLoadingOpen(true)
  const params = cleanObject({
    register: studentId,
    page: pageActual || defaultPage,
    limit: filter || defaultItemsPerPage
  })

  const { data, error, status } = await getJustifyAbsence(params)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToForm
    })
  }
  setCount(data?.count)

  return setAbsence(data?.data)
}

export const createJustifyAbsence = async ({
  values,
  setIsUpdate,
  setOpenModal,
  setLoadingOpen
}) => {
  setLoadingOpen(true)
  const payload = cleanObject({
    file: values.file,
    course: values.course,
    end_date: values.endDate,
    unit_name: values.unitName,
    start_date: values.startDate,
    register: parseFloat(values.register),
    reason_absence: values.reasonForAbsence,
    school_class_code: values.schoolClassCode,
    school_class_id: parseFloat(values.schoolClassId)
  })

  const { error, status } = await postJustifyAbsence(payload)
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListRegisterEadContent
    })
  }

  setOpenModal(false)
  toastNotificationSuccess('Justificativa salva com sucesso.', 1200)

  return setIsUpdate((prev) => !prev)
}

export const removeJustifyAbsence = async ({
  studentId,
  setIsUpdate,
  setLoadingOpen,
  setOpenModalConfirm
}) => {
  setLoadingOpen(true)

  const { error, status } = await deleteJustifyAbsence(studentId)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditTypesActivitys
    })
  }

  setOpenModalConfirm(false)
  toastNotificationSuccess('Justificativa excluída com sucesso.', 1200)
  return setIsUpdate((prev) => !prev)
}
