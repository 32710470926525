import styled from 'styled-components'
import { Grid, Typography } from '@mui/material'

const GridHeaderModal = styled(Grid)`
  display: flex;
  justify-content: space-between;
`

const TypographyHeaderModal = styled(Typography)`
  padding: 13px;
`

const IconClose = styled(Typography)`
  padding: 13px;
  cursor: pointer;
`

export { GridHeaderModal, TypographyHeaderModal, IconClose }
