import { STUDENT_DISCIPLINE_SITUATION } from 'components/templates/PDF/SchoolReport/General/Table/Body'
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { addAsteristic } = utilsPDF

const getFinalResult = ({ approved, situation, endDisciplineYear }) => {
  if (
    [situation].includes(
      STUDENT_DISCIPLINE_SITUATION.STUDYING,
      STUDENT_DISCIPLINE_SITUATION.TO_ATTEND
    ) ||
    !endDisciplineYear
  ) {
    return 'A cursar'
  }

  return approved ? 'Aprovado' : 'Reprovado'
}

const handleGrade = ({ grade, hasAcademicPerformance }) => {
  if (grade) {
    const gradeFixed = grade.toFixed(2).replace('.', ',')
    return hasAcademicPerformance ? addAsteristic(gradeFixed) : gradeFixed
  }

  return '0,0'
}

export function buildTableBody({
  gradeDetails,
  subjects,
  classInfo,
  courseInfo
}) {
  const subjectsMap = new Map()
  subjects.forEach((subject) => subjectsMap.set(subject.subjectId, subject))
  return [
    ...gradeDetails.map((gradeDetail, index) => {
      const { discipline, grade, student, frequency } = gradeDetail
      const endDisciplineYear = subjectsMap.get(discipline.subjectId).endAt

      const amountAbsenceInHours =
        classInfo.classTime === 60
          ? frequency.absence
          : Math.round(frequency.absence * (classInfo.classTime / 60) * 100) /
            100

      return [
        { text: String(index + 1).padStart(3, '0'), alignment: 'center' },
        {
          text: discipline.beginAt
            ? new Date(discipline.beginAt).getFullYear()
            : '',
          alignment: 'center'
        },
        { text: discipline.name },
        {
          text: handleGrade({
            grade: grade.finalGrade,
            hasAcademicPerformance: student.hasAcademicPerformance
          }),
          alignment: 'center'
        },
        { text: discipline.workload, alignment: 'center' },
        { text: amountAbsenceInHours, alignment: 'center' },
        {
          text: getFinalResult({
            approved: student.approved,
            situation: student.situation,
            endDisciplineYear
          }),
          alignment: 'center'
        }
      ]
    }),
    [
      {
        text: [
          { text: 'Carga Horária total no curso ', bold: true },
          `${
            (courseInfo.practicalWorkload ?? 0) +
            (courseInfo.theoreticalWorkload ?? 0)
          } horas`
        ],
        colSpan: 7
      }
    ]
  ]
}
