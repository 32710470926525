import { Formik } from 'formik'
import { FormControlLabel, Grid, InputAdornment, Radio } from '@mui/material'

import { typeMask } from 'services/helpers'
import { TextField } from 'components/molecules'
import { Button, Modal } from 'components/atoms'

import { formatInitialValues } from './helper'
import { typeRate, initialValues as defaultValues } from './constants'

import * as Styled from './style'

const RateModal = ({ open, handleClose, onSubmitForm, item }) => {
  const { deferred, rejected } = typeRate

  const disabledButton = ({ status, workload, observation }) =>
    !status ||
    (!Number(workload) && status === deferred.value) ||
    (!observation && status === rejected.value)

  const disabledWorkload = ({ status }) => status !== deferred.value

  const initialValues = formatInitialValues(item) ?? defaultValues

  return (
    <Modal open={open} onClose={handleClose}>
      <Formik initialValues={initialValues} onSubmit={onSubmitForm}>
        {({
          handleSubmit,
          values,
          handleBlur,
          submitForm,
          handleChange,
          setFieldValue
        }) => (
          <Styled.FormBox onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.CardContainer container spacing={2}>
              <Grid item xs={12}>
                <Styled.Title>Confirmação de análise</Styled.Title>
                <Styled.SubTitle>
                  Registre os detalhes da sua avaliação
                </Styled.SubTitle>
              </Grid>

              <Grid item xs={7}>
                <Styled.RadioGroup
                  row
                  name="status"
                  value={values?.status || ''}
                  onChange={(e) => {
                    if (values?.status !== rejected.value) {
                      setFieldValue('workload', '0')
                    }
                    setFieldValue('status', e.target.value)
                  }}
                >
                  <FormControlLabel
                    value={deferred.value}
                    control={<Radio size="small" />}
                    label={deferred.label}
                  />
                  <FormControlLabel
                    value={rejected.value}
                    control={<Radio size="small" />}
                    label={rejected.label}
                  />
                </Styled.RadioGroup>
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label={`Carga horária${
                    values?.status === deferred.value ? '*' : ''
                  }`}
                  name="workload"
                  value={values?.workload}
                  onChange={handleChange}
                  disabled={disabledWorkload(values)}
                  InputProps={{
                    value: typeMask('NUMBER', values?.workload) || '',
                    endAdornment: (
                      <InputAdornment position="end">horas</InputAdornment>
                    )
                  }}
                  fullWidth
                />
              </Grid>

              <Styled.Observation item xs={12}>
                <TextField
                  label={`Observações / parecer${
                    values?.status === rejected.value ? '*' : ' (opcional)'
                  }`}
                  name="observation"
                  value={values?.observation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={3}
                  multiline
                  fullWidth
                />
                <Styled.Description>
                  Este texto será enviado para o Portal do Aluno
                </Styled.Description>
              </Styled.Observation>

              <Styled.WrapperButtons item xs={12}>
                <Button variant="outlined" onclick={() => handleClose()}>
                  Cancelar
                </Button>
                <Button disabled={disabledButton(values)} onClick={submitForm}>
                  Salvar
                </Button>
              </Styled.WrapperButtons>
            </Styled.CardContainer>
          </Styled.FormBox>
        )}
      </Formik>
    </Modal>
  )
}

export default RateModal
