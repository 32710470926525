import styled from 'styled-components'
import { Button as CompButton } from 'components/atoms'

const Button = styled(CompButton)`
  &.MuiButtonBase-root {
    margin: 60px 0 0;
  }
`

export { Button }
