export const textColors = {
  textColors: {
    primary: {
      mediumGray: '#4E4B59',
      lightGray: '#5F5C6B',
      darkGray: '#33303E',
    },
    error: '#F44336',
    warning: '#ed6c02',
    secondary: {
      mediumGray: '#f0f1f9',
      lightBlue: '#e9f2f9',
      darkBlue: '#2096f3',
    },
    black: {
      darkBlack: '#000',
      textLightPrimary: '#000000de',
      textLightSecondary: '#00000099',
    },
  },
}
