export const mergeInitialValues = (initialValues, savedValues) => {
  if (savedValues) {
    return {
      ...initialValues,
      ...savedValues
    }
  }

  return initialValues
}

