import styled from 'styled-components'
import {
  Toolbar as MuiToolbar,
  Paper as MuiPaper,
  TableContainer as MuiTableContainer,
  Typography as MuiTypography
} from '@mui/material'

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
  }
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    margin-top: 24px;
  }
`

const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    margin-top: ${({ $isSelected }) => ($isSelected ? '5px' : '24px')};
  }
`

const Toolbar = styled(MuiToolbar)`
  &.MuiToolbar-root {
    padding: 0;
    margin-top: 24px;
    align-items: center;
    display: ${({ $displayNone }) => ($displayNone ? 'none' : 'flex')};
  }
`

export { Paper, TableContainer, Toolbar, Typography }
