import { createContext, useState, useContext } from 'react'

const initialState = { loading: false, setLoading: () => {} }

export const LoadingContext = createContext(initialState)
export const LoadingProvider = ({ children }) => {
  const [loading, setIsLoading] = useState(false)
  const [_, setCounterLoadings] = useState(0)

  const setLoading = (booleanParam) => {
    setCounterLoadings((state) => {
      const currentState = booleanParam ? state + 1 : Math.max(state - 1, 0)
      setIsLoading(currentState > 0)
      return currentState
    })
  }

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

export const useLoading = () => useContext(LoadingContext)
