import { lazy, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'components/atoms'
import { getStudentDetailsFinances } from 'services/api/student'
import { sendPendingBillingEmail } from 'services/api/admin'
import { ERROR, ROUTE, SUCCESS } from 'services/constants'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  convertToParams,
  getErrorMessage,
  updateErrorMessage
} from 'services/helpers'
import * as Styled from './style'

const ERROR_MESSAGE_FINANCIAL =
  'Erro ao carregar Detalhamento financeiro, tente novamente...'

const SUCCESS_MESSAGE_SEND_BILLING = 'Boleto enviado com sucesso!'

const Table = lazy(() => import('./Table'))

const ModalSummaryOpenTickets = (props) => {
  const { open, handleClose, register, name, setLoading, setSnack } = props
  const [rows, setRows] = useState([])

  const onClose = () => {
    setRows([])
    if (handleClose) handleClose()
  }

  const loadTickets = async () => {
    if (!register || !open) return

    setSnack('')
    setLoading(true)
    const { data, error, status } = await getStudentDetailsFinances({
      id: register
    })
    setLoading(false)
    if (error) {
      const message = getErrorMessage(ERROR_MESSAGE_FINANCIAL, status)
      setSnack(message, ERROR)
    } else {
      setRows(data?.data)
    }
  }

  const sendToEmail = async (billingId) => {
    setSnack('')
    setLoading(true)
    const { error, status } = await sendPendingBillingEmail(billingId)
    setLoading(false)
    if (error) {
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToSendPendingBillingEmail
      })
    } else {
      setSnack(SUCCESS_MESSAGE_SEND_BILLING, SUCCESS)
    }
  }

  useEffect(() => {
    loadTickets()
  }, [register, open])

  return (
    <Modal open={open} onClose={onClose} fullWidth maxWidth="md">
      <Styled.Card>
        <Styled.Title>Boletos em aberto</Styled.Title>

        <Table rows={rows} sendToEmail={sendToEmail} {...props} />

        <Styled.CardLink>
          <Link
            to={`${ROUTE.ADMIN_FINANCIAL_BREAKDOWN}?${convertToParams({
              register,
              name,
              isCnpj: false,
              hasPage: 'student_summary_tickets'
            })}`}
          >
            Detalhamento financeiro completo
          </Link>
        </Styled.CardLink>

        <Styled.CardFooter>
          <Button onClick={onClose} variant="contained">
            Fechar
          </Button>
        </Styled.CardFooter>
      </Styled.Card>
    </Modal>
  )
}

export default ModalSummaryOpenTickets
