const convertToParams = (data) => data && new URLSearchParams(data).toString()

const convertToParamsArrayString = (data, name) => {
  const newArray = data?.map((obj) =>
    typeof obj === 'number' ? obj : obj.value
  )

  return `${name}[]=${newArray?.join(`&${name}[]=`)}` || []
}

export { convertToParams, convertToParamsArrayString }
