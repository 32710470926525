import styled from 'styled-components'
import { Typography } from '@mui/material'

const Container = styled.div`
  max-width: 100% !important;
  max-height: 90vh !important;
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.background.white};
`

const Header = styled.div`
  margin-bottom: 16px;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

const IconText = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`

export { Container, Header, Title, IconText }
