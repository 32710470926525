export const civilStatusList = [
  { value: 1, name: 'Solteiro(a)' },
  { value: 2, name: 'Casado(a)' },
  { value: 3, name: 'Separado(a)' },
  { value: 4, name: 'Divorciado(a)' },
  { value: 5, name: 'Viúvo(a)' },
]

export const stateList = [
  { value: 1, name: 'Acre' },
  { value: 2, name: 'Alagoas' },
  { value: 3, name: 'Amapá' },
  { value: 4, name: 'Amazonas' },
  { value: 5, name: 'Bahia' },
  { value: 6, name: 'Ceará' },
  { value: 7, name: 'Distrito Federal' },
  { value: 8, name: 'Espirito Santo' },
  { value: 9, name: 'Goiás' },
  { value: 10, name: 'Maranhão' },
  { value: 11, name: 'Mato Grosso' },
  { value: 12, name: 'Mato Grosso do Sul' },
  { value: 13, name: 'Minas Gerais' },
  { value: 14, name: 'Pará' },
  { value: 15, name: 'Paraíba' },
  { value: 16, name: 'Paraná' },
  { value: 17, name: 'Pernambuco' },
  { value: 18, name: 'Piauí' },
  { value: 19, name: 'Rio de Janeiro' },
  { value: 20, name: 'Rio Grande do Norte' },
  { value: 21, name: 'Rio Grande do Sul' },
  { value: 22, name: 'Rondônia' },
  { value: 23, name: 'Roraima' },
  { value: 24, name: 'Santa Catarina' },
  { value: 25, name: 'São Paulo' },
  { value: 26, name: 'Sergipe' },
  { value: 27, name: 'Tocantins' },
]
