const isExistingEnrollment = ({ isOpen, enrollments, setListUnifyUsers }) => {
  if (isOpen.listSelectUsers && !isOpen.DisplayAll) {
    setListUnifyUsers((elem) =>
      elem.concat(
        enrollments.filter(
          (selectedUser) =>
            !elem.some(
              (existingUser) => existingUser.register === selectedUser.register
            )
        )
      )
    )
  }
}

const handleChangeInitPage = ({
  setIsOpen,
  setSelectRegistrationMerge,
  setPageFinally,
  setEnrollmentList,
  setStatus
}) => {
  setIsOpen({ listSelectUsers: false, listSearch: true, DisplayAll: false })
  setSelectRegistrationMerge([])
  setPageFinally({
    state: false
  })
  setStatus({
    empty: true,
    notFound: false
  })
  setEnrollmentList([])
}

export { isExistingEnrollment, handleChangeInitPage }
