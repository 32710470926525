import { Grid } from '@mui/material'
import { Button, InputQuill } from 'components/atoms'
import * as Styled from './style'

const ModalWhatsApp = ({ handleClose, open, message }) => (
  <Styled.Modal open={open} onClose={handleClose}>
    <Styled.Grid>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Styled.IconText>
            <Styled.Title variant="h5">WhatsApp Recebido</Styled.Title>
          </Styled.IconText>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Styled.TextField
            label="Assunto"
            fullWidth
            id="subject"
            value={message.layout}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <InputQuill values={message.content} label="Mensagem" disabled hideToolbar />
        </Grid>
      </Grid>

      <Styled.Footer>
        <Button variant="outlined" onclick={handleClose}>
          Cancelar
        </Button>
      </Styled.Footer>
    </Styled.Grid>
  </Styled.Modal>
)

export default ModalWhatsApp
