import styled from 'styled-components'
import { Grid as MuiGrid, Box } from '@mui/material'
import { PictureAsPdf as MuiPictureAsPdf } from '@mui/icons-material'
import { ButtonFooterV2 } from 'components/molecules'

const Grid = styled(MuiGrid)`
  &.MuiGrid-container {
    margin-top: 25px;
  }
`

const Button = styled(ButtonFooterV2)`
  &.MuiButtonBase-root {
    min-width: 42px;
    margin: 12px 0 0 16px;
  }
`

const PictureAsPdf = styled(MuiPictureAsPdf)`
  color: ${({ theme }) => theme.palette.yellow.warning};
`

const BoxDownload = styled(Box)`
  cursor: pointer;
  padding-left: 4px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.yellow.warning};
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.yellow.warning};
  border-radius: 4px;
`

export { Grid, Button, PictureAsPdf, BoxDownload }
