import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Grid, InputAdornment, Typography } from '@mui/material'

import { useSnack } from 'services/hooks'
import { Button, Modal } from 'components/atoms'
import { InputFile, Select, TextField } from 'components/molecules'

import { getTypeActivities } from '../../services'

import { initialValues } from './constants'

import * as Styled from './style'

const NewActivityModal = ({
  classNameModal,
  open,
  handleClose,
  labelContent,
  type,
  onSubmitForm,
  register,
  course,
  schoolClassCode,
  schoolClassId
}) => {
  const [typeActivities, setTypeActivities] = useState([])

  const { setSnack } = useSnack()

  const hasError = (values, touched, fieldName) =>
    !values?.[fieldName] && !!touched?.[fieldName]

  const onSelectFile = (e, setFieldValue) => {
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        setFieldValue('file', reader.result.toString() || '')
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const disabledButton = ({ classId, activityType, file }) =>
    !classId || !activityType || !file

  const courseAndClass = [
    {
      label: `${course} - ${schoolClassCode}`,
      value: schoolClassId
    }
  ]

  useEffect(() => {
    if (schoolClassId) {
      getTypeActivities({
        setSnack,
        setTypeActivities,
        schoolClassId
      })
    }
  }, [schoolClassId])

  const getWorkloadByActivity = ({ activityType }) =>
    typeActivities.find(({ value }) => value === activityType)?.workload

  return (
    <Modal
      className={classNameModal}
      open={open}
      onClose={handleClose}
      maxWidth="md"
    >
      <Formik
        initialValues={initialValues({
          schoolClassId,
          register
        })}
        onSubmit={onSubmitForm}
      >
        {({
          touched,
          handleSubmit,
          values,
          handleBlur,
          submitForm,
          handleChange,
          setFieldValue
        }) => (
          <Styled.FormBox onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.CardContainer container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Enviar para análise</Typography>
              </Grid>

              <Grid item xs={12}>
                <Select
                  label="Curso/Turma*"
                  name="classId"
                  value={values?.classId}
                  optionsList={courseAndClass}
                  onChange={handleChange}
                  disabled
                  helperText={
                    hasError(values, touched, 'course') && 'Campo obrigatório'
                  }
                  error={hasError(values, touched, 'course')}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={8}>
                <Select
                  label="Tipo de Atividade*"
                  name="activityType"
                  value={values?.activityType}
                  optionsList={typeActivities}
                  onChange={handleChange}
                  helperText={
                    hasError(values, touched, 'activityType') &&
                    'Campo obrigatório'
                  }
                  error={hasError(values, touched, 'activityType')}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Carga horária prevista"
                  name="workload"
                  value={getWorkloadByActivity(values) ?? ''}
                  onChange={handleChange}
                  fullWidth
                  disabled
                  InputProps={{
                    endAdornment: !!getWorkloadByActivity(values) && (
                      <InputAdornment position="end">horas</InputAdornment>
                    )
                  }}
                />
              </Grid>

              <Grid item xs={8}>
                <TextField
                  label="Observações"
                  name="observation"
                  value={values?.observation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={7}
                  multiline
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <InputFile
                  idUpload="upload"
                  onChange={(e) => onSelectFile(e, setFieldValue)}
                  typeFile={type}
                  labelBox={labelContent}
                  imgSrc={values?.file}
                  hasSize
                  width="auto"
                />
              </Grid>

              <Styled.WrapperButtons item xs={12}>
                <Button variant="outlined" onclick={() => handleClose()}>
                  Cancelar
                </Button>
                <Button
                  disabled={disabledButton(values)}
                  onClick={() => submitForm()}
                >
                  Enviar
                </Button>
              </Styled.WrapperButtons>
            </Styled.CardContainer>
          </Styled.FormBox>
        )}
      </Formik>
    </Modal>
  )
}

export default NewActivityModal
