import React, { useEffect, useState } from 'react'
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Tooltip
} from '@mui/material'
import { TextField, Select } from 'components/molecules'
import { Button, DatePicker } from 'components/atoms'
import {
  MAX_LENGTH_PHONE_MOBILE,
  MAX_LENGTH_RG
} from 'components/pages/Admin/Commercial/RegistrationNewEnrollment/constants'
import { typeMask } from 'services/helpers/mask'
import { UF_STATE_BRAZIL } from 'services/helpers/statesBrazil'
import { MAX_LENGTH_PHONE } from 'services/constants'
import { getStudentsDirectCpf } from 'services/api/admin/comercial/courses'
import TAG_MANAGER from 'tagManager'
import { useAddress } from 'services/hooks'
import { messageErroOrSuccess } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getCityByState, getStates } from 'services/api/shared'
import { civilStatusList, MAX_LENGTH_CEP, MAX_LENGTH_CPF } from './constants'
import * as Styled from './style'
import { cepValid } from '../../../constants'

const SecondStep = ({ formik, setSnack, isValidCpf, setIsValidCpf }) => {
  const [citys, setCitys] = useState([])
  const [states, setStates] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [naturalityCitys, setNaturalityCitys] = useState([])
  const [selectedState, setSelectedState] = useState(formik.values.uf)
  const [address, setAddress] = useState({
    district: '',
    address: '',
    city: '',
    state: ''
  })
  const [naturalityUf, setNaturalityUf] = useState(formik.values.naturalidade)

  const { fetchAddress } = useAddress({ setAddress })

  const validateCpf = async () => {
    setIsLoad(true)
    const params = {
      cpf: formik.values.cpf.replace(/\D/g, '')
    }
    const { error, status } = await getStudentsDirectCpf(params)
    if (!error) {
      setIsLoad(false)

      setIsValidCpf(true)
    } else {
      setIsLoad(false)
      setShowError(!!error)
      messageErroOrSuccess({
        error,
        status,
        setSnack,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreatePreEnrollment
      })
    }
  }

  useEffect(() => {
    setShowError('')
    setErrorMessage('')
  }, [formik.values.cpf])

  useEffect(async () => {
    if (naturalityUf) {
      const cities = await getCityByState(naturalityUf)
      setNaturalityCitys(cities)
    }
  }, [naturalityUf])

  useEffect(async () => {
    if (selectedState) {
      const cities = await getCityByState(selectedState)
      setCitys(cities)
    }
  }, [selectedState])

  useEffect(() => {
    setSelectedState(formik.values.uf)
  }, [formik.values.uf])

  useEffect(() => {
    setNaturalityUf(formik.values.naturalidade)
  }, [formik.values.naturalidade])

  useEffect(async () => {
    const listStates = await getStates(selectedState)
    setStates(listStates)
  }, [])

  useEffect(() => {
    const { cep } = formik.values

    if (cep.length === cepValid) fetchAddress(cep)
  }, [formik.values.cep])

  useEffect(() => {
    formik.setFieldValue('bairro', address?.district)
    formik.setFieldValue('uf', address?.state)
    formik.setFieldValue('cidade', address?.city)
    formik.setFieldValue('endereco', address?.address)
  }, [address])

  return (
    <Grid justifyContent="center" container spacing={{ xs: 0, sm: 2 }}>
      <Styled.BoxHeader isLoad={isLoad}>
        <TextField
          id="cpf"
          fullWidth
          label="CPF*"
          name="cpf"
          onChange={(e) => {
            setIsLoad(false)
            setIsValidCpf(false)
            formik.handleChange(e)
          }}
          isMargin="8px 16px 8px 0"
          onBlur={formik.handleBlur}
          helperText={errorMessage}
          error={!isValidCpf && showError}
          value={typeMask('CPF', formik.values.cpf)}
          inputProps={{ maxLength: MAX_LENGTH_CPF }}
        />
        <Button
          size="large"
          loading={isLoad}
          value="Continuar"
          disabled={!formik.values.cpf || isLoad}
          onClick={() => validateCpf(formik.values.cpf)}
          className={TAG_MANAGER.btn_commercial_opens_registration_Maual}
        />
      </Styled.BoxHeader>
      {isValidCpf && (
        <Styled.BoxContainer>
          <div>
            <TextField
              fullWidth
              name="nome"
              label="Nome Completo*"
              onBlur={formik.handleBlur}
              value={formik.values.nome}
              onChange={formik.handleChange}
              helperText={formik.touched.nome && formik.errors.nome}
              error={formik.touched.nome && Boolean(formik.errors.nome)}
            />
            <TextField
              fullWidth
              name="nome_social"
              label="Nome Social (opcional)"
              onChange={formik.handleChange}
              value={formik.values.nome_social}
            />
            <Styled.BoxCard>
              <TextField
                fullWidth
                name="rg"
                label="RG"
                value={formik.values.rg}
                onChange={formik.handleChange}
                inputProps={{
                  maxLength: MAX_LENGTH_RG
                }}
              />
              <DatePicker
                fullWidth
                id="nascimento"
                name="nascimento"
                label="Nascimento"
                value={formik.values.nascimento || null}
                onChange={(newValue) => {
                  formik.setFieldValue('nascimento', newValue)
                }}
              />
            </Styled.BoxCard>
            <TextField
              fullWidth
              name="email"
              type="email"
              label="E-mail"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <Styled.BoxCard>
              <TextField
                fullWidth
                name="cep"
                label="CEP"
                onBlur={(e) => {
                  formik.handleBlur(e)
                }}
                onChange={formik.handleChange}
                value={typeMask('CEP', formik.values.cep)}
                inputProps={{
                  maxLength: MAX_LENGTH_CEP
                }}
              />
              <Select
                id="uf"
                name="uf"
                optionsList={states}
                labelId="select-state"
                value={formik.values.uf}
                onChange={(e) => {
                  formik.handleChange(e)
                  formik.setFieldValue('uf', e.target.value)
                }}
                label="Estado"
              />
            </Styled.BoxCard>
            <Select
              id="cidade"
              name="cidade"
              label="Cidade"
              labelId="select-cidade"
              value={formik.values.cidade}
              optionsList={citys}
              disabled={!formik.values.uf}
              onChange={formik.handleChange}
              isMargin="0 0 16px"
            />
            <Styled.BoxCard>
              <TextField
                fullWidth
                label="Endereço"
                name="endereco"
                onChange={formik.handleChange}
                value={formik.values.endereco}
              />
              <TextField
                fullWidth
                name="numero"
                type="number"
                label="Número"
                value={formik.values.numero}
                onChange={(e) => {
                  formik.handleChange(e)
                  formik.setFieldValue('numero', e.target.value)
                }}
              />
            </Styled.BoxCard>
            <TextField
              fullWidth
              name="complemento"
              label="Complemento"
              onChange={formik.handleChange}
              value={formik.values.complemento}
            />
            <TextField
              fullWidth
              name="bairro"
              label="Bairro"
              value={formik.values.bairro}
              onChange={formik.handleChange}
            />
          </div>

          <div>
            <Styled.BoxCard>
              <Styled.FormControl>
                <Tooltip
                  title="O que consta no documento oficial"
                  placement="top-start"
                >
                  <FormLabel id="demo-controlled-radio-buttons-group">
                    Gênero
                  </FormLabel>
                </Tooltip>

                <Styled.RadioGroup
                  row
                  name="sexo"
                  value={formik.values.sexo}
                  onChange={formik.handleChange}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                >
                  <FormControlLabel
                    value="F"
                    control={<Radio size="small" />}
                    label="Feminino"
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio size="small" />}
                    label="Masculino"
                  />
                </Styled.RadioGroup>
              </Styled.FormControl>
              <Select
                id="civil"
                name="civil"
                isMargin="0 0 16px"
                label="Estado Civil"
                value={formik.values.civil}
                labelId="select-civilStatus"
                optionsList={civilStatusList}
                onChange={formik.handleChange}
              />
            </Styled.BoxCard>
            <TextField
              fullWidth
              name="nacionalidade"
              label="Nacionalidade"
              onChange={formik.handleChange}
              value={formik.values.nacionalidade}
            />
            <Styled.BoxCard>
              <Select
                id="naturalidade"
                name="naturalidade"
                isMargin="0 0 16px"
                label="Naturalidade (UF)"
                optionsList={states}
                labelId="select-naturalnessUF"
                value={formik.values.naturalidade}
                onChange={(e) => {
                  formik.setFieldValue('naturalidade', e.target.value)
                }}
              />
              <Select
                isMargin="0 0 16px"
                id="naturalidade_cidade"
                name="naturalidade_cidade"
                label="Naturalidade (cidade)"
                labelId="select-naturalnessCity"
                disabled={!formik.values.naturalidade}
                value={formik.values.naturalidade_cidade}
                defaultValue={formik.values.naturalidade_cidade}
                optionsList={naturalityCitys}
                onChange={(e) => {
                  formik.handleChange(e)
                  formik.setFieldValue('naturalidade_cidade', e.target.value)
                }}
              />
            </Styled.BoxCard>
            <TextField
              fullWidth
              name="pai"
              label="Nome do Pai"
              value={formik.values.pai}
              onChange={formik.handleChange}
            />
            <TextField
              fullWidth
              name="mae"
              label="Nome da Mãe"
              value={formik.values.mae}
              onChange={formik.handleChange}
            />
            <Styled.Container>
              <TextField
                fullWidth
                label="Celular"
                name="celular"
                onChange={formik.handleChange}
                value={typeMask('PHONE', formik.values.celular)}
                inputProps={{
                  maxLength: MAX_LENGTH_PHONE_MOBILE
                }}
                isMargin="0 0 16px"
              />

              <TextField
                fullWidth
                isMargin="0"
                name="telefone"
                label="Telefone (opcional)"
                onChange={formik.handleChange}
                value={typeMask('TELEPHONE', formik.values.telefone)}
                inputProps={{
                  maxLength: MAX_LENGTH_PHONE
                }}
              />
            </Styled.Container>
            <Styled.FormControl>
              <FormLabel component="legend">
                Melhor horário para contato
              </FormLabel>

              <Styled.RadioGroup
                row
                name="contato"
                value={formik.values.contato}
                onChange={formik.handleChange}
                aria-labelledby="demo-row-radio-buttons-group-label"
              >
                <FormControlLabel
                  value="morning"
                  control={<Radio size="small" />}
                  label="Manhã"
                />
                <FormControlLabel
                  value="afternoon"
                  control={<Radio size="small" />}
                  label="Tarde"
                />
                <FormControlLabel
                  value="night"
                  control={<Radio size="small" />}
                  label="Noite"
                />
              </Styled.RadioGroup>
            </Styled.FormControl>
          </div>
        </Styled.BoxContainer>
      )}
    </Grid>
  )
}

export default SecondStep
