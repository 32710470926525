import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { Image } from '@mui/icons-material'
import * as Styled from './style'
import fileType from './constants'

const InputFile = ({
  photo = false,
  onChange,
  imgSrc,
  typeFile,
  typesFileAllowed = [],
  width,
  name,
  nameFile,
  noPreview,
  disabled,
  labelBox,
  idUpload,
  hasSize,
  border,
  backgroundColor,
  errorTypeFile,
  showErrorFileForbbiden = () => {}
}) => {
  const onChangeCustom = (event) => {
    const target = event?.target
    if (target?.files?.length) {
      const file = event.target.files[0]
      const typeFileUploaded = file.name.split('.')[1]
      const isFileAllowed = !!typesFileAllowed.find((typeAllowed) =>
        typeFileUploaded.toLowerCase().includes(typeAllowed.toLowerCase())
      )

      if (isFileAllowed || !typesFileAllowed.length) {
        onChange(event)
      } else {
        showErrorFileForbbiden()
      }
      target.value = null
    }
  }

  return (
    <label htmlFor={idUpload}>
      <input
        id={idUpload}
        disabled={disabled}
        name={name || idUpload}
        onChange={onChangeCustom}
        style={{ display: 'none' }}
        type="file"
        accept={fileType[typeFile]?.accept}
      />
      <Styled.ButtonUploadFile
        aria-label="add"
        component="span"
        size="large"
        color="inherit"
        variant="text"
        $cursor={disabled ? 'default' : 'pointer'}
        $imgSrc={imgSrc}
        $width={width}
        $noPreview={noPreview}
        $errorTypeFile={errorTypeFile}
        $hasSize={hasSize}
        $borderDashed={border}
        $backgroundColor={backgroundColor}
      >
        {imgSrc ? (
          <>
            {noPreview ? (
              <Styled.Box $photo={photo}>
                {fileType[typeFile]?.icon}
                <Typography variant="caption">{nameFile}</Typography>
              </Styled.Box>
            ) : (
              <>
                {fileType[typeFile]?.previewData(
                  imgSrc,
                  typeFile === 'excel' && nameFile
                )}
              </>
            )}
          </>
        ) : (
          <Styled.Box $photo={photo}>
            {fileType[typeFile]?.icon || <Image color="gray" />}
            <Typography
              $photo={photo}
              variant="subtitle2"
              dangerouslySetInnerHTML={{ __html: labelBox }}
            />
          </Styled.Box>
        )}
      </Styled.ButtonUploadFile>
    </label>
  )
}

export default InputFile

InputFile.propTypes = {
  onChange: PropTypes.func
}

InputFile.defaultProps = {
  onChange: () => {}
}
