import { useSnack } from 'services/hooks'
import { useStore } from 'store/GlobalContext'
import { Carousel } from 'components/organisms'
import { getTwoFirstWords } from 'services/helpers'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'

import * as Styled from './style'
import { academicJoyClass, nameSteps } from './constants'
import { questions, getSteps } from './config'
import { Card, StepWelcome } from './components'
import { useTutorialContext } from './Context/hooks'

const Welcome = () => {
  const { user } = useStore()
  const snackProps = useSnack()
  const {
    currentStep,
    isPending,
    handleStep,
    hasViewedSecretary,
    finishTutorial
  } = useTutorialContext()

  const isHighlightQuestions = isPending && currentStep === nameSteps.three
  const isHighlightHelp = isPending && currentStep === nameSteps.four
  const isHighlightFullSteps =
    isPending && Object.values(nameSteps).includes(currentStep)
  const isHighlightNext =
    isPending &&
    ([nameSteps.one, nameSteps.three].includes(currentStep) ||
      (currentStep === nameSteps.two && hasViewedSecretary))

  const handleStartTutorial = () => {
    window.open(academicJoyClass, '_blank')
  }

  return (
    <Styled.Main>
      <TemplateDefaultHeaderByContent
        snackProps={snackProps}
        content={
          <Styled.Content>
            {isPending && <Styled.Filter />}

            <Styled.BackgroundImage src="/images/backgroundWelcome.svg" />

            <Styled.WelcomeMessage variant="subtitle1" fontWeight="bold">
              {`Seja bem-vindo(a), ${getTwoFirstWords(user.name)}!`}
            </Styled.WelcomeMessage>

            {isPending ? (
              <Carousel
                steps={getSteps({
                  finishTutorial: () => finishTutorial({ userId: user.id })
                })}
                currentStep={currentStep}
                setCurrentStep={handleStep}
                isHighlightNext={isHighlightNext}
                isHighlight={isHighlightFullSteps}
                width="900px"
                height="250px"
              />
            ) : (
              <StepWelcome />
            )}

            <Styled.QuestionsContainer
              isHighlightQuestions={isHighlightQuestions}
            >
              <Styled.HasDoubt variant="subtitle1" fontWeight="bold">
                Tem dúvidas de como funciona o ERP?
              </Styled.HasDoubt>

              <Styled.Questions>
                {questions.map(Card)}

                <Styled.StartTutorial>
                  <Styled.StartTutorialIconButton onClick={handleStartTutorial}>
                    <Styled.StartTutorialPlayIcon />
                  </Styled.StartTutorialIconButton>

                  <Styled.StartTutorialText>
                    Assista os Tutoriais
                  </Styled.StartTutorialText>
                </Styled.StartTutorial>
              </Styled.Questions>
            </Styled.QuestionsContainer>

            <Styled.ButtonHelp isHighlightHelp={isHighlightHelp} />
          </Styled.Content>
        }
      />
    </Styled.Main>
  )
}
export default Welcome
