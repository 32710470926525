import { httpGet } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getDocumentsStatus = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_DOCUMENT.LIST_STATUS}?${convertToParams(
      params
    )}`
  )
  return response
}

export const getDocumentsStatusXLSX = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_DOCUMENT.LIST_STATUS_XLSX}?${convertToParams(
      params
    )}`,
    {
      responseType: 'blob'
    }
  )
  return response
}
