export const KEY_COLUMNS = {
  NAME: 'grouping',
  DISCIPLINE: 'discipline',
  PERIOD: 'period',
  ACTIVE_AND_INACTIVE_STUDENTS: 'workload',
  TEACHER: 'teacher'
}

const tableColumns = [
  { id: KEY_COLUMNS.NAME, label: 'Agrupamento' },
  { id: KEY_COLUMNS.DISCIPLINE, label: 'Disciplina' },
  { id: KEY_COLUMNS.PERIOD, label: 'Período' },
  {
    id: KEY_COLUMNS.ACTIVE_AND_INACTIVE_STUDENTS,
    label: 'Alunos Ativos/Inativos'
  },
  {
    id: KEY_COLUMNS.TEACHER,
    label: 'Professor'
  }
]

const tooltipTextExclude = {
  available: 'Excluir',
  dontAvailable:
    'Não é possível excluir este agrupamento pois há turmas vinculadas'
}

const tooltipTextManagerGrouping = 'Gerenciar Agrupamento'

export { tableColumns, tooltipTextExclude, tooltipTextManagerGrouping }
