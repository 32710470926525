export const FINANCIAL_TAG_MANAGER = {
  btn_student_clear_Financial_filter: 'aluno_btn_limpa_filtroFinanceiro',
  btn_student_filters_Financial_listing: 'aluno_btn_filtra_listagemFinanceiro',
  student_select_filters_financial_situation:
    'aluno_select_filtra_situacaoFinanceiro',
  student_select_toggles_qtFinancial_Records:
    'aluno_select_alterna_qtRegistrosFinanceiro',
  btn_student_prints_Financial_listing: 'aluno_btn_imprime_listagemFinanceiro',
  btn_student_low_grade_Boleto: 'aluno_btn_baixa_notaBoleto',
  btn_student_low_tax_grade: 'aluno_btn_baixa_notaFiscal',
  btn_student_starts_online_payment: 'aluno_btn_inicia_pagamentoOnline',
  btn_student_requests_negotiation: 'aluno_btn_solicita_negociacao'
}
