/* eslint-disable react/destructuring-assignment */
import { useState } from 'react'
import { parseISO } from 'date-fns'

import { AccordionFilter } from 'components/atoms'
import { SUBJECT_STATUS } from 'services/constants'

import { nameAccordion } from './constants'

import Sumary from '../Sumary'
import Details from '../Details'

import * as Styled from './style'

const Accordion = (props) => {
  const {
    subjects,
    schoolClassId,
    schoolClassLevel,
    schoolClassCourse,
    handleRegisterNote,
    handleExcludeLink,
    isInitialOpened
  } = props

  const [expanded, setExpanded] = useState(
    isInitialOpened ? nameAccordion : false
  )

  const hasLinked = !!subjects?.find(
    (subject) => subject?.subjectStatus === SUBJECT_STATUS.LINKED
  )
  const isCanceled = props?.hasOcurrence
  const dateToday = new Date()
  const disabledAccordion = isCanceled

  const arrowMessageTooltip = `${hasLinked ? 'Há' : 'Não há'} vínculos`

  return (
    <Styled.Container>
      <AccordionFilter
        title={<Sumary isCanceled={isCanceled} {...props} />}
        expanded={expanded}
        setExpanded={setExpanded}
        nameAccordion={nameAccordion}
        margin=""
        arrowLabel="Ver Disciplinas"
        arrowMessageTooltip={arrowMessageTooltip}
        disabled={disabledAccordion}
      >
        <Details
          subjects={subjects}
          schoolClassId={schoolClassId}
          schoolClassLevel={schoolClassLevel}
          schoolClassCourse={schoolClassCourse}
          handleRegisterNote={handleRegisterNote}
          handleExcludeLink={handleExcludeLink}
        />
      </AccordionFilter>
    </Styled.Container>
  )
}

export default Accordion
