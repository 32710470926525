import styled from 'styled-components'
import {
  Divider as DividerSchoolClass,
  Grid as GridHeaderCode
} from '@mui/material'

const Box = styled.div`
  display: flex;
  justify-content: ${({ $justifyContent }) => $justifyContent ?? 'flex-end'};
  width: 100%;
  margin: 16px 0;
`

const Grid = styled(GridHeaderCode)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    position: relative;

    & button {
      margin-left: 20px;
    }
  }
`

const Divider = styled(DividerSchoolClass)`
  &.MuiDivider-root {
    width: 100%;
    margin: 25px 0 0 0;
  }
`

const Checkbox = styled.div`
  margin-top: 8px;
  color: #00000099;
`

export { Box, Grid, Divider, Checkbox }
