import styled from 'styled-components'
import {
  Chip,
  Typography,
  Toolbar as MuiToolbar,
  Paper as MuiPaper,
  TableRow as MuiTableRow,
  TableContainer as MuiTableContainer
} from '@mui/material'
import { Button as AtomButton } from 'components/atoms'
import theme from 'theme/designTokens'

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
  }
`

const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    margin-top: 24px;
  }
`

const ColorInformation = styled(Chip)`
  &.MuiChip-root {
    max-width: 34px;
    max-height: 15px;
    border-radius: 0;
  }
`

const Caption = styled.div`
  display: flex;
  align-items: center;
`
const CaptionTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 10px;
    font-size: 14px;
  }
`

const Footer = styled.div`
  display: grid;
  grid-template-columns: 90px 90px;
  grid-gap: 24px;
  margin: 24px 0;
`

const Toolbar = styled(MuiToolbar)`
  &.MuiToolbar-root {
    padding: 0;
    margin-top: 14px;
    align-items: center;
    display: ${({ $displayNone }) => ($displayNone ? 'none' : 'flex')};
  }
`

const Button = styled(AtomButton)`
  &.MuiButtonBase-root {
    border: 1px solid;
    padding: 4px 8px;
    margin-right: 16px;

    &:hover {
      background-color: transparent;
    }
  }
`

const TableRowTotalHoursValues = styled(MuiTableRow)`
  &.MuiTableRow-root {
    background-color: ${() => theme.palette.gray.grayLight};

    & .MuiTableCell-root {
      border-botton: 0px;

      &.totalValue {
        border-left: 1px solid ${() => theme.palette.primary.white};
        border-botton: 0px;

        & .MuiBox-root {
          display: flex;

          & .MuiTypography-root {
            width: 100%;
            text-align: left;
          }
        }
      }

      & .MuiBox-root {
        display: flex;

        & .MuiTypography-root {
          width: 100%;
          text-align: right;
        }
      }
    }
  }
`

export {
  ColorInformation,
  Caption,
  CaptionTitle,
  Footer,
  Toolbar,
  Button,
  Paper,
  TableContainer,
  TableRowTotalHoursValues
}
