import { lazy, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListAlt, Search } from '@mui/icons-material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateBillsReceive } from 'components/templates/Admin'
import { searchBillsReceiveSimplesAdvanced } from 'services/api/admin'
import { usePagination, useSnack } from 'services/hooks'
import {
  convertToParams,
  formatDateEnv,
  IsEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import {
  DEFAULT_LIMIT,
  INITIAL_PAGE,
  bankBillingSavedSucess,
  nameAccordion,
  countOfEmptyList,
  initialValue,
  MESSAGE
} from './constants'
import { useBillsToReceive } from './Context/FormContext'

const Table = lazy(() => import('./components/Table'))
const FormSimpleAdvancedSearch = lazy(() =>
  import('./components/FormSimpleAdvancedSearch')
)

const ListBillsToReceive = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [notSearch, setNotSearch] = useState(true)
  const [contentTable, setContentTable] = useState({})
  const [searchValues, setSearchValues] = useState(initialValue)
  const [switchAdvancedSearch, setSwitchAdvancedSearch] = useState(false)
  const [count, setCount] = useState(false)
  const { formValues, setFormValues } = useBillsToReceive()
  const snackProps = useSnack()
  const { setSnack, snackOpen } = snackProps

  const isSearchParam = location?.state?.savedSearch
  const isParamSavedBankBilling = location?.state?.savedNumberBankBilling
  const isParamUpdateBankBilling = location?.state?.updateNumberBankBilling
  const paramSnackOpen = isParamSavedBankBilling || isParamUpdateBankBilling

  const { activePage, totalPages, handleSetPage, handleSetMaxPageNumber } =
    usePagination()

  const handleSimpleAdvancedSearch = async (values) => {
    setLoadingOpen(true)
    setSearchValues(values)

    const paginationData = {
      limit: DEFAULT_LIMIT,
      page: activePage || INITIAL_PAGE
    }

    const checkSocialReason = {
      is_social_reason: values.checkSocialReason
    }

    const params = {
      ...(values?.switchAdvancedSearch
        ? {
            ...(values.firstPeriod && {
              start_date: formatDateEnv(values.firstPeriod)
            }),
            ...(values.endPeriod && {
              end_date: formatDateEnv(values.endPeriod)
            }),
            ...(values.title && { id_bank_billing: values.title }),
            ...(values.switchDefaulters && {
              debtors: values.switchDefaulters
            }),
            ...(values.switchPaid && { paid: values.switchPaid }),
            ...paginationData,
            ...(values.checkSocialReason && checkSocialReason)
          }
        : {
            search_type: values.searchType,
            search_by: values.searchBy,
            ...paginationData,
            ...(values.checkSocialReason && checkSocialReason)
          })
    }

    const paramsAll = {
      units: values.unity,
      courses: values.course || [],
      params: convertToParams(params),
      switchAdvancedSearch: values?.switchAdvancedSearch
    }
    const {
      data: response,
      error,
      status
    } = await searchBillsReceiveSimplesAdvanced(paramsAll)

    setLoadingOpen(false)
    setCount(response?.count)

    if (error) {
      setNotSearch(true)
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListBillisToReceive
      })
    }

    if (response?.count === countOfEmptyList) {
      setNotSearch(false)

      return setContentTable([])
    }
    setFormValues(values)
    setContentTable(response?.data)
    setSwitchAdvancedSearch(values?.switchAdvancedSearch)

    return (
      activePage === INITIAL_PAGE &&
      handleSetMaxPageNumber(response.count, response.limit)
    )
  }

  useEffect(
    () =>
      searchValues !== initialValue && handleSimpleAdvancedSearch(searchValues),
    [activePage]
  )

  useEffect(() => {
    if (paramSnackOpen) setSnack(bankBillingSavedSucess)
  }, [paramSnackOpen])

  useEffect(() => {
    if (paramSnackOpen && !snackOpen) {
      navigate(location?.pathname, {
        state: {
          updateNumberBankBilling: undefined,
          savedNumberBankBilling: undefined,
          savedSearch: true
        },
        replace: true
      })
    }
  }, [paramSnackOpen, snackOpen])

  useEffect(() => {
    if (
      (isSearchParam &&
        ((formValues?.searchBy && formValues?.searchType) ||
          (formValues?.endPeriod && formValues?.firstPeriod))) ||
      formValues?.title
    ) {
      return handleSimpleAdvancedSearch(formValues)
    }

    return !isSearchParam && setContentTable([]) && setFormValues(initialValue)
  }, [isSearchParam])

  const initialValues = mergeInitialValues(
    initialValue,
    isSearchParam && formValues
  )

  return (
    <>
      <TemplateBillsReceive
        snackProps={snackProps}
        titleHeader="Financeiro - Contas a Receber"
        loadingOpen={loadingOpen}
        listItems={contentTable}
        iconInformative={notSearch ? <ListAlt /> : <Search />}
        messageInformative={
          notSearch ? MESSAGE.NO_SEARCH_PERFOMED : MESSAGE.NOT_FOUND_SEARCH
        }
        form={
          <FormSimpleAdvancedSearch
            expanded={expanded}
            setExpanded={setExpanded}
            nameAccordion={nameAccordion}
            initialValue={initialValues}
            handleSimpleAdvancedSearch={handleSimpleAdvancedSearch}
            handleSetPage={handleSetPage}
            setContentTable={setContentTable}
            setNotSearch={setNotSearch}
          />
        }
        table={
          <>
            {contentTable && !IsEmpty(contentTable) && (
              <Table
                contentTable={contentTable}
                switchAdvancedSearch={switchAdvancedSearch}
                activePage={activePage}
                totalPages={totalPages}
                handleSetPage={handleSetPage}
                setSnack={setSnack}
                setLoadingVisibility={setLoadingOpen}
                searchValues={searchValues}
                count={count}
                isCnpj={formValues.checkSocialReason}
              />
            )}
          </>
        }
      />
    </>
  )
}

export default ListBillsToReceive
