import { useCallback, useEffect, useState } from 'react'
import { ListAlt, Search } from '@mui/icons-material'

import { ModalConfirm } from 'components/molecules'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { defaultlistPerPage } from 'services/hooks/constants'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'

import {
  messages,
  nameAccordion,
  initialStateData,
  initialStateStatus,
  textModalConfirm,
  initialStateModal
} from './constants'
import { excludeGrouping, getGroupingList } from './service'
import { List, GroupingModal, SearchForm, TeacherModal } from './components'
import { useGrouping } from './Context/FormContext'

const Grouping = () => {
  const [shouldUpdateGrouping, setShouldUpdateGrouping] = useState(false)
  const [data, setData] = useState(initialStateData)
  const [rowsPerPage, setRowsPerPage] = useState(defaultlistPerPage)
  const [status, setStatus] = useState(initialStateStatus)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [excludeMmodal, setExcludeModal] = useState(initialStateModal)
  const [groupingMmodal, setGroupingModal] = useState(initialStateModal)
  const [teacherMmodal, setTeacherModal] = useState(initialStateModal)

  const snackProps = useSnack()

  const { setSnack } = snackProps
  const { formValues, setFormValues, resetFormValues } = useGrouping()
  const { items, totalPage, page, handleSetPage } = usePaginationBasic(
    data?.activities,
    rowsPerPage,
    data?.count
  )

  const noResultMessage = useCallback(() => {
    if (status.empty) return messages.empty.text
    if (status.notFound) return messages.notFound.text

    return null
  }, [JSON.stringify(status)])

  const noResultIcon = useCallback(() => {
    if (status.empty) return <ListAlt />
    if (status.notFoundByUnity || status.notFound) return <Search />

    return null
  }, [JSON.stringify(status)])

  const handleSearch = (values) => {
    const shouldClear = values === null

    if (shouldClear) {
      resetFormValues()
      setData(initialStateData)
      return setStatus(initialStateStatus)
    }

    return setFormValues({ search: values })
  }

  const handleGroupingModal = {
    exclude: (item) => setExcludeModal({ item, open: true }),
    addTeacher: (item) => setTeacherModal({ item, open: true, isEdit: false }),
    editTeacher: (item) => setTeacherModal({ item, open: true, isEdit: true }),
    create: (item) => setGroupingModal({ item, open: true, isEdit: false }),
    edit: (item) => setGroupingModal({ item, open: true, isEdit: true })
  }

  const callbackSuccess = {
    exclude: () => {
      setShouldUpdateGrouping(true)
      setExcludeModal(initialStateModal)
    },
    teacher: () => {
      setTeacherModal(initialStateModal)
      setShouldUpdateGrouping(true)
    },
    grouping: () => {
      setShouldUpdateGrouping(true)
      setGroupingModal(initialStateModal)
    }
  }

  const handleExcludeGrouping = ({ id }) => {
    excludeGrouping({
      id,
      setSnack,
      callbackSuccess: callbackSuccess.exclude
    })
  }

  const onSearch = () => {
    getGroupingList({
      setSnack,
      setState: setData,
      search: formValues?.search,
      rowsPerPage,
      currentPage: page,
      setStatus
    })
  }

  useEffect(() => {
    if (formValues?.search) {
      onSearch()
    }
  }, [JSON.stringify(formValues?.search), page, rowsPerPage])

  useEffect(() => {
    if (formValues?.search && shouldUpdateGrouping) {
      setShouldUpdateGrouping(false)
      onSearch()
    }
  }, [shouldUpdateGrouping])

  const hasResult = !!items?.length

  return (
    <TemplateDefaultFormAndResult
      listItems={items}
      expanded={expanded}
      snackProps={snackProps}
      setExpanded={setExpanded}
      nameAccordion={nameAccordion}
      labelButtonHeader="novo agrupamento"
      onClickButton={handleGroupingModal.create}
      iconInformative={noResultIcon()}
      messageInformative={noResultMessage()}
      accordion={<SearchForm handleSearch={handleSearch} setSnack={setSnack} />}
      titleHeader="<strong>Secretaria -</strong> Agrupamentos"
    >
      {!!hasResult && (
        <List
          page={page}
          items={items}
          limit={data?.limit}
          totalPage={totalPage}
          count={data?.count}
          handleSetPage={handleSetPage}
          handleChange={(e) => setRowsPerPage(e.target.value)}
          handleEditGrouping={handleGroupingModal.edit}
          handleAddTeacher={handleGroupingModal.addTeacher}
          handleEditTeacher={handleGroupingModal.editTeacher}
          handleExcludeGrouping={handleGroupingModal.exclude}
        />
      )}

      {groupingMmodal.item && (
        <GroupingModal
          {...groupingMmodal}
          setSnack={setSnack}
          callbackSuccess={callbackSuccess.grouping}
          handleClose={() => setGroupingModal(initialStateModal)}
        />
      )}

      {teacherMmodal.item && (
        <TeacherModal
          {...teacherMmodal}
          setSnack={setSnack}
          callbackSuccess={callbackSuccess.teacher}
          handleClose={() => setTeacherModal(initialStateModal)}
        />
      )}

      {excludeMmodal?.item && (
        <ModalConfirm
          open={excludeMmodal.open}
          handleClose={() => setExcludeModal(initialStateModal)}
          title={textModalConfirm.title}
          message={textModalConfirm.message}
          textButtonNotConfirm={textModalConfirm.buttonNotConfirm}
          textButtonConfirm={textModalConfirm.buttonConfirm}
          onClickNotConfirm={() => setExcludeModal(initialStateModal)}
          onClickConfirm={() => handleExcludeGrouping(excludeMmodal.item)}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default Grouping
