import { createContext, useState, useContext } from 'react'

const initialState = { name: '', inactive: false }

export const FormContext = createContext()

export const DocumentsTypesProvider = ({ children }) => {
  const [formValues, setFormValues] = useState(initialState)

  const resetFormValues = () => {
    setFormValues({ ...initialState })
  }

  return (
    <FormContext.Provider
      value={{
        formValues,
        setFormValues,
        resetFormValues
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useDocumentsTypes = () => useContext(FormContext)
