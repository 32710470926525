import { updateErrorMessage } from './updateErrorMessage'
import { toastNotificationSuccess } from './nofications'

const messageErroOrSuccess = ({
  error,
  status,
  feedbackMessage,
  successMessage,
  isResturnBackPage,
  navigate
}) => {
  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage
    })
  }

  if (!isResturnBackPage) {
    return toastNotificationSuccess(successMessage)
  }

  toastNotificationSuccess(successMessage)

  return setTimeout(() => navigate(-1), 3000)
}

export { messageErroOrSuccess }
