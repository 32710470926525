/* eslint-disable consistent-return */
import { userFinishTutorial as userFinishTutorialService } from 'services/api/shared'
import { messageErroOrSuccess } from 'services/helpers'

const userFinishTutorial = async ({ id, callback }) => {
  const { error, status } = await userFinishTutorialService({ id })

  if (error) {
    return messageErroOrSuccess({
      error,
      status
    })
  }

  callback()
}

export { userFinishTutorial }
