import { ROUTE } from 'services/constants'

const initialState = {
  code: '',
  campaign: '',
  beginAt: null,
  endAt: null,
  amount: '',
  discount: '',
  initialInstallment: '',
  finalInstallment: '',
  unity: '',
  level: '',
  course: '',
  shift: '',
  presential: false,
  ead: false,
  switch: false
}

const MAXLENGTH = 15

const breadcrumbsItems = (hasCreateCupon, numberTicket) => [
  {
    path: ROUTE.ADMIN_FINANCIAL_CUPON,
    label: 'Financeiro'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_CUPON,
    label: 'Cupons de desconto'
  },
  {
    path: hasCreateCupon
      ? ROUTE.ADMIN_FINANCIAL_CUPON_CREATE
      : ROUTE.ADMIN_FINANCIAL_CUPON_EDIT,
    label: hasCreateCupon ? '<b>Novo Cupom</b>' : `<b>Cupom ${numberTicket}</b>`
  }
]

const valueZero = 0
const modalityPresential = 1
const modalityEad = 3
const modalityAll = 0

export {
  initialState,
  MAXLENGTH,
  breadcrumbsItems,
  valueZero,
  modalityPresential,
  modalityEad,
  modalityAll
}
