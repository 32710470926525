import { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import GlobalContext from 'store/GlobalContext'
import {
  removeClassesLocalStorage,
  removeSavedDataFromUrl,
  removeUserFromLocalStorage,
  removeVisibleUpdatePassword
} from 'services/helpers'

export default function Logout() {
  const { resetUserFromGlobalState } = useContext(GlobalContext)

  useEffect(() => {
    removeUserFromLocalStorage()
    resetUserFromGlobalState()
    removeClassesLocalStorage()
    removeVisibleUpdatePassword()
    removeSavedDataFromUrl()
  }, [resetUserFromGlobalState])

  return <Navigate to="/" />
}
