import { parserMinutesForHours, typeMask } from 'services/helpers'

const parserTotalHours = (data) => {
  const total = data.reduce(
    (acc, cur) => Number(acc) + Number(cur.lesson_minutes),
    0
  )

  return parserMinutesForHours(total)
}

const parserTotalValue = (data) => {
  const total = data.reduce(
    (acc, cur) => Number(acc) + Number(cur.total_lessons_cost),
    0
  )

  return typeMask('MONEY_NUMBER', total)
}

export { parserTotalHours, parserTotalValue }
