import styled, { keyframes } from 'styled-components'
import { Paper as MuiPaper } from '@mui/material'
import Logo from 'components/atoms/Logotype'

const FormWrapper = styled.div`
  &:before {
    background: ${(props) =>
      props.$showBackgroundImage
        ? "url('/images/bg-auth.jpg') no-repeat center top"
        : 'none'};
    background-size: cover;
    content: '';
    display: block;
    left: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
  }
`

const Logotype = styled(Logo)`
  margin-bottom: ${(props) => props.theme.spacing(2)};
  position: 'relative';
`

const centralize = keyframes`
  from {
    transform: translate(calc(100% + 180px), -50%);
  }
  to {
    transform: translate(-50%, -50%);
  }
`

const Paper = styled(MuiPaper)`
  max-width: 348px;
  position: fixed;
  width: 90%;
  top: 50%;
  left: 50%;
  padding: 32px;
  animation-name: ${centralize};
  animation-duration: 1s;
  animation-fill-mode: forwards;
`

export { FormWrapper, Logotype, Paper }
