import * as yup from 'yup'

const schema = yup
  .object({
    name: yup.string().required('Você deve digitar o título do documento.'),
    extension: yup.string().required('Você deve selecionar um formato.'),
    is_multiple: yup.boolean(),
    is_required: yup.boolean()
  })
  .required()

const fileFormatItems = [
  // { label: 'BITMAP', value: 'bitmap' },
  // { label: 'JPEG', value: 'jpeg' },
  // { label: 'JPG', value: 'jpg' },
  { label: 'PDF', value: 'pdf' }
  // { label: 'PNG', value: 'png' }
]

export { fileFormatItems, schema }
