import { ROUTE } from 'services/constants'

const breadcrumbsItems = [
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS,
    label: 'Turmas'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_CLASSES_DASHBOARD,
    label: '<b>Dashboard</b>'
  }
]

const orderByType = {
  numeric: 'numeric',
  alphabetic: 'alphabetic'
}

const sortOrder = {
  ascending: 1,
  descending: -1
}

const orderByDecreasing = (a, b) => {
  const order = 1
  return order * (b.students_actives - a.students_actives)
}

const handleOrder = (a, b, orderBy) => {
  if (orderBy.type === orderByType.numeric) {
    const order = orderBy.isDescending
      ? sortOrder.descending
      : sortOrder.ascending
    return order * (b.students_actives - a.students_actives)
  }

  const nameA = a.pole_name.toLowerCase()
  const nameB = b.pole_name.toLowerCase()

  return orderBy.type === orderByType.alphabetic
    ? nameA.localeCompare(nameB)
    : nameB.localeCompare(nameA)
}

const sumStudentsActives = (arr) => {
  let total = 0
  // eslint-disable-next-line no-restricted-syntax
  for (const item of arr) {
    total += item.students_actives
  }
  return total
}

export { breadcrumbsItems, handleOrder, orderByDecreasing, sumStudentsActives }
