import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'

const Grid = styled(MuiGrid)`
  &.MuiGrid-container {
    margin-top: 25px;
  }
`

const Button = styled(ButtonFooterV2)`
  &.MuiButtonBase-root {
    min-width: 42px;
    margin: 12px 0 0 16px;
  }
`

export { Grid, Button }
