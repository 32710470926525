import { useEffect, useState } from 'react'
import { ListAlt, Search } from '@mui/icons-material'

import { usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { ModalConfirm } from 'components/molecules'

import { List, SearchForm } from './components'
import {
  defaultlistPerPage,
  getMessageModalConfirm,
  getTitleModalConfirm,
  messages
} from './constants'

import * as Styled from './style'
import { searchSuppliers, updateStatusSupplier } from './services'
import { SEARCH_TYPE, formModel } from './components/SearchForm/constants'
import { MIN_LENGTH_RAZAO_SOCIAL } from './components/Form/config'

const Supplier = () => {
  const [rowsPerPage, setRowsPerPage] = useState(defaultlistPerPage)
  const [modalStatusSupplier, setModalStatusSupplier] = useState({
    open: false,
    supplier: {
      inactive: false,
      id: null
    }
  })
  const [search, setSearch] = useState(null)
  const [status, setStatus] = useState({ empty: true, notFound: false })
  const [data, setData] = useState({ suppliers: [] })

  const snackProps = useSnack()

  const { items, totalPage, page, handleSetPage } = usePaginationBasic(
    data?.suppliers,
    rowsPerPage
  )

  const noResultMessage = () => {
    if (status.empty) return messages.supplierEmpty.text
    if (status.notFound) return messages.supplierNotFound.text
    return null
  }

  const noResultIcon = () => {
    if (status.empty) return <ListAlt />
    if (status.notFound) return <Search />
    return null
  }

  const handleSearch = (values, formikHelpers, hasSubmit = false) => {
    const hasSelectedRazaoSocial =
      values?.[formModel.searchType.key] === SEARCH_TYPE.REASON_SOCIAL
    const hasMinCharacter =
      values?.[formModel.fieldValue.key]?.length >= MIN_LENGTH_RAZAO_SOCIAL

    if (hasSelectedRazaoSocial && !hasMinCharacter && hasSubmit) {
      formikHelpers?.setFieldError(
        formModel.fieldValue.key,
        'Digite pelo menos 3 caracteres para realizar a busca'
      )
      return
    }
    setSearch(values)
  }

  const handleCloseModal = () => {
    setModalStatusSupplier({
      open: false,
      supplier: {
        inactive: false,
        id: null
      }
    })
  }

  useEffect(() => {
    if (search !== null) {
      searchSuppliers({
        setSnack: snackProps.setSnack,
        search,
        setStatus,
        setData
      })
    }
  }, [search])

  const hasResult = !!data?.suppliers?.length

  return (
    <Styled.Container>
      <TemplateDefaultFormAndResult
        formHeader={<SearchForm handleSearch={handleSearch} />}
        iconInformative={noResultIcon()}
        labelButtonHeader="Novo Fornecedor"
        linkHeader={ROUTE.ADMIN_FINANCIAL_REGISTER_SUPPLIER_CREATE}
        listItems={data?.suppliers}
        messageInformative={noResultMessage()}
        snackProps={snackProps}
        titleHeader="<strong>Financeiro -</strong> Cadastro Cliente/Fornecedor"
      />

      {hasResult && (
        <List
          items={items}
          limit={rowsPerPage}
          page={page}
          count={data?.count}
          totalPage={totalPage}
          handleSetPage={handleSetPage}
          handleChange={(e) => setRowsPerPage(e.target.value)}
          handleStatusSupplier={(id, inactive) =>
            setModalStatusSupplier({
              open: true,
              supplier: {
                inactive,
                id
              }
            })
          }
        />
      )}

      <ModalConfirm
        open={modalStatusSupplier?.open}
        size="medium"
        handleClose={() => handleCloseModal()}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={() => handleCloseModal()}
        onClickConfirm={() => {
          updateStatusSupplier({
            setSnack: snackProps.setSnack,
            idSupplier: modalStatusSupplier?.supplier?.id,
            inactive: modalStatusSupplier?.supplier?.inactive,
            handleCloseModal
          })
        }}
        colorButtonConfirm="error"
        icon={<Styled.IconWarningAmber />}
        title={getTitleModalConfirm(modalStatusSupplier?.supplier?.inactive)}
        message={getMessageModalConfirm(
          modalStatusSupplier?.supplier?.inactive
        )}
      />
    </Styled.Container>
  )
}

export default Supplier
