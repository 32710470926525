import theme from 'theme/designTokens'
import generateDocDefinitionsBase from '../../shared/BasePage'
import { DOCUMENT_TITLE_ACADEMIC_RECORD_PR } from './constants'
import { buildMainContent } from '../MG'
import { buildCompanyInfo } from '../MG/CompanyInfo'
import { buildPrimaryHeaderContent } from '../MG/PrimaryHeader'
import { coatOfArmsPrBase64 } from '../../shared/images/coatOfArmsPR'

export async function AcademicRecordPDFtypePR({
  academicRecordData,
  withSignature
}) {
  const {
    classInfo,
    companyInfo: companyInfoList,
    students,
    studentAcademicReportInfo,
    courseInfo,
    subjects
  } = academicRecordData
  const signatureImage1 = classInfo?.signatures[0]?.image
  const signatureImage2 = classInfo?.signatures[1]?.image
  const [{ student, gradeDetails }] = students

  const studentInfo = {
    ...student,
    ...studentAcademicReportInfo
  }
  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      pageOrientation: 'portrait',
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },
      ...(signatureImage1 || signatureImage2
        ? {
            images: {
              ...(signatureImage1
                ? {
                    signature1: {
                      url: signatureImage1,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {}),
              ...(signatureImage2
                ? {
                    signature2: {
                      url: signatureImage2,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {})
            }
          }
        : {}),
      pageBreakBefore: (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) => {
        const shouldBreakBefore =
          currentNode?.id === 'signatureInfo' &&
          currentNode.pageNumbers.length > 1

        return shouldBreakBefore
      }
    }
  })

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE_ACADEMIC_RECORD_PR,
    mainContent: buildMainContent({
      classInfo,
      student: studentInfo,
      gradeDetails,
      courseInfo,
      subjects,
      withSignature
    }),
    companyInfo: buildCompanyInfo({ companyInfoList }),
    headerContent: buildPrimaryHeaderContent({
      state: 'Paraná',
      rightImage: coatOfArmsPrBase64
    })
  })
}
