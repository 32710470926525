import { createContext, useState, useContext } from 'react'

export const TabContext = createContext()

export const TabActiveProvider = ({ children }) => {
  const [tabActive, setTabActive] = useState(0)

  const resetTabActive = () => {
    setTabActive(0)
  }

  return (
    <TabContext.Provider
      value={{
        tabActive,
        setTabActive,
        resetTabActive
      }}
    >
      {children}
    </TabContext.Provider>
  )
}

export const useTabActive = () => useContext(TabContext)
