import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useSnack } from 'services/hooks'
import { ButtonFooterV2, Snackbar } from 'components/molecules'
import { ROUTE, SUCCESS } from 'services/constants'
import { cleanObject } from 'services/helpers/cleanObject'
import {
  getDetailsDeclaration,
  editContractsTypes
} from 'services/api/admin/system/ContractsTypes'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import TAG_MANAGER from 'tagManager'
import {
  breadcrumbsItems,
  textModalConfirm,
  titleModalConfirm
} from './constants'
import { initialStateForm } from '../../constants'
import { FormContractsTypes } from '../Forms'
import { isDisabledButtonCreate } from '../../helpers'
import schema from './schema'
import * as Styled from './style'

const EditContractsTypes = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const snackProps = useSnack()
  const { snack, setSnack, snackOpen } = snackProps

  const updateContractsTypes = async (values) => {
    setLoading(true)

    const params = cleanObject({
      name: values.name,
      clause: values.description,
      is_electronic_signature: values.signature
    })

    const { error, status } = await editContractsTypes(
      params,
      location?.state.row.id
    )
    setLoading(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditContractsTypes
      })
    }

    setSnack('Contrato atualizado com sucesso.', SUCCESS)

    return setTimeout(
      () =>
        navigate(ROUTE.contracts.LIST_CONTRACTS, {
          state: { savedSearch: true }
        }),
      1500
    )
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialStateForm,
    onSubmit: (values) => {
      updateContractsTypes(values)
    },
    validationSchema: schema
  })

  const handleSearch = async () => {
    const { data, error, status } = await getDetailsDeclaration(
      location?.state.row.id
    )
    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListContractStatus
      })
    }
    formik.setFieldValue('name', data.name)
    formik.setFieldValue('description', data.clause)
    return formik.setFieldValue('signature', data.is_electronic_signature)
  }

  useEffect(() => {
    handleSearch(formik.values)
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      open={isOpen}
      loadingOpen={loading}
      snackProps={snackProps}
      classNameHeader="goBack"
      onClickButton={() => setIsOpen(!isOpen)}
      handleConfirmModal={() =>
        navigate(ROUTE.contracts.LIST_CONTRACTS, {
          state: { savedSearch: true }
        })
      }
      handleCloseModal={() => setIsOpen(false)}
      titleModalConfirm={titleModalConfirm}
      breadcrumbsNotLink={breadcrumbsItems}
      messageModalConfirm={textModalConfirm}
      className={TAG_MANAGER.sistema_btn_cancela_editarContrato}
      tagmanagerModal={TAG_MANAGER.sistema_btn_confirma_cancelarEditarContrato}
      content={
        <>
          <Styled.Form
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
          >
            <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
              <FormContractsTypes formik={formik} />
            </Styled.GridContainer>
            <Styled.Grid end marginTop={2} item xs={12} sm={12} md={12}>
              <ButtonFooterV2
                size="medium"
                loading={loading}
                labelClose="Cancelar"
                labelConfirm="Salvar"
                onClickClose={() => setIsOpen(!isOpen)}
                disabledConfirm={
                  isDisabledButtonCreate(formik.values) || loading
                }
                classNameConfirm={TAG_MANAGER.sistema_btn_salva_editarContrato}
                classNameCancel={TAG_MANAGER.sistema_btn_cancela_editarContrato}
              />
            </Styled.Grid>
          </Styled.Form>
        </>
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default EditContractsTypes
