/* eslint-disable consistent-return */
import {
  formatDate,
  isBrazilianDateFormatValid,
  toastNotificationSuccess,
  updateErrorMessage,
  validateCPF
} from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  getDetailCampaign,
  getRegistrationDetails,
  getStudentsCpf,
  getValidateCoupon,
  registrationStudent
} from 'services/api/admin'
import { isValidAge as isValidAgeHelp } from './components/Content/helpers'
import { MESSAGE, activeStepOne } from './constants'
import { parserBody, parserResponse } from './helpers'

export const handleClose = ({ reason, setShowMessage }) => {
  if (reason === 'clickaway') return
  setShowMessage(false)
}

export const getValidateCoupons = async ({
  data,
  coupon,
  setOpen,
  setIsError,
  setIsValidcoupon,
  setDataCoupon,
  setCouponData
}) => {
  const params = {
    enrollment_id: data?.id,
    coupon
  }

  const { data: response, status } = await getValidateCoupon(params)

  if (status !== 200) {
    setOpen(true)
    return setIsError(true)
  }

  setIsError(false)
  setIsValidcoupon(true)
  setDataCoupon(response?.data)
  return setCouponData(response?.data)
}

export const getDetails = async ({
  id,
  skipped,
  setIsLoad,
  activeStep,
  setSkipped,
  isStepSkipped,
  setActiveStep,
  setDetailsRegistration
}) => {
  setIsLoad(true)
  const { data: response, error } = await getRegistrationDetails(id)
  if (error === undefined) {
    setDetailsRegistration(response)
    const newSkipped = isStepSkipped(activeStep)
      ? new Set(skipped.values()).delete(activeStep)
      : skipped
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }
  setIsLoad(false)
}

export const confirmRegister = async ({
  group,
  skipped,
  setSnack,
  setIsLoad,
  activeStep,
  setSkipped,
  dataCoupon,
  anticipation,
  enrollmentId,
  isStepSkipped,
  setActiveStep,
  financialOfficerType,
  setDetailsRegistration,
  values
}) => {
  setSnack('', '')

  const financialOfficer = financialOfficerType.type

  const dateBirth = isBrazilianDateFormatValid(values.nascimento)
    ? values.nascimento
    : formatDate(values.nascimento)
  const dateBirthFin = isBrazilianDateFormatValid(values.nasc_fin)
    ? values.nasc_fin
    : formatDate(values.nasc_fin)

  const body = parserBody({
    values,
    group,
    enrollmentId,
    dateBirth,
    financialOfficer,
    dateBirthFin,
    dataCoupon,
    anticipation
  })

  const cleanData = cleanObject(body)

  const { data, error, status } = await registrationStudent(
    cleanData,
    financialOfficer
  )

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreatePreEnrollment
    })
  }

  const { id } = data?.data

  toastNotificationSuccess(MESSAGE.SUCCESS, 1500)

  return getDetails({
    id,
    skipped,
    setIsLoad,
    activeStep,
    setSkipped,
    isStepSkipped,
    setActiveStep,
    setDetailsRegistration
  })
}

export const handleNext = ({
  values,
  skipped,
  activeStep,
  setSkipped,
  setActiveStep,
  isStepSkipped,
  setValuesAllForms
}) => {
  const formatedDate = isValidAgeHelp(formatDate(values.nascimento))

  if (values.nascimento) {
    setValuesAllForms({
      ...values,
      financialOfficer: formatedDate
    })
  }

  if (activeStep < activeStepOne) {
    const newSkipped = isStepSkipped(activeStep)
      ? new Set(skipped.values()).delete(activeStep)
      : skipped
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }
}

export const handleBack = ({
  open,
  setOpen,
  activeStep,
  setActiveStep,
  valuesAllForms,
  setValuesAllForms
}) => {
  if (activeStep !== activeStepOne) return setOpen(!open)

  setValuesAllForms({
    ...valuesAllForms,
    financialOfficer: false
  })

  return setActiveStep((prevActiveStep) => prevActiveStep - 1)
}

export const validateCpf = async ({
  cpf,
  setIsLoad,
  courseName,
  setShowError,
  setIsValidCpf,
  valuesAllForms,
  setErrorMessage,
  setValuesAllForms
}) => {
  setIsLoad(true)

  const cleanedCpf = cpf?.replace(/[.-]/g, '')

  if (!validateCPF(cpf)) {
    setIsLoad(false)
    setShowError(true)
    return setErrorMessage(MESSAGE.ERROR_VALIDATE_CPF)
  }

  const {
    data: response,
    error,
    status
  } = await getStudentsCpf({
    cpf: cleanedCpf,
    course: courseName
  })

  setIsLoad(false)

  if (error) {
    const { message } = error
    setShowError(true)
    setErrorMessage(message?.error?.error || MESSAGE.ERROR_VALIDATE_CPF)
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToValidateCPF
    })
  }

  setValuesAllForms({
    ...valuesAllForms,
    ...(response.data !== undefined && parserResponse(response.data))
  })

  return setIsValidCpf(true)
}

export const searchCep = async ({ fetchAddress, cepFin }) => {
  await fetchAddress(cepFin)
}

export const getDetailsCampaign = async ({
  unit,
  setSnack,
  setDetailsCampaign
}) => {
  const params = {
    unit
  }
  const { data, error, status } = await getDetailCampaign(params)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListProfessors
    })
  }
  return setDetailsCampaign(data)
}
