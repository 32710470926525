/* eslint-disable camelcase */
import {
  getCostCenterName,
  getCostCenterNature,
  getCostCenterSubCategory,
  getCostCenterType,
  getCourses
} from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import {
  getBySpecificTypeRequirement,
  getsectorsResponsible
} from 'services/api/admin/management/typesRequirements'
import FEEDBACK_SNACK from 'feedBackSnack'
import { ERROR, SUCCESS } from 'services/constants'
import { parseResponse } from './List/ModalCopy/helpers'
import { parseResponseTypeRequeriment } from './CreateByUpdate/helpers'
import { MESSAGE } from './List/constants'
import { selectListType } from './helpers'

const handleCopyResponse = ({
  setLoadingOpen,
  onSubmit,
  formValues,
  setSnack,
  error,
  data,
  status,
  setOpenModalCreateCopyTypesRequirements,
  setSelected
}) => {
  setLoadingOpen(false)

  if (data[0]?.error) {
    return setSnack(data[0]?.error, ERROR)
  }

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCopyTypesRequirements
    })
  }

  setSelected([])
  setOpenModalCreateCopyTypesRequirements(false)

  setSnack(MESSAGE.SUCCESS_CREATE_COPY, SUCCESS)

  return setTimeout(() => onSubmit(formValues), 3000)
}

const handleGetEdit = async ({
  idRequirement,
  setSnack,
  setLoadingOpen,
  setInitialState,
  unitys
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { data, error, status } = await getBySpecificTypeRequirement(
    idRequirement
  )

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListOccurrenciesTypes
    })
  }

  return setInitialState(parseResponseTypeRequeriment({ ...data, unitys }))
}

const handleCostCenterNature = async ({ setSnack, setListNature }) => {
  setSnack('', '')
  const { data, error, status } = await getCostCenterNature()

  if (error) {
    return updateErrorMessage({ setSnack, error, status })
  }

  const selectListNature = parseResponse(data?.data)

  return setListNature(selectListNature)
}

const handleCostCenter = async ({ setSnack, setListCostCenter }) => {
  setSnack('', '')
  const { data, error, status } = await getCostCenterName()

  if (error) {
    return updateErrorMessage({ setSnack, error, status })
  }

  const SelectListCostCenter = parseResponse(data?.data)

  return setListCostCenter(SelectListCostCenter)
}

const handleTypes = async ({ idCenterCost, setSnack, setListType }) => {
  setSnack('', '')
  const { data, error, status } = await getCostCenterType(idCenterCost)

  if (error) {
    return updateErrorMessage({ setSnack, error, status })
  }

  const newData = selectListType(data?.data)

  return setListType(newData)
}

const handleSubCategory = async ({ idType, setSnack, setListSubcategory }) => {
  setSnack('', '')
  const hasCC5 = true

  const { data, error, status } = await getCostCenterSubCategory(idType)

  if (error) {
    return updateErrorMessage({ setSnack, error, status })
  }

  const newData = selectListType(data?.data, hasCC5)

  return setListSubcategory(newData)
}

const handleSectorsResponsible = async ({ setSnack, setSectors }) => {
  setSnack('', '')
  const { data, error, status } = await getsectorsResponsible()

  if (error) {
    return updateErrorMessage({ setSnack, error, status })
  }

  const selectSectors = data?.reduce((acc, cur) => {
    const { id, sector, responsibles } = cur
    const newItem = { value: id, label: sector, responsibles }
    return [...acc, newItem]
  }, [])

  return setSectors(selectSectors)
}

const handleCourse = async ({ setSnack, setCourses, level, unit, unitys }) => {
  setSnack('')
  const isName = true

  const unitId = unitys?.find((i) => i.value === unit)

  const { data, error, status } = await getCourses({
    level,
    unit_id: unitId?.id
  })

  if (error) {
    return updateErrorMessage({ setSnack, error, status })
  }

  return setCourses(parseResponse(data?.data, isName))
}

export {
  handleGetEdit,
  handleCopyResponse,
  handleCourse,
  handleCostCenter,
  handleCostCenterNature,
  handleTypes,
  handleSubCategory,
  handleSectorsResponsible
}
