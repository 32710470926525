import { NAME, REGISTRATION } from './constants'

const initialValue = {
  searchType: REGISTRATION,
  searchBy: '',
  unit: '',
  beginAt: '',
  endAt: '',
  done: false
}

const simpleSearchBy = [
  { value: REGISTRATION, label: 'Matrícula' },
  { value: NAME, label: 'Nome' }
]

const typeSearch = {
  [NAME]: {
    typeMask: 'NAME',
    type: 'text',
    label: 'Nome do aluno ou parte dele'
  },
  [REGISTRATION]: {
    typeMask: 'NUMBER',
    type: 'number',
    label: 'Número de matrícula'
  }
}

const headCells = [
  {
    id: REGISTRATION,
    numeric: false,
    disabled: false,
    label: 'Matrícula'
  },
  {
    id: NAME,
    numeric: false,
    disabled: false,
    label: 'Nome'
  },
  {
    id: 'solicitation_date',
    numeric: false,
    disabled: false,
    label: 'Solicitação'
  },
  {
    id: 'requirement_number',
    numeric: false,
    disabled: false,
    label: 'Requerimento'
  },
  {
    id: 'unit',
    numeric: false,
    disabled: false,
    label: 'Unidade'
  },
  {
    id: 'school_class_code',
    numeric: false,
    disabled: false,
    label: 'Turma'
  },
  {
    id: 'school_class_course',
    numeric: false,
    disabled: false,
    label: 'Curso'
  }
]

export { initialValue, simpleSearchBy, typeSearch, headCells }
