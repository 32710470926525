import { clone } from 'services/helpers'
import { utilsPDF } from 'components/templates/PDF/shared/utils'
import buildSignatureBase from '../../../shared/BasePage/Signature'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export const buildSignature = ({ signatures, city, withSignature }) => {
  const signature = buildSignatureBase(signatures, city, withSignature)

  return {
    id: 'signatureInfo',
    table: {
      body: [
        [
          {
            text: 'O presente documento não contem emendas ou rasuras. Ficam para todos os efeitos inválidos os espaços inutilizados.',
            border: [true, true, true, false],
            alignment: 'center'
          }
        ],
        [{ stack: [...clone(signature)], border: [true, false, true, true] }]
      ],
      widths: '*'
    },
    layout: {
      ...borderLayoutDefault,
      ...paddingLayoutDefault
    }
  }
}
