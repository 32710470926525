import { formatDateEnv, updateErrorMessage } from 'services/helpers'
import { SUCCESS } from 'services/constants'
import { defaultPage } from './constants'

const getParams = (values, page, filter) => {
  const { cpf, dueDate, endDate, occurrenceNumber, category } = values || {}

  return {
    ...(cpf && { cpf }),
    ...(occurrenceNumber && { id: occurrenceNumber }),
    ...(dueDate && { start_date: formatDateEnv(dueDate) }),
    ...(endDate && { end_date: formatDateEnv(endDate) }),
    ...(category && { occurrence_category: category }),
    page,
    limit: filter
  }
}

const fetchData = async ({
  setLoading,
  setSnack,
  payload,
  page,
  onSubmit,
  formValues,
  setPage,
  onResetAction,
  setSelected,
  endpoint,
  messageSuccess,
  feedbackMessage
}) => {
  setSnack('', '')
  setLoading(true)

  const { error, status } = await endpoint(payload)
  setLoading(false)
  if (!error) {
    if (page === defaultPage) {
      onSubmit(formValues)
      return setSnack(messageSuccess, SUCCESS)
    }
    setPage(defaultPage)
    onResetAction()
    setSelected([])
    return setSnack(messageSuccess, SUCCESS)
  }

  return updateErrorMessage({
    setSnack,
    error,
    status,
    feedbackMessage
  })
}

export { getParams, fetchData }
