import { useState, lazy, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Form as FormikForm, Formik } from 'formik'
import { Search } from '@mui/icons-material'
import FEEDBACK_SNACK from 'feedBackSnack'
import { EmptyState } from 'components/molecules'
import {
  IsEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import { usePaginationBasic } from 'services/hooks'
import { ROUTE, SUCCESS } from 'services/constants'
import { getListOccurrence } from 'services/api/admin'
import { useStudentOccurrence } from '../Store/useStudentOccurrence'
import {
  MESSAGE,
  countDefault,
  defaultItemsPerPage,
  occurrenceCreatedSuccess
} from './constants'
import { getParams } from './services'
import { initialValues } from './config'
import { paramsUrl } from './helpers'

const Form = lazy(() => import('./components/Form'))
const Table = lazy(() => import('./components/Table'))

const ListOccurrences = ({ snackProps, setLoading, params, register }) => {
  const location = useLocation()
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [list, setList] = useState([])
  const { snackOpen, setSnack } = snackProps

  const { formValues, setFormValues, resetFormValues, open, toggleOpen } =
    useStudentOccurrence()

  const { page, totalPage, items, setPage } = usePaginationBasic(
    list,
    filter,
    count
  )

  const isSearchParam = location?.state?.savedSearch || false

  const onSubmit = async (values) => {
    setList([])
    setLoading(true)
    const inactive = values?.inactive
    const type = null

    const { error, status, data } = await getListOccurrence(
      getParams({ register, values, page, filter }),
      type,
      inactive
    )
    setLoading(false)
    setFormValues(values)

    if (data?.count === countDefault) {
      setFormValues(values)
      return setList([])
    }

    if (data?.data) {
      setList(data?.data)
      return setCount(data?.count)
    }

    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListOccurrence
    })
  }

  useEffect(() => {
    if (open) {
      setSnack(occurrenceCreatedSuccess, SUCCESS)
    }
  }, [open])

  useEffect(() => {
    if (open && !snackOpen) {
      toggleOpen()
    }
  }, [open, snackOpen])

  useEffect(() => {
    if (!isSearchParam) {
      resetFormValues()
      return onSubmit(formValues)
    }

    return isSearchParam && onSubmit(formValues)
  }, [isSearchParam, page, filter])

  const initialState = mergeInitialValues(
    initialValues,
    isSearchParam && formValues
  )

  const toUrl = `${ROUTE.SECRETARY_REGISTER_OCCURRENCES_CREATE}?${paramsUrl(
    params
  )}`

  return (
    <>
      {/* SERÁ AJUSTADO QUANDO HOUVER O PARAMETRO */}
      {/* <EmptyState
        labelEmptyState="Não encontramos nenhuma ocorrência nos registros desta ficha"
        toUrl={toUrl}
        labelBtn="Nova ocorrência"
      /> */}

      <Formik
        initialValues={initialState}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(props) => (
          <FormikForm
            onSubmit={props.handleSubmit}
            noValidate
            autoComplete="off"
          >
            <Form
              {...props}
              setFilter={setFilter}
              setPage={setPage}
              setSnack={setSnack}
              toUrl={toUrl}
            />
          </FormikForm>
        )}
      </Formik>
      {list && !IsEmpty(list) ? (
        <Table
          rows={items}
          filter={filter}
          snackProps={snackProps}
          setFilter={setFilter}
          setPage={setPage}
          page={page}
          totalPage={totalPage}
          count={count}
          setSnack={setSnack}
          setLoading={setLoading}
          onSubmit={onSubmit}
        />
      ) : (
        <EmptyState
          icon={<Search />}
          labelEmptyState={MESSAGE.NOT_FOUND_SEARCH}
        />
      )}
    </>
  )
}

export default ListOccurrences
