import * as Styled from './style'

const StepOne = () => (
  <Styled.Container>
    <Styled.BackgroundImage src="/images/onboarding_slide_1.svg" alt="#" />

    <Styled.Message>
      <Styled.MessageText>
        Estamos muito felizes em ter você com a gente no novo ERP da Proz!
      </Styled.MessageText>
      <Styled.MessageImage
        src="/images/onboarding_slide_1_ilustracoes.svg"
        alt="#"
      />
    </Styled.Message>
  </Styled.Container>
)

export default StepOne
