import { Box, Grid, Typography } from '@mui/material'
import { Button } from 'components/atoms'
import { isEmpty } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'
import { initialValues } from '../../constants'

const BoxInfo = ({
  subject,
  setIsEdit,
  setValuesModal,
  setOpenCreateByEdit,
  listContentEad
}) => (
  <Box>
    <Grid container spacing={{ xs: 0, sm: 2 }}>
      <Grid item xs={12} sm={12} md={!isEmpty(listContentEad) ? 8 : 12}>
        <Styled.Title variant="h5" component="h5">
          Cadastro de Conteúdo EAD
        </Styled.Title>
      </Grid>

      {!isEmpty(listContentEad) && (
        <Grid item xs={12} sm={12} md={4}>
          <Styled.BoxButton>
            <Button
              className={TAG_MANAGER.gestao_btn_cadastrar_novoConteudoEAD}
              value="Novo  Conteúdo"
              onClick={() => {
                setIsEdit(false)
                setValuesModal(initialValues)
                setOpenCreateByEdit(true)
              }}
            />
          </Styled.BoxButton>
        </Grid>
      )}

      <Styled.GridText item xs={12} sm={12} md={4}>
        <Styled.Box>
          <Typography variant="subtitle1" component="subtitle1">
            <b>Disciplina</b>
          </Typography>

          <Typography variant="body1" component="body1">
            {subject.discipline || '---'}
          </Typography>
        </Styled.Box>
      </Styled.GridText>

      <Styled.GridText item xs={12} sm={12} md={2}>
        <Styled.Box>
          <Typography variant="subtitle1" component="subtitle1">
            <b>Nível</b>
          </Typography>

          <Typography variant="body1" component="body1">
            {subject.level || '---'}
          </Typography>
        </Styled.Box>
      </Styled.GridText>

      <Styled.GridText item xs={12} sm={12} md={3}>
        <Styled.Box>
          <Typography variant="subtitle1" component="subtitle1">
            <b>Cursos</b>
          </Typography>

          <Typography variant="body1" component="body1">
            {subject.courses || '---'}
          </Typography>
        </Styled.Box>
      </Styled.GridText>

      <Styled.GridText item xs={12} sm={12} md={3}>
        <Styled.Box>
          <Typography variant="subtitle1" component="subtitle1">
            <b>Carga horária</b>
          </Typography>

          <Typography variant="body1" component="body1">
            {`${subject.workload_hour}h` || '---'}
          </Typography>
        </Styled.Box>
      </Styled.GridText>
    </Grid>
  </Box>
)

export { BoxInfo }
