import { getCostCenterSubCategory, getCostCenterType } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'

const handleTypes = async ({ idCenterCost, setListType }) => {
  const { data, error, status } = await getCostCenterType(idCenterCost)

  if (error) {
    return updateErrorMessage({ error, status })
  }

  return setListType(data?.data)
}

const handleSubCategory = async ({ idType, setListSubcategory }) => {
  const { data, error, status } = await getCostCenterSubCategory(idType)

  if (error) {
    return updateErrorMessage({ error, status })
  }

  return setListSubcategory(data?.data)
}

export { handleTypes, handleSubCategory }
