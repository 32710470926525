import theme from 'theme/designTokens'
import { formatDate } from 'services/helpers'
import { FREQUENCY_STATUS } from 'services/constants/frequency'
import { DOT_SVG } from '../../constants'

const handleStatus = ({ status }) => {
  if (!status) return { text: '-' }

  const statusMap = {
    [FREQUENCY_STATUS.PRESENCE]: {
      svg: DOT_SVG
    },
    [FREQUENCY_STATUS.ABSENCE]: {
      text: 'F',
      color: theme.palette.error.main
    },
    [FREQUENCY_STATUS.JUSTIFY]: {
      text: 'F',
      color: theme.palette.success.main
    }
  }

  return statusMap[status?.toUpperCase()] ?? { text: '-' }
}

const buildTableBody = (students) =>
  students.map((student, index) => [
    { text: index + 1, style: ['tableBodyCell'] },
    { text: student.name, colSpan: 2, style: ['tableBodyCell'] },
    {},
    ...student.frequencies.map((frequency) => ({
      ...handleStatus({
        status: frequency?.status
      }),
      alignment: 'center',
      style: ['tableBodyCell']
    })),
    {
      stack: [
        {
          text: student.status ?? '',
          alignment: 'center',
          style: ['tableBodyCell']
        },
        {
          text: student.statusUpdatedAt
            ? formatDate(student.statusUpdatedAt)
            : '',
          alignment: 'center',
          style: ['tableBodyCell']
        }
      ]
    }
  ])
export default buildTableBody
