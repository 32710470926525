import { useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2, Select } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getAtaNovoTec, openPartialReportPDF } from '../../../services'
import { initialValues, optionTypeFile } from './constants'
import * as Styled from './style'
import getSchema from './schema'
import { getBimesterMap, getBimesterPeriodText } from './helpers'

const ModalAtaNovoTec = ({
  handleClose,
  open,
  setSnack,
  setLoadingOpen,
  id,
  staticFormValues
}) => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const isNovotecFrom2024 = true
  // const isNovotecFrom2024 =
  //   staticFormValues.unit === NOVOTEC_UNIT &&
  //   new Date(staticFormValues.beginAt).getFullYear() >= START_YEAR_FOR_NOVOTEC

  const submit = (values) => {
    setLoadingOpen(true)

    if (isNovotecFrom2024) {
      return openPartialReportPDF({
        classId: params.id,
        endDate: values.endDate,
        startDate: values.startDate,
        onError: ({ error, status }) => {
          setLoadingOpen(false)
          updateErrorMessage({
            setSnack,
            error,
            status,
            feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToPartialReport
          })
        },
        onFinish: () => setLoadingOpen(false)
      })
    }

    return getAtaNovoTec({
      setSnack,
      setLoadingOpen,
      values,
      id,
      handleClose
    })
  }

  const formki = useFormik({
    initialValues,
    onSubmit: (values) => submit(values),
    enableReinitialize: true,
    validationSchema: getSchema({ isNovotecFrom2024, unit: params.unit })
  })

  const periodBimester = getBimesterMap(params.unit)

  return (
    <Styled.Modal open={open} onClose={handleClose}>
      <form onSubmit={formki.handleSubmit} noValidate autoComplete="off">
        <Styled.Grid>
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12}>
              <Styled.Title variant="h5" component="h2">
                Ata de Resultados Parcial
              </Styled.Title>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2">
                Escolha o período para as ata parcial que você deseja gerar:
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <DatePicker
                fullWidth
                id="startDate"
                label={isNovotecFrom2024 ? 'De' : 'De (opcional)'}
                value={formki.values?.startDate || null}
                onChange={(newValue) => {
                  formki.setFieldValue('startDate', newValue)
                }}
                error={formki.errors?.startDate}
                helperText={formki.errors?.startDate}
              />
            </Grid>

            <Grid item xs={4}>
              <DatePicker
                fullWidth
                id="endDate"
                label={isNovotecFrom2024 ? 'Até' : 'Até (opcional)'}
                value={formki.values?.endDate || null}
                onChange={(newValue) => {
                  formki.setFieldValue('endDate', newValue)
                }}
                error={formki.errors?.endDate}
                helperText={formki.errors?.endDate}
              />
            </Grid>

            <Grid item xs={3}>
              <Select
                id="typeFile"
                label="Formato"
                value={formki.values?.typeFile || 'pdf'}
                optionsList={optionTypeFile}
                onChange={(e) =>
                  formki.setFieldValue('typeFile', e.target.value)
                }
              />
            </Grid>
            {isNovotecFrom2024 && (
              <Grid item xs={12}>
                <Typography fontWeight={600} variant="body2">
                  É necessário selecionar as datas exatas de início e fim dos
                  bimestres:
                </Typography>
                {periodBimester &&
                  Object?.entries(periodBimester)?.map(([year, bimesters]) =>
                    Object.entries(bimesters).map(
                      ([bimester, { begin, end }]) => (
                        <Typography variant="body2">
                          {getBimesterPeriodText({
                            bimester,
                            begin,
                            end,
                            year
                          })}
                        </Typography>
                      )
                    )
                  )}
              </Grid>
            )}
          </Grid>

          <Styled.Box>
            <ButtonFooterV2
              labelClose="Cancelar"
              labelConfirm="Confirmar"
              size="medium"
              onClickClose={handleClose}
            />
          </Styled.Box>
        </Styled.Grid>
      </form>
    </Styled.Modal>
  )
}

export { ModalAtaNovoTec }
