export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const defaultTotalPage = 1
export const defaultItemsPerPage = 10

const headCellsPending = [
  {
    id: 'class_code',
    label: 'Turma'
  },
  {
    id: 'unit_name',
    label: 'Unidade'
  },
  {
    id: 'class_group',
    label: 'Grupo'
  },
  {
    id: 'course_name',
    label: 'Curso'
  },
  {
    id: 'shift',
    label: 'Turno'
  },
  {
    id: 'students',
    label: 'Alunos'
  }
]

export { headCellsPending }
