import { ListAlt, Search } from '@mui/icons-material'

const initialStateData = { activities: [] }

const initialStateStatus = { empty: true, notFound: false }

const initialStateModal = { open: false, item: null, isEdit: false }

const nameAccordion = 'list_grouping'

const messages = {
  empty: {
    icon: <ListAlt />,
    text: '<strong>Você ainda não fez uma busca</strong><br />Selecione uma unidade para visualizar as turmas agrupadas'
  },
  notFound: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
  }
}

const textModalConfirm = {
  title: 'Excluir Agrupamento',
  message: 'Deseja realmente excluir este agrupamento?',
  buttonConfirm: 'Sim',
  buttonNotConfirm: 'Não'
}

export {
  initialStateData,
  initialStateStatus,
  nameAccordion,
  messages,
  textModalConfirm,
  initialStateModal
}
