import { School } from '@mui/icons-material'
import { ACL } from 'services/constants/acl'
import { ROUTE } from 'services/constants'
import theme from 'theme/designTokens'
import {
  Dashboard,
  Enrollment,
  DashboardClass,
  ListRequisition,
  ListOccurrences,
  CreateOccurrences,
  UpdateOccurrence,
  CreateRequisition,
  EditRequisition,
  ListStudents,
  ListClassDiscipline,
  ListClassDisciplineToLink,
  StudentRegister,
  StudentScore,
  ListSchoolClass,
  UpdateStudentsOccurrence,
  CreateStudentsOccurrence,
  CreateByUpdateClass,
  Reenroll,
  EditDiscipline,
  ClassDetails,
  UseOfStudies,
  AdditionalActivitiesAvaliation,
  AdditionalActivities,
  JustifyAbsence,
  ListDocuments,
  BindByUnbinded,
  ListContracts,
  NewDocumentHistory,
  History,
  Grouping,
  GroupingManager,
  GroupingIncludeDiscipline,
  StudentInteractions,
  ListAcademicPerformances,
  EditAcademicPerformance
} from 'components/pages/Admin'
import EditStudent from 'components/pages/Admin/Secretary/Students/EditStudent'
import CreateStudent from 'components/pages/Admin/Secretary/Students/CreateStudent'
import CreateExpedition from 'components/pages/Admin/Secretary/Students/CreateExpedition'
import FinalizeCreatStudent from 'components/pages/Admin/Secretary/Students/FinalizeCreatStudent'
import StudentDeclaration from 'components/pages/Admin/Secretary/Students/StudentDeclaration'
import CreateDesclaration from 'components/pages/Admin/Secretary/Students/CreateDesclaration'
import { PageDiaryProfessor } from 'components/organisms'
import isAllowUserAccess from '../helpers/isAllowUserAccess'

export const iconProps = {
  color: theme.palette.primary.white
}

const SECRETARY_ROUTES = [
  {
    component: Enrollment,
    exact: true,
    icon: <School sx={{ ...iconProps }} />,
    label: 'Secretaria',
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY'),
    submenu: [
      {
        component: ListStudents,
        exact: true,
        label: 'Alunos',
        path: ROUTE.ADMIN_SECRETARY_STUDENTS,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS')
      },
      {
        component: ListSchoolClass,
        exact: true,
        label: 'Turmas',
        path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SECRETARY_SCHOOL_CLASS')
      },
      {
        component: ListOccurrences,
        exact: true,
        label: 'Ocorrências',
        path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SECRETARY_OCCURRENCES')
      },
      {
        component: ListRequisition,
        exact: true,
        label: 'Requerimento',
        path: ROUTE.ADMIN_SECRETARY_REQUISITION,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SECRETARY_REQUISITION')
      },
      {
        component: AdditionalActivitiesAvaliation,
        exact: true,
        label: 'Atividades complementares',
        path: ROUTE.ADMIN_SECRETARY_ADDITIONAL_ACTIVITIES_AVALIATION,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(
            userPermissions,
            'SECRETARY_STUDENTS_ADDITIONAL_ACTIVITY_AVALIATION'
          )
      },
      {
        component: ListDocuments,
        exact: true,
        label: 'Documentos Pessoais',
        path: ROUTE.ADMIN_SECRETARY_DOCUMENTS,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SECRETARY_DOCUMENT_LIST')
      },
      {
        component: ListContracts,
        exact: true,
        label: 'Contratos',
        path: ROUTE.ADMIN_SECRETARY_CONTRACTS,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SECRETARY_CONTRACT_LIST')
      },
      {
        component: AdditionalActivitiesAvaliation,
        exact: true,
        label: 'Atividades complementares',
        path: ROUTE.ADMIN_SECRETARY_ADDITIONAL_ACTIVITIES_AVALIATION,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(
            userPermissions,
            'SECRETARY_STUDENTS_ADDITIONAL_ACTIVITY'
          )
      },
      {
        component: Grouping,
        exact: true,
        label: 'Agrupamentos',
        path: ROUTE.ADMIN_SECRETARY_GROUPING,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_GROUPING')
      },
      {
        component: ListAcademicPerformances,
        exact: true,
        label: 'Aproveitamento de Estudos',
        path: ROUTE.ADMIN_SECRETARY_ACADEMIC_PERFORMANCES,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SECRETARY_ACADEMIC_PERFORMANCES')
      },
      {
        component: Grouping,
        exact: true,
        label: 'Agrupamentos',
        path: ROUTE.ADMIN_SECRETARY_GROUPING,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_GROUPING')
      }
    ]
  },
  {
    component: GroupingManager,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_GROUPING_MANAGER,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_GROUPING')
  },
  {
    component: GroupingIncludeDiscipline,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_GROUPING_INCLUDE_DISCIPLINE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_GROUPING')
  },
  {
    component: PageDiaryProfessor,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_GROUPING_DIARY,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_GROUPING')
  },
  {
    component: EditDiscipline,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_EDIT_DISCIPLINE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(
        userPermissions,
        'SECRETARY_SCHOOL_CLASS_EDIT_DISCIPLINE'
      )
  },
  {
    component: CreateStudent,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_CREATE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_CREATE')
  },
  {
    component: FinalizeCreatStudent,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_FINALIZE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_CREATE')
  },
  {
    component: EditStudent,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_EDIT')
  },
  {
    component: StudentDeclaration,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_DECLARATION,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_DECLARATION')
  },
  {
    component: CreateDesclaration,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_CREATE_DECLARATION,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENT_DECLARATION')
  },
  {
    component: PageDiaryProfessor,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_PROFESSOR_DIARY,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_SCHOOL_CLASS')
  },
  {
    component: ClassDetails,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_SCHOOL_CLASS')
  },
  {
    component: BindByUnbinded,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_BIND_UNBINDED,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_SCHOOL_CLASS_BIND_UNBINDED')
  },
  {
    component: EditDiscipline,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_EDIT_DISCIPLINE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(
        userPermissions,
        'SECRETARY_SCHOOL_CLASS_EDIT_DISCIPLINE'
      )
  },
  {
    component: CreateStudent,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_CREATE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_CREATE')
  },
  {
    component: CreateExpedition,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER_EXPEDITION,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_EXPEDITION_CREATE')
  },
  {
    component: FinalizeCreatStudent,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_FINALIZE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_CREATE')
  },
  {
    component: EditStudent,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_EDIT')
  },
  {
    component: Reenroll,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_REENROLL,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_SCHOOL_REENROLL')
  },
  {
    component: CreateByUpdateClass,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_CREATE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_SCHOOL_CLASS_CREATE')
  },
  {
    component: CreateOccurrences,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES_CREATE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_OCCURRENCES_CREATE')
  },

  {
    component: UpdateOccurrence,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_OCCURRENCES_EDIT')
  },
  {
    component: CreateRequisition,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_REQUISITION_CREATE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_REQUISITION_CREATE')
  },
  {
    component: EditRequisition,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_REQUISITION_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_REQUISITION_EDIT')
  },
  {
    component: ListClassDiscipline,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_CLASS_DISCIPLINE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: () => true
  },
  {
    component: ListClassDisciplineToLink,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_CLASS_DISCIPLINE_LINK,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: () => true
  },
  {
    component: StudentRegister,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_REGISTER')
  },
  {
    component: StudentScore,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_SCORE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_REGISTER')
  },
  {
    component: AdditionalActivities,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_ADDITIONAL_ACTIVITIES,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(
        userPermissions,
        'SECRETARY_STUDENTS_ADDITIONAL_ACTIVITY'
      )
  },
  {
    component: JustifyAbsence,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_JUSTIFY_ABSENCE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_JUSTIFY_ABSENCE')
  },
  {
    component: CreateStudentsOccurrence,
    exact: true,
    path: ROUTE.SECRETARY_REGISTER_OCCURRENCES_CREATE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(
        userPermissions,
        'SECRETARY_REGISTER_OCCURRENCES_CREATE'
      )
  },
  {
    component: UpdateStudentsOccurrence,
    exact: true,
    path: ROUTE.SECRETARY_REGISTER_OCCURRENCES_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_REGISTER_OCCURRENCES_EDIT')
  },
  {
    component: History,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_HISTORY,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_HISTORY')
  },
  {
    component: UseOfStudies,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_USE_OF_STUDIES,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_USE_OF_STUDIES_LIST')
  },
  {
    component: EditAcademicPerformance,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_ACADEMIC_PERFORMANCES_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_ACADEMIC_PERFORMANCES_EDIT')
  },
  {
    component: NewDocumentHistory,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_NEW_DOCUMENT_HISTORY,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS_HISTORY')
  },
  {
    component: EditAcademicPerformance,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_ACADEMIC_PERFORMANCES_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_ACADEMIC_PERFORMANCES_EDIT')
  },
  {
    component: Dashboard,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_CLASSES_DASHBOARD,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'ADMIN_SECRETARY_CLASSES_DASHBOARD')
  },
  {
    component: DashboardClass,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_CLASSES_DASHBOARD_CLASS,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(
        userPermissions,
        'ADMIN_SECRETARY_CLASSES_DASHBOARD_CLASS'
      )
  },
  {
    component: StudentInteractions,
    exact: true,
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_INTERACTIONS,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'SECRETARY_STUDENTS')
  }
]

export default SECRETARY_ROUTES
