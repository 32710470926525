import styled from 'styled-components'
import { TextField, TableCell as MuiTableCell } from '@mui/material'

const Input = styled(TextField)`
  width: 600px;
  .MuiInputAdornment-root {
    display: none;
  }
  &:hover .MuiInputAdornment-root {
    display: flex;
  }
`
const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: center;
  }
`

const TableCellDescription = styled(MuiTableCell)`
  &.MuiTableCell-root {
    width: 600px;
  }
`

export { Input, TableCell, TableCellDescription }
