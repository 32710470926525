import styled from 'styled-components'
import {
  Paper as MuiPaper,
  TableContainer as MuiTableContainer,
  TableFooter as MuiTableFooter,
  Box
} from '@mui/material'
import theme from 'theme/designTokens'

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
    margin-top: 20px;
  }
`

const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    margin-top: 24px;
  }
`

const TableFooter = styled(MuiTableFooter)`
  &.MuiTableFooter-root {
    background-color: ${() => theme.palette.primary.grayLight};

    & .MuiTypography-root {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
`

const BoxButton = styled(Box)`
  &.MuiBox-root {
    display: flex;
    justify-content: center;

    & .MuiButtonBase-root {
      min-width: 34px;
      padding: 6px 7px;
    }
  }
`

const BoxSumHours = styled.div`
  margin: 0 72px 0 0;
  color: ${() => theme.palette.dark.main};

  & span {
    margin: 0 0 0 32px;
  }
`

export { Paper, TableContainer, TableFooter, BoxButton, BoxSumHours }
