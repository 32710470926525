import styled from 'styled-components'
import theme from 'theme/designTokens'
import { IconButton } from '@mui/material'
import { pathCheck } from 'services/helpers'

const CardContainer = styled.div`
  min-width: 180px;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: ${pathCheck(['palette', 'background', 'white'], theme)};
`

const CardContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  padding: ${({ paddingHeader }) => paddingHeader || '16px 16px 0 16px'};
  height: 70%;
`

const CardContainerFooter = styled.div`
  text-align: center;
  height: 30%;
`

const CardContainerContent = styled.div``

const ButtonClose = styled(IconButton)`
  display: flex;
  align-items: flex-start;
`

export {
  CardContainerContent,
  CardContainer,
  CardContainerHeader,
  CardContainerFooter,
  ButtonClose
}
