import PropTypes from 'prop-types'
import { Form as FormikForm, Formik } from 'formik'
import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { useMakeService } from 'services/hooks'
import { InputSwitch } from 'components/atoms'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import { defaultValues, makeServiceHookParams } from './config'
import * as Styled from './style'

export default function SearchForm({ handleSearch }) {
  const { unitys } = useMakeService(makeServiceHookParams)

  const isDisabled = ({ unityId }) => !unityId

  const handleResetForm = (resetForm) => {
    handleSearch({})
    resetForm()
  }

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      onSubmit={handleSearch}
    >
      {({ handleChange, handleSubmit, resetForm, setFieldValue, values }) => (
        <FormikForm autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Grid item xs={12} sm={12} md={4}>
              <Select
                label="Unidade*"
                onChange={(e) => setFieldValue('unityId', e.target.value)}
                optionsList={unitys}
                value={values.unityId}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                id="sectorName"
                label="Setor"
                onChange={handleChange}
                value={values?.sectorName || ''}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Styled.WrapperButtons>
                <InputSwitch
                  checked={values?.inactive}
                  label="Inativos"
                  onClick={() => setFieldValue('inactive', !values?.inactive)}
                />
                <ButtonFooterV2
                  disabledClose={isDisabled(values)}
                  disabledConfirm={isDisabled(values)}
                  labelClose="Limpar"
                  labelConfirm="Buscar"
                  onClickClose={() => handleResetForm(resetForm)}
                  size="large"
                  startIcon={<Clear />}
                />
              </Styled.WrapperButtons>
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  )
}

SearchForm.propTypes = {
  handleSearch: PropTypes.func
}

SearchForm.defaultProps = {
  handleSearch: () => {}
}
