import { useState } from 'react'
import { indexDefault } from './constants'

const useCheckedTable = (listSortTable, nameHeadCells) => {
  const [selected, setSelected] = useState([])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = listSortTable?.map((n) => n[nameHeadCells])
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (id) => {
    const selectedIndex = selected?.indexOf(id)
    const shouldRemove = selectedIndex !== indexDefault

    const selectedItems = () =>
      shouldRemove
        ? selected?.filter((_, index) => index !== selectedIndex)
        : [...selected, id]

    setSelected(selectedItems)
  }

  const isSelected = (name) => selected?.indexOf(name) !== indexDefault

  return {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected,
    setSelected
  }
}

export default useCheckedTable
