const noteSuccessfullyUpdated = 'Nota atualizada com sucesso'
const alertData =
  'Não será possível atualizar, pois o id da atividade não foi encontrado, veja se houve alteração na ativade!'
const initialValue = 1
const maxGradeValue = 1
const getFirstkeysActivities = [0]
const NOVOTECH = 932

export {
  noteSuccessfullyUpdated,
  initialValue,
  getFirstkeysActivities,
  NOVOTECH,
  maxGradeValue,
  alertData
}
