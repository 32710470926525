import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Tabs, Tab, Box } from '@mui/material'

const TabView = (props) => {
  const { tabs, tabName, fullWidth, initialParams } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [value, setValue] = useState(tabs[0]?.value)

  const newTabName = tabName ?? 'tab'
  const searchParams = new URLSearchParams(location.search)
  const tabParam = searchParams.get(newTabName)

  const handleChange = (_, newValue) => {
    setValue(newValue)
    searchParams.set(newTabName, newValue)
    navigate(`${location.pathname}?${searchParams.toString()}`)
  }

  useEffect(() => {
    if (tabParam) {
      setValue(tabParam)
    }
    if (!tabParam && initialParams) {
      searchParams.set(newTabName, initialParams)
      navigate(`${location.pathname}?${searchParams.toString()}`)
    }
  }, [])

  const tabActive = tabs?.reduce((acc, tab) => {
    acc[tab?.value] = <tab.component {...props} tabActive={value} />
    return acc
  }, {})

  return (
    <Box
      style={{
        ...{ width: fullWidth && '100%' }
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        {tabs?.map((tab) => (
          <Tab
            key={tab?.value}
            value={tab?.value}
            label={tab?.label}
            className={tab?.className}
          />
        ))}
      </Tabs>
      <Box mt={4} mb={2}>
        {tabActive[value]}
      </Box>
    </Box>
  )
}

export default TabView
