import { Typography as MuiTypography } from '@mui/material'
import styled from 'styled-components'

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }
`

export { Typography }
