/* eslint-disable import/no-cycle */
import Tabs from './Tabs'

const LEVEL_TYPES = {
  HIGH_SCHOOL: 'highSchool',
  ELEMENTARY_SCHOOL: 'elementarySchool'
}

const fieldsNameForm = {
  studentData: 'studentData',
  affiliation: 'affiliation',
  schoolData: 'schoolData',
  financialOfficer: 'financialOfficer',
  fieldNameListResponsibles: 'financialOfficerResponsibles',
  fieldNameResponsibleEdit: 'financialOfficerResponsibleEdit'
}

const TABS = [
  {
    label: 'Dados do Aluno',
    value: fieldsNameForm.studentData,
    component: Tabs.TabStudentData
  },
  {
    label: 'Filiação',
    value: fieldsNameForm.affiliation,
    component: Tabs.TabAffiliation
  },
  {
    label: 'Dados Escolares',
    value: fieldsNameForm.schoolData,
    component: Tabs.TabSchoolData
  },
  {
    label: 'Responsável Financeiro',
    value: fieldsNameForm.financialOfficer,
    component: Tabs.TabFinancialOfficer
  }
]

const defaultlistPerPage = 10

const TYPE_RESPONSIBLES = {
  STUDENT: 'student',
  PHYSICAL_PERSON: 'natural_person',
  LEGAL_PERSON: 'legal_person'
}

const listTypeOfResponsible = [
  {
    label: 'O aluno é o responsável financeiro',
    fieldName: TYPE_RESPONSIBLES.STUDENT
  },
  {
    label: 'Pessoa Física',
    fieldName: TYPE_RESPONSIBLES.NATURAL_PERSON
  },
  {
    label: 'Pessoa Jurídica',
    fieldName: TYPE_RESPONSIBLES.LEGAL_PERSON
  }
]

const tabName = 'tabRegistrationData'

const modalMessage = {
  title: 'Cancelar',
  message:
    'Deseja cancelar a operação? Você perderá as alterações que ainda não foram salvas.'
}

const serviceMessage = {
  successMessage: 'Dados salvo com sucesso.',
  feedbackMessage: 'Houve um erro ao salvar. Tente novamente.'
}

export {
  TABS,
  defaultlistPerPage,
  listTypeOfResponsible,
  TYPE_RESPONSIBLES,
  LEVEL_TYPES,
  fieldsNameForm,
  tabName,
  modalMessage,
  serviceMessage
}
