/* eslint-disable camelcase */
import { getUserFromLocalStorage } from 'services/helpers'

const ENFERMINAS_ID = 85
const TRILHAS_UNIT_ID = 937

export const parseUnits = (units) => {
  const { unit, company_id } = getUserFromLocalStorage()
  const shouldAddTrilhasUnit = company_id === ENFERMINAS_ID && unit !== ''

  const trilhasUnit = {
    id: TRILHAS_UNIT_ID,
    value: TRILHAS_UNIT_ID,
    label: '(MG) Trilhas de Futuro',
    polo: true
  }

  return shouldAddTrilhasUnit ? [...units, trilhasUnit] : units
}
