import React, { Suspense } from 'react'
import { LoadingPage, Snackbar } from 'components/molecules'
import { PageTitle, TextNotFound, ToastComponent } from 'components/atoms'
import { IsEmpty } from 'services/helpers'
import { HeaderPage } from 'components/organisms'
import * as Styled from './style'

const TemplateBillsReceive = ({
  titleHeader,
  loadingOpen,
  snackProps,
  listItems,
  iconInformative,
  messageInformative,
  form,
  table,
  linkHeader,
  classNameHeader,
  labelButton
}) => {
  const { snack, snackOpen } = snackProps

  return (
    <Suspense fallback={<LoadingPage open />}>
      <Styled.Content>
        <LoadingPage open={loadingOpen} />
        {/* 
        <PageTitle title={titleHeader} divider /> */}

        <LoadingPage open={loadingOpen} />

        <HeaderPage
          title={titleHeader}
          labelCallToAction={labelButton}
          classNameCallToAction={classNameHeader}
          linkCallToAction={linkHeader}
          divider
        />

        <div>{form}</div>

        {listItems && IsEmpty(listItems) && (
          <TextNotFound icon={iconInformative} text={messageInformative} />
        )}

        <div>{table}</div>

        {snackProps && (
          <Snackbar
            message={snack.message}
            severity={snack.severity}
            shouldOpen={snackOpen}
          />
        )}
      </Styled.Content>

      <ToastComponent />
    </Suspense>
  )
}

export default TemplateBillsReceive
