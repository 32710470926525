import { AccordionFilter as CompAccordionFilter } from 'components/atoms'
import { Grid as MuiGrid } from '@mui/material'

import styled from 'styled-components'

const AccordionFilter = styled(CompAccordionFilter)`
  .MuiPaper-root {
    margin: 0 0 1px;
    border-radius: 0px;
    box-shadow: 0px 1px 3px 0px #0000001f, 0px 1px 1px 0px #00000024,
      0px 2px 1px -1px #00000033;
  }
`
const GridContainer = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`

export { AccordionFilter, GridContainer }
