import React, { useEffect, useState } from 'react'
import * as Styled from './style'
import { updateGrade } from '../service'

const StudentGrade = ({
  student,
  activityData,
  grade,
  disableActions,
  disciplineId,
  handleScore,
  setAlertInfo
}) => {
  const [gradeValue, setGradeValue] = useState(0)

  const setGrade = (event) => {
    setGradeValue(event.target.value)
  }

  useEffect(() => setGradeValue(grade ? grade.value : 0), [])

  return (
    <Styled.Box>
      <Styled.Input
        type="text"
        size="small"
        disabled={disableActions}
        onChange={setGrade}
        value={gradeValue}
        onBlur={(event) =>
          updateGrade({
            event,
            toEditObj: grade,
            activityData,
            student,
            setGradeValue,
            disciplineId,
            setAlertInfo,
            handleScore
          })
        }
      />

      <Styled.Amount> / {activityData?.amount}</Styled.Amount>
    </Styled.Box>
  )
}

export default StudentGrade
