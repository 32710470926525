import { useNavigate } from 'react-router-dom'
import { TableRow, Tooltip } from '@mui/material'
import { LocationOn } from '@mui/icons-material'
import { ROUTE } from 'services/constants'
import { convertToParams } from 'services/helpers'
import * as Styled from './style'

const TableFooter = ({ row, name, id }) => {
  const navigate = useNavigate()
  const isEmptyPole = row?.pole !== 'Sem Polo'

  return (
    <TableRow hover key={row?.id} tabIndex={-1} role="checkbox">
      <Styled.StyledTableCellTitle>
        <Tooltip title="Acessar turma" placement="top">
          <Styled.BoxLink
            onClick={() =>
              navigate(
                `${
                  ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS_DETAILS
                }?${convertToParams({
                  isEdit: false,
                  name,
                  idProfessor: id,
                  id: row.class_id
                })}`
              )
            }
          >
            {row?.class_code}
          </Styled.BoxLink>
        </Tooltip>
      </Styled.StyledTableCellTitle>

      <Styled.StyledTableCell>
        <Styled.ContainerUnitName>
          {row?.unit_name}
          {row?.pole && isEmptyPole && (
            <Styled.ContainerIcon>
              <Tooltip title={`Polo ${row?.pole}`} placement="top" arrow>
                <LocationOn />
              </Tooltip>
            </Styled.ContainerIcon>
          )}
        </Styled.ContainerUnitName>
      </Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.group_name}</Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.course_name}</Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.class_shift}</Styled.StyledTableCell>
    </TableRow>
  )
}

export default TableFooter
