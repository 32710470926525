import React, { lazy } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowBack, NavigateNext } from '@mui/icons-material'

import { useSnack } from 'services/hooks'
import { Snackbar } from 'components/molecules'
import { HeaderPage } from 'components/organisms'
import { TemplateFinancialBreakdown as Template } from 'components/templates/Admin'

import { breadcrumbsItems } from './constants'

const Content = lazy(() => import('./components/Content'))

const UseOfStudies = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { snack, setSnack, snackOpen } = useSnack()

  const params = Object.fromEntries([...searchParams])

  const { register, schoolUnit, studentName, studentCourse, schoolClassId } =
    params

  const handlGoBack = () => {
    navigate(-1)
  }

  return (
    <Template
      header={
        <HeaderPage
          separator={<NavigateNext size="small" />}
          breadcrumbsNotLink={breadcrumbsItems(studentName, studentCourse)}
          classNameCallToAction="goBack"
          callToAction={handlGoBack}
          startIcon={<ArrowBack />}
          labelCallToAction="voltar"
          divider
        />
      }
      content={
        <Content
          setSnack={setSnack}
          snackOpen={snackOpen}
          registerId={register}
          schoolUnit={schoolUnit}
          nameStudent={studentName}
          schoolClassId={schoolClassId}
        />
      }
      snackAlert={
        <Snackbar
          shouldOpen={snackOpen}
          message={snack.message}
          severity={snack.severity}
        />
      }
    />
  )
}

export default UseOfStudies
