import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import { useEffect } from 'react'
import { confirmConciliation } from '../../services'
import * as Styled from './style'

const PreviewConciliation = ({
  previewNF,
  setOpenModal,
  setLoading,
  setNotSearch,
  setPreviewNF,
  valuesSearch,
  setConfirmedEmptyConciliation,
  setFieldValue,
  setListManager,
  setOptionsReprocessing
}) => {
  const previewNFLength = Object.keys(previewNF).length
  const handleConfirmClick = () => {
    if (previewNFLength) {
      confirmConciliation({
        valuesSearch,
        setLoading,
        setNotSearch,
        setOpenModal,
        setPreviewNF,
        setFieldValue,
        setListManager,
        setOptionsReprocessing
      })
    }

    setFieldValue('file', '')
    setOpenModal(false)
  }

  useEffect(() => {
    if (previewNFLength === 0) {
      setFieldValue('file', '')
      setOpenModal(false)
      return setConfirmedEmptyConciliation(true)
    }

    setOpenModal(true)
    return setConfirmedEmptyConciliation(false)
  }, [previewNF])

  return (
    <Styled.Grid container spacing={{ xs: 2, sm: 2 }}>
      <Grid item xs={12} sm={12} md={12}>
        {previewNFLength !== 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Tipo</TableCell>
                <TableCell>Autorizado</TableCell>
                <TableCell>Cancelado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(previewNF).map((key) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>
                    {previewNF[key]?.autorizado?.join(', ')}
                  </TableCell>
                  <TableCell>{previewNF[key]?.cancelado?.join(', ')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Grid>
      <Grid item xs={12}>
        <ButtonFooterV2
          labelConfirm="Confirmar"
          labelClose="Cancelar"
          onClickClose={() => setOpenModal(false)}
          onClickConfirm={handleConfirmClick}
        />
      </Grid>
    </Styled.Grid>
  )
}

export { PreviewConciliation }
