const defaultValues = {
  unit: '',
  situation: '',
  discipline: '',
  course: '',
  startDate: null,
  endDate: null
}

const makeServiceHookParams = {
  unitsValue: true,
  isUnits: true,
  isLevel: true
}

export { defaultValues, makeServiceHookParams }
