import { ROUTE } from 'services/constants'

const defaultPage = 1
const defaultTotalPage = 10
const defaultItemsPerPage = 10

const MESSAGE = {
  success: 'Turma vinculada com sucesso.'
}

const breadCrumbsItems = ({ isEdit, name, code }) => [
  {
    path: '',
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: 'Professores'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: `${name}`
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR_CLASS_LINKED,
    label: `<b>${isEdit ? 'Editar vínculo' : 'Vincular turma'} </b>`
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR_CLASS_LINKED_DETAILS,
    label: `<b>Turma ${code}</b>`
  }
]

const paginationFilter = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' }
]

export {
  MESSAGE,
  defaultPage,
  paginationFilter,
  breadCrumbsItems,
  defaultTotalPage,
  defaultItemsPerPage
}
