import { useState } from 'react'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { LinkTo } from 'components/atoms'
import { ButtonFooterV2, InputFile, ModalConfirm } from 'components/molecules'
import { makeURLAccessLegacy } from 'services/helpers'
import theme from 'theme/designTokens'
import { getRgba } from 'services/helpers/polished'
import { importFile } from '../../services'
import * as Styled from './style'
import { MESSAGE, REMOVE_CHARACTERS, TYPE_FILE_XLSX } from './constants'
import { defaultValues } from './config'

const FormUpload = ({ setSnack, setLoadingOpen, importConfig }) => {
  const [nameFile, setNameFile] = useState('excel')
  const [errorTypeFile, setErrorTypeFile] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const handleCancelConfirm = () => {
    setShowConfirmDialog(false)
  }

  const handleFileChange = ({ event, setFieldValue }) => {
    if (event === '') {
      return setFieldValue('file', '')
    }

    const selectedFile = event.target.files[0]

    if (selectedFile.type === TYPE_FILE_XLSX) {
      setNameFile(selectedFile.name)
      const readFileAsBase64 = (file) =>
        new Promise((resolve) => {
          const reader = new FileReader()
          reader.onload = () => resolve(reader.result)
          reader.readAsDataURL(file)
        })

      return readFileAsBase64(selectedFile).then((base64String) => {
        setErrorTypeFile(false)
        setFieldValue('file', base64String.slice(REMOVE_CHARACTERS.length))
      })
    }

    return setErrorTypeFile(true)
  }

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={async (values, { resetForm }) => {
        await importFile({
          ...importConfig,
          ...values,
          setSnack,
          setLoadingOpen
        })
        setErrorTypeFile(false)
        resetForm()
      }}
      enableReinitialize
    >
      {({ values, setFieldValue, handleSubmit, dirty }) => {
        const handleConfirmConfirm = async () => {
          setShowConfirmDialog(false)
          handleSubmit()
        }

        return (
          <>
            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
              <Styled.Grid>
                <Grid container spacing={{ xs: 0, sm: 2 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Styled.SubTitle variant="body1" component="div">
                      Adicione o arquivo desejado de {importConfig.name}{' '}
                      conforme o template.
                      <div style={{ fontWeight: '300' }}>&nbsp;(</div>
                      <div style={{ fontWeight: '300' }}>
                        <LinkTo
                          tooltipTitle={`Ultima atualização: ${importConfig.templateUpdatedAt}`}
                          target="_blank"
                          linkTo={importConfig.templatePath}
                          nameLink="baixar template"
                        />
                      </div>
                      <div style={{ fontWeight: '300' }}>).</div>
                    </Styled.SubTitle>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    $errorTypeFile={errorTypeFile}
                  >
                    <InputFile
                      idUpload={`upload-${importConfig.id}`}
                      typeFile="excel"
                      labelBox={errorTypeFile ? MESSAGE.ERROR : MESSAGE.SUCCESS}
                      imgSrc={values?.file || ''}
                      name={importConfig.name}
                      nameFile={nameFile}
                      hasSize
                      errorTypeFile={errorTypeFile}
                      border={`2px dashed ${theme.palette.dark.light}`}
                      backgroundColor={
                        errorTypeFile
                          ? getRgba(theme.palette.red.light, 0.21)
                          : theme.palette.purple.light
                      }
                      width="auto"
                      onChange={(e) =>
                        handleFileChange({ event: e, setFieldValue })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <ButtonFooterV2
                      labelClose="Limpar"
                      labelConfirm="Enviar arquivo"
                      size="large"
                      startIcon={<Clear />}
                      onClickClose={() => {
                        setErrorTypeFile(false)
                        handleFileChange({ event: '', setFieldValue })
                      }}
                      type="button"
                      disabledConfirm={errorTypeFile || !dirty}
                      disabledClose={!dirty}
                      onClickConfirm={() => setShowConfirmDialog(true)}
                    />
                  </Grid>
                </Grid>
              </Styled.Grid>
            </Form>

            <ModalConfirm
              open={showConfirmDialog}
              size="medium"
              textButtonNotConfirm="cancelar"
              textButtonConfirm="enviar"
              onClickNotConfirm={handleCancelConfirm}
              onClickConfirm={handleConfirmConfirm}
              iconWarningAmber
              title="Enviar Arquivo"
              message={`<b>Antes de enviar o arquivo, certifique-se de que o mesmo foi testado e validado em outro ambiente, pois essa ação não poderá ser desfeita.</b><br /><br />Você tem certeza que deseja enviar o arquivo ${nameFile}?`}
            />
          </>
        )
      }}
    </Formik>
  )
}

export default FormUpload
