import { Typography } from '@mui/material'
import * as Styled from './style'

const BoxItem = ({ title, subTitle }) => (
  <Styled.BoxItem>
    <Typography variant="subtitle2" fontWeight="bold">
      {title}
    </Typography>
    <Typography variant="subtitle2">{subTitle}</Typography>
  </Styled.BoxItem>
)
export default BoxItem
