import { ROUTE } from 'services/constants'
import {
  dateFormat,
  formatDateEnv,
  getCurrencyStringMaskedOrOriginalValue,
  getMoneyFormatToNumber,
  updateErrorMessage
} from 'services/helpers'
import {
  modalityAll,
  modalityEad,
  modalityPresential,
  valueZero
} from './constants'

const getParamsCupon = (values, idCupon) => {
  const amountValue = values?.amount
  const eadValue = values?.ead
  const presentialValue = values?.presential

  return {
    id: Number(idCupon) || undefined,
    coupon: idCupon ? undefined : values?.code,
    campaign: values?.campaign,
    start_date: formatDateEnv(values?.beginAt),
    end_date: formatDateEnv(values?.endAt),
    discount: getCurrencyStringMaskedOrOriginalValue(values?.discount),
    type_discount: values?.switch ? 'PORCENTAGEM' : 'VALOR',
    maximum_amount: amountValue === '' ? valueZero : Number(amountValue),
    minimum_installment: getCurrencyStringMaskedOrOriginalValue(
      values?.initialInstallment
    ),
    maximum_installment:
      getCurrencyStringMaskedOrOriginalValue(values?.finalInstallment) ||
      modalityPresential,
    unit: values?.unity,
    level: values?.level || undefined,
    course: values?.course || undefined,
    shift: values?.shift || undefined,
    id_modality:
      (presentialValue && !eadValue && modalityPresential) ||
      (!presentialValue && eadValue && modalityEad) ||
      modalityAll
  }
}

const mergeInitialValues = (values) => {
  const discount = values?.discount
  const idModality = values?.id_modality

  return {
    code: values?.coupon,
    campaign: values?.campaign,
    beginAt: dateFormat(values?.start_date),
    endAt: dateFormat(values?.end_date),
    amount: Number(values?.amount),
    switch: values?.type_discount !== 'VALOR',
    discount:
      discount === valueZero
        ? String(valueZero)
        : getMoneyFormatToNumber(discount),
    initialInstallment: getCurrencyStringMaskedOrOriginalValue(
      values?.minimum_installment
    ),
    finalInstallment: getCurrencyStringMaskedOrOriginalValue(
      values?.maximum_installment
    ),
    unity: values?.unit,
    level: values?.level,
    course: values?.course,
    shift: values?.shift,
    presential:
      idModality === modalityAll || idModality === modalityPresential || false,
    ead: idModality === modalityAll || idModality === modalityEad || false
  }
}

const updateSnask = ({
  error,
  status,
  updateCupon,
  savedCupon,
  feedbackMessage,
  setSnack,
  navigate
}) => {
  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage
    })
  }

  return navigate(ROUTE.ADMIN_FINANCIAL_CUPON, {
    state: { savedCupon, updateCupon, savedSearch: true }
  })
}

export { getParamsCupon, mergeInitialValues, updateSnask }
