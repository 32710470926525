import { ROUTE } from 'services/constants'

const defaultItemsPerPage = 10
const defaultPage = 1
const defaultTotalPage = 10

const breadCrumbsItems = (id) => [
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS,
    label: 'Turmas'
  },
  {
    path: `${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS}?id=${id}`,
    label: 'Detalhes da Turma'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_EDIT_DISCIPLINE,
    label: '<b>Editar Disciplinas</b>'
  }
]

const initialValues = {
  order: '',
  nameTeacher: ''
}

const MESSAGE = {
  SUCCESS_CREATE_DISCIPLINE: 'Disciplina criada com sucesso!',
  SUCCESS_UPDATE_DISCIPLINE: 'Disciplina(s) atuazada(s) com sucesso!',
  SUCCESS_DELETE_DISCIPLINE: 'Disciplina(s) deletada(s) com sucesso!'
}

export {
  breadCrumbsItems,
  defaultTotalPage,
  defaultItemsPerPage,
  defaultPage,
  initialValues,
  MESSAGE
}
