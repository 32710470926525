const MANAGEMENT_TAG_MANAGER = {
  academic_btn_create_typeRequirement: 'academico_btn_criar_tipoRequerimento',
  academic_btn_search_typesRequest: 'academico_btn_busca_tiposRequerimento',
  academic_btn_start_copiaTypeRequirementBatch:
    'academico_btn_inicia_copiaTipoRequerimentoLote',
  academic_btn_start_excludeTypeRequirementBatch:
    'academico_btn_inicia_excluiTipoRequerimentoLote',
  academic_btn_start_inativaTypeRequirementBatch:
    'academico_btn_inicia_inativaTipoRequerimentoLote',
  academic_btn_open_requirementtype: 'academico_btn_abre_tipoRequerimento',
  academic_btn_confirm_inativaTypeRequirement:
    'academico_btn_confirma_inativaTipoRequerimento',
  academic_btn_confirms_excludesRequestType:
    'academico_btn_confirma_excluiTipoRequerimento',
  academic_btn_confirm_copiaTypeRequirement:
    'academico_btn_confirma_copiaTipoRequerimento',
  gestao_btn_inicia_copiaDisciplina: 'gestao_btn_starts_copy_discipline',
  gestao_btn_mostra_matrizCurricular: 'btn_management_shows_curriculum_matrix',
  gestao_btn_cancela_copiaDisciplina: 'management_btn_cancel_copy_discipline',
  gestao_btn_confirma_copiaDisciplina:
    'management_btn_confirms_copy_discipline',
  gestao_btn_buscar_disciplina: 'management_btn_seek_discipline',
  gestao_btn_criar_novaDisciplina: 'management_btn_create_new_discipline',
  gestao_btn_cancela_novaDisciplina: 'management_btn_cancel_new_discipline',

  sistema_btn_busca_atividades: 'sistema_btn_busca_atividades',
  academico_btn_salva_atividade: 'academico_btn_salva_atividade',
  academico_btn_edita_atividade: 'academico_btn_edita_atividade',
  academico_btn_cancela_editaAtividade: 'academico_btn_cancela_editaAtividade',
  academico_btn_criar_novoTipoAtividade:
    'academico_btn_criar_novoTipoAtividade',
  gestao_btn_acessa_cadastroConteudoEAD:
    'gestao_btn_acessa_cadastroConteudoEAD',
  gestao_btn_cadastrar_novoConteudoEAD: 'gestao_btn_cadastrar_novoConteudoEAD',
  gestao_btn_editar_ConteudoEAD: 'gestao_btn_editar_ConteudoEAD',
  gestao_btn_excluir_ConteudoEAD: 'gestao_btn_excluir_ConteudoEAD',
  gestao_btn_confirma_cancelarCadastroConteudoEAD:
    'gestao_btn_confirma_cancelarCadastroConteudoEAD',
  gestao_btn_confirma_cadastrarConteudoEAD:
    'gestao_btn_confirma_cadastrarConteudoEAD',
  gestao_btn_confirma_excluirConteudoEAD:
    'gestao_btn_confirma_excluirConteudoEAD',
  gestao_btn_confirma_editarConteudoEAD: 'gestao_btn_confirma_editarConteudoEAD'
}

const COURSE = {
  academic_btn_create_new_course: 'academico_btn_criar_cursos',
  academic_btn_search_course: 'academico_btn_busca_cursos',
  academic_btn_inactive_course_batch: 'academico_btn_inativa_cursoLote',
  academic_btn_delete_course_batch: 'academico_btn_exclui_cursoLote',
  academic_btn_delete_course: 'academico_btn_exclui_curso',
  academic_btn_open_course: 'academico_btn_abre_curso',
  academic_btn_confirm_inactive_course: 'academico_btn_confirma_inativaCurso',
  academic_btn_confirm_delete_course: 'academico_btn_confirma_excluiCurso',
  academic_btn_save_course: 'academico_btn_salva_curso'
}

const UNITI_BY_POLE = {
  academic_btn_create_new_unit: 'academico_btn_criar_novaUnidade',
  academic_btn_search_registered_units:
    'academico_btn_busca_unidadesCadastradas',
  academic_card_edit_unit: 'academico_card_edita_unidade',
  academic_card_edit_pole: 'academico_card_edita_polo',
  academic_btn_accesses_polos: 'academico_btn_acessa_polos',
  academic_checkbox_selects_Active_enrollment:
    'academico_checkbox_seleciona_matriculaAtiva',
  academic_btn_cancels_new_unit: 'academico_btn_cancela_novaUnidade',
  academic_checkbox_selects_enrollment_site:
    'academico_checkbox_seleciona_matriculaSite',
  academic_checkbox_select_includePolo:
    'academico_checkbox_seleciona_incluirPolo',
  academic_btn_saves_new_unit: 'academico_btn_salva_novaUnidade',
  academic_btn_cancel_edit_unit: 'academico_btn_cancela_editarUnidade',
  academic_btn_save_edit_unit: 'academico_btn_salva_editarUnidade',
  academic_btn_accesses_poles: 'academico_btn_acessa_polos',
  academic_btn_creates_new_polo: 'academico_btn_cria_novoPolo',
  academic_btn_search_registered_polo: 'academico_btn_busca_poloCadastrado',
  academic_btn_cancels_new_polo: 'academico_btn_cancela_novoPolo',
  academic_btn_saves_new_polo: 'academico_btn_salva_novoPolo',
  academic_btn_cancel_edit_polo: 'academico_btn_cancela_editarPolo',
  academic_btn_save_edit_polo: 'academico_btn_salva_editarPolo'
}

export const MANAGEMENT = {
  ...COURSE,
  ...UNITI_BY_POLE,
  ...MANAGEMENT_TAG_MANAGER
}
