const STUDENT = 'student'

export const STUDENT_ROUTE = {
  STUDENT_DISCIPLINE: `/${STUDENT}/disciplines`,
  STUDENT_DOCUMENTS: `/${STUDENT}/documents-requests`,
  STUDENT_MY_SCORES: `/${STUDENT}/my-scores`,
  STUDENT_FINANCIAL: `/${STUDENT}/finances`,
  STUDENT_UPDATE_PASSWORD: `/${STUDENT}/update-password`,
  STUDENT_RE_ENROLLMENT: `/${STUDENT}/re-enrollment`,
  STUDENT_CONTRACT: `/${STUDENT}/contract`
}
