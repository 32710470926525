import { useEffect, useState } from 'react'

import { useAddress } from 'services/hooks'

import { TYPE_RESPONSIBLES } from './constants'
import { LegalPersonForm, PhysicalPersonForm, StudentForm } from './components'
import { fieldsNameForm } from '../../constants'

const ResponsibleForm = ({
  values,
  handleChange,
  touched,
  handleBlur,
  fieldNameForm,
  setFieldValue,
  setLoading
}) => {
  const [isSearchByCep, setSearchByCep] = useState(false)
  const [address, setAddress] = useState({
    state: values?.[fieldNameForm]?.state ?? ''
  })
  const { studentData } = fieldsNameForm

  const {
    fetchAddress,
    listCitys,
    listStates,
    isUpdated,
    toggleUpdate,
    handleChangeCep,
    handleChangeState
  } = useAddress({
    address,
    setAddress
  })

  useEffect(() => {
    if (isSearchByCep) {
      setLoading(true)

      setFieldValue(`${fieldNameForm}.district`, address?.district)
      setFieldValue(`${fieldNameForm}.state`, address?.state)
      setFieldValue(`${fieldNameForm}.city`, address?.city)
      setFieldValue(`${fieldNameForm}.address`, address?.address)

      setLoading(false)
      toggleUpdate()
    }
  }, [isUpdated])

  useEffect(async () => {
    if (isSearchByCep) {
      setLoading(true)

      await fetchAddress(values?.[fieldNameForm]?.zipCode)

      setSearchByCep(false)
      setLoading(false)
    }
  }, [isSearchByCep])

  useEffect(() => {
    const valuesResponsible = values?.[fieldNameForm]
    const valuesStudent = values?.[studentData]

    if (
      valuesResponsible?.financialResponsibleType === TYPE_RESPONSIBLES.STUDENT
    ) {
      setFieldValue(fieldNameForm, {
        ...valuesResponsible,
        ...valuesStudent,
        phone: [valuesStudent?.cellphone].map((cell) => ({
          id: Math.random(),
          cell
        }))
      })
    }
  }, [values?.[fieldNameForm]?.financialResponsibleType])

  const ComponentsByResponsible = {
    [TYPE_RESPONSIBLES.STUDENT]: StudentForm,
    [TYPE_RESPONSIBLES.PHYSICAL_PERSON]: PhysicalPersonForm,
    [TYPE_RESPONSIBLES.LEGAL_PERSON]: LegalPersonForm
  }

  const onBlurCep = () => {
    setSearchByCep(true)
  }

  const Component =
    ComponentsByResponsible?.[values?.[fieldNameForm]?.financialResponsibleType]

  return (
    <Component
      values={values}
      touched={touched}
      handleChange={handleChange}
      handleBlur={handleBlur}
      fieldNameForm={fieldNameForm}
      setFieldValue={setFieldValue}
      listCitys={listCitys}
      listStates={listStates}
      handleChangeState={handleChangeState}
      handleChangeCep={handleChangeCep}
      setSearchByCep={setSearchByCep}
    />
  )
}

export default ResponsibleForm
