import { httpDelete, httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams, convertToParamsArrayString } from 'services/helpers'
import { situationsGroupingMock } from 'services/mocks/grouping'

export const getDisciplinesToAddInGrouping = async ({
  params,
  stringGroupingDisciplines
}) => {
  const response = await httpGet(
    `${
      ENDPOINT.ADMIN_SECRETARY_GROUPING.DISCIPLINES_TO_ADD_IN_GROUPING
    }?${convertToParams(params)}&${stringGroupingDisciplines}`
  )

  return response
}

export const getGroupingList = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_GROUPING.LIST}?${convertToParams(params)}`
  )

  return response
}

export const getSituationsGroupingList = async () => {
  const isMock = true

  if (isMock) {
    return Promise.resolve({
      data: situationsGroupingMock
    })
  }

  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_GROUPING.SITUATIONS_LIST}`
  )

  return response
}

export const getGroupedDisciplines = async ({ groupingDisciplines }) => {
  const response = await httpGet(
    `${
      ENDPOINT.ADMIN_SECRETARY_GROUPING.DISCIPLINES_CRUD
    }?${convertToParamsArrayString(groupingDisciplines, 'disciplines_ids')}`
  )
  return response
}

export const includeDisciplineGrouping = async (id) => {
  const isMock = true

  if (isMock) {
    return Promise.resolve({})
  }

  const response = await httpPost(
    `${ENDPOINT.ADMIN_SECRETARY_GROUPING.INCLUDE_DISCIPLINE}/${id}`
  )

  return response
}

export const excludeSchoolClassGrouping = async ({ groupingId, payload }) => {
  const response = await httpDelete(
    `${ENDPOINT.ADMIN_SECRETARY_GROUPING.DISCIPLINES_CRUD}/${groupingId}`,
    payload
  )

  return response
}

export const getTeachersGroupingList = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_SECRETARY_GROUPING.TEACHERS_LIST}?${convertToParams(
      params
    )}`
  )
  return response
}

export const updateTeacherGrouping = async ({ groupingId, teacherId }) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_SECRETARY_GROUPING.UPDATE_TEACHER}/${groupingId}`,
    {
      professor_id: teacherId
    }
  )
  return response
}

export const addDisciplinesInGrouping = async ({ groupingId, payload }) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_SECRETARY_GROUPING.DISCIPLINES_CRUD}/${groupingId}`,
    payload
  )

  return response
}

export const excludeGrouping = async (id) => {
  const response = await httpDelete(
    `${ENDPOINT.ADMIN_SECRETARY_GROUPING.LIST}/${id}`
  )

  return response
}

export const createGrouping = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_SECRETARY_GROUPING.LIST}`,
    payload
  )

  return response
}

export const editGrouping = async ({ id, payload }) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_SECRETARY_GROUPING.LIST}/${id}`,
    payload
  )

  return response
}
