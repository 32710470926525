import { useEffect } from 'react'
import humps from 'humps'
import { Typography } from '@mui/material'
import { getDiaryClass } from 'services/api/professor'
import { useCurrentUser } from 'services/hooks'
import { displayTextClass } from 'services/helpers'
import { useDiary } from 'components/organisms/Diary/context/HeaderDiary'
import * as Styled from './style'
import { ivalidDate } from './constants'

const Resume = () => {
  const { resumeHead, setResumeHead } = useDiary()
  const { disciplineId, group } = useCurrentUser()
  const id = disciplineId?.split(',')?.shift()

  const handleGetResume = async () => {
    const { data } = await getDiaryClass(id)
    setResumeHead(humps?.camelizeKeys(data))
  }

  const varificationDate = (firstDate, endDate) =>
    (firstDate !== ivalidDate && firstDate) ||
    (firstDate !== ivalidDate && endDate)

  useEffect(() => {
    handleGetResume()
  }, [])

  return (
    <Styled.ResumeHead>
      {resumeHead?.name && (
        <>
          <Styled.Box>
            <Typography fontWeight="bold">{resumeHead.name}</Typography>
            {varificationDate(resumeHead.beginAt || resumeHead.endAt) && (
              <Styled.SubTitle>
                {`- ${resumeHead.beginAt} até ${resumeHead.endAt}`}
              </Styled.SubTitle>
            )}
          </Styled.Box>
          <Styled.Box>
            <Typography fontWeight="bold" variant="subtitle2">
              Turma:
            </Typography>
            <Styled.Typography variant="subtitle2">
              {displayTextClass(group, resumeHead?.schoolClass?.grouping)}
            </Styled.Typography>
          </Styled.Box>
          <Styled.Box>
            <Typography fontWeight="bold" variant="subtitle2">
              Período:
            </Typography>
            <Styled.Typography variant="subtitle2">
              {resumeHead.shift}
            </Styled.Typography>
          </Styled.Box>
        </>
      )}
    </Styled.ResumeHead>
  )
}

export default Resume
