import { messageErroOrSuccess } from 'services/helpers'
import { getSituationsGroupingList as getSituationsGroupingListService } from 'services/api/admin'

export const getSituationsList = async ({ setSnack, setListSituations }) => {
  const { error, status, data } = await getSituationsGroupingListService()

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  setListSituations(data)
}
