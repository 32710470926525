const FORMAT_DATE_DEFAULT = 'dd/MM/yyyy'
const DATESTRLENGHT = 10

const initialValues = {
  cpf: '',
  rg: '',
  uf: '',
  cep: '',
  pai: '',
  mae: '',
  crnm: '',
  nome: '',
  sexo: '',
  email: '',
  civil: '',
  metUs: '',
  bairro: '',
  cidade: '',
  numero: '',
  celular: '',
  contato: '',
  country: 'br',
  telefone: '',
  endereco: '',
  nascimento: '',
  observacao: '',
  nome_social: '',
  complemento: '',
  naturalidade: '',
  nacionalidade: '',
  naturalidade_cidade: '',
  financialOfficer: false,
  enrollmentOrigin: ''
}

export { initialValues, FORMAT_DATE_DEFAULT, DATESTRLENGHT }
