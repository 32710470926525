import PropTypes from 'prop-types'
import { useState, useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { MenuItem, ListItemIcon } from '@mui/material'
import { Logout, PersonOutlined, ExpandMore } from '@mui/icons-material'
import { ROUTE } from 'services/constants'
import {
  getTwoFirstWords,
  getUserFromLocalStorage,
  removeClassesLocalStorage,
  removeSavedDataFromUrl,
  removeUserFromLocalStorage,
  removeVisibleUpdatePassword
} from 'services/helpers'
import { useClickOutside } from 'services/hooks'
import { Avatar, Text } from 'components/atoms'
import GlobalContext from 'store/GlobalContext'
import { TYPE_LEGADO, defaultColorPrimary } from './constants'
import * as Styled from './style'

const roleAdmin = 'admin'

const UserMenu = ({ user }) => {
  const { name } = user || ''
  const history = useNavigate()
  const [menuVisibility, setMenuVisibility] = useState(false)
  const menuRef = useRef(false)
  const { resetUserFromGlobalState } = useContext(GlobalContext)
  const { role } = getUserFromLocalStorage()

  const linkLogout = user?.type === TYPE_LEGADO ? user?.url : ROUTE.LOGOUT
  const toggleMenu = () => {
    setMenuVisibility(!menuVisibility)
  }

  const handleProfile = () => {
    history(ROUTE.USER)
    setMenuVisibility(!menuVisibility)
  }

  const handleLogout = () => {
    if (user?.type === TYPE_LEGADO) {
      removeUserFromLocalStorage()
      resetUserFromGlobalState()
      removeClassesLocalStorage()
      removeVisibleUpdatePassword()
      removeSavedDataFromUrl()
    }
  }

  useClickOutside(menuRef, setMenuVisibility, false)

  return (
    <Styled.Toolbar>
      <Styled.AvatarWrapper onClick={toggleMenu} ref={menuRef}>
        <Avatar size="small" src={null} />
        <Styled.AvatarLabel>
          <Styled.AvatarName>{getTwoFirstWords(name)}</Styled.AvatarName>
          <Styled.AvatarType>{user?.role_in_words}</Styled.AvatarType>
        </Styled.AvatarLabel>
        <ExpandMore />
      </Styled.AvatarWrapper>
      {menuVisibility && (
        <Styled.MenuWrapper onClose={toggleMenu} isVisible={menuVisibility}>
          {role !== roleAdmin && (
            <MenuItem onClick={handleProfile}>
              <ListItemIcon fontSize="small">
                <PersonOutlined color={defaultColorPrimary} />
              </ListItemIcon>
              <Text>Perfil</Text>
            </MenuItem>
          )}

          <Styled.Link href={linkLogout} rel="noreferrer">
            <Styled.MenuItem onClick={handleLogout}>
              <ListItemIcon fontSize="small">
                <Logout color={defaultColorPrimary} />
              </ListItemIcon>
              <Text>Sair</Text>
            </Styled.MenuItem>
          </Styled.Link>
        </Styled.MenuWrapper>
      )}
    </Styled.Toolbar>
  )
}

export default UserMenu

UserMenu.propTypes = {
  user: PropTypes.shape({
    data: PropTypes.shape({
      avatar: PropTypes.string,
      name: PropTypes.string
    })
  }).isRequired
}
