import styled, { css } from 'styled-components'
import {
  Grid as MuiGrid,
  AccordionDetails as MuiAccordionDetails,
  styled as styledMUI,
  Box
} from '@mui/material'
import { WarningAmber } from '@mui/icons-material'

import { ButtonFooterV2 } from 'components/molecules'
import theme from 'theme/designTokens'

const Grid = styled(MuiGrid)`
  &.MuiGrid-container {
    ${({ $flex, $isColumn, $marginTop, $JustifyContent, $AlignItems }) =>
      $flex &&
      css`
        padding: 0;
        display: flex;
        min-height: 30px;
        margin-top: ${$marginTop};
        align-items: ${$AlignItems || 'center'};
        flex-direction: ${$isColumn ? 'column' : 'row'};
        justify-content: ${$JustifyContent || 'center'};
        .MuiTypography-root {
          max-width: 330px;
        }
      `}
  }
`

const Button = styled(ButtonFooterV2)`
  &.MuiButtonBase-root {
    min-width: 42px;
    margin: 12px 0 0 16px;
  }
`

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const Text = styled.p`
  margin: 0;
  color: ${() => theme.palette.textColors.black.darkBlack};
`

const MenuButton = styled.div``

const AccordionDetails = styled(MuiAccordionDetails)``

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${theme.palette.yellow.warning};
    font-size: 28px;
  }
`

const Switch = styled.div`
  margin: 5px 0 20px;
`

const WrapperSummary = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
}))

const SummaryLeft = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  width: '50%'
}))

const SummaryRigth = styledMUI(Box)(() => ({
  display: 'flex',
  gap: 16,
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '50%'
}))

export {
  Grid,
  Text,
  Switch,
  Button,
  MenuButton,
  LeftContainer,
  AccordionDetails,
  IconWarningAmber,
  WrapperSummary,
  SummaryLeft,
  SummaryRigth
}
