import styled from 'styled-components'
import { WarningAmber } from '@mui/icons-material'

const Container = styled.div``

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export { Container, IconWarningAmber }
