const { ROUTE } = require('services/constants')

const breadcrumbsItems = ({ studentName, studentCourse }) => [
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: `${studentName}`
  },

  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: `${studentCourse}`
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES_CREATE,
    label: '<b>Editar Curso</b>'
  }
]

export { breadcrumbsItems }
