/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  getStudentByCourse,
  getSchoolClassesByStudent
} from 'services/api/shared'
import { updateErrorMessage } from 'services/helpers'

const useMakeServiceCouseBySchoolClass = (register, courseId, setSnack) => {
  const [courseList, setCourseList] = useState([])
  const [schoolClass, setschoolClass] = useState([])

  const processCourse = (data) =>
    data?.map(({ id, course, group, shift, level, unit }) => ({
      value: id,
      label: `${level}: ${course} [${id}] / Grupo: ${group} / ${shift} / ${unit}`
    })) || []

  const getCourse = async () => {
    const { data, error, status } = await getStudentByCourse(Number(register))
    if (!error) return setCourseList(processCourse(data))

    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCourse
    })
  }

  const handleType = async () => {
    const { data, error, status } = await getSchoolClassesByStudent(
      Number(register),
      courseId
    )

    const processType = data?.reduce((acc, cur) => {
      const { school_class_id, school_class_code } = cur
      const newItem = { value: school_class_id, label: school_class_code }

      return [...acc, newItem]
    }, [])

    if (!error) return setschoolClass(processType)

    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListSchollClass
    })
  }

  useEffect(() => {
    if (register) {
      getCourse()
    }
  }, [register])

  useEffect(() => {
    if (register && courseId) {
      handleType()
    }
  }, [register, courseId])

  return {
    courseList,
    schoolClass
  }
}

export default useMakeServiceCouseBySchoolClass
