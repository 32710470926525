/* eslint-disable no-unused-vars */
import {
  getActivities as getActivitiesService,
  getTypeActivitiesBySchoolClassId as getTypeActivitiesBySchoolClassIdService,
  createActivities as createActivitiesService,
  rateActivity as rateActivityService
} from 'services/api/admin'
import { messageErroOrSuccess, deserialize, serialize } from 'services/helpers'
import { messageFeedback } from './constants'
import { typeRate } from './components/RateModal/constants'

const getActivities = async ({
  setSnack,
  setData,
  setLoadingOpen,
  register,
  setUpdateData
}) => {
  setUpdateData(false)
  setLoadingOpen(true)

  const {
    error,
    status,
    data: { data: activities, ...restPropsPagination }
  } = await getActivitiesService(register)

  await messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  await setData({
    activities: activities.map(serialize),
    ...restPropsPagination
  })

  setLoadingOpen(false)
}

const getTypeActivities = async ({
  setSnack,
  setTypeActivities,
  schoolClassId
}) => {
  const { error, status, data } = await getTypeActivitiesBySchoolClassIdService(
    schoolClassId
  )

  await messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  const newData = (data ?? [])?.map((item) => ({
    label: item?.name,
    value: item?.id,
    workload: item?.workload
  }))

  await setTypeActivities(newData)
}

const createActivities = async ({ setSnack, payload, callbackSuccess }) => {
  const { workload, ...restPayload } = payload
  const { error, status } = await createActivitiesService(
    deserialize(restPayload)
  )

  await messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage: messageFeedback.createActivity.error,
    successMessage: messageFeedback.createActivity.success
  })

  if (!error) {
    setTimeout(callbackSuccess, 1000)
  }
}

const rateActivity = async ({ setSnack, payload, callbackSuccess }) => {
  const { status, workload, observation } = payload

  const { error, statusError } = await rateActivityService(payload?.id, {
    status: typeRate[status]?.label,
    workload,
    observation
  })

  await messageErroOrSuccess({
    error,
    setSnack,
    status: statusError,
    feedbackMessage: messageFeedback.rateActivity.error,
    successMessage: messageFeedback.rateActivity.success
  })

  if (!error) {
    setTimeout(callbackSuccess, 1000)
  }
}

export { getTypeActivities, createActivities, getActivities, rateActivity }
