export const translate = (transcriptions, response) => {
  const toTranslate = JSON.stringify(response)

  const translator = (translation, transcription) => {
    const originalKey = `"${transcription.key}":`
    const translationKey = `"${transcription.translation}":`
    const validator = new RegExp(originalKey, 'ig')
    return translation.replace(validator, translationKey)
  }

  const translations = transcriptions.reduce(translator, toTranslate)
  return JSON.parse(translations)
}

export const isObject = (payload) =>
  typeof payload === 'object' && payload.constructor === Object

export const objectIsEmpty = (payload) =>
  isObject(payload) && !Object.keys(payload).length
