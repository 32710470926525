import { useState, lazy } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form as FormFormik, Formik } from 'formik'
import FEEDBACK_SNACK from 'feedBackSnack'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useMakeServiceCouseBySchoolClass, useSnack } from 'services/hooks'
import { updateErrorMessage } from 'services/helpers'
import { createOccurrences } from 'services/api/admin'
import { useStore } from '../Store/useStore'
import { breadcrumbsItems } from './helpers'
import { initialValues } from './config'
import { getParams } from './services'
import schema from './schema'

const Form = lazy(() => import('./Form'))

const CreateOccurrences = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [register, setRegister] = useState('')
  const [courseId, setCourseId] = useState('')
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const { toggleOpen } = useStore()

  const { courseList, schoolClass } = useMakeServiceCouseBySchoolClass(
    register,
    courseId,
    setSnack
  )

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(ROUTE.ADMIN_SECRETARY_OCCURRENCES, {
      state: { savedSearch: true }
    })
  }

  const onSubmit = async (values) => {
    const { error, status } = await createOccurrences(
      getParams(register, values)
    )

    if (!error) {
      navigate(ROUTE.ADMIN_SECRETARY_OCCURRENCES, {
        state: { savedSearch: true }
      })
      return toggleOpen()
    }

    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToFindStudent
    })
  }

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems()}
      onClickButton={() => setOpenModal(true)}
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      tagmanagerModal={TAG_MANAGER.btn_secretary_cancel_editOccurrence}
      content={
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={schema(courseList, schoolClass)}
        >
          {(props) => (
            <FormFormik
              onSubmit={props.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Form
                {...props}
                setOpenModal={setOpenModal}
                setSnack={setSnack}
                setLoading={setLoading}
                setRegister={setRegister}
                register={register}
                setCourseId={setCourseId}
                courseList={courseList}
                schoolClass={schoolClass}
              />
            </FormFormik>
          )}
        </Formik>
      }
    />
  )
}

export default CreateOccurrences
