import {
  httpGet,
  httpPut,
  httpDelete,
  httpPost,
  httpGetV2
} from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getDiaryClass = async (id) => {
  const response = await httpGet(`${ENDPOINT.PROFESSOR_SUBJECTS}/${id}`)
  return response
}

export const updateClass = async (id, params) => {
  const response = await httpPut(
    `${ENDPOINT.PROFESSOR_DIARY_LESSONS}?ids[]=${id.join('&ids[]=')}`,
    { ...params },
    {},
    process.env.REACT_APP_API_V2
  )
  return response
}

export const getClassList = async ({ disciplineId, page, limit }) => {
  const params = {
    page,
    limit
  }
  const response = await httpGetV2(
    `${ENDPOINT.PROFESSOR_DIARY_LESSONS}?subject_ids[]=${disciplineId.join(
      '&subject_ids[]='
    )}`,
    { params },
    {},
    process.env.REACT_APP_API_V2
  )

  return response
}

export const removeClass = async (idList) => {
  const numberId = idList.toString().split(',')

  const response = await httpDelete(
    `${ENDPOINT.PROFESSOR_DIARY_LESSONS}?ids[]=${numberId.join('&ids[]=')}`
  )
  return response
}

export const saveNewClass = async (params) => {
  const response = await httpPost(
    ENDPOINT.PROFESSOR_DIARY_LESSONS,
    {
      ...params
    },
    {},
    process.env.REACT_APP_API_V2
  )
  return response
}
