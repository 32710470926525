import styled from 'styled-components'
import { TableCell as MuiTableCell, Box as MuiBox } from '@mui/material'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 26rem;
    cursor: pointer;
    text-decoration: underline;
  }
`

const TableCellEnrollment = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 26rem;
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
    padding: 6px 16px;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 27rem;

      & > span {
        color: ${({ theme }) => theme.palette.primary.main};
        text-decoration: underline;
      }

      & > a {
        color: ${({ theme }) => theme.palette.primary.main} !important;
        margin-right: 20px;
      }

      button {
        padding: 0 8px;
      }

      & > div {
        display: none;
      }

      &:hover > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`

const BoxLink = styled(MuiBox)`
  color: #593493 !important;
`

export { BoxLink, TableCell, TableCellTitle, TableCellEnrollment }
