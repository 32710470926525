import FEEDBACK_SNACK from 'feedBackSnack'
import { getListProfessorTimekeeping } from 'services/api/admin'
import { updateErrorMessage, formatDateMonthYear } from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'

const getProfessorTimekeeping = async ({
  values,
  setLoadingOpen,
  setListTimekeeping
}) => {
  setLoadingOpen(true)
  const { competence } = values

  const { data, error, status } = await getListProfessorTimekeeping({
    ...cleanObject(values),
    ...(competence && { competence: formatDateMonthYear(competence) })
  })

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListProfessorTimekeeping
    })
  }

  return setListTimekeeping(data)
}

export { getProfessorTimekeeping }
