import styled from 'styled-components'
import {
  TableHead as MuiTableHead,
  TableCell as MuiTableCell,
  TableSortLabel as MuiTableSortLabel
} from '@mui/material'

const TableHead = styled(MuiTableHead)`
  &.MuiTableHead-root {
    background-color: ${({ theme }) => theme.palette.gray.light};
  }
`
const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    flex-wrap: wrap;
    padding: 6px 12px;
    min-width: 100px;
    border-left: 1px solid;
    border-color: ${({ theme }) => theme.palette.gray.primaryLight};
  }
`

const TableSortLabel = styled(MuiTableSortLabel)`
  width: 100%;
  &.MuiTableSortLabel-root {
    svg {
      opacity: 1;
      display: ${({ $disabledIcon }) => $disabledIcon && 'none'};
    }
  }
`

const TableCellCheckbox = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: center;
  }
`

export { TableHead, TableCell, TableSortLabel, TableCellCheckbox }
