const MANAGEMENT = {
  DISCIPLINES: {
    CRUD: 'disciplines',
    LIST_JOY: 'disciplines/joy/courses',
    CONTENTS: 'contents'
  },
  CURRICULUM: {
    CRUD: 'curriculum',
    SUBJECT: 'disciplines',
    MODULE: 'finances/level-module'
  },
  TYPES_ACTIVETYS: {
    BASE_URL_TYPES_ACTIVETYS: 'complementary-activity/types'
  }
}

export { MANAGEMENT }
