import { useState, Suspense, useEffect } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { getComparator, stableSort } from 'services/helpers/formatTable'
import { PaginationAdvanced } from 'components/molecules'
import { TableHeaderDefault } from 'components/organisms'
import {
  defaultItemsPerPage,
  headCellsPending,
  sortAsc,
  sortDesc
} from './constants'
import TableFooter from './TableFooter'
import { StyledPaper, StyledTableContainer } from './style'
import TableToolbar from './TableToolbar'
import { paginationFilter } from '../../constants'

const TableLinkedClassDetails = ({
  items,
  count,
  filter,
  isEdit,
  setPage,
  setFilter,
  totalPage,
  pageActual,
  isSelected,
  handleClick,
  selectedItem,
  setListSelected,
  handleSelectAllClick
}) => {
  const [order, setOrder] = useState(sortAsc)
  const [orderBy, setOrderBy] = useState()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  useEffect(() => {
    setListSelected(selectedItem)
  }, [selectedItem])

  return (
    <Suspense>
      {Boolean(selectedItem?.length) && (
        <TableToolbar amount={selectedItem?.length} />
      )}
      <StyledPaper>
        <StyledTableContainer>
          <MuiTable>
            <TableHeaderDefault
              checkbox
              order={order}
              orderBy={orderBy}
              rowCount={items?.length}
              headCells={headCellsPending}
              onRequestSort={handleRequestSort}
              numSelected={selectedItem?.length}
              handleSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-${index}`

                  return (
                    <TableFooter
                      row={row}
                      key={row.id}
                      labelId={labelId}
                      selected={selectedItem}
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                    />
                  )
                }
              )}
            </TableBody>
          </MuiTable>
        </StyledTableContainer>

        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={filter}
            count={totalPage}
            page={pageActual}
            isSelectItensPerPage
            optionsList={paginationFilter}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setFilter(e.target.value)
            }}
          />
        )}
      </StyledPaper>
    </Suspense>
  )
}

export default TableLinkedClassDetails
