const displayTextClass = (grouping, code) => {
  if (grouping?.startsWith('Grupo:')) {
    return grouping?.replace(/Grupo:/i, '').trim()
  }

  return grouping || code
}

const displayTextWeekly = (period) => {
  if (period?.startsWith('Semanal - ')) {
    return period?.replace(/Semanal - /i, '').trim()
  }

  return period
}

export { displayTextClass, displayTextWeekly }
