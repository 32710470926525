import { Grid } from '@mui/material'
import { Select, TextField } from 'components/molecules'

const FormListDeclaretion = ({ level, values, setFieldValue }) => (
  <>
    <Grid item xs={12} sm={12} md={4}>
      <Select
        id="level"
        name="level"
        optionsList={level}
        label="Selecione o nível"
        value={values?.level || ''}
        onChange={(e) => setFieldValue('level', e.target.value)}
      />
    </Grid>
    <Grid item xs={12} sm={12} md={4}>
      <TextField
        fullWidth
        name="name"
        isMargin="0"
        value={values?.name}
        label="Nome da Declaração"
        onChange={(e) => setFieldValue('name', e.target.value)}
      />
    </Grid>
  </>
)

export default FormListDeclaretion
