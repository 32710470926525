import { httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const generateContract = async (payload) => {
  const response = await httpPost(`${ENDPOINT.CONTRACT_GENERATE}`, {
    ...payload
  })
  return response
}

export const updateContact = async ({ contract, payloadWithoutMask }) => {
  const response = await httpPut(`${ENDPOINT.UPDATE_CONTACT}/${contract}`, {
    ...payloadWithoutMask
  })
  return response
}

export const confirmSignature = async ({ contract, payload }) => {
  const response = await httpPut(`${ENDPOINT.ACCEPTE_CONTRACT}/${contract}`, {
    ...payload
  })
  return response
}

export const skipSignature = async ({ contract, payloadSkipSignature }) => {
  const response = await httpPut(`${ENDPOINT.SKIP_CONTRACT}/${contract}`, {
    ...payloadSkipSignature
  })
  return response
}
