import { FieldArray, Form, Formik } from 'formik'
import { FormControlLabel, Grid, Radio, Tooltip } from '@mui/material'
import { ButtonFooter, InputFile, TextField } from 'components/molecules'
import { ROUTE } from 'services/constants'
import { navigate } from '@storybook/addon-links'
import {
  LABEL_FILE,
  DOCUMENTS_LIMIT,
  SIZE_FILES,
  TYPE_ACCEPT
} from './constants'
import * as Styled from './style'

const Responsible = ({
  handleCreateOrUpdateResponsible,
  isPole,
  initialValue,
  handleDeleteSignatureResponsible
}) => {
  const onHandleClose = () => {
    navigate(ROUTE.ADMIN_MANAGEMENT_UNITS_POLE)
  }

  const onSelectFile = (e, values, setValues, index) => {
    if (e.target.files && e.target.files.length > SIZE_FILES) {
      const reader = new FileReader()

      const signatures = [...values.signatures]

      const currentDocument = signatures[index]
      reader.addEventListener('load', () => {
        currentDocument.file = reader.result.toString() || ''
        setValues({ ...values, signatures })
      })

      reader.readAsDataURL(e.target.files[0])
    }
  }

  return (
    <>
      <Styled.SubTitle variant="subtitle2" gutterBottom>
        Os responsáveis e as suas assinaturas são utilizadas nos documentos
        gerados para os cursos desta unidade.
        <br />
        {isPole &&
          ' Deixando vazio, o sistema admitirá os responsáveis cadastrados na Unidade.'}
      </Styled.SubTitle>
      <Formik
        initialValues={initialValue}
        enableReinitialize
        onSubmit={handleCreateOrUpdateResponsible}
      >
        {({ values, setValues, handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FieldArray name="signatures">
              {({ push, remove }) => (
                <Grid container spacing={{ xs: 0, sm: 2 }}>
                  {values?.signatures?.map((valuesForms, index) => {
                    const signatureIndex = `signatures[${index}]`
                    const name = `${signatureIndex}.name`
                    const document = `${signatureIndex}.document`
                    const file = `${signatureIndex}.file`
                    const direction = `${signatureIndex}.role`

                    return (
                      <Grid item xs={12} sm={7} md={3}>
                        <Styled.Card>
                          <InputFile
                            idUpload={name}
                            onChange={(e) =>
                              onSelectFile(e, values, setValues, index)
                            }
                            typeFile={TYPE_ACCEPT}
                            labelBox={LABEL_FILE}
                            disabled={valuesForms.notEditable}
                            imgSrc={valuesForms.file}
                            name={file}
                            nameFile={file}
                            hasSize
                            width="auto"
                          />

                          <Styled.RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={direction}
                            value={valuesForms.role || ''}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              disabled={valuesForms.notEditable}
                              value="Diretor"
                              control={<Radio size="small" />}
                              label="Direção"
                            />
                            <FormControlLabel
                              disabled={valuesForms.notEditable}
                              value="Secretaria"
                              control={<Radio size="small" />}
                              label="Secretaria"
                            />
                          </Styled.RadioGroup>
                          <TextField
                            fullWidth
                            disabled={valuesForms.notEditable}
                            label="Nome completo"
                            name={name}
                            onChange={handleChange}
                            InputProps={{
                              value: valuesForms.name || ''
                            }}
                          />

                          <TextField
                            fullWidth
                            disabled={valuesForms.notEditable}
                            label="Documento"
                            name={document}
                            onChange={handleChange}
                            InputProps={{
                              value: valuesForms.document || ''
                            }}
                          />
                        </Styled.Card>
                        <Styled.CardIconAdd>
                          <Tooltip title="Excluir" placement="top" arrow>
                            <Styled.IconRemove
                              onClick={() => {
                                handleDeleteSignatureResponsible({
                                  valuesForms,
                                  index,
                                  remove
                                })
                              }}
                            />
                          </Tooltip>
                        </Styled.CardIconAdd>
                      </Grid>
                    )
                  })}

                  {values?.signatures?.length < DOCUMENTS_LIMIT && (
                    <Styled.CardIconAdd>
                      <Tooltip
                        title="Adicionar responsável"
                        placement="top"
                        arrow
                      >
                        <Styled.IconAdd
                          onClick={() =>
                            push({
                              file: null,
                              role: '',
                              name: '',
                              document: ''
                            })
                          }
                        />
                      </Tooltip>
                    </Styled.CardIconAdd>
                  )}
                </Grid>
              )}
            </FieldArray>

            <ButtonFooter
              textButtonNotConfirm="Cancelar"
              textButtonConfirm="Salvar"
              typeNotConfirm="reset"
              onClickNotConfirm={onHandleClose()}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Responsible
