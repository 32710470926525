/* eslint-disable no-unused-vars */
import { parseISO } from 'date-fns'
import { formatDate, isEqualObjects } from 'services/helpers'
import { MAX_LENGTH_CPF } from 'services/constants'
import { LEVEL_TYPES, TYPE_RESPONSIBLES, fieldsNameForm } from './constants'

const formatInitialValuesStudent = ({
  companyId,
  disabilityName,
  spouseBirth,
  profileImage,
  academicProfileImage,
  birthDate,
  rgDispatcherDate,
  reservistDateDispatcher,
  number,
  ...restValues
}) => ({
  birth: parseISO(birthDate),
  rgDispatcherDate: parseISO(rgDispatcherDate),
  reservistDateDispatcher: parseISO(reservistDateDispatcher),
  addressNumber: number,
  ...restValues
})

const formatPayloadStudent = ({
  phone,
  disabilityId,
  register,
  rgState,
  cellphone,
  cpf,
  addressNumber,
  birth,
  ...restValues
}) => ({
  ...restValues,
  rgUf: rgState,
  disability_type_id: disabilityId === 0 ? null : disabilityId,
  cellPhone: cellphone,
  cpf: (cpf ?? '').slice(0, MAX_LENGTH_CPF),
  number: addressNumber,
  birthDate: birth
})

const formatInitialValuesAffiliation = (values) => ({
  ...values,
  otherGuardian: values?.otherGuardianName
})

const formatPayloadAffiliation = ({ otherGuardian, ...restValues }) => ({
  ...restValues,
  otherGuardianName: otherGuardian
})

const formatInitialValuesSchoolData = (schoolData) =>
  schoolData?.map((item) => {
    const isHigh = Object.keys(item).shift().includes('high')
    const fieldName = isHigh
      ? LEVEL_TYPES.HIGH_SCHOOL
      : LEVEL_TYPES.ELEMENTARY_SCHOOL

    return {
      nivel: fieldName,
      finishedYear: item?.[`${fieldName}FinishedYear`],
      institution: item?.[`${fieldName}Institution`],
      city: item?.[`${fieldName}City`],
      state: item?.[`${fieldName}State`],
      type: item?.[`${fieldName}Type`],
      isFinished: Boolean(item?.[`${fieldName}FinishedYear`])
    }
  })

const formatInitialValuesStudentData = (values) => ({
  [fieldsNameForm.studentData]: formatInitialValuesStudent({
    ...values?.student,
    mother: values?.affiliation?.mother,
    father: values?.affiliation?.father
  }),
  [fieldsNameForm.schoolData]: formatInitialValuesSchoolData(
    values?.schoolData
  ),
  [fieldsNameForm.affiliation]: formatInitialValuesAffiliation(
    values?.affiliation
  )
})

const formatPayloadFinancialOfficer = (values) => {
  const {
    email,
    address,
    addressNumber,
    complement,
    city,
    district,
    phone,
    birth,
    nationality,
    financialResponsibleType,
    state,
    contractId,
    zipCode
  } = values

  const newPhone = phone?.[0]?.cell

  return {
    ...(financialResponsibleType === TYPE_RESPONSIBLES.LEGAL_PERSON && {
      financialResponsibleStateRegistration: values?.stateRegistration,
      financialResponsibleName: values?.razaoSocial,
      financialResponsibleCpf: values?.cnpj
    }),
    ...(financialResponsibleType !== TYPE_RESPONSIBLES.LEGAL_PERSON && {
      financialResponsibleName: values?.name,
      financialResponsibleMaritalStatus: values?.civilStatus,
      financialResponsibleRg: values?.rg,
      financialResponsibleCpf: values?.cpf
    }),
    contractId,
    financialResponsibleNationality: nationality,
    financialResponsibleBirth: birth,
    financialResponsibleEmail: email,
    financialResponsibleZipcode: zipCode,
    financialResponsibleUf: state,
    financialResponsibleCity: city,
    financialResponsibleAddress: address,
    financialResponsibleAddress_number: addressNumber,
    financialResponsibleComplement: complement,
    financialResponsibleDistrict: district,
    financialResponsibleTelephone: newPhone,
    financialResponsibleType
  }
}

const formatPayloadByTab = {
  [fieldsNameForm.studentData]: formatPayloadStudent,
  [fieldsNameForm.affiliation]: formatPayloadAffiliation,
  [fieldsNameForm.financialOfficer]: formatPayloadFinancialOfficer
}

const findResponsible = (listResponsibles, currentResponsible) =>
  (listResponsibles ?? [])?.find(
    (responsible) => responsible?.contractId === currentResponsible?.contractId
  )

const hasFieldsRequireStudentData = ({
  name,
  cpf,
  nationality,
  email,
  crnm,
  country,
  placeOfBirthState,
  placeOfBirthCity,
  zipCode,
  state,
  city,
  address,
  addressNumber,
  mother,
  cellphone,
  contactTime
}) => {
  const validationCountry = country !== 'BR'

  return (
    name &&
    cpf &&
    nationality &&
    email &&
    zipCode &&
    state &&
    city &&
    address &&
    addressNumber &&
    mother &&
    contactTime &&
    cellphone &&
    ((validationCountry && crnm) ||
      (!validationCountry && placeOfBirthState && placeOfBirthCity))
  )
}

const hasFieldsRequireAffiliation = ({
  father,
  fatherCpf,
  mother,
  motherCpf,
  otherGuardian,
  otherGuardianCpf,
  legalGuardianType
}) =>
  (father || legalGuardianType !== 'father') &&
  (fatherCpf || !father) &&
  (mother || legalGuardianType !== 'mother') &&
  (motherCpf || !mother) &&
  (otherGuardian || legalGuardianType !== 'otherGuardian') &&
  (otherGuardianCpf || !otherGuardian)

const hasFieldsRequireFinancialOfficer = ({
  name,
  cpf,
  nationality,
  email,
  zipCode,
  state,
  city,
  address,
  addressNumber,
  financialResponsibleType,
  razaoSocial,
  cnpj,
  phone,
  stateRegistration
}) =>
  (razaoSocial ||
    financialResponsibleType !== TYPE_RESPONSIBLES.LEGAL_PERSON) &&
  (cnpj || financialResponsibleType !== TYPE_RESPONSIBLES.LEGAL_PERSON) &&
  (name || financialResponsibleType === TYPE_RESPONSIBLES.LEGAL_PERSON) &&
  (cpf || financialResponsibleType === TYPE_RESPONSIBLES.LEGAL_PERSON) &&
  (nationality ||
    financialResponsibleType === TYPE_RESPONSIBLES.LEGAL_PERSON) &&
  (stateRegistration ||
    financialResponsibleType !== TYPE_RESPONSIBLES.LEGAL_PERSON) &&
  email &&
  zipCode &&
  state &&
  city &&
  address &&
  addressNumber &&
  phone[0]?.cell

const disabledButton = (currentValues, initialValues, currentTab) => {
  const {
    studentData,
    affiliation,
    financialOfficer,
    fieldNameResponsibleEdit,
    fieldNameListResponsibles
  } = fieldsNameForm

  if ([studentData, affiliation].includes(currentTab)) {
    const isDataEqual = isEqualObjects(
      currentValues?.[currentTab],
      initialValues[currentTab]
    )
    const isStudentDataMissingFields =
      currentTab === studentData &&
      !hasFieldsRequireStudentData(currentValues?.[studentData])
    const isAffiliationMissingFields =
      currentTab === affiliation &&
      !hasFieldsRequireAffiliation(currentValues?.[affiliation])

    return (
      isDataEqual || isStudentDataMissingFields || isAffiliationMissingFields
    )
  }

  if (currentTab === financialOfficer) {
    const currentResponsibleEditing = currentValues[fieldNameResponsibleEdit]
    const currentResponsibleEditingInitialValues = findResponsible(
      currentValues[fieldNameListResponsibles],
      currentResponsibleEditing
    )

    const isResponsibleEqual =
      !!currentResponsibleEditing &&
      isEqualObjects(
        currentResponsibleEditing,
        currentResponsibleEditingInitialValues
      )
    const isFinancialOfficerMissingFields = !hasFieldsRequireFinancialOfficer(
      currentValues?.[fieldNameResponsibleEdit]
    )

    return isResponsibleEqual || isFinancialOfficerMissingFields
  }

  return false
}

export { formatInitialValuesStudentData, formatPayloadByTab, disabledButton }
