import { ROUTE } from 'services/constants'

const MESSAGE = {
  SUCCESS: 'Nova disciplina criada com sucesso',
  SUCCESS_EDIT: 'Disciplina editada com sucesso'
}

const initialState = {
  workload_hour: '',
  workload_minute: '',
  discipline: '',
  code: '',
  modality_id: '',
  level: [],
  courses: [],
  joy_course_api_key: null,
  revision_start_date: '',
  revision_end_date: '',
  active: false
}

const modalityList = [
  {
    value: 1,
    label: 'Presencial'
  },
  {
    value: 3,
    label: 'Online'
  },
  {
    value: 0,
    label: 'Todas'
  }
]

const breadcrumbsItems = (hasCreateSubject, numberSubjects) => [
  {
    path: ROUTE.ADMIN_MANAGEMENT_SUBJECTS,
    label: 'Gestão'
  },
  {
    path: ROUTE.ADMIN_MANAGEMENT_SUBJECTS,
    label: 'Disciplinas'
  },
  {
    path: hasCreateSubject
      ? ROUTE.ADMIN_MANAGEMENT_SUBJECTS_CREATE
      : ROUTE.ADMIN_MANAGEMENT_SUBJECTS_EDIT,
    label: hasCreateSubject
      ? '<b>Novo Disciplina</b>'
      : `<b>Disciplina ${numberSubjects}</b>`
  }
]

const maxLengthCode = 10

export { initialState, modalityList, breadcrumbsItems, MESSAGE, maxLengthCode }
