import styled from 'styled-components'
import { Box as MuiBox } from '@mui/material'
import { TextField } from 'components/molecules'

const Box = styled(MuiBox)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Amount = styled(MuiBox)`
  margin-left: 6px;
  margin-top: 6px;
`

const Input = styled(TextField)`
  width: 60px;

  .MuiInputAdornment-root {
    padding: 6px;
    text-align: center;
  }
`

export { Box, Amount, Input }
