import * as Styled from './style'

const Card = ({ srcImage, title, describe }) => (
  <Styled.Container>
    <Styled.BackgroundImage src={srcImage} alt="#" />

    <Styled.Details>
      <Styled.DetailsTitle>{title}</Styled.DetailsTitle>
      <Styled.DetailsDescribe>{describe}</Styled.DetailsDescribe>
    </Styled.Details>
  </Styled.Container>
)

export default Card
