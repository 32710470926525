import styled from 'styled-components'

const Container = styled.div`
  position: relative;
`

const BackgroundImage = styled.img`
  position: absolute;
`

const Message = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 30px 85px 30px 100px;
`

const MessageText = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.4%;
`

const MessageImage = styled.img``

export { Container, BackgroundImage, Message, MessageText, MessageImage }
