/* eslint-disable camelcase */
import { lazy, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ListAlt, Search, WarningAmber } from '@mui/icons-material'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import TAG_MANAGER from 'tagManager'
import { ERROR, ROUTE, SUCCESS } from 'services/constants'
import {
  useCheckedTable,
  useMakeService,
  usePaginationBasic,
  useSnack
} from 'services/hooks'
import { getErrorMessage, IsEmpty, mergeInitialValues } from 'services/helpers'
import {
  deleteCourses,
  getCourses
} from 'services/api/admin/management/Courses'
import { useCourses } from './Context/FormContext'
import {
  defaultTotalPage,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  initialState,
  isEnrollment,
  listItensPerPage,
  MESSAGE
} from './contants'

const FormSearchCourse = lazy(() => import('./FormSearch'))
const TableCourse = lazy(() => import('./Table'))
const ModalConfirm = lazy(() => import('components/molecules/ModalConfirm'))

const ListCourse = () => {
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [filter, setFilter] = useState(defaultTotalPage)
  const [itensPerPage, setItensPerPage] = useState(defaultTotalPage)
  const [openModalInactivate, setOpenModalInactivate] = useState(false)
  const [count, setCount] = useState(null)
  const [listCourses, setListCourses] = useState([])
  const [isInactiveList, setIsInactiveList] = useState(false)

  const filterIsEnrollment = listCourses?.filter(
    (item) => item.has_enrollment !== isEnrollment
  )

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected: selectedItem,
    setSelected
  } = useCheckedTable(filterIsEnrollment, 'id')

  const location = useLocation()
  const isSearchParam = location?.state?.savedSearch

  const snackProps = useSnack()
  const { setSnack } = snackProps

  const {
    page: pageActual,
    totalPage,
    setPage
  } = usePaginationBasic(listCourses, filter, count)

  const { formValues, setFormValues } = useCourses()

  const paramsMakeService = {
    unitsValue: true,
    isUnits: true,
    isLevel: true,
    setSnack
  }
  const { unitys, level } = useMakeService(paramsMakeService)

  const handleSearch = async (values) => {
    setLoadingOpen(true)

    const params = {
      ...(values.level && { level: values.level }),
      unit_id: Number(values.unitId),
      inactive: values.inactive,
      limit: filter || defaultTotalPage,
      page: pageActual || DEFAULT_PAGE
    }

    const { data, error, status } = await getCourses(params)

    setIsInactiveList(values.inactive)

    setLoadingOpen(false)
    setFormValues(values)
    setCount(data?.count)

    if (error) {
      const message = getErrorMessage(error, status)
      return setSnack(message, ERROR)
    }

    setNotSearch(false)

    return setListCourses(data?.data)
  }

  const handleInactivateGroups = async () => {
    setSnack('', '')
    setLoadingOpen(!loadingOpen)

    const payload = {
      ids: selectedItem
    }

    const { error, status } = await deleteCourses(payload)

    setSelected([])
    setOpenModalInactivate(false)
    setLoadingOpen(false)

    if (error) {
      const message = getErrorMessage(error, status)
      return setSnack(message, ERROR)
    }

    handleSearch(formValues)

    return setSnack(MESSAGE.INACTIVE_SUCCESS, SUCCESS)
  }

  const handleClearForms = () => {
    setFormValues(initialState)
    setListCourses([])
  }

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  useEffect(() => {
    if (formValues?.unitId) handleSearch(formValues)
  }, [pageActual, filter])

  useEffect(() => {
    if (isSearchParam && formValues?.unitId) {
      return handleSearch(formValues)
    }

    return !isSearchParam && setFormValues(initialState)
  }, [isSearchParam])

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  return (
    <TemplateDefaultFormAndResult
      titleHeader="Gestão - Curso"
      snackProps={snackProps}
      labelButtonHeader="Novo Curso"
      loadingOpen={loadingOpen}
      listItems={listCourses}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      linkHeader={ROUTE.ADMIN_MANAGEMENT_COURSE_CREATE}
      classNameHeader={TAG_MANAGER.academic_btn_create_new_course}
      formHeader={
        <FormSearchCourse
          initialState={initialValues}
          handleSearch={handleSearch}
          setPage={setPage}
          handleClearForms={handleClearForms}
          setFilter={setFilter}
          itemUnits={unitys}
          itemLevel={level}
        />
      }
    >
      {listCourses && !IsEmpty(listCourses) && (
        <>
          <TableCourse
            itemsTable={listCourses}
            defaultValue={DEFAULT_ITEMS_PER_PAGE}
            totalPage={totalPage}
            pageActual={pageActual}
            isInactiveList={isInactiveList}
            handleSelectAllClick={handleSelectAllClick}
            handleClick={handleClick}
            isSelected={isSelected}
            setSelected={setSelected}
            selected={selectedItem}
            filter={filter}
            setFilter={setFilter}
            setPage={setPage}
            count={count}
            optionsList={listItensPerPage}
            handleChange={(e) => setItensPerPage(e.target.value)}
            openModalInactivate={openModalInactivate}
            setOpenModalInactivate={setOpenModalInactivate}
            itensPerPage={itensPerPage}
          />

          <ModalConfirm
            open={openModalInactivate}
            icon={<WarningAmber color="warning" fontSize="large" />}
            title="Inativar"
            message="Deseja realmente inativar este(s) curso(s)? A ação torna o curso inelegível para futuras inscrições, mas ainda constará no sistema com seu histórico."
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            colorButtonConfirm="error"
            onClickNotConfirm={() =>
              setOpenModalInactivate(!openModalInactivate)
            }
            className={TAG_MANAGER.academic_btn_confirm_inactive_course}
            onClickConfirm={handleInactivateGroups}
          />
        </>
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ListCourse
