import styled from 'styled-components'
import { AddBox } from '@mui/icons-material'
import { Box } from '@mui/material'

const Container = styled(Box)`
  width: 100%;
`

const EmptyState = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WrapperList = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const WrapperIcon = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningMessage = styled(Box)`
  padding-left: 14px;
  color: ${({ theme }) => theme.palette.dark.light};
`

const IconAdd = styled(AddBox)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    fill: ${({ theme }) => theme.palette.primary.main};
    outline: none;
    border: 0px;
    margin: 17px 0 0 14px;
  }
`
export {
  Container,
  EmptyState,
  WrapperList,
  WrapperIcon,
  IconAdd,
  WarningMessage
}
