import * as yup from 'yup'
import { formatCPF, isValidCPF } from 'services/helpers'
import { CPF, REGISTRATION } from '../ListRequisition/constants'

const getSchema = ({ hasClass }) =>
  yup.object().shape({
    searchType: yup.string().required('Campo obrigatório'),
    searchBy: yup
      .string()
      .when('searchType', {
        is: REGISTRATION,
        then: yup
          .string()
          .trim()
          .matches(
            /^[1-9][0-9]*$/,
            'O número de matrícula deve ser um número inteiro positivo'
          )
          .nullable()
      })
      .when('searchType', {
        is: CPF,
        then: yup
          .string()
          .transform((value) => formatCPF(value))
          .test(
            'cpf-valid',
            'CPF inválido',
            (value) => !value || isValidCPF(value)
          )
          .nullable()
      }),
    schoolClass: hasClass
      ? yup.string().nullable().required('Selecione uma turma')
      : yup.string().nullable()
  })

export default getSchema
