import humps from 'humps'
import { httpGet } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getClasses = async (professorId, params) => {
  const url = `${ENDPOINT.PROFESSOR}${professorId}/${ENDPOINT.PROFESSOR_SUBJECTS}`
  const responseByClasses = await httpGet(
    url,
    { params },
    process.env.REACT_APP_API_V2
  )
  return responseByClasses
}

export const getProfessorsByDisciplines = async ({ disciplinesIds }) => {
  const url = `${ENDPOINT.SCHOOL_CLASS.DISCIPLINES}/${ENDPOINT.PROFESSOR}`

  return httpGet(url, {
    params: humps.decamelizeKeys({ disciplinesIds })
  })
}
