import * as Styled from './style'

const CardDiscipline = ({ name, workload, children }) => (
  <Styled.Card>
    <Styled.CardHeader>
      <Styled.TitleLabel>Disciplina: </Styled.TitleLabel>
      <Styled.TitleValue>
        <b>{name}</b>
      </Styled.TitleValue>
      <Styled.TitleLabel>Carga Horária: </Styled.TitleLabel>
      <Styled.TitleValue>
        <b>{workload}h</b>
      </Styled.TitleValue>
    </Styled.CardHeader>
    <Styled.CardContent>{children}</Styled.CardContent>
  </Styled.Card>
)

export default CardDiscipline
