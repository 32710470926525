import { SCHOOL_CLASS, NAME, REGISTRATION, CONTRACT, EMAIL } from './constants'

const initialValue = {
  searchType: CONTRACT,
  searchBy: '',
  unit: '',
  beginAt: '',
  endAt: ''
}

const simpleSearchBy = [
  { value: CONTRACT, label: 'Contrato' },
  { value: REGISTRATION, label: 'Matrícula' },
  { value: NAME, label: 'Nome' },
  { value: EMAIL, label: 'E-mail' },
  { value: SCHOOL_CLASS, label: 'Turma' }
]

const typeSearch = {
  [CONTRACT]: {
    typeMask: 'NUMBER',
    type: 'number',
    label: 'Número do contrato'
  },
  [NAME]: {
    typeMask: 'NAME',
    type: 'text',
    label: 'Nome do aluno ou parte dele'
  },
  [REGISTRATION]: {
    typeMask: 'NUMBER',
    type: 'number',
    label: 'Número de matrícula'
  },
  [EMAIL]: {
    typeMask: 'NAME',
    type: 'text',
    label: 'E-mail ou parte dele'
  },
  [SCHOOL_CLASS]: {
    typeMask: 'NAME',
    type: 'text',
    label: 'Nome da turma'
  }
}

const headCells = [
  {
    id: CONTRACT,
    numeric: true,
    disabled: false,
    label: 'Contrato'
  },
  {
    id: NAME,
    numeric: false,
    disabled: false,
    label: 'Aluno'
  },
  {
    id: REGISTRATION,
    numeric: true,
    disabled: false,
    label: 'Matrícula'
  },
  {
    id: SCHOOL_CLASS,
    numeric: false,
    disabled: false,
    label: 'Turma'
  },
  {
    id: 'unit',
    numeric: false,
    disabled: false,
    label: 'Unidade'
  },
  {
    id: 'history',
    numeric: false,
    disabled: false,
    label: 'Movimentação'
  },
  {
    id: 'status',
    numeric: false,
    disabled: false,
    label: 'Situação'
  }
]

export { initialValue, simpleSearchBy, typeSearch, headCells }
