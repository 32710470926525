import { useState } from 'react'
import { useFormik } from 'formik'
import { Box, Grid, InputAdornment, Typography } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import {
  ButtonFooterV2,
  DropDown,
  ModalConfirm,
  Select,
  TextField
} from 'components/molecules'
import { Button, DatePicker, InputAutocomplete } from 'components/atoms'
import { formatDateTime, typeMask } from 'services/helpers'
import { disabledButton } from './helpers'
import { getMenuItems, lessonQtyList } from './constants'
import { BoxItemClass } from '../BoxItens'
import * as Styled from './style'
import { ModalPole } from './ModalPole'
import schema from './schema'

const FormSchoolClassDatails = ({
  listGradeSheets,
  formValues,
  setSnack,
  setLoadingOpen,
  staticFormValues,
  updateSchoolClasses,
  modules,
  setStaticFormValues,
  setListGradeSheets,
  setFormValues,
  setOpenModalConfirm,
  classId,
  listCodinator
}) => {
  const [openPole, setOpenPole] = useState(false)
  const [typeModalPole, setTypeModalPole] = useState('')
  const [confirmCurriculum, setConfirmCurriculum] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const openPoleDropDown = Boolean(anchorEl)

  const handleClosePoleDropDown = () => {
    setAnchorEl(null)
  }

  const { values, handleSubmit, setFieldValue, errors, touched, dirty } =
    useFormik({
      initialValues: formValues,
      validateOnMount: true,
      enableReinitialize: true,
      onSubmit: (valuesForm) =>
        updateSchoolClasses({
          setSnack,
          setLoadingOpen,
          listCodinator,
          values: valuesForm,
          setFormValues,
          setStaticFormValues,
          setListGradeSheets
        }),
      validationSchema: schema
    })

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid container spacing={{ xs: 0, sm: 2 }}>
          <Styled.Grid item xs={12} sm={12} md={12}>
            <Typography variant="h5">{values?.code}</Typography>

            <DropDown
              anchorEl={anchorEl}
              handleClose={handleClosePoleDropDown}
              open={openPoleDropDown}
              menuItems={getMenuItems(setTypeModalPole, setOpenPole)}
            />

            {values?.pole && (
              <Button
                type="button"
                variant="contained"
                className="academico_btn_acessa_menu"
                onclick={(e) => handleClick(e)}
                color="primary"
                endIcon={<KeyboardArrowDown />}
                value={staticFormValues?.unit}
              />
            )}
          </Styled.Grid>

          <Grid item xs={12} sm={12} md={3}>
            <TextField
              id="code"
              label="Nome da Turma"
              fullWidth
              disabled
              value={values?.code || ''}
              onChange={(e) => setFieldValue('code', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Select
              id="curriculum"
              label="Matriz Curricular"
              value={values?.curriculum || ''}
              optionsList={listGradeSheets}
              onChange={(e) => {
                setConfirmCurriculum(true)
                setFieldValue('curriculum', e.target.value)
              }}
              helperText={errors && touched.curriculum && errors.curriculum}
              error={Boolean(touched.curriculum && errors.curriculum)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <Styled.TextField
              label="Duração da aula"
              id="classDuration"
              value={values?.classDuration || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">minutos</InputAdornment>
                )
              }}
              onChange={(e) => {
                setFieldValue(
                  'classDuration',
                  typeMask('NUMBER', e.target.value)
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <DatePicker
              fullWidth
              id="startDate"
              label="Início"
              value={formatDateTime(values?.startDate) || null}
              onChange={(newValue) => {
                setFieldValue('startDate', newValue)
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <DatePicker
              fullWidth
              id="endDate"
              label="Fim"
              value={formatDateTime(values?.endDate) || null}
              onChange={(newValue) => {
                setFieldValue('endDate', newValue)
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <TextField
              id="min_workload"
              label="Carga horária mínima de Atividade (opcional)"
              fullWidth
              value={String(values.min_workload || '')}
              onChange={(e) => setFieldValue('min_workload', e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">horas</InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Select
              id="qtyClass"
              label="Quantidade de aulas"
              value={values.qtyClass || ''}
              optionsList={lessonQtyList}
              onChange={(e) => setFieldValue('qtyClass', e.target.value)}
              isMargin="0 0 16px"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <InputAutocomplete
              label="Coordenador"
              limitTags={1}
              labelId={`supervisor-${Math.random()}`}
              value={values?.supervisor || null}
              noOptionsText="Nenhum(a) coordenador(a) encontrado(a) com esse nome."
              handleChange={(_, newValue) => {
                setFieldValue('supervisor', newValue)
              }}
              filterSelectedOptions
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, label) => option === label}
              listOptions={listCodinator}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option?.value}>
                  {option.label}
                </Box>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              multiline
              fullWidth
              minRows={1}
              id="resolution"
              label="Resolução da Turma (opcional)"
              value={values?.resolution || ''}
              onChange={(e) => setFieldValue('resolution', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              multiline
              fullWidth
              minRows={1}
              id="observation"
              label="Observações da Turma (opcional)"
              value={values?.observation || ''}
              onChange={(e) => setFieldValue('observation', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <BoxItemClass
              staticFormValues={staticFormValues}
              modules={modules}
            />
          </Grid>

          <Styled.Box>
            <ButtonFooterV2
              labelClose="Cancelar"
              labelConfirm="Salvar"
              size="medium"
              disabled={disabledButton({ values, dirty })}
              onClickClose={() => setOpenModalConfirm(true)}
            />
          </Styled.Box>
        </Grid>
      </form>

      <Styled.Divider />

      <ModalPole
        setSnack={setSnack}
        setLoadingOpen={setLoadingOpen}
        open={openPole}
        classId={classId}
        handleClosePoleDropDown={handleClosePoleDropDown}
        setOpenModalGradeMap={setOpenPole}
        handleClose={() => setOpenPole(false)}
        typeModal={typeModalPole}
      />

      <ModalConfirm
        open={confirmCurriculum}
        handleClose={() => setConfirmCurriculum(false)}
        icon={<Styled.IconWarningAmber color="secondary" />}
        title="Confirmar Matriz Curricular"
        message={`A Matriz Curricular selecionada <b>${values?.curriculum}</b> está correta?`}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={() => {
          setConfirmCurriculum(false)
          setFieldValue('curriculum', '')
        }}
        onClickConfirm={() => setConfirmCurriculum(false)}
        colorButtonConfirm="error"
      />
    </>
  )
}

export { FormSchoolClassDatails }
