/* eslint-disable no-plusplus */
import { addMonths, startOfMonth } from 'date-fns'
import { formatDate, parserMinutesForHours, typeMask } from 'services/helpers'

const handleCompetence = ({ competence, setInfoHeaders }) => {
  const year = parseInt(competence.split('-')[0], 10)
  const month = parseInt(competence.split('-')[1], 10) - 1
  const competenceDate = new Date(year, month, 1)

  const startPeriod = new Date(year, month - 1, 16)
  const endPeriod = new Date(year, month, 15)
  const paymentDate = new Date(year, month + 1, 10)

  const objDates = {
    start_period: startPeriod,
    end_period: endPeriod,
    date_payment: paymentDate
  }

  // Chama a função setInfoHeaders com o objeto das datas
  return setInfoHeaders(objDates)
}

const getTotalMinutes = (data) => {
  const total = data?.reduce(
    (acc, cur) => Number(acc) + Number(cur.total_minutes),
    0
  )

  return parserMinutesForHours(total)
}

const getTotalValue = (data) => {
  const total = data?.reduce(
    (acc, cur) => Number(acc) + Number(cur.total_value),
    0
  )

  return typeMask('MONEY_NUMBER', total)
}

const parserPayrollIdConsolodation = (data) => [
  ...new Set(data.map((item) => item.payroll_id))
]

const parserObjforXLS = (data) =>
  data.map((obj) => ({
    coluna1: obj.unit,
    coluna2: obj.consolidation_checked_by,
    coluna3: obj.professor_name,
    coluna4: obj.cpf,
    coluna5: parserMinutesForHours(obj.total_minutes),
    coluna6: obj.total_value
  }))

const groupByCPF = (data) => {
  const grouped = data.reduce((acc, obj) => {
    const key = obj.cpf
    acc[key] = acc[key] || { cpf: key, historic: [] }
    acc[key].historic.push(obj)
    return acc
  }, {})

  return Object.values(grouped).map(({ historic }) => {
    const [firstHistory, ...restHistoric] = historic

    const aggregateData = restHistoric.reduce(
      (acc, item) => {
        if (item.unit !== firstHistory.unit) acc.qntUnityCount++
        if (
          item.consolidation_checked_by !==
          firstHistory.consolidation_checked_by
        )
          acc.qntSendByCount++

        return acc
      },
      { qntUnityCount: 0, qntSendByCount: 0 }
    )

    return {
      ...firstHistory,
      totalMinutes: getTotalMinutes(historic),
      totalValue: getTotalValue(historic),
      qntUnityCount: aggregateData.qntUnityCount,
      qntSendByCount: aggregateData.qntSendByCount,
      historic
    }
  })
}

export {
  parserObjforXLS,
  groupByCPF,
  handleCompetence,
  parserPayrollIdConsolodation
}
