const reasonReplacements = [
  {
    value: 'Curso de Aprimoramento Profissional',
    label: 'Curso de Aprimoramento Profissional'
  },
  { value: 'Aula normal', label: 'Aula normal' },
  { value: 'Aula Prática', label: 'Aula Prática' },
  { value: 'Reposição de aulas', label: 'Reposição de aulas' },
  { value: 'Produção de conteúdo', label: 'Produção de conteúdo' },
  { value: 'Prova de recuperação', label: 'Prova de recuperação' },
  { value: 'Aula extra/adicional', label: 'Aula extra/adicional' },
  { value: 'Prova de segunda chamada', label: 'Prova de segunda chamada' },
  { value: 'Substituição com atestado', label: 'Substituição com atestado' },
  { value: 'Substituição sem atestado', label: 'Substituição sem atestado' }
]

const headerData = [
  { id: 'discipline', label: 'Disciplina', disabled: false },
  { id: 'module', label: 'Módulo', disabled: false },
  { id: 'Substituído', label: 'Substituído', disabled: true },
  { id: 'Motivo', label: 'Motivo', disabled: true, align: 'center' },
  { id: 'Valor', label: 'Valor', disabled: true },
  { id: 'Início', label: 'Início', disabled: true },
  { id: 'Fim', label: 'Fim', disabled: true },
  { id: 'Tipo', label: 'Tipo', disabled: true }
]

const substitutionTypes = {
  permanent: { value: 'permanent', label: 'Permamente' },
  temporary: { value: 'temporary', label: 'Temporário' }
}

export { reasonReplacements, headerData, substitutionTypes }
