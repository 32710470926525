import { ROUTE } from 'services/constants'
import isAllowUserAccess from 'routes/helpers/isAllowUserAccess'

const routesCheckedPermission = (userPermissions) => {
  const roles = {
    [ROUTE.ADMIN_FINANCIAL_BILLS_RECEIVE]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL'
    ),
    [ROUTE.ADMIN_FINANCIAL_BANK_BILLING_EDIT]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_BANK_BILLING_EDIT'
    ),
    [ROUTE.ADMIN_FINANCIAL_BANK_BILLING_CREATE]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_BANK_BILLING_CREATE'
    ),
    [ROUTE.ADMIN_FINANCIAL_BREAKDOWN]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_BREAKDOWN'
    ),
    [ROUTE.ADMIN_FINANCIAL_INVOICES]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_INVOICES'
    ),
    [ROUTE.ADMIN_FINANCIAL_INVOICES_BANK_BILLING_EDIT]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_INVOICES_BANK_BILLING_EDIT'
    ),
    [ROUTE.ADMIN_FINANCIAL_GROUPS]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_GROUPS'
    ),
    [ROUTE.ADMIN_FINANCIAL_GROUPS_CREATE]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_GROUPS_CREATE'
    ),
    [ROUTE.ADMIN_FINANCIAL_GROUPS_EDIT]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_GROUPS_EDIT'
    ),
    [ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_PAYMENT_PLAN'
    ),
    [ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN_CREATE]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_PAYMENT_PLAN_CREATE'
    ),
    [ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN_EDIT]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_PAYMENT_PLAN_EDIT'
    ),
    [ROUTE.ADMIN_FINANCIAL_CUPON]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_CUPON'
    ),
    [ROUTE.ADMIN_FINANCIAL_CUPON_CREATE]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_CUPON_CREATE'
    ),
    [ROUTE.ADMIN_FINANCIAL_CUPON_EDIT]: isAllowUserAccess(
      userPermissions,
      'FINANCIAL_CUPON_EDIT'
    )
  }

  return roles
}

export { routesCheckedPermission }
