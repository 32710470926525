import { useEffect, useState } from 'react'
import { getComparator, stableSort } from 'services/helpers'
import { sortAsc, sortDesc } from './constants'

const useSortTable = (list, orderByName, isDesc = false) => {
  const hasOrderByName = orderByName || ''
  const [order, setOrder] = useState(isDesc ? sortDesc : sortAsc)
  const [orderBy, setOrderBy] = useState(hasOrderByName)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }

  const sortTable = stableSort(list, getComparator(order, orderBy))

  useEffect(() => {
    setOrderBy(orderByName)
  }, [orderByName])

  return {
    handleRequestSort,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    sortTable
  }
}

export default useSortTable
