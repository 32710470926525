/* eslint-disable react/no-array-index-key */
import { Grid } from '@mui/material'
import { BoxItem } from 'components/atoms'
import { boxList } from './constants'

const BoxItemClass = ({ staticFormValues, modules }) => (
  <Grid container spacing={{ xs: 0, sm: 2 }}>
    {boxList({ ...staticFormValues, modules }).map((item, index) => (
      <Grid item xs={12} sm={12} md={item?.grid || 4} key={index}>
        <BoxItem title={item.title} subTitle={item.subTitle} />
      </Grid>
    ))}
  </Grid>
)

export { BoxItemClass }
