import TAG_MANAGER from 'tagManager'

const CLASSES_PROGRESS = 'inProgress'
const CLASSES_DONE = 'done'
const CLASSES_NEXT_CLASSES = 'nextClasses'

export const classTypes = [
  {
    value: CLASSES_PROGRESS,
    label: 'Em andamento',
    emptyLabel: 'Não encontramos nenhuma disciplina em andamento',
    tagManager: TAG_MANAGER.teacher_tab_access_course_in_progress
  },
  {
    value: CLASSES_DONE,
    label: 'Encerradas',
    emptyLabel: 'Não encontramos nenhuma turma encerrada',
    tagManager: TAG_MANAGER.teacher_tab_access_subject_closed
  },
  {
    value: CLASSES_NEXT_CLASSES,
    label: 'Próximas',
    emptyLabel: 'Não encontramos nenhuma próxima turma',
    tagManager: TAG_MANAGER.teacher_guide_access_course_next
  }
]

export const defaultClassType = 'inProgress'
export const defaultActivePage = 1
export const defaultTotalPage = 1
export const defaultParams = null
export const defaultClassList = []
export const defaultBreakpoint = '(max-width:768px)'
export const defaultElementSize = 'normal'
export const smallElementSize = 'small'
