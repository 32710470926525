/* eslint-disable no-shadow */
import { parseISO } from 'date-fns'
import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import { initialValues as initialState } from './constants'
import { disabledButton } from './helpers'
import { disabledLinkedEndDate, disabledLinkedStartDate } from '../../helpers'
import * as Styled from './style'

const ModalEditPeriod = ({
  handleClose,
  open,
  handleSubmit,
  setLoadingOpen,
  date,
  setSnack,
  idModalReenroll,
  setOpenModalReenroll,
  className,
  course,
  modules
}) => {
  const initialValues = {
    ...initialState,
    beginDate: parseISO(date.beginDate),
    endDate: parseISO(date.endDate)
  }

  return (
    <Styled.Modal open={open} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          handleSubmit({
            values: {
              begin_date: values.beginDate,
              end_date: values.endDate
            },
            setLoadingOpen,
            setSnack,
            idModalReenroll,
            setOpenModalReenroll
          })
        }}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <Styled.Title variant="h5" component="h2">
                    Editar período
                  </Styled.Title>
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="body2">
                    <b> Turma </b> {className} - {course}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="body2">
                    <b> Módulo </b>{' '}
                    {Number(modules) === 0 ? 'Único' : `${modules}º Módulo`}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <DatePicker
                    fullWidth
                    id="beginDate"
                    minDate={new Date()}
                    disabled={disabledLinkedStartDate({
                      begin_date: date.beginDate
                    })}
                    label="De"
                    value={values?.beginDate || null}
                    onChange={(newValue) =>
                      setFieldValue('beginDate', newValue)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DatePicker
                    fullWidth
                    id="endDate"
                    minDate={new Date()}
                    disabled={disabledLinkedEndDate({ end_date: date.endDate })}
                    label="Até"
                    value={values?.endDate || null}
                    onChange={(newValue) => setFieldValue('endDate', newValue)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {disabledButton({
                    values,
                    beginDate: date.beginDate
                  }) && (
                    <Styled.Text>
                      Data deve ser superior a data de hoje.
                    </Styled.Text>
                  )}
                </Grid>
              </Grid>
              <Styled.Box>
                <ButtonFooterV2
                  labelClose="Cancelar"
                  labelConfirm="Confirmar"
                  size="large"
                  onClickClose={handleClose}
                  disabledConfirm={disabledButton({
                    values,
                    beginDate: date.beginDate
                  })}
                />
              </Styled.Box>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export default ModalEditPeriod
