import FEEDBACK_SNACK from 'feedBackSnack'
import {
  createHistoryStudent,
  getCourseStudent,
  getLoadclassStudent,
  SavedHistoryStudent
} from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import { VALUE_STATUS } from './components/OptionsCourse/constants'
import { querySetForm } from './helpers'

const loadCourseStudent = async ({
  setSnack,
  setLoading,
  setStudentListCourse,
  register
}) => {
  setSnack('')
  setLoading(true)

  const { data: response, error, status } = await getCourseStudent(register)
  setLoading(false)

  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfo
    })
  }

  setStudentListCourse(response)
}

const getHistoryStudent = async ({
  value,
  studentInfo,
  setResponseCreateHitory,
  setLoading,
  setSnack,
  setStatus
}) => {
  setSnack('')
  setLoading(true)

  const payload = querySetForm(value, studentInfo)
  const { data: response, error, status } = await createHistoryStudent(payload)
  setLoading(false)

  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateHistoryStudent
    })
  }

  setResponseCreateHitory(response)
  setStatus(VALUE_STATUS.NEXT_PAGE_TWO)
}

const loadclass = async ({ id, setClassInfo, setSnack }) => {
  setSnack('')

  const { data: response, error, status } = await getLoadclassStudent(id)
  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfo
    })
  }

  setClassInfo(response)
}

const savedHistoryStudent = async ({
  setSnack,
  historicId,
  selectedItem,
  setResponseSavedHistoryStudent
}) => {
  setSnack('')

  const classIds = { school_class_ids: selectedItem }
  const {
    data: response,
    error,
    status
  } = await SavedHistoryStudent(classIds, historicId)

  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToStudentInfo
    })
  }

  setResponseSavedHistoryStudent(!!response)
}

export { loadCourseStudent, getHistoryStudent, loadclass, savedHistoryStudent }
