import styled from 'styled-components'

const Container = styled.div`
  .MuiTypography-h5 {
    text-align: center;
    color: #000;
  }
`

const WrapperElement = styled.div`
  width: calc(600px - 24px);
  display: flex;
  justify-content: center;
  display: flex;

  .MuiSvgIcon-root {
    width: 64px;
    height: 64px;
  }
`

export { Container, WrapperElement }
