import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useSnack } from 'services/hooks'
import { IconUploadPhoto } from 'components/molecules'
import { UploadPhotoV2 } from 'components/organisms'
import {
  breadCrumbsItems,
  initialAcademicEducation,
  intialPesonalData
} from './constants'
import { TabsPersonalDataByAcademicEducation } from './components/Tabs'
import {
  getAllInitialServices,
  getListClassLinked,
  getListReplaceClass,
  uploadProfessorsPhoto
} from './services'
import { useTabActive } from './components/ClassLinked/Context/TabContext'
import * as Styled from './style'
import { initialTimekeeping } from './components/FormTimekeeping/constants'

const CreateByUpdateProfessors = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { setSnack } = snackProps
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { isEdit, id } = params

  const { resetTabActive } = useTabActive()
  const [isUpdate, setIsUpdate] = useState(false)
  const [isFinalized, setIsFinalized] = useState(false)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [listClassLinked, setListClassLinked] = useState([])
  const [listReplacement, setListReplacement] = useState([])
  const [listAcademicEducation, setListAcademicEducation] = useState([])
  const [notPrefessorCreated, setNotPrefessorCreated] = useState(!isEdit)
  const [formPesonalData, setFormPesonalData] = useState(intialPesonalData)
  const [isFinalizedClassLinked, setIsFinalizedClassLinked] = useState(false)
  const [openModalConfirmCancel, setOpenModalConfirmCancel] = useState(false)

  const [listAccess, setListAccess] = useState({})
  const [notSearch, setNotSearch] = useState(false)
  const [errorTypeFile, setErrorTypeFile] = useState(false)
  const [formAcademicEducation, setFormAcademicEducation] = useState(
    initialAcademicEducation
  )
  const [optionsState, setOptionsState] = useState([])
  const [optionsCity, setOptionsCity] = useState([])
  const [optionsCityState, setOptionsCityState] = useState([])
  const [optionsRegional, setOptionsRegional] = useState([])
  const [value, setValue] = useState(0)
  const [openPhoto, setOpenPhoto] = useState(false)
  const initalPhoto = { file: '' }
  const [formTimekeeping, setFormTimekeeping] = useState(
    initialTimekeeping
  )
  const [listTimekeeping, setListTimekeeping] = useState([])

  const onHandleConfirm = () => {
    resetTabActive()
    setOpenModalConfirmCancel(false)
    navigate(ROUTE.ADMIN_HR_PROFESSOR, {
      state: { savedSearch: true }
    })
  }

  useEffect(() => {
    getAllInitialServices({
      id,
      isEdit,
      setSnack,
      setListAccess,
      setLoadingOpen,
      setOptionsState,
      setOptionsRegional,
      setFormPesonalData,
      setListAcademicEducation
    })
  }, [])

  useEffect(() => {
    if (isEdit)
      getListClassLinked({
        id,
        setSnack,
        setNotSearch,
        setLoadingOpen,
        setListClassLinked,
        isFinalizedClassLinked
      })
  }, [isFinalizedClassLinked, isUpdate, isEdit])

  useEffect(() => {
    if (isEdit)
      getListReplaceClass({
        id,
        setSnack,
        isFinalized,
        setNotSearch,
        setLoadingOpen,
        setListReplacement
      })
  }, [isFinalized, isUpdate, isEdit])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      breadcrumbsItems={breadCrumbsItems(isEdit)}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() => setOpenModalConfirmCancel(true)}
      open={openModalConfirmCancel}
      handleCloseModal={() => setOpenModalConfirmCancel(false)}
      handleConfirmModal={() => onHandleConfirm()}
      content={
        <>
          {isEdit && (
            <Styled.Box>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12} sm={12} md={1}>
                  <IconUploadPhoto
                    avatarAdmin
                    onClick={() => setOpenPhoto(!openPhoto)}
                    photo={listAccess?.academic_profile_picture}
                  />
                  <Typography variant="overline" component="overline">
                    {id}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={11}>
                  <Typography variant="h5" component="h5">
                    {formPesonalData.name}
                  </Typography>
                  <Typography variant="caption" component="caption">
                    {formPesonalData.email}
                  </Typography>
                  <Typography variant="caption" component="caption">
                    {formPesonalData.phone_number}
                  </Typography>
                </Grid>
              </Grid>
            </Styled.Box>
          )}

          <UploadPhotoV2
            open={openPhoto}
            initialState={initalPhoto}
            handleClose={() => {
              setOpenPhoto()
            }}
            handleUpload={({ file }) =>
              uploadProfessorsPhoto({
                idProfessor: id,
                file,
                setSnack,
                setLoadingOpen,
                setFormPesonalData,
                setListAcademicEducation,
                setListAccess,
                setNotPrefessorCreated,
                setOpenPhoto
              })
            }
            setErrorTypeFile={setErrorTypeFile}
            errorTypeFile={errorTypeFile}
          />

          <TabsPersonalDataByAcademicEducation
            isEdit={isEdit}
            idProfessor={id}
            setSnack={setSnack}
            tabSelected={value}
            notSearch={notSearch}
            setTabSelected={setValue}
            optionsCity={optionsCity}
            setIsUpdate={setIsUpdate}
            isFinalized={isFinalized}
            optionsState={optionsState}
            setLoadingOpen={setLoadingOpen}
            setOptionsCity={setOptionsCity}
            setIsFinalized={setIsFinalized}
            setOptionsState={setOptionsState}
            optionsRegional={optionsRegional}
            formPesonalData={formPesonalData}
            listClassLinked={listClassLinked}
            listReplacement={listReplacement}
            optionsCityState={optionsCityState}
            setOptionsRegional={setOptionsRegional}
            setFormPesonalData={setFormPesonalData}
            setListClassLinked={setListClassLinked}
            setListReplacement={setListReplacement}
            setOptionsCityState={setOptionsCityState}
            notPrefessorCreated={notPrefessorCreated}
            listAcademicEducation={listAcademicEducation}
            formAcademicEducation={formAcademicEducation}
            isFinalizedClassLinked={isFinalizedClassLinked}
            setNotPrefessorCreated={setNotPrefessorCreated}
            setListAcademicEducation={setListAcademicEducation}
            setFormAcademicEducation={setFormAcademicEducation}
            setIsFinalizedClassLinked={setIsFinalizedClassLinked}
            setOpenModalConfirmCancel={setOpenModalConfirmCancel}
            formTimekeeping={formTimekeeping}
            setFormTimekeeping={setFormTimekeeping}
            listTimekeeping={listTimekeeping}
            setListTimekeeping={setListTimekeeping}
          />
        </>
      }
    />
  )
}

export default CreateByUpdateProfessors
