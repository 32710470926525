import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'

import { dateAddDays } from 'services/helpers'
import { useMakeService } from 'services/hooks'
import { DatePicker } from 'components/atoms'
import { ButtonFooterV2, Select } from 'components/molecules'

import * as Styled from './style'
import { days, defaultValues, makeServiceHookParams } from './config'
import { getListCourses } from '../../service'

export default function SearchForm({ setSnack, handleSearch }) {
  const [listCourse, setListCourse] = useState([])
  const { unitys, level: listLevel } = useMakeService(makeServiceHookParams)

  const listUnitys = unitys?.map((unity) => ({ ...unity, value: unity?.label }))

  const isDisabled = ({ unit, startDate, endDate }) =>
    !unit || (!!startDate && !endDate)

  const handleResetForm = (resetForm) => {
    handleSearch(null)
    resetForm()
  }

  const { handleSubmit, setFieldValue, values, resetForm } = useFormik({
    initialValues: defaultValues,
    onSubmit: handleSearch,
    validateOnMount: true
  })

  useEffect(() => {
    if (values?.unit && values?.level) {
      getListCourses({
        setSnack,
        setState: setListCourse,
        params: {
          level: values?.level,
          unit: values?.unit
        }
      })
    }
  }, [values?.unit, values?.level])

  return (
    <Styled.Container>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12} sm={12} md={4}>
          <Select
            label="Unidade*"
            value={values.unit}
            optionsList={listUnitys}
            onChange={(e) => {
              setFieldValue('unit', e.target.value)
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Select
            label="Nível"
            value={values.level}
            optionsList={listLevel}
            onChange={(e) => setFieldValue('level', e.target.value)}
            disabled={!values?.unit}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <Select
            label="Curso"
            value={values.course}
            optionsList={listCourse}
            onChange={(e) => setFieldValue('course', e.target.value)}
            disabled={!values?.level}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <DatePicker
            fullWidth
            id="startDate"
            label="Solicitados de"
            value={values.startDate}
            onChange={(newValue) => {
              setFieldValue('startDate', newValue)
              setFieldValue('endDate', null)
            }}
            maxDate={values?.endDate ?? undefined}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <DatePicker
            fullWidth
            id="endDate"
            label="Até"
            value={values.endDate}
            onChange={(newValue) => setFieldValue('endDate', newValue)}
            disabled={!values?.startDate}
            minDate={values?.startDate}
            maxDate={dateAddDays(values?.startDate, days)}
            error={!!values?.startDate && !values?.endDate}
            helperText={
              !!values?.startDate &&
              !values?.endDate &&
              'Informe o período final'
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ButtonFooterV2
            disabledClose={isDisabled(values)}
            disabledConfirm={isDisabled(values)}
            labelClose="Limpar"
            labelConfirm="Buscar"
            onClickClose={() => handleResetForm(resetForm)}
            onClickConfirm={() => handleSubmit()}
            size="large"
            startIcon={<Clear />}
          />
        </Grid>
      </Grid>
    </Styled.Container>
  )
}

SearchForm.propTypes = {
  handleSearch: PropTypes.func
}

SearchForm.defaultProps = {
  handleSearch: () => {}
}
