export const KEY_COLUMNS = {
  UNITY: 'unity',
  SECTOR: 'sectorName'
}

const tableColumns = [
  { id: KEY_COLUMNS.UNITY, label: 'Unidade', width: '55%', disabled: true },
  { id: KEY_COLUMNS.SECTOR, label: 'Setor', width: '45%' }
]

export { tableColumns }
