import { PROFESSOR_ENDPOINT } from './professor'
import { STUDENT_ENDPOINT } from './student'
import { COMERCIAL_ENDPOINT } from './comercial'
import { ADMIN } from './admin'

export const ENDPOINT = {
  LOGIN: '/login',
  NOTIFICATION: 'notifications',
  PROFILE: 'profile',
  USER: 'users',
  LOGIN_LEGACY: 'login-legacy',
  LOGS: 'logs',
  ...PROFESSOR_ENDPOINT,
  ...COMERCIAL_ENDPOINT,
  ...STUDENT_ENDPOINT,
  ...ADMIN
}
