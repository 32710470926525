import styled from 'styled-components'
import {
  TableCell as MuiTableCell,
  Button as MuiButton,
  IconButton as MuiIconButton,
  TableHead as MuiTableHead
} from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'

const BoxTableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    background-color: ${({ theme }) => theme.palette.gray.light};
    border-right: 1px solid white;
    font-weight: bold;
    min-width: 180px;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;

    & > div &:hover {
      display: flex;
    }

    &:hover > div {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      border-radius: 4px;
    }
  }
`
const Title = styled.div`
  position: relative;
`

const BoxIcons = styled.div`
  position: absolute;
  z-index: 1;
  top: 1;
  right: 0;
  display: none;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

const Button = styled(MuiButton)`
  &.MuiButtonBase-root-MuiButton-root {
    padding: 6px;
  }
  p {
    margin: 0 0 0 8px;
  }
  svg {
    margin: 0 0 0 8px;
  }
`

const IconButton = styled(MuiIconButton)`
  &.MuiSvgIcon-root {
    margin-left: 8px;
    padding: 8px 0;
  }
`

const EditIcon = styled(Edit)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.background.white};
  }
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.textColors.error};
  }
`

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    background-color: ${({ theme }) => theme.palette.gray.light};
    border-right: 1px solid white;
    font-weight: bold;
    min-height: 56px;
  }
`

const TableHead = styled(MuiTableHead)`
  &.MuiTableHead-root {
    ${({ $dropouts }) => $dropouts && 'display: none'};
  }
`

const BoxActivity = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 8px;
`

export {
  BoxTableCell,
  Title,
  BoxIcons,
  Button,
  IconButton,
  EditIcon,
  DeleteIcon,
  TableCell,
  TableHead,
  BoxActivity
}
