/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ERROR, ROUTE, SUCCESS } from 'services/constants'
import { useSnack } from 'services/hooks'
import { Form, Formik } from 'formik'
import {
  IsEmpty,
  messageErroOrSuccess,
  updateErrorMessage
} from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  createNewCurriculum,
  getCurriculumById,
  updateCurriculum
} from 'services/api/admin'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import { MESSAGE, breadcrumbsItems, initialState } from './constants'
import { listSubjects, parseValues } from './services'
import { deseableNotConfirm, payload } from './helpers'
import Tabs from './Tabs'
import * as Styled from './style'
import { getListCourses } from '../ListCurriculum/services'

const CreateByUpdateCurriculum = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id: isIdCurriculum, course: courseName, isEdit } = params
  const hasCreateCurriculum = location?.state?.newCurriculum

  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [listCourse, setListCourse] = useState([])
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [curriculumEdit, setCurriculum] = useState(initialState)
  const [fields, setFields] = useState(initialState.disciplines)
  const [subject, setSubject] = useState()
  const initialValues = parseValues(curriculumEdit, fields)

  const isResturnBackPage = true
  const hasYearGrid = true

  const handleSubjectsId = async () => {
    setSnack('')
    setLoading(true)
    const {
      data: response,
      error,
      status
    } = await getCurriculumById(Number(isIdCurriculum))

    setFields(response?.disciplines)
    getListCourses({
      setListCourse,
      levels: response?.level,
      setSnack
    })

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCurriculum
      })
    }

    setCurriculum({
      ...response
    })

    return setLoading(false)
  }

  const onUpdateCurriculum = async (values) => {
    setSnack('')
    setLoading(true)

    const { error, status } = await updateCurriculum(
      payload({ values, fields, subject }),
      isIdCurriculum
    )
    setLoading(false)

    if (error) {
      return messageErroOrSuccess({
        navigate,
        error,
        setSnack,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateCurriculum,
        successMessage: MESSAGE.SUCCESS,
        isResturnBackPage
      })
    }

    setSnack(MESSAGE.SUCCESS_EDIT, SUCCESS)

    return setTimeout(() => navigate(-1), 3000)
  }

  const newCurriculum = async (values) => {
    setSnack('')
    setLoading(true)

    if (isIdCurriculum) {
      return onUpdateCurriculum(values)
    }

    if (IsEmpty(fields)) {
      setLoading(false)
      return setSnack(MESSAGE.DISPLINES_IS_EMPTY, ERROR)
    }

    const {
      data: response,
      error,
      status
    } = await createNewCurriculum(
      payload({ values, fields, subject, hasYearGrid })
    )
    setLoading(false)

    if (error) {
      return messageErroOrSuccess({
        navigate,
        error,
        setSnack,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToViewCurriculum,
        successMessage: MESSAGE.SUCCESS_EDIT,
        isResturnBackPage
      })
    }

    setCurriculum({
      ...response
    })

    setSnack(MESSAGE.SUCCESS, SUCCESS)

    return setTimeout(() => navigate(-1), 3000)
  }

  const handleConfirme = () => {
    setOpenModal(false)
    navigate(ROUTE.ADMIN_MANAGEMENT_LIST_CURRICULUM, {
      state: { savedSearch: true }
    })
  }

  useEffect(() => {
    if (isIdCurriculum) handleSubjectsId()
  }, [isIdCurriculum])

  useEffect(() => listSubjects({ setSnack, setSubject }), [])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      loadingOpen={loading}
      breadcrumbsNotLink={breadcrumbsItems(
        hasCreateCurriculum,
        isIdCurriculum,
        courseName
      )}
      classNameHeader={`goBack ${
        location?.state?.newCurriculum &&
        TAG_MANAGER.gestao_btn_cancela_novaDisciplina
      }`}
      onClickButton={() => {
        navigate(ROUTE.ADMIN_MANAGEMENT_LIST_CURRICULUM, {
          state: { savedSearch: true }
        })
      }}
      content={
        <>
          <Formik
            initialValues={isIdCurriculum ? initialValues : initialState}
            onSubmit={newCurriculum}
            enableReinitialize
          >
            {({ ...props }) => (
              <Form onSubmit={props.handleSubmit} noValidate autoComplete="off">
                <Tabs
                  props={props}
                  setLoading={setLoading}
                  setSnack={setSnack}
                  isEdit={isEdit}
                  initialState={initialValues}
                  setOpenModal={setOpenModal}
                  openModalDelete={openModalDelete}
                  setOpenModalDelete={setOpenModalDelete}
                  setListCourse={setListCourse}
                  subject={subject}
                  setSubject={setSubject}
                  listCourse={listCourse}
                  fields={fields}
                  setFields={setFields}
                  isIdCurriculum={isIdCurriculum}
                  navigate={navigate}
                  isResturnBackPage={isResturnBackPage}
                />

                <Styled.Box>
                  <ButtonFooterV2
                    labelClose="Cancelar"
                    labelConfirm="Salvar"
                    size="medium"
                    disabledConfirm={deseableNotConfirm(props.values, fields)}
                    onClickClose={() => setOpenModal(true)}
                  />
                </Styled.Box>
              </Form>
            )}
          </Formik>
          <ModalConfirm
            open={openModal}
            size="medium"
            handleClose={() => setOpenModal(false)}
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            onClickNotConfirm={() => setOpenModal(false)}
            onClickConfirm={() => handleConfirme()}
            colorButtonConfirm="error"
            icon={<Styled.IconWarningAmber />}
            title="Cancelar"
            message="Deseja cancelar a operação?
 Você perderá as alterações que ainda não foram salvas."
            classNameConfirm={TAG_MANAGER.gestao_btn_cancela_novaDisciplina}
          />
        </>
      }
    />
  )
}

export default CreateByUpdateCurriculum
