export const SEARCH_TYPE = {
  ENROLLMENT: 'register',
  NAME: 'name',
  CPF: 'cpf'
}

const typeSearchOptions = [
  {
    id: 1,
    value: SEARCH_TYPE.NAME,
    label: 'Nome'
  },
  {
    id: 2,
    value: SEARCH_TYPE.ENROLLMENT,
    label: 'Matrícula'
  },
  {
    id: 3,
    value: SEARCH_TYPE.CPF,
    label: 'CPF'
  }
]

const formModel = {
  searchType: {
    key: 'searchType',
    label: 'Selecione'
  },
  fieldValue: {
    key: 'fieldValue',
    getLabel: (searchType) => {
      const labels = {
        [SEARCH_TYPE.NAME]: 'Digite o nome ou parte dele',
        [SEARCH_TYPE.CPF]: 'Digite somente números',
        [SEARCH_TYPE.ENROLLMENT]: 'Digite a Matrícula'
      }
      return labels[searchType]
    }
  },
  duplicated: {
    key: 'duplicated',
    label: 'Duplicados'
  }
}

export { typeSearchOptions, formModel }
