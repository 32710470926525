import styled from 'styled-components'
import {
  ListItem as MuiListItem,
  Paper as MuiPaper,
  Typography as MuiTypography
} from '@mui/material'
import { getRgba } from 'services/helpers/polished'

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 33rem;
    height: 21rem;
    overflow: auto;
    margin-top: 2rem;
  }
  span {
    font-size: 16px;
  }
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: 700;
    font-size: 16px;
    color: #000;
    padding: 0.5rem 1rem;
    margin-bottom: 1px solid #000;
  }
`
const ListItem = styled(MuiListItem)`
  &.MuiListItem-root {
    &:focus {
      background-color: ${({ theme }) => getRgba(theme.palette.dark.main, 0.1)};
    }
  }
`

export { Paper, Typography, ListItem }
