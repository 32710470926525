import { Grid, Switch, Typography } from '@mui/material'
import { LoadingPage, Select } from 'components/molecules'
import { useMakeService } from 'services/hooks'
import { useEffect, useState } from 'react'
import {
  getListGroups,
  getListPoles,
  getListUnits
} from 'services/api/admin/comercial/courses'
import {
  parseResponse,
  selectListType
} from 'components/pages/Admin/Commercial/NewEnrollment/helpers'
import { getCourses } from 'services/api/admin'
import * as Styled from './style'
import { getContractBranchList } from './services'

const StepFirst = ({ formik, setSnack, setIsPole, setLoadingVisibility }) => {
  const [groups, setGroups] = useState([])
  const [selectedUnity, setSelectedUnity] = useState()
  const [listCours, setCourses] = useState([])
  const [listPolos, setListPolos] = useState([])
  const [listUnitys, setListUnitys] = useState([])
  const [listContractBranch, setListContractBranch] = useState([])
  const [loading, setLoading] = useState(false)

  const paramsMakeService = {
    isLevel: true,
    isPeriod: true,
    isModule: true,
    hasLabelSemanal: true,
    isContractModel: true,
    setSnack
  }
  const { level, period, modules } = useMakeService(paramsMakeService)
  const isEmptyUnity = formik.values.unidade === ''
  const isEmptyLevel = formik.values.level === ''
  const isEmptyUnityNovotec = formik.values.unidade === '(SP) NOVOTEC'

  const getGroups = async () => {
    const { data } = await getListGroups()
    setGroups(selectListType(data))
  }

  const getCourse = async () => {
    const { values } = formik
    const unitId = selectedUnity?.id ?? values.unit?.id
    const { data } = await getCourses({
      level: values.level,
      unit_id: unitId
    })

    const coursesFormated = parseResponse(data?.data, true)
    setCourses(coursesFormated)
  }

  const getPoles = async (unity) => {
    if (!isEmptyUnity) {
      const unit =
        listUnitys?.find(({ label }) => label === unity) ?? formik.values.unit

      const { data } = await getListPoles(unit?.id)
      const dataFormated = data?.data?.map((item) => ({
        value: item.pole_unit,
        label: item.pole_unit
      }))
      setListPolos(dataFormated)
    }
  }

  const getUnits = async () => {
    const { data } = await getListUnits()
    const dataFormated = data?.data?.map((item) => ({
      id: item.id_unidade,
      value: item.unidade,
      label: item.unidade,
      polo: item.isPole
    }))
    setListUnitys(dataFormated)
  }

  useEffect(async () => {
    setLoading(true)
    if (!isEmptyUnity) await getPoles(formik.values.unidade)
    setLoading(false)
  }, [formik.values.unidade])

  useEffect(async () => {
    setLoading(true)
    if (!isEmptyUnity || !isEmptyLevel) {
      const selected = listUnitys.find(
        (item) => item.value === formik.values.unidade
      )
      setIsPole(selected?.polo)
      setSelectedUnity(selected)
    }
    if (!isEmptyUnity && !isEmptyLevel) {
      await getCourse()
    }
    setLoading(false)
  }, [formik.values.unidade, formik.values.level])

  useEffect(async () => {
    setLoading(true)
    await Promise.all([
      getGroups(),
      getUnits(),
      getContractBranchList({
        setSnack,
        setLoadingVisibility,
        setListContractBranch
      })
    ])
    formik.validateForm()
    setLoading(false)
  }, [])

  return (
    <Styled.Box>
      <LoadingPage open={loading} />
      <Grid
        justifyContent={isEmptyUnity ? 'center' : 'start'}
        container
        spacing={{ xs: 0, sm: 2 }}
      >
        <Grid item xs={12} sm={12} md={!isEmptyUnity ? 3 : 4}>
          <Select
            id="unity"
            name="unidade"
            label="Unidade*"
            optionsList={listUnitys}
            onBlur={formik.handleBlur}
            value={formik.values.unidade}
            onChange={(e) => {
              formik.handleChange(e)
              const unit = listUnitys?.find(
                (unitItem) => e.target.value === unitItem.value
              )
              formik.setFieldValue('level', '')
              formik.setFieldValue('unit', unit)
            }}
            helperText={formik.touched.unidade && formik.errors.unidade}
            error={formik.touched.unidade && Boolean(formik.errors.unidade)}
          />

          {isEmptyUnity && (
            <Styled.Typography variant="body2">
              Selecione a unidade para iniciar a matrícula direta
            </Styled.Typography>
          )}
        </Grid>

        {!isEmptyUnity && (
          <>
            <Grid item xs={12} sm={12} md={3}>
              <Select
                fullWidth
                id="polo"
                name="polo"
                label="Polo*"
                optionsList={listPolos}
                onBlur={formik.handleBlur}
                value={formik.values.polo}
                onChange={formik.handleChange}
                helperText={
                  !isEmptyUnityNovotec &&
                  selectedUnity &&
                  formik.touched.polo &&
                  formik.errors.polo
                }
                error={
                  !isEmptyUnityNovotec &&
                  formik.touched.polo &&
                  Boolean(formik.errors.polo)
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Select
                fullWidth
                id="group"
                name="grupo"
                label="Grupo*"
                optionsList={groups}
                onBlur={formik.handleBlur}
                value={formik.values.grupo}
                onChange={formik.handleChange}
                helperText={formik.touched.grupo && formik.errors.grupo}
                error={formik.touched.grupo && Boolean(formik.errors.grupo)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Styled.Switch>
                <Switch
                  name="ead"
                  label="EAD"
                  onBlur={formik.handleBlur}
                  checked={formik.values.ead}
                  onChange={formik.handleChange}
                  helperText={formik.touched.ead && formik.errors.ead}
                  error={formik.touched.ead && Boolean(formik.errors.ead)}
                />
                <Typography>EAD</Typography>
              </Styled.Switch>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Select
                fullWidth
                id="level"
                name="level"
                label="Nível*"
                optionsList={level}
                onBlur={formik.handleBlur}
                value={formik.values.level}
                onChange={(e) => {
                  formik.handleChange(e)
                }}
                helperText={formik.touched.level && formik.errors.level}
                error={formik.touched.level && Boolean(formik.errors.level)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Select
                fullWidth
                id="course"
                name="curso"
                label="Curso*"
                optionsList={listCours}
                onBlur={formik.handleBlur}
                value={formik.values?.curso}
                onChange={(e) => {
                  formik.setFieldValue('curso', e.target.value)
                }}
                isMargin="0"
                disabled={isEmptyUnity || isEmptyLevel}
                helperText={formik.touched.curso && formik.errors.curso}
                error={formik.touched.curso && Boolean(formik.errors.curso)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Select
                fullWidth
                id="shift"
                isMargin="0"
                name="turno"
                label="Turno*"
                optionsList={period}
                onBlur={formik.handleBlur}
                value={formik.values?.turno}
                onChange={(e) => {
                  formik.setFieldValue('turno', e.target.value)
                }}
                disabled={isEmptyUnity || isEmptyLevel}
                helperText={formik.touched.turno && formik.errors.turno}
                error={formik.touched.turno && Boolean(formik.errors.turno)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Select
                fullWidth
                id="module"
                name="modulo"
                label="Módulo*"
                optionsList={modules}
                disabled={isEmptyLevel}
                onBlur={formik.handleBlur}
                value={formik.values.modulo}
                onChange={(e) => {
                  formik.setFieldValue('modulo', e.target.value)
                }}
                helperText={formik.touched.modulo && formik.errors.modulo}
                error={formik.touched.modulo && Boolean(formik.errors.modulo)}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Select
                fullWidth
                id="contract_branch"
                name="contract_branch"
                label="Filial de Contrato"
                optionsList={listContractBranch}
                onBlur={formik.handleBlur}
                value={formik.values.contract_branch}
                onChange={(e) => {
                  formik.setFieldValue('contract_branch', e.target.value)
                }}
                helperText={
                  formik.touched.contract_branch &&
                  formik.errors.contract_branch
                }
                error={
                  formik.touched.contract_branch &&
                  Boolean(formik.errors.contract_branch)
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </Styled.Box>
  )
}
export default StepFirst
