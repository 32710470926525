import styled from 'styled-components'

const Description = styled.div`
  color: #666;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 20px;
`
const BoxFooter = styled.div`
  @media (max-width: 768px) {
    & {
      text-align: center;
    }
  }
`

const Box = styled.div`
  margin: 24px 0;
`

export { Description, BoxFooter, Box }
