const EDIT = 'edit'
const NEW = 'new'
const DEFAULT_PAGE = 1
const DEFAULT_ITEMS_PER_PAGE = 10

const HEAD_CELLS = [
  { id: 'name', label: 'Documento' },
  { id: 'isActive', label: 'Situação', disabled: true },
  { id: 'type', label: 'Tipo', disabled: true },
  { id: 'isRequired', label: 'Envio', disabled: true },
  { id: 'extension', label: 'Formato de envio', disabled: true }
]

const INITIAL_STATE = {
  name: '',
  type: 'A',
  extension: 'pdf',
  is_multiple: false,
  is_required: false
}

const OPTIONS_TYPE = [
  { label: 'Aluno', value: 'A' },
  { label: 'Curso', value: 'C' }
]

const MESSAGE = {
  SUCCESS_CREATE: 'Novo documento criado com sucesso!',
  SUCCESS_UPDATE: 'Novo documento salvo com sucesso!',
  SUCCESS_INACTIVE: 'Documento inativado com sucesso!',
  SUCCESS_ACTIVE: 'Documento ativado com sucesso!'
}

const nameAccordion = 'searchDocument'

const emptyStateMessage =
  '<b>Encontre os Documentos existentes no sistema.</b><br />Você pode filtrar com outros critérios a sua busca ou buscar pelo título de um documento.'

export {
  EDIT,
  OPTIONS_TYPE,
  NEW,
  MESSAGE,
  INITIAL_STATE,
  DEFAULT_PAGE,
  DEFAULT_ITEMS_PER_PAGE,
  HEAD_CELLS,
  nameAccordion,
  emptyStateMessage
}
