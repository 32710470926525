import { CheckCircleOutline, WarningAmberOutlined } from '@mui/icons-material'

const typeModal = {
  resendCode: 'resendCode',
  invalidCode: 'invalidCode',
  signedContract: 'signedContract'
}

const getMessagesModal = ({ handleResendCode, handleCloseModal }) => ({
  [typeModal.resendCode]: {
    title: 'Confirme o seu E-mail e Celular',
    textButtonConfirm: 'Confirmar Dados',
    textButtonNotConfirm: 'Cancelar',
    onClickConfirm: handleResendCode,
    colorConfirm: 'primary'
  },
  [typeModal.invalidCode]: {
    title: 'Código Inválido',
    message: 'O código de aceite possui 4 dígitos numéricos.',
    icon: <WarningAmberOutlined color="warning" />,
    labelSubmit: 'Tentar novamente',
    handleSubmit: handleCloseModal
  },
  [typeModal.signedContract]: {
    title: 'Contrato Assinado',
    message: 'O contrato foi assinado com sucesso.',
    icon: <CheckCircleOutline color="success" />,
    labelSubmit: 'Ok',
    handleSubmit: handleCloseModal
  }
})

const payloadSkipSignature = {
  skip_signature_first_time: true
}

const nameTypeContract = 'Contrato de Prestação de Serviços Educacionais'

const MESSAGE = {
  skipSuccessfully: 'Contrato pulado com sucesso'
}

export {
  typeModal,
  getMessagesModal,
  payloadSkipSignature,
  nameTypeContract,
  MESSAGE
}
