import { ROUTE } from 'services/constants'

const breadcrumbsItems = [
  {
    path: ROUTE.contracts.LIST_CONTRACTS,
    label: 'Sistema'
  },
  {
    path: ROUTE.contracts.LIST_CONTRACTS,
    label: 'Tipos de Contrato'
  },
  {
    path: ROUTE.contracts.EDIT_CONTRACTS,
    label: '<b> Editar contrato </b>'
  }
]
const titleModalConfirm = 'Cancelar'
const textModalConfirm = 'Deseja cancelar a operação atual? Você perderá as alterações realizadas.'

export { breadcrumbsItems, titleModalConfirm, textModalConfirm }
