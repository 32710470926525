import styled, { css } from 'styled-components'
import {
  WarningAmber,
  InfoOutlined,
  Feed,
  Delete,
  AddBox
} from '@mui/icons-material'
import {
  Typography,
  Tooltip as MuiTooltip,
  RadioGroup as MuiRadioGroup
} from '@mui/material'
import { Button as MuiButton } from 'components/atoms'
import { TextField as MuiTextField } from 'components/molecules'

const Box = styled.div`
  margin: 24px 0;
`

const BoxHeader = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  max-width: 425px;
  align-items: center;
  justify-content: center;
  > .MuiFormControl-root {
    width: 100%;
  }
`
const Button = styled(MuiButton)`
  ${({ $isError }) =>
    $isError &&
    css`
      margin-top: 0px !important;
    `}
`

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-top: 24px;
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`
const BoxCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`

const TextField = styled(MuiTextField)`
  &.MuiTextField-root {
    margin-top: 16px;
  }
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

const Footer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const InfoFooter = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 16px;
`

const Total = styled(Typography)`
  &.MuiTypography-root {
    color: ${({ $isColor, theme }) =>
      $isColor ? theme.palette.gray.main : theme.palette.dark.main};
    margin-left: 8px;
    min-width: 66px;
    text-align: end;
    font-weight: bold;
  }
`
const TextWarning = styled(Typography)`
  &.MuiTypography-root {
    color: #fff;
    padding: 5px;
    background-color: black;
  }
`

const Tooltip = styled(MuiTooltip)`
  &.MuiTooltip-root {
    margin-top: 2rem;
  }
`
const IconFeed = styled(Feed)`
  &.MuiSvgIcon-root {
    margin: 1.5rem 0.5rem;
    width: 2.5rem;
    height: 2rem;
  }
`
const IconInfoOutlinedIcon = styled(InfoOutlined)`
  &.MuiSvgIcon-root {
    margin-left: 16px;
  }
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.textColors.error};
  }
`

const AddIcon = styled(AddBox)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
  }
`
const ContainerField = styled.div`
  display: flex;
  width: 100%;
`

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: 1px dashed ${({ theme }) => theme.palette.gray.bgLight};
  padding: 16px;
  width: 100%;
  margin-bottom: 24px;

  & > div {
    margin-bottom: 16px;
  }
`

const BoxTooltip = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    -webkit-flex-wrap: nowrap;
  }
`
const Form = styled.form``

export {
  Box,
  Form,
  Footer,
  Button,
  BoxCard,
  BoxHeader,
  BoxContainer,
  TextField,
  InfoFooter,
  TextWarning,
  Tooltip,
  IconFeed,
  Total,
  IconInfoOutlinedIcon,
  IconWarningAmber,
  DeleteIcon,
  AddIcon,
  ContainerField,
  Container,
  BoxTooltip,
  RadioGroup
}
