import styled from 'styled-components'
import { WarningAmber } from '@mui/icons-material'

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 16px 0;
`

export { IconWarningAmber, Box }
