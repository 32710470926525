const defaultTotalPage = 1
const defaultlistPerPage = 10
const defaultClassList = []
const maximunSizePage = 6
const defaultActivePage = 1
const getSuccess = 200
const postSuccess = 201
const putSuccess = 204
const currentPageDefault = 0
const ERROR_MESSAGE_PROFESSOR =
  'Erro ao carregar Disciplinas, tente novamente...'
const typeStudent = 'student'
const typeProfessor = 'professor'
const messageErrorAdmin = 'Sem permissão de acesso'
const adminAllowedAccess = '/dev=true'
const adminAllowedAccessLegacy = '/login-legacy'
const sortAsc = 'asc'
const sortDesc = 'desc'
const indexDefault = -1

export {
  defaultTotalPage,
  defaultlistPerPage,
  defaultClassList,
  maximunSizePage,
  defaultActivePage,
  getSuccess,
  postSuccess,
  putSuccess,
  ERROR_MESSAGE_PROFESSOR,
  currentPageDefault,
  typeStudent,
  typeProfessor,
  messageErrorAdmin,
  adminAllowedAccess,
  adminAllowedAccessLegacy,
  sortAsc,
  sortDesc,
  indexDefault
}
