import styled from 'styled-components'

const Container = styled.div`
  width: 10%;
  height: 100%;
  min-width: 18.43px;
  background-color: #ff7f00;
  height: ${({ $height }) => $height || 'auto'};

  &:hover {
    background-color: #b25800;
  }
`

export { Container }
