const MESSAGE = {
  MAIN_MESSAGE:
    '<b>Encontre as Ocorrências registradas</b><br/>Você pode filtrar com outros critérios a sua busca, pesquisar pelo Número/Código da Ocorrência ou CPF do aluno',
  MSG_NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.'
}

const nameAccordion = 'occurrences'
const defaultItemsPerPage = 10
const defaultPage = 1
const countDefault = 0
const occurrenceCreatedSuccess = 'Ocorrência criada com sucesso'

export {
  MESSAGE,
  nameAccordion,
  defaultItemsPerPage,
  defaultPage,
  countDefault,
  occurrenceCreatedSuccess
}
