import { httpGet, httpPut, httpPost } from 'services/api/http'

import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getListSectors = (params) => {
  const response = httpGet(ENDPOINT.SECTORS.LIST, { params })
  return response
}

export const getListResponsiblesByUnityId = (params) => {
  const response = httpGet(
    `${ENDPOINT.SECTORS.LIST_RESPONSIBLES}?${convertToParams(params)}`
  )
  return response
}

export const updateSector = (id, payload) => {
  const response = httpPut(`${ENDPOINT.SECTORS.CRUD}/${id}`, { ...payload })
  return response
}

export const createSector = (payload) => {
  const response = httpPost(ENDPOINT.SECTORS.CRUD, { ...payload })
  return response
}
