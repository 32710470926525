import PropTypes from 'prop-types'
import { Box, Grid, Link, Typography } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import Button from 'components/atoms/Button'
import TextField from 'components/molecules/TextField'

export default function FormPassword({ changeFormType }) {
  return (
    <form>
      <Typography variant="body2" gutterBottom>
        Recupere sua senha por <strong>email</strong>
      </Typography>
      <Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="on"
            autoFocus
            id="email"
            fullWidth
            label="Email"
            name="email"
            required
            type="email"
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth size="large" endIcon={<MailIcon />}>
            Enviar Instruções
          </Button>
        </Grid>
        <Box sx={{ display: 'block', textAlign: 'center' }}>
          <Link
            onClick={changeFormType}
            href="#root"
            underline="hover"
            variant="body2"
          >
            Verfifique seu email e tente novamente
          </Link>
        </Box>
      </Grid>
    </form>
  )
}

FormPassword.propTypes = {
  changeFormType: PropTypes.func,
}
