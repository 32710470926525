import styled from 'styled-components'
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography
} from '@mui/material'
import {
  DatePicker as MuiDatePicker,
  Modal as MuiModal
} from 'components/atoms'

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 470px;
  }
`

const Grid = styled(MuiGrid)`
  padding: 25px;
`
const DatePicker = styled(MuiDatePicker)`
  margin-bottom: 20px !important;
`

const TextField = styled(MuiTextField)`
  &.MuiTextField-root {
    margin-bottom: 20px;
  }
`

const Container = styled.div`
  min-width: 300px;
  min-height: 160px;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background: ${({ theme }) => theme.palette.background.white};
  padding: 24px;
`

const Header = styled.div`
  margin-bottom: 16px;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

const IconText = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`

const ButtonGroup = styled.div`
  margin: 1rem;
`

export {
  Modal,
  Grid,
  TextField,
  Container,
  Header,
  Title,
  IconText,
  DatePicker,
  ButtonGroup
}
