import * as yup from 'yup'

const formSchema = yup.object().shape({
  nome_fin: yup
    .string()
    .min(8, 'Nome inválido, deve ter 8 ou mais caracteres')
    .required('Campo obrigatório'),
  cep_fin: yup.string().required('Campo obrigatório'),
  cnpj: yup.string().required('Campo obrigatório'),
  ieCnpj: yup
    .number()
    .typeError('Deve ser um número')
    .required('Campo obrigatório'),
  uf_fin: yup.string().required('Campo obrigatório'),
  cidade_fin: yup.string().required('Campo obrigatório'),
  end_fin: yup.string().required('Campo obrigatório'),
  numero_fin: yup
    .number()
    .nullable()
    .typeError('Deve ser um número')
    .required('Campo obrigatório'),
  bairro_fin: yup.string().required('Campo obrigatório'),
  email_fin: yup
    .string()
    .email('E-mail inválido.')
    .required('Campo obrigatório'),
  mobile_phone_fin: yup.string().required('Campo obrigatório')
})

export { formSchema }
