const numberSelected = 0
const isSelected = 1
const defaultName = 'name'
const typeAll = 'Tudo'
const defaultItemsPerPage = 10
const defaultTotalPage = 1

const textTitleModalConfirmUserActive = 'Inativar Acesso'

const textTitleModalConfirmUserDisabled = 'Reativar Acesso'

const textMessageModalConfirmUserActive =
  'Tem certeza que deseja inativar o acesso do usuário abaixo?'

const textTItleModalDisciplineAndClass = 'Disciplinas e Turmas'

const titleModalDownloadsFile = 'Baixar planilha'

const headCells = [
  {
    id: 'name',
    label: 'Nome'
  },
  {
    id: 'e-mail',
    label: 'E-mail'
  },
  {
    id: 'cpf',
    label: 'CPF'
  },
  {
    id: 'Registration Number',
    label: 'Matrícula'
  }
]

export {
  headCells,
  defaultItemsPerPage,
  defaultName,
  numberSelected,
  isSelected,
  typeAll,
  defaultTotalPage,
  textTitleModalConfirmUserActive,
  textMessageModalConfirmUserActive,
  textTitleModalConfirmUserDisabled,
  textTItleModalDisciplineAndClass,
  titleModalDownloadsFile
}
