import { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { Close } from '@mui/icons-material'
import { DatePicker } from 'components/atoms'
import { ButtonFooterV2, Select } from 'components/molecules'
import { isEqualObjects } from 'services/helpers'
import { validationSchema } from './schema'
import { getInteractionsStudent } from '../../services'
import * as Styled from './style'
import { searchByOptions, intialState } from '../../constants'

const FormSearchLogs = ({
  register,
  formValues,
  handleClean,
  setSnack,
  setLoadingOpen,
  setNotFound,
  setListLogs,
  setCount,
  page,
  limit,
  resetPagination,
  setFormValues
}) => {
  useEffect(() => {
    getInteractionsStudent({
      register,
      values: formValues,
      setSnack,
      setLoadingOpen,
      setListLogs,
      setNotFound,
      setCount,
      page,
      limit
    })
  }, [formValues, page, limit])

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values) =>
        isEqualObjects(values, formValues)
          ? getInteractionsStudent({
              register,
              values,
              setSnack,
              setLoadingOpen,
              setListLogs,
              setNotFound,
              setCount,
              page,
              limit
            })
          : setFormValues(values)
      }
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        setFieldValue,
        handleSubmit,
        resetForm,
        touched,
        errors
      }) => (
        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid container spacing={{ xs: 2, sm: 2 }} alignItems="center">
            <Grid item xs={12} sm={12} md={3}>
              <Select
                id="search_type"
                value={values?.searchType || ''}
                optionsList={searchByOptions}
                onChange={(e) => {
                  setFieldValue('beginAt', null)
                  setFieldValue('endAt', null)
                  setFieldValue('searchType', e.target.value)
                  setFormValues({ ...intialState, searchType: e.target.value })
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2.7}>
              <DatePicker
                fullWidth
                id="begin_at"
                label="De*"
                maxDate={new Date()}
                value={values?.beginAt || null}
                onChange={(newValue) => {
                  setFieldValue('beginAt', newValue)
                  setFieldValue('endAt', null)
                }}
                helperText={errors && touched.beginAt && errors.beginAt}
                error={Boolean(touched.beginAt && errors.beginAt)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2.7}>
              <DatePicker
                fullWidth
                id="end_at"
                label="Até*"
                value={values?.endAt || null}
                minDate={values?.beginAt}
                maxDate={new Date()}
                onChange={(newValue) => setFieldValue('endAt', newValue)}
                helperText={errors && touched.endAt && errors.endAt}
                error={Boolean(touched.endAt && errors.endAt)}
              />
            </Grid>

            <Styled.GridButton item xs={12} sm={12} md={3.6}>
              <ButtonFooterV2
                labelClose="Limpar"
                labelConfirm="Buscar"
                size="medium"
                startIcon={<Close />}
                onClickClose={() => handleClean({ resetForm })}
                onClickConfirm={resetPagination}
                disabled={!values?.beginAt || !values?.endAt}
              />
            </Styled.GridButton>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
export { FormSearchLogs }
