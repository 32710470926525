import { ListAlt, Search } from '@mui/icons-material'
import { ROUTE } from 'services/constants'

const initialStateStatus = { empty: true, notFound: false }

const nameAccordion = 'list_grouping'

const messages = {
  empty: {
    icon: <ListAlt />,
    text: '<strong>Você ainda não fez uma busca</strong><br />Selecione uma unidade para visualizar as turmas agrupadas'
  },
  notFound: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
  }
}

const breadcrumbsItems = ({ grouping, groupingId }) => [
  {
    path: ROUTE.ADMIN_SECRETARY_GROUPING,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_GROUPING,
    label: 'Agrupamentos'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_GROUPING,
    label: `<b>${grouping} [${groupingId}]</b>`
  }
]

const listIdName = 'id'

export {
  initialStateStatus,
  nameAccordion,
  messages,
  breadcrumbsItems,
  listIdName
}
