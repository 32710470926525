/* eslint-disable camelcase */
const parseBindByUnbinded = (data) =>
  data?.data?.reduce((acc, cur) => {
    const { enrollment, name, occurrence, active, has_grades, ref_id } = cur
    const newItem = {
      enrollment,
      name,
      occurrences: occurrence?.occurrence,
      active,
      has_grades,
      ref_id
    }
    return [...acc, newItem]
  }, [])

const parseChecked = (data) => data?.map((item) => item.enrollment) || []

export { parseBindByUnbinded, parseChecked }
