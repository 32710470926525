import PropTypes from 'prop-types'
import { ListAlt } from '@mui/icons-material'
import { ButtonLinkTo, TextNotFound } from 'components/atoms'
import * as Styled from './style'

const EmptyState = ({
  icon = <ListAlt />,
  labelEmptyState,
  toUrl,
  labelBtn
}) => (
  <Styled.Box>
    <TextNotFound icon={icon} text={labelEmptyState} />
    {labelBtn && <ButtonLinkTo toUrl={toUrl} labelBtn={labelBtn} />}
  </Styled.Box>
)

EmptyState.propTypes = {
  icon: PropTypes.element,
  labelEmptyState: PropTypes.string,
  toUrl: PropTypes.string,
  labelBtn: PropTypes.string
}

export default EmptyState
