import { useState } from 'react'
import { PropTypes } from 'prop-types'
import { InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'
import { Button } from 'components/atoms'
import { TextField, Select } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'

const submitHandler = ({ event, params, onSubmit }) => {
  onSubmit({ event, params })
}

const FilterClasses = ({ onSubmit }) => {
  const periodList = [
    { value: 0, label: 'Manhã' },
    { value: 1, label: 'Tarde' },
    { value: 2, label: 'Noite' }
  ]

  const [course, setCourse] = useState('')
  const [discipline, setDiscipline] = useState('')
  const [shift, setShift] = useState('')

  const submit = (event) => {
    event.preventDefault()
    const params = { course, discipline, shift: periodList[shift]?.label }
    const payload = { event, params, onSubmit }
    submitHandler(payload)
  }

  const setParam = ({ value }, callback) => callback(value)

  const clearFilter = () => {
    setCourse('')
    setDiscipline('')
    setShift('')
  }

  return (
    <Styled.Container onSubmit={submit}>
      <Styled.InputsArea>
        <TextField
          fullWidth
          id="course"
          label="Curso"
          type="text"
          value={course}
          onChange={({ target }) => setParam(target, setCourse)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
          isMargin="0 16px 0 0"
        />
        <TextField
          fullWidth
          id="discipline"
          label="Disciplina"
          type="text"
          value={discipline}
          onChange={({ target }) => setParam(target, setDiscipline)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
          isMargin="0"
        />
        <Select
          fullWidth
          id="shift"
          value={shift}
          label="Período"
          optionsList={periodList}
          onChange={({ target }) => setParam(target, setShift)}
        />
      </Styled.InputsArea>
      <Styled.ButtonsArea>
        <Button
          size="large"
          value="Limpar"
          type="submit"
          variant="outlined"
          onclick={clearFilter}
          className={TAG_MANAGER.teacher_btn_clean_filter}
        />
        <Button
          size="large"
          value="Filtrar"
          type="submit"
          className={TAG_MANAGER.btn_teacher_apply_filter}
        />
      </Styled.ButtonsArea>
    </Styled.Container>
  )
}

export default FilterClasses

FilterClasses.propTypes = {
  onSubmit: PropTypes.func
}

FilterClasses.defaultProps = {
  onSubmit: () => {}
}
