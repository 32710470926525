export default function globalReducer(state, action) {
  switch (action.type) {
    case 'RESET_STATE':
      return {
        ui: {},
        user: {},
      }
    case 'RESET_USER':
      return {
        ui: state.ui,
        user: {},
      }
    case 'SET_USER':
      return {
        ui: state.ui,
        user: action.payload,
      }
    case 'TOGGLE_SIDEBAR':
      return {
        ui: {
          isSidebarOpen: action.payload,
        },
        user: state.user,
      }
    default:
      return state
  }
}
