export const initialStatus = 'Em andamento'
export const switchDefault = '&types[]=disapproved'
export const itemsPerPage = 6
export const studentDefault = 0

export const statusList = [
  {
    value: 'all',
    label: 'Todos',
  },
  {
    value: 'in_progress',
    label: 'Em andamento',
  },
  {
    value: 'done',
    label: 'Encerradas',
  },
  {
    value: 'next',
    label: 'Próximas',
  },
]

export const ERROR_MESSAGE_DISCIPLINE =
  'Erro ao carregar Disciplinas, tente novamente...'
export const ERROR_MESSAGE_FINANCIAL =
  'Erro ao carregar Detalhamento financeiro, tente novamente...'
