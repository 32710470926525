import {
  Grid as GridHeaderCode,
  TextField as MuiTextField,
  Divider as DividerSchoolClass
} from '@mui/material'
import styled from 'styled-components'
import theme from 'theme/designTokens'

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 16px 0;
`

const Grid = styled(GridHeaderCode)`
  &.MuiGrid-root {
    display: flex;
    position: relative;
    align-items: center;
    margin: ${({ $margin }) => $margin};
    padding: ${({ $padding }) => $padding};

    & button {
      margin-left: 20px;
    }
  }
`

const Divider = styled(DividerSchoolClass)`
  &.MuiDivider-root {
    width: 100%;
    margin: 25px 0 0 0;
  }
`

const TextField = styled(MuiTextField)`
  max-width: 150px;
  margin-left: 20px !important;
`

const Text = styled.p`
  margin: 0px;
  color: ${theme.palette.textColors.black.darkBlack};
`

export { Box, Grid, Text, Divider, TextField }
