/* eslint-disable react/no-danger */
import { useRef } from 'react'
import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import { InputQuill } from 'components/atoms'
import * as Styled from './style'
import { onSubmitEmail } from '../../../services'

const initialValues = {
  subject: '',
  html_text: ''
}

const ModalEmail = ({
  handleClose,
  className,
  open,
  icon,
  title,
  message,
  user,
  setLoadingOpen,
  setOpenModalEmail,
  colorConfirm,
  disabledButton,
  setSnack
}) => {
  const quillRef = useRef()
  return (
    <Styled.Modal open={open} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) =>
          onSubmitEmail({
            setSnack,
            setLoadingOpen,
            setOpenModalEmail,
            values,
            user
          })
        }
        enableReinitialize
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <Styled.IconText>
                    {icon}
                    <Styled.Title variant="h5">{title}</Styled.Title>
                  </Styled.IconText>
                  <Typography variant="subtitle1">
                    <span dangerouslySetInnerHTML={{ __html: message }} />
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Styled.TextField
                    label="Destinatário"
                    name="cardTitle"
                    fullWidth
                    multiline
                    disabled
                    value={user.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Styled.TextField
                    label="Assunto"
                    fullWidth
                    id="subject"
                    value={values?.subject}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputQuill
                    referencie={quillRef}
                    values={values?.content}
                    id="content"
                    label="Mensagem"
                    handleChange={(value) => setFieldValue('content', value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Styled.TypographySec fullWidth>
                    Para campos personalizados, use: #alunoNome (Nome do aluno);
                    #alunoNomeSocial (Nome Social do aluno); #alunoMatrícula
                    (Código de matrícula); #alunoSenha (senha do aluno).
                  </Styled.TypographySec>
                </Grid>
              </Grid>
              <ButtonFooterV2
                size="large"
                labelClose="CANCELAR"
                labelConfirm="Enviar"
                onClickClose={handleClose}
                colorConfirm={colorConfirm}
                disabledConfirm={disabledButton && !values?.group}
                className={className}
              />
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export { ModalEmail }
