import * as yup from 'yup'

const schema = yup.object().shape({
  file: yup.string().required('Campo obrigado.'),
  name: yup.string().required('Campo obrigado.'),
  done_at: yup.string().required('Campo obrigado.'),
  document_type_id: yup.string().nullable().required('Campo obrigado.')
})

export { schema }
