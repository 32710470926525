import { useSearchParams } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { makeURLAccessLegacy } from 'services/helpers'
import * as Styled from './style'
import { downloadListRequisitionInXLSX } from '../../services'
import { BIG_NUMBERS_LABELS } from '../../constants'

const BigNumbers = ({
  listCards,
  colorsCard,
  bigNumbers,
  valuesForm,
  setLoading
}) => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id } = params

  const transformStatus = (status) => {
    const statusMap = {
      [BIG_NUMBERS_LABELS.REQUESTED]: 'Solicitado',
      [BIG_NUMBERS_LABELS.DEFERRED]: 'Deferido',
      [BIG_NUMBERS_LABELS.UNDER_REVIEW]: 'Em análise',
      [BIG_NUMBERS_LABELS.REJECTED]: 'Indeferido'
    }
    return statusMap[status] ?? status
  }

  return (
    <Grid container spacing={{ xs: 0, sm: 2 }}>
      {listCards({
        requested: bigNumbers.requested ?? 0,
        underReview: bigNumbers.under_review ?? 0,
        deferred: bigNumbers.deferred ?? 0,
        rejected: bigNumbers.rejected ?? 0,
        idTurma: id || 0
      })?.map((itens, index) => (
        <Styled.Grid key={itens.label} item xs={12} sm={12} md={3}>
          <Styled.Card $color={colorsCard[index]}>
            <Styled.CardContent margin={0}>
              <Typography
                color={colorsCard[index]}
                sx={{ fontWeight: 700 }}
                variant="h3"
              >
                {itens.number}
              </Typography>

              <Typography variant="caption" color="text.secondary">
                {' '}
                {itens.label}{' '}
              </Typography>
            </Styled.CardContent>
          </Styled.Card>

          {itens.hasLink ? (
            <Styled.LinkCard
              onClick={() =>
                downloadListRequisitionInXLSX({
                  values: {
                    ...valuesForm,
                    status: transformStatus(itens.label)
                  },
                  setLoading
                })
              }
            >
              <Styled.Box $color={colorsCard[index]} key={itens.label}>
                {itens.icon}
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color={colorsCard[index]}
                >
                  {itens.description}
                </Typography>
              </Styled.Box>
            </Styled.LinkCard>
          ) : (
            <Styled.Box
              style={{ cursor: 'pointer' }}
              $color={colorsCard[index]}
              onClick={() =>
                itens.path && makeURLAccessLegacy(itens.path, true)
              }
            >
              {itens?.icon}
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                color={colorsCard[index]}
              >
                {itens.description}
              </Typography>
            </Styled.Box>
          )}
        </Styled.Grid>
      ))}
    </Grid>
  )
}

export { BigNumbers }
