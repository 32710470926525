import { Suspense } from 'react'
import { LoadingPage } from 'components/molecules'
import { StyledContent } from './style'

const TemplateProfile = ({ titleHeader, content }) => (
  <Suspense fallback={<LoadingPage open />}>
    <StyledContent>
      <div>{titleHeader}</div>
      <div>{content}</div>
    </StyledContent>
  </Suspense>
)

export default TemplateProfile
