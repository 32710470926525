import styled from 'styled-components'
import { TextField as MuiTextField } from 'components/molecules'
import {
  RadioGroup as MuiRadioGroup,
  FormControl as MuiFormControl
} from '@mui/material'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  margin-top: 16px;
`

const Box = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-rows: auto auto;
`

const BoxItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  & > h6:last-of-type {
    margin-left: 8px;
  }
`
const TextField = styled(MuiTextField)`
  &.MuiTextField-root {
    width: 190px;
    margin-right: 8px;
  }
`

const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`

const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    -webkit-flex-wrap: nowrap;
  }
`
const FormControl = styled(MuiFormControl)``

export {
  Box,
  BoxItem,
  TextField,
  BoxHeader,
  Container,
  RadioGroup,
  FormControl
}
