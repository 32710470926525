const FINANCIAL = {
  FINANCIAL_BREAKDOWND: {
    LIST: '/bank/billing/financial-details',
    UPDATE: '/bank/billing/payment-information',
    INACTIVE: '/bank/billing/inactivate',
    ACTIVE: '/bank/billing/activate',
    SEND_EMAIL: '/email-send/billing/pending',
    UNIFICATION_OF_BANK_SLIP: '/bank/billing/unify',
    NEGOTIATION: 'finances/negotiation',
    LINK_CONTTRACT: 'bank/link-contract',
    UNIFY_DISCOUNTS: '/bank/billing/unify/discounts'
  },
  CAMPAIGNS: {
    CAMPAIGN: 'campaign'
  },
  INVOICES: {
    CRUD: '/bank/invoices',
    XLSX: '/bank/invoices/xlsx',
    EDIT: '/bank/invoices/edit'
  },
  GROUP: {
    CRUD: '/finances/group'
  },
  CUPON: {
    CRUD: '/finances/discount-coupon'
  },
  PAYMENT_PLAN: {
    CRUD: '/finances/payment-plan',
    COPY: '/finances/payment-plan/copy',
    CHARGE: '/finances/payment-plan/charge'
  },
  SHARED: {
    GROUPS: '/finances/groups'
  },
  BANK_RETURN: {
    PROCESS_FILE: 'admin/bank-return/process-file',
    PROCESSED: 'bank-return/processed'
  },
  BANK_BILLING: {
    CRUD: '/bank/billing',
    SCHOOL_CLASSES: '/school-classes',
    COST_CENTER: '/cost-center',
    DOCUMENTS_REQUERIMENTS: '/documents/requirements-type',
    DETAILS: '/bank/billing/details',
    PAYMENT_METHODS: 'finances/payment-methods',
    REPLACEMENT: '/bank/billing/replacement'
  },
  SUPPLIER: {
    CRUD: '/finances/supplier-costumer'
  },
  MANAGER: {
    REPROCESSING: '/bank/invoices/reprocess'
  },
  GET_NET: {
    CHANGE_CARD: '/finances/auth/get-net/change-card'
  }
}

export { FINANCIAL }
