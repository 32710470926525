const defaultName = 'name'

const MESSAGE = {
  DELETE:
    'Você tem certeza que deseja excluir esta formação? Esta ação é irreversível.',
  MAIN: 'Ao marcar esta opção como principal, a seleção anterior será substituída. Deseja continuar?'
}

const headCells = [
  {
    id: 'level',
    label: 'Nível'
  },
  {
    id: 'course',
    label: 'Curso'
  },
  {
    id: 'institutional',
    label: 'Nome da Instituição'
  },
  {
    id: 'yearComplete',
    label: 'Ano de Conclusão'
  }
]

export { headCells, defaultName, MESSAGE }
