import { ROUTE } from 'services/constants'

const defaultItemsPerPage = 10

const breadcrumbItems = (studentName) => [
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: 'Secretaria' },
  { path: ROUTE.ADMIN_SECRETARY_STUDENTS, label: 'Alunos' },
  {
    path: '',
    label: `${studentName}`
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_CLASS_DISCIPLINE,
    label: '<b>Vincular Turma/Disciplina</b>'
  }
]

const messageModalConfirm =
  'Deseja cancelar a operação? Você perderá as alterações que ainda não foram salvas.'

const titleModalConfirm = 'Cancelar'

const emptyValue = ''

export {
  breadcrumbItems,
  messageModalConfirm,
  titleModalConfirm,
  emptyValue,
  defaultItemsPerPage
}
