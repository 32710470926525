import React from 'react'
import Button from 'components/atoms/Button'
import * as Styled from './style'

const FooterModal = ({
  onClickCancel,
  onClickConfirm,
  imgSrc,
  classNameTrakingBtn
}) => (
  <Styled.WrapperFooter>
    <Button
      size="large"
      value="Cancelar"
      type="submit"
      variant="outlined"
      onClick={onClickCancel}
      sx={{ marginRight: '10px' }}
    />

    <Button
      size="large"
      value="Enviar"
      className={classNameTrakingBtn}
      type="submit"
      onClick={onClickConfirm}
      disabled={imgSrc === null}
    />
  </Styled.WrapperFooter>
)

export default FooterModal
