import styled from 'styled-components'
import { Typography } from '@mui/material'

const InformativeText = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`

export { InformativeText }
