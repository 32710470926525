export const frequencyDiaryDataMock = {
  companyInfo: [
    'ESSA Educação Profissional S.A',
    'Rua Cassuarinas, 108 Vila Parque Jabaquara - São Paulo - SP',
    'Portaria do Dirigente Regional Sul 1 - de 05/06/2007 - Publicada no DOE de 06/06/2007- Página 28'
  ],
  classInfo: {
    classStartDate: '2023-02-02',
    classEndDate: '2024-12-15',
    course: 'Técnico em Desenvolvimento de Sistemas',
    classroom: 'Inah Jacy_NOV23TMDSP72',
    unit: '(SP) NOVOTEC',
    pole: 'Inah Jacy de Castro Aguiar EE',
    minimumGrade: 5,
    observation: 'Código de classe: 273357996 Código Escola: 909130',
    directorUnitSignatureImage:
      'https://pincelatomicoapp.s3.sa-east-1.amazonaws.com/development/uploads/170629790223004846f84d121481bb91f0d9f2d86b1b8.png',
    directorUnitName: 'Nilton Amato Junior',
    directorUnitIdentifier: 'RG 16.663.443-8 SSP/SP',
    directorUnitRole: 'Diretor',
    secretaryUnitSignatureImage:
      'https://pincelatomicoapp.s3.sa-east-1.amazonaws.com/development/uploads/170629790214458711b6407cb4f259e2b829a8e9f8699.png',
    secretaryUnitName: 'Daniele Aline Silva de Oliveira',
    secretaryUnitIdentifier: 'RG 49.129.394-X SSP/SP',
    secretaryUnitRole: 'Secretaria',
    unitCorporateReason: 'ESSA Educação Profissional S.A',
    unitAddress: 'Rua Cassuarinas',
    unitDistrict: 'Vila Parque Jabaquara',
    unitAddressNumber: '108',
    unitCity: 'São Paulo',
    unitUf: 'SP',
    unitResolution:
      'Portaria do Dirigente Regional Sul 1 - de 05/06/2007 - Publicada no DOE de 06/06/2007- Página 28',
    unitCNPJ: '08.690.642/0001-71',
    unitCEP: '04321-100',
    directorPoleSignatureImage: null,
    directorPoleName: null,
    directorPoleIdentifier: null,
    directorPoletRole: null,
    secretaryPoleSignatureImage: null,
    secretaryPoleName: null,
    secretaryPoleIdentifier: null,
    secretaryPoleRole: null,
    poleCorporateReason: 'Inah Jacy de Castro Aguiar EE',
    poleAddress: 'Rua Professor Adhemar Antônio Prado',
    poleDistrict: 'Jardim Tietê',
    poleAddressNumber: '192',
    poleCity: 'São Paulo',
    poleUf: 'SP',
    poleResolution: null,
    poleCNPJ: '',
    poleCEP: '03945-010',
    classTime: 45,
    companyId: 81,
    module: 0,
    shift: 'Semanal - Manhã',
    level: 'Curso Técnico',
    disciplineStartDate: '2023-10-09T03:00:00.000Z',
    disciplineEndDate: '2023-12-16T02:59:59.000Z',
    curricularComponent: 'Banco de Dados I',
    totalExpectedWorkload: 60,
    responsibleTeacher: 'MAICOLN VINICIUS DOS SANTOS SILVA',
    numberOfCompletedLessons: 75,
    totalWorkloadCompleted: 56.25,
    period: '02/02/2023 até 15/12/2024',
    signatures: [
      {
        name: 'Nilton Amato Junior',
        role: 'Diretor',
        identifier: 'RG 16.663.443-8 SSP/SP',
        image:
          'https://pincelatomicoapp.s3.sa-east-1.amazonaws.com/development/uploads/170629790223004846f84d121481bb91f0d9f2d86b1b8.png'
      }
    ]
  },
  students: [
    {
      enrollment: 717850,
      name: 'ADRIANO DA SILVA OLIVEIRA',
      statusUpdatedAt: '2024-02-27',
      status: 'Cancelado',
      id_oc: 790203
    },
    {
      enrollment: 717851,
      name: 'ANA CAROLINNY DA SILVA',
      statusUpdatedAt: '2024-02-27',
      status: 'Cancelado',
      id_oc: 790208
    },
    {
      enrollment: 717852,
      name: 'ANDERSON PETERSON DOS ANJOS MASCARENHAS',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717853,
      name: 'ARTHUR VINICIUS BARBOSA DA SILVA',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717854,
      name: 'DANIEL RODRIGO DE OLIVEIRA ANDRADE',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717855,
      name: 'DAVY EDUARDO TEIXEIRA DA SILVA',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717856,
      name: 'ERICK VINICIUS DE OLIVEIRA',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717857,
      name: 'GABRIEL PEREIRA SANTOS',
      statusUpdatedAt: '2024-02-27',
      status: 'Cancelado',
      id_oc: 790221
    },
    {
      enrollment: 717858,
      name: 'GUILHERME ARAUJO SILVA',
      statusUpdatedAt: '2023-12-13',
      status: 'Cancelado',
      id_oc: 738413
    },
    {
      enrollment: 717859,
      name: 'HELOISA NICOLAU BAPTISTA',
      statusUpdatedAt: '2024-02-27',
      status: 'Cancelado',
      id_oc: 790225
    },
    {
      enrollment: 717860,
      name: 'IZABELA FIDELIX DE AGUIAR',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717861,
      name: 'JOAO VICTOR LOPES MARTINS',
      statusUpdatedAt: '2023-12-13',
      status: 'Cancelado',
      id_oc: 738432
    },
    {
      enrollment: 717862,
      name: 'JONATAS DA SILVA PONTUAL',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717863,
      name: 'JOSÉ GUILHERME DOS SANTOS SILVA',
      statusUpdatedAt: '2024-02-27',
      status: 'Cancelado',
      id_oc: 790236
    },
    {
      enrollment: 717864,
      name: 'JULIA SOARES ARAUJO',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 30171708,
      name: 'JULIA SOARES ARAUJO',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717866,
      name: 'KAUÊ GILSON GRIFANTE ALMEIDA',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717867,
      name: 'KAYKE DE ALCANTARA RODRIGUES',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717868,
      name: 'LAÍS HELENA BARBOZA DA HORA',
      statusUpdatedAt: '2023-12-13',
      status: 'Cancelado',
      id_oc: 738438
    },
    {
      enrollment: 717869,
      name: 'LIVYA LUZIA MELO FIDELIS',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717870,
      name: 'LUIS FELIPE TEIXEIRA SANTOS',
      statusUpdatedAt: '2023-12-13',
      status: 'Cancelado',
      id_oc: 738445
    },
    {
      enrollment: 717871,
      name: 'LUIZ MOISES DE OLIVEIRA DOMINGUEZ BERNARDI',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717872,
      name: 'MARCOS HENRIQUE FELIX DA SILVA',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717873,
      name: 'MARCOS HENRIQUE PEREIRA DOS SANTOS',
      statusUpdatedAt: '2024-02-27',
      status: 'Cancelado',
      id_oc: 790252
    },
    {
      enrollment: 717874,
      name: 'MICAEL FABIANO PATRICIO',
      statusUpdatedAt: '2024-02-27',
      status: 'Cancelado',
      id_oc: 790254
    },
    {
      enrollment: 717875,
      name: 'MILLENA LIMA MERCES MIRANDA',
      statusUpdatedAt: '2024-02-27',
      status: 'Cancelado',
      id_oc: 790259
    },
    {
      enrollment: 717876,
      name: 'NICOLAS KENNEDY GONÇALVES DA SILVA',
      statusUpdatedAt: '2024-02-27',
      status: 'Cancelado',
      id_oc: 790263
    },
    {
      enrollment: 717877,
      name: 'PAULO HENRIQUE FRANCISCO DA SILVA',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717878,
      name: 'RHAIRA VITÓRIA DOS SANTOS BARBOSA',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717879,
      name: 'RIQUELME ALVES NOVAIS',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717880,
      name: 'RONNY TOMAZ BISPO',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717881,
      name: 'SABRINA APARECIDA VIANA DA SILVA',
      statusUpdatedAt: '2024-02-27',
      status: 'Cancelado',
      id_oc: 790268
    },
    {
      enrollment: 717882,
      name: 'STEPHANY RODRIGUES DE OLIVEIRA',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717883,
      name: 'STEVEN STANLEY RIBEIRO DE OLIVEIRA',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717884,
      name: 'THIFANY VITÓRIA GONÇALVES PEREIRA',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717885,
      name: 'WELLYNGTON COELHO DOS SANTOS LUIZ',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    },
    {
      enrollment: 717886,
      name: 'WENDHELL TONELO PASSOS',
      statusUpdatedAt: null,
      status: null,
      id_oc: null
    }
  ],
  lessons: [
    {
      ids: [18127847],
      date: '2023-10-10',
      lessonNumber: '1'
    },
    {
      ids: [18127848],
      date: '2023-10-10',
      lessonNumber: '2'
    },
    {
      ids: [18127849],
      date: '2023-10-10',
      lessonNumber: '3'
    },
    {
      ids: [18127850],
      date: '2023-10-10',
      lessonNumber: '4'
    },
    {
      ids: [18127851],
      date: '2023-10-10',
      lessonNumber: '5'
    },
    {
      ids: [18127852],
      date: '2023-10-10',
      lessonNumber: '6'
    },
    {
      ids: [18127853],
      date: '2023-10-10',
      lessonNumber: '7'
    },
    {
      ids: [18127854],
      date: '2023-10-10',
      lessonNumber: '8'
    },
    {
      ids: [18246991],
      date: '2023-10-11',
      lessonNumber: '9'
    },
    {
      ids: [18246992],
      date: '2023-10-11',
      lessonNumber: '10'
    },
    {
      ids: [18246993],
      date: '2023-10-11',
      lessonNumber: '11'
    },
    {
      ids: [18246994],
      date: '2023-10-11',
      lessonNumber: '12'
    },
    {
      ids: [18246995],
      date: '2023-10-11',
      lessonNumber: '13'
    },
    {
      ids: [18246996],
      date: '2023-10-11',
      lessonNumber: '14'
    },
    {
      ids: [18246997],
      date: '2023-10-11',
      lessonNumber: '15'
    },
    {
      ids: [18662891],
      date: '2023-10-17',
      lessonNumber: '16'
    },
    {
      ids: [18662892],
      date: '2023-10-17',
      lessonNumber: '17'
    },
    {
      ids: [18662893],
      date: '2023-10-17',
      lessonNumber: '18'
    },
    {
      ids: [18662894],
      date: '2023-10-17',
      lessonNumber: '19'
    },
    {
      ids: [18662895],
      date: '2023-10-17',
      lessonNumber: '20'
    },
    {
      ids: [18662896],
      date: '2023-10-17',
      lessonNumber: '21'
    },
    {
      ids: [18662897],
      date: '2023-10-17',
      lessonNumber: '22'
    },
    {
      ids: [18662898],
      date: '2023-10-17',
      lessonNumber: '23'
    },
    {
      ids: [18720423],
      date: '2023-10-18',
      lessonNumber: '24'
    },
    {
      ids: [18720424],
      date: '2023-10-18',
      lessonNumber: '25'
    },
    {
      ids: [18720425],
      date: '2023-10-18',
      lessonNumber: '26'
    },
    {
      ids: [18720426],
      date: '2023-10-18',
      lessonNumber: '27'
    },
    {
      ids: [18720427],
      date: '2023-10-18',
      lessonNumber: '28'
    },
    {
      ids: [18720428],
      date: '2023-10-18',
      lessonNumber: '29'
    },
    {
      ids: [18720429],
      date: '2023-10-18',
      lessonNumber: '30'
    },
    {
      ids: [19041071],
      date: '2023-10-24',
      lessonNumber: '31'
    },
    {
      ids: [19041072],
      date: '2023-10-24',
      lessonNumber: '32'
    },
    {
      ids: [19041073],
      date: '2023-10-24',
      lessonNumber: '33'
    },
    {
      ids: [19041074],
      date: '2023-10-24',
      lessonNumber: '34'
    },
    {
      ids: [19041075],
      date: '2023-10-24',
      lessonNumber: '35'
    },
    {
      ids: [19041076],
      date: '2023-10-24',
      lessonNumber: '36'
    },
    {
      ids: [19041077],
      date: '2023-10-24',
      lessonNumber: '37'
    },
    {
      ids: [19041078],
      date: '2023-10-24',
      lessonNumber: '38'
    },
    {
      ids: [19096257],
      date: '2023-10-25',
      lessonNumber: '39'
    },
    {
      ids: [19096258],
      date: '2023-10-25',
      lessonNumber: '40'
    },
    {
      ids: [19096259],
      date: '2023-10-25',
      lessonNumber: '41'
    },
    {
      ids: [19096260],
      date: '2023-10-25',
      lessonNumber: '42'
    },
    {
      ids: [19096261],
      date: '2023-10-25',
      lessonNumber: '43'
    },
    {
      ids: [19096262],
      date: '2023-10-25',
      lessonNumber: '44'
    },
    {
      ids: [19096263],
      date: '2023-10-25',
      lessonNumber: '45'
    },
    {
      ids: [19403636],
      date: '2023-10-31',
      lessonNumber: '46'
    },
    {
      ids: [19403637],
      date: '2023-10-31',
      lessonNumber: '47'
    },
    {
      ids: [19403638],
      date: '2023-10-31',
      lessonNumber: '48'
    },
    {
      ids: [19403639],
      date: '2023-10-31',
      lessonNumber: '49'
    },
    {
      ids: [19403640],
      date: '2023-10-31',
      lessonNumber: '50'
    },
    {
      ids: [19403641],
      date: '2023-10-31',
      lessonNumber: '51'
    },
    {
      ids: [19403642],
      date: '2023-10-31',
      lessonNumber: '52'
    },
    {
      ids: [19403643],
      date: '2023-10-31',
      lessonNumber: '53'
    },
    {
      ids: [19441411],
      date: '2023-11-01',
      lessonNumber: '54'
    },
    {
      ids: [19441412],
      date: '2023-11-01',
      lessonNumber: '55'
    },
    {
      ids: [19441413],
      date: '2023-11-01',
      lessonNumber: '56'
    },
    {
      ids: [19441414],
      date: '2023-11-01',
      lessonNumber: '57'
    },
    {
      ids: [19441415],
      date: '2023-11-01',
      lessonNumber: '58'
    },
    {
      ids: [19441416],
      date: '2023-11-01',
      lessonNumber: '59'
    },
    {
      ids: [19441417],
      date: '2023-11-01',
      lessonNumber: '60'
    },
    {
      ids: [19646910],
      date: '2023-11-07',
      lessonNumber: '61'
    },
    {
      ids: [19646911],
      date: '2023-11-07',
      lessonNumber: '62'
    },
    {
      ids: [19646912],
      date: '2023-11-07',
      lessonNumber: '63'
    },
    {
      ids: [19646913],
      date: '2023-11-07',
      lessonNumber: '64'
    },
    {
      ids: [19646914],
      date: '2023-11-07',
      lessonNumber: '65'
    },
    {
      ids: [19646915],
      date: '2023-11-07',
      lessonNumber: '66'
    },
    {
      ids: [19646916],
      date: '2023-11-07',
      lessonNumber: '67'
    },
    {
      ids: [19646917],
      date: '2023-11-07',
      lessonNumber: '68'
    },
    {
      ids: [20074396],
      date: '2023-11-08',
      lessonNumber: '69'
    },
    {
      ids: [20074397],
      date: '2023-11-08',
      lessonNumber: '70'
    },
    {
      ids: [20074398],
      date: '2023-11-08',
      lessonNumber: '71'
    },
    {
      ids: [20074399],
      date: '2023-11-08',
      lessonNumber: '72'
    },
    {
      ids: [20074400],
      date: '2023-11-08',
      lessonNumber: '73'
    },
    {
      ids: [20074401],
      date: '2023-11-08',
      lessonNumber: '74'
    },
    {
      ids: [20074402],
      date: '2023-11-08',
      lessonNumber: '75'
    }
  ],
  frequencies: [
    {
      enrollment: 717851,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717854,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717858,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717874,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717874,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717875,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 30171708,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717880,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717867,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717867,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717867,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717867,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717867,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717867,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 30171708,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 30171708,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 30171708,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 30171708,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 30171708,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 30171708,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'F'
    },
    {
      enrollment: 717880,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-10',
      lessonNumber: '1',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-10',
      lessonNumber: '2',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-10',
      lessonNumber: '3',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-10',
      lessonNumber: '4',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-10',
      lessonNumber: '5',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-10',
      lessonNumber: '6',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-10',
      lessonNumber: '7',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-10',
      lessonNumber: '8',
      status: 'P'
    },
    {
      enrollment: 717851,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-11',
      lessonNumber: '9',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-11',
      lessonNumber: '10',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-11',
      lessonNumber: '11',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-11',
      lessonNumber: '12',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-11',
      lessonNumber: '13',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-11',
      lessonNumber: '14',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-11',
      lessonNumber: '15',
      status: 'P'
    },
    {
      enrollment: 717851,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717858,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717870,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'F'
    },
    {
      enrollment: 717884,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717851,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717858,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717874,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717874,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717875,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717858,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717863,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717873,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-17',
      lessonNumber: '16',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-17',
      lessonNumber: '17',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-17',
      lessonNumber: '18',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-17',
      lessonNumber: '19',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-17',
      lessonNumber: '20',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-17',
      lessonNumber: '21',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-17',
      lessonNumber: '22',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-17',
      lessonNumber: '23',
      status: 'P'
    },
    {
      enrollment: 717851,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717853,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717858,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717886,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717886,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717886,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717886,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717886,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717886,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717874,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717875,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-18',
      lessonNumber: '24',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717874,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717875,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-18',
      lessonNumber: '25',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717875,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-18',
      lessonNumber: '26',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717875,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-18',
      lessonNumber: '27',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717875,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-18',
      lessonNumber: '28',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717875,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-18',
      lessonNumber: '29',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-18',
      lessonNumber: '30',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717851,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717858,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717873,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717873,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717874,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717858,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717884,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717858,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717862,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717874,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717884,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'F'
    },
    {
      enrollment: 717884,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'F'
    },
    {
      enrollment: 717884,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'F'
    },
    {
      enrollment: 717884,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'F'
    },
    {
      enrollment: 717884,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'F'
    },
    {
      enrollment: 717884,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717853,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717854,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717858,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717870,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717880,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717883,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717863,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-24',
      lessonNumber: '31',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717863,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-24',
      lessonNumber: '32',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717863,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-24',
      lessonNumber: '33',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717863,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-24',
      lessonNumber: '34',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717863,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'F'
    },
    {
      enrollment: 717864,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-24',
      lessonNumber: '35',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-24',
      lessonNumber: '36',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-24',
      lessonNumber: '37',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-24',
      lessonNumber: '38',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-25',
      lessonNumber: '39',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-25',
      lessonNumber: '40',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-25',
      lessonNumber: '41',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-25',
      lessonNumber: '42',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-25',
      lessonNumber: '43',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-25',
      lessonNumber: '44',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-25',
      lessonNumber: '45',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717852,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717857,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717858,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717853,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717853,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717872,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717880,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717880,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717880,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'F'
    },
    {
      enrollment: 717883,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'F'
    },
    {
      enrollment: 717883,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'F'
    },
    {
      enrollment: 717853,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-31',
      lessonNumber: '46',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-31',
      lessonNumber: '47',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-31',
      lessonNumber: '48',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-31',
      lessonNumber: '49',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-31',
      lessonNumber: '50',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-31',
      lessonNumber: '51',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-31',
      lessonNumber: '52',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-10-31',
      lessonNumber: '53',
      status: 'P'
    },
    {
      enrollment: 717851,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717855,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717855,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717855,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717855,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717855,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717855,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717855,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717856,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717879,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-01',
      lessonNumber: '54',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-01',
      lessonNumber: '55',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-01',
      lessonNumber: '56',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-01',
      lessonNumber: '57',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-01',
      lessonNumber: '58',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-01',
      lessonNumber: '59',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-01',
      lessonNumber: '60',
      status: 'P'
    },
    {
      enrollment: 717851,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717869,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717873,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717878,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717860,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-07',
      lessonNumber: '61',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-07',
      lessonNumber: '62',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-07',
      lessonNumber: '63',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-07',
      lessonNumber: '64',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-07',
      lessonNumber: '65',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-07',
      lessonNumber: '66',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-07',
      lessonNumber: '67',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717866,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717876,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717881,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717883,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-07',
      lessonNumber: '68',
      status: 'P'
    },
    {
      enrollment: 717851,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717851,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717857,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717858,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717859,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717861,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717863,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717866,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717868,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717870,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717873,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717874,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717875,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717876,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717881,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717883,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'F'
    },
    {
      enrollment: 717883,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'F'
    },
    {
      enrollment: 717883,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'F'
    },
    {
      enrollment: 717883,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'F'
    },
    {
      enrollment: 717883,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'F'
    },
    {
      enrollment: 717883,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'F'
    },
    {
      enrollment: 717883,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'F'
    },
    {
      enrollment: 717850,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-08',
      lessonNumber: '69',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-08',
      lessonNumber: '70',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-08',
      lessonNumber: '71',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-08',
      lessonNumber: '72',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-08',
      lessonNumber: '73',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-08',
      lessonNumber: '74',
      status: 'P'
    },
    {
      enrollment: 717850,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717852,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717853,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717854,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717855,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717856,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717860,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717862,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717864,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 30171708,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717867,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717869,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717871,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717872,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717877,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717878,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717879,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717880,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717882,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717884,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717885,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    },
    {
      enrollment: 717886,
      date: '2023-11-08',
      lessonNumber: '75',
      status: 'P'
    }
  ]
}
