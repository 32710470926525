const defaultItemsPerPage = 10
const countDefault = 0
const nameAccordion = 'OccurrencieTypes'
const defaultPage = 1
const lengthItem = 1

const MESSAGE = {
  NO_SEARCH:
    '<b>Encontre os tipos de ocorrência disponíveis aqui</b><br/>Você pode filtrar com outros critérios a sua busca.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  SUCCESS_INACTIVE:
    'Os tipo(s) de ocorrência(s) selecionado(s) foram desativado(s) com sucesso.',
  SUCCESS_ACTIVE:
    'Os tipo(s) de ocorrência(s) selecionado(s) foram ativado(s) com sucesso.',
  SUCCESS_CREATE_COPY:
    'A cópia dos tipo(s) de ocorrência(s) selecionado(s) foi criada(s) com sucesso.'
}

const initialState = {
  category: '',
  occurrenceTypes: [],
  inactive: false
}

const DEFAULT_ITENS_PER_PAGE = 6

const listItemsPerPage = [
  {
    value: '6',
    label: '6'
  },
  {
    value: '12',
    label: '12'
  },
  {
    value: '24',
    label: '24'
  },
  {
    value: '36',
    label: '36'
  },
  {
    value: '54',
    label: '54'
  }
]

const sampleList = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 }
]
const rows = [
  {
    id: '15',
    type: 'Bloqueio de Acesso',
    register: 'Acesso Restrito'
  },
  {
    id: '35',
    type: 'Bloqueado Rematricula',
    register: 'Acesso Restrito'
  }
]

const typeAction = {
  remove: 'remove',
  copy: 'copy',
  changeOfValues: 'changeOfValues',
  planAlreadyExists: 'planAlreadyExists',
  change: 'change'
}

export {
  countDefault,
  defaultItemsPerPage,
  sampleList,
  nameAccordion,
  defaultPage,
  lengthItem,
  initialState,
  MESSAGE,
  rows,
  typeAction,
  DEFAULT_ITENS_PER_PAGE,
  listItemsPerPage
}
