import PropTypes from 'prop-types'
import { FormControlLabel, Switch } from '@mui/material'

const InputSwitch = ({
  className,
  label,
  checked,
  onClick,
  disabled,
  onChange
}) => (
  <FormControlLabel
    control={
      <Switch
        className={className}
        checked={checked}
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
      />
    }
    label={label}
  />
)

export default InputSwitch

InputSwitch.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}

InputSwitch.defaultProps = {
  onClick: () => {},
  label: 'Switch',
  checked: false
}
