import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { convertToParams } from 'services/helpers'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'

import { linkClassInDiscilple, getListDisciplinesByFilter } from './service'
import { Content } from './components'
import {
  breadcrumbItems,
  titleModalConfirm,
  messageModalConfirm,
  defaultItemsPerPage
} from './constants'

const ListClassDisciplineToLink = () => {
  const [openModal, setOpenModal] = useState(false)
  const [list, setList] = useState([])
  const [searchParams] = useSearchParams()
  const [filter, setFilter] = useState(defaultItemsPerPage)

  const params = Object.fromEntries([...searchParams])
  const navigate = useNavigate()
  const snackProps = useSnack()
  const { items, totalPage, handleSetPage, setPage } = usePaginationBasic(
    list,
    filter
  )

  const { setSnack } = snackProps
  const {
    register,
    subjectId,
    schoolClassId,
    schoolClassLevel,
    schoolClassCourse,
    subjectWorkloadHours,
    subjectName,
    studentName
  } = params

  const hasResult = !!list?.length

  const handleSearch = ({ searchBy }) => {
    getListDisciplinesByFilter({
      setSnack,
      register,
      subjectId,
      schoolClassId,
      searchBy,
      setList
    })
  }

  const handleLinkDiscipline = (subjectIdNew) => {
    linkClassInDiscilple({
      setSnack,
      register,
      classDisciplineOriginId: subjectId,
      classDisciplineNewId: subjectIdNew,
      callbackSuccess: () =>
        navigate(
          `${ROUTE.ADMIN_SECRETARY_STUDENTS_CLASS_DISCIPLINE}?${convertToParams(
            {
              ...params,
              lastSchoolClassId: schoolClassId
            }
          )}`
        )
    })
  }

  useEffect(() => {
    handleSearch({ searchBy: subjectName })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      breadcrumbsItems={[...breadcrumbItems(studentName)]}
      classNameHeader="goBack"
      content={
        <Content
          rows={items}
          hasResult={hasResult}
          handleSearch={handleSearch}
          handleLinkDiscipline={handleLinkDiscipline}
          schoolClassLevel={schoolClassLevel}
          schoolClassCourse={schoolClassCourse}
          subjectWorkloadHours={subjectWorkloadHours}
          subjectName={subjectName}
          filter={filter}
          setFilter={setFilter}
          totalPage={totalPage}
          handleSetPage={handleSetPage}
          setPage={setPage}
          count={list?.length}
        />
      }
      labelCallToAction="voltar"
      onClickButton={() => setOpenModal(true)}
      snackProps={snackProps}
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => navigate(-1)}
      titleModalConfirm={titleModalConfirm}
      messageModalConfirm={messageModalConfirm}
    />
  )
}

export default ListClassDisciplineToLink
