import { InputAdornment, TextField } from '@mui/material'
import { Select } from 'components/molecules'
import { DatePicker } from 'components/atoms'

import * as Styled from './style'

const FormCampaign = ({ values, errors, unitys, touched, setFieldValue }) => (
  <>
    <Styled.Grid item xs={12} sm={12} md={6}>
      <Select
        id="unit"
        label="Unidade*"
        optionsList={unitys}
        value={values?.unit || unitys[0].value}
        helperText={touched.unit && errors.unit}
        error={touched.unit && Boolean(errors.unit)}
        onChange={(e) => setFieldValue('unit', e.target.value)}
      />
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={6}>
      <TextField
        fullWidth
        id="name"
        name="name"
        isMargin="0"
        label="Nome*"
        value={values?.name}
        helperText={touched.name && errors.name}
        error={touched.name && Boolean(errors.name)}
        onChange={(e) => setFieldValue('name', e.target.value)}
      />
    </Styled.Grid>

    <Styled.Grid item xs={12} sm={12} md={3}>
      <DatePicker
        fullWidth
        id="begin"
        label="Início da vigência*"
        value={values?.begin || null}
        helperText={touched.begin && errors.begin}
        error={touched.begin && Boolean(errors.begin)}
        onChange={(newValue) => {
          setFieldValue('begin', newValue)
        }}
      />
    </Styled.Grid>

    <Styled.Grid item xs={12} sm={12} md={3}>
      <DatePicker
        fullWidth
        id="end"
        label="Fim da vigência"
        value={values?.end || null}
        onChange={(newValue) => {
          setFieldValue('end', newValue)
        }}
      />
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={3}>
      <TextField
        fullWidth
        isMargin="0"
        id="installments"
        name="installments"
        value={values?.installments}
        label="Parcelas Incrementais*"
        helperText={touched.installments && errors.installments}
        error={touched.installments && Boolean(errors.installments)}
        onChange={(e) => setFieldValue('installments', e.target.value)}
      />
    </Styled.Grid>

    <Styled.Grid item xs={12} sm={12} md={3}>
      <TextField
        fullWidth
        isMargin="0"
        type="text"
        id="discount"
        name="discount"
        label="Desconto de Antecipação*"
        InputProps={{
          value: values.discount || '',
          endAdornment: <InputAdornment position="end">%</InputAdornment>
        }}
        helperText={touched.discount && errors.discount}
        error={touched.discount && Boolean(errors.discount)}
        onChange={(e) => setFieldValue('discount', e.target.value)}
      />
    </Styled.Grid>
  </>
)

export { FormCampaign }
