import { Outlet } from 'react-router-dom'
import Container from '@mui/material/Container'

const TemplatePublic = () => (
  <Container style={{ maxWidth: 1300 }}>
    <Outlet />
  </Container>
)

export default TemplatePublic
