import FEEDBACK_SNACK from 'feedBackSnack'
import {
  changePaymentPlanAmounts,
  copyPaymentPlan,
  getPaymentPlan,
  removePaymentPlan
} from 'services/api/admin'
import {
  getMoneyFormatToNumber,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import { MESSAGE, countDefault, defaultPage, nameAccordion } from './constants'

const getParamsByListPaymentPlan = ({ values, filter, currentPage }) => ({
  unit_id: values?.unity,
  level: values?.level || undefined,
  course: values?.course || undefined,
  shift: values?.shift || undefined,
  limit: filter,
  page: currentPage,
  inactive: values?.inactiveCourses
})

const getParamsChangeValues = (values, list, itemsSelected) => {
  const newListOfSelectedIds = list?.filter((item) =>
    itemsSelected?.includes(item?.id)
  )

  const { registration, monthlyPayment, installments, inCash, durationMonths } =
    values

  const params = {
    ...(registration && {
      registration_tax: getMoneyFormatToNumber(registration)
    }),
    ...(monthlyPayment && {
      installment_amount: getMoneyFormatToNumber(monthlyPayment)
    }),
    ...(installments && {
      max_installment: getMoneyFormatToNumber(installments)
    }),
    ...(inCash && {
      cash_discount: getMoneyFormatToNumber(inCash)
    }),
    ...(durationMonths && {
      duration_months: getMoneyFormatToNumber(durationMonths)
    })
  }

  const newParams = newListOfSelectedIds.map((item) => ({
    ...{
      id: item.id,
      readjustment_initial_installment: item.readjustment_initial_installment,
      readjustment_installments_value: item.readjustment_installments_value
    },
    ...params
  }))

  return newParams
}

const onSubmit = async ({
  values,
  setApiResult,
  setLoadingOpen,
  currentPage,
  filter,
  setvaluesForm,
  setFormValues,
  setExpanded,
  setNotSearch
}) => {
  setApiResult({
    list: []
  })
  setLoadingOpen(true)

  const params = {
    values,
    currentPage,
    filter
  }

  const { data, error, status } = await getPaymentPlan(
    getParamsByListPaymentPlan(params),
    values
  )

  setvaluesForm(values)
  setFormValues(values)
  setExpanded('')
  setNotSearch(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListPaymentPlan
    })
  }

  setLoadingOpen(false)

  if (data?.count === countDefault) {
    setExpanded(nameAccordion)
    return setNotSearch(false)
  }

  return setApiResult({
    list: data?.data,
    count: data?.count
  })
}

const onCopyPaymentPlan = async ({
  values,
  setLoadingOpen,
  selectedCopy,
  selected,
  setSelectedCopy,
  setOpen,
  currentPage,
  formValues,
  setPage,
  onResetAction,
  setApiResult,
  filter,
  setvaluesForm,
  setFormValues,
  setExpanded,
  setNotSearch
}) => {
  setLoadingOpen(true)

  const params = {
    id_payment_plan: selectedCopy || selected?.data,
    new_shift: values?.item
  }

  const { error, status } = await copyPaymentPlan(params)

  setSelectedCopy(selected?.data)
  setLoadingOpen(false)

  if (error) {
    setOpen(true)
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissiontoToCopyOfThePaymentPlan
    })
  }

  if (currentPage === defaultPage) {
    onSubmit({
      values: formValues,
      setApiResult,
      setLoadingOpen,
      currentPage,
      filter,
      setvaluesForm,
      setFormValues,
      setExpanded,
      setNotSearch
    })
  }

  setPage(defaultPage)
  setOpen(false)
  setSelectedCopy('')
  onResetAction()

  return toastNotificationSuccess(MESSAGE.newPaymentPlanCreated, 1500)
}

const onRemovePaymentPlan = async ({
  selected,
  selectedItem,
  setLoadingOpen,
  currentPage,
  formValues,
  setPage,
  onResetAction,
  setSelected,
  setApiResult,
  filter,
  setvaluesForm,
  setFormValues,
  setExpanded,
  setNotSearch
}) => {
  const ids = selected?.data || selectedItem
  setLoadingOpen(true)

  if (ids) {
    const { error, status } = await removePaymentPlan(ids)
    setLoadingOpen(false)

    if (error) {
      updateErrorMessage({
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissiontoToRemovePaymentPlan
      })
    }

    if (currentPage === defaultPage) {
      onSubmit({
        values: formValues,
        setApiResult,
        setLoadingOpen,
        currentPage,
        filter,
        setvaluesForm,
        setFormValues,
        setExpanded,
        setNotSearch
      })
    }

    setPage(defaultPage)
    onResetAction()
    setSelected([])

    return toastNotificationSuccess(MESSAGE.deletionDone, 1500)
  }

  return null
}

const onChangeConfirm = async ({
  values,
  setLoadingOpen,
  rowsItems,
  itemsSelected,
  setOpenModal,
  handleClose,
  formValues,
  setApiResult,
  currentPage,
  filter,
  setvaluesForm,
  setFormValues,
  setExpanded,
  setNotSearch
}) => {
  setLoadingOpen(true)

  const { error, status } = await changePaymentPlanAmounts(
    getParamsChangeValues(values, rowsItems, itemsSelected)
  )
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({ error, status })
  }

  setOpenModal(false)
  handleClose()
  onSubmit({
    values: formValues,
    setApiResult,
    setLoadingOpen,
    currentPage,
    filter,
    setvaluesForm,
    setFormValues,
    setExpanded,
    setNotSearch
  })

  return toastNotificationSuccess(MESSAGE.updatedValues, 1500)
}

export {
  getParamsByListPaymentPlan,
  getParamsChangeValues,
  onCopyPaymentPlan,
  onRemovePaymentPlan,
  onChangeConfirm,
  onSubmit
}
