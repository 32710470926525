const TYPE_FILE_PDF = 'application/pdf'
const TYPE_FILE_PNG = 'application/png'

const REMOVE_CHARACTERS = 'data:application/pdf;base64,'

const MESSAGE = {
  SUCCESS: 'Clique aqui para selecionar o arquivo PDF, JPG, PNG, JPEG.',
  ERROR: 'Somente arquivos em PDF, JPG, PNG, JPEG com até 5 MB '
}

export { MESSAGE, TYPE_FILE_PDF, TYPE_FILE_PNG, REMOVE_CHARACTERS }
