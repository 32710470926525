import { ROUTE } from 'services/constants'

const defaultPage = 1
const defaultTotalPage = 10
const defaultItemsPerPage = 10
const replacementType = 'permanent'

const initialValues = {
  endDate: '',
  startDate: '',
  reasonReplacement: '',
  replacement: 'permanent'
}

const MESSAGE = {
  success: 'Turma substituída com sucesso.',
  inactivateSuccess: 'Substituição inativada com sucesso.'
}

const breadCrumbsItems = ({ name, id }) => [
  {
    path: '',
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: `${name}`
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS,
    label: `<b>${'Editar substituição'} </b>`
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS_DETAILS_EDIT,
    label: `<b>Turma ${id}</b>`
  }
]

export {
  MESSAGE,
  defaultPage,
  initialValues,
  replacementType,
  breadCrumbsItems,
  defaultTotalPage,
  defaultItemsPerPage
}
