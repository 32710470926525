import { formatDateMonthYear } from 'services/helpers'
import { defaultItemsPerPage, defaultPage } from './constants'

const paramsOnSubmit = ({ values, filter, pageActual }) =>
  new URLSearchParams({
    ...(values.unity && { unit: values.unity }),
    ...(values.situation && {
      status: values.situation === 'all' ? '' : values.situation
    }),
    ...(values.competence && {
      competence: formatDateMonthYear(values.competence)
    }),
    limit: filter || defaultItemsPerPage,
    page: pageActual || defaultPage
  })

export { paramsOnSubmit }
