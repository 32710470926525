import { ArrowBack, ArrowForward } from '@mui/icons-material'
import * as Styled from './style'

const PaginationBasic = ({
  handlePrevPage,
  disablePrev,
  handleNextPage,
  from,
  to,
  disabledNext
}) => (
  <Styled.Paginate>
    <div>
      <Styled.BottonArrow onClick={handlePrevPage} disabled={disablePrev}>
        <ArrowBack />
      </Styled.BottonArrow>

      <span>
        {from} - {to}
      </span>

      <Styled.BottonArrow onClick={handleNextPage} disabled={disabledNext}>
        <ArrowForward />
      </Styled.BottonArrow>
    </div>
  </Styled.Paginate>
)

export default PaginationBasic
