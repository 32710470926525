import { httpGet, httpPost, httpPut, httpDelete } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getGroups = async (params, values) => {
  const { unit, name } = values
  const unitId = unit ? `&unit_id=${unit}` : ''
  const nameGroup = name ? `&name=${name}` : ''

  const response = await httpGet(
    `${ENDPOINT.GROUP.CRUD}?${convertToParams(params)}${unitId}${nameGroup}`
  )
  return response
}

export const getGroup = async (params) => {
  const response = await httpGet(`${ENDPOINT.GROUP.CRUD}/${params}`, {})
  return response
}

export const updateGroups = async (payload, params) => {
  const response = await httpPut(`${ENDPOINT.GROUP.CRUD}/${params}`, {
    ...payload
  })
  return response
}

export const createGroup = async (payload) => {
  const response = await httpPost(ENDPOINT.GROUP.CRUD, {
    ...payload
  })

  return response
}

export const inactiveGroup = async (payload) => {
  const response = await httpPut(ENDPOINT.GROUP.CRUD, {
    ...payload
  })

  return response
}

export const removeGroup = async (id) => {
  const response = await httpDelete(`${ENDPOINT.GROUP.CRUD}`, {
    ...id
  })

  return response
}
