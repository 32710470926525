import { useEffect } from 'react'
import { useUserStore } from 'store/GlobalContext'
import * as Styled from './style'

const InternshipRedirection = () => {
  const { hasIntern, internUrl } = useUserStore()

  useEffect(() => {
    window.location.replace(internUrl)
  }, [hasIntern])

  return (
    <Styled.Link href={internUrl} target="_blank">
      {' '}
    </Styled.Link>
  )
}

export default InternshipRedirection
