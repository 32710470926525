import { create } from 'zustand'

const useStoreDocuments = create((set) => ({
  formValues: null,
  open: false,
  setFormValues: (items) => set({ formValues: items }),
  resetFormValues: () => set({ formValues: null }),
  toggleOpen: () => set((state) => ({ open: !state.open }))
}))

export { useStoreDocuments }
