import { useNavigate } from 'react-router-dom'
import { Checkbox, TableCell, TableRow, Tooltip } from '@mui/material'
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import { formatStringTagToString } from 'services/helpers'
import * as Styled from './style'

const TableFooter = ({ row, labelId, handleClick, isItemSelected }) => {
  const navigate = useNavigate()

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?.register}
      selected={isItemSelected}
    >
      <TableCell
        padding="checkbox"
        onClick={() => {
          handleClick(row?.register)
        }}
      >
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            'aria-labelledby': labelId
          }}
        />
      </TableCell>

      <Styled.StyledTableCell>
        {formatStringTagToString(row?.register)}
      </Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.cpf}</Styled.StyledTableCell>
      <Styled.StyledTableCellTitle>
        <div className="content">
          <Tooltip title="Ficha do aluno" placement="top">
            <Styled.BoxLink
              onClick={() =>
                navigate(
                  `${ROUTE.ADMIN_SECRETARY_STUDENTS}/register?name=${row.name}&register=${row.register}&tab=registrationData&tabRegistrationData=studentData`,
                  {
                    state: { savedSearch: true, row }
                  }
                )
              }
              className={TAG_MANAGER.sistema_btn_edita_contrato}
            >
              {row?.name}
            </Styled.BoxLink>
          </Tooltip>
        </div>
      </Styled.StyledTableCellTitle>
    </TableRow>
  )
}

export default TableFooter
