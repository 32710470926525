import { Description, Image, PictureAsPdf } from '@mui/icons-material'
import { Box } from '@mui/material'
import * as styled from './style'

const TYPE_FILE_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const TYPE_FILE_XLS = 'application/vnd.ms-excel'
const TYPE_PDF = 'pdf'
const TYPE_IMAGE = 'img'
const TYPE_TXT = 'txt'
const TYPE_EXCEL = 'excel'

const fileType = {
  [TYPE_EXCEL]: {
    icon: <Description color="gray" />,
    accept: `${TYPE_FILE_XLS},${TYPE_FILE_XLSX}`,
    previewData: (previewSrc, nameFile) => (
      <styled.openFile>
        <Description color="gray" />
        <br />
        <Box
          dangerouslySetInnerHTML={{
            __html: `Arquivo ${nameFile} selecionado<br/> cancele para adicionar um novo arquivo`
          }}
        />
      </styled.openFile>
    )
  },
  [TYPE_PDF]: {
    icon: <PictureAsPdf color="gray" />,
    accept: 'application/pdf',
    previewData: (previewSrc) => (
      <embed
        type="application/pdf"
        src={previewSrc}
        width="100%"
        height="210px"
        title="PREVIEW PDF"
      />
    )
  },
  [TYPE_TXT]: {
    icon: <Description color="gray" />,
    accept: '.txt'
  },
  [TYPE_IMAGE]: {
    icon: <Image color="gray" />,
    accept: 'image/*',
    previewData: (previewSrc) => (
      <img alt="imagem" src={previewSrc} width="100%" height="210px" />
    )
  }
}

export default fileType
