import { lazy, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableContainer,
  Typography
} from '@mui/material'
import { TableHeaderDefault } from 'components/organisms'
import { ButtonFooterV2, PaginationAdvanced } from 'components/molecules'
import { useCheckedTable, useSortTable } from 'services/hooks'
import { getLenght, isEmpty, paginationFilter } from 'services/helpers'
import { ROUTE } from 'services/constants'
import { headCells, idNameList, initialValues } from './constants'
import Toolbar from './Toolbar'
import { CardsClass } from '../Cards'
import { defaultItemsPerPage, defaultPage } from '../../constants'
import { updateSchoolClassesDisciplines } from '../../services'
import Modals from '../Modals'
import ModalAddTeacher from '../ModalAddTeacher'
import * as Styled from './style'
import { ModalIntegratedAssessment } from '../ModalIntegratedAssessment'
import { validationSchema } from './schema'

const TableTdBody = lazy(() => import('./TableBody'))

const TableVariables = ({
  listCards,
  iconsCard,
  colorsCard,
  listDisciplines,
  listSelectListDisciplines,
  bigNumbers,
  totalPage,
  page,
  listSchedules,
  unit,
  setPage,
  setFilter,
  count,
  setOpenModalConfirm,
  formValues,
  setListDisciplines,
  staticFormValues,
  filter,
  setCount,
  setSnack,
  setLoadingOpen,
  id
}) => {
  const [openModalDocuments, setOpenModalDocuments] = useState(false)
  const [openModalExportFiles, setOpenModalExportFiles] = useState(false)
  const [openModalAtaNovoTec, setOpenModalAtaNovoTec] = useState(false)

  const [openModalIA, setOpenModalIA] = useState(false)
  const [formAddProfessors, setFormAddProfessors] = useState(initialValues)
  const [idDisc, setIdDisc] = useState(null)
  const [openModalExportFilesInline, setOpenModalExportFilesInline] =
    useState(false)
  const [typeModal, setTypeModal] = useState('')
  const [isBatchAction, setIsBatchAction] = useState(false)
  const [openModalAddTeacher, setOpenModalAddTeacher] = useState(false)
  const [openModalClassSchedule, setOpenModalClassSchedule] = useState(false)
  const [errorTypeFile, setErrorTypeFile] = useState(false)
  const [discipline, setDiscipline] = useState()
  const [formAddIA, setFormIA] = useState({
    file: ''
  })

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected: selectedItem,
    setSelected
  } = useCheckedTable(listDisciplines, idNameList)

  const handleSetPage = (_, currentPage) => {
    setPage(+currentPage)
  }

  const { handleRequestSort, order, orderBy, sortTable } =
    useSortTable(listDisciplines)

  return (
    <>
      <Formik
        initialValues={{ details: sortTable }}
        onSubmit={(values) =>
          updateSchoolClassesDisciplines({
            classId: id,
            setSnack,
            setLoadingOpen,
            values: values?.details,
            setListDisciplines,
            setCount,
            page,
            filter
          })
        }
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, handleSubmit, setFieldValue, touched, errors }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid container spacing={{ xs: 0, sm: 2 }}>
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="body2"
                    display="flex"
                    justifyContent="center"
                    margin={5}
                  >
                    INFORMAÇÕES SOBRE ALUNOS E DISCIPLINAS
                  </Typography>

                  <CardsClass
                    listCards={listCards}
                    bigNumbers={bigNumbers}
                    iconsCard={iconsCard}
                    colorsCard={colorsCard}
                  />

                  <Toolbar
                    setTypeModal={setTypeModal}
                    staticFormValues={staticFormValues}
                    formValues={formValues}
                    setFormAddProfessors={setFormAddProfessors}
                    numSelected={!!getLenght(selectedItem)}
                    listDisciplines={listDisciplines}
                    setOpenModalExportFiles={setOpenModalExportFiles}
                    setOpenModalAtaNovoTec={setOpenModalAtaNovoTec}
                    setOpenModalClassSchedule={setOpenModalClassSchedule}
                    setOpenModalDocuments={setOpenModalDocuments}
                    setOpenModalAddTeacher={setOpenModalAddTeacher}
                    setIsBatchAction={setIsBatchAction}
                    setOpenModalExportFilesInline={
                      setOpenModalExportFilesInline
                    }
                    setOpenModalIA={setOpenModalIA}
                  />

                  {!isEmpty(listDisciplines) && (
                    <Styled.TableContainer>
                      <TableContainer>
                        <Table size="small">
                          <TableHeaderDefault
                            checkbox
                            numSelected={selectedItem.length}
                            order={order}
                            orderBy={orderBy}
                            handleSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={listDisciplines?.length}
                            headCells={headCells}
                          />

                          <TableBody>
                            {values?.details?.map((row, index) => {
                              const isItemSelected = isSelected(row.id)
                              const labelId = `listDisciplines-table-${index}`

                              return (
                                <TableTdBody
                                  key={row.id}
                                  isItemSelected={isItemSelected}
                                  setIdDisc={setIdDisc}
                                  setFormAddProfessors={setFormAddProfessors}
                                  labelId={labelId}
                                  unit={unit}
                                  setOpenModalExportFilesInline={
                                    setOpenModalExportFilesInline
                                  }
                                  values={values}
                                  setOpenModalDocuments={setOpenModalDocuments}
                                  setSnack={setSnack}
                                  item={row}
                                  index={index}
                                  touched={touched}
                                  errors={errors}
                                  setFieldValue={setFieldValue}
                                  handleClick={handleClick}
                                  selected={selectedItem}
                                  setOpenModalAddTeacher={
                                    setOpenModalAddTeacher
                                  }
                                  setDiscipline={setDiscipline}
                                />
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {count > defaultItemsPerPage && (
                        <PaginationAdvanced
                          value={filter}
                          count={totalPage}
                          isSelectItensPerPage
                          optionsList={paginationFilter}
                          page={page}
                          handleSetPage={handleSetPage}
                          handleChange={(e) => {
                            setFilter(e.target.value)
                            setPage(defaultPage)
                          }}
                        />
                      )}
                    </Styled.TableContainer>
                  )}
                </Grid>

                {!isEmpty(listDisciplines) && (
                  <>
                    <Grid item xs={12} sm={12} md={6}>
                      <Styled.BoxGroupingCaptionWarning>
                        <Box className="caption">
                          <Typography variant="body1">A</Typography>
                        </Box>
                        <Typography variant="caption">
                          Em agrupamento
                        </Typography>
                      </Styled.BoxGroupingCaptionWarning>
                      <Styled.BoxGroupingCaptionSub>
                        <Box className="caption">
                          <Typography variant="body1">S</Typography>
                        </Box>
                        <Typography variant="caption">Substituição</Typography>
                      </Styled.BoxGroupingCaptionSub>
                      <Styled.BoxGroupingCaptionInfo>
                        <Box className="caption">
                          <Typography variant="body1">i</Typography>
                        </Box>
                        <Typography variant="caption">
                          Para adicionar professores à disciplinas agrupadas,
                          utilize o fluxo de{' '}
                          <Link to={ROUTE.ADMIN_SECRETARY_GROUPING}>
                            Agrupamento
                          </Link>
                        </Typography>
                      </Styled.BoxGroupingCaptionInfo>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Styled.Box>
                        <ButtonFooterV2
                          labelClose="Cancelar"
                          classNameConfirm="academico_btn_salva_turma"
                          labelConfirm="Salvar"
                          size="medium"
                          onClickClose={() => setOpenModalConfirm(true)}
                        />
                      </Styled.Box>
                    </Grid>
                  </>
                )}
              </>
            </Grid>
          </Form>
        )}
      </Formik>

      <ModalIntegratedAssessment
        setSnack={setSnack}
        setLoadingOpen={setLoadingOpen}
        open={openModalIA}
        handleClose={() => {
          setErrorTypeFile(false)
          setOpenModalIA(false)
          setFormIA({ file: '' })
        }}
        formAddIA={formAddIA}
        setFormIA={setFormIA}
        errorTypeFile={errorTypeFile}
        setErrorTypeFile={setErrorTypeFile}
      />

      <ModalAddTeacher
        setSnack={setSnack}
        open={openModalAddTeacher}
        isBatchAction={isBatchAction}
        setIsBatchAction={setIsBatchAction}
        setSelected={setSelected}
        setOpenModalAddTeacher={setOpenModalAddTeacher}
        handleClose={() => {
          setIsBatchAction(false)
          setOpenModalAddTeacher(false)
        }}
        id={id}
        selectedItem={selectedItem}
        idDisc={idDisc}
        setLoadingOpen={setLoadingOpen}
        setFormAddProfessors={setFormAddProfessors}
        formAddProfessors={formAddProfessors}
        setListDisciplines={setListDisciplines}
        setCount={setCount}
        pageActual={page}
        filter={filter}
        discipline={discipline}
      />

      <Modals
        openModalExportFiles={openModalExportFiles}
        idDisc={idDisc}
        listSchedules={listSchedules}
        setOpenModalExportFiles={setOpenModalExportFiles}
        openModalAtaNovoTec={openModalAtaNovoTec}
        setOpenModalAtaNovoTec={setOpenModalAtaNovoTec}
        openModalExportFilesInline={openModalExportFilesInline}
        openModalAddTeacher={openModalAddTeacher}
        setOpenModalAddTeacher={setOpenModalAddTeacher}
        openModalDocuments={openModalDocuments}
        setOpenModalDocuments={setOpenModalDocuments}
        openModalClassSchedule={openModalClassSchedule}
        listSelectListDisciplines={listSelectListDisciplines}
        setOpenModalClassSchedule={setOpenModalClassSchedule}
        typeModal={typeModal}
        setSnack={setSnack}
        setLoadingOpen={setLoadingOpen}
        staticFormValues={staticFormValues}
      />
    </>
  )
}
export default TableVariables
