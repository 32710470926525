import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('Campo é obrigatório'),
  unit: yup.string().required('Campo é obrigatório'),
  begin: yup.string().required('Campo é obrigatório'),
  discount: yup.string().required('Campo é obrigatório'),
  installments: yup.string().required('Campo é obrigatório')
})

export default schema
