const situations = [
  { value: 'done', label: 'Encerradas' },
  { value: 'nextClasses', label: 'Próximas' },
  { value: 'inProgress', label: 'Em andamento' }
]

const INPROGRESS = 'inProgress'

const MESSAGE = {
  success: 'Vínculo excluído com sucesso.'
}

export { situations, MESSAGE, INPROGRESS }
