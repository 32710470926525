import styled from 'styled-components'
import { Card as MuiCard } from '@mui/material'
import Logo from 'components/atoms/Logotype'

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`

const FormWrapper = styled.div`
  &:before {
    background: url('/images/bg-auth.jpg') no-repeat center top;
    background-size: cover;
    content: '';
    display: block;
    left: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
  }
`

const Card = styled(MuiCard)`
  padding: 24px;
  width: 320px;
  margin: 25% auto;
`

const Logotype = styled(Logo)`
  margin-bottom: ${(props) => props.theme.spacing(2)};
`

const Message = styled.p`
  margin: 0 0 16px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: ${({ theme }) => theme.palette.red.main};
  font-size: 18px;
`

const CardCircularProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export { FormWrapper, Logotype, Box, Card, Message, CardCircularProgress }
