import StudentRecord from '../StudentRecord'
import StudentExpedition from '../StudentExpedition'
import TabOccurrences from './components/TabOccurrences/ListOccurrences'
import TabRegistrationData from './components/TabRegistrationData'
import StudentDocuments from '../StudentDocuments'

const TABS = [
  {
    label: 'cursos e turmas',
    value: 'coursesAndClasses',
    component: ({ register, setSnack, studentInfo }) => (
      <StudentRecord
        register={register}
        setSnack={setSnack}
        studentInfo={studentInfo}
      />
    )
  },
  {
    label: 'ocorrências',
    value: 'occurrences',
    component: TabOccurrences
  },
  {
    label: 'documentos',
    value: 'documents',
    component: ({ register, companyId, setSnack, setLoading, updateSnack }) => (
      <StudentDocuments
        register={register}
        companyId={companyId}
        setSnack={setSnack}
        updateSnack={updateSnack}
        setLoading={setLoading}
      />
    )
  },
  {
    label: 'dados cadastrais',
    value: 'registrationData',
    component: TabRegistrationData
  },
  {
    label: 'expedição',
    value: 'expedition',
    component: ({ register, setSnack, studentInfo }) => (
      <StudentExpedition
        register={register}
        setSnack={setSnack}
        studentInfo={studentInfo}
      />
    )
  }
]

export { TABS }
