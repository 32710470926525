import styled from 'styled-components'
import {
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell
} from '@mui/material'

const TableHead = styled(MuiTableHead)`
  &.MuiTableHead-root {
    ${({ $dropouts }) => $dropouts && 'display: none'};
  }
`
const TableRow = styled(MuiTableRow)`
  &.MuiTableRow-root {
    background-color: ${({ theme }) => theme.palette.gray.light};
  }
`

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    flex-wrap: wrap;
    min-width: 75px;
    max-width: 600px;
    border-left: 1px solid;
    border-color: ${({ theme }) => theme.palette.gray.primaryLight};
    font-weight: bold;
  }
`

export { TableHead, TableRow, TableCell }
