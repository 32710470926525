import { Download, Person } from '@mui/icons-material'
import { ROUTE } from 'services/constants'

const MESSAGE = {
  NO_SEARCH:
    '<b>Encontre os requerimento registrados</b><br/>Você pode filtrar com outros critérios a sua busca ou por aluno.',
  MSG_NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br />Verifique os dados informados e tente novamente.',
  SUCESS: 'Requerimento atualizado',
  HELPER_PERIOD:
    'Sem preenchimento do período, a busca retornará dados dos últimos 3 meses.'
}

const colors = ['#593493', '#4caf50', '#2196F3', '#F44336']

const BIG_NUMBERS_LABELS = {
  REQUESTED: 'Solicitados',
  UNDER_REVIEW: 'Em análise',
  DEFERRED: 'Deferidos',
  REJECTED: 'Indeferidos'
}

const listBigNumbers = ({
  requested,
  underReview,
  deferred,
  rejected,
  idTurma
}) => [
  {
    path: `${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_BIND_UNBINDED}?id=${idTurma}`,
    hasLink: true,
    number: requested,
    label: BIG_NUMBERS_LABELS.REQUESTED,
    icon: <Download />,
    description: 'EXPORTAR LISTA'
  },
  {
    path: `acesso/turma_lista_alunos_pdf.php?id_turma=${idTurma}&modeloL=1`,
    hasLink: true,
    number: underReview,
    label: BIG_NUMBERS_LABELS.UNDER_REVIEW,
    icon: <Download />,
    description: 'EXPORTAR LISTA'
  },
  {
    number: deferred,
    label: BIG_NUMBERS_LABELS.DEFERRED
  },
  {
    number: rejected,
    label: BIG_NUMBERS_LABELS.REJECTED
  }
]

const CPF = 'cpf'
const REGISTRATION = 'register'

const openAccordion = 'openAccordion'
const nameAccordion = 'typesRequisition'
const defaultItemsPerPage = 10
const defaultPage = 1
const countDefault = 0
const createdSuccess = 'Requerimento criado com sucesso'

export {
  MESSAGE,
  openAccordion,
  nameAccordion,
  defaultItemsPerPage,
  defaultPage,
  countDefault,
  createdSuccess,
  CPF,
  REGISTRATION,
  colors,
  listBigNumbers,
  BIG_NUMBERS_LABELS
}
