/* eslint-disable react/no-array-index-key */
import { Grid, Typography } from '@mui/material'
import { makeURLAccessLegacy } from 'services/helpers'
import { useSearchParams } from 'react-router-dom'
import * as Styled from './style'

const CardsClass = ({ listCards, iconsCard, colorsCard, bigNumbers }) => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id } = params

  return (
    <Grid container spacing={{ xs: 0, sm: 2 }}>
      {listCards({
        students: bigNumbers.students,
        inactive: bigNumbers.inactive,
        active: bigNumbers.active,
        binded: bigNumbers.binded,
        idTurma: id
      })?.map((itens, index) => (
        <Grid key={itens.label} item xs={12} sm={12} md={3}>
          <Styled.Card $color={colorsCard[index]}>
            <Styled.CardContent margin={0}>
              <Typography
                color={colorsCard[index]}
                sx={{ fontWeight: 700 }}
                variant="h2"
              >
                {itens.number}
              </Typography>

              <Typography color="text.secondary"> {itens.label} </Typography>
            </Styled.CardContent>
          </Styled.Card>

          {itens.hasLink ? (
            <Styled.LinkCard to={itens?.path} key={index}>
              <Styled.Box $color={colorsCard[index]}>
                {iconsCard[index].icon}
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  color={colorsCard[index]}
                >
                  {itens.description}
                </Typography>
              </Styled.Box>
            </Styled.LinkCard>
          ) : (
            <Styled.Box
              style={{ cursor: 'pointer' }}
              $color={colorsCard[index]}
              onClick={() => makeURLAccessLegacy(itens.path, true)}
            >
              {iconsCard[index].icon}
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                color={colorsCard[index]}
              >
                {itens.description}
              </Typography>
            </Styled.Box>
          )}
        </Grid>
      ))}
    </Grid>
  )
}

export { CardsClass }
