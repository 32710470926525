import styled from 'styled-components'
import { Box as BoxMui } from '@mui/material'

const Form = styled.form``

const BoxGroupingCaption = styled(BoxMui)`
  &.MuiBox-root {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 21px;

    & > div {
      width: 21px;
      height: 21px;
      margin-left: 7px;
      border-radius: 2px;
      color: ${({ theme }) => theme.palette.primary.white};
      background-color: ${({ theme }) => theme.palette.primary.warningText};

      & p {
        text-align: center;
        width: 100%;
      }
    }

    & .MuiTypography-caption {
      font-weight: 500;
      margin-left: 8px;
      margin-top: 2px;
      font-size: 12px;
      line-height: 19.92px;
      letter-spacing: 0.4px;
    }
  }
`

export { Form, BoxGroupingCaption }
