import styled from 'styled-components'

const Paginate = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 14%;

  div {
    display: flex;
    align-items: center;

    span {
      padding: 0 20px;
      font-size: 14px;
      color: ${({ theme }) => theme.palette.dark.main};
    }
  }
`

const BottonArrow = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  cursor: pointer;
  height: 26px;
  background-color: transparent;
  transition: all 0.1s ease-in;
  border: 0px;
  outline: none;

  svg {
    font-size: 18px;
    color: ${({ theme }) => theme.palette.gray.medium};
    transition: all 0.1s ease-in;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.purple.light};
    transition: all 0.1s ease-in;

    svg {
      color: ${({ theme }) => theme.palette.primary.main};
      transition: all 0.1s ease-in;
    }
  }
`

export { Paginate, BottonArrow }
