import * as Styled from './style'
import { firstIndex } from './constants'
import { mountRowsBody, mountRowsHeader } from './helpers'

const SkeletonPdf = ({ editorContent }) => {
  const urlParams = new URLSearchParams(window.location.search)

  const isAvailableToShowDisciplineInfo =
    editorContent.program ||
    editorContent.competence ||
    editorContent.skills ||
    editorContent.guidelines ||
    editorContent.evaluation_system ||
    editorContent.methodology ||
    editorContent.main_bibliography ||
    editorContent.complementary_bibliography

  const rowsHeader = mountRowsHeader({ urlParams })
  const rowsBody = mountRowsBody({ ...editorContent })

  return (
    <Styled.MainPdf id="pdf">
      <Styled.Container>
        <Styled.Border hasDontBorderRight>
          <Styled.Logo src="/images/logo-proz.png" alt="Proz" />
        </Styled.Border>
        <Styled.Border>
          <Styled.TextLogo>PLANO DE ENSINO</Styled.TextLogo>
        </Styled.Border>
      </Styled.Container>

      {isAvailableToShowDisciplineInfo && (
        <Styled.Container>
          {rowsHeader.map((row, rowIndex) => {
            const lastIndex = rowsHeader.length - 1
            const hasDontBorderRight = lastIndex !== rowIndex

            return (
              <Styled.Border
                hasDontBorderTop
                hasDontBorderRight={hasDontBorderRight}
              >
                <Styled.Title>{row.label}</Styled.Title>
                <Styled.TextBoxHtml
                  dangerouslySetInnerHTML={{ __html: row.value }}
                />
              </Styled.Border>
            )
          })}
        </Styled.Container>
      )}

      <Styled.Items>
        {rowsBody.map((row, rowIndex) => {
          const hasDontBorderTop = rowIndex !== firstIndex
          const hasValue = !!row?.value

          return (
            hasValue && (
              <Styled.Border hasDontBorderTop={hasDontBorderTop}>
                <Styled.Title>{row.label}</Styled.Title>
                <Styled.TextBoxHtml
                  dangerouslySetInnerHTML={{ __html: row.value }}
                />
              </Styled.Border>
            )
          )
        })}
      </Styled.Items>
    </Styled.MainPdf>
  )
}

export default SkeletonPdf
