const optionsMaritalStatus = [
  {
    value: 'Solteiro(a)',
    label: 'Solteiro(a)'
  },
  {
    value: 'Casado(a)',
    label: 'Casado(a)'
  },
  {
    value: 'Divorciado(a)',
    label: 'Divorciado(a)'
  },
  {
    value: 'Viúvo(a)',
    label: 'Viúvo(a)'
  },
  {
    value: 'União civil',
    label: 'União civil'
  }
]

const hasCPFText = 'Este CPF já está registrado tente outro!'

export { optionsMaritalStatus, hasCPFText }
