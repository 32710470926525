import styled from 'styled-components'
import { TableCell as MuiTableCell } from '@mui/material'
import theme from 'theme/designTokens'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 26rem;
    cursor: pointer;
  }
`

const TableCellAction = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 90rem;
    cursor: pointer;
  }
`

const TableCellEmail = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 26rem;
    cursor: pointer;
    width: 21rem;
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 16px;
    width: 13em;
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 16rem;
      text-decoration: underline;
      color: ${() => theme.palette.primary.main} !important;
    }
  }
`

export { TableCell, TableCellTitle, TableCellEmail, TableCellAction }
