import styled, { css } from 'styled-components'
import { Link as RouteLink } from 'react-router-dom'
import { TableCell, Typography as MuiTypography } from '@mui/material'

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: left;
    max-width: 200px;
    padding: 6px 16px;
    ${({ $maxWidth }) =>
      $maxWidth &&
      css`
        overflow: hidden;
        text-overflow: ellipsis;
      `}
  }

  &:nth-child(3).MuiTableCell-root {
    max-width: 165px;
  }
  &:nth-child(5).MuiTableCell-root {
    max-width: 130px;
  }
`

const BoxLink = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
`
const Box = styled.div`
  width: 14px;
  height: 14px;
  margin: 0 auto;
  border-radius: 64px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`
const ContainerStudentStatus = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ContainerUnitName = styled(ContainerStudentStatus)`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

const StyledTableCellTitle = styled(TableCell)`
  &.MuiTableCell-root {
    max-width: 235px;
    text-align: left;
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;
    padding: 6px 16px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
      }
    }
  }
`
const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.palette.red.light};
  }
`

const Link = styled(RouteLink)`
  text-transform: uppercase;
`

const ContainerIcon = styled.div`
  & .MuiButtonBase-root {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`

export {
  Box,
  Link,
  BoxLink,
  Typography,
  ContainerIcon,
  StyledTableCell,
  ContainerUnitName,
  StyledTableCellTitle,
  ContainerStudentStatus
}
