import { useNavigate } from 'react-router-dom'
import { TableRow, Tooltip } from '@mui/material'
import { LocationOn } from '@mui/icons-material'
import { ROUTE } from 'services/constants'
import * as Styled from './style'

const TableFooter = ({ row }) => {
  const navigate = useNavigate()
  return (
    <TableRow hover key={row?.id} tabIndex={-1} role="checkbox">
      <Styled.StyledTableCellTitle>
        <Tooltip title="Dashboard da turma" placement="top">
          <Styled.BoxLink
            onClick={() =>
              navigate(
                `${ROUTE.ADMIN_SECRETARY_CLASSES_DASHBOARD_CLASS}?id_turma=${row?.class_id}`,
                {
                  state: { savedSearch: true, row }
                }
              )
            }
          >
            {row?.class_code}
          </Styled.BoxLink>
        </Tooltip>
      </Styled.StyledTableCellTitle>

      <Styled.StyledTableCell>
        <Styled.ContainerUnitName>
          {row?.unit_name}
          {row?.pole && row?.pole !== 'Sem Polo' && (
            <Styled.ContainerIcon>
              <Tooltip title={`Polo ${row?.pole}`} placement="top" arrow>
                <LocationOn />
              </Tooltip>
            </Styled.ContainerIcon>
          )}
        </Styled.ContainerUnitName>
      </Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.class_group}</Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.course_name}</Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.shift}</Styled.StyledTableCell>
      <Styled.StyledTableCell>
        <Styled.ContainerStudentStatus>
          <Tooltip title="Ativos" placement="top">
            <Styled.Box>{row?.students?.active}</Styled.Box>
          </Tooltip>
          <Tooltip title="Cancelados" placement="top">
            <Styled.Box $canceled>{row?.students?.canceled}</Styled.Box>
          </Tooltip>
          <Tooltip title="7 dias de falta" placement="top">
            <Styled.Box $absent>{row?.students?.absent}</Styled.Box>
          </Tooltip>
        </Styled.ContainerStudentStatus>
      </Styled.StyledTableCell>
    </TableRow>
  )
}

export default TableFooter
