import axios from 'axios'
import { setupInterceptors } from './httpInterceptors'

const headersDefault = {
  'content-type': 'application/json'
}

export function createInstance({
  baseURL,
  isInterceptor = false,
  headers = headersDefault
}) {
  const httpInstance = axios.create({
    baseURL,
    headers
  })

  if (isInterceptor) {
    setupInterceptors(httpInstance)
  }

  return httpInstance
}

const responseErrorParsed = (response) => {
  try {
    return JSON.parse(response)
  } catch (err) {
    return response
  }
}

export async function httpClient(service) {
  try {
    const { status, data, statusText } = await service

    return { status, data, statusText }
  } catch (err) {
    const errorMessage = responseErrorParsed(err.request.response)

    const errorResponse = {
      data: undefined,
      status: err.request.status,
      error: {
        message: errorMessage || err.message,
        statusText: err.request.statusText
      }
    }

    return errorResponse
  }
}
