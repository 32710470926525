import { Moving } from '@mui/icons-material'
import { ACL } from 'services/constants/acl'
import { ROUTE } from 'services/constants'
import theme from 'theme/designTokens'
import {
  CreateByUpdateUnitOrPole,
  ListUnits,
  ListPole,
  ListTypedOfRequirements,
  ListSubjects,
  CreateByUpdateDiscipline,
  CreateByUpdateTeachingPlan,
  CreateByUpdateRequirements,
  ListCourse,
  CreateByUpdateCourse,
  ListOccurrenceTypes,
  CreateByUpdateOccurrenceTypes,
  ListCurriculum,
  ListTypedOfActivitys,
  CreateTypedOfActivitys,
  EditTypedOfActivitys,
  CreateByUpdateCurriculum,
  RegisterEadContent
} from 'components/pages/Admin'
import { Welcome } from 'components/pages/Shared'
import isAllowUserAccess from '../helpers/isAllowUserAccess'

export const iconProps = {
  color: theme.palette.primary.white
}

const MANAGEMENT_ROUTES = [
  {
    component: ListUnits,
    exact: true,
    icon: <Moving sx={{ ...iconProps }} />,
    label: 'Gestão',
    path: ROUTE.ADMIN_MANAGEMENT_UNITS_POLE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT'),
    submenu: [
      {
        component: ListUnits,
        exact: true,
        label: 'Unidades',
        path: ROUTE.ADMIN_MANAGEMENT_UNITS_POLE,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'MANAGEMENT_UNITS')
      },
      {
        component: ListCourse,
        exact: true,
        label: 'Cursos',
        path: ROUTE.ADMIN_MANAGEMENT_COURSE,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'MANAGEMENT_COURSE')
      },
      {
        component: ListSubjects,
        exact: true,
        label: 'Disciplinas',
        path: ROUTE.ADMIN_MANAGEMENT_SUBJECTS,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'MANAGEMENT_SUBJECTS')
      },
      {
        component: ListTypedOfRequirements,
        exact: true,
        label: 'Tipos de Requerimento',
        path: ROUTE.ADMIN_MANAGEMENT_TYPE_REQUIREMENTS,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'MANAGEMENT_TYPE_REQUIREMENTS')
      },
      {
        component: ListTypedOfActivitys,
        exact: true,
        label: 'Tipos de Atividade',
        path: ROUTE.ADMIN_MANAGEMENT_TYPE_ACTIVITY,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'MANAGEMENT_TYPE_ACTIVITY')
      },
      {
        component: ListOccurrenceTypes,
        exact: true,
        label: 'Tipos de Ocorrência',
        path: ROUTE.ADMIN_MANAGEMENT_OCCURRENCE,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'MANAGEMENT_OCCURRENCE')
      },

      {
        component: ListCurriculum,
        exact: true,
        label: 'Matriz Curricular',
        path: ROUTE.ADMIN_MANAGEMENT_LIST_CURRICULUM,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'MANAGEMENT_LIST_CURRICULUM')
      }
    ]
  },
  {
    component: RegisterEadContent,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_SUBJECTS_REGISTER_EAD_CONTENT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(
        userPermissions,
        'MANAGEMENT_SUBJECTS_REGISTER_EAD_CONTENT'
      )
  },
  {
    component: CreateByUpdateCurriculum,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_CURRICULUM_CREATE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_CURRICULUM_CREATE')
  },
  {
    component: CreateByUpdateCurriculum,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_CURRICULUM_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_CURRICULUM_EDIT')
  },
  {
    component: CreateByUpdateCourse,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_COURSE_CREATE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_COURSE_CREATE')
  },
  {
    component: CreateByUpdateCourse,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_COURSE_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_COURSE_EDIT')
  },
  {
    component: CreateByUpdateTeachingPlan,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_SUBJECTS_PLAN_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_SUBJECTS_PLAN_CREATE')
  },
  {
    component: CreateByUpdateRequirements,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_TYPE_REQUIREMENTS_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_TYPE_REQUIREMENTS_CREATE')
  },
  {
    component: CreateByUpdateDiscipline,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_SUBJECTS_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_SUBJECTS_CREATE')
  },
  {
    component: CreateByUpdateDiscipline,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_SUBJECTS_EDIT,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_SUBJECTS_EDIT')
  },
  {
    component: CreateByUpdateOccurrenceTypes,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_OCCURRENCE_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_OCCURRENCE_CREATE')
  },
  {
    component: CreateByUpdateUnitOrPole,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_UNIT_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_UNIT_CREATE')
  },
  {
    component: CreateByUpdateUnitOrPole,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_UNIT_EDIT,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_UNIT_EDIT')
  },
  {
    component: ListPole,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_POLE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_POLE')
  },
  {
    component: CreateByUpdateUnitOrPole,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_POLE_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_POLE_CREATE')
  },
  {
    component: CreateByUpdateUnitOrPole,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_POLE_EDIT,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_POLE_EDIT')
  },
  {
    component: Welcome,
    exact: true,
    path: ROUTE.ADMIN,
    permissions: ACL.admin,
    isAllowed: (userPermissions) => isAllowUserAccess(userPermissions, 'ADMIN')
  },
  {
    component: CreateTypedOfActivitys,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_TYPE_ACTIVITY_CREATE,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_TYPE_ACTIVITY')
  },
  {
    component: EditTypedOfActivitys,
    exact: true,
    path: ROUTE.ADMIN_MANAGEMENT_TYPE_ACTIVITY_EDIT,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'MANAGEMENT_TYPE_ACTIVITY')
  }
]

export default MANAGEMENT_ROUTES
