import {
  listReEnrollment,
  acceptTermReEnrollment as acceptTermReEnrollmentService
} from 'services/api/student'
import { toastNotificationSuccess, updateErrorMessage } from 'services/helpers'
import { messagesServiceAccpetTerm } from './constants'

const reEnrollmentList = async ({ username }) => {
  const { data, error, status } = await listReEnrollment({ username })
  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: ''
    })
  }
  return data
}

const acceptTermReEnrollment = async ({ id, isAutomatic, callbackSuccess }) => {
  const { successMessage, feedbackMessage } = messagesServiceAccpetTerm
  const { error, status } = await acceptTermReEnrollmentService(id, isAutomatic)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage
    })
  }
  callbackSuccess()
  return toastNotificationSuccess(successMessage, 1200)
}

export { reEnrollmentList, acceptTermReEnrollment }
