/* eslint-disable consistent-return */
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'

const breadcrumbsItems = [
  {
    path: ROUTE.contracts.LIST_CONTRACTS,
    label: '<b>Sistema - </b> Tipos de Contrato'
  }
]

const isDisabledButton = ({ name }) => {
  const enabled = name && name.length >= 3
  return !enabled
}

const isDisabledButtonCreate = ({ name, description }) => {
  const enabled =
    name && name.length >= 3 && description && description.length >= 3
  return !enabled
}

const getTitleModalConfirm = (isActive) => (isActive ? 'Inativar' : 'Ativar')

const getMessageModalConfirm = (isActive, amount) => {
  let result = `Deseja ${
    isActive ? 'inativar' : 'ativar'
  } o contrato selecionado?`
  if (isActive && amount > 1) {
    result = `Deseja inativar os ${amount} tipos de contratos selecionados?`
  } else if (amount > 1) {
    result = `Deseja ativar os ${amount} tipos de contratos selecionados?`
  }
  return result
}

const getClassModalConfirm = (isActive, amount) => {
  let result = isActive
    ? TAG_MANAGER.sistema_btn_confirma_inativarContrato
    : TAG_MANAGER.sistema_btn_confirma_ativarContrato
  if (isActive && amount > 1) {
    result = TAG_MANAGER.sistema_btn_confirma_inativarContratos
  } else if (amount > 1) {
    result = TAG_MANAGER.sistema_btn_confirma_ativarContratos
  }
  return result
}

export {
  breadcrumbsItems,
  isDisabledButton,
  getClassModalConfirm,
  isDisabledButtonCreate,
  getTitleModalConfirm,
  getMessageModalConfirm
}
