import { Box, Chip, Grid } from '@mui/material'
import { InputAutocomplete, InputSwitch } from 'components/atoms'
import { TextField, Select } from 'components/molecules'
import { isEmpty } from 'services/helpers'
import { useSortTable } from 'services/hooks'
import * as Styled from './style'
import { handleCourses } from '../../services'

const FormClass = ({
  levels,
  unitys,
  courses,
  setCourses,
  groups,
  poles,
  values,
  setFieldValue,
  handleChange,
  setSnack,
  period,
  modules,
  setUnitIdSelected,
  isSearchAdvanced
}) => {
  const { sortTable: sortModules } = useSortTable(modules, 'value')

  return (
    <>
      <Grid item xs={12} sm={12} md={4}>
        <Select
          id="unit"
          value={values?.unit}
          label="Unidade"
          optionsList={unitys}
          onChange={(e) => {
            setUnitIdSelected(e.target.value)
            setFieldValue('unit', e.target.value)
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <TextField
          fullWidth
          id="classIdentifier"
          value={values?.classIdentifier}
          label="Nome da turma"
          onChange={handleChange}
          isMargin="0"
        />
      </Grid>

      {isSearchAdvanced && (
        <>
          <Styled.GroupSwitch item xs={12} sm={12} md={4}>
            <Styled.Switch>
              <InputSwitch
                label="Encerradas"
                checked={values.closed}
                onClick={(e) => setFieldValue('closed', e.target.checked)}
              />
            </Styled.Switch>

            <Styled.Switch>
              <InputSwitch
                label="EAD"
                checked={values.ead}
                onClick={(e) => setFieldValue('ead', e.target.checked)}
              />
            </Styled.Switch>
          </Styled.GroupSwitch>

          {!isEmpty(poles) && (
            <Grid item xs={12} sm={12} md={4}>
              <InputAutocomplete
                label="Polo"
                multiple
                limitTags={1}
                labelId="multiple-limit-poles"
                disabled={isEmpty(poles)}
                value={values?.pole ?? []}
                handleChange={(_, value) => {
                  setFieldValue('pole', value)
                }}
                filterSelectedOptions
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, label) => option === label}
                listOptions={poles}
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option?.value}>
                    {option.label}
                  </Box>
                )}
                renderTags={(selected, getTagProps) =>
                  selected?.map((option, index) => (
                    <Chip
                      key={option?.value}
                      label={option.label}
                      variant="outlined"
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={4}>
            <InputAutocomplete
              label="Grupo"
              multiple
              limitTags={1}
              labelId="multiple-limit-groups"
              value={values?.group ?? []}
              handleChange={(_, value) => {
                setFieldValue('group', value)
              }}
              filterSelectedOptions
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, label) => option === label}
              listOptions={groups}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option?.value}>
                  {option.label}
                </Box>
              )}
              renderTags={(selected, getTagProps) =>
                selected?.map((option, index) => (
                  <Chip
                    key={option?.value}
                    label={option.label}
                    variant="outlined"
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Select
              id="level"
              value={values?.level}
              label="Nível"
              optionsList={levels}
              onChange={(e) => {
                setFieldValue('level', e.target.value)
                handleCourses({
                  unitys,
                  setSnack,
                  setCourses,
                  unit: values?.unit,
                  level: e.target.value
                })
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Select
              id="course"
              value={values?.course}
              label="Curso"
              disabled={!values?.level}
              optionsList={courses}
              onChange={(e) => {
                setFieldValue('course', e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Select
              id="shift"
              value={values?.shift}
              label="Turno"
              optionsList={period}
              onChange={(e) => {
                setFieldValue('shift', e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Select
              id="module"
              value={values?.module}
              label="Módulo"
              optionsList={sortModules}
              onChange={(e) => {
                setFieldValue('module', e.target.value)
              }}
            />
          </Grid>
        </>
      )}
    </>
  )
}

export default FormClass
