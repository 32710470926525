export const ROLES = {
  ADMIN: {
    code: 1,
    isActive: true,
    name: 'admin',
    school: 'all'
  },
  MARKETING: {
    code: 2,
    isActive: true,
    name: 'marketing',
    school: 'all'
  },
  PROFESSOR: {
    code: 5,
    isActive: true,
    name: 'professor',
    school: 'all'
  },
  SECRETARY: {
    code: 3,
    isActive: true,
    name: 'secretary',
    school: 'all'
  },
  STUDENT: {
    code: 4,
    isActive: true,
    name: 'student',
    school: 'all'
  },
  ACADEMIC: {
    code: 6,
    isActive: true,
    name: 'admin-academic',
    school: 'all'
  }
}
