import { lazy } from 'react'
import { Grid, Table, TableBody, TableContainer } from '@mui/material'
import { RemoveCircleOutline, WarningAmber } from '@mui/icons-material'
import { useCheckedTable, useSortTable } from 'services/hooks'
import { IsEmpty } from 'services/helpers'
import { TableHeaderDefault } from 'components/organisms'
import { Button } from 'components/atoms'
import { ModalConfirm, PaginationAdvanced } from 'components/molecules'
import {
  defaultPage,
  defaultTotalPage,
  headCells,
  idNameList,
  paginationFilter
} from './constants'
import * as Styled from './style'
import { currentDate } from '../../helpers'

const TableTdBody = lazy(() => import('./TableBody'))

const TableReenroll = ({
  itemsTable,
  openModalInactive,
  setOpenModalInactive,
  setOpenModalReenroll,
  setOpenModalCreateReenroll,
  setOpenModalEditReenroll,
  setStartDate,
  totalPage,
  pageActual,
  setPage,
  filter,
  setFilter,
  setSnack,
  setLoadingOpen,
  inactiveReenroll,
  count,
  handleSubmitPdfSummary,
  setIdModalReenroll,
  setEndDate,
  id,
  setCount,
  setIsPagination,
  setListEnroll
}) => {
  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    setSelected,
    selected: selectedItem
  } = useCheckedTable(itemsTable, idNameList)

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  const { handleRequestSort, order, orderBy, sortTable } =
    useSortTable(itemsTable)

  const selectedItems = selectedItem?.length

  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 2 }} alignItems="center">
        <Grid item xs={12} sm={12} md={6}>
          <Styled.Toolbar>
            <Button
              color="warning"
              variant="outlined"
              startIcon={<RemoveCircleOutline />}
              value="Inativar"
              disabled={selectedItem?.length < defaultPage}
              onClick={() => setOpenModalInactive(true)}
            />
          </Styled.Toolbar>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Styled.Box>
            <Button
              size="medium"
              value="Nova Rematrícula"
              onClick={() => setOpenModalCreateReenroll(true)}
            />
          </Styled.Box>
        </Grid>
      </Grid>

      {!IsEmpty(selectedItem) &&
        selectedItems &&
        `${selectedItems} itens selecionados`}
      <Styled.TableContainer>
        <TableContainer>
          <Table size="small">
            <TableHeaderDefault
              checkbox
              disabledCheckbox={sortTable.every(
                (row) =>
                  currentDate > new Date(row.end_date) || row.active === false
              )}
              numSelected={selectedItem.length}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={itemsTable?.length}
              headCells={headCells}
            />

            <TableBody>
              {sortTable?.map((row, index) => {
                const isItemSelected = isSelected(row?.id)
                const labelId = `enhanced-table-${index}`

                return (
                  <TableTdBody
                    key={row.id}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    item={row}
                    setSnack={setSnack}
                    setSelected={setSelected}
                    setStartDate={setStartDate}
                    setLoadingOpen={setLoadingOpen}
                    setEndDate={setEndDate}
                    handleClick={handleClick}
                    selected={selectedItem}
                    setOpenModalInactive={setOpenModalInactive}
                    setOpenModalEditReenroll={setOpenModalEditReenroll}
                    handleSubmitPdfSummary={handleSubmitPdfSummary}
                    setIdModalReenroll={setIdModalReenroll}
                    setOpenModalReenroll={setOpenModalReenroll}
                  />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {count > defaultTotalPage && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={pageActual}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setPage(defaultPage)
              setFilter(e.target.value)
            }}
          />
        )}

        <ModalConfirm
          open={openModalInactive}
          icon={<WarningAmber color="warning" fontSize="large" />}
          title="Inativar"
          message="Deseja realmente inativar a rematrícula? 
          Ao inativar, só será possível ativá-la novamente apenas se uma nova rematrícula for criada."
          textButtonNotConfirm="Não"
          textButtonConfirm="Sim"
          colorButtonConfirm="error"
          onClickNotConfirm={() => {
            setSelected([])
            setOpenModalInactive(!openModalInactive)
          }}
          onClickConfirm={() =>
            inactiveReenroll({
              setLoadingOpen,
              setSnack,
              setOpenModalInactive,
              selectedItem,
              setSelected,
              filter,
              pageActual,
              id,
              setCount,
              setIsPagination,
              setListEnroll
            })
          }
        />
      </Styled.TableContainer>
    </>
  )
}
export { TableReenroll }
