import { Form, Formik } from 'formik'
import { Grid, InputAdornment, Typography } from '@mui/material'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import { typeMask } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import { assessmentList } from 'components/pages/Admin/Management/Curriculum/CreateByUpdateCurriculum/TabSecondForm/constants'
import { initialValues } from './constants'
import { disabledButton } from './helpers'
import * as Styled from './style'
import { createDiscipline } from '../../services'

const ModalAddDiscipline = ({
  id,
  filter,
  modules,
  pageActual,
  setCount,
  setFreeDisciplineCodes,
  setIsPagination,
  setEditDisciplineList,
  handleClose,
  open,
  optionDisciplines,
  setSnack,
  setLoadingOpen
}) => (
  <Styled.Modal open={open} onClose={handleClose} maxWidth="lg">
    <Formik
      initialValues={initialValues}
      onSubmit={(values) =>
        createDiscipline({
          setSnack,
          values,
          id,
          setLoadingOpen,
          filter,
          pageActual,
          setCount,
          setFreeDisciplineCodes,
          setIsPagination,
          setEditDisciplineList,
          handleClose
        })
      }
      enableReinitialize
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Styled.Grid>
            <Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12}>
                <Styled.Title variant="h5" component="h2">
                  Adicionar Disciplina
                </Styled.Title>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">
                  Preencha os campos abaixo para adicionar uma disciplina:
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Select
                  id="discipline"
                  label="Disciplina"
                  value={values?.name || ''}
                  optionsList={optionDisciplines}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  id="code"
                  label="Código de Referência"
                  fullWidth
                  value={values?.code || ''}
                  onChange={(e) => setFieldValue('code', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Select
                  id="module"
                  label="Módulo"
                  value={values?.module}
                  optionsList={modules}
                  onChange={(e) => {
                    setFieldValue('module', e.target.value)
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Select
                  id="assessment"
                  label="Avaliação por"
                  value={values?.type_score}
                  optionsList={assessmentList}
                  onChange={(e) => setFieldValue('type_score', e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2} marginRight={2}>
                <Styled.InputAdornment position="start">
                  Carga Horária
                </Styled.InputAdornment>
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  name="workloadHour"
                  fullWidth
                  id="workloadHour"
                  value={typeMask('NUMBER', values?.workload_hours || '')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"> horas </InputAdornment>
                    )
                  }}
                  onChange={(e) =>
                    setFieldValue('workload_hours', e.target.value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  name="workloadMinute"
                  fullWidth
                  id="workloadMinute"
                  value={typeMask('NUMBER', values?.workload_minutes || '')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">minutos</InputAdornment>
                    )
                  }}
                  onChange={(e) =>
                    setFieldValue('workload_minutes', e.target.value)
                  }
                />
              </Grid>
            </Grid>

            <ButtonFooterV2
              labelClose="Cancelar"
              labelConfirm="Confirmar"
              classNameConfirm={
                TAG_MANAGER.secretary_academico_btn_confirma_adicionaDisciplina
              }
              size="medium"
              onClickClose={handleClose}
              disabled={disabledButton(values)}
            />
          </Styled.Grid>
        </Form>
      )}
    </Formik>
  </Styled.Modal>
)

export default ModalAddDiscipline
