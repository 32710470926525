import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  width: 150px;
  height: 185px;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
`

const BackgroundImage = styled.img`
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 auto;
`

const Details = styled.div`
  width: 100%;
  height: 85px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 10px 10px;
  padding: 5px 2px;
  box-sizing: border-box;
`

const DetailsTitle = styled.p`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  margin: 2.5px;
`

const DetailsDescribe = styled(DetailsTitle)`
  color: ${({ theme }) => theme.palette.primary.white};
`

export { Container, BackgroundImage, Details, DetailsTitle, DetailsDescribe }
