import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { Modal } from 'components/atoms'
import ButtonFooterV2 from '../ButtonFooterV2'
import * as Styled from './style'

const ModalContentPdf = ({
  open,
  handleClose,
  icon,
  title,
  message,
  textButtonNotConfirm,
  textButtonConfirm,
  onClickNotConfirm,
  onClickConfirm,
  colorButtonConfirm,
  colorButtonNotConfirm,
  classNameBtnConfirm,
  classNameBtnCancel,
  content,
  maxWidth,
  ...props
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    fullWidth
    {...(maxWidth && { maxWidth })}
  >
    <Styled.Container>
      <Styled.Header>
        <Styled.IconText>
          {icon}
          <Styled.Title variant="h5">{title}</Styled.Title>
        </Styled.IconText>
        <Typography variant="subtitle1">{message}</Typography>
      </Styled.Header>

      {content}

      <ButtonFooterV2
        classNameConfirm={classNameBtnConfirm}
        classNameCancel={classNameBtnCancel}
        labelClose={textButtonNotConfirm}
        labelConfirm={textButtonConfirm}
        onClickClose={onClickNotConfirm}
        onClickConfirm={onClickConfirm}
        colorClose={colorButtonNotConfirm}
        colorConfirm={colorButtonConfirm}
        {...props}
      />
    </Styled.Container>
  </Modal>
)

export default ModalContentPdf

ModalContentPdf.propTypes = {
  open: PropTypes.bool,
  icon: PropTypes.element,
  title: PropTypes.string,
  message: PropTypes.string,
  handleClose: PropTypes.func,
  content: PropTypes.element,
  maxWidth: PropTypes.oneOf(['sm', 'md', 'lg', 'xs', 'xl'])
}

ModalContentPdf.defaultProps = {
  open: false,
  title: '',
  message: '',
  maxWidth: 'sm'
}
