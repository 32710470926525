import { Toolbar, Checkbox, IconButton, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import theme from 'theme/designTokens'
import TAG_MANAGER from 'tagManager'
import { numberSelected } from '../constants'
import * as Styled from './style'

const TableToolbar = (props) => {
  const {
    numSelected,
    selected,
    handleDeleteClass,
    rowCount,
    handleSelectAllClick
  } = props

  return (
    <Styled.ToolbarArea>
      <Toolbar>
        <Checkbox
          color="primary"
          indeterminate={numSelected > numberSelected && numSelected < rowCount}
          checked={rowCount > numberSelected && numSelected === rowCount}
          onChange={handleSelectAllClick}
          inputProps={{
            'aria-label': 'selecione todas as aulas'
          }}
        />
        {numSelected > numberSelected && (
          <>
            <Tooltip title="Delete">
              <IconButton
                className={TAG_MANAGER.btn_teacher_requests_deletes_Classes}
                onClick={() => handleDeleteClass(selected)}
              >
                <DeleteIcon sx={{ color: theme.palette.textColors.error }} />
              </IconButton>
            </Tooltip>
            <Styled.Message>Excluir {numSelected} selecionados</Styled.Message>
          </>
        )}
      </Toolbar>
    </Styled.ToolbarArea>
  )
}
export default TableToolbar
