import { PropTypes } from 'prop-types'
import * as Styled from './style'

const Text = ({ children, type = 'normal' }) => (
  <Styled.TextLine type={type}> {children} </Styled.TextLine>
)

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  type: PropTypes.oneOf(['underline', 'bold', 'normal'])
}

Text.defaultProps = {
  type: 'normal'
}

export default Text
