const NOVOTEC_UNIT = '(SP) NOVOTEC'
const NOVOTEC_UNIT_ID = 932
const MATOGROSSO_UNIT = '(MS) Mato Grosso do Sul'
const MATOGROSSO_UNIT_ID = 1369

const BIMESTER_NOVOTEC_MAP = {
  2024: {
    1: {
      begin: '2024-02-15',
      end: '2024-04-19'
    },
    2: {
      begin: '2024-04-22',
      end: '2024-07-08'
    },
    3: {
      begin: '2024-07-29',
      end: '2024-10-04'
    },
    4: {
      begin: '2024-10-07',
      end: '2024-12-17'
    }
  }
}

const BIMESTER_MS_MAP = {
  2024: {
    1: {
      begin: '2024-02-08',
      end: '2024-04-30'
    },
    2: {
      begin: '2024-05-02',
      end: '2024-07-16'
    },
    3: {
      begin: '2024-08-01',
      end: '2024-09-30'
    },
    4: {
      begin: '2024-10-01',
      end: '2024-12-13'
    }
  }
}

const START_YEAR_FOR_NOVOTEC = 2024

export {
  NOVOTEC_UNIT,
  NOVOTEC_UNIT_ID,
  BIMESTER_NOVOTEC_MAP,
  START_YEAR_FOR_NOVOTEC,
  BIMESTER_MS_MAP,
  MATOGROSSO_UNIT,
  MATOGROSSO_UNIT_ID
}
