import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { ButtonFooterV2, TextField } from 'components/molecules'
import { makeURLAccessLegacy } from 'services/helpers'
import * as Styled from './style'
import { validationSchema } from './schema'

const ModalCoverPage = ({
  idDisc,
  setOpenModalCoverPage,
  openModalCoverPage
}) => {
  const initalValues = { title: '', expirationDate: '' }

  const handleModalSubmit = (values) => {
    makeURLAccessLegacy(
      `acesso/turma_diario_capa.php?capaD=${values.title}&turmaDCapa=${idDisc}`,
      true
    )

    return setOpenModalCoverPage(false)
  }

  return (
    <Styled.Modal
      open={openModalCoverPage}
      onClose={() => setOpenModalCoverPage(false)}
      maxWidth="sm"
    >
      <Formik
        initialValues={initalValues}
        onSubmit={handleModalSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, handleSubmit, touched, errors, dirty, setFieldValue }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <Styled.Title variant="h5" component="h2">
                    Folha de Rosto
                    <Styled.Close
                      onClick={() => setOpenModalCoverPage(false)}
                    />
                  </Styled.Title>
                </Grid>
              </Grid>

              <Styled.GridForm container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    label="Título"
                    id="outlined-title"
                    value={values.title}
                    helperText={touched.title && errors.title}
                    error={touched.title && Boolean(errors.title)}
                    onChange={(e) => setFieldValue('title', e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Styled.Box>
                    <ButtonFooterV2
                      labelClose="Cancelar"
                      labelConfirm="Visualizar folha de rosto"
                      size="medium"
                      disabled={!dirty}
                      onClickClose={() => () => setOpenModalCoverPage(false)}
                    />
                  </Styled.Box>
                </Grid>
              </Styled.GridForm>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export { ModalCoverPage }
