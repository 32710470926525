import styled, { css } from 'styled-components'
import { TableRow as MuiTableRow, TableCell } from '@mui/material'
import { ACTIVITY_STATUS } from 'services/constants'
import {
  BookmarkAdd as MuiBookmarkAdd,
  PictureAsPdf as MuiPictureAsPdf
} from '@mui/icons-material'

const StyleByStatus = {
  [ACTIVITY_STATUS.SENT]: css`
    background-color: ${({ theme }) => theme.palette.gray.light};
    color: #000000;
  `,
  [ACTIVITY_STATUS.DEFERRED]: css`
    background-color: ${({ theme }) => theme.palette.success.main};
    color: #fff;
  `,
  [ACTIVITY_STATUS.REJECTED]: css`
    background-color: ${({ theme }) => theme.palette.red.light};
    color: #fff;
  `
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  tr:hover td {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const TableRow = styled(MuiTableRow)`
  ${({ $isDisabled }) =>
    $isDisabled && ' opacity: 0.5; &:hover { opacity: 1; }'}

  .MuiTableCell-root {
    padding: 10px 15px;
  }

  .actions {
    display: none;
  }

  :hover {
    .actions {
      display: flex;
    }
  }
`

const Status = styled.div`
  padding: 5px;
  border-radius: 16px;
  background-color: red;
  color: #fff;
  text-align: center;

  ${({ status }) => StyleByStatus[status]}
`

const Column = styled(TableCell)`
  max-height: 36px;
`

const ColumnTotalWorkLoad = styled(TableCell)`
  width: 15%;
  background-color: #e8e8e8;
`

const ColumnActivity = styled.div`
  display: flex;
  justify-content: space-between;
`

const BookmarkAdd = styled(MuiBookmarkAdd)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.dark.medium};
    outline: none;
    border: 0px;
  }
`

const PictureAsPdf = styled(MuiPictureAsPdf)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.dark.medium};
    outline: none;
    border: 0px;
  }
`

const Actions = styled.div`
  gap: 5px;
  display: flex;
`

const Link = styled.a`
  margin-left: 0.5rem;
`

export {
  TableRow,
  Wrapper,
  Status,
  Column,
  ColumnTotalWorkLoad,
  ColumnActivity,
  BookmarkAdd,
  PictureAsPdf,
  Actions,
  Link
}
