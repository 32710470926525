import { Table as MuiTable, TableBody } from '@mui/material'
import { TableHeaderDefault } from 'components/organisms'
import { useSortTable } from 'services/hooks'
import TableFooter from './TableFooter'
import { headCellsPending } from './constants'
import * as Styled from './style'

const List = ({ items, isUnifyStatus }) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    items,
    headCellsPending
  )
  return (
    <Styled.StyledPaper>
      <Styled.StyledTableContainer>
        <MuiTable>
          <TableHeaderDefault
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCellsPending}
          />

          <TableBody>
            {sortTable?.map((row) => (
              <TableFooter
                row={row}
                key={row.name}
                isUnifyStatus={isUnifyStatus}
              />
            ))}
          </TableBody>
        </MuiTable>
      </Styled.StyledTableContainer>
    </Styled.StyledPaper>
  )
}
export default List
