import { httpGet } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getFinancial = async (params) => {
  const getFinancialStudent = await httpGet(ENDPOINT.STUDENT_FINANCES, {
    params
  })

  return getFinancialStudent
}

export const getStudentHasOverdue = async ({ register }) => {
  const studentHasOverdue = await httpGet(
    ENDPOINT.STUDENT_FINANCES_HAS_OVERDUE({ register })
  )

  return studentHasOverdue
}

export const listStudentPaymentMethods = async ({ register }) => {
  const studentPaymentMethods = await httpGet(
    ENDPOINT.STUDENT_PAYMENT_METHODS(register)
  )
  return studentPaymentMethods
}
