import React from 'react'
import { Avatar, Typography, Card } from '@mui/material'
import TAG_MANAGER from 'tagManager'
import { invalidDate } from './constants'
import * as Styled from './style'

const BoxDiscipline = ({ listClass }) => {
  const isValidDate = (item) =>
    item?.begin_at !== invalidDate && item?.end_at !== invalidDate

  return (
    <Styled.Container>
      {listClass &&
        listClass?.map((item) => (
          <Card key={item.id}>
            <Styled.Link
              href={item?.joy_class_url ?? '/'}
              target={item?.joy_class_url ? '_blank' : '_self'}
            >
              <Styled.CardContent
                className={TAG_MANAGER.student_card_access_discipline}
                $backgroundColor={item?.type}
              >
                <Styled.Title variant="subtitle2" $color={item?.type}>
                  {item.name}
                </Styled.Title>
                {item?.school_class.code && (
                  <Styled.Title variant="subtitle2" $color={item?.type}>
                    Turma: {item.school_class.code}
                  </Styled.Title>
                )}
                {isValidDate(item) && (
                  <Styled.Subtitle variant="body2" $color={item?.type}>
                    {`${item.begin_at} até ${item.end_at}`}
                  </Styled.Subtitle>
                )}
              </Styled.CardContent>
            </Styled.Link>
            <Styled.CardFooter>
              <Styled.CardTeacher>
                <Avatar alt={item.img} src={item.img} />
                <Styled.CardInfo>
                  <Typography variant="subtitle2">
                    {item.professor?.name ?? '-'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Professor
                  </Typography>
                </Styled.CardInfo>
              </Styled.CardTeacher>
            </Styled.CardFooter>
          </Card>
        ))}
    </Styled.Container>
  )
}

export default BoxDiscipline
