import { useLocation } from 'react-router-dom'

const editPagekeyWords = ['atualizar', 'edit', 'editar', 'update']
const newPagekeyWords = ['create', 'criar', 'new', 'novo', 'nova']

export default function usePageType() {
  const { pathname } = useLocation()
  const lastSegment = pathname?.split('/').pop()

  const checkPageType = (words) =>
    words.some((word) => lastSegment.includes(word))

  const isEdit = checkPageType(editPagekeyWords)
  const isNew = checkPageType(newPagekeyWords)

  return {
    isEdit,
    isNew
  }
}
