import { useWindowEvent } from 'services/hooks'

const handleClickOutside = (element, elementRef, callback, options) => {
  const hasMenuElement = elementRef?.current?.parentElement?.contains(element)
  if (!hasMenuElement) callback(options)
}

const useClickOutside = (elementRef, callback, options) => {
  useWindowEvent('click', ({ target }) =>
    handleClickOutside(target, elementRef, callback, options),
  )
}

export default useClickOutside
