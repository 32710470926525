import { Checkbox, TableRow, Tooltip } from '@mui/material'
import { sortAsc, numberSelected } from '../constants'
import * as Styled from './style'

const TableHeaderDefault = ({
  handleSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  headCells,
  checkbox = false,
  disabledCheckbox = false,
  checkboxTitleTooltip = ''
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const isToOrderById = (id) => orderBy === id

  return (
    <Styled.TableHead>
      <TableRow>
        {checkbox && (
          <Styled.TableCellCheckbox padding="checkbox">
            <Tooltip title={checkboxTitleTooltip}>
              <Checkbox
                disabled={disabledCheckbox}
                color="primary"
                indeterminate={
                  numSelected > numberSelected && numSelected < rowCount
                }
                checked={rowCount > numberSelected && numSelected === rowCount}
                onChange={handleSelectAllClick}
              />
            </Tooltip>
          </Styled.TableCellCheckbox>
        )}
        {headCells?.map((headCell) => (
          <Styled.TableCell
            key={headCell.id}
            sortDirection={isToOrderById(headCell.id) ? order : false}
          >
            <Styled.TableSortLabel
              active={isToOrderById(headCell.id)}
              direction={isToOrderById(headCell.id) ? order : sortAsc}
              onClick={createSortHandler(headCell.id)}
              disabled={headCell.disabled}
              $disabledIcon={headCell.disabled}
            >
              {headCell.label}
            </Styled.TableSortLabel>
          </Styled.TableCell>
        ))}
      </TableRow>
    </Styled.TableHead>
  )
}

export default TableHeaderDefault
