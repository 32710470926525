import { ROLES } from './roles'

export const ACL = {
  all: Object.values(ROLES),

  admin: ROLES.ADMIN,
  adminAcademic: ROLES.ACADEMIC,
  marketing: ROLES.MARKETING,
  professor: ROLES.PROFESSOR,
  secretary: ROLES.SECRETARY,
  student: ROLES.STUDENT,

  routes: {
    userProfile: [ROLES.ACADEMIC, ROLES.ADMIN, ROLES.STUDENT, ROLES.PROFESSOR]
  }
}
