import { useFormikContext } from 'formik'
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material'

import { CardAffiliation } from './components'
import { listAffiliation, listTypeResponsible } from './constants'

const TabAffiliation = () => {
  const { values, handleChange } = useFormikContext()

  const fieldNameAffiliation = 'affiliation'

  return (
    <Grid container xs={12} spacing={{ xs: 0, sm: 5 }}>
      <Grid item xs={12}>
        <FormLabel>Responsável Legal</FormLabel>
        <RadioGroup
          name={`${fieldNameAffiliation}.legalGuardianType`}
          onChange={handleChange}
          value={values?.[fieldNameAffiliation]?.legalGuardianType}
          row
        >
          {listTypeResponsible.map((item) => (
            <FormControlLabel
              value={item.fieldName}
              control={<Radio size="small" />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </Grid>

      {listAffiliation.map((item) => (
        <CardAffiliation
          label={item.label}
          fieldName={item.fieldName}
          fieldNameAffiliation={fieldNameAffiliation}
        />
      ))}
    </Grid>
  )
}

export default TabAffiliation
