import FormGeneralData from './components/FormGeneralData'
import FormHistoric from './components/FormHistoric'

const TABS = [
  {
    label: 'histórico',
    value: 'historic',
    component: ({
      listSector,
      setListSector,
      isActiveSector,
      listResponsible,
      setIsActiveSector,
      setListResponsible,
      setSnack,
      isRequirementId,
      rows,
      filter,
      setFilter,
      setPage,
      page,
      totalPage,
      count,
      requirement,
      setShouldUpdateList
    }) => (
      <FormHistoric
        requirement={requirement}
        listSector={listSector}
        setListSector={setListSector}
        isActiveSector={isActiveSector}
        listResponsible={listResponsible}
        setIsActiveSector={setIsActiveSector}
        setListResponsible={setListResponsible}
        setSnack={setSnack}
        isRequirementId={isRequirementId}
        rows={rows}
        filter={filter}
        setFilter={setFilter}
        setPage={setPage}
        page={page}
        totalPage={totalPage}
        count={count}
        setShouldUpdateList={setShouldUpdateList}
      />
    ),
    className: 'historic'
  },

  {
    label: 'dados gerais',
    value: 'general_data',
    component: ({ requirement }) => (
      <FormGeneralData requirement={requirement} />
    ),
    className: 'general_data'
  }
]

export { TABS }
