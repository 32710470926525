import { Select, TextField } from 'components/molecules'
import { InputSwitch } from 'components/atoms'
import * as Styled from './style'

const FormListTypesOfActivity = ({ level, unitys, listCourses, formik }) => (
  <>
    <Styled.Grid item xs={12} sm={12} md={2}>
      <TextField
        fullWidth
        isMargin="0"
        label="Atividade"
        id="name"
        name="name"
        onBlur={formik.handleBlur}
        value={formik.values?.name}
        error={formik.touched.name && formik.errors.name}
        helperText={formik.touched.name && formik.errors.name}
        onChange={(e) => formik.setFieldValue('name', e.target.value)}
      />
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={2}>
      <Select
        id="unit"
        name="unit"
        label="Unidade"
        optionsList={unitys}
        value={formik.values?.unit || ''}
        onChange={(e) => formik.setFieldValue('unit', e.target.value)}
      />
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={2}>
      <Select
        id="level"
        name="level"
        label="Nível"
        optionsList={level}
        disabled={!formik.values.unit}
        value={formik.values?.level || ''}
        onChange={(e) => formik.setFieldValue('level', e.target.value)}
      />
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={2}>
      <Select
        id="course"
        label="Curso"
        name="course"
        optionsList={listCourses}
        disabled={!formik.values.level}
        value={formik.values?.course || ''}
        onChange={(e) => formik.setFieldValue('course', e.target.value)}
      />
    </Styled.Grid>
    <Styled.Grid item $small xs={12} sm={12} md={2}>
      <Styled.Switch>
        <InputSwitch
          label="Inativos"
          checked={formik.values.inactive}
          onClick={(e) => formik.setFieldValue('inactive', e.target.checked)}
        />
      </Styled.Switch>
    </Styled.Grid>
  </>
)

export default FormListTypesOfActivity
