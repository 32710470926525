/* eslint-disable camelcase */
import { convertDateToUTC, formatDateEnv } from 'services/helpers'
import { numberOfDaysForAddExtraActivity } from './constants'

const parserNewLesson = ({ checkedIdList, detailsSchoolClasses }) =>
  detailsSchoolClasses
    .filter((detailClass) => checkedIdList.includes(detailClass.index))
    .map((obj) => ({
      activity: obj.activity,
      lesson_date: obj.lesson_date,
      lesson_minutes: obj.lesson_minutes,
      lesson_number: obj.lesson_number,
      hourly_lesson_cost: obj.hourly_lesson_cost,
      total_hourly_lesson_cost: obj.total_hourly_lesson_cost,
      discipline_name: obj.discipline_name,
      professor_id: obj.professor_id,
      discipline_id: obj.discipline_id,
      courses: obj.courses,
      shift: obj.shift,
      extra_lesson: false,
      checked: true
    }))

const parserProfessors = (data) =>
  data?.map(({ name, id }) => ({ value: id, label: name })) ?? []

const parserClasses = ({ classes, detailsSchoolClasses }) =>
  classes?.map((classe) => ({
    lesson_date: formatDateEnv(classe.lesson_date),
    hourly_lesson_cost: parseFloat(classe.hourly_lesson_cost.replace(',', '.')),
    courses: detailsSchoolClasses[0].courses,
    lesson_minutes: classe.lesson_minutes,
    professor_id: String(classe.professor_id),
    activity: classe.activity,
    extra_lesson: true,
    checked: true
  }))

const canAddExtraClass = (countingEndDate) => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = currentDate.getMonth()
  const countingDate = convertDateToUTC(countingEndDate)

  const startDay = countingDate.getDate()
  const endDay = numberOfDaysForAddExtraActivity

  // Início do dia (00:00:00.000)
  const startDate = new Date(year, month, startDay + 1)
  startDate.setHours(0, 0, 0, 0)

  // Fim do dia (23:59:59.999)
  const endDate = new Date(year, month, startDay + endDay)
  endDate.setHours(23, 59, 59, 999)

  return currentDate >= startDate && currentDate <= endDate
}

export { parserNewLesson, parserProfessors, parserClasses, canAddExtraClass }
