const initialValues = {
  cpf: '',
  student: '',
  category: '',
  type: '',
  dueDate: '',
  schoolClass: '',
  unity: '',
  level: '',
  course: '',
  observation: ''
}

export { initialValues }
