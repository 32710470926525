import { Box, TableRow } from '@mui/material'
import { formatDateTimePtBr } from 'services/helpers'
import * as Styled from './style'

const TableFooterSms = ({ row }) => (
  <TableRow hover tabIndex={-1} key={row.id}>
    <Styled.TableSentAt>
      <Box>{formatDateTimePtBr(row?.sent_at) || ''}</Box>
    </Styled.TableSentAt>

    <Styled.TableCellTitle>
      <Box>{row?.content || ''}</Box>
    </Styled.TableCellTitle>

    <Styled.TableCellPhone>
      <Box>{row?.mobile_phone || ''}</Box>
    </Styled.TableCellPhone>

    <Styled.TableCell>
      <Box>{row?.user || ''}</Box>
    </Styled.TableCell>
  </TableRow>
)

export { TableFooterSms }
