import { httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const createOrUpdatePoleAndUnit = async (payload, type, id) => {
  if (type?.edit) {
    const response = await httpPut(`${ENDPOINT.UNITS}/${id}`, {
      ...payload
    })

    return response
  }

  const endpointPost = type?.pole
    ? ENDPOINT.ADMIN_SECRETARY_CREATE_POLE
    : ENDPOINT.UNITS

  const response = await httpPost(endpointPost, {
    ...payload
  })

  return response
}

export const getResponsible = async (unit) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_RESPONSIBLE}${Number(unit)}`
  )

  return response
}

export const createResponsible = async (payload, unit) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_MANAGEMENT_CREATE_RESPONSIBLE}`,
    {
      id_unit: Number(unit),
      ...payload
    }
  )

  return response
}

export const deleteResponsible = async (payload, unit) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_MANAGEMENT_DELETE_RESPONSIBLE}`,
    {
      id_unit: Number(unit),
      ...payload
    }
  )

  return response
}

export const getPoleOrUnit = async (id) => {
  const response = await httpGet(`${ENDPOINT.UNITS}/${id}`)

  return response
}
