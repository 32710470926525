import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import GlobalContext from 'store/GlobalContext'
import LoadingPage from 'components/molecules/LoadingPage'
import ErrorPage from 'components/pages/Shared/ErrorPage'
import { ROUTE } from 'services/constants'

export default function Route({ route }) {
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useContext(GlobalContext)

  const hasPermission = (role) => {
    if (role instanceof Array)
      return role.some((r) => r.name === user?.role && r.isActive)

    return role?.name === user?.role && role.isActive
  }

  const isLogged = !!user?.token
  const isAuthorized = isLogged && hasPermission(route.permissions)
  const isDenied = isLogged && !hasPermission(route.permissions)
  const isPublic = !route.permissions

  useEffect(() => {
    setTimeout(() => {
      if (isAuthorized || !isLogged || isPublic) setIsLoading(false)
    }, 500)
  }, [isAuthorized, isLogged, isPublic])

  if (isLoading) return <LoadingPage open />
  if (isAuthorized || isPublic) return <route.component />
  if (isDenied) return <ErrorPage path={route.path} />
  return <Navigate to={ROUTE.LOGIN} />
}

Route.propTypes = {
  route: PropTypes.shape({
    component: PropTypes.func.isRequired,
    permissions: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          isActive: PropTypes.bool.isRequired
        })
      ),
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired
      })
    ])
  }).isRequired
}
