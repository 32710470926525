import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ListAlt } from '@mui/icons-material'
import { Box } from '@mui/material'

import { usePaginationBasic, useSnack } from 'services/hooks'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { Button, TextNotFound } from 'components/atoms'

import {
  breadCrumbsItems,
  defaultItemsPerPage,
  getNewActivityMessageAction
} from './constants'
import { createActivities, getActivities, rateActivity } from './services'
import List from './components/List'
import NewActivityModal from './components/NewActivityModal'

import * as Styled from './style'
import RateModal from './components/RateModal'

const AdditionalActivities = () => {
  const snackProps = useSnack()

  const [opemModal, setOpenModal] = useState({
    activity: false,
    rate: false,
    currentItem: null
  })
  const [updateData, setUpdateData] = useState(true)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [opemModalConfirm, setOpemModalConfirm] = useState(false)
  const [countPeerPage, setCountPeerPage] = useState(defaultItemsPerPage)
  const [data, setData] = useState({ activities: [] })
  const [searchParams] = useSearchParams()

  const navigaete = useNavigate()

  const {
    register,
    name,
    course,
    schoolClassCode,
    schoolClassId,
    isLastPageAvaliation
  } = Object.fromEntries([...searchParams])
  const { setSnack } = snackProps
  const { items, page, handleSetPage, totalPage } = usePaginationBasic(
    data?.activities,
    countPeerPage
  )

  const hasResult = !!items?.length
  const isAvailableToAddNewActivities = !!hasResult

  const handleViewPdf = (item) => {
    window.open(item?.uploadedLink, '_blank')
  }

  const handleRateModal = (item) => {
    setOpenModal((prev) => ({ ...prev, rate: true, currentItem: item }))
  }

  const handleNewActivities = () =>
    setOpenModal((prev) => ({ ...prev, activity: true }))

  const onSubmitRate = (payload) => {
    rateActivity({
      setSnack,
      payload,
      callbackSuccess: () => {
        setUpdateData(true)
        setOpenModal((prev) => ({ ...prev, rate: false, currentItem: null }))
      }
    })
  }

  const onSubmitActivity = (payload) => {
    createActivities({
      setSnack,
      payload,
      callbackSuccess: () => {
        setUpdateData(true)
        setOpenModal((prev) => ({ ...prev, activity: false }))
      }
    })
  }

  useEffect(() => {
    if (updateData) {
      getActivities({
        setSnack,
        setData,
        setLoadingOpen,
        register,
        setUpdateData
      })
    }
  }, [page, countPeerPage, updateData])

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loadingOpen}
      snackProps={snackProps}
      onClickButton={() => setOpemModalConfirm(true)}
      breadcrumbsItems={breadCrumbsItems(name, course)}
      content={
        <Styled.Content>
          {isAvailableToAddNewActivities && (
            <Button onclick={() => handleNewActivities()}>
              {getNewActivityMessageAction(isLastPageAvaliation)}
            </Button>
          )}

          {hasResult && (
            <List
              limit={countPeerPage}
              page={page}
              items={items}
              count={data?.count}
              totalPage={totalPage}
              handleSetPage={handleSetPage}
              handleChange={(e) => setCountPeerPage(e.target.value)}
              isLastPageAvaliation={isLastPageAvaliation}
              handleViewPdf={handleViewPdf}
              handleRate={handleRateModal}
            />
          )}

          {!hasResult && (
            <Styled.EmptyState>
              <TextNotFound
                icon={<ListAlt />}
                text="Não encontramos nenhuma solicitação de atividade complementar para este curso."
              />

              <Box width="189px">
                <Button
                  onclick={() =>
                    setOpenModal((prev) => ({ ...prev, activity: true }))
                  }
                >
                  {getNewActivityMessageAction(isLastPageAvaliation)}
                </Button>
              </Box>
            </Styled.EmptyState>
          )}

          <NewActivityModal
            open={opemModal.activity}
            labelHeader="Enviar Documentos"
            handleClose={() =>
              setOpenModal((prev) => ({ ...prev, activity: false }))
            }
            labelContent="Clique aqui para enviar um documento. Somente arquivos em PDF*"
            type="pdf"
            onSubmitForm={onSubmitActivity}
            register={register}
            course={course}
            schoolClassCode={schoolClassCode}
            schoolClassId={schoolClassId}
          />

          {opemModal.rate && opemModal.currentItem && (
            <RateModal
              item={opemModal.currentItem}
              open={opemModal.rate}
              handleClose={() =>
                setOpenModal((prev) => ({
                  ...prev,
                  rate: false,
                  currentItem: null
                }))
              }
              onSubmitForm={(values) =>
                onSubmitRate({ ...opemModal.currentItem, ...values })
              }
            />
          )}
        </Styled.Content>
      }
      open={opemModalConfirm}
      handleConfirmModal={() => navigaete(-1)}
    />
  )
}

export default AdditionalActivities
