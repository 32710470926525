import { ROUTE } from 'services/constants'

const breadcrumbsItems = () => [
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Ocorrências'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES_CREATE,
    label: '<b>Novo</b>'
  }
]

export { breadcrumbsItems }
