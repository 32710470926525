import React, { useEffect, useState } from 'react'
import { TableBody } from '@mui/material'
import { getComparator, stableSort } from 'services/helpers'
import { useCheckedSingleTable } from 'services/hooks'
import { sortAsc, sortDesc } from './constants'
import TableFooter from './TableFooter'
import * as Styled from './style'

const ListSelectEnrollment = ({
  items,
  setSelectRegistrationMerge,
  setIsOpenRegistrationMerge,
  removeUsersListUnifyById,
  selectUser,
  isUnifyStatus
}) => {
  const [order, setOrder] = useState(sortAsc)
  const [orderBy, setOrderBy] = useState(sortDesc)

  const {
    handleClick,
    isSelected,
    selected: selectedItem
  } = useCheckedSingleTable(items, 'register')

  useEffect(() => {
    setSelectRegistrationMerge(selectedItem)
  }, [selectedItem])

  useEffect(() => {
    if (selectUser && selectUser.length) {
      handleClick(selectUser[0])
    }
  }, [selectUser])

  const handleMensages = ({ mainMessage, secondMessage, notFound }) => {
    if (isUnifyStatus && items.length) {
      return mainMessage
    }
    if (!isUnifyStatus && items.length) {
      return secondMessage
    }
    return notFound
  }

  return (
    <>
      <Styled.Text>
        {handleMensages({
          mainMessage: 'Registro unificado',
          secondMessage: 'Selecione o registro a ser mantido',
          notFound: 'Lista de Unificação'
        })}
      </Styled.Text>
      <Styled.Description>
        {handleMensages({
          mainMessage:
            'A partir de agora somente o registro abaixo será utilizado nas operações.',
          secondMessage:
            'Após escolher os registros, selecione aquele que deseja manter para dar sequência ao processo de unificação.',
          notFound:
            'Você não possui registros para unificação. Para encontrá-los, clique no botão abaixo ou volte para tela inicial.'
        })}
      </Styled.Description>
      <TableBody>
        {stableSort(items, getComparator(order, orderBy)).map((row) => {
          const isItemSelected = isSelected(row.register)

          return (
            <TableFooter
              row={row}
              key={row.register}
              setIsOpen={setIsOpenRegistrationMerge}
              selected={selectedItem}
              handleClick={handleClick}
              isItemSelected={isItemSelected}
              setListSelected={setSelectRegistrationMerge}
              removeUsersListUnifyById={removeUsersListUnifyById}
              selectUser={selectUser}
              isUnifyStatus={isUnifyStatus}
            />
          )
        })}
      </TableBody>
    </>
  )
}

export default ListSelectEnrollment
