import PropTypes from 'prop-types'
import {
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography
} from '@mui/material'

import * as Styled from './style'

const AccordionFilter = ({
  title,
  expanded,
  setExpanded,
  nameAccordion,
  variant = 'body1',
  children,
  margin,
  notMarginExpanded,
  arrowLabel,
  arrowMessageTooltip,
  disabled
}) => {
  const openAccordion = expanded === nameAccordion && !disabled

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Styled.Accordion
      $margin={margin}
      expanded={openAccordion}
      onChange={handleChange(nameAccordion)}
      $hasMargin={notMarginExpanded ? false : openAccordion}
    >
      <AccordionSummary
        expandIcon={
          <Tooltip title={arrowMessageTooltip ?? ''}>
            <Styled.ExpandMore disabled={disabled}>
              <Styled.ExpandMoreIcon expanded={expanded} />
              {arrowLabel}
            </Styled.ExpandMore>
          </Tooltip>
        }
        aria-controls={`${nameAccordion}-content`}
        id={`${nameAccordion}-header`}
      >
        <Typography variant={variant}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Styled.Accordion>
  )
}

export default AccordionFilter

AccordionFilter.propTypes = {
  title: PropTypes.string,
  setExpanded: PropTypes.func,
  margin: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nameAccordion: PropTypes.string
}

AccordionFilter.defaultProps = {
  title: '',
  nameAccordion: 'panel1a',
  margin: 'auto'
}
