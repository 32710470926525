import FEEDBACK_SNACK from 'feedBackSnack'
import {
  isEmpty,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import {
  deletePayroll,
  getListPayroll,
  sendPayrollsToSeniorAPI,
  sendUniquePayroll
} from 'services/api/admin'
import { paramsOnSubmit } from './helpers'
import { MESSAGE } from './constants'

const onSubmit = async ({
  values,
  setLoadingOpen,
  filter,
  pageActual,
  setCount,
  setOpenModalConfirm,
  setFormValues,
  setNotSearch,
  setListPayroll
}) => {
  setLoadingOpen(true)
  setOpenModalConfirm(false)

  const { data, error, status } = await getListPayroll(
    paramsOnSubmit({
      filter,
      pageActual,
      values
    })
  )

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListPayroll
    })
  }

  if (isEmpty(data?.data)) {
    setListPayroll([])
    return setNotSearch(false)
  }

  setCount(data?.count)
  setFormValues(values)

  return setListPayroll(data?.data)
}

const deletePayrollItem = async ({
  id,
  setLoadingOpen,
  values,
  filter,
  pageActual,
  setCount,
  setOpenModalConfirm,
  setFormValues,
  setNotSearch,
  setListPayroll
}) => {
  setLoadingOpen(true)

  const { error, status } = await deletePayroll(id)

  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDeleteClassLinked
    })
  }

  toastNotificationSuccess(MESSAGE.DELETE_SUCCESS, 1500)

  return setTimeout(
    () =>
      onSubmit({
        values,
        setLoadingOpen,
        filter,
        pageActual,
        setCount,
        setOpenModalConfirm,
        setFormValues,
        setNotSearch,
        setListPayroll
      }),
    1500
  )
}

const sendPayroll = async ({ payrollId }) => {
  const { data, error, status } = await sendUniquePayroll({ payrollId })

  if (error) {
    updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreatePayroll
    })
  } else {
    toastNotificationSuccess(MESSAGE.SUCCESS_SEND_PAYROLL)
  }

  return { error }
}

const getPayrolls = async ({ filter, currentPage, values }) => {
  const { data, error, status } = await getListPayroll(
    paramsOnSubmit({
      filter,
      pageActual: currentPage,
      values
    })
  )
  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreatePayroll
    })
  }

  return { data, status }
}

const sendPayrollsToPaymentAPI = async ({ payrollIds }) => {
  const { data, error, status } = await sendPayrollsToSeniorAPI({
    payload: { payroll_ids: payrollIds }
  })

  if (error) {
    updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreatePayroll
    })
  }

  return { data, error, status }
}

export {
  onSubmit,
  deletePayrollItem,
  sendPayroll,
  getPayrolls,
  sendPayrollsToPaymentAPI
}
