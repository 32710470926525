import {
  createDisciplines,
  deleteDisciplines,
  getDisciplines,
  getEditListDiscipline,
  getListDisciplinesJoy,
  updateDetailsSchoolClassDiscipline
} from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { SUCCESS } from 'services/constants'
import { MESSAGE, defaultItemsPerPage, defaultPage } from './constants'
import {
  disciplineDelete,
  parseSelectDisciplines,
  parseSelectDisciplinesJoy,
  parseUpdateDisciplines
} from './helpers'

const getEditDiscipline = async (
  {
    id,
    setSnack,
    filter,
    pageActual,
    setCount,
    setFreeDisciplineCodes,
    setIsPagination,
    setEditDisciplineList,
    setLoadingOpen
  },
  isGetingForOtherEnpoint
) => {
  if (!isGetingForOtherEnpoint) setSnack('')

  if (setLoadingOpen) setLoadingOpen(true)

  const paramsValues = {
    limit: filter || defaultItemsPerPage,
    page: pageActual || defaultPage
  }

  const { data, error, status } = await getEditListDiscipline(paramsValues, id)

  if (setLoadingOpen) setLoadingOpen(false)

  setCount(data?.count)
  if (setFreeDisciplineCodes) setFreeDisciplineCodes(data?.free_discipline_codes)
  setIsPagination(true)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListEditDisciplines
    })
  }

  return setEditDisciplineList({
    disciplineFormList: data?.data
  })
}

const deleteDiscipline = async ({
  setSnack,
  setLoadingOpen,
  selected,
  setSelected,
  id,
  filter,
  pageActual,
  setCount,
  setFreeDisciplineCodes,
  setIsPagination,
  setEditDisciplineList,
  setOpenModalDelete
}) => {
  setSnack('')
  setLoadingOpen(true)

  const payload = disciplineDelete({ selected })

  const { error, status } = await deleteDisciplines(payload, id)
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionTDeleteDisciplines
    })
  }

  setSelected([])
  setOpenModalDelete(false)
  setSnack(MESSAGE.SUCCESS_DELETE_DISCIPLINE, SUCCESS)

  return getEditDiscipline(
    {
      id,
      setSnack,
      setLoadingOpen,
      filter,
      pageActual,
      setCount,
      setFreeDisciplineCodes,
      setIsPagination,
      setEditDisciplineList
    },
    true
  )
}

const createDiscipline = async ({
  setSnack,
  values,
  id,
  setLoadingOpen,
  filter,
  pageActual,
  setCount,
  setFreeDisciplineCodes,
  setIsPagination,
  setEditDisciplineList,
  handleClose
}) => {
  setSnack('')
  setLoadingOpen(true)

  const { error, status } = await createDisciplines({ values, id })

  setLoadingOpen(false)
  handleClose()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionTCreateDisciplines
    })
  }

  setSnack(MESSAGE.SUCCESS_CREATE_DISCIPLINE, SUCCESS)

  return getEditDiscipline(
    {
      id,
      setSnack,
      setLoadingOpen,
      filter,
      pageActual,
      setCount,
      setFreeDisciplineCodes,
      setIsPagination,
      setEditDisciplineList
    },
    true
  )
}

const getOptionsDisciplines = async ({
  setSnack,
  setOptionDisciplines,
  id
}) => {
  setSnack('')

  const paramsValues = {
    limit: 3000,
    page: defaultPage
  }

  const { data, error, status } = await getDisciplines(paramsValues)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListOptionsEditDiscipline
    })
  }

  return setOptionDisciplines(parseSelectDisciplines(data))
}

const getDisciplinesJoy = async ({ setSnack, setOptionDisciplinesJoy }) => {
  setSnack('')

  const { data, error, status } = await getListDisciplinesJoy()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListOptionsJoyEditDiscipline
    })
  }

  return setOptionDisciplinesJoy(parseSelectDisciplinesJoy(data))
}

const updateDisciplines = async ({
  id,
  setSnack,
  setLoadingOpen,
  values,
  setEditDisciplineList,
  setCount,
  setFreeDisciplineCodes,
  setIsPagination,
  pageActual,
  filter
}) => {
  setSnack('')
  setLoadingOpen(true)

  const payload = parseUpdateDisciplines(values)

  const { error, status } = await updateDetailsSchoolClassDiscipline(
    id,
    payload
  )

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateDisciplines
    })
  }

  setLoadingOpen(false)

  setSnack(MESSAGE.SUCCESS_UPDATE_DISCIPLINE, SUCCESS)

  return getEditDiscipline(
    {
      id,
      setSnack,
      setLoadingOpen,
      filter,
      pageActual,
      setCount,
      setFreeDisciplineCodes,
      setIsPagination,
      setEditDisciplineList
    },
    true
  )
}

const getAllIntialServices = async ({
  id,
  setSnack,
  setLoadingOpen,
  filter,
  pageActual,
  setCount,
  setFreeDisciplineCodes,
  setIsPagination,
  setEditDisciplineList,
  setOptionDisciplines,
  setOptionDisciplinesJoy
}) => {
  setLoadingOpen(true)

  await getEditDiscipline({
    id,
    setSnack,
    filter,
    pageActual,
    setCount,
    setFreeDisciplineCodes,
    setIsPagination,
    setEditDisciplineList
  })

  await getOptionsDisciplines({
    setSnack,
    setOptionDisciplines,
    id
  })

  await getDisciplinesJoy({
    setSnack,
    setOptionDisciplinesJoy
  })

  return setLoadingOpen(false)
}

export {
  getEditDiscipline,
  getOptionsDisciplines,
  getAllIntialServices,
  deleteDiscipline,
  updateDisciplines,
  createDiscipline
}
