import humps from 'humps'
import FEEDBACK_SNACK from 'feedBackSnack'
import { formatDateEnv, updateErrorMessage } from 'services/helpers'
import { findUnifyDiscounts } from 'services/api/admin'
import { selectAllTypes } from './constants'

const executeFiltersBar = (list, filterValues) => {
  const listAll = [...list]
  const values = { ...filterValues }
  const filterMap = {
    situation: 'status',
    type: 'type',
    switch: 'due_date',
    reason: 'reason'
  }

  if (values.type === selectAllTypes || values.reason === selectAllTypes) {
    Object.assign(values, { type: '', reason: '' })
  }

  const currentDate = new Date()
  const formattedCurrentDate = formatDateEnv(currentDate)
  const filterKeys = Object.keys(values).filter((key) => !!values[key])

  return listAll.filter((item) => {
    let isShow = true

    filterKeys.forEach((key) => {
      const itemKey = filterMap[key]
      const isSwitch = key === 'switch'

      if (!isShow) {
        return false
      }

      if (isSwitch) {
        isShow = item[itemKey] <= formattedCurrentDate
      } else if (item[itemKey] !== values[key]) {
        isShow = false
      }

      return true
    })

    return isShow
  })
}

const getUnifyDiscounts = async ({ onError }) => {
  const { data, error, status } = await findUnifyDiscounts()

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDisciplines
    })
  }

  return humps.camelizeKeys(data)
}

export { executeFiltersBar, getUnifyDiscounts }
