import styled, { css } from 'styled-components'
import { TableRow as MuiTableRow } from '@mui/material'
import {
  NoteAdd,
  AddBox,
  Delete,
  Download as MuiDownload,
  AppRegistration as MuiAppRegistration
} from '@mui/icons-material'

const Wrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(3)} 0;

  tr:hover td {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const WrapperLink = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  button {
    font-size: 14px;
    margin-left: ${({ theme }) => theme.spacing(1)};
    padding: 0;
    text-align: left;
  }
`

const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const TableRow = styled(MuiTableRow)`
  ${({ $isDisabled }) =>
    $isDisabled && ' opacity: 0.5; &:hover { opacity: 1; }'}

  .MuiTableCell-root {
    padding: 10px 15px;
  }

  .actions {
    display: none;
  }

  :hover {
    .actions {
      display: flex;
    }
  }
`

const IconDownload = styled(MuiDownload)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.dark.medium};
    outline: none;
    border: 0px;
  }
`

const Period = styled.div`
  display: flex;
  flex-direction: column;
`
const PeriodDate = styled.div``

const AddIcon = styled(AddBox)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
  }
`

const AddTeacher = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  gap: 5px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  cursor: pointer;
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.textColors.error};
    cursor: pointer;

    ${({ $disabled }) =>
      $disabled &&
      css`
        color: ${({ theme }) => theme.palette.gray.medium};
      `}
  }
`

const NoteAddIcon = styled(NoteAdd)`
  &.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.gray.main};
    cursor: pointer;
  }
`
const AppRegistrationIcon = styled(MuiAppRegistration)`
  &.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.gray.main};
    cursor: pointer;
  }
`

export {
  TableRow,
  Wrapper,
  WrapperLink,
  IconDownload,
  Period,
  PeriodDate,
  AddIcon,
  AddTeacher,
  DeleteIcon,
  NoteAddIcon,
  AppRegistrationIcon,
  Actions
}
