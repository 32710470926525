import { Box, TableRow, Tooltip } from '@mui/material'
import { Delete, StarBorder } from '@mui/icons-material'
import * as Styled from './style'

const TableFooter = ({
  row,
  setIdSkill,
  setOpenModalConfirm,
  setIsDeleteModal
}) => {
  const emptyValue = ''

  return (
    <>
      <TableRow hover tabIndex={-1} key={row?.id}>
        <Styled.TableCellTitle>
          <div>
            {row?.level || emptyValue}

            <Box>
              <Tooltip title="Excluir" placement="top" arrow>
                <Delete
                  onClick={() => {
                    setIdSkill(row?.id)
                    setOpenModalConfirm(true)
                    setIsDeleteModal(true)
                  }}
                />
              </Tooltip>

              <Tooltip
                title={
                  row?.main_skill
                    ? 'Formação principal'
                    : 'Marcar como principal'
                }
                placement="top"
                arrow
              >
                {row?.main_skill ? (
                  <Styled.StarRate />
                ) : (
                  <StarBorder
                    onClick={() => {
                      setIdSkill(row?.id)
                      setOpenModalConfirm(true)
                      setIsDeleteModal(false)
                    }}
                  />
                )}
              </Tooltip>
            </Box>
          </div>
        </Styled.TableCellTitle>

        <Styled.TableCellCourse>
          <Box>{row?.course_name || emptyValue}</Box>
        </Styled.TableCellCourse>

        <Styled.TableCell>
          <Box>{row?.institution_name || emptyValue}</Box>
        </Styled.TableCell>

        <Styled.TableCell>
          <Box>{row?.conclusion_year || emptyValue}</Box>
        </Styled.TableCell>
      </TableRow>
    </>
  )
}

export { TableFooter }
