import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Typography, Unstable_Grid2 as Grid } from '@mui/material'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import GroupingIcon from 'components/atoms/Icons/GroupingIcon'
import { InputSwitch } from 'components/atoms'
import { breadCrumbsItems } from './constants'
import ClassInfo from './components/ClassInfo'
import ReplaceClassDetailsTable from './components/Table'
import {
  ReplaceClassDetailsProvider,
  useReplaceClassDetailsContext
} from './Context'

export default function ReplaceClassDetailsEdit() {
  return (
    <ReplaceClassDetailsProvider>
      <Content />
    </ReplaceClassDetailsProvider>
  )
}

function Content() {
  const {
    classDetails,
    loadClassDetails,
    loadDisciplines,
    replaceForm,
    openCancelModal,
    setOpenCancelModal,
    setShowOnlyInactives,
    showOnlyInactives,
    setProfessorId,
    openModalInactivate,
    onCloseModalInactivate,
    currentOnConfirmInactivate
  } = useReplaceClassDetailsContext()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { name, id, idProfessor } = params

  useEffect(() => {
    if (idProfessor) {
      setProfessorId(+idProfessor)
      loadDisciplines({ id, idProfessor, onlyInactives: showOnlyInactives })
    }
  }, [idProfessor, showOnlyInactives])

  useEffect(() => {
    loadClassDetails(id)
  }, [id])

  return (
    <>
      <TemplateDefaultHeaderByContent
        open={openCancelModal}
        classNameHeader="goBack"
        handleConfirmModal={() => navigate(-1)}
        handleCloseModal={() => setOpenCancelModal(false)}
        breadcrumbsNotLink={breadCrumbsItems({
          name,
          id: classDetails?.code
        })}
        onClickButton={() => {
          navigate(-1)
        }}
        content={
          <Grid container spacing={4}>
            <Grid xs={12}>
              <ClassInfo info={classDetails} />
            </Grid>
            <Grid xs={12}>
              <Typography fontWeight="bold">Substituições</Typography>
              <Typography marginTop={1}>
                <b>Professor substituto</b> {name}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <InputSwitch
                checked={showOnlyInactives}
                label="Inativos"
                onChange={(_, value) => setShowOnlyInactives(value)}
              />
            </Grid>
            <Grid xs={12}>
              <ReplaceClassDetailsTable />
            </Grid>
            <Grid xs={12}>
              <GroupingIcon />
              <Typography variant="caption" ml={1}>
                Em agrupamento
              </Typography>

              <ButtonFooterV2
                size="large"
                labelClose="Cancelar"
                labelConfirm="salvar substituição"
                disabledConfirm={!replaceForm.isValid}
                onClickClose={() => setOpenCancelModal(true)}
                onClickConfirm={() => replaceForm.handleSubmit()}
              />
            </Grid>
          </Grid>
        }
      />
      <ModalConfirm
        open={openModalInactivate}
        icon={<RemoveCircleOutlineIcon color="warning" />}
        title="Inativar"
        message="Deseja inativar essa substituição? Ao inativar, ela não poderá ser reativada e terá que ser criada novamente, caso necessário."
        labelClose="Não"
        labelConfirm="Sim"
        onClickNotConfirm={onCloseModalInactivate}
        onClickConfirm={currentOnConfirmInactivate}
      />
    </>
  )
}
