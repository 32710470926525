import FEEDBACK_SNACK from 'feedBackSnack'
import { isEmpty, updateErrorMessage } from 'services/helpers'
import { SUCCESS } from 'services/constants'
import {
  bindStudents,
  getBind,
  getUnbinded,
  unbindedStudents
} from 'services/api/admin'
import { MESSAGE, defaultItemsPerPage, defaultPage } from './contants'
import { parseBindByUnbinded } from './helpers'

const getListUnbinded = async ({
  classId,
  setSnack,
  setListUnbinded,
  setLoadingOpen,
  page,
  filter,
  setCountUnbinded,
  setTabSelected
}) => {
  if (setSnack) setSnack('')

  if (setLoadingOpen) setLoadingOpen(true)

  const params = {
    limit: filter || defaultItemsPerPage,
    page: page || defaultPage
  }

  const { data, error, status } = await getUnbinded(params, classId)

  if (isEmpty(data?.data)) {
    setTabSelected(0)
  }

  if (setLoadingOpen) setLoadingOpen(false)

  setCountUnbinded(data?.count)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateDisciplines
    })
  }

  return setListUnbinded(parseBindByUnbinded(data))
}

const getListBind = async ({
  classId,
  setSnack,
  setLoadingOpen,
  setListBind,
  page,
  filter,
  setCountBind,
  setTabSelected
}) => {
  if (setSnack) setSnack('')

  if (setLoadingOpen) setLoadingOpen(true)

  const params = {
    limit: filter || defaultItemsPerPage,
    page: page || defaultPage
  }

  const { data, error, status } = await getBind(params, classId)

  if (isEmpty(data?.data)) {
    setTabSelected(0)
  }

  if (setLoadingOpen) setLoadingOpen(false)

  setCountBind(data?.count)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateDisciplines
    })
  }

  return setListBind(parseBindByUnbinded(data))
}

const getAllServices = async ({
  classId,
  setSnack,
  setLoadingOpen,
  setListBind,
  setListUnbinded,
  pageUnbinded,
  pageBind,
  filter,
  setCountUnbinded,
  setCountBind,
  setTabSelected
}) => {
  setLoadingOpen(true)

  await getListUnbinded({
    classId,
    setSnack,
    setListUnbinded,
    page: pageUnbinded,
    filter,
    setCountUnbinded,
    setTabSelected
  })

  await getListBind({
    classId,
    setSnack,
    setListBind,
    page: pageBind,
    filter,
    setCountBind,
    setTabSelected
  })

  return setLoadingOpen(false)
}

const updateSuccessOrErrorMessage = ({
  isUnbinded,
  classId,
  setSnack,
  setLoadingOpen,
  setListBind,
  pageBind,
  setNotBind,
  filter,
  setCountBind,
  setOpenModal,
  setSelected,
  setListUnbinded,
  pageUnbinded,
  setNotUnbinded,
  setCountUnbinded,
  error,
  status,
  setTabSelected
}) => {
  const { UNCROWDED_SUCCESS, EUTOURAGE_SUCCESS } = MESSAGE

  setOpenModal(false)
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateDisciplines
    })
  }

  setSnack(isUnbinded ? UNCROWDED_SUCCESS : EUTOURAGE_SUCCESS, SUCCESS)
  setSelected([])

  return getAllServices({
    classId,
    setSnack,
    setListBind,
    setLoadingOpen,
    setListUnbinded,
    pageUnbinded,
    pageBind,
    filter,
    setCountUnbinded,
    setCountBind,
    setNotUnbinded,
    setNotBind,
    setTabSelected
  })
}

const updateListBindByUnbinded = async ({
  isUnbinded,
  classId,
  setSnack,
  setLoadingOpen,
  setListBind,
  pageBind,
  filter,
  setCountBind,
  items,
  setOpenModal,
  selected,
  setSelected,
  setListUnbinded,
  pageUnbinded,
  setCountUnbinded,
  setTabSelected
}) => {
  setSnack('')
  setLoadingOpen(true)

  const payload = items
    .filter((i) => selected.includes(i.enrollment))
    .map((i) => ({
      enrollment: i.enrollment,
      class_id: Number(classId),
      ...(isUnbinded && { ref_id: Number(i.ref_id) })
    }))

  const { error, status } = await (isUnbinded
    ? bindStudents(payload)
    : unbindedStudents(payload))

  return updateSuccessOrErrorMessage({
    isUnbinded,
    classId,
    setSnack,
    setLoadingOpen,
    setListBind,
    pageBind,
    filter,
    setCountBind,
    setOpenModal,
    setSelected,
    setListUnbinded,
    pageUnbinded,
    setCountUnbinded,
    error,
    status,
    setTabSelected
  })
}

export {
  getAllServices,
  updateListBindByUnbinded,
  getListUnbinded,
  getListBind
}
