import { SUCCESS } from 'services/constants'
import { cleanObject } from 'services/helpers/cleanObject'
import { sendDashStudentEmail } from 'services/api/admin/secretary/schoolClass'
import { MESSAGE } from './constants'

const { default: FEEDBACK_SNACK } = require('feedBackSnack')
const {
  getDetailsDashClass,
  getStudentDashClass,
  getExportReport
} = require('services/api/admin')
const { updateErrorMessage, formatDateEnv } = require('services/helpers')

const getDashClass = async ({
  values,
  classId,
  endDate,
  setSnack,
  startDate,
  setDetailsDash,
  setLoadingVisibility
}) => {
  setSnack('')
  setLoadingVisibility(true)

  const params = cleanObject({
    start_date: formatDateEnv(values.startDate || startDate),
    end_date: formatDateEnv(values.endDate || endDate)
  })

  const { data, error, status } = await getDetailsDashClass(params, classId)
  setLoadingVisibility(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDetailsDashboar
    })
  }

  return setDetailsDash(data)
}

const getListStudent = async ({
  page,
  filter,
  values,
  classId,
  endDate,
  setSnack,
  startDate,
  setListStudents,
  setLoadingVisibility
}) => {
  setSnack('')
  setLoadingVisibility(true)

  const params = cleanObject({
    start_date: formatDateEnv(values.startDate || startDate),
    end_date: formatDateEnv(values.endDate || endDate),
    page,
    limit: filter
  })

  const {
    data: { data },
    error,
    status
  } = await getStudentDashClass(params, classId)
  setLoadingVisibility(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDetailsDashboar
    })
  }

  return setListStudents({
    initial: data,
    filtered: data
  })
}

const handleExport = async ({ row, values, classId, setSnack }) => {
  setSnack('')

  const startDate = formatDateEnv(values.startDate || row?.start_at)
  const endDate = formatDateEnv(values.endDate || row?.end_at)

  const params = cleanObject({
    start_date: startDate,
    end_date: endDate
  })

  const { data, error, status } = await getExportReport(params, classId)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDetailsDashboar
    })
  }

  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
  })

  const url = window.URL.createObjectURL(blob)

  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = `extrato-relatorio-${startDate}-${endDate}.xlsx`
  downloadLink.click()

  URL.revokeObjectURL(url)

  return downloadLink.remove()
}

const onSubmitEmail = async ({
  values,
  listEmail,
  setSnack,
  setIsOpenModalEmail,
  setLoadingVisibility
}) => {
  setSnack('')
  setLoadingVisibility(true)

  const payload = {
    student_emails: listEmail,
    subject: values?.subject,
    text: values?.html_text
  }
  const { error, status } = await sendDashStudentEmail(payload)
  setLoadingVisibility(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToResetPassword
    })
  }
  setIsOpenModalEmail(false)
  return setSnack(MESSAGE.SUCCESS_EMAIL, SUCCESS)
}

const getCardsNumber = ({ detailsDash, theme }) => [
  {
    variant: 'text',
    subTitle: 'Alunos na turma',
    hasColor: theme.palette.primary.main,
    title: detailsDash?.total_students_class,
    hasColorTitle: theme.palette.primary.main,
    disabled: Boolean(!detailsDash?.total_students_class)
  },
  {
    variant: 'text',
    subTitle: 'Alunos com 2 dias de faltas consecutivas',
    hasColor: theme.palette.blue.main,
    title: detailsDash?.student_2_absents,
    hasColorTitle: theme.palette.blue.main,
    disabled: Boolean(!detailsDash?.student_2_absents)
  },
  {
    variant: 'text',
    subTitle: 'Alunos com 5 dias de faltas consecutivas',
    hasColor: theme.palette.primary.warningText,
    title: detailsDash?.student_5_absents,
    hasColorTitle: theme.palette.primary.warningText,
    disabled: Boolean(!detailsDash?.student_5_absents)
  },
  {
    variant: 'text',
    subTitle: 'Alunos com 7 dias de faltas consecutivas',
    hasColor: theme.palette.yellow.warning,
    title: detailsDash?.student_7_absents,
    hasColorTitle: theme.palette.yellow.warning,
    disabled: Boolean(!detailsDash?.student_7_absents)
  },
  {
    variant: 'text',
    subTitle: 'Alunos com 15 dias de faltas consecutivas',
    hasColor: theme.palette.red.light,
    title: detailsDash?.student_15_absents,
    hasColorTitle: theme.palette.red.light,
    disabled: Boolean(!detailsDash?.student_15_absents)
  }
]

const handleFilterListByStatus = ({
  filteredBy,
  detailsDash,
  setListStudents
}) =>
  ({
    [detailsDash?.total_students_class]: () =>
      setListStudents((prev) => ({
        ...prev,
        filtered: prev.initial
      })),
    [detailsDash?.student_2_absents]: () =>
      setListStudents((prev) => ({
        ...prev,
        filtered: prev.initial.filter(
          (turma) => turma.absents.student_2_absents
        )
      })),
    [detailsDash?.student_5_absents]: () =>
      setListStudents((prev) => ({
        ...prev,
        filtered: prev.initial.filter(
          (turma) => turma.absents.student_5_absents
        )
      })),
    [detailsDash?.student_7_absents]: () =>
      setListStudents((prev) => ({
        ...prev,
        filtered: prev.initial.filter(
          (turma) => turma.absents.student_7_absents
        )
      })),
    [detailsDash?.student_15_absents]: () =>
      setListStudents((prev) => ({
        ...prev,
        filtered: prev.initial.filter(
          (turma) => turma.absents.student_15_absents
        )
      }))
  }[filteredBy]())

export {
  getDashClass,
  getListStudent,
  handleExport,
  getCardsNumber,
  onSubmitEmail,
  handleFilterListByStatus
}
