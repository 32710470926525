export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const defaultTotalPage = 1
export const defaultItemsPerPage = 10

const headCellsPending = [
  {
    id: 'discipline',
    label: 'Disciplina'
  },
  {
    id: 'discipline',
    label: 'Módulo'
  },
  {
    id: 'workload_hours',
    label: 'Carga Horária'
  },
  {
    id: 'workload_hours',
    label: 'Professor'
  },
  {
    id: 'permanent_professor_hourly_lesson_cost',
    label: 'Valor'
  }
]

export { headCellsPending }
