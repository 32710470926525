import { SHARED } from './shared'
import { SECRETARY } from './secretary'
import { FINANCIAL } from './financial'
import { MANAGEMENT } from './management'
import { SYSTEM } from './system'
import { COMMERCIAL } from './commercial'
import { HUMAN_RESOURCES } from './humanResources'

export const ADMIN = {
  ...SHARED,
  ...SECRETARY,
  ...FINANCIAL,
  ...COMMERCIAL,
  ...MANAGEMENT,
  ...SYSTEM,
  ...HUMAN_RESOURCES,
  ADMIN_SEARCH_ADVANCED: '/bank/billing/search/advanced',
  ADMIN_SEARCH_SIMPLES: '/bank/billing/search/simple',
  ADMIN_SEARCH_SIMPLES_DOWNLOAD_XML: '/bank/billing/xlsx/download',
  ADMIN_SEARCH_SIMPLES_ADVANCED_UNIT_COURSES: '/units/units-courses/list',
  ADMIN_BANK_ACCOUNTS: '/bank/accounts',
  ADMIN_SECRETARY_CREATE_POLE: '/units/poles',
  ADMIN_COURSE_LEVEL: 'courses/level',
  ADMIN_COURSES_SHIFTS_LIST: 'courses/shifts/list',
  ADMIN_COURSES_LIST: 'courses/list',
  ADMIN_CONTRACT_MODEL: 'finances/contractual-clause',
  ADMIN_LEVEL_MODULE: 'finances/level-module',
  ADMIN_MANAGEMENT_COURSES: 'courses',
  ADMIN_MANAGEMENT_LEVEL_COURSES: 'courses/level-courses',
  ADMIN_MANAGEMENT_CURRICULUM_LEVEL_COURSES: 'courses/distinct',
  ADMIN_MANAGEMENT_DISCIPLINES: 'disciplines',
  ADMIN_MANAGEMENT_JOY: 'disciplines/joy/courses',
  ADMIN_MANAGEMENT_RESPONSIBLE: 'units/signature-responsible/',
  ADMIN_MANAGEMENT_CREATE_RESPONSIBLE: 'units/create-signature-responsible',
  ADMIN_MANAGEMENT_DELETE_RESPONSIBLE: 'units/delete-signature-responsible',
  ADMIN_MANAGEMENT_DOCUMENTS: 'documents',
  ADMIN_MANAGEMENT_OCCURRENCE_TYPES_CATEGORIES: 'occurrences/types/categories',
  ADMIN_MANAGEMENT_OCCURRENCE_TYPES_EVENTS: 'occurrences/types/events',
  ADMIN_MANAGEMENT_OCCURRENCE_TYPES_SECRETARY_TYPES:
    'occurrences/secretary-type',
  ADMIN_MANAGEMENT_LIST_OCCURRENCE_TYPES: 'occurrences/types',
  ADMIN_MANAGEMENT_LIST_TYPES_REQUIREMENT: 'requirements/types',
  ADMIN_MANAGEMENT_INACTIVE_OCCURRENCE_TYPES: 'occurrences/types/inactive',
  ADMIN_MANAGEMENT_ACTIVE_OCCURRENCE_TYPES: 'occurrences/types/active',
  ADMIN_MANAGEMENT_COPY_OCCURRENCE_TYPES: 'occurrences/types/copy',
  ADMIN_MANAGEMENT_CREATE_OCCURRENCE_TYPES: 'occurrences/types',
  ADMIN_MANAGEMENT_INACTIVE_TYPES_REQUIREMENT: 'requirements/types/disable',
  ADMIN_MANAGEMENT_COPY_TYPES_REQUIREMENT: 'requirements/types/copy',
  ADMIN_MANAGEMENT_TYPES_REQUIREMENT: 'requirements/types',
  ADMIN_MANAGEMENT_SECTOR_RESPONSIBLE_TYPES_REQUIREMENT:
    'requirements/types/sector-responsibles',
  ADMIN_MANAGEMENT_SPECIFIC_TYPES_REQUIREMENT: 'requirements/types',
  ADMIN_MANAGER_EXTRACT: 'bank/invoices/extract',
  ADMIN_MANAGER_XLS_CONCILIACAO: 'bank/invoices/conciliate',

  SYSTEM: {
    DOCUMENT_TYPES: {
      CRUD: 'documents',
      LIST: 'documents/paginate'
    },
    ENROLLMENTS: {
      SEARCH: 'enrollments/unify-enrollment',
      UNIFY_ENROLLMENT: 'enrollments/unify-enrollment',
      SIMILARITIES: 'enrollments/unify-enrollment/similarities'
    }
  }
}
