import { httpDelete, httpGet, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getAcademicPerformances = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_ACADEMIC_PERFORMANCE.CRUD}?${convertToParams(params)}`
  )
  return response
}

export const deleteAcademicPerformance = async ({ register, requirementNumber }) => {
  const response = await httpDelete(
    `${ENDPOINT.ADMIN_ACADEMIC_PERFORMANCE.CRUD}/${register}`, { requirement_number: requirementNumber }
  )
  return response
}


export const getAcademicPerformancesDiciplines = async ({ register, classId, requirementNumber }) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_ACADEMIC_PERFORMANCE.DISCIPLINES}/${register}?${convertToParams({ class_id: classId, requirement_number: requirementNumber, is_solicitation: false })}`
  )
  return response
}

export const updateAcademicPerformancesDiciplines = async ({ register, body }) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_ACADEMIC_PERFORMANCE.CRUD}/${register}`,
    body
  )
  return response
}
