const password = 'password'
const tickets = 'tickets'
const requeriment = 'requeriment'
const coursesAndClasses = 'coursesAndClasses'
const occurrences = 'occurrences'
const documents = 'documents'
const registrationData = 'registrationData'

const MESSAGE = {
  SUCCESS_SMS: 'SMS enviado com sucesso!',
  SUCCESS_EMAIL: 'E-mail enviado com sucesso.'
}

const SUCCESS = 'success'

export {
  password,
  tickets,
  requeriment,
  coursesAndClasses,
  occurrences,
  documents,
  registrationData,
  MESSAGE,
  SUCCESS
}
