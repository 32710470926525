import { useCallback, useEffect, useState } from 'react'
import { ListAlt, Search } from '@mui/icons-material'

import { usePaginationBasic, useSnack } from 'services/hooks'
import { defaultlistPerPage } from 'services/hooks/constants'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'

import { initialStateData, initialStateStatus, messages } from './constants'
import { getActivitiesList } from './service'
import { List, SearchForm } from './components'
import { useAdditionalActivities } from './Context/FormContext'

const AdditionalActivitiesAvaliation = () => {
  const [data, setData] = useState(initialStateData)
  const [rowsPerPage, setRowsPerPage] = useState(defaultlistPerPage)
  const [status, setStatus] = useState(initialStateStatus)

  const snackProps = useSnack()

  const { setSnack } = snackProps
  const { formValues, setFormValues } = useAdditionalActivities()
  const { items, totalPage, page, handleSetPage } = usePaginationBasic(
    data?.activities,
    rowsPerPage,
    data?.count
  )

  const noResultMessage = useCallback(() => {
    if (status.empty) return messages.empty.text
    if (status.notFound) return messages.notFound.text

    return null
  }, [JSON.stringify(status)])

  const noResultIcon = useCallback(() => {
    if (status.empty) return <ListAlt />
    if (status.notFoundByUnity || status.notFound) return <Search />

    return null
  }, [JSON.stringify(status)])

  const handleSearch = (values) => {
    const shouldClear = values === null

    if (shouldClear) {
      setData(initialStateData)
      setStatus(initialStateStatus)
    } else {
      setFormValues({ search: values })
    }
  }

  useEffect(() => {
    if (formValues?.search) {
      getActivitiesList({
        setSnack,
        setState: setData,
        search: formValues?.search,
        rowsPerPage,
        currentPage: page,
        setStatus
      })
    }
  }, [JSON.stringify(formValues?.search), page, rowsPerPage])

  const hasResult = !!items?.length

  return (
    <TemplateDefaultFormAndResult
      formHeader={
        <SearchForm handleSearch={handleSearch} setSnack={setSnack} />
      }
      iconInformative={noResultIcon()}
      listItems={items}
      messageInformative={noResultMessage()}
      snackProps={snackProps}
      titleHeader="<strong>Secretaria -</strong> Atividades complementares"
    >
      {!!hasResult && (
        <List
          items={items}
          limit={data?.limit}
          page={page}
          count={data?.count}
          totalPage={totalPage}
          handleSetPage={handleSetPage}
          handleChange={(e) => setRowsPerPage(e.target.value)}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default AdditionalActivitiesAvaliation
