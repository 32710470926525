import styled from 'styled-components'
import { MenuItem as MuiMenuItem } from '@mui/material'

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
`

const AvatarLabel = styled.div`
  display: block;
  float: left;
  margin: 0 1rem;
`

const AvatarName = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
`

const AvatarType = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 300;
`

const Toolbar = styled.div`
  display: flex;
  position: relative;
`

const MenuWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: ${(props) => (props.isVisible ? 'auto' : 0)};
  position: absolute;
  top: 2rem;
  z-index: 1000;
  padding: 1rem 0;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.background.white};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  overflow: hidden;
  & > li {
    width: 100%;
  }
`

const Link = styled.a`
  width: 100%;
  text-decoration: none;
`

const MenuItem = styled(MuiMenuItem)`
  width: 100%;
`

export {
  AvatarWrapper,
  AvatarLabel,
  AvatarName,
  AvatarType,
  Toolbar,
  MenuWrapper,
  Link,
  MenuItem
}
