import { ACL } from 'services/constants/acl'
import { ROUTE } from 'services/constants'
import theme from 'theme/designTokens'
import PDFPoc from 'components/pages/PDF/POC'
import isAllowUserAccess from '../helpers/isAllowUserAccess'

export const iconProps = {
  color: theme.palette.primary.white
}

const PDF_ROUTES = [
  {
    component: PDFPoc,
    exact: true,
    path: ROUTE.ADMIN_PDF_POC,
    permissions: ACL.admin,
    submenu: [],
    isAllowed: () => true
    // isAllowed: (userPermissions) =>
    //   isAllowUserAccess(userPermissions, 'PDF_POC')
  }
]

export default PDF_ROUTES
