import { Delete, Edit, School } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'

const generalMenu = ({
  register,
  handleEdit,
  handleClose,
  toggleModalExclude,
  emitDiploma,
  companyId,
  historicStudentId,
  diplomaId
}) => [
  {
    label: 'Editar',
    icon: <Edit />,
    className: TAG_MANAGER.secretaria_btn_editar_registroExpedicao,
    onClick: () => {
      handleClose()
      handleEdit()
    }
  },
  {
    label: 'Emitir Diploma',
    icon: <School />,
    className: TAG_MANAGER.secretaria_btn_emitir_diplomaExpedicao,
    onClick: () => {
      handleClose()
      emitDiploma({ companyId, register, historicStudentId, diplomaId })
    }
  },
  {
    label: 'Excluir',
    icon: <Delete />,
    className: TAG_MANAGER.secretaria_btn_excluir_registroExpedicao,
    onClick: () => {
      handleClose()
      toggleModalExclude()
    }
  }
]

export { generalMenu }
