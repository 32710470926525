import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useMakeService, usePaginationBasic, useSnack } from 'services/hooks'
import { ModalConfirm } from 'components/molecules'
import { TableEditDisplines } from './components/Table'
import ModalAddDiscipline from './components/ModalAddDiscipline'
import {
  breadCrumbsItems,
  defaultItemsPerPage,
  defaultTotalPage,
  initialValues
} from './constants'
import * as Styled from './style'
import { getAllIntialServices, getEditDiscipline } from './services'

const EditDiscipline = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const [editDisciplineList, setEditDisciplineList] = useState({
    disciplineFormList: []
  })
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [itensPerPage, setItensPerPage] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(null)
  const [freeDisciplineCodes, setFreeDisciplineCodes] = useState([])
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [refreshList, setRefreshList] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [optionDisciplines, setOptionDisciplines] = useState([])
  const [optionDisciplinesJoy, setOptionDisciplinesJoy] = useState([])
  const [openModalAddDiscipline, setOpenModalAddDiscipline] = useState(false)
  const [fields, setFields] = useState(initialValues)
  const [filter, setFilter] = useState(defaultTotalPage)
  const [isPagination, setIsPagination] = useState(false)
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id } = params

  const {
    page: pageActual,
    totalPage,
    setPage,
    items
  } = usePaginationBasic(
    editDisciplineList.disciplineFormList,
    defaultItemsPerPage,
    count
  )

  const paramsMakeService = {
    isModule: true,
    setSnack
  }
  const { modules } = useMakeService(paramsMakeService)

  const onHandleConfirm = () => {
    setOpenModalConfirm(false)
    navigate(`${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS}?id=${id}`)
  }

  useEffect(() => {
    if (isPagination)
      getEditDiscipline({
        id,
        setSnack,
        setLoadingOpen,
        filter,
        pageActual,
        setCount,
        setFreeDisciplineCodes,
        setIsPagination,
        setEditDisciplineList
      })
  }, [pageActual, filter])

  useEffect(() => {
    if (refreshList) {
      getAllIntialServices({
        id,
        setSnack,
        setLoadingOpen,
        filter,
        pageActual,
        setCount,
        setFreeDisciplineCodes,
        setIsPagination,
        setEditDisciplineList,
        setOptionDisciplines,
        setOptionDisciplinesJoy
      })

      setRefreshList(true)
    }
  }, [refreshList])

  useEffect(() => {
    getAllIntialServices({
      id,
      setSnack,
      setLoadingOpen,
      filter,
      pageActual,
      setCount,
      setFreeDisciplineCodes,
      setIsPagination,
      setEditDisciplineList,
      setOptionDisciplines,
      setOptionDisciplinesJoy
    })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      loadingOpen={loadingOpen}
      breadcrumbsItems={breadCrumbsItems(id)}
      classNameHeader="goBack"
      onClickButton={() => setOpenModalConfirm(true)}
      open={openModalConfirm}
      handleCloseModal={() => setOpenModalConfirm(false)}
      handleConfirmModal={() => onHandleConfirm()}
      content={
        <>
          <ModalConfirm
            open={openModal}
            icon={<Styled.IconWarningAmber />}
            title="Cancelar"
            message="Deseja cancelar a operação atual? Você perderá as alterações realizadas."
            size="medium"
            handleClose={() => setOpenModal(false)}
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            onClickNotConfirm={() => setOpenModal(false)}
            onClickConfirm={() => {
              setOpenModal(false)
              setRefreshList(true)
            }}
            colorButtonConfirm="error"
          />

          <ModalAddDiscipline
            id={id}
            modules={modules}
            setSnack={setSnack}
            open={openModalAddDiscipline}
            handleClose={() => setOpenModalAddDiscipline(false)}
            fields={fields}
            setLoadingOpen={setLoadingOpen}
            filter={filter}
            pageActual={pageActual}
            setCount={setCount}
            setFreeDisciplineCodes={setFreeDisciplineCodes}
            setIsPagination={setIsPagination}
            setEditDisciplineList={setEditDisciplineList}
            optionDisciplines={optionDisciplines}
            setFields={setFields}
          />

          <TableEditDisplines
            id={id}
            itemsTable={items}
            totalPage={totalPage}
            pageActual={pageActual}
            setEditDisciplineList={setEditDisciplineList}
            setCount={setCount}
            setIsPagination={setIsPagination}
            setPage={setPage}
            count={count}
            navigate={navigate}
            setSnack={setSnack}
            setLoadingOpen={setLoadingOpen}
            itensPerPage={itensPerPage}
            filter={filter}
            setFilter={setFilter}
            setOpenModalDelete={setOpenModalDelete}
            openModalDelete={openModalDelete}
            setOpenModalAddDiscipline={setOpenModalAddDiscipline}
            openModalConfirm={openModalConfirm}
            setOpenModal={setOpenModal}
            optionDisciplines={optionDisciplines}
            optionDisciplinesJoy={optionDisciplinesJoy}
            setFreeDisciplineCodes={setFreeDisciplineCodes}
            freeDisciplineCodes={freeDisciplineCodes}
          />
        </>
      }
    />
  )
}

export default EditDiscipline
