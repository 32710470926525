import FEEDBACK_SNACK from 'feedBackSnack'
import { updateErrorMessage } from 'services/helpers'
import { SUCCESS } from 'services/constants'
import { FALLBACK_VALUE, MESSAGE } from './constant'

const payload = ({ values, checked }) => ({
  ...(values.record && { occurrence_name: values.record }),
  active: true,
  category: values.category,
  name: values.occurrence,
  events: checked
})

const savedSuccessfully = ({
  isEdit,
  setLoadingOpen,
  setSnack,
  error,
  navigate,
  status
}) => {
  setLoadingOpen(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListOccurrenciesTypes
    })
  }

  setSnack(
    isEdit
      ? MESSAGE.SUCCESS_UPDATED_OCCURRENCE_TYPE
      : MESSAGE.SUCCESS_CREATE_OCCURRENCE_TYPE,
    SUCCESS
  )

  return setTimeout(() => navigate(FALLBACK_VALUE), 3000)
}

export { payload, savedSuccessfully }
