import React, { Suspense } from 'react'
import { ArrowBack, NavigateNext } from '@mui/icons-material'
import { HeaderPage } from 'components/organisms'
import { LoadingPage, ModalConfirm, Snackbar } from 'components/molecules'
import { ToastComponent } from 'components/atoms'
import { textMessageModalConfirm, textTitleModalConfirm } from './constants'
import * as Styled from './style'

const TemplateDefaultHeaderByContent = ({
  loadingOpen,
  snackProps,
  titleHeader,
  labelButtonHeader = 'voltar',
  onClickButton,
  classNameHeader,
  linkHeader,
  content,
  noIconButton,
  breadcrumbsItems,
  breadcrumbsNotLink,
  open,
  infoDownBreadcrumbs,
  handleCloseModal,
  handleConfirmModal,
  tagmanagerModal,
  titleModalConfirm = textTitleModalConfirm,
  messageModalConfirm = textMessageModalConfirm
}) => (
  <Suspense fallback={<LoadingPage open />}>
    <Styled.Content>
      <LoadingPage open={loadingOpen} />

      <HeaderPage
        title={titleHeader}
        labelCallToAction={labelButtonHeader}
        classNameCallToAction={classNameHeader}
        linkCallToAction={linkHeader}
        callToAction={onClickButton}
        breadcrumbsItems={breadcrumbsItems}
        breadcrumbsNotLink={breadcrumbsNotLink}
        separator={<NavigateNext size="small" />}
        infoDownBreadcrumbs={infoDownBreadcrumbs}
        startIcon={!noIconButton && <ArrowBack />}
        divider
      />

      <Styled.BoxForm>{content}</Styled.BoxForm>

      {snackProps && (
        <Snackbar
          message={snackProps.snack.message}
          severity={snackProps.snack.severity}
          shouldOpen={snackProps.snackOpen}
        />
      )}

      <ModalConfirm
        open={open}
        size="medium"
        handleClose={handleCloseModal}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={handleCloseModal}
        onClickConfirm={handleConfirmModal}
        colorButtonConfirm="error"
        iconWarningAmber
        title={titleModalConfirm}
        message={messageModalConfirm}
        classNameBtnConfirm={tagmanagerModal}
      />

      <ToastComponent />
    </Styled.Content>
  </Suspense>
)

export default TemplateDefaultHeaderByContent
