import { useState } from 'react'
import { typeMask } from 'services/helpers'
import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { InputCheckbox, Button } from 'components/atoms'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import { disabledButton } from './helpers'
import * as Styled from './style'
import CardDiscipline from '../CardDiscipline'
import InputInternshipHours from '../InputInternshipHours'
import { updateAcademicPerformance } from '../../services'
import ModalFeedback from '../ModalFeedback'

const FormEditAcademicPerformance = ({
  formValues,
  setSnack,
  setLoadingOpen,
  setOpenModalConfirm,
  student,
  listStatus,
  classId,
  requirementNumber,
  setAcademicPerformances,
  isEditable,
  academicPerformances
}) => {
  const [showConfirmFeedback, setShowConfirmFeedback] = useState(false)
  const { course } = academicPerformances
  return (
    <>
      <Formik
        initialValues={formValues}
        onSubmit={(values) =>
          updateAcademicPerformance({
            register: student.register,
            classId,
            requirementNumber,
            setSnack,
            setLoadingOpen,
            values,
            setAcademicPerformances
          })
        }
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, dirty }) => (
          <>
            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
              {values.academicPerformances.length > 0 && (
                <>
                  <Styled.Box $justifyContent="space-between">
                    <Typography variant="h6">
                      Disciplinas para aproveitamento
                    </Typography>
                    <Typography variant="caption" sx={{ marginTop: '8px' }}>
                      Preencha os campos abaixo para realizar a análise e o
                      parecer das disciplinas solicitadas para aproveitamento
                    </Typography>
                  </Styled.Box>

                  <Styled.Box $justifyContent="space-between">
                    <Styled.Checkbox>
                      <InputCheckbox
                        label="Enviar e-mail ao aluno"
                        checked={values.isSendEmail}
                        handleChange={(e) =>
                          setFieldValue('isSendEmail', e.target.checked)
                        }
                      />
                    </Styled.Checkbox>

                    {isEditable && (
                      <Button
                        size="medium"
                        value="Criar parecer final"
                        onClick={() => setShowConfirmFeedback(true)}
                        className={TAG_MANAGER.secretary_btn_create_feedback_academic_performance}
                      />
                    )}
                  </Styled.Box>

                  {!!course?.internship && (
                    <CardDiscipline name="Estágio obrigatório" workload={course.internship_hours}>
                      <Grid container spacing={{ xs: 0, sm: 2 }}>
                        <Grid item xs={12} sm={12} md={6}>
                          <Styled.Checkbox>
                            <InputCheckbox
                              label="Estágio inserido na Matriz Curricular do curso"
                              checked={values.hasInternship}
                              handleChange={(e) =>
                                setFieldValue('hasInternship', e.target.checked)
                              }
                            />
                          </Styled.Checkbox>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <InputInternshipHours
                            value={values.internshipHours || ''}
                            onChange={(e) =>
                              setFieldValue(
                                'internshipHours',
                                typeMask('NUMBER', e.target.value)
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </CardDiscipline>
                  )}

                  {values.academicPerformances.map(
                    (academicPerformance, index) => (
                      <CardDiscipline
                        name={academicPerformance.discipline}
                        workload={academicPerformance.disciplineWorkload}
                        key={academicPerformance.discipline}
                      >
                        <Grid container spacing={{ xs: 0, sm: 2 }}>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              label="Nome da disciplina de origem"
                              fullWidth
                              value={academicPerformance.disciplineOrigin ?? ''}
                              onChange={(e) =>
                                setFieldValue(
                                  `academicPerformances.${index}.disciplineOrigin`,
                                  e.target.value
                                )
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              label="Instituição"
                              fullWidth
                              value={academicPerformance.institution ?? ''}
                              onChange={(e) =>
                                setFieldValue(
                                  `academicPerformances.${index}.institution`,
                                  e.target.value
                                )
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={3}>
                            <TextField
                              label="Ano de Conclusão"
                              fullWidth
                              value={academicPerformance.conclusion ?? ''}
                              onChange={(e) =>
                                setFieldValue(
                                  `academicPerformances.${index}.conclusion`,
                                  e.target.value
                                )
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={3}>
                            <TextField
                              label="Avaliação/Aplicador"
                              fullWidth
                              value={academicPerformance.applier ?? ''}
                              onChange={(e) =>
                                setFieldValue(
                                  `academicPerformances.${index}.applier`,
                                  e.target.value
                                )
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={3}>
                            <TextField
                              label="Nota"
                              fullWidth
                              value={academicPerformance.score || ''}
                              onChange={(e) =>
                                setFieldValue(
                                  `academicPerformances.${index}.score`,
                                  e.target.value
                                )
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={3}>
                            <Select
                              label="Situação"
                              value={academicPerformance.status ?? ''}
                              optionsList={listStatus}
                              onChange={(e) =>
                                setFieldValue(
                                  `academicPerformances.${index}.status`,
                                  e.target.value
                                )
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              id={`observation.${index}`}
                              label="Observação"
                              fullWidth
                              value={academicPerformance.observation ?? ''}
                              onChange={(e) =>
                                setFieldValue(
                                  `academicPerformances.${index}.observation`,
                                  e.target.value
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </CardDiscipline>
                    )
                  )}

                  <Grid container spacing={{ xs: 0, sm: 2 }}>
                    <Styled.Box>
                      <ButtonFooterV2
                        labelClose="Cancelar"
                        labelConfirm="Salvar"
                        size="medium"
                        disabled={disabledButton({ dirty, isEditable })}
                        onClickClose={() => setOpenModalConfirm(true)}
                        classNameCancel={TAG_MANAGER.secretary_btn_cancel_edit_academic_performance}
                        classNameConfirm={TAG_MANAGER.secretary_btn_confirm_save_edit_academic_performance}
                      />
                    </Styled.Box>
                  </Grid>
                </>
              )}
            </Form>

            <ModalFeedback
              open={showConfirmFeedback}
              setShowConfirmFeedback={setShowConfirmFeedback}
              values={values}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              student={student}
            />
          </>
        )}
      </Formik>

      <Styled.Divider />
    </>
  )
}

export { FormEditAcademicPerformance }
