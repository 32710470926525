import { Grid } from '@mui/material'
import { Close } from '@mui/icons-material'
import { DatePicker } from 'components/atoms'
import { ButtonFooterV2 } from 'components/molecules'
import * as Styled from './style'

const FormSearchLogs = ({ formik, handleClean, setIsButtonSet }) => (
  <Styled.Paper>
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Grid item xs={12} sm={12} md={3}>
          <DatePicker
            fullWidth
            id="begin_at"
            label="Período inicial*"
            value={formik.values?.begin_at || null}
            onChange={(newValue) => formik.setFieldValue('begin_at', newValue)}
            helperText={
              formik.errors && formik.touched.begin_at && formik.errors.begin_at
            }
            error={Boolean(formik.touched.begin_at && formik.errors.begin_at)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <DatePicker
            fullWidth
            id="end_at"
            label="Período final*"
            value={formik.values?.end_at || null}
            onChange={(newValue) => formik.setFieldValue('end_at', newValue)}
            helperText={
              formik.errors && formik.touched.end_at && formik.errors.end_at
            }
            error={Boolean(formik.touched.end_at && formik.errors.end_at)}
          />
        </Grid>

        <Styled.GridButton item xs={12} sm={12} md={6}>
          <ButtonFooterV2
            labelClose="Limpar"
            labelConfirm="Buscar"
            size="large"
            startIcon={<Close />}
            onClickClose={() => handleClean({ resetForm: formik.resetForm })}
            onClickConfirm={() => setIsButtonSet(true)}
            disabled={!formik.dirty}
          />
        </Styled.GridButton>
      </Grid>
    </form>
  </Styled.Paper>
)

export { FormSearchLogs }
