import { ROUTE } from 'services/constants'

const basePath = ROUTE.ADMIN_MANAGEMENT_COURSE

const breadcrumbsPath = [
  {
    path: ROUTE.ADMIN_MANAGEMENT_COURSE,
    label: 'Gestão'
  },
  {
    path: ROUTE.ADMIN_MANAGEMENT_COURSE,
    label: 'Cursos'
  }
]

const breadcrumbsItems = (isEdit, course) => [
  ...breadcrumbsPath,
  {
    path: '',
    label: `<b>${isEdit ? course : 'Novo Curso'}</b>`
  }
]
export { breadcrumbsItems, basePath }
