import { emptyValue } from './constants'

const emptyCodMec = ''
const technicalCourse = 'Curso Técnico'
const technicalSpecialization = 'Especialização Técnica'

const disabledButton = (values) => {
  const requiredFields = new Set(['course', 'level', 'theoreticalTime'])

  if ([technicalCourse, technicalSpecialization].includes(values.level)) {
    requiredFields.add('technologicalAxis')
    requiredFields.add('ownership')
  }

  const hasMissingFields = Array.from(requiredFields).some(
    (field) => !values[field]
  )

  return hasMissingFields
}

const response = (data) => ({
  unity: data.unit,
  course: data.name,
  pole: data.pole,
  ownership: data.title,
  mec: String(data.cod_mec),
  resolutionAuthorization: data.resolution,
  level: data.level,
  technologicalAxis: data.technological_axis,
  practicalTime: String(
    data.practical_workload === null || data.practical_workload === emptyValue
      ? ''
      : data.practical_workload
  ),
  theoreticalTime: String(
    data.theoretical_workload === null ||
      data.theoretical_workload === emptyValue
      ? ''
      : data.theoretical_workload
  ),
  minimumAge: String(
    data.minimum_age === null || data.minimum_age === emptyValue
      ? ''
      : data.minimum_age
  ),
  complementaryActivity: String(
    data.complementary_activities_hours === null ||
      data.complementary_activities_hours === emptyValue
      ? ''
      : data.complementary_activities_hours
  ),
  internship: String(data.internship_hours),
  active: !data.inactive,
  dontSumPracticalWorkload: !!data.dont_sum_practical_workload
})

const payload = ({
  active,
  course,
  unity,
  level,
  pole,
  practicalTime,
  theoreticalTime,
  mec,
  ownership,
  technologicalAxis,
  resolutionAuthorization,
  internship,
  complementaryActivity,
  minimumAge,
  dontSumPracticalWorkload
}) => ({
  inactive: !active,
  ...(pole && { pole }),
  ...(technologicalAxis && { technological_axis: technologicalAxis }),
  ...(mec !== emptyCodMec && { cod_mec: Number(mec) }),
  ...(resolutionAuthorization && { resolution: resolutionAuthorization }),
  ...(practicalTime && { practical_workload: Number(practicalTime) }),
  level,
  internship: !!internship,
  name: course,
  unit: unity,
  title: ownership,
  theoretical_workload: theoreticalTime ? Number(theoreticalTime) : emptyValue,
  internship_hours: internship ? Number(internship) : emptyValue,
  complementary_activities_hours: complementaryActivity
    ? Number(complementaryActivity)
    : emptyValue,
  minimum_age: minimumAge ? Number(minimumAge) : emptyValue,
  dont_sum_practical_workload: dontSumPracticalWorkload
})

export { response, disabledButton, payload }
