import * as yup from 'yup'

const schema = yup.object().shape({
  level: yup.string().required('O nível é obrigatório.'),
  course_name: yup.string().required('O curso é obrigatório.'),
  institution_name: yup
    .string()
    .required('O nome da instituição é obrigatório.'),
  conclusion_year: yup
    .string()
    .matches(/^[0-9]+$/, 'Somente números são permitidas neste campo.')
    .required('O ano de conclusão é obrigatório.')
})

export { schema }
