import FEEDBACK_SNACK from 'feedBackSnack'
import {
  putExpedition,
  postExpedition,
  getStudentIncomeTax,
  getDetailsExpedition,
  getCourseStudentExpedition
} from 'services/api/admin'
import { ROUTE } from 'services/constants'
import {
  convertDateToUTC,
  formatDateEnv,
  formatDateIso,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'
import { MESSAGE } from './constants'

const handleSelectDocuments = async ({
  register,
  values,
  setLoading,
  setSnack,
  setListIncomeTax
}) => {
  setSnack('', '')
  setLoading(true)

  const { data, error, status } = await getStudentIncomeTax({
    register,
    year: values?.year
  })

  setLoading(false)
  if (error) {
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
    })
    return
  }

  setListIncomeTax(data)
}

const handleSearch = async ({
  diplomaId,
  setFieldValue,
  setSnack,
  setLoading
}) => {
  setLoading(true)

  const { data, error, status } = await getDetailsExpedition(diplomaId)

  setLoading(false)
  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListExpedition
    })
  }

  Object.keys(data).forEach((key) => setFieldValue(key, data[key]))
  setFieldValue(
    'sistec_date',
    data.sistec_date && convertDateToUTC(data.sistec_date)
  )
  setFieldValue(
    'completion_date',
    data.completion_date && convertDateToUTC(data.completion_date)
  )
  setFieldValue(
    'registration_date',
    data.registration_date && convertDateToUTC(data.registration_date)
  )
  setFieldValue(
    'sistec_register_date',
    data.sistec_register_date && convertDateToUTC(data.sistec_register_date)
  )
  return true
}

const getCourseList = async ({ setSnack, setCourseList, register }) => {
  const { data, error, status } = await getCourseStudentExpedition(register)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status
    })
  }

  const listFormatted = data.map((item) => ({
    value: item.course_id,
    label: item.course
  }))

  return setCourseList(listFormatted)
}

const createExpedition = async ({
  values,
  register,
  setSnack,
  navigate,
  setLoading
}) => {
  setSnack('')
  setLoading(true)
  const body = cleanObject({
    sistec: values.sistec,
    course_id: values.course_id,
    register: parseFloat(register),
    sistec_date: values.sistec_date,
    book_number: values.book_number,
    page_number: values.page_number,
    completion_date: values.completion_date,
    registration_number: values.registration_number,
    sistec_register_date: values.sistec_register_date,
    registration_date: values.registration_date
  })
  const { error, status } = await postExpedition(body)

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateExpedition
    })
  }
  toastNotificationSuccess(MESSAGE.SUCCESS, 1500)

  return setTimeout(() => navigate(-1), 1500)
}

const editExpedition = async ({
  values,
  register,
  setSnack,
  navigate,
  diplomaId,
  setLoading
}) => {
  setSnack('')
  setLoading(true)
  const body = cleanObject({
    sistec: values.sistec,
    course_id: values.course_id,
    register: parseFloat(register),
    book_number: values.book_number,
    page_number: values.page_number,
    sistec_date: values.sistec_date ? formatDateEnv(values.sistec_date) : '',
    registration_number: values.registration_number,
    registration_date: values.registration_date,
    completion_date: values.completion_date
      ? formatDateEnv(values.completion_date)
      : '',
    sistec_register_date: values.sistec_register_date
      ? formatDateEnv(formatDateIso(values.sistec_register_date))
      : ''
  })

  const { error, status } = await putExpedition(body, diplomaId)

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditExpedition
    })
  }
  toastNotificationSuccess(MESSAGE.SUCCESS, 1500)

  return setTimeout(() => navigate(-1), 1500)
}

export {
  handleSearch,
  getCourseList,
  editExpedition,
  createExpedition,
  handleSelectDocuments
}
