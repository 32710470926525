const headerData = ({ inactives }) => [
  { id: 'disciplineName', label: 'Disciplina', disabled: false },
  { id: 'module', label: 'Módulo', disabled: false },
  { id: 'disciplineWorkload', label: 'Carga horária', disabled: false },
  { id: 'hourlyLessonCost', label: 'Valor', disabled: false },
  { id: 'createdAt', label: 'Criação de vínculo', disabled: false },
  ...(inactives
    ? [
        { id: 'deletedAt', label: 'Inativação', disabled: false },
        {
          id: 'professor',
          label: 'Professor atual',
          disabled: true
        }
      ]
    : [])
]

export { headerData }
