const groupingMock = Array(10).fill({
  disciplineId: 1,
  register: 30115182,
  name: 'Curso Técnico em Estética/Procedimentos Estéticos Corporais/Manhã [11178]',
  discipline: 'Procedimentos Estéticos Corporais',
  periodStart: '21/09/2022',
  periodEnd: '21/10/2022',
  activeStudents: 10,
  inactiveStudents: 8,
  teacher: 'Sara Baptista de Oliveira',
  teacherId: 690024368,
  id: Math.random()
})

const situationsGroupingMock = [
  {
    label: 'Em Andamento',
    value: 'in_progress'
  },
  {
    label: 'Próximos',
    value: 'upcoming'
  },
  {
    label: 'Encerrados',
    value: 'closed'
  }
]

const groupingDisciplineMock = [
  {
    id: 1,
    register: 30115182,
    name: '44775 - Instrumentação Cirúrgica',
    group: '2022 - AGOSTO',
    unit: '(SP) Diadema',
    course: 'Técnico em Estética',
    schoolClassId: '64864',
    schoolClass: 'DIA224TENF',
    shift: 'Semanal - Manhã',
    activeStudents: 10,
    inactiveStudents: 8
  },
  {
    id: 2,
    register: 30115182,
    name: '44775 - Instrumentação Cirúrgica',
    group: '2022 - AGOSTO',
    unit: '(SP) Diadema',
    course: 'Técnico em Estética',
    schoolClassId: '64864',
    schoolClass: 'DIA224TENF',
    shift: 'Semanal - Manhã',
    activeStudents: 10,
    inactiveStudents: 8
  },
  {
    id: 3,
    register: 30115182,
    name: '44775 - Instrumentação Cirúrgica',
    group: '2022 - AGOSTO',
    unit: '(SP) Diadema',
    course: 'Técnico em Estética',
    schoolClassId: '64864',
    schoolClass: 'DIA224TENF',
    shift: 'Semanal - Manhã',
    activeStudents: 10,
    inactiveStudents: 8
  }
]

export { groupingMock, situationsGroupingMock, groupingDisciplineMock }
