import { useEffect, useState } from 'react'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  getBanckAccount,
  getContractModel,
  getGroup,
  getLevel,
  getPeriod,
  getUnits,
  getModules,
  getCourses,
  getEvaluations,
  getModalities
} from 'services/api/shared'
import { displayTextWeekly, updateErrorMessage } from 'services/helpers'

const useMakeService = ({
  isUnits,
  isGroup,
  isLevel,
  isPeriod,
  isModalities,
  unitsValue = false,
  coursesValue = false,
  levelsValue = false,
  periodValue = false,
  hasLabelSemanal = false,
  modalitiesValue = false,
  evaluationsValue = false,
  isAccount,
  isContractModel,
  isEvaluations,
  isModule,
  isCourse = {
    selectedLevel: false,
    unit_id: null,
    level: ''
  }
}) => {
  const [unitys, setUnitys] = useState([])
  const [evaluations, setEvaluations] = useState([])
  const [modalities, setModalities] = useState([])
  const [group, setGroup] = useState([])
  const [level, setLevel] = useState([])
  const [course, setCourse] = useState([])
  const [period, setPeriod] = useState([])
  const [accounts, setAccounts] = useState([])
  const [contractModel, setContractModel] = useState([])
  const [modules, setModules] = useState([])

  const fetchData = async (
    endpoint,
    setState,
    dataProcessor,
    hasDataIntoData,
    feedbackMessage
  ) => {
    const { data, error, status } = await endpoint

    if (error) {
      return updateErrorMessage({
        error,
        status,
        feedbackMessage
      })
    }

    return setState(dataProcessor(hasDataIntoData ? data?.data : data))
  }

  const processUnits = (data) =>
    data?.data?.reduce((acc, cur) => {
      const { unidade, id_unidade: idUnidade, has_polo: polo } = cur

      const newItem = unitsValue
        ? { id: idUnidade, value: idUnidade, label: unidade, polo }
        : { id: idUnidade, value: unidade, label: unidade, polo }
      return [...acc, newItem]
    }, [])

  const processGroup = (data) => {
    const newList = [{ name: 'Grupo Automático' }, ...data]

    return newList?.reduce((acc, cur) => {
      const { name } = cur
      return [...acc, { value: name, label: name }]
    }, [])
  }

  const processLevels = (data) =>
    data?.data?.reduce((acc, cur) => {
      const { name, id: idLevels } = cur
      const newItem = {
        id: idLevels,
        value: levelsValue ? idLevels : name,
        label: name
      }
      return [...acc, newItem]
    }, [])

  const processPeriods = (data) =>
    data?.reduce((acc, cur) => {
      const { name, id } = cur
      const newItem = periodValue
        ? { value: id, label: displayTextWeekly(name) }
        : {
            value: name,
            label: hasLabelSemanal ? name : displayTextWeekly(name)
          }
      return [...acc, newItem]
    }, [])

  const processAccount = (data) =>
    data?.data?.map(({ ref_account: value, name: label }) => ({
      value,
      label
    })) || []

  const processCourse = (data) =>
    data?.reduce((acc, cur) => {
      const { id, name } = cur
      const newItem = coursesValue
        ? { id, value: name, label: name }
        : { value: name, label: name }
      return [...acc, newItem]
    }, [])

  const processEvaluation = (data = []) =>
    data?.reduce((acc, cur) => {
      const { name, id } = cur
      const newItem = evaluationsValue
        ? { value: id, label: name }
        : { value: name, label: name }
      return [...acc, newItem]
    }, [])

  const processModalities = (data = []) =>
    data?.reduce((acc, cur) => {
      const { name, id } = cur
      const newItem = modalitiesValue
        ? { value: id, label: name }
        : { value: name, label: name }
      return [...acc, newItem]
    }, [])

  const processContractModel = (data = []) =>
    data.map(({ name }) => ({ value: name, label: name }))

  const processModules = (data) =>
    data?.map(({ module_number: value, name: label }) => ({
      value,
      label
    })) || []

  useEffect(() => {
    if (isCourse.selectedLevel) {
      const hasDataIntoData = true

      fetchData(
        getCourses({
          level: isCourse.level,
          unit_id: isCourse.unit_id
        }),
        setCourse,
        processCourse,
        hasDataIntoData,
        FEEDBACK_SNACK.noAccessPermissionCourse
      )
    }
  }, [isCourse.selectedLevel, isCourse.unit_id, isCourse.level])

  useEffect(() => {
    if (isEvaluations) {
      const hasDataIntoData = true
      fetchData(
        getEvaluations(),
        setEvaluations,
        processEvaluation,
        hasDataIntoData,
        FEEDBACK_SNACK.noAccessPermissionEvaluations
      )
    }
  }, [isEvaluations])

  useEffect(() => {
    if (isModalities) {
      const hasDataIntoData = true
      fetchData(
        getModalities(),
        setModalities,
        processModalities,
        hasDataIntoData,
        FEEDBACK_SNACK.noAccessPermissionModalities
      )
    }
  }, [isModalities])

  useEffect(() => {
    if (isUnits) {
      const hasDataIntoData = false
      fetchData(
        getUnits(),
        setUnitys,
        processUnits,
        hasDataIntoData,
        FEEDBACK_SNACK.noAccessPermissionToUnits
      )
    }
  }, [isUnits])

  useEffect(() => {
    if (isGroup) {
      const hasDataIntoData = false
      fetchData(
        getGroup(),
        setGroup,
        processGroup,
        hasDataIntoData,
        FEEDBACK_SNACK.noAccessPermissionToGroup
      )
    }
  }, [isGroup])

  useEffect(() => {
    if (isLevel) {
      const hasDataIntoData = false
      fetchData(
        getLevel(),
        setLevel,
        processLevels,
        hasDataIntoData,
        FEEDBACK_SNACK.noAccessPermissionToLevel
      )
    }
  }, [isLevel])

  useEffect(() => {
    if (isPeriod) {
      const hasDataIntoData = false
      fetchData(
        getPeriod(),
        setPeriod,
        processPeriods,
        hasDataIntoData,
        FEEDBACK_SNACK.noAccessPermissionToShifts
      )
    }
  }, [isPeriod])

  useEffect(() => {
    if (isAccount) {
      const hasDataIntoData = false
      fetchData(
        getBanckAccount(),
        setAccounts,
        processAccount,
        hasDataIntoData,
        FEEDBACK_SNACK.noAccessPermissionToAccount
      )
    }
  }, [isAccount])

  useEffect(() => {
    if (isContractModel) {
      const hasDataIntoData = false
      fetchData(
        getContractModel(),
        setContractModel,
        processContractModel,
        hasDataIntoData,
        FEEDBACK_SNACK.noAccessPermissionToContractModel
      )
    }
  }, [isContractModel])

  useEffect(() => {
    if (isModule) {
      const hasDataIntoData = false
      fetchData(
        getModules(),
        setModules,
        processModules,
        hasDataIntoData,
        FEEDBACK_SNACK.noAccessPermissionToModules
      )
    }
  }, [isModule])

  return {
    unitys,
    group,
    course,
    modalities,
    level,
    evaluations,
    period,
    accounts,
    contractModel,
    modules
  }
}

export default useMakeService
