import { Checkbox, TableRow } from '@mui/material'
import { formatDate, parserMinutesForHours, typeMask } from 'services/helpers'
import * as Styled from './style'
import { emptyValue, emptyValueMoney } from '../constants'

const TableFooter = ({ row }) => (
  <TableRow hover tabIndex={-1}>
    <Styled.TableCell>
      {formatDate(row?.lesson_date) || emptyValue}
    </Styled.TableCell>

    <Styled.TableCell>{row?.day_of_week || emptyValue}</Styled.TableCell>

    <Styled.TableCell>
      {parserMinutesForHours(row?.lesson_minutes) || emptyValue}
    </Styled.TableCell>

    <Styled.TableCell>
      {typeMask('MONEY_NUMBER', row?.total_lessons_cost) || emptyValueMoney}
    </Styled.TableCell>

    <Styled.TableCellNumberLessons>
      <div>{row?.lesson_number || emptyValue}</div>
    </Styled.TableCellNumberLessons>

    <Styled.TableCell>
      {row?.discipline_name || emptyValue}{' '}
      {!!row?.has_grouping && <Styled.Box>A</Styled.Box>}
    </Styled.TableCell>

    <Styled.TableCell>
      {row?.school_classes_code || emptyValue}
    </Styled.TableCell>

    <Styled.TableCell>{row?.courses || emptyValue}</Styled.TableCell>

    <Styled.TableCell>{row?.shift || emptyValue}</Styled.TableCell>

    <Styled.TableCell>{row?.lesson_type || emptyValue}</Styled.TableCell>
  </TableRow>
)

export { TableFooter }
