import { IsEmpty } from 'services/helpers'

/* eslint-disable camelcase */
const deseableNotConfirm = (values, fields) => {
  const verifyFields = fields?.map(
    ({
      discipline,
      code,
      avaliation_type,
      module_number,
      workload_hours,
      workload_minutes
    }) =>
      discipline !== '' &&
      code !== '' &&
      avaliation_type !== '' &&
      module_number !== '' &&
      workload_hours !== '' &&
      workload_minutes !== ''
  )

  return (
    !values.level ||
    !values.course ||
    !values.yearGrid ||
    IsEmpty(fields) ||
    verifyFields?.includes(false)
  )
}

const parserSubjects = (response) =>
  response?.reduce((acc, cur) => {
    const {
      active,
      discipline,
      workload_hours,
      workload_minutes,
      discipline_code,
      avaliation_type,
      module_number,
      reference_code
    } = cur

    const newItem = {
      discipline,
      avaliation_type,
      workload_hours,
      workload_minutes,
      code: discipline_code,
      module_number: Number(module_number),
      active: Boolean(active),
      reference_code
    }
    return [...acc, newItem]
  }, [])

const parserSubjectsTwo = (response, subject) =>
  response?.reduce((acc, cur) => {
    const {
      active,
      id,
      code,
      discipline,
      workload_hours,
      workload_minutes,
      avaliation_type,
      module_number,
      reference_code
    } = cur

    const disciplineValue = subject.find((item) => item.code === code)

    const newItem = {
      ...(id && { id }),
      discipline: disciplineValue?.discipline || discipline,
      code,
      avaliation_type,
      workload_hours: Number(workload_hours),
      workload_minutes: Number(workload_minutes),
      module_number: Number(module_number),
      active: Boolean(active),
      reference_code
    }
    return [...acc, newItem]
  }, [])

const payload = ({ values, fields, subject, hasYearGrid }) => ({
  ...(hasYearGrid && {
    year_grid: values?.yearGrid,
    level: values?.level,
    course: values?.course
  }),
  resolution: values?.resolution,
  complement: values?.complement,
  disciplines: parserSubjectsTwo(fields, subject)
})

export { payload, parserSubjects, parserSubjectsTwo, deseableNotConfirm }
