const fieldsNameForm = {
  studentData: 'studentData',
  affiliation: 'affiliation',
  schoolData: 'schoolData',
  financialOfficer: 'financialOfficer',
  fieldNameListResponsibles: 'financialOfficerResponsibles',
  fieldNameResponsibleEdit: 'financialOfficerResponsibleEdit'
}

const TYPE_RESPONSIBLES = {
  STUDENT: 'student',
  NATURAL_PERSON: 'natural_person',
  LEGAL_PERSON: 'legal_person'
}

const listTypeOfResponsible = [
  {
    label: 'O aluno é o responsável financeiro',
    fieldName: TYPE_RESPONSIBLES.STUDENT
  },
  {
    label: 'Pessoa Física',
    fieldName: TYPE_RESPONSIBLES.NATURAL_PERSON
  },
  {
    label: 'Pessoa Jurídica',
    fieldName: TYPE_RESPONSIBLES.LEGAL_PERSON
  }
]

export { fieldsNameForm, listTypeOfResponsible, TYPE_RESPONSIBLES }
