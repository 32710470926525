import FEEDBACK_SNACK from 'feedBackSnack'
import {
  getListRequirements,
  getListRequirementsCounters,
  getListRequirementsInXLSX,
  getSecretaryTypes,
  getSector
} from 'services/api/admin'
import {
  exportSpreadsheetByXLSX,
  formatDateEnv,
  updateErrorMessage
} from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'
import { countDefault } from './constants'

const getParams = ({ values, page, filter }) => {
  const {
    unity,
    sector,
    type,
    requisitionId,
    dueDate,
    endDate,
    searchType,
    searchBy,
    finalized,
    status
  } = values || {}

  const valueType = values?.type?.map(({ id }) => id)

  return {
    ...(unity && { unit: unity }),
    ...(sector && { sector_id: sector }),
    ...(type && { requirement_type: valueType }),
    ...(requisitionId && { requirement_id: requisitionId }),
    ...(dueDate && { start_date: formatDateEnv(dueDate) }),
    ...(endDate && { end_date: formatDateEnv(endDate) }),
    ...(searchType && searchBy && { [searchType]: searchBy }),
    ...(status && { status }),
    finalized,
    page,
    limit: filter
  }
}

const getListSector = async ({ setListSector, unity, active, setSnack }) => {
  setSnack('')
  const params = cleanObject({
    ...(unity && { unit_id: unity }),
    active
  })

  const { data, error, status } = await getSector(params)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListTypesRequirements
    })
  }

  const selectSector = data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { id, value: id, label: name }
    return [...acc, newItem]
  }, [])

  return setListSector(selectSector)
}

const getListTypes = async ({ setListTypes, unity, setSnack }) => {
  setSnack('')

  const { data, error, status } = await getSecretaryTypes({
    ...(unity && { unit: unity })
  })

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListTypesRequirements
    })
  }

  const selectTypes = data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { id, value: name, label: name }
    return [...acc, newItem]
  }, [])

  return setListTypes(selectTypes)
}

const downloadListRequisitionInXLSX = async ({ values, setLoading }) => {
  setLoading(true)
  const params = getParams({ values })
  const { data, error, status } = await getListRequirementsInXLSX(params)
  setLoading(false)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
    })
  }
  return exportSpreadsheetByXLSX(
    data,
    `requisicoes-status(${params.status}).xlsx`
  )
}

const getRequirementsCounter = async ({ values }) => {
  const params = getParams({ values })
  const { data, error, status } = await getListRequirementsCounters(params)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
    })
  }

  return data
}

const onSubmitRequirements = async ({
  values,
  setLoading,
  page,
  filter,
  listSector,
  listTypes,
  setBigNumbers,
  setRequirements,
  setFormValues,
  setValuesForm,
  setNotSearch,
  setCount,
  setExpanded,
  register
}) => {
  setLoading(true)
  const requirementCounterPromise = getRequirementsCounter({
    values
  })

  const requirementListPromise = getListRequirements(
    getParams({ values, page, filter })
  )

  const [{ error, status, data }, bigNumbersData] = await Promise.all([
    requirementListPromise,
    requirementCounterPromise
  ])

  setBigNumbers(bigNumbersData)
  setRequirements(data?.data)
  setLoading(false)
  setFormValues(values)
  setValuesForm(values)

  if (data?.count === countDefault) {
    setValuesForm(null)
    setRequirements([])
    return setNotSearch(false)
  }

  if (data?.data) {
    setRequirements(data?.data)
    setCount(data?.count)

    if (!register) setExpanded(false)
    return setNotSearch(false)
  }

  return updateErrorMessage({
    error,
    status,
    feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListRequisition
  })
}

export {
  getParams,
  getListSector,
  getListTypes,
  downloadListRequisitionInXLSX,
  getRequirementsCounter,
  onSubmitRequirements
}
