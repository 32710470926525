import {
  makeURLAccessLegacy,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { deleteExpedition, getListExpedition } from 'services/api/admin'
import { generalMenu } from './helpers'
import { MESSAGE } from './constants'

const emitDiploma = ({
  companyId,
  register,
  historicStudentId,
  diplomaId,
  isManual = false
}) => {
  const url = {
    manual: `acesso/aluno_certificado_manual_pdf.php?id=${diplomaId}&matricula=${register}`,
    default: `acesso/aluno_diploma_CT${companyId}.php?id=${historicStudentId}&matricula=${register}`
  }

  makeURLAccessLegacy(isManual ? url.manual : url.default, true)
}

const getMenuItems = ({
  register,
  handleEdit,
  handleClose,
  toggleModalExclude,
  companyId,
  historicStudentId,
  diplomaId
}) =>
  generalMenu({
    register,
    handleEdit,
    handleClose,
    toggleModalExclude,
    companyId,
    emitDiploma,
    historicStudentId,
    diplomaId
  })

const handleDeleteExpedition = async ({
  setSnack,
  diplomaId,
  setLoading,
  setUpdateList,
  setModalExclude
}) => {
  setSnack('')
  setLoading(true)

  const { error, status } = await deleteExpedition(diplomaId)

  setLoading(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDeleteExpedition
    })
  }
  setUpdateList(true)
  setModalExclude(false)
  return toastNotificationSuccess(MESSAGE.SUCCESS, 1500)
}

const handleListExpedition = async ({
  setSnack,
  register,
  setLoading,
  setNotSearch,
  setExpeditionList
}) => {
  setSnack('', '')

  const { data, error, status } = await getListExpedition(parseFloat(register))

  setLoading(false)
  if (error) {
    setNotSearch(true)
    updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListExpedition
    })
    return
  }

  setExpeditionList(data)
}

export { getMenuItems, handleListExpedition, handleDeleteExpedition }
