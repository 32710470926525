import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TimePicker as Picker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { getGlobalLocale } from 'services/helpers'

const TimePicker = ({
  value,
  onChange,
  id,
  disabled,
  helperText,
  error,
  label,
  size,
  fullWidth,
  disabledInput,
  ...props
}) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={getGlobalLocale()}
  >
    <Picker
      components={[
        'TimePicker',
        'MobileTimePicker',
        'DesktopTimePicker',
        'StaticTimePicker'
      ]}
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          size={size}
          helperText={helperText}
          error={error}
          fullWidth={fullWidth}
          disabled={disabledInput}
        />
      )}
    />
  </LocalizationProvider>
)

export default TimePicker

TimePicker.propTypes = {
  onChange: PropTypes.func,
  size: PropTypes.string,
  id: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disableFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  inputFormat: PropTypes.string
}

TimePicker.defaultProps = {
  onChange: () => {},
  size: '',
  id: '',
  helperText: '',
  disableFuture: false,
  error: false,
  disabled: false
}
