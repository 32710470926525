import styled from 'styled-components'
import { CheckCircleOutline } from '@mui/icons-material'

const CheckCircleOutlineIcon = styled(CheckCircleOutline)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.green.main};
  }
`

export { CheckCircleOutlineIcon }
