import { useEffect, useState } from 'react'
import humps from 'humps'
import { getClasses } from 'services/api/professor'
import { getErrorMessage } from 'services/helpers'
import { ERROR } from 'services/constants'
import {
  defaultClassList,
  defaultTotalPage,
  maximunSizePage,
  ERROR_MESSAGE_PROFESSOR,
} from './constants'

const useGetProfessor = ({
  professorId,
  setLoadingVisibility,
  classType,
  activePage,
  filterParams,
  updateSnack,
  setSnack,
}) => {
  const [classList, setClassList] = useState(defaultClassList)
  const [totalPages, setTotalPages] = useState(defaultTotalPage)

  const payload = {
    type: classType,
    limit: maximunSizePage,
    page: activePage,
    ...filterParams,
  }

  const setMaxPageNumber = ({ count, limit }) => {
    const maxPageNumber = Math.ceil(count / limit)
    setTotalPages(maxPageNumber)
  }

  const loadData = async () => {
    setClassList(defaultClassList)
    updateSnack({})
    const {
      error,
      status,
      data: response,
    } = await getClasses(professorId, payload)

    if (error) {
      const message = getErrorMessage(ERROR_MESSAGE_PROFESSOR, status)
      setSnack(message, ERROR)
    }

    if (response?.data) {
      const classesData = humps.camelizeKeys(response?.data)
      setMaxPageNumber(response)
      setClassList(classesData)
    }

    setLoadingVisibility(false)
  }

  useEffect(() => {
    loadData()
  }, [classType, activePage, filterParams])

  return { classList, totalPages }
}

export default useGetProfessor
