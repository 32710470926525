/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { convertToParams } from 'services/helpers'
import { cleanObject } from 'services/helpers/cleanObject'

const formatParamsSearchSupplier = (values) => {
  const params = convertToParams(
    cleanObject({
      inactive: values?.inactive,
      search_type: values?.searchType,
      field_value: values?.fieldValue
    })
  )
  return params
}

const formatInitialSuppliers = ({ razao_social, ...restValues }) => ({
  ...restValues,
  razaoSocial: razao_social
})

const formatPayloadCreateSupplier = ({
  phone,
  addressNumber,
  razaoSocial,
  stateRegistration,
  municipalRegistration,
  tradeName,
  zipCode,
  complement,
  ...restValues
}) => {
  const listPhones = (phone ?? [])?.reduce((prev, current, index) => {
    if (!current?.cell) return { ...prev }

    const phoneIndex = index === 0 ? '' : index + 1

    return {
      ...prev,
      [`telephone${phoneIndex}`]: current.cell
    }
  }, {})

  return {
    ...restValues,
    razao_social: razaoSocial,
    state_registration: stateRegistration,
    municipal_registration: municipalRegistration,
    trade_name: tradeName,
    zip_code: zipCode,
    address_number: addressNumber,
    ...(complement && { complement }),
    ...listPhones
  }
}

const formatInitialValuesSupplier = ({
  address_number,
  razao_social,
  state_registration,
  telephone,
  telephone2,
  telephone3,
  trade_name,
  zip_code,
  complement,
  municipal_registration,
  ...restValues
}) => {
  const listPhone = [telephone, telephone2, telephone3]
    ?.filter((number) => !!number)
    ?.map((number) => ({
      id: Math.random(),
      cell: number
    }))

  return {
    ...restValues,
    municipalRegistration: municipal_registration,
    addressNumber: address_number,
    razaoSocial: razao_social,
    stateRegistration: state_registration || '',
    phone: listPhone,
    tradeName: trade_name,
    zipCode: zip_code,
    complement: complement ?? ''
  }
}

const formatPayloadUpdateSupplier = ({
  id,
  inactive,
  phone,
  addressNumber,
  razaoSocial,
  stateRegistration,
  tradeName,
  zipCode,
  complement,
  municipalRegistration,
  ...restValues
}) => {
  const listPhones = (phone ?? [])?.reduce((prev, current, index) => {
    if (!current?.cell) return { ...prev }

    const phoneIndex = index === 0 ? '' : index + 1

    return {
      ...prev,
      [`telephone${phoneIndex}`]: current.cell
    }
  }, {})

  return {
    ...restValues,
    razao_social: razaoSocial,
    state_registration: stateRegistration,
    trade_name: tradeName,
    zip_code: zipCode,
    municipal_registration: municipalRegistration,
    address_number: addressNumber,
    ...(complement && { complement }),
    ...listPhones
  }
}

export {
  formatParamsSearchSupplier,
  formatInitialSuppliers,
  formatPayloadCreateSupplier,
  formatInitialValuesSupplier,
  formatPayloadUpdateSupplier
}
