import { convertToParams } from 'services/helpers'

const paramsUrl = ({ name, register, tab, cpf, hasPage }) =>
  convertToParams({
    name,
    register,
    tab,
    cpf,
    hasPage
  })

export { paramsUrl }
