import styled from 'styled-components'
import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell
} from '@mui/material'
import theme from 'theme/designTokens'

const Wrapper = styled.div`
  margin-top: ${() => theme.spacing(3)};

  tr:hover td {
    background: ${() => theme.palette.action.hover};
  }
`

const PLUS_ONE_SIZE = '20px'

const PlusOne = styled.span`
  background: ${() => theme.palette.blue.main};
  border-radius: 2px;
  color: ${() => theme.palette.common.white};
  display: inline-flex;
  flex-shrink: 0;
  font-size: 10px;
  font-weight: bold;
  line-height: ${PLUS_ONE_SIZE};
  margin-left: ${() => theme.spacing(1)};
  min-height: ${PLUS_ONE_SIZE};
  min-width: ${PLUS_ONE_SIZE};
  place-content: center;
`

const WrapperActionButtons = styled.div`
  opacity: 0;
  pointer-events: none;
`

const showActionButtons = `
  display: inline-flex;
  opacity: 1;
  pointer-events: all;
`

const WrapperLink = styled.div`
  align-items: center;
  display: flex;

  button {
    font-size: 14px;
    margin-left: ${() => theme.spacing(1)};
    padding: 0;
    text-align: left;
  }

  &:hover > ${WrapperActionButtons} {
    ${showActionButtons}
  }
`

const TableRow = styled(MuiTableRow)`
  &:hover ${WrapperLink} ${WrapperActionButtons} {
    ${showActionButtons}
  }
`

const Nolink = styled.span`
  font-size: 14px;
  margin-left: ${() => theme.spacing(1)};
  padding: 0;
  text-align: left;
  color: ${() => theme.palette.primary.main};
`

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    color: ${({ $isInactive }) =>
      $isInactive ? theme.palette.dark.main : theme.palette.red.light};
  }
`

const Legend = styled.div`
  margin-top: ${() => theme.spacing(3)};
  font-size: 12px;

  ${PlusOne} {
    margin-right: ${() => theme.spacing(1)};
  }
`

export {
  Legend,
  PlusOne,
  TableRow,
  Wrapper,
  WrapperActionButtons,
  WrapperLink,
  TableCell,
  Nolink
}
