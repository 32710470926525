export const classMessage = {
  create: {
    success: 'Nova aula adicionada com sucesso'
  },
  update: {
    success: 'Aula editada com sucesso'
  }
}

export const BACK_RETURN = -1
