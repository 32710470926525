import styled from 'styled-components'
import { Button as MuiButton, Toolbar } from '@mui/material'

const ToolbarArea = styled(Toolbar)`
  &.MuiToolbar-root {
    gap: 10px;
    padding: 0;
    display: flex;
    margin-top: 24px;
    min-height: 34px;
    align-items: start;
  }
`

const Button = styled(MuiButton)`
  &.MuiButton-root {
    color: #ff7f00;
    padding: 4px 8px;
    border: 1px solid;
    border-color: #ff7f00;
  }
  &.MuiButton-root:hover {
    color: #ff7f00;
    border-color: #ff7f00;
    background-color: transparent;
  }
`
export { ToolbarArea, Button }
