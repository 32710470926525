const state = [
  {
    value: 'SP',
    label: 'São Paulo'
  },
  {
    value: 'RJ',
    label: 'Rio de Janeiro'
  },
  {
    value: 'MG',
    label: 'Minas Gerais'
  },
  {
    value: 'BA',
    label: 'Bahia'
  }
]

const civilStatusList = [
  { value: 'Solteiro(a)', label: 'Solteiro(a)' },
  { value: 'Casado(a)', label: 'Casado(a)' },
  { value: 'Viúvo(a)', label: 'Viúvo(a)' },
  { value: 'Divorciado(a)', label: 'Divorciado(a)' }
]

const MAX_LENGTH_CPF = 14
const MAX_LENGTH_PHONE = 15
const MAX_LENGTH_CEP = 9

export {
  state,
  civilStatusList,
  MAX_LENGTH_CPF,
  MAX_LENGTH_PHONE,
  MAX_LENGTH_CEP
}
