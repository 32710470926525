import { useState } from 'react'
import { Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { ButtonFooterV2, InputFile } from 'components/molecules'
import { getRgba } from 'services/helpers/polished'
import theme from 'theme/designTokens'
import { MESSAGE, allowedExtensions, maxSizePhoto } from './constants'
import * as Styled from './style'

const UploadPhotoV2 = ({
  open,
  initialState,
  handleClose,
  setErrorTypeFile,
  handleUpload,
  errorTypeFile
}) => {
  const [nameFile, setNameFile] = useState('image')

  const handleFileChange = ({ event, setFieldValue }) => {
    if (event === '') {
      return setFieldValue('file', '')
    }

    const selectedFile = event.target.files[0]

    if (
      allowedExtensions.includes(selectedFile?.type) &&
      selectedFile?.size < maxSizePhoto
    ) {
      setNameFile(selectedFile.name)
      const readFileAsBase64 = (file) =>
        new Promise((resolve) => {
          const reader = new FileReader()
          reader.onload = () => resolve(reader.result)
          reader.readAsDataURL(file)
        })

      return readFileAsBase64(selectedFile).then((base64String) => {
        setErrorTypeFile(false)
        setFieldValue('file', base64String)
      })
    }

    return setErrorTypeFile(true)
  }

  return (
    <Styled.Modal open={open} onClose={handleClose} maxWidth="xs">
      <Formik
        initialValues={initialState}
        onSubmit={handleUpload}
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, dirty }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12} sm={12} md={11}>
                  <Styled.Title variant="h5" component="h5">
                    Enviar Imagem
                  </Styled.Title>
                </Grid>

                <Styled.GridClose item xs={12} sm={12} md={1}>
                  <Styled.Close
                    onClick={() => {
                      handleFileChange({ event: '', setFieldValue })
                      handleClose()
                    }}
                  />
                </Styled.GridClose>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  $errorTypeFile={errorTypeFile}
                >
                  <InputFile
                    photo
                    idUpload="upload"
                    typeFile="img"
                    labelBox={errorTypeFile ? MESSAGE.ERROR : MESSAGE.SUCCESS}
                    imgSrc={values?.file || ''}
                    name="upload photo"
                    nameFile={nameFile}
                    hasSize
                    errorTypeFile={errorTypeFile}
                    border={`2px dashed ${theme.palette.dark.light}`}
                    backgroundColor={
                      errorTypeFile
                        ? getRgba(theme.palette.red.light, 0.21)
                        : theme.palette.purple.light
                    }
                    width="auto"
                    onChange={(e) =>
                      handleFileChange({ event: e, setFieldValue })
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Styled.Title variant="caption" component="caption">
                    Somente imagens em PNG, JPG ou JPEG, com até 5mb
                  </Styled.Title>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <ButtonFooterV2
                    labelClose="Cancelar"
                    labelConfirm="Enviar"
                    size="large"
                    onClickClose={() => {
                      setErrorTypeFile(false)
                      handleFileChange({ event: '', setFieldValue })
                      handleClose()
                    }}
                    disabledConfirm={errorTypeFile || !dirty}
                  />
                </Grid>
              </Grid>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export default UploadPhotoV2
