import { lazy, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { convertToParams, formatDate, isEmpty } from 'services/helpers'
import { ROUTE } from 'services/constants'
import { useSortTable } from 'services/hooks'
import { ButtonFooterV2 } from 'components/molecules'
import { Button, TextNotFound } from 'components/atoms'
import { breadcrumbsItems, company, emptyValue } from './constants'
import {
  getDetailsManageReceipts,
  getInfoHeaders,
  handleExportXLS,
  sendReceipts
} from './services'
import * as Styled from './style'

const Table = lazy(() => import('./Table'))

const ManageReceipts = () => {
  const navigate = useNavigate()
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [detailsManageReceipts, setDetailsManageReceipts] = useState([])
  const [infoHeaders, setInfoHeaders] = useState({})

  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])

  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    detailsManageReceipts,
    'professor_name'
  )

  useEffect(async () => {
    setLoadingOpen(true)
    await Promise.all([
      getDetailsManageReceipts({
        params,
        setDetailsManageReceipts
      }),
      getInfoHeaders({ params, setInfoHeaders })
    ])

    setLoadingOpen(false)
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      breadcrumbsItems={breadcrumbsItems}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() =>
        navigate(ROUTE.ADMIN_HR_TIMEKEEPING, {
          state: { savedSearch: true }
        })
      }
      content={
        <>
          {isEmpty(detailsManageReceipts) ? (
            <>
              <TextNotFound
                icon={<Search />}
                text="Não foram encontrados recibos para essa unidade."
              />

              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Styled.GridButtonEmpty item xs={12} sm={12} md={12}>
                  <Button
                    size="medium"
                    value="Gerenciar turmas"
                    onClick={() =>
                      navigate(
                        `${ROUTE.ADMIN_HR_MANAGE_CLASSES}?payroll_id=${params.payroll_id}`
                      )
                    }
                  />
                </Styled.GridButtonEmpty>
              </Grid>
            </>
          ) : (
            <>
              <Box>
                <Grid container spacing={{ xs: 3, sm: 3 }}>
                  <Styled.Grid item xs={12} sm={12} md={11}>
                    <Typography variant="body1">
                      <b>Empresa:</b>{' '}
                      {company[infoHeaders.company_id] || emptyValue}
                    </Typography>

                    <Typography variant="body1">
                      <b>Apuração:</b>{' '}
                      {formatDate(infoHeaders.counting_start_date) ||
                        emptyValue}{' '}
                      a{' '}
                      {formatDate(infoHeaders.counting_end_date) || emptyValue}
                    </Typography>
                    <Typography variant="body1">
                      <b>Pagamento:</b>{' '}
                      {formatDate(infoHeaders.payment_date) || emptyValue}
                    </Typography>
                  </Styled.Grid>

                  <Styled.GridIcon item xs={12} sm={12} md={1}>
                    <Tooltip
                      title="Baixar comprovante em Excel"
                      placement="top"
                      arrow
                    >
                      <Styled.Download
                        onClick={() =>
                          handleExportXLS({
                            detailsManageReceipts,
                            setLoadingOpen
                          })
                        }
                      />
                    </Tooltip>
                  </Styled.GridIcon>
                </Grid>
              </Box>

              <Typography variant="h5">
                <b>Unidade:</b> {params.unit}
              </Typography>

              <Table
                order={order}
                rows={sortTable}
                orderBy={orderBy}
                handleRequestSort={handleRequestSort}
              />

              <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <ButtonFooterV2
                    size="medium"
                    labelConfirm="Enviar para consolidação"
                    onClickConfirm={() =>
                      sendReceipts({
                        params,
                        setLoadingOpen,
                        navigate
                      })
                    }
                  />
                </Grid>
              </Styled.GridContainer>
            </>
          )}
        </>
      }
    />
  )
}

export default ManageReceipts
