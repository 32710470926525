export const formatValuesToCreateSector = (values) => ({
  sector_name: values?.sectorName,
  unit_id: values?.unityId,
  responsibles: values?.users
})

export const formatValuesToUpdateSector = (values) => ({
  sector_name: values?.sectorName,
  unit_id: values?.unityId,
  responsibles: values?.users,
  active: !values?.inactive
})

export const formatValuesToResponsible = (values) => ({
  id: values?.id_user,
  name: values?.nome,
  unity: values?.unit_name
})

export const formatValuesInitialSector = (values) => ({
  ...values,
  users: values?.users?.map((user) => ({ ...user, unity: values?.unity }))
})
