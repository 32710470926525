import { CardActions } from '@mui/material'
import * as Styled from './style'

const CardNumber = ({
  iconFooter,
  iconCard,
  onClickCard,
  onClickFooter,
  title,
  subTitle,
  labelButton,
  hasColor,
  hasColorTitle,
  variant,
  classNameBtn,
  classNameCard,
  disabled,
  width,
  padding,
  opacity
}) => (
  <Styled.Container $padding={padding}>
    <Styled.Card $hasColor={hasColor} $width={width}>
      <Styled.CardActionArea
        onClick={onClickCard}
        className={classNameCard}
        disabled={disabled}
        $opacity={opacity}
      >
        <Styled.BoxTitle>
          <Styled.Title $hasColor={hasColorTitle}>{title}</Styled.Title>
          {iconCard}
        </Styled.BoxTitle>

        <Styled.SubTitle>{subTitle}</Styled.SubTitle>
      </Styled.CardActionArea>
    </Styled.Card>
    <CardActions>
      <Styled.Button
        size="small"
        startIcon={iconFooter}
        onClick={onClickFooter}
        $hasColor={hasColor}
        variant={variant}
        className={classNameBtn}
        disabled={disabled}
        $opacity={opacity}
      >
        {labelButton}
      </Styled.Button>
    </CardActions>
  </Styled.Container>
)
export default CardNumber
