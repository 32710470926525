import { formatDateEnv } from 'services/helpers'

const typeAll = 'Todos'
const pending = 'pending'

const getParamsByInvoices = ({ values, filter, pageActual, cards }) => ({
  begin_at: formatDateEnv(values?.beginAt),
  end_at: formatDateEnv(values?.endAt),
  sent: values?.sent,
  paid: values?.paid,
  type: values?.type === typeAll ? '' : values?.type,
  bank_billing_id: values?.bankBillingId,
  limit: filter,
  page: pageActual,
  status: cards,
  local_payment: !values?.localPayment ? '' : values?.localPayment,
  razao_social: values?.razaoSocial
})

const getParamsByGenerateGrades = (formValues, units) => ({
  begin_at: formatDateEnv(formValues?.beginAt),
  end_at: formatDateEnv(formValues?.endAt),
  sent: formValues?.sent,
  paid: formValues?.paid,
  local_payment: formValues?.localPayment,
  status: pending,
  units,
  type: formValues?.type || undefined,
  bank_billing_id: formValues?.bankBillingId || undefined,
  razao_social: formValues?.razaoSocial
})

export { getParamsByInvoices, getParamsByGenerateGrades }
