import styled from 'styled-components'
import { TableCell as MuiTableCell } from '@mui/material'
import { StarRate as IStarRate } from '@mui/icons-material'
import theme from 'theme/designTokens'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 26rem;
    cursor: pointer;
  }
`

const StarRate = styled(IStarRate)`
  &.MuiSvgIcon-root {
    color: ${() => theme.palette.primary.main};
  }
`

const TableCellCourse = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 26rem;
    }
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 16rem;

      svg {
        cursor: pointer;
      }

      & > a {
        color: ${() => theme.palette.primary.main} !important;
        margin-right: 20px;
      }

      button {
        padding: 0 8px;
      }

      & > div {
        display: flex;

        & svg:first-child {
          display: none;
          color: ${() => theme.palette.red.main};
        }
      }

      &:hover > div svg:first-child {
        display: block;
      }
    }
  }
`

export { TableCell, TableCellTitle, TableCellCourse, StarRate }
