import styled from 'styled-components'
import {
  Grid as MuiGrid,
  Typography,
  InputAdornment as MuiInputAdornment
} from '@mui/material'
import { Modal as MuiModal } from 'components/atoms'

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 790px;
  }
`

const Grid = styled(MuiGrid)`
  max-width: 670px;
  padding: 21px;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

const InputAdornment = styled(MuiInputAdornment)`
  margin-top: 30px;
`

export { Modal, Grid, Title, InputAdornment }
