const parseCountrys = (data) =>
  data?.reduce((acc, cur) => {
    const { name, value, nationality } = cur

    const newItem = {
      label: name,
      value,
      nationality
    }

    return [...acc, newItem]
  }, [])

export { parseCountrys }
