import TemplatePublic from 'components/templates/Shared/Public'
import ErrorPage from 'components/pages/Shared/ErrorPage'
import { AutomaticLogin, Login, Logout } from 'components/pages/Shared'
import { ROUTE } from 'services/constants'

const template = TemplatePublic

const PUBLIC_ROUTES = [
  {
    component: ErrorPage,
    exact: true,
    path: ROUTE.ERROR,
    template,
    isAllowed: () => true
  },
  {
    component: Login,
    exact: true,
    path: ROUTE.LOGIN_ROOT,
    template,
    isAllowed: () => true
  },
  {
    component: Login,
    exact: true,
    path: ROUTE.LOGIN,
    template,
    isAllowed: () => true
  },
  {
    component: AutomaticLogin,
    exact: true,
    path: ROUTE.LOGIN_LEGADO,
    template,
    isAllowed: () => true
  },
  {
    component: AutomaticLogin,
    exact: true,
    path: ROUTE.LOGIN_LEGACY,
    template,
    isAllowed: () => true
  },
  {
    component: Logout,
    exact: true,
    path: ROUTE.LOGOUT,
    template,
    isAllowed: () => true
  },
  {
    component: Login,
    exact: true,
    path: 'dev=true',
    template,
    isAllowed: () => true
  }
]

export default PUBLIC_ROUTES
