import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ListAlt } from '@mui/icons-material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { isEmpty } from 'services/helpers'
import { Button, TextNotFound } from 'components/atoms'
import { Grid } from '@mui/material'
import { ModalConfirm } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import { MESSAGE, breadCrumbsItems, initialState } from './constants'
import { TableDocumentsFiles } from './components/Table'
import * as Styled from './style'
import { deleteDocumentsProfessor, getAllServices } from './services'
import { ModalForm } from './components/ModalForm'

const DocumentFiles = () => {
  const navigate = useNavigate()
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [idDocument, setIdDocument] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [listDocuments, setListDocuments] = useState([])
  const [documentsType, setDocumentsType] = useState([])
  const [formModal, setFormModal] = useState(initialState)
  const [errorTypeFile, setErrorTypeFile] = useState(false)
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id, name } = params

  const handleClose = () => {
    setOpenModal(false)
    setFormModal(initialState)
  }

  useEffect(() => {
    getAllServices({
      id,
      setSnack,
      setLoadingOpen,
      setDocumentsType,
      setListDocuments
    })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loadingOpen}
      snackProps={snackProps}
      breadcrumbsItems={breadCrumbsItems}
      classNameBtnConfirm={TAG_MANAGER.rh_btn_cancela_documento}
      infoDownBreadcrumbs={`${id} - ${name}`}
      classNameHeader="goBack"
      onClickButton={() => {
        navigate(ROUTE.ADMIN_HR_PROFESSOR, {
          state: { savedSearch: true }
        })
      }}
      content={
        <>
          <Grid container spacing={{ xs: 0, sm: 2 }}>
            <Styled.Grid item xs={12} sm={12} md={12}>
              <Button
                type="button"
                color="primary"
                value="Novo Documento"
                onclick={() => setOpenModal(true)}
              />
            </Styled.Grid>
          </Grid>
          {!isEmpty(listDocuments) ? (
            <TableDocumentsFiles
              rows={listDocuments}
              setOpenModalConfirm={setOpenModalConfirm}
              setIdDocument={setIdDocument}
            />
          ) : (
            <TextNotFound icon={<ListAlt />} text={MESSAGE.NOT_FOUND_SEARCH} />
          )}

          <ModalForm
            open={openModal}
            setSnack={setSnack}
            setLoadingOpen={setLoadingOpen}
            documentsType={documentsType}
            setListDocuments={setListDocuments}
            handleClose={handleClose}
            formModal={formModal}
            setErrorTypeFile={setErrorTypeFile}
            errorTypeFile={errorTypeFile}
          />

          <ModalConfirm
            open={openModalConfirm}
            icon={<Styled.IconWarningAmber />}
            title="Excluir Documento"
            message="Você tem certeza que deseja excluir este documento? Esta ação é irreversível."
            size="medium"
            handleClose={() => setOpenModalConfirm(false)}
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            classNameBtnConfirm={TAG_MANAGER.rh_btn_confirma_excluirDocumento}
            onClickNotConfirm={() => setOpenModalConfirm(false)}
            onClickConfirm={() =>
              deleteDocumentsProfessor({
                id,
                idDocument,
                setListDocuments,
                setSnack,
                setLoadingOpen,
                setOpenModalConfirm
              })
            }
            colorButtonConfirm="error"
          />
        </>
      }
    />
  )
}

export default DocumentFiles
