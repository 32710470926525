import { useEffect, useState } from 'react'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  ListItemIcon,
  Checkbox
} from '@mui/material'
import { isEmpty } from 'services/helpers'
import * as Styled from './style'

const TransferList = ({ setListDocsSubmit, leftList, rightList }) => {
  const [selected, setSelected] = useState([])
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const handleToggle = (id) => {
    if (isEmpty(selected) || !selected?.includes(id)) {
      return setSelected([...selected, id])
    }

    const filtered = (selected || []).reduce((acc, item) => {
      if (item !== id) {
        acc.push(item)
      }
      return acc
    }, [])

    return setSelected([...filtered])
  }

  const handleCheckedRight = () => {
    const newRight = left?.filter(({ value }) => selected.includes(value))
    const newLeft = left?.filter(({ value }) => !selected.includes(value))

    if (setListDocsSubmit) {
      setListDocsSubmit([...right, ...newRight])
    }

    setLeft(newLeft)

    setRight([...right, ...newRight])
    setSelected([])
  }

  const handleCheckedLeft = () => {
    const lastRight = right?.filter(({ value }) => selected.includes(value))
    const newRight = right?.filter(({ value }) => !selected.includes(value))

    if (setListDocsSubmit) {
      setListDocsSubmit(newRight)
    }

    setRight(newRight)
    setLeft([...left, ...lastRight])
    setSelected([])
  }

  const handleAllRight = () => {
    setRight(right.concat(left))
    setLeft([])
  }

  const handleAllLeft = () => {
    setLeft(left.concat(right))
    setRight([])
  }

  useEffect(() => {
    setLeft(leftList)
    setRight(rightList)
  }, [])

  const customList = (items) => (
    <Styled.Paper>
      <List dense component="div" role="list">
        <Styled.Typography>Tipos de documento possíveis</Styled.Typography>
        <Divider />
        {items?.map(({ value, label }) => {
          const labelId = `transfer-list-item-${label}-label`
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={() => handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={selected?.includes(value)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={label} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Styled.Paper>
  )

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left?.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={isEmpty(selected)}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={isEmpty(right)}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right?.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  )
}
export default TransferList
