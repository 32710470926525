import styled, { css } from 'styled-components'
import {
  TableRow as MuiTableRow,
  Tooltip as MuiTooltip,
  Link as MuiLink
} from '@mui/material'
import { Delete } from '@mui/icons-material'

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  tr:hover td {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const WrapperLink = styled.div`
  width: fit-content;
  align-items: center;
  display: flex;

  button {
    font-size: 14px;
    margin-left: ${({ theme }) => theme.spacing(1)};
    padding: 0;
    text-align: left;
  }

  .icon {
    display: none;
  }

  :hover {
    .icon {
      display: flex;
      cursor: pointer;
    }
  }
`

const TableRow = styled(MuiTableRow)`
  ${({ $isDisabled }) =>
    $isDisabled && ' opacity: 0.5; &:hover { opacity: 1; }'}
`

const Tooltip = styled(MuiTooltip)`
  &.MuiToolbar-root {
    padding: 0;
    align-items: center;
  }
`

const Link = styled(MuiLink)`
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `}
`

const DoneYear = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.textColors.error};
  }
  cursor: pointer;
`

export { TableRow, Wrapper, WrapperLink, Tooltip, Link, DeleteIcon, DoneYear }
