import styled from 'styled-components'
import { Box as MuiBox, Typography } from '@mui/material'

const Container = styled(MuiBox)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.dark.light};
`

const AccordionSummary = styled.div`
  display: flex;
`

const Box = styled(MuiBox)`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.dark.main};
  display: flex;
  gap: 5px;
`

const LightText = styled(Typography)`
  color: ${({ theme }) => theme.palette.gray.main};
`

export { Container, AccordionSummary, Box, Text, LightText }
