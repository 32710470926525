const MESSAGE = {
  NO_SEARCH:
    '<b>Encontre os cupons disponíveis aqui</b><br/>Você pode selecionar uma unidade para buscar e filtrar os resultados.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  SUCCESS: 'Cupom salvo'
}

const typeAction = {
  remove: 'remove'
}

const nameAccordion = 'cupon'
const newCupon = 'newCupon'
const defaultItemsPerPage = 10
const lengthUnits = 1
const countDefault = 0
const defaultPage = 1

export {
  MESSAGE,
  typeAction,
  nameAccordion,
  newCupon,
  defaultItemsPerPage,
  lengthUnits,
  countDefault,
  defaultPage
}
