export const messagesServices = {
  createSector: {
    success: 'Novo setor criado com sucesso!',
    feedback: 'Erro ao tentar criar setor!'
  },
  updateSector: {
    success: 'Setor atualizado com sucesso!',
    feedback: 'Erro ao tentar atualizar setor!'
  },
  listSectors: {
    success: '',
    feedback: ''
  }
}
