import { useState, useEffect, lazy } from 'react'
import { useFormikContext } from 'formik'
import { useAddress } from 'services/hooks'
import { getCityByState } from 'services/api/shared'
import { isEmpty } from 'services/helpers'
import { initialData } from './service'
import { listRaces } from './constants'

const FormStudentData = lazy(() => import('./components/FormStudentData'))

const TabStudentData = () => {
  const { values, handleChange, setFieldValue, handleBlur } = useFormikContext()
  const fieldNameStudent = 'studentData'

  const [countrys, setCountrys] = useState([])
  const [selectedState, setSelectedState] = useState(
    values?.[fieldNameStudent]?.placeOfBirthState ?? ''
  )
  const [listTypeDisability, setListTypeDisability] = useState([])
  const [placeOfBirthStates, setPlaceOfBirthStates] = useState([])
  const [placeOfBirthCities, setPlaceOfBirthCities] = useState([])
  const [isSearchByCep, setSearchByCep] = useState(false)
  const [address, setAddress] = useState({
    state: values?.[fieldNameStudent]?.state ?? ''
  })

  const isCountryBr = values?.[fieldNameStudent]?.country === 'BR'
  const isEmptyCountry = values?.[fieldNameStudent]?.country === ''

  const {
    fetchAddress,
    listCitys,
    listStates,
    isUpdated,
    toggleUpdate,
    handleChangeState,
    handleChangeCep
  } = useAddress({ address, setAddress })

  useEffect(() => {
    if (isSearchByCep) {
      setFieldValue(`${fieldNameStudent}.district`, address?.district)
      setFieldValue(`${fieldNameStudent}.state`, address?.state)
      setFieldValue(`${fieldNameStudent}.city`, address?.city)
      setFieldValue(`${fieldNameStudent}.address`, address?.address)

      toggleUpdate()
    }
  }, [isUpdated])

  useEffect(async () => {
    if (isSearchByCep) {
      await fetchAddress(values?.[fieldNameStudent]?.zipCode)
      setSearchByCep(false)
    }
  }, [isSearchByCep])

  useEffect(async () => {
    if (!isEmpty(countrys) && values?.[fieldNameStudent]?.country !== '') {
      const { nationality } = countrys?.find(
        ({ value }) => value === values?.[fieldNameStudent]?.country
      )
      setFieldValue(`${fieldNameStudent}.nationality`, nationality)
    }
  }, [values?.[fieldNameStudent]?.country, countrys])

  useEffect(async () => {
    if (values?.[fieldNameStudent]?.placeOfBirthState) {
      const cities = await getCityByState(
        values?.[fieldNameStudent]?.placeOfBirthState
      )
      setPlaceOfBirthCities(cities)
    }
  }, [values?.[fieldNameStudent]?.placeOfBirthState])

  useEffect(() => {
    initialData({ setCountrys, setPlaceOfBirthStates })
  }, [])

  return (
    <FormStudentData
      values={values}
      isCountryBr={isCountryBr}
      isEmptyCountry={isEmptyCountry}
      countrys={countrys}
      fieldNameStudent={fieldNameStudent}
      handleChange={handleChange}
      handleBlur={handleBlur}
      setFieldValue={setFieldValue}
      setSelectedState={setSelectedState}
      placeOfBirthStates={placeOfBirthStates}
      placeOfBirthCities={placeOfBirthCities}
      handleChangeCep={handleChangeCep}
      setSearchByCep={setSearchByCep}
      handleChangeState={handleChangeState}
      listStates={listStates}
      listRaces={listRaces}
      listTypeDisability={listTypeDisability}
      listCitys={listCitys}
    />
  )
}

export default TabStudentData
