import { ROUTE } from 'services/constants'
import { convertToParams, formatDateMonthYearBr } from 'services/helpers'

const initialValues = { checked: [] }
const emptyValue = '---'
const company = {
  81: 'ESSA Educação Profissional S.A.',
  85: 'Enferminas Educação Profissional S.A.',
  86: 'Aprendizagem Educação Profissional S.A.'
}

const MESSAGE = {
  SUCCESS_CHECKED: 'Turma confirmada com sucesso!'
}

const breadcrumbsItems = (params) => [
  {
    path: ROUTE.ADMIN_HR_TIMEKEEPING,
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_TIMEKEEPING,
    label: 'Apontamento de Horas'
  },
  {
    path: `${ROUTE.ADMIN_HR_MANAGE_CONSOLIDATION}?${convertToParams({
      competence: formatDateMonthYearBr(params.competence),
      unit: params.unit
    })}`,
    label: '<b>Consolidação</b>'
  }
]

export { breadcrumbsItems, initialValues, MESSAGE, emptyValue, company }
