import styled from 'styled-components'
import {
  TableCell as MuiTableCell,
  TableBody as MuiTableBody
} from '@mui/material'

const TableCellScore = styled(MuiTableCell)`
  &.MuiTableCell-root {
    background-color: ${({ theme }) => theme.palette.yellow.main};
    color: ${({ theme, $colorFinalGrade }) =>
      $colorFinalGrade
        ? theme.palette.textColors.secondary.darkBlue
        : theme.palette.textColors.error};
    width: 10%;
    text-align: center;
  }
`

const TableBody = styled(MuiTableBody)`
  &.MuiTableCell-root {
    border-bottom: none;
  }
`

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    width: ${(props) => props.$fieldSize};
    text-align: ${({ $fiedlName }) => ($fiedlName ? 'left' : 'center')};
  }
`

const TagAcademicPerformances = styled.p`
  padding: 5px;
  background-color: ${({ theme }) => theme.palette.red.light};
  border-radius: 5px;
  color: ${({ theme }) => theme.palette.primary.white};
  margin: 5px 0 0 0;
`

export { TableCellScore, TableBody, TableCell, TagAcademicPerformances }
