const titleHeader =
  'Selecione o tipo de histórico, o curso desejado e confirme os dados para a emissão do documento.'

const description =
  'Se a turma é do tipo Módulo Único (todas as disciplinas), basta selecionar a turma principal, se não, selecione todas as turmas por ordem.'
const MESSAGE = {
  NO_SEARCH_GROUPS:
    '<b>Encontre os grupos já criados aqui</b><br/>Você precisa selecionar ao menos a unidade e a<br/> situação para listar os grupos disponíveis.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  savedGroup: 'Grupo salvo com sucesso',
  updateGroup: 'Grupo atualizado com sucesso'
}

const VALUE_STATUS = {
  NEXT_PAGE_ONE: '1',
  NEXT_PAGE_TWO: '2'
}

const defaultItemsPerPage = 10
const countDefault = 0
const defaultPage = 1
const requeriment = 'requeriment'
const emptyValue = ''
const idSelected = 1
const defaultName = 'name'

export {
  MESSAGE,
  titleHeader,
  description,
  VALUE_STATUS,
  defaultItemsPerPage,
  countDefault,
  defaultPage,
  requeriment,
  emptyValue,
  idSelected,
  defaultName
}
