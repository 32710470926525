import styled, { css } from 'styled-components'
import {
  Grid as MuiGrid,
  RadioGroup as MuiRadioGroup,
  FormControl as MuiFormControl
} from '@mui/material'
import { AddBox, Delete } from '@mui/icons-material'
import { TextField as MolecTextField } from 'components/molecules'

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  margin-top: 24px;

  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`

const BoxCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`

const Grid = styled(MuiGrid)`
  display: flex;
  text-align: center;
  justify-content: center;
`
const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > button.MuiButtonBase-root {
    padding: 8px 30px;
  }
  ${({ isLoad }) =>
    isLoad &&
    css`
      > button.MuiButtonBase-root {
        padding: 8px 0px;
        min-width: 145px;
      }
    `}
`

const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    -webkit-flex-wrap: nowrap;
  }
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.textColors.error};
  }
`

const AddIcon = styled(AddBox)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
  }
`
const ContainerField = styled.div`
  display: flex;
  width: 100%;
`

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: 1px dashed ${({ theme }) => theme.palette.gray.bgLight};
  padding: 16px;
  width: 100%;
  margin-bottom: 24px;
  & > div {
    margin-bottom: 16px;
  }
`

const BoxTooltip = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const BoxPhone = styled.div`
  border: 2px ${({ theme }) => theme.palette.gray.borderLight} dashed;
  padding: 17px 12px 0 12px;
`

const IconAdd = styled(AddBox)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    fill: ${({ theme }) => theme.palette.primary.main};
    outline: none;
    border: 0px;
    margin: 17px 0 0 14px;
  }
`

const IconRemove = styled(Delete)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.red.light};
    outline: none;
    border: 0px;
    margin: 17px 0 0 14px;
  }
`
const TextField = styled(MolecTextField)`
  &.MuiFormControl-root {
    margin: 0 0 20px 0;
  }
`
const FormControl = styled(MuiFormControl)`
  ${({ error }) =>
    error &&
    css`
      label {
        span {
          color: #d32f2f;
        }
      }
    `}
`
const TextError = styled.p`
  color: #d32f2f;
  margin-top: 3px;
  text-align: left;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 0.75rem;
`

export {
  Grid,
  BoxCard,
  AddIcon,
  IconAdd,
  BoxPhone,
  TextError,
  BoxHeader,
  TextField,
  Container,
  RadioGroup,
  BoxTooltip,
  DeleteIcon,
  IconRemove,
  FormControl,
  BoxContainer,
  ContainerField
}
