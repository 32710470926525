import { getCurrency, getLanguage } from 'i18n/language'

export const formatMoney = (value, language, country) => {
  const currentLanguage = language || getLanguage()
  const currentCurrency = country || getCurrency()

  return value.toLocaleString(currentLanguage, {
    style: 'currency',
    currency: currentCurrency,
  })
}
