import { lazy, useState } from 'react'
import { Tab } from '@mui/material'
import { schoolClassData, TAB, classParameters } from './constants'
import * as Styled from './style'

const TabActive = lazy(() => import('./TabActive'))

const Tabs = ({
  openModal,
  setOpenModal,
  setFieldValue,
  unitys,
  levels,
  groups,
  listCodinator,
  periods,
  course,
  listPole,
  setConfirmCurriculum,
  values,
  setSnack,
  setFormValues,
  modules,
  evaluations,
  setHasPole,
  modalities,
  setSelectedLevel,
  listGradeSheet,
  selectedLevel,
  setLoadingVisibility,
  hasPole,
  loadingVisibility,
  touched,
  setCourses,
  errors
}) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedTab, setSelectedTab] = useState(TAB.SCHOOL_CLASS_DATA)
  const [value, setValue] = useState(schoolClassData)
  const [messageError, setMessageError] = useState(false)

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Styled.Tabs value={value} onChange={handleChangeTabs} aria-label="tabs">
        <Tab
          value={schoolClassData}
          label="Dados da Turma"
          onClick={() => setSelectedTab(TAB.SCHOOL_CLASS_DATA)}
        />

        <Tab
          value={classParameters}
          label="Parametrizações da turma"
          onClick={() => setSelectedTab(TAB.DISCIPLINES)}
        />
      </Styled.Tabs>

      <TabActive
        unitys={unitys}
        hasPole={hasPole}
        levels={levels}
        groups={groups}
        listGradeSheet={listGradeSheet}
        setSnack={setSnack}
        values={values}
        listPole={listPole}
        setLoadingVisibility={setLoadingVisibility}
        setCourses={setCourses}
        courses={course}
        setConfirmCurriculum={setConfirmCurriculum}
        setHasPole={setHasPole}
        listCodinator={listCodinator}
        loadingVisibility={loadingVisibility}
        setFormValues={setFormValues}
        modalities={modalities}
        periods={periods}
        evaluations={evaluations}
        modules={modules}
        selectedLevel={selectedLevel}
        setSelectedLevel={setSelectedLevel}
        tabSelected={value}
        setFieldValue={setFieldValue}
        messageError={messageError}
        setMessageError={setMessageError}
        openModal={openModal}
        setOpenModal={setOpenModal}
        touched={touched}
        errors={errors}
      />
    </>
  )
}

export default Tabs
