import styled from 'styled-components'
import { Grid, Typography } from '@mui/material'
import { Button } from 'components/atoms'
import { PictureAsPdf as iconpdf } from '@mui/icons-material'

const SwitchAdvSearch = styled.div`
  padding: 20px 0 10px;
`

const LinkWorksheets = styled.div`
  cursor: pointer;
`

const PictureAsPdf = styled(iconpdf)`
  &.MuiSvgIcon-root {
    width: 2em;
    font-size: 53px;
    margin: 14px 0 0 0;
  }
`

const ButtonExport = styled(Button)`
  &.MuiButton-root {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primary.main};
    border: 0px;
    box-shadow: none;

    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.white};
    }
  }
`

const GridButtonExport = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    justify-content: flex-end;
  }
`

const GridWorksheets = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
`

const LabelWorksheets = styled(Typography)`
  width: 93px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.palette.dark.main};
`

export {
  SwitchAdvSearch,
  LabelWorksheets,
  GridWorksheets,
  LinkWorksheets,
  ButtonExport,
  GridButtonExport,
  PictureAsPdf
}
