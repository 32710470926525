const initialValues = {
  type: [],
  category: '',
  occurrenceNumber: '',
  dueDate: null,
  endDate: null,
  cpf: '',
  inactive: false
}

export { initialValues }
