import { messageErroOrSuccess } from 'services/helpers'
import {
  getSimilarities,
  getStudentsUnifyEnrollent,
  getUnify
} from 'services/api/admin'
import { defaultPage, messagesServices } from './constants'

const searchEnrollment = async ({
  setSnack,
  setStatus,
  setEnrollmentList,
  filter,
  page,
  values,
  setCount,
  setLoading
}) => {
  setSnack('', '')
  setLoading(true)
  const paginationData = {
    limit: filter,
    page: page || defaultPage
  }

  const params = {
    ...paginationData,
    [values.searchType]: values.fieldValue,
    duplicated: values.duplicated
  }

  const { data, error, status } = await getStudentsUnifyEnrollent(params)
  setLoading(false)

  if (error) {
    messageErroOrSuccess({
      error,
      setSnack,
      status,
      feedbackMessage: messagesServices.listEnrollment.feedback,
      successMessage: messagesServices.listEnrollment.success
    })
  }

  const enrollmentList = data ?? []

  setEnrollmentList(enrollmentList.data)
  setCount(enrollmentList.count)

  setStatus({
    empty: false,
    notFound: !!error || !enrollmentList?.length
  })
}

const searchSimilarities = async ({
  setSnack,
  setStatus,
  setSimilarities,
  values,
  setLoading
}) => {
  setSnack('')
  setLoading(true)

  const params = { ids: values }

  const { data, error, status } = await getSimilarities(params)
  setLoading(false)

  if (error) {
    messageErroOrSuccess({
      error,
      setSnack,
      status,
      feedbackMessage: messagesServices.listUnify.feedback,
      successMessage: messagesServices.listUnify.success
    })
  }

  const enrollmentList = data ?? []

  setSimilarities(enrollmentList)

  setStatus({
    empty: false,
    notFound: !!error || !enrollmentList?.length
  })
}

const getUnifyEnrollment = async ({
  setSnack,
  setStatus,
  setLoading,
  mergeIdsValues,
  mainIdValue,
  setIsUnifyStatus
}) => {
  setSnack('')
  setLoading(true)

  const ids = mergeIdsValues?.map((id) => `ids[]=${id}`).join('&')

  const params = { ids, id: mainIdValue }

  const { data, error, status } = await getUnify(params)
  setLoading(false)

  if (error) {
    messageErroOrSuccess({
      error,
      setSnack,
      status,
      feedbackMessage: messagesServices.updateUnify.feedback,
      successMessage: messagesServices.updateUnify.success
    })
  }

  setIsUnifyStatus(true)

  setStatus({
    empty: false,
    notFound: !!error
  })
}

export { searchEnrollment, searchSimilarities, getUnifyEnrollment }
