const PROFESSOR_TAG_MANAGER = {
  rh_btn_edita_vinculo: 'rh_btn_edita_vinculo',
  rh_btn_exclui_vinculo: 'rh_btn_exclui_vinculo',
  rh_btn_baixa_documento: 'rh_btn_baixa_documento',
  rh_btn_deleta_documento: 'rh_btn_deleta_documento',
  rh_btn_cancela_documento: 'rh_btn_cancela_documento',
  rh_btn_cria_novoDocumento: 'rh_btn_cria_novoDocumento',
  rh_btn_criar_novoProfessor: 'rh_btn_criar_novoProfessor',
  rh_btn_acessa_vincularTurma: 'rh_btn_acessa_vincularTurma',
  rh_btn_confirma_enviarEmail: 'rh_btn_confirma_enviarEmail',
  rh_btn_confirma_excluiVinculo: 'rh_btn_confirma_excluiVinculo',
  rh_btn_confirma_envioDocumento: 'rh_btn_confirma_envioDocumento',
  rh_btn_confirma_excluirDocumento: 'rh_btn_confirma_excluirDocumento',
  rh_btn_confirma_inativarProfessor: 'rh_btn_confirma_inativarProfessor',

  rh_btn_busca_turmas: 'rh_btn_busca_turmas',
  rh_btn_acessa_turma: 'rh_btn_acessa_turma',
  rh_btn_salva_vinculo: 'rh_btn_salva_vinculo',
  rh_btn_cancela_vinculo: 'rh_btn_cancela_vinculo',
  rh_btn_confirma_cancelarVinculo: 'rh_btn_confirma_cancelarVinculo'
}

export const HUMAN_RESOURCES = {
  ...PROFESSOR_TAG_MANAGER
}
