import { useCallback, useEffect, useState } from 'react'
import { ListAlt, Search } from '@mui/icons-material'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { List, SearchForm } from './components'
import { messages } from './config'
import { getSectors } from '../services'
import { defaultlistPerPage } from './constants'
import { useSectors } from './Context/FormContext'

const Sectors = () => {
  const { formValues, setFormValues } = useSectors()

  const [rowsPerPage, setRowsPerPage] = useState(defaultlistPerPage)
  const [status, setStatus] = useState({
    empty: true,
    notFound: false,
    notFoundByUnity: false
  })
  const [data, setData] = useState({ sectors: [] })

  const snackProps = useSnack()
  const { items, totalPage, page, handleSetPage } = usePaginationBasic(
    data?.sectors,
    rowsPerPage,
    data?.count
  )

  const noResultMessage = useCallback(() => {
    if (status.empty) return messages.empty.text
    if (status.notFoundByUnity) return messages.notFoundByUnity.text
    if (status.notFound) return messages.notFound.text

    return null
  }, [JSON.stringify(status)])

  const noResultIcon = useCallback(() => {
    if (status.empty) return <ListAlt />
    if (status.notFoundByUnity || status.notFound) return <Search />

    return null
  }, [JSON.stringify(status)])

  const handleSearch = (values) => {
    setFormValues({ search: values })
  }

  useEffect(() => {
    if (formValues?.search) {
      getSectors({
        setSnack: snackProps.setSnack,
        setState: setData,
        search: formValues?.search,
        rowsPerPage,
        currentPage: page,
        setStatus
      })
    }
  }, [JSON.stringify(formValues?.search), page, rowsPerPage])

  const hasResult = !!items?.length

  return (
    <>
      <TemplateDefaultFormAndResult
        formHeader={
          <SearchForm
            initialValues={formValues?.search}
            handleSearch={handleSearch}
          />
        }
        iconInformative={noResultIcon()}
        labelButtonHeader="Novo setor"
        linkHeader={ROUTE.sectors.create}
        listItems={items}
        messageInformative={noResultMessage()}
        snackProps={snackProps}
        titleHeader="<strong>Sistema -</strong> Setores"
      />

      {!!hasResult && (
        <List
          sectors={items}
          limit={data?.limit}
          page={page}
          count={data?.count}
          totalPage={totalPage}
          handleSetPage={handleSetPage}
          handleChange={(e) => setRowsPerPage(e.target.value)}
        />
      )}
    </>
  )
}

export default Sectors
