import { createContext, useState, useContext } from 'react'

const initialState = {
  unitId: '',
  level: '',
  inactive: false
}

export const FormContext = createContext(initialState)
export const CoursesProvider = ({ children }) => {
  const [formValues, setFormValues] = useState(initialState)

  const resetFormValues = () => {
    setFormValues({ ...initialState })
  }

  return (
    <FormContext.Provider
      value={{
        formValues,
        setFormValues,
        resetFormValues
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useCourses = () => useContext(FormContext)
