import { useNavigate } from 'react-router-dom'
import { TemplateProfessorDiary as Template } from 'components/templates/Professor'
import { Resume, Snackbar } from 'components/molecules'
import { PageTitle } from 'components/atoms'
import { Tabs } from 'components/organisms'
import { useSnack } from 'services/hooks'
import { diaryProfessorTabs } from './tabsDiary'
import { DiaryProvider } from './context/HeaderDiary'
import { BACK_RETURN } from './constants'

const PageDiaryProfessor = ({ customValue }) => {
  const { snack, setSnack, snackOpen, updateSnack } = useSnack()
  const navigate = useNavigate()

  const params = {
    setSnack,
    updateSnack
  }

  return (
    <DiaryProvider>
      <Template
        titleHeader={
          <>
            <PageTitle
              isButton
              title="Diário de Conteúdo, Frequência e Notas"
              valueButton="Voltar"
              onClickCallToAction={() =>
                navigate(BACK_RETURN, {
                  state: { savedSearch: true }
                })
              }
              divider
            />

            <Resume />
          </>
        }
        content={
          <Tabs
            customValue={customValue}
            contents={diaryProfessorTabs.map(({ getContent }) =>
              getContent({ ...params })
            )}
            navigations={diaryProfessorTabs.map(({ navigation }) => navigation)}
          />
        }
        snackAlert={
          <Snackbar
            message={snack.message}
            severity={snack.severity}
            shouldOpen={snackOpen}
          />
        }
      />
    </DiaryProvider>
  )
}

export default PageDiaryProfessor
