import React, { lazy } from 'react'
import { TemplateChangePassword as Template } from 'components/templates/Shared'
import { Snackbar } from 'components/molecules'
import { useSnack } from 'services/hooks'
import * as Styled from './style'

const Content = lazy(() => import('./components/Content'))

const ChangePassword = () => {
  const { snack, setSnack, snackOpen } = useSnack()

  return (
    <Template
      titleHeader={<Styled.Title>Alteração de senha</Styled.Title>}
      content={<Content setSnack={setSnack} />}
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default ChangePassword
