import styled from 'styled-components'
import { Link as RouteLink } from 'react-router-dom'
import { WarningAmber } from '@mui/icons-material'

const BoxLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 8px;

  & > a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const Link = styled(RouteLink)`
  text-transform: uppercase;
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export { BoxLink, Link, IconWarningAmber }
