import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { buildTableHeader } from './Header'
import { buildTableBody } from './Body'
import { buildTableFooter } from './Footer'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export default function buildTable({ classInfo, students }) {
  return [
    {
      table: {
        body: [buildTableHeader(), ...buildTableBody({ students, classInfo })],
        widths: ['auto', '*', 'auto', 'auto', ...new Array(7).fill(50)]
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    },
    buildTableFooter(classInfo)
  ]
}
