import styled, { css } from 'styled-components'
import { Accordion as MuiAccordion } from '@mui/material'
import { ExpandMore as MuiExpandMore } from '@mui/icons-material'

const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root {
    margin: ${({ $margin }) => ($margin ? '24px 0 0 0' : 'auto')};

    &:before {
      display: none;
    }

    &.Mui-expanded {
      min-height: 0;
      margin: ${({ $hasMargin }) => ($hasMargin ? '24px 0 0 0' : '0')};
    }
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0deg);
  }
`

const ExpandMore = styled.div`
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.palette.gray.medium};
    `}
`

const ExpandMoreIcon = styled(MuiExpandMore)`
  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(180deg);
    `}
`

export { Accordion, ExpandMore, ExpandMoreIcon }
