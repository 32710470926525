const importsList = ({ showNovotecLabel }) => [
  {
    id: 'imports-students',
    name: `${showNovotecLabel ? 'NOVOTEC - ' : ''}Alunos e Turmas`,
    route: 'novotec/students',
    templatePath: '/templates/template_alunos_novotec.xlsx',
    templateUpdatedAt: '13/10/2023'
  },
  {
    id: 'imports-grades',
    name: `${showNovotecLabel ? 'NOVOTEC - ' : ''}Avaliação Integrada`,
    route: 'novotec/grades',
    templatePath: '/templates/template_notas_novotec.xlsx',
    templateUpdatedAt: '09/03/2024'
  },
  {
    id: 'activities',
    name: 'Criação de atividades',
    route: 'activities',
    templatePath: '/templates/template_criacao_atividades.xlsx',
    templateUpdatedAt: '31/01/2024'
  },
  {
    id: 'update-school-class-curriculum',
    name: 'Atualização de matriz curricular de turmas',
    route: 'update-school-class-curriculum',
    templatePath: '/templates/template_atualizar_grade_curricular_turmas.xlsx',
    templateUpdatedAt: '09/01/2024'
  }
]

export { importsList }
