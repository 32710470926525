import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useCheckedTable, usePaginationBasic, useSnack } from 'services/hooks'
import { Box, Grid, Typography } from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import { isEmpty } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import { FormSchoolClassDatails } from './components/Form'
import {
  breadCrumbsItems,
  defaultItemsPerPage,
  initialValues
} from './constants'
import TableReplaceClassDetails from './components/Table'
import { getDetails } from '../LinkedClassDetails/services'
import {
  editClass,
  getListDisciplines,
  handleTransformList,
  postReplaceClass
} from './services'
import { schema as validationSchema } from './schema'
import { isDisabledButton } from './helpers'
import * as Styled from './style'

const ReplaceClassDetails = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { setSnack } = snackProps
  const [count, setCount] = useState(null)
  const [searchParams] = useSearchParams()
  const [isUpdate, setIsUpdate] = useState(false)
  const [isPermanent, setIsPermanent] = useState(false)
  const [classDetails, setClassDetails] = useState()
  const [listSelected, setListSelected] = useState([])
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [listDisciplines, setListDisciplines] = useState([])
  const [hourlyLessonCost, setHourlyLessonCost] = useState('')
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const params = Object.fromEntries([...searchParams])
  const { isEdit, name, id, idProfessor } = params

  const {
    page: pageActual,
    totalPage,
    items,
    totalItems,
    setPage
  } = usePaginationBasic(listDisciplines, filter, count)

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    setSelected,
    selected: selectedItem
  } = useCheckedTable(items, 'id')

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const disciplines = handleTransformList(listSelected, hourlyLessonCost)

      if (isEdit === 'true') {
        editClass({
          id,
          name,
          values,
          setSnack,
          navigate,
          idProfessor,
          setSelected,
          disciplines,
          setIsUpdate,
          isEdit: true,
          setLoadingOpen,
          setListSelected
        })
      } else {
        postReplaceClass({
          id,
          name,
          values,
          setSnack,
          navigate,
          isEdit: true,
          idProfessor,
          setSelected,
          disciplines,
          setIsUpdate,
          setLoadingOpen,
          setListSelected
        })
      }
    },
    validationSchema
  })

  const handleClean = () => {
    setSelected([])
    setListSelected([])
    setHourlyLessonCost('')
  }

  const onHandleConfirm = () => {
    handleClean()
    setOpenModalConfirm(false)
    navigate(-1)
  }

  useEffect(() => {
    getDetails({
      id,
      setSnack,
      setLoadingOpen,
      setClassDetails
    })
  }, [isUpdate])

  useEffect(() => {
    getListDisciplines({
      id,
      filter,
      setSnack,
      setCount,
      pageActual,
      setLoadingOpen,
      setListDisciplines
    })
  }, [pageActual, filter, isUpdate])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      open={openModalConfirm}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      handleConfirmModal={() => onHandleConfirm()}
      handleCloseModal={() => setOpenModalConfirm(false)}
      breadcrumbsNotLink={breadCrumbsItems({
        isEdit,
        name,
        id: classDetails?.code
      })}
      onClickButton={() => {
        navigate(-1)
      }}
      content={
        <>
          <Styled.Form autoComplete="off">
            <FormSchoolClassDatails
              values={formik.values}
              valuesForm={classDetails}
              hourlyLessonCost={hourlyLessonCost}
              setFieldValue={formik.setFieldValue}
              setHourlyLessonCost={setHourlyLessonCost}
            />
          </Styled.Form>

          {listDisciplines && !isEmpty(listDisciplines) && (
            <>
              <TableReplaceClassDetails
                items={items}
                filter={filter}
                setPage={setPage}
                count={totalItems}
                setFilter={setFilter}
                totalPage={totalPage}
                pageActual={pageActual}
                isSelected={isSelected}
                handleClick={handleClick}
                setSelected={setSelected}
                isEdit={isEdit === 'true'}
                selectedItem={selectedItem}
                setListSelected={setListSelected}
                setListDisciplines={setListDisciplines}
                handleSelectAllClick={handleSelectAllClick}
              />
              <Grid item xs={12} sm={12} md={6}>
                <Styled.BoxGroupingCaption>
                  <Box className="caption">
                    <Typography variant="body1">A</Typography>
                  </Box>
                  <Typography variant="caption">Em agrupamento</Typography>
                </Styled.BoxGroupingCaption>
              </Grid>

              <Grid marginTop="30px" item xs={12} sm={12} md={12}>
                <ButtonFooterV2
                  size="large"
                  labelClose="Cancelar"
                  labelConfirm="salvar substituição"
                  disabledConfirm={
                    isDisabledButton(formik.values) ||
                    isEmpty(listSelected) ||
                    isEmpty(hourlyLessonCost)
                  }
                  onClickClose={() => setOpenModalConfirm(true)}
                  onClickConfirm={() => formik.handleSubmit()}
                  classNameCancel={TAG_MANAGER.rh_btn_cancela_vinculo}
                  classNameBtnConfirm={TAG_MANAGER.rh_btn_salva_vinculo}
                />
              </Grid>
            </>
          )}
        </>
      }
    />
  )
}

export default ReplaceClassDetails
