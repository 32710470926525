const initialValues = {
  cnpj: '',
  rg_fin: '',
  ieCnpj: '',
  uf_fin: '',
  cep_fin: '',
  end_fin: '',
  tel_fin: '',
  cpf_fin: '',
  comp_fin: '',
  sexo_fin: '',
  nasc_fin: '',
  nome_fin: '',
  email_fin: '',
  metUs_fin: '',
  civil_fin: '',
  nacio_fin: '',
  cidade_fin: '',
  numero_fin: '',
  bairro_fin: '',
  observacao_fin: '',
  nome_social_fin: '',
  mobile_phone_fin: '',
  naturalidade_uf_fin: ''
}

const allowedValues = [
  'Brasileiro',
  'brasileiro',
  'Brasileira',
  'Brasil',
  'Brasileiro(a)',
  'brasileiro (a)',
  'brasileira',
  'brasil',
  'brasileiro(a)',
  'brasileiro (a)'
]

export { initialValues, allowedValues }
