import { lazy, useEffect, useState } from 'react'
import { ListAlt, Search } from '@mui/icons-material'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { useActionSelected, usePaginationBasic, useSnack } from 'services/hooks'
import {
  isEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import { ROUTE } from 'services/constants'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getCurriculum } from 'services/api/admin'
import { Form, Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { defaultTotalPage } from 'services/hooks/constants'
import { defaultItemsPerPage } from './components/Table/constants'
import {
  MESSAGE,
  countDefault,
  defaultPage,
  initialState,
  nameAccordion,
  newCurriculum,
  typeAction
} from './constants'
import { useCurriculum } from './Context/FormnContext'
import { getListCourses } from './services'

const FormCurriculum = lazy(() => import('./components/Form'))
const Table = lazy(() => import('./components/Table'))

const ListCurriculum = () => {
  const snackProps = useSnack()
  const location = useLocation()
  const navigate = useNavigate()

  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [valuesForm, setValuesForm] = useState(null)
  const [listCurriculum, setListCurriculum] = useState([])
  const [count, setCount] = useState(null)
  const { onActionSelected } = useActionSelected()
  const [listCourse, setListCourse] = useState([])

  const isSearchParam = location?.state?.savedSearch

  const { formValues, setFormValues, resetFormValues } = useCurriculum()
  const { setSnack } = snackProps
  const {
    page: pageActual,
    totalPage,
    items,
    setPage
  } = usePaginationBasic(listCurriculum, filter, count)

  const handleSearch = async (values) => {
    setNotSearch(false)
    setLoadingOpen(true)

    const params = {
      ...(values?.course && { course: values?.course }),
      ...(values?.level && { level: values?.level }),
      ...(values?.IsInactive && {
        active: !values?.IsInactive
      }),
      limit: filter || defaultItemsPerPage,
      page: pageActual || defaultPage
    }
    const { data, error, status } = await getCurriculum(params)
    setLoadingOpen(false)
    setFormValues(values)
    setValuesForm(values)

    if (error) {
      setNotSearch(true)
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCurriculum
      })
    }

    if (data?.count === countDefault) {
      setNotSearch(false)

      return setListCurriculum([])
    }

    setListCurriculum(data?.data)
    setCount(data?.count)
    return setNotSearch(true)
  }

  useEffect(() => {
    if (isSearchParam && (formValues?.course || formValues?.level)) {
      return handleSearch(formValues)
    }
    return setFormValues(initialState)
  }, [isSearchParam])

  useEffect(() => {
    if (valuesForm !== null) {
      handleSearch(valuesForm)
    }
  }, [pageActual, filter])

  useEffect(
    () =>
      getListCourses({
        setListCourse,
        levels: formValues?.level,
        setSnack
      }),
    []
  )

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  const onClear = () => {
    resetFormValues()
    setLoadingOpen(false)
    setNotSearch(true)
    setListCurriculum([])

    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }
  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  return (
    <TemplateDefaultFormAndResult
      titleHeader="Gestão - Matriz Curricular"
      snackProps={snackProps}
      loadingOpen={loadingOpen}
      listItems={listCurriculum}
      labelButtonHeader="Nova matriz"
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      onClickButton={() => {
        navigate(ROUTE.ADMIN_MANAGEMENT_CURRICULUM_CREATE, {
          state: { newCurriculum }
        })
      }}
      formHeader={
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSearch}
            enableReinitialize
          >
            {({ ...props }) => (
              <>
                <Form noValidate autoComplete="off">
                  <FormCurriculum
                    {...props}
                    onClear={onClear}
                    setPage={setPage}
                    setFilter={setFilter}
                    setSnack={snackProps.setSnack}
                    isSearchParam={isSearchParam}
                    initialState={initialValues}
                    handleSearch={handleSearch}
                    nameAccordion={nameAccordion}
                    setListCourse={setListCourse}
                    listCourse={listCourse}
                  />
                </Form>
              </>
            )}
          </Formik>
        </>
      }
    >
      {listCurriculum && !isEmpty(listCurriculum) && (
        <Table
          rowsItems={items}
          count={count}
          setLoadingOpen={setLoadingOpen}
          setSnack={snackProps.setSnack}
          handleSearch={handleSearch}
          valuesForm={valuesForm}
          setFilter={setFilter}
          setPage={setPage}
          totalPage={totalPage}
          filter={filter}
          pageActual={pageActual}
          onValues={(item) => onActionSelected(typeAction.changeOfValues, item)}
          setListCourse={setListCourse}
          listCourse={listCourse}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ListCurriculum
