const ADMIN = '/admin'
const ADMIN_FINANCIAL = `${ADMIN}/financial`
const ADMIN_SECRETARY = `${ADMIN}/secretary`
const ADMIN_COMMERCIAL = `${ADMIN}/commercial`
const ADMIN_HR = `${ADMIN}/human-resources`
const ADMIN_MANAGEMENT = `${ADMIN}/management`
const ADMIN_SYSTEM = `${ADMIN}/system`
const ADMIN_PDF = `${ADMIN}/pdf`

const ROUTE_FINANCIAL = {
  ADMIN_FINANCIAL_CREATE_CAMPAIGN: `${ADMIN_FINANCIAL}/create/campaign`,
  ADMIN_FINANCIAL_UPDATE_CAMPAIGN: `${ADMIN_FINANCIAL}/update/campaign`,
  ADMIN_FINANCIAL_CAMPAIGNS: `${ADMIN_FINANCIAL}/campaigns`,
  ADMIN_FINANCIAL_BILLS_RECEIVE: `${ADMIN_FINANCIAL}/bills-receive`,
  ADMIN_FINANCIAL_BANK_BILLING_EDIT: `${ADMIN_FINANCIAL}/bank-billing/financial-breakdown/edit`,
  ADMIN_FINANCIAL_BANK_BILLING_CREATE: `${ADMIN_FINANCIAL}/bank-billing/financial-breakdown/create`,
  ADMIN_FINANCIAL_INVOICES: `${ADMIN_FINANCIAL}/invoices`,
  ADMIN_FINANCIAL_INVOICES_BANK_BILLING_EDIT: `${ADMIN_FINANCIAL}/invoices/bank-billing/edit`,
  ADMIN_FINANCIAL_BANK_RETURN: `${ADMIN_FINANCIAL}/bank-return`,
  ADMIN_FINANCIAL_BREAKDOWN: `${ADMIN_FINANCIAL}/bank-return/financial-breakdown`,
  ADMIN_FINANCIAL_BREAKDOWN_NEW_BANK_BILLING: `${ADMIN_FINANCIAL}/bank-return/financial-breakdown/new-bank-billing`,
  ADMIN_FINANCIAL_GROUPS: `${ADMIN_FINANCIAL}/groups`,
  ADMIN_FINANCIAL_GROUPS_CREATE: `${ADMIN_FINANCIAL}/groups/create`,
  ADMIN_FINANCIAL_GROUPS_EDIT: `${ADMIN_FINANCIAL}/groups/edit`,
  ADMIN_FINANCIAL_PAYMENT_PLAN: `${ADMIN_FINANCIAL}/payment-plans`,
  ADMIN_FINANCIAL_REGISTER_SUPPLIER: `${ADMIN_FINANCIAL}/register-supplier`,
  ADMIN_FINANCIAL_REGISTER_SUPPLIER_CREATE: `${ADMIN_FINANCIAL}/register-supplier/create`,
  ADMIN_FINANCIAL_REGISTER_SUPPLIER_EDIT: `${ADMIN_FINANCIAL}/register-supplier/edit`,
  ADMIN_FINANCIAL_PAYMENT_PLAN_CREATE: `${ADMIN_FINANCIAL}/payment-plans/create`,
  ADMIN_FINANCIAL_PAYMENT_PLAN_EDIT: `${ADMIN_FINANCIAL}/payment-plans/edit`,
  ADMIN_FINANCIAL_CUPON: `${ADMIN_FINANCIAL}/cupons`,
  ADMIN_FINANCIAL_CUPON_CREATE: `${ADMIN_FINANCIAL}/cupons/create`,
  ADMIN_FINANCIAL_CUPON_EDIT: `${ADMIN_FINANCIAL}/cupons/edit`,
  ADMIN_FINANCIAL_MANAGER: `${ADMIN_FINANCIAL}/manager`
}

const ROUTE_SECRETARY = {
  ADMIN_SECRETARY_GROUPING: `${ADMIN_SECRETARY}/grouping`,
  ADMIN_SECRETARY_GROUPING_MANAGER: `${ADMIN_SECRETARY}/grouping/manager`,
  ADMIN_SECRETARY_GROUPING_INCLUDE_DISCIPLINE: `${ADMIN_SECRETARY}/grouping/include-discipline`,
  ADMIN_SECRETARY_GROUPING_DIARY: `${ADMIN_SECRETARY}/grouping/diary`,
  ADMIN_SECRETARY_ADDITIONAL_ACTIVITIES_AVALIATION: `${ADMIN_SECRETARY}/additional-activities-avaliation`,
  ADMIN_SECRETARY_OCCURRENCES: `${ADMIN_SECRETARY}/occurrences`,
  ADMIN_SECRETARY_OCCURRENCES_EDIT: `${ADMIN_SECRETARY}/occurrences/edit`,
  ADMIN_SECRETARY_OCCURRENCES_CREATE: `${ADMIN_SECRETARY}/occurrences/create`,
  ADMIN_SECRETARY_REQUISITION: `${ADMIN_SECRETARY}/requisition`,
  ADMIN_SECRETARY_REQUISITION_EDIT: `${ADMIN_SECRETARY}/requisition/edit`,
  ADMIN_SECRETARY_REQUISITION_CREATE: `${ADMIN_SECRETARY}/requisition/create`,
  ADMIN_SECRETARY_STUDENTS: `${ADMIN_SECRETARY}/students`,
  ADMIN_SECRETARY_STUDENTS_CREATE: `${ADMIN_SECRETARY}/students/list`,
  ADMIN_SECRETARY_STUDENTS_FINALIZE: `${ADMIN_SECRETARY}/students/create/finalize`,
  ADMIN_SECRETARY_STUDENTS_EDIT: `${ADMIN_SECRETARY}/students/edit`,
  ADMIN_SECRETARY_STUDENTS_REGISTER: `${ADMIN_SECRETARY}/students/register`,
  ADMIN_SECRETARY_STUDENTS_REGISTER_EXPEDITION: `${ADMIN_SECRETARY}/students/register/expedition`,
  ADMIN_SECRETARY_STUDENTS_CLASS_DISCIPLINE: `${ADMIN_SECRETARY}/students/class-discipline`,
  ADMIN_SECRETARY_STUDENTS_CLASS_DISCIPLINE_LINK: `${ADMIN_SECRETARY}/students/class-discipline-link`,
  ADMIN_SECRETARY_STUDENTS_SCORE: `${ADMIN_SECRETARY}/students/register/score`,
  ADMIN_SECRETARY_STUDENTS_DECLARATION: `${ADMIN_SECRETARY}/students/declaration`,
  ADMIN_SECRETARY_STUDENTS_CREATE_DECLARATION: `${ADMIN_SECRETARY}/students/declaration/create`,
  ADMIN_SECRETARY_STUDENTS_ADDITIONAL_ACTIVITIES: `${ADMIN_SECRETARY}/students/additional-activies`,
  ADMIN_SECRETARY_STUDENTS_JUSTIFY_ABSENCE: `${ADMIN_SECRETARY}/students/justify-absence`,
  ADMIN_SECRETARY_STUDENTS_USE_OF_STUDIES: `${ADMIN_SECRETARY}/students/use-of-studies`,
  ADMIN_SECRETARY_STUDENTS_INTERACTIONS: `${ADMIN_SECRETARY}/students/interactions`,
  ADMIN_SECRETARY_SCHOOL_CLASS: `${ADMIN_SECRETARY}/classes`,
  SECRETARY_REGISTER_OCCURRENCES_EDIT: `${ADMIN_SECRETARY}/students/register/occurrences/edit`,
  SECRETARY_REGISTER_OCCURRENCES_CREATE: `${ADMIN_SECRETARY}/students/register/occurrences/create`,
  ADMIN_SECRETARY_SCHOOL_CLASS_CREATE: `${ADMIN_SECRETARY}/classes/create`,
  ADMIN_SECRETARY_SCHOOL_REENROLL: `${ADMIN_SECRETARY}/classes/reenroll`,
  ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS: `${ADMIN_SECRETARY}/classes/class-details`,
  ADMIN_SECRETARY_SCHOOL_CLASS_BIND_UNBINDED: `${ADMIN_SECRETARY}/classes/bind-unbinded`,
  ADMIN_SECRETARY_SCHOOL_CLASS_STUDENTLIST: `${ADMIN_SECRETARY}/classes/student-list`,
  ADMIN_SECRETARY_SCHOOL_CLASS_EDIT_DISCIPLINE: `${ADMIN_SECRETARY}/classes/edit-discipline`,
  ADMIN_SECRETARY_PROFESSOR_DIARY: `${ADMIN_SECRETARY}/diary`,
  ADMIN_SECRETARY_DOCUMENTS: `${ADMIN_SECRETARY}/documents`,
  ADMIN_SECRETARY_CONTRACTS: `${ADMIN_SECRETARY}/contracts`,
  ADMIN_SECRETARY_ACADEMIC_PERFORMANCES: `${ADMIN_SECRETARY}/academic-performances`,
  ADMIN_SECRETARY_ACADEMIC_PERFORMANCES_EDIT: `${ADMIN_SECRETARY}/academic-performances/edit`,
  ADMIN_SECRETARY_STUDENTS_HISTORY: `${ADMIN_SECRETARY}/history`,
  ADMIN_SECRETARY_STUDENTS_NEW_DOCUMENT_HISTORY: `${ADMIN_SECRETARY}/history/new-document`,
  ADMIN_SECRETARY_CLASSES_DASHBOARD: `${ADMIN_SECRETARY}/dashboard`,
  ADMIN_SECRETARY_CLASSES_DASHBOARD_CLASS: `${ADMIN_SECRETARY}/dashboard/turma`
}

const ROUTE_COMMERCIAL = {
  ADMIN_COMMERCIAL_REGISTRATION: `${ADMIN_COMMERCIAL}/registration`,
  ADMIN_COMMERCIAL_REGISTRATION_CREATE: `${ADMIN_COMMERCIAL}/registration/create`,
  ADMIN_COMMERCIAL_REGISTRATION_SUBSCRIPTION: `${ADMIN_COMMERCIAL}/registration/subscription`,
  ADMIN_COMMERCIAL_ENROLLMENT_PAYMENT: `${ADMIN_COMMERCIAL}/enrollment-payment`,
  ADMIN_COMMERCIAL_DIRECT_ENROLLMENT: `${ADMIN_COMMERCIAL}/direct-enrollment`
}

const ROUTE_MANAGEMENT = {
  ADMIN_MANAGEMENT_OCCURRENCE: `${ADMIN_MANAGEMENT}/occurrence`,
  ADMIN_MANAGEMENT_OCCURRENCE_CREATE: `${ADMIN_MANAGEMENT}/occurrence/create`,
  ADMIN_MANAGEMENT_TYPE_REQUIREMENTS: `${ADMIN_MANAGEMENT}/types-requirements`,
  ADMIN_MANAGEMENT_TYPE_ACTIVITY_CREATE: `${ADMIN_MANAGEMENT}/types-activity/create`,
  ADMIN_MANAGEMENT_TYPE_ACTIVITY_EDIT: `${ADMIN_MANAGEMENT}/types-activity/edit`,
  ADMIN_MANAGEMENT_TYPE_ACTIVITY: `${ADMIN_MANAGEMENT}/types-activity`,
  ADMIN_MANAGEMENT_TYPE_REQUIREMENTS_CREATE: `${ADMIN_MANAGEMENT}/types-requirements/create`,
  ADMIN_MANAGEMENT_SUBJECTS: `${ADMIN_MANAGEMENT}/subjects`,
  ADMIN_MANAGEMENT_SUBJECTS_CREATE: `${ADMIN_MANAGEMENT}/subjects/create`,
  ADMIN_MANAGEMENT_SUBJECTS_EDIT: `${ADMIN_MANAGEMENT}/subjects/edit`,
  ADMIN_MANAGEMENT_SUBJECTS_PLAN_CREATE: `${ADMIN_MANAGEMENT}/subjects/teaching-plan/create`,
  ADMIN_MANAGEMENT_SUBJECTS_REGISTER_EAD_CONTENT: `${ADMIN_MANAGEMENT}/subjects/register-ead-content`,
  ADMIN_MANAGEMENT_LIST_CURRICULUM: `${ADMIN_MANAGEMENT}/curriculum`,
  ADMIN_MANAGEMENT_CURRICULUM_CREATE: `${ADMIN_MANAGEMENT}/curriculum/create`,
  ADMIN_MANAGEMENT_CURRICULUM_EDIT: `${ADMIN_MANAGEMENT}/curriculum/edit`,
  ADMIN_MANAGEMENT_UNITS_POLE: `${ADMIN_SECRETARY}/units`,
  ADMIN_MANAGEMENT_UNIT_CREATE: `${ADMIN_SECRETARY}/units/create`,
  ADMIN_MANAGEMENT_UNIT_EDIT: `${ADMIN_SECRETARY}/units/edit`,
  ADMIN_MANAGEMENT_POLE: `${ADMIN_SECRETARY}units/pole`,
  ADMIN_MANAGEMENT_POLE_CREATE: `${ADMIN_SECRETARY}/units/pole/create`,
  ADMIN_MANAGEMENT_POLE_EDIT: `${ADMIN_SECRETARY}units/pole/edit`,
  ADMIN_MANAGEMENT_COURSE: `${ADMIN_SECRETARY}/course`,
  ADMIN_MANAGEMENT_COURSE_CREATE: `${ADMIN_SECRETARY}/course/create`,
  ADMIN_MANAGEMENT_COURSE_EDIT: `${ADMIN_SECRETARY}/course/edit`
}

const HR_ROUTES = {
  ADMIN_HR_PROFESSOR: `${ADMIN_HR}/professor`,
  ADMIN_HR_CREATE_PROFESSOR: `${ADMIN_HR}/professor/create`,
  ADMIN_HR_DETAILS_PROFESSOR: `${ADMIN_HR}/professor/details`,
  ADMIN_HR_PROFESSOR_REPLACE_CLASS: `${ADMIN_HR}/professor/replace-class`,
  ADMIN_HR_PROFESSOR_CLASS_LINKED: `${ADMIN_HR}/professor/bond-class`,
  ADMIN_HR_PROFESSOR_CLASS_LINKED_DETAILS: `${ADMIN_HR}/professor/bond-class-details`,
  ADMIN_HR_PROFESSOR_CLASS_LINKED_DETAILS_EDIT: `${ADMIN_HR}/professor/bond-class-details/edit`,
  ADMIN_HR_PROFESSOR_REPLACE_CLASS_DETAILS: `${ADMIN_HR}/professor/replace-class-details`,
  ADMIN_HR_PROFESSOR_REPLACE_CLASS_DETAILS_EDIT: `${ADMIN_HR}/professor/replace-class-details/edit`,
  ADMIN_HR_CREATE_DOCUMENT_FILES: `${ADMIN_HR}/professor/document-files`,
  ADMIN_HR_CREATE_INTERACTIONS: `${ADMIN_HR}/professor/interactions`,
  ADMIN_HR_TIMEKEEPING: `${ADMIN_HR}/timekeeping`,
  ADMIN_HR_CREATE_TIMEKEEPING: `${ADMIN_HR}/timekeeping/create`,
  ADMIN_HR_EDIT_TIMEKEEPING: `${ADMIN_HR}/timekeeping/edit`,
  ADMIN_HR_MANAGE_CLASSES: `${ADMIN_HR}/manage-classes`,
  ADMIN_HR_MANAGE_CLASSES_DETAILS: `${ADMIN_HR}/manage-classes/details`,
  ADMIN_HR_MANAGE_RECEIPTS: `${ADMIN_HR}/manage-receipts`,
  ADMIN_HR_MANAGE_CONSOLIDATION: `${ADMIN_HR}/consolidation`
}

const ROUTE_SYSTEM = {
  documentTypes: {
    list: `${ADMIN_SYSTEM}/document-types`,
    unifyEnrollment: `${ADMIN_SYSTEM}/unify-enrollment`
  },

  sectors: {
    create: `${ADMIN_SYSTEM}/sectors/create`,
    edit: `${ADMIN_SYSTEM}/sectors/edit`,
    list: `${ADMIN_SYSTEM}/sectors`
  },

  LIST_DECLARATIONS: `${ADMIN_SYSTEM}/declarations`,
  EDIT_DECLARATIONS: `${ADMIN_SYSTEM}/declarations/edit`,
  CREATE_DECLARATIONS: `${ADMIN_SYSTEM}/declarations/create`,

  contracts: {
    LIST_CONTRACTS: `${ADMIN_SYSTEM}/contracts`,
    EDIT_CONTRACTS: `${ADMIN_SYSTEM}/contracts/edit`,
    CREATE_CONTRACTS: `${ADMIN_SYSTEM}/contracts/create`
  },

  logs: {
    USERS: `${ADMIN_SYSTEM}/logs-users`
  },
  imports: {
    LIST: `${ADMIN_SYSTEM}/imports`
  }
}

const PDF_ROUTE = {
  ADMIN_PDF_POC: `${ADMIN_PDF}/poc`
}

export const ADMIN_ROUTE = {
  ADMIN: '/admin',
  ...ROUTE_FINANCIAL,
  ...ROUTE_SECRETARY,
  ...ROUTE_COMMERCIAL,
  ...ROUTE_MANAGEMENT,
  ...ROUTE_SYSTEM,
  ...HR_ROUTES,
  ...PDF_ROUTE
}
