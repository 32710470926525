/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-danger */
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { Delete, RemoveRedEye } from '@mui/icons-material'
import { IconButton, TableRow, Tooltip } from '@mui/material'
import { ROUTE } from 'services/constants'
import { formatStringTagToString, makeURLAccessLegacy } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'

const TableFooter = ({ row, onManualEnrollment, isItemSelected }) => {
  const navigate = useNavigate()
  const [isMouseOver, setIsMouseOver] = useState(false)

  const handlePush = () => {
    const legacyUrl = makeURLAccessLegacy(
      `acesso/sistema_declaracao_pdf.php?idDeclaracao=${row?.id}`
    )
    window.open(legacyUrl, '_blank')
  }

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row?.id}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      selected={isItemSelected}
    >
      <Styled.StyledTableCellTitle>
        <div className="content">
          <Styled.BoxLink
            onClick={() =>
              navigate(ROUTE.EDIT_DECLARATIONS, {
                state: { savedSearch: true, row }
              })
            }
            className={TAG_MANAGER.sistema_btn_acessa_declaracao}
          >
            {row?.name}
          </Styled.BoxLink>
          {isMouseOver && (
            <div className="container_icons">
              <IconButton
                disableRipple
                onClick={() => {
                  handlePush()
                  onManualEnrollment('modalView', row)
                }}
                className={TAG_MANAGER.sistema_btn_visualiza_declaracao}
              >
                <Tooltip title="Visualizar" placement="top">
                  <RemoveRedEye />
                </Tooltip>
              </IconButton>
              <IconButton
                disableRipple
                className={TAG_MANAGER.sistema_btn_exclui_declaracao}
                onClick={() => onManualEnrollment('modalConfirm', row)}
              >
                <Tooltip title="Excluir" placement="top">
                  <Delete />
                </Tooltip>
              </IconButton>
            </div>
          )}
        </div>
      </Styled.StyledTableCellTitle>

      <Styled.StyledTableCell $isLevel>{row?.level}</Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.type}</Styled.StyledTableCell>
      <Styled.StyledTableCell $maxHeight>
        {formatStringTagToString(row?.body)}
      </Styled.StyledTableCell>
    </TableRow>
  )
}

export default TableFooter
