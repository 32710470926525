import styled from 'styled-components'
import {
  RadioGroup as MuiRadioGroup,
  Card as MuiCard,
  Typography
} from '@mui/material'
import { AddBox, Delete } from '@mui/icons-material'

const Card = styled(MuiCard)`
  &.MuiCard-root {
    padding: 16px;
  }
`

const CardIconAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    -webkit-flex-wrap: nowrap;
    justify-content: space-around;
    margin: 16px;
  }
`
const IconAdd = styled(AddBox)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    fill: ${({ theme }) => theme.palette.primary.main};
    outline: none;
    border: 0px;
    margin-left: 16px;
  }
`
const IconRemove = styled(Delete)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.red.light};
    outline: none;
    border: 0px;
    margin: 16px;
  }
`

const SubTitle = styled(Typography)`
  &.MuiTypography-root {
    margin: 24px 0;
  }
`

export { RadioGroup, IconAdd, IconRemove, Card, CardIconAdd, SubTitle }
