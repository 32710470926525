const initialValues = {
  register: '',
  unit: '',
  pole: '',
  group: '',
  level: '',
  course: '',
  module: '',
  modality: '',
  shift: '',
  workload: null,
  observation: '',
  school_class_id: '',
  school_class_code: ''
}

const isDisabledButton = (values) => {
  const { unit } = values

  const disabled = !unit

  return disabled
}

const modalityList = [
  {
    id: 1,
    value: 'Presencial',
    label: 'Presencial'
  },
  {
    id: 3,
    value: 'Online',
    label: 'Online'
  }
]

const MESSAGE = {
  SUCCESS: 'Matrícula em novo curso realizada com sucesso.',
  NO_SEARCH:
    '<b>Encontre os cursos disponíveis aqui</b>Você precisa selecionar ao menos a unidade e o nível para listar os cursos disponíveis. </br> </br> Para ser mais específico, pode informar o Curso, Turno e Modalidade.',
  MSG_NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br />Verifique os dados informados e tente novamente.'
}

const defaultItemsPerPage = 10
const defaultPage = 1
const countDefault = 0

const SUCCESS = 'success'

export {
  initialValues,
  modalityList,
  MESSAGE,
  SUCCESS,
  defaultItemsPerPage,
  defaultPage,
  countDefault,
  isDisabledButton
}
