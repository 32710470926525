const boxList = ({
  unit,
  group,
  modality,
  pole,
  level,
  course,
  shift,
  module,
  avaliationMethod,
  minimumGrade,
  minimumFrequency,
  recoveryMin,
  reclassificationMin,
  modules
}) => {
  const newModule = modules?.find((item) => item.value === module)

  return [
    {
      title: 'Curso',
      subTitle: course,
      grid: 12
    },
    {
      title: 'Unidade',
      subTitle: unit
    },
    ...((pole !== '' && [
      {
        title: 'Polo',
        subTitle: pole
      }
    ]) ||
      []),
    {
      title: 'Grupo',
      subTitle: group
    },
    {
      title: 'Método de Avaliação',
      subTitle: avaliationMethod
    },
    {
      title: 'Modalidade',
      subTitle: modality
    },
    {
      title: 'Nível',
      subTitle: level
    },
    {
      title: 'Turno',
      subTitle: shift
    },

    {
      title: 'Módulo',
      subTitle: newModule?.label || ''
    },
    {
      title: 'Nota mínima de aprovação',
      subTitle: minimumGrade
    },
    {
      title: 'Nota mínima de recuperação',
      subTitle: recoveryMin || '---'
    },
    {
      title: 'Nota mínima de reclassificação',
      subTitle: reclassificationMin || '---'
    },
    {
      title: 'Percentual máximo de faltas',
      subTitle: `${minimumFrequency}%`
    }
  ]
}

export { boxList }
