import styled from 'styled-components'
import { Box as MuiBox } from '@mui/material'

const Box = styled(MuiBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div {
    margin: 0 0 20px;
  }

  a {
    margin: 0;
  }
`

export { Box }
