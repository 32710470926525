export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const indexDefault = -1
const headCellsPending = [
  {
    id: 'name',
    label: 'Tipo de registro'
  },
  {
    id: 'amount',
    label: 'Quantidade'
  },
  {
    id: 'situation',
    label: 'Situação'
  }
]

export { headCellsPending }
