import { getCostCenterUnit } from 'services/api/admin'
import { messageErroOrSuccess } from 'services/helpers'

export const getContractBranchList = async ({
  setListContractBranch,
  setLoadingVisibility,
  setSnack
}) => {
  setLoadingVisibility(true)

  const {
    error,
    status,
    data: { data }
  } = await getCostCenterUnit()

  await messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  const listFormatted = (data ?? []).map((item) => ({
    label: item.name,
    value: item.id
  }))

  setListContractBranch(listFormatted)
  setLoadingVisibility(false)
}
