import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import { useFormikContext } from 'formik'
import { Tooltip } from '@mui/material'
import { ListAlt } from '@mui/icons-material'

import { Button, TextNotFound } from 'components/atoms'
import { usePaginationBasic } from 'services/hooks'
import { defaultlistPerPage } from 'services/hooks/constants'

import { FormationModal, List } from './components'
import { MAX_FORMATION } from './constants'

import * as Styled from './style'

const TabSchoolData = ({ handleUpdateData }) => {
  const fieldNameSchoolData = 'schoolData'

  const { values } = useFormikContext()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])

  const [modal, setModal] = useState({ open: false, item: {} })
  const [countPeerPage, setCountPeerPage] = useState(defaultlistPerPage)

  const { items, handleSetPage, totalPage, page } = usePaginationBasic(
    values?.[fieldNameSchoolData],
    countPeerPage
  )

  const handleAddFormation = () => {
    setModal({ open: true, item: undefined })
  }

  const handleEditFormation = (item) => {
    setModal({ open: true, item })
  }

  const handleCloseModal = () => setModal({ open: false, item: undefined })

  const callbackSuccess = () => {
    handleCloseModal()

    setTimeout(() => handleUpdateData(), 800)
  }

  const hasItem = !!items?.length
  const isAvailableToAddFormation = items?.length < MAX_FORMATION

  return (
    <Styled.Container>
      {!hasItem && (
        <Styled.EmptyState
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <TextNotFound
            widthText="385px"
            icon={<ListAlt />}
            text="<strong>Ainda não há nenhuma formação cadastrada.</strong><br />Adicione uma nova
            clicando no botão abaixo."
          />
          <Button onClick={handleAddFormation}>Nova formação</Button>
        </Styled.EmptyState>
      )}

      {hasItem && (
        <Styled.WrapperList
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <List
            limit={countPeerPage}
            page={page}
            items={items}
            totalPage={totalPage}
            handleSetPage={handleSetPage}
            handleChange={(e) => setCountPeerPage(e.target.value)}
            handleEdit={handleEditFormation}
          />

          {isAvailableToAddFormation && (
            <Styled.WrapperIcon>
              <Tooltip title="Adicionar formação" onClick={handleAddFormation}>
                <Styled.IconAdd />
              </Tooltip>
            </Styled.WrapperIcon>
          )}

          {!isAvailableToAddFormation && (
            <Styled.WarningMessage>
              Só é possível adicionar duas formações. Você pode editá-las
              clicando no nível de cada uma.
            </Styled.WarningMessage>
          )}
        </Styled.WrapperList>
      )}

      {modal?.open && (
        <FormationModal
          initialValuesToEdit={modal.item}
          open={modal.open}
          handleClose={handleCloseModal}
          registerId={params?.register}
          callbackSuccess={callbackSuccess}
          itemsList={items}
        />
      )}
    </Styled.Container>
  )
}

export default TabSchoolData
