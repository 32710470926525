import { lazy, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form as FormikForm, Formik } from 'formik'
import { ListAlt, Search } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { convertToParams, isEmpty, mergeInitialValues } from 'services/helpers'
import {
  MESSAGE,
  REGISTRATION,
  colors,
  countDefault,
  createdSuccess,
  defaultItemsPerPage,
  listBigNumbers,
  nameAccordion
} from './constants'
import { onSubmitRequirements } from './services'
import { initialValues } from './config'
import { useStore } from './Store/useStore'
import schema from './schema'
import * as Styled from './style'
import { BigNumbers } from './components/Cards'

const Table = lazy(() => import('./components/Table'))
const Form = lazy(() => import('./components/Form'))

const ListRequisition = () => {
  const snackProps = useSnack()
  const location = useLocation()
  const navigate = useNavigate()
  const [requirements, setRequirements] = useState([])
  const [loading, setLoading] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [expanded, setExpanded] = useState(nameAccordion)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [valuesForm, setValuesForm] = useState(null)
  const [count, setCount] = useState(countDefault)
  const [listSector, setListSector] = useState([])
  const [listTypes, setListTypes] = useState([])
  const [isActiveSector, setIsActiveSector] = useState(true)
  const [bigNumbers, setBigNumbers] = useState({})
  const { setSnack, snackOpen } = snackProps

  const { formValues, setFormValues, resetFormValues, open, toggleOpen } =
    useStore()

  const { page, totalPage, items, setPage } = usePaginationBasic(
    requirements,
    filter,
    count
  )

  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])
  const { register } = params
  const isSearchParam = location?.state?.savedSearch

  const defaultFormValues = {
    ...initialValues,
    ...(register && { searchType: REGISTRATION, searchBy: register })
  }

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.MSG_NOT_FOUND_SEARCH

  const onSubmit = async (values) =>
    onSubmitRequirements({
      filter,
      listSector,
      listTypes,
      page,
      register,
      setBigNumbers,
      setCount,
      setExpanded,
      setFormValues,
      setLoading,
      setNotSearch,
      setRequirements,
      setValuesForm,
      values
    })

  const cancelAutomaticSearch = () => {
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  useEffect(() => {
    const values = (register && defaultFormValues) || valuesForm
    if (values) {
      onSubmit(values)
    }
  }, [page, filter, register])

  useEffect(() => {
    if (!isSearchParam && !register) {
      resetFormValues()
      cancelAutomaticSearch()
      return setRequirements([])
    }
    return isSearchParam && formValues !== null && onSubmit(formValues)
  }, [isSearchParam])

  useEffect(() => {
    if (open) setSnack(createdSuccess)
  }, [open])

  useEffect(() => {
    if (open && !snackOpen) {
      toggleOpen()
    }
  }, [open, snackOpen])

  const onClear = () => {
    setValuesForm(null)
    resetFormValues()
    setRequirements([])
    setNotSearch(true)
    cancelAutomaticSearch()
  }

  const initialState = mergeInitialValues(
    defaultFormValues,
    isSearchParam && formValues
  )

  const linkHeader = `${ROUTE.ADMIN_SECRETARY_REQUISITION_CREATE}${
    !formValues?.searchBy
      ? ''
      : `?${convertToParams({
          searchType: formValues.searchType,
          searchBy: formValues.searchBy
        })}`
  }`

  return (
    <TemplateDefaultFormAndResult
      classNameHeader={TAG_MANAGER.secretary_btn_create_requirement}
      titleHeader="Secretaria - Requerimento"
      labelButtonHeader="novo requerimento"
      snackProps={snackProps}
      nameAccordion={nameAccordion}
      loadingOpen={loading}
      listItems={requirements}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      linkHeader={linkHeader}
      expanded={expanded}
      setExpanded={setExpanded}
      accordion={
        <Formik
          initialValues={initialState}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={schema}
        >
          {(props) => (
            <FormikForm
              onSubmit={props.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Form
                {...props}
                setFilter={setFilter}
                setPage={setPage}
                setSnack={setSnack}
                onClear={onClear}
                setListSector={setListSector}
                listSector={listSector}
                isActiveSector={isActiveSector}
                listTypes={listTypes}
                setListTypes={setListTypes}
                setValuesForm={setValuesForm}
              />
            </FormikForm>
          )}
        </Formik>
      }
    >
      {requirements && !isEmpty(requirements) && (
        <>
          <Styled.Typography variant="overline">
            Informações sobre requerimentos
          </Styled.Typography>

          <BigNumbers
            listCards={listBigNumbers}
            colorsCard={colors}
            bigNumbers={bigNumbers}
            valuesForm={valuesForm}
            setLoading={setLoading}
          />

          <Table
            rows={items}
            filter={filter}
            snackProps={snackProps}
            setFilter={setFilter}
            setPage={setPage}
            page={page}
            totalPage={totalPage}
            count={count}
            setSnack={setSnack}
            setLoading={setLoading}
            onSubmit={onSubmit}
            valuesForm={valuesForm}
            setListSector={setListSector}
            listTypes={listTypes}
            setListTypes={setListTypes}
            listSector={listSector}
          />
        </>
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ListRequisition
