const defaultTotalPage = 10
const nameTable = 'Notas Fiscais'

const CARD = {
  ALL: 'all',
  PENDING: 'pending',
  ERROR: 'error',
  SENT: 'sent',
  PROCESSING: 'processing'
}

const generateNotesSuccess =
  'Os títulos estão em processamento. Quando finalizado, notificaremos.'

const handleCellInvoice = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Título'
  },

  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tipo'
  },
  {
    id: 'due_date',
    numeric: false,
    disablePadding: false,
    label: 'Vencimento'
  },
  {
    id: 'paid_at',
    numeric: false,
    disablePadding: false,
    label: 'Pago em'
  },
  {
    id: 'nfs_value',
    numeric: false,
    disablePadding: false,
    label: 'Valor NFS'
  },
  {
    id: 'nfe_value',
    numeric: false,
    disablePadding: false,
    label: 'Valor NF-e'
  },
  {
    id: 'total',
    numeric: false,
    disablePadding: false,
    label: 'Total'
  },
  {
    id: 'unit',
    numeric: false,
    disablePadding: false,
    label: 'Unidade'
  }
]

const defaultTypeOrder = 'Tipo'
const defautlError = 'error'
const defaultItemsPerPage = 10
const defaultPage = 1
const invoicesPage = 'invoices'
const errorWhenExportingWorksheet = 'Erro ao exportar planilha'
const noTitleFound = 0
const orderByDueDate = 'due_date'
const orderByPaid = 'paid_at'
const unitsLength = 0
const NFE_TABLE = 'nfe'
const NFSE_TABLE = 'nfse'
const STATUS_SEND = 'send'
const STATUS_PROCESS = 'process'
const STATUS_CONSULT = 'confer'

const messagesOptions = {
  [STATUS_SEND]: {
    toastMsg: 'Nota reenviada com sucesso.',
    modalTitle: 'Reenviar Nota',
    modalMsg:
      'Esta ação irá reenviar a nota com os mesmos dados, <b>não mudando o número</b> da mesma. Deseja reenviar?'
  },
  [STATUS_PROCESS]: {
    toastMsg: 'Nota reprocessada com sucesso.',
    modalTitle: 'Reprocessar Nota',
    modalMsg:
      'Esta ação irá reprocessar e enviar a nota com novos dados, <b>mudando o número</b> da mesma. Deseja reenviar?'
  },
  [STATUS_CONSULT]: {
    toastMsg: 'Nota reconsultada com sucesso.',
    modalTitle: 'Reconsultar Nota',
    modalMsg: 'Esta ação irá reconsultar o status da nota. Deseja reconsultar?'
  }
}
const nfs = [NFSE_TABLE, NFE_TABLE]
const statusOptions = [
  { statusToSend: STATUS_SEND, value: 'Reenviar' },
  { statusToSend: STATUS_PROCESS, value: 'Reprocessar' },
  { statusToSend: STATUS_CONSULT, value: 'Reconsultar' }
]

export {
  STATUS_SEND,
  STATUS_PROCESS,
  STATUS_CONSULT,
  messagesOptions,
  NFE_TABLE,
  NFSE_TABLE,
  defaultTotalPage,
  nameTable,
  CARD,
  generateNotesSuccess,
  defaultTypeOrder,
  defautlError,
  defaultItemsPerPage,
  handleCellInvoice,
  defaultPage,
  invoicesPage,
  errorWhenExportingWorksheet,
  noTitleFound,
  unitsLength,
  orderByDueDate,
  orderByPaid,
  nfs,
  statusOptions
}
