/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material'
import { ExpandMore, MoreVert } from '@mui/icons-material'
import { convertToParams, formatDate } from 'services/helpers'
import { DropDown, LoadingPage, ModalConfirm } from 'components/molecules'
import { ROUTE } from 'services/constants'
import { useNavigate } from 'react-router-dom'
import { Button } from 'components/atoms'
import TAG_MANAGER from 'tagManager'
import { useUserStore } from 'store/GlobalContext'
import {
  getMenuItems,
  handleDeleteExpedition,
  handleListExpedition
} from './services'
import { messageModalConfirm } from './constants'
import * as Styled from './style'

const StudentExpedition = ({ register, setSnack, studentInfo }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [contract, setContract] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [notSearch, setNotSearch] = useState(true)
  const [updateList, setUpdateList] = useState(true)
  const [studentCourse, setStudentCourse] = useState('')
  const [modalExclude, setModalExclude] = useState(false)
  const [expeditionList, setExpeditionList] = useState([])
  const [selectStundenInfo, setSelectStundenInfo] = useState()
  const studentName = studentInfo?.student?.name
  const user = useUserStore()
  const open = Boolean(anchorEl)

  const toggleModalExclude = () => {
    setModalExclude(!modalExclude)
  }

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const handleEdit = () => {
    const params = convertToParams({
      register,
      studentName,
      isEdit: true,
      diplomaId: selectStundenInfo.diploma_id
    })
    navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER_EXPEDITION}?${params}`)
  }

  const handleDelete = () => {
    handleDeleteExpedition({
      setSnack,
      setLoading,
      setUpdateList,
      diplomaId: selectStundenInfo.diploma_id,
      setModalExclude
    })
  }

  const handleNewRegister = () => {
    const params = convertToParams({
      register,
      contract,
      studentName
    })
    navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER_EXPEDITION}?${params}`)
  }

  const paramsGetMenu = {
    register,
    handleEdit,
    handleClose,
    toggleModalExclude,
    companyId: user.company_id,
    historicStudentId: selectStundenInfo?.historic_student_id,
    diplomaId: selectStundenInfo?.diploma_id
  }

  useEffect(() => {
    if (updateList) {
      handleListExpedition({
        setSnack,
        register,
        setLoading,
        setNotSearch,
        setExpeditionList
      })

      setUpdateList(false)
    }
  }, [updateList])

  return (
    <>
      <LoadingPage open={loading} />
      <Styled.ButtonGroup>
        <Button
          variant="contained"
          onClick={handleNewRegister}
          className={TAG_MANAGER.secretaria_btn_cadastrar_registroExpedicao}
        >
          novo registro
        </Button>
      </Styled.ButtonGroup>

      {expeditionList?.map((item) => (
        <Accordion key={item.register}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMore />}
          >
            <Styled.LeftContainer>
              <Tooltip title={`Curso: ${item?.course}`} placement="top" arrow>
                <Styled.Text>
                  <b>{item?.course || ''}</b>
                </Styled.Text>
              </Tooltip>
            </Styled.LeftContainer>
            <Styled.RightContainer>
              <Styled.TextGroup>
                Concluído em {formatDate(item.completion_date)}
              </Styled.TextGroup>
            </Styled.RightContainer>
          </AccordionSummary>

          <Styled.AccordionDetails>
            <Grid container spacing={{ xs: 2, sm: 2 }}>
              <Grid item xs={12} sm={12} md={3}>
                <Styled.SectionAccordionTop>
                  <Styled.Text>
                    <b>Nº de Registro </b>
                    {item?.registration_number || ''}
                  </Styled.Text>
                </Styled.SectionAccordionTop>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Styled.SectionAccordionTop>
                  <Styled.Text>
                    <b>Data de Registro </b>

                    {formatDate(item.registration_date)}
                  </Styled.Text>
                </Styled.SectionAccordionTop>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <Styled.SectionAccordionTop>
                  <Styled.Text>
                    <b>Nº do Livro </b>
                    {item?.book_number || ''}
                  </Styled.Text>
                </Styled.SectionAccordionTop>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Styled.SectionAccordionTop>
                  <Styled.Text>
                    <b>Nº da Folha </b>
                    {item?.page_number || ''}
                  </Styled.Text>
                </Styled.SectionAccordionTop>
              </Grid>
              <Grid item xs={12} sm={12} md={1}>
                <Styled.MenuButton>
                  <IconButton
                    onClick={(e) => {
                      handleClick(e)
                      setContract(item?.contract)
                      setStudentCourse(item?.course)
                      setSelectStundenInfo(item)
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Styled.MenuButton>
              </Grid>
              <Styled.SecondGrid item xs={12} sm={12} md={12}>
                <Styled.SectionAccordionTop>
                  <Styled.Text>
                    <b>SED </b>
                    {item?.sed || ''}
                  </Styled.Text>
                </Styled.SectionAccordionTop>
              </Styled.SecondGrid>
            </Grid>
          </Styled.AccordionDetails>
        </Accordion>
      ))}

      <DropDown
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        menuItems={getMenuItems(paramsGetMenu)}
      />

      <ModalConfirm
        iconWarningAmber
        open={modalExclude}
        onClickConfirm={handleDelete}
        handleClose={toggleModalExclude}
        onClickNotConfirm={toggleModalExclude}
        classNameBtnConfirm={
          TAG_MANAGER.secretaria_btn_confirma_excluirRegistroExpedicao
        }
        {...messageModalConfirm}
      />
    </>
  )
}

export default StudentExpedition
