import { lazy, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form as FormikForm, Formik } from 'formik'
import { ListAlt, Search } from '@mui/icons-material'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE, SUCCESS } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import {
  IsEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import {
  activeOccurrenceTypes,
  copyOccurrenceTypes,
  getOccurrenceTypes,
  inactiveOccurrenceTypes
} from 'services/api/admin'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  countDefault,
  defaultItemsPerPage,
  initialState,
  MESSAGE,
  nameAccordion
} from './constants'
import { listSelectCategories, listSelectOccurrenceTypes } from '../services'
import { useOccurrenceTypes } from './Context/FormContext'
import {
  newOccurrenceTypes,
  paramsOnSubmit,
  payloadCreateCopy
} from './helpers'

const Form = lazy(() => import('./Form'))
const Table = lazy(() => import('./Table'))

const ListOccurrenceTypes = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const snackProps = useSnack()
  const [expanded, setExpanded] = useState(nameAccordion)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [isButtonSet, setIsButtonSet] = useState(false)
  const [listOccurrenceTypes, setListOccurrenceTypes] = useState([])
  const [listCategories, setListCategories] = useState([])
  const [optionsOccurrenceTypes, setOptionsOccurrenceTypes] = useState([])
  const [valuesForm, setValuesForm] = useState(null)
  const [nameTypeSingle, setNameTypeSingle] = useState('')
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)

  const { formValues, setFormValues, resetFormValues } = useOccurrenceTypes()

  const {
    page: pageActual,
    totalPage,
    setPage
  } = usePaginationBasic(listOccurrenceTypes, filter, count)

  const isSearchParam = location?.state?.savedSearch

  const { setSnack } = snackProps

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  const cancelAutomaticSearch = () => {
    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  const onSubmit = async (values) => {
    setLoadingOpen(true)
    setSnack('')

    const { data, error, status } = await getOccurrenceTypes(
      paramsOnSubmit({ values, filter, pageActual }),
      newOccurrenceTypes(values?.occurrenceTypes)
    )

    setLoadingOpen(false)
    setFormValues(values)
    setCount(data?.count)

    if (!IsEmpty(data?.data)) {
      setNotSearch(false)
    }

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToListOccurrenciesTypes
      })
    }

    return setListOccurrenceTypes(data?.data)
  }

  const createCopyOccurrences = async ({
    values,
    selected: selectedIds,
    setOpenModalCreateCopyOccurrencyTypes,
    setSelected
  }) => {
    setLoadingOpen(true)
    setSnack('')

    const { error, status } = await copyOccurrenceTypes(
      payloadCreateCopy({
        values,
        selectedIds
      })
    )

    setLoadingOpen(false)
    setOpenModalCreateCopyOccurrencyTypes(false)
    setSelected([])

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToCopyOccurrenciesTypes
      })
    }

    setSnack(MESSAGE.SUCCESS_CREATE_COPY, SUCCESS)

    return setTimeout(() => onSubmit(formValues), 3000)
  }

  const inactiveOccurrences = async ({
    selected: selectedIds,
    setOpenModalInactiveOccurrencyTypes,
    setSelected
  }) => {
    setLoadingOpen(true)
    setSnack('')

    const { error, status } = await inactiveOccurrenceTypes({
      ids: selectedIds || []
    })

    setLoadingOpen(false)
    setOpenModalInactiveOccurrencyTypes(false)
    setSelected([])

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToInactiveOccurrenciesTypes
      })
    }

    setSnack(MESSAGE.SUCCESS_INACTIVE, SUCCESS)

    return setTimeout(() => onSubmit(formValues), 3000)
  }

  const activeOccurrences = async ({
    selected: selectedIds,
    setOpenModalActiveOccurrencyTypes,
    setSelected
  }) => {
    setLoadingOpen(true)
    setSnack('')

    const payload = {
      ids: selectedIds || []
    }

    const { error, status } = await activeOccurrenceTypes(payload)

    setLoadingOpen(false)
    setOpenModalActiveOccurrencyTypes(false)
    setSelected([])

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToActiveOccurrenciesTypes
      })
    }

    setSnack(MESSAGE.SUCCESS_ACTIVE, SUCCESS)

    return setTimeout(() => onSubmit(formValues), 3000)
  }

  const onClear = () => {
    setIsButtonSet(false)
    setValuesForm(initialState)
    resetFormValues()
    setListOccurrenceTypes([])
    setNotSearch(true)
    cancelAutomaticSearch()
  }

  useEffect(() => {
    listSelectCategories({ setSnack, setListCategories })
  }, [])

  useEffect(() => {
    if (isButtonSet) onSubmit(formValues)
  }, [pageActual, filter])

  useEffect(() => {
    if (isSearchParam && formValues?.category) {
      return onSubmit(formValues)
    }

    return !isSearchParam && setFormValues(initialState)
  }, [isSearchParam])

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  return (
    <TemplateDefaultFormAndResult
      titleHeader="Gestão - Tipos de Ocorrência"
      snackProps={snackProps}
      labelButtonHeader="Novo Tipo"
      loadingOpen={loadingOpen}
      listItems={listOccurrenceTypes}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      linkHeader={ROUTE.ADMIN_MANAGEMENT_OCCURRENCE_CREATE}
      classNameHeader={TAG_MANAGER.academic_btn_create_new_course}
      nameAccordion={nameAccordion}
      expanded={expanded}
      setExpanded={setExpanded}
      accordion={
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(props) => (
            <FormikForm
              onSubmit={props.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Form
                {...props}
                getOptionsOccurrenceTypes={listSelectOccurrenceTypes}
                setFilter={setFilter}
                setPage={setPage}
                setIsButtonSet={setIsButtonSet}
                optionsOccurrenceTypes={optionsOccurrenceTypes}
                setOptionsOccurrenceTypes={setOptionsOccurrenceTypes}
                setSnack={setSnack}
                onClear={onClear}
                categories={listCategories}
              />
            </FormikForm>
          )}
        </Formik>
      }
    >
      {listOccurrenceTypes && !IsEmpty(listOccurrenceTypes) && (
        <Table
          rows={listOccurrenceTypes}
          onRemove={listOccurrenceTypes}
          formValues={formValues}
          onCopy={listOccurrenceTypes}
          count={count}
          nameTypeSingle={nameTypeSingle}
          setNameTypeSingle={setNameTypeSingle}
          createCopyOccurrences={createCopyOccurrences}
          listCategories={listCategories}
          totalPage={totalPage}
          activeOccurrences={activeOccurrences}
          inactiveOccurrences={inactiveOccurrences}
          setFilter={setFilter}
          setPage={setPage}
          filter={filter}
          pages={pageActual}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}

export default ListOccurrenceTypes
