import { useState, useEffect, lazy } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import TAG_MANAGER from 'tagManager'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useSnack } from 'services/hooks'
import { getCuponById, saveCupon, updateCupon } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import { breadcrumbsItems, initialState } from './constants'
import { getParamsCupon, mergeInitialValues, updateSnask } from './services'

const FormCupon = lazy(() => import('./FormCupon'))

const CreateByUpdateCupon = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [cuponEdit, setCuponEdit] = useState(initialState)
  const [openModal, setOpenModal] = useState(false)

  const params = Object.fromEntries([...searchParams])
  const { coupon: numberTicket, id: idCupon } = params

  const snackProps = useSnack()
  const { setSnack } = snackProps

  const hasCreateCupon = location?.state?.newCupon

  const handleCuponId = async () => {
    setSnack('', '')
    setLoading(true)
    const {
      data: response,
      error,
      status
    } = await getCuponById(Number(idCupon))

    setLoading(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToViewCupon
      })
    }

    return setCuponEdit(response)
  }

  const onUpdateCupon = async (values) => {
    const { error, status } = await updateCupon(getParamsCupon(values, idCupon))
    setLoading(false)
    return updateSnask({
      error,
      status,
      savedCupon: true,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateCupon,
      setSnack,
      navigate
    })
  }

  const onSubmit = async (values) => {
    setSnack('', '')
    setLoading(true)
    if (numberTicket) {
      return onUpdateCupon(values)
    }

    const { error, status } = await saveCupon(getParamsCupon(values))
    setLoading(false)
    return updateSnask({
      error,
      status,
      savedCupon: true,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCreateCupon,
      setSnack,
      navigate
    })
  }

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(ROUTE.ADMIN_FINANCIAL_CUPON, {
      state: { savedSearch: true }
    })
  }

  useEffect(() => {
    if (idCupon) handleCuponId()
  }, [idCupon])

  const initialValues = mergeInitialValues(cuponEdit)

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems(hasCreateCupon, numberTicket)}
      classNameHeader={`goBack ${
        location?.state?.newPlan && TAG_MANAGER.btn_financier_cancels_new_group
      }`}
      onClickButton={() => setOpenModal(true)}
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      tagmanagerModal={TAG_MANAGER.financeiro_btn_confirma_excluiCupom}
      content={
        <>
          <Formik
            initialValues={idCupon ? initialValues : initialState}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ ...props }) => (
              <Form onSubmit={props.handleSubmit} noValidate autoComplete="off">
                <FormCupon
                  setSnack={setSnack}
                  idCupon={idCupon}
                  {...props}
                  setOpenModal={setOpenModal}
                />
              </Form>
            )}
          </Formik>
        </>
      }
    />
  )
}

export default CreateByUpdateCupon
