import {
  getStudentDataByRegisterId as getStudentDataByRegisterIdService,
  updateResponsible as updateResponsibleService,
  updateRegistrationData as updateRegistrationDataService
} from 'services/api/admin'
import { deserialize, messageErroOrSuccess, serialize } from 'services/helpers'

import { formatInitialValuesStudentData, formatPayloadByTab } from './helpers'
import { fieldsNameForm, serviceMessage } from './constants'

const getStudentDataByRegisterId = async ({
  setData,
  registerId,
  setLoadingVisibility,
  setShouldUpdateData
}) => {
  setLoadingVisibility(true)

  const { error, status, data } = await getStudentDataByRegisterIdService(
    registerId
  )

  await messageErroOrSuccess({ error, status })

  const dataFormatted = formatInitialValuesStudentData(serialize(data))

  await setData(dataFormatted)

  setShouldUpdateData(false)
  setLoadingVisibility(false)
}

const updateRegistrationData = async ({
  registerId,
  values,
  currentTab,
  setShouldUpdateData
}) => {
  const formatPayload = formatPayloadByTab[currentTab]
  const currentPayload =
    currentTab === fieldsNameForm.financialOfficer
      ? values?.[fieldsNameForm.fieldNameResponsibleEdit]
      : values?.[currentTab]
  const serviceFunction =
    currentTab === fieldsNameForm.financialOfficer
      ? updateResponsibleService
      : updateRegistrationDataService

  const newPayload = deserialize(formatPayload(currentPayload))

  const { feedbackMessage, successMessage } = serviceMessage
  const { error, status } = await serviceFunction(newPayload, registerId)

  await messageErroOrSuccess({
    error,
    status,
    feedbackMessage,
    successMessage
  })

  if (!error) {
    setTimeout(() => setShouldUpdateData(true), 800)
  }
}

export { getStudentDataByRegisterId, updateRegistrationData }
