const numberSelected = 0
const isSelected = 1
const defaultName = 'name'
const typeAll = 'Tudo'
const defaultItemsPerPage = 10
const defaultTotalPage = 1

const headCells = [
  {
    disabled: true,
    label: 'Tipo do Documento'
  },
  {
    id: 'name',
    label: 'Nome do Curso'
  },
  {
    id: 'done_at',
    label: 'Data do Documento'
  }
]

export {
  headCells,
  defaultItemsPerPage,
  defaultName,
  numberSelected,
  isSelected,
  typeAll,
  defaultTotalPage
}
