import styled from 'styled-components'
import {
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography
} from '@mui/material'
import { Modal as MuiModal } from 'components/atoms'

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 80%;
  }
`

const Grid = styled(MuiGrid)`
  padding: 15px;
`

const TextField = styled(MuiTextField)`
  &.MuiTextField-root {
    margin-bottom: 20px;
  }
`

const Title = styled(MuiTypography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

const IconText = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`

const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
`

export {
  Modal,
  Grid,
  TextField,
  Title,
  IconText,
  Footer
}
