import { Paper, Table, TableBody, Tooltip } from '@mui/material'

import { useSortTable } from 'services/hooks'
import { TableHeaderDefault } from 'components/organisms'

import { keyColumns, tableColumns } from './constants'

import * as Styled from './style'

const List = ({ items, handleExcludeGroupedDiscipline }) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    items,
    keyColumns.name
  )

  const total = items?.reduce(
    (prev, curr) => prev + (curr?.activeStudents ?? 0),
    0
  )

  return (
    <Paper>
      <Table>
        <TableHeaderDefault
          order={order}
          orderBy={orderBy}
          headCells={tableColumns}
          rowCount={items?.length}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {sortTable?.map((item) => (
            <Styled.TableRow key={item?.id}>
              <Styled.TableCell style={{ width: '35%' }}>
                <Styled.NameContainer className="nameContainer">
                  <Styled.NameTexts>
                    <Styled.Name>{item?.discipline}</Styled.Name>
                    <Styled.Group>{item?.schoolClassGroup}</Styled.Group>
                  </Styled.NameTexts>
                  <Tooltip title="Excluir" placement="top" className="actions">
                    <Styled.DeleteIcon
                      onClick={() => handleExcludeGroupedDiscipline(item)}
                    />
                  </Tooltip>
                </Styled.NameContainer>
              </Styled.TableCell>
              <Styled.TableCell>{item?.schoolClassUnit}</Styled.TableCell>
              <Styled.TableCell>{item?.schoolClassCourse}</Styled.TableCell>
              <Styled.TableCell>
                <Styled.NameTexts>
                  <Styled.Group>{`${item?.schoolClassId} - ${item?.schoolClassCode}`}</Styled.Group>
                  <Styled.Group>
                    {item?.schoolClassShift && `[${item?.schoolClassShift}]`}
                  </Styled.Group>
                </Styled.NameTexts>
              </Styled.TableCell>
              <Styled.TableCell
                style={{ width: '10%' }}
              >{`${item?.activeStudents}/${item?.inactiveStudents}`}</Styled.TableCell>
            </Styled.TableRow>
          ))}
        </TableBody>

        <Styled.TableRow $total>
          <Styled.TableCell />
          <Styled.TableCell />
          <Styled.TableCell />
          <Styled.TableCell>
            <Styled.Total>Total de Alunos</Styled.Total>
          </Styled.TableCell>
          <Styled.TableCell $hasBorder style={{ width: '10%' }}>
            <Styled.Total>{total ?? '-'}</Styled.Total>
          </Styled.TableCell>
        </Styled.TableRow>
      </Table>
    </Paper>
  )
}

export default List
