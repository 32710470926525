import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import {
  Typography as MuiTypography,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle
} from '@mui/material'

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`

const Info = styled.div`
  display: grid;
  grid-template-columns: 1fr 110px 100px;
  background-color: ${({ theme }) => theme.palette.gray.primaryLight};
  padding: 16px;
`

const Title = styled(MuiTypography)`
  &.MuiTypography-root {
    font-size: 14px;
  }
`
const Text = styled(MuiTypography)`
  &.MuiTypography-root {
    font-size: 14px;
    text-align: end;
  }
`

const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
`

const Message = styled(MuiTypography)`
  &.MuiTypography-root {
    font-size: 12px;
    padding: 16px 0;
  }
`

const BulletinLink = styled(Link)`
  margin-left: 24px;
`

const DialogContent = styled(MuiDialogContent)`
  &.MuiDialogContent-root {
    padding: 16px;
  }
`

const DialogTitle = styled(MuiDialogTitle)`
  &.MuiDialogTitle-root {
    font-weight: bold;
    padding: 0;
  }
`
const Informative = styled(MuiTypography)`
  &.MuiTypography-root {
    font-size: 16px;
    padding-bottom: 16px;
  }
`
const styledBoxSharedStyles = css`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  padding: 16px;
  border-color: ${({ theme }) => theme.palette.gray.primaryLight};
`

const Box = styled.div`
  ${styledBoxSharedStyles}
  background-color: ${({ theme }) => theme.palette.white};
`

const BoxTotal = styled.div`
  ${styledBoxSharedStyles}
  background-color:${({ theme }) =>
    theme.palette.textColors.secondary.mediumGray};
`

const Progress = styled.div`
  height: 200px;
  width: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const LoadingTitle = styled.p`
  color: ${({ theme }) => theme.palette.primary.main};
`

export {
  Header,
  Info,
  Box,
  Title,
  Text,
  Footer,
  Message,
  BulletinLink,
  DialogContent,
  DialogTitle,
  Informative,
  BoxTotal,
  Progress,
  LoadingTitle
}
