/* eslint-disable camelcase */
const parseStateByCity = (data, uf) =>
  data?.reduce((acc, cur) => {
    const { id, estado, cidade } = cur
    const newItem = { value: uf ? id : cidade, label: uf ? estado : cidade }
    return [...acc, newItem]
  }, [])

const parseRegional = (data) =>
  data?.reduce((acc, cur) => {
    const { name } = cur
    const newItem = { value: name, label: name }
    return [...acc, newItem]
  }, [])

const parseList = (data) =>
  [data]?.reduce((acc, cur) => {
    const {
      name,
      social_name,
      cpf,
      rg,
      inep,
      nis,
      document_issue,
      email,
      birth_date,
      birth_city,
      birth_uf,
      mother_name,
      father_name,
      marital_status,
      admission_date,
      phone_number,
      cell_number,
      regional,
      zip_code,
      state,
      city,
      street,
      number,
      complement_address,
      neighborhood
    } = cur

    const newItem = {
      name,
      social_name,
      cpf,
      rg,
      inep,
      nis,
      document_issue,
      email,
      birth_date,
      birth_city,
      birth_uf,
      mother_name,
      father_name,
      marital_status,
      admission_date,
      phone_number,
      cell_number,
      regional,
      zip_code,
      state,
      city,
      street,
      number,
      complement_address,
      neighborhood
    }
    return { ...acc, ...newItem }
  }, {})

const parseCreate = (data) =>
  [data]?.reduce((acc, cur) => {
    const {
      name,
      social_name,
      cpf,
      rg,
      inep,
      nis,
      document_issue,
      email,
      birth_date,
      birth_city,
      birth_uf,
      mother_name,
      father_name,
      marital_status,
      admission_date,
      phone_number,
      cell_number,
      regional,
      zip_code,
      state,
      city,
      street,
      number,
      complement_address,
      neighborhood
    } = cur

    const newItem = {
      name,
      ...(social_name !== '' && { social_name }),
      cpf,
      rg,
      inep,
      nis,
      document_issue,
      email,
      birth_date,
      birth_city,
      birth_uf,
      mother_name,
      father_name,
      marital_status,
      admission_date,
      phone_number,
      cell_number,
      regional,
      address: {
        zip_code,
        state,
        city,
        street,
        number,
        complement_address,
        neighborhood
      }
    }
    return { ...acc, ...newItem }
  }, {})

const parseUpdate = (data) =>
  [data]?.reduce((acc, cur) => {
    const {
      name,
      social_name,
      cpf,
      rg,
      inep,
      nis,
      document_issue,
      email,
      birth_date,
      birth_city,
      birth_uf,
      mother_name,
      father_name,
      marital_status,
      admission_date,
      phone_number,
      cell_number,
      regional,
      zip_code,
      state,
      city,
      street,
      number,
      complement_address,
      neighborhood
    } = cur

    const newItem = {
      name,
      ...(social_name !== '' && { social_name }),
      cpf,
      rg,
      inep,
      nis,
      document_issue,
      email,
      birth_date,
      birth_city,
      birth_uf,
      mother_name,
      father_name,
      marital_status,
      admission_date,
      phone_number,
      cell_number,
      regional,
      address: {
        zip_code,
        state,
        city,
        street,
        number,
        complement_address,
        neighborhood
      }
    }
    return { ...acc, ...newItem }
  }, {})

export { parseStateByCity, parseRegional, parseUpdate, parseCreate, parseList }
