import React from 'react'
import ReactHtmlParser from 'react-html-parser'

export const formatStringTagToString = (htmlString) => {
  const parsedHtml = ReactHtmlParser(htmlString)

  let formattedString = ''

  const processChildren = (children) => {
    children.forEach((child) => {
      if (typeof child === 'string') {
        const formattedText = child.replace(/\s+/g, ' ').trim()
        formattedString += formattedText
      } else if (child.props && child.props.children) {
        processChildren(React.Children.toArray(child.props.children))
      }
    })
  }

  processChildren(React.Children.toArray(parsedHtml))

  return formattedString
}

export const convertToHTML = (inputValue) => {
  const htmlString = inputValue.replace(
    /(#\w+)/g,
    '<span style="font-size: 12pt;">$1</span>'
  )

  return `<p style="text-align: justify;"><span style="font-size: 12pt;">${htmlString}</span></p>`
}
