import { InputAdornment } from '@mui/material'
import * as Styled from './style'

const InputInternshipHours = ({ value, onChange }) => (
  <Styled.Container>
    <Styled.InputLabel>Carga Horária de estágio cursada</Styled.InputLabel>
    <Styled.TextField
      fullWidth
      value={value}
      onChange={onChange}
      InputProps={{
        endAdornment: <InputAdornment position="end">horas</InputAdornment>
      }}
    />
  </Styled.Container>
)

export default InputInternshipHours
