import styled from 'styled-components'
import { CardContent as MuiCardContent, Card as MuiCard } from '@mui/material'
import { PictureAsPdf as MuiPictureAsPdf } from '@mui/icons-material'

const PictureAsPdf = styled(MuiPictureAsPdf)`
  color: rgba(0, 0, 0, 0.54);
`

const Card = styled(MuiCard)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  background: rgba(250, 250, 250, 1);
`

const CardContent = styled(MuiCardContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
`

export { PictureAsPdf, Card, CardContent }
