import TAG_MANAGER from 'tagManager'
import { CREATE, PATH_UNITS, PATH_UNITS_POLE } from './constants'

const convertPayload = ({
  typePage,
  values,
  PAGE_EDIT,
  companyId,
  unitId
}) => ({
  ...(typePage?.pole && {
    ...(typePage?.edit !== PAGE_EDIT && { name: values.namePole })
  }),
  ...(typePage?.unit && {
    ...(typePage?.edit !== PAGE_EDIT && { name: values.unit }),
    id_pabx: values.pabx,
    ...(values.supervisoryBody && { supervisory_body: values.supervisoryBody })
  }),
  ...(typePage?.edit !== PAGE_EDIT && { id_unit: Number(unitId) }),
  ...(values.resolution && { resolution: values.resolution }),
  ...(values.credential && { credential: values.credential }),
  ...(values.responsible && { responsible: values.responsible }),
  ...(values.firstPhone && { first_cellphone: values.firstPhone }),
  ...(values.secondPhone && { second_cellphone: values.secondPhone }),
  ...(values.email && { email: values.email }),
  ...(values.responsiblePhone && { telephone: values.responsiblePhone }),
  active: values.active,
  cnpj: values.cnpj,
  company_id: companyId,
  company_name: values.socialReason,
  academic_name: values.academicName,
  zip_code: values.cep,
  address: values.address,
  address_number: values.numberAddress,
  complement: values.complement,
  district: values.neighborhood,
  city: values.city,
  state: values.state
})

const convertResponse = ({ data, typePage }) => ({
  ...(typePage?.pole && {
    namePole: data.name
  }),
  ...(typePage?.unit && {
    unit: data.name,
    pabx: data.id_pabx,
    supervisoryBody: data.supervisory_body
  }),
  active: data.active,
  cnpj: data.cnpj,
  socialReason: data.company_name,
  email: data.email,
  academicName: data.academic_name,
  cep: data.zip_code,
  address: data.address,
  numberAddress: data.address_number,
  complement: data.complement,
  neighborhood: data.district,
  city: data.city,
  state: data.state,
  responsible: data.responsible,
  responsiblePhone: data.telephone,
  firstPhone: data.first_cellphone,
  secondPhone: data.second_cellphone,
  resolution: data.resolution,
  credential: data.credential
})

const tagManagerGoBackButton = (type) => {
  if (type.pole) {
    if (type.edit) return TAG_MANAGER.academic_btn_cancel_edit_polo

    return TAG_MANAGER.academic_btn_cancels_new_polo
  }

  if (type.edit) return TAG_MANAGER.academic_btn_cancel_edit_unit

  return TAG_MANAGER.academic_btn_cancels_new_unit
}

const linkRouteRedirect = ({
  goBackPole,
  situation,
  hasLinkedPoles,
  typePage,
  convertToParams,
  isUnit,
  unitId
}) => {
  if (situation === CREATE && !hasLinkedPoles && !goBackPole) {
    return `${PATH_UNITS}`
  }

  if (typePage.unit) {
    if (!hasLinkedPoles) return `${PATH_UNITS}`

    return `${PATH_UNITS}`
  }

  return `${PATH_UNITS_POLE}?${convertToParams({
    unit: isUnit,
    unitId: Number(unitId)
  })}`
}

const linkCallToActionGoBack = ({
  goBackPole,
  situation,
  hasLinkedPoles,
  typePage,
  navigate,
  convertToParams,
  isUnit,
  unitId
}) =>
  navigate(
    linkRouteRedirect({
      goBackPole,
      situation,
      hasLinkedPoles,
      typePage,
      convertToParams,
      isUnit,
      unitId
    }),
    {
      state: { savedSearch: true }
    }
  )

export {
  convertPayload,
  convertResponse,
  tagManagerGoBackButton,
  linkCallToActionGoBack
}
