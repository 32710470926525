import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ModalConfirm } from 'components/molecules'
import { convertToParams } from 'services/helpers'
import { ROUTE } from 'services/constants'

import { modal } from './constants'

import Table from '../Table'

import * as Styled from './style'

const Details = ({
  subjects,
  schoolClassId,
  schoolClassLevel,
  schoolClassCourse,
  handleExcludeLink,
  handleRegisterNote
}) => {
  const [modalExclude, setModalExclude] = useState({
    open: false,
    linkedId: null
  })
  const [searchParams] = useSearchParams()

  const params = Object.fromEntries([...searchParams])

  const { register, studentName } = params

  const handleCloseExcludeModal = () =>
    setModalExclude({ open: false, subjectId: null })

  const handleExcludeLinkModal = (linkedId) => {
    setModalExclude({ open: true, linkedId })
  }

  const linkTo = ({ subjectId, subjectWorkloadHours, subjectName }) =>
    `${ROUTE.ADMIN_SECRETARY_STUDENTS_CLASS_DISCIPLINE_LINK}?${convertToParams({
      subjectId,
      register,
      schoolClassId,
      schoolClassLevel,
      schoolClassCourse,
      subjectWorkloadHours,
      subjectName,
      studentName
    })}`

  return (
    <Styled.Container>
      <Table
        rows={subjects}
        handleRegisterNote={handleRegisterNote}
        handleExcludeLink={handleExcludeLinkModal}
        linkTo={linkTo}
      />

      <ModalConfirm
        open={modalExclude.open}
        handleClose={handleCloseExcludeModal}
        onClickNotConfirm={handleCloseExcludeModal}
        onClickConfirm={() =>
          handleExcludeLink(modalExclude.linkedId, handleCloseExcludeModal)
        }
        title={modal.title}
        message={modal.message}
        textButtonNotConfirm={modal.buttonNotConfirm}
        textButtonConfirm={modal.buttonConfirm}
      />
    </Styled.Container>
  )
}

export default Details
