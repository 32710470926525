import { useEffect, useState } from 'react'
import FEEDBACK_SNACK from 'feedBackSnack'
import { updateErrorMessage } from 'services/helpers'
import { getCourse } from 'services/api/shared'

const useGetCourseByUnit = ({
  courseValue = true,
  formValues,
  unitys,
  setFieldValue,
  active
}) => {
  const [course, setCourse] = useState([])

  const handleLevel = async ({ value, unity, type }) => {
    setFieldValue('level', value)

    if (type) {
      return setFieldValue('course', '')
    }

    const labelUnity = unitys?.find((item) => item.value === unity)

    const { data, error, status } = await getCourse(
      value,
      labelUnity?.label,
      active
    )

    if (error) {
      return updateErrorMessage({
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToCourse
      })
    }

    const list = data?.reduce((acc, cur) => {
      const { name, id } = cur
      const newItem = courseValue
        ? { value: name, label: name }
        : { value: id, label: name }
      return [...acc, newItem]
    }, [])

    return setCourse(list)
  }

  useEffect(() => {
    if (formValues?.level) {
      handleLevel(formValues?.level, formValues?.unity)
    }
  }, [formValues?.level])

  return {
    handleLevel,
    course
  }
}

export default useGetCourseByUnit
