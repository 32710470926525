import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { MINUTES_TO_1_HOUR } from 'services/constants/time'
import { NOVOTEC_UNIT } from 'services/constants/units'

const { borderLayoutDefault, paddingLayoutDefault, noBorderOnTop } = utilsPDF

const buildClassInfo = (info) => ({
  table: {
    body: [
      [
        {
          stack: [{ text: 'Curso', bold: true }, { text: info.course }],
          colSpan: 2,
          border: noBorderOnTop
        },
        {},
        {
          stack: [{ text: 'Turma', bold: true }, { text: info.classroom }],
          colSpan: 2,
          border: noBorderOnTop
        },
        {},
        {
          stack: [
            { text: 'Unidade/Polo', bold: true },
            { text: `${info.unit}${info.pole ? ` / ${info.pole}` : ''}` }
          ],
          colSpan: 2,
          border: noBorderOnTop
        },
        {}
      ],
      [
        {
          stack: [
            { text: 'Componente curricular:', bold: true },
            { text: info.curricularComponent }
          ],
          colSpan: 4,
          border: noBorderOnTop
        },
        {},
        {},
        {},
        {
          stack: [{ text: 'Período', bold: true }, { text: info.period }],
          border: noBorderOnTop
        },
        {
          stack: [
            {
              text:
                info.unit === NOVOTEC_UNIT
                  ? 'Aulas'
                  : 'Carga horária total prevista',
              bold: true
            },
            {
              text:
                info.unit === NOVOTEC_UNIT
                  ? `${
                      info.totalExpectedWorkload /
                      (info.classTime / MINUTES_TO_1_HOUR)
                    }`
                  : `${info.totalExpectedWorkload} horas`
            }
          ],
          border: noBorderOnTop
        }
      ]
    ],
    widths: ['*', 'auto', 'auto', 'auto', '*', 'auto']
  },
  layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
})

export default buildClassInfo
