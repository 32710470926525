import { useState } from 'react'
import { useSnack } from 'services/hooks'
import { useUserStore } from 'store/GlobalContext'
import { LoadingPage } from 'components/molecules'
import { initialTimekeeping } from 'components/pages/Admin/HumanResources/Professor/CreateByUpdate/components/FormTimekeeping/constants'
import { FormTimekeeping } from 'components/pages/Admin/HumanResources/Professor/CreateByUpdate/components/FormTimekeeping'

const Timekeeping = () => {
  const [loadingOpen, setLoadingOpen] = useState(false)
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const [formTimekeeping, setFormTimekeeping] = useState(initialTimekeeping)
  const [listTimekeeping, setListTimekeeping] = useState([])
  const { username: idProfessor } = useUserStore()

  return (
    <>
      <LoadingPage open={loadingOpen} />
      <FormTimekeeping
        formTimekeeping={formTimekeeping}
        setSnack={setSnack}
        setLoadingOpen={setLoadingOpen}
        setFormTimekeeping={setFormTimekeeping}
        idProfessor={idProfessor}
        listTimekeeping={listTimekeeping}
        setListTimekeeping={setListTimekeeping}
      />
    </>
  )
}

export default Timekeeping
