import styled from 'styled-components'
import { WarningAmber } from '@mui/icons-material'
import { Grid as MuiGrid } from '@mui/material'

const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const GridContainer = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
`
const Form = styled.form``

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

const Comments = styled.p`
  text-align: center;
`

const Description = styled.p``

const Box = styled.div`
  margin: 2rem 0;
`

export {
  Grid,
  GridContainer,
  Form,
  IconWarningAmber,
  Description,
  Comments,
  Box
}
