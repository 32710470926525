import {
  Checkbox,
  InputAdornment,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import { Select } from 'components/molecules'
import {
  currencyFormat,
  currencyMaskWithoutPrefix,
  formatDate
} from 'services/helpers'
import * as Styled from './style'

const TableFooter = ({
  row,
  isEdit,
  labelId,
  handleClick,
  isItemSelected,
  setListDisciplines
}) => (
  <TableRow hover key={row?.id} tabIndex={-1} role="checkbox">
    <Styled.StyledTableCell
      padding="checkbox"
      onClick={() => handleClick(row?.id)}
    >
      <Checkbox
        color="primary"
        checked={isItemSelected}
        inputProps={{
          'aria-labelledby': labelId
        }}
      />
    </Styled.StyledTableCell>
    <Styled.StyledTableCell>
      <Styled.Box>
        {row?.discipline}
        <Styled.ContainerIcon className="boxIcons">
          {Boolean(row?.has_grouping) && (
            <Tooltip title="Em Agrupamento" placement="top" arrow>
              <Styled.BoxGrouping>
                <Typography variant="body1">A</Typography>
              </Styled.BoxGrouping>
            </Tooltip>
          )}
        </Styled.ContainerIcon>
      </Styled.Box>
    </Styled.StyledTableCell>
    <Styled.StyledTableCell $width={8}>{row?.module}º</Styled.StyledTableCell>
    <Styled.StyledTableCell $width={10}>
      {row?.workload_hours}h{row?.workload_minutes}min
    </Styled.StyledTableCell>
    {isEdit && (
      <>
        <Styled.StyledTableCell>
          {row?.substitution_info?.substitution_reason &&
          !row?.substitution_info?.start_date
            ? 'Permanente'
            : `
      ${
        row?.substitution_info?.start_date
          ? formatDate(row?.substitution_info?.start_date)
          : '-'
      }
        `}
        </Styled.StyledTableCell>

        <Styled.StyledTableCell>
          {row?.substitution_info?.substitution_reason &&
          !row?.substitution_info?.end_date
            ? 'Permanente'
            : `
      ${
        row?.substitution_info?.end_date
          ? formatDate(row?.substitution_info?.end_date)
          : '-'
      }
        `}
        </Styled.StyledTableCell>
        <Styled.StyledTableCell>
          {row?.substitution_info?.substitution_reason || '-'}
        </Styled.StyledTableCell>
      </>
    )}
    <Styled.StyledTableCell>
      <Select
        disabled={!isItemSelected}
        optionsList={row.professors}
        value={row?.professorsSelected?.value}
        onChange={(e) => {
          setListDisciplines((prev) =>
            prev.map((item) => {
              if (item.id === row.id) {
                return {
                  ...item,
                  professorsSelected: {
                    id: e.target.value,
                    value: e.target.value,
                    label: e.target.value,
                    position: e.target.position
                  }
                }
              }
              return item
            })
          )
        }}
      />
    </Styled.StyledTableCell>
    <Styled.StyledTableCell $width={12}>
      <Styled.Text $isDisabled={!row?.substitution_info?.hourly_lesson_cost}>
        R$
        {currencyMaskWithoutPrefix(
          currencyFormat(
            row?.substitution_info?.hourly_lesson_cost ||
              row?.professorsSelected?.hourlyLessonCost ||
              '0'
          )
        )}
      </Styled.Text>
    </Styled.StyledTableCell>
  </TableRow>
)
export default TableFooter
