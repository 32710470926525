import {
  getListHRSchoolClass,
  getOptionsCoordinator,
  getOptionsSchoolClass,
  revertHRSchoolClass
} from 'services/api/admin'
import { toastNotificationSuccess, updateErrorMessage } from 'services/helpers'
import { getCourses } from 'services/api/shared'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  parserOptions,
  parserOptionsCoordinator,
  parserOptionsSchoolClass
} from './helpers'

const getCoordinator = async ({ course, setCoordinators }) => {
  const params = !!course && { course }

  const { data, error, status } = await getOptionsCoordinator(params)

  if (error) {
    return updateErrorMessage({
      error,
      status
    })
  }

  return setCoordinators(parserOptionsCoordinator(data))
}

const getListCourses = async ({ unitId, level, setCourses }) => {
  const { data, error, status } = await getCourses({
    unit_id: unitId.value
  })

  if (error) updateErrorMessage({ error, status })

  return setCourses(parserOptions(data.data))
}

const getListOptionschoolClasses = async ({
  coordinatorId,
  unit,
  course,
  setSchoolClassesOptions
}) => {
  const params = {
    ...(!!unit && { unit }),
    ...(!!course && { course }),
    ...(!!coordinatorId && { coordinator_id: coordinatorId })
  }

  const { data, error, status } = await getOptionsSchoolClass(params)

  if (error) updateErrorMessage({ error, status })

  return setSchoolClassesOptions(parserOptionsSchoolClass(data))
}

const getListSchoolClasses = async ({
  payrollId,
  values,
  setLoadingOpen,
  setCount,
  setSchoolClasses,
  page,
  filter
}) => {
  setLoadingOpen(true)

  const { data, error, status } = await getListHRSchoolClass({
    ...(values.course && { course: values.course }),
    ...(values.coordinator_id && { coordinator_id: values.coordinator_id }),
    ...(values.school_class_id && { school_class_id: values.school_class_id }),
    payroll_id: payrollId,
    page,
    limit: filter
  })

  setCount(data?.count)
  setLoadingOpen(false)

  if (error)
    updateErrorMessage({
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToListSchoolClassManagement
    })

  return setSchoolClasses(data?.data)
}

const revertedHRSchoolClass = async ({
  schoolClassesId,
  nameClass,
  setValuesReset,
  setLoadingOpen,
  setOpenModalConfirm,
  formik,
  pageActual,
  filter,
  setCount,
  setSchoolClasses,
  params
}) => {
  setLoadingOpen(true)

  const { error, status } = await revertHRSchoolClass({
    payrollId: params.payroll_id,
    schoolClassesId: String(schoolClassesId)
  })

  setLoadingOpen(false)
  setOpenModalConfirm(false)
  setValuesReset({})

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListReverted
    })
  }

  toastNotificationSuccess('Dados atualizados com sucesso', 2000)

  return getListSchoolClasses({
    payrollId: params.payroll_id,
    values: formik.values,
    setLoadingOpen,
    page: pageActual,
    filter,
    setCount,
    setSchoolClasses
  })
}

export {
  getCoordinator,
  getListCourses,
  getListOptionschoolClasses,
  getListSchoolClasses,
  revertedHRSchoolClass
}
