import PropTypes from 'prop-types'
import theme from 'theme/designTokens'
import { IconButton } from '@mui/material'
import { CircleNotifications } from '@mui/icons-material'
import CircleIcon from '@mui/icons-material/Circle'
import TAG_MANAGER from 'tagManager'
import { maximunSize } from './constants'
import * as Styled from './style'

const Badge = ({ id, handleOpenModal, badgeContent }) => (
  <IconButton
    size="large"
    color="inherit"
    onClick={handleOpenModal}
    aria-label={id}
  >
    <Styled.IconBadge
      color="warning"
      badgeContent={
        maximunSize <= badgeContent && (
          <CircleIcon
            sx={{ fontSize: 10, color: theme.palette.success.main }}
          />
        )
      }
    >
      <CircleNotifications
        className={TAG_MANAGER.student_btn_Header_access_notifications}
      />
    </Styled.IconBadge>
  </IconButton>
)

export default Badge

Badge.propTypes = {
  handleOpenModal: PropTypes.func,
  id: PropTypes.string
}

Badge.defaultProps = {
  handleOpenModal: () => {},
  id: ''
}
