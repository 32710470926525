import styled from 'styled-components'

const ButtonGrup = styled.div`
  margin: 1rem 0;
`

const Text = styled.p`
  text-align: center;
`

const Description = styled.p`
  text-align: center;
`
const ButtonSearch = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid
    var(--primary-states-outlined-resting-border, rgba(63, 81, 181, 0.5));
  color: var(--primary-main, #593493);

  padding: 0.5rem 1rem;
  background: transparent;
  text-align: center;
  cursor: pointer;
`

const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 3rem;
`

export { ButtonGrup, Text, Description, ButtonSearch, ButtonBox }
