import { Form as FormikForm, Formik } from 'formik'
import { Grid } from '@mui/material'
import { Clear } from '@mui/icons-material'
import { InputSwitch } from 'components/atoms'
import { ButtonFooterV2, TextField } from 'components/molecules'
import { defaultValues } from './constants'

const SearchForm = ({ onSubmit, handleResetSearch }) => (
  <Formik enableReinitialize initialValues={defaultValues} onSubmit={onSubmit}>
    {({ handleChange, setFieldValue, values, resetForm }) => (
      <FormikForm>
        <Grid container xs={12} spacing={{ xs: 2 }}>
          <Grid item xs={7.5}>
            <TextField
              fullWidth
              id="name"
              label="Título do Documento"
              onChange={handleChange}
              value={values?.name || ''}
            />
          </Grid>

          <Grid item xs={1.5}>
            <InputSwitch
              label="Inativos"
              checked={values.inactive}
              onClick={(e) => setFieldValue('inactive', e.target.checked)}
            />
          </Grid>

          <Grid item xs={3}>
            <ButtonFooterV2
              labelClose="Limpar"
              labelConfirm="Buscar"
              onClickClose={() => handleResetSearch(resetForm)}
              size="large"
              startIcon={<Clear />}
            />
          </Grid>
        </Grid>
      </FormikForm>
    )}
  </Formik>
)
export default SearchForm
