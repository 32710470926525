import { createContext, useContext } from 'react'
import { getUserFromLocalStorage, getSavedDataFromUrl } from 'services/helpers'

import initialState from './initialState'

const GlobalContext = createContext(initialState)

export default GlobalContext

export function useStore() {
  return useContext(GlobalContext)
}

export function useUserStore() {
  const user = getUserFromLocalStorage()

  return user
}

export function useSavedDataFromUrl() {
  const data = getSavedDataFromUrl()

  return data
}
