import styled from 'styled-components'
import { Form } from 'formik'
import { Grid, Chip as MuiChip } from '@mui/material'
import { InputAutocomplete } from 'components/atoms'

const Chip = styled(MuiChip)`
  &.MuiChip-root {
    font-weight: bold;
  }
`

const SwitchAdvSearch = styled.div`
  padding: 20px 0 10px;
`

const GroupButtons = styled(Grid)`
  &.MuiGrid-root {
    &.MuiGrid-item {
      text-align: end;
      margin-bottom: ${({ $marginBottom }) => $marginBottom};

      @media screen and (max-width: 1024px) {
        text-align: start;
      }
    }
  }
`

const Box = styled(Grid)`
  &.MuiGrid-root {
    margin-bottom: ${({ $marginBottom }) => $marginBottom};
  }
`

const Checkbox = styled.div`
  margin-top: 8px;
`

const Switch = styled.div`
  margin-top: 4px;
`

const CardGrid = styled(Grid)`
  &.MuiGrid-root {
    &.MuiGrid-item {
      .MuiBox-root {
        margin-bottom: 0;
      }
    }
  }
`

const InputMultiSelect = styled(InputAutocomplete)`
  & .MuiInputLabel-formControl {
    background-color: #fff;
    padding-right: 5px;
  }
`

const FormBox = styled(Form)`
  padding-right: 28px;
`

export {
  SwitchAdvSearch,
  Chip,
  Checkbox,
  Switch,
  InputMultiSelect,
  CardGrid,
  GroupButtons,
  FormBox,
  Box
}
