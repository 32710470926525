/* eslint-disable react/no-danger */
import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import * as Styled from './style'

const initialValues = {
  content: ''
}

const ModalSms = ({
  handleClose,
  open,
  icon,
  title,
  message,
  onSubmit,
  colorConfirm,
  disabledButton,
  phone
}) => (
  <Styled.Modal open={open} onClose={handleClose}>
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, handleChange }) => (
        <Form>
          <Styled.Grid>
            <Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Styled.IconText>
                  {icon}
                  <Styled.Title variant="h5">{title}</Styled.Title>
                </Styled.IconText>
                <Typography variant="subtitle1">
                  <span dangerouslySetInnerHTML={{ __html: message }} />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Styled.TextField
                  label="Destinatário"
                  name="phone"
                  fullWidth
                  multiline
                  value={phone}
                  disabled
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Styled.TextField
                  placeholder="Mensagem"
                  fullWidth
                  id="content"
                  value={values?.content}
                  onChange={handleChange}
                  multiline
                  minRows={4}
                  isMargin="0 0 24px"
                />
              </Grid>
            </Grid>
            <ButtonFooterV2
              size="large"
              labelClose="CANCELAR"
              labelConfirm="Enviar"
              onClickClose={handleClose}
              colorConfirm={colorConfirm}
              disabledConfirm={disabledButton && !values?.group}
            />
          </Styled.Grid>
        </Form>
      )}
    </Formik>
  </Styled.Modal>
)

export default ModalSms
