import PropTypes from 'prop-types'
import { InputLabel, FormControl, Autocomplete, TextField } from '@mui/material'
import * as Styled from './style'

const InputAutocomplete = ({
  className,
  labelId,
  handleChange,
  listOptions,
  title,
  disabledSelect,
  disabledInput,
  width,
  renderTags,
  label,
  placeholder,
  isOptionEqualToValue,
  defaultValue,
  freeSolo,
  getOptionLabel,
  filterSelectedOptions,
  renderOption,
  helperText,
  multiple = false,
  isColor,
  value,
  hasMarginBottom,
  ...props
}) => (
  <Styled.BoxAutoComplete $width={width} $hasMarginBottom={hasMarginBottom}>
    <FormControl fullWidth disabled={disabledSelect}>
      <InputLabel id={labelId} disabled={disabledInput}>
        {title}
      </InputLabel>
      <Autocomplete
        className={className}
        id={labelId}
        multiple={multiple}
        value={value}
        options={listOptions}
        filterSelectedOptions={filterSelectedOptions}
        getOptionLabel={getOptionLabel}
        defaultValue={defaultValue}
        onChange={handleChange}
        isOptionEqualToValue={isOptionEqualToValue}
        freeSolo={freeSolo}
        renderTags={renderTags}
        renderOption={renderOption}
        {...props}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={placeholder} />
        )}
      />
      {helperText && (
        <Styled.FormText
          $isColor={isColor}
          dangerouslySetInnerHTML={{ __html: helperText }}
        />
      )}
    </FormControl>
  </Styled.BoxAutoComplete>
)

export default InputAutocomplete

InputAutocomplete.propTypes = {
  className: PropTypes.string,
  handleChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  renderOption: PropTypes.func,
  renderTags: PropTypes.func,
  multiple: PropTypes.bool,
  freeSolo: PropTypes.bool,
  title: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  filterSelectedOptions: PropTypes.bool,
  listOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

InputAutocomplete.defaultProps = {
  handleChange: () => {},
  getOptionLabel: () => {},
  isOptionEqualToValue: () => {},
  renderOption: () => {},
  renderTags: () => {},
  multiple: false,
  freeSolo: false,
  title: '',
  label: '',
  placeholder: '',
  listOptions: [],
  filterSelectedOptions: false
}
