/* eslint-disable camelcase */
const formatterMoney = (data) =>
  data ? data.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : ''

const parseResponseTypeRequeriment = ({
  account_reference,
  value,
  level,
  unit,
  requirement,
  course,
  cost_center_name_cc3,
  cost_center_type_cc4,
  cost_center_subcategory_cc5,
  portal,
  cost_center_nature_cc6,
  responsible_sector_id,
  responsible_user_id,
  term,
  request_text,
  considerations
}) => ({
  level,
  course,
  portal,
  requirement,
  account: account_reference,
  amount: formatterMoney(value),
  unity: unit,
  costCenter: cost_center_name_cc3,
  type: cost_center_type_cc4,
  subcategory: cost_center_subcategory_cc5,
  nature: cost_center_nature_cc6,
  sector: responsible_sector_id,
  responsible: responsible_user_id,
  term: term?.replace(/[^0-9]/g, ''),
  requestText: request_text,
  observation: considerations
})

export { parseResponseTypeRequeriment }
