import styled from 'styled-components'
import { Box as MuiBox, TableCell as MuiTableCell } from '@mui/material'
import theme from 'theme/designTokens'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px;
    width: 8.375rem;
  }
`

const statusInactive = styled(MuiBox)`
  &.MuiBox-root {
    color: ${() => theme.palette.error.light};
  }
`

const TableCellOccurrence = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px;
    width: 36.313rem;
  }
`

const TableCellName = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px;
    width: 16rem;
  }
`

const TableCellCheckbox = styled(MuiTableCell)`
  &.MuiTableCell-root {
    width: 0%;
  }
`

const TableCellCenter = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: center;
    padding: 6px;
    width: 10%;
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    text-decoration: underline;
    width: 7.5rem;
    padding: 6px;
    margin-left: 6px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${() => theme.palette.primary.main};

      button {
        padding: 0;
        margin-left: 8px;
      }

      &:hover > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`

export {
  TableCell,
  TableCellTitle,
  TableCellCenter,
  TableCellCheckbox,
  TableCellOccurrence,
  TableCellName,
  statusInactive
}
