import { ROUTE } from 'services/constants'

const breadcrumbsItems = (title) => [
  {
    path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS,
    label: 'Turmas'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_CLASSES_DASHBOARD,
    label: 'Dashboard'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_CLASSES_DASHBOARD_CLASS,
    label: `<b>Turma ${title}</b>`
  }
]

const isDisabledButton = ({ startDate, endDate }) => !startDate || !endDate

export { breadcrumbsItems, isDisabledButton }
