import styled from 'styled-components'
import { TableCell as MuiTableCell } from '@mui/material'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
  }
`

const TableCellHours = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: center;
    padding: 6px 16px;
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    text-decoration: underline;
    padding: 6px;
    width: 55%;
    margin-left: 6px;
    padding: 10px 13px;

    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      color: ${({ theme }) => theme.palette.primary.main};

      button {
        padding: 0;
      }

      & > .bnt-delete {
        display: none;
        margin: 0 0 0 20px;
      }

      &:hover > .bnt-delete {
        display: flex;
        align-items: center;
        justify-content: center;

        & .MuiSvgIcon-root {
          color: ${({ theme }) => theme.palette.red.main};
        }
      }
    }
  }
`

export { TableCell, TableCellTitle, TableCellHours }
