const initialValues = {
  serviceSector: '',
  responsible: '',
  situation: '',
  description: '',
  file: ''
}

const payload = ({ values, listSector, listResponsible }) => {
  const idSector = listSector.find(
    (requirement) => requirement.value === values?.serviceSector
  )

  const idResponsible = listResponsible.find(
    (requirement) => requirement.value === values?.responsible
  )

  return {
    sector_id: idSector?.id,
    responsible_sector_id: idResponsible?.id || '',
    responsible_sector_user: idResponsible?.responsible_sector_user || '',
    status: values?.situation,
    observation: values?.description,
    file: values?.file || undefined
  }
}

const MESSAGE = {
  SUCCESS: 'Requerimento salvo com sucesso'
}

const statusHistoric = [
  {
    value: 'Em análise',
    label: 'Em análise'
  },
  {
    value: 'Indeferido',
    label: 'Indeferido'
  },
  {
    value: 'Cancelado',
    label: 'Cancelado'
  },
  {
    value: 'Deferido',
    label: 'Deferido'
  }
]

export { initialValues, statusHistoric, payload, MESSAGE }
