import { MIN_AGE } from '../../constants'

const isValidAge = (birth) => {
  const currentYear = new Date().getFullYear()
  const yearFromBirth = birth.split('/')[2]
  const isValidDate = Number(currentYear) - Number(yearFromBirth)
  return isValidDate > MIN_AGE
}
const formatedCity = (city) => {
  const result = city
    .toLowerCase()
    .replace(/(^|\s)\S/g, (match) => match.toUpperCase())
  return result || ''
}

export { isValidAge, formatedCity }
