import { lazy, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid } from '@mui/material'
import { Search } from '@mui/icons-material'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useCheckedTable, useSortTable } from 'services/hooks'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import { convertToParams, isEmpty } from 'services/helpers'
import { AccordionFilter, TextNotFound } from 'components/atoms'
import {
  MESSAGE,
  breadcrumbsItems,
  initialValues,
  initialValuesClasses,
  numberOfDaysForAddExtraActivity
} from './constants'
import {
  createCheckSchoolClasses,
  getDetailsSchoolClasses,
  getListProfessors,
  resetHoursClass
} from './services'
import * as Styled from './style'
import { textMessageModal } from './components/Table/constants'
import schema from './schema'

const Table = lazy(() => import('./components/Table'))
const AddExtraClasses = lazy(() => import('./components/AddExtraClasses'))

const DetailsManageClasses = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [expanded, setExpanded] = useState('AddExtraClass')
  const [notFound, setNotFound] = useState(false)
  const [detailsSchoolClasses, setDetailsSchoolClasses] = useState([])
  const [classes, setClasses] = useState([])
  const [optionsProfessors, setOptionsProfessors] = useState([])
  const [openModalReset, setOpenModalReset] = useState(false)
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    detailsSchoolClasses,
    'lesson_date'
  )

  const isChecked = params.status === 'checked'
  const isSent = params.status === 'sent'

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected: selectedItem,
    setSelected
  } = useCheckedTable(detailsSchoolClasses, 'index')

  const handleResetHoursClass = async () => {
    setLoadingOpen(true)
    await resetHoursClass({
      classId: params.schoolClassId,
      payrollId: params.payrollId
    })
    setSearchParams({ ...params, status: 'pending' })
    setOpenModalReset(false)
  }

  const formikAddClasses = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialValuesClasses,
    onSubmit: (values) => {
      const newClass = {
        id: Math.random(),
        total_lessons_cost: (
          +values.hourly_lesson_cost.replace(',', '.') * +values.lesson_hour
        )?.toString(),
        lesson_minutes: +values.lesson_hour * 60,
        ...values
      }

      setClasses([...classes, newClass])
      formikAddClasses.handleReset()
    },
    validationSchema: schema
  })

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      setLoadingOpen(true)
      await createCheckSchoolClasses({
        checkedIdList: values.checked,
        classes,
        params,
        detailsSchoolClasses
      })

      if (isChecked) {
        await getDetailsSchoolClasses({
          params,
          setDetailsSchoolClasses,
          setNotFound
        })
      }

      setSearchParams({ ...params, status: 'checked' })
      setOpenModalReset(false)
      setSelected([])
      setClasses([])
      setLoadingOpen(false)
    }
  })

  useEffect(async () => {
    setLoadingOpen(true)

    await Promise.all([
      getListProfessors({ params, setOptionsProfessors }),
      getDetailsSchoolClasses({
        params,
        setDetailsSchoolClasses,
        setNotFound
      })
    ])
    setLoadingOpen(false)
  }, [searchParams])

  return (
    <>
      <TemplateDefaultHeaderByContent
        breadcrumbsItems={breadcrumbsItems({ params, navigate })}
        classNameHeader="goBack"
        loadingOpen={loadingOpen}
        onClickButton={() =>
          navigate(
            `${ROUTE.ADMIN_HR_MANAGE_CLASSES}?${convertToParams({
              payroll_id: params.payrollId,
              unit: params.unit
            })}`,
            {
              state: { savedSearch: true }
            }
          )
        }
        content={
          <>
            {notFound ? (
              <TextNotFound
                icon={<Search />}
                text="Não foram encontrado nenhum detalhe dessa turma."
              />
            ) : (
              <form
                onSubmit={formik.handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Table
                  order={order}
                  orderBy={orderBy}
                  isSent={isSent}
                  isChecked={isChecked}
                  row={detailsSchoolClasses}
                  sortTable={detailsSchoolClasses}
                  selected={selectedItem}
                  values={formik.values.checked}
                  setFieldValues={formik.setFieldValue}
                  handleRequestSort={handleRequestSort}
                  handleClick={handleClick}
                  isSelected={isSelected}
                  handleSelectAllClick={handleSelectAllClick}
                />

                {isChecked && (
                  <AccordionFilter
                    title={MESSAGE.titleAcc}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    nameAccordion="AddExtraClass"
                    margin
                  >
                    <AddExtraClasses
                      formik={formikAddClasses}
                      classes={classes}
                      optionsProfessors={optionsProfessors}
                      setClasses={setClasses}
                    />
                  </AccordionFilter>
                )}

                <Styled.GridContainer container spacing={{ xs: 0, sm: 2 }}>
                  <Grid item xs={12} sm={12} md={isSent ? 12 : 9}>
                    <Styled.Typography variant="caption" component="caption">
                      <Styled.Box>A</Styled.Box>
                      <div className="text">Em agrupamento</div>
                    </Styled.Typography>

                    <Styled.Typography variant="caption">
                      <Styled.BoxAE>AE</Styled.BoxAE>
                      <div className="textAE">
                        A adição de atividades extras ficará disponível por
                        <b> {numberOfDaysForAddExtraActivity} dias</b> após a
                        apuração.
                      </div>
                    </Styled.Typography>
                  </Grid>

                  {!isSent && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ButtonFooterV2
                        size="medium"
                        variantClose={isChecked ? 'contained' : 'outlined'}
                        labelConfirm="Confirmar"
                        labelClose={!isChecked ? 'Cancelar' : 'Resetar'}
                        colorClose={!isChecked ? 'primary' : 'warning'}
                        disabledConfirm={
                          (isChecked && isEmpty(classes)) ||
                          (!isChecked && isEmpty(formik.values.checked))
                        }
                        onClickClose={() => {
                          if (isChecked) return setOpenModalReset(true)

                          formik.setFieldValue('checked', [])
                          return setSelected([])
                        }}
                      />
                    </Grid>
                  )}
                </Styled.GridContainer>
              </form>
            )}
          </>
        }
      />

      <ModalConfirm
        open={openModalReset}
        size="medium"
        handleClose={() => setOpenModalReset(false)}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={() => setOpenModalReset(false)}
        onClickConfirm={handleResetHoursClass}
        colorButtonConfirm="error"
        iconWarningAmber
        title="Resetar conferência de turma"
        message={textMessageModal}
      />
    </>
  )
}

export default DetailsManageClasses
