import styled from 'styled-components'
import theme from 'theme/designTokens'

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border: 1px dashed ${() => theme.palette.gray.bgLight};
  padding: 16px;
  width: 100%;

  & > div {
    margin-bottom: 16px;
  }
`

const BoxTooltip = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

export { Container, BoxTooltip }
