import React, { useState, lazy, Suspense, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppBar, Box, Link } from '@mui/material'
import { useStore } from 'store/GlobalContext'
import { ROUTE } from 'services/constants'
import { useMainRoute } from 'services/hooks'
import { Logotype } from 'components/atoms'
import UserMenu from './components/UserMenu'
import NotificationMenu from './components/NotificationMenu'
import * as Styled from './style'

const Sidebar = lazy(() => import('components/organisms/Sidebar'))

const Header = () => {
  const { user } = useStore()
  const location = useLocation()
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const { url } = useMainRoute()
  const navigate = useNavigate()
  const isScreenInitial = location.pathname === ROUTE.ADMIN

  useEffect(
    () => isScreenInitial && setIsOpenSidebar(true),
    [location.pathname]
  )

  return (
    <>
      <AppBar color="primary" sx={{ zIndex: 2 }}>
        <Styled.Container>
          <Styled.ItemWrapper>
            {!isScreenInitial && (
              <Styled.IconButton
                onClick={() => setIsOpenSidebar(!isOpenSidebar)}
              >
                <Styled.MenuRounded fontSize="small" />
              </Styled.IconButton>
            )}

            <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(url)}>
              <Logotype alt="Proz" height="44px" width="120px" isDark={false} />
            </Box>
          </Styled.ItemWrapper>

          <Styled.ItemWrapper>
            <Styled.ItemWrapper>
              <NotificationMenu />
            </Styled.ItemWrapper>
            <Styled.ItemWrapperUser>
              <UserMenu user={user} />
            </Styled.ItemWrapperUser>
          </Styled.ItemWrapper>
        </Styled.Container>
      </AppBar>

      <Suspense fallback={null}>
        <Sidebar
          isOpenSidebar={isOpenSidebar}
          setIsOpenSidebar={setIsOpenSidebar}
        />
      </Suspense>
    </>
  )
}
export default Header
