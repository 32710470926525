import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório.'),
  cpf: yup
    .string()
    .test('len', 'O CPF deve conter exatamente 11 dígitos.', (val) => {
      if (!val) return false
      const cpf = val.replace(/[^\d]/g, '')
      return cpf && cpf.toString().length === 11
    })
    .required('O CPF é obrigatório.'),
  email: yup
    .string()
    .email('Insira um e-mail válido.')
    .required('O e-mail é obrigatório.')
})

export { schema }
