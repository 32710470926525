import { ROUTE } from 'services/constants'

const breadcrumbsItems = () => [
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Requerimentos'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES_CREATE,
    label: '<b>Novo</b>'
  }
]

const isDisabledButton = (values) => {
  const { type } = values

  const disabled = !type

  return disabled
}

const payload = ({ values, register }) => ({
  register,
  requirement_type: values?.type,
  school_class_id: values?.schoolClass,
  course_id: values?.course,
  sector_id: values?.initialSector,
  ...(values?.responsible && { responsible_sector_id: values?.responsible }),
  observation: values?.observation,
  attachment: values?.attachment || undefined
})

export { isDisabledButton, breadcrumbsItems, payload }
