import { MAX_LESSONS_PER_PAGE } from '../constants'
import { utilsPDF } from '../../shared/utils'
import buildTableHeader from './Header'
import buildTableBody from './Body'
import buildTableFooter from './Footer'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

const associateStudenstWithFrequencies = ({ students, lessons, frequencies }) =>
  students.map((studentElement) => {
    const student = {
      ...studentElement,
      frequencies: new Array(MAX_LESSONS_PER_PAGE)
        .fill(null)
        .map((element, index) => {
          const lesson = lessons[index]
          if (lesson) {
            const frequencyFound = frequencies.find(
              (frequency) =>
                +frequency.lessonNumber === +lesson.lessonNumber &&
                +frequency.enrollment === +studentElement.enrollment
            )
            return frequencyFound
          }
          return element
        })
    }
    return student
  })

const buildTable = ({
  students,
  lessons,
  frequencies,
  classInfo,
  multiplyLessonBy
}) => {
  const studentsWithFrequency = associateStudenstWithFrequencies({
    frequencies,
    lessons,
    students
  })
  return [
    {
      table: {
        body: [
          ...buildTableHeader({ lessons, multiplyLessonBy }),
          ...buildTableBody(studentsWithFrequency)
        ],
        widths: [
          'auto',
          '*',
          17,
          ...new Array(MAX_LESSONS_PER_PAGE).fill(16),
          80
        ]
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    }
  ]
}

export default buildTable
