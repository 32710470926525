import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'

const Grid = styled(MuiGrid)`
  &.MuiGrid-container {
    margin-top: 25px;
  }
`

export { Grid }
