/* eslint-disable camelcase */
import FEEDBACK_SNACK from 'feedBackSnack'
import { getCurriculumSubject } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import { parserSubjects } from './helpers'

const parseValues = (values) => ({
  active: values?.active,
  level: values?.level,
  course: values?.course,
  yearGrid: values?.year_grid,
  resolution: values?.resolution,
  complement: values?.complement,
  disciplines: [
    {
      discipline: values?.disciplines,
      workload: values?.workload,
      code: values?.discipline_code,
      avaliationType: values?.avaliation_type,
      moduleNumber: values?.module_number,
      active: values?.active
    }
  ]
})

const listSubjects = async ({ setSnack, setSubject }) => {
  setSnack('')

  const { data: response, error, status } = await getCurriculumSubject()

  const res = parserSubjects(response.data)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListListJoyCourses
    })
  }

  return setSubject(res)
}

export { parseValues, listSubjects }
