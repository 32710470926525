import styled from 'styled-components'
import { Box as BoxMui, Grid as MuiGrid, Typography } from '@mui/material'
import { Close as MuiClose } from '@mui/icons-material'
import { Modal as MuiModal } from 'components/atoms'

const Box = styled(BoxMui)`
  &.MuiBox-root {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 16px 0;
  }
`

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 790px;
  }
`

const Grid = styled(MuiGrid)`
  padding: 21px;
`

const GridForm = styled(MuiGrid)`
  &.MuiGrid-root {
    margin-top: 13px;
  }
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const Close = styled(MuiClose)`
  color: rgba(0, 0, 0, 0.54);
  :hover {
    cursor: pointer;
  }
`

export { Box, Modal, Grid, Title, Close, GridForm }
