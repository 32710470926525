const KEY_COLUMNS = {
  ID: 'id',
  ACTIVE: 'active',
  LAST_MOVING: 'lastMoving',
  RESPONSIBLE: 'responsible',
  OBSERVATION: 'observation',
  COURSE_LOAD: 'courseLoad',
  SITUATION: 'situation'
}

const tableColumns = ({ isLastPageAvaliation }) => [
  ...(!isLastPageAvaliation ? [{ id: KEY_COLUMNS.ID, label: 'ID' }] : []),
  { id: KEY_COLUMNS.ACTIVE, label: 'Atividade' },
  ...(!isLastPageAvaliation
    ? [
        { id: KEY_COLUMNS.LAST_MOVING, label: 'Última movimentação' },
        { id: KEY_COLUMNS.RESPONSIBLE, label: 'Responsável' }
      ]
    : [{ id: KEY_COLUMNS.OBSERVATION, label: 'Observação / Parecer' }]),
  { id: KEY_COLUMNS.COURSE_LOAD, label: 'Carga horária' },
  { id: KEY_COLUMNS.SITUATION, label: 'Situação' }
]

export { KEY_COLUMNS, tableColumns }
