import { Grid, Typography } from '@mui/material'
import { EmailOutlined, Download } from '@mui/icons-material'
import * as Styled from './style'
import { handleExport } from '../../DashboardClass/service'

const TableToolbar = ({
  row,
  values,
  amount,
  classId,
  setSnack,
  setIsOpenModalEmail
}) => (
  <Styled.ToolbarArea>
    <Grid container spacing={{ xs: 0, sm: 2 }}>
      {Boolean(amount) && (
        <Grid item xs={12} sm={12} md={1.8}>
          <Styled.Button
            variant="outlined"
            onClick={() => setIsOpenModalEmail(true)}
            startIcon={<EmailOutlined sx={{ color: '#FF7F00' }} />}
          >
            Enviar e-mail
          </Styled.Button>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={2.4}>
        <Styled.Button
          variant="outlined"
          onClick={() => handleExport({ row, values, classId, setSnack })}
          startIcon={<Download sx={{ color: '#FF7F00' }} />}
        >
          Exportar relatório
        </Styled.Button>
      </Grid>
      {Boolean(amount) && (
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body2">
            {amount > 1
              ? `${amount} itens selecionados`
              : `${amount} item selecionado`}
          </Typography>
        </Grid>
      )}
    </Grid>
  </Styled.ToolbarArea>
)
export default TableToolbar
