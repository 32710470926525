import styled from 'styled-components'
import {
  Grid as MuiGrid,
  Box as MuiBox,
  Typography as MuiTypography
} from '@mui/material'
import { Download as iconDownload } from '@mui/icons-material'
import theme from 'theme/designTokens'

const Box = styled(MuiBox)`
  &.MuiBox-root  {
    width: 22px;
    height: 22px;
    font-size: 14px;
    texte-align: center:
    text-transform: uppercase;
    font-weight: bold;
    color: ${() => theme.palette.primary.white};
    background-color: ${() => theme.palette.yellow.dark};
  }
`

const GridContainer = styled(MuiGrid)`
  &.MuiGrid-container {
    padding: 44px 0;

    & .MuiGrid-item {
      &:first-child {
        padding-top: 6px;
      }

      &:last-child {
        padding-top: 0px;
      }
    }
  }
`
const Grid = styled(MuiGrid)`
  &.MuiGrid-item {
    padding-bottom: 48px;
  }
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    display: flex;
    align-items: center;
    width: 100%;

    & .text {
      margin-left: 5px;
    }
  }
`

const GridIcon = styled(MuiGrid)`
  &.MuiGrid-item {
    display: flex;
    justify-content: flex-end;
    padding-right: 18px;
  }
`

const GridButtonEmpty = styled(MuiGrid)`
  &.MuiGrid-item {
    display: flex;
    justify-content: center;
  }
`

const Download = styled(iconDownload)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${() => theme.palette.dark.medium};
  }
`

export {
  Box,
  Typography,
  GridContainer,
  Grid,
  Download,
  GridIcon,
  GridButtonEmpty
}
