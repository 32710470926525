import styled from 'styled-components'
import { Grid as MuiGrid, Typography } from '@mui/material'
import { Close as MuiClose } from '@mui/icons-material'
import { Modal as MuiModal } from 'components/atoms'

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 790px;
  }
`

const Grid = styled(MuiGrid)`
  padding: 21px;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const Close = styled(MuiClose)`
  color: rgba(0, 0, 0, 0.54);
  :hover {
    cursor: pointer;
  }
`

export { Modal, Grid, Title, Close }
