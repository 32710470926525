import { Grid, InputAdornment, Typography } from '@mui/material'

import { formatDate, currencyMaskWithoutPrefix } from 'services/helpers'
import * as Styled from './style'

const FormSchoolClassDatails = ({
  values,
  hourlyLessonCost,
  setHourlyLessonCost
}) => {
  const StyledText = ({ field, content, $margin, md }) => (
    <Styled.Grid item xs={12} sm={12} md={md || 4} $margin={$margin}>
      <Styled.Text>
        <b>{field}</b> {content}
      </Styled.Text>
    </Styled.Grid>
  )

  return (
    <>
      <Grid container spacing={{ xs: 0, sm: 2 }}>
        <Styled.Grid $margin="0 0 15px" item xs={12} sm={12} md={12}>
          <Typography variant="h5">Turma {values?.code}</Typography>
        </Styled.Grid>
        <StyledText field="Unidade" content={values?.unit} />
        {values?.pole && <StyledText field="Polo" content={values?.pole} />}
        <StyledText field="Grupo" content={values?.group} />
        <StyledText field="Modalidade" content={values?.modality} />
        <StyledText field="Nível" content={values?.level} />
        <StyledText field="Curso" content={values?.name} />
        <StyledText field="Turno" content={values?.shift} />
        <StyledText field="Início" content={formatDate(values?.begin_at)} />
        <StyledText field="Fim" content={formatDate(values?.end_at)} />
        <StyledText field="Valor hora-aula" $margin="45px 0 0" md={12} />
        <Styled.Grid item xs={12} sm={12} md={8}>
          Informe o valor hora-aula para as disciplinas selecionadas:
          <Styled.TextField
            fullWidth
            id="formatted-numberformat-input"
            name="value"
            onChange={(e) => {
              setHourlyLessonCost(e.target.value)
            }}
            InputProps={{
              value: currencyMaskWithoutPrefix(hourlyLessonCost || ''),
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              )
            }}
            isMargin="0"
          />
        </Styled.Grid>
      </Grid>
    </>
  )
}
export { FormSchoolClassDatails }
