import { httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getTypesOfActivitys = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.TYPES_ACTIVETYS.BASE_URL_TYPES_ACTIVETYS}?${convertToParams(
      params
    )}`
  )
  return response
}

export const getDetailsTypesOfActivity = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.TYPES_ACTIVETYS.BASE_URL_TYPES_ACTIVETYS}/${id}`
  )
  return response
}

export const postTypesOfActivity = async (params) => {
  const response = await httpPost(
    ENDPOINT.TYPES_ACTIVETYS.BASE_URL_TYPES_ACTIVETYS,
    {
      ...params
    }
  )

  return response
}

export const editTypesOfActivity = async (id, params) => {
  const response = await httpPut(
    `${ENDPOINT.TYPES_ACTIVETYS.BASE_URL_TYPES_ACTIVETYS}/${id}`,
    params
  )

  return response
}

export const editStatusActivitysTypes = async (params) => {
  const response = await httpPut(
    `${ENDPOINT.TYPES_ACTIVETYS.BASE_URL_TYPES_ACTIVETYS}/inactivate
  `,
    params
  )

  return response
}
