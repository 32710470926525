const listTypeResponsible = [
  {
    label: 'Pai',
    fieldName: 'pai'
  },
  {
    label: 'Mãe',
    fieldName: 'mae'
  },
  {
    label: 'Outro',
    fieldName: 'outro'
  }
]

const listAffiliation = [
  {
    label: 'Pai',
    fieldName: 'father'
  },
  {
    label: 'Mãe',
    fieldName: 'mother'
  },
  {
    label: 'Outro',
    fieldName: 'otherGuardian'
  }
]

export { listAffiliation, listTypeResponsible }
