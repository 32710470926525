/* eslint-disable no-unused-expressions */
import { useState } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { PersonAddAlt1, PersonRemoveAlt1 } from '@mui/icons-material'
import { TableHeaderDefault } from 'components/organisms'
import { useCheckedTable, useSortTable } from 'services/hooks'
import { ModalConfirm, PaginationAdvanced } from 'components/molecules'
import { getLenght, isEmpty, paginationFilter } from 'services/helpers'
import * as Styled from './style'
import { MESSAGES, filterByIdCheckbox, headCells } from './constants'
import { TableTdBody } from './TableBody'
import { defaultItemsPerPage, defaultPage } from '../../contants'
import Toolbar from './Toolbar'
import { parseChecked } from '../../helpers'
import { updateListBindByUnbinded } from '../../services'

const TableBindByUnbinded = ({
  filter,
  rowsItems,
  countBind,
  countUnbinded,
  unbinded,
  totalPageUnbinded,
  setPageUnbinded,
  totalPageBind,
  setPageBind,
  setFilter,
  pageUnbinded,
  pageBind,
  classId,
  setSnack,
  setLoadingOpen,
  setListUnbinded,
  setCountUnbinded,
  setCountBind,
  setNotUnbinded,
  setNotBind,
  setListBind,
  setTabSelected
}) => {
  const [openModal, setOpenModal] = useState(false)

  const { handleRequestSort, order, orderBy, sortTable } =
    useSortTable(rowsItems)

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected,
    setSelected
  } = useCheckedTable(rowsItems, filterByIdCheckbox)

  const handleSetPage = (_, page) => {
    if (unbinded) {
      return setPageUnbinded(+page)
    }

    return setPageBind(+page)
  }

  const handleConfirme = ({ isUnbinded }) =>
    updateListBindByUnbinded({
      isUnbinded,
      classId,
      setSnack,
      setLoadingOpen,
      setListBind,
      pageBind,
      setCountBind,
      setNotBind,
      filter,
      items: sortTable,
      setOpenModal,
      selected,
      setSelected,
      setListUnbinded,
      pageUnbinded,
      setNotUnbinded,
      setCountUnbinded,
      setTabSelected
    })

  const allInactive = !sortTable.every((i) => !i.has_grades && !i?.occurrences)

  const isShowPagination =
    countBind > defaultItemsPerPage || countUnbinded > defaultItemsPerPage

  const handleChecked = () => {
    if (isEmpty(selected)) {
      const responseMapForEnrollment = parseChecked(rowsItems)?.filter(
        (enrollment) => {
          const row = rowsItems?.find((item) => item.enrollment === enrollment)
          return row && !row.has_grades && !row?.occurrences
        }
      )

      responseMapForEnrollment.map(handleClick)
      return setSelected(responseMapForEnrollment)
    }

    return setSelected([])
  }

  return (
    <>
      <Toolbar
        hasBind={!unbinded}
        setOpenModal={setOpenModal}
        selected={selected}
      />

      {!isEmpty(selected) && (
        <Styled.Typography variant="body1">{`${getLenght(
          selected
        )} items selecionados`}</Styled.Typography>
      )}

      <Styled.Paper>
        <Styled.TableContainer $isSelected={!isEmpty(selected)}>
          <MuiTable>
            <TableHeaderDefault
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={
                !unbinded ? handleChecked : handleSelectAllClick
              }
              onRequestSort={handleRequestSort}
              rowCount={rowsItems?.length}
              isSelected={isSelected}
              headCells={headCells}
              disabledCheckbox={allInactive}
              checkbox
            />
            <TableBody>
              {sortTable?.map((row, index) => {
                const isItemSelected = isSelected(row?.enrollment)
                const labelId = `enhanced-table-${index}`

                return (
                  <TableTdBody
                    row={row}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    handleClick={handleClick}
                  />
                )
              })}
            </TableBody>
          </MuiTable>
        </Styled.TableContainer>

        {isShowPagination && (
          <PaginationAdvanced
            isSelectItensPerPage
            value={filter}
            optionsList={paginationFilter}
            count={unbinded ? totalPageUnbinded : totalPageBind}
            page={unbinded ? pageUnbinded : pageBind}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setFilter(e.target.value)

              if (unbinded) {
                return setPageUnbinded(defaultPage)
              }

              return setPageBind(defaultPage)
            }}
          />
        )}
      </Styled.Paper>

      <ModalConfirm
        open={openModal}
        size="medium"
        handleClose={() => setOpenModal(false)}
        textButtonNotConfirm="Cancelar"
        textButtonConfirm="Confirmar"
        onClickNotConfirm={() => setOpenModal(false)}
        onClickConfirm={() => handleConfirme({ isUnbinded: unbinded })}
        colorButtonNotConfirm="error"
        colorButtonConfirm="primary"
        icon={unbinded ? <PersonAddAlt1 /> : <PersonRemoveAlt1 />}
        title={unbinded ? 'Enturmar' : 'Desenturmar'}
        message={unbinded ? MESSAGES.CONFIRM_BIND : MESSAGES.CONFIRM_UNBINDED}
      />
    </>
  )
}

export { TableBindByUnbinded }
