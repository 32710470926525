import { jsPDF as JsPDF } from 'jspdf'
import 'jspdf-autotable'
import { DATE_FORMAT } from 'services/constants'
import { dateFormat } from './date'
import { formatMoney } from './money'

const EMPTY_DATE = ''

export const generatePDF = (extractDetail) => {
  const doc = new JsPDF()
  const tableColumn = [
    'Titulo',
    'Vencimento',
    'Valor',
    'Valor Pago',
    'Pago em',
    'Situação',
  ]
  const tableRows = []

  extractDetail.forEach((extract) => {
    const extractData = [
      extract.idTitulo,
      dateFormat(extract.dueDate, DATE_FORMAT.DD_MM_YYYY),
      formatMoney(extract.amount),
      formatMoney(extract.amountPaid),
      extract.paymentDate
        ? dateFormat(extract.paymentDate, DATE_FORMAT.DD_MM_YYYY)
        : EMPTY_DATE,
      extract.statusLabel,
    ]
    tableRows.push(extractData)
  })

  doc.autoTable(tableColumn, tableRows, {
    headStyles: { fillColor: [224, 224, 224], textColor: [0, 0, 0] },
    startY: 25,
  })

  const date = dateFormat(new Date(), DATE_FORMAT.DD_MM_YYYY)
  doc.text('Detalhamento Financeiro', 14, 20)
  doc.setFontSize(10)
  doc.text(`Gerado por Proz Educação ERP, em ${date}`, 70, 290)
  doc.save('extrato-detalhado.pdf')
}
