import { ROUTE } from 'services/constants'
import { convertToParams } from 'services/helpers'

const MESSAGE = {
  SUCCESS_SAVE_ACADEMIC_PERFORMANCE:
    'Aproveitamento de Estudos salvo com sucesso.',
  ERROR_SAVE_ACADEMIC_PERFORMANCE:
    'Houve um erro ao salvar Aproveitamento de estudos. Tente novamente.',
  SUCCESS_SAVE_ACADEMIC_PERFORMANCE_FEEDBACK:
    'Parecer Final concluído com sucesso.',
  ERROR_SAVE_ACADEMIC_PERFORMANCE_FEEDBACK:
    'Houve um erro ao concluir Parecer Final. Tente novamente.'
}

const PENDING = 0
const APPROVED = 1
const REJECTED = 2
const INCORRECT = 3
const TRANSFERRED = 4

const LIST_STATUS = [
  {
    value: PENDING,
    label: 'Pendente'
  },
  {
    value: APPROVED,
    label: 'Deferido'
  },
  {
    value: REJECTED,
    label: 'Indeferido'
  },
  {
    value: TRANSFERRED,
    label: 'Transferido'
  },
  {
    value: INCORRECT,
    label: 'Incorreto'
  }
]

const breadCrumbsItems = ({ classId, register, name }) => [
  {
    path: ROUTE.ADMIN_SECRETARY_ACADEMIC_PERFORMANCES,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_ACADEMIC_PERFORMANCES,
    label: 'Aproveitamento de Estudos'
  },
  {
    path: `${ROUTE.ADMIN_SECRETARY_ACADEMIC_PERFORMANCES_EDIT
      }?${convertToParams({
        register,
        name,
        classId
      })}`,
    label: `<b>[${register}] ${name}</b>`
  }
]

const initialFormValues = {
  hasInternship: false,
  internshipHours: '',
  isSendEmail: false,
  feedback: '',
  academicPerformances: []
}

export { breadCrumbsItems, initialFormValues, LIST_STATUS, MESSAGE }
