const initialValues = {
  status: '',
  workload: '',
  observation: ''
}

const enumRate = {
  Deferido: 'deferred',
  Indeferido: 'rejected'
}

const typeRate = {
  deferred: {
    label: 'Deferido',
    value: 'deferred'
  },
  rejected: {
    label: 'Indeferido',
    value: 'rejected'
  }
}

export { initialValues, enumRate, typeRate }
