import { FormControlLabel, Radio } from '@mui/material'
import { Select, TextField } from 'components/molecules'
import { InputCheckbox } from 'components/atoms'
import * as Styled from './style'
import { progressCoursersOptions } from './config'

const FormDeclarationContent = ({ level, values, setFieldValue }) => (
  <>
    <Styled.Grid item xs={12} sm={12} md={6}>
      <Select
        id="level"
        name="level"
        optionsList={level}
        value={values?.level}
        label="Selecione o nível"
        onChange={(e) => setFieldValue('level', e.target.value)}
      />
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={6}>
      <TextField
        fullWidth
        id="name"
        name="name"
        isMargin="0"
        value={values?.name}
        label="Nome da Declaração"
        onChange={(e) => setFieldValue('name', e.target.value)}
      />
    </Styled.Grid>

    <Styled.Grid flex item xs={12} sm={12} md={12}>
      <Styled.Typography variant="body1">
        Emitir somente com o curso em andamento?
      </Styled.Typography>
      <Styled.RadioGroup
        row
        name="check_date"
        value={values?.check_date}
        onChange={(e) => {
          setFieldValue('check_date', e.target.value === 'true')
        }}
      >
        <FormControlLabel
          value={progressCoursersOptions.yes.value}
          label={progressCoursersOptions.yes.label}
          checked={values?.check_date}
          control={<Radio size="small" />}
        />
        <FormControlLabel
          value={progressCoursersOptions.no.value}
          label={progressCoursersOptions.no.label}
          checked={!values?.check_date && values?.check_date !== null}
          control={<Radio size="small" />}
        />
      </Styled.RadioGroup>
    </Styled.Grid>
    <Styled.Grid flex item xs={12} sm={12} md={12}>
      <Styled.Typography variant="body1">
        Necessário para Emissão:
      </Styled.Typography>
      <Styled.RadioGroup
        row
        name="sending_required"
        value={values?.sending_required}
        onChange={(e) => setFieldValue('sending_required', e.target.value)}
      >
        <FormControlLabel
          value="1"
          control={<Radio size="small" />}
          label="Turma"
        />
        <FormControlLabel
          value="2"
          control={<Radio size="small" />}
          label="Curso"
        />
        <FormControlLabel
          value="0"
          control={<Radio size="small" />}
          label="Livre"
        />
        <FormControlLabel
          value="3"
          control={<Radio size="small" />}
          label="Imposto de Renda"
        />
      </Styled.RadioGroup>
    </Styled.Grid>
    <Styled.Grid flex item xs={12} sm={12} md={12}>
      <Styled.Typography variant="body1">
        Onde a declaração deverá ser emitida:
      </Styled.Typography>
      <InputCheckbox
        // className={TAG_MANAGER.financial_check_filters_pj}
        label="Portal do Aluno"
        checked={values?.online}
        handleChange={(e) => {
          setFieldValue('online', e.target.checked)
        }}
      />
      <InputCheckbox
        // className={TAG_MANAGER.financial_check_filters_pj}
        label="Secretaria"
        checked={values?.face_to_face}
        handleChange={(e) => {
          setFieldValue('face_to_face', e.target.checked)
        }}
      />
    </Styled.Grid>
  </>
)

export default FormDeclarationContent
