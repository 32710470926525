import {
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  httpPut
} from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getTypesRequirements = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_LIST_TYPES_REQUIREMENT}?${convertToParams(
      params
    )}`
  )

  return response
}

export const inactiveTypesRequirements = async (params) => {
  const queryString = new URLSearchParams(params).toString()
  const url = `${ENDPOINT.ADMIN_MANAGEMENT_INACTIVE_TYPES_REQUIREMENT}?${queryString}`

  const response = await httpPatch(url)

  return response
}

export const copyTypesRequirements = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_MANAGEMENT_COPY_TYPES_REQUIREMENT}`,
    {
      ...payload
    }
  )

  return response
}

export const createTypeRequirement = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_MANAGEMENT_TYPES_REQUIREMENT}`,
    {
      ...payload
    }
  )

  return response
}

export const updateTypeRequirement = async (id, payload) => {
  const response = await httpPut(
    `${ENDPOINT.ADMIN_MANAGEMENT_TYPES_REQUIREMENT}/${id}`,
    {
      ...payload
    }
  )

  return response
}

export const getsectorsResponsible = async () => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_SECTOR_RESPONSIBLE_TYPES_REQUIREMENT}`
  )

  return response
}

export const getBySpecificTypeRequirement = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.ADMIN_MANAGEMENT_SPECIFIC_TYPES_REQUIREMENT}/${id}`
  )

  return response
}

export const deleteTypesRequirements = async (payload) => {
  const response = await httpDelete(
    `${ENDPOINT.ADMIN_MANAGEMENT_TYPES_REQUIREMENT}`,
    {
      ...payload
    }
  )

  return response
}
