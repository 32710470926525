import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'

const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const GridContainer = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
`
const Form = styled.form``

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export { Grid, GridContainer, Form, IconWarningAmber }