import TAG_MANAGER from 'tagManager'

const isDisabledButton = ({ name }) => {
  const enabled = name && name.length >= 4
  return !enabled
}

const getTitleModalConfirm = (isActive) => (isActive ? 'Inativar' : 'Ativar')

const getMessageModalConfirm = (isActive, amount) => {
  let result = `Deseja realmente ${
    isActive ? 'inativar' : 'ativar'
  } a Atividade selecionado?`
  if (isActive && amount > 1) {
    result = `Deseja inativar as ${amount} tipos de Atividade selecionados?`
  } else if (amount > 1) {
    result = `Deseja ativar as ${amount} tipos de Atividade selecionados?`
  }
  return result
}

const getClassModalConfirm = (isActive, amount) => {
  let result = isActive
    ? TAG_MANAGER.sistema_btn_confirma_inativarContrato
    : TAG_MANAGER.sistema_btn_confirma_ativarContrato
  if (isActive && amount > 1) {
    result = TAG_MANAGER.sistema_btn_confirma_inativarContratos
  } else if (amount > 1) {
    result = TAG_MANAGER.sistema_btn_confirma_ativarContratos
  }
  return result
}
export {
  isDisabledButton,
  getClassModalConfirm,
  getTitleModalConfirm,
  getMessageModalConfirm
}
