import { ROUTE } from 'services/constants'

const selectAllTypes = 'Todos'
const defaultTotalPage = 1

const situations = {
  pending: 0,
  deferred: 1,
  rejected: 2,
  incorrect: 3,
  transferred: 4
}

const situationsById = {
  0: 'Pendente',
  1: 'Deferido',
  2: 'Indeferido',
  3: 'Incorreto',
  4: 'Transferido'
}

const type = {
  situation: {
    pending: 'pending',
    effective: 'effective',
    inactive: 'inactive'
  }
}

const breadcrumbsItems = (studentName, studentCourse) => [
  {
    path: '',
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: 'Alunos'
  },
  {
    path: '',
    label: studentName
  },
  {
    path: '',
    label: 'Curso'
  },
  {
    path: '',
    label: studentCourse
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_BREAKDOWN,
    label: '<b>Aproveitamento de estudos</b>'
  }
]

const initialState = {
  situation: 'pending',
  type: '',
  reason: '',
  switch: false
}

const statusAllowed = [
  situationsById[situations.incorrect],
  situationsById[situations.rejected],
  ''
]

export {
  type,
  selectAllTypes,
  defaultTotalPage,
  breadcrumbsItems,
  initialState,
  situations,
  situationsById,
  statusAllowed
}
