import { useState, lazy, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Formik } from 'formik'
import TAG_MANAGER from 'tagManager'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE, SUCCESS } from 'services/constants'
import { useSnack } from 'services/hooks'
import { editStudent, getStudentData } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { MESSAGE, initialValues } from './constants'
import { breadcrumbsItems } from './helpers'
import { FormFormik } from '../../Requisition/EditRequisition/components/FormHistoric/style'
import { mergeInitialValues, payload } from './services'

const Form = lazy(() => import('./Form'))

const EditStudent = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { contract, studentName, studentCourse } = params
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [studentId, setStudentId] = useState(initialValues)

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(ROUTE.ADMIN_SECRETARY_STUDENTS, {
      state: { savedSearch: true }
    })
  }
  const savedSuccessfully = () => {
    setSnack(MESSAGE.SUCCESS_EDIT, SUCCESS)

    return setTimeout(() => navigate(-1), 3000)
  }

  const handleStudentId = async () => {
    setSnack('')
    setLoading(true)
    const { data, error, status } = await getStudentData(contract)

    setLoading(false)
    if (error) {
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToResetPassword
      })
    }

    return setStudentId(data)
  }

  const onSubmit = async (values) => {
    setSnack('')
    setLoading(true)
    const { error, status } = await editStudent(contract, payload(values))
    setLoading(false)
    if (error) {
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToResetPassword
      })
    }

    return savedSuccessfully()
  }

  useEffect(() => {
    handleStudentId()
  }, [])

  const initialState = mergeInitialValues(studentId)

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems({ studentName, studentCourse })}
      onClickButton={() => setOpenModal(true)}
      classNameHeader="goBack"
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      tagmanagerModal={TAG_MANAGER.secretary_btn_BackApplication}
      content={
        <Formik
          initialValues={initialState}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(props) => (
            <FormFormik
              onSubmit={props.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <Form
                {...props}
                setSnack={setSnack}
                setOpenModal={setOpenModal}
              />
            </FormFormik>
          )}
        </Formik>
      }
    />
  )
}

export default EditStudent
