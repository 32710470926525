import styled from 'styled-components'

const CardContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  max-width: 400px;
`

export { CardContainer }
