import styled from 'styled-components'
import { Table as MuiTable } from '@mui/material'

const Table = styled(MuiTable)`
  min-width: 750px;
  .MuiTableRow-root.Mui-selected {
    background-color: ${({ theme }) => theme.palette.yellow.main};
  }
`

export { Table }
