import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'
import { AddBox, Delete } from '@mui/icons-material'
import { TextField as MolecTextField } from 'components/molecules'

const Grid = styled(MuiGrid)`
  display: flex;
  justify-content: center;
  text-align: center;
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.textColors.error};
  }
`

const AddIcon = styled(AddBox)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
  }
`

const BoxPhone = styled.div`
  border: 2px ${({ theme }) => theme.palette.gray.borderLight} dashed;
  padding: 17px 12px 0 12px;
`

const IconAdd = styled(AddBox)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    fill: ${({ theme }) => theme.palette.primary.main};
    outline: none;
    border: 0px;
    margin: 17px 0 0 14px;
  }
`

const IconRemove = styled(Delete)`
  &.MuiSvgIcon-root {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.red.light};
    outline: none;
    border: 0px;
    margin: 17px 0 0 14px;
  }
`
const TextField = styled(MolecTextField)`
  &.MuiFormControl-root {
    margin: 0 0 20px 0;
  }
`

export { Grid, DeleteIcon, AddIcon, BoxPhone, IconAdd, IconRemove, TextField }
