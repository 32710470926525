import {
  getListDisciplinesByFilter as getListDisciplinesByFilterService,
  linkClassInDiscilple as linkClassInDiscilpleService
} from 'services/api/admin'
import { deserialize, messageErroOrSuccess, serialize } from 'services/helpers'

const getListDisciplinesByFilter = async ({
  setSnack,
  register,
  subjectId,
  schoolClassId,
  searchBy,
  setList
}) => {
  const params = deserialize({
    register,
    subjectId,
    schoolClassId,
    subjectName: searchBy
  })

  const { error, status, data } = await getListDisciplinesByFilterService(
    params
  )

  await messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  const response = (data ?? [])?.map((item) => serialize(item))

  setList(response)
}

const linkClassInDiscilple = async ({
  setSnack,
  register,
  classDisciplineOriginId,
  classDisciplineNewId,
  callbackSuccess
}) => {
  const { error, status } = await linkClassInDiscilpleService({
    register,
    payload: {
      classDisciplineOriginId,
      classDisciplineNewId
    }
  })

  await messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Vínculo realizado com sucesso.'
  })

  if (!error) {
    setTimeout(callbackSuccess, 1000)
  }
}

export { getListDisciplinesByFilter, linkClassInDiscilple }
