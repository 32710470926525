import { ROUTE } from 'services/constants'

const breadCrumbsItems = [
  {
    path: '',
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: 'Professores'
  },
  {
    path: ROUTE.ADMIN_HR_CREATE_DOCUMENT_FILES,
    label: '<b>Arquivos de Documentos</b>'
  }
]

const initialState = {
  document_type_id: null,
  name: '',
  done_at: '',
  file: ''
}

const MESSAGE = {
  SUCCESS: 'Documento criado com sucesso!',
  DELETE_SUCCESS: 'Documento deletado com sucesso!',
  NOT_FOUND_SEARCH:
    'Nenhum documento encontrado, adicione um novo pelo<br /> botão acima.'
}

export { breadCrumbsItems, MESSAGE, initialState }
