import styled from 'styled-components'
import { Form as FomikForm } from 'formik'
import { Chip as MuiChip, Grid as MuiGrid } from '@mui/material'

const Form = styled(FomikForm)`
  margin: 23px 0;
`

const Chip = styled(MuiChip)`
  &.MuiChip-root {
    font-weight: bold;
  }
`

const Grid = styled(MuiGrid)`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`

export { Form, Chip, Grid }
