import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { borderLayoutDefault, paddingLayoutDefault, noBorderOnTop } = utilsPDF

export function buildStudentInfo({ student }) {
  const isForeigner = student.nationality?.toLowerCase() !== 'brasileiro(a)'

  return {
    table: {
      body: [
        [
          {
            text: [{ text: 'Nome  ', bold: true }, { text: student.name }],
            border: noBorderOnTop,
            colSpan: 4
          },
          {},
          {},
          {}
        ],
        [
          {
            stack: [
              {
                text: [
                  { text: isForeigner ? 'CRNM  ' : 'RG  ', bold: true },
                  { text: isForeigner ? student.crnm : student.rg }
                ],
                margin: [0, 0, 0, 5]
              },
              {
                text: [
                  { text: 'Orgão Exp.:  ', bold: true },
                  { text: student.rgDispatcher }
                ]
              }
            ]
          },
          {
            stack: [
              {
                text: [
                  { text: 'Nacionalidade  ', bold: true },
                  { text: student.nationality?.toLowerCase() }
                ],
                margin: [0, 0, 0, 5]
              },
              {
                text: [
                  { text: 'Naturalidade  ', bold: true },
                  {
                    text: `${student.placeOfBirth} - ${student.placeOfBirthCity}`
                  }
                ]
              }
            ]
          },
          {
            stack: [
              {
                text: [{ text: 'CPF  ', bold: true }, { text: student.cpf }],
                margin: [0, 0, 0, 5]
              },
              {
                text: [
                  { text: 'Data de nascimento  ', bold: true },
                  { text: student.birthDate }
                ]
              }
            ]
          },
          {
            stack: [
              {
                text: [
                  { text: 'R.M  ', bold: true },
                  { text: student.register }
                ],
                margin: [0, 0, 0, 5]
              },
              {
                text: [
                  { text: 'R.A  ', bold: true },
                  { text: student.studentRegistration }
                ]
              }
            ]
          }
        ]
      ],
      widths: '*'
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}
