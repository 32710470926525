import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import PDF from 'services/helpers/PDF'
import { Button } from 'components/atoms'
import { ResultReportPDF } from 'components/templates/PDF/ResultReport'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import FrequencyDiaryPDF from 'components/templates/PDF/FrequencyDiary'
import { frequencyDiaryDataMock } from 'components/templates/PDF/FrequencyDiary/mock'
import noteDiaryNovotecPDF from 'components/templates/PDF/NoteDiary/Novotec'
import { noteDiaryDataMock } from 'components/templates/PDF/NoteDiary/Novotec/mock'
import PartialReportNovotecPDF from 'components/templates/PDF/PartialReport'
import { partialReportDataMock } from 'components/templates/PDF/PartialReport/mock'
import SchoolReportPDF from 'components/templates/PDF/SchoolReport/General'
import { getSchoolReportToPDF } from 'services/api/admin'
import { noteDiaryGeneralPDF } from 'components/templates/PDF/NoteDiary/General'
import { noteDiaryGeneralMock } from 'components/templates/PDF/NoteDiary/General/mock'
import { finalReportMock } from 'components/templates/PDF/ResultReport/mock'
import AcademicRecordPDFtypeSP from 'components/templates/PDF/academicRecord/SP'
import { academicRecordDataTypeSPMock } from 'components/templates/PDF/academicRecord/SP/mock'
import { AcademicRecordPDFtypeMG } from 'components/templates/PDF/academicRecord/MG'
import { academicRecordDataTypeMGMock } from 'components/templates/PDF/academicRecord/MG/mock'

export default function POCPdf() {
  const [loading, setLoading] = useState(false)
  const pdf = PDF()

  const openFinalReportPDF = async () => {
    const docDefinitions = await ResultReportPDF(finalReportMock)
    setLoading(true)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  const openFrequencyDiaryPDF = async () => {
    const docDefinitions = await FrequencyDiaryPDF(frequencyDiaryDataMock)
    setLoading(true)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  const openNoteDiaryNovotecPDF = async () => {
    const docDefinitions = await noteDiaryNovotecPDF(noteDiaryDataMock)
    setLoading(true)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }
  const openNoteDiaryGeneralPDF = async () => {
    const docDefinitions = await noteDiaryGeneralPDF(noteDiaryGeneralMock)
    setLoading(true)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  const openPartialReportNovotecPDF = async () => {
    const docDefinitions = await PartialReportNovotecPDF(partialReportDataMock)
    setLoading(true)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  const openSchoolReportPDF = async () => {
    setLoading(true)
    const { data } = await getSchoolReportToPDF({ classId: 66085 })
    const docDefinitions = await SchoolReportPDF(data)
    // const docDefinitions = await SchoolReportPDF(schoolReportDataMock)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  const openAcademicRecordPDFtypeSP = async () => {
    setLoading(true)
    const docDefinitions = await AcademicRecordPDFtypeSP({
      academicRecordData: academicRecordDataTypeSPMock,
      withSignature: true
    })

    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  const openAcademicRecordPDFtypeMG = async () => {
    setLoading(true)
    const docDefinitions = await AcademicRecordPDFtypeMG({
      academicRecordData: academicRecordDataTypeMGMock,
      withSignature: true
    })

    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  useEffect(() => {
    openAcademicRecordPDFtypeMG()
  }, [])

  return (
    <TemplateDefaultFormAndResult titleHeader="PDF POC" loadingOpen={loading}>
      <h1>POC pdf</h1>
      <Button onclick={openAcademicRecordPDFtypeMG}>
        Histório escolar MG mockado
      </Button>
      <br />
      <Button onclick={openAcademicRecordPDFtypeSP}>
        Histório escolar SP mockado
      </Button>
      <br />
      <Button onclick={openSchoolReportPDF}>Boletim escolar mockado</Button>
      <br />
      <Button onclick={openPartialReportNovotecPDF}>
        Ata parcial novotec mockado
      </Button>
      <br />
      <Button onclick={openNoteDiaryNovotecPDF}>
        Diário de notas novotec mockado
      </Button>
      <br />
      <Button onclick={openNoteDiaryGeneralPDF}>
        Diário de notas GERAL mockado
      </Button>
      <br />
      <Button onclick={openFrequencyDiaryPDF}>
        Diário de frequência mockado
      </Button>
      <br />
      <Button onclick={openFinalReportPDF}>resultado final mockado</Button>
    </TemplateDefaultFormAndResult>
  )
}
