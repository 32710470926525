import theme from 'theme/designTokens'

const color = {
  approved: theme.palette.success.main,
  disapproved: theme.palette.textColors.error,
  in_progress: theme.palette.primary.main,
  next: theme.palette.textColors.secondary.darkBlue,
}

export const getStatusColor = (sent) => color[sent]
