import styled from 'styled-components'
import { TableCell as MuiTableCell } from '@mui/material'
import theme from 'theme/designTokens'

const TableSentAt = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 15%;
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 16px;
    width: 30%;
    text-decoration: underline;
    color: ${() => theme.palette.primary.main} !important;
    cursor: pointer;
    & > div {
      width: 16rem;
    }
  }
`

export { TableCellTitle, TableSentAt }
