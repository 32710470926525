/* eslint-disable camelcase */
import {
  deleteSchoolClass,
  downloadOccurrences,
  getGeneralReport,
  getLevels,
  getModules,
  getOptionsCourses,
  getShifts,
  getTrailsReport,
  sendEmail
} from 'services/api/admin'
import {
  convertToParamsArrayString,
  exportSpreadsheetByXLSX,
  exportXLSX,
  formatDate,
  formatDateEnv,
  getDataFormKeys,
  makeURLAccessLegacy,
  toastNotificationSuccess,
  updateErrorMessage
} from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { SUCCESS } from 'services/constants'
import { getUnitsRegions } from 'services/api/shared'
import {
  makeURLDash,
  parseResponse,
  processData,
  processDataValuesId,
  typeEndpointPole
} from './helpers'
import { MESSAGE, messageSuccessDownload, payloadDelete } from './constants'

const downloadOccurence = async ({
  setSnack,
  values,
  selected,
  handleClose,
  setSelected
}) => {
  setSnack('')

  const params = {
    occurrence_category: values.category,
    start_date: formatDateEnv(values.startDate),
    end_date: formatDateEnv(values.endDate)
  }

  const schoolClassIds = selected
    .map((item) => `school_class_ids[]=${Number(item)}`)
    .join('&')

  const occurrenceIds = values.type
    ? values.type
        .map((item) => `occurrence_types_ids[]=${Number(item.value)}`)
        .join('&')
    : ''

  const { data, error, status } = await downloadOccurrences({
    params,
    ...(occurrenceIds && { occurrenceIds }),
    schoolClassIds
  })

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  exportXLSX(data, 'relatorio-ocorrencias')
  setSelected([])
  handleClose()

  return toastNotificationSuccess(messageSuccessDownload)
}

const handleShifts = async ({ setSnack, setShifts }) => {
  setSnack('')

  const { data, error, status } = await getShifts()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  return setShifts(processData(data?.data))
}

const handleLevels = async ({ setSnack, setLevels }) => {
  setSnack('')

  const { data, error, status } = await getLevels()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  return setLevels(processData(data?.data))
}

const handleModules = async ({ setSnack, setModules }) => {
  setSnack('')

  const { data, error, status } = await getModules()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  return setModules(processDataValuesId(data?.data))
}

const handleCourses = async ({ unitys, setSnack, setCourses, level, unit }) => {
  setSnack('')
  const { label } = unitys.find(({ id }) => id === unit)

  const params = {
    level_name: level,
    unit_name: label
  }

  const { data, error, status } = await getOptionsCourses(params)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  return setCourses(processData(data?.data))
}

const deleteSubmitClass = async ({
  setSnack,
  setLoading,
  selected: selectedIds,
  setSelected,
  onSubmit,
  setOpenModalConfirm,
  formValues
}) => {
  setSnack('')
  setLoading(true)

  const { error, status } = await deleteSchoolClass(
    payloadDelete({ selectedIds })
  )
  setLoading(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDeleteClass
    })
  }

  setSelected([])
  setSnack(MESSAGE.SUCCESS_DELETE_CLASS, SUCCESS)
  setOpenModalConfirm(false)

  return setTimeout(() => onSubmit(formValues), 1500)
}

const searchReports = async ({
  values,
  setSnack,
  selected,
  setLoading,
  setSelected,
  isReportTrilhas,
  setOpenModalReport
}) => {
  setSnack('')
  setLoading(true)

  const params = {
    begin_at: formatDateEnv(values.startDate),
    end_at: formatDateEnv(values.endDate)
  }

  const paramsToReport = [
    selected,
    params,
    convertToParamsArrayString(selected, 'school_class_ids')
  ]
  const { data, error, status } = isReportTrilhas
    ? await getTrailsReport(...paramsToReport)
    : await getGeneralReport(...paramsToReport)

  setSelected([])
  setLoading(false)
  setOpenModalReport(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToExportGeneralReport
    })
  }

  if (isReportTrilhas) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
    })
    const url = window.URL.createObjectURL(blob)

    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = `relatorio-trilhas-${values.startDate}-${values?.endDate}.xlsx`
    downloadLink.click()

    URL.revokeObjectURL(url)
    downloadLink.remove()
  } else {
    window.open(
      `${data?.url}?id_turma=${selected}&inicioNovotec=${formatDate(
        params.begin_at
      )}&fimNovotec=${formatDate(params.end_at)}`
    )
  }

  return setSnack(MESSAGE.SUCCESS_DOWNLOAD_REPORT, SUCCESS)
}

const sendEmails = async ({
  values,
  setSnack,
  setLoading,
  selected,
  setSelected,
  handleClose
}) => {
  setSnack('')
  setLoading(true)

  const params = {
    student: values.students,
    financial_responsible: values.financialResponsible,
    father: values.father,
    mother: values.mother,
    other: values.otherResponsible
  }

  const payload = {
    subject: values.subject,
    content: values.content
  }

  const { error, status } = await sendEmail(
    payload,
    params,
    convertToParamsArrayString(selected, 'school_class_ids')
  )

  setSelected([])
  setLoading(false)
  handleClose()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToSendEmail
    })
  }

  return setSnack(MESSAGE.SUCCESS_SEND_EMAIL, SUCCESS)
}

const redirectDashboard = async ({
  setSnack,
  setLoading,
  formValues,
  unitys
}) => {
  setSnack('')
  setLoading(true)

  const res = await makeURLAccessLegacy(
    `acesso/turma_dash.php?${makeURLDash({ unitys, formValues })}`
  )
  setLoading(false)

  return window.open(res)
}

const onUnitsRegions = async ({ setSnack, setListPole, unitIdSelected }) => {
  const isName = true

  const {
    data: response,
    error,
    status
  } = await getUnitsRegions(typeEndpointPole(unitIdSelected))

  const dataPole = getDataFormKeys([0, 'units'], response?.data)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToRedirectDashboard
    })
  }

  return setListPole(parseResponse(dataPole, isName))
}

export {
  deleteSubmitClass,
  onUnitsRegions,
  redirectDashboard,
  sendEmails,
  handleLevels,
  searchReports,
  handleShifts,
  handleCourses,
  handleModules,
  downloadOccurence
}
