import { ROUTE } from 'services/constants'
import {
  getCurrencyStringMaskedOrOriginalValue,
  getMoneyFormattedToBRL,
  updateErrorMessage
} from 'services/helpers'
import { amount, noDefinedValue, zeroMoney } from './constants'

const getParamsCreateByUpdate = (values) => {
  const additionValue = values?.addition
  const everyValue = values?.every
  const groupValue = values?.group

  return {
    course_id: values?.course,
    shift: values?.shift,
    website_registration: values?.websiteRegistration,
    module: values?.module || noDefinedValue,
    duration_months: Number(values?.duration),
    ...(groupValue && {
      group: groupValue === 'Grupo Automático' ? '**' : groupValue
    }),
    contract_model: values?.contract,
    is_ead: values?.checkedEAD,
    max_installment: getCurrencyStringMaskedOrOriginalValue(
      values?.installments
    ),
    installment_value: getCurrencyStringMaskedOrOriginalValue(
      values?.valueInstallments
    ),
    registration_tax:
      getCurrencyStringMaskedOrOriginalValue(values?.registration) ||
      noDefinedValue,
    cash_discount:
      getCurrencyStringMaskedOrOriginalValue(values?.cashDiscount) ||
      noDefinedValue,
    cash_discount_type: values?.checkedReal === false ? 'percentage' : 'amount',
    bank_account_ref: values?.account,
    readjustment_installments_value:
      additionValue === '' || additionValue === null
        ? Number(noDefinedValue)
        : getCurrencyStringMaskedOrOriginalValue(additionValue),
    readjustment_initial_installment:
      everyValue === '' || everyValue === null
        ? Number(noDefinedValue)
        : getCurrencyStringMaskedOrOriginalValue(everyValue),
    type_of_payment: values.typeOfPayment
  }
}

const mergeInitialValues = (values) => {
  const cashDiscount = values?.cash_discount
  const cashDiscountAmount =
    cashDiscount === noDefinedValue ? zeroMoney : cashDiscount
  const cashDiscountPercentege =
    cashDiscount === noDefinedValue ? String(noDefinedValue) : cashDiscount
  const cashDiscountTypeValue =
    values?.cash_discount_type === amount
      ? cashDiscountAmount
      : cashDiscountPercentege

  return {
    unity: values?.unit_id,
    unityName: values?.unit,
    course: values?.course_id,
    websiteRegistration: values?.website_registration,
    level: values?.level,
    shift: values?.shift,
    module: values?.module,
    workload: values?.workload,
    duration: Number(values?.duration_months) || values?.duration_months,
    group: values?.group === '**' ? 'Grupo Automático' : values?.group,
    installments: values?.installment_quantity,
    valueInstallments: getMoneyFormattedToBRL(values?.installment_amount),
    registration: getMoneyFormattedToBRL(values?.registration_tax),
    cashDiscount: cashDiscountTypeValue,
    account: values?.bank_account_ref,
    contract: values?.contract_model,
    every: values?.readjustment_initial_installment || null,
    addition: getMoneyFormattedToBRL(values?.readjustment_installments_value),
    checkedEAD: values?.modality !== 'Presencial',
    checkedReal: values?.cash_discount_type === amount,
    typeOfPayment: values?.type_of_payment
  }
}

const updateSnask = ({
  error,
  status,
  updatePayment,
  savedPayment,
  feedbackMessage,
  navigate
}) => {
  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage
    })
  }

  return navigate(ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN, {
    state: { savedPayment, updatePayment, savedSearch: true }
  })
}

export { getParamsCreateByUpdate, mergeInitialValues, updateSnask }
