import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'

const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;

    & button {
      margin-left: 20px;
    }
  }
`

const Switch = styled.div`
  margin-top: 4px;
  max-width: 125px;
`

export { Grid, Switch }
