import { formatDateEnv } from 'services/helpers'

const getParams = (register, values) => ({
  register,
  occurrence_type_id: values?.type,
  occurrence_date: formatDateEnv(values?.dueDate),
  course_id: values?.course,
  ...(values?.schoolClass && { school_class_id: values.schoolClass }),
  description: values?.observation
})

export { getParams }
