import { useEffect } from 'react'
import { Tab, Tabs } from '@mui/material'
import TabContents from 'components/organisms/Tabs/TabContents'
import theme from 'theme/designTokens'
import { optionsTabs } from './tabs'
import * as Styled from './style'
import { useTabActive } from '../ClassLinked/Context/TabContext'

const TabsPersonalDataByAcademicEducation = ({
  isEdit,
  setSnack,
  notSearch,
  idProfessor,
  tabSelected,
  setIsUpdate,
  isFinalized,
  setIsFinalized,
  setLoadingOpen,
  setTabSelected,
  formPesonalData,
  formAcademicEducation,
  setOptionsCity,
  setOptionsState,
  setOptionsRegional,
  optionsState,
  optionsCity,
  optionsRegional,
  optionsCityState,
  listReplacement,
  listClassLinked,
  setListClassLinked,
  setListReplacement,
  setOptionsCityState,
  notPrefessorCreated,
  isFinalizedClassLinked,
  setFormAcademicEducation,
  setIsFinalizedClassLinked,
  setNotPrefessorCreated,
  listAcademicEducation,
  setListAcademicEducation,
  setFormPesonalData,
  setOpenModalConfirmCancel,
  formTimekeeping,
  setFormTimekeeping,
  listTimekeeping,
  setListTimekeeping
}) => {
  const { tabActive, setTabActive } = useTabActive()
  const tabs = optionsTabs({
    isEdit,
    setSnack,
    notSearch,
    idProfessor,
    isFinalized,
    setIsFinalized,
    setLoadingOpen,
    listReplacement,
    formPesonalData,
    isFinalizedClassLinked,
    formAcademicEducation,
    setOptionsCity,
    setOptionsState,
    setOptionsRegional,
    optionsState,
    optionsCity,
    setIsUpdate,
    optionsRegional,
    setIsFinalizedClassLinked,
    setFormAcademicEducation,
    optionsCityState,
    setOptionsCityState,
    notPrefessorCreated,
    setNotPrefessorCreated,
    listAcademicEducation,
    setListAcademicEducation,
    listClassLinked,
    setFormPesonalData,
    setListClassLinked,
    setListReplacement,
    setOpenModalConfirmCancel,
    formTimekeeping,
    setFormTimekeeping,
    listTimekeeping,
    setListTimekeeping
  })

  const handleTabChange = (event, newValue) => {
    setTabActive(newValue)
    setTabSelected(newValue)
  }

  useEffect(() => setTabSelected(tabActive), [])

  return (
    <>
      <Styled.Box $borderBottom={1} $borderColor={theme.palette.gray.light}>
        <Tabs value={tabSelected} onChange={handleTabChange}>
          {tabs.map(({ label, disabled }) => (
            <Tab key={label} label={label} disabled={disabled} />
          ))}
        </Tabs>
      </Styled.Box>

      {tabs?.map((content, index) => {
        const contentKey = `tab-content-${index}`
        return (
          <Styled.WrapperContent key={contentKey}>
            <TabContents index={index} key={contentKey} value={tabSelected}>
              {content.component()}
            </TabContents>
          </Styled.WrapperContent>
        )
      })}
    </>
  )
}

export { TabsPersonalDataByAcademicEducation }
