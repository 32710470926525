import { StepFour, StepOne, StepThree, StepTwo } from './components'

const getSteps = ({ finishTutorial }) => [
  {
    content: <StepOne />
  },
  {
    content: <StepTwo />
  },
  {
    content: <StepThree />
  },
  {
    content: <StepFour finishTutorial={finishTutorial} />
  }
]

const questions = [
  {
    title: 'Temos vídeos tutorias',
    describe:
      'Você pode assistí-los e entender como funciona cada fluxo do nosso ERP!',
    srcImage: '/images/doubt_1.svg'
  },
  {
    title: 'Acesse a Academia Proz',
    describe:
      'Clique no botão ao lado e use seu login e senha da Joy para assistir os tutoriais.',
    srcImage: '/images/doubt_2.svg'
  }
]

export { getSteps, questions }
