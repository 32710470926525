import styled from 'styled-components'

import { Place } from '@mui/icons-material'
import { TableCell as MuiTableCell, Tooltip as MuiTooltip } from '@mui/material'

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: ${({ width }) => width};
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    width: 50%;
    padding: 6px;
    height: 40px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        padding: 0 8px;
      }
    }
  }
`

const Link = styled.a``

const PlaceIcon = styled(Place)`
  &.MuiSvgIcon-root {
    color: #593493;
  }
  margin-left: 5px;
`

const Tooltip = styled(MuiTooltip)`
  &.MuiToolbar-root {
    padding: 0;
    align-items: center;
  }
`

export { TableCell, TableCellTitle, Link, Tooltip, PlaceIcon }
