/* eslint-disable consistent-return */
import { getLoadLog } from 'services/api/admin/system/LogTypes'
import { getParams } from './helpers'

const { default: FEEDBACK_SNACK } = require('feedBackSnack')
const { SUCCESS } = require('services/constants')
const { updateErrorMessage } = require('services/helpers')
const { MESSAGE } = require('./constants')

const loadLog = async ({
  payload,
  message = MESSAGE.SUCCESS_UPDATE,
  setSnack,
  setLoadingOpen,
  setNotFoundSearct,
  limit,
  pageActual = 1,
  setLogs,
  setFormValues,
  setCount
}) => {
  setLoadingOpen(true)
  setSnack('')
  setNotFoundSearct(false)

  setFormValues(payload)
  const params = getParams({ values: payload, filter: limit, pageActual })

  const { data, error, status } = await getLoadLog(params)
  setLoadingOpen(false)
  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage:
        FEEDBACK_SNACK.noAccessPermissionToInactiveOccurrenciesTypes
    })
  }
  setCount(data?.count)
  setSnack(message, SUCCESS)
  setLogs(data)
}

export { loadLog }
