const getAddressByCep = async (cep) => {
  try {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)

    const data = await response.json()

    if (response.ok && !data.erro) {
      return {
        logradouro: data.logradouro,
        bairro: data.bairro,
        cidade: data.localidade,
        uf: data.uf
      }
    }

    throw new Error('CEP não encontrado')
  } catch (error) {
    console.error(error)
    throw new Error('Ocorreu um erro ao buscar o endereço')
  }
}

export { getAddressByCep }
