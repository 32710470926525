import { TYPE_RESPONSIBLES } from '../../constants'

const KEY_COLUMNS = {
  COURSE: 'course',
  CONTRACT: 'contract',
  MODULE: 'module',
  UNIT: 'unit',
  RESPONSIBLE_FINANCIAL: 'responsible_financial'
}

const tableColumns = [
  { id: KEY_COLUMNS.COURSE, label: 'Curso' },
  { id: KEY_COLUMNS.CONTRACT, label: 'Contrato' },
  { id: KEY_COLUMNS.MODULE, label: 'Módulo' },
  { id: KEY_COLUMNS.UNIT, label: 'Unidade' },
  { id: KEY_COLUMNS.RESPONSIBLE_FINANCIAL, label: 'Responsável Financeiro' }
]

const fieldNameByType = {
  [TYPE_RESPONSIBLES.NATURAL_PERSON]: 'name',
  [TYPE_RESPONSIBLES.STUDENT]: 'name',
  [TYPE_RESPONSIBLES.LEGAL_PERSON]: 'razaoSocial'
}

export { tableColumns, KEY_COLUMNS, fieldNameByType }
