import { useEffect, useRef, useState } from 'react'
import { jsPDF as JSPDF } from 'jspdf'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  ButtonFooterV2,
  ModalConfirm,
  ModalContentPdf
} from 'components/molecules'
import { AccordionFilter, PageTitle } from 'components/atoms'
import { updateErrorMessage } from 'services/helpers'
import { getTeachingPlanById, updateTeachingPlanById } from 'services/api/admin'
import { ROUTE, SUCCESS } from 'services/constants'
import { MESSAGE, initialValues, modulesReactQuill } from './constants'
import SkeletonPdf from '../SkeletonPdf'
import * as Styled from './style'

const FormTeachingPlan = ({ setSnack }) => {
  const [expanded, setExpanded] = useState('ementa')
  const pdfRef = useRef()
  const [openModal, setOpenModal] = useState(false)
  const [listPlan, setListPlan] = useState({})
  const [loadingOpen, setLoading] = useState(false)
  const [modalContent, setModalContent] = useState(false)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id: idSubject } = params
  const urlParams = new URLSearchParams(window.location.search)
  const disciplineName = urlParams.get('discipline')

  const [editorContent, setEditorContent] = useState({})

  const handleAccordionFilter = ({ idAcc, title, values, e }) => {
    const newValue = {
      id: idAcc,
      title,
      content: e.target.value
    }

    const updatedAccordion = values.accordion.map((item) =>
      item.id === idAcc ? newValue : item
    )

    return updatedAccordion
  }

  const handleQuillChange = (value, id, setFieldValue) => {
    setEditorContent((prevState) => ({
      ...prevState,
      [id]: value
    }))

    const updatedAccordion = handleAccordionFilter({
      idAcc: id,
      title: '',
      values: { accordion: [] },
      e: { target: { value: encodeURIComponent(value) } }
    })
    setFieldValue(`accordion[${id}].content`, updatedAccordion)
  }

  const handleTeachingPlan = async () => {
    setSnack('')

    setLoading(true)
    const { data, error, status } = await getTeachingPlanById(Number(idSubject))
    setLoading(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListSubjects
      })
    }

    return setListPlan(data)
  }

  const onUpdateTeachingPlanById = async () => {
    setSnack('')
    setLoading(true)

    const { error, status } = await updateTeachingPlanById(
      editorContent,
      idSubject
    )

    setLoading(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToUpdateCupon
      })
    }

    setSnack(MESSAGE.updateSuccessfully, SUCCESS)

    return setTimeout(() => navigate(-1), 3000)
  }

  const handleShowPdf = async () => {
    const pdf = new JSPDF('portrait', 'pt', 'a4')
    try {
      const data = await pdfRef.current

      pdf.html(data).then(() => {
        pdf.save(`${disciplineName}`)
      })
      setModalContent(!modalContent)
    } catch (error) {
      console.error(error)
    }
  }

  const handleConfirme = () => {
    setOpenModal(false)
    navigate(ROUTE.ADMIN_MANAGEMENT_SUBJECTS, {
      state: { savedSearch: true }
    })
  }

  useEffect(() => {
    if (idSubject) handleTeachingPlan()
  }, [])

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onUpdateTeachingPlanById}
        enableReinitialize
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Grid
              justifyContent="center"
              alignItems="center"
              container
              spacing={{ xs: 0, sm: 2 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <PageTitle title="Plano de Ensino" />
              </Grid>
              <ModalContentPdf
                open={modalContent}
                maxWidth="md"
                handleClose={() => setModalContent(!modalContent)}
                content={
                  <>
                    <div ref={pdfRef}>
                      <SkeletonPdf editorContent={listPlan} />
                    </div>

                    <Grid item xs={12} sm={12} md={6} pb={3}>
                      <Styled.Box onClick={() => handleShowPdf()}>
                        <Styled.PictureAsPdf />
                      </Styled.Box>
                    </Grid>
                  </>
                }
              />
              <Grid item xs={12} sm={12} md={6}>
                <Styled.Box onClick={() => setModalContent(!modalContent)}>
                  <Styled.PictureAsPdf />
                </Styled.Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Styled.Typography>
                  Insira os dados referentes aos itens do planejamento da
                  disciplina abaixo:
                </Styled.Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                {values.accordion?.map(({ id: idAcc, title }) => (
                  <AccordionFilter
                    key={idAcc}
                    title={title}
                    margin={false}
                    nameAccordion={idAcc}
                    setExpanded={setExpanded}
                    expanded={expanded}
                    notMarginExpanded
                  >
                    <ReactQuill
                      value={editorContent[idAcc] || listPlan[idAcc] || ''}
                      onChange={(value) =>
                        handleQuillChange(value, idAcc, setFieldValue)
                      }
                      modules={modulesReactQuill}
                      formats={[
                        'header',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'list',
                        'bullet',
                        'align',
                        'color',
                        'background'
                      ]}
                      escapeHTML={false}
                    />
                  </AccordionFilter>
                ))}
              </Grid>

              <Styled.Box>
                <ButtonFooterV2
                  labelClose="Cancelar"
                  labelConfirm="Salvar"
                  onClickClose={() => setOpenModal(true)}
                  size="medium"
                />
              </Styled.Box>
            </Grid>
          </Form>
        )}
      </Formik>

      <ModalConfirm
        open={openModal}
        size="medium"
        handleClose={() => setOpenModal(false)}
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={() => setOpenModal(false)}
        onClickConfirm={() => handleConfirme()}
        colorButtonConfirm="error"
        icon={<Styled.IconWarningAmber />}
        title="Cancelar"
        message="Deseja cancelar a operação?
Você perderá as alterações que ainda não foram salvas."
      />
    </>
  )
}

export default FormTeachingPlan
