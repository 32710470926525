const BILLS_RECEIVE = {
  btn_financier_requests_worksheet_Titles:
    'financeiro_btn_solicita_planilhaTitulos',
  financial_btn_download_Simplified_spreadsheet:
    'financeiro_btn_baixar_planilhaSimplificada',
  financial_btn_download_Detailed_worksheet:
    'financeiro_btn_baixar_planilhaDetalhada',
  financial_alternative_switch_typeSearch:
    'financeiro_switch_alterna_tipoBusca',
  financial_check_filters_pj: 'financeiro_check_filtra_pj',
  financial_select_toggles_fieldSearch: 'financeiro_select_alterna_campoBusca',
  financial_switch_filters_defaulters: 'financeiro_switch_filtra_inadimplentes',
  financial_switch_filters_paid: 'financeiro_switch_filtra_pagos',
  financial_select_filters_unit: 'financeiro_select_filtra_unidade',
  financial_select_filters_course: 'financeiro_select_filtra_curso',
  financial_input_inform_title: 'financeiro_input_informa_titulo',
  financial_btn_clear_search: 'financeiro_btn_limpar_busca',
  financial_btn_apply_search: 'financeiro_btn_aplicar_busca',
  financier_switch_filters_status_title:
    'financeiro_switch_filtra_situacaoTitulo',
  financial_switch_filters_title_type: 'financeiro_switch_filtra_tipoTitulo',
  financial_switch_filters_inactivated_reason:
    'financeiro_switch_filtra_motivoInativado',
  financial_switch_filters_overdue_bonds:
    'financeiro_switch_filtra_titulosAtrasados',
  btn_financier_requests_create_new_title:
    'financeiro_btn_solicita_criarNovoTitulo',
  financial_btn_send_bill_batch: 'financeiro_btn_envia_boletoEmailLote',
  btn_finance_requests_advance_installments:
    'financeiro_btn_solicita_adiantarParcelas',
  financier_btn_requests_apply_scholarship:
    'financeiro_btn_solicita_aplicarBolsa',
  btn_financier_requests_to_apply_discount:
    'financeiro_btn_solicita_aplicarDesconto',
  btn_financier_requests_inactivate_title:
    'financeiro_btn_solicita_inativarTitulo',
  financial_btn_reactivate_title_lot: 'financeiro_btn_reativar_tituloLote',
  financial_btn_download_ticket: 'financeiro_btn_baixar_boleto',
  btn_financier_sends_email_slip: 'financial_btn_envia_boletoEmail',
  btn_financier_pay_online: 'financial_btn_pay_online',
  btn_financier_download_payment_voucher:
    'financial_btn_baixar_comprovante_pagamento',
  funder_link_opens_title: 'financeiro_link_abre_titulo',
  financial_btn_confirms_inative_title:
    'financeiro_btn_confirma_inativarTitulo',
  financial_btn_confirms_link_contract:
    'financeiro_btn_confirma_vinculo_contrato',
  financier_btn_confirms_to_apply_stock_exchange:
    'financeiro_btn_confirma_aplicarBolsa',
  financier_btn_confirms_reactivate_title:
    'financeiro_btn_confirma_reativarTitulo',
  btn_financier_confirms_apply_discount:
    'financeiro_btn_confirma_aplicarDesconto',
  financial_btn_confirms_advance_installments:
    'financeiro_btn_confirma_adiantarParcelas',
  financial_btn_download_nfs: 'financeiro_btn_baixar_nfs',
  financial_btn_download_nfe: 'financeiro_btn_baixar_nfe',
  financial_link_opens_title: 'financeiro_link_abre_titulo',
  financial_btn_reactivate_title: 'financeiro_btn_reativar_titulo',
  financial_tab_access_title_cost_center:
    'financeiro_tab_acessa_tituloCentroCusto',
  financial_link_opens_student_sheet: 'financeiro_link_abre_fichaAluno',
  financial_tab_access_main_title: 'financeiro_tab_acessa_tituloPrincipal',
  financial_link_opens_responsible_financial:
    'financeiro_link_abre_responsavelFinanceiro',
  financial_tab_accesses_discount_title: 'financeiro_tab_acessa_tituloDesconto',
  financial_select_toggles_title_payment_method:
    'financeiro_select_alterna_tituloFormaPagamento',
  finanncial_link_retorna_detailamento_financial:
    'financeiro_link_retorna_detalhamentoFinanceiro',
  financial_input_fills_title_amount_paid:
    'financeiro_input_preenche_tituloValorPago',
  financial_btn_saves_title_home: 'financeiro_btn_salva_tituloPrincipal',
  finance_btn_cancels_title: 'financeiro_btn_cancela_tituloPrincipal',
  btn_financier_adds_title_cost_center:
    'financeiro_btn_adiciona_tituloCentroCusto',
  finance_btn_excludes_title_cost_center:
    'financeiro_btn_exclui_tituloCentroCusto',
  financier_btn_saves_title_cost_center:
    'financeiro_btn_salva_tituloCentroCusto',
  financier_btn_cancels_title_cost_center:
    'financeiro_btn_cancela_tituloCentroCusto',
  btn_financier_adds_discount_title: 'financeiro_btn_adiciona_tituloDesconto',
  financial_btn_excludes_discount_title: 'financeiro_btn_exclui_tituloDesconto',
  financial_btn_saves_title_discount: 'financeiro_btn_salva_tituloDesconto',
  financier_btn_cancels_discount_title: 'financeiro_btn_cancela_tituloDesconto',
  financial_link_access_info_CNAB: 'financeiro_link_acessa_infoCNAB',
  financial_btn_clear_process_return: 'financeiro_btn_limpar_processoRetorno',
  financial_btn_confirms_process_return:
    'financeiro_btn_confirma_processarRetorno',
  financial_switch_filters_local_cashier: 'financeiro_switch_filtra_caixaLocal',
  financial_switch_filters_issued_notes:
    'financeiro_switch_filtra_notasEmitidas',
  financial_select_filters_title_type: 'financeiro_select_filtra_tipoTitulo',
  financial_input_filters_unit: 'financeiro_input_filtra_unidade',
  btn_financier_filters_active_securities:
    'financeiro_btn_filtra_titulosAtivos',
  btn_financier_exports_active_securities:
    'financeiro_btn_exporta_titulosAtivos',
  financial_btn_displays_title_table_details:
    'financeiro_btn_exibe_detalhesTituloTabela',
  btn_financier_requests_to_generate_notes:
    'financeiro_btn_solicita_gerarNotas',
  btn_financier_filters_pending_securities:
    'financeiro_btn_filtra_titulosPendentes',
  btn_financier_exports_securities_error: 'financeiro_btn_exporta_titulosErro',
  financial_btn_filters_titles_error: 'financeiro_btn_filtra_titulosErro',
  financial_input_fills_negotiationValueInput:
    'financeiro_input_preenche_negociacaoValorEntrada',
  financial_select_select_quantityInstallments:
    'financeiro_select_seleciona_quantidadeParcelas',
  financial_input_fill_obsNegotiation:
    'financeiro_input_preenche_obsNegociacao',
  financial_btn_cancels_new_negotiation:
    'financeiro_btn_cancela_novaNegociacao',
  btn_financial_confirms_new_Negotiation:
    'financeiro_btn_confirma_novaNegociacao',
  finan_btn_requests_replacementTitle:
    'financeiro_btn_solicita_substituicaoTitulo',
  finan_btn_link_contract: 'financeiro_btn_vinculo_contrato',
  finan_btn_confirm_replacementTitle:
    'financeiro_btn_confirma_substituicaoTitulo'
}

const INVOICES = {
  btn_financier_exports_securities_processing:
    'financeiro_btn_exporta_titulosProcessando',
  btn_financier_filters_securities_processing:
    'financeiro_btn_filtra_titulosProcessando',
  btn_financier_filters_issued_banknotes: 'financeiro_btn_filtra_notasEmitidas',
  btn_financier_exports_issued_notes: 'financeiro_btn_exporta_notasEmitidas',
  btn_financier_exports_lot_NFE: 'financeiro_btn_exporta_loteNFE',
  btn_financier_exports_batch_NFS: 'financeiro_btn_exporta_loteNFS',
  btn_financier_exports_report_notes: 'financeiro_btn_exporta_relatorioNotas',
  financial_btn_confirms_generate_notes: 'financeiro_btn_confirma_gerarNotas'
}

const GROUP = {
  financial_btn_saves_new_group: 'financeiro_btn_salva_novoGrupo',
  btn_financier_cancels_new_group: 'financeiro_btn_cancela_novoGrupo'
}

const COUPON = {
  btn_finance_starts_new_Coupon: 'financeiro_btn_inicia_novoCupom',
  financial_btn_open_coupon: 'financeiro_btn_abre_cupom',
  financial_btn_starts_excludeCoupon: 'financeiro_btn_inicia_excluiCupom ',
  btn_financial_confirm_delete_Coupon: 'financeiro_btn_confirma_excluiCupom  ',
  financial_btn_saves_coupon: 'financeiro_btn_salva_cupom '
}

const PAYMENT_PLAN = {
  financial_btn_starts_newPlan: 'financeiro_btn_inicia_novoPlano',
  financial_btn_starts_excludesBatchPlan:
    'financeiro_btn_inicia_excluiPlanoLote ',
  financial_btn_starts_changeFlatValue:
    'financeiro_btn_inicia_alteraValorPlano',
  financial_link_open_paymentPlan: 'financeiro_link_abre_planoPagamento',
  financial_btn_altera_valuesPlans: 'financeiro_btn_altera_valoresPlanos',
  financial_btn_confirm_excludePlan: 'financeiro_btn_confirma_excluiPlano',
  financial_btn_confirm_copyPlan: 'financeiro_btn_confirma_copiaPlano',
  financial_btn_salves_plan: 'financeiro_btn_salva_plano',
  financial_btn_starts_excludePlan: 'financeiro_btn_inicia_excluiPlano',
  financial_btn_starts_copyPlano: 'financeiro_btn_inicia_copiaPlano'
}

const CAMPAIGNS = {
  financeiro_btn_busca_Campanhas: 'financeiro_btn_busca_Campanhas',
  financeiro_btn_acessa_Campanha: 'financeiro_btn_acessa_Campanha',
  financeiro_btn_salvar_NovaCampanha: 'financeiro_btn_salvar_NovaCampanha',
  financeiro_btn_acessa_NovaCampanha: 'financeiro_btn_acessa_NovaCampanha',
  financeiro_btn_cancela_NovaCampanha: 'financeiro_btn_cancela_NovaCampanha',
  financeiro_btn_salvar_EditarCampanha: 'financeiro_btn_salvar_EditarCampanha',
  financeiro_btn_acessa_CampanhaInativa:
    'financeiro_btn_acessa_CampanhaInativa',
  financeiro_btn_cancela_EditarCampanha:
    'financeiro_btn_cancela_EditarCampanha',
  financeiro_btn_confirma_InativaCampanha:
    'financeiro_btn_confirma_InativaCampanha',
  financeiro_btn_confirma_cancelaCampanha:
    'financeiro_btn_confirma_cancelaCampanha'
}

export const FINANCIAL = {
  ...GROUP,
  ...COUPON,
  ...INVOICES,
  ...CAMPAIGNS,
  ...PAYMENT_PLAN,
  ...BILLS_RECEIVE
}
