import styled from 'styled-components'

const LinkTo = styled.div`
  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`


const ExternalLink = styled.a`
  cursor: pointer;
`

export { LinkTo, ExternalLink }
