import { useState } from 'react'
import { Unstable_Grid2 as Grid, Typography } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import { FORM_TYPE } from './config'
import { FormLogin, FormPassword } from './components'
import * as Styled from './style'

export default function Login() {
  const { password, login } = FORM_TYPE
  const [formType, setFormType] = useState(login)

  const handleFormType = (type) => {
    setTimeout(() => setFormType(type), 500)
  }

  const isEnvProd = process.env.REACT_APP_ENV === 'prod'

  const Form = (isProduction) =>
    formType === login ? (
      <FormLogin
        changeFormType={() => handleFormType(password)}
        isProduction={isProduction}
      />
    ) : (
      <FormPassword
        changeFormType={() => handleFormType(login)}
        isProduction={isProduction}
      />
    )

  return (
    <Styled.FormWrapper $showBackgroundImage={isEnvProd}>
      <Styled.Paper elevation={3}>
        <Styled.Logotype width="160" />
        {Form(isEnvProd)}
        {!isEnvProd && (
          <Grid container alignItems="center" mt={1}>
            <Grid xs={12} sm={3} display="flex" justifyContent="center">
              <WarningAmber
                sx={{ fontSize: '60px', color: (t) => t.palette.dark.medium }}
              />
            </Grid>
            <Grid xs={12} sm={9}>
              <Typography
                sx={{ color: (t) => t.palette.dark.medium }}
                variant="body2"
              >
                Este é um ambiente de teste do sistema e não deverá ser usado
                para operações cotidianas. Caso tenha dúvidas se você acessou o
                ambiente que deveria, contate o gestor da sua área.
              </Typography>
            </Grid>
          </Grid>
        )}
      </Styled.Paper>
    </Styled.FormWrapper>
  )
}
