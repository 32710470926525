import moment from 'moment'
import { TextField } from 'components/molecules'
import { Button } from 'components/atoms'
import {
  codeLengthCorrect,
  getMessageWarning,
  messageErrorLengthCode
} from './constants'

import * as Styled from './style'

const Content = ({
  email,
  filepath,
  cellphone,
  expireDays,
  handleResendCode,
  handleSkipAssignature,
  handleConfirmAssignature,
  code,
  setCode,
  skipContractDays,
  skippedClickedAt
}) => {
  const hasTyped = !!code?.length
  const isAvailableToConfirmAssignature = code?.length === codeLengthCorrect

  const isSkipButtonVisible = () => {
    if (!skippedClickedAt) {
      return true
    }

    const clickedDate = moment(skippedClickedAt)
    const currentDate = moment()
    const differenceInDays = currentDate.diff(clickedDate, 'days')

    return differenceInDays < skipContractDays
  }

  return (
    <Styled.Container>
      <embed
        src={filepath}
        width="100%"
        height="257px"
        type="application/pdf"
      />

      <Styled.ContainerWarning>
        <Styled.Warning />
        <Styled.TextWarning>
          {getMessageWarning({ email, cellphone, expireDays })}
        </Styled.TextWarning>
      </Styled.ContainerWarning>

      <Styled.CodeWrapper>
        <TextField
          fullWidth
          label="Digite o código de aceite aqui"
          value={code}
          onChange={(event) => setCode(event.target.value)}
          error={!isAvailableToConfirmAssignature && hasTyped}
          helperText={
            !isAvailableToConfirmAssignature &&
            hasTyped &&
            messageErrorLengthCode
          }
        />
        <Styled.FakeLink onClick={handleResendCode}>
          Não recebeu o código? Clique aqui
        </Styled.FakeLink>
      </Styled.CodeWrapper>

      <Button
        onClick={handleConfirmAssignature}
        disabled={!isAvailableToConfirmAssignature}
      >
        Confirmar assinatura de contrato
      </Button>

      {isSkipButtonVisible() && (
        <Styled.FakeLink onClick={handleSkipAssignature}>
          PULAR ASSINATURA
        </Styled.FakeLink>
      )}
    </Styled.Container>
  )
}

export default Content
