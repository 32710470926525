import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ListAlt as MuiListAlt,
  AddCircle as MuiAddCircle
} from '@mui/icons-material'
import { useSnack } from 'services/hooks'
import { Button } from 'components/atoms'
import { ROUTE } from 'services/constants'
import { ModalConfirm } from 'components/molecules'
import { TemplateDefaultHeaderByContent as Template } from 'components/templates/Admin'

import { excludeSchoolClassGrouping, getGroupedDisciplines } from './service'
import { Actions, List } from './components'
import { breadcrumbsItems, textModalConfirm } from './constants'

import * as Styled from './style'

const Manager = () => {
  const [loading, setLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [disciplines, setDisciplines] = useState([])
  const [modal, setModal] = useState({ idItem: null, open: false })

  const location = useLocation()
  const snackProps = useSnack()
  const navigate = useNavigate()

  const { groupingDisciplines, grouping, groupingId } = location.state
  const { setSnack } = snackProps

  useEffect(() => {
    if (!isUpdated) {
      getGroupedDisciplines({
        setSnack,
        setDisciplines,
        setLoading,
        groupingDisciplines,
        callbackSuccess: () => {
          setIsUpdated(true)
        }
      })
    }
  }, [isUpdated])

  useEffect(() => {
    setIsUpdated(false)
  }, [location.state.groupingDisciplines.length])

  const toggleModal = () => {
    setModal({ ...modal, open: !modal.open })
  }

  const handleIncludeDiscipline = () => {
    navigate(`${ROUTE.ADMIN_SECRETARY_GROUPING_INCLUDE_DISCIPLINE}`, {
      state: {
        grouping,
        groupingId,
        groupingDisciplines
      }
    })
    setIsUpdated(false)
  }

  const handleExcludeSchoolClassGrouping = (idItem) => {
    excludeSchoolClassGrouping({
      groupingId,
      disciplineId: idItem,
      setSnack,
      callbackSuccess: () => {
        toggleModal()
        navigate(`${ROUTE.ADMIN_SECRETARY_GROUPING_MANAGER}`, {
          state: {
            ...location.state,
            groupingDisciplines: groupingDisciplines.filter(
              (id) => id !== idItem
            )
          }
        })
      }
    })
  }

  const hasResult = !!disciplines?.length

  return (
    <Template
      loadingOpen={loading}
      linkHeader={ROUTE.ADMIN_SECRETARY_GROUPING}
      listItems={disciplines}
      snackProps={snackProps}
      classNameHeader="goBack"
      breadcrumbsItems={breadcrumbsItems({ grouping, groupingId })}
      content={
        <>
          {hasResult ? (
            <>
              <Actions handleIncludeDiscipline={handleIncludeDiscipline} />

              <List
                items={disciplines}
                handleExcludeGroupedDiscipline={(item) => {
                  setModal({ idItem: item?.id, open: true })
                }}
              />
            </>
          ) : (
            <Styled.EmptyState>
              <Styled.EmptyStateMessageContainer>
                <MuiListAlt />

                <Styled.EmptyStateMessage>
                  Não encontramos nenhuma turma/disciplina neste agrupamento.
                </Styled.EmptyStateMessage>
              </Styled.EmptyStateMessageContainer>

              <Button
                startIcon={<MuiAddCircle />}
                onClick={handleIncludeDiscipline}
              >
                Incluir disciplina
              </Button>
            </Styled.EmptyState>
          )}

          {!!modal?.idItem && (
            <ModalConfirm
              open={modal.open}
              handleClose={toggleModal}
              title={textModalConfirm.title}
              onClickNotConfirm={toggleModal}
              message={textModalConfirm.message}
              textButtonConfirm={textModalConfirm.buttonConfirm}
              textButtonNotConfirm={textModalConfirm.buttonNotConfirm}
              onClickConfirm={() =>
                handleExcludeSchoolClassGrouping(modal.idItem)
              }
            />
          )}
        </>
      }
    />
  )
}

export default Manager
