import styled from 'styled-components'
import { AddBox as MuiAddIcon } from '@mui/icons-material'

const Title = styled.h3`
  font-size: 32px;
  font-weight: 500;
  margin: ${({ $isMargin }) => ($isMargin ? '0' : '0 0 16px 0')};
`

const Card = styled.div`
  padding: 24px;
`

const CardFooter = styled.div`
  margin-top: 24px;
  text-align: end;

  button {
    margin: 0;
  }
`

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

const BoxIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const AddIcon = styled(MuiAddIcon)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
    font-size: 30px;
  }
  :hover {
    cursor: pointer;
  }
`

export { Card, Title, CardFooter, Box, AddIcon, BoxIcon }
