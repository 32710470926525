import { Grid } from '@mui/material'
import { TextField, ButtonFooterV2 } from 'components/molecules'
import { useState } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { handleOrder, sumStudentsActives } from '../../helpers'
import * as Styled from './style'

const ModalStudentByPole = ({
  open,
  values,
  listStudent,
  onClickConfirm,
  textButtonConfirm,
  colorButtonConfirm = 'error',
  ...props
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [orderBy, setOrderBy] = useState({
    type: 'alphabetic',
    isAlphabetic: true,
    isDescending: false
  })

  const isAlphabetic = orderBy.type === 'alphabetic'

  const isTop = isAlphabetic ? orderBy.isAlphabetic : orderBy.isDescending

  const filteredList = listStudent.filter((item) =>
    item?.pole_name.toUpperCase().includes(searchValue.toUpperCase())
  )

  return (
    <Styled.Modal open={open}>
      <Styled.Grid>
        <Grid container spacing={{ xs: 0, sm: 2 }}>
          <Grid item xs={12}>
            <Styled.Title variant="h5" component="h2">
              Alunos/Polo
            </Styled.Title>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            fullWidth
            id="pole"
            value={values?.pole}
            label="Buscar polo"
            onChange={(e) => setSearchValue(e.target.value)}
            isMargin="16px 0"
          />
        </Grid>
        <Styled.List>
          <Styled.ListItem>
            <Styled.Typography
              fontSize={16}
              variant="body2"
              $isTop={isTop}
              onClick={() =>
                setOrderBy({
                  ...orderBy,
                  type: 'alphabetic',
                  isAlphabetic: !orderBy.isAlphabetic
                })
              }
            >
              Polos ({listStudent.length}) <ArrowDownwardIcon />
            </Styled.Typography>
            <Styled.Typography
              fontSize={16}
              variant="body2"
              $isTop={isTop}
              onClick={() =>
                setOrderBy({
                  ...orderBy,
                  type: 'numeric',
                  isDescending: !orderBy.isDescending
                })
              }
            >
              Alunos ({sumStudentsActives(listStudent)}) <ArrowDownwardIcon />
            </Styled.Typography>
          </Styled.ListItem>
          {filteredList
            .sort((a, b) => handleOrder(a, b, orderBy))
            .map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Styled.ListItem key={index}>
                <Styled.ListItemText $isTitle primary={item?.pole_name} />
                <Styled.ListItemText $small primary={item?.students_actives} />
              </Styled.ListItem>
            ))}
        </Styled.List>
        <Grid item xs={12} sm={12} md={12} margin="15px 0px 0">
          <ButtonFooterV2
            labelConfirm={textButtonConfirm}
            onClickConfirm={onClickConfirm}
            colorConfirm={colorButtonConfirm}
            {...props}
          />
        </Grid>
      </Styled.Grid>
    </Styled.Modal>
  )
}

export default ModalStudentByPole
