const DEFAULT_ITEMS_PER_PAGE = 10
const DEFAULT_PAGE = 1

const defaultValues = {
  level: '',
  issuance: '',
  declaration: ''
}

const progressCoursersOptions = {
  yes: {
    label: 'Sim',
    value: true
  },
  no: {
    label: 'Não',
    value: false
  }
}

const makeServiceHookParams = {
  isLevel: true
}

export {
  progressCoursersOptions,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  defaultValues,
  makeServiceHookParams
}
