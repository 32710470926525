import { messageErroOrSuccess } from 'services/helpers'
import {
  getTeachersGroupingList as getTeachersGroupingListService,
  updateTeacherGrouping as updateTeacherGroupingService
} from 'services/api/admin'

export const getTeachersGroupingList = async ({
  setSnack,
  setListTeachers,
  name
}) => {
  const { error, status, data } = await getTeachersGroupingListService({ name })

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  const listFormatted = (data ?? []).map((item) => ({
    label: item?.name,
    value: item?.id
  }))

  setListTeachers(listFormatted)
}

export const updateTeacherGrouping = async ({
  setSnack,
  groupingId,
  teacherId,
  callbackSuccess
}) => {
  const { error, status } = await updateTeacherGroupingService({
    groupingId,
    teacherId: teacherId ?? null
  })

  messageErroOrSuccess({
    error,
    setSnack,
    status,
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Agrupamento editado com sucesso.'
  })

  if (!error) {
    setTimeout(callbackSuccess, 1000)
  }
}
