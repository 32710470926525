/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import { Divider, Grid } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { ButtonCallToAction } from 'components/molecules'
import * as Styled from './style'

const PageTitle = ({
  title,
  padding,
  divider,
  isButton,
  valueButton,
  linkCallToAction,
  onClickCallToAction
}) => (
  <>
    <Grid container spacing={{ xs: 0, sm: 2 }}>
      <Grid item xs={12} sm={12} md={isButton ? 6 : 12}>
        <Styled.Typography variant="h5" $padding={padding}>
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </Styled.Typography>
      </Grid>

      {isButton && (
        <Styled.Grid item xs={12} sm={12} md={6}>
          {linkCallToAction ? (
            <ButtonCallToAction
              labelCallToAction={valueButton}
              linkCallToAction={linkCallToAction}
              startIcon={<ArrowBack />}
            />
          ) : (
            <ButtonCallToAction
              labelCallToAction={valueButton}
              callToAction={onClickCallToAction}
              startIcon={<ArrowBack />}
            />
          )}
        </Styled.Grid>
      )}
    </Grid>

    {divider && <Divider />}
  </>
)
export default PageTitle

PageTitle.propTypes = {
  title: PropTypes.node.isRequired,
  divider: PropTypes.bool,
  padding: PropTypes.string,
  isButton: PropTypes.bool,
  valueButton: PropTypes.string,
  linkCallToAction: PropTypes.string,
  onClickCallToAction: PropTypes.func
}
