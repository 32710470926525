import { Outlet, useLocation } from 'react-router-dom'
import Container from '@mui/material/Container'
import Header from 'components/organisms/Header'
import { useTutorialContext } from 'components/pages/Shared/Welcome/Context/hooks'
import * as Styled from './style'

const TemplatePrivate = () => {
  const { pathname } = useLocation()

  return (
    <div style={{ display: 'flex' }}>
      <Styled.WrapperContainer>
        <Header />
        <Container style={{ maxWidth: pathname === '/admin' ? 'none' : 1200 }}>
          <Outlet />
        </Container>
      </Styled.WrapperContainer>
    </div>
  )
}

export default TemplatePrivate
