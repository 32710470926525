import { Grid } from '@mui/material'
import { Select } from 'components/molecules'
import * as Styled from './style'
import { listIssuance } from '../constants'

const TableToolbar = ({ formik }) => (
  <Styled.ToolbarArea>
    <Grid container spacing={{ xs: 0, sm: 2 }}>
      <Grid item xs={12} sm={12} md={2}>
        <Select
          id="issuance"
          label="Emissão"
          name="issuance"
          optionsList={listIssuance}
          value={formik.values?.issuance}
          onChange={(e) => formik.setFieldValue('issuance', e.target.value)}
        />
      </Grid>
    </Grid>
  </Styled.ToolbarArea>
)
export default TableToolbar
