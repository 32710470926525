import { httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getListRequirements = async (params) => {
  const response = await httpGet(ENDPOINT.REQUIRIMENT.CRUD, { params })
  return response
}

export const getListRequirementsById = async (id) => {
  const response = await httpGet(`${ENDPOINT.REQUIRIMENT.CRUD}/${id}`)
  return response
}

export const getSector = async (params = {}) => {
  const response = await httpGet(
    `${ENDPOINT.REQUIRIMENT.SECTOR}?${convertToParams(params)}`
  )

  return response
}

export const getHistoric = async (params = {}) => {
  const response = await httpGet(
    `${ENDPOINT.REQUIRIMENT.HISTORIC}?${convertToParams(params)}`
  )

  return response
}

export const getListSector = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.REQUIRIMENT.SECTOR}?${convertToParams(params)}`
  )

  return response
}

export const getListResponsible = async (params = {}) => {
  const response = await httpGet(
    `${ENDPOINT.REQUIRIMENT.RESPONSIBLE}?${convertToParams(params)}`
  )

  return response
}

export const getSecretaryTypes = async (params = {}) => {
  const response = await httpGet(
    `${ENDPOINT.REQUIRIMENT.SECRETARY_TYPES}?${convertToParams(params)}`
  )

  return response
}

export const getRequirements = async () => {
  const response = await httpGet(ENDPOINT.REQUIRIMENT.SECRETARY_TYPES)

  return response
}

export const getRequirementsCourse = async (params = {}) => {
  const response = await httpGet(
    `${ENDPOINT.REQUIRIMENT.COURSE_SCHOOL}?${convertToParams(params)}`
  )

  return response
}

export const getStudentDetails = async (params = {}) => {
  const response = await httpGet(
    `${ENDPOINT.REQUIRIMENT.STUDENT_DETAILS}?${convertToParams(params)}`
  )

  return response
}

export const newRequirements = async (payload) => {
  const response = await httpPost(ENDPOINT.REQUIRIMENT.CRUD, payload)

  return response
}

export const statusRequirements = async (data) => {
  const response = await httpPut(ENDPOINT.REQUIRIMENT.STATUS, data)
  return response
}

export const newRequirementsById = async (id, payload) => {
  const response = await httpPut(`${ENDPOINT.REQUIRIMENT.CRUD}/${id}`, payload)
  return response
}

export const getListRequirementsInXLSX = async (params) => {
  const response = await httpGet(ENDPOINT.REQUIRIMENT.XLSX, {
    params,
    responseType: 'blob'
  })
  return response
}

export const getListRequirementsCounters = async (params) => {
  const response = await httpGet(ENDPOINT.REQUIRIMENT.COUNTER, {
    params
  })
  return response
}
export const getRequirementsByCPFOrEnrollment = async (params) => {
  const response = await httpGet(ENDPOINT.REQUIRIMENT.EXISTING, {
    params
  })
  return response
}
