import PropTypes from 'prop-types'
import * as Styled from './style'

const TextField = ({
  className,
  error,
  helperText,
  id,
  label,
  type,
  variant,
  onBlur,
  fullWidth,
  size,
  isMargin,
  onChange,
  disabled,
  InputProps,
  ...props
}) => (
  <Styled.TextField
    className={className}
    error={error}
    fullWidth={fullWidth}
    helperText={helperText}
    id={id}
    disabled={disabled}
    label={label}
    type={type}
    variant={variant}
    onBlur={onBlur}
    size={size}
    $isMargin={isMargin}
    InputProps={InputProps}
    onChange={onChange}
    {...props}
  />
)

export default TextField

TextField.propTypes = {
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  disabled: PropTypes.bool
}

TextField.defaultProps = {
  variant: 'outlined'
}
