import { ERROR, ROUTE } from 'services/constants'
import {
  convertToParams,
  formatDateEnv,
  getErrorMessage,
  getCurrencyStringMaskedOrOriginalValue,
  currencyFormat
} from 'services/helpers'
import {
  invalidDate,
  notFoundNF,
  tooglePercentage,
  toogleValue,
  zeroValue
} from './constants'

const getParamsByBiling = ({
  numberBankBilling,
  values,
  costCenter,
  registerId,
  enabledFields
}) => {
  if (!numberBankBilling) {
    return {
      requirement_type_id: values.service || undefined,
      register: registerId || undefined,
      subject_id: values.shift || undefined,
      description: values.comments || undefined,
      additional_description: values.additionalComments || undefined
    }
  }

  return {
    register: registerId || undefined,
    due_date:
      values?.paidAt && values?.paidAt !== invalidDate
        ? formatDateEnv(values?.paidAt)
        : undefined,
    bank_billing_value:
      getCurrencyStringMaskedOrOriginalValue(values?.originalValue) ||
      undefined,
    description: values?.comments || undefined,
    additional_description: values.additionalComments || undefined,
    cost_center_id: costCenter?.id ?? undefined,
    subsidiary_id: values?.unit || undefined,
    id_center_cost_name: values?.costCenter || undefined,
    id_center_cost_type: values?.type || undefined,
    id_center_cost_subcategory: values?.subcategory || undefined,
    id_center_cost_nature: values?.nature || undefined,
    value_type: values?.checked === false ? toogleValue : tooglePercentage,
    cost_center_value: values?.amount || undefined,
    account: values?.accountId || undefined,
    amount_paid: values?.amountPaid
      ? getCurrencyStringMaskedOrOriginalValue(values?.amountPaid)
      : undefined,
    payment_date:
      values?.paymentDate && values?.paymentDate !== invalidDate
        ? formatDateEnv(values?.paymentDate)
        : undefined,
    transaction_type: values?.paymentMethod || undefined,
    authorization_code: values?.authorizationCode || undefined,
    voucher_number: values?.voucherNumber || undefined,
    ...(values?.installments && {
      max_installments: Number(values?.installments)
    }),
    edit: !(values?.invoices?.length > notFoundNF || enabledFields)
  }
}

const updateErrorMessage = ({
  error,
  status,
  setSnack,
  duplicate,
  setMessageError,
  feedbackMessage
}) => {
  if (error) {
    const message = getErrorMessage(error, status, feedbackMessage)
    setSnack(message, ERROR)

    if (message === duplicate) {
      setMessageError(message)
    }
  }
}

const balanceAmount = (values) => {
  const { updatedValue, amountPaid } = values
  const masktAmountPaid = getCurrencyStringMaskedOrOriginalValue(amountPaid)
  const masktUpdatedValue = getCurrencyStringMaskedOrOriginalValue(updatedValue)

  if (!Number.isNaN(masktAmountPaid) && !Number.isNaN(masktUpdatedValue)) {
    const currency = masktUpdatedValue - masktAmountPaid

    return currency
  }
  return ''
}

const valueGreaterThanAllowed = (values) => balanceAmount(values) < zeroValue

const totalBalance = (values) => {
  const rawValue = balanceAmount(values)
  if (rawValue) {
    return currencyFormat(rawValue)
  }
  return rawValue
}

const pathRoute = (register, name, situation, title) => {
  const PATH = title
    ? `${ROUTE.ADMIN_FINANCIAL_BANK_BILLING_EDIT}?${convertToParams({
        register,
        name,
        situation,
        title
      })}`
    : `${ROUTE.ADMIN_FINANCIAL_BANK_BILLING_CREATE}?${convertToParams({
        register,
        name
      })}`

  return PATH
}

const listBreadcrumbs = (title, isPage) => {
  const billTitlePath = title ? `Título ${title}` : 'Novo título'

  const listInvoice = [
    {
      label: 'Financeiro'
    },
    {
      label: 'Notas Fiscais'
    },
    {
      label: `<b>${billTitlePath}</b>`
    }
  ]

  const listBillisReceive = [
    {
      label: 'Financeiro'
    },
    {
      label: 'Contas a Receber'
    },
    {
      label: `<b>${billTitlePath}</b>`
    }
  ]

  const listFinacialBreakdown = [
    {
      label: 'Financeiro'
    },
    {
      label: 'Contas a Receber'
    },
    {
      label: 'Detalhamento Financeiro'
    },
    {
      label: `<b>${billTitlePath}</b>`
    }
  ]

  const roles = {
    invoices: listInvoice,
    billsReceive: listBillisReceive,
    financialBreakdown: listFinacialBreakdown
  }

  return roles[isPage]
}

export {
  getParamsByBiling,
  updateErrorMessage,
  balanceAmount,
  pathRoute,
  listBreadcrumbs,
  valueGreaterThanAllowed,
  totalBalance
}
