import { createContext, useContext, useState } from 'react'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { useFormik } from 'formik'
import {
  brazilianCurrencyFormat,
  convertDateToUTC,
  toastNotificationWarning
} from 'services/helpers'
import {
  deleteClassReplacement,
  getClassInfo,
  getDisciplines,
  updateReplacement
} from '../services'
import { validateReplaceForm } from './schema'

const initialState = {
  classDetails: {
    id: null,
    name: '',
    code: '',
    shift: '',
    unit: '',
    level: '',
    group: '',
    modality_id: null,
    modality: '',
    lesson_duration: null,
    grade_year: '',
    begin_at: '',
    end_at: '',
    company_id: null,
    type_frequency: '',
    minimum_frequency: null,
    by_subject: null,
    stage: { id: null, name: '', type: null, sum_module: null }
  },
  disciplines: [],
  loadClassDetails: (id) => {},
  loadDisciplines: ({ id, idProfessor, onlyInactives }) => {},
  replaceForm: {},
  setFieldValue: (disciplineId, field, value) => {},
  openCancelModal: false,
  setOpenCancelModal: (value) => {},
  showOnlyInactives: false,
  setShowOnlyInactives: (value) => {},
  professorId: null,
  setProfessorId: (value) => {},
  openModalInactivate: false,
  setOpenModalInactivate: (value) => {},
  currentOnConfirmInactivate: () => {},
  handleOpenModalInactivate: (onConfirm) => {},
  inactivateReplacedSubjects: ({ replaceIds }) => {},
  onCloseModalInactivate: () => {}
}

export const ReplaceClassDetailsContext = createContext(initialState)

export function ReplaceClassDetailsProvider({ children }) {
  const { setLoading } = useLoading()
  const [classDetails, setClassDetails] = useState(initialState.classDetails)
  const [disciplines, setDisciplines] = useState(initialState.disciplines)
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [showOnlyInactives, setShowOnlyInactives] = useState(false)
  const [professorId, setProfessorId] = useState(initialState.professorId)
  const [openModalInactivate, setOpenModalInactivate] = useState(
    initialState.openModalInactivate
  )
  const [currentOnConfirmInactivate, setCurrentOnConfirmInactivate] = useState(
    initialState.currentOnConfirmInactivate
  )

  const handleOpenModalInactivate = (onConfirm) => {
    setCurrentOnConfirmInactivate(() => onConfirm)
    setOpenModalInactivate(true)
  }

  const onCloseModalInactivate = () => setOpenModalInactivate(false)

  const inactivateReplacedSubjects = async ({ replaceIds = [] }) => {
    setLoading(true)

    const replaces = replaceIds.map((replaceId) => ({
      id: replaceId,
      disciplineId: disciplines.find(
        (discipline) => discipline.id === replaceId
      )?.disciplineId
    }))

    let hasError = false

    await deleteClassReplacement({
      schoolClassId: classDetails.id,
      substituteProfessorId: professorId,
      replaces,
      onError: () => {
        setLoading(false)
        hasError = true
      }
    })

    if (!hasError) {
      onCloseModalInactivate()
      setDisciplines((prev) =>
        prev.filter((discipline) => !replaceIds.includes(discipline.id))
      )
    }

    setLoading(false)
  }

  const loadClassDetails = async (id) => {
    setLoading(true)
    const classInfo = await getClassInfo({
      id,
      onError: () => setLoading(false)
    })

    if (classInfo) {
      setClassDetails(classInfo)
    }
    setLoading(false)
  }

  const loadDisciplines = async ({ id, idProfessor, onlyInactives }) => {
    setLoading(true)

    const disciplinesData = await getDisciplines({
      classId: id,
      professorId: idProfessor ?? professorId,
      inactive: onlyInactives,
      onError: () => setLoading(false)
    })

    setDisciplines(disciplinesData)
    setLoading(false)
  }

  const replaceForm = useFormik({
    initialValues: { replaces: [] },
    validate: validateReplaceForm,
    onSubmit: async (values) => {
      if (!values.replaces.length) {
        return toastNotificationWarning('Nenhuma substituição foi modificada')
      }
      setLoading(true)

      await updateReplacement({
        id: classDetails.id,
        values: values.replaces,
        onError: () => setLoading(false)
      })

      return setLoading(false)
    }
  })

  const setFieldValue = (disciplineId, field, value) => {
    const { replaces } = replaceForm.values

    const updatedReplaces = [...replaces]

    const discipline = updatedReplaces.find(
      (disciplineEl) => disciplineEl.id === disciplineId
    )

    if (discipline) {
      discipline[field] = value
    } else {
      const disciplineInState = disciplines.find(
        ({ id }) => id === disciplineId
      )

      if (!disciplineInState) return

      const newReplaceEdit = {
        id: disciplineId,
        hourlyLessonCost: brazilianCurrencyFormat(
          disciplineInState?.hourlyLessonCost?.toFixed(2)
        ),
        substitutionReason: disciplineInState?.substitutionReason,
        substituteProfessorId: disciplineInState?.substituteProfessorId,
        isPermanent: disciplineInState?.isPermanent,
        startDate:
          disciplineInState?.startDate &&
          convertDateToUTC(disciplineInState?.startDate),
        endDate:
          disciplineInState?.endDate &&
          convertDateToUTC(disciplineInState?.endDate),
        disciplineId: disciplineInState?.disciplineId
      }

      newReplaceEdit[field] = value

      updatedReplaces.push(newReplaceEdit)
    }

    replaceForm.setFieldValue('replaces', updatedReplaces)
  }

  return (
    <ReplaceClassDetailsContext.Provider
      value={{
        classDetails,
        loadClassDetails,
        loadDisciplines,
        disciplines,
        replaceForm,
        setFieldValue,
        openCancelModal,
        setOpenCancelModal,
        showOnlyInactives,
        setShowOnlyInactives,
        professorId,
        setProfessorId,
        openModalInactivate,
        setOpenModalInactivate,
        currentOnConfirmInactivate,
        handleOpenModalInactivate,
        inactivateReplacedSubjects,
        onCloseModalInactivate
      }}
    >
      {children}
    </ReplaceClassDetailsContext.Provider>
  )
}

export const useReplaceClassDetailsContext = () =>
  useContext(ReplaceClassDetailsContext)
