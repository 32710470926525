import styled from 'styled-components'
import {
  Grid as MuiGrid,
  Box as MuiBox,
  Typography as MuiTypography
} from '@mui/material'
import theme from 'theme/designTokens'

const Box = styled(MuiBox)`
  &.MuiBox-root  {
    width: 22px;
    height: 22px;
    font-size: 14px;
    texte-align: center:
    text-transform: uppercase;
    font-weight: bold;
    color: ${() => theme.palette.primary.white};
    background-color: ${() => theme.palette.yellow.dark};
  }
`

const BoxAE = styled(MuiBox)`
  &.MuiBox-root {
    width: 22px;
    height: 22px;
    font-size: 14px;
    font-weight: bold;
    color: ${() => theme.palette.primary.white};
    background-color: ${() => theme.palette.primary.main};
    text-align: center;
    margin: 11px 0 0 0;
  }
`

const GridContainer = styled(MuiGrid)`
  &.MuiGrid-container {
    padding: 44px 0;

    & .MuiGrid-item {
      &:first-child {
        padding-top: 6px;
      }

      &:last-child {
        padding-top: 0px;
      }
    }
  }
`

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    display: flex;
    align-items: center;
    width: 100%;

    & .text {
      margin-left: 5px;
    }

    & .textAE {
      margin: 13px 0 0 5px;
    }
  }
`

export { Box, Typography, GridContainer, BoxAE }
