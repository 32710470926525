import styled, { css } from 'styled-components'
import { Link as RouteLink } from 'react-router-dom'
import { TableCell } from '@mui/material'

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    ${({ $maxHeight }) =>
      $maxHeight &&
      `
        max-height: 78px;
        min-height: 55px;
        overflow: hidden;
        max-width: 570px;
        line-height: 1.6;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      `}
    ${({ $isCenter }) =>
      $isCenter &&
      css`
        text-align: center;
      `}
    div.content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .container_icons {
        width: auto;
        p {
          margin: 0;
        }
      }
    }
  }
`

const BoxLink = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`

const StyledTableCellTitle = styled(TableCell)`
  &.MuiTableCell-root {
    width: 30%;
    text-align: left;
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
    padding: 6px 16px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
      }
    }
  }
`

const Link = styled(RouteLink)`
  text-transform: uppercase;
`

export { StyledTableCell, StyledTableCellTitle, BoxLink, Link }
