import getAllPermissions from './permissions'

const isAllowUserAccess = (userPermissions, permissionKey) => {
  // if (!userPermissions || !permissionKey) {
  //   console.error('Parâmetros inválidos para isAllowUserAccess')
  //   return false
  // }

  try {
    const allPermissions = getAllPermissions(userPermissions)

    if (!Object.prototype.hasOwnProperty.call(allPermissions, permissionKey)) {
      console.warn(`Permissão ${permissionKey} não reconhecida`)
      return false
    }

    return Boolean(allPermissions[permissionKey])
  } catch (error) {
    console.error('Erro ao obter permissões:', error)
    return false
  }
}

export default isAllowUserAccess
