import { useLocation } from 'react-router-dom'
import { TableRow, Tooltip } from '@mui/material'

import { LinkTo } from 'components/atoms'
import { SUBJECT_STATUS_NAME } from 'services/constants'
import { formatDate } from 'services/helpers'

import { emptyValue } from '../../../constants'

import * as Styled from './style'

const TableFooter = ({
  row,
  handleRegisterNote,
  handleExcludeLink,
  linkTo
}) => {
  const location = useLocation()

  const {
    subjectId,
    subjectName,
    subjectModule,
    subjectWorkloadHours,
    subjectWorkloadMin,
    linkedSubject
  } = row

  const isAvailabeToRegisterNote = !!linkedSubject
  const isAvailabeToExcludeLink = !!linkedSubject
  const isAvailabeToLink = !linkedSubject

  const currentRoute = `${location.pathname}${location.search}`

  return (
    <TableRow hover key={subjectId}>
      <Styled.TableCellTitle>
        <div>
          <Styled.ContentName>
            <LinkTo
              tooltipTitle={isAvailabeToLink ? 'Click para vincular' : ''}
              nameLink={subjectName}
              linkTo={isAvailabeToLink ? linkTo : currentRoute}
            />
            {linkedSubject && (
              <Styled.Name>
                Vínculo: [{linkedSubject?.schoolClassId}]{' '}
                {linkedSubject?.schoolClassCode} -{' '}
                {linkedSubject?.schoolClassGroup} (
                {formatDate(linkedSubject?.linkedSubjectStartDate)} à{' '}
                {formatDate(linkedSubject?.linkedSubjectEndDate)}) -{' '}
                {linkedSubject?.resposible} (
                {formatDate(linkedSubject?.dateTime)})
              </Styled.Name>
            )}
          </Styled.ContentName>
          <Styled.Actions>
            {isAvailabeToRegisterNote && (
              <Tooltip title="Registrar Nota">
                <Styled.AddIcon onClick={handleRegisterNote} />
              </Tooltip>
            )}
            {isAvailabeToExcludeLink && (
              <Tooltip title="Excluir vínculo">
                <Styled.DeleteIcon onClick={handleExcludeLink} />
              </Tooltip>
            )}
          </Styled.Actions>
        </div>
      </Styled.TableCellTitle>
      <Styled.TableCellRegister>
        {subjectModule ? `${subjectModule}º` : emptyValue}
      </Styled.TableCellRegister>
      <Styled.TableCell>
        {`${subjectWorkloadHours}h${subjectWorkloadMin}min`}
      </Styled.TableCell>
      <Styled.TableCell>
        <Styled.Status situation={row?.subjectStatus}>
          {SUBJECT_STATUS_NAME[row?.subjectStatus] ?? emptyValue}
        </Styled.Status>
      </Styled.TableCell>
    </TableRow>
  )
}

export default TableFooter
