import styled from 'styled-components'
import { Paper } from '@mui/material'

const StyledPaper = styled(Paper)`
  width: 100%;
  margin-bottom: 24px;
`

const StyledPagination = styled.div`
  align-items: center;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
`

export { StyledPaper, StyledPagination }
