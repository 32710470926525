export const SUBJECT_STATUS = {
  LINKED: 'linked',
  NOT_LINKED: 'not_linked',
  PASSING_GRADE: 'passing_grade'
}

export const SUBJECT_STATUS_NAME = {
  [SUBJECT_STATUS.LINKED]: 'Vinculado',
  [SUBJECT_STATUS.NOT_LINKED]: 'Não Vinculado',
  [SUBJECT_STATUS.PASSING_GRADE]: 'Nota de Aproveitamento'
}

export const yearGradeOldList = [
  '01/2020',
  '01/2021',
  '2021/01',
  '2021/01 - EAD'
]
