import { TableRow } from '@mui/material'
import * as Styled from './style'

const TableFooter = ({ row, isUnifyStatus }) => (
  <TableRow hover tabIndex={-1} key={row?.name}>
    <Styled.StyledTableCell>{row?.name}</Styled.StyledTableCell>
    <Styled.StyledTableCell>{row?.amount}</Styled.StyledTableCell>
    <Styled.StyledTableCell>
      {isUnifyStatus ? (
        <Styled.BoxText>Unificado</Styled.BoxText>
      ) : (
        'Não unificado'
      )}
    </Styled.StyledTableCell>
  </TableRow>
)

export default TableFooter
