import {
  getCoordinators,
  getCurriculumById,
  getEvaluations,
  getGradeSheets,
  getLevels,
  getModalities,
  getModules,
  getOptionsCourses,
  getSchoolClassesSubjects,
  getShifts
} from 'services/api/admin'
import { getDataFormKeys, updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { getUnitsRegions } from 'services/api/shared'
import {
  parseSupervisor,
  parseResponse,
  processData,
  processDataDiscipline,
  processDataValuesId,
  typeEndpointPole
} from './helpers'

const getListCoordinators = async ({
  setSnack,
  setLoadingVisibility,
  setListCodinator
}) => {
  setSnack('')
  setLoadingVisibility(true)

  const { data, error, status } = await getCoordinators()

  setLoadingVisibility(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCurriculum
    })
  }

  return setListCodinator(parseSupervisor(data))
}

const getListGradeSheets = async ({
  setSnack,
  setLoadingVisibility,
  formValues,
  id,
  setFormValues
}) => {
  setSnack('')
  setLoadingVisibility(true)

  const { data: response, error, status } = await getCurriculumById(Number(id))

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListCurriculum
    })
  }

  setFormValues({
    ...formValues,
    disciplines: [...response?.disciplines]
  })

  return setLoadingVisibility(false)
}

const onUnitsRegions = async ({ setSnack, setListPole, unitSelected }) => {
  const isName = true

  const {
    data: response,
    error,
    status
  } = await getUnitsRegions(typeEndpointPole(unitSelected))

  const dataPole = getDataFormKeys([0, 'units'], response?.data)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status
    })
  }

  return setListPole(parseResponse(dataPole, isName))
}

const getListSchoolClassSubjects = async ({
  setSnack,
  setLoadingVisibility,
  formValues,
  setFormValues
}) => {
  setSnack('')
  setLoadingVisibility(true)

  const params = {
    level: formValues.level,
    course: formValues.course,
    grade_sheet: formValues.curriculum
  }

  const { data, error, status } = await getSchoolClassesSubjects(params)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListSubjects
    })
  }

  setFormValues({
    ...formValues,
    disciplines: processDataDiscipline(data)
  })

  return setLoadingVisibility(false)
}

const handleGradeSheet = async ({
  setSnack,
  formValues,
  setListGradeSheet
}) => {
  setSnack('')

  const { data, error, status } = await getGradeSheets({
    level_name: formValues?.level,
    course_name: formValues?.course,
    active: true
  })

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  return setListGradeSheet(processData(data?.data))
}

const handleShifts = async ({ setSnack, setShifts }) => {
  setSnack('')

  const { data, error, status } = await getShifts()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  return setShifts(processData(data?.data))
}

const handleLevels = async ({ setSnack, setLevels }) => {
  setSnack('')

  const { data, error, status } = await getLevels()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  return setLevels(processData(data?.data))
}

const handleCourses = async ({ setSnack, setCourses, level, unit }) => {
  setSnack('')

  const params = {
    unit_name: unit,
    level_name: level
  }

  const { data, error, status } = await getOptionsCourses(params)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  return setCourses(processData(data?.data))
}

const handleEvaluations = async ({ setSnack, setEvaluations }) => {
  setSnack('')

  const { data, error, status } = await getEvaluations()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  return setEvaluations(processDataValuesId(data?.data))
}

const handleModalities = async ({ setSnack, setModalities }) => {
  setSnack('')

  const MODALITY_VALUES = [1, 3, 13, 14]

  const { data, error, status } = await getModalities()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  const modalities = processDataValuesId(data?.data)

  const modalitiesFiltered = modalities?.filter((item) =>
    MODALITY_VALUES.includes(item.value)
  )

  return setModalities(modalitiesFiltered)
}

const handleModules = async ({ setSnack, setModules }) => {
  setSnack('')

  const { data, error, status } = await getModules()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListGradeSheets
    })
  }

  return setModules(processDataValuesId(data?.data))
}

const getAllServices = async ({
  setSnack,
  setShifts,
  setLevels,
  setLoadingVisibility,
  setEvaluations,
  setModalities,
  setModules,
  setListCodinator
}) => {
  setLoadingVisibility(true)

  await handleShifts({
    setSnack,
    setShifts
  })

  await handleLevels({
    setSnack,
    setLevels
  })

  await getListCoordinators({
    setSnack,
    setLoadingVisibility,
    setListCodinator
  })

  await handleEvaluations({ setSnack, setEvaluations })

  await handleModalities({ setSnack, setModalities })

  await handleModules({ setSnack, setModules })

  return setLoadingVisibility(false)
}

export {
  getListGradeSheets,
  handleGradeSheet,
  getListSchoolClassSubjects,
  onUnitsRegions,
  getAllServices,
  handleCourses,
  getListCoordinators
}
