const isValidCPF = (value) => {
  if (!value) return ''
  const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/

  return regex.test(value)
}

const validateCPF = (cpf) => {
  const totalDigits = 11
  const cpfFormatted = cpf.replace(/[^\d]/g, '')

  if (cpfFormatted.length !== totalDigits || /^(.)\1{10}$/.test(cpfFormatted)) {
    return false
  }

  const calculateDigit = (slice) =>
    ((slice.reduce((acc, value, i) => acc + value * (slice.length + 1 - i), 0) *
      10) %
      11) %
    10

  const digits = Array.from(cpfFormatted, Number)
  const verificationDigits = digits.slice(9)

  const calculatedDigits = [digits.slice(0, 9), digits.slice(0, 10)].map(
    (slice) => calculateDigit(slice)
  )

  return Boolean(
    verificationDigits.every(
      (digit, index) => digit === calculatedDigits[index]
    )
  )
}

const formatCPF = (value) => {
  if (!value) return ''

  return value
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
    .substr(0, 14)
}

export { isValidCPF, formatCPF, validateCPF }
