import { ROUTE } from 'services/constants'

const breadCrumbsItems = (studentName, studentCourseName) => [
  {
    path: '',
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: 'Alunos'
  },
  {
    path: '',
    label: studentName
  },
  {
    path: '',
    label: 'Curso'
  },
  {
    path: '',
    label: studentCourseName
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_ADDITIONAL_ACTIVITIES,
    label: '<b>Atividade complementar</b>'
  }
]

const defaultItemsPerPage = 10

const titleModalConfirm = ''

const messageModalConfirm = ''

const textButtonNotConfirm = 'Cancelar'

const textButtonConfirm = 'Enviar'

const getNewActivityMessageAction = (isLastPageAvaliation) =>
  `Nova ${isLastPageAvaliation ? 'atividade' : 'solicitação'}`

const messageFeedback = {
  createActivity: {
    success: 'Solicitação registrada',
    error: 'Erro ao tentar registrar solicitação'
  },
  rateActivity: {
    success: 'Registro salvo',
    error: 'Erro ao tentar salvar registro'
  }
}

export {
  getNewActivityMessageAction,
  breadCrumbsItems,
  defaultItemsPerPage,
  titleModalConfirm,
  messageModalConfirm,
  textButtonNotConfirm,
  textButtonConfirm,
  messageFeedback
}
