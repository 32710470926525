import styled from 'styled-components'
import { Box } from '@mui/material'

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 24px;

  & .MuiTypography-root {
    padding: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
`

const BoxInfo = styled.div`
  width: 80%;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const BoxInfoDownBreadcrumbs = styled(Box)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  width: 80%;
  margin: 15px 0 0 0;
`

export { Container, BoxInfo, BoxInfoDownBreadcrumbs }
