/* eslint-disable no-useless-escape */
import * as yup from 'yup'

const schema = () =>
  yup.object().shape({
    nationality: yup.string().required('Nacionalidade é obrigatório').nullable(),
    place_of_birth_state: yup
      .string()
      .required('Naturalidade (UF) é obrigatório').nullable(),
    place_of_birth_city: yup
      .string()
      .required('Naturalidade (Cidade) é obrigatório').nullable(),
    cpf: yup.string().required('CPF é obrigatório').nullable(),
    birth_date: yup.date().required('Data de Nascimento é obrigatório').nullable(),
    mother: yup.string().required('Nome da mãe é obrigatório').nullable()
  })
export default schema
