import { cleanObject } from 'services/helpers/cleanObject'

const { default: FEEDBACK_SNACK } = require('feedBackSnack')
const { getDashboardCompany } = require('services/api/admin')
const { updateErrorMessage } = require('services/helpers')

const getDetailsDash = async ({
  unit,
  setSnack,
  situation,
  setListClasses,
  setDetailsDash,
  setListStudent,
  setLoadingVisibility
}) => {
  setSnack('')
  setLoadingVisibility(true)
  const emptySituation = situation ? 'closure' : 'progress'
  const params = cleanObject({
    unit,
    situation: emptySituation
  })

  const { data, error, status } = await getDashboardCompany(params)
  setLoadingVisibility(false)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDetailsDashboar
    })
  }

  setDetailsDash(data)
  setListStudent(data?.students_by_poles || [])
  return setListClasses({
    initial: data?.classes,
    filtered: data?.classes
  })
}

const handleFilterList = async ({ value, listStudent, setFilteredList }) => {
  const list = listStudent || []
  const filteredList = list.filter((item) => item === value)

  return setFilteredList(filteredList)
}

const handleFilterListByStatus = ({
  filteredBy,
  detailsDash,
  setListClasses
}) =>
  ({
    [detailsDash?.total_classes]: () =>
      setListClasses((prev) => ({
        ...prev,
        filtered: prev.initial
      })),
    [detailsDash?.active_students]: () =>
      setListClasses((prev) => ({
        ...prev,
        filtered: prev.initial.filter((turma) => turma.students.active > 0)
      })),
    [detailsDash?.canceled_students]: () =>
      setListClasses((prev) => ({
        ...prev,
        filtered: prev.initial.filter((turma) => turma.students.canceled > 0)
      })),
    [detailsDash?.absent_students]: () =>
      setListClasses((prev) => ({
        ...prev,
        filtered: prev.initial.filter((turma) => turma.students.absent > 0)
      }))
  }[filteredBy]())

const getCardsNumber = ({ detailsDash, theme }) => [
  {
    variant: 'text',
    subTitle: 'Total de Turmas',
    hasColor: theme.palette.primary.main,
    title: detailsDash?.total_classes,
    hasColorTitle: theme.palette.primary.main,
    disabled: Boolean(!detailsDash?.total_classes)
  },
  {
    variant: 'text',
    subTitle: 'Alunos Ativos',
    hasColor: theme.palette.success.main,
    title: detailsDash?.active_students,
    hasColorTitle: theme.palette.success.main,
    disabled: Boolean(!detailsDash?.active_students)
  },
  {
    variant: 'text',
    subTitle: 'Alunos Cancelados',
    hasColor: theme.palette.red.main,
    title: detailsDash?.canceled_students,
    hasColorTitle: theme.palette.red.main,
    disabled: Boolean(!detailsDash?.canceled_students)
  },
  {
    variant: 'text',
    subTitle: 'Alunos com 7 dias consecutivos de falta',
    hasColor: '#FF7F00',
    title: detailsDash?.absent_students,
    hasColorTitle: '#FF7F00',
    disabled: Boolean(!detailsDash?.absent_students)
  }
]

export {
  getDetailsDash,
  getCardsNumber,
  handleFilterList,
  handleFilterListByStatus
}
