import styled from 'styled-components'
import { Stepper as MuiStepper } from '@mui/material'

const Stepper = styled(MuiStepper)`
  &.MuiStepper-root {
    align-items: center;
    margin: 24px 80px;
  }
`
const Form = styled.form`
  .MuiPaper-root {
    color: #fff;
    min-width: 250px;
  }
`

export { Stepper, Form }
