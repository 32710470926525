import { getTypesDisabilities } from 'services/api/admin'
import { getCountry, getStates } from 'services/api/shared'
import { parseCountrys } from './helpers'

const fetchTypesDisabilities = async ({ setListTypeDisability }) => {
  const { data } = await getTypesDisabilities()

  const newResponse = [
    ...(data ?? [])?.map((item) => ({
      label: item?.name,
      value: item?.id
    })),
    { label: 'Não possui', value: 0 }
  ]

  setListTypeDisability(newResponse)
}

const initialData = async ({ setCountrys, setPlaceOfBirthStates }) => {
  const [countries, states] = await Promise.all([getCountry(), getStates()])
  const parsedCountries = parseCountrys(countries)

  setCountrys(parsedCountries)
  setPlaceOfBirthStates(states)
}

export { fetchTypesDisabilities, initialData }
