import { useState, Suspense } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { getComparator, stableSort } from 'services/helpers/formatTable'
import { PaginationAdvanced } from 'components/molecules'
import { paginationFilter } from 'services/helpers'
import { TableHeaderDefault } from 'components/organisms'
import { sortAsc, sortDesc, indexDefault, headCellsPending } from './constants'
import { DEFAULT_PAGE } from '../FormListDeclaretion/config'
import TableToolbar from './TableToolbar'
import TableFooter from './TableFooter'
import { StyledPaper, StyledTableContainer } from './style'

const TableDeclaration = ({
  items,
  formik,
  setPage,
  totalPage,
  pageActual,
  onManualEnrollment,
  filter,
  setFilter
}) => {
  const [order, setOrder] = useState(sortAsc)
  const [orderBy, setOrderBy] = useState()
  const [selected, setSelected] = useState([])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = items.map((n) => n.title)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const isSelected = (title) => selected.indexOf(title) !== indexDefault

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  return (
    <Suspense>
      <TableToolbar formik={formik} selected={selected} />
      <StyledPaper>
        <StyledTableContainer>
          <MuiTable>
            <TableHeaderDefault
              order={order}
              orderBy={orderBy}
              headCells={headCellsPending}
              onRequestSort={handleRequestSort}
              handleSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy)).map(
                (row, index) => {
                  const isItemSelected = isSelected(row.title)
                  const labelId = `enhanced-table-${index}`

                  return (
                    <TableFooter
                      row={row}
                      key={row.id}
                      labelId={labelId}
                      isItemSelected={isItemSelected}
                      onManualEnrollment={onManualEnrollment}
                    />
                  )
                }
              )}
            </TableBody>
          </MuiTable>
        </StyledTableContainer>
        {items?.length > DEFAULT_PAGE && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={pageActual}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setFilter(e.target.value)
            }}
          />
        )}
      </StyledPaper>
    </Suspense>
  )
}

export default TableDeclaration
