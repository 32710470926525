import { replacementType } from './constants'

const isDisabledButton = ({
  endDate,
  startDate,
  replacement,
  reasonReplacement
}) => {
  let enabled = reasonReplacement
  if (replacement !== replacementType) {
    enabled = startDate && endDate && reasonReplacement
    return !enabled
  }
  return !enabled
}

export { isDisabledButton }
