import { useEffect, useState } from 'react'
import { WarningAmber } from '@mui/icons-material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { useSnack } from 'services/hooks'
import { useListUsersContext } from '../Context/listUsersContext'
import { getUnifyEnrollment, searchSimilarities } from '../services'
import List from './componets/List'
import ListSelectEnrollment from './componets/ListSelectEnrollment'
import { messagesModalUnify } from './constants'
import * as Styled from './style'

const FinallyUnify = ({ handleChangeInitPage, selectUser }) => {
  const snackProps = useSnack()
  const [similarities, setSimilarities] = useState()
  const [status, setStatus] = useState()
  const [loading, setLoading] = useState()
  const [listRegistration, setListRegistration] = useState()
  const [isUnifyStatus, setIsUnifyStatus] = useState(false)
  const [openModalBack, setOpenModalBack] = useState(false)
  const [openModalUnify, setOpenModalUnify] = useState(false)

  const {
    listUnifyUsers,
    setListUnifyUsers,
    removeUsersListUnifyById,
    resetListUnify
  } = useListUsersContext()

  useEffect(() => {
    const registerArray = listUnifyUsers?.map((user) => user.register)
    const values = registerArray?.filter((id) => listRegistration !== id)

    searchSimilarities({
      setSnack: snackProps.setSnack,
      setStatus,
      setSimilarities,
      values,
      setLoading
    })
  }, [listRegistration])

  const handleUnify = () => {
    const registerArray = listUnifyUsers?.map((user) => user.register)
    const values = registerArray?.filter((id) => listRegistration !== id)
    setOpenModalUnify(false)
    getUnifyEnrollment({
      setSnack: snackProps.setSnack,
      setStatus,
      setLoading,
      mergeIdsValues: values,
      mainIdValue: listRegistration,
      setIsUnifyStatus
    })
  }

  const UserAlreadyMerged = listUnifyUsers?.filter(
    (elem) => listRegistration === elem.register
  )

  const handleClose = () => {
    resetListUnify()
    handleChangeInitPage()
  }

  return (
    <>
      <TemplateDefaultFormAndResult
        formHeader={
          <ListSelectEnrollment
            items={isUnifyStatus ? UserAlreadyMerged : listUnifyUsers}
            setSelectRegistrationMerge={setListRegistration}
            removeUsersListUnifyById={removeUsersListUnifyById}
            selectUser={selectUser}
            isUnifyStatus={isUnifyStatus}
          />
        }
        snackProps={snackProps}
        labelButtonHeader={isUnifyStatus ? 'Fechar' : 'Voltar'}
        onClickButton={() => handleClose()}
        titleHeader="<strong>Sistema -</strong> Unificar matrículas"
      />

      {listUnifyUsers.length ? (
        <>
          <Styled.Text>
            Registros encontrados dos cadastros a serem unificados
          </Styled.Text>

          <List items={similarities?.data} isUnifyStatus={isUnifyStatus} />
          {!isUnifyStatus && (
            <Styled.ButtonGrup>
              <ButtonFooterV2
                labelClose="Cancelar"
                labelConfirm="Unificar"
                onClickConfirm={() => setOpenModalUnify(true)}
                onClickClose={() => setOpenModalBack(true)}
                size="large"
              />
            </Styled.ButtonGrup>
          )}
        </>
      ) : (
        <>
          <Styled.ButtonBox>
            <Styled.ButtonSearch
              onClick={() => handleChangeInitPage(handleChangeInitPage)}
            >
              BUSCAR OUTRO REGISTRO
              <SearchOutlinedIcon />
            </Styled.ButtonSearch>
          </Styled.ButtonBox>
        </>
      )}

      <ModalConfirm
        icon={<WarningAmber color="warning" fontSize="large" />}
        open={openModalUnify}
        onClickNotConfirm={() => setOpenModalUnify(false)}
        title={messagesModalUnify.title}
        message={messagesModalUnify.text}
        textButtonNotConfirm="cancelar"
        textButtonConfirm="confirmar"
        onClickConfirm={handleUnify}
      />
    </>
  )
}

export default FinallyUnify
