import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Grid,
  Accordion,
  IconButton,
  AccordionSummary,
  Tooltip,
  Avatar
} from '@mui/material'
import { ExpandMore, ListAlt, Edit as EditIcon } from '@mui/icons-material'
import { convertToParams, isEmpty } from 'services/helpers'
import { Button, TextNotFound } from 'components/atoms'
import { ROUTE } from 'services/constants'
import { MESSAGE } from '../../constants'

import * as Styled from './style'

const Replacement = ({ listReplacement }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { name, id } = params

  const handleEditReplaceClass = (classId) => {
    navigate(
      `${ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS_DETAILS_EDIT}?${convertToParams(
        {
          isEdit: true,
          idProfessor: id,
          name,
          id: classId
        }
      )}`
    )
  }

  return (
    <>
      <Styled.Grid
        $flex
        $isColumn
        container
        $marginTop="25px"
        $AlignItems="flex-end"
        spacing={{ xs: 0, sm: 2 }}
      >
        <Styled.Grid xs={12} sm={12} md={12}>
          <Button
            type="button"
            color="primary"
            value="NOVA SUBSTITUIÇÃO"
            onclick={() =>
              navigate(
                `${ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS}?${convertToParams({
                  id,
                  name
                })}`
              )
            }
          />
        </Styled.Grid>
      </Styled.Grid>
      <Styled.Grid xs={12} sm={12} md={12}>
        {isEmpty(listReplacement) && (
          <TextNotFound icon={<ListAlt />} text={MESSAGE.NOT_REPLACEMENT} />
        )}
      </Styled.Grid>

      {!isEmpty(listReplacement) && (
        <>
          {listReplacement?.map((item) => (
            <Accordion key={item.course}>
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                expandIcon={<ExpandMore />}
              >
                <Styled.WrapperSummary>
                  <Styled.SummaryLeft>
                    <Styled.Text>
                      <b>Turma {item.school_class_code}</b>
                    </Styled.Text>
                    <Tooltip title="Editar Substituição" arrow>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          handleEditReplaceClass(item.school_class_id)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Styled.SummaryLeft>
                  <Styled.SummaryRigth>
                    <Tooltip title="Ativos" arrow>
                      <Avatar
                        sx={{
                          bgcolor: (t) => t.palette.success.main,
                          width: 32,
                          height: 32,
                          fontSize: 14
                        }}
                      >
                        {item.active_substitutions || 0}
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Inativos" arrow>
                      <Avatar
                        sx={{
                          bgcolor: (t) => t.palette.red.light,
                          mr: (t) => t.spacing(1),
                          width: 32,
                          height: 32,
                          fontSize: 14
                        }}
                      >
                        {item.inactive_substitutions || 0}
                      </Avatar>
                    </Tooltip>
                  </Styled.SummaryRigth>
                </Styled.WrapperSummary>
              </AccordionSummary>

              <Styled.AccordionDetails>
                <Styled.Grid
                  $flex
                  container
                  $AlignItems="center"
                  $JustifyContent="flex-start"
                  spacing={{ xs: 2, sm: 2 }}
                >
                  <Grid item xs={12} sm={12} md={3}>
                    <Styled.Text>
                      <b>Nivel</b> {item?.level}
                    </Styled.Text>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Styled.Text>
                      <b>Curso</b> {item?.course}
                    </Styled.Text>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Styled.Text>
                      <b>Unidade</b> {item?.unit}
                    </Styled.Text>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Styled.Text>
                      <b>Polo</b> {item?.pole}
                    </Styled.Text>
                  </Grid>
                </Styled.Grid>
              </Styled.AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
    </>
  )
}

export { Replacement }
