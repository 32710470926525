import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Search } from '@mui/icons-material'

import { useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { convertToParams, makeURLAccessLegacy } from 'services/helpers'
import { EmptyState } from 'components/molecules'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'

import { Accordion } from './components'
import { breadcrumbItems } from './constants'
import {
  getListCoursesClassesByStudent,
  excludeClassLinkOfDiscilple
} from './services'

import * as Styled from './style'

const ListClassDiscipline = () => {
  const [updateState, setUpdateState] = useState(true)
  const [list, setList] = useState([])

  const navigate = useNavigate()
  const snackProps = useSnack()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])

  const { setSnack } = snackProps
  const { lastSchoolClassId, register, studentName, isLastPageListStudents } =
    params

  const hasResult = !!list?.length

  const handleRegisterNote = ({ schoolClassId, linkedSubjectId }) => {
    const legacyUrl = makeURLAccessLegacy(
      `acesso/diario_notas.php?matricula=${register}&id_turma=${schoolClassId}&turma_disc=${linkedSubjectId}`
    )
    window.open(legacyUrl, '_blank')
  }

  const handleExcludeLink = (linkedId, handleClose) => {
    excludeClassLinkOfDiscilple({
      setSnack,
      linkedId,
      callbackSuccess: () => {
        handleClose()
        setUpdateState(true)
      }
    })
  }

  const customRedirect = () => {
    const pathNameRoute = isLastPageListStudents
      ? ROUTE.ADMIN_SECRETARY_STUDENTS
      : ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER

    navigate(
      `${pathNameRoute}?${convertToParams({
        register,
        studentName
      })}`
    )
  }

  useEffect(() => {
    if (updateState) {
      getListCoursesClassesByStudent({
        setSnack,
        register,
        setList,
        updateState,
        setUpdateState
      })
    }
  }, [updateState])

  return (
    <TemplateDefaultHeaderByContent
      breadcrumbsItems={[...breadcrumbItems(studentName)]}
      classNameHeader="goBack"
      content={
        <>
          {hasResult && (
            <Styled.ContainerList>
              <Styled.Title>Vínculos Turmas/Disciplinas</Styled.Title>

              {list?.map((item) => {
                const { schoolClassId } = item

                const isInitialOpened =
                  schoolClassId === Number(lastSchoolClassId)

                return (
                  <Accordion
                    key={schoolClassId}
                    handleRegisterNote={handleRegisterNote}
                    handleExcludeLink={handleExcludeLink}
                    isInitialOpened={isInitialOpened}
                    {...item}
                  />
                )
              })}
            </Styled.ContainerList>
          )}

          {!hasResult && (
            <EmptyState
              icon={<Search />}
              labelEmptyState="Não foi encontrada nenhuma turma para o aluno."
            />
          )}
        </>
      }
      labelCallToAction="voltar"
      onClickButton={customRedirect}
      snackProps={snackProps}
    />
  )
}

export default ListClassDiscipline
