import styled from 'styled-components'
import { Grid as MuiGrid, Typography } from '@mui/material'
import { AddBox as MuiAddIcon, Delete } from '@mui/icons-material'
import { Modal as MuiModal } from 'components/atoms'

const Modal = styled(MuiModal)`
  &.MuiDialog-paper {
    max-width: 790px;
  }
`

const Grid = styled(MuiGrid)`
  padding: 21px;
`

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
  }
`

const AddIcon = styled(MuiAddIcon)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.primary.main};
    padding: 0;
    font-size: 30px;
  }
  :hover {
    cursor: pointer;
  }
`

const DeleteIcon = styled(Delete)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.textColors.error};
  }
  margin-top: 10px;
  :hover {
    cursor: pointer;
  }
`

export { Modal, Grid, Title, AddIcon, DeleteIcon, Box }
