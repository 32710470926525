/* eslint-disable no-useless-escape */
import * as yup from 'yup'

const schema = yup.object().shape({
  content_description: yup.string().required('O campo descrição é obrigatório'),
  content_section: yup
    .string()
    .required('O campo capítulo/unidade  é obrigatório'),
  content_workload: yup.string().required('O campo carga horária é obrigatório')
})

export default schema
