import Content from './Content'
import Frequency from './Frequency'
import Grade from './Grades'

const diaryProfessorTabs = [
  {
    index: 0,
    getContent: ({ ...params }) => <Content params={params} />,
    navigation: 'Conteúdo',
    title: 'Diário de Conteúdo'
  },
  {
    index: 1,
    getContent: ({ ...params }) => <Frequency params={params} />,
    navigation: 'Frequência',
    title: 'Diário de Frequência'
  },
  {
    index: 2,
    getContent: ({ ...params }) => <Grade params={params} />,
    navigation: 'Notas',
    title: 'Diário de Notas'
  }
]

export { diaryProfessorTabs }
