import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { isEmpty } from 'services/helpers'
import { ListAlt } from '@mui/icons-material'
import { TextNotFound } from 'components/atoms'
import { MESSAGE, breadCrumbsItems, defaultItemsPerPage } from './contants'
import { TabsBindByUnbinded } from './components/Tabs'
import { getAllServices, getListBind, getListUnbinded } from './services'

const BindByUnbinded = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { setSnack } = snackProps
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id } = params

  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [listUnbinded, setListUnbinded] = useState([])
  const [listBind, setListBind] = useState([])
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [countUnbinded, setCountUnbinded] = useState(null)
  const [countBind, setCountBind] = useState(null)
  const [value, setValue] = useState(0)

  const onHandleConfirm = () => {
    setOpenModalConfirm(false)
    navigate(`${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS}?id=${id}`)
  }

  const {
    page: pageUnbinded,
    totalPage: totalPageUnbinded,
    setPage: setPageUnbinded,
    items: itemUnbinded
  } = usePaginationBasic(listUnbinded, filter, countUnbinded)

  const {
    page: pageBind,
    totalPage: totalPageBind,
    setPage: setPageBind,
    items: itemBind
  } = usePaginationBasic(listBind, filter, countBind)

  useEffect(() => {
    getListUnbinded({
      classId: id,
      setSnack,
      setListUnbinded,
      setCountUnbinded,
      page: pageUnbinded,
      filter,
      setTabSelected: setValue
    })
  }, [pageUnbinded, filter])

  useEffect(() => {
    getListBind({
      classId: id,
      setSnack,
      setListBind,
      page: pageBind,
      filter,
      setCountBind,
      setTabSelected: setValue
    })
  }, [pageBind, filter])

  useEffect(() => {
    getAllServices({
      classId: id,
      setSnack,
      setLoadingOpen,
      setListUnbinded,
      setListBind,
      setCountUnbinded,
      setCountBind,
      pageUnbinded,
      pageBind,
      filter,
      setTabSelected: setValue
    })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      breadcrumbsItems={breadCrumbsItems(id)}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() => setOpenModalConfirm(true)}
      open={openModalConfirm}
      handleCloseModal={() => setOpenModalConfirm(false)}
      handleConfirmModal={() => onHandleConfirm()}
      content={
        <>
          {!isEmpty(itemUnbinded) || !isEmpty(itemBind) ? (
            <TabsBindByUnbinded
              classId={id}
              setSnack={setSnack}
              filter={filter}
              setFilter={setFilter}
              pageUnbinded={pageUnbinded}
              pageBind={pageBind}
              itemUnbinded={itemUnbinded}
              itemBind={itemBind}
              countUnbinded={countUnbinded}
              countBind={countBind}
              totalPageUnbinded={totalPageUnbinded}
              totalPageBind={totalPageBind}
              setPageUnbinded={setPageUnbinded}
              setPageBind={setPageBind}
              setLoadingOpen={setLoadingOpen}
              setListBind={setListBind}
              setListUnbinded={setListUnbinded}
              setCountUnbinded={setCountUnbinded}
              setCountBind={setCountBind}
              tabSelected={value}
              setTabSelected={setValue}
            />
          ) : (
            <TextNotFound icon={<ListAlt />} text={MESSAGE.NOT_FOUND_SEARCH} />
          )}
        </>
      }
    />
  )
}

export default BindByUnbinded
