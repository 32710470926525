const initialValues = {
  unity: '',
  pole: '',
  group: '',
  level: '',
  course: '',
  module_id: '',
  modality_id: '',
  shift: '',
  workload: '',
  observation: ''
}

const modalityList = [
  {
    value: 1,
    label: 'Presencial'
  },
  {
    value: 3,
    label: 'Online'
  },
  {
    value: 0,
    label: 'Todas'
  }
]

const MESSAGE = {
  SUCCESS_EDIT: 'Dados salvos com sucesso.'
}

const SUCCESS = 'success'

export { initialValues, modalityList, MESSAGE, SUCCESS }
