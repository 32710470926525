import React from 'react'
import { Box, Table, TableContainer, Paper } from '@mui/material'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'

const ActivityTable = ({
  activity,
  studentsAll,
  disableActions,
  entityTitle,
  titleButton,
  startIcon,
  handleEditActivity,
  handleDeleteActivity,
  handleCreateActivity,
  handleScore,
  dropouts,
  classButtonRemove,
  classButtonAdd,
  classButtonNew
}) => (
  <>
    {activity && activity ? (
      <Box sx={{ width: '100%', padding: 0 }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ '.MuiTableCell-root': { borderBottom: 'none' } }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHeader
                classButtonRemove={classButtonRemove}
                classButtonAdd={classButtonAdd}
                classButtonNew={classButtonNew}
                activity={activity}
                handleEditActivity={handleEditActivity}
                handleDeleteActivity={handleDeleteActivity}
                disableActions={disableActions}
                titleButton={titleButton}
                startIcon={startIcon}
                handleCreateActivity={handleCreateActivity}
                dropouts={dropouts}
              />

              {studentsAll &&
                studentsAll.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableFooter
                      index={index}
                      key={labelId}
                      handleScore={handleScore}
                      labelId={labelId}
                      row={row}
                      activity={activity}
                      dropouts={dropouts}
                      disableActions={disableActions}
                    />
                  )
                })}
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    ) : (
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <p>Ainda não há {entityTitle} registradas</p>
      </Box>
    )}
  </>
)

export default ActivityTable
