const initialValues = {
  accordion: [
    { id: 'program', title: 'Ementa', content: '' },
    {
      id: 'skills',
      title: 'Competência e Habilidades',
      content: ''
    },
    { id: 'evaluation_system', title: 'Sistema de Avaliação', content: '' },
    {
      id: 'methodology',
      title: 'Metodologias/Recursos',
      content: ''
    },
    { id: 'competence', title: 'Conteúdo', content: '' },
    {
      id: 'main_bibliography',
      title: 'Bibliografia Principal',
      content: ''
    },
    {
      id: 'complementary_bibliography',
      title: 'Bibliografia Complementar',
      content: ''
    }
  ]
}

const MESSAGE = {
  updateSuccessfully: 'Plano de Ensino salvo com sucesso.'
}

const modulesReactQuill = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ['clean']
  ]
}
export { initialValues, MESSAGE, modulesReactQuill }
