import {
  Delete,
  Feed,
  BookmarkAdd,
  PlaylistAddCheck,
  ListAlt,
  FactCheck,
  Badge,
  HowToReg
} from '@mui/icons-material'
import { makeURLAccessLegacy } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'

const navEdit = ({ handleEdit, handleClose, copyContract }) => [
  {
    label: 'Editar',
    icon: <FactCheck color="warning" />,
    className: TAG_MANAGER.secretariat_btn_student_score,
    onClick: () => {
      handleClose()
      handleEdit()
    }
  },
  {
    label: '2ª Via do contrato',
    icon: <Feed />,
    onClick: () => {
      handleClose()
      copyContract()
    }
  }
]

const generalMenu = ({
  register,
  handleClose,
  handleScore,
  handleCardTitle,
  handleUseOfStudies,
  isHideConfirmReEnrollment,
  handleConfirmReEnrollment,
  isHideUseOfStudies,
  redirectToJustifyAbsence,
  isAvailableToAccessJustifyAbsence,
  redirectToAdditionalActivities,
  selectStundenInfo,
  handleDeclaration,
  disableConfirmReEnrollment
}) => [
  {
    label: 'Declarações',
    icon: <Feed />,
    className: TAG_MANAGER.secretariat_btn_access_studentdeclarations,
    onClick() {
      handleClose()
      handleDeclaration(selectStundenInfo)
    }
  },
  {
    label: '2ª Via do contrato',
    icon: <Feed />,
    className: TAG_MANAGER.secretariat_btn_access_studentdeclarations,
    onClick: () => {
      handleClose()
      const legacyUrl = makeURLAccessLegacy(
        `acesso/aluno_contrato_pdf81.php?matricula=${register}&ref_curso=${selectStundenInfo?.contract}`
      )
      window.open(legacyUrl, '_blank')
    }
  },
  {
    label: 'Atividades complementares',
    icon: <BookmarkAdd />,
    className: TAG_MANAGER.secretariat_btn_access_linkStudentDiscipline,
    onClick: () => {
      handleClose()
      redirectToAdditionalActivities()
    }
  },
  {
    label: 'Aproveitamento de estudos',
    icon: <PlaylistAddCheck />,
    className: TAG_MANAGER.secretary_btn_open_slipStudent,
    isHide: isHideUseOfStudies,
    onClick: () => {
      handleClose()
      handleUseOfStudies()
    }
  },
  {
    label: 'Boletim',
    icon: <ListAlt />,
    className: TAG_MANAGER.secretary_btn_start_changeStudentPassword,
    onClick: () => {
      handleClose()
      handleScore(register)
    }
  },
  {
    label: 'Carteira estudantil',
    icon: <Badge />,
    className: TAG_MANAGER.secretariat_btn_student_score,
    onClick: () => {
      handleClose()
      handleCardTitle()
    }
  },
  {
    label: 'Justificativa de faltas',
    icon: <HowToReg />,
    isHide: isAvailableToAccessJustifyAbsence,
    className: TAG_MANAGER.secretaria_btn_acessa_justificativasDeFaltas,
    onClick: () => {
      handleClose()
      redirectToJustifyAbsence()
    }
  },
  {
    label: 'Confirmar rematrícula',
    icon: <Styled.FactCheck />,
    className: TAG_MANAGER.secretariat_btn_re_enrollment_student,
    isHide: isHideConfirmReEnrollment,
    onClick: () => {
      handleClose()
      handleConfirmReEnrollment()
    },
    disabled: disableConfirmReEnrollment
  }
]

export { generalMenu, navEdit }
