const MESSAGE = {
  NO_SEARCH_PERFORMED:
    '<b>Você ainda não fez uma busca</b><br/>Selecione uma opção e você pode combinar com os filtros para obter uma pesquisa mais específica por turmas.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.'
}

const SUCCESS_DELETE_ACADEMIC_PERFORMANCE =
  'Solicitação de aproveitamento excluída com sucesso.'
const ERROR_DELETE_ACADEMIC_PERFORMANCE =
  'Houve um erro ao excluir a solicitação aproveitamento. Tente novamente.'

const nameAccordion = 'secretary_academic_performances'
const NAME = 'name'
const REGISTRATION = 'register'
const defaultItemsPerPage = 10
const countDefault = 0
const defaultPage = 1
const emptyValue = ''

export {
  nameAccordion,
  MESSAGE,
  NAME,
  REGISTRATION,
  SUCCESS_DELETE_ACADEMIC_PERFORMANCE,
  ERROR_DELETE_ACADEMIC_PERFORMANCE,
  defaultItemsPerPage,
  countDefault,
  defaultPage,
  emptyValue
}
