import { useState, Suspense } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { getComparator, stableSort } from 'services/helpers/formatTable'
import { PaginationAdvanced } from 'components/molecules'
import { paginationFilter } from 'services/helpers'
import { TableHeaderDefault } from 'components/organisms'
import {
  defaultItemsPerPage,
  defaultTotalPage,
  headCellsPending,
  sortAsc,
  sortDesc
} from './constants'
import TableFooter from './TableFooter'
import { StyledPaper, StyledTableContainer, StyledText } from './style'

const TableLinkedClass = ({
  id,
  name,
  items,
  count,
  filter,
  setPage,
  setFilter,
  totalPage,
  pageActual
}) => {
  const [order, setOrder] = useState(sortAsc)
  const [orderBy, setOrderBy] = useState()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  return (
    <Suspense>
      <StyledText>RESUltado PARA a(s) turma(s) SELECIONADA(S)</StyledText>
      <StyledPaper>
        <StyledTableContainer>
          <MuiTable>
            <TableHeaderDefault
              order={order}
              orderBy={orderBy}
              rowCount={items?.length}
              headCells={headCellsPending}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy)).map((row) => (
                <TableFooter row={row} key={row.id} id={id} name={name} />
              ))}
            </TableBody>
          </MuiTable>
        </StyledTableContainer>

        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={filter}
            count={totalPage}
            page={pageActual}
            isSelectItensPerPage
            handleSetPage={handleSetPage}
            optionsList={paginationFilter}
            handleChange={(e) => {
              setPage(defaultTotalPage)
              setFilter(e.target.value)
            }}
          />
        )}
      </StyledPaper>
    </Suspense>
  )
}

export default TableLinkedClass
