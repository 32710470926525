import { useLocation, useNavigate } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { usePaginationBasic, useSnack } from 'services/hooks'
import TAG_MANAGER from 'tagManager'
import { ROUTE } from 'services/constants'
import { PageTitle } from 'components/atoms'
import { BarChart, CardNumber, ChartGeneric } from 'components/molecules'
import theme from 'theme/designTokens'
import { IsEmpty } from 'services/helpers'
import { ArrowForward } from '@mui/icons-material'
import { breadcrumbsItems, orderByDecreasing } from './helpers'
import * as Styled from './style'
import TableDash from '../TableDash'
import { defaultItemsPerPage } from './constants'
import {
  getCardsNumber,
  getDetailsDash,
  handleFilterListByStatus
} from './service'
import ModalStudentByPole from './components/Modal'

const Dashboard = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { unitSelected, situation } = useLocation().state

  const { setSnack } = snackProps
  const [open, setOpen] = useState(false)
  const [detailsDash, setDetailsDash] = useState()
  const [listClasses, setListClasses] = useState({ initial: [], filtered: [] })
  const [filter, setFilter] = useState(defaultItemsPerPage)

  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [listStudent, setListStudent] = useState([])

  const maxAmountPoles = 24
  const amountPolesHighlight = 5
  const maxAmountStudent = 5

  const {
    page: pageActual,
    totalPage,
    items,
    totalItems,
    setPage
  } = usePaginationBasic(listClasses.filtered, filter)

  const data = [
    detailsDash?.students_by_shift?.night || 0,
    detailsDash?.students_by_shift?.weekly_night || 0,
    detailsDash?.students_by_shift?.weekly_morning || 0,
    detailsDash?.students_by_shift?.weekly_afternoon || 0
  ]

  const maxAmountStudentsActives = detailsDash?.students_by_poles.reduce(
    (max, current) =>
      current.students_actives > max.students_actives ? current : max
  )

  useEffect(() => {
    getDetailsDash({
      setSnack,
      situation,
      setListClasses,
      setDetailsDash,
      setListStudent,
      setLoadingVisibility,
      unit: unitSelected?.label
    })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loadingVisibility}
      snackProps={snackProps}
      onClickButton={() =>
        navigate(ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS, {
          state: { savedSearch: true }
        })
      }
      breadcrumbsNotLink={breadcrumbsItems}
      classNameHeader="goBack"
      tagmanagerModal={TAG_MANAGER.secretary_btn_BackApplication}
      content={
        <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
          <Styled.Grid isRow item xs={12} sm={12} md={12}>
            <PageTitle title={`<b>Dashboard -</b> ${unitSelected?.label}`} />
          </Styled.Grid>
          <Styled.Grid isRow item xs={12} sm={12} md={12}>
            <Styled.Grid
              item
              isligth
              sm={12}
              xs={12}
              md={5.9}
              height="100%"
              padding="16px"
            >
              <PageTitle title="<b>Alunos/Turno</b>" />
              <ChartGeneric
                labels={[
                  'Noite',
                  'Semanal noite',
                  'Semanal manhã',
                  'Semanal tarde'
                ]}
                values={data}
                backgroundColor={['#A186CB', '#7A5CA8', '#593493', '#3E2466']}
              />
            </Styled.Grid>
            <Styled.Grid item padding="16px" isligth md={5.9} xs={12} sm={12}>
              <PageTitle title="Total de Polos:" />
              <PageTitle
                title={`<b>${
                  detailsDash?.students_by_poles?.length ?? '-'
                }</b>`}
              />
              <PageTitle title="Alunos/Polo:" />
              <Styled.ContainerChartBar>
                {detailsDash?.students_by_poles
                  ?.slice(0, maxAmountPoles)
                  .map((item, index) => {
                    const percentage =
                      (item.students_actives /
                        maxAmountStudentsActives.students_actives) *
                      100
                    return (
                      <BarChart
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${item.pole_name}_${index}`}
                        value={item.students_actives}
                        label={item.pole_name}
                        height={percentage}
                      />
                    )
                  })}
              </Styled.ContainerChartBar>
              <Styled.List>
                <Styled.ListItem>
                  <Typography variant="body2" fontSize={16}>
                    Polos em destaque
                  </Typography>
                  <Typography variant="body2" fontSize={16}>
                    Alunos
                  </Typography>
                </Styled.ListItem>
                {detailsDash?.students_by_poles
                  .sort((a, b) => orderByDecreasing(a, b, false))
                  ?.slice(0, amountPolesHighlight)
                  .map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Styled.ListItem key={index}>
                      <Styled.ListItemText $isTitle primary={item?.pole_name} />
                      <Styled.ListItemText
                        $small
                        primary={item?.students_actives}
                      />
                    </Styled.ListItem>
                  ))}
                {listStudent.length > maxAmountStudent && (
                  <Styled.Button
                    variant="body2"
                    fontSize={16}
                    onClick={() => setOpen(!open)}
                  >
                    ver todos <ArrowForward />
                  </Styled.Button>
                )}
              </Styled.List>
            </Styled.Grid>
          </Styled.Grid>
          <Styled.Grid isRow item xs={12} sm={12} md={12}>
            <Styled.ContainerCards>
              {getCardsNumber({ detailsDash, theme }).map((cardData) => (
                <CardNumber
                  {...cardData}
                  onClickCard={() => {
                    handleFilterListByStatus({
                      detailsDash,
                      setListClasses,
                      filteredBy: cardData.title
                    })
                  }}
                />
              ))}
            </Styled.ContainerCards>
          </Styled.Grid>
          <Styled.Grid item xs={12} sm={12} md={12}>
            {listClasses && !IsEmpty(listClasses) && (
              <TableDash
                items={items}
                count={totalItems}
                filter={filter}
                setPage={setPage}
                setFilter={setFilter}
                totalPage={totalPage}
                pageActual={pageActual}
              />
            )}
          </Styled.Grid>
          <ModalStudentByPole
            open={open}
            listStudent={listStudent}
            textButtonConfirm="fechar"
            onClickConfirm={() => setOpen(!open)}
            colorButtonConfirm="primary"
          />
        </Styled.Grid>
      }
    />
  )
}
export default Dashboard
