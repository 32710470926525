import { Campaign } from '@mui/icons-material'
import { ACL } from 'services/constants/acl'
import { ROUTE } from 'services/constants'
import theme from 'theme/designTokens'
import {
  NewEnrollment,
  Enrollment,
  RegistrationNewEnrollment,
  DirectEnrollment,
  EnrollmentPayment
} from 'components/pages/Admin'
import isAllowUserAccess from '../helpers/isAllowUserAccess'

export const iconProps = {
  color: theme.palette.primary.white
}

const COMMERCIAL_ROUTES = [
  {
    component: Enrollment,
    exact: true,
    icon: <Campaign sx={{ ...iconProps }} />,
    label: 'Comercial',
    path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'COMMERCIAL'),
    submenu: [
      {
        component: NewEnrollment,
        exact: true,
        label: 'Pré Matrícula',
        path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION_CREATE,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'COMMERCIAL_REGISTRATION')
      },

      {
        component: DirectEnrollment,
        exact: true,
        label: 'Matrícula Direta',
        path: ROUTE.ADMIN_COMMERCIAL_DIRECT_ENROLLMENT,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'COMMERCIAL_DIRECT_ENROLLMENT')
      },
      {
        component: Enrollment,
        exact: true,
        label: 'Matrícula Manual',
        path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION,
        permissions: ACL.admin,
        submenu: [],
        isAllowed: (userPermissions) =>
          isAllowUserAccess(userPermissions, 'COMMERCIAL_REGISTRATION')
      }
    ]
  },
  {
    component: NewEnrollment,
    exact: true,
    path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION_CREATE,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'COMMERCIAL_REGISTRATION_CREATE')
  },
  {
    component: RegistrationNewEnrollment,
    exact: true,
    path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION_SUBSCRIPTION,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'COMMERCIAL_REGISTRATION_SUBSCRIPTION')
  },
  {
    component: EnrollmentPayment,
    exact: true,
    path: ROUTE.ADMIN_COMMERCIAL_ENROLLMENT_PAYMENT,
    permissions: ACL.admin,
    isAllowed: (userPermissions) =>
      isAllowUserAccess(userPermissions, 'COMMERCIAL_REGISTRATION')
  }
]

export default COMMERCIAL_ROUTES
