import styled from 'styled-components'
import { Badge } from '@mui/material'
import { maximunSize } from './constants'

const IconBadge = styled(Badge)`
  .MuiBadge-badge {
    background-color: ${({ badgeContent }) =>
      badgeContent >= maximunSize ? 'warning' : 'transparent'};
  }
`

export { IconBadge }
