import styled from 'styled-components'

const Content = styled.div`
  margin-bottom: 20px;
  margin-top: 64px;
`

const BoxForm = styled.div``

export { Content, BoxForm }
