import { httpDelete, httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getDeclarations = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.DECLARATIONS.BASE_DECLARATIONS}?${convertToParams(params)}`
  )

  return response
}

export const getDetailsDeclaration = async (id) => {
  const response = await httpGet(
    `${ENDPOINT.DECLARATIONS.BASE_DECLARATIONS}/${id}`
  )

  return response
}

export const postDeclarations = async (params) => {
  const response = await httpPost(ENDPOINT.DECLARATIONS.BASE_DECLARATIONS, {
    ...params
  })

  return response
}

export const deleteDeclarations = async (id) => {
  const response = await httpDelete(
    `${ENDPOINT.DECLARATIONS.BASE_DECLARATIONS}/${id}`
  )

  return response
}

export const editDeclarations = async (id, params) => {
  const response = await httpPut(`${ENDPOINT.DECLARATIONS.BASE_DECLARATIONS}/${id}`, params)

  return response
}
