import { useNavigate } from 'react-router-dom'
import { IconButton, Paper, Table, TableBody, TableCell } from '@mui/material'
import { RemoveCircleOutline, RestartAlt } from '@mui/icons-material'

import { ROUTE } from 'services/constants'
import { paginationFilter } from 'services/helpers'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'
import { useSortTable } from 'services/hooks'

import { tableColumns, KEY_COLUMNS } from './config'
import { defaultlistPerPage } from '../../constants'

import * as Styled from './style'

const List = ({
  limit,
  page,
  items,
  count,
  totalPage,
  handleSetPage,
  handleChange,
  handleStatusSupplier
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    items,
    KEY_COLUMNS.REASON_SOCIAL
  )

  const navigate = useNavigate()

  const redirectToEidt = (supplier) => {
    navigate(ROUTE.ADMIN_FINANCIAL_REGISTER_SUPPLIER_EDIT, {
      state: { supplier }
    })
  }

  return (
    <Styled.Wrapper>
      <Paper>
        <Table size="medium">
          <TableHeaderDefault
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableColumns}
          />
          <TableBody>
            {sortTable?.map((item, index) => {
              const labelId = `suppliers-table-${index}`
              const isAvailableToEdit = !item?.inactive

              return (
                <Styled.TableRow key={labelId}>
                  <TableCell padding="checkbox" style={{ width: '25%' }}>
                    <Styled.WrapperLink>
                      <Styled.Link
                        disabled={!isAvailableToEdit}
                        onClick={() => redirectToEidt(item)}
                        component="button"
                        href="#root"
                      >
                        {item?.id}
                      </Styled.Link>
                      {!item?.inactive ? (
                        <Styled.Tooltip title="Inativar" placement="top" arrow>
                          <IconButton
                            className="icon"
                            color="gray"
                            onClick={() => handleStatusSupplier(item?.id, true)}
                          >
                            <RemoveCircleOutline />
                          </IconButton>
                        </Styled.Tooltip>
                      ) : (
                        <Styled.Tooltip title="Ativar" placement="top" arrow>
                          <IconButton
                            className="icon"
                            color="warning"
                            onClick={() =>
                              handleStatusSupplier(item?.id, false)
                            }
                          >
                            <RestartAlt />
                          </IconButton>
                        </Styled.Tooltip>
                      )}
                    </Styled.WrapperLink>
                  </TableCell>
                  <TableCell style={{ width: '50%' }}>
                    {item?.razaoSocial}
                  </TableCell>
                  <TableCell style={{ width: '25%' }}>{item?.cnpj}</TableCell>
                </Styled.TableRow>
              )
            })}
          </TableBody>
        </Table>

        {count > defaultlistPerPage && (
          <PaginationAdvanced
            isSelectItensPerPage
            handleChange={handleChange}
            handleSetPage={handleSetPage}
            count={totalPage}
            optionsList={paginationFilter}
            page={page}
            smallSize
            value={limit}
          />
        )}
      </Paper>
    </Styled.Wrapper>
  )
}

export default List
