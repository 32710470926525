import { useEffect, useState } from 'react'
import { Form as FormikForm, Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListAlt, Search } from '@mui/icons-material'
import { TextNotFound } from 'components/atoms'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { isEmpty } from 'services/helpers'
import { usePaginationBasic, useSnack } from 'services/hooks'
import TAG_MANAGER from 'tagManager'
import Form from './components/Form'
import List from './components/List'
import { DEFAULT_ITEMS_PER_PAGE, initialState, MESSAGE } from './constants'
import { validationSchema } from './schema'
import { loadLog } from './services'
import * as Styled from './style'

const LogUsers = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [listDeclarations, setListDeclarations] = useState([])
  const [loadingOpen, setLoadingOpen] = useState()
  const [notFoundSearch, setNotFoundSearct] = useState(true)
  const [formValues, setFormValues] = useState()
  const [count, setCount] = useState(null)
  const [logs, setLogs] = useState([])
  const [filter, setFilter] = useState(DEFAULT_ITEMS_PER_PAGE)
  const snackProps = useSnack()
  const { setSnack } = snackProps

  const {
    page: currentPage,
    totalPage,
    items,
    setPage
  } = usePaginationBasic(logs?.data, filter, count)

  useEffect(() => {
    if (count)
      loadLog({
        payload: formValues,
        setSnack,
        setLoadingOpen,
        setNotFoundSearct,
        limit: filter,
        pageActual: currentPage,
        setLogs,
        formValues,
        setCount,
        setFormValues
      })
  }, [currentPage, filter])

  return (
    <>
      <TemplateDefaultFormAndResult
        titleHeader="<b>Sistema - </b> Logs de Usuários"
        snackProps={snackProps}
        loadingOpen={loadingOpen}
        listItems={listDeclarations}
        labelButtonHeader="Voltar"
        loadingVisibility={!logs && loadingVisibility}
        classNameHeader={TAG_MANAGER.sistema_btn_cria_novaDeclaracao}
        tagmanagerModal={TAG_MANAGER.sistema_btn_cria_novaDeclaracao}
        linkHeader={ROUTE.ADMIN}
        formHeader={
          <Formik
            initialValues={initialState}
            onSubmit={(values) => {
              loadLog({
                payload: values,
                setSnack,
                setLoadingOpen,
                setNotFoundSearct,
                limit: filter,
                pageActual: currentPage,
                setLogs,
                setFormValues,
                setCount
              })
            }}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({
              handleSubmit,
              setFieldValue,
              values,
              touched,
              errors,
              resetForm
            }) => (
              <FormikForm onSubmit={handleSubmit} noValidate autoComplete="off">
                <Styled.Description>
                  Selecione e preencha os campos para realizar uma busca
                </Styled.Description>
                <Form
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  errors={errors}
                  onClear={resetForm}
                />
              </FormikForm>
            )}
          </Formik>
        }
      />
      <Styled.Comments>{MESSAGE.OBS}</Styled.Comments>

      {notFoundSearch && (
        <TextNotFound
          widthText="700px"
          icon={<ListAlt />}
          text={MESSAGE.NO_SEARCH}
        />
      )}

      {count === 0 && (
        <TextNotFound icon={<Search />} text={MESSAGE.NOT_FOUND_SEARCH} />
      )}

      {count > 0 && !isEmpty(items) && (
        <Styled.Box>
          <List
            typeSearch={formValues}
            count={count}
            filter={filter}
            setFilter={setFilter}
            setPage={setPage}
            totalPage={totalPage}
            currentPage={currentPage}
            rowsItems={items}
          />
        </Styled.Box>
      )}
    </>
  )
}

export default LogUsers
