/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Tabs } from 'components/organisms'
import { LoadingPage, ButtonFooter, Snackbar } from 'components/molecules'
import { ERROR, ROUTE, SUCCESS } from 'services/constants'
import {
  getDataFormKeys,
  getErrorMessage,
  IsEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import { useMakeService, useSnack } from 'services/hooks'
import {
  createByUpdateDocsRequired,
  createCourses,
  getCourse,
  updateCourse
} from 'services/api/admin'
import { getUnitsRegions } from 'services/api/shared'
import { initialState, MESSAGE, typeEndpointPole } from './constants'
import { optionsTabs } from './tabs'
import { response, disabledButton, payload } from './helpers'
import { docsReqSaved, getListDocsRequired } from './services'

const Content = () => {
  const navigate = useNavigate()
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [listDocumentsRequired, setListDocumentsRequired] = useState([])
  const [listSavedDocRequired, setListSavedDocRequired] = useState([])
  const [listDocsSubmit, setListDocsSubmit] = useState([])
  const [documentsRequired, setDocumentsRequired] = useState([])
  const [listPole, setListPole] = useState([])
  const [hasPole, setHasPole] = useState(false)
  const [formNewCourse, setFormCourse] = useState(initialState)
  const [value, setValue] = useState('formeCourse')
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const isEdit = params?.isEdit
  const idCourse = params?.idCourse
  const nameCourse = params?.course

  const snackProps = useSnack()
  const { setSnack, snack, snackOpen } = snackProps

  const paramsMakeService = {
    isUnits: true,
    isLevel: true,
    setSnack
  }
  const { unitys, level } = useMakeService(paramsMakeService)

  const savedSuccessfully = () => {
    setSnack(isEdit ? MESSAGE.updateSuccess : MESSAGE.createSuccess, SUCCESS)

    return setTimeout(() => navigate(-1), 3000)
  }

  const handleSuccessOrError = ({ error, status }) => {
    setLoadingVisibility(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: `para ${isEdit ? 'atualizar' : 'criar'}  um curso`
      })
    }

    return savedSuccessfully()
  }

  const handleSubmitForm = async (values) => {
    setSnack('', '')
    setLoadingVisibility(true)

    const payloadObject = payload(values)

    const payloadDoocs = {
      document_type_ids: listDocsSubmit?.reduce((acc, cur) => {
        const { value: id } = cur
        const newItem = id

        return [...acc, newItem]
      }, [])
    }

    if (isEdit) {
      const { error, status } =
        (await updateCourse(idCourse, payloadObject),
        await createByUpdateDocsRequired(idCourse, payloadDoocs))

      return handleSuccessOrError({ isEdit, error, status })
    }

    const { error, status } = await createCourses(payloadObject)

    return handleSuccessOrError({ isEdit, error, status })
  }

  const onHandleClose = () => {
    navigate(ROUTE.ADMIN_MANAGEMENT_COURSE, {
      state: { savedSearch: true }
    })
  }

  const onUnitsRegions = async (unitId) => {
    const {
      data: res,
      error,
      status
    } = await getUnitsRegions(typeEndpointPole(unitId))
    const data = getDataFormKeys([0, 'units'], res?.data)

    const newData = data?.reduce((acc, cur) => {
      const { name } = cur
      const newItem = { value: name, label: name }

      return [...acc, newItem]
    }, [])

    setListPole(newData)

    if (error) {
      const message = getErrorMessage(error, status)
      setSnack(message, ERROR)
    }
  }

  const handleEditCourse = async () => {
    setSnack('', '')
    setLoadingVisibility(true)

    const { data, error, status } = await getCourse(idCourse)

    if (data?.pole) {
      const findUnit = unitys?.find((item) => item.label === data.unit)
      onUnitsRegions(findUnit?.id)
      setHasPole(true)
    }

    setLoadingVisibility(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: 'para visualizar curso'
      })
    }
    const dataForm = response(data)

    return setFormCourse(dataForm)
  }

  const getDocsReqSaved = (listDocs) =>
    docsReqSaved({
      listDocs,
      setListDocumentsRequired,
      setListSavedDocRequired,
      setSnack,
      setLoadingVisibility,
      idCourse
    })

  const getDocsRequired = async () => {
    const listDocumentRequired = await getListDocsRequired({
      setSnack,
      setLoadingVisibility
    })

    return getDocsReqSaved(listDocumentRequired)
  }

  const filterListPole = async (inputValue) =>
    listPole?.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    )

  const initialValues = mergeInitialValues(formNewCourse)
  const conditionFormValues = isEdit ? initialValues : initialState

  useEffect(() => {
    if (isEdit) {
      getDocsRequired()
      if (unitys && !IsEmpty(unitys)) handleEditCourse()
    }
  }, [unitys])

  const tabs = optionsTabs(idCourse)

  return (
    <>
      <LoadingPage open={loadingVisibility} />

      <Tabs
        customValue={[value, setValue]}
        contents={tabs?.map(({ component }) => (
          <Formik
            initialValues={conditionFormValues}
            onSubmit={handleSubmitForm}
            enableReinitialize
          >
            {({ ...props }) => (
              <Form noValidate autoComplete="off">
                {component({
                  unitys,
                  level,
                  hasPole,
                  setHasPole,
                  isEdit,
                  nameCourse,
                  listPole,
                  conditionFormValues,
                  onUnitsRegions,
                  filterListPole,
                  setListDocsSubmit,
                  formNewCourse,
                  setFormCourse,
                  listDocumentsRequired,
                  listSavedDocRequired,
                  documentsRequired,
                  setDocumentsRequired,
                  ...props
                })}

                <ButtonFooter
                  textButtonNotConfirm="Cancelar"
                  textButtonConfirm="Salvar"
                  sizeType="large"
                  onClickNotConfirm={() => onHandleClose(props.values)}
                  disabledConfirm={disabledButton(props.values)}
                />
              </Form>
            )}
          </Formik>
        ))}
        navigations={tabs.map(({ label }) => label)}
      />

      <Snackbar
        message={snack.message}
        severity={snack.severity}
        shouldOpen={snackOpen}
      />
    </>
  )
}

export default Content
