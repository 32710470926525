import { ROUTE } from 'services/constants'
import { convertToParams } from 'services/helpers'

const handleNewCourse = ({ navigate, register, name }) => {
  const params = convertToParams({
    name,
    register
  })
  navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_CREATE_DECLARATION}?${params}`)
}

export { handleNewCourse }
