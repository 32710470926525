import * as yup from 'yup'

const schema = yup.object().shape({
  course_id: yup.string().required('Campo é obrigatório'),
  book_number: yup.string().nullable(),
  page_number: yup.string().nullable(),
  completion_date: yup.string().required('Campo é obrigatório'),
  registration_date: yup.string().nullable(),
  registration_number: yup.string().nullable()
})

export default schema
