import { Popover as MuiPopoper } from '@mui/material'

const Popover = ({ children, open, idPopover, anchorEl, handleClose }) => (
  <MuiPopoper
    id={idPopover}
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
  >
    {children}
  </MuiPopoper>
)

export default Popover
