const { ROUTE } = require('services/constants')

const breadcrumbsItems = ({ studentName, name }) => [
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: `${studentName}`
  },

  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: `${name}`
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES_CREATE,
    label: '<b>Novo Curso</b>'
  }
]

export { breadcrumbsItems }
