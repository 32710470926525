const MESSAGE = {
  NO_SEARCH_INVOICES:
    '<b>Você ainda não fez uma busca</b><br/>Selecione um período e você pode combinar com os filtros para<br/>obter uma pesquisa mais específica por títulos.',
  NOT_FOUND_SEARCH_INVOICES:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  ERROR_BRINGING_UNITS: 'Erro ao carregar unidades'
}

const limitValueOfTitlesToConvert = 1000
const ALL = 'all'
const nameAccordion = 'invoices'
const defaultTotalPage = 10
const defaultPage = 1
const totalItems = 0
const bankBillingUpdatedSucess = 'Título salvo'

const initialState = {
  beginAt: null,
  endAt: null,
  unity: [],
  bankBillingId: '',
  type: '',
  localPayment: false,
  paid: false,
  sent: false,
  status: '',
  razaoSocial: false
}

export {
  MESSAGE,
  limitValueOfTitlesToConvert,
  ALL,
  nameAccordion,
  defaultTotalPage,
  defaultPage,
  totalItems,
  bankBillingUpdatedSucess,
  initialState
}
