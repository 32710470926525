import { httpDelete, httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getListProfessors = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.CRUD}?${convertToParams(params)}`
  )

  return response
}

export const sendProfessorEmail = async (id, payload) => {
  const response = await httpPost(
    `${ENDPOINT.PROFESSORS.CRUD}/${id}/${ENDPOINT.PROFESSORS.EMAIL}`,
    {
      ...payload
    }
  )
  return response
}

export const createSkills = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.SKILLS}`,
    {
      ...payload
    }
  )
  return response
}

export const deleteSkills = async (idSkill) => {
  const response = await httpDelete(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.SKILLS}/${idSkill}`
  )
  return response
}

export const listSkills = async (idProfessor) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.CRUD}/${idProfessor}/${ENDPOINT.PROFESSORS.SKILLS}`
  )
  return response
}

export const mainSkills = async (idSkill) => {
  const response = await httpPut(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.SKILLS}/${idSkill}/${ENDPOINT.PROFESSORS.MAIN}`
  )
  return response
}

export const putActiveProfessor = async (id, params) => {
  const response = await httpPut(`${ENDPOINT.PROFESSORS.CRUD}/${id}/${params}`)

  return response
}

export const getListProfessor = async (id) => {
  const response = await httpGet(`${ENDPOINT.PROFESSORS.CRUD}/${id}`)

  return response
}

export const getListInteractions = async ({ id, params }) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.CRUD}/${id}/${
      ENDPOINT.PROFESSORS.INTERACTIONS
    }?${convertToParams(params)}`
  )

  return response
}

export const updateProfessor = async (idProfessor, payload) => {
  const response = await httpPut(
    `${ENDPOINT.PROFESSORS.CRUD}/${idProfessor}`,
    payload
  )

  return response
}

export const uploadPhoto = async (idProfessor, payload) => {
  const response = await httpPut(
    `${ENDPOINT.PROFESSORS.CRUD}/${idProfessor}/${ENDPOINT.PROFESSORS.ACADEMIC_PIC}`,
    payload
  )

  return response
}

export const getCourseReportExportXLSX = async (params, id) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.CRUD}/${id}/${ENDPOINT.PROFESSORS.EXPORT_XLSX}?${params}`,
    {
      responseType: 'blob'
    }
  )

  return response
}

export const createProfessor = async (payload) => {
  const response = await httpPost(`${ENDPOINT.PROFESSORS.CRUD}`, {
    ...payload
  })
  return response
}

export const validationCPF = async (cpf) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.CRUD}/${cpf}/${ENDPOINT.PROFESSORS.VALIDATE_CPF}`
  )
  return response
}

export const listDocumentsTypes = async () => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.DOCUMENTS}/${ENDPOINT.PROFESSORS.TYPES}`
  )
  return response
}

export const listDocuments = async (idProfessor) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.CRUD}/${idProfessor}/${ENDPOINT.PROFESSORS.DOCUMENTS}`
  )
  return response
}

export const createDocuments = async (idProfessor, payload) => {
  const response = await httpPost(
    `${ENDPOINT.PROFESSORS.CRUD}/${idProfessor}/${ENDPOINT.PROFESSORS.DOCUMENTS}`,
    payload
  )
  return response
}

export const deleteDocuments = async (idDocs) => {
  const response = await httpDelete(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.DOCUMENTS}/${idDocs}`
  )
  return response
}

export const getClassLinked = async (idProfessor, params) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.CRUD}/${idProfessor}/${
      ENDPOINT.PROFESSORS.SCHOOL_CLASSES_LINKED
    }?${convertToParams(params)}`
  )
  return response
}

export const getReplaceClass = async (idProfessor, params) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.CRUD}/${idProfessor}/${
      ENDPOINT.PROFESSORS.SUBSTITUTE
    }/${ENDPOINT.PROFESSORS.SCHOOL_CLASSES}?${convertToParams(params)}`
  )
  return response
}

export const getDetailsClassLinked = async (idProfessor) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.SCHOOL_CLASSES}/${idProfessor}`
  )
  return response
}

export const editDetailsClassLinked = async (idClass, payload) => {
  const response = await httpPut(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.SCHOOL_CLASSES_LINK}/${idClass}`,
    payload
  )
  return response
}

export const postClassLinked = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.SCHOOL_CLASSES_LINK}`,
    payload
  )
  return response
}

export const getSchoolClassListDisciplines = async (id, params) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.SCHOOL_CLASSES}/${id}/${
      ENDPOINT.PROFESSORS.DISCIPLINES
    }?${convertToParams(params)}`
  )
  return response
}

export const getReplacedSubjects = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.SUBSTITUTE}/link/${
      ENDPOINT.PROFESSORS.DISCIPLINES
    }?${convertToParams(params)}`
  )
  return response
}

export const listClassLinked = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.SCHOOL_CLASSES}/${
      ENDPOINT.PROFESSORS.SEARCH
    }?${convertToParams(params)}`
  )
  return response
}

export const getDisciplinesLinked = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSOR_LINKED_DISCIPLINES}?${convertToParams(params)}`
  )
  return response
}

export const modifyDisciplinesLinked = async (classId, params) => {
  const response = await httpPut(
    ENDPOINT.PROFESSOR_LINKED_SCHOOL_CLASS(classId),
    params
  )
  return response
}

export const removeDisciplinesLinked = async (params) => {
  const response = await httpDelete(
    ENDPOINT.PROFESSOR_LINKED_SCHOOL_CLASS(),
    params
  )
  return response
}

export const listCodeClass = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.SCHOOL_CLASSES}/${
      ENDPOINT.PROFESSORS.SEARCH_ALL
    }?${convertToParams(params)}`
  )
  return response
}

export const deleteDetailsClassLinked = async (idProfessor, payload) => {
  const response = await httpPut(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.LINKED_SCHOOL_CLASS}/${ENDPOINT.PROFESSORS.DELETE}/${idProfessor}`,
    payload
  )
  return response
}

export const deleteDetailsClassReplacement = async (payload) => {
  const response = await httpDelete(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.SUBSTITUTION}/${ENDPOINT.PROFESSORS.LINK}/${ENDPOINT.PROFESSORS.SCHOOL_CLASS}`,
    payload
  )
  return response
}

export const editDetailsClassReplacement = async (idProfessor, payload) => {
  const response = await httpPut(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.SUBSTITUTE}/${ENDPOINT.PROFESSORS.SCHOOL_CLASSES_LINK}/${idProfessor}`,
    payload
  )
  return response
}

export const postClassReplacement = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.PROFESSORS.CRUD}/${ENDPOINT.PROFESSORS.SUBSTITUTION}/${ENDPOINT.PROFESSORS.SCHOOL_CLASS}`,
    payload
  )
  return response
}
