import { getUserFromLocalStorage } from 'services/helpers'

const onRequest = (config) => {
  const user = getUserFromLocalStorage()
  const token = user?.token
  const newConfig = { ...config }

  if (!newConfig?.headers?.Authorization) {
    newConfig.headers = {
      ...newConfig.headers,
      Authorization: `Bearer ${token}`
    }
  }

  return newConfig
}

const onRequestError = (error) => Promise.reject(error)
const onResponse = (response) => response

const onResponseError = (error) => {
  const { data, status } = error.response
  const HTTP_UNAUTHORIZED_MSG = 'Unauthorized'

  if (
    (data?.error === HTTP_UNAUTHORIZED_MSG || status === 401) &&
    window.location.pathname !== '/'
  ) {
    setTimeout(() => {
      window.location.href = '/logout'
    }, 2000)
  }

  return Promise.reject(error)
}

export function setupInterceptors(axiosInstance) {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, onResponseError)

  return axiosInstance
}
