const textNotFound =
  'Não encontramos nenhum registro financeiro no momento.<br/> Para mais informações, contate a secretaria da sua unidade.'
const textFilterNotFound =
  'Não encontramos registros financeiros como resultado desta busca.<br/> Tente aplicar outro critério de filtro.'

const MAX_VIEWPORT = 768
const defaultPage = 10
const TOTAL_ITEMS = 0
const DEFAULT_ITEMS = 1
const defaultPageActive = 1
const STATUS_ALL = 'all'
const ERROR_MESSAGE = 'Erro ao carregar dados do Financeiro, tente novamente...'

export {
  textNotFound,
  STATUS_ALL,
  TOTAL_ITEMS,
  DEFAULT_ITEMS,
  textFilterNotFound,
  defaultPage,
  defaultPageActive,
  MAX_VIEWPORT,
  ERROR_MESSAGE,
}
