import * as yup from 'yup'

const phoneRegExp = /^\(?([1-9]{2})\)?\s?9\d{4}-?\d{4}$/

const formSchema = (hasBirthplace, isBrazilian) =>
  yup.object().shape({
    uf_fin: yup.string().required('Campo obrigatório'),
    cep_fin: yup.string().required('Campo obrigatório'),
    civil_fin: yup.string().required('Campo obrigatório'),
    end_fin: yup.string().required('Campo obrigatório'),
    ...(hasBirthplace &&
      isBrazilian && {
        naturalidade_uf_fin: yup.string().required('Campo obrigatório')
      }),
    cidade_fin: yup.string().required('Campo obrigatório'),
    numero_fin: yup
      .number()
      .nullable()
      .typeError('Deve ser um número')
      .required('Campo obrigatório'),
    nacio_fin: yup.string().required('Campo obrigatório'),
    bairro_fin: yup.string().required('Campo obrigatório'),
    mobile_phone_fin: yup
      .string()
      .matches(phoneRegExp, 'Número de celular inválido')
      .required('Campo obrigatório'),
    email_fin: yup
      .string()
      .email('E-mail inválido.')
      .required('Campo obrigatório'),
    nome_fin: yup
      .string()
      .min(8, 'Nome inválido, deve ter 8 ou mais caracteres')
      .required('Campo obrigatório'),
    ...(isBrazilian && { rg_fin: yup.string().required('Campo obrigatório') }),
    cpf_fin: yup.string().required('Campo obrigatório'),
    sexo_fin: yup.string().required('Campo obrigatório'),
    nasc_fin: yup
      .date()
      .min(new Date(1900, 0, 1), 'A data deve ser posterior a 01/01/1900')
      .nullable()
      .required('Campo obrigatório')
      .typeError('Data inválida')
      .test('maioridade', 'É necessário ter mais de 18 anos', (value, ctx) => {
        if (value === null) return true
        const dob = new Date(value)
        const validDate = new Date()
        const valid = validDate.getFullYear() - dob.getFullYear() >= 18
        return !valid ? ctx.createError() : valid
      })
  })

export { formSchema }
