import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import { Badge } from '@mui/material'
import * as Styled from './style'

const IconUploadPhoto = ({ onClick, photo, avatarAdmin }) => (
  <Styled.WrapperButton
    onClick={onClick}
    component="span"
    size="large"
    color="inherit"
    variant="text"
    disabled={!avatarAdmin}
  >
    <Badge
      badgeContent={
        !photo && (
          <Styled.Box $avatarAdmin={avatarAdmin}>
            <AddAPhotoIcon />
          </Styled.Box>
        )
      }
    >
      <Styled.Avatar $avatarAdmin={avatarAdmin} src={photo} />
    </Badge>
  </Styled.WrapperButton>
)

export default IconUploadPhoto
