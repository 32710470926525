import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { isEmpty } from 'services/helpers'
import { ROUTE } from 'services/constants'
import { usePageType, useSnack } from 'services/hooks'

import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'

import { Form } from '../components'
import { breadcrumbItems } from './config'
import { formatValuesInitialSector } from './helpers'
import {
  createSector,
  getListResponsibleSector,
  updateSector
} from '../../services'

const Mutate = () => {
  const [payload, setPayload] = useState([])
  const [responsibles, setResponsibles] = useState([])
  const location = useLocation()

  const { isEdit, isNew } = usePageType()

  const snackProps = useSnack()
  const navigate = useNavigate()

  const sector = formatValuesInitialSector(location?.state?.sector ?? {})

  const handlePayload = (value) => {
    setPayload(value)
  }

  const handleCreate = async (values) => {
    createSector({
      values,
      navigate,
      setSnack: snackProps.setSnack
    })
  }

  const handleUpdate = (values) => {
    updateSector({
      id: values?.id,
      payload: values,
      setSnack: snackProps.setSnack,
      navigate
    })
  }

  useEffect(() => {
    if (isEmpty(payload)) return

    if (isNew) handleCreate(payload)
    if (isEdit) handleUpdate(payload)
  }, [payload])

  useEffect(() => {
    getListResponsibleSector({
      setSnack: snackProps.setSnack,
      setResponsibles,
      unityId: sector?.unityId ?? 0
    })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      breadcrumbsItems={[...breadcrumbItems(isEdit, sector?.sectorName)]}
      classNameHeader="goBack"
      content={
        <Form
          sector={sector}
          responsibles={responsibles}
          isEdit={isEdit}
          handlePayload={handlePayload}
        />
      }
      labelCallToAction="voltar"
      linkHeader={ROUTE.sectors.list}
      snackProps={snackProps}
    />
  )
}

export default Mutate
