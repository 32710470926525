import { TemplateStudentMyScores as Template } from 'components/templates/Student'
import { Snackbar } from 'components/molecules'
import { useSnack } from 'services/hooks'
import { PageTitle } from 'components/atoms'
import ContentMyScores from './components/ContentMyScores'

const MyScores = () => {
  const { snack, setSnack, snackOpen, updateSnack } = useSnack()

  return (
    <Template
      titleHeader={<PageTitle title="Minhas Notas" divider />}
      content={
        <ContentMyScores setSnack={setSnack} updateSnack={updateSnack} />
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default MyScores
