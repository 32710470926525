import * as yup from 'yup'

const linkedClassDetailsEditFormSchemaBase = yup.object().shape({
  id: yup.number().required('Id da disciplina é requerido'),
  hourlyLessonCost: yup.string().required('Valor é requirido')
})

export const validateLinkedClassDetailsEditForm = (values) => {
  const errors = { links: {} }

  values.links.forEach((link) => {
    try {
      linkedClassDetailsEditFormSchemaBase.validateSync(link, {
        abortEarly: false
      })
    } catch (error) {
      const linksErrors = {}
      error.inner.forEach((validationError) => {
        const { path } = validationError.params
        linksErrors[path] = validationError.message
      })
      errors.links[link.id] = linksErrors
    }
  })
  return Object.keys(errors.links).length ? errors : null
}
