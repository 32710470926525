import { useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useSnack } from 'services/hooks'
import FormTeachingPlan from './Form'

const CreateByUpdateTeachingPlan = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const pageEdit = params?.discipline
  const snackProps = useSnack()
  const navigate = useNavigate()

  const { setSnack } = snackProps

  const breadCrumbsItems = [
    {
      path: '',
      label: 'Gestão'
    },
    {
      path: '',
      label: 'Disciplinas'
    },
    {
      path: '',
      label: `<b>${pageEdit || 'Competências Digitais'}</b>`
    }
  ]

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      breadcrumbsItems={breadCrumbsItems}
      classNameHeader="goBack"
      onClickButton={() => {
        navigate(ROUTE.ADMIN_MANAGEMENT_SUBJECTS, {
          state: { savedSearch: true }
        })
      }}
      content={<FormTeachingPlan setSnack={setSnack} />}
    />
  )
}

export default CreateByUpdateTeachingPlan
