const MESSAGE = {
  NO_SEARCH:
    '<b>Encontre os cursos disponíveis aqui</b><br/>Você precisa selecionar ao menos a unidade e o nível para listar os cursos disponíveis.<br/><br/>Para ser mais específico, pode informar o Curso, Turno e Modalidade.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  ERROR_BRINGING_UNITS: 'Erro ao carregar unidades'
}

const initialState = {
  unity: '',
  type: '',
  level: '',
  course: '',
  shift: '',
  checked: false,
  inactive: false
}

const listOptions = [
  { value: 'Item 1', label: 'Item 1' },
  { value: 'Item 2', label: 'Item 2' }
]

const defaultTotalPage = 10

const DEFAULT_ITEMS_PER_PAGE = 10
const DEFAULT_PAGE = 1
const isEnrollment = 1

export const defaultItemsPerPage = 10
export const enrollmentGroup = 'enrollmentGroup'
export {
  MESSAGE,
  listOptions,
  initialState,
  isEnrollment,
  DEFAULT_PAGE,
  defaultTotalPage,
  DEFAULT_ITEMS_PER_PAGE
}
