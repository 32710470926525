import { ROUTE } from 'services/constants'

const isUF = true
const defaultPage = 1
const defaultItemsPerPage = 200

const intialPesonalData = {
  name: '',
  social_name: '',
  cpf: '',
  rg: '',
  inep: '',
  nis: '',
  document_issue: '',
  email: '',
  birth_date: '',
  birth_city: '',
  birth_uf: '',
  mother_name: '',
  father_name: '',
  marital_status: '',
  admission_date: '',
  phone_number: '',
  cell_number: '',
  regional: '',
  zip_code: '',
  state: '',
  city: '',
  street: '',
  number: '',
  complement_address: '',
  neighborhood: ''
}

const initialAcademicEducation = {
  level: '',
  course_name: '',
  institution_name: '',
  conclusion_year: ''
}

const breadCrumbsItems = (isEdit) => [
  {
    path: '',
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: 'Professores'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: `<b>${isEdit ? 'Editar' : 'Novo'} Professor</b>`
  }
]

const MESSAGE = {
  UPLOAD_PHOTO_SUCCESS: 'Foto atualizada com sucesso!',
  CREATE_SUCCESS: 'Professor(a) criado(a) com sucesso!',
  UPDATE_SUCCESS: 'Professor(a) atualizado(a) com sucesso!',
  CREATE_SKILL_SUCCESS: 'Formação acadêmica criada com sucesso!',
  DELETE_SKILL_SUCCESS: 'Formação acadêmica deletada com sucesso!',
  UPDATE_SKILL_SUCCESS: 'Formação acadêmica atualizada com sucesso!',
  NOT_CLASS_LINKED:
    'Ainda não há nenhuma turma vinculada.<br /> Crie um novo vínculo clicando no botão acima.',
  NOT_REPLACEMENT:
    'Ainda não há nenhuma substituição.<br /> Crie uma nova clicando no botão acima.',
  NOT_ACADEMIC_EDUCATION:
    'Nenhuma formação acadêmica encontrada, adicione uma nova <br/> preenchendo os campos acima.'
}

export {
  defaultPage,
  intialPesonalData,
  defaultItemsPerPage,
  initialAcademicEducation,
  breadCrumbsItems,
  MESSAGE,
  isUF
}
