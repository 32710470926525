import styled from 'styled-components'
import { TableCell as MuiTableCell } from '@mui/material'

const Scores = styled(MuiTableCell)`
  &.MuiTableCell-root {
    padding: 8px;
    width: 15%;
    &[data-alert='true'] {
      background-color: ${({ theme }) => theme.palette.yellow.main};
    }
    div {
      min-width: 40px;
    }
  }
`

const Register = styled(MuiTableCell)`
  &.MuiTableCell-root {
    max-width: 30px;
    text-align: center;
  }
`

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    width: ${(props) => props.$fieldSize};
    text-align: ${({ $fiedlName }) => ($fiedlName ? 'left' : 'center')};
  }
`

const TextStudent = styled.div`
  display: flex;
  flex-direction: column;
`

const TextDropouts = styled.span`
  background-color: ${({ theme }) => theme.palette.red.light};
  padding: 2.5px 10px;
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.primary.white};
  width: fit-content;
`

export { Scores, Register, TableCell, TextStudent, TextDropouts }
