import { ROUTE } from 'services/constants'

const defaultPage = 1
const defaultTotalPage = 10
const defaultItemsPerPage = 10
const replacementType = 'permanent'

const initialValues = {
  endDate: '',
  startDate: '',
  reasonReplacement: '',
  replacement: 'permanent'
}

const MESSAGE = {
  success: 'Turma substituída com sucesso.'
}

const breadCrumbsItems = ({ isEdit, name, id }) => [
  {
    path: '',
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR,
    label: `${name}`
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS,
    label: `<b>${
      isEdit === 'true' ? 'Editar substituição' : 'Substituir turma'
    } </b>`
  },
  {
    path: ROUTE.ADMIN_HR_PROFESSOR_REPLACE_CLASS_DETAILS,
    label: `<b>Turma ${id}</b>`
  }
]

export {
  MESSAGE,
  defaultPage,
  initialValues,
  replacementType,
  breadCrumbsItems,
  defaultTotalPage,
  defaultItemsPerPage
}
