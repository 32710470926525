import { useState, useEffect, lazy } from 'react'
import { useNavigate } from 'react-router-dom'
import { Pagination } from '@mui/material'
import { useUserStore } from 'store/GlobalContext'
import { useGetProfessor, useSnack } from 'services/hooks'
import {
  getVisibleUpdatePassword,
  IsEmpty,
  setVisibleUpdatePassword
} from 'services/helpers'
import { LoadingPage, Snackbar } from 'components/molecules'
import { FilterClasses, Tabs } from 'components/organisms'
import { PageTitle } from 'components/atoms'
import { TemplateClasses as Template } from 'components/templates/Professor'
import { ROUTE } from 'services/constants'
import {
  classTypes,
  defaultClassType,
  defaultActivePage,
  defaultParams,
  defaultTotalPage
} from './constants'
import * as Styled from './style'

const Classrooms = lazy(() => import('./components/Classrooms'))

const ClassesProfessor = () => {
  const navigate = useNavigate()
  const [loadingVisibility, setLoadingVisibility] = useState(true)
  const [classType, setclassType] = useState(defaultClassType)
  const [activePage, setActivePage] = useState(defaultActivePage)
  const [filterParams, setFilterParams] = useState(defaultParams)
  const isVisibleUpdatePassword = getVisibleUpdatePassword()
  const { username: professorId, first_access: firstAccess } = useUserStore()
  const { snack, setSnack, snackOpen, updateSnack } = useSnack()

  const setFilter = ({ params }) => {
    setLoadingVisibility(true)
    setFilterParams({ ...params })
  }

  const setPage = (event, page) => {
    setLoadingVisibility(true)
    setActivePage(+page)
  }

  const getClassTypeByLabel = (tabs, navigation) =>
    tabs.find((tab) => tab.label === navigation)

  const setActiveClassType = ({ navigation }) => {
    const { value: activeClassType } = getClassTypeByLabel(
      classTypes,
      navigation
    )

    if (classType === activeClassType) return
    setclassType(activeClassType)
    setActivePage(defaultActivePage)
    setLoadingVisibility(true)
  }

  const state = {
    professorId,
    setLoadingVisibility,
    classType,
    activePage,
    filterParams,
    updateSnack,
    setSnack
  }

  const { classList, totalPages } = useGetProfessor(state)

  const setNavigationList = (classTypesList) =>
    classTypesList.map((classTypeItem) => classTypeItem.label)

  const setTabContent = (classTypesList) =>
    classTypesList.map(({ value }) => (
      <Styled.TabContent key={value}>
        <Classrooms classList={classList} navegationSelection={classType} />
      </Styled.TabContent>
    ))

  const tabsContent = setTabContent(classTypes)
  const navigationList = setNavigationList(classTypes)
  useEffect(() => {
    if (firstAccess && isVisibleUpdatePassword) {
      setVisibleUpdatePassword(false)
      navigate(ROUTE.PROFESSOR_UPDATE_PASSWORD)
    }
  }, [firstAccess, isVisibleUpdatePassword])

  return (
    <Template
      titleHeader={<PageTitle title="Disciplinas" />}
      content={
        <>
          <FilterClasses onSubmit={setFilter} />
          <Tabs
            contents={tabsContent}
            navigations={navigationList}
            onClickTab={setActiveClassType}
          />

          {!IsEmpty(classList) && totalPages > defaultTotalPage && (
            <Styled.PaginationWrapper>
              <Pagination
                count={totalPages}
                page={activePage}
                onChange={setPage}
              />
            </Styled.PaginationWrapper>
          )}

          <LoadingPage open={loadingVisibility} />
        </>
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default ClassesProfessor
