import React, { lazy } from 'react'
import { TemplateNotifications as Template } from 'components/templates/Shared'
import { PageTitle } from 'components/atoms'
import { PaginationAdvanced } from 'components/molecules'
import { paginationFilter } from 'services/helpers'
import { defaultItems, defaultPage } from '../../constants'

const ListNotifications = lazy(() => import('../ListNotifications'))

const PageNotification = ({
  notification,
  handleConfirmModal,
  onHandleReadMessage,
  handleSetPage,
  count,
  filter,
  page,
  totalPage,
  setFilter,
  setPage
}) => (
  <Template
    titleHeader={<PageTitle title="Notificações" divider />}
    content={
      <>
        <ListNotifications
          notification={notification}
          handleConfirmModal={handleConfirmModal}
          onHandleReadMessage={onHandleReadMessage}
        />

        {count > defaultItems && (
          <PaginationAdvanced
            smallSize
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={page}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setFilter(e.target.value)
              setPage(defaultPage)
            }}
          />
        )}
      </>
    }
  />
)

export default PageNotification
