const { ROUTE } = require('services/constants')

const breadcrumbsItems = ({ studentName, isEdit }) => [
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER,
    label: 'Alunos'
  },

  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER,
    label: `${studentName}`
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER,
    label: 'Expedição'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_REGISTER_EXPEDITION,
    label: `<b>${isEdit === 'true' ? 'Editar' : 'Novo'} Registro</b>`
  }
]

const MESSAGE = {
  NO_SEARCH: 'Não encontramos nenhum documento nos registros desta ficha.',
  SUCCESS: 'Dados de expedição salvos com sucesso.'
}

const messageModalConfirm = {
  title: 'Cancelar operação',
  message:
    'Deseja cancelar a operação de cadastro? Os dados manipulados até aqui serão perdidos.'
}

const initialState = {
  sistec: '',
  course_id: '',
  page_number: '',
  book_number: '',
  sistec_date: '',
  certification: '',
  completion_date: '',
  registration_date: '',
  registration_number: '',
  sistec_register_date: ''
}

export { breadcrumbsItems, MESSAGE, messageModalConfirm, initialState }
