import { TextField } from 'components/molecules'
import { InputCheckbox, InputQuill } from 'components/atoms'
import * as Styled from './style'

const FormContractsTypes = ({ formik }) => (
  <>
    <Styled.Grid item xs={12} sm={12} md={9}>
      <TextField
        fullWidth
        id="name"
        name="name"
        isMargin="0"
        value={formik.values?.name}
        label="Título do contrato*"
        onBlur={formik.handleBlur}
        error={formik.touched.name && formik.errors.name}
        helperText={formik.touched.name && formik.errors.name}
        onChange={(e) => formik.setFieldValue('name', e.target.value)}
      />
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={3}>
      <Styled.Checkbox>
        <InputCheckbox
          label="Assinatura eletrônica"
          checked={formik.values.signature}
          handleChange={(e) =>
            formik.setFieldValue('signature', e.target.checked)
          }
        />
      </Styled.Checkbox>
    </Styled.Grid>
    <Styled.Grid item xs={12} sm={12} md={12}>
      <InputQuill
        height="400px"
        id="description"
        label="Título do contrato*"
        values={formik.values?.description || ''}
        error={formik.touched.description && formik.errors.description}
        helperText={formik.touched.description && formik.errors.description}
        handleChange={(value) => formik.setFieldValue('description', value)}
      />
    </Styled.Grid>
  </>
)

export default FormContractsTypes
