import styled, { css } from 'styled-components'
import { situations } from './contants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 27px;
  align-items: center;
`

const Card = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 25px 100px;
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.palette.green.main};
  color: ${({ theme }) => theme.palette.primary.white};

  ${({ situation }) =>
    situation === situations.irregular &&
    css`
      background-color: ${({ theme }) => theme.palette.red.light};
    `}

  .icon {
    width: 80px;
    height: 80px;
  }
`

const Texts = styled.div`
  display: flex;
  flex-direction: column;
`

const TypeSituation = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
`

const Situation = styled(TypeSituation)`
  font-size: 48px;
  text-transform: uppercase;
`

const FeedbackMessage = styled(TypeSituation)`
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: ${({ theme }) => theme.palette.dark.light};
`

export { Container, Card, Texts, TypeSituation, Situation, FeedbackMessage }
