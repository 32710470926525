import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2, Select } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import { initialValues, optionTypeFile } from './constants'
import * as Styled from './style'
import { getExportFiles } from '../../../services'

const ModalExportFiles = ({
  handleClose,
  open,
  typeModal,
  setSnack,
  setLoadingOpen,
  id
}) => (
  <Styled.Modal open={open} onClose={handleClose}>
    <Formik
      initialValues={initialValues}
      onSubmit={(values) =>
        getExportFiles({
          setSnack,
          setLoadingOpen,
          values,
          id,
          handleClose,
          typeModal
        })
      }
      enableReinitialize
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Styled.Grid>
            <Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12}>
                <Styled.Title variant="h5" component="h2">
                  {typeModal === 'grades'
                    ? 'Mapa de Notas'
                    : 'Ata de Resultados Finais'}
                </Styled.Title>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">
                  Escolha o período para as
                  {typeModal === 'grades' ? ' notas ' : ' atas de resultados '}
                  que você deseja gerar:
                </Typography>
              </Grid>

              <Grid item xs={5}>
                <DatePicker
                  fullWidth
                  id="startDate"
                  label="De (opcional)"
                  value={values?.startDate || null}
                  onChange={(newValue) => {
                    setFieldValue('startDate', newValue)
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <DatePicker
                  fullWidth
                  id="endDate"
                  label="Até (opcional)"
                  value={values?.endDate || null}
                  onChange={(newValue) => {
                    setFieldValue('endDate', newValue)
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <Select
                  id="typeFile"
                  label="Formato"
                  value={values?.typeFile || ''}
                  optionsList={optionTypeFile}
                  onChange={(e) => setFieldValue('typeFile', e.target.value)}
                />
              </Grid>
            </Grid>

            <Styled.Box>
              <ButtonFooterV2
                labelClose="Cancelar"
                labelConfirm="Confirmar"
                classNameConfirm={
                  typeModal === 'grades'
                    ? 'academico_btn_baixa_Notas'
                    : 'academico_btn_baixa_ataResultados'
                }
                size="medium"
                onClickClose={handleClose}
              />
            </Styled.Box>
          </Styled.Grid>
        </Form>
      )}
    </Formik>
  </Styled.Modal>
)

export { ModalExportFiles }
