import styled from 'styled-components'
import { MenuItem as MuiMenuItem } from '@mui/material'

const MenuItem = styled(MuiMenuItem)`
  &.MuiButtonBase-root {
    padding: 8px 21px;

    &:last-child {
      padding-bottom: 17px;
    }

    &:first-child {
      padding-top: 17px;
    }

    svg {
      color: ${({ theme }) => theme.palette.dark.medium};
      margin-right: 14px;
    }
  }
`

export { MenuItem }
