import { ROUTE } from 'services/constants'

const breadcrumbsItems = (
  hasCreateRequisition,
  isRequirementId,
  isTypeRequirement
) => [
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_OCCURRENCES,
    label: 'Requerimentos'
  },
  {
    path: hasCreateRequisition
      ? ROUTE.ADMIN_SECRETARY_REQUISITION_CREATE
      : ROUTE.ADMIN_SECRETARY_REQUISITION_EDIT,
    label: hasCreateRequisition
      ? '<b>Nova Matriz</b>'
      : `<b> <b>[${isRequirementId}] ${isTypeRequirement}</b>`
  }
]

const isDisabledButton = (values) => {
  const { serviceSector, description, situation } = values

  const disabled = !serviceSector || !description || !situation

  return disabled
}

export { isDisabledButton, breadcrumbsItems }
