import { useSearchParams } from 'react-router-dom'
import { TableCell, TableRow, Typography } from '@mui/material'
import { dateFormat, DATE_FORMAT } from 'services/helpers'
import { headCells, VALUE_TAB_ID, VALUE_WIDTH_TAB_ID } from './constants'
import * as Styled from './style'
import { NOVOTECH } from '../constants'

const TableHeader = ({
  activity,
  titleButton,
  startIcon,
  handleEditActivity,
  handleDeleteActivity,
  handleCreateActivity,
  dropouts,
  classButtonRemove,
  classButtonAdd,
  classButtonNew
}) => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { unit, isProfessor } = params

  return (
    <Styled.TableHead $dropouts={!dropouts}>
      <TableRow>
        {headCells &&
          headCells.map((item) => (
            <Styled.TableCell
              key={item.id}
              align="center"
              width={item?.label === VALUE_TAB_ID ? VALUE_WIDTH_TAB_ID : ''}
            >
              {item.label}
            </Styled.TableCell>
          ))}
        {activity &&
          activity.map((item) => (
            <Styled.BoxTableCell
              key={item.id}
              align="center"
              padding={item.disablePadding ? 'normal' : 'none'}
            >
              <Styled.Title>
                <Styled.BoxActivity>
                  <Typography variant="p">
                    {Number(unit) === NOVOTECH ? item.description : item.name}
                  </Typography>
                  <Typography variant="p">
                    {dateFormat(item.done_at, DATE_FORMAT.DD_MM_YYYY)}
                  </Typography>
                </Styled.BoxActivity>
                <Styled.BoxIcons>
                  <Styled.IconButton
                    className={classButtonAdd}
                    onClick={() => {
                      handleEditActivity(item)
                    }}
                  >
                    <Styled.EditIcon />
                  </Styled.IconButton>
                  <Styled.IconButton
                    className={classButtonRemove}
                    onClick={() => handleDeleteActivity(item)}
                  >
                    <Styled.DeleteIcon />
                  </Styled.IconButton>
                </Styled.BoxIcons>
              </Styled.Title>
            </Styled.BoxTableCell>
          ))}
        {!(isProfessor && Number(unit)) && (
          <TableCell>
            <Styled.Button
              className={classButtonNew}
              onClick={handleCreateActivity}
              variant="contained"
              color="primary"
            >
              {startIcon}
              <p>{titleButton}</p>
            </Styled.Button>
          </TableCell>
        )}
      </TableRow>
    </Styled.TableHead>
  )
}

export default TableHeader
