import styled from 'styled-components'

import { Button as AtomButton } from 'components/atoms'

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 0 24px 0;
`

const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin: 0;
  }
`

export { AlignRight, ActionsContainer }
