const DEFAULT_ITEMS_PER_PAGE = 10
const DEFAULT_PAGE = 1

const defaultValues = {
  inactive: false,
  sectorName: '',
  unityId: ''
}

const makeServiceHookParams = {
  unitsValue: true,
  isUnits: true
}

export {
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  defaultValues,
  makeServiceHookParams
}
