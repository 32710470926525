import { SYSTEM } from './system'
import { FINANCIAL } from './financial'
import { SECRETARY } from './secretary'
import { COMMERCIAL } from './commercial'
import { MANAGEMENT } from './management'
import { HUMAN_RESOURCES } from './HumanResources'

export const ADMIN_TAG_MANAGER = {
  ...SYSTEM,
  ...SECRETARY,
  ...MANAGEMENT,
  ...HUMAN_RESOURCES,
  ...FINANCIAL,
  ...MANAGEMENT,
  ...COMMERCIAL,
  ...MANAGEMENT,
  ...HUMAN_RESOURCES,
  ...FINANCIAL,
  ...MANAGEMENT,
  ...COMMERCIAL
}
