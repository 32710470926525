import { createContext, useContext, useState } from 'react'
import { toastNotificationSuccess } from 'services/helpers'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { getAuthTokenToChangeCard, getStudentPaymentMethods } from '../services'

const initialState = {
  studentPaymentMethods: [],
  loadStudentPaymentMethods: ({ register }) => {},
  openCheckoutToChangeCreditcard: ({
    buttonRef,
    contractId,
    register,
    getnetSubscriptionId
  }) => {},
  changeCreditCardInfo: {
    bankAccount: null,
    getnetClientId: null,
    getnetClientSecret: null,
    getnetSellerId: null,
    register: null,
    token: null,
    getnetSubscriptionId: null
  },
  clearChangeCreditCardInfo: () => {}
}
const StudentFinancialContext = createContext(initialState)

export function StudentFinancialProvider({ children }) {
  const [studentPaymentMethods, setStudentPaymentMethods] = useState(
    initialState.studentPaymentMethods
  )
  const [changeCreditCardInfo, setChangeCreditCardInfo] = useState(
    initialState.changeCreditCardInfo
  )

  const { setLoading } = useLoading()

  const clearChangeCreditCardInfo = () =>
    setChangeCreditCardInfo(initialState.changeCreditCardInfo)

  const loadStudentPaymentMethods = async ({ register }) => {
    setLoading(true)
    const result = await getStudentPaymentMethods({
      register,
      onError: () => setLoading(false)
    })

    if (result) setStudentPaymentMethods(result)

    setLoading(false)
  }
  const buildIframeToCheckout = ({
    contractId,
    authContent,
    getnetSubscriptionId
  }) => {
    const isEnvProd = process.env.REACT_APP_ENV === 'prod'
    const urlHomolog = process.env.REACT_APP_GETNET_CHECKOUT_HOMOLOG
    const urlProd = process.env.REACT_APP_GETNET_CHECKOUT_PROD

    const src = isEnvProd ? urlProd : urlHomolog

    const script = document.createElement('script')
    script.async = true
    script.src = src
    script.dataset.getnetSellerid = authContent.getnetSellerId
    script.dataset.getnetToken = authContent.token
    script.dataset.getnetCustomerid = authContent.register
    script.dataset.getnetButtonClass = `change-credit-card-button-getnet-${contractId}`
    // script.dataset.getnetUrlCallback = 'https://ecommerce.com.br/sucesso'
    script.dataset.getnetRecurrency = 'true'
    script.dataset.getnetRecurrencySubscriptionid = getnetSubscriptionId
    script.dataset.getnetUpdateCard = true

    script.addEventListener('load', (event) => {
      const eventMethod = window.addEventListener
        ? 'addEventListener'
        : 'attachEvent'
      const eventer = window[eventMethod]
      const messageEvent =
        eventMethod === 'attachEvent' ? 'onmessage' : 'message'

      // Ouvindo o evento do loader
      eventer(
        messageEvent,
        (e) => {
          const data = e.data || ''

          switch (data.status || data) {
            // Corfirmação positiva do checkout.
            case 'success':
              toastNotificationSuccess('Troca de cartão efetivada', 5000)
              break

            // Notificação de que o IFrame de checkout foi fechado a aprovação.
            case 'close':
              clearChangeCreditCardInfo()
              script?.parentNode?.removeChild(script)
              break

            // Notificação que um boleto foi registrado com sucesso
            case 'pending':
              // console.log(data.detail)
              break

            // Notificação que houve um erro na tentativa de pagamento
            case 'error':
              console.warn(data.detail.message)
              break

            // Ignora qualquer outra mensagem
            default:
              break
          }
        },
        false
      )
    })

    return script
  }

  const openCheckoutToChangeCreditcard = async ({
    buttonRef,
    contractId,
    register,
    getnetSubscriptionId
  }) => {
    if (!buttonRef.current) return

    setLoading(true)

    const authContent = await getAuthTokenToChangeCard({
      contractId,
      register,
      onError: () => setLoading(false)
    })

    let iframeScript
    if (authContent) {
      setChangeCreditCardInfo({ ...authContent, getnetSubscriptionId })
      iframeScript = buildIframeToCheckout({
        contractId,
        authContent,
        getnetSubscriptionId
      })
    }

    document.body.appendChild(iframeScript)

    iframeScript.onload = () => {
      buttonRef.current.click()
      setLoading(false)
    }
  }

  return (
    <StudentFinancialContext.Provider
      value={{
        studentPaymentMethods,
        loadStudentPaymentMethods,
        openCheckoutToChangeCreditcard,
        changeCreditCardInfo,
        clearChangeCreditCardInfo
      }}
    >
      {children}
    </StudentFinancialContext.Provider>
  )
}

export const useStudentFinancialContext = () =>
  useContext(StudentFinancialContext)
