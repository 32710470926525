import { createContext, useContext, useState } from 'react'

const initialState = []

export const ListUsersContext = createContext()

export const ListUnifyUsersProvider = ({ children }) => {
  const [listUnifyUsers, setListUnifyUsers] = useState(initialState)

  const removeUsersListUnifyById = (registerToRemove) => {
    setListUnifyUsers((prevList) =>
      prevList.filter((user) => user.register !== registerToRemove)
    )
  }

  const resetListUnify = () => {
    setListUnifyUsers(initialState)
  }

  return (
    <ListUsersContext.Provider
      value={{
        listUnifyUsers,
        setListUnifyUsers,
        removeUsersListUnifyById,
        resetListUnify
      }}
    >
      {children}
    </ListUsersContext.Provider>
  )
}

export const useListUsersContext = () => useContext(ListUsersContext)
