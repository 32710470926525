import * as yup from 'yup'

import { formatDateEnv } from 'services/helpers'
import { getBimesterMap } from './helpers'

const isMatchingBimester = ({ value, context, isEnd = false, unit }) =>
  Object.values(getBimesterMap(unit)).some((yearBimesters) =>
    Object.values(yearBimesters).some((bimester) => {
      const { startDate } = context.parent
      const valueFormatted = formatDateEnv(value)
      const startDateFormatted = formatDateEnv(startDate)
      const matchBegin = !isEnd && bimester.begin === startDateFormatted
      const matchEnd = isEnd && bimester.end === valueFormatted
      return matchBegin || matchEnd
    })
  )

export default function getSchema({ isNovotecFrom2024, unit }) {
  const schema = yup.object().shape({
    typeFile: yup.string(),
    startDate: !isNovotecFrom2024
      ? yup.date()
      : yup
          .date()
          .required('Data de início do bimestre é obrigatório')
          .test({
            name: 'match-to-some-beginning-of-the-bimester',
            message:
              'A data preenchida não corresponde ao início de um bimestre.',
            test: (value, context) =>
              isMatchingBimester({ value, context, unit })
          }),
    endDate: !isNovotecFrom2024
      ? yup.date()
      : yup
          .date()
          .required('Data de fim do bimestre é obrigatório')
          .test({
            name: 'is-after-start',
            message: 'A data de fim deve ser posterior à data de início',
            test: (value, context) => value > context.parent?.startDate
          })
          .test({
            message: 'A data preenchida não corresponde ao fim de um bimestre.',
            name: 'match-to-the-end-date-of-the-chosen-bimester',
            test: (value, context) =>
              isMatchingBimester({ value, context, unit, isEnd: true })
          })
  })

  return schema
}
