import { format } from 'date-fns'

export const getBodyToRequest = (values) => {
  const body = {
    rg: values.rg,
    cpf: values.cpf,
    cep: values.cep,
    state: values.uf,
    name: values.nome,
    pole: values.polo,
    gender: values.sexo,
    father_name: values.pai,
    mother_name: values.mae,
    civil_status: values.civil,
    contact_time: values.contato,
    birth_place_uf: values.naturalidade,
    birth_place_city: values.naturalidade_cidade,

    social_name: values.nome_social,
    complement: values.complemento,
    birthdate:
      values.nascimento !== ''
        ? format(new Date(values.nascimento), 'dd/MM/yyyy')
        : '',
    nationality: values.nacionalidade,
    number: values.numero.toString(),
    module: values.modulo,
    group: values.grupo,
    address: values.endereco,
    shift: values.turno,
    phone: values.telefone,
    email: values.email,
    mobile_phone: values.celular,
    unit: values.unidade,
    level: values.level,
    course: values.curso,
    city: values.cidade,
    district: values.bairro,
    modality_id: values.ead ? 3 : 1
  }
  return body
}
