import React, { lazy } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ArrowBack, NavigateNext } from '@mui/icons-material'
import { TemplateFinancialBreakdown as Template } from 'components/templates/Admin'
import { Snackbar } from 'components/molecules'
import { HeaderPage } from 'components/organisms'
import { useSnack } from 'services/hooks'
import { ROUTE } from 'services/constants'
import { ToastComponent } from 'components/atoms'
import { convertToParams } from 'services/helpers'
import { useFinancialBreakdown } from './Context/FormContext'
import { breadcrumbsItems, initialState } from './constants'
import * as Styled from './style'

const Content = lazy(() => import('./components/Content'))

const FinancialBreakdown = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { snack, setSnack, snackOpen } = useSnack()
  const navigate = useNavigate()

  const { setFormValues, resetFormValues } = useFinancialBreakdown()

  const handlGoBack = () => {
    setFormValues(initialState)
    resetFormValues()
    if (params.hasPage) navigate(-1)
    else
      navigate(ROUTE.ADMIN_FINANCIAL_BILLS_RECEIVE, {
        state: { savedSearch: true }
      })
  }
  return (
    <Template
      header={
        <HeaderPage
          separator={<NavigateNext size="small" />}
          breadcrumbsNotLink={breadcrumbsItems}
          classNameCallToAction="goBack"
          callToAction={handlGoBack}
          startIcon={<ArrowBack />}
          labelCallToAction="voltar"
          divider
        >
          <Styled.BoxLink>
            <Styled.Link
              to={`${ROUTE.ADMIN_FINANCIAL_BREAKDOWN}?${convertToParams({
                register: params.register,
                name: params.name
              })}`}
            >
              {`${params.register} - ${params.name}`}
            </Styled.Link>
          </Styled.BoxLink>
        </HeaderPage>
      }
      content={
        <Content
          setSnack={setSnack}
          nameStudent={params.name}
          registerId={params.register}
          isCnpj={params.isCnpj}
          snackOpen={snackOpen}
        />
      }
      snackAlert={
        <>
          <Snackbar
            message={snack.message}
            severity={snack.severity}
            shouldOpen={snackOpen}
          />

          <ToastComponent />
        </>
      }
    />
  )
}

export default FinancialBreakdown
