import { lazy, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ModalConfirm } from 'components/molecules'
import { ROUTE } from 'services/constants'
import { useMakeService, useSnack } from 'services/hooks'
import * as Styled from './style'
import { breadCrumbsItems, initialValues } from './constants'
import { handleGetEdit } from '../services'

const ModalExample = lazy(() => import('./components/ModalFillingExample'))
const Tabs = lazy(() => import('./components/Tabs'))

const CreateByUpdateRequirements = () => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const pageEdit = params?.discipline
  const isEdit = params?.isEdit
  const idRequirement = params?.requerimentId
  const snackProps = useSnack()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [isChangedForm, setIsChangedForm] = useState(false)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [openModalExample, setOpenModalExample] = useState(false)
  const [initialState, setInitialState] = useState(initialValues)

  const { setSnack } = snackProps

  const paramsMakeService = {
    isUnits: true,
    isLevel: true,
    isAccount: true,
    setSnack
  }
  const { unitys, level, accounts } = useMakeService(paramsMakeService)

  const redirect = () =>
    navigate(ROUTE.ADMIN_MANAGEMENT_TYPE_REQUIREMENTS, {
      state: { savedSearch: true }
    })

  const onHandleConfirm = () => {
    if (!isChangedForm) {
      return redirect()
    }

    return setOpenModal(true)
  }

  useEffect(() => {
    if (isEdit) {
      handleGetEdit({
        idRequirement,
        setSnack,
        setLoadingOpen,
        setInitialState,
        unitys
      })
    }
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      loadingOpen={loadingOpen}
      breadcrumbsItems={breadCrumbsItems(pageEdit)}
      classNameHeader="goBack"
      onClickButton={() => onHandleConfirm()}
      content={
        <>
          <Tabs
            setOpenModal={setOpenModal}
            setOpenModalExample={setOpenModalExample}
            setSnack={setSnack}
            isEdit={isEdit}
            setIsChangedForm={setIsChangedForm}
            idRequirement={idRequirement}
            initialState={initialState}
            unitys={unitys}
            onHandleConfirm={onHandleConfirm}
            accounts={accounts}
            level={level}
          />

          <ModalExample
            open={openModalExample}
            setOpenModalExample={setOpenModalExample}
            handleClose={() => setOpenModalExample(!openModalExample)}
          />

          <ModalConfirm
            open={openModal}
            size="medium"
            handleClose={() => setOpenModal(false)}
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            onClickNotConfirm={() => setOpenModal(false)}
            onClickConfirm={() => redirect()}
            colorButtonConfirm="error"
            icon={<Styled.IconWarningAmber />}
            title="Cancelar"
            message="Deseja cancelar a operação?
            Você perderá as alterações que ainda não foram salvas."
          />
        </>
      }
    />
  )
}

export default CreateByUpdateRequirements
