import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { TabView } from 'components/organisms'
import { ROUTE } from 'services/constants'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { getHistoric, getListRequirementsById } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TABS } from './config'
import { breadcrumbsItems } from './helpers'
import { countDefault, defaultItemsPerPage } from './constants'

const EditRequisition = () => {
  const [searchParams] = useSearchParams()
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const location = useLocation()
  const params = Object.fromEntries([...searchParams])
  const {
    requirementId: isRequirementId,
    type: isTypeRequirement,
    tab
  } = params
  const hasCreateRequisition = location?.state?.newRequisition
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [requirement, setRequirement] = useState(false)
  const [requirementHistoric, setRequirementHistoric] = useState([])
  const [listSector, setListSector] = useState([])
  const [isActiveSector, setIsActiveSector] = useState(true)
  const [listResponsible, setListResponsible] = useState([])
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [shouldUpdateList, setShouldUpdateList] = useState(true)

  const { page, totalPage, items, setPage } = usePaginationBasic(
    requirementHistoric,
    filter,
    count
  )

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(ROUTE.ADMIN_SECRETARY_REQUISITION, {
      state: { savedSearch: true }
    })
  }

  const handleRequirimentId = async () => {
    setSnack('')
    setLoading(true)
    const {
      data: response,
      error,
      status
    } = await getListRequirementsById(Number(isRequirementId))

    setRequirement(response)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListRequisitionID
      })
    }

    return setLoading(false)
  }

  const handleHistoric = async () => {
    setSnack('')
    setLoading(true)
    const { data, error, status } = await getHistoric({
      requirement_id: Number(isRequirementId)
    })

    setRequirementHistoric(data?.data)
    setCount(data?.count)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToListRequisitionHistoric
      })
    }

    return setLoading(false)
  }

  useEffect(() => {
    if (isRequirementId && shouldUpdateList) {
      handleRequirimentId()
      handleHistoric()
      setShouldUpdateList(false)
    }
  }, [isRequirementId, shouldUpdateList])

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems(
        hasCreateRequisition,
        isRequirementId,
        isTypeRequirement,
        tab
      )}
      onClickButton={() => setOpenModal(true)}
      classNameHeader="goBack"
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      content={
        <>
          <TabView
            tabs={TABS}
            requirement={requirement}
            snackProps={snackProps}
            setLoading={setLoading}
            setOpenModal={setOpenModal}
            isActiveSector={isActiveSector}
            setIsActiveSector={setIsActiveSector}
            requirementHistoric={requirementHistoric}
            listSector={listSector}
            setListSector={setListSector}
            listResponsible={listResponsible}
            setListResponsible={setListResponsible}
            setShouldUpdateList={setShouldUpdateList}
            setSnack={setSnack}
            isRequirementId={isRequirementId}
            rows={items}
            filter={filter}
            setFilter={setFilter}
            setPage={setPage}
            page={page}
            totalPage={totalPage}
            count={count}
          />
        </>
      }
    />
  )
}

export default EditRequisition
