const { ROUTE } = require('services/constants')

const breadcrumbsItems = ({ name }) => [
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS,
    label: 'Secretaria'
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_CREATE,
    label: 'Alunos'
  },

  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_CREATE,
    label: `${name}`
  },
  {
    path: ROUTE.ADMIN_SECRETARY_STUDENTS_DECLARATION,
    label: '<b>Declarações</b>'
  }
]

const MESSAGE = {
  NO_SEARCH: 'Não encontramos nenhum documento nos registros desta ficha.',
  DELETE_SUCCESS: 'Deletado com sucesso.'
}
const SUCCESS = 'success'

const defaultItemsPerPage = 10
const countDefault = 0
const defaultPage = 1

export {
  breadcrumbsItems,
  MESSAGE,
  SUCCESS,
  defaultItemsPerPage,
  countDefault,
  defaultPage
}
