import { ROUTE } from 'services/constants'

const breadcrumbItems = (isEdit, sectorName) => [
  {
    path: ROUTE.ADMIN_FINANCIAL_REGISTER_SUPPLIER,
    label: 'Financeiro'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_REGISTER_SUPPLIER_CREATE,
    label: 'Cadastro Cliente/Fornecedor'
  },
  {
    path: '',
    label: `<b>${isEdit ? sectorName : 'Novo Fornecedor'}</b>`
  }
]

const messageModalConfirm =
  'Deseja cancelar a operação? Você perderá as alterações que ainda não foram salvas.'

const titleModalConfirm = 'Cancelar'

export { breadcrumbItems, messageModalConfirm, titleModalConfirm }
