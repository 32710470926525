import React from 'react'
import { Typography, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import * as Styled from './style'

const HeaderModal = ({ onClick, label }) => (
  <Styled.WrapperTitle>
    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
      {label}
    </Typography>

    <IconButton onClick={onClick}>
      <CloseIcon />
    </IconButton>
  </Styled.WrapperTitle>
)

export default HeaderModal
