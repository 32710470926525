/* eslint-disable camelcase */
import { ROUTE } from 'services/constants'
import { formatDateTime, isEmpty } from 'services/helpers'
import { breadCrumbsFirstStage, inactive } from './constants'

const breadCrumbsItems = (firstStageCompleted) => {
  if (!firstStageCompleted) return breadCrumbsFirstStage

  return [
    ...breadCrumbsFirstStage,
    {
      path: ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_CREATE,
      label: '<b>Cadastro</b>'
    }
  ]
}

const parseSupervisor = (data) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { value: id, label: name }
    return [...acc, newItem]
  }, [])

const parserSubjects = (response) =>
  response?.reduce((acc, cur) => {
    const {
      active,
      discipline,
      workload_hours,
      workload_minutes,
      discipline_code,
      avaliation_type,
      module_number
    } = cur

    const newItem = {
      discipline,
      avaliation_type,
      workload_hours,
      workload_minutes,
      code: discipline_code,
      module_number: Number(module_number),
      active: Boolean(active)
    }
    return [...acc, newItem]
  }, [])

const processData = (data = []) =>
  data?.reduce((acc, cur) => {
    const { id, name, module_amount } = cur
    const newItem = { id, value: name, label: name, module_amount }
    return [...acc, newItem]
  }, [])

const processDataValuesId = (data = []) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { value: id, label: name }
    return [...acc, newItem]
  }, [])

const processDataDiscipline = (data = []) =>
  data?.data.reduce((acc, cur) => {
    const {
      id,
      code,
      active,
      discipline,
      workload_hours,
      workload_minutes,
      module_number,
      joy_course_api_key
    } = cur
    const newItem = {
      module_number,
      id,
      code,
      active,
      name: discipline,
      hours: workload_hours,
      minutes: workload_minutes,
      joyCourseApiKey: joy_course_api_key,
      module: module_number === 0 ? 'Único' : `${module_number}º`,
      startDate: '',
      endDate: ''
    }
    return [...acc, newItem]
  }, [])

const processDataSubjects = (data) =>
  data.reduce((acc, cur) => {
    const {
      id,
      name,
      active,
      code,
      hours,
      minutes,
      module,
      startDate,
      endDate,
      joyCourseApiKey
    } = cur
    const newItem = {
      id,
      name,
      code,
      active,
      module: Number(module?.replace('º', '')),
      workload_hours: Number(hours),
      workload_minutes: Number(minutes),
      begin_at: startDate,
      end_at: endDate,
      ...(joyCourseApiKey && {
        joy_course_api_key: joyCourseApiKey
      })
    }
    return [...acc, newItem]
  }, [])

const disabledButton = ({
  absences,
  approval,
  classDuration,
  course,
  curriculum,
  endDate,
  group,
  level,
  method,
  modality,
  module,
  name,
  reEnrollmentDates,
  recovery,
  shift,
  startDate,
  unity,
  pole,
  workload,
  hasPole
}) => {
  const requiredProperties = [
    absences,
    approval,
    classDuration,
    course,
    curriculum,
    endDate,
    group,
    level,
    method,
    modality,
    module,
    name,
    recovery,
    shift,
    startDate,
    unity,
    workload
  ]

  if (hasPole) {
    const newList = [...requiredProperties, pole]
    return !newList.every((prop) => prop !== undefined && prop !== null)
  }

  return !(
    requiredProperties.every((prop) => prop !== undefined && prop !== null) &&
    !isEmpty(reEnrollmentDates)
  )
}

const parseSubmitNewCLass = ({
  absences,
  approval,
  classDuration,
  course,
  curriculum,
  disciplines,
  endDate,
  group,
  level,
  method,
  modality,
  module,
  name,
  pole,
  reEnrollmentDates,
  recovery,
  reclassification,
  shift,
  startDate,
  unity,
  min_workload,
  qtyClass,
  supervisor,
  unitys
}) => {
  const { label } = unitys?.find((i) => i.value === unity)

  return {
    name,
    unit: label,
    recovery_min: recovery,
    min_workload,
    ...(pole !== '' && { pole }),
    group,
    evaluation: method,
    begin_at: formatDateTime(startDate),
    score_min: approval,
    duration: classDuration,
    end_at: formatDateTime(endDate),
    modality,
    reclassification_min: reclassification,
    level,
    course,
    grade_sheet: curriculum,
    ...(qtyClass && { amount_classes: qtyClass }),
    ...(supervisor?.value && { coordinator_id: supervisor.value }),
    module,
    shift_name: shift,
    frequency_min: absences,
    re_enrollments: reEnrollmentDates,
    subjects: processDataSubjects(
      disciplines?.filter((i) => i.active !== inactive)
    )
  }
}

const handleSelectUnit = ({
  event,
  values,
  selectedLevel,
  setFieldValue,
  setSelectedLevel,
  setHasPole,
  unitys,
  setFormValues
}) => {
  const isPole = unitys?.find((i) => i.id === event.target.value)
  setHasPole(isPole.polo)
  setFormValues({ ...values, unity: event.target.value })
  setFieldValue('unity', event.target.value)
  setSelectedLevel({ ...selectedLevel, unit_id: event.target.value })
}

const convertPoles = ({ pole }) => {
  const newArray = pole?.map((objPole) => objPole.value)

  return `pole_name[]=${newArray?.join('&pole_name[]=')}` || []
}

const typeEndpointPole = (unitId) => `type=pole&unit_id=${unitId}`

const parseResponse = (data, isName) =>
  data?.reduce((acc, cur) => {
    const { id, name } = cur
    const newItem = { value: isName ? name : id, label: name }
    return [...acc, newItem]
  }, [])

export {
  parseResponse,
  breadCrumbsItems,
  typeEndpointPole,
  convertPoles,
  disabledButton,
  processData,
  parserSubjects,
  processDataDiscipline,
  parseSubmitNewCLass,
  handleSelectUnit,
  processDataValuesId,
  parseSupervisor
}
