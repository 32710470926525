import { coatOfArmsBrazilBase64 } from 'components/templates/PDF/shared/images/coatOfArmsBrazil'
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { borderLayoutDefault, paddingLayoutDefault, logoProzSVG } = utilsPDF

export function buildPrimaryHeaderContent({ state, rightImage }) {
  return {
    table: {
      body: [
        [
          {
            fit: [140, 140],
            image: coatOfArmsBrazilBase64,
            alignment: 'center'
          },
          {
            stack: [
              {
                text: 'República Federativa do Brasil',
                bold: true,
                fontSize: 14,
                lineHeight: 1.2,
                alignment: 'center',
                margin: [0, 5, 0, 0]
              },
              {
                text: `Estado de ${state}`,
                bold: true,
                lineHeight: 1.2,
                fontSize: 12,
                alignment: 'center',
                margin: [0, 0, 0, 5]
              },
              {
                svg: logoProzSVG,
                width: 60,
                alignment: 'center',
                margin: [0, 0, 0, 5]
              }
            ]
          },
          {
            image: rightImage,
            width: 100,
            alignment: 'center'
          }
        ]
      ],
      widths: [120, '*', 120]
    },
    layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
  }
}
