const initialValues = {
  class_id: ''
}

const modalityList = [
  {
    value: 1,
    label: 'Presencial'
  },
  {
    value: 3,
    label: 'Online'
  },
  {
    value: 0,
    label: 'Todas'
  }
]

const MESSAGE = {
  SUCCESS_CREATE: 'Matrícula em novo curso realizada com sucesso.'
}

const SUCCESS = 'success'

export { initialValues, modalityList, MESSAGE, SUCCESS }
