import PropTypes from 'prop-types'
import { Avatar as MuiAvatar } from '@mui/material'
import TAG_MANAGER from 'tagManager'

export default function Avatar({ alt, marginRight, size, src }) {
  const sizes = { small: 40, medium: 60, large: 80 }

  return (
    <MuiAvatar
      className={TAG_MANAGER.btn_Header_student_access_Profile_shortcut}
      alt={alt}
      src={src || '/images/avatar-default.png'}
      sx={{
        height: sizes[size],
        marginRight,
        width: sizes[size]
      }}
    />
  )
}

Avatar.propTypes = {
  alt: PropTypes.string,
  marginRight: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  src: PropTypes.string
}

Avatar.defaultProps = {
  alt: 'Avatar',
  size: 'medium'
}
