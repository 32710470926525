import { useState, lazy } from 'react'
import { Table as MuiTable, TableBody } from '@mui/material'
import { getComparator, stableSort } from 'services/helpers/formatTable'
import { PaginationAdvanced } from 'components/molecules'
import { sortAsc, sortDesc, indexDefault } from './constants'
import { StyledPaper, StyledTableContainer } from './style'
import { defaultItemsPerPage, paginationFilter } from '../../constants'

const TableHeader = lazy(() => import('./TableHeader'))
const TableFooter = lazy(() => import('./TableFooter'))

const TableEnrollment = ({
  count,
  items,
  setPage,
  totalPage,
  pageActual,
  onManualEnrollment,
  filter,
  setFilter
}) => {
  const [order, setOrder] = useState(sortAsc)
  const [orderBy, setOrderBy] = useState()
  const [selected, setSelected] = useState([])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === sortAsc
    setOrder(isAsc ? sortDesc : sortAsc)
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = items.map((n) => n.title)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const isSelected = (title) => selected.indexOf(title) !== indexDefault

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  return (
    <StyledPaper>
      <StyledTableContainer>
        <MuiTable>
          <TableHeader
            order={order}
            orderBy={orderBy}
            rowCount={items.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            handleSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {stableSort(items, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.title)
                const labelId = `enhanced-table-${index}`

                return (
                  <TableFooter
                    row={row}
                    key={row.id}
                    labelId={labelId}
                    isItemSelected={isItemSelected}
                    onManualEnrollment={onManualEnrollment}
                  />
                )
              }
            )}
          </TableBody>
        </MuiTable>
      </StyledTableContainer>

      {count > defaultItemsPerPage && (
        <PaginationAdvanced
          value={filter}
          optionsList={paginationFilter}
          isSelectItensPerPage
          count={totalPage}
          page={pageActual}
          handleSetPage={handleSetPage}
          handleChange={(e) => {
            setFilter(e.target.value)
          }}
        />
      )}
    </StyledPaper>
  )
}

export { TableEnrollment }
