const TYPE_FILE_XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const TYPE_FILE_XLS = 'application/vnd.ms-excel'
const REMOVE_CHARACTERS =
  'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'

const MESSAGE = {
  SUCCESS: 'Clique aqui para selecionar o arquivo. Somente arquivos .xlsx',
  ERROR:
    'Arquivo inválido. <br /> Os arquivos devem respeitar o formato do template e devem ser .xlsx '
}

export { MESSAGE, TYPE_FILE_XLS, TYPE_FILE_XLSX, REMOVE_CHARACTERS }
