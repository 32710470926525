import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'

const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    justify-content: flex-end;
  }
`
const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export { Grid, IconWarningAmber }
