import styled from 'styled-components'
import { TableRow as MuiTableRow } from '@mui/material'

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  tr:hover td {
    background: ${({ theme }) => theme.palette.action.hover};
  }
`

const WrapperLink = styled.div`
  align-items: center;
  display: flex;

  button {
    font-size: 14px;
    margin-left: ${({ theme }) => theme.spacing(1)};
    padding: 0;
    text-align: left;
  }
`

const TableRow = styled(MuiTableRow)`
  ${({ $isDisabled }) =>
    $isDisabled && ' opacity: 0.5; &:hover { opacity: 1; }'}
`

export { TableRow, Wrapper, WrapperLink }
