import theme from 'theme/designTokens'
import generateDocDefinitionsBase from '../../shared/BasePage'
import { DOCUMENT_TITLE } from '../contants'
import buildClassInfo from '../ClassInfo'
import BuildTable from './Table'
import buildTableBody from './Table/Body'
import buildStub from '../Stub'
import buildDividerDash from '../../shared/DividerDash'

const buildSchoolReportContent = ({ classInfo, students }) =>
  students.map((student) => [
    buildClassInfo({ classInfo, student }),
    BuildTable({ classInfo, student }),
    buildDividerDash({ margin: [0, 20, 0, 20] }),
    buildStub({ classInfo, student })
  ])

export default async function SchoolReportPDF(schoolReportData) {
  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },

      pageBreakBefore: (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) => {
        const shouldBreakBefore =
          currentNode?.id === 'stub' && currentNode.pageNumbers.length > 1

        return shouldBreakBefore
      }
    }
  })

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE,
    mainContent: buildSchoolReportContent({
      classInfo: {
        ...schoolReportData.classInfo,
        professorDaysGrade: schoolReportData.professorDaysGrade
      },
      students: schoolReportData.students
    }),
    companyInfo: schoolReportData.companyInfo
  })
}
