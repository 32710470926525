import styled from 'styled-components'
import { Box as MuiBox } from '@mui/material'
import Button from 'components/atoms/Button'
import { getRgba } from 'services/helpers/polished'
import theme from 'theme/designTokens'

const openFile = styled.div`
  color: ${theme.palette.dark.main};
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.4px;
  width: 100%;
  text-align: center;
  text-decoration: none;
`

const ButtonUploadFile = styled(Button)`
  &.MuiButtonBase-root {
    display: grid;
    cursor: ${({ $cursor }) => $cursor};
    grid-auto-columns: 1fr;
    min-height: ${({ $hasSize }) => ($hasSize ? '194px' : '160px')};
    min-width: ${({ $hasSize }) => ($hasSize ? 'auto' : '360px')};
    background-color: ${({ $backgroundColor }) =>
      $backgroundColor || theme.palette.textColors.secondary.lightBlue};
    text-align: center;
    text-transform: initial;
    ${({ $borderDashed }) => $borderDashed && `border: ${$borderDashed};`}
    ${({ $errorTypeFile }) =>
      $errorTypeFile &&
      `
        & .MuiTypography-root {
          color: ${theme.palette.red.main};
        }
      `}
    ${({ $imgSrc, $noPreview, $width }) => {
      if ($imgSrc === null || $noPreview) {
        return `
        border: 1px dashed ${getRgba(
          theme.palette.textColors.black.darkBlack,
          0.6
        )};
        margin: ${({ $hasSize }) => ($hasSize ? '0' : '16px')};
      `
      }

      return `padding: 0; width: ${$width || '391px'};    
      min-height: ${({ $hasSize }) => ($hasSize ? '150px' : '160px')};
      min-width: 228px;
      margin-top: 0;
      margin-bottom: 0;
      `
    }};
  }
`

const Box = styled(MuiBox)`
  &.MuiBox-root {
    display: ${({ $photo }) => ($photo ? 'block' : 'flex')};
    justify-content: center;
    flex-wrap: wrap;

    span,
    h6 {
      width: 100%;
      ${({ $photo }) =>
        $photo &&
        `
        width: 24em;
        margin: 0 auto;
      `}
    }
  }
`

export { ButtonUploadFile, Box, openFile }
