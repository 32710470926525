import { Table as MuiTable, TableBody } from '@mui/material'
import { useSortTable } from 'services/hooks'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'
import { paginationFilter } from 'services/helpers'
import { defaultTotalPage } from 'services/hooks/constants'
import { headCells } from './constants'
import * as Styled from './style'
import { TableFooter } from './TableFooter'
import { defaultItemsPerPage } from '../../constants'

const TableLog = ({
  rows,
  count,
  filter,
  totalPage,
  pages,
  handleSetPage,
  setFilter,
  setPages
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    rows,
    'created_at'
  )

  return (
    <>
      <Styled.Paper>
        <Styled.TableContainer>
          <MuiTable>
            <TableHeaderDefault
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {sortTable?.map((row) => (
                <TableFooter key={row.register} row={row} />
              ))}
            </TableBody>
          </MuiTable>
        </Styled.TableContainer>

        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={pages}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setFilter(e.target.value)
              setPages(defaultTotalPage)
            }}
          />
        )}
      </Styled.Paper>
    </>
  )
}

export { TableLog }
