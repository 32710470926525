import { lazy } from 'react'
import { Grid, Table as MuiTable, TableBody } from '@mui/material'
import { TableHeaderDefault } from 'components/organisms'
import { ButtonFooterV2, PaginationAdvanced } from 'components/molecules'
import {
  useActionSelected,
  useCheckedTable,
  useSortTable
} from 'services/hooks'
import { paginationFilter } from 'services/helpers'
import TAG_MANAGER from 'tagManager'
import { useStore } from 'components/pages/Admin/Secretary/Occurrences/Store/useStore'
import {
  defaultItemsPerPage,
  inactive,
  complement,
  defaultPage
} from './constants'
import { headCells } from './config'
import * as Styled from './style'

const TableFooter = lazy(() => import('./TableFooter'))

const Table = ({
  rows,
  filter,
  setFilter,
  totalPage,
  page,
  setPage,
  count,
  handleOnSubit,
  setOpen
}) => {
  const { formValues } = useStore()
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(rows)
  const { onActionSelected, selected, onResetAction } = useActionSelected()

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected: selectedItem,
    setSelected
  } = useCheckedTable(rows, 'id')

  const handleSetPage = (_, currencyPage) => {
    setSelected([])
    setPage(+currencyPage)
  }
  return (
    <>
      <Styled.Paper>
        <Styled.TableContainer>
          <MuiTable>
            <TableHeaderDefault
              checkbox={!formValues?.inactive}
              numSelected={selectedItem.length}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
              headCells={headCells}
            />

            <TableBody>
              {sortTable?.map((row, index) => {
                const isItemSelected = isSelected(row.id)
                const labelId = `enhanced-table-${index}`

                return (
                  <TableFooter
                    key={row?.id}
                    isItemSelected={isItemSelected}
                    labelId={labelId}
                    row={row}
                    handleClick={handleClick}
                    selected={selectedItem}
                    onInactive={(item) => onActionSelected(inactive, item)}
                    onComplement={(item) => onActionSelected(complement, item)}
                    checkbox={!formValues?.inactive}
                  />
                )
              })}
            </TableBody>
          </MuiTable>
        </Styled.TableContainer>
        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={page}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setPage(defaultPage)
              setFilter(e.target.value)
            }}
          />
        )}
      </Styled.Paper>
      <Styled.GroupButtons>
        <ButtonFooterV2
          classNameConfirm={TAG_MANAGER.secretaria_btn_cancela_historico}
          labelClose="Cancelar"
          labelConfirm="Salvar"
          onClickClose={() => setOpen(true)}
          onClickConfirm={() => handleOnSubit(selectedItem)}
          disabledConfirm={selectedItem < 1}
          size="medium"
        />
      </Styled.GroupButtons>
    </>
  )
}

export default Table
