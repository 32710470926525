import { ROUTE } from 'services/constants'

const breadcrumbsItems = (title) => [
  {
    path: ROUTE.LIST_DECLARATIONS,
    label: 'Sistema'
  },
  {
    path: ROUTE.LIST_DECLARATIONS,
    label: 'Tipos de Declarações'
  },
  {
    path: ROUTE.EDIT_DECLARATIONS,
    label: `<b> ${title} </b>`
  }
]

export { breadcrumbsItems }
