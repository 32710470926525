import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  CardContent as MuiCardContent,
  Card as MuiCard,
  Grid as MuiGrid
} from '@mui/material'

const Card = styled(MuiCard)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 5px solid ${(props) => props.$color};
  border-radius: 4px;
`

const Grid = styled(MuiGrid)`
  &.MuiGrid-container {
    padding: 0 120px;
  }
`

const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #593493;
  margin: 10px;

  & svg {
    color: ${(props) => props.$color};
  }
`

const LinkCard = styled(Link)`
  text-decoration: none;
`

export { Box, Card, LinkCard, CardContent, Grid }
