import { useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import { InputQuill } from 'components/atoms'
import { initialValues } from './constants'
import * as Styled from './style'
import { handleSubmitEditText } from '../../services'

const ModalEditReenroll = ({
  handleClose,
  open,
  setLoadingOpen,
  setSnack,
  idModalReenroll,
  setOpenModalEditReenroll,
  className,
  course,
  modules
}) => {
  const quillRef = useRef()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])

  return (
    <Styled.Modal open={open} onClose={() => handleClose()}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) =>
          handleSubmitEditText({
            idModalReenroll,
            values,
            setLoadingOpen,
            setSnack,
            setOpenModalEditReenroll
          })
        }
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <Styled.Title variant="h5" component="h2">
                    Editar texto de rematrícula
                  </Styled.Title>
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="body2">
                    <b> Turma </b> {className} - {course}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="body2">
                    <b> Módulo </b>{' '}
                    {Number(modules) === 0 ? 'Único' : `${modules}º Módulo`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <InputQuill
                    referencie={quillRef}
                    id="text"
                    label="Texto do Modelo"
                    values={values?.text || ''}
                    handleChange={(value) => setFieldValue('text', value)}
                  />
                </Grid>
              </Grid>
              <Styled.Box>
                <ButtonFooterV2
                  labelClose="Cancelar"
                  labelConfirm="Confirmar"
                  size="large"
                  onClickClose={() => handleClose()}
                />
              </Styled.Box>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export default ModalEditReenroll
