import { useNavigate } from 'react-router-dom'
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  Tooltip
} from '@mui/material'

import { useSortTable } from 'services/hooks'
import { defaultlistPerPage } from 'services/hooks/constants'
import {
  convertToParams,
  makeURLAccessLegacy,
  paginationFilter
} from 'services/helpers'
import { ROUTE } from 'services/constants'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'

import { KEY_COLUMNS, tableColumns } from './config'

import * as Styled from './style'

const List = ({
  limit,
  page,
  items,
  count,
  totalPage,
  handleSetPage,
  handleChange
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    items,
    KEY_COLUMNS.NAME
  )

  const navigate = useNavigate()

  const redirectToEidt = ({
    name,
    register,
    course,
    schoolClassCode,
    schoolClassId
  }) => {
    navigate(
      `${
        ROUTE.ADMIN_SECRETARY_STUDENTS_ADDITIONAL_ACTIVITIES
      }?${convertToParams({
        register,
        name,
        course,
        schoolClassCode,
        schoolClassId,
        isLastPageAvaliation: true
      })}`
    )
  }

  const handleDownloadSummary = ({ register, schoolClassId }) => {
    const legacyUrl = makeURLAccessLegacy(
      `acesso/atividades_complementar_pdf.php?matricula=${register}&idTurma=${schoolClassId}&t=1`
    )
    window.open(legacyUrl, '_blank')
  }

  return (
    <Styled.Wrapper>
      <Paper>
        <Table size="medium">
          <TableHeaderDefault
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableColumns}
          />
          <TableBody>
            {sortTable?.map((item) => (
              <Styled.TableRow key={item?.id}>
                <TableCell padding="checkbox" style={{ width: '30%' }}>
                  <Styled.WrapperLink>
                    <Tooltip title="Detalhe das atividades">
                      <Link
                        onClick={() => redirectToEidt(item)}
                        component="button"
                        href="#root"
                      >
                        {item?.name}
                      </Link>
                    </Tooltip>

                    <Tooltip title="Baixar Resumo" className="downloadSummary">
                      <Styled.IconDownload
                        onClick={() => handleDownloadSummary(item)}
                      />
                    </Tooltip>
                  </Styled.WrapperLink>
                </TableCell>
                <TableCell>{item?.course}</TableCell>
                <TableCell>{item?.schoolClassCode}</TableCell>
                <TableCell>{item?.totalRequests}</TableCell>
              </Styled.TableRow>
            ))}
          </TableBody>
        </Table>

        {count > defaultlistPerPage && (
          <PaginationAdvanced
            isSelectItensPerPage
            handleChange={handleChange}
            handleSetPage={handleSetPage}
            count={totalPage}
            optionsList={paginationFilter}
            page={page}
            smallSize
            value={limit}
          />
        )}
      </Paper>
    </Styled.Wrapper>
  )
}

export default List
