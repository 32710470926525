import styled from 'styled-components'

const Title = styled.p`
  color: ${({ theme }) => theme.palette.dark.light};
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 60%;
`

const SubTitle = styled(Title)`
  font-weight: 400;
`

export default { Title, SubTitle }
