/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Tooltip
} from '@mui/material'
import { ExpandMore, MoreVert } from '@mui/icons-material'
import { getListCoursesClasses } from 'services/api/admin'
import FEEDBACK_SNACK from 'feedBackSnack'
import {
  convertToParams,
  formatDate,
  makeURLAccessLegacy,
  updateErrorMessage,
  formatDateTimePtBr,
  formatDateTime
} from 'services/helpers'
import { DropDown, ModalConfirm } from 'components/molecules'
import { ROUTE } from 'services/constants'
import { useNavigate } from 'react-router-dom'
import { Button, LinkTo } from 'components/atoms'
import theme from 'theme/designTokens'
import { useStore } from 'store/GlobalContext'
import isAllowUserAccess from 'routes/helpers/isAllowUserAccess'
import {
  confirmReEnrollment,
  formatDateAndHour,
  getMenuItems
} from './services'
import {
  inProgress,
  messageModalConfirm,
  noSchoolClass,
  occurrence,
  pendingContract,
  pendingReEnrollment
} from './constants'
import Modal from './components/Modal'
import * as Styled from './style'

const StudentRecord = ({ register, setSnack, studentInfo }) => {
  const navigate = useNavigate()
  const { user } = useStore()

  const [loading, setLoading] = useState(false)
  const [schoolList, setSchoolList] = useState([])
  const [notSearch, setNotSearch] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [contract, setContract] = useState('')
  const [selectStundenInfo, setSelectStundenInfo] = useState('')
  const [studentCourse, setStudentCourse] = useState('')
  const [openModalCard, setOpenModalCard] = useState(false)
  const [updateList, setUpdateList] = useState(true)
  const studentName = studentInfo?.student?.name

  const open = Boolean(anchorEl)

  const handleListCourses = async () => {
    setSnack('', '')

    const {
      data: response,
      error,
      status
    } = await getListCoursesClasses(register)

    setLoading(false)
    if (error) {
      setNotSearch(true)
      updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
      })
      return
    }

    setSchoolList(response)
  }

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const handleScore = () => {
    const params = convertToParams({
      name: studentInfo.student.name,
      register,
      course: studentCourse,
      schoolClassId: selectStundenInfo?.school_class_id
    })

    navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_SCORE}?${params}`)
  }

  const handleEdit = () => {
    const params = convertToParams({
      register,
      contract,
      studentName,
      studentCourse
    })

    navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_EDIT}?${params}`)
  }

  const handleNewCourse = () => {
    const params = convertToParams({
      register,
      contract,
      studentName
    })
    navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_CREATE}?${params}`)
  }

  const handleCardTitle = () => {
    setOpenModalCard(true)
  }

  const isAvailableToViewUseOfStudies =
    !!selectStundenInfo?.school_class_id && !selectStundenInfo?.occurrence

  const handleUseOfStudies = () => {
    const params = convertToParams({
      register,
      studentName,
      studentCourse,
      schoolUnit: selectStundenInfo?.unit,
      schoolClassId: selectStundenInfo?.school_class_id
    })
    navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_USE_OF_STUDIES}?${params}`)
  }

  const isAvailableToConfirmReEnrollment =
    selectStundenInfo?.status === 'pending_re_enrollment'

  const handleConfirmReEnrollment = () => {
    confirmReEnrollment({
      reEnrollmentId: selectStundenInfo?.school_class_re_enrollment_id,
      callbackSuccess: () => {
        setUpdateList(true)
      }
    })
  }

  const redirectToAdditionalActivities = () => {
    const params = convertToParams({
      register,
      name: studentInfo.student.name,
      course: selectStundenInfo.course,
      schoolClassCode: selectStundenInfo.school_class_code,
      schoolClassId: selectStundenInfo.school_class_id
    })

    navigate(
      `${ROUTE.ADMIN_SECRETARY_STUDENTS_ADDITIONAL_ACTIVITIES}?${params}`
    )
  }

  const redirectToJustifyAbsence = () => {
    const params = convertToParams({
      register,
      name: studentInfo.student.name,
      course: selectStundenInfo.course,
      unitName: selectStundenInfo.unit,
      schoolClassCode: selectStundenInfo.school_class_code,
      schoolClassId: selectStundenInfo.school_class_id
    })

    navigate(`${ROUTE.ADMIN_SECRETARY_STUDENTS_JUSTIFY_ABSENCE}?${params}`)
  }

  const onSubmitCardTitle = (values) => {
    makeURLAccessLegacy(
      `acesso/turma_lista_alunos_carteirinha.php?${convertToParams({
        tituloC: values.cardTitle,
        modeloL: 1,
        id_turma: selectStundenInfo?.school_class_id,
        validadeC: formatDate(values.validity)
      })}`,
      true
    )
    return setOpenModalCard(false)
  }

  const linkClassDetail = (item) => {
    navigate(
      `${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_DETAILS}?id=${item?.school_class_id}`,
      {
        state: { savedSearch: true }
      }
    )
  }

  const copyContract = () => {
    const legacyUrl = makeURLAccessLegacy(
      `acesso/aluno_contrato_pdf81.php?matricula=${register}&ref_curso=${selectStundenInfo?.contract}`
    )

    window.open(legacyUrl, '_blank')
    setOpenModalCard(false)
  }

  const isAvailableToAccessJustifyAbsence = isAllowUserAccess(
    user.permissions,
    'SECRETARY_STUDENTS_JUSTIFY_ABSENCE'
  )

  const paramsGetMenu = {
    register,
    handleClose,
    handleScore,
    handleEdit,
    handleCardTitle,
    handleUseOfStudies,
    isAvailableToAccessJustifyAbsence: !isAvailableToAccessJustifyAbsence,
    isHideConfirmReEnrollment: !isAvailableToConfirmReEnrollment,
    disableConfirmReEnrollment: studentInfo.overdue.hasOverdue,
    handleConfirmReEnrollment,
    isHideUseOfStudies: !isAvailableToViewUseOfStudies,
    copyContract,
    selectStundenInfo,
    redirectToAdditionalActivities,
    redirectToJustifyAbsence,
    onlyEdit: selectStundenInfo.status === noSchoolClass
  }

  useEffect(() => {
    if (updateList) {
      handleListCourses()
      setUpdateList(false)
    }
  }, [updateList])

  return (
    <>
      <Styled.ButtonGroup>
        <Button onClick={handleNewCourse} variant="contained">
          novo curso
        </Button>
      </Styled.ButtonGroup>

      {schoolList?.map((item) => (
        <Accordion key={item.course}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMore />}
          >
            <Styled.LeftContainer>
              <Tooltip
                title={
                  item?.registration_date && item?.registration_user
                    ? `Matricula efetivada em ${formatDateAndHour(
                        item.registration_date
                      )} [${item.registration_user}]`
                    : ''
                }
                placement="top"
                arrow
              >
                <Styled.Text>
                  <b>{item?.course || ''}</b> {item?.contract || ''}
                </Styled.Text>
              </Tooltip>
            </Styled.LeftContainer>
            <Styled.RightContainer>
              <Styled.TextGroup>{item?.group || ''}</Styled.TextGroup>
            </Styled.RightContainer>
          </AccordionSummary>

          <Styled.AccordionDetails>
            <Grid container spacing={{ xs: 2, sm: 2 }}>
              <Grid item xs={12} sm={12} md={5}>
                <Styled.SectionAccordionTop>
                  <Styled.TextAccordion>
                    <Styled.Link onClick={() => linkClassDetail(item)}>
                      {item?.school_class_code || ''}
                    </Styled.Link>
                  </Styled.TextAccordion>

                  <Styled.TextAccordion>
                    {item?.unit || ''}{' '}
                  </Styled.TextAccordion>

                  <Tooltip
                    title={`Enturmado por ${
                      item?.enrolling_user || 'sem informação'
                    }`}
                  >
                    <Styled.TextAccordion>
                      <b>Enturmado em</b>{' '}
                      {formatDateTimePtBr(item?.enrolling_date)}
                    </Styled.TextAccordion>
                  </Tooltip>
                </Styled.SectionAccordionTop>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <Styled.SecondGrid>
                  {item?.module && (
                    <Styled.SectionAccordionTop>
                      <Styled.TextAccordion>
                        {item?.module || ''}
                      </Styled.TextAccordion>
                    </Styled.SectionAccordionTop>
                  )}
                  {item?.is_ead && (
                    <Styled.SectionAccordion>
                      <Styled.Ead>
                        <Styled.TextIsEad>EAD</Styled.TextIsEad>
                      </Styled.Ead>
                    </Styled.SectionAccordion>
                  )}
                  {item?.shift && (
                    <Styled.SectionAccordionTop
                      $isMg={
                        item?.status === noSchoolClass ? '0 2rem' : undefined
                      }
                    >
                      <Styled.TextAccordion>
                        {item?.shift || ''}{' '}
                      </Styled.TextAccordion>
                    </Styled.SectionAccordionTop>
                  )}
                  {item?.status === inProgress && (
                    <Styled.SectionAccordion>
                      <>
                        <Styled.TextAccordion>
                          <b>Início</b> {formatDate(item?.start_date) || ''}
                        </Styled.TextAccordion>
                        <Styled.TextAccordion>
                          <b>Fim</b> {formatDate(item?.end_date) || ''}
                        </Styled.TextAccordion>
                      </>
                    </Styled.SectionAccordion>
                  )}
                  <Styled.SectionAccordionTop>
                    {item?.status === inProgress && (
                      <Box
                        sx={{ position: 'relative', display: 'inline-flex' }}
                      >
                        <Styled.CircularProgress
                          variant="determinate"
                          value={item?.progress_percent}
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Styled.TextProgress
                            variant="caption"
                            component="div"
                            color="#00000099"
                          >
                            {`${Math.round(item.progress_percent)}%`}
                          </Styled.TextProgress>
                        </Box>
                      </Box>
                    )}

                    {item?.status === pendingReEnrollment && (
                      <Styled.Status $bgColor={theme.palette.secondary.main}>
                        <Styled.TextStatus>
                          Rematrícula pendente
                        </Styled.TextStatus>
                      </Styled.Status>
                    )}

                    {item?.status === occurrence && (
                      <>
                        <Styled.StatusOccurece $bgColor="#F44336">
                          <Styled.TextStatus>
                            {item?.occurrence_tag}
                          </Styled.TextStatus>
                        </Styled.StatusOccurece>
                        <LinkTo
                          tooltipTitle="Ocorrência"
                          linkTo={`${
                            ROUTE.ADMIN_SECRETARY_OCCURRENCES_EDIT
                          }?${convertToParams({
                            id: item?.occurrence_id
                          })}`}
                          nameLink={`${item?.occurrence_type} ${item?.occurrence_id}`}
                        />
                      </>
                    )}

                    {item?.status === pendingContract && (
                      <Styled.Status $bgColor={theme.palette.secondary.main}>
                        <Styled.TextStatus>Contrato pendente</Styled.TextStatus>
                      </Styled.Status>
                    )}
                  </Styled.SectionAccordionTop>
                  {item?.status === noSchoolClass && (
                    <Styled.SectionAccordion $isMg="0 2rem">
                      <Styled.Status
                        $bgColor={theme.palette.blue.main}
                        $isMg="2rem 0 0"
                      >
                        <Styled.TextStatus>Sem turma</Styled.TextStatus>
                      </Styled.Status>
                    </Styled.SectionAccordion>
                  )}

                  <Styled.MenuButton
                    $isMg={
                      item?.status === noSchoolClass ? '0 2rem' : undefined
                    }
                  >
                    <IconButton
                      onClick={(e) => {
                        handleClick(e)
                        setContract(item?.contract)
                        setStudentCourse(item?.course)
                        setSelectStundenInfo(item)
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </Styled.MenuButton>
                </Styled.SecondGrid>
              </Grid>
            </Grid>
          </Styled.AccordionDetails>
        </Accordion>
      ))}

      <DropDown
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
        menuItems={getMenuItems(paramsGetMenu)}
      />

      <Modal
        isFormattedDate
        open={openModalCard}
        handleClose={() => setOpenModalCard(false)}
        onSubmit={onSubmitCardTitle}
        title="Carteira estudantil"
        message={`${studentInfo?.student?.name} - ${selectStundenInfo?.school_class_code} - ${selectStundenInfo?.course}`}
        setSnack={setSnack}
      />
    </>
  )
}

export default StudentRecord
