import { httpPost } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const updateResume = async (payload) => {
  const response = await httpPost(`${ENDPOINT.ADMIN_MANAGER_EXTRACT}`, payload)

  return response
}

export const updateExtract = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_MANAGER_EXTRACT}/xlsx`,
    payload,
    {
      responseType: 'blob'
    }
  )

  return response
}

export const xlsxConciliation = async (formData) => {
  const response = await httpPost(
    `${ENDPOINT.ADMIN_MANAGER_XLS_CONCILIACAO}`,
    formData
  )

  return response
}

export const reprocessing = async (payload) => {
  const response = await httpPost(`${ENDPOINT.MANAGER.REPROCESSING}`, {
    ...payload
  })

  return response
}
