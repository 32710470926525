import PropTypes from 'prop-types'
import ReactQuill, { Quill } from 'react-quill' // ES6
import 'react-quill/dist/quill.snow.css'
import { Typography } from '@mui/material'
import { ContainerQuill } from './style'

Quill.register(Quill.import('attributors/style/direction'), true)
Quill.register(Quill.import('attributors/style/align'), true)

const modulesReactQuill = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    ['clean']
  ]
}

const InputQuill = ({
  id,
  referencie,
  values,
  label,
  handleBlur,
  handleChange,
  height,
  helperText,
  error,
  hideToolbar,
  disabled
}) => (
  <ContainerQuill $height={height || '162px'} $error={error}>
    <ReactQuill
      id={id}
      theme="snow"
      value={values}
      ref={referencie}
      onBlur={handleBlur}
      onChange={(val) => {
        handleChange(val)
      }}
      modules={{...modulesReactQuill, ...(hideToolbar && {toolbar: false})}}
      placeholder={label}
      formats={[
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'list',
        'bullet',
        'align',
        'color',
        'background'
      ]}
      readOnly={disabled}
    />
    {helperText && error && (
      <Typography variant="caption" component="caption">
        {helperText}
      </Typography>
    )}
  </ContainerQuill>
)

export default InputQuill

InputQuill.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string
}

InputQuill.defaultProps = {
  handleChange: () => {}
}
