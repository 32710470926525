const KEY_COLUMNS = {
  CODE: 'code',
  REASON_SOCIAL: 'reasonSocial',
  CNPJ: 'cnpj'
}

const tableColumns = [
  { id: KEY_COLUMNS.CODE, label: 'Código' },
  { id: KEY_COLUMNS.REASON_SOCIAL, label: 'Razão Social' },
  { id: KEY_COLUMNS.CNPJ, label: 'CNPJ' }
]

export { tableColumns, KEY_COLUMNS }
