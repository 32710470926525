import { Formik, Form } from 'formik'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { Modal } from 'components/atoms'
import Select from '../Select'
import * as Styled from './style'

const ModalSelect = ({
  open,
  handleClose,
  icon,
  title,
  message,
  subMessage,
  labelSelect,
  optionsList,
  textButtonNotConfirm,
  textButtonConfirm,
  onClickNotConfirm,
  colorButtonConfirm,
  colorButtonNotConfirm,
  hasMaxWidth,
  initialState,
  onSubmit,
  disabledSelect,
  classNameBtnConfirm,
  classNameBtnCancel,
  onClickConfirm,
  maxWidthModal,
  ...props
}) => {
  const onHandleChange = (e, setFieldValue) => {
    const { value } = e.target
    setFieldValue('item', value)
  }

  return (
    <Modal open={open} onClose={handleClose} fullWidth maxWidth={maxWidthModal}>
      <Formik
        initialValues={initialState}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Styled.IconText>
                  {icon}
                  <Styled.Title variant="h5">{title} </Styled.Title>
                </Styled.IconText>
              </Grid>

              {message && (
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="subtitle1">{message}</Typography>
                </Grid>
              )}

              {subMessage && (
                <Grid item xs={12} sm={12} md={12}>
                  <Styled.Typography variant="subtitle2">
                    {subMessage}
                  </Styled.Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <Select
                  id="selectBag"
                  disabled={disabledSelect}
                  value={values?.item || ''}
                  label={labelSelect}
                  optionsList={optionsList}
                  onChange={(e) => onHandleChange(e, setFieldValue)}
                  {...props}
                />
              </Grid>

              <Styled.GridButtons item xs={12} sm={12} md={12}>
                <Styled.Button
                  className={classNameBtnCancel}
                  value={textButtonNotConfirm}
                  onClick={() => onClickNotConfirm()}
                  color={colorButtonNotConfirm}
                  $hasMaxWidth={hasMaxWidth}
                  variant="outlined"
                />

                <Styled.Button
                  type="submit"
                  className={classNameBtnConfirm}
                  value={textButtonConfirm}
                  color={colorButtonConfirm}
                  $hasMaxWidth={hasMaxWidth}
                  onClick={onClickConfirm}
                  disabled={!values?.item}
                />
              </Styled.GridButtons>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalSelect

ModalSelect.propTypes = {
  open: PropTypes.bool,
  icon: PropTypes.element,
  title: PropTypes.string,
  message: PropTypes.string,
  subMessage: PropTypes.string,
  handleClose: PropTypes.func
}

ModalSelect.defaultProps = {
  open: false,
  title: '',
  message: '',
  subMessage: ''
}
