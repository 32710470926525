const FEEDBACK_BILLS_TO_RECEIVE = {
  noAccessPermissionToListBillisToReceive: 'para listar título(s)'
}

const FEEDBACK_FINANCIAL_DETAILS = {
  noAccessPermissionToListFinancialDetails:
    'para listar detalhamento financeiro',
  noAccessPermissionToListCampaigns: 'para listar  campanha(s)',
  noAccessPermissionToRemoveFinancialDetails: 'para remover título(s)',
  noAccessPermissionToInactiveFinancialDetails: 'para inavitar título(s)',
  noAccessPermissionToActiveFinancialDetails: 'para ativar título(s)',
  noAccessPermissionToAdvanceInstallments: 'para adiantar parcelas',
  noAccessPermissionToSendEmail: 'para enviar email(s)',
  noAccessPermissionToPerformTrading: 'para realizar negociação'
}

const FEEDBACK_TITLE_DETAILS = {
  noAccessPermissionToListTitleDetails: 'para listar título(s)',
  noAccessPermissionToCreateTitleDetails: 'para salvar título(s)',
  noAccessPermissionToUpdateTitleDetails: 'para atualizar título(s)'
}

const FEEDBACK_CAMPAIGNS = {
  noAccessPermissionToEditCampaigns: 'para editar uma Campanha',
  noAccessPermissionToCreateCampaigns: 'para criar uma Campanha',
  noAccessPermissionToInactivateCampaigns: 'para inativar uma Campanha'
}

const FEEDBACK_GROUP = {
  noAccessPermissionToListGroup: 'para listar grupo(s)',
  noAccessPermissionToRemoveGroup: 'para remover grupo(s)',
  noAccessPermissionToInactiveGroup: 'para inavitar grupo(s)',
  noAccessPermissionToCreateGroup: 'para salvar grupo',
  noAccessPermissionToUpdateGroup: 'para atualizar grupo',
  noAccessPermissionToViewGroup: 'para vizualizar grupo'
}

const FEEDBACK_CUPON = {
  noAccessPermissionToListCupon: 'para listar cupon(s)',
  noAccessPermissionToRemoveCupon: 'para remover cupon(s)',
  noAccessPermissionToCreateCupon: 'para salvar cupon',
  noAccessPermissionToUpdateCupon: 'para atualizar cupon',
  noAccessPermissionToViewCupon: 'para vizualizar cupon',
  noAccessPermissiontoTodownloadCupon: 'para fazer Download de cupon(s)'
}

const FEEDBACK_CONTRACT = {
  noAccessPermissionToListContract: 'para listar contrato(s)',
  noAccessPermissionToRemoveContract: 'para remover contrato(s)',
  noAccessPermissionToCreateContract: 'para salvar contrato',
  noAccessPermissionToUpdateContract: 'para atualizar contrato',
  noAccessPermissionToViewContract: 'para vizualizar contrato',
  noAccessPermissiontoTodownloadContract: 'para fazer Download de contrato(s)'
}

const FEEDBACK_INVOICES = {
  noAccessPermissionToListInvoices: 'para listar Notas Fiscais',
  noAccessPermissiontoToDownloadInvoices:
    'para fazer Download de Notas Fiscais',
  noAccessPermissiontoToGenerateInvoices: 'para fazer gerar Notas Fiscais',
  noAccessPermissiontoToResendInvoices: 'para reenviar Notas Fiscais'
}

const FEEDBACK_PAYMENT_PLAN = {
  noAccessPermissionToListPaymentPlan: 'para listar plano de pagamento',
  noAccessPermissiontoToCopyOfThePaymentPlan:
    'para criar cópia de plano de pagamento',
  noAccessPermissiontoToRemovePaymentPlan: 'para remover plano de pagamento',
  noAccessPermissionToCreatePaymentPlan: 'para salvar plano de pagamento',
  noAccessPermissionToUpdatePaymentPlan: 'para atualizar plano de pagamento',
  noAccessPermissionToViewPaymentPlan: 'para vizualizar plano de pagamento'
}

const FEEDBACK_ALL = {
  noAccessPermissionToCourses: 'para listar cursos',
  noAccessPermissionToCostCenter: 'para listar centro de custo',
  noAccessPermissionToCostCenterUnit: 'para listar unidades do centro de custo',
  noAccessPermissionToCostCenterNature:
    'para listar natureza do centro de custo',
  noAccessPermissionToCostCenterType: 'para listar tipo do centro de custo',
  noAccessPermissionToCostCenterSubCategory:
    'para listar sub-categoria do centro de custo',
  noAccessPermissionToListPaymentAccount: 'para listar conta de pagamento',
  noAccessPermissionToListPaymentMethod: 'para listar forma de pagamento',
  noAccessPermissionToUnits: 'para listar unidades',
  noAccessPermissionToGroup: 'para listar grupos',
  noAccessPermissionToCourse: 'para listar cursos',
  noAccessPermissionToLevel: 'para listar nível',
  noAccessPermissionToShifts: 'para listar turnos',
  noAccessPermissionToAccount: 'para listar contas',
  noAccessPermissionToContractModel: 'para listar modelo de contrato',
  noAccessPermissionToModules: 'para listar modulos',
  noAccessPermissionToReprocessing: 'para criar reprocesso'
}

export const FEEDBACK_FINANCIAL = {
  ...FEEDBACK_ALL,
  ...FEEDBACK_GROUP,
  ...FEEDBACK_CUPON,
  ...FEEDBACK_INVOICES,
  ...FEEDBACK_CAMPAIGNS,
  ...FEEDBACK_PAYMENT_PLAN,
  ...FEEDBACK_TITLE_DETAILS,
  ...FEEDBACK_BILLS_TO_RECEIVE,
  ...FEEDBACK_FINANCIAL_DETAILS,
  ...FEEDBACK_CONTRACT
}
