import styled from 'styled-components'
import { Chip, Typography } from '@mui/material'
import { Button } from 'components/atoms'

const HeaderFrequency = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;

  .buttonConfirmationFrequency {
    margin-right: 50px;
  }
`

const ButtonConfirmFrequency = styled(Button)`
  &.MuiButton-root {
    margin: 0;
  }
`

const Box = styled.div`
  margin-top: 48px;
`

const ColorInformation = styled(Chip)`
  &.MuiChip-root {
    max-width: 34px;
    max-height: 15px;
    border-radius: 0;
  }
`

const Caption = styled.div`
  display: flex;
  align-items: center;
`

const CaptionTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 10px;
    font-size: 14px;
  }
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 16px;
    margin: 10px 0;
  }
`

export {
  HeaderFrequency,
  Box,
  ButtonConfirmFrequency,
  ColorInformation,
  Caption,
  CaptionTitle,
  Title
}
