import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useSnack } from 'services/hooks'
import { ModalConfirm } from 'components/molecules'
import TAG_MANAGER from 'tagManager'
import { FormEditAcademicPerformance } from './components/Form'
import { LIST_STATUS, breadCrumbsItems, initialFormValues } from './constants'
import * as Styled from './style'
import { getAcademicPerformances } from './services'
import { parseAcademicPerformanceDisciplines } from './helpers'

const EditAcademicPerformance = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { register, name, classId, requirementNumber } = params
  const navigate = useNavigate()
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [formValues, setFormValues] = useState(initialFormValues)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [academicPerformances, setAcademicPerformances] = useState({
    course: {},
    disciplines: []
  })
  const [isEditable, setIsEditable] = useState(false)

  const onHandleConfirm = () => {
    setOpenModalConfirm(false)
    navigate(ROUTE.ADMIN_SECRETARY_ACADEMIC_PERFORMANCES, {
      state: { savedSearch: true }
    })
  }

  useEffect(() => {
    if (register && classId)
      getAcademicPerformances({
        register,
        classId,
        requirementNumber,
        setSnack,
        setAcademicPerformances,
        setLoadingOpen
      })
  }, [register, classId, requirementNumber])

  useEffect(() => {
    const disciplines = academicPerformances?.disciplines || []
    const {
      has_internship: hasInternship,
      internship_hours: internshipHours
    } = disciplines[0]?.academic_performance || {}

    const isEditableForm = disciplines.some((disc) => disc?.academic_performance?.is_editable)
    setFormValues((current) => ({
      ...current,
      hasInternship,
      internshipHours,
      academicPerformances: parseAcademicPerformanceDisciplines(disciplines)
    }))
    setIsEditable(isEditableForm)
  }, [academicPerformances])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      breadcrumbsItems={breadCrumbsItems({
        register,
        name,
        classId
      })}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() => setOpenModalConfirm(true)}
      open={openModalConfirm}
      handleCloseModal={() => setOpenModalConfirm(false)}
      handleConfirmModal={() => onHandleConfirm()}
      tagmanagerModal={TAG_MANAGER.secretary_btn_cancel_edit_academic_performance}
      content={
        <>
          <FormEditAcademicPerformance
            setFormValues={setFormValues}
            setSnack={setSnack}
            setLoadingOpen={setLoadingOpen}
            formValues={formValues}
            classId={classId}
            requirementNumber={requirementNumber}
            register={register}
            setOpenModalConfirm={setOpenModalConfirm}
            listStatus={LIST_STATUS}
            setAcademicPerformances={setAcademicPerformances}
            isEditable={isEditable}
            student={{ register, name }}
            academicPerformances={academicPerformances}
          />

          <ModalConfirm
            open={openModalConfirm}
            icon={<Styled.IconWarningAmber />}
            title="Cancelar"
            message="Deseja cancelar a operação atual? Você perderá as alterações realizadas."
            size="medium"
            handleClose={() => setOpenModalConfirm(false)}
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            onClickNotConfirm={() => setOpenModalConfirm(false)}
            onClickConfirm={() => setOpenModalConfirm(false)}
            colorButtonConfirm="error"
            classNameBtnConfirm={TAG_MANAGER.secretary_btn_confirm_cancel_edit_academic_performance}
          />
        </>
      }
    />
  )
}

export default EditAcademicPerformance
