import { lazy, useState } from 'react'
import { Tab } from '@mui/material'
import { LoadingPage } from 'components/molecules'
import { disciplines, headquarters, initDisciplines, TAB } from './constants'
import * as Styled from './style'

const TabActive = lazy(() => import('./TabActive'))

const Tabs = ({
  openModalDelete,
  setOpenModalDelete,
  setListCourse,
  listCourse,
  isEdit,
  setLoading,
  setSnack,
  initialValues,
  setOpenModal,
  fields,
  setFields,
  isIdCurriculum,
  subject,
  setSubject,
  navigate,
  props
}) => {
  const [loadingVisibility, setLoadingVisibility] = useState(false)
  const [selectTab, setSelectedTab] = useState(TAB.HEADQUARTERS)
  const [value, setValue] = useState(headquarters)
  const [messageError, setMessageError] = useState(false)

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue)
    setLoadingVisibility(false)
  }

  return (
    <>
      <LoadingPage open={loadingVisibility} />

      <Styled.Tabs
        value={value}
        onChange={handleChangeTabs}
        aria-label="secondary tabs example"
      >
        <Tab
          value={headquarters}
          label="Dados da matriz"
          onClick={() => setSelectedTab(TAB.HEADQUARTERS)}
          setListCourse={setListCourse}
          listCourse={listCourse}
          setLoading={setLoading}
          setSnack={setSnack}
          initialState={initialValues}
          setOpenModal={setOpenModal}
        />
        <Tab
          value={disciplines}
          label="Disciplinas"
          onClick={() => {
            if (!isEdit) {
              setFields(initDisciplines)
            }
            setSelectedTab(TAB.DISCIPLINES)
          }}
        />
      </Styled.Tabs>

      <TabActive
        {...props}
        value={value}
        messageError={messageError}
        setMessageError={setMessageError}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        setListCourse={setListCourse}
        listCourse={listCourse}
        isEdit={isEdit}
        setLoading={setLoading}
        setSnack={setSnack}
        subject={subject}
        setSubject={setSubject}
        initialState={initialValues}
        setOpenModal={setOpenModal}
        fields={fields}
        setFields={setFields}
        isIdCurriculum={isIdCurriculum}
        navigate={navigate}
      />
    </>
  )
}

export default Tabs
