import { useState, useEffect } from 'react'
import { Chip, FormControlLabel, FormLabel, Radio } from '@mui/material'
import TAG_MANAGER from 'tagManager'
import { BoxItem, Button } from 'components/atoms'
import { ModalConfirm } from 'components/molecules'
import { typeMask } from 'services/helpers'
import { TYPES_OF_PAYMENT } from 'components/pages/Admin/Financial/PaymentPlans/constants'
import * as Styled from './style'
import { getValidateCoupons } from '../../services'
import { textDiscountedInstallments } from '../../helpers'
import { activeStepPayment } from '../../constants'

const HeaderSubscription = ({
  data,
  group,
  activeStep,
  couponData,
  anticipation,
  setDataCoupon,
  setCouponData,
  setAnticipation,
  detailsCampaign
}) => {
  const [open, setOpen] = useState(false)
  const [coupon, setCoupon] = useState('')
  const [isError, setIsError] = useState(false)
  const [isValidcoupon, setIsValidcoupon] = useState(false)

  const handleDelete = () => {
    setIsValidcoupon(false)
    setCoupon('')
  }

  const isActiveStepPayment = activeStep === activeStepPayment

  const installmentQty = data?.installment_quantity
  const installments =
    detailsCampaign && Object.keys(detailsCampaign).length
      ? installmentQty + detailsCampaign?.installments
      : installmentQty

  const isRecorrencePayment =
    data.type_of_payment === TYPES_OF_PAYMENT.RECURRENCE.value

  return (
    <Styled.Container>
      <Styled.Box>
        <BoxItem title="Unidade" subTitle={data?.unit_name} />
        <BoxItem title="Nível" subTitle={data?.level_name} />
        <BoxItem title="Curso" subTitle={data?.course_name} />
        <BoxItem title="Turno" subTitle={data?.course_shift} />
      </Styled.Box>
      <Styled.Box>
        <BoxItem title="Modalidade" subTitle={data?.modality_name} />
        <BoxItem title="Grupo" subTitle={group} />
        {!isRecorrencePayment && (
          <>
            {couponData?.enrollment_tax && isValidcoupon ? (
              <BoxItem
                title="Matrícula"
                subTitle={`R$ ${couponData?.enrollment_tax}`}
              />
            ) : (
              <BoxItem
                title="Matrícula"
                subTitle={`${typeMask('MONEY_NUMBER', data?.enrollment_fee)}`}
              />
            )}
          </>
        )}
        {couponData && isValidcoupon ? (
          <BoxItem
            title="Mensalidade"
            subTitle={textDiscountedInstallments({
              couponData,
              detailsCampaign
            })}
          />
        ) : (
          <BoxItem
            title="Mensalidade"
            subTitle={`${installments}x de ${typeMask(
              'MONEY_NUMBER',
              data?.installment_fee
            )}`}
          />
        )}
      </Styled.Box>
      {!isRecorrencePayment && (
        <Styled.Box>
          <Styled.BoxHeader>
            {isValidcoupon ? (
              <Chip
                label={coupon}
                onDelete={handleDelete}
                disabled={isActiveStepPayment}
              />
            ) : (
              <>
                <Styled.TextField
                  label="Cupom de desconto"
                  type="text"
                  value={coupon}
                  onChange={(e) => setCoupon(e?.target?.value)}
                  isMargin="0px"
                  size="small"
                  error={isError}
                  disabled={isActiveStepPayment}
                />
                <Button
                  color="warning"
                  variant="outlined"
                  disabled={isActiveStepPayment}
                  onClick={() =>
                    getValidateCoupons({
                      data,
                      coupon,
                      setOpen,
                      setIsError,
                      setIsValidcoupon,
                      setDataCoupon,
                      setCouponData
                    })
                  }
                  className={TAG_MANAGER.commercial_btn_applies_couponDiscount}
                >
                  aplicar
                </Button>
              </>
            )}
          </Styled.BoxHeader>
          {detailsCampaign && Object.keys(detailsCampaign).length !== 0 && (
            <Styled.FormControl>
              <FormLabel component="legend">
                <b>
                  Deseja antecipar {detailsCampaign?.installments} parcelas com
                  desconto de {detailsCampaign?.discount}%?*
                </b>
              </FormLabel>

              <Styled.RadioGroup row value={anticipation}>
                <FormControlLabel
                  label="Sim"
                  value="true"
                  disabled={isActiveStepPayment}
                  control={<Radio size="small" />}
                  onClick={() =>
                    isActiveStepPayment ? {} : setAnticipation(true)
                  }
                />
                <FormControlLabel
                  label="Não"
                  value="false"
                  disabled={isActiveStepPayment}
                  control={<Radio size="small" />}
                  onClick={() =>
                    isActiveStepPayment ? {} : setAnticipation(false)
                  }
                />
              </Styled.RadioGroup>
            </Styled.FormControl>
          )}
        </Styled.Box>
      )}
      <ModalConfirm
        open={open}
        handleClose={() => setOpen(!open)}
        title="Cupom inválido"
        message="Este cupom não pode ser utilizado. Verifique se digitou corretamente e se a inscrição atende aos critérios corretamente."
        textButtonConfirm="Ok"
        onClickConfirm={() => setOpen(!open)}
        colorButtonConfirm="primary"
      />
    </Styled.Container>
  )
}
export default HeaderSubscription
