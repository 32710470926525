import FEEDBACK_SNACK from 'feedBackSnack'
import { formatDateEnv, isEmpty, updateErrorMessage } from 'services/helpers'
import { getListInteractions, getListProfessor } from 'services/api/admin'
import { parseList } from './helpers'

const listInfoProfessors = async ({ id, setSnack, setFormPesonalData }) => {
  setSnack('')

  const { data, error, status } = await getListProfessor(id)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListProfessors
    })
  }

  return setFormPesonalData(parseList(data))
}

const getInteractionsProfessor = async ({
  id,
  values,
  setSnack,
  setLoadingOpen,
  setListLogs,
  setNotFound,
  setCount,
  filter,
  pages
}) => {
  setSnack('')
  setLoadingOpen(true)

  const params = {
    begin_at: formatDateEnv(values.begin_at),
    end_at: formatDateEnv(values.end_at),
    page: pages,
    limit: filter
  }

  const {
    data: response,
    error,
    status
  } = await getListInteractions({ id, params })

  setLoadingOpen(false)
  setCount(response?.data?.count)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListInteractions
    })
  }

  setNotFound(!!isEmpty(response?.data.data))

  return setListLogs(response.data.data)
}

export { getInteractionsProfessor, listInfoProfessors }
