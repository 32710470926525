const nameAccordion = 'class-discipline'
const emptyValue = ''

const modal = {
  title: 'Excluir Vínculo',
  message: 'Deseja realmente excluir o vínculo criado?',
  buttonConfirm: 'Sim',
  buttonNotConfirm: 'Não'
}

export { nameAccordion, emptyValue, modal }
