import { httpGet, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getProfile = async ({ id }) => {
  const getUserProfile = await httpGet(
    `${ENDPOINT.USER}/${id}/${ENDPOINT.PROFILE}`,
  )

  return getUserProfile
}

export const updatePassword = async ({ id, params }) => {
  const getUserProfile = await httpPut(
    `${ENDPOINT.USER}/${id}/password`,
    params,
  )

  return getUserProfile
}
