const headColumns = {
  classId: 'classId',
  classCode: 'classCode',
  discipline: 'discipline',
  course: 'course',
  group: 'group',
  unit: 'unit',
  start: 'start'
}

const headCells = [
  {
    id: headColumns.classId,
    label: 'ID'
  },
  {
    id: headColumns.classCode,
    label: 'Turma'
  },
  {
    id: headColumns.discipline,
    label: 'Disciplina'
  },
  {
    id: headColumns.course,
    label: 'Curso'
  },
  {
    id: headColumns.group,
    label: 'Grupo'
  },
  {
    id: headColumns.unit,
    label: 'Unidade'
  },
  {
    id: headColumns.start,
    label: 'Início'
  },
  {
    id: headColumns.end,
    label: 'Fim'
  }
]

export { headCells, headColumns }
