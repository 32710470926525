import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'

const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const GridContainer = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
  }
`
const Form = styled.form``

const Strong = styled.strong`
  gap: 5px;
  display: flex;
  font-size: 14px;
  margin-top: 10px;
  align-items: center;
`
const Text = styled.p`
  color: #f44336;
  margin: 0 -4px;
  font-size: 16px;
  font-weight: bold;
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

const SwitchAdvSearch = styled.div`
  padding: 20px 0 10px;
`

export {
  Grid,
  Form,
  Text,
  Strong,
  GridContainer,
  SwitchAdvSearch,
  IconWarningAmber
}
