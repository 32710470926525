import { AddCircle as MuiAddCircle } from '@mui/icons-material'

import { Button } from 'components/atoms'

import * as Styled from './style'

const Actions = ({ handleIncludeDiscipline }) => (
  <Styled.ActionsContainer>
    <Styled.AlignRight>
      <Button startIcon={<MuiAddCircle />} onClick={handleIncludeDiscipline}>
        Incluir disciplina
      </Button>
    </Styled.AlignRight>
  </Styled.ActionsContainer>
)
export default Actions
