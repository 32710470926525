import { formatDateOnlyDay, formatDateOnlyMonth } from 'services/helpers'
import { utilsPDF } from 'components/templates/PDF/shared/utils'
import { MAX_LESSONS_PER_PAGE } from '../../constants'

const { noBorderOnTop } = utilsPDF

function padNumberWithZeroes(num, amount = 3) {
  let numStr = num.toString()
  while (numStr.length < amount) {
    numStr = `0${numStr}`
  }
  return numStr
}

const buildTableHeader = ({ lessons = [], multiplyLessonBy }) => {
  const lessonsInfo = new Array(MAX_LESSONS_PER_PAGE)
    .fill({
      month: '',
      day: '',
      lessonNumber: ''
    })
    .map((lessonInfo, index) => {
      const lesson = lessons[index]
      const lessonNumber = index + 1 + multiplyLessonBy * MAX_LESSONS_PER_PAGE
      if (lesson) {
        return {
          month: formatDateOnlyMonth(lesson.date),
          day: formatDateOnlyDay(lesson.date),
          lessonNumber: padNumberWithZeroes(lessonNumber)
        }
      }
      return lessonInfo
    })

  return [
    [
      {
        text: '',
        border: [true, false, false, false],
        style: ['tableHeaderCell']
      },
      {
        text: '',
        border: [true, false, false, false],
        style: ['tableHeaderCell']
      },
      {
        text: 'Mês',
        bold: true,
        border: noBorderOnTop,
        style: ['tableHeaderCell']
      },
      ...lessonsInfo.map((lessonInfo) => ({
        text: lessonInfo.month,
        bold: true,
        border: noBorderOnTop,
        style: ['tableHeaderCell'],
        alignment: 'center'
      })),
      {
        text: '',
        border: [true, false, true, false],
        style: ['tableHeaderCell']
      }
    ],
    [
      {
        text: 'Nº',
        bold: true,
        style: ['tableHeaderCell'],
        border: [true, false, false, false]
      },
      {
        text: 'Nome',
        bold: true,
        style: ['tableHeaderCell'],
        border: [true, false, false, false]
      },
      { text: 'Dia', bold: true, style: ['tableHeaderCell'] },
      ...lessonsInfo.map((lessonInfo) => ({
        text: lessonInfo.day,
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center'
      })),
      {
        text: 'Status',
        bold: true,
        border: [true, false, true, false],
        style: ['tableHeaderCell'],
        alignment: 'center'
      }
    ],
    [
      {
        style: ['tableHeaderCell'],
        text: '',
        border: [true, false, false, true]
      },
      {
        style: ['tableHeaderCell'],
        text: '',
        border: [true, false, false, true]
      },
      { text: 'Aula', bold: true, style: ['tableHeaderCell'] },
      ...lessonsInfo.map((lessonInfo) => ({
        text: lessonInfo.lessonNumber ?? '',
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center'
      })),
      {
        style: ['tableHeaderCell'],
        text: '',
        border: [true, false, true, true]
      }
    ]
  ]
}
export default buildTableHeader
