import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { TabView } from 'components/organisms'
import { ROUTE } from 'services/constants'
import { useSnack } from 'services/hooks'
import { breadcrumbsItems } from './helpers'
import { useStudentOccurrence } from './components/TabOccurrences/Store/useStudentOccurrence'
import Header from './components/Header'
import { TABS } from './config'
import { StudentRegisterProvider, useStudentRegisterContext } from './context'

export default function StudentRegister() {
  return (
    <StudentRegisterProvider>
      <Content />
    </StudentRegisterProvider>
  )
}

const Content = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])
  const { register, name } = params
  const snackProps = useSnack()
  const { setSnack, updateSnack } = snackProps
  const { resetFormValues } = useStudentOccurrence()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [shouldUpdateData, setShouldUpdateData] = useState(true)
  const { studentInfo, getStudentInfo } = useStudentRegisterContext()
  const PATH_STUDENT = ROUTE.ADMIN_SECRETARY_STUDENTS

  useEffect(() => {
    getStudentInfo({
      register
    })
  }, [register])

  const onHandleConfirm = () => {
    setOpen(false)
    resetFormValues()
    navigate(PATH_STUDENT, {
      state: { savedSearch: true }
    })
  }

  const handleGoBack = () => {
    setOpen(false)
    resetFormValues()
    navigate(PATH_STUDENT, {
      state: { savedSearch: true }
    })
  }

  return (
    <TemplateDefaultHeaderByContent
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems(studentInfo.student.name)}
      onClickButton={() => setOpen(true)}
      open={open}
      handleCloseModal={() => handleGoBack()}
      handleConfirmModal={() => onHandleConfirm()}
      setLoading={setLoading}
      content={
        <>
          <Header
            studentInfo={studentInfo}
            setLoading={setLoading}
            setSnack={setSnack}
          />

          <TabView
            tabs={TABS}
            register={register}
            companyId={studentInfo?.student?.company_id}
            studentInfo={studentInfo}
            setSnack={setSnack}
            updateSnack={updateSnack}
            snackProps={snackProps}
            setLoading={setLoading}
            params={params}
            shouldUpdateData={shouldUpdateData}
            setShouldUpdateData={setShouldUpdateData}
          />
        </>
      }
    />
  )
}
