const random = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)

export const CreateUUID = (numberOfNodes = 3) => {
  let counter = 0
  let uuid = ''

  while (counter <= numberOfNodes) {
    counter += 1
    uuid += `${random()}`
  }

  return uuid
}
