import { FormUnitPole } from 'components/organisms'
import Responsible from '../Responsible'

const UNIT_DATA = 'Dados da unidade'
const POLE_DATA = 'Dados do polo'
const UNIT_RESPONSIBLE = 'Responsáveis'

const optionsTabs = (isPole, isEditForm) => {
  const dataForm = [
    {
      index: 0,
      getContent: ({
        nameUnit,
        nameUnitExist,
        namePoleExist,
        hasLinkedPoles,
        setDisabledLinkedPoles,
        isNameUnit,
        unitId,
        editForm,
        initialState,
        formUnitPole,
        setFormUnitPole,
        typePage,
        handleCreateOrUpdatePoleOrUnit,
        handleCreateResponsible,
        PATH
      }) => (
        <FormUnitPole
          nameUnit={nameUnit}
          hasLinkedPoles={hasLinkedPoles}
          isNameUnit={isNameUnit}
          unitId={unitId}
          setDisabledLinkedPoles={setDisabledLinkedPoles}
          nameUnitExist={nameUnitExist}
          namePoleExist={namePoleExist}
          editForm={editForm}
          initialState={initialState}
          handleCreateResponsible={handleCreateResponsible}
          formUnitPole={formUnitPole}
          setFormUnitPole={setFormUnitPole}
          typePage={typePage}
          handleCreateOrUpdatePoleOrUnit={handleCreateOrUpdatePoleOrUnit}
          PATH={PATH}
        />
      ),
      navigation: isPole ? POLE_DATA : UNIT_DATA,
      title: isPole ? POLE_DATA : UNIT_DATA
    }
  ]

  if (isEditForm !== 'edit') {
    return dataForm
  }

  return [
    ...dataForm,
    {
      index: 1,
      getContent: ({
        handleCreateResponsible,
        responsible,
        handleDeleteSignatureResponsible
      }) => (
        <Responsible
          handleCreateOrUpdateResponsible={handleCreateResponsible}
          initialValue={responsible}
          isPole={isPole}
          handleDeleteSignatureResponsible={handleDeleteSignatureResponsible}
        />
      ),
      navigation: UNIT_RESPONSIBLE,
      title: UNIT_RESPONSIBLE
    }
  ]
}

export { optionsTabs }
