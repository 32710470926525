import { lazy } from 'react'
import { Table, TableContainer } from '@mui/material'
import { TableHeaderDefault } from 'components/organisms'
import { headCells } from './constants'
import * as Styled from './style'

const TableTdBody = lazy(() => import('./TableBody'))

const TableVariables = ({
  itemsTable,
  handleTagClick,
  handleClick,
  values,
  setFieldValue
}) => (
  <Styled.TableContainer>
    <TableContainer>
      <Table size="small">
        <TableHeaderDefault
          headCells={headCells}
          rowCount={itemsTable?.length}
        />
        <TableTdBody
          handleTagClick={handleTagClick}
          handleClick={handleClick}
          content={itemsTable}
          values={values}
          setFieldValue={setFieldValue}
        />
      </Table>
    </TableContainer>
  </Styled.TableContainer>
)
export default TableVariables
