export const keyColumns = {
  name: 'name',
  unit: 'unit',
  course: 'course',
  schoolClass: 'schoolClass',
  stateStudent: 'stateStudent'
}

const tableColumns = [
  {
    id: keyColumns.name,
    label: 'Disciplina/Grupo'
  },
  {
    id: keyColumns.unit,
    label: 'Unidade'
  },
  {
    id: keyColumns.course,
    label: 'Curso'
  },
  {
    id: keyColumns.schoolClass,
    label: 'Turma'
  },
  {
    id: keyColumns.stateStudent,
    label: 'Alunos Ativos/Inativos'
  }
]

export { tableColumns }
