import FEEDBACK_SNACK from 'feedBackSnack'
import {
  reprocessing,
  updateExtract,
  updateResume,
  xlsxConciliation
} from 'services/api/admin/financial/Manager'
import { exportSpreadsheetByXLSX, updateErrorMessage } from 'services/helpers'
import { toastNotificationSuccess } from 'services/helpers/nofications'
import { MESSAGE, initialState } from './contants'
import { parserExtract, parserReprocessing } from './helpers'

const handleManager = async ({
  values,
  setLoading,
  setNotSearch,
  setListManager,
  setOptionsReprocessing
}) => {
  setLoading(true)

  const payload = {
    year: values?.year || initialState?.year,
    month: values?.month || initialState?.month,
    action: 'resume'
  }
  const { data: response, error, status } = await updateResume(payload)

  setLoading(false)

  if (error) {
    setNotSearch(true)

    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
    })
  }

  setOptionsReprocessing(parserReprocessing(response.list_reprocessing))

  return setListManager(parserExtract(response.resume))
}

const nfsZip = async ({ values, setSnack, setLoading, setNotSearch }) => {
  setLoading(true)

  const payload = {
    year: values?.year,
    month: values?.month,
    action: 'gl_nfs'
  }

  const { data: response, error, status } = await updateResume(payload)

  setLoading(false)

  if (error) {
    setNotSearch(true)

    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
    })
  }

  return window.open(response.link, '_blank')
}

const nfeZip = async ({ values, setLoading, setNotSearch }) => {
  setLoading(true)

  const payload = {
    year: values?.year,
    month: values?.month,
    action: 'gl_nfe'
  }

  const { data: response, error, status } = await updateResume(payload)

  setLoading(false)

  if (error) {
    setNotSearch(true)

    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
    })
  }

  return window.open(response.link, '_blank')
}

const sendConciliation = async ({
  values,
  stateFormSearch,
  setLoading,
  setNotSearch,
  setOpenModal,
  setPreviewNF
}) => {
  setLoading(true)

  const formData = new FormData()
  formData.append('year', stateFormSearch?.year)
  formData.append('month', stateFormSearch?.month)
  formData.append('file', values.file)
  formData.append('action', 'preview')

  const { data, error, status } = await xlsxConciliation(formData)

  setLoading(false)

  if (error) {
    setNotSearch(true)

    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
    })
  }

  setOpenModal(true)
  return setPreviewNF(data?.data)
}

const confirmConciliation = async ({
  setLoading,
  valuesSearch,
  setNotSearch,
  setOpenModal,
  setFieldValue,
  setListManager,
  setOptionsReprocessing
}) => {
  setLoading(true)

  const formData = new FormData()
  formData.append('year', valuesSearch?.year)
  formData.append('month', valuesSearch?.month)
  formData.append('action', 'conciliate')

  const { error, status } = await xlsxConciliation(formData)

  setLoading(false)
  setOpenModal(false)

  if (error) {
    setNotSearch(true)

    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
    })
  }

  toastNotificationSuccess(MESSAGE.SUCCESS_SEND, 2500)
  setFieldValue('file', '')

  return handleManager({
    values: valuesSearch,
    setLoading,
    setNotSearch,
    setListManager,
    setOptionsReprocessing
  })
}

const disabledButton = ({ file }) => !file

const extractManager = async ({ values, setLoading, setNotSearch }) => {
  setLoading(true)

  const payload = {
    year: values?.year,
    month: values?.month,
    action: 'extract'
  }

  const { data, error, status } = await updateExtract(payload)

  setLoading(false)

  if (error) {
    setNotSearch(true)

    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListStudents
    })
  }

  return exportSpreadsheetByXLSX(
    data,
    `extrato-nf-${values?.year}-${values?.month}.xlsx`
  )
}

const createReprocessing = async ({
  setLoading,
  values,
  stateFormSearch,
  setFieldValue,
  setNotSearch,
  setListManager,
  setOptionsReprocessing,
  setConfirmedReprocessing
}) => {
  setLoading(true)

  const payload = {
    year: stateFormSearch?.year,
    month: stateFormSearch?.month,
    params: values.cnpj
  }

  const { error, status } = await reprocessing(payload)
  setConfirmedReprocessing(false)

  if (error) {
    setLoading(false)

    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToReprocessing
    })
  }

  setFieldValue('cnpj', '')
  toastNotificationSuccess('Reporcessamento criado com sucesso', 1500)

  return handleManager({
    values: stateFormSearch,
    setLoading,
    setNotSearch,
    setListManager,
    setOptionsReprocessing
  })
}

export {
  nfsZip,
  nfeZip,
  sendConciliation,
  confirmConciliation,
  disabledButton,
  handleManager,
  extractManager,
  createReprocessing
}
