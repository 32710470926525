import { useState } from 'react'

export const useListWithRemoval = (initialList) => {
  const [list, setList] = useState(initialList)

  const removeItemsByIds = (itemIdsToRemove) => {
    const updatedList = list.filter(
      (item) => !itemIdsToRemove.includes(item.id)
    )
    setList(updatedList)
  }

  return { list, removeItemsByIds }
}
