import { httpDelete, httpGet, httpPost, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getOptionsCoordinator = async (params = {}) => {
  const queryParams =
    Object.keys(params).length > 0 ? `?${convertToParams(params)}` : ''

  const url = `${ENDPOINT.MANAGE_CLASSES.BASE}/${ENDPOINT.MANAGE_CLASSES.COORDINATOR}${queryParams}`

  const response = await httpGet(url)

  return response
}

export const getOptionsSchoolClass = async (params) => {
  const queryParams =
    Object.keys(params).length > 0 ? `?${convertToParams(params)}` : ''

  const url = `${ENDPOINT.MANAGE_CLASSES.BASE}${queryParams}`

  const response = await httpGet(url)

  return response
}

export const getListHRSchoolClass = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.MANAGE_CLASSES.BASE}/${
      ENDPOINT.MANAGE_CLASSES.LIST
    }?${convertToParams(params)}`
  )

  return response
}

export const getDetailsHRSchoolClass = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.MANAGE_CLASSES.BASE}/${
      ENDPOINT.MANAGE_CLASSES.DETAILS
    }?${convertToParams(params)}`
  )

  return response
}

export const createCheckedHRSchoolClass = async (payload) => {
  const response = await httpPost(
    `${ENDPOINT.MANAGE_CLASSES.BASE}/${ENDPOINT.MANAGE_CLASSES.CHECK}`,
    {
      ...payload
    }
  )

  return response
}

export const revertHRSchoolClass = async ({ payrollId, schoolClassesId }) => {
  const response = await httpDelete(
    `${ENDPOINT.MANAGE_CLASSES.BASE}/${ENDPOINT.MANAGE_CLASSES.REVERT}/${payrollId}`,
    {
      school_class_id: schoolClassesId
    }
  )

  return response
}

export const getDetailsHRManageReceipts = async (id) => {
  const response = await httpGet(`${ENDPOINT.MANAGE_RECEIPT.BASE}/${id}`)

  return response
}

export const sendManageReceipts = async (id) => {
  const response = await httpPut(`${ENDPOINT.MANAGE_RECEIPT.BASE}/${id}`)

  return response
}

export const sendManageConsolidation = async (payload) => {
  const response = await httpPut(`${ENDPOINT.CONSOLIDATION.BASE}`, {
    ...payload
  })

  return response
}

export const getListPayroll = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.PAYROLL.LIST}?${convertToParams(params)}`
  )

  return response
}

export const createPayroll = async (payload) => {
  const response = await httpPost(`${ENDPOINT.PAYROLL.SIMPLE_URL_PAYROLL}`, {
    ...payload
  })

  return response
}

export const editPayroll = async ({ id, payload }) => {
  const response = await httpPut(
    `${ENDPOINT.PAYROLL.SIMPLE_URL_PAYROLL}/${id}`,
    {
      ...payload
    }
  )

  return response
}

export const deletePayroll = async (idItem) => {
  const response = await httpDelete(
    `${ENDPOINT.PAYROLL.SIMPLE_URL_PAYROLL}/${idItem}`
  )

  return response
}

export const selectPayroll = async (id) => {
  const response = await httpGet(`${ENDPOINT.PAYROLL.SIMPLE_URL_PAYROLL}/${id}`)

  return response
}

export const getConsolidation = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.CONSOLIDATION.BASE}?${convertToParams(params)}`
  )

  return response
}

export const getOptionsProfessors = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.MANAGE_CLASSES.BASE}/${ENDPOINT.MANAGE_CLASSES.DETAILS}/${ENDPOINT.MANAGE_CLASSES.PROFESSORS}/${params.school_class_id}`
  )

  return response
}

export const getListProfessorTimekeeping = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.PROFESSORS.TIMEKEEPING}?${convertToParams(params)}`
  )

  return response
}

export const sendUniquePayroll = async ({ payrollId }) => {
  const response = await httpPut(
    `${ENDPOINT.PAYROLL.SIMPLE_URL_PAYROLL}/${payrollId}/status`
  )
  return response
}

export const sendPayrollsToSeniorAPI = async ({ payload }) => {
  const response = await httpPost(
    `${ENDPOINT.PAYROLL.SIMPLE_URL_PAYROLL}/send-senior-api`,
    payload
  )

  return response
}
