import { createContext, useState, useContext } from 'react'
import { initialState } from '../constants'

export const FormContext = createContext(initialState)

export const LinkedClassProvider = ({ children }) => {
  const [formValues, setFormValues] = useState(initialState)

  const resetFormValues = () => {
    setFormValues({ ...initialState })
  }

  return (
    <FormContext.Provider
      value={{
        formValues,
        setFormValues,
        resetFormValues
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useLinkedClass = () => useContext(FormContext)
