import { ListAlt, Search } from '@mui/icons-material'

const defaultItemsPerPage = 10
const defaultPage = 1
const countDefault = 2

const MESSAGE = {
  NO_SEARCH: {
    icon: <ListAlt />,
    text: '<strong>Encontre os tipos de atividade registradas.</strong><br />Você pode usar os critérios de filtro para realizar uma busca mais precisa.'
  },
  NOT_FOUND_SEARCH: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
  }
}

const initialState = {
  unit: '',
  name: '',
  level: '',
  course: '',
  inactive: false
}

export { MESSAGE, countDefault, defaultPage, defaultItemsPerPage, initialState }
