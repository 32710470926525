export function buildTableFooter({ courseInfo }) {
  const hasInternshipWorkload = !!courseInfo.internshipWorkload
  const totalWorkload =
    courseInfo.theoreticalWorkload +
    (courseInfo.internshipWorkload || courseInfo.practicalWorkload)

  return [
    [
      {
        text: 'Resumo de Carga horária',
        bold: true,
        style: ['tableHeaderCell'],
        colSpan: 7
      },
      {},
      {},
      {},
      {},
      {},
      {}
    ],
    [
      {
        text: 'Carga horária teórica',
        bold: true,
        colSpan: 6,
        style: ['tableBodyCell']
      },
      {},
      {},
      {},
      {},
      {},
      { text: courseInfo.theoreticalWorkload, style: ['tableBodyCell'] }
    ],
    [
      {
        text: hasInternshipWorkload
          ? 'Carga horária de estágio supervisionado'
          : 'Carga horária prática profissional',
        bold: true,
        colSpan: 6,
        style: ['tableBodyCell']
      },
      {},
      {},
      {},
      {},
      {},
      {
        text: courseInfo.internshipWorkload || courseInfo.practicalWorkload,
        style: ['tableBodyCell']
      }
    ],
    [
      {
        text: 'Carga horária total',
        bold: true,
        colSpan: 6,
        style: ['tableBodyCell']
      },
      {},
      {},
      {},
      {},
      {},
      {
        text: totalWorkload,
        style: ['tableBodyCell']
      }
    ]
  ]
}
