import React, { useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ErrorPage from 'components/pages/Shared/ErrorPage'
import { IsEmpty, useDebouncedEffect } from 'services/helpers'
import { TemplatePrivate } from 'components/templates/Shared'
import { LoadingPage } from 'components/molecules'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import GlobalContext from '../store/GlobalContext'
import PUBLIC_ROUTES from './publicRoutes'
import RoutePrivate from './Component/Route'
import { getPriviteRoutes } from './allRoutes'

const RoutesMain = () => {
  const { user } = useContext(GlobalContext)
  const { loading } = useLoading()
  const isEmptyUser = IsEmpty(user)
  const userPermissions = isEmptyUser ? {} : user.permissions

  const mapRecursiveSubmenuRoute = (route) =>
    route.path && (
      <>
        <React.Fragment key={route.path}>
          <Route path="*" element={<ErrorPage path={route.path} />} />
          <Route element={<TemplatePrivate />}>
            <Route element={<RoutePrivate route={route} />} path={route.path}>
              <Route element={<route.component />} />
            </Route>
          </Route>
        </React.Fragment>
        {route.submenu &&
          !IsEmpty(route.submenu) &&
          route.submenu.map((r) => mapRecursiveSubmenuRoute(r))}
      </>
    )

  useDebouncedEffect(() => {
    const isAvailableToViewZendesk =
      !['professor', 'student'].includes(user?.role) &&
      window.location.pathname !== '/'

    const zendeskIframe = document.getElementById('launcher')

    if (zendeskIframe) {
      const iframeContent = zendeskIframe.contentWindow.document

      const zendeskHelpButton = iframeContent.getElementById('Embed')

      zendeskHelpButton.style.display =
        !isAvailableToViewZendesk && zendeskHelpButton ? 'none' : 'flex'
    }
  }, 800)

  return (
    <BrowserRouter>
      <LoadingPage open={loading} />
      <Routes>
        {PUBLIC_ROUTES.map((route) => (
          <React.Fragment key={route.path}>
            <Route path="*" element={<ErrorPage path={route.path} />} />
            <Route element={<route.template />}>
              <Route path={route.path} element={<route.component />} />
            </Route>
          </React.Fragment>
        ))}

        {isEmptyUser
          ? null
          : getPriviteRoutes().map((route) => {
              const checkAllowedfirstLevelMenu =
                route.isAllowed && route.isAllowed(userPermissions)

              return route.submenu && !IsEmpty(route.submenu)
                ? route.submenu
                    .filter(
                      (item) =>
                        item.isAllowed && item.isAllowed(userPermissions)
                    )
                    .map(mapRecursiveSubmenuRoute)
                : route.path && checkAllowedfirstLevelMenu && (
                    <React.Fragment key={route.path}>
                      <Route
                        path="*"
                        element={<ErrorPage path={route.path} />}
                      />
                      <Route element={<TemplatePrivate />}>
                        <Route
                          element={
                            <RoutePrivate route={route} path={route.path} />
                          }
                          path={route.path}
                        >
                          <Route element={<route.component />} />
                        </Route>
                      </Route>
                    </React.Fragment>
                  )
            })}
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesMain
