import { WarningAmber } from '@mui/icons-material'
import styled from 'styled-components'
import theme from 'theme/designTokens'

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 16px 0;
`

const Form = styled.form`
  margin: 23px 0;
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${() => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export { Box, Form, IconWarningAmber }
