import { objResponse } from './contants'

const parserReprocessing = (data) =>
  data.reduce(
    (acc, item) => [
      ...acc,
      {
        label: item.option,
        value: item.value
      }
    ],
    []
  )

const calculateNewValue = (obj, data, statusConditions, type) => {
  const property = statusConditions[obj.id]
  const { qtde } = data.find(
    ({ status }) => status.includes(property) && status.includes(type)
  ) || {
    qtde: 0
  }

  return qtde
}

const processExtract = (data, type, statusConditions) =>
  objResponse.map((obj) => ({
    ...obj,
    value: calculateNewValue(obj, data, statusConditions, type)
  }))

const parserExtract = (data) => {
  const statusConditions = {
    fila: 'FILA',
    erro_autorizacao: 'erro_autorizacao',
    autorizado: 'autorizado',
    cancelado: 'cancelado',
    processando_autorizacao: 'processando_autorizacao'
  }

  return {
    nfe: processExtract(data, 'NFE', statusConditions),
    nfs: processExtract(data, 'NFS', statusConditions)
  }
}

export { parserExtract, parserReprocessing }
