import { Typography } from '@mui/material'
import styled from 'styled-components'

const CardGroup = styled.div`
  display: flex;
`
const CardGroupTitle = styled.div`
  margin-left: 10px;
`

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: ${({ $hasfontWeight }) => $hasfontWeight && 'bold'};
    color: ${({ theme }) => theme.palette.dark.medium};
  }
`

export { CardGroup, CardGroupTitle, Title }
