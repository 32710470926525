import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'

const Title = styled.h3`
  font-size: 32px;
  font-weight: 500;
  margin: 0 0 16px 0;
`

const PeriodText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.15px;
`

const Footer = styled(MuiGrid)`
  &.MuiGrid-root {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:last-child button {
      margin-left: 1rem;
    }
  }
`

export { Title, PeriodText, Footer }
