import { Box, Tooltip, Typography, styled } from '@mui/material'

const BoxReplacement = styled(Box)(({ theme, size, fontSize }) => ({
  width: size ?? '21px',
  height: size ?? '21px',
  display: 'inline-block',
  background: theme.palette.blue.main,
  borderRadius: '2px'
}))

export default function ReplacementIcon({ tooltipTitle, size, fontSize }) {
  return (
    <Tooltip title={tooltipTitle} placement="top" arrow>
      <BoxReplacement size={size}>
        <Typography color="white" textAlign="center" fontSize={fontSize}>
          S
        </Typography>
      </BoxReplacement>
    </Tooltip>
  )
}
