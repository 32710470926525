import { ROUTE } from 'services/constants'

const defaultItemsPerPage = 10
const defaultTotalPage = 1
const countDefault = 0

const initialState = {
  course: '',
  coordinator_id: '',
  school_class_id: ''
}

const MESSAGE = {
  EMPTY_RESULT:
    'Não encontramos um resultado para essa busca.<br/> Verifique os dados informados e tente novamente.',
  NOT_SEARCH:
    '<b>Você ainda não fez uma busca</b><br /> Selecione uma opção e você pode combinar com os filtros para obter uma pesquisa mais específica por turmas.'
}

const breadcrumbsItems = [
  {
    path: ROUTE.ADMIN_HR_TIMEKEEPING,
    label: 'RH'
  },
  {
    path: ROUTE.ADMIN_HR_TIMEKEEPING,
    label: 'Apontamento de Horas'
  },
  {
    path: ROUTE.ADMIN_HR_MANAGE_CLASSES,
    label: '<b>Gerenciar Turmas</b>'
  }
]

export {
  breadcrumbsItems,
  initialState,
  MESSAGE,
  defaultItemsPerPage,
  defaultTotalPage,
  countDefault
}
