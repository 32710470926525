import styled from 'styled-components'
import {
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
  Typography,
  Button as MuiButton
} from '@mui/material'

const Card = styled(MuiCard)`
  &.MuiCard-root {
    height: 100px;
    width: ${({ $width }) => $width || '178px'};
    border-bottom: 5px solid;
    border-color: ${(props) => props.$hasColor};
    transition: all 0.2s ease-out;

    &:hover {
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px rgb(0 0 0 / 12%);
    }
  }
`

const CardActionArea = styled(MuiCardActionArea)`
  &.MuiCardActionArea-root {
    height: 100%;
    text-align: center;
    padding: 16px;
    opacity: ${({ disabled, $opacity }) => $opacity || (disabled && '0.5')};
  }
`

const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 14px;
  }
`
const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: bold;
    font-size: 40px;
    line-height: 116.7%;
    color: ${(props) => props.$hasColor};
  }
`

const SubTitle = styled(Typography)`
  &.MuiTypography-root {
    font-weight: normal;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.4px;
    line-height: 166%;
  }
`

const Button = styled(MuiButton)`
  &.MuiButton-root {
    color: ${(props) => props.$hasColor};
    border-color: ${(props) => props.$hasColor};
    opacity: ${({ disabled, $opacity }) => $opacity || (disabled && '0.5')};

    &:hover {
      border-color: ${(props) => props.$hasColor};
    }
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ $padding }) => $padding || 0};
`
export { Card, CardActionArea, BoxTitle, Title, SubTitle, Container, Button }
