const SHARED = {
  GROUPS: '/finances/groups',
  SCHOOL_CLASSES_STUDENT: 'school-classes/student',
  STUDENTS_COURSES: 'students/courses',
  STUDENTS_DETAILS: 'students/details',
  UNITS: '/units',
  EVALUATIONS: 'school-classes/evaluations',
  MODALITIES: 'school-classes/modalities'
}

export { SHARED }
