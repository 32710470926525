const initialValues = {
  cpf: '',
  crnm: '',
  nome: '',
  country: 'br',
  nome_social: '',
  rg: '',
  nascimento: '',
  email: '',
  cep: '',
  uf: '',
  cidade: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  metUs: '',
  sexo: '',
  civil: '',
  nacionalidade: '',
  naturalidade: '',
  naturalidade_cidade: '',
  pai: '',
  mae: '',
  celular: '',
  telefone: '',
  contato: '',
  observacao: '',
  financialOfficer: false,
  cpf_fin: '',
  cnpj: '',
  nome_fin: '',
  ieCnpj: '',
  nome_social_fin: '',
  rg_fin: '',
  nasc_fin: '',
  email_fin: '',
  cep_fin: '',
  uf_fin: '',
  cidade_fin: '',
  end_fin: '',
  numero_fin: '',
  comp_fin: '',
  bairro_fin: '',
  metUs_fin: '',
  sexo_fin: '',
  civil_fin: '',
  nacio_fin: '',
  naturalidade_uf_fin: '',
  tel_fin: '',
  mobile_phone_fin: '',
  observacao_fin: ''
}

export { initialValues }
