/* eslint-disable camelcase */
const disciplineDelete = ({ selected }) => ({
  ids: selected
})

const payloadEditDiscipline = (values) => ({
  name: values?.name,
  type_score: values?.type_score,
  reference_code: values?.reference_code,
  joy_course_api_key: values?.joy_course_api_key
})

const parseSelectDisciplines = (data) =>
  data?.data?.reduce((acc, cur) => {
    const { discipline } = cur
    const newItem = { value: discipline, label: discipline }
    return [...acc, newItem]
  }, [])

const parseSelectDisciplinesJoy = (data) =>
  data?.reduce((acc, cur) => {
    const { name, api_key } = cur
    const newItem = { value: api_key, label: name }
    return [...acc, newItem]
  }, [])

const parseUpdateDisciplines = (data) =>
  data?.reduce((acc, cur) => {
    const { id, discipline, type_score, joy_course_api_key, reference_code } =
      cur
    const newItem = {
      id,
      name: discipline,
      type_score,
      joy_course_api_key,
      reference_code
    }
    return [...acc, newItem]
  }, [])

export {
  disciplineDelete,
  payloadEditDiscipline,
  parseSelectDisciplines,
  parseSelectDisciplinesJoy,
  parseUpdateDisciplines
}
