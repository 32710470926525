import { httpGet, httpPut } from 'services/api/http'
import { ENDPOINT } from 'services/constants'
import { convertToParams } from 'services/helpers'

export const getStudentsUnifyEnrollent = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.SYSTEM.ENROLLMENTS.SEARCH}?${convertToParams(params)}`
  )
  return response
}

export const getSimilarities = async (params) => {
  const response = await httpGet(
    `${ENDPOINT.SYSTEM.ENROLLMENTS.SIMILARITIES}`,
    { params }
  )
  return response
}

export const getUnify = async ({ ids, id }) => {
  const response = await httpPut(
    `${ENDPOINT.SYSTEM.ENROLLMENTS.UNIFY_ENROLLMENT}/${id}?${ids}`
  )

  return response
}
