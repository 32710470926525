import { cleanObjectWithZero } from 'services/helpers/cleanObject'
import { registrationDirect } from 'services/api/admin'
import { toastNotificationSuccess, updateErrorMessage } from 'services/helpers'
import { FEEDBACK_COMMERCIAL } from 'feedBackSnack/Admin/commercial'
import { getBodyToRequest } from './helpers'
import { FINAL_STEP, MESSAGE, initialStateForm } from './constants'

const confirmRegister = async ({
  values,
  setContentForm,
  setEnrollmentDetails,
  setActiveStep,
  setLoadingVisibility
}) => {
  setLoadingVisibility(true)
  const body = getBodyToRequest(values)

  const filteredBody = cleanObjectWithZero({ ...body })

  const {
    data: response,
    error,
    status
  } = await registrationDirect(filteredBody)

  if (error) {
    return updateErrorMessage({
      error,
      status,
      feedbackMessage:
        FEEDBACK_COMMERCIAL.noAccessPermissionTocreateDirectEnrollment
    })
  }

  setEnrollmentDetails(response.data)
  setActiveStep(FINAL_STEP)
  setLoadingVisibility(false)
  setContentForm(initialStateForm)
  return toastNotificationSuccess(MESSAGE.success, 1500)
}

export { confirmRegister }
