import { Link } from 'react-router-dom'
import { CardMedia, CardContent, Tooltip } from '@mui/material'
import { colorSuccess, colorSecondary } from './constants'
import * as Styled from './style'

const DisciplineCard = ({
  classNameJoy,
  classNameDiary,
  title,
  subTitle,
  children,
  disabledDiary,
  image,
  joyAccessMessage,
  dailyAccessMessage,
  dailyLink,
  linkJoy,
  fisrtIcon,
  secondIcon
}) => (
  <Styled.Card>
    <Styled.CardHeader title={title} subheader={subTitle} />
    <CardMedia component="img" height="194" image={image} alt={image} />

    <CardContent>{children}</CardContent>

    <Styled.CardActions $disabledDiary={disabledDiary}>
      <Tooltip title={joyAccessMessage} placement="top">
        <Styled.LinkWrapper color={colorSecondary}>
          <a
            href={linkJoy}
            target={linkJoy ? '_blank' : '_self'}
            rel="noreferrer"
            className={classNameJoy}
          >
            {fisrtIcon}
          </a>
        </Styled.LinkWrapper>
      </Tooltip>

      <Tooltip title={dailyAccessMessage} placement="top">
        <Styled.LinkWrapper color={colorSuccess}>
          <Link
            className={classNameDiary}
            to={dailyLink}
            rel="noreferrer"
            title={dailyLink}
          >
            {secondIcon}
          </Link>
        </Styled.LinkWrapper>
      </Tooltip>
    </Styled.CardActions>
  </Styled.Card>
)
export default DisciplineCard
