import { useEffect } from 'react'
import { Tab, Tabs } from '@mui/material'
import TabContents from 'components/organisms/Tabs/TabContents'
import theme from 'theme/designTokens'
import { optionsTabs } from './tabs'
import * as Styled from './style'

const TabsBindByUnbinded = ({
  filter,
  itemUnbinded,
  itemBind,
  countUnbinded,
  countBind,
  totalPageUnbinded,
  setPageUnbinded,
  totalPageBind,
  setPageBind,
  pageUnbinded,
  setFilter,
  pageBind,
  classId,
  setSnack,
  setLoadingOpen,
  setListUnbinded,
  setCountUnbinded,
  setCountBind,
  setListBind,
  tabSelected,
  setTabSelected
}) => {
  const tabs = optionsTabs({
    filter,
    itemUnbinded,
    itemBind,
    countUnbinded,
    countBind,
    totalPageUnbinded,
    setPageUnbinded,
    totalPageBind,
    setPageBind,
    pageUnbinded,
    setFilter,
    pageBind,
    classId,
    setSnack,
    setLoadingOpen,
    setListUnbinded,
    setCountUnbinded,
    setCountBind,
    setListBind,
    setTabSelected
  })

  const handleTabChange = (event, newValue) => {
    setTabSelected(newValue)
  }

  useEffect(() => {
    if (tabSelected >= tabs.length) {
      setTabSelected(0)
    }
  }, [tabs])

  return (
    <>
      <Styled.Box $borderBottom={1} $borderColor={theme.palette.gray.light}>
        <Tabs value={tabSelected} onChange={handleTabChange}>
          {tabs.map(({ label }) => (
            <Tab key={label} label={label} />
          ))}
        </Tabs>
      </Styled.Box>

      {tabs?.map((content, index) => {
        const contentKey = `tab-content-${index}`
        return (
          <Styled.WrapperContent key={contentKey}>
            <TabContents index={index} key={contentKey} value={tabSelected}>
              {content.component()}
            </TabContents>
          </Styled.WrapperContent>
        )
      })}
    </>
  )
}

export { TabsBindByUnbinded }
