/* eslint-disable camelcase */

const parseList = (data) =>
  [data]?.reduce((acc, cur) => {
    const { name, email, phone_number } = cur

    const newItem = {
      name,
      email,
      phone_number
    }
    return { ...acc, ...newItem }
  }, {})

export { parseList }
