const maxLengthPhones = 15
const maxLengthCNPJ = 18
const maxLengthCEP = 9
const IS_EDIT = 'edit'

const ERROR_MESSAGE_SEARCH_CEP = 'Este CEP não existe. Digite um CEP existente!'
const EXIST_NAME_POLE = 'Nome de polo já cadastrado, utilize outro nome!'
const EXIST_NAME_UNIT = 'Nome da unidade já cadastrado, utilize outro nome!'
const INFO_CEP =
  'Após preencher o CEP os campos Endereço, Bairro, Cidade e Estado serão preenchidos automaticamente, caso não forem preencha você mesmo!'

const disabledButton = (formUnitPole) => {
  if (
    !!formUnitPole.namePole ||
    !!formUnitPole.responsiblePhone ||
    !!formUnitPole.unit ||
    !!formUnitPole.pabx ||
    !!formUnitPole.supervisoryBody ||
    !!formUnitPole.activeEnrollment ||
    !!formUnitPole.cnpj ||
    !!formUnitPole.socialReason ||
    !!formUnitPole.email ||
    !!formUnitPole.academicName ||
    !!formUnitPole.cep ||
    !!formUnitPole.address ||
    !!formUnitPole.numberAddress ||
    !!formUnitPole.neighborhood ||
    !!formUnitPole.city ||
    !!formUnitPole.state ||
    !!formUnitPole.responsible ||
    !!formUnitPole.responsiblePhone ||
    !!formUnitPole.firstPhone ||
    !!formUnitPole.secondPhone ||
    !!formUnitPole.resolution ||
    !!formUnitPole.credential
  )
    return false
  return true
}

export {
  ERROR_MESSAGE_SEARCH_CEP,
  EXIST_NAME_POLE,
  EXIST_NAME_UNIT,
  INFO_CEP,
  IS_EDIT,
  disabledButton,
  maxLengthPhones,
  maxLengthCNPJ,
  maxLengthCEP
}
