import * as yup from 'yup'

const MAX_LENGTH_CEP = 9
const MAX_LENGTH_PHONE = 15
const MAX_LENGTH_CNPJ = 18
const MAX_PHONE_ADDED = 3
const MIN_LENGTH_RAZAO_SOCIAL = 3

const validationSchema = yup.object().shape({
  razaoSocial: yup.string().required('Campo obrigatório.'),
  tradeName: yup.string().required('Campo obrigatório.'),
  cnpj: yup.string().required('Campo obrigatório.'),
  email: yup.string().optional(),
  zipCode: yup.string().optional(),
  state: yup.string().optional(),
  city: yup.string().optional(),
  address: yup.string().optional(),
  addressNumber: yup.string().optional(),
  complement: yup.string().optional(),
  district: yup.string().optional(),
  phone: yup
    .array(
      yup.object().shape({
        cell: yup.string().optional()
      })
    )
    .min(0)
})

const typeOptions = {
  B2C: 'B2C',
  B2G: 'B2G',
  B2B: 'B2B'
}
const typeOptionsToSelect = Object.entries(typeOptions).map(([key, value]) => ({
  value,
  label: key
}))

const defaultValues = {
  razaoSocial: '',
  tradeName: '',
  cnpj: '',
  stateRegistration: '',
  municipalRegistration: '',
  type: typeOptions.B2C,
  email: '',
  zipCode: '',
  city: '',
  state: '',
  address: '',
  addressNumber: '',
  district: '',
  complement: '',
  phone: [
    {
      id: Math.random(),
      cell: ''
    }
  ]
}

export {
  defaultValues,
  validationSchema,
  MAX_LENGTH_PHONE,
  MAX_LENGTH_CEP,
  MAX_LENGTH_CNPJ,
  MAX_PHONE_ADDED,
  MIN_LENGTH_RAZAO_SOCIAL,
  typeOptions,
  typeOptionsToSelect
}
