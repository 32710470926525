import styled from 'styled-components'
import { Box } from '@mui/material'

const BoxButton = styled(Box)`
  &.MuiBox-root {
    display: flex;
    justify-content: center;
  }
`

export { BoxButton }
