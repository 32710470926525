import * as yup from 'yup'

const NOVOTEC_ID = '932'

const formSchema = (hasPole) => [
  yup.object().shape({
    unidade: yup.string().required('Campo obrigatório'),
    polo: yup.string().when('unidade', {
      is: (val) => val !== NOVOTEC_ID && hasPole,
      then: yup.string().required('Campo obrigatório')
    }),
    grupo: yup.string().required('Campo obrigatório'),
    level: yup.string().required('Campo obrigatório'),
    curso: yup.string().required('Campo obrigatório'),
    turno: yup.string().required('Campo obrigatório'),
    modulo: yup.string().required('Campo obrigatório')
  }),
  yup.object().shape({
    nome: yup
      .string()
      .min(8, 'Nome inválido, deve ter 8 ou mais caracteres')
      .required('Campo obrigatório'),
    cpf: yup.string().required('Campo obrigatório')
  })
]

export { formSchema }
