const messageServiceExclude = {
  successMessage: 'Registro de expedição excluído com sucesso.',
  feedbackMessage:
    'Houve um erro ao excluir registro de expedição. Tente novamente.'
}

const messageModalConfirm = {
  title: 'Excluir',
  textButtonConfirm: 'Sim',
  textButtonNotConfirm: 'Não',
  message:
    'Deseja remover esse registro de expedição ? Você perderá todas as informações sobre ele.'
}

const MESSAGE = {
  SUCCESS: 'Registro de expedição excluído com sucesso.'
}

export { MESSAGE, messageServiceExclude, messageModalConfirm }
