import { Paper, Table, TableBody, TableCell } from '@mui/material'
import { PaginationAdvanced } from 'components/molecules'
import { TableHeaderDefault } from 'components/organisms'
import { formatDateTimePtBr, paginationFilter } from 'services/helpers'
import { useSortTable } from 'services/hooks'
import {
  DEFAULT_ITEMS_PER_PAGE,
  LIMIT as DEFAULT_PAGE,
  selectTypeSearch
} from '../../constants'
import { headCellsDetails, headCellsPage } from './config'
import * as Styled from './style'

export default function List({
  typeSearch,
  count,
  filter,
  setFilter,
  setPage,
  totalPage,
  currentPage,
  rowsItems
}) {
  const { handleRequestSort, order, orderBy, sortTable } =
    useSortTable(rowsItems)

  const handleSetPage = (_, page) => {
    setPage(+page)
  }

  return (
    <Styled.Wrapper>
      <Paper>
        <Table size="medium">
          <TableHeaderDefault
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={
              (typeSearch.search_type === selectTypeSearch?.pages &&
                headCellsPage) ||
              headCellsDetails
            }
          />
          <TableBody>
            {sortTable?.map(({ id, datetime, user, ip, page, details }) => (
              <Styled.TableRow key={id}>
                <TableCell>{formatDateTimePtBr(datetime)}</TableCell>
                <TableCell>{user}</TableCell>
                <TableCell>{ip}</TableCell>
                <TableCell>{page || details}</TableCell>
              </Styled.TableRow>
            ))}
          </TableBody>
        </Table>

        {count > DEFAULT_ITEMS_PER_PAGE && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={currentPage}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setPage(DEFAULT_PAGE)
              setFilter(e.target.value)
            }}
          />
        )}
      </Paper>
    </Styled.Wrapper>
  )
}
