import styled, { css } from 'styled-components'

const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`

const EmptyState = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;

  ${({ $isCenter }) =>
    $isCenter &&
    css`
      justify-content: center;
    `}
`

export { Content, EmptyState }
