/* eslint-disable camelcase */
import { typeMask } from 'services/helpers'
import { allowedCountry, enrollmentOriginList } from './constants'

const textDiscountedInstallments = ({ couponData, detailsCampaign }) => {
  const discountedInstallments = typeMask(
    'MONEY_NUMBER',
    couponData?.discounted_installments?.value
  )
  const installments = typeMask('MONEY_NUMBER', couponData?.installments?.value)

  const monthlyInstallmentsTitle = couponData?.discounted_installments
    ? `${
        couponData?.discounted_installments?.count - 1
      }x de ${`${discountedInstallments}`}`
    : ''

  const regularInstallmentsInfo = couponData?.installments
    ? ` + ${
        Number(couponData?.installments?.count) +
        Number(detailsCampaign.installments ?? 0)
      }x de ${installments}`
    : ''

  return `${monthlyInstallmentsTitle} ${regularInstallmentsInfo}`
}

const parserBody = ({
  values,
  group,
  enrollmentId,
  dateBirth,
  financialOfficer,
  dateBirthFin,
  dataCoupon,
  anticipation
}) => ({
  group,
  ...(allowedCountry.includes(values.country) && { rg: values.rg }),
  cpf: values.cpf,
  crnm: values.crnm,
  country: values.country,
  city: values.cidade,
  name: values.nome,
  cep: values.cep,
  state: values.uf,
  email: values.email,
  gender: values.sexo,
  enrollment_id: enrollmentId,
  father_name: values.pai,
  address: values.endereco,
  meet_proz: values.metUs,
  birthdate: dateBirth,
  note: values.observacao,
  mother_name: values.mae,
  contact_time: values.contato,
  district: values.bairro,
  social_name: values.nome_social,
  complement: values.complemento,
  nationality: values.nacionalidade,
  number: values.numero.toString(),
  civil_status: values.civil,
  mobile_phone: values.celular,
  birth_place_uf: values.naturalidade,
  birth_place_city: values.naturalidade_cidade,
  phone: !values.telefone ? null : values.telefone,
  enrollment_origin: values.enrollmentOrigin,

  ...(allowedCountry.includes(values.nacio_fin) && {
    rg_fin: values.rg_fin
  }),
  cpf_fin: financialOfficer === 'cnpj' ? values.cnpj : values.cpf_fin,
  ie_cnpj: String(values.ieCnpj),
  name_fin: values.nome_fin,
  city_fin: values.cidade_fin,
  state_fin: values.uf_fin,
  cep_fin: values.cep_fin,
  email_fin: values.email_fin,
  gender_fin: values.sexo_fin,
  ...(financialOfficer !== 'student' &&
    financialOfficer !== 'cnpj' && {
      birthdate_fin: dateBirthFin
    }),
  address_fin: values.end_fin,
  note_fin: values.observacao_fin,
  district_fin: values.bairro_fin,
  social_name_fin: values.nome_social_fin,
  nationality_fin: values.nacio_fin,
  number_fin: !values.numero_fin ? null : values.numero_fin.toString(),
  civil_status_fin: values.civil_fin,
  birth_place_uf_fin: values.naturalidade_uf_fin,
  anticipation,
  coupon_id: dataCoupon ? dataCoupon.coupon_id.toString() : null,
  mobile_phone_fin: !values.mobile_phone_fin
    ? null
    : values.mobile_phone_fin.toString(),
  phone_fin: !values.tel_fin ? null : values?.tel_fin.toString()
})

const parserResponse = ({
  cpf,
  nome,
  sexo,
  civil,
  nome_social,
  country,
  crnm,
  nacionalidade,
  rg,
  nascimento,
  naturalidade,
  naturalidade_cidade,
  email,
  pai,
  mae,
  cep,
  cidade,
  bairro,
  uf,
  meet_proz,
  endereco,
  numero,
  telefone,
  celular,
  complemento,
  observacao,
  contact_time
}) => ({
  cpf,
  nome,
  sexo,
  nome_social,
  civil,
  country,
  nacionalidade,
  rg,
  nascimento,
  naturalidade,
  crnm,
  naturalidade_cidade,
  email,
  pai,
  mae,
  cep,
  uf,
  cidade,
  bairro,
  endereco,
  numero,
  complemento,
  telefone,
  celular,
  metUs: meet_proz,
  contato: contact_time,
  observacao,
  financialOfficer: false
})

const getEnrollmentOriginLabel = (value) =>
  enrollmentOriginList.find((el) => el.value === value)?.label

export {
  textDiscountedInstallments,
  parserResponse,
  parserBody,
  getEnrollmentOriginLabel
}
