const sortDirection = 'desc'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator(order, orderBy) {
  return (a, b) => {
    const comparison = descendingComparator(a, b, orderBy)
    return order === sortDirection ? comparison : -comparison
  }
}

export function stableSort(array, comparator) {
  if (!Array.isArray(array)) return []

  const indexes = Array.from({ length: array.length }, (_, index) => index)

  indexes.sort((a, b) => {
    const order = comparator(array[a], array[b])
    if (order !== 0) return order
    return a - b
  })

  return indexes.map((index) => array[index])
}
