const cancel = 'cancel'
const defer = 'defer'
const tabHistoric = 'historic'

const defaultItemsPerPage = 10
const defaultTotalPage = 1

const typeAction = {
  remove: 'remove',
  copy: 'copy'
}

export {
  cancel,
  defer,
  tabHistoric,
  defaultItemsPerPage,
  typeAction,
  defaultTotalPage
}
