const emptyValue = ''
const emptyHoursValue = 0

const headCells = [
  {
    id: 'content_description',
    numeric: false,
    disablePadding: true,
    label: 'Conteúdo'
  },
  {
    numeric: false,
    disablePadding: false,
    disabled: true,
    label: 'Capítulo/Unidade'
  },
  {
    numeric: false,
    disablePadding: false,
    disabled: true,
    label: 'Carga horária'
  }
]

export { headCells, emptyValue, emptyHoursValue }
