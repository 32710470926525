import { deserialize } from 'services/helpers'

const parseAcademicPerformanceDisciplines = (items) =>
  items.map(
    ({
      discipline,
      discipline_workload: disciplineWorkload,
      academic_performance: academicPerformance
    }) => ({
      discipline,
      disciplineWorkload,
      id: academicPerformance.id,
      score: academicPerformance.score ?? '',
      disciplineOrigin: academicPerformance.discipline_origin,
      applier: academicPerformance.applier ?? '',
      institution: academicPerformance.institution ?? '',
      conclusion: academicPerformance.conclusion ?? '',
      status: academicPerformance.status ?? '',
      observation: academicPerformance.observation ?? ''
    })
  )

const parseUpdateAcademicPerformanceDisciplines = ({
  academicPerformances,
  ...rest
}) => ({
  ...deserialize(rest),
  academic_performances: academicPerformances.map(
    ({
      id,
      status,
      disciplineOrigin,
      institution,
      conclusion,
      applier,
      score,
      observation
    }) => ({
      id,
      status,
      discipline_origin: disciplineOrigin,
      institution,
      conclusion,
      applier,
      score,
      observation
    })
  )
})

export {
  parseAcademicPerformanceDisciplines,
  parseUpdateAcademicPerformanceDisciplines
}
