import { useState } from 'react'
import { Grid, Snackbar } from '@mui/material'
import { TemplateInvoices as Template } from 'components/templates/Admin'
import { HeaderPage } from 'components/organisms'
import { BoxItem } from 'components/atoms'
import { useSnack } from 'services/hooks'
import Content from './components/Content'
import * as Styled from './style'
import { FINAL_STEP, INITIAL_STEP, initialStateForm } from './constants'

const DirectEnrollment = () => {
  const { snack, snackOpen, setSnack } = useSnack()
  const [activeStep, setActiveStep] = useState(INITIAL_STEP)

  const [contentForm, setContentForm] = useState(initialStateForm)

  return (
    <Template
      titleHeader={
        <HeaderPage title="<b> Comercial </b> - Matrícula Direta" divider>
          {activeStep === FINAL_STEP && (
            <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12} sm={12} md={4}>
                <BoxItem title="Unidade" subTitle={contentForm?.unidade} />
                <BoxItem title="Nível" subTitle={contentForm?.level} />
                <BoxItem title="Curso" subTitle={contentForm?.curso} />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                {contentForm?.polo && (
                  <BoxItem title="Polo" subTitle={contentForm?.polo} />
                )}
                <BoxItem
                  title="Modalidade"
                  subTitle={contentForm?.ead ? 'Online' : 'Presencial'}
                />
                <BoxItem title="Grupo" subTitle={contentForm?.grupo} />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <BoxItem title="Turno" subTitle={contentForm?.turno} />
              </Grid>
            </Styled.Grid>
          )}
        </HeaderPage>
      }
      content={
        <Content
          setSnack={setSnack}
          activeStep={activeStep}
          contentForm={contentForm}
          setActiveStep={setActiveStep}
          setContentForm={setContentForm}
        />
      }
      snackAlert={
        <Snackbar
          message={snack.message}
          severity={snack.severity}
          shouldOpen={snackOpen}
        />
      }
    />
  )
}

export default DirectEnrollment
