import { Formik } from 'formik'
import { Grid, TextField, Tooltip } from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import { FilePresent } from '@mui/icons-material'
import { Button } from 'components/atoms'
import { initialValues } from './config'
import * as Styled from './style'

const FormGeneralData = ({ setOpenModal, requirement }) => (
  <Formik initialValues={initialValues} enableReinitialize>
    {({ values, handleSubmit, setFieldValue, handleChange }) => (
      <Styled.Form onSubmit={handleSubmit} noValidate autoComplete="off">
        <Grid
          justifyContent="center"
          alignItems="center"
          container
          spacing={{ xs: 2, sm: 2 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Styled.Title variant="h4">{requirement?.name}</Styled.Title>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="typeRequirement"
              label="Tipo de Requerimento"
              value={requirement?.requirement_type || ''}
              fullWidth
              onChange={(e) => setFieldValue('typeRequirement', e.target.value)}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <TextField
              id="initialSector"
              label="Setor inicial"
              value={requirement?.sector || ''}
              fullWidth
              onChange={(e) => setFieldValue('initialSector', e.target.value)}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <TextField
              id="responsible"
              label="Responsável"
              value={requirement?.responsible_sector || ''}
              fullWidth
              onChange={(e) => setFieldValue('responsible', e.target.value)}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="class"
              label="Turma"
              value={requirement?.school_class || ''}
              fullWidth
              onChange={(e) => setFieldValue('class', e.target.value)}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="course"
              label="Curso"
              fullWidth
              value={requirement?.course || ''}
              onChange={(e) => setFieldValue('course', e.target.value)}
              disabled
            />
          </Grid>
          {/* SERÁ ADICIONADO NA V2 */}
          {/* <Grid item xs={12} sm={12} md={6}>
            <Typography>
              <b>Fluxo de deferimento</b> <br /> Quando deferido, este
              requerimento pode registrar/disparar estas ações
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Select
              id="occurrence"
              label="Ocorrência"
              value={values?.occurrence}
              optionsList={occurrenceList}
              onChange={(e) => setFieldValue('occurrence', e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Select
              id="declaration"
              label="Declaração"
              value={values?.declaration}
              optionsList={declarationList}
              onChange={(e) => setFieldValue('declaration', e.target.value)}
            />
          </Grid> */}

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              id="description"
              label="Observação"
              fullWidth
              value={requirement?.observation || ''}
              onChange={handleChange}
              multiline
              rows={5}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Styled.Text>Anexos</Styled.Text>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Tooltip title="Visualizar anexo" placement="top">
              <FilePresent />
            </Tooltip>
            {requirement?.opening_document ? (
              <Styled.Link
                href={requirement?.opening_document || ''}
                target="_blank"
              >
                Abertura
              </Styled.Link>
            ) : (
              <Styled.Link
                onClick={(e) => e.preventDefault()}
                style={{ pointerEvents: 'none' }}
              >
                Abertura
              </Styled.Link>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Tooltip title="Visualizar anexo" placement="top">
              <FilePresent />
            </Tooltip>
            {requirement?.requirement_protocol_pdf ? (
              <Styled.Link
                href={requirement.requirement_protocol_pdf}
                target="_blank"
              >
                Documento de Solicitação
              </Styled.Link>
            ) : (
              <Styled.Link
                onClick={(e) => e.preventDefault()}
                style={{ pointerEvents: 'none' }}
              >
                Documento de Solicitação
              </Styled.Link>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Tooltip title="Visualizar anexo" placement="top">
              <FilePresent />
            </Tooltip>
            {requirement?.response_document ? (
              <Styled.Link
                href={requirement?.response_document || ''}
                target="_blank"
              >
                Resposta
              </Styled.Link>
            ) : (
              <Styled.Link
                onClick={(e) => e.preventDefault()}
                style={{ pointerEvents: 'none' }}
              >
                Resposta
              </Styled.Link>
            )}
          </Grid>

          <Styled.Box>
            <ButtonFooterV2
              labelClose="Cancelar"
              labelConfirm="Salvar"
              size="large"
              onClickClose={() => setOpenModal(true)}
            />
          </Styled.Box>
        </Grid>
      </Styled.Form>
    )}
  </Formik>
)

export default FormGeneralData
