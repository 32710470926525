const DEFAULT_ITEMS_PER_PAGE = 10
const DEFAULT_PAGE = 1
const isEnrollment = 1

const MESSAGE = {
  NO_SEARCH:
    '<b>Encontre os cursos disponíveis aqui</b><br/> Você precisa selecionar ao menos a unidade e o nível para buscar pelo curso desejado.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  INACTIVE_SUCCESS: 'Inativado com sucesso'
}

const initialState = {
  unitId: '',
  level: '',
  inactive: false
}

const defaultTotalPage = 10

const listItensPerPage = [
  {
    value: '6',
    label: '6'
  },
  {
    value: '12',
    label: '12'
  },
  {
    value: '24',
    label: '24'
  },
  {
    value: '36',
    label: '36'
  },
  {
    value: '54',
    label: '54'
  }
]

export {
  MESSAGE,
  isEnrollment,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PAGE,
  defaultTotalPage,
  initialState,
  listItensPerPage
}
