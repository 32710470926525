import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'
import { useSortTable } from 'services/hooks'
import { paginationFilter } from 'services/helpers'
import * as Styled from './style'
import { headCells } from './constants'
import TableContent from './TableFooter'
import { defaultItemsPerPage, defaultTotalPage } from '../../constants'

const TableEad = ({
  listContentEad,
  pageActual,
  totalPage,
  setPage,
  handleSetPage,
  setOpenCreateByEdit,
  setIsDelete,
  openCreateByEdit,
  setIdSubject,
  setConfirmModal,
  setValuesModal,
  handleHoursSum,
  setIsEdit,
  count,
  filter,
  setFilter
}) => {
  const { handleRequestSort, order, orderBy, sortTable } =
    useSortTable(listContentEad)

  return (
    <>
      <Styled.Paper>
        <Styled.TableContainer>
          <MuiTable>
            <TableHeaderDefault
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />

            <TableBody>
              {sortTable?.map((row) => (
                <TableContent
                  key={row.id}
                  row={row}
                  setIsDelete={setIsDelete}
                  setConfirmModal={setConfirmModal}
                  setIdSubject={setIdSubject}
                  setIsEdit={setIsEdit}
                  setValuesModal={setValuesModal}
                  setOpenCreateByEdit={setOpenCreateByEdit}
                  openCreateByEdit={openCreateByEdit}
                />
              ))}
            </TableBody>

            <Styled.TableFooter>
              <TableRow>
                <TableCell colSpan={12}>
                  <Typography component="body1" variant="body1">
                    <Styled.BoxSumHours>
                      <b>
                        Total de horas <span>{handleHoursSum()} h</span>
                      </b>
                    </Styled.BoxSumHours>
                  </Typography>
                </TableCell>
              </TableRow>
            </Styled.TableFooter>
          </MuiTable>
        </Styled.TableContainer>

        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={pageActual}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setPage(defaultTotalPage)
              setFilter(e.target.value)
            }}
          />
        )}
      </Styled.Paper>
    </>
  )
}

export { TableEad }
