/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box, IconButton, TableRow, Tooltip } from '@mui/material'
import { formatDate } from 'services/helpers'
import { Delete } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'

const TableFooter = ({ row, setOpenModalConfirm, setIdDocument }) => (
  <>
    <TableRow hover tabIndex={-1} key={row?.id}>
      <Styled.TableCellTitle>
        <Box>
          <Tooltip title="Clique para baixar" placement="top" arrow>
            <span
              className={TAG_MANAGER.rh_btn_baixa_documento}
              onClick={() => window.open(row?.url)}
            >
              {row?.type?.name}
            </span>
          </Tooltip>

          <Styled.ContainerIcon>
            <Tooltip title="Excluir" placement="top" arrow>
              <IconButton
                className={TAG_MANAGER.rh_btn_deleta_documento}
                disableRipple
                onClick={() => {
                  setIdDocument(row?.id)
                  setOpenModalConfirm(true)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Styled.ContainerIcon>
        </Box>
      </Styled.TableCellTitle>

      <Styled.TableCell>
        <div>{row?.name}</div>
      </Styled.TableCell>
      <Styled.TableCellDate>
        <div>{formatDate(row?.done_at)}</div>
      </Styled.TableCellDate>
    </TableRow>
  </>
)

export { TableFooter }
