const isDisabledButton = ({ beginAt, endAt, cpf }) => {
  const enabled = (beginAt && endAt) || cpf
  return !enabled
}

const remapInstallmentsToSelect = async ({ setList, details }) => {
  const list = details || []
  const arrayNovo = list.map((obj) => ({
    value: obj.installments,
    label: obj.payment_plan
  }))

  return setList(arrayNovo)
}

const handleFormated = ({ setAmoutFormated, details }) => {
  const amount = details?.amount || 0
  const formated = amount.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })
  setAmoutFormated(formated)
}
export { isDisabledButton, remapInstallmentsToSelect, handleFormated }
