import { makeURLAccessLegacy, messageErroOrSuccess } from 'services/helpers'
import { deleteCourseById as deleteCourseByIdService } from 'services/api/admin'

import { generalMenu, navEdit } from './helpers'
import { messageServiceExcludeCourse } from './constants'

const getMenuItems = ({
  register,
  handleClose,
  handleScore,
  handleEdit,
  handleCardTitle,
  handleUseOfStudies,
  handleDeleteCourse,
  isHideConfirmReEnrollment,
  disableConfirmReEnrollment,
  handleConfirmReEnrollment,
  isHideUseOfStudies,
  selectStundenInfo,
  isAvailableToAccessJustifyAbsence,
  redirectToJustifyAbsence,
  redirectToAdditionalActivities,
  copyContract,
  onlyEdit
}) =>
  onlyEdit
    ? navEdit({
        register,
        handleEdit,
        handleClose,
        copyContract
      })
    : generalMenu({
        register,
        handleClose,
        handleScore,
        selectStundenInfo,
        handleCardTitle,
        handleUseOfStudies,
        isHideConfirmReEnrollment,
        isAvailableToAccessJustifyAbsence,
        handleConfirmReEnrollment,
        isHideUseOfStudies,
        redirectToJustifyAbsence,
        redirectToAdditionalActivities,
        disableConfirmReEnrollment
      })

const deleteCourseById = async ({ courseId, setSnack, callbackSuccess }) => {
  const { error, status } = deleteCourseByIdService(courseId)

  messageErroOrSuccess({
    error,
    setSnack,
    status,
    successMessage: messageServiceExcludeCourse.successMessage,
    feedbackMessage: messageServiceExcludeCourse.feedbackMessage
  })

  if (!error) {
    setTimeout(callbackSuccess, 1000)
  }
}

const confirmReEnrollment = async ({ reEnrollmentId, callbackSuccess }) => {
  try {
    const legacyUrl = makeURLAccessLegacy(
      `acesso/aluno_rematricula_confirmar.php?id=${reEnrollmentId}`
    )
    window.open(legacyUrl, '_blank')
    setTimeout(callbackSuccess, 1000)
  } catch (error) {
    console.error(error)
  }
}

function formatDateAndHour(dateString) {
  if (!dateString) return ''

  const dateObject = new Date(dateString)
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }

  return dateObject.toLocaleString('pt-BR', options)
}

export {
  getMenuItems,
  deleteCourseById,
  confirmReEnrollment,
  formatDateAndHour
}
