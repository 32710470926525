import styled from 'styled-components'
import { Button as MuiButton, Toolbar } from '@mui/material'

const ToolbarArea = styled(Toolbar)`
  &.MuiToolbar-root {
    gap: 10px;
    padding: 0;
    display: flex;
    margin-top: 24px;
    align-items: start;
    flex-direction: column;
  }
`

const Button = styled(MuiButton)`
  &.MuiButton-root {
    border: 1px solid;
    color: ${({ $isColor, theme }) =>
      $isColor ? theme.palette.red.main : theme.palette.yellow.warning};
    border-color: ${({ $isColor, theme }) =>
      $isColor ? theme.palette.red.main : theme.palette.yellow.warning};
    padding: 4px 8px;
  }
  &.MuiButton-root:hover {
    color: ${({ theme }) => theme.palette.yellow.warning};
    border-color: ${({ theme }) => theme.palette.yellow.warning};
    background-color: transparent;
  }
`
export { ToolbarArea, Button }
