import { ROUTE } from 'services/constants'

const FALLBACK_VALUE = -1

const initialValues = {
  category: '',
  occurrence: '',
  record: ''
}

const MESSAGE = {
  SUCCESS_CREATE_OCCURRENCE_TYPE:
    'Novo tipo de ocorrência foi criado com sucesso.',
  SUCCESS_UPDATED_OCCURRENCE_TYPE:
    'O tipo de ocorrência foi atualizado com sucesso.',
  SUCCESS_CREATE_COPY:
    'A cópia dos tipos de ocorrência selecionados foi criada com sucesso.'
}

const itemsBreadCrumbs = [
  {
    path: ROUTE.ADMIN_MANAGEMENT_OCCURRENCE,
    label: 'Gestão'
  },
  {
    path: ROUTE.ADMIN_MANAGEMENT_OCCURRENCE,
    label: 'Tipos de Ocorrência'
  }
]

const breadCrumbsItems = ({ isEdit, nameTypeEdit }) => {
  if (isEdit) {
    return [
      ...itemsBreadCrumbs,
      {
        path: ROUTE.ADMIN_MANAGEMENT_OCCURRENCE,
        label: `${nameTypeEdit}`
      },
      {
        path: ROUTE.ADMIN_MANAGEMENT_OCCURRENCE_CREATE,
        label: '<b>Editar Tipo</b>'
      }
    ]
  }

  return [
    ...itemsBreadCrumbs,
    {
      path: ROUTE.ADMIN_MANAGEMENT_OCCURRENCE_CREATE,
      label: '<b>Novo Tipo</b>'
    }
  ]
}

export { breadCrumbsItems, initialValues, MESSAGE, FALLBACK_VALUE }
