import { useState } from 'react'
import { defaultActivePage, currentPageDefault } from './constants'

const usePaginationBasic = (list, countPerPage, count) => {
  const isPaginatorByMemory = !count
  const itemsPerPage = parseInt(countPerPage, 10)
  const [page, setPage] = useState(defaultActivePage)
  const totalItems = isPaginatorByMemory ? list?.length : count
  const totalPage = Math.ceil(totalItems / itemsPerPage)
  const pageIndex = page - defaultActivePage
  const startPage = pageIndex * itemsPerPage
  const items = isPaginatorByMemory
    ? list?.slice(startPage, startPage + itemsPerPage)
    : list

  const handlePrevPage = () => {
    const currentPage = page - defaultActivePage
    if (currentPage > currentPageDefault) {
      setPage(currentPage)
    }
  }
  const handleNextPage = () => {
    const currentPage = page + defaultActivePage
    if (currentPage <= totalPage) {
      setPage(currentPage)
    }
  }

  const handleSetPage = (_, currentPage) => {
    setPage(+currentPage)
  }

  return {
    items,
    totalItems,
    handlePrevPage,
    handleNextPage,
    page,
    totalPage,
    setPage,
    handleSetPage
  }
}

export default usePaginationBasic
