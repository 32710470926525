const initialValues = ({ schoolClassId, register }) => ({
  register,
  classId: schoolClassId,
  activityType: null,
  workload: '',
  observation: '',
  file: ''
})

export { initialValues }
