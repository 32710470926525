import { ICONS } from 'theme/icons'
import TAG_MANAGER from 'tagManager'
import { makeURLAccessLegacy } from 'services/helpers'
import { onSubmitReportExportXLSX } from '../../../services'

const worksheets = [
  {
    name_seo: TAG_MANAGER.financial_btn_download_Simplified_spreadsheet,
    label: 'Planilha em PDF',
    download: ({ values, id }) =>
      makeURLAccessLegacy(
        `acesso/rh_professor_disciplinas_pdf.php?cod_prof=${id}&unidade=${
          values.unit || ''
        }`,
        true
      )
  },
  {
    name_seo: TAG_MANAGER.financial_btn_download_Detailed_worksheet,
    icon: ICONS.excel,
    label: 'Planilha em Excel',
    download: ({
      setSnack,
      setLoading,
      values,
      id,
      isValidation,
      setModalDonwloadsFile,
      setDisciplineAndClass
    }) =>
      onSubmitReportExportXLSX({
        setSnack,
        setLoading,
        values,
        id,
        isValidation,
        setModalDonwloadsFile,
        setDisciplineAndClass
      })
  }
]

export { worksheets }
