import { useNavigate } from 'react-router-dom'
import { Radio, TableCell, TableRow, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ROUTE } from 'services/constants'
import TAG_MANAGER from 'tagManager'
import { formatStringTagToString } from 'services/helpers'
import * as Styled from './style'

const TableFooter = ({
  row,
  handleClick,
  isItemSelected,
  removeUsersListUnifyById,
  selectUser,
  isUnifyStatus
}) => {
  const navigate = useNavigate()

  return (
    <TableRow>
      {!isUnifyStatus && (
        <TableCell
          padding="checkbox"
          onClick={() => handleClick(row?.register)}
        >
          <Radio
            color="primary"
            checked={isItemSelected}
            inputProps={{
              'aria-labelledby': selectUser[0]
            }}
          />
        </TableCell>
      )}
      <Styled.StyledTableCellTitle>
        <div className="content">
          <Tooltip title="Ficha do aluno" placement="top">
            <Styled.BoxLink
              onClick={() =>
                navigate(
                  `${ROUTE.ADMIN_SECRETARY_STUDENTS}/register?name=${row.name}&register=${row.register}&tab=registrationData&tabRegistrationData=studentData`,
                  {
                    state: { savedSearch: true, row }
                  }
                )
              }
              className={TAG_MANAGER.sistema_btn_edita_contrato}
            >
              {row?.name}
            </Styled.BoxLink>
          </Tooltip>
        </div>
      </Styled.StyledTableCellTitle>

      <Styled.StyledTableCell>
        {formatStringTagToString(row?.register)}
      </Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.cpf}</Styled.StyledTableCell>
      <Styled.StyledTableCell>{row?.unit}</Styled.StyledTableCell>
      {!isUnifyStatus && (
        <Styled.StyledTableButtonRemover
          onClick={() => removeUsersListUnifyById(row?.register)}
        >
          <div className="content">
            <Tooltip title="Remover da lista" placement="top">
              <CloseIcon />
            </Tooltip>
          </div>
        </Styled.StyledTableButtonRemover>
      )}
    </TableRow>
  )
}

export default TableFooter
