import { FormAcademicEducation } from '../FormAcademicEducation'
import { FormPersonalData } from '../FormPersonalData'
import { Replacement } from '../Replacement'
import { ClassLinked } from '../ClassLinked'
import { FormTimekeeping } from '../FormTimekeeping'

const optionsTabs = ({
  isEdit,
  setSnack,
  notSearch,
  isFinalized,
  optionsCity,
  idProfessor,
  setIsUpdate,
  optionsState,
  setNotSearch,
  setIsFinalized,
  setLoadingOpen,
  setOptionsCity,
  listReplacement,
  setOptionsState,
  formPesonalData,
  optionsRegional,
  optionsCityState,
  listClassLinked,
  setListReplacement,
  setOptionsRegional,
  setFormPesonalData,
  setListClassLinked,
  notPrefessorCreated,
  setOptionsCityState,
  formAcademicEducation,
  listAcademicEducation,
  setNotPrefessorCreated,
  isFinalizedClassLinked,
  setListAcademicEducation,
  setFormAcademicEducation,
  setIsFinalizedClassLinked,
  setOpenModalConfirmCancel,
  formTimekeeping,
  setFormTimekeeping,
  listTimekeeping,
  setListTimekeeping
}) => {
  const tabFormPersonalData = {
    label: 'Dados Pessoais',
    component: () => (
      <FormPersonalData
        isEdit={isEdit}
        idProfessor={idProfessor}
        formPesonalData={formPesonalData}
        setSnack={setSnack}
        setNotPrefessorCreated={setNotPrefessorCreated}
        setLoadingOpen={setLoadingOpen}
        setOptionsCity={setOptionsCity}
        setOptionsState={setOptionsState}
        setOptionsRegional={setOptionsRegional}
        optionsState={optionsState}
        optionsCity={optionsCity}
        optionsRegional={optionsRegional}
        optionsCityState={optionsCityState}
        setOptionsCityState={setOptionsCityState}
        setFormPesonalData={setFormPesonalData}
        setListAcademicEducation={setListAcademicEducation}
        setOpenModalConfirmCancel={setOpenModalConfirmCancel}
      />
    )
  }

  const tabFormAcademicEducation = isEdit && {
    label: 'Formação Acadêmica',
    disabled: notPrefessorCreated,
    component: () => (
      <FormAcademicEducation
        formAcademicEducation={formAcademicEducation}
        setSnack={setSnack}
        setLoadingOpen={setLoadingOpen}
        setFormAcademicEducation={setFormAcademicEducation}
        setFormPesonalData={setFormPesonalData}
        idProfessor={idProfessor}
        listAcademicEducation={listAcademicEducation}
        setListAcademicEducation={setListAcademicEducation}
      />
    )
  }

  const tabClassLinked = isEdit && {
    label: 'VÍNCULO DE TURMA',
    disabled: notPrefessorCreated,
    component: () => (
      <ClassLinked
        setSnack={setSnack}
        notSearch={notSearch}
        idProfessor={idProfessor}
        setIsUpdate={setIsUpdate}
        setNotSearch={setNotSearch}
        setLoadingOpen={setLoadingOpen}
        listClassLinked={listClassLinked}
        setFormPesonalData={setFormPesonalData}
        setListClassLinked={setListClassLinked}
        isFinalizedClassLinked={isFinalizedClassLinked}
        setIsFinalizedClassLinked={setIsFinalizedClassLinked}
      />
    )
  }

  const tabReplacement = isEdit && {
    label: 'substituição',
    disabled: notPrefessorCreated,
    component: () => (
      <Replacement
        setSnack={setSnack}
        idProfessor={idProfessor}
        isFinalized={isFinalized}
        setIsUpdate={setIsUpdate}
        setIsFinalized={setIsFinalized}
        setLoadingOpen={setLoadingOpen}
        listReplacement={listReplacement}
        setFormPesonalData={setFormPesonalData}
        setListReplacement={setListReplacement}
      />
    )
  }

  const tabFormTimekeeping = isEdit && {
    label: 'Apontamento de horas',
    disabled: notPrefessorCreated,
    component: () => (
      <FormTimekeeping
        formTimekeeping={formTimekeeping}
        setSnack={setSnack}
        setLoadingOpen={setLoadingOpen}
        setFormTimekeeping={setFormTimekeeping}
        idProfessor={idProfessor}
        listTimekeeping={listTimekeeping}
        setListTimekeeping={setListTimekeeping}
      />
    )
  }

  return [
    tabFormPersonalData,
    tabFormAcademicEducation,
    tabClassLinked,
    tabReplacement,
    tabFormTimekeeping
  ].filter(Boolean)
}

export { optionsTabs }
