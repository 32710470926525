export const KEY_COLUMNS = {
  NAME: 'name',
  COURSE: 'course',
  CLASS: 'schoolClassCode',
  WORKLOAD: 'workload',
  REQUESTED: 'totalRequests'
}

const tableColumns = [
  { id: KEY_COLUMNS.NAME, label: 'Nome' },
  { id: KEY_COLUMNS.COURSE, label: 'Curso' },
  { id: KEY_COLUMNS.CLASS, label: 'Turma' },
  { id: KEY_COLUMNS.REQUESTED, label: 'Solicitações' }
]

export { tableColumns }
