import { utilsPDF } from 'components/templates/PDF/shared/utils'
import buildTableBody from './Body'
import buildTableFooter from './Footer'
import buildTableHeader from './Header'

const { borderLayoutDefault, paddingLayoutDefault } = utilsPDF

export default function buildTable({ classInfo, activities, students }) {
  return [
    {
      table: {
        body: [...buildTableHeader(activities), ...buildTableBody(students)],
        widths: [
          'auto',
          '*',
          'auto',
          28,
          ...new Array(8).fill(37),
          20,
          'auto',
          'auto',
          'auto'
        ]
      },
      layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
    },
    buildTableFooter(classInfo)
  ]
}
