import { httpGet, httpPut, httpPost } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getDocumentTypes = async (params) =>
  httpGet(ENDPOINT.DOCUMENT_TYPES.LIST, { params })

export const updateDocumentType = async (id, params) =>
  httpPut(`${ENDPOINT.DOCUMENT_TYPES.CRUD}/${id}`, { ...params })

export const createDocumentType = async (params) =>
  httpPost(ENDPOINT.DOCUMENT_TYPES.CRUD, params)
