import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ListAlt } from '@mui/icons-material'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { Button, TextNotFound } from 'components/atoms'
import TAG_MANAGER from 'tagManager'
import { ModalConfirm } from 'components/molecules'
import { IsEmpty } from 'services/helpers'
import { MESSAGE, breadCrumbsItems, defaultItemsPerPage } from './constants'
import JustifyAbsenceModal from './components/JustifyAbsenceModal'
import Table from './components/Table'

import * as Styled from './style'
import {
  createJustifyAbsence,
  removeJustifyAbsence,
  getListJustifyAbsence
} from './service'

const JustifyAbsence = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const [count, setCount] = useState(null)
  const [searchParams] = useSearchParams()
  const [absence, setAbsence] = useState([])
  const [selected, setSelected] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const { name, unitName, register, course, schoolClassId, schoolClassCode } =
    Object.fromEntries([...searchParams])

  const {
    page: pageActual,
    totalPage,
    items,
    setPage
  } = usePaginationBasic(absence, filter, count)
  const hasResult = !!absence?.length

  const handleSubmit = (values) => {
    const data = {
      course,
      register,
      unitName,
      ...values,
      schoolClassId,
      schoolClassCode
    }

    createJustifyAbsence({
      setIsUpdate,
      values: data,
      setOpenModal,
      setLoadingOpen
    })
  }

  const handleDelete = () => {
    removeJustifyAbsence({
      setIsUpdate,
      setLoadingOpen,
      setOpenModalConfirm,
      studentId: selected.id
    })
  }

  useEffect(() => {
    getListJustifyAbsence({
      filter,
      setCount,
      pageActual,
      setAbsence,
      setLoadingOpen,
      studentId: register
    })
  }, [pageActual, filter, isUpdate])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() => navigate(-1)}
      breadcrumbsItems={breadCrumbsItems(name)}
      content={
        <Styled.Content>
          {!hasResult && (
            <TextNotFound icon={<ListAlt />} text={MESSAGE.NO_SEARCH} />
          )}
          <Styled.EmptyState $isCenter={!hasResult}>
            <Button
              onclick={() => setOpenModal(true)}
              className={TAG_MANAGER.secretaria_btn_cadastrar_novaJustificativa}
            >
              nova justificativa
            </Button>
          </Styled.EmptyState>
          <JustifyAbsenceModal
            type="pdf"
            open={openModal}
            onSubmitForm={handleSubmit}
            handleClose={() => setOpenModal(false)}
          />

          {hasResult && (
            <Table
              items={items}
              count={count}
              filter={filter}
              setPage={setPage}
              totalPage={totalPage}
              setFilter={setFilter}
              pageActual={pageActual}
              setSelected={setSelected}
              handleDelete={() => setOpenModalConfirm(!openModalConfirm)}
            />
          )}

          <ModalConfirm
            size="medium"
            iconWarningAmber
            open={openModalConfirm}
            textButtonConfirm="Sim"
            textButtonNotConfirm="Não"
            colorButtonConfirm="error"
            title="Excluir justificativa"
            onClickConfirm={handleDelete}
            handleClose={() => setOpenModalConfirm(!openModalConfirm)}
            onClickNotConfirm={() => setOpenModalConfirm(!openModalConfirm)}
            message="Deseja remover esta justificativa de falta? Você perderá as informações sobre ela."
            // classNameBtnConfirm={TAG_MANAGER.rh_btn_confirma_inativarProfessor}
          />
        </Styled.Content>
      }
    />
  )
}

export default JustifyAbsence
