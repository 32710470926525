import * as yup from 'yup'
import { isValidYear } from 'services/helpers'

const schema = yup.object().shape({
  dueDate: yup
    .string()
    .test(
      'isValidDate',
      'A data para ser válida o ano precisa conter os 4 dígitos',
      isValidYear
    )
})

export default schema
