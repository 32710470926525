import { ListAlt, Search } from '@mui/icons-material'
import {
  formModel,
  SEARCH_TYPE
} from './SearchUnifyEnrollment/components/SearchForm/constants'

const messagesServices = {
  listUnify: {
    success: 'Pesquisa com sucesso!',
    feedback: 'Erro ao tentar Pesquisar!'
  },
  updateUnify: {
    success: 'Unificação atualizado com sucesso!',
    feedback: 'Houve um erro ao unificar as matrículas. Tente novamente.'
  },
  listEnrollment: {
    success: 'Pesquisa com sucesso!',
    feedback: 'Erro ao tentar Pesquisar!'
  }
}

const messages = {
  supplierEmpty: {
    icon: <ListAlt />,
    text: '<strong>Encontre alunos registrados/duplicados no sistema.</strong><br />Selecione um critério e realize uma busca para encontrar uma matrícula e realizar a unificação'
  },
  supplierNotFound: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente'
  },
  createSupplier: {
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Cadastro realizado com sucesso'
  },
  updateSupplier: {
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Cadastro atualizado com sucesso'
  },
  inactiveSupplier: {
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Cliente/Fornecedor inativado com sucesso.'
  },
  activeSupplier: {
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.',
    successMessage: 'Cliente/Fornecedor ativado com sucesso.'
  }
}

const DEFAULT_ITEMS_PER_PAGE = 10
const MIN_LENGTH_NAME = 3
const MIN_LENGTH_ENROLLMENT = 5
const MAX_LENGTH_CPF = 11
const defaultPage = 1

const hasSelected = (values) => {
  const searchType = values?.[formModel.searchType.key]
  const fieldValue = values?.[formModel.fieldValue.key]
  const hasSelectedName = searchType === SEARCH_TYPE.NAME
  const hasMinCharacter = fieldValue?.length >= MIN_LENGTH_NAME
  const hasSelectedEnrollment = searchType === SEARCH_TYPE.ENROLLMENT
  const hasMinCharacterEnrollment = fieldValue?.length >= MIN_LENGTH_ENROLLMENT
  const hasSelectedCPF = searchType === SEARCH_TYPE.CPF
  const hasMinCharacterCPF = fieldValue?.length >= MAX_LENGTH_CPF
  return {
    hasSelectedName,
    hasMinCharacter,
    hasSelectedEnrollment,
    hasMinCharacterEnrollment,
    hasSelectedCPF,
    hasMinCharacterCPF
  }
}

const getTitleModalConfirm = (inactive) => (inactive ? 'Inativar' : 'Ativar')

const getMessageModalConfirm = (inactive) =>
  `Deseja realmente ${
    inactive ? 'inativar' : 'ativar'
  } este cliente/fornecedor?`

export {
  messages,
  DEFAULT_ITEMS_PER_PAGE,
  getTitleModalConfirm,
  getMessageModalConfirm,
  MIN_LENGTH_NAME,
  MIN_LENGTH_ENROLLMENT,
  hasSelected,
  defaultPage,
  messagesServices
}
