import styled from 'styled-components'
import { Typography as MuiTypography, Grid as MuiGrid } from '@mui/material'

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    margin: 12px 0;
  }
`
const Grid = styled(MuiGrid)`
  &.MuiGrid-root {
    margin-top: 2px;
  }
`
export { Typography, Grid }
