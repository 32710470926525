export const sortAsc = 'asc'
export const sortDesc = 'desc'
export const defaultTotalPage = 1
export const defaultItemsPerPage = 10

const headCellsPending = [
  {
    id: 'discipline',
    label: 'Disciplina'
  },
  {
    id: 'module',
    label: 'Módulo'
  },
  {
    id: 'workload_hours',
    label: 'Carga Horária'
  },
  {
    id: 'start_date',
    label: 'Início'
  },
  {
    id: 'end_date',
    label: 'Fim'
  },
  {
    id: 'substitution_reason',
    label: 'Motivo da substituição'
  },
  {
    id: 'replacement',
    label: 'Substituir'
  },
  {
    id: 'substitute_professor_hourly_lesson_cost',
    label: 'Valor'
  }
]

export { headCellsPending }
