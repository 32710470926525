import styled from 'styled-components'
import { TableCell as MuiTableCell } from '@mui/material'
import theme from 'theme/designTokens'

const TableCreatedAt = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 20%;
  }
`

const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 10%;
  }
`

const TableCellEmail = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    width: 20%;
  }
`

const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 16px;
    width: 40%;
    text-decoration: underline;
    color: ${() => theme.palette.primary.main} !important;
    cursor: pointer;
    & > div {
      width: 16rem;
    }
  }
`

export { TableCell, TableCellTitle, TableCellEmail, TableCreatedAt }
