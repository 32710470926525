import { API_URL } from 'services/helpers'
import { createInstance, httpClient } from './httpClient'

const httpInstance = (versionAPI) => {
  const URL = API_URL(versionAPI)

  return createInstance({
    baseURL: URL,
    isInterceptor: true
  })
}

export function httpGet(url = '', opts = {}, versionAPI) {
  const config = { ...opts }

  return httpClient(httpInstance(versionAPI).get(url, config))
}

export function httpGetDownload(url = '', opts = {}, versionAPI) {
  const config = { ...opts }

  return httpClient(httpInstance(versionAPI).get(url, config))
}

export function httpPost(url = '', data = {}, opts = {}, versionAPI) {
  const config = { ...opts }

  return httpClient(httpInstance(versionAPI).post(url, data, config))
}

export function httpPatch(url = '', data = {}, opts = {}, versionAPI) {
  const config = { ...opts }

  return httpClient(httpInstance(versionAPI).patch(url, data, config))
}

export function httpPut(url = '', data = {}, opts = {}, versionAPI) {
  const config = { ...opts }

  return httpClient(httpInstance(versionAPI).put(url, data, config))
}

export function httpDelete(url = '', data = {}, opts = {}, versionAPI) {
  const config = { ...opts }
  return httpClient(httpInstance(versionAPI).delete(url, { data }, config))
}

export function httpGetV2(url = '', data = {}, opts = {}, versionAPI) {
  const config = { ...opts }
  return httpClient(httpInstance(versionAPI).get(url, data, config))
}
