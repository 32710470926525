import { useState, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import { ButtonFooterV2, Select } from 'components/molecules'
import { getLenght } from 'services/helpers'
import { InputAutocomplete } from 'components/atoms'
import {
  addMoreOne,
  orderList,
  textAddProfessor,
  textBatchAction
} from './constants'
import * as Styled from './style'
import { getProfessors, handleCreateProfessors } from '../../services'
import { initialValues } from '../Table/constants'

const ModalAddTeacher = ({
  handleClose,
  open,
  setSnack,
  setLoadingOpen,
  id,
  setSelected,
  idDisc,
  isBatchAction,
  setFormAddProfessors,
  selectedItem,
  formAddProfessors,
  setIsBatchAction,
  setListDisciplines,
  setCount,
  pageActual,
  filter,
  discipline
}) => {
  const [listProfessors, setListProfessors] = useState([])

  const removeProfessor = ({ values, setFieldValue, index }) => {
    const fieldValues =
      getLenght(values.teacher) === 1
        ? initialValues.teacher
        : values.teacher.filter((i, indx) => indx !== index)

    return setFieldValue('teacher', fieldValues)
  }

  useEffect(() => {
    getProfessors({ setSnack, setListProfessors })
  }, [])

  return (
    <Styled.Modal open={open} onClose={handleClose}>
      <Formik
        initialValues={formAddProfessors}
        onSubmit={(values) =>
          handleCreateProfessors({
            isBatchAction,
            selectedItem,
            setSelected,
            values,
            setSnack,
            setLoadingOpen,
            handleClose,
            id,
            idDisc,
            setListDisciplines,
            setCount,
            pageActual,
            filter
          })
        }
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12}>
                  <Styled.Title variant="h5" component="h2">
                    Adicionar Professor
                  </Styled.Title>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2">
                    {isBatchAction ? textBatchAction : textAddProfessor}
                  </Typography>
                </Grid>

                {!isBatchAction && (
                  <Grid item xs={12}>
                    <Typography variant="body2" fontWeight="bold">
                      {discipline}
                    </Typography>
                  </Grid>
                )}

                {values?.teacher
                  ?.sort((a, b) => a.order - b.order)
                  ?.map((item, index) => {
                    const updatedProfessors = [...values.teacher]

                    return (
                      <>
                        {!isBatchAction && (
                          <Grid item xs={3}>
                            <Select
                              id="order"
                              label="Ordenação"
                              value={item?.order || ''}
                              optionsList={orderList}
                              onChange={(e) => {
                                updatedProfessors[index].order = e.target.value
                                setFieldValue('teacher', updatedProfessors)
                              }}
                            />
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={!isBatchAction ? 7.5 : 12}
                          marginBottom={isBatchAction && '15px'}
                        >
                          <InputAutocomplete
                            label="Nome do Professor"
                            limitTags={1}
                            labelId={`professor-${Math.random()}`}
                            value={item?.professor || null}
                            noOptionsText="Nenhum(a) professor(a) encontrado(a) com esse nome."
                            handleChange={(_, value) => {
                              updatedProfessors[index].professor = value
                              setFieldValue('teacher', updatedProfessors)
                            }}
                            filterSelectedOptions
                            getOptionLabel={(option) => option.label}
                            isOptionEqualToValue={(option, label) =>
                              option === label
                            }
                            listOptions={listProfessors}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                {...props}
                                key={option?.value}
                              >
                                {option.label}
                              </Box>
                            )}
                          />
                        </Grid>

                        {!isBatchAction && (
                          <Grid item xs={12} sm={12} md={0.5}>
                            <Styled.DeleteIcon
                              className="academico_btn_exclui_professor"
                              onClick={() =>
                                removeProfessor({
                                  values,
                                  setFieldValue,
                                  index
                                })
                              }
                            />
                          </Grid>
                        )}
                      </>
                    )
                  })}

                {getLenght(values.teacher) < 8 && !isBatchAction && (
                  <Grid item xs={12} sm={12} md={12}>
                    <Styled.Box>
                      <Tooltip
                        title="Adicionar disciplina"
                        placement="top"
                        arrow
                      >
                        <Styled.AddIcon
                          className="academico_btn_adiciona_novoProfessor"
                          onClick={() =>
                            setFieldValue(
                              'teacher',
                              values.teacher.concat([
                                {
                                  order: getLenght(values.teacher) + addMoreOne,
                                  professor: null
                                }
                              ])
                            )
                          }
                        />
                      </Tooltip>
                    </Styled.Box>
                  </Grid>
                )}
              </Grid>

              <ButtonFooterV2
                labelClose="Cancelar"
                labelConfirm="Confirmar"
                classNameConfirm={
                  getLenght(values?.teacher) > 1
                    ? 'academico_btn_confirma_editaProfessor'
                    : 'academico_btn_confirma_adicionaProfessor'
                }
                size="medium"
                onClickClose={() => {
                  setIsBatchAction(false)
                  setFormAddProfessors(initialValues)
                  handleClose()
                }}
              />
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export default ModalAddTeacher
