import { Typography } from '@mui/material'
import * as Styled from './style'

const TableToolbar = ({ amount }) => (
  <Styled.ToolbarArea>
    <Typography variant="body2">
      {amount > 1
        ? `${amount} itens selecionados`
        : `${amount} item selecionado`}
    </Typography>
  </Styled.ToolbarArea>
)
export default TableToolbar
