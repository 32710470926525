import { useFormik } from 'formik'
import { Box, Grid } from '@mui/material'

import { useMakeService } from 'services/hooks'
import { ButtonFooterV2, Select, TextField } from 'components/molecules'
import { DatePicker, Modal } from 'components/atoms'

import { formatDateTime, parseUnits } from 'services/helpers'
import * as Styled from './style'
import validationSchema from './schema'
import { formatInitialValues } from './helpers'
import { defaultValues } from './constants'
import { createGrouping, editGrouping } from './service'

const GroupingModal = ({
  open,
  item,
  handleClose,
  setSnack,
  isEdit,
  callbackSuccess
}) => {
  const initialValues = isEdit ? formatInitialValues(item) : defaultValues

  const paramsMakeService = {
    isUnits: true,
    unitsValue: true,
    isLevel: true,
    setSnack
  }

  const { level, unitys } = useMakeService(paramsMakeService)

  const listLevel = level.map((currentItem) => ({
    ...currentItem,
    value: currentItem?.label
  }))

  const listUnitys = unitys.map((currentItem) => ({
    ...currentItem,
    value: currentItem?.label
  }))

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      const functionService = isEdit ? editGrouping : createGrouping

      functionService({
        setSnack,
        values,
        callbackSuccess
      })
    },
    validationSchema
  })

  return (
    <Modal open={open} onClose={handleClose} fullWidth maxWidth="md">
      <Box p={3}>
        <Styled.Title>{isEdit ? 'Editar' : 'Novo'} Agrupamento</Styled.Title>

        <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
          <Grid container xs={12} spacing={2}>
            {!isEdit && (
              <Grid item xs={6}>
                <Select
                  fullWidth
                  label="Unidade"
                  id="unit"
                  value={formik.values?.unit}
                  onChange={(event) => {
                    formik.setFieldValue('unit', event.target.value)
                    formik.setFieldValue('level', '')
                  }}
                  optionsList={parseUnits(listUnitys)}
                />
              </Grid>
            )}

            {!isEdit && (
              <Grid item xs={6}>
                <Select
                  fullWidth
                  label="Nível"
                  id="level"
                  value={formik.values?.level}
                  onChange={(event) => {
                    formik.setFieldValue('level', event.target.value)
                  }}
                  optionsList={listLevel}
                />
              </Grid>
            )}

            <Grid item xs={3}>
              <Styled.PeriodText>Período de Agrupamento:</Styled.PeriodText>
            </Grid>

            <Grid item xs={4.5}>
              <DatePicker
                fullWidth
                label="Início"
                id="startDate"
                value={formatDateTime(formik.values?.startDate)}
                onChange={(newValue) => {
                  formik.setFieldValue('startDate', newValue)
                }}
              />
            </Grid>

            <Grid item xs={4.5}>
              <DatePicker
                fullWidth
                label="Fim"
                id="endDate"
                value={formatDateTime(formik.values?.endDate)}
                minDate={formik.values?.startDate}
                onChange={(newValue) =>
                  formik.setFieldValue('endDate', newValue)
                }
                disabled={!formik.values?.startDate}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                name="name"
                label="Nome do Agrupamento"
                value={formik.values?.name}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>

          <Styled.Footer>
            <ButtonFooterV2
              type="submit"
              labelClose="Cancelar"
              labelConfirm="Salvar"
              disabledConfirm={!(formik.dirty && formik.isValid)}
              onClickClose={handleClose}
            />
          </Styled.Footer>
        </form>
      </Box>
    </Modal>
  )
}

export default GroupingModal
