import humps from 'humps'
import { MESSAGE } from './constants'

const { default: FEEDBACK_SNACK } = require('feedBackSnack')
const {
  getDetailsClassLinked,
  editDetailsClassReplacement,
  getReplacedSubjects,
  deleteDetailsClassReplacement
} = require('services/api/admin')
const {
  updateErrorMessage,
  toastNotificationSuccess,
  removeCurrencyMask
} = require('services/helpers')

const getClassInfo = async ({ id, onError }) => {
  try {
    const { data, error, status } = await getDetailsClassLinked(id)

    if (error) {
      onError?.({ error, status })
      return updateErrorMessage({
        error,
        status,
        feedbackMessage:
          FEEDBACK_SNACK.noAccessPermissionToViewDetailsClassLinked
      })
    }

    return data
  } catch (error) {
    return onError?.()
  }
}

const getDisciplines = async ({ classId, professorId, inactive, onError }) => {
  const { data, error, status } = await getReplacedSubjects({
    professor_id: professorId,
    school_class_id: classId,
    inactive
  })

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListDisciplines
    })
  }

  return humps.camelizeKeys(data)
}

const updateReplacement = async ({ id, values, onError }) => {
  const params = values.map((value) => ({
    ...value,
    hourlyLessonCost: removeCurrencyMask(value.hourlyLessonCost)
  }))

  const { error, status } = await editDetailsClassReplacement(
    parseFloat(id),
    humps.decamelizeKeys(params)
  )

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToEditClassLinked
    })
  }

  return toastNotificationSuccess('Alteraçoes realizadas com sucesso', 1500)
}

const deleteClassReplacement = async ({
  substituteProfessorId,
  schoolClassId,
  replaces,
  onError
}) => {
  const payload = humps.decamelizeKeys({
    substituteProfessorId,
    schoolClassId,
    substitution: replaces
  })
  const { error, status } = await deleteDetailsClassReplacement(payload)

  if (error) {
    onError?.({ error, status })
    return updateErrorMessage({
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToDeleteClassLinked
    })
  }

  return toastNotificationSuccess(MESSAGE.inactivateSuccess, 1500)
}

export {
  getClassInfo,
  getDisciplines,
  updateReplacement,
  deleteClassReplacement
}
