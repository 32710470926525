import { ListAlt, Search } from '@mui/icons-material'

const initialStateData = { activities: [] }

const initialStateStatus = { empty: true, notFound: false }

const messages = {
  empty: {
    icon: <ListAlt />,
    text: '<strong>Encontre as solicitações de Atividades Complementares</strong><br />Você pode filtrar com outros critérios a sua busca'
  },
  notFound: {
    icon: <Search />,
    text: 'Não encontramos um resultado para essa busca.<br /> Verifique os dados informados e tente novamente.'
  }
}

export { initialStateData, initialStateStatus, messages }
