/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, PageTitle } from 'components/atoms'
import { LoadingPage } from 'components/molecules'
import { defaultActivePage, defaultlistPerPage } from 'services/hooks/constants'

import { ROUTE } from 'services/constants'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { formatDate, getUserFromLocalStorage } from 'services/helpers'
import { DisplaySituation, Table } from './components'
import { acceptTermReEnrollment, reEnrollmentList } from './service'
import * as Styled from './style'

const Content = () => {
  const { setLoading, loading } = useLoading()
  const { username } = getUserFromLocalStorage()
  const [data, setData] = useState(null)
  const [page, setPage] = useState(defaultActivePage)
  const [rowsPerPage, setRowsPerPage] = useState(defaultlistPerPage)
  const navigate = useNavigate()
  const callbackSuccess = () => {
    navigate(ROUTE.STUDENT_DISCIPLINE)
  }
  const handleClick = (path) => {
    window.open(path, '_blank')
  }

  const handleAcceptTerm = async ({
    isAutomatic = false,
    studentReenrollmentId = null
  }) => {
    await acceptTermReEnrollment({
      id: studentReenrollmentId ?? data.student_reenrollment_id,
      callbackSuccess,
      isAutomatic
    })
  }

  const formatarTextoHTML = (html) => {
    const textoSemStrong = html
      .replace(/<strong>/g, '')
      .replace(/<\/strong>/g, '')

    return textoSemStrong
  }

  useEffect(async () => {
    setLoading(true)
    const reEnrollmentInfo = await reEnrollmentList({
      username
    })

    if (!reEnrollmentInfo.pending_financial_situation) {
      await handleAcceptTerm({
        isAutomatic: true,
        studentReenrollmentId: reEnrollmentInfo.student_reenrollment_id
      })
    } else {
      setLoading(false)
    }

    setData(reEnrollmentInfo)
  }, [])

  return (
    <>
      {loading || !data ? (
        <LoadingPage />
      ) : (
        <Styled.Container>
          <Styled.Warning>
            <Styled.WarningTitle>
              INFORMAÇÃO SOBRE ACESSO AO PORTAL ACADÊMICO
            </Styled.WarningTitle>
            <Styled.WarningDescribe>
              Para acessar o Portal Acadêmico após a data limite de{' '}
              <strong>{formatDate(data?.reenrollment_deadline_date)}</strong>,
              você deverá entrar em contato com a Instituição.
            </Styled.WarningDescribe>
          </Styled.Warning>

          <PageTitle title={`Olá, ${data?.name}`} />
          {data?.course_name && (
            <Styled.Info>
              <Styled.InfoKey>Curso</Styled.InfoKey>
              <Styled.InfoValue>{data?.course_name}</Styled.InfoValue>
            </Styled.Info>
          )}

          <Styled.WrapperInfo>
            {data?.group && (
              <Styled.Info>
                <Styled.InfoKey>Grupo</Styled.InfoKey>
                <Styled.InfoValue>{data?.group}</Styled.InfoValue>
              </Styled.Info>
            )}

            {data?.unit && (
              <Styled.Info>
                <Styled.InfoKey>Unidade</Styled.InfoKey>
                <Styled.InfoValue>{data?.unit}</Styled.InfoValue>
              </Styled.Info>
            )}
            {data?.pole && (
              <Styled.Info>
                <Styled.InfoKey>Polo</Styled.InfoKey>
                <Styled.InfoValue>{data?.pole}</Styled.InfoValue>
              </Styled.Info>
            )}
          </Styled.WrapperInfo>

          <Styled.WarningPeriod $expired={data?.reenrollment_deadline_expired}>
            <Styled.WarningPeriodContent>
              <Styled.WarningPeriodTitle>
                {data?.reenrollment_deadline_expired
                  ? 'Período de Rematrícula encerrado!'
                  : 'Período de Rematrícula'}
              </Styled.WarningPeriodTitle>

              <Styled.WarningPeriodDescribe>
                <Styled.WarningPeriodTextWrap>
                  <Styled.WarningPeriodTextOrange>
                    ATENÇÃO!
                  </Styled.WarningPeriodTextOrange>
                  {data?.reenrollment_deadline_expired ? (
                    <>
                      A data limite de rematrícula foi ultrapassada
                      <strong>
                        ({formatDate(data?.reenrollment_deadline_date)})!
                      </strong>
                    </>
                  ) : (
                    'Estamos no período de Rematrícula e, conforme previsto em contrato, você somente poderá'
                  )}

                  <br />
                </Styled.WarningPeriodTextWrap>
                {data?.reenrollment_deadline_expired ? (
                  <Styled.Text>
                    Entre em contato com a{' '}
                    <strong>Secretaria/Coordenação </strong>
                    para confirmar sua rematrícula e regularizar sua situação,
                    caso possua alguma irregularidade informada abaixo.
                  </Styled.Text>
                ) : (
                  <>
                    realizar a rematrícula se a sua situação acadêmica e
                    financeira estiverem regulares na Instituição.
                    <br />
                    <br />
                    Confira as informações a baixo e, se houver alguma
                    irregularidade, regularize-se o mais rápido possível dentro
                    da data limite (
                    <strong>
                      {formatDate(data?.reenrollment_deadline_date)}
                    </strong>
                    ).
                    <br />
                    <br />
                    <Styled.WarningPeriodTextWrap strong>
                      Se estiver tudo certo, leia o{' '}
                      <Styled.WarningPeriodTextOrange>
                        Termo de Aceite
                      </Styled.WarningPeriodTextOrange>{' '}
                      e clique em “confirmar rematrícula” no final da página.
                    </Styled.WarningPeriodTextWrap>
                  </>
                )}
              </Styled.WarningPeriodDescribe>
            </Styled.WarningPeriodContent>
            <Styled.WarningPeriodImage src="/images/re-enrollment.svg" />
          </Styled.WarningPeriod>

          <Styled.StepTitle>Passo 1 - Verifique sua situação:</Styled.StepTitle>

          <Styled.Situations>
            <DisplaySituation
              situation={
                data?.pending_academic_situation ? 'irregular' : 'regular'
              }
              typeSituation="Situação Acadêmica"
              feedbackMessage={
                data?.pending_academic_situation
                  ? 'Documentação pendente. Entre em contato com a secretaria/coordenação.'
                  : 'Ótimo! Sua documentação acadêmica está em dia'
              }
            />
            <DisplaySituation
              typeSituation="Situação Financeira"
              feedbackMessage={
                data?.pending_financial_situation
                  ? `Você possui ${data.student_expired_bills.length} título(s) pendente(s), confira abaixo.`
                  : 'Ótimo! Seus títulos de pagamento estão em dia'
              }
              situation={
                data?.pending_financial_situation ? 'irregular' : 'regular'
              }
            />
          </Styled.Situations>
          {data?.student_expired_bills?.length > 0 && (
            <Table
              page={page}
              setPage={setPage}
              handleClick={handleClick}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              dataTable={data?.student_expired_bills}
            />
          )}

          {!data?.pending_financial_situation &&
            !data?.pending_academic_situation &&
            !data?.reenrollment_deadline_expired && (
              <>
                <Styled.StepTitle>
                  Passo 2 - Leia o termo abaixo com atenção:
                </Styled.StepTitle>

                <Styled.Term>
                  <Styled.TermTitle>TERMO DE ACEITE</Styled.TermTitle>

                  <Styled.TermContent>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formatarTextoHTML(
                          data?.text_term_reenrollment || ''
                        )
                      }}
                    />
                  </Styled.TermContent>

                  <Styled.TermStudentAccept>
                    Eu, {data?.name}, concordo com os termos acima descritos.
                  </Styled.TermStudentAccept>

                  <Button onClick={handleAcceptTerm}>
                    Confirmar rematrícula
                  </Button>
                </Styled.Term>
              </>
            )}

          {(data?.pending_financial_situation ||
            data?.pending_academic_situation) && (
            <Styled.TextErro>
              Não é possível confirmar sua rematrícula pois você apresenta
              <strong> SITUAÇÃO IRREGULAR</strong>. Para regularizar sua
              situação, entre em contato com a Secretaria/Coordenação.
            </Styled.TextErro>
          )}
        </Styled.Container>
      )}
    </>
  )
}

export default Content
