import styled from 'styled-components'
import { Grid } from '@mui/material'

const CardContainer = styled(Grid)`
  border-radius: 8px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;

  padding: 24px;
  box-sizing: border-box;
`

const WrapperButtons = styled(Grid)`
  display: flex;
  gap: 25px;
  padding: 0 0 0 24px;
  margin: 0;

  button {
    margin: 0;
  }
`

const FormBox = styled.form``

export { CardContainer, WrapperButtons, FormBox }
