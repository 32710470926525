import { lazy, useState } from 'react'
import { Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { convertToParams, makeURLAccessLegacy } from 'services/helpers'
import { useSnack } from 'services/hooks'
import { MESSAGE, SUCCESS, breadcrumbsItems, initialState } from './constants'
import { FormFormik } from './styles'

const Form = lazy(() => import('./components/Form'))

const CreateDesclaration = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries([...searchParams])
  const { name, register } = params
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [selectedRequirement, setSelectedRequirement] = useState([])

  const onHandleConfirm = () => {
    setOpenModal(false)
    navigate(-1)
  }

  const savedSuccessfully = () => {
    setSnack(MESSAGE.SUCCESS, SUCCESS)

    return setTimeout(() => navigate(-1), 3000)
  }

  const onSubmit = (values) => {
    makeURLAccessLegacy(
      `acesso/aluno_declaracao_pdf.php?${convertToParams({
        ano: values.ano,
        turma: values.turma,
        curso: values.curso,
        modelo: values.modelo,
        reqturma: values.reqturma,
        timbrado: values.timbrado,
        horario_fim: values.horario_fim,
        observacoes: values.observacoes,
        horario_inicio: values.horario_inicio,
        matricula: register,
        titulo: selectedRequirement
      })}`,
      true
    )

    return savedSuccessfully()
  }

  return (
    <TemplateDefaultHeaderByContent
      classNameHeader="goBack"
      loadingOpen={loading}
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems({ name })}
      setLoading={setLoading}
      open={openModal}
      handleCloseModal={() => setOpenModal(false)}
      handleConfirmModal={() => onHandleConfirm()}
      onClickButton={() => setOpenModal(true)}
      content={
        <>
          <Formik
            initialValues={initialState}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(props) => (
              <FormFormik
                onSubmit={props.handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Form
                  {...props}
                  setSnack={setSnack}
                  setLoading={setLoading}
                  register={register}
                  setOpenModal={setOpenModal}
                  selectedRequirement={selectedRequirement}
                  setSelectedRequirement={setSelectedRequirement}
                />
              </FormFormik>
            )}
          </Formik>
        </>
      }
    />
  )
}

export default CreateDesclaration
