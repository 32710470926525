const parserCountriesOptions = (countries) =>
  countries
    .map(({ value, name, nationality }) => ({
      value: value.toLowerCase(),
      label: name,
      nationality
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

export { parserCountriesOptions }
