import { minLengthClassName } from './constants'

const isDisabledButton = (values) => {
  if (values.switch_advanced_search) {
    return Boolean(values.unity)
  }
  const enable = values.class.length > minLengthClassName
  return enable
}

export { isDisabledButton }
