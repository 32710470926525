import { lazy, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListAlt, Search } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import { useActionSelected, usePaginationBasic, useSnack } from 'services/hooks'
import {
  IsEmpty,
  mergeInitialValues,
  updateErrorMessage
} from 'services/helpers'
import { ROUTE } from 'services/constants'
import { getDisciplines } from 'services/api/admin/management/Subjects'
import { Form, Formik } from 'formik'
import {
  MESSAGE,
  initialState,
  typeAction,
  countDefault,
  defaultItemsPerPage,
  nameAccordion,
  newDiscipline
} from './constants'
import { useSubjects } from './Context/FormnContext'
import { FormSearchSubjects } from './FormSearchSubjects'

const Table = lazy(() => import('./Table'))

const ListSubjects = () => {
  const snackProps = useSnack()
  const location = useLocation()
  const navigate = useNavigate()

  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notSearch, setNotSearch] = useState(true)
  const [listSubject, setListSubject] = useState([])
  const [count, setCount] = useState(null)
  const { onActionSelected } = useActionSelected()
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const isSearchParam = location?.state?.savedSearch
  const [valuesForm, setvaluesForm] = useState(null)

  const { formValues, setFormValues, resetFormValues } = useSubjects()
  const { setSnack } = snackProps
  const {
    page: pageActual,
    totalPage,
    items,
    setPage
  } = usePaginationBasic(listSubject, filter, count)

  const handleSearch = async (values) => {
    setNotSearch(false)
    setLoadingOpen(true)

    const params = {
      ...(values?.discipline && { discipline: values?.discipline }),
      ...(values?.level && { level: values?.level }),
      ...(values?.is_ead && { is_ead: values?.is_ead }),
      ...(values?.is_inactive && {
        is_inactive: values?.is_inactive
      }),
      limit: filter,
      page: pageActual
    }
    const { data, error, status } = await getDisciplines(params)
    setLoadingOpen(false)
    setFormValues(values)
    setvaluesForm(values)

    if (error) {
      setNotSearch(true)
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToListSubjects
      })
    }

    if (data?.count === countDefault) {
      setNotSearch(false)

      return setListSubject([])
    }

    setListSubject(data?.data)
    setCount(data?.count)
    return setNotSearch(true)
  }

  useEffect(() => {
    if (isSearchParam && (formValues?.discipline || formValues?.level)) {
      return handleSearch(formValues)
    }
    return setFormValues(initialState)
  }, [isSearchParam])

  useEffect(() => {
    if (valuesForm !== null) {
      handleSearch(valuesForm)
    }
  }, [pageActual, filter])

  const initialValues = mergeInitialValues(
    initialState,
    isSearchParam && formValues
  )

  const messageInformative = notSearch
    ? MESSAGE.NO_SEARCH
    : MESSAGE.NOT_FOUND_SEARCH

  const onClear = () => {
    resetFormValues()
    setLoadingOpen(false)
    setNotSearch(true)
    setListSubject()

    navigate(location?.pathname, {
      state: {
        savedSearch: undefined
      },
      replace: true
    })
  }

  return (
    <TemplateDefaultFormAndResult
      titleHeader="Gestão - Disciplinas"
      snackProps={snackProps}
      loadingOpen={loadingOpen}
      labelButtonHeader="Nova disciplina"
      listItems={listSubject}
      iconInformative={notSearch ? <ListAlt /> : <Search />}
      messageInformative={messageInformative}
      classNameHeader={TAG_MANAGER.management_btn_create_new_Discipline}
      onClickButton={() => {
        navigate(ROUTE.ADMIN_MANAGEMENT_SUBJECTS_CREATE, {
          state: { newDiscipline }
        })
      }}
      formHeader={
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSearch}
            enableReinitialize
          >
            {({ ...props }) => (
              <>
                <Form noValidate autoComplete="off">
                  <FormSearchSubjects
                    {...props}
                    onClear={onClear}
                    setPage={setPage}
                    setFilter={setFilter}
                    setSnack={snackProps.setSnack}
                    isSearchParam={isSearchParam}
                    initialState={initialValues}
                    handleSearch={handleSearch}
                    nameAccordion={nameAccordion}
                  />
                </Form>
              </>
            )}
          </Formik>
        </>
      }
    >
      {listSubject && !IsEmpty(listSubject) && (
        <Table
          rowsItems={items}
          count={count}
          setLoadingOpen={setLoadingOpen}
          setSnack={snackProps.setSnack}
          handleSearch={handleSearch}
          formValues={formValues}
          setFilter={setFilter}
          setPage={setPage}
          totalPage={totalPage}
          filter={filter}
          pageActual={pageActual}
          setOpenModalMatrices={(item) => onActionSelected(item)}
          setOpenModal={(item) => onActionSelected(typeAction.copy, item)}
          onValues={(item) => onActionSelected(typeAction.changeOfValues, item)}
        />
      )}
    </TemplateDefaultFormAndResult>
  )
}
export default ListSubjects
