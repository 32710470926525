import { useEffect, useState } from 'react'
import {
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel
} from '@mui/material'
import { typeMask } from 'services/helpers'
import { MAX_LENGTH_PHONE } from 'services/constants'
import * as Styled from './style'
import { shiftList } from './constants'

const BoxPhones = ({
  isShift = false,
  labelPhoneFirst,
  labelPhoneSecond,
  phoneFirstFieldName,
  phoneFirstValues,
  phoneSecondFieldName,
  phoneSecondValues,
  handleChange,
  fieldNameCheck,
  setFieldValue,
  valueCheckbox,
  isRequiredShift
}) => {
  const [values, setValues] = useState({
    [phoneFirstFieldName]: phoneFirstValues,
    [phoneSecondFieldName]: phoneSecondValues,
    morningShift: false,
    afternoonShift: false,
    nightShift: false
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target
    handleChange(event)
    setValues((prevValues) => ({ ...prevValues, [name]: value }))
  }

  const handleChangeShiftCheckbox = (type) => {
    setValues((prevValues) => ({
      ...prevValues,
      morningShift: type === 'morning',
      afternoonShift: type === 'afternoon',
      nightShift: type === 'night'
    }))
  }

  useEffect(() => handleChangeShiftCheckbox(valueCheckbox), [])

  useEffect(() => {
    const { morningShift, afternoonShift, nightShift } = values
    const shiftObject = {
      morning: morningShift,
      afternoon: afternoonShift,
      night: nightShift
    }

    if (fieldNameCheck) {
      const selectedShift =
        Object.keys(shiftObject).find((key) => shiftObject[key]) || ''
      setFieldValue(fieldNameCheck, selectedShift)
    }
  }, [values])

  return (
    <Styled.Container>
      {[phoneFirstFieldName, phoneSecondFieldName]?.map(
        (phoneFieldName, index) => (
          <TextField
            key={phoneFieldName}
            label={!index ? labelPhoneFirst : labelPhoneSecond}
            name={phoneFieldName}
            value={typeMask('PHONE', values[phoneFieldName]) || ''}
            onChange={handleInputChange}
            inputProps={{
              maxLength: MAX_LENGTH_PHONE
            }}
            isMargin="0"
            fullWidth
          />
        )
      )}

      {isShift && (
        <Styled.BoxTooltip>
          <FormControl>
            <FormLabel>
              Melhor horário para contato {isRequiredShift && '*'}
            </FormLabel>
            <FormGroup row>
              {shiftList.map(({ key, label }) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      onChange={() => handleChangeShiftCheckbox(key)}
                      checked={values[`${key}Shift`]}
                    />
                  }
                  label={label}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Styled.BoxTooltip>
      )}
    </Styled.Container>
  )
}

export default BoxPhones
