import {
  Feed,
  Inbox,
  LibraryAdd,
  MonetizationOn,
  VpnKey,
  ListAlt,
  PhoneIphone,
  Mail,
  SwapHoriz
} from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
// import { makeURLAccessLegacy } from './createRoutes'

const getMenuItems = ({
  item,
  handleRequeriment,
  handleTickets,
  handlePassword,
  handleLinkClassDiscipline,
  handleClose,
  handleSendSms,
  handleSendEmail,
  handleDeclaration,
  handleHistory,
  handleInteractions
}) => [
  {
    label: 'Declarações',
    icon: <Feed />,
    className: TAG_MANAGER.secretariat_btn_access_studentdeclarations,
    onClick() {
      handleClose()
      handleDeclaration(item)
    }
  },
  {
    label: 'Histórico',
    icon: <ListAlt />,
    className: TAG_MANAGER.secretariat_btn_access_studenthistory,
    onClick() {
      handleClose()
      handleHistory(item)
    }
  },
  // {
  //   label: 'Histórico',
  //   icon: <ListAlt />,
  //   className: TAG_MANAGER.secretariat_btn_access_studenthistory,
  //   onClick() {
  //     handleClose()
  //     const legacyUrl = makeURLAccessLegacy(
  //       `acesso/aluno_historico.php?id=${item.register}`
  //     )
  //     window.open(legacyUrl, '_blank')
  //   }
  // },
  {
    label: 'Requerimentos',
    icon: <Inbox />,
    className: TAG_MANAGER.secretary_btn_access_studentrequirements,
    onClick() {
      handleClose()
      handleRequeriment(item)
    }
  },
  {
    label: 'Vincular turma/disciplina',
    icon: <LibraryAdd />,
    className: TAG_MANAGER.secretariat_btn_access_linkStudentDiscipline,
    onClick() {
      handleLinkClassDiscipline()
    }
  },
  {
    label: 'Boletos',
    icon: <MonetizationOn />,
    className: TAG_MANAGER.secretary_btn_open_slipStudent,
    onClick() {
      handleClose()
      handleTickets(item)
    }
  },
  {
    label: 'Redefinir senha',
    icon: <VpnKey />,
    className: TAG_MANAGER.secretary_btn_start_changeStudentPassword,
    onClick() {
      handleClose()
      handlePassword(item)
    }
  },
  {
    label: 'Enviar SMS',
    icon: <PhoneIphone />,
    className: TAG_MANAGER.secretariat_btn_access_linkStudentInteractions,
    onClick() {
      handleSendSms(item)
    }
  },
  {
    label: 'Enviar E-mail',
    icon: <Mail />,
    className: TAG_MANAGER.secretariat_btn_access_linkStudentInteractions,
    onClick() {
      handleSendEmail(item)
    }
  },
  {
    label: 'Interações',
    icon: <SwapHoriz />,
    className: TAG_MANAGER.secretariat_btn_access_linkStudentInteractions,
    onClick() {
      handleInteractions(item)
    }
  }
]

export { getMenuItems }
