import { updateErrorMessage } from 'services/helpers'
import { countDefault } from './constants'

const getParams = ({ values, filter, pageActual }) => ({
  ...(values?.unity && { unit: values.unity }),
  ...(values?.level && { level: values.level }),
  ...(values?.course && { course: values.course }),
  ...(values?.pole && { pole: values.polo }),
  limit: filter,
  page: pageActual
})

const fetchData = async ({
  params,
  endpoint,
  setState,
  setLoading,
  feedbackMessage,
  setSnack,
  setSearch,
  setAccordion
}) => {
  const { data, error, status } = await endpoint(params)
  setLoading(false)
  setAccordion(false)

  if (error) {
    setSearch(true)
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage
    })
  }

  if (data?.count === countDefault) {
    setSearch(false)
    return setState({
      list: [],
      count: null
    })
  }

  return setState({
    list: data?.data,
    count: data?.count
  })
}
export { getParams, fetchData }
