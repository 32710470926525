import TAG_MANAGER from 'tagManager'

export const newActivityAddSuccess = 'Nova atividade adicionada com sucesso'
export const editActivitySuccess = 'Atividade editada com sucesso'
export const maxPage = 2
export const itemsPerPage = 4
export const defaultPage = 1
export const typeCreate = 'create'
export const typeEdit = 'edit'
export const typeDelete = 'delete'
export const typeScore = 'score'
export const validButtonText = 3
export const TIME_REGEX = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/
export const TIME_REGEX_ERROR = 'Formato de data inválido: deve ser HH:mm'
export const CUSTOM_SHIFT = 'custom'
export const ACTIVITY_TYPE_INTEGRATED_EVALUATION = 2008
export const ROLE_ADMIN = 'admin'

export const classManager = {
  assessmentsButtonRemove: TAG_MANAGER.btn_teacher_deletes_activity,
  assessmentsButtonAdd: TAG_MANAGER.btn_teacher_changes_activity,
  assessmentsButtonNew: TAG_MANAGER.btn_teacher_requests_create_Activity,

  diversifiedButtonRemove: TAG_MANAGER.btn_teacher_delete_atividadeOnline,
  diversifiedButtonAdd: TAG_MANAGER.btn_teacher_changes_atividadeOnline,
  diversifiedButtonNew: TAG_MANAGER.btn_teacher_requests_criarAtividadeOnline,

  recoveryButtonRemove: TAG_MANAGER.btn_teacher_delete_recovery,
  recoveryButtonAdd: TAG_MANAGER.btn_teacher_changes_recovery,
  recoveryButtonNew: TAG_MANAGER.btn_teacher_request_newRecovery
}
