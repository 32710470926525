const minLimitCharacter = 4
const addMoreOne = 1
const textBatchAction =
  'Preencha o campo abaixo para definir quem deverá ministrar as disciplinas selecionadas:'
const textAddProfessor =
  'Preencha os campos abaixo para definir quem deverá ministrar as disciplinas:'

const orderList = [
  {
    value: 1,
    label: '1°'
  },
  {
    value: 2,
    label: '2°'
  },
  {
    value: 3,
    label: '3°'
  },
  {
    value: 4,
    label: '4°'
  },
  {
    value: 5,
    label: '5°'
  },
  {
    value: 6,
    label: '6°'
  },
  {
    value: 7,
    label: '7°'
  },
  {
    value: 8,
    label: '8°'
  }
]

export {
  orderList,
  minLimitCharacter,
  addMoreOne,
  textBatchAction,
  textAddProfessor
}
