import { css } from 'styled-components'
import { SUBJECT_STATUS } from 'services/constants'

const defaultItemsPerPage = 10

const headColumns = {
  discipline: 'discipline',
  module: 'module',
  workload: 'workload',
  status: 'status'
}

const headCells = [
  {
    id: headColumns.discipline,
    numeric: false,
    disabled: false,
    label: 'Disciplina'
  },
  {
    id: headColumns.module,
    numeric: false,
    disabled: false,
    label: 'Módulo'
  },
  {
    id: headColumns.workload,
    numeric: false,
    disabled: false,
    label: 'Carga Horária'
  },

  {
    id: headColumns.status,
    numeric: false,
    disabled: false,
    label: 'Situação'
  }
]

const statusColorBySituation = {
  [SUBJECT_STATUS.NOT_LINKED]: css`
    background-color: ${({ theme }) => theme.palette.gray.light};
    color: ${({ theme }) => theme.palette.textColors.black.textLightPrimary};
  `,
  [SUBJECT_STATUS.PASSING_GRADE]: css`
    background-color: ${({ theme }) => theme.palette.red.light};
    color: ${({ theme }) => theme.palette.primary.white};
  `,
  [SUBJECT_STATUS.LINKED]: css`
    background-color: ${({ theme }) => theme.palette.success.main};
    color: ${({ theme }) => theme.palette.primary.white};
  `
}
export { defaultItemsPerPage, headCells, headColumns, statusColorBySituation }
