import styled from 'styled-components'
import {
  Paper as MuiPaper,
  TableContainer as MuiTableContainer
} from '@mui/material'

const Paper = styled(MuiPaper)`
  &.MuiPaper-root {
    width: 100%;
  }
`

const TableContainer = styled(MuiTableContainer)``

export { Paper, TableContainer }
