import { ROUTE } from 'services/constants'

const breadcrumbsItems = (paymentPlanId, period) => [
  {
    path: ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN_CREATE,
    label: 'Financeiro'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN_CREATE,
    label: 'Planos de pagamento'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_PAYMENT_PLAN_CREATE,
    label: paymentPlanId
      ? `<b>${paymentPlanId} - ${period}</b>`
      : '<b>Novo plano</b>'
  }
]

export { breadcrumbsItems }
