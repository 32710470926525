import { ROUTE } from 'services/constants'

const selectAllTypes = 'Todos'
const defaultTotalPage = 1

const type = {
  situation: {
    pending: 'pending',
    effective: 'effective',
    inactive: 'inactive'
  }
}

const breadcrumbsItems = [
  {
    path: ROUTE.ADMIN_FINANCIAL_BILLS_RECEIVE,
    label: 'Financeiro'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_BILLS_RECEIVE,
    label: 'Contas a Receber'
  },
  {
    path: ROUTE.ADMIN_FINANCIAL_BREAKDOWN,
    label: '<b>Detalhamento Financeiro</b>'
  }
]

const initialState = {
  situation: 'pending',
  type: '',
  reason: '',
  switch: false
}
export {
  type,
  selectAllTypes,
  defaultTotalPage,
  breadcrumbsItems,
  initialState
}
