import styled from 'styled-components'
import {
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  CardActions as MuiCardActions
} from '@mui/material'
import { pathCheck } from 'services/helpers/ramda'
import theme from 'theme/designTokens'

const Card = styled(MuiCard)`
  &.MuiCard-root {
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.05);
    width: 100%;
  }
`

const CardHeader = styled(MuiCardHeader)`
  &.MuiCardHeader-root {
    width: 100%;
    min-height: 100px;
  }

  &.MuiCardHeader-root .MuiCardHeader-title {
    font-size: 16px;
    font-weight: bold;
  }
`

const CardActions = styled(MuiCardActions)`
  &.MuiCardActions-root {
    display: ${({ $disabledDiary }) => ($disabledDiary ? 'none' : 'grid')};
    grid-template-columns: 50px 56px;
    grid-gap: 32px;
    justify-content: center;
  }
`

const LinkWrapper = styled.div`
  background: transparent;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  transition: background cubic-bezier(0.15, 0.77, 0.69, 1.26) 0.3s;

  & > a {
    align-items: center;
    display: flex;
    color: ${pathCheck(['palette', 'primary', 'main'], theme)};
    font-size: 1.3rem;
    justify-content: center;
    padding: 0.8rem;
    transition: background cubic-bezier(0.15, 0.77, 0.69, 1.26) 0.3s;
  }

  &:hover {
    background: ${({ color }) =>
      color
        ? pathCheck(['palette', [color], 'light'], theme)
        : pathCheck(['palette', 'primary', 'main'], theme)};
    transition: filter cubic-bezier(0.55, 0.38, 1, 1) 0.3s;

    & > a {
      color: ${({ color }) =>
        color
          ? pathCheck(['palette', [color], 'main'], theme)
          : pathCheck(['palette', 'primary', 'white'], theme)};
      transition: filter cubic-bezier(0.55, 0.38, 1, 1) 0.3s;
    }
  }
`

export { Card, CardActions, LinkWrapper, CardHeader }
