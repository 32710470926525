import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import TAG_MANAGER from 'tagManager'
import FEEDBACK_SNACK from 'feedBackSnack'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useSnack } from 'services/hooks'
import { getGroup } from 'services/api/admin'
import { updateErrorMessage } from 'services/helpers'
import { mergeInitialValues } from './services'
import { activity, breadcrumbsItems, initialState, typePage } from './constants'
import FormGroup from './FormGroup'

const CreateByUpdateGroup = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [groupEdit, setGroupEdit] = useState(initialState)

  const params = Object.fromEntries([...searchParams])
  const { page: pageEdit, group: nameGroup } = params

  const snackProps = useSnack()
  const { setSnack } = snackProps

  const handleGetGroup = async () => {
    setSnack('', '')
    setLoadingOpen(true)
    const { data: response, error, status } = await getGroup(Number(params?.id))

    setLoadingOpen(false)

    if (error) {
      return updateErrorMessage({
        setSnack,
        error,
        status,
        feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToViewGroup
      })
    }

    return setGroupEdit(response)
  }

  useEffect(() => {
    if (nameGroup) handleGetGroup()
  }, [nameGroup])

  const initialValues = mergeInitialValues(groupEdit)
  const disabledFields = groupEdit?.active !== activity && pageEdit === typePage

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      breadcrumbsNotLink={breadcrumbsItems(nameGroup)}
      loadingOpen={loadingOpen}
      classNameHeader={`goBack ${TAG_MANAGER.btn_financier_cancels_new_group}`}
      onClickButton={() => {
        navigate(ROUTE.ADMIN_FINANCIAL_GROUPS, {
          state: { savedSearch: true }
        })
      }}
      content={
        <FormGroup
          nameGroup={nameGroup}
          setLoadingOpen={setLoadingOpen}
          params={params}
          setSnack={setSnack}
          initialValues={nameGroup ? initialValues : initialState}
          disabledFields={disabledFields}
        />
      }
    />
  )
}

export default CreateByUpdateGroup
