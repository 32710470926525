import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
import { ReportProblemOutlined } from '@mui/icons-material'
import { ButtonFooterV2, InputFile } from 'components/molecules'
import { makeURLAccessLegacy } from 'services/helpers'
import theme from 'theme/designTokens'
import { getRgba } from 'services/helpers/polished'
import * as Styled from './style'
import {
  MESSAGE,
  REMOVE_CHARACTERS,
  TYPE_FILE_XLS,
  TYPE_FILE_XLSX
} from './constants'
import { importIntegrateEvaluations } from '../../services'

const ModalIntegratedAssessment = ({
  open,
  setSnack,
  setLoadingOpen,
  setFormIA,
  handleClose,
  formAddIA,
  setErrorTypeFile,
  errorTypeFile
}) => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id } = params
  const [nameFile, setNameFile] = useState('excel')
  const handleFileChange = ({ event, setFieldValue }) => {
    if (event === '') {
      return setFieldValue('file', '')
    }

    const selectedFile = event.target.files[0]

    if (selectedFile.type === TYPE_FILE_XLSX) {
      setNameFile(selectedFile.name)
      const readFileAsBase64 = (file) =>
        new Promise((resolve) => {
          const reader = new FileReader()
          reader.onload = () => resolve(reader.result)
          reader.readAsDataURL(file)
        })

      return readFileAsBase64(selectedFile).then((base64String) => {
        setErrorTypeFile(false)
        setFieldValue('file', base64String.slice(REMOVE_CHARACTERS.length))
      })
    }

    return setErrorTypeFile(true)
  }

  return (
    <Styled.Modal open={open} onClose={handleClose} maxWidth="md">
      <Formik
        initialValues={formAddIA}
        onSubmit={(values) =>
          importIntegrateEvaluations({
            id,
            values,
            setSnack,
            setLoadingOpen,
            handleClose,
            setFormIA
          })
        }
        enableReinitialize
      >
        {({ values, setFieldValue, handleSubmit, dirty }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid>
              <Grid container spacing={{ xs: 0, sm: 2 }}>
                <Grid item xs={12} sm={12} md={11}>
                  <Styled.Title variant="h4" component="h4">
                    Importar Avaliação Integrada
                  </Styled.Title>
                </Grid>

                <Styled.GridClose item xs={12} sm={12} md={1}>
                  <Styled.Close onClick={() => handleClose()} />
                </Styled.GridClose>

                <Grid item xs={12} sm={12} md={12}>
                  <Styled.SubTitle variant="body1" component="body1">
                    <ReportProblemOutlined />
                    Essa importação apaga as notas existentes e substitui pelas
                    encontradas na nova planilha.
                  </Styled.SubTitle>
                  <Styled.LinkTemplate
                    onClick={() =>
                      makeURLAccessLegacy(
                        'externos/files/template_avaliacao_integrada.xlsx',
                        true
                      )
                    }
                  >
                    Baixar Template
                  </Styled.LinkTemplate>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  $errorTypeFile={errorTypeFile}
                >
                  <InputFile
                    idUpload="upload"
                    typeFile="excel"
                    labelBox={errorTypeFile ? MESSAGE.ERROR : MESSAGE.SUCCESS}
                    imgSrc={values?.file || ''}
                    name="avaliação integrada"
                    nameFile={nameFile}
                    hasSize
                    errorTypeFile={errorTypeFile}
                    border={`2px dashed ${theme.palette.dark.light}`}
                    backgroundColor={
                      errorTypeFile
                        ? getRgba(theme.palette.red.light, 0.21)
                        : theme.palette.purple.light
                    }
                    width="auto"
                    onChange={(e) =>
                      handleFileChange({ event: e, setFieldValue })
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <ButtonFooterV2
                    labelClose="Cancelar"
                    labelConfirm="Importar"
                    size="large"
                    onClickClose={() => {
                      setErrorTypeFile(false)
                      handleFileChange({ event: '', setFieldValue })
                    }}
                    disabledConfirm={errorTypeFile || !dirty}
                  />
                </Grid>
              </Grid>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Styled.Modal>
  )
}

export { ModalIntegratedAssessment }
