import { ROUTE } from 'services/constants'

const breadcrumbsItems = [
  {
    path: ROUTE.ADMIN_COMMERCIAL_REGISTRATION_CREATE,
    label: '<b>Sistema - </b> Tipos de Declarações'
  }
]

const isDisabledButton = ({ level, name }) => {
  const enabled = level && name
  return !enabled
}

const isDisabledButtonList = ({ level, name }) => {
  const enabled = level || name
  return !enabled
}
export { breadcrumbsItems, isDisabledButton, isDisabledButtonList }
