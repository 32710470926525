import styled from 'styled-components'

const WrapperFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px;
  height: 44px;
`

export { WrapperFooter }
