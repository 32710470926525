import humps from 'humps'
import { getResponsibleFinancial as getResponsibleFinancialService } from 'services/api/admin'
import { messageErroOrSuccess } from 'services/helpers'

import { formatInitialValuesResponsible } from './helpers'

const getResponsibleFinancial = async ({
  setLoading,
  setResponsibles,
  registerId
}) => {
  setLoading(true)
  const { error, status, data } = await getResponsibleFinancialService(
    registerId
  )

  messageErroOrSuccess({
    error,
    status
  })

  const newData = humps.camelizeKeys(data)
  const responsibles = (newData ?? [])?.map(formatInitialValuesResponsible)

  setResponsibles(responsibles)
  setLoading(false)
}

export { getResponsibleFinancial }
