import { Typography } from '@mui/material'
import { ControlPoint } from '@mui/icons-material'
import TAG_MANAGER from 'tagManager'
import * as Styled from './style'

const TableToolbar = ({ setIsOpen, amount }) => (
  <Styled.ToolbarArea>
    <Styled.Button
      variant="outlined"
      onClick={setIsOpen}
      startIcon={<ControlPoint sx={{ color: '#ED6C02' }} />}
      className={TAG_MANAGER.sistema_btn_ativa_contratos}
    >
      adicionar à lista de unificação
    </Styled.Button>
    <Typography variant="body2">
      {amount > 1
        ? `${amount} itens selecionados`
        : `${amount} item selecionado`}
    </Typography>
  </Styled.ToolbarArea>
)
export default TableToolbar
