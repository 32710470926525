const OCCURRENCES = {
  btn_secretary_create_occurrence: 'secretaria_btn_criar_ocorrencia',
  btn_secretary_search_for_occurrence: 'secretaria_btn_busca_ocorrencia',
  btn_secretary_inactive_occurrence: 'secretaria_btn_inativa_ocorrencia',
  btn_secretary_active_occurrence: 'secretaria_btn_ativa_ocorrencia',
  btn_secretary_complements_occurrence: 'secretaria_btn_complementa_ocorrencia',
  btn_secretary_starts_inactive_Occurrence_Batch:
    'secretaria_btn_inicia_inativaOcorrenciaLote',
  btn_secretary_starts_Inactive_Occurrence:
    'secretaria_btn_inicia_InativaOcorrencia',
  btn_secretary_starts_complements_Occurrence:
    'secretaria_btn_inicia_complementaOcorrencia',
  btn_secretary_saves_occurrence: 'secretaria_btn_salva_ocorrencia',
  btn_secretary_cancel_editOccurrence: 'secretaria_btn_cancela_editaOcorrencia'
}

const REQUISITION = {
  secretary_btn_create_requirement: 'secretaria_btn_criar_requerimento',
  secretary_btn_search_requirement: 'secretaria_btn_busca_requerimento',
  secretaria_btn_initiates_deferBatchApplication:
    'secretaria_btn_start_defereRequerimentoLote',
  secretary_btn_inicia_defereRequirementBatch:
    'secretaria_btn_start_cancelBatchRequest',
  secretary_btn_open_requirement: 'secretaria_btn_abre_requerimento',
  secretary_btn_open_stundentFile: 'secretaria_btn_abre_fichaAluno',
  secretary_btn_start_cancelRequirement:
    'secretaria_btn_inicia_cancelaRequerimento',
  secretary_btn_cancel_requirement: 'secretaria_btn_cancela_requerimento',
  secretary_btn_defers_requirement: 'secretaria_btn_defere_requerimento',
  secretary_btn_initiates_cancelBatchRequest:
    'secretaria_btn_inicia_cancelaRequerimentoLote',
  secretary_btn_start_deferBatchRequest:
    'secretaria_btn_inicia_defereRequerimentoLote',
  secretary_btn_start_cancelApplication:
    'secretaria_btn_inicia_defereRequerimento',
  secretary_btn_updates_situationRequest:
    'secretaria_btn_atualiza_situacaoRequerimento',
  secretary_btn_cancelEditApplication:
    'secretaria_btn_cancela_editaRequerimento',
  secretary_btn_BackApplication: 'secretaria_btn_volta_editaRequerimento'
}

const SCHOOL_CLASS = {
  secretary_btn_search_student: 'secretaria_btn_busca_aluno',
  secretary_bnt_delete_scoolCLass: 'academico_bnt_exclui_turma',
  secretary_bnt_search_scoolCLass: 'academico_bnt_busca_turmas',
  secretary_bnt_cria_newScoolCLass: 'academico_bnt_cria_novaTurma',
  secretary_bnt_details_scoolCLass: 'academico_bnt_acessa_detalhesTurma',
  secretary_bnt_export_generalReport:
    'academico_bnt_confirma_exportaRelatorioGeralTurmas',
  secretary_btn_access_studentrecord: 'secretaria_btn_acessa_fichaAluno',
  secretariat_btn_access_studentdeclarations:
    'secretaria_btn_acessa_declaracoesAluno',
  secretary_btn_access_studentrequirements:
    'secretaria_btn_acessa_requerimentosAluno',
  secretariat_btn_access_linkStudentDiscipline:
    'secretaria_btn_acessa_vincularDisciplinaAluno',
  secretariat_btn_access_linkStudentDocument:
    'secretaria_btn_acessa_vincularDocumentoAluno',
  secretariat_btn_access_linkStudentInteractions:
    'secretaria_btn_acessa_vincularInterações Aluno',
  secretary_btn_open_slipStudent: 'secretaria_btn_abre_boletosAluno',
  secretary_btn_start_changeStudentPassword:
    'secretaria_btn_inicia_alteraSenhaAluno',
  secretariat_btn_download_slipStudent: 'secretaria_btn_baixa_boletoAluno',
  secretariat_btn_send_studentslip: 'secretaria_btn_envia_boletoAluno',
  secretariat_btn_download_payment_voucher:
    'secretaria_btn_baixa_comprovante_pagamento',
  secretariat_btn_student_score: 'secretaria_btn_boletimAluno',
  secretariat_btn_re_enrollment_student: 'secretaria_btn_rematrículaAluno',
  secretary_btn_confirm_changeStudentPassword:
    'secretaria_btn_confirma_alteraSenhaAluno',
  secretaria_btn_confirma_cadastrarNovaJustificativa:
    'secretaria_btn_confirma_cadastrarNovaJustificativa',
  secretaria_btn_cadastrar_novaJustificativa:
    'secretaria_btn_cadastrar_novaJustificativa',
  secretaria_btn_acessa_justificativasDeFaltas:
    'secretaria_btn_acessa_justificativasDeFaltas'
}

const DOCUMENTS = {
  secretary_documents_select_toggles_fieldSearch:
    'secretaria_documentos_select_alterna_campo_busca',
  secretary_btn_search_documents: 'secretaria_btn_busca_documentosPessoais',
  secretary_btn_access_student_documents:
    'secretaria_btn_acessa_documentosFichaAluno',
  secretary_btn_filter_documents_pending:
    'secretaria_btn_busca_documentosPendentes',
  secretary_btn_export_documents_pending:
    'secretaria_btn_baixa_listaDocumentosPendentes',
  secretary_btn_filter_documents_conferred:
    'secretaria_btn_busca_documentosConferidos',
  secretary_btn_export_documents_conferred:
    'secretaria_btn_baixa_listaDocumentosConferidos',
  secretary_btn_filter_documents_rejected:
    'secretaria_btn_busca_documentosIndeferidos',
  secretary_btn_export_documents_rejected:
    'secretaria_btn_baixa_listaDocumentosIndeferidos'
}

const CONTRACTS = {
  sistema_btn_cria_novoContrato: 'sistema_btn_cria_novoContrato',
  sistema_btn_busca_contratos: 'sistema_btn_busca_contratos',
  sistema_btn_inativa_Contratos: 'sistema_btn_inativa_Contratos',
  sistema_btn_confirma_inativarContratos:
    'sistema_btn_confirma_inativarContratos',
  sistema_btn_confirma_inativarContrato:
    'sistema_btn_confirma_inativarContrato',
  sistema_btn_baixa_contrato: 'sistema_btn_baixa_contrato',
  sistema_btn_inativa_contrato: 'sistema_btn_inativa_contrato',
  sistema_btn_edita_contrato: 'sistema_btn_edita_contrato',
  sistema_btn_ativa_contrato: 'sistema_btn_ativa_contrato',
  sistema_btn_confirma_ativarContrato: 'sistema_btn_confirma_ativarContrato',
  sistema_btn_ativa_contratos: 'sistema_btn_ativa_contratos',
  sistema_btn_confirma_ativarContratos: 'sistema_btn_confirma_ativarContratos',
  sistema_btn_cancela_novoContrato: 'sistema_btn_cancela_novoContrato',
  sistema_btn_salva_novoContrato: 'sistema_btn_salva_novoContrato',
  sistema_btn_confirma_cancelarNovoContrato:
    'sistema_btn_confirma_cancelarNovoContrato',
  sistema_btn_cancela_editarContrato: 'sistema_btn_cancela_editarContrato',
  sistema_btn_salva_editarContrato: 'sistema_btn_salva_editarContrato',
  sistema_btn_confirma_cancelarEditarContrato:
    'sistema_btn_confirma_cancelarEditarContrato',

  secretary_contracts_select_toggles_fieldSearch:
    'secretaria_contratos_select_alterna_campo_busca',
  secretary_btn_search_contracts: 'secretaria_btn_busca_contratosMatricula',
  secretary_btn_download_student_contract:
    'secretaria_btn_baixa_contratoMatricula',
  secretary_btn_export_contracts_awaiting_signature:
    'secretaria_btn_busca_ContratosNaoAssinados',
  secretary_btn_filter_contracts_awaiting_signature:
    'secretaria_btn_baixa_listaContratosNaoAssinados',
  secretary_btn_export_contracts_signed:
    'secretaria_btn_busca_ContratosAssinados',
  secretary_btn_filter_contracts_signed:
    'secretaria_btn_baixa_listaContratosAssinados',
  secretary_btn_export_contracts_awaiting_access:
    'secretaria_btn_busca_contratosNaoAcessados',
  secretary_btn_filter_contracts_awaiting_access:
    'secretaria_btn_baixa_listaContratosNaoAcessados'
}

const ACADEMIC_PERFORMANCES = {
  secretary_academic_performances_select_toggles_fieldSearch:
    'secretaria_aproveitamentoEstudos_select_alterna_campo_busca',
  secretary_btn_search_academic_performances:
    'secretaria_btn_busca_aproveitamentoEstudos',
  secretary_btn_download_academic_performance:
    'secretaria_btn_baixa_analiseAproveitamentoEstudos',
  secretary_btn_delete_academic_performance:
    'secretaria_btn_exclui_aproveitamentoEstudos',
  secretary_btn_confirm_delete_academic_performance:
    'secretaria_btn_confirma_excluirAproveitamentoEstudos',
  secretary_btn_edit_academic_performance:
    'secretaria_btn_edita_aproveitamentoEstudos',
  secretary_btn_cancel_edit_academic_performance:
    'secretaria_btn_cancela_editarAproveitamentoEstudos',
  secretary_btn_confirm_cancel_edit_academic_performance:
    'secretaria_btn_confirma_cancelarAproveitamentoEstudos',
  secretary_btn_confirm_save_edit_academic_performance:
    'secretaria_btn_confirma_salvarAproveitamentoEstudos',
  secretary_btn_create_feedback_academic_performance:
    'secretaria_btn_cria_parecerAproveitamentoEstudos',
  secretary_btn_confirm_create_feedback_academic_performance:
    'secretaria_btn_confirma_criarParecerAproveitamentoEstudos',
  secretary_btn_confirm_creation_feedback_academic_performance:
    'secretaria_btn_confirma_criacaoParecerAproveitamentoEstudos'
}

const HISTORY = {
  secretaria_btn_cancela_historico: 'secretaria_btn_cancela_historico',
  secretaria_btn_salva_dadosHistorico: 'secretaria_btn_salva_dadosHistorico'
}

const EXPEDITION = {
  secretaria_btn_editar_registroExpedicao:
    'secretaria_btn_editar_registroExpedicao',
  secretaria_btn_salvar_registroExpedicao:
    'secretaria_btn_salvar_registroExpedicao',
  secretaria_btn_excluir_registroExpedicao:
    'secretaria_btn_excluir_registroExpedicao',
  secretaria_btn_emitir_diplomaExpedicao:
    'secretaria_btn_emitir_diplomaExpedicao',
  secretaria_btn_cancelar_registroExpedicao:
    'secretaria_btn_cancelar_registroExpedicao',
  secretaria_btn_cadastrar_registroExpedicao:
    'secretaria_btn_cadastrar_registroExpedicao',
  secretaria_btn_confirma_excluirRegistroExpedicao:
    'secretaria_btn_confirma_excluirRegistroExpedicao',
  secretaria_btn_confirma_cancelarRegistroExpedicao:
    'secretaria_btn_confirma_cancelarRegistroExpedicao'
}

export const SECRETARY = {
  ...REQUISITION,
  ...OCCURRENCES,
  ...SCHOOL_CLASS,
  ...DOCUMENTS,
  ...CONTRACTS,
  ...EXPEDITION,
  ...ACADEMIC_PERFORMANCES,
  ...HISTORY
}
