import styled from 'styled-components'
import { Paper, Toolbar as MuiToolbar } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'

const TableContainer = styled(Paper)`
  &.MuiPaper-root {
    margin: ${({ $switchAdvancedSearch }) =>
      $switchAdvancedSearch ? '5px 0 0 0' : '13px 0 0 0'};
  }
`

const Toolbar = styled(MuiToolbar)`
  &.MuiToolbar-root {
    padding: 0;
    align-items: center;
    display: ${({ $displayNone }) => ($displayNone ? 'none' : 'flex')};
  }
`

const BoxButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`

const IconWarningAmber = styled(WarningAmber)`
  &.MuiSvgIcon-root {
    color: ${({ theme }) => theme.palette.yellow.warning};
    font-size: 28px;
  }
`

export { TableContainer, Toolbar, Box, BoxButton, IconWarningAmber }
