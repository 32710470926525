import { Typography } from '@mui/material'

import { Button, Modal } from 'components/atoms'

import * as Styled from './style'

const ModalInformative = ({
  open,
  icon,
  title,
  message,
  maxWidth,
  labelSubmit,
  handleClose,
  handleSubmit
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    fullWidth
    {...(maxWidth && { maxWidth })}
  >
    <Styled.Container>
      {icon}

      <Styled.Header>
        <Styled.Title variant="h5">{title}</Styled.Title>
        <Typography variant="subtitle1">{message}</Typography>
      </Styled.Header>

      <Button onClick={handleSubmit}>{labelSubmit}</Button>
    </Styled.Container>
  </Modal>
)

export default ModalInformative
