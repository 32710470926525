import { Pagination } from '@mui/material'
import Select from '../Select'
import { DEFAULT_TOTAL_ITENS } from './constants'
import * as Styled from './style'

const PaginationAdvanced = ({
  count,
  page,
  handleSetPage,
  handleChange,
  value,
  optionsList,
  isSelectItensPerPage,
  smallSize
}) => (
  <Styled.BoxPagination $smallSize={smallSize}>
    {count !== DEFAULT_TOTAL_ITENS && (
      <Pagination count={count} page={page} onChange={handleSetPage} />
    )}

    {isSelectItensPerPage && (
      <Styled.BoxFormPagination>
        <Select
          isMargin="0 0 0 0"
          size="small"
          value={value}
          onChange={handleChange}
          optionsList={optionsList}
        />
        <Styled.Typography variant="body1">/página</Styled.Typography>
      </Styled.BoxFormPagination>
    )}
  </Styled.BoxPagination>
)

export default PaginationAdvanced
