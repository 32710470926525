import { useState } from 'react'
import {
  TableRow,
  TableCell,
  TextField,
  Typography,
  Tooltip,
  Unstable_Grid2 as Grid,
  IconButton
} from '@mui/material'
import { RemoveCircleOutline } from '@mui/icons-material'
import { brazilianCurrencyFormat, convertDateToUTC } from 'services/helpers'
import { Select } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import GroupingIcon from 'components/atoms/Icons/GroupingIcon'
import { useReplaceClassDetailsContext } from '../../../Context'
import { reasonReplacements, substitutionTypes } from '../constants'

export default function TableBodyRow({ discipline }) {
  const {
    setFieldValue,
    replaceForm,
    showOnlyInactives,
    handleOpenModalInactivate,
    inactivateReplacedSubjects
  } = useReplaceClassDetailsContext()
  const [mouseOver, setMouseOver] = useState(false)
  const fontSizeCell = '14px'
  const disciplineForm = replaceForm.values.replaces.find(
    ({ id }) => id === discipline.id
  )
  const emptyValye = '--'
  const replaceErrors = replaceForm?.errors?.replaces?.[discipline.id]
  const hasGrouping = Boolean(discipline?.hasGrouping)
  const isPermanent =
    (disciplineForm?.isPermanent ?? discipline.isPermanent) === true
  const shouldDisableRow = showOnlyInactives

  const getValueDateAt = (key) => {
    if (!disciplineForm?.[key] && !discipline?.[key]) {
      return null
    }

    return (
      disciplineForm?.[key] ??
      (discipline?.[key] && convertDateToUTC(discipline?.[key]))
    )
  }
  const sizeDisciplines = {
    1: { first: 12, second: 0 },
    2: { first: 9, second: 3 },
    3: { first: 7, second: 5 },
    4: { first: 7, second: 5 }
  }

  const getSizeDisciplines = () => {
    let size = 1
    if (hasGrouping) size += 1
    if (mouseOver) size += 1
    return size
  }

  return (
    <TableRow
      hover
      onMouseEnter={() => !showOnlyInactives && setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <TableCell sx={{ maxWidth: '140px', minWidth: '140px' }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid xs={sizeDisciplines[getSizeDisciplines()]?.first}>
            <Typography
              variant="body2"
              component="span"
              fontSize={fontSizeCell}
              color={shouldDisableRow ? (t) => t.palette.text.disabled : ''}
            >
              {discipline.disciplineName}
            </Typography>
          </Grid>

          <Grid xs={sizeDisciplines[getSizeDisciplines()]?.second}>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={0.5}
            >
              {hasGrouping && (
                <Grid xs={6}>
                  <GroupingIcon
                    tooltipTitle="Em Agrupamento"
                    size="16px"
                    fontSize="12px"
                  />
                </Grid>
              )}
              <Grid
                xs={hasGrouping ? 6 : 12}
                sx={{
                  visibility:
                    mouseOver && !shouldDisableRow ? 'visible' : 'hidden'
                }}
              >
                <Tooltip title="Inativar substituição">
                  <IconButton
                    sx={{ padding: 0 }}
                    disabled={shouldDisableRow}
                    onClick={() => {
                      handleOpenModalInactivate(() =>
                        inactivateReplacedSubjects({
                          replaceIds: [discipline.id]
                        })
                      )
                    }}
                  >
                    <RemoveCircleOutline color="error" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          textAlign="center"
          color={shouldDisableRow ? (t) => t.palette.text.disabled : ''}
        >
          {discipline.module ? `${discipline.module}º` : emptyValye}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          fontSize={fontSizeCell}
          color={shouldDisableRow ? (t) => t.palette.text.disabled : ''}
        >
          {discipline.oldProfessorName ?? emptyValye}
        </Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: '150px', minWidth: '150px' }}>
        <Select
          disabled={shouldDisableRow}
          size="small"
          optionsList={reasonReplacements}
          value={
            disciplineForm?.substitutionReason ??
            discipline.substitutionReason ??
            ''
          }
          onChange={(e) => {
            setFieldValue(discipline.id, 'substitutionReason', e.target.value)
          }}
          sx={{ fontSize: fontSizeCell }}
        />
      </TableCell>
      <TableCell sx={{ minWidth: '120px', maxWidth: '120px' }}>
        <TextField
          disabled={shouldDisableRow}
          sx={{ '& .MuiInputBase-input': { fontSize: fontSizeCell } }}
          error={
            replaceErrors?.hourlyLessonCost && replaceErrors.hourlyLessonCost
          }
          size="small"
          value={
            disciplineForm?.hourlyLessonCost ??
            brazilianCurrencyFormat(discipline.hourlyLessonCost?.toFixed(2))
          }
          onChange={(e) => {
            const valueFormatted = brazilianCurrencyFormat(e.target.value)
            setFieldValue(discipline.id, 'hourlyLessonCost', valueFormatted)
          }}
        />
      </TableCell>
      <TableCell sx={{ minWidth: '165px' }}>
        <DatePicker
          error={replaceErrors?.startDate && replaceErrors?.startDate}
          helperText={replaceErrors?.startDate}
          disabled={isPermanent || shouldDisableRow}
          disabledInput={isPermanent || shouldDisableRow}
          defaultValue={null}
          size="small"
          label="Início"
          value={getValueDateAt('startDate')}
          onChange={(value) => {
            setFieldValue(discipline.id, 'startDate', value)
          }}
          fontSize={fontSizeCell}
        />
      </TableCell>
      <TableCell sx={{ minWidth: '165px' }}>
        <DatePicker
          error={replaceErrors?.endDate && replaceErrors?.endDate}
          helperText={replaceErrors?.endDate}
          disabled={isPermanent || shouldDisableRow}
          disabledInput={isPermanent || shouldDisableRow}
          defaultValue={null}
          size="small"
          label="Fim"
          value={getValueDateAt('endDate')}
          onChange={(value) => {
            setFieldValue(discipline.id, 'endDate', value)
          }}
          fontSize={fontSizeCell}
        />
      </TableCell>
      <TableCell sx={{ maxWidth: '150px', minWidth: '150px' }}>
        <Select
          disabled={shouldDisableRow}
          size="small"
          optionsList={Object.values(substitutionTypes)}
          value={
            (isPermanent
              ? substitutionTypes.permanent.value
              : substitutionTypes.temporary.value) ?? ''
          }
          onChange={(e) => {
            const isPermanentChange =
              e.target.value === substitutionTypes.permanent.value

            if (isPermanentChange) {
              setFieldValue(discipline.id, 'startDate', null)
              setFieldValue(discipline.id, 'endDate', null)
            }

            setFieldValue(discipline.id, 'isPermanent', isPermanentChange)
          }}
          sx={{ fontSize: fontSizeCell }}
        />
      </TableCell>
    </TableRow>
  )
}
