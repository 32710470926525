import { createContext, useContext, useEffect, useState } from 'react'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { useFormik } from 'formik'
import {
  brazilianCurrencyFormat,
  toastNotificationWarning
} from 'services/helpers'
import { useCheckedTable } from 'services/hooks'
import {
  getClassInfo,
  getDisciplines
} from '../../ReplaceClassDetailsEdit/services'
import {
  deleteDisciplinesLinked,
  loadDisciplinesLinked,
  updateDisciplinesLinked
} from '../services'
import { validateLinkedClassDetailsEditForm } from './schema'

const initialState = {
  classDetails: {
    id: null,
    name: '',
    code: '',
    shift: '',
    unit: '',
    level: '',
    group: '',
    modality_id: null,
    modality: '',
    lesson_duration: null,
    grade_year: '',
    begin_at: '',
    end_at: '',
    company_id: null,
    type_frequency: '',
    minimum_frequency: null,
    by_subject: null,
    stage: { id: null, name: '', type: null, sum_module: null }
  },
  loadClassDetails: (classId) => {},
  disciplines: [],
  loadDisciplines: (classId, idProfessor, showOnlyInactives) => {},
  professorId: null,
  linkedClassDetailsEditForm: {},
  setFieldValue: (disciplineId, field, value) => {},
  handleSelectAllClick: (event) => {},
  handleClick: (id) => {},
  isSelected: (id) => {},
  selectedIds: [],
  disciplinesThatCanBeCheked: [],
  inactivateDisciplinesLinked: ({ disciplinesId, allCheked }) => {},
  openModalInactivate: false,
  handleOpenModalInactivate: (onConfirm) => {},
  onCloseModalInactivate: () => {},
  currentOnConfirmInactivate: () => {},
  setShowOnlyInactives: () => {},
  showOnlyInactives: false
}

export const LinkedClassDetailsEditContext = createContext(initialState)

export function LinkedClassDetailsEditProvider({ children }) {
  const { setLoading } = useLoading()
  const [classDetails, setClassDetails] = useState(initialState.classDetails)
  const [disciplines, setDisciplines] = useState(initialState.disciplines)
  const [professorId, setProfessorId] = useState(initialState.professorId)
  const [openModalInactivate, setOpenModalInactivate] = useState(
    initialState.openModalInactivate
  )
  const [showOnlyInactives, setShowOnlyInactives] = useState(
    initialState.showOnlyInactives
  )
  const [currentOnConfirmInactivate, setCurrentOnConfirmInactivate] = useState(
    initialState.currentOnConfirmInactivate
  )
  const disciplinesThatCanBeCheked = disciplines.filter(
    (discipline) => !discipline.hasGrouping && !showOnlyInactives
  )

  const {
    handleSelectAllClick,
    handleClick,
    isSelected,
    selected: selectedIds,
    setSelected: setSelectedIds
  } = useCheckedTable(disciplinesThatCanBeCheked, 'id')

  const loadClassDetails = async (classId) => {
    setLoading(true)
    const classInfo = await getClassInfo({
      id: classId,
      onError: () => setLoading(false)
    })

    if (classInfo) {
      setClassDetails(classInfo)
    }
    setLoading(false)
  }

  const handleOpenModalInactivate = (onConfirm) => {
    setCurrentOnConfirmInactivate(() => onConfirm)
    setOpenModalInactivate(true)
  }
  const onCloseModalInactivate = () => setOpenModalInactivate(false)

  const linkedClassDetailsEditForm = useFormik({
    initialValues: { links: [] },
    validate: validateLinkedClassDetailsEditForm,
    onSubmit: async (values) => {
      if (!values.links.length) {
        return toastNotificationWarning('Nenhuma vínculo foi modificado')
      }
      setLoading(true)

      await updateDisciplinesLinked({
        classId: classDetails.id,
        values: values.links,
        onError: () => setLoading(false)
      })

      return setLoading(false)
    }
  })

  const setFieldValue = (disciplineId, field, value) => {
    const { links } = linkedClassDetailsEditForm.values

    const updatedlinks = [...links]

    const discipline = updatedlinks.find(
      (disciplineEl) => disciplineEl.id === disciplineId
    )

    if (discipline) {
      discipline[field] = value
    } else {
      const disciplineInState = disciplines.find(
        ({ id }) => id === disciplineId
      )

      if (!disciplineInState) return

      const newLinkEdit = {
        id: disciplineId,
        hourlyLessonCost: brazilianCurrencyFormat(
          disciplineInState?.hourlyLessonCost?.toFixed(2)
        )
      }

      newLinkEdit[field] = value

      updatedlinks.push(newLinkEdit)
    }

    linkedClassDetailsEditForm.setFieldValue('links', updatedlinks)
  }

  const loadDisciplines = async ({ classId, idProfessor, onlyInactives }) => {
    setLoading(true)

    const disciplinesData = await loadDisciplinesLinked({
      schoolClassId: classId,
      inactive: onlyInactives,
      professorId: idProfessor ?? professorId,
      onError: () => setLoading(false)
    })

    if (disciplinesData) {
      const disciplinesFiltered = disciplinesData.filter((discipline) => {
        const firstLetter = +discipline.disciplineName?.split('')[0]
        return !(!Number.isNaN(firstLetter) && typeof firstLetter === 'number')
      })
      setDisciplines(disciplinesFiltered)
    }
    linkedClassDetailsEditForm.resetForm()
    setLoading(false)
  }

  const inactivateDisciplinesLinked = async ({ disciplinesId, allCheked }) => {
    setLoading(true)

    await deleteDisciplinesLinked({
      professorId,
      classId: classDetails.id,
      disciplinesId: [...(allCheked ? selectedIds : disciplinesId)],
      onError: () => setLoading(false)
    })
    setSelectedIds([])
    await loadDisciplines({
      classId: classDetails.id,
      idProfessor: professorId,
      onlyInactives: showOnlyInactives
    })
    onCloseModalInactivate()
    setLoading(false)
  }

  return (
    <LinkedClassDetailsEditContext.Provider
      value={{
        loadClassDetails,
        classDetails,
        disciplines,
        loadDisciplines,
        setProfessorId,
        professorId,
        linkedClassDetailsEditForm,
        setFieldValue,
        handleSelectAllClick,
        handleClick,
        isSelected,
        selectedIds,
        disciplinesThatCanBeCheked,
        inactivateDisciplinesLinked,
        openModalInactivate,
        handleOpenModalInactivate,
        onCloseModalInactivate,
        currentOnConfirmInactivate,
        showOnlyInactives,
        setShowOnlyInactives
      }}
    >
      {children}
    </LinkedClassDetailsEditContext.Provider>
  )
}

export const useLinkedClassDetailsEditContext = () =>
  useContext(LinkedClassDetailsEditContext)
