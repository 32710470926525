import { useState } from 'react'
import { Formik, Form } from 'formik'
import { Box, Chip, Grid, Typography } from '@mui/material'
import { Modal, DatePicker, InputAutocomplete } from 'components/atoms'
import { ButtonFooterV2, Select } from 'components/molecules'
import { useMakeServiceOccurrence } from 'services/hooks'
import * as Styled from './style'
import { validationSchemaOcurrence } from './schema'
import { downloadOccurence } from '../../../../services'

const ModalOcurrences = ({
  open,
  handleClose,
  icon,
  title,
  labelDateStart,
  labelDateEnd,
  message,
  subMessage,
  colorButtonConfirm,
  colorButtonNotConfirm,
  initialState,
  selected: schoolClassId,
  classNameBtnConfirm,
  setSelected,
  classNameBtnCancel,
  setSnack
}) => {
  const [categoryId, setCatedoryId] = useState('')

  const handleCategory = ({ e, setFieldValue }) => {
    setFieldValue('category', e.target.value)
    setCatedoryId(e.target.value)
  }

  const { categories, typeOfOccurrence } = useMakeServiceOccurrence({
    isCategory: true,
    setSnack,
    categoryId
  })

  return (
    <Modal open={open} onClose={handleClose} fullWidth>
      <Formik
        initialValues={initialState}
        enableReinitialize
        onSubmit={(values) =>
          downloadOccurence({
            setSnack,
            values,
            selected: schoolClassId,
            handleClose,
            setSelected
          })
        }
        validationSchema={validationSchemaOcurrence}
      >
        {({ setFieldValue, values, handleSubmit, dirty, errors, touched }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Styled.IconText>
                  {icon}
                  <Styled.Title variant="h5">{title}</Styled.Title>
                </Styled.IconText>
              </Grid>

              {message && (
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="subtitle1">{message}</Typography>
                </Grid>
              )}

              {subMessage && (
                <Grid item xs={12} sm={12} md={12}>
                  <Styled.Typography variant="subtitle2">
                    {subMessage}
                  </Styled.Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={6}>
                <DatePicker
                  fullWidth
                  id="startDate"
                  value={values.startDate}
                  label={labelDateStart}
                  disableFuture
                  onChange={(newValue) => setFieldValue('startDate', newValue)}
                  helperText={errors && touched.startDate && errors.startDate}
                  error={Boolean(touched.startDate && errors.startDate)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <DatePicker
                  size="large"
                  fullWidth
                  id="endDate"
                  value={values.endDate}
                  label={labelDateEnd}
                  disableFuture
                  onChange={(newValue) => setFieldValue('endDate', newValue)}
                  helperText={errors && touched.endDate && errors.endDate}
                  error={Boolean(touched.endDate && errors.endDate)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Select
                  id="category"
                  value={values?.category || ''}
                  label="Categoria"
                  optionsList={categories}
                  onChange={(e) => handleCategory({ e, setFieldValue })}
                  helperText={errors && touched.category && errors.category}
                  error={Boolean(touched.category && errors.category)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <InputAutocomplete
                  hasMarginBottom
                  label="Tipo"
                  multiple
                  limitTags={1}
                  placeholder="Tipo"
                  labelId="select-multiple-type"
                  value={values?.type || []}
                  handleChange={(_, value) => setFieldValue('type', value)}
                  filterSelectedOptions
                  disabled={!values?.category}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, label) => option === label}
                  listOptions={typeOfOccurrence}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option?.value}>
                      {option.label}
                    </Box>
                  )}
                  renderTags={(selected, getTagProps) =>
                    selected.map((option, index) => (
                      <Chip
                        key={option?.value}
                        label={option.label}
                        variant="outlined"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </Grid>

              <Styled.GridButtons item xs={12} sm={12} md={12}>
                <ButtonFooterV2
                  disabledConfirm={!dirty}
                  classNameCancel={classNameBtnCancel}
                  classNameConfirm={classNameBtnConfirm}
                  colorClose={colorButtonNotConfirm}
                  colorConfirm={colorButtonConfirm}
                  labelClose="Cancelar"
                  labelConfirm="Salvar"
                  onClickClose={() => handleClose()}
                />
              </Styled.GridButtons>
            </Styled.Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalOcurrences
