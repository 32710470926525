import { Table as MuiTable, TableBody } from '@mui/material'
import { useSortTable } from 'services/hooks'
import { TableHeaderDefault } from 'components/organisms'
import { PaginationAdvanced } from 'components/molecules'
import { paginationFilter } from 'services/helpers'
import { defaultTotalPage } from 'services/hooks/constants'
import { emailHeadCells, smsHeadCells, whatsAppHeadCells } from './constants'
import * as Styled from './style'
import { TableFooterEmail } from './TableFooterEmail'
import { TableFooterSms } from './TableFooterSms'
import { defaultItemsPerPage, EMAIL, SMS, WHATSAPP } from '../../constants'
import { TableFooterWhatsApp } from './TableFooterWhatsApp'

const TableFooter = ({ type, ...props }) =>
  ({
    [EMAIL]: <TableFooterEmail {...props} />,
    [SMS]: <TableFooterSms {...props} />,
    [WHATSAPP]: <TableFooterWhatsApp {...props} />
  }[type])

const TableLog = ({
  rows,
  count,
  limit,
  totalPage,
  page,
  handleSetPage,
  setFilter,
  setPage,
  searchType,
  loadingOpen
}) => {
  const headCells = (type) =>
    ({
      [EMAIL]: emailHeadCells,
      [SMS]: smsHeadCells,
      [WHATSAPP]: whatsAppHeadCells
    }[type])

  const sortBy = searchType === EMAIL ? 'created_at' : 'sent_at'
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(
    rows,
    sortBy,
    true
  )

  return (
    <>
      <Styled.Paper>
        <Styled.TableContainer>
          <MuiTable>
            <TableHeaderDefault
              headCells={headCells(searchType)}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!loadingOpen &&
                sortTable?.map((row) => (
                  <TableFooter key={row.id} type={searchType} row={row} />
                ))}
            </TableBody>
          </MuiTable>
        </Styled.TableContainer>

        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={limit}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={page}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setFilter(e.target.value)
              setPage(defaultTotalPage)
            }}
          />
        )}
      </Styled.Paper>
    </>
  )
}

export { TableLog }
