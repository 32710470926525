import { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ListAlt, Search } from '@mui/icons-material'
import { ROUTE } from 'services/constants'
import { usePaginationBasic, useSnack } from 'services/hooks'
import { isEmpty } from 'services/helpers'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { TextNotFound } from 'components/atoms'
import { getInteractionsProfessor, listInfoProfessors } from './services'
import { TableLog } from './components/Table'
import { FormSearchLogs } from './components/Form'
import {
  MESSAGE,
  breadCrumbsItems,
  countDefault,
  defaultItemsPerPage,
  intialPesonalData,
  initialState
} from './constants'
import * as Styled from './style'
import { validationSchema } from './components/Form/schema'

const Interactions = () => {
  const snackProps = useSnack()
  const navigate = useNavigate()
  const { setSnack } = snackProps
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id } = params

  const [loadingOpen, setLoadingOpen] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [isButtonSet, setIsButtonSet] = useState(false)
  const [openModalConfirmCancel, setOpenModalConfirmCancel] = useState(false)
  const [formPesonalData, setFormPesonalData] = useState(intialPesonalData)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [listLogs, setListLogs] = useState([])

  const { page, totalPage, setPage, items, handleSetPage } = usePaginationBasic(
    listLogs,
    filter,
    count
  )

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: (values) =>
      getInteractionsProfessor({
        id,
        values,
        setSnack,
        setLoadingOpen,
        setListLogs,
        setNotFound,
        setCount,
        pages: page,
        filter
      }),
    validationSchema
  })

  const onHandleConfirm = () => {
    setOpenModalConfirmCancel(false)
    navigate(ROUTE.ADMIN_HR_PROFESSOR, {
      state: { savedSearch: true }
    })
  }

  const handleClean = ({ resetForm }) => {
    setListLogs([])
    setNotFound(false)
    resetForm(initialState)
    formik.setFieldValue('')
  }

  useEffect(() => {
    if (isButtonSet)
      getInteractionsProfessor({
        id,
        values: formik.values,
        setSnack,
        setLoadingOpen,
        setListLogs,
        setNotFound,
        setCount,
        pages: page,
        filter
      })
  }, [page, filter])

  useEffect(() => {
    listInfoProfessors({
      id,
      setSnack,
      setFormPesonalData
    })
  }, [])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      breadcrumbsItems={breadCrumbsItems}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() => setOpenModalConfirmCancel(true)}
      open={openModalConfirmCancel}
      handleCloseModal={() => setOpenModalConfirmCancel(false)}
      handleConfirmModal={() => onHandleConfirm()}
      ac
      content={
        <>
          <Styled.Box>
            <Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12} sm={12} md={11}>
                <Typography variant="h5" component="h5">
                  {formPesonalData.name}
                </Typography>
                <Typography variant="overline" component="overline">
                  {id}
                </Typography>
                <Typography variant="caption" component="caption">
                  {formPesonalData.email}
                </Typography>
                <Typography variant="caption" component="caption">
                  {formPesonalData.phone_number}
                </Typography>
              </Grid>
            </Grid>
          </Styled.Box>

          <FormSearchLogs
            handleClean={handleClean}
            setIsButtonSet={setIsButtonSet}
            formik={formik}
          />

          {items && !isEmpty(items) ? (
            <TableLog
              rows={items}
              pages={page}
              totalPage={totalPage}
              setPage={setPage}
              count={count}
              filter={filter}
              handleSetPage={handleSetPage}
              setFilter={setFilter}
              setPages={setPage}
            />
          ) : (
            <TextNotFound
              icon={notFound ? <Search /> : <ListAlt />}
              text={notFound ? MESSAGE.NOT_FOUND : MESSAGE.NOT_SEARCH}
            />
          )}
        </>
      }
    />
  )
}

export default Interactions
