import { camelizeKeys, decamelizeKeys } from 'humps'

export const deserialize = (data) => ({ ...decamelizeKeys(data) })

export const serialize = (data) => ({ ...camelizeKeys(data) })

export const formatBigNumber = (value, limit = 1000) => {
  if (!value) return '0'

  const abbrev = ['', 'K']
  const unrangifiedOrder = Math.floor(Math.log10(Math.abs(value)) / 3)
  const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1))
  const suffix = abbrev[order]

  const result =
    value < limit
      ? value
      : // eslint-disable-next-line no-restricted-properties
        (value / Math.pow(10, order * 3)).toFixed(0) + suffix
  return result
}

export const normalizeAndRemoveDiacriticalCharacters = (value) =>
  value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
