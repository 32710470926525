import PropTypes from 'prop-types'
import * as Styled from './style'

const TextGroup = ({ title, subtitle, icon }) => (
  <Styled.CardGroup>
    {icon}
    <Styled.CardGroupTitle>
      {title && (
        <Styled.Title $hasfontWeight variant="body1">
          {title}
        </Styled.Title>
      )}

      {subtitle && <Styled.Title variant="body2">{subtitle}</Styled.Title>}
    </Styled.CardGroupTitle>
  </Styled.CardGroup>
)

export default TextGroup

TextGroup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.element
}

TextGroup.defaultProps = {
  title: '',
  subtitle: '',
  icon: null
}
