const initialState = {
  unity: null,
  unityName: '',
  course: null,
  level: '',
  shift: '',
  module: 0,
  duration: null,
  group: 'Grupo Automático',
  installments: '',
  valueInstallments: '',
  registration: '',
  cashDiscount: '',
  account: '',
  contract: '',
  every: '',
  addition: '',
  workload: '',
  checkedEAD: false,
  checkedReal: false,
  checkedPercentage: false,
  websiteRegistration: false
}

export { initialState }
