import styled from 'styled-components'
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  IconButton as MuiIconButton
} from '@mui/material'

const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-size: 24px;
    margin-top: 20px;
  }
`

const Grid = styled(MuiGrid)`
  display: flex;
  justify-content: center;
  text-align: center;
`

const BoxLink = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: start;
  margin: 10px 0;

  & > a {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  & > a > button {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

const IconButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    margin-bottom: 4px;
    padding: 0;
    margin-left: 8px;
  }
`

export { Typography, Grid, BoxLink, IconButton }
