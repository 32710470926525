import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoading } from 'components/pages/Shared/Loading/Context/LoadingContext'
import { ModalConfirm } from 'components/molecules'
import { ROUTE, SUCCESS } from 'services/constants'
import { usePaginationBasic } from 'services/hooks'
import TAG_MANAGER from 'tagManager'
import {
  getHistoryStudent,
  loadclass,
  savedHistoryStudent
} from '../../services'
import FormSearch from './components/FormSearch'
import Table from './components/Table'
import {
  countDefault,
  defaultItemsPerPage,
  description,
  MESSAGE,
  titleHeader,
  VALUE_STATUS
} from './constants'
import * as Styled from './style'

const OptionsCourse = ({
  studentListCourse,
  studentInfo,
  setSnack,
  params
}) => {
  const [responseCreateHitory, setResponseCreateHitory] = useState()
  const { setLoading } = useLoading()
  const [status, setStatus] = useState(VALUE_STATUS.NEXT_PAGE_ONE)
  const [classInfo, setClassInfo] = useState()
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(countDefault)
  const [responseSavedHistoryStudent, setResponseSavedHistoryStudent] =
    useState(false)
  const [open, setOpen] = useState()
  const { student, name } = params
  const ADMIN_SECRETARY_STUDENTS_HISTORY = `${ROUTE.ADMIN_SECRETARY_STUDENTS_HISTORY}?student=${student}&name=${name}`
  const { page, totalPage, items, setPage, handleSetPage } = usePaginationBasic(
    classInfo?.data,
    filter,
    count
  )
  const history = useNavigate()

  useEffect(async () => {
    if (status === VALUE_STATUS.NEXT_PAGE_TWO) {
      setLoading(true)
      await loadclass({
        id: responseCreateHitory?.id,
        setClassInfo,
        setSnack
      })
      setLoading(false)
    }
  }, [responseCreateHitory, status])

  const handleOnSubit = (selectedItem) => {
    const historicId = responseCreateHitory?.id
    savedHistoryStudent({
      setSnack,
      setResponseSavedHistoryStudent,
      historicId,
      selectedItem
    })
  }

  useEffect(() => {
    if (responseSavedHistoryStudent) {
      setSnack('')
      setSnack(MESSAGE.SUCCESS_COPY, SUCCESS)
      history(ADMIN_SECRETARY_STUDENTS_HISTORY)
    }
  }, [responseSavedHistoryStudent])

  const onResetAction = () => {
    setOpen(false)
  }

  const onConfirm = () => {
    history(ADMIN_SECRETARY_STUDENTS_HISTORY)
  }

  return (
    <>
      {status === VALUE_STATUS.NEXT_PAGE_ONE && (
        <>
          <Styled.Text>{titleHeader}</Styled.Text>
          <FormSearch
            studentListCourse={studentListCourse}
            onSubmit={getHistoryStudent}
            studentInfo={studentInfo}
            setOpen={setOpen}
            setResponseCreateHitory={setResponseCreateHitory}
            setLoading={setLoading}
            setSnack={setSnack}
            setStatus={setStatus}
          />
        </>
      )}

      {status === VALUE_STATUS.NEXT_PAGE_TWO && (
        <>
          <Styled.Title>{responseCreateHitory?.course}</Styled.Title>
          <Styled.Text>{description}</Styled.Text>
          <Styled.Text>Selecione a(s) turma(s):</Styled.Text>
          <Table
            rows={items}
            filter={filter}
            setFilter={setFilter}
            setPage={setPage}
            page={page}
            totalPage={totalPage}
            count={count}
            setOpen={setOpen}
            handleSetPage={handleSetPage}
            handleOnSubit={handleOnSubit}
          />
        </>
      )}
      <ModalConfirm
        classNameBtnConfirm={TAG_MANAGER.secretaria_btn_cancela_historico}
        open={open}
        icon={<Styled.ErrorOutlineIcon color="secondary" />}
        handleClose={onResetAction}
        title="Cancelar"
        message="Deseja cancelar a operação? Você perderá as alterações que ainda não foram salvas."
        textButtonNotConfirm="Não"
        textButtonConfirm="Sim"
        onClickNotConfirm={onResetAction}
        onClickConfirm={() => onConfirm()}
        colorButtonConfirm="primary"
      />
    </>
  )
}

export { OptionsCourse }
