import PropTypes from 'prop-types'
import { Form as FormikForm, Formik } from 'formik'
import { Grid } from '@mui/material'
import { ButtonFooterV2, TextField, Select } from 'components/molecules'
import { InputCheckbox } from 'components/atoms'
import { fileFormatItems, schema } from './config'
import { OPTIONS_TYPE } from '../../constants'

export default function Form({
  handleSetModalContent,
  onSubmit,
  initialValues
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ dirty, handleChange, isValid, setFieldValue, values }) => (
        <FormikForm>
          <Grid container pt={3} spacing={{ xs: 3 }}>
            <Grid item xs={12} sm={7} md={6}>
              <TextField
                fullWidth
                id="name"
                label="Título do Documento*"
                onChange={handleChange}
                value={values?.name || ''}
              />
            </Grid>

            <Grid item xs={12} sm={7} md={3}>
              <Select
                id="type"
                value={values?.type || ''}
                label="Tipo"
                optionsList={OPTIONS_TYPE}
                onChange={(e) => setFieldValue('type', e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={5} md={3}>
              <Select
                id="extension"
                value={values?.extension || ''}
                disabled
                label="Formato do envio*"
                optionsList={fileFormatItems}
                onChange={(e) => setFieldValue('extension', e.target.value)}
              />
            </Grid>

            <Grid px={3}>
              <InputCheckbox
                checked={values?.is_required}
                handleChange={({ target }) =>
                  setFieldValue('is_required', target.checked)
                }
                label="Envio obrigatório"
                name="is_required"
              />

              <InputCheckbox
                checked={values?.is_multiple}
                handleChange={({ target }) =>
                  setFieldValue('is_multiple', target.checked)
                }
                label="Pode ser enviado mais de uma vez"
                name="is_multiple"
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonFooterV2
                disabledConfirm={!(dirty && isValid)}
                labelConfirm="Confirmar"
                labelClose="Cancelar"
                onClickClose={() => handleSetModalContent()}
              />
            </Grid>
          </Grid>
        </FormikForm>
      )}
    </Formik>
  )
}

Form.propTypes = {
  handleSetModalContent: PropTypes.func
}

Form.defaultProps = {
  handleSetModalContent: () => {}
}
