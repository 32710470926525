import { Form, Formik } from 'formik'
import { Grid, Typography } from '@mui/material'
import { ButtonFooterV2 } from 'components/molecules'
import { DatePicker } from 'components/atoms'
import { initialValues } from './constants'
import { disabledButton } from './helpers'
import * as Styled from './style'

const ModalCreatePeriod = ({
  handleClose,
  open,
  handleCreateSubmit,
  setLoadingOpen,
  schoolClassId,
  setSnack,
  setOpenModalCreateReenroll,
  className,
  course,
  modules
}) => (
  <Styled.Modal open={open} onClose={handleClose}>
    <Formik
      initialValues={initialValues}
      onSubmit={(values) =>
        handleCreateSubmit({
          values,
          setLoadingOpen,
          schoolClassId,
          setSnack,
          setOpenModalCreateReenroll
        })
      }
      enableReinitialize
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Styled.Grid>
            <Grid container spacing={{ xs: 0, sm: 2 }}>
              <Grid item xs={12}>
                <Styled.Title variant="h5" component="h2">
                  Nova rematrícula
                </Styled.Title>
              </Grid>

              <Grid item xs={12} sm={12} md={8}>
                <Typography variant="body2">
                  Escolha o período de rematrícula que você deseja gerar:
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={8}>
                <Typography variant="body2">
                  <b> Turma </b> {className} - {course}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="body2">
                  <b> Módulo </b>{' '}
                  {Number(modules) === 0 ? 'Único' : `${modules}º Módulo`}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <DatePicker
                  fullWidth
                  id="beginDate"
                  label="De"
                  value={values?.beginDate || null}
                  onChange={(newValue) => setFieldValue('beginDate', newValue)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <DatePicker
                  fullWidth
                  id="endDate"
                  label="Até"
                  value={values?.endDate || null}
                  onChange={(newValue) => setFieldValue('endDate', newValue)}
                />
              </Grid>
            </Grid>
            <Styled.Box>
              <ButtonFooterV2
                labelClose="Cancelar"
                labelConfirm="Confirmar"
                size="large"
                onClickClose={handleClose}
                disabledConfirm={disabledButton(values)}
              />
            </Styled.Box>
          </Styled.Grid>
        </Form>
      )}
    </Formik>
  </Styled.Modal>
)

export default ModalCreatePeriod
