import buildObservations from 'components/templates/PDF/shared/Observation'
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const { borderLayoutDefault } = utilsPDF

export default function buildTableFooter() {
  return {
    table: {
      body: [
        ...buildObservations(
          {
            stack: [
              {
                text: '• O detalhamento de notas, faltas e ocorrências estão disponíveis em seu Portal Acadêmico. Se tiver dúvidas sobre o usuário e acesso procure a Coordenação / Secretaria da Unidade.'
              },
              {
                text: '• Disciplinas com asterisco (*) tem a nota vinculada a outra turma.'
              },
              {
                text: '• Faltas precedidas de um asterisco (*) estão acima do percentual permitido.'
              },
              {
                text: '• Notas precedidas de um asteriscos (*) estão abaixo da média e de dois asteriscos (**) são provenientes de aproveitamento de estudos.'
              }
            ]
          },
          'Componente Curricular'
        )
      ],
      widths: [240, 60, '*']
    },
    layout: {
      ...borderLayoutDefault,
      paddingBottom: () => 10,
      paddingLeft: () => 10,
      paddingRight: () => 10,
      paddingTop: () => 10
    }
  }
}
