import TAG_MANAGER from 'tagManager'
import { ModalConfirm } from 'components/molecules'

const ModalConfirmDelete = ({
  academicPerformance,
  setAcademicPerformance,
  onConfirm
}) => {
  const closeDialog = () => setAcademicPerformance(null)

  const onClickConfirm = async () => {
    if (onConfirm) await onConfirm(academicPerformance)
    closeDialog()
  }

  return (
    <ModalConfirm
      open={!!academicPerformance}
      size="medium"
      handleClose={closeDialog}
      textButtonNotConfirm="Não"
      textButtonConfirm="Sim"
      onClickNotConfirm={closeDialog}
      onClickConfirm={onClickConfirm}
      iconWarningAmber
      title="Excluir solicitação"
      message={
        <>
          Você tem certeza que deseja excluir <b>toda</b> a solicitação de
          aproveitamento de estudos de <b>{academicPerformance?.name}</b>?
        </>
      }
      classNameBtnConfirm={
        TAG_MANAGER.secretary_btn_confirm_delete_academic_performance
      }
    />
  )
}

export default ModalConfirmDelete
