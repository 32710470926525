import FEEDBACK_SNACK from 'feedBackSnack'
import {
  confirmSignature as confirmSignatureService,
  generateContract,
  skipSignature,
  updateContact
} from 'services/api/student'
import { messageErroOrSuccess, updateErrorMessage } from 'services/helpers'
import { SUCCESS } from 'services/constants'
import { MESSAGE } from './constants'

export const contractGenerate = async ({
  setSnack,
  username,
  setContractInfo
}) => {
  const payload = {
    register: username
  }

  const { data, error, status } = await generateContract(payload)

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  setContractInfo(data)
}

export const confirmSignature = async ({
  setSnack,
  callbackSuccess,
  callbackError,
  contract,
  code
}) => {
  const payload = {
    accept_code: Number(code)
  }
  const { error, status } = await confirmSignatureService({ contract, payload })

  messageErroOrSuccess({
    error,
    setSnack,
    status
  })

  return !error ? callbackSuccess() : callbackError()
}

export const resendCode = async ({
  setSnack,
  payload,
  callbackSuccess,
  contract
}) => {
  const payloadWithoutMask = {
    ...payload,
    telephone: payload.telephone.replace(/\D/g, '')
  }

  const { error, status } = await updateContact({
    contract,
    payloadWithoutMask
  })

  messageErroOrSuccess({
    error,
    setSnack,
    status,
    successMessage: 'Novo código enviado com sucesso!'
  })

  if (!error) {
    callbackSuccess()
  }
}

export const signatureSkip = async ({
  setSnack,
  payloadSkipSignature,
  contract,
  redirectHome
}) => {
  const { error, status } = await skipSignature({
    contract,
    payloadSkipSignature
  })

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: FEEDBACK_SNACK.noAccessPermissionToViewContract
    })
  }

  setSnack(MESSAGE.updateSuccessfully, SUCCESS)

  return redirectHome()
}
