import { httpGet, httpPut, httpDelete } from 'services/api/http'
import { ENDPOINT } from 'services/constants'

export const getNotifications = async (params) => {
  const getNotification = await httpGet(`${ENDPOINT.NOTIFICATION}`, {
    params
  })
  return getNotification
}

export const removeNotifications = async ({ id }) => {
  const deleteNotification = await httpDelete(`${ENDPOINT.NOTIFICATION}/${id}`)

  return deleteNotification
}

export const updateNotifications = async ({ id }) => {
  const putNotification = await httpPut(`${ENDPOINT.NOTIFICATION}/${id}/read`, {
    id
  })
  return putNotification
}
