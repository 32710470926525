const initialState = { name: '', unit: '', active: false }
const countDefault = 0
const defaultItemsPerPage = 10
const defaultPage = 1

const MESSAGE = {
  NO_SEARCH_GROUPS:
    '<b>Encontre os grupos já criados aqui</b><br/>Você precisa selecionar ao menos a unidade e a<br/> situação para listar os grupos disponíveis.',
  NOT_FOUND_SEARCH:
    'Não encontramos um resultado para essa busca.<br/>Verifique os dados informados e tente novamente.',
  savedGroup: 'Grupo salvo com sucesso',
  updateGroup: 'Grupo atualizado com sucesso'
}
export { defaultItemsPerPage, initialState, countDefault, MESSAGE, defaultPage }
