const SYSTEM_TAG = {
  sistema_btn_busca_declaracao: 'sistema_btn_busca_declaracao',
  sistema_btn_acessa_declaracao: 'sistema_btn_acessa_declaracao',
  sistema_btn_exclui_declaracao: 'sistema_btn_exclui_declaracao',
  sistema_btn_acessa_camposAluno: 'sistema_btn_acessa_camposAluno',
  sistema_btn_acessa_camposTurma: 'sistema_btn_acessa_camposTurma',
  sistema_btn_acessa_camposCurso: 'sistema_btn_acessa_camposCurso',
  sistema_btn_cria_novaDeclaracao: 'sistema_btn_cria_novaDeclaracao',
  sistema_btn_acessa_camposSistema: 'sistema_btn_acessa_camposSistema',
  sistema_btn_visualiza_declaracao: 'sistema_btn_visualiza_declaracao',
  sistema_btn_acessa_camposUnidade: 'sistema_btn_acessa_camposUnidade',
  sistema_btn_salva_novaDeclaracao: 'sistema_btn_salva_novaDeclaracao',
  sistema_btn_cancela_novaDeclaracao: 'sistema_btn_cancela_novaDeclaracao',
  sistema_btn_salva_editarDeclaracao: 'sistema_btn_salva_editarDeclaracao',
  sistema_btn_acessa_camposFinanceiro: 'sistema_btn_acessa_camposFinanceiro',
  sistema_btn_confirma_excluirDeclaracao:
    'sistema_btn_confirma_excluirDeclaracao'
}

export const SYSTEM = {
  ...SYSTEM_TAG
}
