/* eslint-disable camelcase */
import { getDocsRequiredSaved } from 'services/api/admin'
import { getDocuments } from 'services/api/shared'
import { updateErrorMessage } from 'services/helpers'

const docsReqSaved = async ({
  listDocs,
  setListDocumentsRequired,
  setListSavedDocRequired,
  setSnack,
  setLoadingVisibility,
  idCourse
}) => {
  setSnack('', '')

  const { data, error, status } = await getDocsRequiredSaved(idCourse)
  setLoadingVisibility(false)

  const newData = data?.reduce((acc, cur) => {
    const { tipo_documento, id_tipo_documento } = cur
    const newItem = { value: id_tipo_documento, label: tipo_documento }

    return [...acc, newItem]
  }, [])

  const filteredListDocs = listDocs?.reduce(
    (accumulator, { label: labelListDocs, value: id }) => {
      const labelExists = newData?.some(
        ({ label }) => String(label) === String(labelListDocs)
      )
      if (!labelExists) {
        accumulator.push({ value: id, label: labelListDocs })
      }
      return accumulator
    },
    []
  )

  setListDocumentsRequired(filteredListDocs)

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: 'para visualizar a lista de documentos obrigatórios'
    })
  }

  return setListSavedDocRequired(newData)
}

const getListDocsRequired = async ({ setSnack, setLoadingVisibility }) => {
  setSnack('', '')
  setLoadingVisibility(true)

  const { data, error, status } = await getDocuments()

  if (error) {
    return updateErrorMessage({
      setSnack,
      error,
      status,
      feedbackMessage: 'para visualizar a lista de documentos obrigatórios'
    })
  }

  const newData = data?.reduce((acc, cur) => {
    const { name, id } = cur
    const newItem = { value: id, label: name }

    return [...acc, newItem]
  }, [])

  return newData
}

export { docsReqSaved, getListDocsRequired }
