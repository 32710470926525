import { TableCell } from '@mui/material'
import { Link as RouteLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    text-align: left;
    padding: 6px 16px;
    div.content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .container_icons {
        width: auto;
        p {
          margin: 0;
        }
      }
    }
    ${({ maxHeight }) =>
      maxHeight &&
      css`
        max-width: 540px;
        max-height: 78px;
        min-height: 55px;
        overflow: hidden;
        line-height: 1.6;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      `}
  }
`

const BoxLink = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
`
const Box = styled.div`
  width: 47px;
  padding: 7px;
  display: flex;
  margin: 0 auto;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  align-items: center;
  border-radius: 16px;
  letter-spacing: 0.16px;
  justify-content: center;
  color: ${({ theme }) => theme.palette.primary.white};
  background-color: ${({ theme }) => theme.palette.success.main};

  ${({ isElectronicSignature }) =>
    isElectronicSignature &&
    css`
      background-color: ${({ theme }) => theme.palette.red.light};
    `}
`

const StyledTableCellTitle = styled(TableCell)`
  &.MuiTableCell-root {
    width: 30%;
    text-align: left;
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;
    padding: 6px 16px;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
      }
    }
  }
`

const Link = styled(RouteLink)`
  text-transform: uppercase;
`

export { StyledTableCell, StyledTableCellTitle, BoxLink, Box, Link }
