import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  details: Yup.array().of(
    Yup.object().shape({
      startDate: Yup.date().nullable().typeError('Data Inválida'),
      endDate: Yup.date()
        .nullable()
        .typeError('Data Inválida')
        .when('startDate', (startDate, schema) =>
          schema.min(
            startDate || new Date(0),
            'A data final não pode ser menor do que a data inicial'
          )
        )
    })
  )
})

export { validationSchema }
