import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Checkbox,
  Typography,
  Unstable_Grid2 as Grid
} from '@mui/material'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import InfoIcon from '@mui/icons-material/Info'
import TAG_MANAGER from 'tagManager'
import { ROUTE } from 'services/constants'
import { convertToParams } from 'services/helpers'
import { Button, InputSwitch } from 'components/atoms'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ButtonFooterV2, ModalConfirm } from 'components/molecules'
import GroupingIcon from 'components/atoms/Icons/GroupingIcon'
import ReplacementIcon from 'components/atoms/Icons/ReplacementIcon'
import ClassInfo from '../ReplaceClassDetailsEdit/components/ClassInfo'
import {
  LinkedClassDetailsEditProvider,
  useLinkedClassDetailsEditContext
} from './context'
import { breadCrumbsItems } from './constants'
import LinkedClassDetailsEditTable from './components/Table'

export default function LinkedClassDetailsEdit() {
  return (
    <LinkedClassDetailsEditProvider>
      <Content />
    </LinkedClassDetailsEditProvider>
  )
}

function Content() {
  const {
    classDetails,
    loadClassDetails,
    loadDisciplines,
    setProfessorId,
    disciplines,
    linkedClassDetailsEditForm,
    openModalInactivate,
    handleOpenModalInactivate,
    onCloseModalInactivate,
    currentOnConfirmInactivate,
    inactivateDisciplinesLinked,
    selectedIds,
    showOnlyInactives,
    setShowOnlyInactives
  } = useLinkedClassDetailsEditContext()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { isEdit, name, id, idProfessor } = params

  useEffect(() => {
    if (idProfessor) {
      setProfessorId(idProfessor)
      loadDisciplines({
        classId: id,
        idProfessor,
        onlyInactives: showOnlyInactives
      })
    }
  }, [idProfessor, showOnlyInactives])

  useEffect(() => {
    loadClassDetails(id)
  }, [id])

  return (
    <TemplateDefaultHeaderByContent
      classNameHeader="goBack"
      handleConfirmModal={() => navigate(-1)}
      breadcrumbsNotLink={breadCrumbsItems({
        code: classDetails.code,
        name
      })}
      onClickButton={() => {
        navigate(-1)
      }}
      content={
        <>
          <Grid container spacing={3}>
            <Grid>
              <ClassInfo
                info={classDetails}
                contentToAction={
                  <Button
                    type="button"
                    color="primary"
                    value="Novo Vínculo"
                    className={TAG_MANAGER.rh_btn_acessa_vincularTurma}
                    onclick={() =>
                      navigate(
                        `${
                          ROUTE.ADMIN_HR_PROFESSOR_CLASS_LINKED
                        }?${convertToParams({
                          id,
                          name
                        })}`
                      )
                    }
                  />
                }
              />
            </Grid>
            <Grid xs={12}>
              <Typography fontWeight="bold">Vínculos</Typography>
              <Typography marginTop={1}>
                <b>Professor vinculado</b> {name}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <InputSwitch
                checked={showOnlyInactives}
                label="Inativos"
                onChange={(_, value) => setShowOnlyInactives(value)}
              />
            </Grid>
            {!!disciplines.length && (
              <Grid xs={12}>
                <Button
                  disabled={!selectedIds.length}
                  value="Inativar Vínculos"
                  variant="outlined"
                  startIcon={<RemoveCircleOutlineIcon />}
                  color="warning"
                  onclick={() =>
                    handleOpenModalInactivate(() =>
                      inactivateDisciplinesLinked({ allCheked: true })
                    )
                  }
                />
              </Grid>
            )}
            <Grid xs={12}>
              <LinkedClassDetailsEditTable />
            </Grid>
          </Grid>
          <Grid container mt={(t) => t.spacing(3)} spacing={1}>
            <Grid xs={12} md={6} lg={8}>
              <Box display="flex" alignItems="center">
                <GroupingIcon />
                <Typography
                  component="span"
                  ml={(t) => t.spacing(1)}
                  variant="caption"
                >
                  Em Agrupamento
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" mt={(t) => t.spacing(1)}>
                <ReplacementIcon />
                <Typography
                  component="span"
                  ml={(t) => t.spacing(1)}
                  variant="caption"
                >
                  Em Substituição
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mt={(t) => t.spacing(1)}>
                <InfoIcon color="primary" />
                <Typography
                  component="span"
                  ml={(t) => t.spacing(1)}
                  variant="caption"
                >
                  Vínculos de disciplinas agrupadas devem ser adicionadas e/ou
                  editadas no fluxo de{' '}
                  <Link to={ROUTE.ADMIN_SECRETARY_GROUPING}>Agrupamento</Link>.
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <ButtonFooterV2
                size="large"
                labelClose="Cancelar"
                labelConfirm="salvar alterações"
                disabledConfirm={
                  !linkedClassDetailsEditForm.isValid || showOnlyInactives
                }
                onClickClose={() => navigate(-1)}
                onClickConfirm={() => linkedClassDetailsEditForm.handleSubmit()}
              />
            </Grid>
          </Grid>
          <ModalConfirm
            open={openModalInactivate}
            icon={<RemoveCircleOutlineIcon color="warning" />}
            title="Inativar"
            message="Deseja inativar esse(s) vínculo(s) de disciplina? Ao inativar, ele não poderá ser reativado e terá que ser criado novamente, caso necessário."
            labelClose="Não"
            labelConfirm="Sim"
            onClickNotConfirm={onCloseModalInactivate}
            onClickConfirm={currentOnConfirmInactivate}
          />
        </>
      }
    />
  )
}
