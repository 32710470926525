import {
  createGrouping as createGroupingService,
  editGrouping as editGroupingService
} from 'services/api/admin'
import { deserialize, messageErroOrSuccess } from 'services/helpers'
import { formatCreatePayload, formatEditPayload } from './helpers'

export const createGrouping = async ({ setSnack, values, callbackSuccess }) => {
  const { error, status } = await createGroupingService(
    deserialize(formatCreatePayload(values))
  )

  messageErroOrSuccess({
    error,
    setSnack,
    status,
    successMessage: 'Agrupamento criado com sucesso.',
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.'
  })

  if (!error) {
    setTimeout(callbackSuccess, 1000)
  }
}

export const editGrouping = async ({
  setSnack,
  values: { id, ...restValues },
  callbackSuccess
}) => {
  const { error, status } = await editGroupingService({
    id,
    payload: deserialize(formatEditPayload(restValues))
  })

  messageErroOrSuccess({
    error,
    setSnack,
    status,
    successMessage: 'Agrupamento editado com sucesso.',
    feedbackMessage: 'Houve um erro ao salvar. Tente novamente.'
  })

  if (!error) {
    setTimeout(callbackSuccess, 1000)
  }
}
