import { Table as MuiTable, TableBody } from '@mui/material'
import { PaginationAdvanced } from 'components/molecules'
import { useSortTable } from 'services/hooks'
import { TableHeaderDefault } from 'components/organisms'
import { paginationFilter } from 'services/helpers'
import { defaultItemsPerPage, headCells, defaultTotalPage } from './constants'
import * as Styled from './style'
import { TableFooter } from './TableFooter'

const TableDocumentsFiles = ({
  rows,
  count,
  totalPage,
  setFilter,
  filter,
  setOpenModalConfirm,
  setPage,
  pages,
  setIdDocument,
  handleSetPage
}) => {
  const { handleRequestSort, order, orderBy, sortTable } = useSortTable(rows)

  return (
    <>
      <Styled.Paper>
        <Styled.TableContainer>
          <MuiTable>
            <TableHeaderDefault
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {sortTable?.map((row) => (
                <TableFooter
                  row={row}
                  key={row.register}
                  setIdDocument={setIdDocument}
                  setOpenModalConfirm={setOpenModalConfirm}
                />
              ))}
            </TableBody>
          </MuiTable>
        </Styled.TableContainer>

        {count > defaultItemsPerPage && (
          <PaginationAdvanced
            value={filter}
            optionsList={paginationFilter}
            isSelectItensPerPage
            count={totalPage}
            page={pages}
            handleSetPage={handleSetPage}
            handleChange={(e) => {
              setFilter(e.target.value)
              setPage(defaultTotalPage)
            }}
          />
        )}
      </Styled.Paper>
    </>
  )
}

export { TableDocumentsFiles }
